import { useMutation } from "react-query";

const removeColumnLegend = (values) => {
  return values?.length > 1 ? values.slice(2, values.length) : [];
};

/** @type {() => import("react-query").UseMutationResult<void, unknown, { series: any[]; data: any[];  }>} */
export const useImportExcel = () => {
  return useMutation(async ({ result }) => {
    const { default: ExcelJS } = await import("exceljs");
    const workbook = new ExcelJS.Workbook();
    const res = await workbook.xlsx.load(result);
    const worksheet = workbook.getWorksheet(1);
    if (worksheet) {
      const dateColumns = removeColumnLegend(
        worksheet.getColumn(1).values
      );
      const openColumns = removeColumnLegend(worksheet.getColumn(2).values);
      const highColumns = removeColumnLegend(worksheet.getColumn(3).values);
      const lowColumns = removeColumnLegend(worksheet.getColumn(4).values);
      const closeColumns = removeColumnLegend(worksheet.getColumn(5).values);

      let series = openColumns.map((o, i) => {
        if (dateColumns[i]) {
          return {
            timestamp: Math.floor(new Date(dateColumns[i]).getTime() / 1000), // timestamp in second
            open: o,
            high: highColumns[i],
            low: lowColumns[i],
            close: closeColumns[i],
          };
        }
   
      });

      // temporarly add elements if array is too small
      if (series?.length < 1500) {
        const preArray = [...Array(1499)].map((_el) => {
          return {
            timestamp: undefined,
            open: undefined,
            high: undefined,
            low: undefined,
            close: undefined,
          };
        });
        series = [
          ...preArray,
          ...series,
        ];
      }


      return series;
    }
  });
};
