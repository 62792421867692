import styled from "@emotion/styled";
import tw from "twin.macro";

export const EmptyState = styled("div")(tw`text-center`);

export const EmptyStateIllustration = styled("svg")(
  tw`mx-auto h-12 w-12 text-gray-400`
);

export const EmptyStateTitle = styled("h3")(
  tw`mt-2 text-sm font-medium text-gray-900`
);

export const EmptyStateDescription = styled("p")(
  tw`mt-1 text-sm text-gray-500`
);

export const EmptyStateActions = styled("div")(tw`mt-6`);
