/** @jsxImportSource @emotion/react */
import { Tab } from "@headlessui/react";
import tw from "twin.macro";

const DEFAULT_TAB = [
  {
    label: "Tab A",
    content: <div></div>,
  },
  {
    label: "Tab A",
    content: <div></div>,
  },
];

export const Tabs = ({ tabs = DEFAULT_TAB }) => {
  return (
    <Tab.Group>
      <Tab.List tw="w-fit bg-white border-2 border-gray-100 rounded-md divide-x-2">
        {tabs.map((t) => (
          <Tab tw="!outline-0 ">
            {({ selected }) => (
              <button
                tw="px-10 py-2.5 text-sm"
                css={[selected && tw`border-b-4 border-b-blue-500`]}
              >
                {t.label}
              </button>
            )}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels tw="h-full w-full">
        {tabs.map((t) => (
          <Tab.Panel tw="pt-2 2xl:pt-4 h-full" >
            {t.content}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};
