import { saveAs } from "file-saver";
import { useMutation } from "react-query";

export const seriesPhase3 = [
  {
    name: "Base values",
    keys: [
      // init values
      { label: "Date", key: "date" },
      { label: "Close", key: "close" },
      { label: "base indicator", key: "baseIndicator" },

      { label: "h2", key: "h2" },

      // series T
      { label: "serieT1", key: "serieT1" },
      { label: "serieT1Indicator", key: "serieT1Indicator" },
      { label: "serieT2", key: "serieT2" },
      { label: "serieT2Indicator", key: "serieT2Indicator" },
      { label: "serieT3", key: "serieT3" },
      { label: "serieT3Indicator", key: "serieT3Indicator" },
      { label: "serieT4", key: "serieT4" },
      { label: "serieT4Indicator", key: "serieT4Indicator" },
      { label: "serieT5", key: "serieT5" },
      { label: "serieT5Indicator", key: "serieT5Indicator" },

      { label: "univSerie", key: "univSerie" },

      { label: "p2", key: "p2" },

      { label: "q", key: "q" },
      { label: "r", key: "r" },
      { label: "s", key: "s" },
      { label: "t", key: "t" },
      { label: "u", key: "u" },
      { label: "v", key: "v" },

      // red columns
      { label: "red1", key: "red1" },
      { label: "red2", key: "red2" },
      { label: "red3", key: "red3" },
      { label: "red4", key: "red4" },
      { label: "red5", key: "red5" },

      { label: "ac", key: "ac" },
      { label: "ad", key: "ad" },
      { label: "ae", key: "ae" },
      { label: "af", key: "af" },
      { label: "ag", key: "ag" },

      { label: "ai", key: "ai" },
      { label: "plannedSerie", key: "plannedSerie" },

      { label: "aj", key: "aj" },
      { label: "ak", key: "ak" },
      { label: "al", key: "al" },
      { label: "am", key: "am" },
      { label: "an", key: "an" },
      { label: "ao", key: "ao" },
      { label: "ap", key: "ap" },

      { label: "t2", key: "t2" },
      { label: "u2", key: "u2" },
      { label: "v2", key: "v2" },

      { label: "ai2", key: "ai2" },
      { label: "aj2", key: "aj2" },
      { label: "ak2", key: "ak2" },
      { label: "al2", key: "al2" },
      { label: "am2", key: "am2" },
      { label: "an2", key: "an2" },
      { label: "ao2", key: "ao2" },
      { label: "ap2", key: "ap2" },
    ],
  },
];

const horizontalSpacing = 1;

const drawColumns = (series) => {
  let columns = [];
  for (let i = 0; i < series.length; i++) {
    for (let y = 0; y < series[i].keys.length; y++) {
      columns.push({ name: series[i].keys[y].label });
    }
    for (let s = 0; s < horizontalSpacing; s++) {
      columns.push({ name: " " });
    }
  }
  return columns;
};

const parseData = (data) => {
  const base = {
    date: data.date || undefined,
    close: data.close || undefined,

    baseIndicator: data.baseIndicator || undefined,

    h2: data.h2 || undefined,
    serieT1: data.serieT1 || undefined,
    serieT1Indicator: data.serieT1Indicator || undefined,
    serieT2: data.serieT2 || undefined,
    serieT2Indicator: data.serieT2Indicator || undefined,
    serieT3: data.serieT3 || undefined,
    serieT3Indicator: data.serieT3Indicator || undefined,
    serieT4: data.serieT4 || undefined,
    serieT4Indicator: data.serieT4Indicator || undefined,
    serieT5: data.serieT5 || undefined,

    serieT5Indicator: data.serieT5Indicator || undefined,
    univSerie: data.univSerie || undefined,
    p2: data.p2 || undefined,
    q: data.q || undefined,
    r: data.r || undefined,
    s: data.s || undefined,
    t: data.t || undefined,
    u: data.u || undefined,
    v: data.v || undefined,

    red1: data.red1 || undefined,
    red2: data.red2 || undefined,
    red3: data.red3 || undefined,
    red4: data.red4 || undefined,
    red5: data.red5 || undefined,
    ac: data.ac || undefined,
    ad: data.ad || undefined,
    ae: data.ae || undefined,
    af: data.af || undefined,
    ag: data.ag || undefined,
    ai: data.ai || undefined,

    plannedSerie: data.plannedSerie || undefined,
    aj: data.aj || undefined,
    ak: data.ak || undefined,
    al: data.al || undefined,
    am: data.am || undefined,
    an: data.an || undefined,
    ao: data.ao || undefined,
    ap: data.ap || undefined,
    
    t2: data.t2 || undefined,
    u2: data.u2 || undefined,
    v2: data.v2 || undefined,
    ai2: data.ai2 || undefined,
    aj2: data.aj2 || undefined,
    ak2: data.ak2 || undefined,
    al2: data.al2 || undefined,
    am2: data.am2 || undefined,
    an2: data.an2 || undefined,
    ao2: data.ao2 || undefined,
    ap2: data.ap2 || undefined,
    index: data.index || undefined,
  };
  const modelsKeys = Object.keys(data).filter((key) => key.match('"model".*'));
  const predictions = modelsKeys.map((modelKey) => {
    const prediction = JSON.parse(modelKey.split("-")[0]);
    const valueKey = modelKey.split("-")[1];
    const value = data[modelKey];
    return { ...prediction, valueKey, value };
  });

  return {
    base,
    predictions: predictions.length > 0 ? predictions : undefined,
  };
};

const findPredictionsFromKey = (predictions, valueKey) => {
  if (predictions) {
    return predictions.filter((prediction) => valueKey === prediction.valueKey);
  } else {
    return [];
  }
};

const drawRows = (series, data) => {
  const rows = [];

  for (let i = 0; i < data.length; i++) {
    const seriesRows = [];
    const parsedData = parseData(data[i]);

    let keyIndex = 0;
    let oldValueKey = series[0].valueKey;

    for (let y = 0; y < series.length; y++) {
      const serie = series[y];

      // Find predictions associated to the valueKey
      const currentPredictions = findPredictionsFromKey(
        parsedData.predictions,
        serie.valueKey
      );

      // If we don't have a duplicated valueKey we reset the keyIndex
      if (oldValueKey !== serie.valueKey) {
        keyIndex = 0;
      }

      // Explore all needed keys
      for (let x = 0; x < serie.keys.length; x++) {
        const currentKey = serie.keys[x].key;

        // Get a base value
        if (parsedData.base[currentKey]) {
          seriesRows.push(parsedData.base[currentKey]);
        } else {
          // Get a prediction value
          if (parsedData.predictions) {
            if (currentPredictions.length > 0) {
              const value = currentPredictions[keyIndex][currentKey];
              seriesRows.push(value);
            } else {
              // If prediction is empty push an empty column
              seriesRows.push("");
            }
            // If not base & not prediction push an empty column
          } else {
            seriesRows.push("");
          }
        }
      }

      // If multiple prediction with identical valueKey, we increment index to get the next value
      if (
        currentPredictions.length !== 0 &&
        currentPredictions.length - 1 > keyIndex
      ) {
        keyIndex++;
      }
      oldValueKey = serie.valueKey;

      // Add spacing
      for (let s = 0; s < horizontalSpacing; s++) {
        seriesRows.push("");
      }
    }
    rows.push(seriesRows.flat());
  }
  return rows;
};

/** @type {() => import("react-query").UseMutationResult<void, unknown, { series: any[]; data: any[];  }>} */
export const useExportDataToXSLXPhase3Mutation = () => {
  return useMutation(async ({ phase3 }) => {
    const { default: ExcelJS } = await import("exceljs");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("export");

    worksheet.addTable({
      ref: "A1",
      columns: drawColumns(seriesPhase3),
      rows: drawRows(seriesPhase3, phase3.exportData),
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    let fileName = "export-phase3";
    saveAs(blob, `${fileName}.xlsx`);
  });
};
