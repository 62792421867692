export const historicalSeries = [
	{
		id: 0,
		label: "EUR/USD - 5min",
		value: "EUR/USD - 5min",
		symbol: "EUR/USD",
		period: "5min",
		from: "2022/04/11",
		to: "2022/05/11",
    tradingInstrumentId: 1,
		timestepInSeconds: 5 * 60,
		data: [
      {
        timestamp: 1649682300,
        open: 1.08992,
        close: 1.09029,
        high: 1.09051,
        low: 1.08991
      },
      {
        timestamp: 1649682600,
        open: 1.09028,
        close: 1.08985,
        high: 1.09043,
        low: 1.08945
      },
      {
        timestamp: 1649682900,
        open: 1.08985,
        close: 1.08962,
        high: 1.09018,
        low: 1.08959
      },
      {
        timestamp: 1649683200,
        open: 1.08962,
        close: 1.08933,
        high: 1.08976,
        low: 1.08928
      },
      {
        timestamp: 1649683500,
        open: 1.08933,
        close: 1.08868,
        high: 1.08955,
        low: 1.08862
      },
      {
        timestamp: 1649683800,
        open: 1.08869,
        close: 1.08842,
        high: 1.0892,
        low: 1.08823
      },
      {
        timestamp: 1649684100,
        open: 1.08843,
        close: 1.08816,
        high: 1.08863,
        low: 1.08797
      },
      {
        timestamp: 1649684400,
        open: 1.08816,
        close: 1.08876,
        high: 1.08876,
        low: 1.08803
      },
      {
        timestamp: 1649684700,
        open: 1.08876,
        close: 1.08874,
        high: 1.08899,
        low: 1.08843
      },
      {
        timestamp: 1649685000,
        open: 1.08875,
        close: 1.08913,
        high: 1.08917,
        low: 1.0887
      },
      {
        timestamp: 1649685300,
        open: 1.08912,
        close: 1.0882,
        high: 1.08916,
        low: 1.08819
      },
      {
        timestamp: 1649685600,
        open: 1.08819,
        close: 1.08878,
        high: 1.08882,
        low: 1.08785
      },
      {
        timestamp: 1649685900,
        open: 1.08878,
        close: 1.08893,
        high: 1.08906,
        low: 1.08864
      },
      {
        timestamp: 1649686200,
        open: 1.08892,
        close: 1.08914,
        high: 1.08947,
        low: 1.08888
      },
      {
        timestamp: 1649686500,
        open: 1.08914,
        close: 1.08886,
        high: 1.08933,
        low: 1.08885
      },
      {
        timestamp: 1649686800,
        open: 1.08884,
        close: 1.08899,
        high: 1.08899,
        low: 1.08834
      },
      {
        timestamp: 1649687100,
        open: 1.089,
        close: 1.08853,
        high: 1.08908,
        low: 1.08844
      },
      {
        timestamp: 1649687400,
        open: 1.08854,
        close: 1.08892,
        high: 1.08913,
        low: 1.08848
      },
      {
        timestamp: 1649687700,
        open: 1.08892,
        close: 1.0889,
        high: 1.08903,
        low: 1.08852
      },
      {
        timestamp: 1649688000,
        open: 1.08891,
        close: 1.08871,
        high: 1.08903,
        low: 1.08849
      },
      {
        timestamp: 1649688300,
        open: 1.08871,
        close: 1.08945,
        high: 1.08954,
        low: 1.08871
      },
      {
        timestamp: 1649688600,
        open: 1.08944,
        close: 1.08964,
        high: 1.08982,
        low: 1.08916
      },
      {
        timestamp: 1649688900,
        open: 1.08964,
        close: 1.08963,
        high: 1.08991,
        low: 1.08941
      },
      {
        timestamp: 1649689200,
        open: 1.08963,
        close: 1.08992,
        high: 1.08993,
        low: 1.08944
      },
      {
        timestamp: 1649689500,
        open: 1.08993,
        close: 1.08931,
        high: 1.09002,
        low: 1.08928
      },
      {
        timestamp: 1649689800,
        open: 1.08931,
        close: 1.08876,
        high: 1.08937,
        low: 1.08868
      },
      {
        timestamp: 1649690100,
        open: 1.08875,
        close: 1.08896,
        high: 1.08923,
        low: 1.08859
      },
      {
        timestamp: 1649690400,
        open: 1.08894,
        close: 1.08863,
        high: 1.0891,
        low: 1.08855
      },
      {
        timestamp: 1649690700,
        open: 1.08863,
        close: 1.08859,
        high: 1.08879,
        low: 1.08833
      },
      {
        timestamp: 1649691000,
        open: 1.08858,
        close: 1.08878,
        high: 1.08885,
        low: 1.08841
      },
      {
        timestamp: 1649691300,
        open: 1.08878,
        close: 1.08855,
        high: 1.08891,
        low: 1.08853
      },
      {
        timestamp: 1649691600,
        open: 1.08856,
        close: 1.08856,
        high: 1.08886,
        low: 1.0885
      },
      {
        timestamp: 1649691900,
        open: 1.08857,
        close: 1.08795,
        high: 1.08874,
        low: 1.08793
      },
      {
        timestamp: 1649692200,
        open: 1.08794,
        close: 1.08767,
        high: 1.08802,
        low: 1.08759
      },
      {
        timestamp: 1649692500,
        open: 1.08767,
        close: 1.08763,
        high: 1.08769,
        low: 1.08743
      },
      {
        timestamp: 1649692800,
        open: 1.08765,
        close: 1.08748,
        high: 1.0877,
        low: 1.08734
      },
      {
        timestamp: 1649693100,
        open: 1.08747,
        close: 1.08752,
        high: 1.08763,
        low: 1.08745
      },
      {
        timestamp: 1649693400,
        open: 1.08751,
        close: 1.08758,
        high: 1.08767,
        low: 1.08742
      },
      {
        timestamp: 1649693700,
        open: 1.08758,
        close: 1.08786,
        high: 1.08788,
        low: 1.08755
      },
      {
        timestamp: 1649694000,
        open: 1.08787,
        close: 1.08775,
        high: 1.08796,
        low: 1.08764
      },
      {
        timestamp: 1649694300,
        open: 1.08776,
        close: 1.08822,
        high: 1.08822,
        low: 1.08766
      },
      {
        timestamp: 1649694600,
        open: 1.08821,
        close: 1.0885,
        high: 1.08852,
        low: 1.08816
      },
      {
        timestamp: 1649694900,
        open: 1.08851,
        close: 1.08836,
        high: 1.08856,
        low: 1.08815
      },
      {
        timestamp: 1649695200,
        open: 1.08836,
        close: 1.08825,
        high: 1.08839,
        low: 1.08816
      },
      {
        timestamp: 1649695500,
        open: 1.08825,
        close: 1.08841,
        high: 1.08851,
        low: 1.08825
      },
      {
        timestamp: 1649695800,
        open: 1.08841,
        close: 1.08775,
        high: 1.08841,
        low: 1.08764
      },
      {
        timestamp: 1649696100,
        open: 1.08774,
        close: 1.08785,
        high: 1.08802,
        low: 1.08774
      },
      {
        timestamp: 1649696400,
        open: 1.08784,
        close: 1.08827,
        high: 1.08832,
        low: 1.08783
      },
      {
        timestamp: 1649696700,
        open: 1.08826,
        close: 1.0887,
        high: 1.08872,
        low: 1.08822
      },
      {
        timestamp: 1649697000,
        open: 1.0887,
        close: 1.0882,
        high: 1.08872,
        low: 1.08815
      },
      {
        timestamp: 1649697300,
        open: 1.08819,
        close: 1.08791,
        high: 1.08825,
        low: 1.08791
      },
      {
        timestamp: 1649697600,
        open: 1.08791,
        close: 1.08795,
        high: 1.08804,
        low: 1.08782
      },
      {
        timestamp: 1649697900,
        open: 1.08798,
        close: 1.08788,
        high: 1.08812,
        low: 1.08788
      },
      {
        timestamp: 1649698200,
        open: 1.08788,
        close: 1.08832,
        high: 1.08833,
        low: 1.08786
      },
      {
        timestamp: 1649698500,
        open: 1.08832,
        close: 1.0883,
        high: 1.08855,
        low: 1.08827
      },
      {
        timestamp: 1649698800,
        open: 1.08829,
        close: 1.08855,
        high: 1.08859,
        low: 1.08818
      },
      {
        timestamp: 1649699100,
        open: 1.08855,
        close: 1.08874,
        high: 1.08894,
        low: 1.08855
      },
      {
        timestamp: 1649699400,
        open: 1.08875,
        close: 1.08849,
        high: 1.0888,
        low: 1.08844
      },
      {
        timestamp: 1649699700,
        open: 1.08848,
        close: 1.08829,
        high: 1.08853,
        low: 1.08825
      },
      {
        timestamp: 1649700000,
        open: 1.0883,
        close: 1.08843,
        high: 1.08843,
        low: 1.08812
      },
      {
        timestamp: 1649700300,
        open: 1.08843,
        close: 1.08869,
        high: 1.08885,
        low: 1.08832
      },
      {
        timestamp: 1649700600,
        open: 1.08868,
        close: 1.08864,
        high: 1.08876,
        low: 1.08858
      },
      {
        timestamp: 1649700900,
        open: 1.08863,
        close: 1.0889,
        high: 1.08895,
        low: 1.08862
      },
      {
        timestamp: 1649701200,
        open: 1.08891,
        close: 1.08905,
        high: 1.08906,
        low: 1.0888
      },
      {
        timestamp: 1649701500,
        open: 1.08906,
        close: 1.08888,
        high: 1.0892,
        low: 1.08883
      },
      {
        timestamp: 1649701800,
        open: 1.08886,
        close: 1.08946,
        high: 1.08948,
        low: 1.08886
      },
      {
        timestamp: 1649702100,
        open: 1.08946,
        close: 1.08932,
        high: 1.08955,
        low: 1.0892
      },
      {
        timestamp: 1649702400,
        open: 1.08932,
        close: 1.08936,
        high: 1.08948,
        low: 1.08932
      },
      {
        timestamp: 1649702700,
        open: 1.08935,
        close: 1.08949,
        high: 1.08949,
        low: 1.08928
      },
      {
        timestamp: 1649703000,
        open: 1.08948,
        close: 1.08937,
        high: 1.0895,
        low: 1.08922
      },
      {
        timestamp: 1649703300,
        open: 1.08936,
        close: 1.08908,
        high: 1.08936,
        low: 1.08878
      },
      {
        timestamp: 1649703600,
        open: 1.08908,
        close: 1.08895,
        high: 1.08915,
        low: 1.08889
      },
      {
        timestamp: 1649703900,
        open: 1.08893,
        close: 1.08905,
        high: 1.08915,
        low: 1.08893
      },
      {
        timestamp: 1649704200,
        open: 1.08905,
        close: 1.08892,
        high: 1.08915,
        low: 1.08882
      },
      {
        timestamp: 1649704500,
        open: 1.08893,
        close: 1.08873,
        high: 1.08895,
        low: 1.08872
      },
      {
        timestamp: 1649704800,
        open: 1.08873,
        close: 1.08877,
        high: 1.0888,
        low: 1.08863
      },
      {
        timestamp: 1649705100,
        open: 1.08876,
        close: 1.08865,
        high: 1.08876,
        low: 1.08863
      },
      {
        timestamp: 1649705400,
        open: 1.08864,
        close: 1.08867,
        high: 1.08882,
        low: 1.08858
      },
      {
        timestamp: 1649705700,
        open: 1.08867,
        close: 1.08865,
        high: 1.08876,
        low: 1.0886
      },
      {
        timestamp: 1649706000,
        open: 1.08864,
        close: 1.08883,
        high: 1.08888,
        low: 1.0886
      },
      {
        timestamp: 1649706300,
        open: 1.08884,
        close: 1.08869,
        high: 1.08884,
        low: 1.08853
      },
      {
        timestamp: 1649706600,
        open: 1.08869,
        close: 1.08833,
        high: 1.08876,
        low: 1.08831
      },
      {
        timestamp: 1649706900,
        open: 1.08833,
        close: 1.08849,
        high: 1.08851,
        low: 1.08823
      },
      {
        timestamp: 1649707200,
        open: 1.08844,
        close: 1.08848,
        high: 1.0886,
        low: 1.0884
      },
      {
        timestamp: 1649707500,
        open: 1.08848,
        close: 1.08838,
        high: 1.08851,
        low: 1.0883
      },
      {
        timestamp: 1649707800,
        open: 1.08838,
        close: 1.08834,
        high: 1.08845,
        low: 1.0883
      },
      {
        timestamp: 1649708100,
        open: 1.08833,
        close: 1.08834,
        high: 1.08839,
        low: 1.08828
      },
      {
        timestamp: 1649708400,
        open: 1.08832,
        close: 1.08832,
        high: 1.08839,
        low: 1.08829
      },
      {
        timestamp: 1649708700,
        open: 1.08831,
        close: 1.08817,
        high: 1.08833,
        low: 1.0881
      },
      {
        timestamp: 1649709000,
        open: 1.08816,
        close: 1.08814,
        high: 1.08827,
        low: 1.08813
      },
      {
        timestamp: 1649709300,
        open: 1.08814,
        close: 1.08826,
        high: 1.0883,
        low: 1.08814
      },
      {
        timestamp: 1649709600,
        open: 1.08824,
        close: 1.08827,
        high: 1.08828,
        low: 1.08809
      },
      {
        timestamp: 1649709900,
        open: 1.08829,
        close: 1.08827,
        high: 1.08837,
        low: 1.08821
      },
      {
        timestamp: 1649710200,
        open: 1.08826,
        close: 1.08844,
        high: 1.0885,
        low: 1.08824
      },
      {
        timestamp: 1649710500,
        open: 1.08844,
        close: 1.08844,
        high: 1.08851,
        low: 1.08842
      },
      {
        timestamp: 1649710800,
        open: 1.08844,
        close: 1.08859,
        high: 1.08913,
        low: 1.08844
      },
      {
        timestamp: 1649711100,
        open: 1.08859,
        close: 1.08856,
        high: 1.08862,
        low: 1.08856
      },
      {
        timestamp: 1649711400,
        open: 1.08849,
        close: 1.08854,
        high: 1.08854,
        low: 1.08849
      },
      {
        timestamp: 1649711700,
        open: 1.08856,
        close: 1.08854,
        high: 1.08856,
        low: 1.08842
      },
      {
        timestamp: 1649712000,
        open: 1.08859,
        close: 1.08911,
        high: 1.08915,
        low: 1.08859
      },
      {
        timestamp: 1649712300,
        open: 1.08885,
        close: 1.0888,
        high: 1.08893,
        low: 1.08849
      },
      {
        timestamp: 1649712600,
        open: 1.08878,
        close: 1.0884,
        high: 1.08878,
        low: 1.08833
      },
      {
        timestamp: 1649712900,
        open: 1.08841,
        close: 1.08848,
        high: 1.08863,
        low: 1.08832
      },
      {
        timestamp: 1649713200,
        open: 1.08847,
        close: 1.08841,
        high: 1.08848,
        low: 1.08837
      },
      {
        timestamp: 1649713500,
        open: 1.08842,
        close: 1.08836,
        high: 1.08848,
        low: 1.08836
      },
      {
        timestamp: 1649713800,
        open: 1.08835,
        close: 1.08839,
        high: 1.08842,
        low: 1.08834
      },
      {
        timestamp: 1649714100,
        open: 1.08838,
        close: 1.08845,
        high: 1.08855,
        low: 1.08832
      },
      {
        timestamp: 1649714400,
        open: 1.08844,
        close: 1.08854,
        high: 1.0887,
        low: 1.08844
      },
      {
        timestamp: 1649714700,
        open: 1.08854,
        close: 1.0885,
        high: 1.08856,
        low: 1.08836
      },
      {
        timestamp: 1649715000,
        open: 1.0885,
        close: 1.08852,
        high: 1.08858,
        low: 1.08847
      },
      {
        timestamp: 1649715300,
        open: 1.08851,
        close: 1.08842,
        high: 1.08856,
        low: 1.08837
      },
      {
        timestamp: 1649715600,
        open: 1.08841,
        close: 1.08866,
        high: 1.08867,
        low: 1.08834
      },
      {
        timestamp: 1649715900,
        open: 1.08866,
        close: 1.08865,
        high: 1.08868,
        low: 1.08859
      },
      {
        timestamp: 1649716200,
        open: 1.08864,
        close: 1.08864,
        high: 1.0887,
        low: 1.08852
      },
      {
        timestamp: 1649716500,
        open: 1.08862,
        close: 1.08852,
        high: 1.08864,
        low: 1.0885
      },
      {
        timestamp: 1649716800,
        open: 1.08852,
        close: 1.08836,
        high: 1.08854,
        low: 1.08836
      },
      {
        timestamp: 1649717100,
        open: 1.08837,
        close: 1.08817,
        high: 1.08837,
        low: 1.08808
      },
      {
        timestamp: 1649717400,
        open: 1.08817,
        close: 1.08809,
        high: 1.08819,
        low: 1.08808
      },
      {
        timestamp: 1649717700,
        open: 1.08809,
        close: 1.08812,
        high: 1.08821,
        low: 1.08806
      },
      {
        timestamp: 1649718000,
        open: 1.08812,
        close: 1.08806,
        high: 1.0883,
        low: 1.08802
      },
      {
        timestamp: 1649718300,
        open: 1.08803,
        close: 1.08801,
        high: 1.08817,
        low: 1.08799
      },
      {
        timestamp: 1649718600,
        open: 1.08801,
        close: 1.08794,
        high: 1.08809,
        low: 1.08789
      },
      {
        timestamp: 1649718900,
        open: 1.08794,
        close: 1.08833,
        high: 1.0884,
        low: 1.0879
      },
      {
        timestamp: 1649719200,
        open: 1.08833,
        close: 1.08823,
        high: 1.08863,
        low: 1.08818
      },
      {
        timestamp: 1649719500,
        open: 1.08823,
        close: 1.08811,
        high: 1.08824,
        low: 1.08807
      },
      {
        timestamp: 1649719800,
        open: 1.08813,
        close: 1.08813,
        high: 1.08819,
        low: 1.08804
      },
      {
        timestamp: 1649720100,
        open: 1.08813,
        close: 1.08805,
        high: 1.08813,
        low: 1.08802
      },
      {
        timestamp: 1649720400,
        open: 1.08804,
        close: 1.08794,
        high: 1.08813,
        low: 1.08794
      },
      {
        timestamp: 1649720700,
        open: 1.08793,
        close: 1.08778,
        high: 1.08801,
        low: 1.08777
      },
      {
        timestamp: 1649721000,
        open: 1.08778,
        close: 1.08782,
        high: 1.08798,
        low: 1.08775
      },
      {
        timestamp: 1649721300,
        open: 1.08782,
        close: 1.08777,
        high: 1.08788,
        low: 1.08776
      },
      {
        timestamp: 1649721600,
        open: 1.08777,
        close: 1.08769,
        high: 1.08807,
        low: 1.08762
      },
      {
        timestamp: 1649721900,
        open: 1.08769,
        close: 1.08813,
        high: 1.08823,
        low: 1.08767
      },
      {
        timestamp: 1649722200,
        open: 1.08814,
        close: 1.08851,
        high: 1.0888,
        low: 1.08809
      },
      {
        timestamp: 1649722500,
        open: 1.08852,
        close: 1.08789,
        high: 1.08855,
        low: 1.08789
      },
      {
        timestamp: 1649722800,
        open: 1.0879,
        close: 1.08824,
        high: 1.0883,
        low: 1.08788
      },
      {
        timestamp: 1649723100,
        open: 1.08824,
        close: 1.08811,
        high: 1.08835,
        low: 1.08804
      },
      {
        timestamp: 1649723400,
        open: 1.08812,
        close: 1.08775,
        high: 1.08821,
        low: 1.08765
      },
      {
        timestamp: 1649723700,
        open: 1.08774,
        close: 1.08765,
        high: 1.08774,
        low: 1.08758
      },
      {
        timestamp: 1649724000,
        open: 1.08764,
        close: 1.08744,
        high: 1.08766,
        low: 1.08734
      },
      {
        timestamp: 1649724300,
        open: 1.08742,
        close: 1.08728,
        high: 1.08742,
        low: 1.08722
      },
      {
        timestamp: 1649724600,
        open: 1.08728,
        close: 1.08759,
        high: 1.08762,
        low: 1.08717
      },
      {
        timestamp: 1649724900,
        open: 1.0876,
        close: 1.08752,
        high: 1.08769,
        low: 1.08751
      },
      {
        timestamp: 1649725200,
        open: 1.08752,
        close: 1.08721,
        high: 1.08752,
        low: 1.08719
      },
      {
        timestamp: 1649725500,
        open: 1.08721,
        close: 1.08737,
        high: 1.0874,
        low: 1.08721
      },
      {
        timestamp: 1649725800,
        open: 1.08738,
        close: 1.08708,
        high: 1.08738,
        low: 1.08708
      },
      {
        timestamp: 1649726100,
        open: 1.08708,
        close: 1.08688,
        high: 1.08709,
        low: 1.08677
      },
      {
        timestamp: 1649726400,
        open: 1.08688,
        close: 1.08674,
        high: 1.08702,
        low: 1.08672
      },
      {
        timestamp: 1649726700,
        open: 1.08673,
        close: 1.08688,
        high: 1.08705,
        low: 1.0867
      },
      {
        timestamp: 1649727000,
        open: 1.08688,
        close: 1.08701,
        high: 1.08704,
        low: 1.08681
      },
      {
        timestamp: 1649727300,
        open: 1.08702,
        close: 1.08726,
        high: 1.08731,
        low: 1.08691
      },
      {
        timestamp: 1649727600,
        open: 1.08725,
        close: 1.08698,
        high: 1.08726,
        low: 1.08692
      },
      {
        timestamp: 1649727900,
        open: 1.08697,
        close: 1.08708,
        high: 1.08709,
        low: 1.08681
      },
      {
        timestamp: 1649728200,
        open: 1.08707,
        close: 1.08712,
        high: 1.08725,
        low: 1.08707
      },
      {
        timestamp: 1649728500,
        open: 1.08713,
        close: 1.08717,
        high: 1.0872,
        low: 1.08709
      },
      {
        timestamp: 1649728800,
        open: 1.08718,
        close: 1.08694,
        high: 1.08719,
        low: 1.08687
      },
      {
        timestamp: 1649729100,
        open: 1.08693,
        close: 1.08683,
        high: 1.08694,
        low: 1.08675
      },
      {
        timestamp: 1649729400,
        open: 1.08682,
        close: 1.08697,
        high: 1.08702,
        low: 1.08677
      },
      {
        timestamp: 1649729700,
        open: 1.08698,
        close: 1.08685,
        high: 1.08707,
        low: 1.08682
      },
      {
        timestamp: 1649730000,
        open: 1.08683,
        close: 1.08702,
        high: 1.08712,
        low: 1.08679
      },
      {
        timestamp: 1649730300,
        open: 1.08701,
        close: 1.08725,
        high: 1.08726,
        low: 1.087
      },
      {
        timestamp: 1649730600,
        open: 1.08724,
        close: 1.08737,
        high: 1.08739,
        low: 1.08721
      },
      {
        timestamp: 1649730900,
        open: 1.08737,
        close: 1.0874,
        high: 1.08754,
        low: 1.08731
      },
      {
        timestamp: 1649731200,
        open: 1.0874,
        close: 1.08744,
        high: 1.08751,
        low: 1.08737
      },
      {
        timestamp: 1649731500,
        open: 1.08745,
        close: 1.08731,
        high: 1.08749,
        low: 1.08725
      },
      {
        timestamp: 1649731800,
        open: 1.08731,
        close: 1.08731,
        high: 1.0874,
        low: 1.08717
      },
      {
        timestamp: 1649732100,
        open: 1.08732,
        close: 1.08722,
        high: 1.08738,
        low: 1.08712
      },
      {
        timestamp: 1649732400,
        open: 1.08721,
        close: 1.08761,
        high: 1.08762,
        low: 1.08718
      },
      {
        timestamp: 1649732700,
        open: 1.0876,
        close: 1.08771,
        high: 1.08772,
        low: 1.08751
      },
      {
        timestamp: 1649733000,
        open: 1.0877,
        close: 1.0876,
        high: 1.08776,
        low: 1.08756
      },
      {
        timestamp: 1649733300,
        open: 1.08759,
        close: 1.08759,
        high: 1.08763,
        low: 1.08749
      },
      {
        timestamp: 1649733600,
        open: 1.0876,
        close: 1.08746,
        high: 1.08761,
        low: 1.08743
      },
      {
        timestamp: 1649733900,
        open: 1.08746,
        close: 1.0874,
        high: 1.08746,
        low: 1.08732
      },
      {
        timestamp: 1649734200,
        open: 1.08739,
        close: 1.08751,
        high: 1.08762,
        low: 1.08739
      },
      {
        timestamp: 1649734500,
        open: 1.08751,
        close: 1.08741,
        high: 1.08758,
        low: 1.08733
      },
      {
        timestamp: 1649734800,
        open: 1.0874,
        close: 1.08731,
        high: 1.08745,
        low: 1.08731
      },
      {
        timestamp: 1649735100,
        open: 1.08732,
        close: 1.08746,
        high: 1.08746,
        low: 1.08731
      },
      {
        timestamp: 1649735400,
        open: 1.08746,
        close: 1.08736,
        high: 1.0875,
        low: 1.08728
      },
      {
        timestamp: 1649735700,
        open: 1.08737,
        close: 1.08752,
        high: 1.08756,
        low: 1.08735
      },
      {
        timestamp: 1649736000,
        open: 1.08755,
        close: 1.08762,
        high: 1.08775,
        low: 1.08754
      },
      {
        timestamp: 1649736300,
        open: 1.08762,
        close: 1.08768,
        high: 1.08772,
        low: 1.0876
      },
      {
        timestamp: 1649736600,
        open: 1.08767,
        close: 1.08762,
        high: 1.08776,
        low: 1.08761
      },
      {
        timestamp: 1649736900,
        open: 1.08763,
        close: 1.08774,
        high: 1.08775,
        low: 1.08757
      },
      {
        timestamp: 1649737200,
        open: 1.08775,
        close: 1.08806,
        high: 1.08807,
        low: 1.08775
      },
      {
        timestamp: 1649737500,
        open: 1.08805,
        close: 1.08823,
        high: 1.08832,
        low: 1.08802
      },
      {
        timestamp: 1649737800,
        open: 1.08823,
        close: 1.08838,
        high: 1.08839,
        low: 1.08819
      },
      {
        timestamp: 1649738100,
        open: 1.08838,
        close: 1.08825,
        high: 1.0884,
        low: 1.08818
      },
      {
        timestamp: 1649738400,
        open: 1.08826,
        close: 1.0882,
        high: 1.08827,
        low: 1.08819
      },
      {
        timestamp: 1649738700,
        open: 1.08819,
        close: 1.08834,
        high: 1.08834,
        low: 1.08817
      },
      {
        timestamp: 1649739000,
        open: 1.08834,
        close: 1.08818,
        high: 1.08834,
        low: 1.08816
      },
      {
        timestamp: 1649739300,
        open: 1.08817,
        close: 1.08802,
        high: 1.08822,
        low: 1.08799
      },
      {
        timestamp: 1649739600,
        open: 1.08802,
        close: 1.08785,
        high: 1.08806,
        low: 1.0878
      },
      {
        timestamp: 1649739900,
        open: 1.08785,
        close: 1.08789,
        high: 1.08796,
        low: 1.08781
      },
      {
        timestamp: 1649740200,
        open: 1.08789,
        close: 1.08784,
        high: 1.08794,
        low: 1.08767
      },
      {
        timestamp: 1649740500,
        open: 1.08784,
        close: 1.08784,
        high: 1.08789,
        low: 1.08766
      },
      {
        timestamp: 1649740800,
        open: 1.08783,
        close: 1.08801,
        high: 1.08814,
        low: 1.08779
      },
      {
        timestamp: 1649741100,
        open: 1.08801,
        close: 1.08758,
        high: 1.08806,
        low: 1.08747
      },
      {
        timestamp: 1649741400,
        open: 1.08759,
        close: 1.08799,
        high: 1.08805,
        low: 1.08758
      },
      {
        timestamp: 1649741700,
        open: 1.088,
        close: 1.08799,
        high: 1.08809,
        low: 1.08788
      },
      {
        timestamp: 1649742000,
        open: 1.08798,
        close: 1.08817,
        high: 1.08827,
        low: 1.08796
      },
      {
        timestamp: 1649742300,
        open: 1.08816,
        close: 1.0879,
        high: 1.08825,
        low: 1.08787
      },
      {
        timestamp: 1649742600,
        open: 1.08789,
        close: 1.08736,
        high: 1.0879,
        low: 1.08733
      },
      {
        timestamp: 1649742900,
        open: 1.08736,
        close: 1.08714,
        high: 1.08754,
        low: 1.08708
      },
      {
        timestamp: 1649743200,
        open: 1.08715,
        close: 1.08729,
        high: 1.0873,
        low: 1.08651
      },
      {
        timestamp: 1649743500,
        open: 1.08728,
        close: 1.08667,
        high: 1.08728,
        low: 1.08667
      },
      {
        timestamp: 1649743800,
        open: 1.08668,
        close: 1.08704,
        high: 1.08707,
        low: 1.08661
      },
      {
        timestamp: 1649744100,
        open: 1.08704,
        close: 1.08669,
        high: 1.08707,
        low: 1.08663
      },
      {
        timestamp: 1649744400,
        open: 1.08668,
        close: 1.08636,
        high: 1.08675,
        low: 1.08632
      },
      {
        timestamp: 1649744700,
        open: 1.08636,
        close: 1.08645,
        high: 1.08649,
        low: 1.08613
      },
      {
        timestamp: 1649745000,
        open: 1.08645,
        close: 1.08652,
        high: 1.08657,
        low: 1.08625
      },
      {
        timestamp: 1649745300,
        open: 1.08651,
        close: 1.0866,
        high: 1.08674,
        low: 1.0863
      },
      {
        timestamp: 1649745600,
        open: 1.08659,
        close: 1.08669,
        high: 1.08686,
        low: 1.08659
      },
      {
        timestamp: 1649745900,
        open: 1.0867,
        close: 1.08691,
        high: 1.08704,
        low: 1.08668
      },
      {
        timestamp: 1649746200,
        open: 1.08691,
        close: 1.08681,
        high: 1.08703,
        low: 1.08669
      },
      {
        timestamp: 1649746500,
        open: 1.0868,
        close: 1.08611,
        high: 1.08681,
        low: 1.08608
      },
      {
        timestamp: 1649746800,
        open: 1.08611,
        close: 1.08558,
        high: 1.08633,
        low: 1.08544
      },
      {
        timestamp: 1649747100,
        open: 1.08561,
        close: 1.08623,
        high: 1.08632,
        low: 1.08541
      },
      {
        timestamp: 1649747400,
        open: 1.08624,
        close: 1.08569,
        high: 1.08641,
        low: 1.08567
      },
      {
        timestamp: 1649747700,
        open: 1.0857,
        close: 1.08587,
        high: 1.0864,
        low: 1.08542
      },
      {
        timestamp: 1649748000,
        open: 1.08587,
        close: 1.08608,
        high: 1.08617,
        low: 1.08564
      },
      {
        timestamp: 1649748300,
        open: 1.08609,
        close: 1.0861,
        high: 1.08646,
        low: 1.08602
      },
      {
        timestamp: 1649748600,
        open: 1.0861,
        close: 1.08639,
        high: 1.08652,
        low: 1.08593
      },
      {
        timestamp: 1649748900,
        open: 1.08637,
        close: 1.08674,
        high: 1.08692,
        low: 1.08634
      },
      {
        timestamp: 1649749200,
        open: 1.08674,
        close: 1.08643,
        high: 1.08685,
        low: 1.08634
      },
      {
        timestamp: 1649749500,
        open: 1.08644,
        close: 1.08669,
        high: 1.08674,
        low: 1.08634
      },
      {
        timestamp: 1649749800,
        open: 1.08669,
        close: 1.0871,
        high: 1.08716,
        low: 1.08656
      },
      {
        timestamp: 1649750100,
        open: 1.0871,
        close: 1.08715,
        high: 1.08737,
        low: 1.08696
      },
      {
        timestamp: 1649750400,
        open: 1.08715,
        close: 1.08647,
        high: 1.08721,
        low: 1.08646
      },
      {
        timestamp: 1649750700,
        open: 1.08646,
        close: 1.08676,
        high: 1.0868,
        low: 1.08634
      },
      {
        timestamp: 1649751000,
        open: 1.08676,
        close: 1.08701,
        high: 1.08703,
        low: 1.08667
      },
      {
        timestamp: 1649751300,
        open: 1.087,
        close: 1.08639,
        high: 1.087,
        low: 1.0862
      },
      {
        timestamp: 1649751600,
        open: 1.08638,
        close: 1.08669,
        high: 1.08669,
        low: 1.08631
      },
      {
        timestamp: 1649751900,
        open: 1.08667,
        close: 1.08648,
        high: 1.08691,
        low: 1.08648
      },
      {
        timestamp: 1649752200,
        open: 1.08648,
        close: 1.08623,
        high: 1.08652,
        low: 1.08615
      },
      {
        timestamp: 1649752500,
        open: 1.08621,
        close: 1.08637,
        high: 1.08665,
        low: 1.08621
      },
      {
        timestamp: 1649752800,
        open: 1.08638,
        close: 1.08618,
        high: 1.08672,
        low: 1.08609
      },
      {
        timestamp: 1649753100,
        open: 1.08619,
        close: 1.0859,
        high: 1.08619,
        low: 1.08558
      },
      {
        timestamp: 1649753400,
        open: 1.08591,
        close: 1.08633,
        high: 1.08637,
        low: 1.08586
      },
      {
        timestamp: 1649753700,
        open: 1.08632,
        close: 1.08628,
        high: 1.08641,
        low: 1.08609
      },
      {
        timestamp: 1649754000,
        open: 1.08628,
        close: 1.08635,
        high: 1.08655,
        low: 1.08605
      },
      {
        timestamp: 1649754300,
        open: 1.08635,
        close: 1.08661,
        high: 1.08675,
        low: 1.08623
      },
      {
        timestamp: 1649754600,
        open: 1.0866,
        close: 1.08707,
        high: 1.08715,
        low: 1.08654
      },
      {
        timestamp: 1649754900,
        open: 1.08708,
        close: 1.08706,
        high: 1.08732,
        low: 1.08693
      },
      {
        timestamp: 1649755200,
        open: 1.08707,
        close: 1.08696,
        high: 1.08716,
        low: 1.08687
      },
      {
        timestamp: 1649755500,
        open: 1.08695,
        close: 1.0868,
        high: 1.08705,
        low: 1.08673
      },
      {
        timestamp: 1649755800,
        open: 1.0868,
        close: 1.08681,
        high: 1.08704,
        low: 1.08665
      },
      {
        timestamp: 1649756100,
        open: 1.08681,
        close: 1.08677,
        high: 1.08694,
        low: 1.08667
      },
      {
        timestamp: 1649756400,
        open: 1.08677,
        close: 1.08696,
        high: 1.08712,
        low: 1.08675
      },
      {
        timestamp: 1649756700,
        open: 1.08696,
        close: 1.08677,
        high: 1.087,
        low: 1.08668
      },
      {
        timestamp: 1649757000,
        open: 1.08677,
        close: 1.0868,
        high: 1.08682,
        low: 1.08647
      },
      {
        timestamp: 1649757300,
        open: 1.08679,
        close: 1.08742,
        high: 1.08745,
        low: 1.08667
      },
      {
        timestamp: 1649757600,
        open: 1.08742,
        close: 1.08718,
        high: 1.08744,
        low: 1.08699
      },
      {
        timestamp: 1649757900,
        open: 1.08718,
        close: 1.08732,
        high: 1.08735,
        low: 1.08691
      },
      {
        timestamp: 1649758200,
        open: 1.08731,
        close: 1.08718,
        high: 1.08731,
        low: 1.087
      },
      {
        timestamp: 1649758500,
        open: 1.08719,
        close: 1.0871,
        high: 1.08721,
        low: 1.08698
      },
      {
        timestamp: 1649758800,
        open: 1.08711,
        close: 1.08711,
        high: 1.08716,
        low: 1.08688
      },
      {
        timestamp: 1649759100,
        open: 1.08712,
        close: 1.08734,
        high: 1.08739,
        low: 1.08709
      },
      {
        timestamp: 1649759400,
        open: 1.08735,
        close: 1.08689,
        high: 1.08735,
        low: 1.08688
      },
      {
        timestamp: 1649759700,
        open: 1.08688,
        close: 1.08707,
        high: 1.08712,
        low: 1.0867
      },
      {
        timestamp: 1649760000,
        open: 1.08704,
        close: 1.0872,
        high: 1.08739,
        low: 1.08704
      },
      {
        timestamp: 1649760300,
        open: 1.08721,
        close: 1.08684,
        high: 1.08723,
        low: 1.08672
      },
      {
        timestamp: 1649760600,
        open: 1.08683,
        close: 1.08681,
        high: 1.0869,
        low: 1.08669
      },
      {
        timestamp: 1649760900,
        open: 1.08682,
        close: 1.08676,
        high: 1.08682,
        low: 1.08661
      },
      {
        timestamp: 1649761200,
        open: 1.08676,
        close: 1.08664,
        high: 1.08692,
        low: 1.08657
      },
      {
        timestamp: 1649761500,
        open: 1.08664,
        close: 1.08665,
        high: 1.08674,
        low: 1.08642
      },
      {
        timestamp: 1649761800,
        open: 1.08664,
        close: 1.08678,
        high: 1.08681,
        low: 1.08656
      },
      {
        timestamp: 1649762100,
        open: 1.08678,
        close: 1.08623,
        high: 1.08679,
        low: 1.08619
      },
      {
        timestamp: 1649762400,
        open: 1.08623,
        close: 1.08633,
        high: 1.08648,
        low: 1.08605
      },
      {
        timestamp: 1649762700,
        open: 1.08633,
        close: 1.08618,
        high: 1.08639,
        low: 1.08588
      },
      {
        timestamp: 1649763000,
        open: 1.0862,
        close: 1.08649,
        high: 1.0866,
        low: 1.08615
      },
      {
        timestamp: 1649763300,
        open: 1.08649,
        close: 1.08606,
        high: 1.08651,
        low: 1.08552
      },
      {
        timestamp: 1649763600,
        open: 1.08606,
        close: 1.08559,
        high: 1.08607,
        low: 1.08553
      },
      {
        timestamp: 1649763900,
        open: 1.0856,
        close: 1.08586,
        high: 1.08596,
        low: 1.08554
      },
      {
        timestamp: 1649764200,
        open: 1.08586,
        close: 1.08611,
        high: 1.08621,
        low: 1.08555
      },
      {
        timestamp: 1649764500,
        open: 1.0861,
        close: 1.0863,
        high: 1.08639,
        low: 1.08601
      },
      {
        timestamp: 1649764800,
        open: 1.0863,
        close: 1.08609,
        high: 1.08635,
        low: 1.08584
      },
      {
        timestamp: 1649765100,
        open: 1.08609,
        close: 1.08631,
        high: 1.08655,
        low: 1.08603
      },
      {
        timestamp: 1649765400,
        open: 1.0863,
        close: 1.08611,
        high: 1.08646,
        low: 1.08609
      },
      {
        timestamp: 1649765700,
        open: 1.08611,
        close: 1.08571,
        high: 1.08614,
        low: 1.08564
      },
      {
        timestamp: 1649766000,
        open: 1.08572,
        close: 1.08567,
        high: 1.086,
        low: 1.08551
      },
      {
        timestamp: 1649766300,
        open: 1.08566,
        close: 1.08546,
        high: 1.08568,
        low: 1.08518
      },
      {
        timestamp: 1649766600,
        open: 1.08546,
        close: 1.08702,
        high: 1.08792,
        low: 1.08544
      },
      {
        timestamp: 1649766900,
        open: 1.087,
        close: 1.08721,
        high: 1.08737,
        low: 1.08642
      },
      {
        timestamp: 1649767200,
        open: 1.08718,
        close: 1.08895,
        high: 1.08928,
        low: 1.08718
      },
      {
        timestamp: 1649767500,
        open: 1.08894,
        close: 1.08957,
        high: 1.08989,
        low: 1.08888
      },
      {
        timestamp: 1649767800,
        open: 1.0896,
        close: 1.08961,
        high: 1.08977,
        low: 1.08888
      },
      {
        timestamp: 1649768100,
        open: 1.08961,
        close: 1.08965,
        high: 1.09044,
        low: 1.08914
      },
      {
        timestamp: 1649768400,
        open: 1.08964,
        close: 1.089,
        high: 1.08964,
        low: 1.08869
      },
      {
        timestamp: 1649768700,
        open: 1.08897,
        close: 1.08945,
        high: 1.0904,
        low: 1.08897
      },
      {
        timestamp: 1649769000,
        open: 1.08944,
        close: 1.08833,
        high: 1.08952,
        low: 1.08813
      },
      {
        timestamp: 1649769300,
        open: 1.08833,
        close: 1.08744,
        high: 1.08843,
        low: 1.08734
      },
      {
        timestamp: 1649769600,
        open: 1.08744,
        close: 1.088,
        high: 1.08803,
        low: 1.0873
      },
      {
        timestamp: 1649769900,
        open: 1.08801,
        close: 1.08779,
        high: 1.08845,
        low: 1.08756
      },
      {
        timestamp: 1649770200,
        open: 1.08778,
        close: 1.08742,
        high: 1.08822,
        low: 1.08729
      },
      {
        timestamp: 1649770500,
        open: 1.08742,
        close: 1.08701,
        high: 1.08763,
        low: 1.08696
      },
      {
        timestamp: 1649770800,
        open: 1.08703,
        close: 1.08744,
        high: 1.0876,
        low: 1.08696
      },
      {
        timestamp: 1649771100,
        open: 1.08746,
        close: 1.08754,
        high: 1.08796,
        low: 1.08726
      },
      {
        timestamp: 1649771400,
        open: 1.08755,
        close: 1.08763,
        high: 1.0879,
        low: 1.0874
      },
      {
        timestamp: 1649771700,
        open: 1.08762,
        close: 1.08709,
        high: 1.08762,
        low: 1.08697
      },
      {
        timestamp: 1649772000,
        open: 1.08708,
        close: 1.08782,
        high: 1.08791,
        low: 1.08707
      },
      {
        timestamp: 1649772300,
        open: 1.08783,
        close: 1.08846,
        high: 1.08854,
        low: 1.08778
      },
      {
        timestamp: 1649772600,
        open: 1.08846,
        close: 1.08848,
        high: 1.08865,
        low: 1.08836
      },
      {
        timestamp: 1649772900,
        open: 1.08848,
        close: 1.08802,
        high: 1.08848,
        low: 1.08797
      },
      {
        timestamp: 1649773200,
        open: 1.08802,
        close: 1.08777,
        high: 1.08836,
        low: 1.08774
      },
      {
        timestamp: 1649773500,
        open: 1.08777,
        close: 1.08807,
        high: 1.08808,
        low: 1.08765
      },
      {
        timestamp: 1649773800,
        open: 1.08805,
        close: 1.08848,
        high: 1.08854,
        low: 1.08783
      },
      {
        timestamp: 1649774100,
        open: 1.08849,
        close: 1.08833,
        high: 1.08864,
        low: 1.08826
      },
      {
        timestamp: 1649774400,
        open: 1.08833,
        close: 1.08885,
        high: 1.08889,
        low: 1.08833
      },
      {
        timestamp: 1649774700,
        open: 1.08885,
        close: 1.08838,
        high: 1.08891,
        low: 1.08811
      },
      {
        timestamp: 1649775000,
        open: 1.08839,
        close: 1.08826,
        high: 1.08859,
        low: 1.0879
      },
      {
        timestamp: 1649775300,
        open: 1.08827,
        close: 1.08734,
        high: 1.08836,
        low: 1.08728
      },
      {
        timestamp: 1649775600,
        open: 1.08734,
        close: 1.0875,
        high: 1.08751,
        low: 1.08715
      },
      {
        timestamp: 1649775900,
        open: 1.08751,
        close: 1.0871,
        high: 1.08761,
        low: 1.08707
      },
      {
        timestamp: 1649776200,
        open: 1.0871,
        close: 1.08675,
        high: 1.08713,
        low: 1.0863
      },
      {
        timestamp: 1649776500,
        open: 1.08674,
        close: 1.08671,
        high: 1.08674,
        low: 1.08608
      },
      {
        timestamp: 1649776800,
        open: 1.08672,
        close: 1.08659,
        high: 1.08696,
        low: 1.08623
      },
      {
        timestamp: 1649777100,
        open: 1.08658,
        close: 1.08625,
        high: 1.08678,
        low: 1.08613
      },
      {
        timestamp: 1649777400,
        open: 1.08625,
        close: 1.0856,
        high: 1.08636,
        low: 1.08541
      },
      {
        timestamp: 1649777700,
        open: 1.08559,
        close: 1.08584,
        high: 1.08598,
        low: 1.08549
      },
      {
        timestamp: 1649778000,
        open: 1.08583,
        close: 1.08571,
        high: 1.08597,
        low: 1.08569
      },
      {
        timestamp: 1649778300,
        open: 1.0857,
        close: 1.0853,
        high: 1.08573,
        low: 1.0852
      },
      {
        timestamp: 1649778600,
        open: 1.08531,
        close: 1.08536,
        high: 1.08558,
        low: 1.08529
      },
      {
        timestamp: 1649778900,
        open: 1.08535,
        close: 1.08535,
        high: 1.08555,
        low: 1.08532
      },
      {
        timestamp: 1649779200,
        open: 1.08534,
        close: 1.08544,
        high: 1.08554,
        low: 1.08518
      },
      {
        timestamp: 1649779500,
        open: 1.08544,
        close: 1.08575,
        high: 1.08578,
        low: 1.08539
      },
      {
        timestamp: 1649779800,
        open: 1.08575,
        close: 1.08559,
        high: 1.08583,
        low: 1.0854
      },
      {
        timestamp: 1649780100,
        open: 1.08558,
        close: 1.08634,
        high: 1.08648,
        low: 1.08545
      },
      {
        timestamp: 1649780400,
        open: 1.08633,
        close: 1.08622,
        high: 1.08657,
        low: 1.08593
      },
      {
        timestamp: 1649780700,
        open: 1.08623,
        close: 1.08548,
        high: 1.08636,
        low: 1.08547
      },
      {
        timestamp: 1649781000,
        open: 1.08548,
        close: 1.08439,
        high: 1.08551,
        low: 1.08434
      },
      {
        timestamp: 1649781300,
        open: 1.08439,
        close: 1.08438,
        high: 1.08453,
        low: 1.08394
      },
      {
        timestamp: 1649781600,
        open: 1.08439,
        close: 1.08353,
        high: 1.08444,
        low: 1.08328
      },
      {
        timestamp: 1649781900,
        open: 1.08353,
        close: 1.08384,
        high: 1.08397,
        low: 1.08346
      },
      {
        timestamp: 1649782200,
        open: 1.08383,
        close: 1.08386,
        high: 1.08412,
        low: 1.08364
      },
      {
        timestamp: 1649782500,
        open: 1.08387,
        close: 1.08419,
        high: 1.08437,
        low: 1.08379
      },
      {
        timestamp: 1649782800,
        open: 1.08418,
        close: 1.08438,
        high: 1.0845,
        low: 1.08403
      },
      {
        timestamp: 1649783100,
        open: 1.08439,
        close: 1.08419,
        high: 1.08455,
        low: 1.08404
      },
      {
        timestamp: 1649783400,
        open: 1.0842,
        close: 1.08386,
        high: 1.08424,
        low: 1.08374
      },
      {
        timestamp: 1649783700,
        open: 1.08386,
        close: 1.08382,
        high: 1.08397,
        low: 1.08371
      },
      {
        timestamp: 1649784000,
        open: 1.08383,
        close: 1.08355,
        high: 1.08384,
        low: 1.08337
      },
      {
        timestamp: 1649784300,
        open: 1.08356,
        close: 1.08376,
        high: 1.08376,
        low: 1.0834
      },
      {
        timestamp: 1649784600,
        open: 1.08376,
        close: 1.08368,
        high: 1.08376,
        low: 1.08345
      },
      {
        timestamp: 1649784900,
        open: 1.08367,
        close: 1.08297,
        high: 1.08369,
        low: 1.08285
      },
      {
        timestamp: 1649785200,
        open: 1.08296,
        close: 1.08344,
        high: 1.08356,
        low: 1.08287
      },
      {
        timestamp: 1649785500,
        open: 1.08344,
        close: 1.08334,
        high: 1.08363,
        low: 1.08323
      },
      {
        timestamp: 1649785800,
        open: 1.08335,
        close: 1.08337,
        high: 1.08353,
        low: 1.08321
      },
      {
        timestamp: 1649786100,
        open: 1.08336,
        close: 1.08292,
        high: 1.08339,
        low: 1.08283
      },
      {
        timestamp: 1649786400,
        open: 1.08293,
        close: 1.08296,
        high: 1.08304,
        low: 1.08279
      },
      {
        timestamp: 1649786700,
        open: 1.08297,
        close: 1.08271,
        high: 1.08316,
        low: 1.08267
      },
      {
        timestamp: 1649787000,
        open: 1.08272,
        close: 1.08251,
        high: 1.08272,
        low: 1.08219
      },
      {
        timestamp: 1649787300,
        open: 1.0825,
        close: 1.0824,
        high: 1.08258,
        low: 1.08233
      },
      {
        timestamp: 1649787600,
        open: 1.0824,
        close: 1.08257,
        high: 1.08264,
        low: 1.08234
      },
      {
        timestamp: 1649787900,
        open: 1.08257,
        close: 1.08269,
        high: 1.08279,
        low: 1.08256
      },
      {
        timestamp: 1649788200,
        open: 1.08268,
        close: 1.08287,
        high: 1.08289,
        low: 1.08263
      },
      {
        timestamp: 1649788500,
        open: 1.08286,
        close: 1.08273,
        high: 1.08287,
        low: 1.08268
      },
      {
        timestamp: 1649788800,
        open: 1.08272,
        close: 1.08274,
        high: 1.0828,
        low: 1.08262
      },
      {
        timestamp: 1649789100,
        open: 1.08272,
        close: 1.08273,
        high: 1.08294,
        low: 1.08268
      },
      {
        timestamp: 1649789400,
        open: 1.08274,
        close: 1.08289,
        high: 1.08292,
        low: 1.08262
      },
      {
        timestamp: 1649789700,
        open: 1.08288,
        close: 1.08285,
        high: 1.08327,
        low: 1.08282
      },
      {
        timestamp: 1649790000,
        open: 1.08283,
        close: 1.08315,
        high: 1.0833,
        low: 1.08283
      },
      {
        timestamp: 1649790300,
        open: 1.08316,
        close: 1.08318,
        high: 1.08331,
        low: 1.08313
      },
      {
        timestamp: 1649790600,
        open: 1.08319,
        close: 1.0832,
        high: 1.0833,
        low: 1.08312
      },
      {
        timestamp: 1649790900,
        open: 1.0832,
        close: 1.0831,
        high: 1.08327,
        low: 1.08299
      },
      {
        timestamp: 1649791200,
        open: 1.08309,
        close: 1.08331,
        high: 1.08339,
        low: 1.08308
      },
      {
        timestamp: 1649791500,
        open: 1.08332,
        close: 1.08315,
        high: 1.08342,
        low: 1.08313
      },
      {
        timestamp: 1649791800,
        open: 1.08314,
        close: 1.08323,
        high: 1.08326,
        low: 1.08307
      },
      {
        timestamp: 1649792100,
        open: 1.08323,
        close: 1.08329,
        high: 1.0833,
        low: 1.08318
      },
      {
        timestamp: 1649792400,
        open: 1.0833,
        close: 1.08329,
        high: 1.08335,
        low: 1.08312
      },
      {
        timestamp: 1649792700,
        open: 1.08328,
        close: 1.08335,
        high: 1.08343,
        low: 1.08314
      },
      {
        timestamp: 1649793000,
        open: 1.08335,
        close: 1.08319,
        high: 1.08336,
        low: 1.08311
      },
      {
        timestamp: 1649793300,
        open: 1.08317,
        close: 1.08312,
        high: 1.08321,
        low: 1.08295
      },
      {
        timestamp: 1649793600,
        open: 1.08313,
        close: 1.08286,
        high: 1.08314,
        low: 1.08284
      },
      {
        timestamp: 1649793900,
        open: 1.08286,
        close: 1.08291,
        high: 1.08292,
        low: 1.08273
      },
      {
        timestamp: 1649794200,
        open: 1.08291,
        close: 1.08318,
        high: 1.0832,
        low: 1.08288
      },
      {
        timestamp: 1649794500,
        open: 1.08317,
        close: 1.08309,
        high: 1.08318,
        low: 1.08305
      },
      {
        timestamp: 1649794800,
        open: 1.0831,
        close: 1.08289,
        high: 1.0831,
        low: 1.08281
      },
      {
        timestamp: 1649795100,
        open: 1.08289,
        close: 1.0829,
        high: 1.08293,
        low: 1.08279
      },
      {
        timestamp: 1649795400,
        open: 1.0829,
        close: 1.08282,
        high: 1.08302,
        low: 1.08279
      },
      {
        timestamp: 1649795700,
        open: 1.0828,
        close: 1.08301,
        high: 1.08301,
        low: 1.08279
      },
      {
        timestamp: 1649796000,
        open: 1.08302,
        close: 1.08285,
        high: 1.08302,
        low: 1.08282
      },
      {
        timestamp: 1649796300,
        open: 1.08285,
        close: 1.08278,
        high: 1.08286,
        low: 1.08277
      },
      {
        timestamp: 1649796600,
        open: 1.08279,
        close: 1.08279,
        high: 1.08284,
        low: 1.08275
      },
      {
        timestamp: 1649796900,
        open: 1.0828,
        close: 1.08325,
        high: 1.08325,
        low: 1.08266
      },
      {
        timestamp: 1649797200,
        open: 1.08345,
        close: 1.08279,
        high: 1.08357,
        low: 1.08279
      },
      {
        timestamp: 1649797500,
        open: 1.0828,
        close: 1.0828,
        high: 1.0828,
        low: 1.08279
      },
      {
        timestamp: 1649797800,
        open: 1.08279,
        close: 1.08279,
        high: 1.0828,
        low: 1.08279
      },
      {
        timestamp: 1649798100,
        open: 1.08281,
        close: 1.08279,
        high: 1.0834,
        low: 1.08279
      },
      {
        timestamp: 1649798400,
        open: 1.08279,
        close: 1.08288,
        high: 1.08288,
        low: 1.08279
      },
      {
        timestamp: 1649798700,
        open: 1.08286,
        close: 1.08284,
        high: 1.08288,
        low: 1.08283
      },
      {
        timestamp: 1649799000,
        open: 1.08287,
        close: 1.08281,
        high: 1.0829,
        low: 1.08279
      },
      {
        timestamp: 1649799300,
        open: 1.08281,
        close: 1.08281,
        high: 1.08286,
        low: 1.0828
      },
      {
        timestamp: 1649799600,
        open: 1.08281,
        close: 1.08285,
        high: 1.08286,
        low: 1.0828
      },
      {
        timestamp: 1649799900,
        open: 1.08286,
        close: 1.08298,
        high: 1.08324,
        low: 1.08284
      },
      {
        timestamp: 1649800200,
        open: 1.08299,
        close: 1.08298,
        high: 1.08324,
        low: 1.08298
      },
      {
        timestamp: 1649800500,
        open: 1.08297,
        close: 1.08296,
        high: 1.08301,
        low: 1.0829
      },
      {
        timestamp: 1649800800,
        open: 1.083,
        close: 1.08296,
        high: 1.08303,
        low: 1.08281
      },
      {
        timestamp: 1649801100,
        open: 1.08296,
        close: 1.0831,
        high: 1.08311,
        low: 1.08282
      },
      {
        timestamp: 1649801400,
        open: 1.0831,
        close: 1.08289,
        high: 1.08324,
        low: 1.08288
      },
      {
        timestamp: 1649801700,
        open: 1.0829,
        close: 1.08274,
        high: 1.08297,
        low: 1.08274
      },
      {
        timestamp: 1649802000,
        open: 1.08273,
        close: 1.08276,
        high: 1.08278,
        low: 1.08268
      },
      {
        timestamp: 1649802300,
        open: 1.08277,
        close: 1.08286,
        high: 1.08293,
        low: 1.08276
      },
      {
        timestamp: 1649802600,
        open: 1.08285,
        close: 1.08296,
        high: 1.08297,
        low: 1.08285
      },
      {
        timestamp: 1649802900,
        open: 1.08296,
        close: 1.08291,
        high: 1.08296,
        low: 1.08277
      },
      {
        timestamp: 1649803200,
        open: 1.0829,
        close: 1.08282,
        high: 1.08295,
        low: 1.08282
      },
      {
        timestamp: 1649803500,
        open: 1.08282,
        close: 1.08292,
        high: 1.08293,
        low: 1.08281
      },
      {
        timestamp: 1649803800,
        open: 1.08293,
        close: 1.08283,
        high: 1.08295,
        low: 1.08283
      },
      {
        timestamp: 1649804100,
        open: 1.08283,
        close: 1.08292,
        high: 1.08295,
        low: 1.08281
      },
      {
        timestamp: 1649804400,
        open: 1.08291,
        close: 1.08282,
        high: 1.08297,
        low: 1.08275
      },
      {
        timestamp: 1649804700,
        open: 1.0828,
        close: 1.08272,
        high: 1.0828,
        low: 1.08265
      },
      {
        timestamp: 1649805000,
        open: 1.08271,
        close: 1.08265,
        high: 1.08276,
        low: 1.08265
      },
      {
        timestamp: 1649805300,
        open: 1.08266,
        close: 1.0825,
        high: 1.08266,
        low: 1.08248
      },
      {
        timestamp: 1649805600,
        open: 1.0825,
        close: 1.08247,
        high: 1.08254,
        low: 1.08247
      },
      {
        timestamp: 1649805900,
        open: 1.08248,
        close: 1.08255,
        high: 1.08259,
        low: 1.08245
      },
      {
        timestamp: 1649806200,
        open: 1.08255,
        close: 1.08245,
        high: 1.0826,
        low: 1.0824
      },
      {
        timestamp: 1649806500,
        open: 1.08244,
        close: 1.08248,
        high: 1.08249,
        low: 1.08242
      },
      {
        timestamp: 1649806800,
        open: 1.08248,
        close: 1.08255,
        high: 1.08261,
        low: 1.08247
      },
      {
        timestamp: 1649807100,
        open: 1.08255,
        close: 1.08274,
        high: 1.08274,
        low: 1.08255
      },
      {
        timestamp: 1649807400,
        open: 1.08275,
        close: 1.08269,
        high: 1.08286,
        low: 1.08267
      },
      {
        timestamp: 1649807700,
        open: 1.08271,
        close: 1.08266,
        high: 1.08274,
        low: 1.08263
      },
      {
        timestamp: 1649808000,
        open: 1.08266,
        close: 1.08295,
        high: 1.083,
        low: 1.08257
      },
      {
        timestamp: 1649808300,
        open: 1.08295,
        close: 1.08278,
        high: 1.08295,
        low: 1.0827
      },
      {
        timestamp: 1649808600,
        open: 1.08279,
        close: 1.0824,
        high: 1.0829,
        low: 1.08238
      },
      {
        timestamp: 1649808900,
        open: 1.0824,
        close: 1.08254,
        high: 1.08267,
        low: 1.0824
      },
      {
        timestamp: 1649809200,
        open: 1.08255,
        close: 1.08218,
        high: 1.08256,
        low: 1.08218
      },
      {
        timestamp: 1649809500,
        open: 1.08218,
        close: 1.08219,
        high: 1.08227,
        low: 1.08173
      },
      {
        timestamp: 1649809800,
        open: 1.0822,
        close: 1.08202,
        high: 1.08222,
        low: 1.082
      },
      {
        timestamp: 1649810100,
        open: 1.08202,
        close: 1.08214,
        high: 1.08219,
        low: 1.08201
      },
      {
        timestamp: 1649810400,
        open: 1.08213,
        close: 1.08141,
        high: 1.08213,
        low: 1.08135
      },
      {
        timestamp: 1649810700,
        open: 1.08139,
        close: 1.08167,
        high: 1.08182,
        low: 1.08137
      },
      {
        timestamp: 1649811000,
        open: 1.08167,
        close: 1.08155,
        high: 1.08179,
        low: 1.0812
      },
      {
        timestamp: 1649811300,
        open: 1.08156,
        close: 1.08166,
        high: 1.08168,
        low: 1.0815
      },
      {
        timestamp: 1649811600,
        open: 1.08166,
        close: 1.0818,
        high: 1.08193,
        low: 1.08151
      },
      {
        timestamp: 1649811900,
        open: 1.0818,
        close: 1.08202,
        high: 1.08207,
        low: 1.08176
      },
      {
        timestamp: 1649812200,
        open: 1.08203,
        close: 1.08212,
        high: 1.08217,
        low: 1.0819
      },
      {
        timestamp: 1649812500,
        open: 1.08212,
        close: 1.08243,
        high: 1.08254,
        low: 1.08211
      },
      {
        timestamp: 1649812800,
        open: 1.08244,
        close: 1.0824,
        high: 1.0825,
        low: 1.08235
      },
      {
        timestamp: 1649813100,
        open: 1.0824,
        close: 1.08215,
        high: 1.08245,
        low: 1.08215
      },
      {
        timestamp: 1649813400,
        open: 1.08216,
        close: 1.08205,
        high: 1.08225,
        low: 1.08191
      },
      {
        timestamp: 1649813700,
        open: 1.08205,
        close: 1.08228,
        high: 1.0823,
        low: 1.08203
      },
      {
        timestamp: 1649814000,
        open: 1.08229,
        close: 1.08271,
        high: 1.08275,
        low: 1.08223
      },
      {
        timestamp: 1649814300,
        open: 1.08271,
        close: 1.08273,
        high: 1.08277,
        low: 1.0826
      },
      {
        timestamp: 1649814600,
        open: 1.08274,
        close: 1.08292,
        high: 1.08296,
        low: 1.08273
      },
      {
        timestamp: 1649814900,
        open: 1.08293,
        close: 1.08307,
        high: 1.08315,
        low: 1.0829
      },
      {
        timestamp: 1649815200,
        open: 1.08307,
        close: 1.08339,
        high: 1.08367,
        low: 1.08307
      },
      {
        timestamp: 1649815500,
        open: 1.08339,
        close: 1.08309,
        high: 1.08339,
        low: 1.08306
      },
      {
        timestamp: 1649815800,
        open: 1.08309,
        close: 1.08311,
        high: 1.08324,
        low: 1.08299
      },
      {
        timestamp: 1649816100,
        open: 1.0831,
        close: 1.083,
        high: 1.08311,
        low: 1.08289
      },
      {
        timestamp: 1649816400,
        open: 1.08299,
        close: 1.08333,
        high: 1.08333,
        low: 1.08289
      },
      {
        timestamp: 1649816700,
        open: 1.08333,
        close: 1.08329,
        high: 1.08338,
        low: 1.08319
      },
      {
        timestamp: 1649817000,
        open: 1.0833,
        close: 1.0835,
        high: 1.0835,
        low: 1.08323
      },
      {
        timestamp: 1649817300,
        open: 1.08349,
        close: 1.08371,
        high: 1.08371,
        low: 1.08349
      },
      {
        timestamp: 1649817600,
        open: 1.0837,
        close: 1.08357,
        high: 1.08381,
        low: 1.08357
      },
      {
        timestamp: 1649817900,
        open: 1.08358,
        close: 1.08367,
        high: 1.08376,
        low: 1.08353
      },
      {
        timestamp: 1649818200,
        open: 1.08367,
        close: 1.08369,
        high: 1.08376,
        low: 1.08364
      },
      {
        timestamp: 1649818500,
        open: 1.0837,
        close: 1.08358,
        high: 1.08394,
        low: 1.08358
      },
      {
        timestamp: 1649818800,
        open: 1.08358,
        close: 1.08394,
        high: 1.08395,
        low: 1.08335
      },
      {
        timestamp: 1649819100,
        open: 1.08392,
        close: 1.08378,
        high: 1.08394,
        low: 1.0836
      },
      {
        timestamp: 1649819400,
        open: 1.08379,
        close: 1.0839,
        high: 1.08395,
        low: 1.08378
      },
      {
        timestamp: 1649819700,
        open: 1.08391,
        close: 1.08391,
        high: 1.08395,
        low: 1.08378
      },
      {
        timestamp: 1649820000,
        open: 1.08391,
        close: 1.08401,
        high: 1.0841,
        low: 1.0839
      },
      {
        timestamp: 1649820300,
        open: 1.084,
        close: 1.08406,
        high: 1.08417,
        low: 1.08396
      },
      {
        timestamp: 1649820600,
        open: 1.08406,
        close: 1.0835,
        high: 1.08406,
        low: 1.08345
      },
      {
        timestamp: 1649820900,
        open: 1.0835,
        close: 1.08355,
        high: 1.08361,
        low: 1.08347
      },
      {
        timestamp: 1649821200,
        open: 1.08355,
        close: 1.0836,
        high: 1.08364,
        low: 1.08349
      },
      {
        timestamp: 1649821500,
        open: 1.0836,
        close: 1.08371,
        high: 1.08375,
        low: 1.0836
      },
      {
        timestamp: 1649821800,
        open: 1.0837,
        close: 1.08381,
        high: 1.08385,
        low: 1.08369
      },
      {
        timestamp: 1649822100,
        open: 1.08382,
        close: 1.08381,
        high: 1.08396,
        low: 1.08379
      },
      {
        timestamp: 1649822400,
        open: 1.0838,
        close: 1.08355,
        high: 1.08386,
        low: 1.08353
      },
      {
        timestamp: 1649822700,
        open: 1.08355,
        close: 1.08391,
        high: 1.08396,
        low: 1.08347
      },
      {
        timestamp: 1649823000,
        open: 1.08391,
        close: 1.0838,
        high: 1.08392,
        low: 1.08369
      },
      {
        timestamp: 1649823300,
        open: 1.0838,
        close: 1.08368,
        high: 1.08381,
        low: 1.08354
      },
      {
        timestamp: 1649823600,
        open: 1.08367,
        close: 1.08339,
        high: 1.08367,
        low: 1.08337
      },
      {
        timestamp: 1649823900,
        open: 1.08339,
        close: 1.08339,
        high: 1.08346,
        low: 1.08323
      },
      {
        timestamp: 1649824200,
        open: 1.08338,
        close: 1.08329,
        high: 1.0835,
        low: 1.08328
      },
      {
        timestamp: 1649824500,
        open: 1.08329,
        close: 1.08325,
        high: 1.0833,
        low: 1.08316
      },
      {
        timestamp: 1649824800,
        open: 1.08325,
        close: 1.08344,
        high: 1.08344,
        low: 1.08315
      },
      {
        timestamp: 1649825100,
        open: 1.08345,
        close: 1.0834,
        high: 1.08346,
        low: 1.08332
      },
      {
        timestamp: 1649825400,
        open: 1.08341,
        close: 1.08307,
        high: 1.08351,
        low: 1.08305
      },
      {
        timestamp: 1649825700,
        open: 1.08307,
        close: 1.08342,
        high: 1.08345,
        low: 1.08302
      },
      {
        timestamp: 1649826000,
        open: 1.08342,
        close: 1.08346,
        high: 1.08348,
        low: 1.08327
      },
      {
        timestamp: 1649826300,
        open: 1.08346,
        close: 1.08319,
        high: 1.08346,
        low: 1.08302
      },
      {
        timestamp: 1649826600,
        open: 1.08319,
        close: 1.08321,
        high: 1.08322,
        low: 1.0831
      },
      {
        timestamp: 1649826900,
        open: 1.0832,
        close: 1.08321,
        high: 1.08327,
        low: 1.08308
      },
      {
        timestamp: 1649827200,
        open: 1.08321,
        close: 1.08324,
        high: 1.08336,
        low: 1.08311
      },
      {
        timestamp: 1649827500,
        open: 1.08324,
        close: 1.08348,
        high: 1.0835,
        low: 1.08324
      },
      {
        timestamp: 1649827800,
        open: 1.08348,
        close: 1.08338,
        high: 1.08355,
        low: 1.08316
      },
      {
        timestamp: 1649828100,
        open: 1.08338,
        close: 1.08366,
        high: 1.08371,
        low: 1.08328
      },
      {
        timestamp: 1649828400,
        open: 1.08366,
        close: 1.08384,
        high: 1.08398,
        low: 1.0836
      },
      {
        timestamp: 1649828700,
        open: 1.08385,
        close: 1.08403,
        high: 1.08404,
        low: 1.08382
      },
      {
        timestamp: 1649829000,
        open: 1.08402,
        close: 1.08412,
        high: 1.08412,
        low: 1.08389
      },
      {
        timestamp: 1649829300,
        open: 1.08414,
        close: 1.08443,
        high: 1.08448,
        low: 1.08409
      },
      {
        timestamp: 1649829600,
        open: 1.08444,
        close: 1.08428,
        high: 1.08456,
        low: 1.0842
      },
      {
        timestamp: 1649829900,
        open: 1.08429,
        close: 1.08393,
        high: 1.08429,
        low: 1.08376
      },
      {
        timestamp: 1649830200,
        open: 1.08394,
        close: 1.08425,
        high: 1.08436,
        low: 1.08394
      },
      {
        timestamp: 1649830500,
        open: 1.08424,
        close: 1.08395,
        high: 1.08425,
        low: 1.08387
      },
      {
        timestamp: 1649830800,
        open: 1.08394,
        close: 1.08355,
        high: 1.08404,
        low: 1.08331
      },
      {
        timestamp: 1649831100,
        open: 1.08357,
        close: 1.08281,
        high: 1.08365,
        low: 1.08265
      },
      {
        timestamp: 1649831400,
        open: 1.08282,
        close: 1.08283,
        high: 1.08294,
        low: 1.08252
      },
      {
        timestamp: 1649831700,
        open: 1.08282,
        close: 1.08273,
        high: 1.08297,
        low: 1.08263
      },
      {
        timestamp: 1649832000,
        open: 1.08274,
        close: 1.08311,
        high: 1.08327,
        low: 1.08253
      },
      {
        timestamp: 1649832300,
        open: 1.08311,
        close: 1.08311,
        high: 1.08326,
        low: 1.08281
      },
      {
        timestamp: 1649832600,
        open: 1.08311,
        close: 1.08239,
        high: 1.08315,
        low: 1.08222
      },
      {
        timestamp: 1649832900,
        open: 1.08239,
        close: 1.08228,
        high: 1.08302,
        low: 1.08221
      },
      {
        timestamp: 1649833200,
        open: 1.08228,
        close: 1.08255,
        high: 1.08281,
        low: 1.08228
      },
      {
        timestamp: 1649833500,
        open: 1.08257,
        close: 1.08169,
        high: 1.08265,
        low: 1.08158
      },
      {
        timestamp: 1649833800,
        open: 1.08169,
        close: 1.08207,
        high: 1.08208,
        low: 1.08167
      },
      {
        timestamp: 1649834100,
        open: 1.08208,
        close: 1.08209,
        high: 1.08235,
        low: 1.08199
      },
      {
        timestamp: 1649834400,
        open: 1.08208,
        close: 1.08218,
        high: 1.08243,
        low: 1.08196
      },
      {
        timestamp: 1649834700,
        open: 1.08218,
        close: 1.08282,
        high: 1.08299,
        low: 1.08203
      },
      {
        timestamp: 1649835000,
        open: 1.08281,
        close: 1.08319,
        high: 1.08322,
        low: 1.08259
      },
      {
        timestamp: 1649835300,
        open: 1.08319,
        close: 1.08337,
        high: 1.08337,
        low: 1.0831
      },
      {
        timestamp: 1649835600,
        open: 1.08337,
        close: 1.0836,
        high: 1.08374,
        low: 1.08333
      },
      {
        timestamp: 1649835900,
        open: 1.08359,
        close: 1.08372,
        high: 1.08383,
        low: 1.08355
      },
      {
        timestamp: 1649836200,
        open: 1.08371,
        close: 1.08377,
        high: 1.08408,
        low: 1.0837
      },
      {
        timestamp: 1649836500,
        open: 1.08378,
        close: 1.08389,
        high: 1.08403,
        low: 1.0835
      },
      {
        timestamp: 1649836800,
        open: 1.08388,
        close: 1.08378,
        high: 1.08391,
        low: 1.08352
      },
      {
        timestamp: 1649837100,
        open: 1.08376,
        close: 1.08384,
        high: 1.08396,
        low: 1.08362
      },
      {
        timestamp: 1649837400,
        open: 1.08384,
        close: 1.0834,
        high: 1.08386,
        low: 1.08338
      },
      {
        timestamp: 1649837700,
        open: 1.08341,
        close: 1.08305,
        high: 1.08346,
        low: 1.08277
      },
      {
        timestamp: 1649838000,
        open: 1.08304,
        close: 1.0836,
        high: 1.0838,
        low: 1.08289
      },
      {
        timestamp: 1649838300,
        open: 1.08361,
        close: 1.08341,
        high: 1.08368,
        low: 1.08335
      },
      {
        timestamp: 1649838600,
        open: 1.08341,
        close: 1.08336,
        high: 1.08351,
        low: 1.08314
      },
      {
        timestamp: 1649838900,
        open: 1.08336,
        close: 1.08322,
        high: 1.08343,
        low: 1.08303
      },
      {
        timestamp: 1649839200,
        open: 1.0832,
        close: 1.0833,
        high: 1.08378,
        low: 1.08319
      },
      {
        timestamp: 1649839500,
        open: 1.08329,
        close: 1.08358,
        high: 1.08379,
        low: 1.08326
      },
      {
        timestamp: 1649839800,
        open: 1.08358,
        close: 1.08353,
        high: 1.08364,
        low: 1.08317
      },
      {
        timestamp: 1649840100,
        open: 1.08352,
        close: 1.08377,
        high: 1.08389,
        low: 1.08347
      },
      {
        timestamp: 1649840400,
        open: 1.08376,
        close: 1.08355,
        high: 1.08376,
        low: 1.08327
      },
      {
        timestamp: 1649840700,
        open: 1.08355,
        close: 1.08355,
        high: 1.08373,
        low: 1.08338
      },
      {
        timestamp: 1649841000,
        open: 1.08354,
        close: 1.08338,
        high: 1.08362,
        low: 1.08338
      },
      {
        timestamp: 1649841300,
        open: 1.08337,
        close: 1.08295,
        high: 1.08337,
        low: 1.08281
      },
      {
        timestamp: 1649841600,
        open: 1.08297,
        close: 1.0827,
        high: 1.08309,
        low: 1.08268
      },
      {
        timestamp: 1649841900,
        open: 1.08271,
        close: 1.08285,
        high: 1.08292,
        low: 1.08258
      },
      {
        timestamp: 1649842200,
        open: 1.08286,
        close: 1.083,
        high: 1.08314,
        low: 1.0827
      },
      {
        timestamp: 1649842500,
        open: 1.08299,
        close: 1.08296,
        high: 1.08314,
        low: 1.08274
      },
      {
        timestamp: 1649842800,
        open: 1.08297,
        close: 1.08287,
        high: 1.08307,
        low: 1.08277
      },
      {
        timestamp: 1649843100,
        open: 1.08286,
        close: 1.0831,
        high: 1.08311,
        low: 1.08271
      },
      {
        timestamp: 1649843400,
        open: 1.0831,
        close: 1.08321,
        high: 1.08324,
        low: 1.0829
      },
      {
        timestamp: 1649843700,
        open: 1.08321,
        close: 1.08355,
        high: 1.08359,
        low: 1.08318
      },
      {
        timestamp: 1649844000,
        open: 1.08356,
        close: 1.08331,
        high: 1.08362,
        low: 1.08331
      },
      {
        timestamp: 1649844300,
        open: 1.08332,
        close: 1.08274,
        high: 1.08337,
        low: 1.08274
      },
      {
        timestamp: 1649844600,
        open: 1.08274,
        close: 1.08322,
        high: 1.08322,
        low: 1.08274
      },
      {
        timestamp: 1649844900,
        open: 1.08322,
        close: 1.08281,
        high: 1.08323,
        low: 1.08277
      },
      {
        timestamp: 1649845200,
        open: 1.08281,
        close: 1.08284,
        high: 1.08304,
        low: 1.08268
      },
      {
        timestamp: 1649845500,
        open: 1.08285,
        close: 1.08291,
        high: 1.08304,
        low: 1.08268
      },
      {
        timestamp: 1649845800,
        open: 1.08291,
        close: 1.08265,
        high: 1.083,
        low: 1.08264
      },
      {
        timestamp: 1649846100,
        open: 1.08263,
        close: 1.08268,
        high: 1.0828,
        low: 1.08258
      },
      {
        timestamp: 1649846400,
        open: 1.08269,
        close: 1.08269,
        high: 1.08279,
        low: 1.08267
      },
      {
        timestamp: 1649846700,
        open: 1.08269,
        close: 1.08266,
        high: 1.08279,
        low: 1.08238
      },
      {
        timestamp: 1649847000,
        open: 1.08265,
        close: 1.0827,
        high: 1.08281,
        low: 1.08247
      },
      {
        timestamp: 1649847300,
        open: 1.08269,
        close: 1.08274,
        high: 1.08283,
        low: 1.0826
      },
      {
        timestamp: 1649847600,
        open: 1.08274,
        close: 1.08307,
        high: 1.08329,
        low: 1.08266
      },
      {
        timestamp: 1649847900,
        open: 1.08306,
        close: 1.08324,
        high: 1.08343,
        low: 1.08303
      },
      {
        timestamp: 1649848200,
        open: 1.08325,
        close: 1.08375,
        high: 1.08391,
        low: 1.08323
      },
      {
        timestamp: 1649848500,
        open: 1.08375,
        close: 1.08381,
        high: 1.08395,
        low: 1.08368
      },
      {
        timestamp: 1649848800,
        open: 1.08381,
        close: 1.08399,
        high: 1.08414,
        low: 1.08377
      },
      {
        timestamp: 1649849100,
        open: 1.08399,
        close: 1.08425,
        high: 1.08434,
        low: 1.08378
      },
      {
        timestamp: 1649849400,
        open: 1.08426,
        close: 1.08445,
        high: 1.08474,
        low: 1.08417
      },
      {
        timestamp: 1649849700,
        open: 1.08445,
        close: 1.08435,
        high: 1.08485,
        low: 1.0843
      },
      {
        timestamp: 1649850000,
        open: 1.08434,
        close: 1.08394,
        high: 1.08442,
        low: 1.0839
      },
      {
        timestamp: 1649850300,
        open: 1.08394,
        close: 1.08375,
        high: 1.08415,
        low: 1.08366
      },
      {
        timestamp: 1649850600,
        open: 1.08374,
        close: 1.08316,
        high: 1.08375,
        low: 1.08292
      },
      {
        timestamp: 1649850900,
        open: 1.08317,
        close: 1.08314,
        high: 1.08351,
        low: 1.08283
      },
      {
        timestamp: 1649851200,
        open: 1.08314,
        close: 1.08278,
        high: 1.08355,
        low: 1.08274
      },
      {
        timestamp: 1649851500,
        open: 1.08277,
        close: 1.08295,
        high: 1.08296,
        low: 1.08262
      },
      {
        timestamp: 1649851800,
        open: 1.08297,
        close: 1.0827,
        high: 1.08323,
        low: 1.08246
      },
      {
        timestamp: 1649852100,
        open: 1.0827,
        close: 1.08267,
        high: 1.08283,
        low: 1.08248
      },
      {
        timestamp: 1649852400,
        open: 1.08267,
        close: 1.08245,
        high: 1.08283,
        low: 1.08239
      },
      {
        timestamp: 1649852700,
        open: 1.08244,
        close: 1.08233,
        high: 1.08245,
        low: 1.08208
      },
      {
        timestamp: 1649853000,
        open: 1.08233,
        close: 1.08204,
        high: 1.08234,
        low: 1.08159
      },
      {
        timestamp: 1649853300,
        open: 1.08205,
        close: 1.0827,
        high: 1.08275,
        low: 1.08189
      },
      {
        timestamp: 1649853600,
        open: 1.08271,
        close: 1.08299,
        high: 1.08324,
        low: 1.08265
      },
      {
        timestamp: 1649853900,
        open: 1.08299,
        close: 1.08285,
        high: 1.08302,
        low: 1.08269
      },
      {
        timestamp: 1649854200,
        open: 1.08285,
        close: 1.08294,
        high: 1.083,
        low: 1.08259
      },
      {
        timestamp: 1649854500,
        open: 1.08295,
        close: 1.08187,
        high: 1.08297,
        low: 1.08185
      },
      {
        timestamp: 1649854800,
        open: 1.08188,
        close: 1.08243,
        high: 1.08248,
        low: 1.08181
      },
      {
        timestamp: 1649855100,
        open: 1.08241,
        close: 1.08178,
        high: 1.08258,
        low: 1.0817
      },
      {
        timestamp: 1649855400,
        open: 1.08177,
        close: 1.08178,
        high: 1.08203,
        low: 1.0816
      },
      {
        timestamp: 1649855700,
        open: 1.08178,
        close: 1.08176,
        high: 1.08199,
        low: 1.08166
      },
      {
        timestamp: 1649856000,
        open: 1.08176,
        close: 1.08138,
        high: 1.0819,
        low: 1.08135
      },
      {
        timestamp: 1649856300,
        open: 1.08138,
        close: 1.08154,
        high: 1.08178,
        low: 1.08105
      },
      {
        timestamp: 1649856600,
        open: 1.08153,
        close: 1.08174,
        high: 1.08203,
        low: 1.08096
      },
      {
        timestamp: 1649856900,
        open: 1.08173,
        close: 1.08214,
        high: 1.08227,
        low: 1.08151
      },
      {
        timestamp: 1649857200,
        open: 1.08214,
        close: 1.08251,
        high: 1.0828,
        low: 1.08203
      },
      {
        timestamp: 1649857500,
        open: 1.08251,
        close: 1.0825,
        high: 1.08256,
        low: 1.08226
      },
      {
        timestamp: 1649857800,
        open: 1.0825,
        close: 1.08243,
        high: 1.08264,
        low: 1.08218
      },
      {
        timestamp: 1649858100,
        open: 1.08245,
        close: 1.08176,
        high: 1.0825,
        low: 1.08164
      },
      {
        timestamp: 1649858400,
        open: 1.08177,
        close: 1.08213,
        high: 1.08226,
        low: 1.08152
      },
      {
        timestamp: 1649858700,
        open: 1.08215,
        close: 1.08219,
        high: 1.08245,
        low: 1.08196
      },
      {
        timestamp: 1649859000,
        open: 1.08219,
        close: 1.08319,
        high: 1.08336,
        low: 1.08218
      },
      {
        timestamp: 1649859300,
        open: 1.0832,
        close: 1.0836,
        high: 1.08381,
        low: 1.08318
      },
      {
        timestamp: 1649859600,
        open: 1.0836,
        close: 1.08348,
        high: 1.08381,
        low: 1.0832
      },
      {
        timestamp: 1649859900,
        open: 1.08349,
        close: 1.08322,
        high: 1.08349,
        low: 1.083
      },
      {
        timestamp: 1649860200,
        open: 1.08321,
        close: 1.08344,
        high: 1.08367,
        low: 1.08316
      },
      {
        timestamp: 1649860500,
        open: 1.08344,
        close: 1.08325,
        high: 1.08359,
        low: 1.08324
      },
      {
        timestamp: 1649860800,
        open: 1.08324,
        close: 1.0835,
        high: 1.08351,
        low: 1.08295
      },
      {
        timestamp: 1649861100,
        open: 1.08351,
        close: 1.08364,
        high: 1.08382,
        low: 1.08351
      },
      {
        timestamp: 1649861400,
        open: 1.08364,
        close: 1.08443,
        high: 1.08466,
        low: 1.08363
      },
      {
        timestamp: 1649861700,
        open: 1.08443,
        close: 1.08482,
        high: 1.08495,
        low: 1.08431
      },
      {
        timestamp: 1649862000,
        open: 1.08483,
        close: 1.08537,
        high: 1.08566,
        low: 1.08458
      },
      {
        timestamp: 1649862300,
        open: 1.08536,
        close: 1.08529,
        high: 1.08552,
        low: 1.08522
      },
      {
        timestamp: 1649862600,
        open: 1.08529,
        close: 1.08524,
        high: 1.08529,
        low: 1.08485
      },
      {
        timestamp: 1649862900,
        open: 1.08524,
        close: 1.08563,
        high: 1.08565,
        low: 1.08524
      },
      {
        timestamp: 1649863200,
        open: 1.08562,
        close: 1.08553,
        high: 1.08576,
        low: 1.08537
      },
      {
        timestamp: 1649863500,
        open: 1.08554,
        close: 1.08644,
        high: 1.0868,
        low: 1.0854
      },
      {
        timestamp: 1649863800,
        open: 1.08644,
        close: 1.08639,
        high: 1.08694,
        low: 1.08628
      },
      {
        timestamp: 1649864100,
        open: 1.0864,
        close: 1.08647,
        high: 1.08656,
        low: 1.08617
      },
      {
        timestamp: 1649864400,
        open: 1.08647,
        close: 1.08673,
        high: 1.08682,
        low: 1.08638
      },
      {
        timestamp: 1649864700,
        open: 1.08674,
        close: 1.08659,
        high: 1.08676,
        low: 1.08634
      },
      {
        timestamp: 1649865000,
        open: 1.08659,
        close: 1.08683,
        high: 1.0869,
        low: 1.08644
      },
      {
        timestamp: 1649865300,
        open: 1.08684,
        close: 1.08722,
        high: 1.08742,
        low: 1.08676
      },
      {
        timestamp: 1649865600,
        open: 1.08723,
        close: 1.08775,
        high: 1.08809,
        low: 1.08723
      },
      {
        timestamp: 1649865900,
        open: 1.08774,
        close: 1.08824,
        high: 1.08854,
        low: 1.08772
      },
      {
        timestamp: 1649866200,
        open: 1.08823,
        close: 1.08869,
        high: 1.08869,
        low: 1.08816
      },
      {
        timestamp: 1649866500,
        open: 1.0887,
        close: 1.08871,
        high: 1.08951,
        low: 1.08826
      },
      {
        timestamp: 1649866800,
        open: 1.0887,
        close: 1.08876,
        high: 1.08914,
        low: 1.0885
      },
      {
        timestamp: 1649867100,
        open: 1.08875,
        close: 1.08852,
        high: 1.08877,
        low: 1.0883
      },
      {
        timestamp: 1649867400,
        open: 1.08852,
        close: 1.08871,
        high: 1.08899,
        low: 1.08852
      },
      {
        timestamp: 1649867700,
        open: 1.0887,
        close: 1.08838,
        high: 1.08886,
        low: 1.08831
      },
      {
        timestamp: 1649868000,
        open: 1.08838,
        close: 1.08859,
        high: 1.08878,
        low: 1.08836
      },
      {
        timestamp: 1649868300,
        open: 1.08859,
        close: 1.08852,
        high: 1.08861,
        low: 1.08835
      },
      {
        timestamp: 1649868600,
        open: 1.08853,
        close: 1.08825,
        high: 1.08861,
        low: 1.08819
      },
      {
        timestamp: 1649868900,
        open: 1.08826,
        close: 1.08827,
        high: 1.0884,
        low: 1.08815
      },
      {
        timestamp: 1649869200,
        open: 1.08827,
        close: 1.0878,
        high: 1.0884,
        low: 1.08779
      },
      {
        timestamp: 1649869500,
        open: 1.08781,
        close: 1.08789,
        high: 1.08795,
        low: 1.08754
      },
      {
        timestamp: 1649869800,
        open: 1.08789,
        close: 1.0879,
        high: 1.08797,
        low: 1.08773
      },
      {
        timestamp: 1649870100,
        open: 1.0879,
        close: 1.0885,
        high: 1.08853,
        low: 1.0878
      },
      {
        timestamp: 1649870400,
        open: 1.08849,
        close: 1.08842,
        high: 1.08868,
        low: 1.08816
      },
      {
        timestamp: 1649870700,
        open: 1.08841,
        close: 1.08842,
        high: 1.08846,
        low: 1.08801
      },
      {
        timestamp: 1649871000,
        open: 1.08841,
        close: 1.08827,
        high: 1.08863,
        low: 1.08822
      },
      {
        timestamp: 1649871300,
        open: 1.08827,
        close: 1.08818,
        high: 1.0883,
        low: 1.08788
      },
      {
        timestamp: 1649871600,
        open: 1.08818,
        close: 1.08785,
        high: 1.08824,
        low: 1.08783
      },
      {
        timestamp: 1649871900,
        open: 1.08784,
        close: 1.08778,
        high: 1.08792,
        low: 1.08768
      },
      {
        timestamp: 1649872200,
        open: 1.08779,
        close: 1.08754,
        high: 1.08786,
        low: 1.08749
      },
      {
        timestamp: 1649872500,
        open: 1.08754,
        close: 1.08754,
        high: 1.08765,
        low: 1.0874
      },
      {
        timestamp: 1649872800,
        open: 1.08756,
        close: 1.08739,
        high: 1.08759,
        low: 1.08724
      },
      {
        timestamp: 1649873100,
        open: 1.0874,
        close: 1.08786,
        high: 1.08792,
        low: 1.08738
      },
      {
        timestamp: 1649873400,
        open: 1.08786,
        close: 1.08799,
        high: 1.08799,
        low: 1.08758
      },
      {
        timestamp: 1649873700,
        open: 1.088,
        close: 1.08829,
        high: 1.0883,
        low: 1.08792
      },
      {
        timestamp: 1649874000,
        open: 1.0883,
        close: 1.0883,
        high: 1.0886,
        low: 1.08823
      },
      {
        timestamp: 1649874300,
        open: 1.0883,
        close: 1.08838,
        high: 1.0885,
        low: 1.08804
      },
      {
        timestamp: 1649874600,
        open: 1.08836,
        close: 1.08812,
        high: 1.08852,
        low: 1.08808
      },
      {
        timestamp: 1649874900,
        open: 1.08812,
        close: 1.08788,
        high: 1.08824,
        low: 1.08786
      },
      {
        timestamp: 1649875200,
        open: 1.08789,
        close: 1.08834,
        high: 1.08836,
        low: 1.08789
      },
      {
        timestamp: 1649875500,
        open: 1.08833,
        close: 1.08871,
        high: 1.08872,
        low: 1.08829
      },
      {
        timestamp: 1649875800,
        open: 1.0887,
        close: 1.08855,
        high: 1.08879,
        low: 1.08854
      },
      {
        timestamp: 1649876100,
        open: 1.08854,
        close: 1.08864,
        high: 1.08875,
        low: 1.08849
      },
      {
        timestamp: 1649876400,
        open: 1.08863,
        close: 1.08861,
        high: 1.08877,
        low: 1.08848
      },
      {
        timestamp: 1649876700,
        open: 1.08862,
        close: 1.08889,
        high: 1.089,
        low: 1.08859
      },
      {
        timestamp: 1649877000,
        open: 1.08889,
        close: 1.08894,
        high: 1.08895,
        low: 1.08877
      },
      {
        timestamp: 1649877300,
        open: 1.08892,
        close: 1.08891,
        high: 1.08905,
        low: 1.08884
      },
      {
        timestamp: 1649877600,
        open: 1.08893,
        close: 1.08889,
        high: 1.08915,
        low: 1.0888
      },
      {
        timestamp: 1649877900,
        open: 1.08889,
        close: 1.08901,
        high: 1.08912,
        low: 1.08881
      },
      {
        timestamp: 1649878200,
        open: 1.08901,
        close: 1.08874,
        high: 1.08901,
        low: 1.08873
      },
      {
        timestamp: 1649878500,
        open: 1.08874,
        close: 1.08845,
        high: 1.08881,
        low: 1.08837
      },
      {
        timestamp: 1649878800,
        open: 1.08845,
        close: 1.08854,
        high: 1.08869,
        low: 1.08845
      },
      {
        timestamp: 1649879100,
        open: 1.08855,
        close: 1.08843,
        high: 1.0886,
        low: 1.08838
      },
      {
        timestamp: 1649879400,
        open: 1.08843,
        close: 1.08845,
        high: 1.08847,
        low: 1.08834
      },
      {
        timestamp: 1649879700,
        open: 1.08844,
        close: 1.08836,
        high: 1.08861,
        low: 1.08833
      },
      {
        timestamp: 1649880000,
        open: 1.08836,
        close: 1.08855,
        high: 1.08865,
        low: 1.08836
      },
      {
        timestamp: 1649880300,
        open: 1.08854,
        close: 1.0886,
        high: 1.08862,
        low: 1.08851
      },
      {
        timestamp: 1649880600,
        open: 1.0886,
        close: 1.08857,
        high: 1.08865,
        low: 1.08856
      },
      {
        timestamp: 1649880900,
        open: 1.08858,
        close: 1.0887,
        high: 1.08871,
        low: 1.08856
      },
      {
        timestamp: 1649881200,
        open: 1.08871,
        close: 1.08894,
        high: 1.08894,
        low: 1.08871
      },
      {
        timestamp: 1649881500,
        open: 1.08895,
        close: 1.08898,
        high: 1.08905,
        low: 1.08892
      },
      {
        timestamp: 1649881800,
        open: 1.08897,
        close: 1.08902,
        high: 1.08907,
        low: 1.08893
      },
      {
        timestamp: 1649882100,
        open: 1.08902,
        close: 1.08915,
        high: 1.08917,
        low: 1.08902
      },
      {
        timestamp: 1649882400,
        open: 1.08914,
        close: 1.08918,
        high: 1.08924,
        low: 1.08902
      },
      {
        timestamp: 1649882700,
        open: 1.08918,
        close: 1.08913,
        high: 1.08919,
        low: 1.08904
      },
      {
        timestamp: 1649883000,
        open: 1.08914,
        close: 1.08923,
        high: 1.08925,
        low: 1.08908
      },
      {
        timestamp: 1649883300,
        open: 1.08923,
        close: 1.0892,
        high: 1.08939,
        low: 1.0892
      },
      {
        timestamp: 1649883600,
        open: 1.08926,
        close: 1.08918,
        high: 1.08926,
        low: 1.08918
      },
      {
        timestamp: 1649883900,
        open: 1.08918,
        close: 1.08927,
        high: 1.08929,
        low: 1.08916
      },
      {
        timestamp: 1649884200,
        open: 1.0892,
        close: 1.08911,
        high: 1.08923,
        low: 1.08909
      },
      {
        timestamp: 1649884500,
        open: 1.08911,
        close: 1.08944,
        high: 1.08947,
        low: 1.0891
      },
      {
        timestamp: 1649884800,
        open: 1.08936,
        close: 1.08945,
        high: 1.08945,
        low: 1.08927
      },
      {
        timestamp: 1649885100,
        open: 1.08945,
        close: 1.08945,
        high: 1.0895,
        low: 1.08943
      },
      {
        timestamp: 1649885400,
        open: 1.08945,
        close: 1.08942,
        high: 1.08948,
        low: 1.0894
      },
      {
        timestamp: 1649885700,
        open: 1.08943,
        close: 1.08939,
        high: 1.08943,
        low: 1.08938
      },
      {
        timestamp: 1649886000,
        open: 1.08941,
        close: 1.08931,
        high: 1.08942,
        low: 1.08929
      },
      {
        timestamp: 1649886300,
        open: 1.08929,
        close: 1.08942,
        high: 1.08947,
        low: 1.08928
      },
      {
        timestamp: 1649886600,
        open: 1.0894,
        close: 1.08939,
        high: 1.08942,
        low: 1.0893
      },
      {
        timestamp: 1649886900,
        open: 1.08939,
        close: 1.08912,
        high: 1.08952,
        low: 1.08912
      },
      {
        timestamp: 1649887200,
        open: 1.08913,
        close: 1.08917,
        high: 1.08948,
        low: 1.08907
      },
      {
        timestamp: 1649887500,
        open: 1.08916,
        close: 1.08907,
        high: 1.08932,
        low: 1.08901
      },
      {
        timestamp: 1649887800,
        open: 1.08909,
        close: 1.08893,
        high: 1.08909,
        low: 1.08893
      },
      {
        timestamp: 1649888100,
        open: 1.08895,
        close: 1.08895,
        high: 1.08909,
        low: 1.0889
      },
      {
        timestamp: 1649888400,
        open: 1.08896,
        close: 1.08901,
        high: 1.08902,
        low: 1.08888
      },
      {
        timestamp: 1649888700,
        open: 1.08901,
        close: 1.08909,
        high: 1.08914,
        low: 1.08901
      },
      {
        timestamp: 1649889000,
        open: 1.08908,
        close: 1.08898,
        high: 1.08911,
        low: 1.08898
      },
      {
        timestamp: 1649889300,
        open: 1.08896,
        close: 1.08886,
        high: 1.08903,
        low: 1.08882
      },
      {
        timestamp: 1649889600,
        open: 1.08887,
        close: 1.08892,
        high: 1.08893,
        low: 1.08876
      },
      {
        timestamp: 1649889900,
        open: 1.08893,
        close: 1.08897,
        high: 1.08899,
        low: 1.08885
      },
      {
        timestamp: 1649890200,
        open: 1.08896,
        close: 1.08904,
        high: 1.08904,
        low: 1.08895
      },
      {
        timestamp: 1649890500,
        open: 1.08903,
        close: 1.08902,
        high: 1.08908,
        low: 1.089
      },
      {
        timestamp: 1649890800,
        open: 1.08903,
        close: 1.08907,
        high: 1.08908,
        low: 1.08901
      },
      {
        timestamp: 1649891100,
        open: 1.08908,
        close: 1.08927,
        high: 1.08931,
        low: 1.08906
      },
      {
        timestamp: 1649891400,
        open: 1.08927,
        close: 1.08931,
        high: 1.08932,
        low: 1.08922
      },
      {
        timestamp: 1649891700,
        open: 1.08931,
        close: 1.08932,
        high: 1.08934,
        low: 1.08926
      },
      {
        timestamp: 1649892000,
        open: 1.08932,
        close: 1.08953,
        high: 1.08955,
        low: 1.08927
      },
      {
        timestamp: 1649892300,
        open: 1.08952,
        close: 1.08962,
        high: 1.08964,
        low: 1.08946
      },
      {
        timestamp: 1649892600,
        open: 1.08962,
        close: 1.08952,
        high: 1.08962,
        low: 1.08944
      },
      {
        timestamp: 1649892900,
        open: 1.08953,
        close: 1.08948,
        high: 1.08955,
        low: 1.08939
      },
      {
        timestamp: 1649893200,
        open: 1.08948,
        close: 1.0896,
        high: 1.0896,
        low: 1.08947
      },
      {
        timestamp: 1649893500,
        open: 1.0896,
        close: 1.08958,
        high: 1.0896,
        low: 1.0895
      },
      {
        timestamp: 1649893800,
        open: 1.08959,
        close: 1.08961,
        high: 1.08963,
        low: 1.08949
      },
      {
        timestamp: 1649894100,
        open: 1.08962,
        close: 1.08958,
        high: 1.08982,
        low: 1.08957
      },
      {
        timestamp: 1649894400,
        open: 1.08958,
        close: 1.09001,
        high: 1.09006,
        low: 1.08958
      },
      {
        timestamp: 1649894700,
        open: 1.09,
        close: 1.08942,
        high: 1.09004,
        low: 1.08942
      },
      {
        timestamp: 1649895000,
        open: 1.08942,
        close: 1.08964,
        high: 1.08968,
        low: 1.0893
      },
      {
        timestamp: 1649895300,
        open: 1.08965,
        close: 1.08953,
        high: 1.08973,
        low: 1.08948
      },
      {
        timestamp: 1649895600,
        open: 1.08953,
        close: 1.08941,
        high: 1.08962,
        low: 1.0894
      },
      {
        timestamp: 1649895900,
        open: 1.08942,
        close: 1.0891,
        high: 1.08944,
        low: 1.08908
      },
      {
        timestamp: 1649896200,
        open: 1.0891,
        close: 1.08892,
        high: 1.08911,
        low: 1.0888
      },
      {
        timestamp: 1649896500,
        open: 1.08892,
        close: 1.08878,
        high: 1.08893,
        low: 1.08871
      },
      {
        timestamp: 1649896800,
        open: 1.08878,
        close: 1.08843,
        high: 1.08882,
        low: 1.08841
      },
      {
        timestamp: 1649897100,
        open: 1.08843,
        close: 1.0886,
        high: 1.08871,
        low: 1.08843
      },
      {
        timestamp: 1649897400,
        open: 1.0886,
        close: 1.08855,
        high: 1.08864,
        low: 1.08838
      },
      {
        timestamp: 1649897700,
        open: 1.08855,
        close: 1.08854,
        high: 1.08856,
        low: 1.08834
      },
      {
        timestamp: 1649898000,
        open: 1.08853,
        close: 1.08856,
        high: 1.08862,
        low: 1.08835
      },
      {
        timestamp: 1649898300,
        open: 1.08857,
        close: 1.08872,
        high: 1.08885,
        low: 1.08856
      },
      {
        timestamp: 1649898600,
        open: 1.08873,
        close: 1.08876,
        high: 1.08893,
        low: 1.0887
      },
      {
        timestamp: 1649898900,
        open: 1.08877,
        close: 1.08913,
        high: 1.08913,
        low: 1.08876
      },
      {
        timestamp: 1649899200,
        open: 1.08913,
        close: 1.0892,
        high: 1.08924,
        low: 1.08897
      },
      {
        timestamp: 1649899500,
        open: 1.08922,
        close: 1.08928,
        high: 1.0894,
        low: 1.08922
      },
      {
        timestamp: 1649899800,
        open: 1.08929,
        close: 1.0895,
        high: 1.08958,
        low: 1.08923
      },
      {
        timestamp: 1649900100,
        open: 1.08951,
        close: 1.08946,
        high: 1.08969,
        low: 1.08945
      },
      {
        timestamp: 1649900400,
        open: 1.08946,
        close: 1.08958,
        high: 1.08959,
        low: 1.08928
      },
      {
        timestamp: 1649900700,
        open: 1.08959,
        close: 1.08995,
        high: 1.08997,
        low: 1.0895
      },
      {
        timestamp: 1649901000,
        open: 1.08994,
        close: 1.09004,
        high: 1.09006,
        low: 1.08988
      },
      {
        timestamp: 1649901300,
        open: 1.09005,
        close: 1.0907,
        high: 1.09096,
        low: 1.08999
      },
      {
        timestamp: 1649901600,
        open: 1.0907,
        close: 1.09038,
        high: 1.09074,
        low: 1.09038
      },
      {
        timestamp: 1649901900,
        open: 1.09038,
        close: 1.09068,
        high: 1.09076,
        low: 1.09038
      },
      {
        timestamp: 1649902200,
        open: 1.09068,
        close: 1.0903,
        high: 1.09069,
        low: 1.09028
      },
      {
        timestamp: 1649902500,
        open: 1.0903,
        close: 1.09048,
        high: 1.09049,
        low: 1.09027
      },
      {
        timestamp: 1649902800,
        open: 1.09049,
        close: 1.09032,
        high: 1.09051,
        low: 1.09029
      },
      {
        timestamp: 1649903100,
        open: 1.09033,
        close: 1.09054,
        high: 1.09055,
        low: 1.09029
      },
      {
        timestamp: 1649903400,
        open: 1.09053,
        close: 1.09057,
        high: 1.09064,
        low: 1.09044
      },
      {
        timestamp: 1649903700,
        open: 1.09058,
        close: 1.09054,
        high: 1.09072,
        low: 1.09053
      },
      {
        timestamp: 1649904000,
        open: 1.09054,
        close: 1.09065,
        high: 1.09089,
        low: 1.09054
      },
      {
        timestamp: 1649904300,
        open: 1.09065,
        close: 1.09065,
        high: 1.09079,
        low: 1.09062
      },
      {
        timestamp: 1649904600,
        open: 1.09064,
        close: 1.09059,
        high: 1.09068,
        low: 1.09054
      },
      {
        timestamp: 1649904900,
        open: 1.09058,
        close: 1.09027,
        high: 1.09058,
        low: 1.09025
      },
      {
        timestamp: 1649905200,
        open: 1.09028,
        close: 1.09037,
        high: 1.09062,
        low: 1.09027
      },
      {
        timestamp: 1649905500,
        open: 1.09037,
        close: 1.09043,
        high: 1.0905,
        low: 1.0903
      },
      {
        timestamp: 1649905800,
        open: 1.09043,
        close: 1.09041,
        high: 1.09053,
        low: 1.09031
      },
      {
        timestamp: 1649906100,
        open: 1.09042,
        close: 1.09062,
        high: 1.09064,
        low: 1.0904
      },
      {
        timestamp: 1649906400,
        open: 1.09061,
        close: 1.09065,
        high: 1.09067,
        low: 1.09052
      },
      {
        timestamp: 1649906700,
        open: 1.09065,
        close: 1.09085,
        high: 1.09102,
        low: 1.09063
      },
      {
        timestamp: 1649907000,
        open: 1.09085,
        close: 1.09069,
        high: 1.09086,
        low: 1.09063
      },
      {
        timestamp: 1649907300,
        open: 1.09069,
        close: 1.09083,
        high: 1.09083,
        low: 1.09062
      },
      {
        timestamp: 1649907600,
        open: 1.09083,
        close: 1.09072,
        high: 1.09085,
        low: 1.09068
      },
      {
        timestamp: 1649907900,
        open: 1.09073,
        close: 1.09059,
        high: 1.09076,
        low: 1.09054
      },
      {
        timestamp: 1649908200,
        open: 1.09058,
        close: 1.09063,
        high: 1.09071,
        low: 1.09057
      },
      {
        timestamp: 1649908500,
        open: 1.09064,
        close: 1.0908,
        high: 1.09093,
        low: 1.09064
      },
      {
        timestamp: 1649908800,
        open: 1.09081,
        close: 1.09074,
        high: 1.09089,
        low: 1.09064
      },
      {
        timestamp: 1649909100,
        open: 1.09074,
        close: 1.09058,
        high: 1.09074,
        low: 1.09056
      },
      {
        timestamp: 1649909400,
        open: 1.09058,
        close: 1.09056,
        high: 1.0906,
        low: 1.09043
      },
      {
        timestamp: 1649909700,
        open: 1.09058,
        close: 1.09066,
        high: 1.09068,
        low: 1.09052
      },
      {
        timestamp: 1649910000,
        open: 1.09066,
        close: 1.09072,
        high: 1.09078,
        low: 1.09066
      },
      {
        timestamp: 1649910300,
        open: 1.09073,
        close: 1.09094,
        high: 1.09096,
        low: 1.09068
      },
      {
        timestamp: 1649910600,
        open: 1.09095,
        close: 1.09097,
        high: 1.09101,
        low: 1.09082
      },
      {
        timestamp: 1649910900,
        open: 1.09096,
        close: 1.091,
        high: 1.09101,
        low: 1.09094
      },
      {
        timestamp: 1649911200,
        open: 1.09099,
        close: 1.09093,
        high: 1.09101,
        low: 1.09091
      },
      {
        timestamp: 1649911500,
        open: 1.09094,
        close: 1.09132,
        high: 1.09135,
        low: 1.09094
      },
      {
        timestamp: 1649911800,
        open: 1.09132,
        close: 1.09132,
        high: 1.09137,
        low: 1.09128
      },
      {
        timestamp: 1649912100,
        open: 1.09131,
        close: 1.09131,
        high: 1.09135,
        low: 1.0912
      },
      {
        timestamp: 1649912400,
        open: 1.09131,
        close: 1.09135,
        high: 1.09146,
        low: 1.09126
      },
      {
        timestamp: 1649912700,
        open: 1.09135,
        close: 1.09135,
        high: 1.09147,
        low: 1.09135
      },
      {
        timestamp: 1649913000,
        open: 1.09136,
        close: 1.09174,
        high: 1.09178,
        low: 1.09135
      },
      {
        timestamp: 1649913300,
        open: 1.09173,
        close: 1.09168,
        high: 1.09173,
        low: 1.09143
      },
      {
        timestamp: 1649913600,
        open: 1.09168,
        close: 1.09174,
        high: 1.09188,
        low: 1.09165
      },
      {
        timestamp: 1649913900,
        open: 1.09175,
        close: 1.09165,
        high: 1.09176,
        low: 1.09159
      },
      {
        timestamp: 1649914200,
        open: 1.09165,
        close: 1.09141,
        high: 1.09175,
        low: 1.0914
      },
      {
        timestamp: 1649914500,
        open: 1.09141,
        close: 1.09146,
        high: 1.0915,
        low: 1.0913
      },
      {
        timestamp: 1649914800,
        open: 1.09147,
        close: 1.09157,
        high: 1.09165,
        low: 1.09145
      },
      {
        timestamp: 1649915100,
        open: 1.09157,
        close: 1.09179,
        high: 1.09187,
        low: 1.09155
      },
      {
        timestamp: 1649915400,
        open: 1.09178,
        close: 1.09134,
        high: 1.09178,
        low: 1.09127
      },
      {
        timestamp: 1649915700,
        open: 1.09133,
        close: 1.0911,
        high: 1.09141,
        low: 1.09101
      },
      {
        timestamp: 1649916000,
        open: 1.09109,
        close: 1.09152,
        high: 1.09158,
        low: 1.09108
      },
      {
        timestamp: 1649916300,
        open: 1.09153,
        close: 1.0919,
        high: 1.09192,
        low: 1.09138
      },
      {
        timestamp: 1649916600,
        open: 1.09189,
        close: 1.09176,
        high: 1.09192,
        low: 1.0916
      },
      {
        timestamp: 1649916900,
        open: 1.09176,
        close: 1.09164,
        high: 1.09184,
        low: 1.09159
      },
      {
        timestamp: 1649917200,
        open: 1.09163,
        close: 1.09152,
        high: 1.0917,
        low: 1.09134
      },
      {
        timestamp: 1649917500,
        open: 1.09152,
        close: 1.09159,
        high: 1.09174,
        low: 1.09145
      },
      {
        timestamp: 1649917800,
        open: 1.09159,
        close: 1.09185,
        high: 1.09191,
        low: 1.0914
      },
      {
        timestamp: 1649918100,
        open: 1.09184,
        close: 1.09232,
        high: 1.09241,
        low: 1.09178
      },
      {
        timestamp: 1649918400,
        open: 1.09231,
        close: 1.09184,
        high: 1.09234,
        low: 1.09183
      },
      {
        timestamp: 1649918700,
        open: 1.09183,
        close: 1.09208,
        high: 1.09218,
        low: 1.09177
      },
      {
        timestamp: 1649919000,
        open: 1.09208,
        close: 1.09182,
        high: 1.0922,
        low: 1.09179
      },
      {
        timestamp: 1649919300,
        open: 1.09182,
        close: 1.09191,
        high: 1.09205,
        low: 1.09155
      },
      {
        timestamp: 1649919600,
        open: 1.0919,
        close: 1.09128,
        high: 1.09205,
        low: 1.09122
      },
      {
        timestamp: 1649919900,
        open: 1.09128,
        close: 1.09131,
        high: 1.09168,
        low: 1.0911
      },
      {
        timestamp: 1649920200,
        open: 1.09131,
        close: 1.09163,
        high: 1.09166,
        low: 1.09113
      },
      {
        timestamp: 1649920500,
        open: 1.09164,
        close: 1.09169,
        high: 1.0918,
        low: 1.09145
      },
      {
        timestamp: 1649920800,
        open: 1.09168,
        close: 1.0914,
        high: 1.09169,
        low: 1.09127
      },
      {
        timestamp: 1649921100,
        open: 1.09139,
        close: 1.09109,
        high: 1.09143,
        low: 1.091
      },
      {
        timestamp: 1649921400,
        open: 1.09108,
        close: 1.0907,
        high: 1.09113,
        low: 1.0904
      },
      {
        timestamp: 1649921700,
        open: 1.0907,
        close: 1.09113,
        high: 1.09121,
        low: 1.09063
      },
      {
        timestamp: 1649922000,
        open: 1.09113,
        close: 1.09125,
        high: 1.09139,
        low: 1.09113
      },
      {
        timestamp: 1649922300,
        open: 1.09125,
        close: 1.09089,
        high: 1.09131,
        low: 1.09078
      },
      {
        timestamp: 1649922600,
        open: 1.0909,
        close: 1.09088,
        high: 1.0911,
        low: 1.09073
      },
      {
        timestamp: 1649922900,
        open: 1.09087,
        close: 1.09091,
        high: 1.09101,
        low: 1.09069
      },
      {
        timestamp: 1649923200,
        open: 1.09091,
        close: 1.09056,
        high: 1.09091,
        low: 1.09054
      },
      {
        timestamp: 1649923500,
        open: 1.09055,
        close: 1.09088,
        high: 1.09095,
        low: 1.09054
      },
      {
        timestamp: 1649923800,
        open: 1.09089,
        close: 1.0909,
        high: 1.0913,
        low: 1.09088
      },
      {
        timestamp: 1649924100,
        open: 1.09091,
        close: 1.0908,
        high: 1.09124,
        low: 1.09078
      },
      {
        timestamp: 1649924400,
        open: 1.09079,
        close: 1.09089,
        high: 1.09099,
        low: 1.09068
      },
      {
        timestamp: 1649924700,
        open: 1.09089,
        close: 1.0905,
        high: 1.09089,
        low: 1.09039
      },
      {
        timestamp: 1649925000,
        open: 1.0905,
        close: 1.09063,
        high: 1.09082,
        low: 1.09037
      },
      {
        timestamp: 1649925300,
        open: 1.09062,
        close: 1.09082,
        high: 1.09083,
        low: 1.09051
      },
      {
        timestamp: 1649925600,
        open: 1.09082,
        close: 1.09118,
        high: 1.09133,
        low: 1.0907
      },
      {
        timestamp: 1649925900,
        open: 1.09119,
        close: 1.09108,
        high: 1.0913,
        low: 1.09105
      },
      {
        timestamp: 1649926200,
        open: 1.09108,
        close: 1.09107,
        high: 1.0912,
        low: 1.09103
      },
      {
        timestamp: 1649926500,
        open: 1.09107,
        close: 1.09089,
        high: 1.09118,
        low: 1.09083
      },
      {
        timestamp: 1649926800,
        open: 1.09088,
        close: 1.09035,
        high: 1.09089,
        low: 1.09032
      },
      {
        timestamp: 1649927100,
        open: 1.09036,
        close: 1.0906,
        high: 1.09064,
        low: 1.09023
      },
      {
        timestamp: 1649927400,
        open: 1.09059,
        close: 1.09134,
        high: 1.09137,
        low: 1.09058
      },
      {
        timestamp: 1649927700,
        open: 1.09135,
        close: 1.09127,
        high: 1.09147,
        low: 1.09123
      },
      {
        timestamp: 1649928000,
        open: 1.09125,
        close: 1.09123,
        high: 1.09133,
        low: 1.09103
      },
      {
        timestamp: 1649928300,
        open: 1.09122,
        close: 1.09145,
        high: 1.09146,
        low: 1.09107
      },
      {
        timestamp: 1649928600,
        open: 1.09146,
        close: 1.09173,
        high: 1.09182,
        low: 1.09142
      },
      {
        timestamp: 1649928900,
        open: 1.09174,
        close: 1.09174,
        high: 1.09183,
        low: 1.09154
      },
      {
        timestamp: 1649929200,
        open: 1.09173,
        close: 1.09159,
        high: 1.09175,
        low: 1.09119
      },
      {
        timestamp: 1649929500,
        open: 1.09159,
        close: 1.09149,
        high: 1.09171,
        low: 1.09137
      },
      {
        timestamp: 1649929800,
        open: 1.0915,
        close: 1.09132,
        high: 1.09151,
        low: 1.09111
      },
      {
        timestamp: 1649930100,
        open: 1.09132,
        close: 1.09146,
        high: 1.09154,
        low: 1.09117
      },
      {
        timestamp: 1649930400,
        open: 1.09145,
        close: 1.09162,
        high: 1.09173,
        low: 1.09143
      },
      {
        timestamp: 1649930700,
        open: 1.09163,
        close: 1.09141,
        high: 1.09164,
        low: 1.09128
      },
      {
        timestamp: 1649931000,
        open: 1.09142,
        close: 1.0914,
        high: 1.09164,
        low: 1.09132
      },
      {
        timestamp: 1649931300,
        open: 1.0914,
        close: 1.09149,
        high: 1.09156,
        low: 1.09136
      },
      {
        timestamp: 1649931600,
        open: 1.09148,
        close: 1.09167,
        high: 1.09179,
        low: 1.09135
      },
      {
        timestamp: 1649931900,
        open: 1.09167,
        close: 1.09104,
        high: 1.09167,
        low: 1.09101
      },
      {
        timestamp: 1649932200,
        open: 1.09104,
        close: 1.09028,
        high: 1.09104,
        low: 1.09026
      },
      {
        timestamp: 1649932500,
        open: 1.09029,
        close: 1.09072,
        high: 1.09076,
        low: 1.09019
      },
      {
        timestamp: 1649932800,
        open: 1.09073,
        close: 1.09074,
        high: 1.09086,
        low: 1.09059
      },
      {
        timestamp: 1649933100,
        open: 1.09074,
        close: 1.09075,
        high: 1.09088,
        low: 1.09058
      },
      {
        timestamp: 1649933400,
        open: 1.09075,
        close: 1.09089,
        high: 1.09096,
        low: 1.09065
      },
      {
        timestamp: 1649933700,
        open: 1.09089,
        close: 1.09065,
        high: 1.09092,
        low: 1.0906
      },
      {
        timestamp: 1649934000,
        open: 1.09064,
        close: 1.09062,
        high: 1.09073,
        low: 1.09027
      },
      {
        timestamp: 1649934300,
        open: 1.09062,
        close: 1.09044,
        high: 1.09063,
        low: 1.09032
      },
      {
        timestamp: 1649934600,
        open: 1.09044,
        close: 1.09067,
        high: 1.09078,
        low: 1.09032
      },
      {
        timestamp: 1649934900,
        open: 1.09067,
        close: 1.0907,
        high: 1.0907,
        low: 1.09029
      },
      {
        timestamp: 1649935200,
        open: 1.09069,
        close: 1.09068,
        high: 1.09078,
        low: 1.09059
      },
      {
        timestamp: 1649935500,
        open: 1.09068,
        close: 1.09054,
        high: 1.09069,
        low: 1.09043
      },
      {
        timestamp: 1649935800,
        open: 1.09054,
        close: 1.09122,
        high: 1.09134,
        low: 1.09046
      },
      {
        timestamp: 1649936100,
        open: 1.09121,
        close: 1.09144,
        high: 1.09148,
        low: 1.09108
      },
      {
        timestamp: 1649936400,
        open: 1.09144,
        close: 1.09185,
        high: 1.09191,
        low: 1.09129
      },
      {
        timestamp: 1649936700,
        open: 1.09187,
        close: 1.08748,
        high: 1.09187,
        low: 1.08714
      },
      {
        timestamp: 1649937000,
        open: 1.08748,
        close: 1.08721,
        high: 1.08829,
        low: 1.08639
      },
      {
        timestamp: 1649937300,
        open: 1.08721,
        close: 1.0866,
        high: 1.0876,
        low: 1.08657
      },
      {
        timestamp: 1649937600,
        open: 1.0866,
        close: 1.08743,
        high: 1.08753,
        low: 1.08658
      },
      {
        timestamp: 1649937900,
        open: 1.08742,
        close: 1.08765,
        high: 1.08781,
        low: 1.08721
      },
      {
        timestamp: 1649938200,
        open: 1.08765,
        close: 1.08788,
        high: 1.08838,
        low: 1.08763
      },
      {
        timestamp: 1649938500,
        open: 1.08785,
        close: 1.08793,
        high: 1.08807,
        low: 1.08765
      },
      {
        timestamp: 1649938800,
        open: 1.08794,
        close: 1.0885,
        high: 1.08865,
        low: 1.08786
      },
      {
        timestamp: 1649939100,
        open: 1.08851,
        close: 1.08857,
        high: 1.08862,
        low: 1.08825
      },
      {
        timestamp: 1649939400,
        open: 1.08856,
        close: 1.08768,
        high: 1.08876,
        low: 1.08735
      },
      {
        timestamp: 1649939700,
        open: 1.08768,
        close: 1.08849,
        high: 1.08915,
        low: 1.08637
      },
      {
        timestamp: 1649940000,
        open: 1.08849,
        close: 1.08811,
        high: 1.08872,
        low: 1.08704
      },
      {
        timestamp: 1649940300,
        open: 1.08811,
        close: 1.0868,
        high: 1.0886,
        low: 1.08666
      },
      {
        timestamp: 1649940600,
        open: 1.08679,
        close: 1.08566,
        high: 1.08681,
        low: 1.08525
      },
      {
        timestamp: 1649940900,
        open: 1.08565,
        close: 1.08485,
        high: 1.08569,
        low: 1.08443
      },
      {
        timestamp: 1649941200,
        open: 1.08484,
        close: 1.0842,
        high: 1.08498,
        low: 1.08334
      },
      {
        timestamp: 1649941500,
        open: 1.0842,
        close: 1.08411,
        high: 1.08482,
        low: 1.08389
      },
      {
        timestamp: 1649941800,
        open: 1.08411,
        close: 1.0835,
        high: 1.08419,
        low: 1.08337
      },
      {
        timestamp: 1649942100,
        open: 1.08348,
        close: 1.08233,
        high: 1.0837,
        low: 1.08217
      },
      {
        timestamp: 1649942400,
        open: 1.08233,
        close: 1.08244,
        high: 1.08297,
        low: 1.08175
      },
      {
        timestamp: 1649942700,
        open: 1.08245,
        close: 1.08249,
        high: 1.08343,
        low: 1.08207
      },
      {
        timestamp: 1649943000,
        open: 1.08249,
        close: 1.08196,
        high: 1.08285,
        low: 1.08196
      },
      {
        timestamp: 1649943300,
        open: 1.08196,
        close: 1.08156,
        high: 1.08235,
        low: 1.08086
      },
      {
        timestamp: 1649943600,
        open: 1.08157,
        close: 1.0786,
        high: 1.08161,
        low: 1.0785
      },
      {
        timestamp: 1649943900,
        open: 1.07861,
        close: 1.07899,
        high: 1.07929,
        low: 1.07858
      },
      {
        timestamp: 1649944200,
        open: 1.07898,
        close: 1.07658,
        high: 1.07904,
        low: 1.07581
      },
      {
        timestamp: 1649944500,
        open: 1.07657,
        close: 1.07717,
        high: 1.07788,
        low: 1.07643
      },
      {
        timestamp: 1649944800,
        open: 1.07718,
        close: 1.07819,
        high: 1.07855,
        low: 1.07709
      },
      {
        timestamp: 1649945100,
        open: 1.07819,
        close: 1.07844,
        high: 1.07848,
        low: 1.0775
      },
      {
        timestamp: 1649945400,
        open: 1.07844,
        close: 1.07825,
        high: 1.07886,
        low: 1.07815
      },
      {
        timestamp: 1649945700,
        open: 1.07824,
        close: 1.07893,
        high: 1.07904,
        low: 1.07823
      },
      {
        timestamp: 1649946000,
        open: 1.07893,
        close: 1.0792,
        high: 1.07992,
        low: 1.07873
      },
      {
        timestamp: 1649946300,
        open: 1.07921,
        close: 1.07964,
        high: 1.07984,
        low: 1.079
      },
      {
        timestamp: 1649946600,
        open: 1.07964,
        close: 1.07852,
        high: 1.07978,
        low: 1.0784
      },
      {
        timestamp: 1649946900,
        open: 1.07851,
        close: 1.07801,
        high: 1.07885,
        low: 1.07794
      },
      {
        timestamp: 1649947200,
        open: 1.07802,
        close: 1.07868,
        high: 1.07884,
        low: 1.07759
      },
      {
        timestamp: 1649947500,
        open: 1.07868,
        close: 1.07871,
        high: 1.07921,
        low: 1.07829
      },
      {
        timestamp: 1649947800,
        open: 1.07871,
        close: 1.07772,
        high: 1.07876,
        low: 1.0772
      },
      {
        timestamp: 1649948100,
        open: 1.07772,
        close: 1.07785,
        high: 1.07794,
        low: 1.07695
      },
      {
        timestamp: 1649948400,
        open: 1.07786,
        close: 1.07827,
        high: 1.07845,
        low: 1.07744
      },
      {
        timestamp: 1649948700,
        open: 1.07825,
        close: 1.08074,
        high: 1.0808,
        low: 1.07795
      },
      {
        timestamp: 1649949000,
        open: 1.08075,
        close: 1.08068,
        high: 1.08183,
        low: 1.08007
      },
      {
        timestamp: 1649949300,
        open: 1.08068,
        close: 1.08137,
        high: 1.08173,
        low: 1.0805
      },
      {
        timestamp: 1649949600,
        open: 1.08138,
        close: 1.08084,
        high: 1.08178,
        low: 1.0802
      },
      {
        timestamp: 1649949900,
        open: 1.08084,
        close: 1.07969,
        high: 1.08089,
        low: 1.07939
      },
      {
        timestamp: 1649950200,
        open: 1.07969,
        close: 1.07944,
        high: 1.08031,
        low: 1.07939
      },
      {
        timestamp: 1649950500,
        open: 1.07944,
        close: 1.0791,
        high: 1.07973,
        low: 1.07907
      },
      {
        timestamp: 1649950800,
        open: 1.07911,
        close: 1.07904,
        high: 1.07915,
        low: 1.07838
      },
      {
        timestamp: 1649951100,
        open: 1.07904,
        close: 1.0792,
        high: 1.07942,
        low: 1.07857
      },
      {
        timestamp: 1649951400,
        open: 1.0792,
        close: 1.07945,
        high: 1.07964,
        low: 1.07898
      },
      {
        timestamp: 1649951700,
        open: 1.07945,
        close: 1.07982,
        high: 1.07982,
        low: 1.07901
      },
      {
        timestamp: 1649952000,
        open: 1.07982,
        close: 1.08056,
        high: 1.08078,
        low: 1.07954
      },
      {
        timestamp: 1649952300,
        open: 1.08057,
        close: 1.08248,
        high: 1.08292,
        low: 1.0803
      },
      {
        timestamp: 1649952600,
        open: 1.08249,
        close: 1.08229,
        high: 1.08252,
        low: 1.0819
      },
      {
        timestamp: 1649952900,
        open: 1.08229,
        close: 1.08283,
        high: 1.08285,
        low: 1.08177
      },
      {
        timestamp: 1649953200,
        open: 1.08283,
        close: 1.08323,
        high: 1.08323,
        low: 1.08232
      },
      {
        timestamp: 1649953500,
        open: 1.08322,
        close: 1.08247,
        high: 1.08322,
        low: 1.08236
      },
      {
        timestamp: 1649953800,
        open: 1.08247,
        close: 1.08228,
        high: 1.08254,
        low: 1.08224
      },
      {
        timestamp: 1649954100,
        open: 1.08228,
        close: 1.08206,
        high: 1.08264,
        low: 1.08198
      },
      {
        timestamp: 1649954400,
        open: 1.08207,
        close: 1.08184,
        high: 1.08209,
        low: 1.08163
      },
      {
        timestamp: 1649954700,
        open: 1.08184,
        close: 1.08175,
        high: 1.08198,
        low: 1.08149
      },
      {
        timestamp: 1649955000,
        open: 1.08176,
        close: 1.08179,
        high: 1.08191,
        low: 1.08153
      },
      {
        timestamp: 1649955300,
        open: 1.08179,
        close: 1.08168,
        high: 1.08179,
        low: 1.08138
      },
      {
        timestamp: 1649955600,
        open: 1.08168,
        close: 1.08157,
        high: 1.08199,
        low: 1.08147
      },
      {
        timestamp: 1649955900,
        open: 1.08155,
        close: 1.08134,
        high: 1.08158,
        low: 1.0812
      },
      {
        timestamp: 1649956200,
        open: 1.08135,
        close: 1.08123,
        high: 1.08157,
        low: 1.08117
      },
      {
        timestamp: 1649956500,
        open: 1.08123,
        close: 1.08154,
        high: 1.08157,
        low: 1.08096
      },
      {
        timestamp: 1649956800,
        open: 1.08154,
        close: 1.08139,
        high: 1.0816,
        low: 1.08138
      },
      {
        timestamp: 1649957100,
        open: 1.08139,
        close: 1.08163,
        high: 1.0819,
        low: 1.08124
      },
      {
        timestamp: 1649957400,
        open: 1.08164,
        close: 1.08204,
        high: 1.08209,
        low: 1.08158
      },
      {
        timestamp: 1649957700,
        open: 1.08204,
        close: 1.08207,
        high: 1.08276,
        low: 1.08199
      },
      {
        timestamp: 1649958000,
        open: 1.08207,
        close: 1.08188,
        high: 1.08232,
        low: 1.08187
      },
      {
        timestamp: 1649958300,
        open: 1.08189,
        close: 1.08234,
        high: 1.08246,
        low: 1.08187
      },
      {
        timestamp: 1649958600,
        open: 1.08235,
        close: 1.0822,
        high: 1.08253,
        low: 1.0822
      },
      {
        timestamp: 1649958900,
        open: 1.08222,
        close: 1.08248,
        high: 1.0825,
        low: 1.08217
      },
      {
        timestamp: 1649959200,
        open: 1.08248,
        close: 1.0826,
        high: 1.08263,
        low: 1.08233
      },
      {
        timestamp: 1649959500,
        open: 1.08259,
        close: 1.08254,
        high: 1.08271,
        low: 1.08249
      },
      {
        timestamp: 1649959800,
        open: 1.08254,
        close: 1.08285,
        high: 1.08304,
        low: 1.08249
      },
      {
        timestamp: 1649960100,
        open: 1.08286,
        close: 1.08238,
        high: 1.08295,
        low: 1.08237
      },
      {
        timestamp: 1649960400,
        open: 1.08238,
        close: 1.08237,
        high: 1.08246,
        low: 1.08215
      },
      {
        timestamp: 1649960700,
        open: 1.08235,
        close: 1.08263,
        high: 1.08263,
        low: 1.08226
      },
      {
        timestamp: 1649961000,
        open: 1.08264,
        close: 1.08276,
        high: 1.08284,
        low: 1.08264
      },
      {
        timestamp: 1649961300,
        open: 1.08276,
        close: 1.08285,
        high: 1.08297,
        low: 1.08274
      },
      {
        timestamp: 1649961600,
        open: 1.08285,
        close: 1.08308,
        high: 1.08318,
        low: 1.08285
      },
      {
        timestamp: 1649961900,
        open: 1.08309,
        close: 1.083,
        high: 1.08313,
        low: 1.08283
      },
      {
        timestamp: 1649962200,
        open: 1.08301,
        close: 1.08279,
        high: 1.08302,
        low: 1.08277
      },
      {
        timestamp: 1649962500,
        open: 1.08279,
        close: 1.08307,
        high: 1.08311,
        low: 1.08248
      },
      {
        timestamp: 1649962800,
        open: 1.08307,
        close: 1.08351,
        high: 1.08355,
        low: 1.08302
      },
      {
        timestamp: 1649963100,
        open: 1.08351,
        close: 1.08333,
        high: 1.08351,
        low: 1.08328
      },
      {
        timestamp: 1649963400,
        open: 1.08332,
        close: 1.08352,
        high: 1.08352,
        low: 1.08326
      },
      {
        timestamp: 1649963700,
        open: 1.08351,
        close: 1.08348,
        high: 1.08353,
        low: 1.08338
      },
      {
        timestamp: 1649964000,
        open: 1.08348,
        close: 1.08293,
        high: 1.08356,
        low: 1.08289
      },
      {
        timestamp: 1649964300,
        open: 1.08292,
        close: 1.08285,
        high: 1.08295,
        low: 1.08277
      },
      {
        timestamp: 1649964600,
        open: 1.08286,
        close: 1.08283,
        high: 1.08287,
        low: 1.08265
      },
      {
        timestamp: 1649964900,
        open: 1.08282,
        close: 1.08264,
        high: 1.08286,
        low: 1.08261
      },
      {
        timestamp: 1649965200,
        open: 1.08264,
        close: 1.08278,
        high: 1.08283,
        low: 1.08244
      },
      {
        timestamp: 1649965500,
        open: 1.08277,
        close: 1.08286,
        high: 1.08295,
        low: 1.08272
      },
      {
        timestamp: 1649965800,
        open: 1.08285,
        close: 1.08284,
        high: 1.08286,
        low: 1.08258
      },
      {
        timestamp: 1649966100,
        open: 1.08284,
        close: 1.0825,
        high: 1.08294,
        low: 1.08248
      },
      {
        timestamp: 1649966400,
        open: 1.08249,
        close: 1.08292,
        high: 1.08295,
        low: 1.08247
      },
      {
        timestamp: 1649966700,
        open: 1.08293,
        close: 1.08302,
        high: 1.08305,
        low: 1.08281
      },
      {
        timestamp: 1649967000,
        open: 1.08302,
        close: 1.08306,
        high: 1.08319,
        low: 1.08301
      },
      {
        timestamp: 1649967300,
        open: 1.08307,
        close: 1.08307,
        high: 1.08312,
        low: 1.08303
      },
      {
        timestamp: 1649967600,
        open: 1.08307,
        close: 1.08308,
        high: 1.08309,
        low: 1.08303
      },
      {
        timestamp: 1649967900,
        open: 1.08308,
        close: 1.08308,
        high: 1.0831,
        low: 1.08285
      },
      {
        timestamp: 1649968200,
        open: 1.08307,
        close: 1.08333,
        high: 1.08337,
        low: 1.08306
      },
      {
        timestamp: 1649968500,
        open: 1.08332,
        close: 1.08329,
        high: 1.08334,
        low: 1.08317
      },
      {
        timestamp: 1649968800,
        open: 1.0833,
        close: 1.08326,
        high: 1.08336,
        low: 1.08323
      },
      {
        timestamp: 1649969100,
        open: 1.08326,
        close: 1.08343,
        high: 1.08352,
        low: 1.08318
      },
      {
        timestamp: 1649969400,
        open: 1.08346,
        close: 1.08319,
        high: 1.0835,
        low: 1.08318
      },
      {
        timestamp: 1649969700,
        open: 1.08318,
        close: 1.08309,
        high: 1.08321,
        low: 1.08278
      },
      {
        timestamp: 1649970000,
        open: 1.08309,
        close: 1.08312,
        high: 1.08319,
        low: 1.08309
      },
      {
        timestamp: 1649970300,
        open: 1.08316,
        close: 1.08312,
        high: 1.08316,
        low: 1.08309
      },
      {
        timestamp: 1649970600,
        open: 1.08312,
        close: 1.08292,
        high: 1.08315,
        low: 1.08292
      },
      {
        timestamp: 1649970900,
        open: 1.08294,
        close: 1.08297,
        high: 1.08315,
        low: 1.08292
      },
      {
        timestamp: 1649971200,
        open: 1.08297,
        close: 1.083,
        high: 1.08304,
        low: 1.08295
      },
      {
        timestamp: 1649971500,
        open: 1.08299,
        close: 1.08303,
        high: 1.08309,
        low: 1.0829
      },
      {
        timestamp: 1649971800,
        open: 1.08304,
        close: 1.08294,
        high: 1.08314,
        low: 1.08293
      },
      {
        timestamp: 1649972100,
        open: 1.08294,
        close: 1.08294,
        high: 1.08294,
        low: 1.08293
      },
      {
        timestamp: 1649972400,
        open: 1.08292,
        close: 1.0831,
        high: 1.0831,
        low: 1.08292
      },
      {
        timestamp: 1649972700,
        open: 1.0831,
        close: 1.08309,
        high: 1.0831,
        low: 1.08309
      },
      {
        timestamp: 1649973000,
        open: 1.08309,
        close: 1.08305,
        high: 1.08309,
        low: 1.08292
      },
      {
        timestamp: 1649973300,
        open: 1.08305,
        close: 1.08305,
        high: 1.08305,
        low: 1.08305
      },
      {
        timestamp: 1649973600,
        open: 1.08305,
        close: 1.08277,
        high: 1.08305,
        low: 1.08275
      },
      {
        timestamp: 1649973900,
        open: 1.08276,
        close: 1.08245,
        high: 1.08279,
        low: 1.08245
      },
      {
        timestamp: 1649974200,
        open: 1.08252,
        close: 1.08263,
        high: 1.08272,
        low: 1.08252
      },
      {
        timestamp: 1649974500,
        open: 1.08264,
        close: 1.08269,
        high: 1.08269,
        low: 1.0826
      },
      {
        timestamp: 1649974800,
        open: 1.08269,
        close: 1.08254,
        high: 1.08269,
        low: 1.08249
      },
      {
        timestamp: 1649975100,
        open: 1.08253,
        close: 1.08243,
        high: 1.08267,
        low: 1.08241
      },
      {
        timestamp: 1649975400,
        open: 1.08243,
        close: 1.08269,
        high: 1.08283,
        low: 1.08243
      },
      {
        timestamp: 1649975700,
        open: 1.08268,
        close: 1.08256,
        high: 1.0827,
        low: 1.08236
      },
      {
        timestamp: 1649976000,
        open: 1.08256,
        close: 1.08238,
        high: 1.08274,
        low: 1.08238
      },
      {
        timestamp: 1649976300,
        open: 1.08237,
        close: 1.08244,
        high: 1.08267,
        low: 1.08234
      },
      {
        timestamp: 1649976600,
        open: 1.08243,
        close: 1.08244,
        high: 1.0825,
        low: 1.08238
      },
      {
        timestamp: 1649976900,
        open: 1.08243,
        close: 1.08246,
        high: 1.08251,
        low: 1.0823
      },
      {
        timestamp: 1649977200,
        open: 1.08245,
        close: 1.08261,
        high: 1.08271,
        low: 1.08245
      },
      {
        timestamp: 1649977500,
        open: 1.0826,
        close: 1.08263,
        high: 1.08272,
        low: 1.08253
      },
      {
        timestamp: 1649977800,
        open: 1.08262,
        close: 1.08258,
        high: 1.08269,
        low: 1.08246
      },
      {
        timestamp: 1649978100,
        open: 1.08255,
        close: 1.08239,
        high: 1.08255,
        low: 1.08234
      },
      {
        timestamp: 1649978400,
        open: 1.08239,
        close: 1.08185,
        high: 1.08253,
        low: 1.08183
      },
      {
        timestamp: 1649978700,
        open: 1.08186,
        close: 1.08172,
        high: 1.08187,
        low: 1.0817
      },
      {
        timestamp: 1649979000,
        open: 1.08171,
        close: 1.08174,
        high: 1.08176,
        low: 1.08169
      },
      {
        timestamp: 1649979300,
        open: 1.08173,
        close: 1.08158,
        high: 1.08173,
        low: 1.08155
      },
      {
        timestamp: 1649979600,
        open: 1.08158,
        close: 1.08192,
        high: 1.08202,
        low: 1.08155
      },
      {
        timestamp: 1649979900,
        open: 1.08191,
        close: 1.08223,
        high: 1.08237,
        low: 1.08173
      },
      {
        timestamp: 1649980200,
        open: 1.08223,
        close: 1.08203,
        high: 1.08244,
        low: 1.08199
      },
      {
        timestamp: 1649980500,
        open: 1.08204,
        close: 1.08223,
        high: 1.08239,
        low: 1.082
      },
      {
        timestamp: 1649980800,
        open: 1.08229,
        close: 1.08172,
        high: 1.08249,
        low: 1.08169
      },
      {
        timestamp: 1649981100,
        open: 1.0817,
        close: 1.08168,
        high: 1.08184,
        low: 1.08164
      },
      {
        timestamp: 1649981400,
        open: 1.08171,
        close: 1.08161,
        high: 1.08172,
        low: 1.08157
      },
      {
        timestamp: 1649981700,
        open: 1.0816,
        close: 1.08156,
        high: 1.08163,
        low: 1.08146
      },
      {
        timestamp: 1649982000,
        open: 1.08158,
        close: 1.08146,
        high: 1.08163,
        low: 1.08145
      },
      {
        timestamp: 1649982300,
        open: 1.08145,
        close: 1.08145,
        high: 1.08149,
        low: 1.08142
      },
      {
        timestamp: 1649982600,
        open: 1.08144,
        close: 1.08158,
        high: 1.08158,
        low: 1.0814
      },
      {
        timestamp: 1649982900,
        open: 1.08159,
        close: 1.08165,
        high: 1.08165,
        low: 1.08158
      },
      {
        timestamp: 1649983200,
        open: 1.08164,
        close: 1.08163,
        high: 1.08164,
        low: 1.0816
      },
      {
        timestamp: 1649983500,
        open: 1.08164,
        close: 1.08165,
        high: 1.08173,
        low: 1.08161
      },
      {
        timestamp: 1649983800,
        open: 1.08164,
        close: 1.08127,
        high: 1.08171,
        low: 1.08125
      },
      {
        timestamp: 1649984100,
        open: 1.08128,
        close: 1.08117,
        high: 1.08166,
        low: 1.08002
      },
      {
        timestamp: 1649984400,
        open: 1.08117,
        close: 1.08099,
        high: 1.0815,
        low: 1.08088
      },
      {
        timestamp: 1649984700,
        open: 1.08098,
        close: 1.08098,
        high: 1.08102,
        low: 1.0808
      },
      {
        timestamp: 1649985000,
        open: 1.08098,
        close: 1.0815,
        high: 1.0816,
        low: 1.08094
      },
      {
        timestamp: 1649985300,
        open: 1.0815,
        close: 1.0811,
        high: 1.08151,
        low: 1.08103
      },
      {
        timestamp: 1649985600,
        open: 1.0811,
        close: 1.08136,
        high: 1.08153,
        low: 1.08108
      },
      {
        timestamp: 1649985900,
        open: 1.08137,
        close: 1.08138,
        high: 1.08143,
        low: 1.08135
      },
      {
        timestamp: 1649986200,
        open: 1.08139,
        close: 1.08137,
        high: 1.08142,
        low: 1.08136
      },
      {
        timestamp: 1649986500,
        open: 1.08136,
        close: 1.08145,
        high: 1.08145,
        low: 1.08136
      },
      {
        timestamp: 1649986800,
        open: 1.08144,
        close: 1.08134,
        high: 1.08148,
        low: 1.08134
      },
      {
        timestamp: 1649987100,
        open: 1.08135,
        close: 1.08138,
        high: 1.08155,
        low: 1.08135
      },
      {
        timestamp: 1649987400,
        open: 1.08138,
        close: 1.08135,
        high: 1.08164,
        low: 1.08129
      },
      {
        timestamp: 1649987700,
        open: 1.08135,
        close: 1.08135,
        high: 1.0814,
        low: 1.08127
      },
      {
        timestamp: 1649988000,
        open: 1.08135,
        close: 1.08134,
        high: 1.08146,
        low: 1.08125
      },
      {
        timestamp: 1649988300,
        open: 1.08134,
        close: 1.08136,
        high: 1.08145,
        low: 1.08131
      },
      {
        timestamp: 1649988600,
        open: 1.08137,
        close: 1.08139,
        high: 1.08142,
        low: 1.08132
      },
      {
        timestamp: 1649988900,
        open: 1.08138,
        close: 1.08138,
        high: 1.08143,
        low: 1.08132
      },
      {
        timestamp: 1649989200,
        open: 1.08138,
        close: 1.08131,
        high: 1.08146,
        low: 1.08129
      },
      {
        timestamp: 1649989500,
        open: 1.08131,
        close: 1.08137,
        high: 1.08141,
        low: 1.08129
      },
      {
        timestamp: 1649989800,
        open: 1.08138,
        close: 1.08131,
        high: 1.08152,
        low: 1.08129
      },
      {
        timestamp: 1649990100,
        open: 1.0813,
        close: 1.08127,
        high: 1.08131,
        low: 1.08125
      },
      {
        timestamp: 1649990400,
        open: 1.08128,
        close: 1.08119,
        high: 1.08134,
        low: 1.08119
      },
      {
        timestamp: 1649990700,
        open: 1.08119,
        close: 1.08114,
        high: 1.08144,
        low: 1.08113
      },
      {
        timestamp: 1649991000,
        open: 1.08115,
        close: 1.08127,
        high: 1.0814,
        low: 1.08114
      },
      {
        timestamp: 1649991300,
        open: 1.08128,
        close: 1.07979,
        high: 1.08132,
        low: 1.07975
      },
      {
        timestamp: 1649991600,
        open: 1.07978,
        close: 1.08027,
        high: 1.08093,
        low: 1.07973
      },
      {
        timestamp: 1649991900,
        open: 1.08027,
        close: 1.08037,
        high: 1.08046,
        low: 1.08004
      },
      {
        timestamp: 1649992200,
        open: 1.08036,
        close: 1.08028,
        high: 1.08051,
        low: 1.08018
      },
      {
        timestamp: 1649992500,
        open: 1.08028,
        close: 1.08037,
        high: 1.0806,
        low: 1.08014
      },
      {
        timestamp: 1649992800,
        open: 1.0804,
        close: 1.08025,
        high: 1.08046,
        low: 1.08017
      },
      {
        timestamp: 1649993100,
        open: 1.08028,
        close: 1.08011,
        high: 1.08028,
        low: 1.08001
      },
      {
        timestamp: 1649993400,
        open: 1.08012,
        close: 1.08009,
        high: 1.08012,
        low: 1.08005
      },
      {
        timestamp: 1649993700,
        open: 1.08009,
        close: 1.08007,
        high: 1.08014,
        low: 1.08004
      },
      {
        timestamp: 1649994000,
        open: 1.08008,
        close: 1.08027,
        high: 1.08034,
        low: 1.08007
      },
      {
        timestamp: 1649994300,
        open: 1.08027,
        close: 1.08021,
        high: 1.08029,
        low: 1.08017
      },
      {
        timestamp: 1649994600,
        open: 1.08021,
        close: 1.08023,
        high: 1.08028,
        low: 1.08019
      },
      {
        timestamp: 1649994900,
        open: 1.08025,
        close: 1.0806,
        high: 1.08068,
        low: 1.08022
      },
      {
        timestamp: 1649995200,
        open: 1.0806,
        close: 1.08048,
        high: 1.08075,
        low: 1.08046
      },
      {
        timestamp: 1649995500,
        open: 1.08047,
        close: 1.08047,
        high: 1.0805,
        low: 1.08044
      },
      {
        timestamp: 1649995800,
        open: 1.08046,
        close: 1.0805,
        high: 1.08051,
        low: 1.08046
      },
      {
        timestamp: 1649996100,
        open: 1.08049,
        close: 1.08046,
        high: 1.08049,
        low: 1.08041
      },
      {
        timestamp: 1649996400,
        open: 1.08048,
        close: 1.08038,
        high: 1.08051,
        low: 1.08038
      },
      {
        timestamp: 1649996700,
        open: 1.08037,
        close: 1.08042,
        high: 1.08048,
        low: 1.08034
      },
      {
        timestamp: 1649997000,
        open: 1.08042,
        close: 1.08047,
        high: 1.08049,
        low: 1.08042
      },
      {
        timestamp: 1649997300,
        open: 1.08046,
        close: 1.08047,
        high: 1.08048,
        low: 1.08044
      },
      {
        timestamp: 1649997600,
        open: 1.08049,
        close: 1.08047,
        high: 1.08049,
        low: 1.08046
      },
      {
        timestamp: 1649997900,
        open: 1.08048,
        close: 1.08054,
        high: 1.08055,
        low: 1.08048
      },
      {
        timestamp: 1649998200,
        open: 1.08053,
        close: 1.08077,
        high: 1.0808,
        low: 1.0805
      },
      {
        timestamp: 1649998500,
        open: 1.08076,
        close: 1.08066,
        high: 1.08077,
        low: 1.08058
      },
      {
        timestamp: 1649998800,
        open: 1.08065,
        close: 1.08081,
        high: 1.08082,
        low: 1.08057
      },
      {
        timestamp: 1649999100,
        open: 1.0808,
        close: 1.08068,
        high: 1.08082,
        low: 1.08068
      },
      {
        timestamp: 1649999400,
        open: 1.08069,
        close: 1.08069,
        high: 1.0807,
        low: 1.08067
      },
      {
        timestamp: 1649999700,
        open: 1.08068,
        close: 1.08071,
        high: 1.08075,
        low: 1.08065
      },
      {
        timestamp: 1650000000,
        open: 1.08074,
        close: 1.08078,
        high: 1.08079,
        low: 1.08073
      },
      {
        timestamp: 1650000300,
        open: 1.08077,
        close: 1.08071,
        high: 1.08077,
        low: 1.08069
      },
      {
        timestamp: 1650000600,
        open: 1.08072,
        close: 1.08064,
        high: 1.08073,
        low: 1.08052
      },
      {
        timestamp: 1650000900,
        open: 1.08064,
        close: 1.08059,
        high: 1.08064,
        low: 1.08059
      },
      {
        timestamp: 1650001200,
        open: 1.0806,
        close: 1.08082,
        high: 1.08083,
        low: 1.0806
      },
      {
        timestamp: 1650001500,
        open: 1.08082,
        close: 1.08074,
        high: 1.08085,
        low: 1.08069
      },
      {
        timestamp: 1650001800,
        open: 1.08076,
        close: 1.08078,
        high: 1.08083,
        low: 1.08072
      },
      {
        timestamp: 1650002100,
        open: 1.08076,
        close: 1.08068,
        high: 1.08076,
        low: 1.08066
      },
      {
        timestamp: 1650002400,
        open: 1.08068,
        close: 1.08069,
        high: 1.08071,
        low: 1.08064
      },
      {
        timestamp: 1650002700,
        open: 1.08068,
        close: 1.08065,
        high: 1.0807,
        low: 1.08063
      },
      {
        timestamp: 1650003000,
        open: 1.08064,
        close: 1.08066,
        high: 1.08073,
        low: 1.08064
      },
      {
        timestamp: 1650003300,
        open: 1.08065,
        close: 1.08068,
        high: 1.08074,
        low: 1.0806
      },
      {
        timestamp: 1650003600,
        open: 1.08067,
        close: 1.08069,
        high: 1.0807,
        low: 1.08062
      },
      {
        timestamp: 1650003900,
        open: 1.08069,
        close: 1.08065,
        high: 1.0807,
        low: 1.08064
      },
      {
        timestamp: 1650004200,
        open: 1.08065,
        close: 1.0807,
        high: 1.0807,
        low: 1.08063
      },
      {
        timestamp: 1650004500,
        open: 1.08071,
        close: 1.08066,
        high: 1.08071,
        low: 1.08059
      },
      {
        timestamp: 1650004800,
        open: 1.08066,
        close: 1.08073,
        high: 1.08077,
        low: 1.08065
      },
      {
        timestamp: 1650005100,
        open: 1.08072,
        close: 1.08069,
        high: 1.08075,
        low: 1.08065
      },
      {
        timestamp: 1650005400,
        open: 1.08071,
        close: 1.0807,
        high: 1.08087,
        low: 1.0807
      },
      {
        timestamp: 1650005700,
        open: 1.08069,
        close: 1.08038,
        high: 1.08073,
        low: 1.08031
      },
      {
        timestamp: 1650006000,
        open: 1.08038,
        close: 1.0805,
        high: 1.08071,
        low: 1.08038
      },
      {
        timestamp: 1650006300,
        open: 1.08049,
        close: 1.08065,
        high: 1.08074,
        low: 1.08046
      },
      {
        timestamp: 1650006600,
        open: 1.08066,
        close: 1.08085,
        high: 1.08087,
        low: 1.08065
      },
      {
        timestamp: 1650006900,
        open: 1.08084,
        close: 1.08082,
        high: 1.08088,
        low: 1.08081
      },
      {
        timestamp: 1650007200,
        open: 1.08083,
        close: 1.08087,
        high: 1.08091,
        low: 1.08083
      },
      {
        timestamp: 1650007500,
        open: 1.08086,
        close: 1.08091,
        high: 1.08094,
        low: 1.0808
      },
      {
        timestamp: 1650007800,
        open: 1.0809,
        close: 1.08088,
        high: 1.08095,
        low: 1.08086
      },
      {
        timestamp: 1650008100,
        open: 1.08088,
        close: 1.08081,
        high: 1.08091,
        low: 1.08079
      },
      {
        timestamp: 1650008400,
        open: 1.0808,
        close: 1.08086,
        high: 1.08087,
        low: 1.08079
      },
      {
        timestamp: 1650008700,
        open: 1.08085,
        close: 1.08087,
        high: 1.08092,
        low: 1.08085
      },
      {
        timestamp: 1650009000,
        open: 1.08088,
        close: 1.08089,
        high: 1.0809,
        low: 1.08085
      },
      {
        timestamp: 1650009300,
        open: 1.08089,
        close: 1.08129,
        high: 1.08134,
        low: 1.08084
      },
      {
        timestamp: 1650009600,
        open: 1.08128,
        close: 1.0812,
        high: 1.08135,
        low: 1.08107
      },
      {
        timestamp: 1650009900,
        open: 1.08121,
        close: 1.08147,
        high: 1.08147,
        low: 1.08118
      },
      {
        timestamp: 1650010200,
        open: 1.08145,
        close: 1.08138,
        high: 1.08148,
        low: 1.08133
      },
      {
        timestamp: 1650010500,
        open: 1.08137,
        close: 1.08135,
        high: 1.08141,
        low: 1.08134
      },
      {
        timestamp: 1650010800,
        open: 1.08135,
        close: 1.08169,
        high: 1.08181,
        low: 1.08135
      },
      {
        timestamp: 1650011100,
        open: 1.08168,
        close: 1.08162,
        high: 1.08171,
        low: 1.08159
      },
      {
        timestamp: 1650011400,
        open: 1.08162,
        close: 1.08147,
        high: 1.08162,
        low: 1.08147
      },
      {
        timestamp: 1650011700,
        open: 1.08146,
        close: 1.08178,
        high: 1.08182,
        low: 1.08144
      },
      {
        timestamp: 1650012000,
        open: 1.08178,
        close: 1.0819,
        high: 1.08196,
        low: 1.08176
      },
      {
        timestamp: 1650012300,
        open: 1.08189,
        close: 1.08201,
        high: 1.08203,
        low: 1.08187
      },
      {
        timestamp: 1650012600,
        open: 1.08198,
        close: 1.08219,
        high: 1.08243,
        low: 1.08197
      },
      {
        timestamp: 1650012900,
        open: 1.08217,
        close: 1.08198,
        high: 1.08226,
        low: 1.08178
      },
      {
        timestamp: 1650013200,
        open: 1.08197,
        close: 1.08186,
        high: 1.08202,
        low: 1.0817
      },
      {
        timestamp: 1650013500,
        open: 1.08185,
        close: 1.08181,
        high: 1.08191,
        low: 1.08158
      },
      {
        timestamp: 1650013800,
        open: 1.08182,
        close: 1.08198,
        high: 1.08201,
        low: 1.08181
      },
      {
        timestamp: 1650014100,
        open: 1.08199,
        close: 1.08187,
        high: 1.08204,
        low: 1.08177
      },
      {
        timestamp: 1650014400,
        open: 1.08191,
        close: 1.08199,
        high: 1.08201,
        low: 1.08191
      },
      {
        timestamp: 1650014700,
        open: 1.08199,
        close: 1.08197,
        high: 1.082,
        low: 1.08189
      },
      {
        timestamp: 1650015000,
        open: 1.08198,
        close: 1.08197,
        high: 1.08199,
        low: 1.08194
      },
      {
        timestamp: 1650015300,
        open: 1.08195,
        close: 1.08194,
        high: 1.08199,
        low: 1.0819
      },
      {
        timestamp: 1650015600,
        open: 1.08193,
        close: 1.08174,
        high: 1.08196,
        low: 1.08167
      },
      {
        timestamp: 1650015900,
        open: 1.08175,
        close: 1.08179,
        high: 1.08184,
        low: 1.08161
      },
      {
        timestamp: 1650016200,
        open: 1.08178,
        close: 1.08177,
        high: 1.08196,
        low: 1.08162
      },
      {
        timestamp: 1650016500,
        open: 1.08173,
        close: 1.08149,
        high: 1.08201,
        low: 1.08137
      },
      {
        timestamp: 1650016800,
        open: 1.08148,
        close: 1.08148,
        high: 1.08184,
        low: 1.08132
      },
      {
        timestamp: 1650017100,
        open: 1.08147,
        close: 1.08134,
        high: 1.08185,
        low: 1.08124
      },
      {
        timestamp: 1650017400,
        open: 1.08133,
        close: 1.08157,
        high: 1.08178,
        low: 1.08129
      },
      {
        timestamp: 1650017700,
        open: 1.08156,
        close: 1.08154,
        high: 1.08164,
        low: 1.08121
      },
      {
        timestamp: 1650018000,
        open: 1.08156,
        close: 1.08143,
        high: 1.08165,
        low: 1.08131
      },
      {
        timestamp: 1650018300,
        open: 1.08143,
        close: 1.08148,
        high: 1.08172,
        low: 1.0814
      },
      {
        timestamp: 1650018600,
        open: 1.08149,
        close: 1.08142,
        high: 1.08161,
        low: 1.08128
      },
      {
        timestamp: 1650018900,
        open: 1.08141,
        close: 1.08139,
        high: 1.08151,
        low: 1.08136
      },
      {
        timestamp: 1650019200,
        open: 1.08139,
        close: 1.08134,
        high: 1.08148,
        low: 1.0813
      },
      {
        timestamp: 1650019500,
        open: 1.08133,
        close: 1.08098,
        high: 1.08134,
        low: 1.08096
      },
      {
        timestamp: 1650019800,
        open: 1.08098,
        close: 1.08092,
        high: 1.08107,
        low: 1.08092
      },
      {
        timestamp: 1650020100,
        open: 1.08092,
        close: 1.08094,
        high: 1.08099,
        low: 1.08079
      },
      {
        timestamp: 1650020400,
        open: 1.08091,
        close: 1.08089,
        high: 1.08094,
        low: 1.08087
      },
      {
        timestamp: 1650020700,
        open: 1.08088,
        close: 1.08081,
        high: 1.08088,
        low: 1.0808
      },
      {
        timestamp: 1650021000,
        open: 1.08082,
        close: 1.08091,
        high: 1.08096,
        low: 1.08082
      },
      {
        timestamp: 1650021300,
        open: 1.0809,
        close: 1.08062,
        high: 1.08093,
        low: 1.08058
      },
      {
        timestamp: 1650021600,
        open: 1.08063,
        close: 1.08081,
        high: 1.08089,
        low: 1.08061
      },
      {
        timestamp: 1650021900,
        open: 1.08079,
        close: 1.08079,
        high: 1.08082,
        low: 1.08076
      },
      {
        timestamp: 1650022200,
        open: 1.0808,
        close: 1.0808,
        high: 1.08087,
        low: 1.08062
      },
      {
        timestamp: 1650022500,
        open: 1.08079,
        close: 1.08092,
        high: 1.08098,
        low: 1.08079
      },
      {
        timestamp: 1650022800,
        open: 1.08091,
        close: 1.08102,
        high: 1.08102,
        low: 1.08091
      },
      {
        timestamp: 1650023100,
        open: 1.08102,
        close: 1.08137,
        high: 1.08138,
        low: 1.081
      },
      {
        timestamp: 1650023400,
        open: 1.08136,
        close: 1.08131,
        high: 1.08141,
        low: 1.08129
      },
      {
        timestamp: 1650023700,
        open: 1.08131,
        close: 1.08166,
        high: 1.08174,
        low: 1.08129
      },
      {
        timestamp: 1650024000,
        open: 1.08166,
        close: 1.08162,
        high: 1.08179,
        low: 1.08131
      },
      {
        timestamp: 1650024300,
        open: 1.08162,
        close: 1.08179,
        high: 1.08185,
        low: 1.08156
      },
      {
        timestamp: 1650024600,
        open: 1.0818,
        close: 1.082,
        high: 1.08204,
        low: 1.08171
      },
      {
        timestamp: 1650024900,
        open: 1.08199,
        close: 1.08178,
        high: 1.08201,
        low: 1.08166
      },
      {
        timestamp: 1650025200,
        open: 1.08177,
        close: 1.08186,
        high: 1.082,
        low: 1.08176
      },
      {
        timestamp: 1650025500,
        open: 1.08185,
        close: 1.08189,
        high: 1.08189,
        low: 1.08179
      },
      {
        timestamp: 1650025800,
        open: 1.08189,
        close: 1.08182,
        high: 1.08194,
        low: 1.08177
      },
      {
        timestamp: 1650026100,
        open: 1.08181,
        close: 1.08173,
        high: 1.08181,
        low: 1.08158
      },
      {
        timestamp: 1650026400,
        open: 1.08172,
        close: 1.08146,
        high: 1.08175,
        low: 1.08143
      },
      {
        timestamp: 1650026700,
        open: 1.08145,
        close: 1.08152,
        high: 1.08154,
        low: 1.08141
      },
      {
        timestamp: 1650027000,
        open: 1.08151,
        close: 1.08149,
        high: 1.08152,
        low: 1.08137
      },
      {
        timestamp: 1650027300,
        open: 1.08148,
        close: 1.08141,
        high: 1.08151,
        low: 1.08131
      },
      {
        timestamp: 1650027600,
        open: 1.0814,
        close: 1.08131,
        high: 1.08145,
        low: 1.08122
      },
      {
        timestamp: 1650027900,
        open: 1.08131,
        close: 1.08123,
        high: 1.08136,
        low: 1.08115
      },
      {
        timestamp: 1650028200,
        open: 1.08124,
        close: 1.08126,
        high: 1.08129,
        low: 1.08116
      },
      {
        timestamp: 1650028500,
        open: 1.08127,
        close: 1.08089,
        high: 1.08129,
        low: 1.08076
      },
      {
        timestamp: 1650028800,
        open: 1.08088,
        close: 1.08077,
        high: 1.08117,
        low: 1.08077
      },
      {
        timestamp: 1650029100,
        open: 1.08077,
        close: 1.08085,
        high: 1.08104,
        low: 1.08076
      },
      {
        timestamp: 1650029400,
        open: 1.08084,
        close: 1.08084,
        high: 1.08089,
        low: 1.0808
      },
      {
        timestamp: 1650029700,
        open: 1.08083,
        close: 1.08104,
        high: 1.0811,
        low: 1.0808
      },
      {
        timestamp: 1650030000,
        open: 1.08103,
        close: 1.08111,
        high: 1.08115,
        low: 1.08103
      },
      {
        timestamp: 1650030300,
        open: 1.08112,
        close: 1.08097,
        high: 1.08112,
        low: 1.08092
      },
      {
        timestamp: 1650030600,
        open: 1.08096,
        close: 1.08104,
        high: 1.08105,
        low: 1.08093
      },
      {
        timestamp: 1650030900,
        open: 1.08105,
        close: 1.08135,
        high: 1.08148,
        low: 1.08098
      },
      {
        timestamp: 1650031200,
        open: 1.08135,
        close: 1.08106,
        high: 1.08136,
        low: 1.08103
      },
      {
        timestamp: 1650031500,
        open: 1.08105,
        close: 1.08104,
        high: 1.08115,
        low: 1.08097
      },
      {
        timestamp: 1650031800,
        open: 1.08104,
        close: 1.08116,
        high: 1.08119,
        low: 1.08099
      },
      {
        timestamp: 1650032100,
        open: 1.08115,
        close: 1.08095,
        high: 1.08117,
        low: 1.08083
      },
      {
        timestamp: 1650032400,
        open: 1.081,
        close: 1.08102,
        high: 1.08104,
        low: 1.08096
      },
      {
        timestamp: 1650032700,
        open: 1.08102,
        close: 1.08099,
        high: 1.08103,
        low: 1.08099
      },
      {
        timestamp: 1650033000,
        open: 1.08101,
        close: 1.08107,
        high: 1.08111,
        low: 1.08099
      },
      {
        timestamp: 1650033300,
        open: 1.08107,
        close: 1.08099,
        high: 1.08109,
        low: 1.08096
      },
      {
        timestamp: 1650033600,
        open: 1.081,
        close: 1.08106,
        high: 1.08108,
        low: 1.081
      },
      {
        timestamp: 1650033900,
        open: 1.08106,
        close: 1.08098,
        high: 1.08112,
        low: 1.08095
      },
      {
        timestamp: 1650034200,
        open: 1.08097,
        close: 1.08082,
        high: 1.08109,
        low: 1.08075
      },
      {
        timestamp: 1650034500,
        open: 1.08079,
        close: 1.08091,
        high: 1.08095,
        low: 1.08074
      },
      {
        timestamp: 1650034800,
        open: 1.0809,
        close: 1.08091,
        high: 1.08097,
        low: 1.08081
      },
      {
        timestamp: 1650035100,
        open: 1.08091,
        close: 1.08097,
        high: 1.08099,
        low: 1.08084
      },
      {
        timestamp: 1650035400,
        open: 1.08096,
        close: 1.08098,
        high: 1.08098,
        low: 1.08096
      },
      {
        timestamp: 1650035700,
        open: 1.08098,
        close: 1.08087,
        high: 1.08098,
        low: 1.08087
      },
      {
        timestamp: 1650036000,
        open: 1.08093,
        close: 1.08089,
        high: 1.08093,
        low: 1.08088
      },
      {
        timestamp: 1650036300,
        open: 1.08086,
        close: 1.08072,
        high: 1.08086,
        low: 1.08071
      },
      {
        timestamp: 1650036600,
        open: 1.0809,
        close: 1.081,
        high: 1.08101,
        low: 1.08075
      },
      {
        timestamp: 1650036900,
        open: 1.08098,
        close: 1.08102,
        high: 1.08123,
        low: 1.08071
      },
      {
        timestamp: 1650037200,
        open: 1.08117,
        close: 1.08114,
        high: 1.08125,
        low: 1.08091
      },
      {
        timestamp: 1650037500,
        open: 1.08113,
        close: 1.08089,
        high: 1.08113,
        low: 1.08083
      },
      {
        timestamp: 1650037800,
        open: 1.08103,
        close: 1.08113,
        high: 1.08113,
        low: 1.08103
      },
      {
        timestamp: 1650038100,
        open: 1.08107,
        close: 1.08094,
        high: 1.08107,
        low: 1.08066
      },
      {
        timestamp: 1650038400,
        open: 1.08093,
        close: 1.08103,
        high: 1.08106,
        low: 1.08083
      },
      {
        timestamp: 1650038700,
        open: 1.081,
        close: 1.08077,
        high: 1.08109,
        low: 1.08072
      },
      {
        timestamp: 1650039000,
        open: 1.08076,
        close: 1.08081,
        high: 1.08097,
        low: 1.08072
      },
      {
        timestamp: 1650039300,
        open: 1.08081,
        close: 1.08088,
        high: 1.08092,
        low: 1.08075
      },
      {
        timestamp: 1650039600,
        open: 1.08094,
        close: 1.081,
        high: 1.08104,
        low: 1.08092
      },
      {
        timestamp: 1650039900,
        open: 1.08097,
        close: 1.08106,
        high: 1.08116,
        low: 1.08089
      },
      {
        timestamp: 1650040200,
        open: 1.08121,
        close: 1.08097,
        high: 1.08123,
        low: 1.08096
      },
      {
        timestamp: 1650040500,
        open: 1.08097,
        close: 1.08082,
        high: 1.08097,
        low: 1.08081
      },
      {
        timestamp: 1650040800,
        open: 1.08081,
        close: 1.08093,
        high: 1.08104,
        low: 1.08078
      },
      {
        timestamp: 1650041100,
        open: 1.08091,
        close: 1.08078,
        high: 1.08091,
        low: 1.08078
      },
      {
        timestamp: 1650041400,
        open: 1.08085,
        close: 1.08078,
        high: 1.08099,
        low: 1.08077
      },
      {
        timestamp: 1650041700,
        open: 1.08077,
        close: 1.0807,
        high: 1.08082,
        low: 1.0807
      },
      {
        timestamp: 1650042000,
        open: 1.08079,
        close: 1.08093,
        high: 1.08095,
        low: 1.08066
      },
      {
        timestamp: 1650042300,
        open: 1.08089,
        close: 1.0808,
        high: 1.08089,
        low: 1.08079
      },
      {
        timestamp: 1650042600,
        open: 1.08087,
        close: 1.08089,
        high: 1.08096,
        low: 1.08083
      },
      {
        timestamp: 1650042900,
        open: 1.08084,
        close: 1.08081,
        high: 1.08093,
        low: 1.08074
      },
      {
        timestamp: 1650043200,
        open: 1.08088,
        close: 1.08095,
        high: 1.08095,
        low: 1.08083
      },
      {
        timestamp: 1650043500,
        open: 1.08091,
        close: 1.08083,
        high: 1.08094,
        low: 1.0808
      },
      {
        timestamp: 1650043800,
        open: 1.08088,
        close: 1.08088,
        high: 1.08096,
        low: 1.08085
      },
      {
        timestamp: 1650044100,
        open: 1.08088,
        close: 1.08098,
        high: 1.081,
        low: 1.0808
      },
      {
        timestamp: 1650044400,
        open: 1.08096,
        close: 1.08089,
        high: 1.08102,
        low: 1.08084
      },
      {
        timestamp: 1650044700,
        open: 1.08087,
        close: 1.08106,
        high: 1.0812,
        low: 1.08086
      },
      {
        timestamp: 1650045000,
        open: 1.08108,
        close: 1.08119,
        high: 1.08132,
        low: 1.08106
      },
      {
        timestamp: 1650045300,
        open: 1.08117,
        close: 1.08107,
        high: 1.08119,
        low: 1.081
      },
      {
        timestamp: 1650045600,
        open: 1.08117,
        close: 1.08118,
        high: 1.08123,
        low: 1.08113
      },
      {
        timestamp: 1650045900,
        open: 1.08116,
        close: 1.08105,
        high: 1.08116,
        low: 1.08095
      },
      {
        timestamp: 1650046200,
        open: 1.08106,
        close: 1.08101,
        high: 1.08106,
        low: 1.08092
      },
      {
        timestamp: 1650046500,
        open: 1.08101,
        close: 1.08095,
        high: 1.08107,
        low: 1.08088
      },
      {
        timestamp: 1650046800,
        open: 1.08096,
        close: 1.08102,
        high: 1.08107,
        low: 1.08093
      },
      {
        timestamp: 1650047100,
        open: 1.08103,
        close: 1.08101,
        high: 1.08111,
        low: 1.08099
      },
      {
        timestamp: 1650047400,
        open: 1.081,
        close: 1.08105,
        high: 1.08112,
        low: 1.081
      },
      {
        timestamp: 1650047700,
        open: 1.08104,
        close: 1.08111,
        high: 1.08112,
        low: 1.08103
      },
      {
        timestamp: 1650048000,
        open: 1.08111,
        close: 1.08121,
        high: 1.08121,
        low: 1.08109
      },
      {
        timestamp: 1650048300,
        open: 1.08119,
        close: 1.08104,
        high: 1.08122,
        low: 1.08104
      },
      {
        timestamp: 1650048600,
        open: 1.08103,
        close: 1.08106,
        high: 1.08114,
        low: 1.081
      },
      {
        timestamp: 1650048900,
        open: 1.08105,
        close: 1.08108,
        high: 1.08118,
        low: 1.08104
      },
      {
        timestamp: 1650049200,
        open: 1.08107,
        close: 1.081,
        high: 1.08124,
        low: 1.08095
      },
      {
        timestamp: 1650049500,
        open: 1.08099,
        close: 1.08092,
        high: 1.08116,
        low: 1.08082
      },
      {
        timestamp: 1650049800,
        open: 1.08095,
        close: 1.08101,
        high: 1.08105,
        low: 1.08092
      },
      {
        timestamp: 1650050100,
        open: 1.08098,
        close: 1.08095,
        high: 1.08126,
        low: 1.08094
      },
      {
        timestamp: 1650050400,
        open: 1.081,
        close: 1.08076,
        high: 1.08103,
        low: 1.08076
      },
      {
        timestamp: 1650050700,
        open: 1.08075,
        close: 1.08073,
        high: 1.08077,
        low: 1.08068
      },
      {
        timestamp: 1650051000,
        open: 1.08072,
        close: 1.08081,
        high: 1.08087,
        low: 1.08072
      },
      {
        timestamp: 1650051300,
        open: 1.08082,
        close: 1.08099,
        high: 1.08104,
        low: 1.08078
      },
      {
        timestamp: 1650051600,
        open: 1.08098,
        close: 1.08097,
        high: 1.08113,
        low: 1.08093
      },
      {
        timestamp: 1650051900,
        open: 1.08103,
        close: 1.08093,
        high: 1.0811,
        low: 1.08091
      },
      {
        timestamp: 1650052200,
        open: 1.08095,
        close: 1.08108,
        high: 1.08108,
        low: 1.08087
      },
      {
        timestamp: 1650052500,
        open: 1.08107,
        close: 1.08108,
        high: 1.08111,
        low: 1.08107
      },
      {
        timestamp: 1650052800,
        open: 1.08108,
        close: 1.08109,
        high: 1.08117,
        low: 1.08095
      },
      {
        timestamp: 1650053100,
        open: 1.08107,
        close: 1.08117,
        high: 1.08127,
        low: 1.08099
      },
      {
        timestamp: 1650053400,
        open: 1.08117,
        close: 1.08122,
        high: 1.08136,
        low: 1.08116
      },
      {
        timestamp: 1650053700,
        open: 1.08122,
        close: 1.08122,
        high: 1.08127,
        low: 1.08112
      },
      {
        timestamp: 1650054000,
        open: 1.08125,
        close: 1.08127,
        high: 1.08133,
        low: 1.08121
      },
      {
        timestamp: 1650054300,
        open: 1.08127,
        close: 1.08125,
        high: 1.08128,
        low: 1.08117
      },
      {
        timestamp: 1650054600,
        open: 1.08125,
        close: 1.08127,
        high: 1.08134,
        low: 1.08124
      },
      {
        timestamp: 1650054900,
        open: 1.08131,
        close: 1.08125,
        high: 1.08142,
        low: 1.08123
      },
      {
        timestamp: 1650055200,
        open: 1.08125,
        close: 1.08145,
        high: 1.08157,
        low: 1.08125
      },
      {
        timestamp: 1650055500,
        open: 1.08143,
        close: 1.08142,
        high: 1.08159,
        low: 1.08121
      },
      {
        timestamp: 1650055800,
        open: 1.08139,
        close: 1.08176,
        high: 1.0819,
        low: 1.08139
      },
      {
        timestamp: 1650223500,
        open: 1.08122,
        close: 1.08122,
        high: 1.08122,
        low: 1.08122
      },
      {
        timestamp: 1650224100,
        open: 1.08071,
        close: 1.08074,
        high: 1.0808,
        low: 1.08071
      },
      {
        timestamp: 1650224400,
        open: 1.08077,
        close: 1.08077,
        high: 1.08077,
        low: 1.08077
      },
      {
        timestamp: 1650225000,
        open: 1.08047,
        close: 1.08021,
        high: 1.08054,
        low: 1.08018
      },
      {
        timestamp: 1650225300,
        open: 1.08023,
        close: 1.08032,
        high: 1.08077,
        low: 1.08021
      },
      {
        timestamp: 1650225600,
        open: 1.08032,
        close: 1.08061,
        high: 1.08061,
        low: 1.08032
      },
      {
        timestamp: 1650225900,
        open: 1.08058,
        close: 1.08058,
        high: 1.08058,
        low: 1.08058
      },
      {
        timestamp: 1650226200,
        open: 1.08073,
        close: 1.08085,
        high: 1.08085,
        low: 1.08059
      },
      {
        timestamp: 1650226500,
        open: 1.08095,
        close: 1.08086,
        high: 1.08104,
        low: 1.08085
      },
      {
        timestamp: 1650226800,
        open: 1.08092,
        close: 1.0805,
        high: 1.08092,
        low: 1.0805
      },
      {
        timestamp: 1650227100,
        open: 1.0805,
        close: 1.08083,
        high: 1.08093,
        low: 1.08049
      },
      {
        timestamp: 1650227400,
        open: 1.08081,
        close: 1.0808,
        high: 1.08092,
        low: 1.08079
      },
      {
        timestamp: 1650227700,
        open: 1.08083,
        close: 1.08072,
        high: 1.08083,
        low: 1.08072
      },
      {
        timestamp: 1650228300,
        open: 1.08083,
        close: 1.08083,
        high: 1.08083,
        low: 1.0808
      },
      {
        timestamp: 1650228600,
        open: 1.08086,
        close: 1.08083,
        high: 1.08093,
        low: 1.08083
      },
      {
        timestamp: 1650228900,
        open: 1.08086,
        close: 1.08093,
        high: 1.08093,
        low: 1.08086
      },
      {
        timestamp: 1650229200,
        open: 1.08096,
        close: 1.08084,
        high: 1.08096,
        low: 1.08084
      },
      {
        timestamp: 1650229500,
        open: 1.08084,
        close: 1.08085,
        high: 1.08085,
        low: 1.08084
      },
      {
        timestamp: 1650229800,
        open: 1.08089,
        close: 1.08089,
        high: 1.0809,
        low: 1.08088
      },
      {
        timestamp: 1650230400,
        open: 1.08089,
        close: 1.08089,
        high: 1.08089,
        low: 1.08084
      },
      {
        timestamp: 1650230700,
        open: 1.08089,
        close: 1.08089,
        high: 1.08091,
        low: 1.08089
      },
      {
        timestamp: 1650231000,
        open: 1.08089,
        close: 1.08089,
        high: 1.08089,
        low: 1.08089
      },
      {
        timestamp: 1650231300,
        open: 1.08089,
        close: 1.08089,
        high: 1.08089,
        low: 1.08087
      },
      {
        timestamp: 1650231600,
        open: 1.08089,
        close: 1.08091,
        high: 1.08091,
        low: 1.08089
      },
      {
        timestamp: 1650231900,
        open: 1.08093,
        close: 1.08091,
        high: 1.08099,
        low: 1.08091
      },
      {
        timestamp: 1650232500,
        open: 1.08091,
        close: 1.08098,
        high: 1.08098,
        low: 1.08091
      },
      {
        timestamp: 1650232800,
        open: 1.08098,
        close: 1.08101,
        high: 1.0811,
        low: 1.08049
      },
      {
        timestamp: 1650233100,
        open: 1.08102,
        close: 1.08113,
        high: 1.08125,
        low: 1.08102
      },
      {
        timestamp: 1650233400,
        open: 1.08114,
        close: 1.08116,
        high: 1.08121,
        low: 1.08104
      },
      {
        timestamp: 1650233700,
        open: 1.08115,
        close: 1.0807,
        high: 1.08121,
        low: 1.08069
      },
      {
        timestamp: 1650234000,
        open: 1.0807,
        close: 1.08046,
        high: 1.08086,
        low: 1.08043
      },
      {
        timestamp: 1650234300,
        open: 1.08048,
        close: 1.08084,
        high: 1.08112,
        low: 1.08045
      },
      {
        timestamp: 1650234600,
        open: 1.08084,
        close: 1.08139,
        high: 1.08153,
        low: 1.08076
      },
      {
        timestamp: 1650234900,
        open: 1.08138,
        close: 1.08138,
        high: 1.08143,
        low: 1.08118
      },
      {
        timestamp: 1650235200,
        open: 1.08137,
        close: 1.08145,
        high: 1.08149,
        low: 1.08124
      },
      {
        timestamp: 1650235500,
        open: 1.08144,
        close: 1.08164,
        high: 1.08168,
        low: 1.08133
      },
      {
        timestamp: 1650235800,
        open: 1.08163,
        close: 1.08159,
        high: 1.08194,
        low: 1.08142
      },
      {
        timestamp: 1650236100,
        open: 1.08159,
        close: 1.08214,
        high: 1.08222,
        low: 1.08152
      },
      {
        timestamp: 1650236400,
        open: 1.08213,
        close: 1.08192,
        high: 1.08213,
        low: 1.08159
      },
      {
        timestamp: 1650236700,
        open: 1.08193,
        close: 1.08181,
        high: 1.08204,
        low: 1.08174
      },
      {
        timestamp: 1650237000,
        open: 1.08183,
        close: 1.08192,
        high: 1.08202,
        low: 1.0818
      },
      {
        timestamp: 1650237300,
        open: 1.08191,
        close: 1.08181,
        high: 1.0821,
        low: 1.08175
      },
      {
        timestamp: 1650237600,
        open: 1.08182,
        close: 1.08182,
        high: 1.08189,
        low: 1.0817
      },
      {
        timestamp: 1650237900,
        open: 1.08181,
        close: 1.08214,
        high: 1.08214,
        low: 1.08181
      },
      {
        timestamp: 1650238200,
        open: 1.08214,
        close: 1.08191,
        high: 1.08224,
        low: 1.0818
      },
      {
        timestamp: 1650238500,
        open: 1.08191,
        close: 1.08179,
        high: 1.08191,
        low: 1.08177
      },
      {
        timestamp: 1650238800,
        open: 1.08179,
        close: 1.08165,
        high: 1.08188,
        low: 1.08165
      },
      {
        timestamp: 1650239100,
        open: 1.08164,
        close: 1.08147,
        high: 1.08167,
        low: 1.08134
      },
      {
        timestamp: 1650239400,
        open: 1.08147,
        close: 1.08147,
        high: 1.08158,
        low: 1.08143
      },
      {
        timestamp: 1650239700,
        open: 1.08148,
        close: 1.08149,
        high: 1.08169,
        low: 1.08142
      },
      {
        timestamp: 1650240000,
        open: 1.08149,
        close: 1.08132,
        high: 1.08166,
        low: 1.08126
      },
      {
        timestamp: 1650240300,
        open: 1.08132,
        close: 1.08131,
        high: 1.0814,
        low: 1.08124
      },
      {
        timestamp: 1650240600,
        open: 1.08131,
        close: 1.08145,
        high: 1.08152,
        low: 1.08131
      },
      {
        timestamp: 1650240900,
        open: 1.08144,
        close: 1.08082,
        high: 1.08146,
        low: 1.08078
      },
      {
        timestamp: 1650241200,
        open: 1.08083,
        close: 1.08091,
        high: 1.08091,
        low: 1.0806
      },
      {
        timestamp: 1650241500,
        open: 1.0809,
        close: 1.08059,
        high: 1.08096,
        low: 1.08051
      },
      {
        timestamp: 1650241800,
        open: 1.08058,
        close: 1.0804,
        high: 1.08064,
        low: 1.08036
      },
      {
        timestamp: 1650242100,
        open: 1.08041,
        close: 1.08035,
        high: 1.08046,
        low: 1.08026
      },
      {
        timestamp: 1650242400,
        open: 1.08035,
        close: 1.07976,
        high: 1.08045,
        low: 1.07966
      },
      {
        timestamp: 1650242700,
        open: 1.07976,
        close: 1.07992,
        high: 1.08007,
        low: 1.07969
      },
      {
        timestamp: 1650243000,
        open: 1.07992,
        close: 1.0799,
        high: 1.07994,
        low: 1.07943
      },
      {
        timestamp: 1650243300,
        open: 1.07989,
        close: 1.08018,
        high: 1.08032,
        low: 1.07988
      },
      {
        timestamp: 1650243600,
        open: 1.08018,
        close: 1.07972,
        high: 1.08021,
        low: 1.07971
      },
      {
        timestamp: 1650243900,
        open: 1.07972,
        close: 1.07987,
        high: 1.07989,
        low: 1.07965
      },
      {
        timestamp: 1650244200,
        open: 1.07987,
        close: 1.0804,
        high: 1.08055,
        low: 1.07987
      },
      {
        timestamp: 1650244500,
        open: 1.08041,
        close: 1.08033,
        high: 1.08071,
        low: 1.08031
      },
      {
        timestamp: 1650244800,
        open: 1.08033,
        close: 1.08077,
        high: 1.0808,
        low: 1.07982
      },
      {
        timestamp: 1650245100,
        open: 1.08076,
        close: 1.08098,
        high: 1.08108,
        low: 1.08053
      },
      {
        timestamp: 1650245400,
        open: 1.08099,
        close: 1.08081,
        high: 1.08111,
        low: 1.08068
      },
      {
        timestamp: 1650245700,
        open: 1.0808,
        close: 1.0807,
        high: 1.08084,
        low: 1.08065
      },
      {
        timestamp: 1650246000,
        open: 1.08069,
        close: 1.08083,
        high: 1.08089,
        low: 1.08062
      },
      {
        timestamp: 1650246300,
        open: 1.08084,
        close: 1.08126,
        high: 1.08128,
        low: 1.0808
      },
      {
        timestamp: 1650246600,
        open: 1.08126,
        close: 1.08079,
        high: 1.08126,
        low: 1.08079
      },
      {
        timestamp: 1650246900,
        open: 1.08079,
        close: 1.08075,
        high: 1.08102,
        low: 1.08068
      },
      {
        timestamp: 1650247200,
        open: 1.08075,
        close: 1.08073,
        high: 1.081,
        low: 1.08065
      },
      {
        timestamp: 1650247500,
        open: 1.08074,
        close: 1.08058,
        high: 1.08083,
        low: 1.08041
      },
      {
        timestamp: 1650247800,
        open: 1.08059,
        close: 1.08037,
        high: 1.08067,
        low: 1.08032
      },
      {
        timestamp: 1650248100,
        open: 1.08036,
        close: 1.08017,
        high: 1.08037,
        low: 1.08012
      },
      {
        timestamp: 1650248400,
        open: 1.08016,
        close: 1.08007,
        high: 1.08016,
        low: 1.07996
      },
      {
        timestamp: 1650248700,
        open: 1.08007,
        close: 1.07999,
        high: 1.08017,
        low: 1.07992
      },
      {
        timestamp: 1650249000,
        open: 1.07998,
        close: 1.07987,
        high: 1.08003,
        low: 1.0798
      },
      {
        timestamp: 1650249300,
        open: 1.07986,
        close: 1.07995,
        high: 1.07996,
        low: 1.07966
      },
      {
        timestamp: 1650249600,
        open: 1.07996,
        close: 1.07976,
        high: 1.08002,
        low: 1.07975
      },
      {
        timestamp: 1650249900,
        open: 1.07976,
        close: 1.07995,
        high: 1.07995,
        low: 1.07973
      },
      {
        timestamp: 1650250200,
        open: 1.07995,
        close: 1.07993,
        high: 1.07996,
        low: 1.07978
      },
      {
        timestamp: 1650250500,
        open: 1.07994,
        close: 1.08026,
        high: 1.08039,
        low: 1.07992
      },
      {
        timestamp: 1650250800,
        open: 1.08027,
        close: 1.08031,
        high: 1.08036,
        low: 1.08023
      },
      {
        timestamp: 1650251100,
        open: 1.08032,
        close: 1.07984,
        high: 1.08034,
        low: 1.07982
      },
      {
        timestamp: 1650251400,
        open: 1.07985,
        close: 1.07971,
        high: 1.07991,
        low: 1.07953
      },
      {
        timestamp: 1650251700,
        open: 1.07972,
        close: 1.0796,
        high: 1.07995,
        low: 1.07957
      },
      {
        timestamp: 1650252000,
        open: 1.07959,
        close: 1.07976,
        high: 1.07989,
        low: 1.07959
      },
      {
        timestamp: 1650252300,
        open: 1.07977,
        close: 1.07977,
        high: 1.07985,
        low: 1.0797
      },
      {
        timestamp: 1650252600,
        open: 1.07976,
        close: 1.07966,
        high: 1.07996,
        low: 1.07965
      },
      {
        timestamp: 1650252900,
        open: 1.07966,
        close: 1.07952,
        high: 1.07977,
        low: 1.07945
      },
      {
        timestamp: 1650253200,
        open: 1.07952,
        close: 1.07946,
        high: 1.07966,
        low: 1.07946
      },
      {
        timestamp: 1650253500,
        open: 1.07945,
        close: 1.07931,
        high: 1.07947,
        low: 1.07914
      },
      {
        timestamp: 1650253800,
        open: 1.07932,
        close: 1.07938,
        high: 1.07951,
        low: 1.07929
      },
      {
        timestamp: 1650254100,
        open: 1.07937,
        close: 1.07937,
        high: 1.0795,
        low: 1.07932
      },
      {
        timestamp: 1650254400,
        open: 1.07938,
        close: 1.07951,
        high: 1.07961,
        low: 1.07922
      },
      {
        timestamp: 1650254700,
        open: 1.07951,
        close: 1.07958,
        high: 1.0797,
        low: 1.07948
      },
      {
        timestamp: 1650255000,
        open: 1.07958,
        close: 1.0797,
        high: 1.07971,
        low: 1.07947
      },
      {
        timestamp: 1650255300,
        open: 1.0797,
        close: 1.07943,
        high: 1.07975,
        low: 1.07939
      },
      {
        timestamp: 1650255600,
        open: 1.07944,
        close: 1.0795,
        high: 1.07964,
        low: 1.07938
      },
      {
        timestamp: 1650255900,
        open: 1.0795,
        close: 1.07935,
        high: 1.0795,
        low: 1.07925
      },
      {
        timestamp: 1650256200,
        open: 1.07934,
        close: 1.07909,
        high: 1.07935,
        low: 1.07909
      },
      {
        timestamp: 1650256500,
        open: 1.07908,
        close: 1.07922,
        high: 1.07922,
        low: 1.07902
      },
      {
        timestamp: 1650256800,
        open: 1.07921,
        close: 1.07928,
        high: 1.07929,
        low: 1.07915
      },
      {
        timestamp: 1650257100,
        open: 1.07928,
        close: 1.07923,
        high: 1.07929,
        low: 1.07918
      },
      {
        timestamp: 1650257400,
        open: 1.07923,
        close: 1.07905,
        high: 1.07925,
        low: 1.07901
      },
      {
        timestamp: 1650257700,
        open: 1.07906,
        close: 1.07933,
        high: 1.07945,
        low: 1.07906
      },
      {
        timestamp: 1650258000,
        open: 1.07933,
        close: 1.0794,
        high: 1.07945,
        low: 1.07925
      },
      {
        timestamp: 1650258300,
        open: 1.0794,
        close: 1.0793,
        high: 1.07951,
        low: 1.07929
      },
      {
        timestamp: 1650258600,
        open: 1.0793,
        close: 1.0793,
        high: 1.07934,
        low: 1.07914
      },
      {
        timestamp: 1650258900,
        open: 1.0793,
        close: 1.07914,
        high: 1.07931,
        low: 1.07914
      },
      {
        timestamp: 1650259200,
        open: 1.07913,
        close: 1.07922,
        high: 1.07924,
        low: 1.07904
      },
      {
        timestamp: 1650259500,
        open: 1.07921,
        close: 1.07919,
        high: 1.07921,
        low: 1.07905
      },
      {
        timestamp: 1650259800,
        open: 1.0792,
        close: 1.07939,
        high: 1.07939,
        low: 1.07917
      },
      {
        timestamp: 1650260100,
        open: 1.07938,
        close: 1.07933,
        high: 1.0794,
        low: 1.07924
      },
      {
        timestamp: 1650260400,
        open: 1.07933,
        close: 1.07925,
        high: 1.07947,
        low: 1.07915
      },
      {
        timestamp: 1650260700,
        open: 1.07924,
        close: 1.07915,
        high: 1.07928,
        low: 1.0791
      },
      {
        timestamp: 1650261000,
        open: 1.07914,
        close: 1.0794,
        high: 1.07942,
        low: 1.07893
      },
      {
        timestamp: 1650261300,
        open: 1.0794,
        close: 1.07951,
        high: 1.07957,
        low: 1.07935
      },
      {
        timestamp: 1650261600,
        open: 1.07951,
        close: 1.0797,
        high: 1.0797,
        low: 1.07935
      },
      {
        timestamp: 1650261900,
        open: 1.07969,
        close: 1.07932,
        high: 1.07975,
        low: 1.07927
      },
      {
        timestamp: 1650262200,
        open: 1.07932,
        close: 1.07958,
        high: 1.07981,
        low: 1.07928
      },
      {
        timestamp: 1650262500,
        open: 1.07959,
        close: 1.07945,
        high: 1.07959,
        low: 1.07913
      },
      {
        timestamp: 1650262800,
        open: 1.07944,
        close: 1.07976,
        high: 1.0798,
        low: 1.07943
      },
      {
        timestamp: 1650263100,
        open: 1.07974,
        close: 1.07957,
        high: 1.07988,
        low: 1.07954
      },
      {
        timestamp: 1650263400,
        open: 1.07956,
        close: 1.07981,
        high: 1.07998,
        low: 1.07942
      },
      {
        timestamp: 1650263700,
        open: 1.07981,
        close: 1.08006,
        high: 1.08008,
        low: 1.07965
      },
      {
        timestamp: 1650264000,
        open: 1.08004,
        close: 1.07966,
        high: 1.08005,
        low: 1.07955
      },
      {
        timestamp: 1650264300,
        open: 1.07966,
        close: 1.07993,
        high: 1.07996,
        low: 1.07966
      },
      {
        timestamp: 1650264600,
        open: 1.07995,
        close: 1.07997,
        high: 1.08004,
        low: 1.07969
      },
      {
        timestamp: 1650264900,
        open: 1.07995,
        close: 1.07945,
        high: 1.07996,
        low: 1.07942
      },
      {
        timestamp: 1650265200,
        open: 1.07944,
        close: 1.07993,
        high: 1.07999,
        low: 1.07943
      },
      {
        timestamp: 1650265500,
        open: 1.07992,
        close: 1.07988,
        high: 1.07999,
        low: 1.07981
      },
      {
        timestamp: 1650265800,
        open: 1.07988,
        close: 1.08006,
        high: 1.08012,
        low: 1.07988
      },
      {
        timestamp: 1650266100,
        open: 1.08006,
        close: 1.08044,
        high: 1.08044,
        low: 1.08003
      },
      {
        timestamp: 1650266400,
        open: 1.08044,
        close: 1.08022,
        high: 1.08044,
        low: 1.08011
      },
      {
        timestamp: 1650266700,
        open: 1.08023,
        close: 1.07996,
        high: 1.08037,
        low: 1.07993
      },
      {
        timestamp: 1650267000,
        open: 1.07996,
        close: 1.07923,
        high: 1.07996,
        low: 1.07922
      },
      {
        timestamp: 1650267300,
        open: 1.07924,
        close: 1.0792,
        high: 1.0794,
        low: 1.07919
      },
      {
        timestamp: 1650267600,
        open: 1.07921,
        close: 1.07889,
        high: 1.07925,
        low: 1.07883
      },
      {
        timestamp: 1650267900,
        open: 1.07887,
        close: 1.07907,
        high: 1.07916,
        low: 1.07879
      },
      {
        timestamp: 1650268200,
        open: 1.07906,
        close: 1.07942,
        high: 1.07947,
        low: 1.07901
      },
      {
        timestamp: 1650268500,
        open: 1.07941,
        close: 1.07921,
        high: 1.07945,
        low: 1.07909
      },
      {
        timestamp: 1650268800,
        open: 1.07921,
        close: 1.07913,
        high: 1.07952,
        low: 1.07913
      },
      {
        timestamp: 1650269100,
        open: 1.07913,
        close: 1.07881,
        high: 1.07914,
        low: 1.07879
      },
      {
        timestamp: 1650269400,
        open: 1.07882,
        close: 1.07873,
        high: 1.07895,
        low: 1.07867
      },
      {
        timestamp: 1650269700,
        open: 1.07873,
        close: 1.07876,
        high: 1.07883,
        low: 1.0787
      },
      {
        timestamp: 1650270000,
        open: 1.07875,
        close: 1.07874,
        high: 1.07895,
        low: 1.07864
      },
      {
        timestamp: 1650270300,
        open: 1.07875,
        close: 1.07863,
        high: 1.0788,
        low: 1.07839
      },
      {
        timestamp: 1650270600,
        open: 1.07864,
        close: 1.07887,
        high: 1.07892,
        low: 1.0786
      },
      {
        timestamp: 1650270900,
        open: 1.07888,
        close: 1.07931,
        high: 1.07937,
        low: 1.07887
      },
      {
        timestamp: 1650271200,
        open: 1.07931,
        close: 1.07909,
        high: 1.07938,
        low: 1.07899
      },
      {
        timestamp: 1650271500,
        open: 1.07909,
        close: 1.0792,
        high: 1.07935,
        low: 1.07907
      },
      {
        timestamp: 1650271800,
        open: 1.07921,
        close: 1.07929,
        high: 1.07936,
        low: 1.0792
      },
      {
        timestamp: 1650272100,
        open: 1.0793,
        close: 1.07925,
        high: 1.07935,
        low: 1.07912
      },
      {
        timestamp: 1650272400,
        open: 1.07925,
        close: 1.07951,
        high: 1.07965,
        low: 1.07924
      },
      {
        timestamp: 1650272700,
        open: 1.07952,
        close: 1.07976,
        high: 1.07991,
        low: 1.07952
      },
      {
        timestamp: 1650273000,
        open: 1.07976,
        close: 1.07952,
        high: 1.07989,
        low: 1.07952
      },
      {
        timestamp: 1650273300,
        open: 1.07951,
        close: 1.07936,
        high: 1.07954,
        low: 1.07931
      },
      {
        timestamp: 1650273600,
        open: 1.07935,
        close: 1.07957,
        high: 1.07981,
        low: 1.07935
      },
      {
        timestamp: 1650273900,
        open: 1.07957,
        close: 1.07944,
        high: 1.0796,
        low: 1.07943
      },
      {
        timestamp: 1650274200,
        open: 1.07944,
        close: 1.07944,
        high: 1.0795,
        low: 1.07939
      },
      {
        timestamp: 1650274500,
        open: 1.07944,
        close: 1.0795,
        high: 1.07955,
        low: 1.07943
      },
      {
        timestamp: 1650274800,
        open: 1.0795,
        close: 1.07955,
        high: 1.07958,
        low: 1.07944
      },
      {
        timestamp: 1650275100,
        open: 1.07954,
        close: 1.07925,
        high: 1.07956,
        low: 1.07925
      },
      {
        timestamp: 1650275400,
        open: 1.07926,
        close: 1.07905,
        high: 1.07935,
        low: 1.07903
      },
      {
        timestamp: 1650275700,
        open: 1.07905,
        close: 1.07936,
        high: 1.0795,
        low: 1.07905
      },
      {
        timestamp: 1650276000,
        open: 1.07936,
        close: 1.07949,
        high: 1.07949,
        low: 1.07909
      },
      {
        timestamp: 1650276300,
        open: 1.07948,
        close: 1.07957,
        high: 1.0797,
        low: 1.0794
      },
      {
        timestamp: 1650276600,
        open: 1.07956,
        close: 1.07953,
        high: 1.07978,
        low: 1.07952
      },
      {
        timestamp: 1650276900,
        open: 1.07953,
        close: 1.07937,
        high: 1.07959,
        low: 1.07935
      },
      {
        timestamp: 1650277200,
        open: 1.07936,
        close: 1.0794,
        high: 1.07947,
        low: 1.07935
      },
      {
        timestamp: 1650277500,
        open: 1.07939,
        close: 1.07958,
        high: 1.07972,
        low: 1.07926
      },
      {
        timestamp: 1650277800,
        open: 1.07958,
        close: 1.0797,
        high: 1.07978,
        low: 1.07958
      },
      {
        timestamp: 1650278100,
        open: 1.07969,
        close: 1.07971,
        high: 1.07993,
        low: 1.07969
      },
      {
        timestamp: 1650278400,
        open: 1.07971,
        close: 1.07981,
        high: 1.07998,
        low: 1.07971
      },
      {
        timestamp: 1650278700,
        open: 1.07981,
        close: 1.07988,
        high: 1.08001,
        low: 1.07976
      },
      {
        timestamp: 1650279000,
        open: 1.07989,
        close: 1.07983,
        high: 1.08014,
        low: 1.07983
      },
      {
        timestamp: 1650279300,
        open: 1.07983,
        close: 1.08015,
        high: 1.08022,
        low: 1.07983
      },
      {
        timestamp: 1650279600,
        open: 1.08015,
        close: 1.08019,
        high: 1.08048,
        low: 1.08014
      },
      {
        timestamp: 1650279900,
        open: 1.08018,
        close: 1.08033,
        high: 1.08035,
        low: 1.08017
      },
      {
        timestamp: 1650280200,
        open: 1.08033,
        close: 1.08045,
        high: 1.08046,
        low: 1.08023
      },
      {
        timestamp: 1650280500,
        open: 1.08046,
        close: 1.08074,
        high: 1.08079,
        low: 1.08031
      },
      {
        timestamp: 1650280800,
        open: 1.08075,
        close: 1.08099,
        high: 1.08099,
        low: 1.08063
      },
      {
        timestamp: 1650281100,
        open: 1.081,
        close: 1.08104,
        high: 1.0811,
        low: 1.0808
      },
      {
        timestamp: 1650281400,
        open: 1.08105,
        close: 1.08067,
        high: 1.08114,
        low: 1.08066
      },
      {
        timestamp: 1650281700,
        open: 1.08066,
        close: 1.08065,
        high: 1.0807,
        low: 1.08049
      },
      {
        timestamp: 1650282000,
        open: 1.08064,
        close: 1.08073,
        high: 1.0808,
        low: 1.08053
      },
      {
        timestamp: 1650282300,
        open: 1.08074,
        close: 1.0809,
        high: 1.081,
        low: 1.08061
      },
      {
        timestamp: 1650282600,
        open: 1.0809,
        close: 1.08136,
        high: 1.08138,
        low: 1.08074
      },
      {
        timestamp: 1650282900,
        open: 1.08135,
        close: 1.08101,
        high: 1.08144,
        low: 1.08073
      },
      {
        timestamp: 1650283200,
        open: 1.08101,
        close: 1.08075,
        high: 1.08104,
        low: 1.08073
      },
      {
        timestamp: 1650283500,
        open: 1.08076,
        close: 1.08046,
        high: 1.08082,
        low: 1.08031
      },
      {
        timestamp: 1650283800,
        open: 1.08047,
        close: 1.08026,
        high: 1.08047,
        low: 1.08019
      },
      {
        timestamp: 1650284100,
        open: 1.08025,
        close: 1.08008,
        high: 1.08034,
        low: 1.07998
      },
      {
        timestamp: 1650284400,
        open: 1.08008,
        close: 1.08058,
        high: 1.08078,
        low: 1.08008
      },
      {
        timestamp: 1650284700,
        open: 1.08058,
        close: 1.08079,
        high: 1.08093,
        low: 1.08057
      },
      {
        timestamp: 1650285000,
        open: 1.0808,
        close: 1.08142,
        high: 1.08152,
        low: 1.08065
      },
      {
        timestamp: 1650285300,
        open: 1.08142,
        close: 1.0813,
        high: 1.0815,
        low: 1.08111
      },
      {
        timestamp: 1650285600,
        open: 1.0813,
        close: 1.08082,
        high: 1.0813,
        low: 1.0808
      },
      {
        timestamp: 1650285900,
        open: 1.08081,
        close: 1.08081,
        high: 1.08087,
        low: 1.08063
      },
      {
        timestamp: 1650286200,
        open: 1.08081,
        close: 1.08035,
        high: 1.08083,
        low: 1.0803
      },
      {
        timestamp: 1650286500,
        open: 1.08034,
        close: 1.08058,
        high: 1.08066,
        low: 1.08025
      },
      {
        timestamp: 1650286800,
        open: 1.08057,
        close: 1.0807,
        high: 1.08098,
        low: 1.08043
      },
      {
        timestamp: 1650287100,
        open: 1.0807,
        close: 1.08014,
        high: 1.08073,
        low: 1.08006
      },
      {
        timestamp: 1650287400,
        open: 1.08014,
        close: 1.07991,
        high: 1.08023,
        low: 1.07973
      },
      {
        timestamp: 1650287700,
        open: 1.07994,
        close: 1.08028,
        high: 1.08048,
        low: 1.07989
      },
      {
        timestamp: 1650288000,
        open: 1.08028,
        close: 1.08032,
        high: 1.08047,
        low: 1.08014
      },
      {
        timestamp: 1650288300,
        open: 1.08031,
        close: 1.08029,
        high: 1.08037,
        low: 1.07995
      },
      {
        timestamp: 1650288600,
        open: 1.0803,
        close: 1.08069,
        high: 1.08111,
        low: 1.08029
      },
      {
        timestamp: 1650288900,
        open: 1.08069,
        close: 1.08021,
        high: 1.08078,
        low: 1.08019
      },
      {
        timestamp: 1650289200,
        open: 1.0802,
        close: 1.08049,
        high: 1.08056,
        low: 1.07997
      },
      {
        timestamp: 1650289500,
        open: 1.08045,
        close: 1.08084,
        high: 1.08088,
        low: 1.08004
      },
      {
        timestamp: 1650289800,
        open: 1.08085,
        close: 1.08079,
        high: 1.08115,
        low: 1.08073
      },
      {
        timestamp: 1650290100,
        open: 1.0808,
        close: 1.08011,
        high: 1.08083,
        low: 1.07998
      },
      {
        timestamp: 1650290400,
        open: 1.08011,
        close: 1.0805,
        high: 1.08055,
        low: 1.07999
      },
      {
        timestamp: 1650290700,
        open: 1.08049,
        close: 1.07998,
        high: 1.0805,
        low: 1.07994
      },
      {
        timestamp: 1650291000,
        open: 1.07998,
        close: 1.08036,
        high: 1.08048,
        low: 1.07993
      },
      {
        timestamp: 1650291300,
        open: 1.08036,
        close: 1.08,
        high: 1.0804,
        low: 1.07994
      },
      {
        timestamp: 1650291600,
        open: 1.07999,
        close: 1.08023,
        high: 1.08023,
        low: 1.07994
      },
      {
        timestamp: 1650291900,
        open: 1.08023,
        close: 1.08003,
        high: 1.08027,
        low: 1.07993
      },
      {
        timestamp: 1650292200,
        open: 1.08004,
        close: 1.08004,
        high: 1.0801,
        low: 1.07993
      },
      {
        timestamp: 1650292500,
        open: 1.08004,
        close: 1.08005,
        high: 1.08011,
        low: 1.07988
      },
      {
        timestamp: 1650292800,
        open: 1.08004,
        close: 1.07985,
        high: 1.08007,
        low: 1.07983
      },
      {
        timestamp: 1650293100,
        open: 1.07986,
        close: 1.07989,
        high: 1.07996,
        low: 1.07974
      },
      {
        timestamp: 1650293400,
        open: 1.0799,
        close: 1.07929,
        high: 1.07991,
        low: 1.07922
      },
      {
        timestamp: 1650293700,
        open: 1.0793,
        close: 1.07933,
        high: 1.07963,
        low: 1.07916
      },
      {
        timestamp: 1650294000,
        open: 1.07933,
        close: 1.07984,
        high: 1.07984,
        low: 1.07904
      },
      {
        timestamp: 1650294300,
        open: 1.07984,
        close: 1.07961,
        high: 1.07987,
        low: 1.07955
      },
      {
        timestamp: 1650294600,
        open: 1.0796,
        close: 1.07919,
        high: 1.0797,
        low: 1.07909
      },
      {
        timestamp: 1650294900,
        open: 1.07919,
        close: 1.07917,
        high: 1.07923,
        low: 1.07905
      },
      {
        timestamp: 1650295200,
        open: 1.07918,
        close: 1.07897,
        high: 1.07928,
        low: 1.07896
      },
      {
        timestamp: 1650295500,
        open: 1.07897,
        close: 1.07878,
        high: 1.07911,
        low: 1.07872
      },
      {
        timestamp: 1650295800,
        open: 1.07878,
        close: 1.07878,
        high: 1.07913,
        low: 1.07877
      },
      {
        timestamp: 1650296100,
        open: 1.07876,
        close: 1.07894,
        high: 1.07897,
        low: 1.07874
      },
      {
        timestamp: 1650296400,
        open: 1.07893,
        close: 1.0789,
        high: 1.07895,
        low: 1.07877
      },
      {
        timestamp: 1650296700,
        open: 1.0789,
        close: 1.07871,
        high: 1.07912,
        low: 1.0787
      },
      {
        timestamp: 1650297000,
        open: 1.07872,
        close: 1.07851,
        high: 1.07875,
        low: 1.07851
      },
      {
        timestamp: 1650297300,
        open: 1.07852,
        close: 1.07843,
        high: 1.07862,
        low: 1.07838
      },
      {
        timestamp: 1650297600,
        open: 1.07845,
        close: 1.0787,
        high: 1.0787,
        low: 1.07844
      },
      {
        timestamp: 1650297900,
        open: 1.0787,
        close: 1.07805,
        high: 1.07874,
        low: 1.07804
      },
      {
        timestamp: 1650298200,
        open: 1.07806,
        close: 1.07751,
        high: 1.07809,
        low: 1.0772
      },
      {
        timestamp: 1650298500,
        open: 1.0775,
        close: 1.07744,
        high: 1.07762,
        low: 1.07736
      },
      {
        timestamp: 1650298800,
        open: 1.07743,
        close: 1.07722,
        high: 1.07754,
        low: 1.07712
      },
      {
        timestamp: 1650299100,
        open: 1.07721,
        close: 1.07735,
        high: 1.07756,
        low: 1.07719
      },
      {
        timestamp: 1650299400,
        open: 1.07736,
        close: 1.07741,
        high: 1.07752,
        low: 1.07729
      },
      {
        timestamp: 1650299700,
        open: 1.07742,
        close: 1.07741,
        high: 1.07756,
        low: 1.07737
      },
      {
        timestamp: 1650300000,
        open: 1.0774,
        close: 1.07757,
        high: 1.07762,
        low: 1.07724
      },
      {
        timestamp: 1650300300,
        open: 1.07756,
        close: 1.07745,
        high: 1.07771,
        low: 1.07739
      },
      {
        timestamp: 1650300600,
        open: 1.07744,
        close: 1.07708,
        high: 1.07752,
        low: 1.07706
      },
      {
        timestamp: 1650300900,
        open: 1.07708,
        close: 1.07735,
        high: 1.07741,
        low: 1.07706
      },
      {
        timestamp: 1650301200,
        open: 1.07736,
        close: 1.07758,
        high: 1.07762,
        low: 1.07722
      },
      {
        timestamp: 1650301500,
        open: 1.07757,
        close: 1.07762,
        high: 1.07775,
        low: 1.0775
      },
      {
        timestamp: 1650301800,
        open: 1.07762,
        close: 1.07779,
        high: 1.07785,
        low: 1.07762
      },
      {
        timestamp: 1650302100,
        open: 1.07779,
        close: 1.07766,
        high: 1.07786,
        low: 1.07758
      },
      {
        timestamp: 1650302400,
        open: 1.07766,
        close: 1.07797,
        high: 1.07798,
        low: 1.0776
      },
      {
        timestamp: 1650302700,
        open: 1.07796,
        close: 1.07795,
        high: 1.07797,
        low: 1.07773
      },
      {
        timestamp: 1650303000,
        open: 1.07796,
        close: 1.07798,
        high: 1.07817,
        low: 1.07787
      },
      {
        timestamp: 1650303300,
        open: 1.07798,
        close: 1.07806,
        high: 1.07814,
        low: 1.07794
      },
      {
        timestamp: 1650303600,
        open: 1.07806,
        close: 1.07798,
        high: 1.07807,
        low: 1.07789
      },
      {
        timestamp: 1650303900,
        open: 1.07798,
        close: 1.07827,
        high: 1.07828,
        low: 1.0779
      },
      {
        timestamp: 1650304200,
        open: 1.07827,
        close: 1.07821,
        high: 1.07831,
        low: 1.07802
      },
      {
        timestamp: 1650304500,
        open: 1.07822,
        close: 1.07837,
        high: 1.07845,
        low: 1.07822
      },
      {
        timestamp: 1650304800,
        open: 1.07837,
        close: 1.07849,
        high: 1.07859,
        low: 1.07832
      },
      {
        timestamp: 1650305100,
        open: 1.07848,
        close: 1.07847,
        high: 1.07856,
        low: 1.07841
      },
      {
        timestamp: 1650305400,
        open: 1.07848,
        close: 1.0784,
        high: 1.07854,
        low: 1.07839
      },
      {
        timestamp: 1650305700,
        open: 1.07843,
        close: 1.07858,
        high: 1.07858,
        low: 1.07833
      },
      {
        timestamp: 1650306000,
        open: 1.07857,
        close: 1.07853,
        high: 1.07867,
        low: 1.07841
      },
      {
        timestamp: 1650306300,
        open: 1.07853,
        close: 1.0785,
        high: 1.07857,
        low: 1.07835
      },
      {
        timestamp: 1650306600,
        open: 1.07851,
        close: 1.07861,
        high: 1.07865,
        low: 1.07844
      },
      {
        timestamp: 1650306900,
        open: 1.07861,
        close: 1.07866,
        high: 1.07872,
        low: 1.0786
      },
      {
        timestamp: 1650307200,
        open: 1.07865,
        close: 1.07843,
        high: 1.07867,
        low: 1.07841
      },
      {
        timestamp: 1650307500,
        open: 1.07844,
        close: 1.07844,
        high: 1.0785,
        low: 1.07834
      },
      {
        timestamp: 1650307800,
        open: 1.07844,
        close: 1.07859,
        high: 1.0786,
        low: 1.07838
      },
      {
        timestamp: 1650308100,
        open: 1.07859,
        close: 1.07848,
        high: 1.0786,
        low: 1.07837
      },
      {
        timestamp: 1650308400,
        open: 1.07848,
        close: 1.07817,
        high: 1.07849,
        low: 1.07813
      },
      {
        timestamp: 1650308700,
        open: 1.07817,
        close: 1.07845,
        high: 1.0785,
        low: 1.07815
      },
      {
        timestamp: 1650309000,
        open: 1.07844,
        close: 1.07827,
        high: 1.0785,
        low: 1.07819
      },
      {
        timestamp: 1650309300,
        open: 1.07826,
        close: 1.07802,
        high: 1.07829,
        low: 1.07801
      },
      {
        timestamp: 1650309600,
        open: 1.07801,
        close: 1.07813,
        high: 1.07816,
        low: 1.07788
      },
      {
        timestamp: 1650309900,
        open: 1.07811,
        close: 1.078,
        high: 1.07823,
        low: 1.07796
      },
      {
        timestamp: 1650310200,
        open: 1.07798,
        close: 1.07811,
        high: 1.0782,
        low: 1.07793
      },
      {
        timestamp: 1650310500,
        open: 1.0781,
        close: 1.07818,
        high: 1.07818,
        low: 1.07803
      },
      {
        timestamp: 1650310800,
        open: 1.07817,
        close: 1.07811,
        high: 1.0783,
        low: 1.07802
      },
      {
        timestamp: 1650311100,
        open: 1.0781,
        close: 1.07812,
        high: 1.07813,
        low: 1.07798
      },
      {
        timestamp: 1650311400,
        open: 1.07812,
        close: 1.07823,
        high: 1.07825,
        low: 1.07807
      },
      {
        timestamp: 1650311700,
        open: 1.07824,
        close: 1.07838,
        high: 1.07849,
        low: 1.07806
      },
      {
        timestamp: 1650312000,
        open: 1.07839,
        close: 1.07824,
        high: 1.07843,
        low: 1.07819
      },
      {
        timestamp: 1650312300,
        open: 1.07822,
        close: 1.07823,
        high: 1.07832,
        low: 1.07819
      },
      {
        timestamp: 1650312600,
        open: 1.07824,
        close: 1.07816,
        high: 1.07827,
        low: 1.07814
      },
      {
        timestamp: 1650312900,
        open: 1.07816,
        close: 1.0783,
        high: 1.07835,
        low: 1.07815
      },
      {
        timestamp: 1650313200,
        open: 1.07829,
        close: 1.07834,
        high: 1.07835,
        low: 1.07829
      },
      {
        timestamp: 1650313500,
        open: 1.07833,
        close: 1.07834,
        high: 1.07843,
        low: 1.07833
      },
      {
        timestamp: 1650313800,
        open: 1.07835,
        close: 1.07832,
        high: 1.07842,
        low: 1.07832
      },
      {
        timestamp: 1650314100,
        open: 1.07831,
        close: 1.07839,
        high: 1.0784,
        low: 1.07822
      },
      {
        timestamp: 1650314400,
        open: 1.07838,
        close: 1.07843,
        high: 1.07848,
        low: 1.07837
      },
      {
        timestamp: 1650314700,
        open: 1.07841,
        close: 1.07828,
        high: 1.07842,
        low: 1.07828
      },
      {
        timestamp: 1650315000,
        open: 1.07829,
        close: 1.0782,
        high: 1.07829,
        low: 1.07816
      },
      {
        timestamp: 1650315300,
        open: 1.07821,
        close: 1.07838,
        high: 1.07839,
        low: 1.07816
      },
      {
        timestamp: 1650315600,
        open: 1.07842,
        close: 1.07832,
        high: 1.07857,
        low: 1.07824
      },
      {
        timestamp: 1650315900,
        open: 1.07831,
        close: 1.07831,
        high: 1.07832,
        low: 1.07822
      },
      {
        timestamp: 1650316200,
        open: 1.07831,
        close: 1.07832,
        high: 1.07836,
        low: 1.0783
      },
      {
        timestamp: 1650316500,
        open: 1.07833,
        close: 1.07831,
        high: 1.07837,
        low: 1.07825
      },
      {
        timestamp: 1650316800,
        open: 1.07832,
        close: 1.07831,
        high: 1.07832,
        low: 1.07831
      },
      {
        timestamp: 1650317100,
        open: 1.07832,
        close: 1.07831,
        high: 1.07832,
        low: 1.07831
      },
      {
        timestamp: 1650317400,
        open: 1.07831,
        close: 1.07825,
        high: 1.07842,
        low: 1.07819
      },
      {
        timestamp: 1650317700,
        open: 1.07825,
        close: 1.07819,
        high: 1.07826,
        low: 1.07819
      },
      {
        timestamp: 1650318000,
        open: 1.07827,
        close: 1.07832,
        high: 1.07832,
        low: 1.0782
      },
      {
        timestamp: 1650318300,
        open: 1.07831,
        close: 1.07824,
        high: 1.07832,
        low: 1.07815
      },
      {
        timestamp: 1650318600,
        open: 1.07825,
        close: 1.07831,
        high: 1.07834,
        low: 1.07824
      },
      {
        timestamp: 1650318900,
        open: 1.07831,
        close: 1.07797,
        high: 1.07832,
        low: 1.07797
      },
      {
        timestamp: 1650319200,
        open: 1.07797,
        close: 1.07805,
        high: 1.07808,
        low: 1.07795
      },
      {
        timestamp: 1650319500,
        open: 1.07805,
        close: 1.07852,
        high: 1.07857,
        low: 1.07805
      },
      {
        timestamp: 1650319800,
        open: 1.07853,
        close: 1.07868,
        high: 1.0789,
        low: 1.07852
      },
      {
        timestamp: 1650320100,
        open: 1.07868,
        close: 1.07838,
        high: 1.07873,
        low: 1.07834
      },
      {
        timestamp: 1650320400,
        open: 1.07838,
        close: 1.07846,
        high: 1.07847,
        low: 1.0782
      },
      {
        timestamp: 1650320700,
        open: 1.07846,
        close: 1.07845,
        high: 1.07857,
        low: 1.07839
      },
      {
        timestamp: 1650321000,
        open: 1.07844,
        close: 1.07822,
        high: 1.07845,
        low: 1.0781
      },
      {
        timestamp: 1650321300,
        open: 1.07823,
        close: 1.07838,
        high: 1.07838,
        low: 1.07823
      },
      {
        timestamp: 1650321600,
        open: 1.07838,
        close: 1.07828,
        high: 1.07845,
        low: 1.07828
      },
      {
        timestamp: 1650321900,
        open: 1.07828,
        close: 1.07823,
        high: 1.07833,
        low: 1.07822
      },
      {
        timestamp: 1650322200,
        open: 1.07823,
        close: 1.07827,
        high: 1.07832,
        low: 1.07822
      },
      {
        timestamp: 1650322500,
        open: 1.07827,
        close: 1.0782,
        high: 1.07833,
        low: 1.07819
      },
      {
        timestamp: 1650322800,
        open: 1.07819,
        close: 1.07855,
        high: 1.07856,
        low: 1.07813
      },
      {
        timestamp: 1650323100,
        open: 1.07851,
        close: 1.07797,
        high: 1.07857,
        low: 1.07797
      },
      {
        timestamp: 1650323400,
        open: 1.07797,
        close: 1.0781,
        high: 1.07813,
        low: 1.07784
      },
      {
        timestamp: 1650323700,
        open: 1.0781,
        close: 1.07826,
        high: 1.07827,
        low: 1.07807
      },
      {
        timestamp: 1650324000,
        open: 1.07826,
        close: 1.07828,
        high: 1.07831,
        low: 1.07824
      },
      {
        timestamp: 1650324300,
        open: 1.07828,
        close: 1.07818,
        high: 1.07828,
        low: 1.07817
      },
      {
        timestamp: 1650324600,
        open: 1.07817,
        close: 1.07783,
        high: 1.07818,
        low: 1.07779
      },
      {
        timestamp: 1650324900,
        open: 1.07784,
        close: 1.07771,
        high: 1.07785,
        low: 1.07762
      },
      {
        timestamp: 1650325200,
        open: 1.07773,
        close: 1.07787,
        high: 1.07803,
        low: 1.07772
      },
      {
        timestamp: 1650325500,
        open: 1.07788,
        close: 1.07794,
        high: 1.07801,
        low: 1.07785
      },
      {
        timestamp: 1650325800,
        open: 1.07794,
        close: 1.07817,
        high: 1.07819,
        low: 1.07791
      },
      {
        timestamp: 1650326100,
        open: 1.07817,
        close: 1.07809,
        high: 1.07825,
        low: 1.07808
      },
      {
        timestamp: 1650326400,
        open: 1.07809,
        close: 1.0779,
        high: 1.07828,
        low: 1.0777
      },
      {
        timestamp: 1650326700,
        open: 1.07789,
        close: 1.07853,
        high: 1.07866,
        low: 1.07785
      },
      {
        timestamp: 1650327000,
        open: 1.07853,
        close: 1.07851,
        high: 1.07884,
        low: 1.07843
      },
      {
        timestamp: 1650327300,
        open: 1.07852,
        close: 1.07838,
        high: 1.07862,
        low: 1.07832
      },
      {
        timestamp: 1650327600,
        open: 1.07838,
        close: 1.07858,
        high: 1.07861,
        low: 1.07835
      },
      {
        timestamp: 1650327900,
        open: 1.07857,
        close: 1.07832,
        high: 1.07869,
        low: 1.07823
      },
      {
        timestamp: 1650328200,
        open: 1.07831,
        close: 1.07825,
        high: 1.07839,
        low: 1.07811
      },
      {
        timestamp: 1650328500,
        open: 1.07825,
        close: 1.07815,
        high: 1.07836,
        low: 1.07807
      },
      {
        timestamp: 1650328800,
        open: 1.07814,
        close: 1.07755,
        high: 1.07815,
        low: 1.07744
      },
      {
        timestamp: 1650329100,
        open: 1.07753,
        close: 1.07749,
        high: 1.07771,
        low: 1.07739
      },
      {
        timestamp: 1650329400,
        open: 1.0775,
        close: 1.07755,
        high: 1.07769,
        low: 1.07737
      },
      {
        timestamp: 1650329700,
        open: 1.07756,
        close: 1.07798,
        high: 1.078,
        low: 1.07748
      },
      {
        timestamp: 1650330000,
        open: 1.07798,
        close: 1.07824,
        high: 1.0783,
        low: 1.07791
      },
      {
        timestamp: 1650330300,
        open: 1.07824,
        close: 1.07807,
        high: 1.07834,
        low: 1.07792
      },
      {
        timestamp: 1650330600,
        open: 1.07807,
        close: 1.07805,
        high: 1.07838,
        low: 1.078
      },
      {
        timestamp: 1650330900,
        open: 1.07806,
        close: 1.07785,
        high: 1.07809,
        low: 1.07761
      },
      {
        timestamp: 1650331200,
        open: 1.07784,
        close: 1.07803,
        high: 1.07803,
        low: 1.07774
      },
      {
        timestamp: 1650331500,
        open: 1.07802,
        close: 1.07823,
        high: 1.07845,
        low: 1.07802
      },
      {
        timestamp: 1650331800,
        open: 1.07822,
        close: 1.07798,
        high: 1.07852,
        low: 1.07797
      },
      {
        timestamp: 1650332100,
        open: 1.07798,
        close: 1.07791,
        high: 1.07809,
        low: 1.07771
      },
      {
        timestamp: 1650332400,
        open: 1.07791,
        close: 1.07798,
        high: 1.0782,
        low: 1.07781
      },
      {
        timestamp: 1650332700,
        open: 1.07798,
        close: 1.078,
        high: 1.07811,
        low: 1.07784
      },
      {
        timestamp: 1650333000,
        open: 1.078,
        close: 1.07805,
        high: 1.07811,
        low: 1.07787
      },
      {
        timestamp: 1650333300,
        open: 1.07804,
        close: 1.07778,
        high: 1.07806,
        low: 1.07769
      },
      {
        timestamp: 1650333600,
        open: 1.07778,
        close: 1.07765,
        high: 1.07786,
        low: 1.07762
      },
      {
        timestamp: 1650333900,
        open: 1.07765,
        close: 1.07733,
        high: 1.07768,
        low: 1.07732
      },
      {
        timestamp: 1650334200,
        open: 1.07733,
        close: 1.07723,
        high: 1.07747,
        low: 1.07721
      },
      {
        timestamp: 1650334500,
        open: 1.07723,
        close: 1.07723,
        high: 1.07732,
        low: 1.07684
      },
      {
        timestamp: 1650334800,
        open: 1.07721,
        close: 1.07695,
        high: 1.07725,
        low: 1.07688
      },
      {
        timestamp: 1650335100,
        open: 1.07695,
        close: 1.07663,
        high: 1.07697,
        low: 1.07661
      },
      {
        timestamp: 1650335400,
        open: 1.07662,
        close: 1.07631,
        high: 1.07662,
        low: 1.07617
      },
      {
        timestamp: 1650335700,
        open: 1.07633,
        close: 1.077,
        high: 1.07723,
        low: 1.07632
      },
      {
        timestamp: 1650336000,
        open: 1.07699,
        close: 1.07685,
        high: 1.077,
        low: 1.07677
      },
      {
        timestamp: 1650336300,
        open: 1.07685,
        close: 1.0768,
        high: 1.07693,
        low: 1.07675
      },
      {
        timestamp: 1650336600,
        open: 1.07679,
        close: 1.07708,
        high: 1.07709,
        low: 1.07679
      },
      {
        timestamp: 1650336900,
        open: 1.07707,
        close: 1.07693,
        high: 1.07716,
        low: 1.07671
      },
      {
        timestamp: 1650337200,
        open: 1.07693,
        close: 1.07707,
        high: 1.07726,
        low: 1.07677
      },
      {
        timestamp: 1650337500,
        open: 1.07707,
        close: 1.07703,
        high: 1.07713,
        low: 1.07698
      },
      {
        timestamp: 1650337800,
        open: 1.07703,
        close: 1.07686,
        high: 1.07713,
        low: 1.07684
      },
      {
        timestamp: 1650338100,
        open: 1.07684,
        close: 1.07708,
        high: 1.07709,
        low: 1.0768
      },
      {
        timestamp: 1650338400,
        open: 1.07709,
        close: 1.07707,
        high: 1.07714,
        low: 1.07698
      },
      {
        timestamp: 1650338700,
        open: 1.07708,
        close: 1.07699,
        high: 1.07721,
        low: 1.07692
      },
      {
        timestamp: 1650339000,
        open: 1.07699,
        close: 1.07692,
        high: 1.07711,
        low: 1.07691
      },
      {
        timestamp: 1650339300,
        open: 1.07692,
        close: 1.07717,
        high: 1.07717,
        low: 1.07687
      },
      {
        timestamp: 1650339600,
        open: 1.07717,
        close: 1.07714,
        high: 1.07727,
        low: 1.07706
      },
      {
        timestamp: 1650339900,
        open: 1.07712,
        close: 1.07745,
        high: 1.07745,
        low: 1.07703
      },
      {
        timestamp: 1650340200,
        open: 1.07744,
        close: 1.07749,
        high: 1.07753,
        low: 1.07739
      },
      {
        timestamp: 1650340500,
        open: 1.07749,
        close: 1.07732,
        high: 1.0776,
        low: 1.07732
      },
      {
        timestamp: 1650340800,
        open: 1.07732,
        close: 1.07763,
        high: 1.07766,
        low: 1.07732
      },
      {
        timestamp: 1650341100,
        open: 1.07764,
        close: 1.07789,
        high: 1.07796,
        low: 1.07761
      },
      {
        timestamp: 1650341400,
        open: 1.07789,
        close: 1.07789,
        high: 1.07803,
        low: 1.07783
      },
      {
        timestamp: 1650341700,
        open: 1.07789,
        close: 1.07824,
        high: 1.07825,
        low: 1.07786
      },
      {
        timestamp: 1650342000,
        open: 1.07823,
        close: 1.07803,
        high: 1.07824,
        low: 1.078
      },
      {
        timestamp: 1650342300,
        open: 1.07803,
        close: 1.07774,
        high: 1.07803,
        low: 1.07764
      },
      {
        timestamp: 1650342600,
        open: 1.07774,
        close: 1.07778,
        high: 1.07783,
        low: 1.07767
      },
      {
        timestamp: 1650342900,
        open: 1.07778,
        close: 1.07757,
        high: 1.07781,
        low: 1.07756
      },
      {
        timestamp: 1650343200,
        open: 1.07757,
        close: 1.07758,
        high: 1.07762,
        low: 1.07739
      },
      {
        timestamp: 1650343500,
        open: 1.07758,
        close: 1.07737,
        high: 1.07763,
        low: 1.07733
      },
      {
        timestamp: 1650343800,
        open: 1.07734,
        close: 1.07693,
        high: 1.0775,
        low: 1.07692
      },
      {
        timestamp: 1650344100,
        open: 1.07693,
        close: 1.07729,
        high: 1.07745,
        low: 1.07681
      },
      {
        timestamp: 1650344400,
        open: 1.07728,
        close: 1.07726,
        high: 1.07741,
        low: 1.07723
      },
      {
        timestamp: 1650344700,
        open: 1.07725,
        close: 1.07704,
        high: 1.0773,
        low: 1.07686
      },
      {
        timestamp: 1650345000,
        open: 1.07703,
        close: 1.07683,
        high: 1.07705,
        low: 1.07678
      },
      {
        timestamp: 1650345300,
        open: 1.07683,
        close: 1.07716,
        high: 1.07716,
        low: 1.07673
      },
      {
        timestamp: 1650345600,
        open: 1.07717,
        close: 1.07718,
        high: 1.07732,
        low: 1.07709
      },
      {
        timestamp: 1650345900,
        open: 1.07718,
        close: 1.07724,
        high: 1.07741,
        low: 1.07718
      },
      {
        timestamp: 1650346200,
        open: 1.07725,
        close: 1.07765,
        high: 1.07766,
        low: 1.07719
      },
      {
        timestamp: 1650346500,
        open: 1.07766,
        close: 1.07772,
        high: 1.07781,
        low: 1.07751
      },
      {
        timestamp: 1650346800,
        open: 1.07771,
        close: 1.07741,
        high: 1.07773,
        low: 1.07725
      },
      {
        timestamp: 1650347100,
        open: 1.07742,
        close: 1.07749,
        high: 1.07766,
        low: 1.07727
      },
      {
        timestamp: 1650347400,
        open: 1.0775,
        close: 1.07762,
        high: 1.07775,
        low: 1.07746
      },
      {
        timestamp: 1650347700,
        open: 1.07762,
        close: 1.07752,
        high: 1.07763,
        low: 1.07733
      },
      {
        timestamp: 1650348000,
        open: 1.07751,
        close: 1.07737,
        high: 1.07752,
        low: 1.07726
      },
      {
        timestamp: 1650348300,
        open: 1.07738,
        close: 1.07726,
        high: 1.07743,
        low: 1.07681
      },
      {
        timestamp: 1650348600,
        open: 1.07725,
        close: 1.07727,
        high: 1.0774,
        low: 1.07711
      },
      {
        timestamp: 1650348900,
        open: 1.07727,
        close: 1.07735,
        high: 1.07743,
        low: 1.07693
      },
      {
        timestamp: 1650349200,
        open: 1.07736,
        close: 1.07785,
        high: 1.0781,
        low: 1.07718
      },
      {
        timestamp: 1650349500,
        open: 1.07786,
        close: 1.07795,
        high: 1.07807,
        low: 1.07779
      },
      {
        timestamp: 1650349800,
        open: 1.07795,
        close: 1.07823,
        high: 1.07857,
        low: 1.07788
      },
      {
        timestamp: 1650350100,
        open: 1.07823,
        close: 1.07825,
        high: 1.07851,
        low: 1.07818
      },
      {
        timestamp: 1650350400,
        open: 1.07824,
        close: 1.07844,
        high: 1.07846,
        low: 1.07803
      },
      {
        timestamp: 1650350700,
        open: 1.07844,
        close: 1.07831,
        high: 1.07869,
        low: 1.07829
      },
      {
        timestamp: 1650351000,
        open: 1.07831,
        close: 1.07878,
        high: 1.07885,
        low: 1.0783
      },
      {
        timestamp: 1650351300,
        open: 1.07877,
        close: 1.07834,
        high: 1.07877,
        low: 1.07833
      },
      {
        timestamp: 1650351600,
        open: 1.07834,
        close: 1.07843,
        high: 1.07886,
        low: 1.07824
      },
      {
        timestamp: 1650351900,
        open: 1.07844,
        close: 1.07833,
        high: 1.07854,
        low: 1.07824
      },
      {
        timestamp: 1650352200,
        open: 1.07833,
        close: 1.07873,
        high: 1.07884,
        low: 1.07819
      },
      {
        timestamp: 1650352500,
        open: 1.07873,
        close: 1.07852,
        high: 1.07896,
        low: 1.07848
      },
      {
        timestamp: 1650352800,
        open: 1.07853,
        close: 1.07862,
        high: 1.07873,
        low: 1.07826
      },
      {
        timestamp: 1650353100,
        open: 1.07862,
        close: 1.07914,
        high: 1.07928,
        low: 1.07845
      },
      {
        timestamp: 1650353400,
        open: 1.07914,
        close: 1.07911,
        high: 1.07936,
        low: 1.07893
      },
      {
        timestamp: 1650353700,
        open: 1.07911,
        close: 1.07894,
        high: 1.07919,
        low: 1.07884
      },
      {
        timestamp: 1650354000,
        open: 1.07894,
        close: 1.07916,
        high: 1.07935,
        low: 1.07882
      },
      {
        timestamp: 1650354300,
        open: 1.07916,
        close: 1.07894,
        high: 1.07923,
        low: 1.07888
      },
      {
        timestamp: 1650354600,
        open: 1.07894,
        close: 1.07977,
        high: 1.07986,
        low: 1.07888
      },
      {
        timestamp: 1650354900,
        open: 1.07976,
        close: 1.08024,
        high: 1.08035,
        low: 1.0797
      },
      {
        timestamp: 1650355200,
        open: 1.08025,
        close: 1.08005,
        high: 1.08031,
        low: 1.07995
      },
      {
        timestamp: 1650355500,
        open: 1.08005,
        close: 1.07998,
        high: 1.0802,
        low: 1.07993
      },
      {
        timestamp: 1650355800,
        open: 1.07998,
        close: 1.08046,
        high: 1.08055,
        low: 1.07984
      },
      {
        timestamp: 1650356100,
        open: 1.08045,
        close: 1.08075,
        high: 1.08096,
        low: 1.08026
      },
      {
        timestamp: 1650356400,
        open: 1.08076,
        close: 1.08103,
        high: 1.08105,
        low: 1.0805
      },
      {
        timestamp: 1650356700,
        open: 1.08103,
        close: 1.08075,
        high: 1.08113,
        low: 1.08062
      },
      {
        timestamp: 1650357000,
        open: 1.08076,
        close: 1.08093,
        high: 1.08153,
        low: 1.08071
      },
      {
        timestamp: 1650357300,
        open: 1.08093,
        close: 1.08087,
        high: 1.08134,
        low: 1.08068
      },
      {
        timestamp: 1650357600,
        open: 1.08088,
        close: 1.08024,
        high: 1.08092,
        low: 1.08017
      },
      {
        timestamp: 1650357900,
        open: 1.08025,
        close: 1.0795,
        high: 1.08038,
        low: 1.07946
      },
      {
        timestamp: 1650358200,
        open: 1.07951,
        close: 1.08027,
        high: 1.08027,
        low: 1.07939
      },
      {
        timestamp: 1650358500,
        open: 1.08028,
        close: 1.08116,
        high: 1.08116,
        low: 1.08028
      },
      {
        timestamp: 1650358800,
        open: 1.08116,
        close: 1.08064,
        high: 1.08122,
        low: 1.08063
      },
      {
        timestamp: 1650359100,
        open: 1.08063,
        close: 1.0805,
        high: 1.0808,
        low: 1.08041
      },
      {
        timestamp: 1650359400,
        open: 1.08049,
        close: 1.08033,
        high: 1.08063,
        low: 1.08027
      },
      {
        timestamp: 1650359700,
        open: 1.08034,
        close: 1.08032,
        high: 1.0807,
        low: 1.08028
      },
      {
        timestamp: 1650360000,
        open: 1.08033,
        close: 1.07987,
        high: 1.08036,
        low: 1.07973
      },
      {
        timestamp: 1650360300,
        open: 1.07987,
        close: 1.07976,
        high: 1.07993,
        low: 1.07944
      },
      {
        timestamp: 1650360600,
        open: 1.07975,
        close: 1.07991,
        high: 1.07994,
        low: 1.0795
      },
      {
        timestamp: 1650360900,
        open: 1.0799,
        close: 1.08013,
        high: 1.08029,
        low: 1.07989
      },
      {
        timestamp: 1650361200,
        open: 1.08013,
        close: 1.08064,
        high: 1.08071,
        low: 1.08013
      },
      {
        timestamp: 1650361500,
        open: 1.08064,
        close: 1.08058,
        high: 1.08069,
        low: 1.08054
      },
      {
        timestamp: 1650361800,
        open: 1.08057,
        close: 1.08003,
        high: 1.0806,
        low: 1.08002
      },
      {
        timestamp: 1650362100,
        open: 1.08002,
        close: 1.07991,
        high: 1.0801,
        low: 1.07959
      },
      {
        timestamp: 1650362400,
        open: 1.07991,
        close: 1.07957,
        high: 1.07991,
        low: 1.07943
      },
      {
        timestamp: 1650362700,
        open: 1.07957,
        close: 1.07928,
        high: 1.07958,
        low: 1.07923
      },
      {
        timestamp: 1650363000,
        open: 1.07927,
        close: 1.0792,
        high: 1.07938,
        low: 1.07892
      },
      {
        timestamp: 1650363300,
        open: 1.07919,
        close: 1.07895,
        high: 1.07928,
        low: 1.07895
      },
      {
        timestamp: 1650363600,
        open: 1.07896,
        close: 1.07936,
        high: 1.07936,
        low: 1.07896
      },
      {
        timestamp: 1650363900,
        open: 1.07937,
        close: 1.07944,
        high: 1.07955,
        low: 1.07933
      },
      {
        timestamp: 1650364200,
        open: 1.07943,
        close: 1.07919,
        high: 1.07949,
        low: 1.07895
      },
      {
        timestamp: 1650364500,
        open: 1.07919,
        close: 1.07913,
        high: 1.07928,
        low: 1.07908
      },
      {
        timestamp: 1650364800,
        open: 1.07913,
        close: 1.07901,
        high: 1.07932,
        low: 1.07896
      },
      {
        timestamp: 1650365100,
        open: 1.07902,
        close: 1.07884,
        high: 1.07902,
        low: 1.07852
      },
      {
        timestamp: 1650365400,
        open: 1.07885,
        close: 1.07903,
        high: 1.07915,
        low: 1.0788
      },
      {
        timestamp: 1650365700,
        open: 1.07904,
        close: 1.07946,
        high: 1.07947,
        low: 1.07901
      },
      {
        timestamp: 1650366000,
        open: 1.07944,
        close: 1.07923,
        high: 1.07951,
        low: 1.07904
      },
      {
        timestamp: 1650366300,
        open: 1.07924,
        close: 1.07905,
        high: 1.07942,
        low: 1.0789
      },
      {
        timestamp: 1650366600,
        open: 1.07904,
        close: 1.07869,
        high: 1.07904,
        low: 1.07858
      },
      {
        timestamp: 1650366900,
        open: 1.07869,
        close: 1.0788,
        high: 1.07889,
        low: 1.0786
      },
      {
        timestamp: 1650367200,
        open: 1.07879,
        close: 1.0791,
        high: 1.07913,
        low: 1.07875
      },
      {
        timestamp: 1650367500,
        open: 1.07911,
        close: 1.07929,
        high: 1.0794,
        low: 1.0791
      },
      {
        timestamp: 1650367800,
        open: 1.07929,
        close: 1.07904,
        high: 1.07945,
        low: 1.07903
      },
      {
        timestamp: 1650368100,
        open: 1.07905,
        close: 1.07887,
        high: 1.07909,
        low: 1.0787
      },
      {
        timestamp: 1650368400,
        open: 1.07889,
        close: 1.07908,
        high: 1.07919,
        low: 1.07885
      },
      {
        timestamp: 1650368700,
        open: 1.07906,
        close: 1.0795,
        high: 1.07951,
        low: 1.07906
      },
      {
        timestamp: 1650369000,
        open: 1.07949,
        close: 1.07928,
        high: 1.07974,
        low: 1.07926
      },
      {
        timestamp: 1650369300,
        open: 1.07929,
        close: 1.07964,
        high: 1.07981,
        low: 1.07915
      },
      {
        timestamp: 1650369600,
        open: 1.07964,
        close: 1.07958,
        high: 1.07975,
        low: 1.07931
      },
      {
        timestamp: 1650369900,
        open: 1.07957,
        close: 1.0802,
        high: 1.08033,
        low: 1.07953
      },
      {
        timestamp: 1650370200,
        open: 1.0802,
        close: 1.08032,
        high: 1.0805,
        low: 1.08006
      },
      {
        timestamp: 1650370500,
        open: 1.08032,
        close: 1.07988,
        high: 1.08033,
        low: 1.07987
      },
      {
        timestamp: 1650370800,
        open: 1.07988,
        close: 1.07923,
        high: 1.07998,
        low: 1.07898
      },
      {
        timestamp: 1650371100,
        open: 1.07924,
        close: 1.0799,
        high: 1.07995,
        low: 1.07923
      },
      {
        timestamp: 1650371400,
        open: 1.07991,
        close: 1.07988,
        high: 1.08016,
        low: 1.07959
      },
      {
        timestamp: 1650371700,
        open: 1.07987,
        close: 1.08,
        high: 1.0801,
        low: 1.07949
      },
      {
        timestamp: 1650372000,
        open: 1.07998,
        close: 1.07962,
        high: 1.08012,
        low: 1.07954
      },
      {
        timestamp: 1650372300,
        open: 1.07961,
        close: 1.07939,
        high: 1.07961,
        low: 1.07895
      },
      {
        timestamp: 1650372600,
        open: 1.07939,
        close: 1.07979,
        high: 1.07997,
        low: 1.07939
      },
      {
        timestamp: 1650372900,
        open: 1.07979,
        close: 1.07946,
        high: 1.07981,
        low: 1.07915
      },
      {
        timestamp: 1650373200,
        open: 1.07947,
        close: 1.0793,
        high: 1.07964,
        low: 1.07908
      },
      {
        timestamp: 1650373500,
        open: 1.0793,
        close: 1.07934,
        high: 1.07953,
        low: 1.07898
      },
      {
        timestamp: 1650373800,
        open: 1.07935,
        close: 1.07884,
        high: 1.07943,
        low: 1.07874
      },
      {
        timestamp: 1650374100,
        open: 1.07884,
        close: 1.07895,
        high: 1.0791,
        low: 1.07865
      },
      {
        timestamp: 1650374400,
        open: 1.07896,
        close: 1.07932,
        high: 1.07937,
        low: 1.07874
      },
      {
        timestamp: 1650374700,
        open: 1.07932,
        close: 1.0792,
        high: 1.07939,
        low: 1.07893
      },
      {
        timestamp: 1650375000,
        open: 1.0792,
        close: 1.07969,
        high: 1.08024,
        low: 1.07918
      },
      {
        timestamp: 1650375300,
        open: 1.07968,
        close: 1.07962,
        high: 1.08024,
        low: 1.07946
      },
      {
        timestamp: 1650375600,
        open: 1.07963,
        close: 1.07971,
        high: 1.07994,
        low: 1.07958
      },
      {
        timestamp: 1650375900,
        open: 1.0797,
        close: 1.08071,
        high: 1.08091,
        low: 1.07965
      },
      {
        timestamp: 1650376200,
        open: 1.08072,
        close: 1.08024,
        high: 1.08074,
        low: 1.08018
      },
      {
        timestamp: 1650376500,
        open: 1.08025,
        close: 1.08014,
        high: 1.08042,
        low: 1.08013
      },
      {
        timestamp: 1650376800,
        open: 1.0801,
        close: 1.0805,
        high: 1.08051,
        low: 1.07995
      },
      {
        timestamp: 1650377100,
        open: 1.08051,
        close: 1.07993,
        high: 1.08061,
        low: 1.07987
      },
      {
        timestamp: 1650377400,
        open: 1.07993,
        close: 1.08021,
        high: 1.08021,
        low: 1.07991
      },
      {
        timestamp: 1650377700,
        open: 1.08021,
        close: 1.08001,
        high: 1.08033,
        low: 1.08
      },
      {
        timestamp: 1650378000,
        open: 1.08,
        close: 1.08075,
        high: 1.08079,
        low: 1.07998
      },
      {
        timestamp: 1650378300,
        open: 1.08074,
        close: 1.08047,
        high: 1.08075,
        low: 1.08047
      },
      {
        timestamp: 1650378600,
        open: 1.08,
        close: 1.07883,
        high: 1.08002,
        low: 1.07865
      },
      {
        timestamp: 1650378900,
        open: 1.07883,
        close: 1.07892,
        high: 1.07909,
        low: 1.07875
      },
      {
        timestamp: 1650379200,
        open: 1.07892,
        close: 1.07864,
        high: 1.07893,
        low: 1.07856
      },
      {
        timestamp: 1650379500,
        open: 1.07865,
        close: 1.07882,
        high: 1.07942,
        low: 1.07864
      },
      {
        timestamp: 1650379800,
        open: 1.07883,
        close: 1.07895,
        high: 1.07954,
        low: 1.07865
      },
      {
        timestamp: 1650380100,
        open: 1.07893,
        close: 1.07862,
        high: 1.07934,
        low: 1.07857
      },
      {
        timestamp: 1650380400,
        open: 1.07862,
        close: 1.07858,
        high: 1.07881,
        low: 1.07831
      },
      {
        timestamp: 1650380700,
        open: 1.0786,
        close: 1.07875,
        high: 1.07884,
        low: 1.07853
      },
      {
        timestamp: 1650381000,
        open: 1.07874,
        close: 1.07854,
        high: 1.07874,
        low: 1.07826
      },
      {
        timestamp: 1650381300,
        open: 1.07855,
        close: 1.07954,
        high: 1.07955,
        low: 1.07845
      },
      {
        timestamp: 1650381600,
        open: 1.07953,
        close: 1.0795,
        high: 1.07967,
        low: 1.07929
      },
      {
        timestamp: 1650381900,
        open: 1.07952,
        close: 1.07944,
        high: 1.07969,
        low: 1.0793
      },
      {
        timestamp: 1650382200,
        open: 1.07943,
        close: 1.07916,
        high: 1.07958,
        low: 1.07905
      },
      {
        timestamp: 1650382500,
        open: 1.07916,
        close: 1.07916,
        high: 1.0793,
        low: 1.07893
      },
      {
        timestamp: 1650382800,
        open: 1.07915,
        close: 1.07866,
        high: 1.07917,
        low: 1.07859
      },
      {
        timestamp: 1650383100,
        open: 1.07867,
        close: 1.07856,
        high: 1.07871,
        low: 1.07848
      },
      {
        timestamp: 1650383400,
        open: 1.07855,
        close: 1.07905,
        high: 1.07931,
        low: 1.07855
      },
      {
        timestamp: 1650383700,
        open: 1.07904,
        close: 1.079,
        high: 1.0793,
        low: 1.07872
      },
      {
        timestamp: 1650384000,
        open: 1.079,
        close: 1.07857,
        high: 1.07904,
        low: 1.07854
      },
      {
        timestamp: 1650384300,
        open: 1.07856,
        close: 1.07869,
        high: 1.0788,
        low: 1.07845
      },
      {
        timestamp: 1650384600,
        open: 1.07869,
        close: 1.07909,
        high: 1.07927,
        low: 1.07869
      },
      {
        timestamp: 1650384900,
        open: 1.07909,
        close: 1.07904,
        high: 1.07928,
        low: 1.07897
      },
      {
        timestamp: 1650385200,
        open: 1.07904,
        close: 1.0789,
        high: 1.07929,
        low: 1.07886
      },
      {
        timestamp: 1650385500,
        open: 1.0789,
        close: 1.07955,
        high: 1.07959,
        low: 1.07889
      },
      {
        timestamp: 1650385800,
        open: 1.07955,
        close: 1.07966,
        high: 1.07976,
        low: 1.07944
      },
      {
        timestamp: 1650386100,
        open: 1.07966,
        close: 1.07963,
        high: 1.07976,
        low: 1.07953
      },
      {
        timestamp: 1650386400,
        open: 1.07963,
        close: 1.07965,
        high: 1.07979,
        low: 1.07958
      },
      {
        timestamp: 1650386700,
        open: 1.07965,
        close: 1.07956,
        high: 1.07984,
        low: 1.07954
      },
      {
        timestamp: 1650387000,
        open: 1.07957,
        close: 1.07964,
        high: 1.07969,
        low: 1.07954
      },
      {
        timestamp: 1650387300,
        open: 1.07963,
        close: 1.07971,
        high: 1.07988,
        low: 1.07961
      },
      {
        timestamp: 1650387600,
        open: 1.07971,
        close: 1.08,
        high: 1.08012,
        low: 1.07971
      },
      {
        timestamp: 1650387900,
        open: 1.08001,
        close: 1.08003,
        high: 1.08042,
        low: 1.08001
      },
      {
        timestamp: 1650388200,
        open: 1.08003,
        close: 1.08017,
        high: 1.08025,
        low: 1.07998
      },
      {
        timestamp: 1650388500,
        open: 1.08017,
        close: 1.08038,
        high: 1.08041,
        low: 1.08013
      },
      {
        timestamp: 1650388800,
        open: 1.08037,
        close: 1.08021,
        high: 1.08041,
        low: 1.08006
      },
      {
        timestamp: 1650389100,
        open: 1.0802,
        close: 1.07993,
        high: 1.08029,
        low: 1.07984
      },
      {
        timestamp: 1650389400,
        open: 1.07992,
        close: 1.0799,
        high: 1.08006,
        low: 1.07988
      },
      {
        timestamp: 1650389700,
        open: 1.07989,
        close: 1.07975,
        high: 1.07991,
        low: 1.07971
      },
      {
        timestamp: 1650390000,
        open: 1.07976,
        close: 1.07955,
        high: 1.07978,
        low: 1.07953
      },
      {
        timestamp: 1650390300,
        open: 1.07954,
        close: 1.07954,
        high: 1.07971,
        low: 1.07935
      },
      {
        timestamp: 1650390600,
        open: 1.07954,
        close: 1.07948,
        high: 1.07962,
        low: 1.07932
      },
      {
        timestamp: 1650390900,
        open: 1.07948,
        close: 1.07896,
        high: 1.0795,
        low: 1.07893
      },
      {
        timestamp: 1650391200,
        open: 1.07896,
        close: 1.07943,
        high: 1.07947,
        low: 1.07879
      },
      {
        timestamp: 1650391500,
        open: 1.07946,
        close: 1.07932,
        high: 1.07955,
        low: 1.07919
      },
      {
        timestamp: 1650391800,
        open: 1.07932,
        close: 1.07915,
        high: 1.07934,
        low: 1.07901
      },
      {
        timestamp: 1650392100,
        open: 1.07914,
        close: 1.07901,
        high: 1.07924,
        low: 1.07888
      },
      {
        timestamp: 1650392400,
        open: 1.07901,
        close: 1.07836,
        high: 1.07901,
        low: 1.07832
      },
      {
        timestamp: 1650392700,
        open: 1.07835,
        close: 1.07878,
        high: 1.07881,
        low: 1.07831
      },
      {
        timestamp: 1650393000,
        open: 1.07881,
        close: 1.07869,
        high: 1.07881,
        low: 1.07863
      },
      {
        timestamp: 1650393300,
        open: 1.07868,
        close: 1.07875,
        high: 1.07882,
        low: 1.07862
      },
      {
        timestamp: 1650393600,
        open: 1.07874,
        close: 1.07885,
        high: 1.07891,
        low: 1.07874
      },
      {
        timestamp: 1650393900,
        open: 1.07885,
        close: 1.0792,
        high: 1.07926,
        low: 1.07866
      },
      {
        timestamp: 1650394200,
        open: 1.07921,
        close: 1.0792,
        high: 1.07923,
        low: 1.07905
      },
      {
        timestamp: 1650394500,
        open: 1.07921,
        close: 1.07908,
        high: 1.07937,
        low: 1.07905
      },
      {
        timestamp: 1650394800,
        open: 1.07907,
        close: 1.0791,
        high: 1.07917,
        low: 1.07905
      },
      {
        timestamp: 1650395100,
        open: 1.0791,
        close: 1.07914,
        high: 1.07925,
        low: 1.07908
      },
      {
        timestamp: 1650395400,
        open: 1.07915,
        close: 1.07914,
        high: 1.07924,
        low: 1.07907
      },
      {
        timestamp: 1650395700,
        open: 1.07912,
        close: 1.07905,
        high: 1.07915,
        low: 1.07889
      },
      {
        timestamp: 1650396000,
        open: 1.07906,
        close: 1.07873,
        high: 1.07906,
        low: 1.07869
      },
      {
        timestamp: 1650396300,
        open: 1.07874,
        close: 1.07857,
        high: 1.07889,
        low: 1.07854
      },
      {
        timestamp: 1650396600,
        open: 1.07858,
        close: 1.07855,
        high: 1.07874,
        low: 1.07845
      },
      {
        timestamp: 1650396900,
        open: 1.07854,
        close: 1.07875,
        high: 1.07875,
        low: 1.07836
      },
      {
        timestamp: 1650397200,
        open: 1.07874,
        close: 1.07876,
        high: 1.0788,
        low: 1.07863
      },
      {
        timestamp: 1650397500,
        open: 1.07875,
        close: 1.07866,
        high: 1.07877,
        low: 1.07858
      },
      {
        timestamp: 1650397800,
        open: 1.07867,
        close: 1.07884,
        high: 1.07905,
        low: 1.0786
      },
      {
        timestamp: 1650398100,
        open: 1.07883,
        close: 1.07896,
        high: 1.07902,
        low: 1.07869
      },
      {
        timestamp: 1650398400,
        open: 1.07895,
        close: 1.07876,
        high: 1.07897,
        low: 1.07856
      },
      {
        timestamp: 1650398700,
        open: 1.07876,
        close: 1.07901,
        high: 1.07902,
        low: 1.07874
      },
      {
        timestamp: 1650399000,
        open: 1.079,
        close: 1.07895,
        high: 1.0791,
        low: 1.07894
      },
      {
        timestamp: 1650399300,
        open: 1.07892,
        close: 1.07902,
        high: 1.07914,
        low: 1.07888
      },
      {
        timestamp: 1650399600,
        open: 1.07902,
        close: 1.0791,
        high: 1.0791,
        low: 1.07895
      },
      {
        timestamp: 1650399900,
        open: 1.0791,
        close: 1.07911,
        high: 1.07918,
        low: 1.07906
      },
      {
        timestamp: 1650400200,
        open: 1.07911,
        close: 1.079,
        high: 1.07911,
        low: 1.07899
      },
      {
        timestamp: 1650400500,
        open: 1.079,
        close: 1.07898,
        high: 1.07904,
        low: 1.07897
      },
      {
        timestamp: 1650400800,
        open: 1.07896,
        close: 1.079,
        high: 1.07904,
        low: 1.07892
      },
      {
        timestamp: 1650401100,
        open: 1.07899,
        close: 1.07892,
        high: 1.079,
        low: 1.07881
      },
      {
        timestamp: 1650401400,
        open: 1.07894,
        close: 1.07912,
        high: 1.07914,
        low: 1.07893
      },
      {
        timestamp: 1650401700,
        open: 1.07912,
        close: 1.07883,
        high: 1.07929,
        low: 1.0788
      },
      {
        timestamp: 1650402000,
        open: 1.07883,
        close: 1.07894,
        high: 1.07903,
        low: 1.07883
      },
      {
        timestamp: 1650402300,
        open: 1.07892,
        close: 1.07882,
        high: 1.079,
        low: 1.0788
      },
      {
        timestamp: 1650402600,
        open: 1.07881,
        close: 1.07887,
        high: 1.07901,
        low: 1.07881
      },
      {
        timestamp: 1650402900,
        open: 1.07887,
        close: 1.07897,
        high: 1.07897,
        low: 1.07887
      },
      {
        timestamp: 1650403200,
        open: 1.079,
        close: 1.07902,
        high: 1.07902,
        low: 1.07897
      },
      {
        timestamp: 1650403500,
        open: 1.07897,
        close: 1.07895,
        high: 1.07899,
        low: 1.07893
      },
      {
        timestamp: 1650403800,
        open: 1.07897,
        close: 1.07897,
        high: 1.07902,
        low: 1.07895
      },
      {
        timestamp: 1650404100,
        open: 1.07899,
        close: 1.07913,
        high: 1.07935,
        low: 1.07895
      },
      {
        timestamp: 1650404400,
        open: 1.07913,
        close: 1.07917,
        high: 1.07927,
        low: 1.07909
      },
      {
        timestamp: 1650404700,
        open: 1.07917,
        close: 1.07907,
        high: 1.07933,
        low: 1.07906
      },
      {
        timestamp: 1650405000,
        open: 1.07909,
        close: 1.07913,
        high: 1.07916,
        low: 1.07905
      },
      {
        timestamp: 1650405300,
        open: 1.07913,
        close: 1.07917,
        high: 1.07917,
        low: 1.07911
      },
      {
        timestamp: 1650405600,
        open: 1.07923,
        close: 1.07893,
        high: 1.07927,
        low: 1.0789
      },
      {
        timestamp: 1650405900,
        open: 1.07892,
        close: 1.07904,
        high: 1.0791,
        low: 1.0789
      },
      {
        timestamp: 1650406200,
        open: 1.07905,
        close: 1.07892,
        high: 1.07911,
        low: 1.07892
      },
      {
        timestamp: 1650406500,
        open: 1.07891,
        close: 1.07892,
        high: 1.0791,
        low: 1.07887
      },
      {
        timestamp: 1650406800,
        open: 1.0789,
        close: 1.07889,
        high: 1.07911,
        low: 1.07881
      },
      {
        timestamp: 1650407100,
        open: 1.0789,
        close: 1.07927,
        high: 1.07927,
        low: 1.07884
      },
      {
        timestamp: 1650407400,
        open: 1.07926,
        close: 1.07911,
        high: 1.07936,
        low: 1.079
      },
      {
        timestamp: 1650407700,
        open: 1.07912,
        close: 1.07917,
        high: 1.07923,
        low: 1.0791
      },
      {
        timestamp: 1650408000,
        open: 1.07916,
        close: 1.0791,
        high: 1.07921,
        low: 1.07909
      },
      {
        timestamp: 1650408300,
        open: 1.07909,
        close: 1.07913,
        high: 1.0792,
        low: 1.07891
      },
      {
        timestamp: 1650408600,
        open: 1.07913,
        close: 1.07903,
        high: 1.07913,
        low: 1.07902
      },
      {
        timestamp: 1650408900,
        open: 1.07902,
        close: 1.07909,
        high: 1.07914,
        low: 1.079
      },
      {
        timestamp: 1650409200,
        open: 1.0791,
        close: 1.07892,
        high: 1.07914,
        low: 1.0789
      },
      {
        timestamp: 1650409500,
        open: 1.07891,
        close: 1.07894,
        high: 1.07904,
        low: 1.07888
      },
      {
        timestamp: 1650409800,
        open: 1.07893,
        close: 1.07886,
        high: 1.07896,
        low: 1.07885
      },
      {
        timestamp: 1650410100,
        open: 1.07887,
        close: 1.07908,
        high: 1.07911,
        low: 1.07886
      },
      {
        timestamp: 1650410400,
        open: 1.07908,
        close: 1.07888,
        high: 1.07929,
        low: 1.07888
      },
      {
        timestamp: 1650410700,
        open: 1.07888,
        close: 1.07891,
        high: 1.07903,
        low: 1.07888
      },
      {
        timestamp: 1650411000,
        open: 1.07891,
        close: 1.07898,
        high: 1.0791,
        low: 1.07884
      },
      {
        timestamp: 1650411300,
        open: 1.07897,
        close: 1.07898,
        high: 1.07904,
        low: 1.0788
      },
      {
        timestamp: 1650411600,
        open: 1.07897,
        close: 1.07913,
        high: 1.07917,
        low: 1.07897
      },
      {
        timestamp: 1650411900,
        open: 1.07914,
        close: 1.07908,
        high: 1.07921,
        low: 1.07907
      },
      {
        timestamp: 1650412200,
        open: 1.07907,
        close: 1.07935,
        high: 1.07946,
        low: 1.07905
      },
      {
        timestamp: 1650412500,
        open: 1.07937,
        close: 1.0795,
        high: 1.07961,
        low: 1.07937
      },
      {
        timestamp: 1650412800,
        open: 1.07952,
        close: 1.07946,
        high: 1.07978,
        low: 1.07941
      },
      {
        timestamp: 1650413100,
        open: 1.07945,
        close: 1.07949,
        high: 1.07951,
        low: 1.07922
      },
      {
        timestamp: 1650413400,
        open: 1.07948,
        close: 1.07937,
        high: 1.07964,
        low: 1.07922
      },
      {
        timestamp: 1650413700,
        open: 1.07938,
        close: 1.07928,
        high: 1.07945,
        low: 1.07911
      },
      {
        timestamp: 1650414000,
        open: 1.07928,
        close: 1.07904,
        high: 1.07928,
        low: 1.07899
      },
      {
        timestamp: 1650414300,
        open: 1.07903,
        close: 1.07899,
        high: 1.07916,
        low: 1.07883
      },
      {
        timestamp: 1650414600,
        open: 1.07899,
        close: 1.07901,
        high: 1.07903,
        low: 1.07891
      },
      {
        timestamp: 1650414900,
        open: 1.07901,
        close: 1.07899,
        high: 1.07907,
        low: 1.07879
      },
      {
        timestamp: 1650415200,
        open: 1.07899,
        close: 1.07902,
        high: 1.07905,
        low: 1.07889
      },
      {
        timestamp: 1650415500,
        open: 1.079,
        close: 1.07912,
        high: 1.07922,
        low: 1.07887
      },
      {
        timestamp: 1650415800,
        open: 1.07912,
        close: 1.07935,
        high: 1.07961,
        low: 1.07912
      },
      {
        timestamp: 1650416100,
        open: 1.07935,
        close: 1.07972,
        high: 1.07998,
        low: 1.07935
      },
      {
        timestamp: 1650416400,
        open: 1.07973,
        close: 1.07982,
        high: 1.07988,
        low: 1.07957
      },
      {
        timestamp: 1650416700,
        open: 1.07982,
        close: 1.0798,
        high: 1.08012,
        low: 1.07979
      },
      {
        timestamp: 1650417000,
        open: 1.0798,
        close: 1.07988,
        high: 1.08017,
        low: 1.07958
      },
      {
        timestamp: 1650417300,
        open: 1.07987,
        close: 1.07928,
        high: 1.07997,
        low: 1.07927
      },
      {
        timestamp: 1650417600,
        open: 1.07928,
        close: 1.07922,
        high: 1.07932,
        low: 1.07903
      },
      {
        timestamp: 1650417900,
        open: 1.07923,
        close: 1.07855,
        high: 1.07923,
        low: 1.07848
      },
      {
        timestamp: 1650418200,
        open: 1.07855,
        close: 1.0788,
        high: 1.07887,
        low: 1.07848
      },
      {
        timestamp: 1650418500,
        open: 1.07879,
        close: 1.07869,
        high: 1.07889,
        low: 1.07849
      },
      {
        timestamp: 1650418800,
        open: 1.07869,
        close: 1.07874,
        high: 1.07883,
        low: 1.0785
      },
      {
        timestamp: 1650419100,
        open: 1.07874,
        close: 1.07894,
        high: 1.07902,
        low: 1.07868
      },
      {
        timestamp: 1650419400,
        open: 1.07895,
        close: 1.07945,
        high: 1.07958,
        low: 1.07893
      },
      {
        timestamp: 1650419700,
        open: 1.07944,
        close: 1.07977,
        high: 1.07991,
        low: 1.07942
      },
      {
        timestamp: 1650420000,
        open: 1.07976,
        close: 1.07968,
        high: 1.07995,
        low: 1.0795
      },
      {
        timestamp: 1650420300,
        open: 1.07968,
        close: 1.07942,
        high: 1.07973,
        low: 1.07938
      },
      {
        timestamp: 1650420600,
        open: 1.07942,
        close: 1.07945,
        high: 1.07953,
        low: 1.07938
      },
      {
        timestamp: 1650420900,
        open: 1.07946,
        close: 1.07974,
        high: 1.07975,
        low: 1.07933
      },
      {
        timestamp: 1650421200,
        open: 1.07973,
        close: 1.07998,
        high: 1.08001,
        low: 1.07968
      },
      {
        timestamp: 1650421500,
        open: 1.07998,
        close: 1.08083,
        high: 1.08105,
        low: 1.07997
      },
      {
        timestamp: 1650421800,
        open: 1.08082,
        close: 1.0806,
        high: 1.08086,
        low: 1.08046
      },
      {
        timestamp: 1650422100,
        open: 1.0806,
        close: 1.08067,
        high: 1.08074,
        low: 1.08042
      },
      {
        timestamp: 1650422400,
        open: 1.08067,
        close: 1.08068,
        high: 1.08094,
        low: 1.08052
      },
      {
        timestamp: 1650422700,
        open: 1.08068,
        close: 1.08065,
        high: 1.08077,
        low: 1.08057
      },
      {
        timestamp: 1650423000,
        open: 1.08066,
        close: 1.08069,
        high: 1.08079,
        low: 1.08053
      },
      {
        timestamp: 1650423300,
        open: 1.08068,
        close: 1.08078,
        high: 1.08078,
        low: 1.08059
      },
      {
        timestamp: 1650423600,
        open: 1.08079,
        close: 1.08077,
        high: 1.08109,
        low: 1.08077
      },
      {
        timestamp: 1650423900,
        open: 1.08078,
        close: 1.08089,
        high: 1.08096,
        low: 1.08069
      },
      {
        timestamp: 1650424200,
        open: 1.08088,
        close: 1.08142,
        high: 1.08145,
        low: 1.08071
      },
      {
        timestamp: 1650424500,
        open: 1.08141,
        close: 1.08118,
        high: 1.08148,
        low: 1.08098
      },
      {
        timestamp: 1650424800,
        open: 1.08119,
        close: 1.08134,
        high: 1.0814,
        low: 1.08117
      },
      {
        timestamp: 1650425100,
        open: 1.08135,
        close: 1.08189,
        high: 1.082,
        low: 1.08134
      },
      {
        timestamp: 1650425400,
        open: 1.08188,
        close: 1.08165,
        high: 1.08189,
        low: 1.08153
      },
      {
        timestamp: 1650425700,
        open: 1.08164,
        close: 1.08173,
        high: 1.0819,
        low: 1.08162
      },
      {
        timestamp: 1650426000,
        open: 1.08174,
        close: 1.08221,
        high: 1.08229,
        low: 1.08163
      },
      {
        timestamp: 1650426300,
        open: 1.08221,
        close: 1.08214,
        high: 1.08236,
        low: 1.08207
      },
      {
        timestamp: 1650426600,
        open: 1.08214,
        close: 1.08169,
        high: 1.0822,
        low: 1.08152
      },
      {
        timestamp: 1650426900,
        open: 1.08169,
        close: 1.08196,
        high: 1.08196,
        low: 1.08147
      },
      {
        timestamp: 1650427200,
        open: 1.08195,
        close: 1.08198,
        high: 1.08202,
        low: 1.08159
      },
      {
        timestamp: 1650427500,
        open: 1.08199,
        close: 1.08202,
        high: 1.08219,
        low: 1.08194
      },
      {
        timestamp: 1650427800,
        open: 1.08202,
        close: 1.08198,
        high: 1.08205,
        low: 1.08177
      },
      {
        timestamp: 1650428100,
        open: 1.08199,
        close: 1.08171,
        high: 1.08203,
        low: 1.08165
      },
      {
        timestamp: 1650428400,
        open: 1.08171,
        close: 1.08182,
        high: 1.08187,
        low: 1.08156
      },
      {
        timestamp: 1650428700,
        open: 1.08182,
        close: 1.08212,
        high: 1.08215,
        low: 1.08182
      },
      {
        timestamp: 1650429000,
        open: 1.08211,
        close: 1.08222,
        high: 1.08223,
        low: 1.08196
      },
      {
        timestamp: 1650429300,
        open: 1.08223,
        close: 1.08188,
        high: 1.08229,
        low: 1.08187
      },
      {
        timestamp: 1650429600,
        open: 1.08188,
        close: 1.08206,
        high: 1.08206,
        low: 1.08188
      },
      {
        timestamp: 1650429900,
        open: 1.08205,
        close: 1.08199,
        high: 1.08219,
        low: 1.08198
      },
      {
        timestamp: 1650430200,
        open: 1.08199,
        close: 1.08185,
        high: 1.08208,
        low: 1.08181
      },
      {
        timestamp: 1650430500,
        open: 1.08185,
        close: 1.0821,
        high: 1.08211,
        low: 1.08177
      },
      {
        timestamp: 1650430800,
        open: 1.08209,
        close: 1.08169,
        high: 1.08209,
        low: 1.08167
      },
      {
        timestamp: 1650431100,
        open: 1.08169,
        close: 1.08175,
        high: 1.08195,
        low: 1.08169
      },
      {
        timestamp: 1650431400,
        open: 1.08174,
        close: 1.08163,
        high: 1.08174,
        low: 1.08148
      },
      {
        timestamp: 1650431700,
        open: 1.08163,
        close: 1.08153,
        high: 1.08165,
        low: 1.08137
      },
      {
        timestamp: 1650432000,
        open: 1.08153,
        close: 1.08176,
        high: 1.08179,
        low: 1.08148
      },
      {
        timestamp: 1650432300,
        open: 1.08174,
        close: 1.08153,
        high: 1.0819,
        low: 1.08151
      },
      {
        timestamp: 1650432600,
        open: 1.08153,
        close: 1.08138,
        high: 1.08165,
        low: 1.08137
      },
      {
        timestamp: 1650432900,
        open: 1.08137,
        close: 1.08183,
        high: 1.08185,
        low: 1.08136
      },
      {
        timestamp: 1650433200,
        open: 1.08182,
        close: 1.08188,
        high: 1.08196,
        low: 1.08179
      },
      {
        timestamp: 1650433500,
        open: 1.08188,
        close: 1.08174,
        high: 1.08195,
        low: 1.0817
      },
      {
        timestamp: 1650433800,
        open: 1.08175,
        close: 1.08166,
        high: 1.08178,
        low: 1.08152
      },
      {
        timestamp: 1650434100,
        open: 1.08166,
        close: 1.08173,
        high: 1.082,
        low: 1.08155
      },
      {
        timestamp: 1650434400,
        open: 1.08172,
        close: 1.08163,
        high: 1.08191,
        low: 1.08155
      },
      {
        timestamp: 1650434700,
        open: 1.08163,
        close: 1.08139,
        high: 1.08173,
        low: 1.08118
      },
      {
        timestamp: 1650435000,
        open: 1.0814,
        close: 1.08154,
        high: 1.08159,
        low: 1.08121
      },
      {
        timestamp: 1650435300,
        open: 1.08153,
        close: 1.0816,
        high: 1.08168,
        low: 1.08119
      },
      {
        timestamp: 1650435600,
        open: 1.08161,
        close: 1.08149,
        high: 1.08161,
        low: 1.08132
      },
      {
        timestamp: 1650435900,
        open: 1.08148,
        close: 1.0809,
        high: 1.0815,
        low: 1.08086
      },
      {
        timestamp: 1650436200,
        open: 1.08091,
        close: 1.0813,
        high: 1.0814,
        low: 1.08091
      },
      {
        timestamp: 1650436500,
        open: 1.0813,
        close: 1.08131,
        high: 1.08139,
        low: 1.08113
      },
      {
        timestamp: 1650436800,
        open: 1.08132,
        close: 1.08127,
        high: 1.08132,
        low: 1.08105
      },
      {
        timestamp: 1650437100,
        open: 1.08126,
        close: 1.08134,
        high: 1.08145,
        low: 1.08109
      },
      {
        timestamp: 1650437400,
        open: 1.08134,
        close: 1.0812,
        high: 1.08149,
        low: 1.08111
      },
      {
        timestamp: 1650437700,
        open: 1.0812,
        close: 1.08137,
        high: 1.08138,
        low: 1.08112
      },
      {
        timestamp: 1650438000,
        open: 1.08139,
        close: 1.08149,
        high: 1.08153,
        low: 1.08095
      },
      {
        timestamp: 1650438300,
        open: 1.08148,
        close: 1.08085,
        high: 1.0815,
        low: 1.08083
      },
      {
        timestamp: 1650438600,
        open: 1.08086,
        close: 1.08131,
        high: 1.08147,
        low: 1.08077
      },
      {
        timestamp: 1650438900,
        open: 1.08131,
        close: 1.08129,
        high: 1.0814,
        low: 1.08119
      },
      {
        timestamp: 1650439200,
        open: 1.08128,
        close: 1.08157,
        high: 1.08169,
        low: 1.08126
      },
      {
        timestamp: 1650439500,
        open: 1.08159,
        close: 1.08136,
        high: 1.08195,
        low: 1.08128
      },
      {
        timestamp: 1650439800,
        open: 1.08137,
        close: 1.08163,
        high: 1.08168,
        low: 1.08111
      },
      {
        timestamp: 1650440100,
        open: 1.08164,
        close: 1.08173,
        high: 1.08181,
        low: 1.08152
      },
      {
        timestamp: 1650440400,
        open: 1.08173,
        close: 1.08182,
        high: 1.08195,
        low: 1.08162
      },
      {
        timestamp: 1650440700,
        open: 1.08182,
        close: 1.08134,
        high: 1.08185,
        low: 1.08131
      },
      {
        timestamp: 1650441000,
        open: 1.08133,
        close: 1.08122,
        high: 1.08143,
        low: 1.08118
      },
      {
        timestamp: 1650441300,
        open: 1.08122,
        close: 1.081,
        high: 1.08124,
        low: 1.0809
      },
      {
        timestamp: 1650441600,
        open: 1.08101,
        close: 1.08078,
        high: 1.08102,
        low: 1.08064
      },
      {
        timestamp: 1650441900,
        open: 1.08078,
        close: 1.08079,
        high: 1.0811,
        low: 1.08065
      },
      {
        timestamp: 1650442200,
        open: 1.08079,
        close: 1.08145,
        high: 1.08146,
        low: 1.08079
      },
      {
        timestamp: 1650442500,
        open: 1.08146,
        close: 1.082,
        high: 1.0821,
        low: 1.08146
      },
      {
        timestamp: 1650442800,
        open: 1.08198,
        close: 1.08186,
        high: 1.08201,
        low: 1.08168
      },
      {
        timestamp: 1650443100,
        open: 1.08187,
        close: 1.08199,
        high: 1.08207,
        low: 1.0818
      },
      {
        timestamp: 1650443400,
        open: 1.08199,
        close: 1.08236,
        high: 1.08236,
        low: 1.08195
      },
      {
        timestamp: 1650443700,
        open: 1.08235,
        close: 1.08291,
        high: 1.08336,
        low: 1.08232
      },
      {
        timestamp: 1650444000,
        open: 1.08291,
        close: 1.08361,
        high: 1.08372,
        low: 1.08289
      },
      {
        timestamp: 1650444300,
        open: 1.08362,
        close: 1.08297,
        high: 1.0837,
        low: 1.08297
      },
      {
        timestamp: 1650444600,
        open: 1.08298,
        close: 1.08373,
        high: 1.08419,
        low: 1.08298
      },
      {
        timestamp: 1650444900,
        open: 1.08373,
        close: 1.08405,
        high: 1.08406,
        low: 1.08367
      },
      {
        timestamp: 1650445200,
        open: 1.08405,
        close: 1.08399,
        high: 1.08426,
        low: 1.08397
      },
      {
        timestamp: 1650445500,
        open: 1.08399,
        close: 1.08439,
        high: 1.08439,
        low: 1.08382
      },
      {
        timestamp: 1650445800,
        open: 1.08439,
        close: 1.08359,
        high: 1.08439,
        low: 1.08357
      },
      {
        timestamp: 1650446100,
        open: 1.08359,
        close: 1.08354,
        high: 1.08367,
        low: 1.08333
      },
      {
        timestamp: 1650446400,
        open: 1.08354,
        close: 1.08328,
        high: 1.08357,
        low: 1.08303
      },
      {
        timestamp: 1650446700,
        open: 1.08328,
        close: 1.08393,
        high: 1.08414,
        low: 1.08325
      },
      {
        timestamp: 1650447000,
        open: 1.08394,
        close: 1.08394,
        high: 1.08409,
        low: 1.08359
      },
      {
        timestamp: 1650447300,
        open: 1.08395,
        close: 1.08549,
        high: 1.0858,
        low: 1.08394
      },
      {
        timestamp: 1650447600,
        open: 1.0855,
        close: 1.08563,
        high: 1.08677,
        low: 1.08538
      },
      {
        timestamp: 1650447900,
        open: 1.08565,
        close: 1.08617,
        high: 1.08618,
        low: 1.08565
      },
      {
        timestamp: 1650448200,
        open: 1.08618,
        close: 1.08605,
        high: 1.08661,
        low: 1.08601
      },
      {
        timestamp: 1650448500,
        open: 1.08605,
        close: 1.0855,
        high: 1.08609,
        low: 1.08545
      },
      {
        timestamp: 1650448800,
        open: 1.0855,
        close: 1.08544,
        high: 1.08597,
        low: 1.08529
      },
      {
        timestamp: 1650449100,
        open: 1.08546,
        close: 1.08532,
        high: 1.08557,
        low: 1.08519
      },
      {
        timestamp: 1650449400,
        open: 1.08531,
        close: 1.08631,
        high: 1.08635,
        low: 1.08531
      },
      {
        timestamp: 1650449700,
        open: 1.0863,
        close: 1.08626,
        high: 1.08664,
        low: 1.08616
      },
      {
        timestamp: 1650450000,
        open: 1.08627,
        close: 1.08592,
        high: 1.08634,
        low: 1.08574
      },
      {
        timestamp: 1650450300,
        open: 1.08593,
        close: 1.0862,
        high: 1.08636,
        low: 1.08573
      },
      {
        timestamp: 1650450600,
        open: 1.0862,
        close: 1.08659,
        high: 1.08659,
        low: 1.08619
      },
      {
        timestamp: 1650450900,
        open: 1.08658,
        close: 1.08607,
        high: 1.08659,
        low: 1.08607
      },
      {
        timestamp: 1650451200,
        open: 1.08608,
        close: 1.08558,
        high: 1.08619,
        low: 1.08549
      },
      {
        timestamp: 1650451500,
        open: 1.08558,
        close: 1.08596,
        high: 1.08602,
        low: 1.08557
      },
      {
        timestamp: 1650451800,
        open: 1.08596,
        close: 1.08636,
        high: 1.0864,
        low: 1.08573
      },
      {
        timestamp: 1650452100,
        open: 1.08636,
        close: 1.08594,
        high: 1.08645,
        low: 1.08594
      },
      {
        timestamp: 1650452400,
        open: 1.08594,
        close: 1.08575,
        high: 1.08598,
        low: 1.08539
      },
      {
        timestamp: 1650452700,
        open: 1.08574,
        close: 1.08496,
        high: 1.08577,
        low: 1.08489
      },
      {
        timestamp: 1650453000,
        open: 1.08496,
        close: 1.08454,
        high: 1.08498,
        low: 1.08448
      },
      {
        timestamp: 1650453300,
        open: 1.08454,
        close: 1.08461,
        high: 1.08487,
        low: 1.0845
      },
      {
        timestamp: 1650453600,
        open: 1.08462,
        close: 1.08434,
        high: 1.08464,
        low: 1.08404
      },
      {
        timestamp: 1650453900,
        open: 1.08435,
        close: 1.08434,
        high: 1.08446,
        low: 1.08413
      },
      {
        timestamp: 1650454200,
        open: 1.08435,
        close: 1.08458,
        high: 1.08461,
        low: 1.08435
      },
      {
        timestamp: 1650454500,
        open: 1.08458,
        close: 1.08465,
        high: 1.08468,
        low: 1.08441
      },
      {
        timestamp: 1650454800,
        open: 1.08464,
        close: 1.08434,
        high: 1.08476,
        low: 1.08424
      },
      {
        timestamp: 1650455100,
        open: 1.08433,
        close: 1.084,
        high: 1.08445,
        low: 1.08395
      },
      {
        timestamp: 1650455400,
        open: 1.084,
        close: 1.08357,
        high: 1.0842,
        low: 1.08341
      },
      {
        timestamp: 1650455700,
        open: 1.08357,
        close: 1.08285,
        high: 1.08363,
        low: 1.08257
      },
      {
        timestamp: 1650456000,
        open: 1.08284,
        close: 1.0831,
        high: 1.08322,
        low: 1.08272
      },
      {
        timestamp: 1650456300,
        open: 1.08309,
        close: 1.08314,
        high: 1.08324,
        low: 1.08303
      },
      {
        timestamp: 1650456600,
        open: 1.08316,
        close: 1.08301,
        high: 1.08327,
        low: 1.08293
      },
      {
        timestamp: 1650456900,
        open: 1.08302,
        close: 1.08294,
        high: 1.08315,
        low: 1.0829
      },
      {
        timestamp: 1650457200,
        open: 1.08294,
        close: 1.0828,
        high: 1.08315,
        low: 1.08268
      },
      {
        timestamp: 1650457500,
        open: 1.0828,
        close: 1.0833,
        high: 1.08331,
        low: 1.08276
      },
      {
        timestamp: 1650457800,
        open: 1.08329,
        close: 1.08225,
        high: 1.08337,
        low: 1.08219
      },
      {
        timestamp: 1650458100,
        open: 1.08225,
        close: 1.08283,
        high: 1.08294,
        low: 1.08213
      },
      {
        timestamp: 1650458400,
        open: 1.08284,
        close: 1.08268,
        high: 1.08308,
        low: 1.08261
      },
      {
        timestamp: 1650458700,
        open: 1.08268,
        close: 1.08269,
        high: 1.08276,
        low: 1.0824
      },
      {
        timestamp: 1650459000,
        open: 1.0827,
        close: 1.0827,
        high: 1.08292,
        low: 1.0823
      },
      {
        timestamp: 1650459300,
        open: 1.08269,
        close: 1.08298,
        high: 1.08315,
        low: 1.08254
      },
      {
        timestamp: 1650459600,
        open: 1.08297,
        close: 1.08348,
        high: 1.08372,
        low: 1.08273
      },
      {
        timestamp: 1650459900,
        open: 1.0835,
        close: 1.08264,
        high: 1.0835,
        low: 1.08226
      },
      {
        timestamp: 1650460200,
        open: 1.08264,
        close: 1.08338,
        high: 1.08388,
        low: 1.08261
      },
      {
        timestamp: 1650460500,
        open: 1.08341,
        close: 1.08303,
        high: 1.08342,
        low: 1.08268
      },
      {
        timestamp: 1650460800,
        open: 1.08304,
        close: 1.08329,
        high: 1.0834,
        low: 1.08269
      },
      {
        timestamp: 1650461100,
        open: 1.08329,
        close: 1.0839,
        high: 1.08395,
        low: 1.08328
      },
      {
        timestamp: 1650461400,
        open: 1.08389,
        close: 1.08375,
        high: 1.08394,
        low: 1.08363
      },
      {
        timestamp: 1650461700,
        open: 1.08375,
        close: 1.08371,
        high: 1.08398,
        low: 1.08367
      },
      {
        timestamp: 1650462000,
        open: 1.0837,
        close: 1.08351,
        high: 1.08376,
        low: 1.08345
      },
      {
        timestamp: 1650462300,
        open: 1.0835,
        close: 1.08342,
        high: 1.08375,
        low: 1.0832
      },
      {
        timestamp: 1650462600,
        open: 1.08341,
        close: 1.08356,
        high: 1.08376,
        low: 1.08317
      },
      {
        timestamp: 1650462900,
        open: 1.08356,
        close: 1.08355,
        high: 1.08357,
        low: 1.08324
      },
      {
        timestamp: 1650463200,
        open: 1.08354,
        close: 1.08413,
        high: 1.08413,
        low: 1.0834
      },
      {
        timestamp: 1650463500,
        open: 1.08413,
        close: 1.08509,
        high: 1.08525,
        low: 1.08413
      },
      {
        timestamp: 1650463800,
        open: 1.08509,
        close: 1.08559,
        high: 1.08571,
        low: 1.085
      },
      {
        timestamp: 1650464100,
        open: 1.08559,
        close: 1.08601,
        high: 1.08604,
        low: 1.08552
      },
      {
        timestamp: 1650464400,
        open: 1.08601,
        close: 1.08614,
        high: 1.08614,
        low: 1.08556
      },
      {
        timestamp: 1650464700,
        open: 1.08613,
        close: 1.08619,
        high: 1.08622,
        low: 1.0859
      },
      {
        timestamp: 1650465000,
        open: 1.08618,
        close: 1.0864,
        high: 1.08642,
        low: 1.08615
      },
      {
        timestamp: 1650465300,
        open: 1.08641,
        close: 1.08639,
        high: 1.08648,
        low: 1.08633
      },
      {
        timestamp: 1650465600,
        open: 1.08639,
        close: 1.08633,
        high: 1.08646,
        low: 1.08631
      },
      {
        timestamp: 1650465900,
        open: 1.0851,
        close: 1.08548,
        high: 1.08561,
        low: 1.08507
      },
      {
        timestamp: 1650466200,
        open: 1.08548,
        close: 1.08594,
        high: 1.08596,
        low: 1.08547
      },
      {
        timestamp: 1650466500,
        open: 1.08593,
        close: 1.08596,
        high: 1.08626,
        low: 1.08573
      },
      {
        timestamp: 1650466800,
        open: 1.08596,
        close: 1.08659,
        high: 1.0866,
        low: 1.08593
      },
      {
        timestamp: 1650467100,
        open: 1.08659,
        close: 1.08665,
        high: 1.08669,
        low: 1.08649
      },
      {
        timestamp: 1650467400,
        open: 1.08664,
        close: 1.08628,
        high: 1.0867,
        low: 1.08603
      },
      {
        timestamp: 1650467700,
        open: 1.08628,
        close: 1.08553,
        high: 1.08639,
        low: 1.08546
      },
      {
        timestamp: 1650468000,
        open: 1.08553,
        close: 1.08558,
        high: 1.08566,
        low: 1.08535
      },
      {
        timestamp: 1650468300,
        open: 1.08557,
        close: 1.08582,
        high: 1.08589,
        low: 1.08538
      },
      {
        timestamp: 1650468600,
        open: 1.08581,
        close: 1.08519,
        high: 1.08581,
        low: 1.08515
      },
      {
        timestamp: 1650468900,
        open: 1.08519,
        close: 1.08498,
        high: 1.08525,
        low: 1.08473
      },
      {
        timestamp: 1650469200,
        open: 1.08498,
        close: 1.08529,
        high: 1.08536,
        low: 1.08497
      },
      {
        timestamp: 1650469500,
        open: 1.08529,
        close: 1.08565,
        high: 1.08591,
        low: 1.08529
      },
      {
        timestamp: 1650469800,
        open: 1.08566,
        close: 1.08593,
        high: 1.08598,
        low: 1.08562
      },
      {
        timestamp: 1650470100,
        open: 1.08592,
        close: 1.08554,
        high: 1.08616,
        low: 1.08553
      },
      {
        timestamp: 1650470400,
        open: 1.08554,
        close: 1.08549,
        high: 1.08575,
        low: 1.08548
      },
      {
        timestamp: 1650470700,
        open: 1.08549,
        close: 1.08538,
        high: 1.08572,
        low: 1.08537
      },
      {
        timestamp: 1650471000,
        open: 1.08539,
        close: 1.08578,
        high: 1.08579,
        low: 1.08535
      },
      {
        timestamp: 1650471300,
        open: 1.08577,
        close: 1.08582,
        high: 1.08606,
        low: 1.08551
      },
      {
        timestamp: 1650471600,
        open: 1.08583,
        close: 1.08557,
        high: 1.08597,
        low: 1.08556
      },
      {
        timestamp: 1650471900,
        open: 1.08556,
        close: 1.0857,
        high: 1.08573,
        low: 1.08535
      },
      {
        timestamp: 1650472200,
        open: 1.0857,
        close: 1.08589,
        high: 1.08592,
        low: 1.08548
      },
      {
        timestamp: 1650472500,
        open: 1.08588,
        close: 1.08589,
        high: 1.08597,
        low: 1.08575
      },
      {
        timestamp: 1650472800,
        open: 1.08588,
        close: 1.08549,
        high: 1.08596,
        low: 1.08545
      },
      {
        timestamp: 1650473100,
        open: 1.08548,
        close: 1.08527,
        high: 1.08563,
        low: 1.08527
      },
      {
        timestamp: 1650473400,
        open: 1.08527,
        close: 1.0848,
        high: 1.0853,
        low: 1.08479
      },
      {
        timestamp: 1650473700,
        open: 1.0848,
        close: 1.08436,
        high: 1.08489,
        low: 1.08415
      },
      {
        timestamp: 1650474000,
        open: 1.08435,
        close: 1.08448,
        high: 1.08463,
        low: 1.08418
      },
      {
        timestamp: 1650474300,
        open: 1.08449,
        close: 1.08447,
        high: 1.08488,
        low: 1.08438
      },
      {
        timestamp: 1650474600,
        open: 1.08446,
        close: 1.08448,
        high: 1.08472,
        low: 1.08441
      },
      {
        timestamp: 1650474900,
        open: 1.08447,
        close: 1.08422,
        high: 1.0846,
        low: 1.08405
      },
      {
        timestamp: 1650475200,
        open: 1.08424,
        close: 1.08464,
        high: 1.08467,
        low: 1.08419
      },
      {
        timestamp: 1650475500,
        open: 1.08465,
        close: 1.08475,
        high: 1.08499,
        low: 1.08462
      },
      {
        timestamp: 1650475800,
        open: 1.08475,
        close: 1.0848,
        high: 1.08491,
        low: 1.08462
      },
      {
        timestamp: 1650476100,
        open: 1.08481,
        close: 1.0845,
        high: 1.08482,
        low: 1.08447
      },
      {
        timestamp: 1650476400,
        open: 1.08449,
        close: 1.08489,
        high: 1.08513,
        low: 1.08447
      },
      {
        timestamp: 1650476700,
        open: 1.08488,
        close: 1.08482,
        high: 1.08507,
        low: 1.08482
      },
      {
        timestamp: 1650477000,
        open: 1.08483,
        close: 1.08488,
        high: 1.08497,
        low: 1.08479
      },
      {
        timestamp: 1650477300,
        open: 1.08488,
        close: 1.08524,
        high: 1.08532,
        low: 1.08488
      },
      {
        timestamp: 1650477600,
        open: 1.08524,
        close: 1.08556,
        high: 1.0858,
        low: 1.08517
      },
      {
        timestamp: 1650477900,
        open: 1.08556,
        close: 1.0856,
        high: 1.08565,
        low: 1.08538
      },
      {
        timestamp: 1650478200,
        open: 1.0856,
        close: 1.08552,
        high: 1.0858,
        low: 1.08551
      },
      {
        timestamp: 1650478500,
        open: 1.08551,
        close: 1.08521,
        high: 1.08558,
        low: 1.0852
      },
      {
        timestamp: 1650478800,
        open: 1.0852,
        close: 1.08527,
        high: 1.08544,
        low: 1.0851
      },
      {
        timestamp: 1650479100,
        open: 1.08527,
        close: 1.08518,
        high: 1.08529,
        low: 1.08502
      },
      {
        timestamp: 1650479400,
        open: 1.08519,
        close: 1.08478,
        high: 1.08527,
        low: 1.08477
      },
      {
        timestamp: 1650479700,
        open: 1.08479,
        close: 1.08469,
        high: 1.08481,
        low: 1.08454
      },
      {
        timestamp: 1650480000,
        open: 1.08468,
        close: 1.08439,
        high: 1.08468,
        low: 1.0841
      },
      {
        timestamp: 1650480300,
        open: 1.0844,
        close: 1.08432,
        high: 1.08451,
        low: 1.08415
      },
      {
        timestamp: 1650480600,
        open: 1.08432,
        close: 1.08434,
        high: 1.08464,
        low: 1.08428
      },
      {
        timestamp: 1650480900,
        open: 1.08432,
        close: 1.08425,
        high: 1.08442,
        low: 1.08407
      },
      {
        timestamp: 1650481200,
        open: 1.08424,
        close: 1.08434,
        high: 1.08458,
        low: 1.08414
      },
      {
        timestamp: 1650481500,
        open: 1.08435,
        close: 1.08424,
        high: 1.08452,
        low: 1.08419
      },
      {
        timestamp: 1650481800,
        open: 1.08424,
        close: 1.08463,
        high: 1.08465,
        low: 1.08422
      },
      {
        timestamp: 1650482100,
        open: 1.08462,
        close: 1.08455,
        high: 1.08469,
        low: 1.08451
      },
      {
        timestamp: 1650482400,
        open: 1.08454,
        close: 1.08488,
        high: 1.08496,
        low: 1.08454
      },
      {
        timestamp: 1650482700,
        open: 1.08488,
        close: 1.08505,
        high: 1.08519,
        low: 1.08484
      },
      {
        timestamp: 1650483000,
        open: 1.08506,
        close: 1.08485,
        high: 1.08511,
        low: 1.0848
      },
      {
        timestamp: 1650483300,
        open: 1.08485,
        close: 1.08481,
        high: 1.08486,
        low: 1.08464
      },
      {
        timestamp: 1650483600,
        open: 1.08481,
        close: 1.08515,
        high: 1.08516,
        low: 1.08481
      },
      {
        timestamp: 1650483900,
        open: 1.08515,
        close: 1.08535,
        high: 1.08538,
        low: 1.08495
      },
      {
        timestamp: 1650484200,
        open: 1.08536,
        close: 1.08514,
        high: 1.08544,
        low: 1.08513
      },
      {
        timestamp: 1650484500,
        open: 1.08514,
        close: 1.0854,
        high: 1.08553,
        low: 1.0851
      },
      {
        timestamp: 1650484800,
        open: 1.08541,
        close: 1.08529,
        high: 1.08552,
        low: 1.08527
      },
      {
        timestamp: 1650485100,
        open: 1.08529,
        close: 1.08524,
        high: 1.08535,
        low: 1.08524
      },
      {
        timestamp: 1650485400,
        open: 1.08523,
        close: 1.08523,
        high: 1.08529,
        low: 1.08522
      },
      {
        timestamp: 1650485700,
        open: 1.08523,
        close: 1.08519,
        high: 1.08529,
        low: 1.08513
      },
      {
        timestamp: 1650486000,
        open: 1.08519,
        close: 1.0851,
        high: 1.08522,
        low: 1.08509
      },
      {
        timestamp: 1650486300,
        open: 1.0851,
        close: 1.08501,
        high: 1.0851,
        low: 1.08499
      },
      {
        timestamp: 1650486600,
        open: 1.08502,
        close: 1.08493,
        high: 1.08502,
        low: 1.08489
      },
      {
        timestamp: 1650486900,
        open: 1.08493,
        close: 1.08489,
        high: 1.08497,
        low: 1.08485
      },
      {
        timestamp: 1650487200,
        open: 1.08489,
        close: 1.08502,
        high: 1.08502,
        low: 1.08487
      },
      {
        timestamp: 1650487500,
        open: 1.08501,
        close: 1.08536,
        high: 1.08541,
        low: 1.08494
      },
      {
        timestamp: 1650487800,
        open: 1.08535,
        close: 1.08528,
        high: 1.08544,
        low: 1.08525
      },
      {
        timestamp: 1650488100,
        open: 1.0853,
        close: 1.08552,
        high: 1.08563,
        low: 1.08526
      },
      {
        timestamp: 1650488400,
        open: 1.08559,
        close: 1.08515,
        high: 1.08596,
        low: 1.08507
      },
      {
        timestamp: 1650488700,
        open: 1.08517,
        close: 1.08511,
        high: 1.08519,
        low: 1.08507
      },
      {
        timestamp: 1650489000,
        open: 1.0851,
        close: 1.08512,
        high: 1.08514,
        low: 1.08504
      },
      {
        timestamp: 1650489300,
        open: 1.08514,
        close: 1.08517,
        high: 1.08518,
        low: 1.08514
      },
      {
        timestamp: 1650489600,
        open: 1.08517,
        close: 1.08516,
        high: 1.08519,
        low: 1.08511
      },
      {
        timestamp: 1650489900,
        open: 1.08514,
        close: 1.08519,
        high: 1.08519,
        low: 1.08514
      },
      {
        timestamp: 1650490200,
        open: 1.08519,
        close: 1.08518,
        high: 1.08519,
        low: 1.08517
      },
      {
        timestamp: 1650490500,
        open: 1.08517,
        close: 1.08515,
        high: 1.08518,
        low: 1.08515
      },
      {
        timestamp: 1650490800,
        open: 1.08514,
        close: 1.08516,
        high: 1.08516,
        low: 1.08514
      },
      {
        timestamp: 1650491100,
        open: 1.08516,
        close: 1.08515,
        high: 1.08517,
        low: 1.08514
      },
      {
        timestamp: 1650491400,
        open: 1.08517,
        close: 1.08517,
        high: 1.08517,
        low: 1.08513
      },
      {
        timestamp: 1650491700,
        open: 1.08517,
        close: 1.0853,
        high: 1.0853,
        low: 1.08514
      },
      {
        timestamp: 1650492000,
        open: 1.08533,
        close: 1.0852,
        high: 1.08566,
        low: 1.08507
      },
      {
        timestamp: 1650492300,
        open: 1.08521,
        close: 1.08499,
        high: 1.08522,
        low: 1.08498
      },
      {
        timestamp: 1650492600,
        open: 1.08498,
        close: 1.08493,
        high: 1.08513,
        low: 1.08492
      },
      {
        timestamp: 1650492900,
        open: 1.08492,
        close: 1.08472,
        high: 1.08494,
        low: 1.08471
      },
      {
        timestamp: 1650493200,
        open: 1.08473,
        close: 1.08472,
        high: 1.08484,
        low: 1.08467
      },
      {
        timestamp: 1650493500,
        open: 1.08472,
        close: 1.08483,
        high: 1.08493,
        low: 1.08472
      },
      {
        timestamp: 1650493800,
        open: 1.08481,
        close: 1.08508,
        high: 1.0851,
        low: 1.08479
      },
      {
        timestamp: 1650494100,
        open: 1.08508,
        close: 1.08507,
        high: 1.08512,
        low: 1.08506
      },
      {
        timestamp: 1650494400,
        open: 1.08508,
        close: 1.08545,
        high: 1.08546,
        low: 1.08506
      },
      {
        timestamp: 1650494700,
        open: 1.08545,
        close: 1.08525,
        high: 1.08548,
        low: 1.08517
      },
      {
        timestamp: 1650495000,
        open: 1.08523,
        close: 1.08529,
        high: 1.08544,
        low: 1.08518
      },
      {
        timestamp: 1650495300,
        open: 1.08528,
        close: 1.08537,
        high: 1.08548,
        low: 1.08518
      },
      {
        timestamp: 1650495600,
        open: 1.08538,
        close: 1.08545,
        high: 1.08556,
        low: 1.08535
      },
      {
        timestamp: 1650495900,
        open: 1.08544,
        close: 1.08537,
        high: 1.08544,
        low: 1.08532
      },
      {
        timestamp: 1650496200,
        open: 1.08537,
        close: 1.08529,
        high: 1.08546,
        low: 1.08524
      },
      {
        timestamp: 1650496500,
        open: 1.08528,
        close: 1.0853,
        high: 1.08534,
        low: 1.08512
      },
      {
        timestamp: 1650496800,
        open: 1.0853,
        close: 1.08541,
        high: 1.08543,
        low: 1.08515
      },
      {
        timestamp: 1650497100,
        open: 1.0854,
        close: 1.08526,
        high: 1.08542,
        low: 1.08518
      },
      {
        timestamp: 1650497400,
        open: 1.08527,
        close: 1.08523,
        high: 1.08528,
        low: 1.08515
      },
      {
        timestamp: 1650497700,
        open: 1.08521,
        close: 1.08504,
        high: 1.08521,
        low: 1.08492
      },
      {
        timestamp: 1650498000,
        open: 1.08504,
        close: 1.08501,
        high: 1.08505,
        low: 1.08496
      },
      {
        timestamp: 1650498300,
        open: 1.08501,
        close: 1.08482,
        high: 1.08501,
        low: 1.08478
      },
      {
        timestamp: 1650498600,
        open: 1.08482,
        close: 1.08491,
        high: 1.08503,
        low: 1.08482
      },
      {
        timestamp: 1650498900,
        open: 1.08491,
        close: 1.08502,
        high: 1.08505,
        low: 1.08487
      },
      {
        timestamp: 1650499200,
        open: 1.085,
        close: 1.0849,
        high: 1.08514,
        low: 1.08484
      },
      {
        timestamp: 1650499500,
        open: 1.0849,
        close: 1.08485,
        high: 1.08512,
        low: 1.08474
      },
      {
        timestamp: 1650499800,
        open: 1.08486,
        close: 1.08453,
        high: 1.08489,
        low: 1.08452
      },
      {
        timestamp: 1650500100,
        open: 1.08452,
        close: 1.08441,
        high: 1.08458,
        low: 1.08423
      },
      {
        timestamp: 1650500400,
        open: 1.08442,
        close: 1.08437,
        high: 1.08449,
        low: 1.08422
      },
      {
        timestamp: 1650500700,
        open: 1.08437,
        close: 1.08422,
        high: 1.08438,
        low: 1.08406
      },
      {
        timestamp: 1650501000,
        open: 1.08422,
        close: 1.08407,
        high: 1.0843,
        low: 1.08392
      },
      {
        timestamp: 1650501300,
        open: 1.08408,
        close: 1.08396,
        high: 1.08413,
        low: 1.0839
      },
      {
        timestamp: 1650501600,
        open: 1.08397,
        close: 1.08401,
        high: 1.08415,
        low: 1.08397
      },
      {
        timestamp: 1650501900,
        open: 1.08401,
        close: 1.08381,
        high: 1.08424,
        low: 1.08381
      },
      {
        timestamp: 1650502200,
        open: 1.08382,
        close: 1.08393,
        high: 1.08395,
        low: 1.08333
      },
      {
        timestamp: 1650502500,
        open: 1.08392,
        close: 1.08365,
        high: 1.08399,
        low: 1.08318
      },
      {
        timestamp: 1650502800,
        open: 1.08365,
        close: 1.08378,
        high: 1.08389,
        low: 1.08358
      },
      {
        timestamp: 1650503100,
        open: 1.08377,
        close: 1.08396,
        high: 1.08396,
        low: 1.08367
      },
      {
        timestamp: 1650503400,
        open: 1.08397,
        close: 1.08406,
        high: 1.08414,
        low: 1.08378
      },
      {
        timestamp: 1650503700,
        open: 1.08405,
        close: 1.08426,
        high: 1.08445,
        low: 1.08403
      },
      {
        timestamp: 1650504000,
        open: 1.08425,
        close: 1.08416,
        high: 1.08443,
        low: 1.084
      },
      {
        timestamp: 1650504300,
        open: 1.08417,
        close: 1.0841,
        high: 1.08417,
        low: 1.08398
      },
      {
        timestamp: 1650504600,
        open: 1.08409,
        close: 1.08366,
        high: 1.08416,
        low: 1.08354
      },
      {
        timestamp: 1650504900,
        open: 1.08367,
        close: 1.08389,
        high: 1.08406,
        low: 1.08367
      },
      {
        timestamp: 1650505200,
        open: 1.08388,
        close: 1.08382,
        high: 1.08397,
        low: 1.08368
      },
      {
        timestamp: 1650505500,
        open: 1.08383,
        close: 1.08409,
        high: 1.0841,
        low: 1.08379
      },
      {
        timestamp: 1650505800,
        open: 1.08409,
        close: 1.08366,
        high: 1.08419,
        low: 1.08359
      },
      {
        timestamp: 1650506100,
        open: 1.08365,
        close: 1.08385,
        high: 1.08389,
        low: 1.08364
      },
      {
        timestamp: 1650506400,
        open: 1.08385,
        close: 1.0833,
        high: 1.08386,
        low: 1.08322
      },
      {
        timestamp: 1650506700,
        open: 1.0833,
        close: 1.08328,
        high: 1.08339,
        low: 1.08326
      },
      {
        timestamp: 1650507000,
        open: 1.08328,
        close: 1.08313,
        high: 1.08333,
        low: 1.08305
      },
      {
        timestamp: 1650507300,
        open: 1.08313,
        close: 1.08345,
        high: 1.08349,
        low: 1.08313
      },
      {
        timestamp: 1650507600,
        open: 1.08344,
        close: 1.08337,
        high: 1.08369,
        low: 1.08336
      },
      {
        timestamp: 1650507900,
        open: 1.08337,
        close: 1.08329,
        high: 1.08347,
        low: 1.08327
      },
      {
        timestamp: 1650508200,
        open: 1.08329,
        close: 1.08326,
        high: 1.08339,
        low: 1.08326
      },
      {
        timestamp: 1650508500,
        open: 1.08326,
        close: 1.08321,
        high: 1.08339,
        low: 1.08321
      },
      {
        timestamp: 1650508800,
        open: 1.08321,
        close: 1.08334,
        high: 1.08335,
        low: 1.08315
      },
      {
        timestamp: 1650509100,
        open: 1.08333,
        close: 1.08298,
        high: 1.08334,
        low: 1.08297
      },
      {
        timestamp: 1650509400,
        open: 1.08299,
        close: 1.08274,
        high: 1.08301,
        low: 1.08271
      },
      {
        timestamp: 1650509700,
        open: 1.08274,
        close: 1.08267,
        high: 1.0829,
        low: 1.08247
      },
      {
        timestamp: 1650510000,
        open: 1.08266,
        close: 1.08272,
        high: 1.08292,
        low: 1.08243
      },
      {
        timestamp: 1650510300,
        open: 1.08272,
        close: 1.08286,
        high: 1.08293,
        low: 1.08264
      },
      {
        timestamp: 1650510600,
        open: 1.08286,
        close: 1.08301,
        high: 1.08308,
        low: 1.08277
      },
      {
        timestamp: 1650510900,
        open: 1.08301,
        close: 1.08293,
        high: 1.08313,
        low: 1.08291
      },
      {
        timestamp: 1650511200,
        open: 1.08294,
        close: 1.08284,
        high: 1.08295,
        low: 1.08279
      },
      {
        timestamp: 1650511500,
        open: 1.08285,
        close: 1.08295,
        high: 1.08296,
        low: 1.0827
      },
      {
        timestamp: 1650511800,
        open: 1.08294,
        close: 1.08287,
        high: 1.08314,
        low: 1.08284
      },
      {
        timestamp: 1650512100,
        open: 1.08287,
        close: 1.08306,
        high: 1.08306,
        low: 1.0828
      },
      {
        timestamp: 1650512400,
        open: 1.08305,
        close: 1.08321,
        high: 1.08325,
        low: 1.08297
      },
      {
        timestamp: 1650512700,
        open: 1.0832,
        close: 1.0828,
        high: 1.08322,
        low: 1.0828
      },
      {
        timestamp: 1650513000,
        open: 1.0828,
        close: 1.08276,
        high: 1.08287,
        low: 1.0826
      },
      {
        timestamp: 1650513300,
        open: 1.08276,
        close: 1.08312,
        high: 1.08317,
        low: 1.08264
      },
      {
        timestamp: 1650513600,
        open: 1.08314,
        close: 1.08329,
        high: 1.08332,
        low: 1.0831
      },
      {
        timestamp: 1650513900,
        open: 1.08329,
        close: 1.08315,
        high: 1.08331,
        low: 1.08309
      },
      {
        timestamp: 1650514200,
        open: 1.08314,
        close: 1.08331,
        high: 1.08332,
        low: 1.08304
      },
      {
        timestamp: 1650514500,
        open: 1.0833,
        close: 1.0835,
        high: 1.08362,
        low: 1.0833
      },
      {
        timestamp: 1650514800,
        open: 1.0835,
        close: 1.08335,
        high: 1.08354,
        low: 1.08326
      },
      {
        timestamp: 1650515100,
        open: 1.08334,
        close: 1.08334,
        high: 1.08343,
        low: 1.08329
      },
      {
        timestamp: 1650515400,
        open: 1.08335,
        close: 1.08345,
        high: 1.08352,
        low: 1.0833
      },
      {
        timestamp: 1650515700,
        open: 1.08345,
        close: 1.08328,
        high: 1.08345,
        low: 1.08326
      },
      {
        timestamp: 1650516000,
        open: 1.08329,
        close: 1.08336,
        high: 1.08336,
        low: 1.08321
      },
      {
        timestamp: 1650516300,
        open: 1.08337,
        close: 1.08308,
        high: 1.08339,
        low: 1.08302
      },
      {
        timestamp: 1650516600,
        open: 1.08306,
        close: 1.08305,
        high: 1.08314,
        low: 1.08298
      },
      {
        timestamp: 1650516900,
        open: 1.08304,
        close: 1.08329,
        high: 1.08341,
        low: 1.08297
      },
      {
        timestamp: 1650517200,
        open: 1.0833,
        close: 1.0841,
        high: 1.08426,
        low: 1.08323
      },
      {
        timestamp: 1650517500,
        open: 1.08409,
        close: 1.08408,
        high: 1.08417,
        low: 1.08394
      },
      {
        timestamp: 1650517800,
        open: 1.08407,
        close: 1.0845,
        high: 1.08456,
        low: 1.08404
      },
      {
        timestamp: 1650518100,
        open: 1.08451,
        close: 1.08439,
        high: 1.08468,
        low: 1.08438
      },
      {
        timestamp: 1650518400,
        open: 1.08439,
        close: 1.08462,
        high: 1.0847,
        low: 1.08436
      },
      {
        timestamp: 1650518700,
        open: 1.08461,
        close: 1.08469,
        high: 1.0849,
        low: 1.08443
      },
      {
        timestamp: 1650519000,
        open: 1.08471,
        close: 1.0848,
        high: 1.08485,
        low: 1.08461
      },
      {
        timestamp: 1650519300,
        open: 1.0848,
        close: 1.08467,
        high: 1.08482,
        low: 1.08448
      },
      {
        timestamp: 1650519600,
        open: 1.08466,
        close: 1.08466,
        high: 1.08482,
        low: 1.0846
      },
      {
        timestamp: 1650519900,
        open: 1.08467,
        close: 1.0851,
        high: 1.08521,
        low: 1.08462
      },
      {
        timestamp: 1650520200,
        open: 1.08509,
        close: 1.085,
        high: 1.08524,
        low: 1.08497
      },
      {
        timestamp: 1650520500,
        open: 1.08499,
        close: 1.08477,
        high: 1.08525,
        low: 1.08473
      },
      {
        timestamp: 1650520800,
        open: 1.08476,
        close: 1.08513,
        high: 1.08545,
        low: 1.08468
      },
      {
        timestamp: 1650521100,
        open: 1.08513,
        close: 1.08539,
        high: 1.0856,
        low: 1.08505
      },
      {
        timestamp: 1650521400,
        open: 1.0854,
        close: 1.08526,
        high: 1.0854,
        low: 1.08487
      },
      {
        timestamp: 1650521700,
        open: 1.08525,
        close: 1.08577,
        high: 1.08581,
        low: 1.08505
      },
      {
        timestamp: 1650522000,
        open: 1.08578,
        close: 1.0855,
        high: 1.08581,
        low: 1.08532
      },
      {
        timestamp: 1650522300,
        open: 1.08549,
        close: 1.08585,
        high: 1.08608,
        low: 1.08549
      },
      {
        timestamp: 1650522600,
        open: 1.08585,
        close: 1.08807,
        high: 1.08836,
        low: 1.08577
      },
      {
        timestamp: 1650522900,
        open: 1.08806,
        close: 1.08882,
        high: 1.08895,
        low: 1.08792
      },
      {
        timestamp: 1650523200,
        open: 1.08881,
        close: 1.08963,
        high: 1.08987,
        low: 1.0885
      },
      {
        timestamp: 1650523500,
        open: 1.08963,
        close: 1.08937,
        high: 1.08964,
        low: 1.08918
      },
      {
        timestamp: 1650523800,
        open: 1.08937,
        close: 1.09005,
        high: 1.09008,
        low: 1.08931
      },
      {
        timestamp: 1650524100,
        open: 1.09005,
        close: 1.09029,
        high: 1.09041,
        low: 1.08971
      },
      {
        timestamp: 1650524400,
        open: 1.09029,
        close: 1.08928,
        high: 1.09039,
        low: 1.08913
      },
      {
        timestamp: 1650524700,
        open: 1.08929,
        close: 1.08971,
        high: 1.08979,
        low: 1.08885
      },
      {
        timestamp: 1650525000,
        open: 1.08971,
        close: 1.09012,
        high: 1.09031,
        low: 1.0896
      },
      {
        timestamp: 1650525300,
        open: 1.09012,
        close: 1.09063,
        high: 1.09105,
        low: 1.09012
      },
      {
        timestamp: 1650525600,
        open: 1.09061,
        close: 1.09124,
        high: 1.09125,
        low: 1.09032
      },
      {
        timestamp: 1650525900,
        open: 1.09123,
        close: 1.09141,
        high: 1.09164,
        low: 1.09119
      },
      {
        timestamp: 1650526200,
        open: 1.09142,
        close: 1.09137,
        high: 1.09148,
        low: 1.09082
      },
      {
        timestamp: 1650526500,
        open: 1.09136,
        close: 1.09164,
        high: 1.09186,
        low: 1.09134
      },
      {
        timestamp: 1650526800,
        open: 1.09164,
        close: 1.09234,
        high: 1.09298,
        low: 1.09159
      },
      {
        timestamp: 1650527100,
        open: 1.09234,
        close: 1.0928,
        high: 1.09299,
        low: 1.09207
      },
      {
        timestamp: 1650527400,
        open: 1.0928,
        close: 1.09279,
        high: 1.09371,
        low: 1.09267
      },
      {
        timestamp: 1650527700,
        open: 1.09278,
        close: 1.09277,
        high: 1.09331,
        low: 1.09246
      },
      {
        timestamp: 1650528000,
        open: 1.09278,
        close: 1.09209,
        high: 1.09292,
        low: 1.09208
      },
      {
        timestamp: 1650528300,
        open: 1.0921,
        close: 1.09192,
        high: 1.09216,
        low: 1.09172
      },
      {
        timestamp: 1650528600,
        open: 1.09192,
        close: 1.09231,
        high: 1.09247,
        low: 1.09177
      },
      {
        timestamp: 1650528900,
        open: 1.09232,
        close: 1.09193,
        high: 1.09239,
        low: 1.09183
      },
      {
        timestamp: 1650529200,
        open: 1.09193,
        close: 1.09168,
        high: 1.092,
        low: 1.09167
      },
      {
        timestamp: 1650529500,
        open: 1.09168,
        close: 1.09142,
        high: 1.09197,
        low: 1.09139
      },
      {
        timestamp: 1650529800,
        open: 1.09143,
        close: 1.09152,
        high: 1.09158,
        low: 1.09119
      },
      {
        timestamp: 1650530100,
        open: 1.09153,
        close: 1.09182,
        high: 1.09185,
        low: 1.09138
      },
      {
        timestamp: 1650530400,
        open: 1.09183,
        close: 1.09255,
        high: 1.09257,
        low: 1.0918
      },
      {
        timestamp: 1650530700,
        open: 1.09254,
        close: 1.09241,
        high: 1.09308,
        low: 1.0923
      },
      {
        timestamp: 1650531000,
        open: 1.09241,
        close: 1.0924,
        high: 1.09255,
        low: 1.09221
      },
      {
        timestamp: 1650531300,
        open: 1.0924,
        close: 1.09223,
        high: 1.09281,
        low: 1.09222
      },
      {
        timestamp: 1650531600,
        open: 1.09224,
        close: 1.09241,
        high: 1.09261,
        low: 1.09185
      },
      {
        timestamp: 1650531900,
        open: 1.09242,
        close: 1.09231,
        high: 1.0926,
        low: 1.09224
      },
      {
        timestamp: 1650532200,
        open: 1.09231,
        close: 1.09216,
        high: 1.09251,
        low: 1.09191
      },
      {
        timestamp: 1650532500,
        open: 1.09217,
        close: 1.09238,
        high: 1.09254,
        low: 1.09203
      },
      {
        timestamp: 1650532800,
        open: 1.09238,
        close: 1.09219,
        high: 1.09239,
        low: 1.09173
      },
      {
        timestamp: 1650533100,
        open: 1.0922,
        close: 1.09173,
        high: 1.09224,
        low: 1.09154
      },
      {
        timestamp: 1650533400,
        open: 1.09173,
        close: 1.0917,
        high: 1.09212,
        low: 1.09168
      },
      {
        timestamp: 1650533700,
        open: 1.0917,
        close: 1.09131,
        high: 1.09195,
        low: 1.09126
      },
      {
        timestamp: 1650534000,
        open: 1.09131,
        close: 1.0913,
        high: 1.09159,
        low: 1.09118
      },
      {
        timestamp: 1650534300,
        open: 1.09129,
        close: 1.09103,
        high: 1.09132,
        low: 1.0909
      },
      {
        timestamp: 1650534600,
        open: 1.09104,
        close: 1.09108,
        high: 1.09148,
        low: 1.09089
      },
      {
        timestamp: 1650534900,
        open: 1.09108,
        close: 1.09148,
        high: 1.09154,
        low: 1.09107
      },
      {
        timestamp: 1650535200,
        open: 1.09148,
        close: 1.09149,
        high: 1.09159,
        low: 1.09138
      },
      {
        timestamp: 1650535500,
        open: 1.0915,
        close: 1.0916,
        high: 1.09192,
        low: 1.09139
      },
      {
        timestamp: 1650535800,
        open: 1.0916,
        close: 1.09163,
        high: 1.09164,
        low: 1.09122
      },
      {
        timestamp: 1650536100,
        open: 1.09162,
        close: 1.09178,
        high: 1.09201,
        low: 1.09154
      },
      {
        timestamp: 1650536400,
        open: 1.09178,
        close: 1.09183,
        high: 1.09224,
        low: 1.09178
      },
      {
        timestamp: 1650536700,
        open: 1.09183,
        close: 1.09156,
        high: 1.09185,
        low: 1.0915
      },
      {
        timestamp: 1650537000,
        open: 1.09155,
        close: 1.09147,
        high: 1.09172,
        low: 1.09102
      },
      {
        timestamp: 1650537300,
        open: 1.09147,
        close: 1.09089,
        high: 1.0916,
        low: 1.09088
      },
      {
        timestamp: 1650537600,
        open: 1.09089,
        close: 1.0905,
        high: 1.091,
        low: 1.09048
      },
      {
        timestamp: 1650537900,
        open: 1.09049,
        close: 1.09063,
        high: 1.0908,
        low: 1.09038
      },
      {
        timestamp: 1650538200,
        open: 1.09064,
        close: 1.09021,
        high: 1.09087,
        low: 1.09021
      },
      {
        timestamp: 1650538500,
        open: 1.09021,
        close: 1.0901,
        high: 1.09035,
        low: 1.09006
      },
      {
        timestamp: 1650538800,
        open: 1.09011,
        close: 1.08989,
        high: 1.09019,
        low: 1.08963
      },
      {
        timestamp: 1650539100,
        open: 1.0899,
        close: 1.08948,
        high: 1.09009,
        low: 1.08932
      },
      {
        timestamp: 1650539400,
        open: 1.08949,
        close: 1.08903,
        high: 1.0895,
        low: 1.08877
      },
      {
        timestamp: 1650539700,
        open: 1.08903,
        close: 1.0887,
        high: 1.08925,
        low: 1.08853
      },
      {
        timestamp: 1650540000,
        open: 1.0887,
        close: 1.08793,
        high: 1.08885,
        low: 1.08788
      },
      {
        timestamp: 1650540300,
        open: 1.08792,
        close: 1.0884,
        high: 1.08861,
        low: 1.08772
      },
      {
        timestamp: 1650540600,
        open: 1.0884,
        close: 1.08859,
        high: 1.08861,
        low: 1.08799
      },
      {
        timestamp: 1650540900,
        open: 1.0886,
        close: 1.08851,
        high: 1.08867,
        low: 1.08817
      },
      {
        timestamp: 1650541200,
        open: 1.08852,
        close: 1.08783,
        high: 1.08853,
        low: 1.08782
      },
      {
        timestamp: 1650541500,
        open: 1.08783,
        close: 1.08837,
        high: 1.08851,
        low: 1.08777
      },
      {
        timestamp: 1650541800,
        open: 1.08837,
        close: 1.08763,
        high: 1.08843,
        low: 1.08757
      },
      {
        timestamp: 1650542100,
        open: 1.08763,
        close: 1.08805,
        high: 1.08823,
        low: 1.08752
      },
      {
        timestamp: 1650542400,
        open: 1.08807,
        close: 1.08852,
        high: 1.0886,
        low: 1.08798
      },
      {
        timestamp: 1650542700,
        open: 1.08852,
        close: 1.08853,
        high: 1.08859,
        low: 1.0883
      },
      {
        timestamp: 1650543000,
        open: 1.08853,
        close: 1.08881,
        high: 1.08881,
        low: 1.08846
      },
      {
        timestamp: 1650543300,
        open: 1.0888,
        close: 1.08875,
        high: 1.08884,
        low: 1.08856
      },
      {
        timestamp: 1650543600,
        open: 1.08875,
        close: 1.08824,
        high: 1.0888,
        low: 1.08812
      },
      {
        timestamp: 1650543900,
        open: 1.08825,
        close: 1.08841,
        high: 1.08862,
        low: 1.08819
      },
      {
        timestamp: 1650544200,
        open: 1.08842,
        close: 1.0878,
        high: 1.08862,
        low: 1.08763
      },
      {
        timestamp: 1650544500,
        open: 1.08779,
        close: 1.08874,
        high: 1.0888,
        low: 1.08779
      },
      {
        timestamp: 1650544800,
        open: 1.08875,
        close: 1.08889,
        high: 1.08905,
        low: 1.08857
      },
      {
        timestamp: 1650545100,
        open: 1.08889,
        close: 1.08893,
        high: 1.08909,
        low: 1.08868
      },
      {
        timestamp: 1650545400,
        open: 1.08893,
        close: 1.08918,
        high: 1.08941,
        low: 1.08867
      },
      {
        timestamp: 1650545700,
        open: 1.08919,
        close: 1.08919,
        high: 1.08924,
        low: 1.08898
      },
      {
        timestamp: 1650546000,
        open: 1.08918,
        close: 1.08897,
        high: 1.08919,
        low: 1.08859
      },
      {
        timestamp: 1650546300,
        open: 1.08897,
        close: 1.08934,
        high: 1.08944,
        low: 1.08878
      },
      {
        timestamp: 1650546600,
        open: 1.08935,
        close: 1.08924,
        high: 1.08949,
        low: 1.08893
      },
      {
        timestamp: 1650546900,
        open: 1.08926,
        close: 1.08903,
        high: 1.08954,
        low: 1.08901
      },
      {
        timestamp: 1650547200,
        open: 1.08904,
        close: 1.08884,
        high: 1.08904,
        low: 1.08839
      },
      {
        timestamp: 1650547500,
        open: 1.08883,
        close: 1.08943,
        high: 1.08968,
        low: 1.08883
      },
      {
        timestamp: 1650547800,
        open: 1.08944,
        close: 1.08922,
        high: 1.08968,
        low: 1.0888
      },
      {
        timestamp: 1650548100,
        open: 1.08922,
        close: 1.08898,
        high: 1.0894,
        low: 1.08882
      },
      {
        timestamp: 1650548400,
        open: 1.08899,
        close: 1.08857,
        high: 1.08909,
        low: 1.08855
      },
      {
        timestamp: 1650548700,
        open: 1.08857,
        close: 1.08831,
        high: 1.08859,
        low: 1.08808
      },
      {
        timestamp: 1650549000,
        open: 1.08831,
        close: 1.08844,
        high: 1.08867,
        low: 1.08791
      },
      {
        timestamp: 1650549300,
        open: 1.08845,
        close: 1.08731,
        high: 1.08854,
        low: 1.08723
      },
      {
        timestamp: 1650549600,
        open: 1.08731,
        close: 1.08674,
        high: 1.08758,
        low: 1.08672
      },
      {
        timestamp: 1650549900,
        open: 1.08674,
        close: 1.08697,
        high: 1.08709,
        low: 1.0863
      },
      {
        timestamp: 1650550200,
        open: 1.08698,
        close: 1.08722,
        high: 1.08757,
        low: 1.08696
      },
      {
        timestamp: 1650550500,
        open: 1.08723,
        close: 1.0875,
        high: 1.08758,
        low: 1.08697
      },
      {
        timestamp: 1650550800,
        open: 1.08753,
        close: 1.08721,
        high: 1.08765,
        low: 1.08715
      },
      {
        timestamp: 1650551100,
        open: 1.08723,
        close: 1.0871,
        high: 1.08754,
        low: 1.08703
      },
      {
        timestamp: 1650551400,
        open: 1.0871,
        close: 1.08719,
        high: 1.08743,
        low: 1.08702
      },
      {
        timestamp: 1650551700,
        open: 1.08718,
        close: 1.08707,
        high: 1.08735,
        low: 1.08687
      },
      {
        timestamp: 1650552000,
        open: 1.08706,
        close: 1.08672,
        high: 1.08711,
        low: 1.08648
      },
      {
        timestamp: 1650552300,
        open: 1.08671,
        close: 1.08552,
        high: 1.08674,
        low: 1.08543
      },
      {
        timestamp: 1650552600,
        open: 1.08552,
        close: 1.08461,
        high: 1.08568,
        low: 1.08454
      },
      {
        timestamp: 1650552900,
        open: 1.08461,
        close: 1.08438,
        high: 1.0848,
        low: 1.08418
      },
      {
        timestamp: 1650553200,
        open: 1.08437,
        close: 1.08436,
        high: 1.08486,
        low: 1.08433
      },
      {
        timestamp: 1650553500,
        open: 1.08437,
        close: 1.08513,
        high: 1.08524,
        low: 1.0843
      },
      {
        timestamp: 1650553800,
        open: 1.08515,
        close: 1.08517,
        high: 1.08535,
        low: 1.08499
      },
      {
        timestamp: 1650554100,
        open: 1.08518,
        close: 1.08543,
        high: 1.08568,
        low: 1.08514
      },
      {
        timestamp: 1650554400,
        open: 1.08543,
        close: 1.08576,
        high: 1.08584,
        low: 1.08525
      },
      {
        timestamp: 1650554700,
        open: 1.08577,
        close: 1.08578,
        high: 1.08594,
        low: 1.08562
      },
      {
        timestamp: 1650555000,
        open: 1.08578,
        close: 1.08508,
        high: 1.08578,
        low: 1.0848
      },
      {
        timestamp: 1650555300,
        open: 1.08508,
        close: 1.08558,
        high: 1.08605,
        low: 1.08501
      },
      {
        timestamp: 1650555600,
        open: 1.08558,
        close: 1.08488,
        high: 1.08598,
        low: 1.08488
      },
      {
        timestamp: 1650555900,
        open: 1.08488,
        close: 1.08471,
        high: 1.08513,
        low: 1.08448
      },
      {
        timestamp: 1650556200,
        open: 1.0847,
        close: 1.08409,
        high: 1.08489,
        low: 1.0838
      },
      {
        timestamp: 1650556500,
        open: 1.0841,
        close: 1.08427,
        high: 1.08437,
        low: 1.08374
      },
      {
        timestamp: 1650556800,
        open: 1.08428,
        close: 1.08437,
        high: 1.0844,
        low: 1.08395
      },
      {
        timestamp: 1650557100,
        open: 1.08436,
        close: 1.08431,
        high: 1.08464,
        low: 1.08426
      },
      {
        timestamp: 1650557400,
        open: 1.08431,
        close: 1.08394,
        high: 1.0844,
        low: 1.08371
      },
      {
        timestamp: 1650557700,
        open: 1.08393,
        close: 1.08382,
        high: 1.08397,
        low: 1.08372
      },
      {
        timestamp: 1650558000,
        open: 1.08382,
        close: 1.08361,
        high: 1.08433,
        low: 1.08361
      },
      {
        timestamp: 1650558300,
        open: 1.08359,
        close: 1.08361,
        high: 1.08375,
        low: 1.08337
      },
      {
        timestamp: 1650558600,
        open: 1.08362,
        close: 1.08409,
        high: 1.08421,
        low: 1.08345
      },
      {
        timestamp: 1650558900,
        open: 1.08408,
        close: 1.0845,
        high: 1.0845,
        low: 1.08395
      },
      {
        timestamp: 1650559200,
        open: 1.0845,
        close: 1.08489,
        high: 1.08508,
        low: 1.08438
      },
      {
        timestamp: 1650559500,
        open: 1.08488,
        close: 1.08464,
        high: 1.08492,
        low: 1.08455
      },
      {
        timestamp: 1650559800,
        open: 1.08464,
        close: 1.08425,
        high: 1.08468,
        low: 1.08418
      },
      {
        timestamp: 1650560100,
        open: 1.08426,
        close: 1.08442,
        high: 1.08444,
        low: 1.08413
      },
      {
        timestamp: 1650560400,
        open: 1.08442,
        close: 1.08406,
        high: 1.08446,
        low: 1.084
      },
      {
        timestamp: 1650560700,
        open: 1.08407,
        close: 1.08438,
        high: 1.08441,
        low: 1.0838
      },
      {
        timestamp: 1650561000,
        open: 1.08438,
        close: 1.08487,
        high: 1.08489,
        low: 1.08431
      },
      {
        timestamp: 1650561300,
        open: 1.08485,
        close: 1.0846,
        high: 1.08488,
        low: 1.08391
      },
      {
        timestamp: 1650561600,
        open: 1.0846,
        close: 1.08534,
        high: 1.08571,
        low: 1.08451
      },
      {
        timestamp: 1650561900,
        open: 1.08534,
        close: 1.08547,
        high: 1.08548,
        low: 1.0849
      },
      {
        timestamp: 1650562200,
        open: 1.08547,
        close: 1.08535,
        high: 1.0859,
        low: 1.08519
      },
      {
        timestamp: 1650562500,
        open: 1.08535,
        close: 1.08568,
        high: 1.08577,
        low: 1.08489
      },
      {
        timestamp: 1650562800,
        open: 1.08567,
        close: 1.08555,
        high: 1.08592,
        low: 1.08547
      },
      {
        timestamp: 1650563100,
        open: 1.08554,
        close: 1.0856,
        high: 1.08572,
        low: 1.08529
      },
      {
        timestamp: 1650563400,
        open: 1.08561,
        close: 1.08527,
        high: 1.08564,
        low: 1.08516
      },
      {
        timestamp: 1650563700,
        open: 1.08526,
        close: 1.08504,
        high: 1.08581,
        low: 1.08499
      },
      {
        timestamp: 1650564000,
        open: 1.08504,
        close: 1.08464,
        high: 1.08504,
        low: 1.0845
      },
      {
        timestamp: 1650564300,
        open: 1.08465,
        close: 1.08445,
        high: 1.0852,
        low: 1.0844
      },
      {
        timestamp: 1650564600,
        open: 1.08445,
        close: 1.08437,
        high: 1.08464,
        low: 1.08435
      },
      {
        timestamp: 1650564900,
        open: 1.08439,
        close: 1.08421,
        high: 1.08459,
        low: 1.08415
      },
      {
        timestamp: 1650565200,
        open: 1.08421,
        close: 1.08435,
        high: 1.08445,
        low: 1.08413
      },
      {
        timestamp: 1650565500,
        open: 1.08437,
        close: 1.08412,
        high: 1.08456,
        low: 1.08412
      },
      {
        timestamp: 1650565800,
        open: 1.08412,
        close: 1.08483,
        high: 1.08494,
        low: 1.08388
      },
      {
        timestamp: 1650566100,
        open: 1.08481,
        close: 1.08434,
        high: 1.08496,
        low: 1.08432
      },
      {
        timestamp: 1650566400,
        open: 1.08434,
        close: 1.08449,
        high: 1.08476,
        low: 1.08428
      },
      {
        timestamp: 1650566700,
        open: 1.08451,
        close: 1.08451,
        high: 1.08464,
        low: 1.08433
      },
      {
        timestamp: 1650567000,
        open: 1.08452,
        close: 1.08433,
        high: 1.08466,
        low: 1.08429
      },
      {
        timestamp: 1650567300,
        open: 1.08434,
        close: 1.0839,
        high: 1.08448,
        low: 1.08385
      },
      {
        timestamp: 1650567600,
        open: 1.08391,
        close: 1.08413,
        high: 1.0842,
        low: 1.08381
      },
      {
        timestamp: 1650567900,
        open: 1.08414,
        close: 1.084,
        high: 1.08429,
        low: 1.08399
      },
      {
        timestamp: 1650568200,
        open: 1.084,
        close: 1.08373,
        high: 1.08401,
        low: 1.0836
      },
      {
        timestamp: 1650568500,
        open: 1.08374,
        close: 1.08354,
        high: 1.08383,
        low: 1.08337
      },
      {
        timestamp: 1650568800,
        open: 1.08353,
        close: 1.08361,
        high: 1.08364,
        low: 1.08342
      },
      {
        timestamp: 1650569100,
        open: 1.08362,
        close: 1.0837,
        high: 1.08378,
        low: 1.08335
      },
      {
        timestamp: 1650569400,
        open: 1.08369,
        close: 1.08346,
        high: 1.08369,
        low: 1.08327
      },
      {
        timestamp: 1650569700,
        open: 1.08345,
        close: 1.08331,
        high: 1.08356,
        low: 1.08328
      },
      {
        timestamp: 1650570000,
        open: 1.0833,
        close: 1.08327,
        high: 1.08332,
        low: 1.08319
      },
      {
        timestamp: 1650570300,
        open: 1.08327,
        close: 1.0832,
        high: 1.08328,
        low: 1.08312
      },
      {
        timestamp: 1650570600,
        open: 1.08321,
        close: 1.0833,
        high: 1.08337,
        low: 1.08314
      },
      {
        timestamp: 1650570900,
        open: 1.08331,
        close: 1.08398,
        high: 1.0841,
        low: 1.08326
      },
      {
        timestamp: 1650571200,
        open: 1.08397,
        close: 1.08386,
        high: 1.08426,
        low: 1.08376
      },
      {
        timestamp: 1650571500,
        open: 1.08387,
        close: 1.08373,
        high: 1.084,
        low: 1.08366
      },
      {
        timestamp: 1650571800,
        open: 1.08375,
        close: 1.08377,
        high: 1.08381,
        low: 1.0837
      },
      {
        timestamp: 1650572100,
        open: 1.08376,
        close: 1.0842,
        high: 1.0842,
        low: 1.08376
      },
      {
        timestamp: 1650572400,
        open: 1.08421,
        close: 1.084,
        high: 1.08428,
        low: 1.084
      },
      {
        timestamp: 1650572700,
        open: 1.08402,
        close: 1.0841,
        high: 1.08429,
        low: 1.08401
      },
      {
        timestamp: 1650573000,
        open: 1.08411,
        close: 1.08408,
        high: 1.08415,
        low: 1.08408
      },
      {
        timestamp: 1650573300,
        open: 1.08409,
        close: 1.08381,
        high: 1.0841,
        low: 1.0837
      },
      {
        timestamp: 1650573600,
        open: 1.08382,
        close: 1.08371,
        high: 1.0839,
        low: 1.08369
      },
      {
        timestamp: 1650573900,
        open: 1.08373,
        close: 1.0838,
        high: 1.0838,
        low: 1.08366
      },
      {
        timestamp: 1650574200,
        open: 1.08381,
        close: 1.0836,
        high: 1.08381,
        low: 1.0836
      },
      {
        timestamp: 1650574500,
        open: 1.08361,
        close: 1.08376,
        high: 1.08376,
        low: 1.08315
      },
      {
        timestamp: 1650574800,
        open: 1.08377,
        close: 1.08415,
        high: 1.08427,
        low: 1.08377
      },
      {
        timestamp: 1650575100,
        open: 1.08415,
        close: 1.08376,
        high: 1.08438,
        low: 1.08371
      },
      {
        timestamp: 1650575400,
        open: 1.08378,
        close: 1.08371,
        high: 1.08387,
        low: 1.08367
      },
      {
        timestamp: 1650575700,
        open: 1.08372,
        close: 1.08367,
        high: 1.08373,
        low: 1.0836
      },
      {
        timestamp: 1650576000,
        open: 1.08368,
        close: 1.08369,
        high: 1.08377,
        low: 1.08362
      },
      {
        timestamp: 1650576300,
        open: 1.08366,
        close: 1.08366,
        high: 1.08369,
        low: 1.08361
      },
      {
        timestamp: 1650576600,
        open: 1.08365,
        close: 1.08362,
        high: 1.0837,
        low: 1.08362
      },
      {
        timestamp: 1650576900,
        open: 1.08363,
        close: 1.08387,
        high: 1.08399,
        low: 1.08362
      },
      {
        timestamp: 1650577200,
        open: 1.08387,
        close: 1.08386,
        high: 1.08391,
        low: 1.08383
      },
      {
        timestamp: 1650577500,
        open: 1.08386,
        close: 1.0839,
        high: 1.08394,
        low: 1.08384
      },
      {
        timestamp: 1650577800,
        open: 1.08393,
        close: 1.08385,
        high: 1.08397,
        low: 1.08383
      },
      {
        timestamp: 1650578100,
        open: 1.08384,
        close: 1.08408,
        high: 1.08422,
        low: 1.08384
      },
      {
        timestamp: 1650578400,
        open: 1.08381,
        close: 1.08353,
        high: 1.08398,
        low: 1.0835
      },
      {
        timestamp: 1650578700,
        open: 1.08352,
        close: 1.08365,
        high: 1.08369,
        low: 1.08345
      },
      {
        timestamp: 1650579000,
        open: 1.08366,
        close: 1.08346,
        high: 1.08368,
        low: 1.08336
      },
      {
        timestamp: 1650579300,
        open: 1.08347,
        close: 1.08356,
        high: 1.08358,
        low: 1.08339
      },
      {
        timestamp: 1650579600,
        open: 1.08357,
        close: 1.0836,
        high: 1.08371,
        low: 1.08353
      },
      {
        timestamp: 1650579900,
        open: 1.08361,
        close: 1.08355,
        high: 1.08369,
        low: 1.08355
      },
      {
        timestamp: 1650580200,
        open: 1.08357,
        close: 1.08355,
        high: 1.0836,
        low: 1.08353
      },
      {
        timestamp: 1650580500,
        open: 1.08353,
        close: 1.08338,
        high: 1.08362,
        low: 1.08336
      },
      {
        timestamp: 1650580800,
        open: 1.08337,
        close: 1.08371,
        high: 1.08376,
        low: 1.08335
      },
      {
        timestamp: 1650581100,
        open: 1.0837,
        close: 1.08388,
        high: 1.08389,
        low: 1.08367
      },
      {
        timestamp: 1650581400,
        open: 1.08389,
        close: 1.08404,
        high: 1.08404,
        low: 1.08378
      },
      {
        timestamp: 1650581700,
        open: 1.08405,
        close: 1.08404,
        high: 1.08407,
        low: 1.08387
      },
      {
        timestamp: 1650582000,
        open: 1.08404,
        close: 1.08376,
        high: 1.0841,
        low: 1.08376
      },
      {
        timestamp: 1650582300,
        open: 1.08375,
        close: 1.0836,
        high: 1.08375,
        low: 1.08356
      },
      {
        timestamp: 1650582600,
        open: 1.08361,
        close: 1.08371,
        high: 1.08376,
        low: 1.08361
      },
      {
        timestamp: 1650582900,
        open: 1.08372,
        close: 1.08376,
        high: 1.08379,
        low: 1.08368
      },
      {
        timestamp: 1650583200,
        open: 1.08376,
        close: 1.08371,
        high: 1.08376,
        low: 1.08356
      },
      {
        timestamp: 1650583500,
        open: 1.08372,
        close: 1.08362,
        high: 1.08387,
        low: 1.08353
      },
      {
        timestamp: 1650583800,
        open: 1.08361,
        close: 1.08371,
        high: 1.08377,
        low: 1.08352
      },
      {
        timestamp: 1650584100,
        open: 1.08371,
        close: 1.08363,
        high: 1.08376,
        low: 1.08354
      },
      {
        timestamp: 1650584400,
        open: 1.08364,
        close: 1.08362,
        high: 1.08377,
        low: 1.08352
      },
      {
        timestamp: 1650584700,
        open: 1.08362,
        close: 1.08362,
        high: 1.08378,
        low: 1.08355
      },
      {
        timestamp: 1650585000,
        open: 1.08362,
        close: 1.08359,
        high: 1.08369,
        low: 1.08351
      },
      {
        timestamp: 1650585300,
        open: 1.08359,
        close: 1.08358,
        high: 1.08363,
        low: 1.08345
      },
      {
        timestamp: 1650585600,
        open: 1.08359,
        close: 1.08373,
        high: 1.08383,
        low: 1.08334
      },
      {
        timestamp: 1650585900,
        open: 1.08372,
        close: 1.08411,
        high: 1.08426,
        low: 1.08372
      },
      {
        timestamp: 1650586200,
        open: 1.08409,
        close: 1.08385,
        high: 1.08412,
        low: 1.0837
      },
      {
        timestamp: 1650586500,
        open: 1.08385,
        close: 1.08387,
        high: 1.08403,
        low: 1.08377
      },
      {
        timestamp: 1650586800,
        open: 1.08388,
        close: 1.08397,
        high: 1.08409,
        low: 1.08379
      },
      {
        timestamp: 1650587100,
        open: 1.08397,
        close: 1.08391,
        high: 1.084,
        low: 1.08378
      },
      {
        timestamp: 1650587400,
        open: 1.0839,
        close: 1.08422,
        high: 1.08437,
        low: 1.08385
      },
      {
        timestamp: 1650587700,
        open: 1.08424,
        close: 1.08403,
        high: 1.08425,
        low: 1.08387
      },
      {
        timestamp: 1650588000,
        open: 1.08403,
        close: 1.08363,
        high: 1.08405,
        low: 1.08357
      },
      {
        timestamp: 1650588300,
        open: 1.08362,
        close: 1.08356,
        high: 1.08368,
        low: 1.08348
      },
      {
        timestamp: 1650588600,
        open: 1.08356,
        close: 1.08353,
        high: 1.08357,
        low: 1.08327
      },
      {
        timestamp: 1650588900,
        open: 1.08353,
        close: 1.08378,
        high: 1.08401,
        low: 1.08346
      },
      {
        timestamp: 1650589200,
        open: 1.08379,
        close: 1.08375,
        high: 1.08402,
        low: 1.0837
      },
      {
        timestamp: 1650589500,
        open: 1.08375,
        close: 1.08403,
        high: 1.08405,
        low: 1.08367
      },
      {
        timestamp: 1650589800,
        open: 1.08404,
        close: 1.08432,
        high: 1.08436,
        low: 1.08404
      },
      {
        timestamp: 1650590100,
        open: 1.08433,
        close: 1.08454,
        high: 1.0847,
        low: 1.0843
      },
      {
        timestamp: 1650590400,
        open: 1.08454,
        close: 1.08459,
        high: 1.08464,
        low: 1.08452
      },
      {
        timestamp: 1650590700,
        open: 1.08458,
        close: 1.0844,
        high: 1.08474,
        low: 1.08434
      },
      {
        timestamp: 1650591000,
        open: 1.0844,
        close: 1.08442,
        high: 1.08445,
        low: 1.08417
      },
      {
        timestamp: 1650591300,
        open: 1.0844,
        close: 1.08399,
        high: 1.08463,
        low: 1.08399
      },
      {
        timestamp: 1650591600,
        open: 1.08399,
        close: 1.08414,
        high: 1.08419,
        low: 1.08398
      },
      {
        timestamp: 1650591900,
        open: 1.08414,
        close: 1.08403,
        high: 1.08416,
        low: 1.0837
      },
      {
        timestamp: 1650592200,
        open: 1.08404,
        close: 1.08405,
        high: 1.08412,
        low: 1.08391
      },
      {
        timestamp: 1650592500,
        open: 1.08406,
        close: 1.08411,
        high: 1.08412,
        low: 1.08392
      },
      {
        timestamp: 1650592800,
        open: 1.08412,
        close: 1.08422,
        high: 1.08422,
        low: 1.08398
      },
      {
        timestamp: 1650593100,
        open: 1.08422,
        close: 1.0842,
        high: 1.0843,
        low: 1.08411
      },
      {
        timestamp: 1650593400,
        open: 1.08421,
        close: 1.08407,
        high: 1.08422,
        low: 1.08403
      },
      {
        timestamp: 1650593700,
        open: 1.08405,
        close: 1.08404,
        high: 1.08418,
        low: 1.084
      },
      {
        timestamp: 1650594000,
        open: 1.08404,
        close: 1.084,
        high: 1.0841,
        low: 1.08398
      },
      {
        timestamp: 1650594300,
        open: 1.08401,
        close: 1.0841,
        high: 1.08419,
        low: 1.08399
      },
      {
        timestamp: 1650594600,
        open: 1.0841,
        close: 1.08407,
        high: 1.0841,
        low: 1.08398
      },
      {
        timestamp: 1650594900,
        open: 1.08406,
        close: 1.08405,
        high: 1.08411,
        low: 1.084
      },
      {
        timestamp: 1650595200,
        open: 1.08406,
        close: 1.08404,
        high: 1.0841,
        low: 1.08391
      },
      {
        timestamp: 1650595500,
        open: 1.08403,
        close: 1.0838,
        high: 1.08403,
        low: 1.08355
      },
      {
        timestamp: 1650595800,
        open: 1.08381,
        close: 1.08446,
        high: 1.08453,
        low: 1.08378
      },
      {
        timestamp: 1650596100,
        open: 1.08444,
        close: 1.08415,
        high: 1.08449,
        low: 1.08411
      },
      {
        timestamp: 1650596400,
        open: 1.08415,
        close: 1.08329,
        high: 1.0842,
        low: 1.08316
      },
      {
        timestamp: 1650596700,
        open: 1.08329,
        close: 1.08341,
        high: 1.08359,
        low: 1.08325
      },
      {
        timestamp: 1650597000,
        open: 1.08341,
        close: 1.08365,
        high: 1.08371,
        low: 1.08336
      },
      {
        timestamp: 1650597300,
        open: 1.08365,
        close: 1.08406,
        high: 1.08407,
        low: 1.08362
      },
      {
        timestamp: 1650597600,
        open: 1.08405,
        close: 1.084,
        high: 1.08419,
        low: 1.08394
      },
      {
        timestamp: 1650597900,
        open: 1.084,
        close: 1.08397,
        high: 1.08416,
        low: 1.08393
      },
      {
        timestamp: 1650598200,
        open: 1.08397,
        close: 1.08409,
        high: 1.08415,
        low: 1.08391
      },
      {
        timestamp: 1650598500,
        open: 1.0841,
        close: 1.08407,
        high: 1.08422,
        low: 1.08405
      },
      {
        timestamp: 1650598800,
        open: 1.08407,
        close: 1.08415,
        high: 1.08426,
        low: 1.08403
      },
      {
        timestamp: 1650599100,
        open: 1.08416,
        close: 1.08409,
        high: 1.08423,
        low: 1.084
      },
      {
        timestamp: 1650599400,
        open: 1.08409,
        close: 1.08409,
        high: 1.08417,
        low: 1.08398
      },
      {
        timestamp: 1650599700,
        open: 1.08408,
        close: 1.08421,
        high: 1.08425,
        low: 1.08408
      },
      {
        timestamp: 1650600000,
        open: 1.0842,
        close: 1.08422,
        high: 1.08425,
        low: 1.08418
      },
      {
        timestamp: 1650600300,
        open: 1.08421,
        close: 1.08434,
        high: 1.08448,
        low: 1.0842
      },
      {
        timestamp: 1650600600,
        open: 1.08433,
        close: 1.08416,
        high: 1.08437,
        low: 1.08415
      },
      {
        timestamp: 1650600900,
        open: 1.08416,
        close: 1.08445,
        high: 1.08446,
        low: 1.08416
      },
      {
        timestamp: 1650601200,
        open: 1.08445,
        close: 1.08479,
        high: 1.08514,
        low: 1.08444
      },
      {
        timestamp: 1650601500,
        open: 1.0848,
        close: 1.08456,
        high: 1.08482,
        low: 1.08451
      },
      {
        timestamp: 1650601800,
        open: 1.08457,
        close: 1.08453,
        high: 1.08458,
        low: 1.08443
      },
      {
        timestamp: 1650602100,
        open: 1.08452,
        close: 1.0846,
        high: 1.08475,
        low: 1.08435
      },
      {
        timestamp: 1650602400,
        open: 1.08461,
        close: 1.08456,
        high: 1.08466,
        low: 1.08451
      },
      {
        timestamp: 1650602700,
        open: 1.08457,
        close: 1.08438,
        high: 1.08457,
        low: 1.08421
      },
      {
        timestamp: 1650603000,
        open: 1.08438,
        close: 1.08444,
        high: 1.08458,
        low: 1.08429
      },
      {
        timestamp: 1650603300,
        open: 1.08443,
        close: 1.08465,
        high: 1.08465,
        low: 1.08436
      },
      {
        timestamp: 1650603600,
        open: 1.08464,
        close: 1.08501,
        high: 1.08504,
        low: 1.08457
      },
      {
        timestamp: 1650603900,
        open: 1.085,
        close: 1.08504,
        high: 1.08512,
        low: 1.08485
      },
      {
        timestamp: 1650604200,
        open: 1.08505,
        close: 1.08482,
        high: 1.08523,
        low: 1.08482
      },
      {
        timestamp: 1650604500,
        open: 1.08482,
        close: 1.08489,
        high: 1.08496,
        low: 1.08481
      },
      {
        timestamp: 1650604800,
        open: 1.08489,
        close: 1.08448,
        high: 1.08489,
        low: 1.08448
      },
      {
        timestamp: 1650605100,
        open: 1.08448,
        close: 1.0843,
        high: 1.08448,
        low: 1.08426
      },
      {
        timestamp: 1650605400,
        open: 1.0843,
        close: 1.0842,
        high: 1.0843,
        low: 1.08396
      },
      {
        timestamp: 1650605700,
        open: 1.08421,
        close: 1.0847,
        high: 1.0847,
        low: 1.08419
      },
      {
        timestamp: 1650606000,
        open: 1.08469,
        close: 1.0845,
        high: 1.0848,
        low: 1.08448
      },
      {
        timestamp: 1650606300,
        open: 1.08451,
        close: 1.08448,
        high: 1.08459,
        low: 1.0843
      },
      {
        timestamp: 1650606600,
        open: 1.08447,
        close: 1.08469,
        high: 1.08472,
        low: 1.08445
      },
      {
        timestamp: 1650606900,
        open: 1.08468,
        close: 1.08466,
        high: 1.08487,
        low: 1.08452
      },
      {
        timestamp: 1650607200,
        open: 1.08467,
        close: 1.0845,
        high: 1.08481,
        low: 1.08421
      },
      {
        timestamp: 1650607500,
        open: 1.0845,
        close: 1.08485,
        high: 1.08522,
        low: 1.08435
      },
      {
        timestamp: 1650607800,
        open: 1.08485,
        close: 1.08495,
        high: 1.08521,
        low: 1.08471
      },
      {
        timestamp: 1650608100,
        open: 1.08494,
        close: 1.08481,
        high: 1.08496,
        low: 1.08469
      },
      {
        timestamp: 1650608400,
        open: 1.08482,
        close: 1.08467,
        high: 1.08492,
        low: 1.08437
      },
      {
        timestamp: 1650608700,
        open: 1.08467,
        close: 1.08431,
        high: 1.08471,
        low: 1.08423
      },
      {
        timestamp: 1650609000,
        open: 1.08433,
        close: 1.08419,
        high: 1.08453,
        low: 1.08413
      },
      {
        timestamp: 1650609300,
        open: 1.08419,
        close: 1.08467,
        high: 1.08471,
        low: 1.08402
      },
      {
        timestamp: 1650609600,
        open: 1.08469,
        close: 1.08416,
        high: 1.08481,
        low: 1.08408
      },
      {
        timestamp: 1650609900,
        open: 1.08416,
        close: 1.08351,
        high: 1.08424,
        low: 1.0835
      },
      {
        timestamp: 1650610200,
        open: 1.08352,
        close: 1.08272,
        high: 1.08373,
        low: 1.0827
      },
      {
        timestamp: 1650610500,
        open: 1.08271,
        close: 1.08273,
        high: 1.08307,
        low: 1.08257
      },
      {
        timestamp: 1650610800,
        open: 1.08273,
        close: 1.08208,
        high: 1.08295,
        low: 1.08179
      },
      {
        timestamp: 1650611100,
        open: 1.0821,
        close: 1.08191,
        high: 1.08247,
        low: 1.0817
      },
      {
        timestamp: 1650611400,
        open: 1.08191,
        close: 1.08149,
        high: 1.08203,
        low: 1.08142
      },
      {
        timestamp: 1650611700,
        open: 1.08148,
        close: 1.08072,
        high: 1.08165,
        low: 1.08071
      },
      {
        timestamp: 1650612000,
        open: 1.08071,
        close: 1.08146,
        high: 1.08181,
        low: 1.08051
      },
      {
        timestamp: 1650612300,
        open: 1.08146,
        close: 1.08103,
        high: 1.08155,
        low: 1.0808
      },
      {
        timestamp: 1650612600,
        open: 1.08104,
        close: 1.08131,
        high: 1.08146,
        low: 1.08097
      },
      {
        timestamp: 1650612900,
        open: 1.08133,
        close: 1.08147,
        high: 1.08156,
        low: 1.08128
      },
      {
        timestamp: 1650613200,
        open: 1.08148,
        close: 1.08146,
        high: 1.08154,
        low: 1.08083
      },
      {
        timestamp: 1650613500,
        open: 1.08145,
        close: 1.08149,
        high: 1.08173,
        low: 1.08113
      },
      {
        timestamp: 1650613800,
        open: 1.08149,
        close: 1.08141,
        high: 1.08169,
        low: 1.08123
      },
      {
        timestamp: 1650614100,
        open: 1.08141,
        close: 1.08092,
        high: 1.08152,
        low: 1.08082
      },
      {
        timestamp: 1650614400,
        open: 1.08092,
        close: 1.08123,
        high: 1.08123,
        low: 1.08049
      },
      {
        timestamp: 1650614700,
        open: 1.08124,
        close: 1.08075,
        high: 1.08135,
        low: 1.08036
      },
      {
        timestamp: 1650615000,
        open: 1.08074,
        close: 1.08097,
        high: 1.08123,
        low: 1.08045
      },
      {
        timestamp: 1650615300,
        open: 1.08096,
        close: 1.08006,
        high: 1.08097,
        low: 1.07958
      },
      {
        timestamp: 1650615600,
        open: 1.08007,
        close: 1.08039,
        high: 1.08066,
        low: 1.08
      },
      {
        timestamp: 1650615900,
        open: 1.08039,
        close: 1.08025,
        high: 1.0805,
        low: 1.08004
      },
      {
        timestamp: 1650616200,
        open: 1.08026,
        close: 1.08006,
        high: 1.08036,
        low: 1.07991
      },
      {
        timestamp: 1650616500,
        open: 1.08004,
        close: 1.07917,
        high: 1.08009,
        low: 1.07909
      },
      {
        timestamp: 1650616800,
        open: 1.07916,
        close: 1.07968,
        high: 1.07994,
        low: 1.07913
      },
      {
        timestamp: 1650617100,
        open: 1.07966,
        close: 1.07947,
        high: 1.08001,
        low: 1.07938
      },
      {
        timestamp: 1650617400,
        open: 1.07946,
        close: 1.07976,
        high: 1.08006,
        low: 1.07928
      },
      {
        timestamp: 1650617700,
        open: 1.07977,
        close: 1.0799,
        high: 1.08025,
        low: 1.07967
      },
      {
        timestamp: 1650618000,
        open: 1.0799,
        close: 1.07978,
        high: 1.08014,
        low: 1.07928
      },
      {
        timestamp: 1650618300,
        open: 1.07979,
        close: 1.07963,
        high: 1.08,
        low: 1.07947
      },
      {
        timestamp: 1650618600,
        open: 1.07963,
        close: 1.0803,
        high: 1.08037,
        low: 1.07963
      },
      {
        timestamp: 1650618900,
        open: 1.08031,
        close: 1.08066,
        high: 1.08081,
        low: 1.08029
      },
      {
        timestamp: 1650619200,
        open: 1.08066,
        close: 1.08077,
        high: 1.08081,
        low: 1.08028
      },
      {
        timestamp: 1650619500,
        open: 1.08076,
        close: 1.08051,
        high: 1.08094,
        low: 1.08047
      },
      {
        timestamp: 1650619800,
        open: 1.0805,
        close: 1.08028,
        high: 1.08066,
        low: 1.08024
      },
      {
        timestamp: 1650620100,
        open: 1.08029,
        close: 1.07995,
        high: 1.0804,
        low: 1.0799
      },
      {
        timestamp: 1650620400,
        open: 1.07995,
        close: 1.08032,
        high: 1.08049,
        low: 1.07993
      },
      {
        timestamp: 1650620700,
        open: 1.08031,
        close: 1.08,
        high: 1.08031,
        low: 1.07986
      },
      {
        timestamp: 1650621000,
        open: 1.08002,
        close: 1.08033,
        high: 1.08033,
        low: 1.0798
      },
      {
        timestamp: 1650621300,
        open: 1.08034,
        close: 1.08064,
        high: 1.08066,
        low: 1.08032
      },
      {
        timestamp: 1650621600,
        open: 1.08065,
        close: 1.08051,
        high: 1.08068,
        low: 1.08027
      },
      {
        timestamp: 1650621900,
        open: 1.08051,
        close: 1.0804,
        high: 1.08063,
        low: 1.08031
      },
      {
        timestamp: 1650622200,
        open: 1.0804,
        close: 1.08042,
        high: 1.08055,
        low: 1.08027
      },
      {
        timestamp: 1650622500,
        open: 1.08041,
        close: 1.08026,
        high: 1.08047,
        low: 1.08002
      },
      {
        timestamp: 1650622800,
        open: 1.08025,
        close: 1.08038,
        high: 1.08044,
        low: 1.08021
      },
      {
        timestamp: 1650623100,
        open: 1.08039,
        close: 1.0805,
        high: 1.08071,
        low: 1.08039
      },
      {
        timestamp: 1650623400,
        open: 1.08049,
        close: 1.0805,
        high: 1.08075,
        low: 1.08035
      },
      {
        timestamp: 1650623700,
        open: 1.08052,
        close: 1.08049,
        high: 1.08076,
        low: 1.08049
      },
      {
        timestamp: 1650624000,
        open: 1.08049,
        close: 1.08058,
        high: 1.08068,
        low: 1.08031
      },
      {
        timestamp: 1650624300,
        open: 1.0806,
        close: 1.08076,
        high: 1.08092,
        low: 1.08057
      },
      {
        timestamp: 1650624600,
        open: 1.08076,
        close: 1.08122,
        high: 1.08132,
        low: 1.08076
      },
      {
        timestamp: 1650624900,
        open: 1.08123,
        close: 1.08166,
        high: 1.08166,
        low: 1.08114
      },
      {
        timestamp: 1650625200,
        open: 1.08165,
        close: 1.08184,
        high: 1.08196,
        low: 1.08149
      },
      {
        timestamp: 1650625500,
        open: 1.08183,
        close: 1.08198,
        high: 1.08204,
        low: 1.08154
      },
      {
        timestamp: 1650625800,
        open: 1.08199,
        close: 1.08219,
        high: 1.08239,
        low: 1.08192
      },
      {
        timestamp: 1650626100,
        open: 1.08219,
        close: 1.08253,
        high: 1.08259,
        low: 1.08195
      },
      {
        timestamp: 1650626400,
        open: 1.08253,
        close: 1.08225,
        high: 1.08255,
        low: 1.08214
      },
      {
        timestamp: 1650626700,
        open: 1.08226,
        close: 1.0822,
        high: 1.08248,
        low: 1.08212
      },
      {
        timestamp: 1650627000,
        open: 1.0822,
        close: 1.08204,
        high: 1.08226,
        low: 1.08188
      },
      {
        timestamp: 1650627300,
        open: 1.08205,
        close: 1.08241,
        high: 1.08252,
        low: 1.08203
      },
      {
        timestamp: 1650627600,
        open: 1.08242,
        close: 1.08255,
        high: 1.08256,
        low: 1.08242
      },
      {
        timestamp: 1650627900,
        open: 1.08255,
        close: 1.08223,
        high: 1.08255,
        low: 1.08223
      },
      {
        timestamp: 1650628200,
        open: 1.08224,
        close: 1.08203,
        high: 1.08233,
        low: 1.08194
      },
      {
        timestamp: 1650628500,
        open: 1.08201,
        close: 1.08219,
        high: 1.08228,
        low: 1.08201
      },
      {
        timestamp: 1650628800,
        open: 1.0822,
        close: 1.08201,
        high: 1.08221,
        low: 1.08172
      },
      {
        timestamp: 1650629100,
        open: 1.082,
        close: 1.08189,
        high: 1.08214,
        low: 1.08171
      },
      {
        timestamp: 1650629400,
        open: 1.08189,
        close: 1.08176,
        high: 1.08189,
        low: 1.0816
      },
      {
        timestamp: 1650629700,
        open: 1.08177,
        close: 1.08217,
        high: 1.08241,
        low: 1.08159
      },
      {
        timestamp: 1650630000,
        open: 1.08218,
        close: 1.08218,
        high: 1.08239,
        low: 1.08198
      },
      {
        timestamp: 1650630300,
        open: 1.08219,
        close: 1.08186,
        high: 1.08227,
        low: 1.08185
      },
      {
        timestamp: 1650630600,
        open: 1.08186,
        close: 1.08172,
        high: 1.08205,
        low: 1.08166
      },
      {
        timestamp: 1650630900,
        open: 1.08173,
        close: 1.08219,
        high: 1.08234,
        low: 1.08173
      },
      {
        timestamp: 1650631200,
        open: 1.08219,
        close: 1.08208,
        high: 1.08238,
        low: 1.08184
      },
      {
        timestamp: 1650631500,
        open: 1.08208,
        close: 1.0822,
        high: 1.08224,
        low: 1.08189
      },
      {
        timestamp: 1650631800,
        open: 1.0822,
        close: 1.08199,
        high: 1.08221,
        low: 1.08157
      },
      {
        timestamp: 1650632100,
        open: 1.082,
        close: 1.08202,
        high: 1.08231,
        low: 1.08192
      },
      {
        timestamp: 1650632400,
        open: 1.082,
        close: 1.0823,
        high: 1.08237,
        low: 1.08191
      },
      {
        timestamp: 1650632700,
        open: 1.08229,
        close: 1.08389,
        high: 1.08456,
        low: 1.08228
      },
      {
        timestamp: 1650633000,
        open: 1.08389,
        close: 1.08266,
        high: 1.0841,
        low: 1.08256
      },
      {
        timestamp: 1650633300,
        open: 1.08266,
        close: 1.08261,
        high: 1.08286,
        low: 1.08229
      },
      {
        timestamp: 1650633600,
        open: 1.0826,
        close: 1.08319,
        high: 1.08329,
        low: 1.08251
      },
      {
        timestamp: 1650633900,
        open: 1.08318,
        close: 1.08275,
        high: 1.08328,
        low: 1.08269
      },
      {
        timestamp: 1650634200,
        open: 1.08274,
        close: 1.08207,
        high: 1.08275,
        low: 1.08203
      },
      {
        timestamp: 1650634500,
        open: 1.0821,
        close: 1.08232,
        high: 1.08234,
        low: 1.08179
      },
      {
        timestamp: 1650634800,
        open: 1.08232,
        close: 1.08228,
        high: 1.08241,
        low: 1.08221
      },
      {
        timestamp: 1650635100,
        open: 1.08228,
        close: 1.08108,
        high: 1.08228,
        low: 1.08106
      },
      {
        timestamp: 1650635400,
        open: 1.08108,
        close: 1.08167,
        high: 1.08167,
        low: 1.08094
      },
      {
        timestamp: 1650635700,
        open: 1.08167,
        close: 1.08176,
        high: 1.08183,
        low: 1.08153
      },
      {
        timestamp: 1650636000,
        open: 1.08176,
        close: 1.08171,
        high: 1.08183,
        low: 1.08167
      },
      {
        timestamp: 1650636300,
        open: 1.08171,
        close: 1.08149,
        high: 1.08174,
        low: 1.08127
      },
      {
        timestamp: 1650636600,
        open: 1.08148,
        close: 1.08114,
        high: 1.08148,
        low: 1.08114
      },
      {
        timestamp: 1650636900,
        open: 1.08113,
        close: 1.08058,
        high: 1.08113,
        low: 1.08013
      },
      {
        timestamp: 1650637200,
        open: 1.08058,
        close: 1.08025,
        high: 1.08071,
        low: 1.08013
      },
      {
        timestamp: 1650637500,
        open: 1.08026,
        close: 1.08028,
        high: 1.08044,
        low: 1.07999
      },
      {
        timestamp: 1650637800,
        open: 1.08029,
        close: 1.0796,
        high: 1.08051,
        low: 1.0796
      },
      {
        timestamp: 1650638100,
        open: 1.0796,
        close: 1.07922,
        high: 1.07973,
        low: 1.07874
      },
      {
        timestamp: 1650638400,
        open: 1.0792,
        close: 1.07921,
        high: 1.07975,
        low: 1.07893
      },
      {
        timestamp: 1650638700,
        open: 1.07921,
        close: 1.07864,
        high: 1.07921,
        low: 1.0784
      },
      {
        timestamp: 1650639000,
        open: 1.07863,
        close: 1.079,
        high: 1.07916,
        low: 1.0786
      },
      {
        timestamp: 1650639300,
        open: 1.07899,
        close: 1.0786,
        high: 1.07905,
        low: 1.07856
      },
      {
        timestamp: 1650639600,
        open: 1.07862,
        close: 1.07845,
        high: 1.0787,
        low: 1.07824
      },
      {
        timestamp: 1650639900,
        open: 1.07845,
        close: 1.07785,
        high: 1.07847,
        low: 1.07778
      },
      {
        timestamp: 1650640200,
        open: 1.07785,
        close: 1.07816,
        high: 1.07822,
        low: 1.07762
      },
      {
        timestamp: 1650640500,
        open: 1.07816,
        close: 1.07804,
        high: 1.07831,
        low: 1.07773
      },
      {
        timestamp: 1650640800,
        open: 1.07804,
        close: 1.07805,
        high: 1.07808,
        low: 1.07734
      },
      {
        timestamp: 1650641100,
        open: 1.07804,
        close: 1.07858,
        high: 1.07867,
        low: 1.07794
      },
      {
        timestamp: 1650641400,
        open: 1.07859,
        close: 1.07871,
        high: 1.07903,
        low: 1.0784
      },
      {
        timestamp: 1650641700,
        open: 1.07871,
        close: 1.07845,
        high: 1.07888,
        low: 1.0782
      },
      {
        timestamp: 1650642000,
        open: 1.07846,
        close: 1.07859,
        high: 1.07877,
        low: 1.0783
      },
      {
        timestamp: 1650642300,
        open: 1.07859,
        close: 1.0786,
        high: 1.07882,
        low: 1.0784
      },
      {
        timestamp: 1650642600,
        open: 1.07861,
        close: 1.07906,
        high: 1.07915,
        low: 1.0786
      },
      {
        timestamp: 1650642900,
        open: 1.07906,
        close: 1.07884,
        high: 1.07929,
        low: 1.07875
      },
      {
        timestamp: 1650643200,
        open: 1.07885,
        close: 1.07895,
        high: 1.07927,
        low: 1.07876
      },
      {
        timestamp: 1650643500,
        open: 1.07895,
        close: 1.07882,
        high: 1.07909,
        low: 1.07876
      },
      {
        timestamp: 1650643800,
        open: 1.07881,
        close: 1.07839,
        high: 1.07881,
        low: 1.07826
      },
      {
        timestamp: 1650644100,
        open: 1.07838,
        close: 1.07808,
        high: 1.07847,
        low: 1.07802
      },
      {
        timestamp: 1650644400,
        open: 1.07808,
        close: 1.07784,
        high: 1.07808,
        low: 1.07772
      },
      {
        timestamp: 1650644700,
        open: 1.07784,
        close: 1.07786,
        high: 1.07788,
        low: 1.07769
      },
      {
        timestamp: 1650645000,
        open: 1.07785,
        close: 1.07837,
        high: 1.0784,
        low: 1.07785
      },
      {
        timestamp: 1650645300,
        open: 1.07837,
        close: 1.07832,
        high: 1.07863,
        low: 1.07824
      },
      {
        timestamp: 1650645600,
        open: 1.07832,
        close: 1.07834,
        high: 1.07839,
        low: 1.07816
      },
      {
        timestamp: 1650645900,
        open: 1.07833,
        close: 1.07864,
        high: 1.07865,
        low: 1.07826
      },
      {
        timestamp: 1650646200,
        open: 1.07863,
        close: 1.07845,
        high: 1.07863,
        low: 1.07822
      },
      {
        timestamp: 1650646500,
        open: 1.07846,
        close: 1.07803,
        high: 1.07856,
        low: 1.07797
      },
      {
        timestamp: 1650646800,
        open: 1.07802,
        close: 1.07854,
        high: 1.07859,
        low: 1.0779
      },
      {
        timestamp: 1650647100,
        open: 1.07854,
        close: 1.07838,
        high: 1.07871,
        low: 1.07836
      },
      {
        timestamp: 1650647400,
        open: 1.07838,
        close: 1.07837,
        high: 1.07849,
        low: 1.07821
      },
      {
        timestamp: 1650647700,
        open: 1.07836,
        close: 1.07858,
        high: 1.07863,
        low: 1.07826
      },
      {
        timestamp: 1650648000,
        open: 1.07858,
        close: 1.0784,
        high: 1.07861,
        low: 1.07824
      },
      {
        timestamp: 1650648300,
        open: 1.0784,
        close: 1.07869,
        high: 1.07877,
        low: 1.07828
      },
      {
        timestamp: 1650648600,
        open: 1.0787,
        close: 1.07893,
        high: 1.079,
        low: 1.07867
      },
      {
        timestamp: 1650648900,
        open: 1.07893,
        close: 1.07926,
        high: 1.07958,
        low: 1.07891
      },
      {
        timestamp: 1650649200,
        open: 1.07925,
        close: 1.07934,
        high: 1.07939,
        low: 1.0792
      },
      {
        timestamp: 1650649500,
        open: 1.07933,
        close: 1.07941,
        high: 1.07943,
        low: 1.0792
      },
      {
        timestamp: 1650649800,
        open: 1.07941,
        close: 1.079,
        high: 1.07947,
        low: 1.07895
      },
      {
        timestamp: 1650650100,
        open: 1.07899,
        close: 1.07885,
        high: 1.07903,
        low: 1.07874
      },
      {
        timestamp: 1650650400,
        open: 1.07884,
        close: 1.07931,
        high: 1.07933,
        low: 1.0787
      },
      {
        timestamp: 1650650700,
        open: 1.07931,
        close: 1.07896,
        high: 1.07943,
        low: 1.07884
      },
      {
        timestamp: 1650651000,
        open: 1.07896,
        close: 1.07889,
        high: 1.07904,
        low: 1.07874
      },
      {
        timestamp: 1650651300,
        open: 1.07889,
        close: 1.07953,
        high: 1.07957,
        low: 1.07884
      },
      {
        timestamp: 1650651600,
        open: 1.07954,
        close: 1.07922,
        high: 1.07958,
        low: 1.07918
      },
      {
        timestamp: 1650651900,
        open: 1.07922,
        close: 1.07933,
        high: 1.07936,
        low: 1.07904
      },
      {
        timestamp: 1650652200,
        open: 1.07933,
        close: 1.07895,
        high: 1.07936,
        low: 1.07895
      },
      {
        timestamp: 1650652500,
        open: 1.07894,
        close: 1.07924,
        high: 1.07934,
        low: 1.07882
      },
      {
        timestamp: 1650652800,
        open: 1.07923,
        close: 1.0795,
        high: 1.07957,
        low: 1.07916
      },
      {
        timestamp: 1650653100,
        open: 1.07951,
        close: 1.07953,
        high: 1.07958,
        low: 1.07925
      },
      {
        timestamp: 1650653400,
        open: 1.07953,
        close: 1.07929,
        high: 1.07953,
        low: 1.07901
      },
      {
        timestamp: 1650653700,
        open: 1.07927,
        close: 1.07868,
        high: 1.07931,
        low: 1.07854
      },
      {
        timestamp: 1650654000,
        open: 1.07869,
        close: 1.07892,
        high: 1.07902,
        low: 1.07866
      },
      {
        timestamp: 1650654300,
        open: 1.07892,
        close: 1.07908,
        high: 1.07925,
        low: 1.07884
      },
      {
        timestamp: 1650654600,
        open: 1.0791,
        close: 1.07914,
        high: 1.07927,
        low: 1.079
      },
      {
        timestamp: 1650654900,
        open: 1.07915,
        close: 1.07944,
        high: 1.07949,
        low: 1.07901
      },
      {
        timestamp: 1650655200,
        open: 1.07944,
        close: 1.07945,
        high: 1.07957,
        low: 1.0793
      },
      {
        timestamp: 1650655500,
        open: 1.07946,
        close: 1.0795,
        high: 1.07955,
        low: 1.07936
      },
      {
        timestamp: 1650655800,
        open: 1.0795,
        close: 1.07955,
        high: 1.07957,
        low: 1.07943
      },
      {
        timestamp: 1650656100,
        open: 1.07954,
        close: 1.07986,
        high: 1.07987,
        low: 1.07951
      },
      {
        timestamp: 1650656400,
        open: 1.07987,
        close: 1.07974,
        high: 1.08012,
        low: 1.07967
      },
      {
        timestamp: 1650656700,
        open: 1.07973,
        close: 1.07958,
        high: 1.07974,
        low: 1.07933
      },
      {
        timestamp: 1650657000,
        open: 1.07957,
        close: 1.07951,
        high: 1.07958,
        low: 1.07925
      },
      {
        timestamp: 1650657300,
        open: 1.0795,
        close: 1.07937,
        high: 1.07955,
        low: 1.07911
      },
      {
        timestamp: 1650657600,
        open: 1.07936,
        close: 1.07977,
        high: 1.07994,
        low: 1.07934
      },
      {
        timestamp: 1650657900,
        open: 1.07978,
        close: 1.0793,
        high: 1.07985,
        low: 1.07918
      },
      {
        timestamp: 1650658200,
        open: 1.0793,
        close: 1.07973,
        high: 1.07977,
        low: 1.0793
      },
      {
        timestamp: 1650658500,
        open: 1.07973,
        close: 1.07984,
        high: 1.07989,
        low: 1.07973
      },
      {
        timestamp: 1650658800,
        open: 1.07986,
        close: 1.08001,
        high: 1.08015,
        low: 1.07986
      },
      {
        timestamp: 1650659100,
        open: 1.08,
        close: 1.08006,
        high: 1.08012,
        low: 1.07992
      },
      {
        timestamp: 1650659400,
        open: 1.08007,
        close: 1.07997,
        high: 1.08007,
        low: 1.07991
      },
      {
        timestamp: 1650659700,
        open: 1.07996,
        close: 1.07996,
        high: 1.08001,
        low: 1.07989
      },
      {
        timestamp: 1650660000,
        open: 1.07995,
        close: 1.07996,
        high: 1.08002,
        low: 1.07977
      },
      {
        timestamp: 1650660300,
        open: 1.07996,
        close: 1.07991,
        high: 1.07998,
        low: 1.07979
      },
      {
        timestamp: 1650660600,
        open: 1.0799,
        close: 1.08026,
        high: 1.08029,
        low: 1.07987
      },
      {
        timestamp: 1650660900,
        open: 1.08027,
        close: 1.08007,
        high: 1.08037,
        low: 1.07984
      },
      {
        timestamp: 1650826500,
        open: 1.08532,
        close: 1.08531,
        high: 1.08532,
        low: 1.08531
      },
      {
        timestamp: 1650826800,
        open: 1.08557,
        close: 1.08488,
        high: 1.08557,
        low: 1.08439
      },
      {
        timestamp: 1650827100,
        open: 1.08491,
        close: 1.08438,
        high: 1.08491,
        low: 1.08424
      },
      {
        timestamp: 1650827400,
        open: 1.08438,
        close: 1.08381,
        high: 1.08441,
        low: 1.08381
      },
      {
        timestamp: 1650827700,
        open: 1.08388,
        close: 1.08374,
        high: 1.08388,
        low: 1.08374
      },
      {
        timestamp: 1650828000,
        open: 1.08374,
        close: 1.08338,
        high: 1.084,
        low: 1.08338
      },
      {
        timestamp: 1650828300,
        open: 1.08325,
        close: 1.08338,
        high: 1.08344,
        low: 1.08325
      },
      {
        timestamp: 1650828600,
        open: 1.08339,
        close: 1.08381,
        high: 1.08385,
        low: 1.08339
      },
      {
        timestamp: 1650828900,
        open: 1.08374,
        close: 1.08338,
        high: 1.0839,
        low: 1.08324
      },
      {
        timestamp: 1650829200,
        open: 1.08345,
        close: 1.08384,
        high: 1.08428,
        low: 1.08338
      },
      {
        timestamp: 1650829500,
        open: 1.08384,
        close: 1.08369,
        high: 1.0843,
        low: 1.08366
      },
      {
        timestamp: 1650829800,
        open: 1.08374,
        close: 1.08385,
        high: 1.08425,
        low: 1.08366
      },
      {
        timestamp: 1650830100,
        open: 1.08385,
        close: 1.08382,
        high: 1.08388,
        low: 1.08328
      },
      {
        timestamp: 1650830400,
        open: 1.08374,
        close: 1.08295,
        high: 1.08374,
        low: 1.08262
      },
      {
        timestamp: 1650830700,
        open: 1.08295,
        close: 1.08238,
        high: 1.08295,
        low: 1.0821
      },
      {
        timestamp: 1650831000,
        open: 1.08238,
        close: 1.08241,
        high: 1.08295,
        low: 1.08238
      },
      {
        timestamp: 1650831300,
        open: 1.08237,
        close: 1.08246,
        high: 1.08246,
        low: 1.08237
      },
      {
        timestamp: 1650831600,
        open: 1.0824,
        close: 1.08226,
        high: 1.08246,
        low: 1.08199
      },
      {
        timestamp: 1650831900,
        open: 1.08235,
        close: 1.0823,
        high: 1.08235,
        low: 1.0823
      },
      {
        timestamp: 1650832200,
        open: 1.08235,
        close: 1.0822,
        high: 1.08235,
        low: 1.0822
      },
      {
        timestamp: 1650832500,
        open: 1.08225,
        close: 1.08175,
        high: 1.08225,
        low: 1.08105
      },
      {
        timestamp: 1650832800,
        open: 1.08174,
        close: 1.0815,
        high: 1.08197,
        low: 1.08095
      },
      {
        timestamp: 1650833100,
        open: 1.08178,
        close: 1.08114,
        high: 1.08195,
        low: 1.08113
      },
      {
        timestamp: 1650833400,
        open: 1.08115,
        close: 1.08168,
        high: 1.08168,
        low: 1.08115
      },
      {
        timestamp: 1650833700,
        open: 1.08088,
        close: 1.0809,
        high: 1.0809,
        low: 1.08088
      },
      {
        timestamp: 1650834000,
        open: 1.08167,
        close: 1.08167,
        high: 1.08169,
        low: 1.08167
      },
      {
        timestamp: 1650834300,
        open: 1.08162,
        close: 1.08084,
        high: 1.08167,
        low: 1.08045
      },
      {
        timestamp: 1650834600,
        open: 1.0809,
        close: 1.08025,
        high: 1.0809,
        low: 1.07992
      },
      {
        timestamp: 1650834900,
        open: 1.08023,
        close: 1.08045,
        high: 1.08096,
        low: 1.08023
      },
      {
        timestamp: 1650835200,
        open: 1.08048,
        close: 1.08119,
        high: 1.08129,
        low: 1.08048
      },
      {
        timestamp: 1650835500,
        open: 1.08119,
        close: 1.08107,
        high: 1.08121,
        low: 1.08075
      },
      {
        timestamp: 1650835800,
        open: 1.08108,
        close: 1.0808,
        high: 1.08108,
        low: 1.08054
      },
      {
        timestamp: 1650836100,
        open: 1.08077,
        close: 1.0808,
        high: 1.08093,
        low: 1.0807
      },
      {
        timestamp: 1650836400,
        open: 1.0808,
        close: 1.08075,
        high: 1.08086,
        low: 1.08075
      },
      {
        timestamp: 1650836700,
        open: 1.08075,
        close: 1.08089,
        high: 1.08089,
        low: 1.08042
      },
      {
        timestamp: 1650837000,
        open: 1.08089,
        close: 1.08084,
        high: 1.08094,
        low: 1.08079
      },
      {
        timestamp: 1650837300,
        open: 1.08084,
        close: 1.08083,
        high: 1.08093,
        low: 1.08069
      },
      {
        timestamp: 1650837600,
        open: 1.08084,
        close: 1.08073,
        high: 1.08173,
        low: 1.08066
      },
      {
        timestamp: 1650837900,
        open: 1.08073,
        close: 1.08064,
        high: 1.08104,
        low: 1.08033
      },
      {
        timestamp: 1650838200,
        open: 1.08063,
        close: 1.08019,
        high: 1.08073,
        low: 1.08016
      },
      {
        timestamp: 1650838500,
        open: 1.0802,
        close: 1.08035,
        high: 1.08039,
        low: 1.08007
      },
      {
        timestamp: 1650838800,
        open: 1.08034,
        close: 1.08008,
        high: 1.08035,
        low: 1.08006
      },
      {
        timestamp: 1650839100,
        open: 1.08008,
        close: 1.08013,
        high: 1.0802,
        low: 1.08005
      },
      {
        timestamp: 1650839400,
        open: 1.08013,
        close: 1.07976,
        high: 1.08027,
        low: 1.07968
      },
      {
        timestamp: 1650839700,
        open: 1.07976,
        close: 1.07988,
        high: 1.07995,
        low: 1.07969
      },
      {
        timestamp: 1650840000,
        open: 1.07989,
        close: 1.08021,
        high: 1.08021,
        low: 1.07978
      },
      {
        timestamp: 1650840300,
        open: 1.08021,
        close: 1.08035,
        high: 1.08041,
        low: 1.08021
      },
      {
        timestamp: 1650840600,
        open: 1.08035,
        close: 1.08056,
        high: 1.08077,
        low: 1.08027
      },
      {
        timestamp: 1650840900,
        open: 1.08057,
        close: 1.08073,
        high: 1.08076,
        low: 1.08037
      },
      {
        timestamp: 1650841200,
        open: 1.08073,
        close: 1.08055,
        high: 1.08074,
        low: 1.0805
      },
      {
        timestamp: 1650841500,
        open: 1.08056,
        close: 1.08083,
        high: 1.08091,
        low: 1.08051
      },
      {
        timestamp: 1650841800,
        open: 1.08084,
        close: 1.08107,
        high: 1.08108,
        low: 1.08081
      },
      {
        timestamp: 1650842100,
        open: 1.08108,
        close: 1.08063,
        high: 1.08112,
        low: 1.08058
      },
      {
        timestamp: 1650842400,
        open: 1.08062,
        close: 1.08088,
        high: 1.0809,
        low: 1.08054
      },
      {
        timestamp: 1650842700,
        open: 1.08087,
        close: 1.08106,
        high: 1.08117,
        low: 1.08085
      },
      {
        timestamp: 1650843000,
        open: 1.08106,
        close: 1.08097,
        high: 1.08115,
        low: 1.08094
      },
      {
        timestamp: 1650843300,
        open: 1.08097,
        close: 1.08107,
        high: 1.0813,
        low: 1.08094
      },
      {
        timestamp: 1650843600,
        open: 1.08107,
        close: 1.08101,
        high: 1.08109,
        low: 1.08093
      },
      {
        timestamp: 1650843900,
        open: 1.081,
        close: 1.08096,
        high: 1.08114,
        low: 1.08092
      },
      {
        timestamp: 1650844200,
        open: 1.08096,
        close: 1.08083,
        high: 1.08097,
        low: 1.0808
      },
      {
        timestamp: 1650844500,
        open: 1.08083,
        close: 1.08102,
        high: 1.08123,
        low: 1.08083
      },
      {
        timestamp: 1650844800,
        open: 1.08102,
        close: 1.08072,
        high: 1.0812,
        low: 1.08007
      },
      {
        timestamp: 1650845100,
        open: 1.08076,
        close: 1.08066,
        high: 1.08128,
        low: 1.08063
      },
      {
        timestamp: 1650845400,
        open: 1.08067,
        close: 1.08055,
        high: 1.08069,
        low: 1.08048
      },
      {
        timestamp: 1650845700,
        open: 1.08055,
        close: 1.08051,
        high: 1.0808,
        low: 1.0805
      },
      {
        timestamp: 1650846000,
        open: 1.08051,
        close: 1.08042,
        high: 1.08057,
        low: 1.08025
      },
      {
        timestamp: 1650846300,
        open: 1.08042,
        close: 1.08062,
        high: 1.08066,
        low: 1.08029
      },
      {
        timestamp: 1650846600,
        open: 1.08062,
        close: 1.0803,
        high: 1.08089,
        low: 1.08029
      },
      {
        timestamp: 1650846900,
        open: 1.0803,
        close: 1.08079,
        high: 1.08081,
        low: 1.0803
      },
      {
        timestamp: 1650847200,
        open: 1.08079,
        close: 1.08066,
        high: 1.0811,
        low: 1.08053
      },
      {
        timestamp: 1650847500,
        open: 1.08066,
        close: 1.08059,
        high: 1.08073,
        low: 1.08034
      },
      {
        timestamp: 1650847800,
        open: 1.08058,
        close: 1.0807,
        high: 1.08072,
        low: 1.08033
      },
      {
        timestamp: 1650848100,
        open: 1.08069,
        close: 1.08027,
        high: 1.08076,
        low: 1.08011
      },
      {
        timestamp: 1650848400,
        open: 1.08027,
        close: 1.08031,
        high: 1.08052,
        low: 1.08013
      },
      {
        timestamp: 1650848700,
        open: 1.08032,
        close: 1.08003,
        high: 1.08032,
        low: 1.07992
      },
      {
        timestamp: 1650849000,
        open: 1.08004,
        close: 1.07984,
        high: 1.08005,
        low: 1.0797
      },
      {
        timestamp: 1650849300,
        open: 1.07985,
        close: 1.08033,
        high: 1.0804,
        low: 1.0798
      },
      {
        timestamp: 1650849600,
        open: 1.08034,
        close: 1.08033,
        high: 1.08045,
        low: 1.08008
      },
      {
        timestamp: 1650849900,
        open: 1.08033,
        close: 1.07993,
        high: 1.0805,
        low: 1.07993
      },
      {
        timestamp: 1650850200,
        open: 1.07994,
        close: 1.07934,
        high: 1.08003,
        low: 1.07929
      },
      {
        timestamp: 1650850500,
        open: 1.07934,
        close: 1.07966,
        high: 1.07974,
        low: 1.07931
      },
      {
        timestamp: 1650850800,
        open: 1.07966,
        close: 1.0791,
        high: 1.07968,
        low: 1.07901
      },
      {
        timestamp: 1650851100,
        open: 1.0791,
        close: 1.07858,
        high: 1.07926,
        low: 1.07852
      },
      {
        timestamp: 1650851400,
        open: 1.07858,
        close: 1.07865,
        high: 1.07895,
        low: 1.07844
      },
      {
        timestamp: 1650851700,
        open: 1.07864,
        close: 1.07878,
        high: 1.0788,
        low: 1.07858
      },
      {
        timestamp: 1650852000,
        open: 1.07878,
        close: 1.07892,
        high: 1.07895,
        low: 1.07863
      },
      {
        timestamp: 1650852300,
        open: 1.07892,
        close: 1.07872,
        high: 1.07896,
        low: 1.07865
      },
      {
        timestamp: 1650852600,
        open: 1.07871,
        close: 1.07846,
        high: 1.07873,
        low: 1.07837
      },
      {
        timestamp: 1650852900,
        open: 1.07846,
        close: 1.07844,
        high: 1.07854,
        low: 1.07833
      },
      {
        timestamp: 1650853200,
        open: 1.07845,
        close: 1.07853,
        high: 1.07869,
        low: 1.07832
      },
      {
        timestamp: 1650853500,
        open: 1.07852,
        close: 1.07824,
        high: 1.07859,
        low: 1.07811
      },
      {
        timestamp: 1650853800,
        open: 1.07825,
        close: 1.07822,
        high: 1.07843,
        low: 1.07815
      },
      {
        timestamp: 1650854100,
        open: 1.07822,
        close: 1.07792,
        high: 1.07822,
        low: 1.07785
      },
      {
        timestamp: 1650854400,
        open: 1.07792,
        close: 1.07788,
        high: 1.07802,
        low: 1.07781
      },
      {
        timestamp: 1650854700,
        open: 1.07788,
        close: 1.07811,
        high: 1.07828,
        low: 1.07787
      },
      {
        timestamp: 1650855000,
        open: 1.0781,
        close: 1.07848,
        high: 1.07849,
        low: 1.0781
      },
      {
        timestamp: 1650855300,
        open: 1.07847,
        close: 1.07818,
        high: 1.07848,
        low: 1.07791
      },
      {
        timestamp: 1650855600,
        open: 1.0782,
        close: 1.07816,
        high: 1.07831,
        low: 1.07807
      },
      {
        timestamp: 1650855900,
        open: 1.07816,
        close: 1.07834,
        high: 1.07842,
        low: 1.0781
      },
      {
        timestamp: 1650856200,
        open: 1.07834,
        close: 1.07855,
        high: 1.07863,
        low: 1.0783
      },
      {
        timestamp: 1650856500,
        open: 1.07854,
        close: 1.07855,
        high: 1.07864,
        low: 1.07837
      },
      {
        timestamp: 1650856800,
        open: 1.07854,
        close: 1.07865,
        high: 1.07872,
        low: 1.07854
      },
      {
        timestamp: 1650857100,
        open: 1.07865,
        close: 1.0789,
        high: 1.0789,
        low: 1.07854
      },
      {
        timestamp: 1650857400,
        open: 1.0789,
        close: 1.07888,
        high: 1.07891,
        low: 1.07869
      },
      {
        timestamp: 1650857700,
        open: 1.07887,
        close: 1.07855,
        high: 1.07891,
        low: 1.07851
      },
      {
        timestamp: 1650858000,
        open: 1.07856,
        close: 1.0785,
        high: 1.0786,
        low: 1.07845
      },
      {
        timestamp: 1650858300,
        open: 1.0785,
        close: 1.07853,
        high: 1.07861,
        low: 1.07843
      },
      {
        timestamp: 1650858600,
        open: 1.07854,
        close: 1.07849,
        high: 1.07856,
        low: 1.07844
      },
      {
        timestamp: 1650858900,
        open: 1.07849,
        close: 1.0782,
        high: 1.07856,
        low: 1.0782
      },
      {
        timestamp: 1650859200,
        open: 1.07821,
        close: 1.07839,
        high: 1.07841,
        low: 1.0782
      },
      {
        timestamp: 1650859500,
        open: 1.07839,
        close: 1.07848,
        high: 1.07858,
        low: 1.07833
      },
      {
        timestamp: 1650859800,
        open: 1.07847,
        close: 1.07855,
        high: 1.07859,
        low: 1.07838
      },
      {
        timestamp: 1650860100,
        open: 1.07854,
        close: 1.07856,
        high: 1.07861,
        low: 1.07845
      },
      {
        timestamp: 1650860400,
        open: 1.07857,
        close: 1.07844,
        high: 1.0786,
        low: 1.07829
      },
      {
        timestamp: 1650860700,
        open: 1.07846,
        close: 1.07844,
        high: 1.07859,
        low: 1.07839
      },
      {
        timestamp: 1650861000,
        open: 1.07845,
        close: 1.07808,
        high: 1.07848,
        low: 1.07803
      },
      {
        timestamp: 1650861300,
        open: 1.07807,
        close: 1.07835,
        high: 1.07841,
        low: 1.07804
      },
      {
        timestamp: 1650861600,
        open: 1.07835,
        close: 1.07812,
        high: 1.07838,
        low: 1.07788
      },
      {
        timestamp: 1650861900,
        open: 1.07811,
        close: 1.07782,
        high: 1.07813,
        low: 1.07779
      },
      {
        timestamp: 1650862200,
        open: 1.07781,
        close: 1.07769,
        high: 1.07787,
        low: 1.07761
      },
      {
        timestamp: 1650862500,
        open: 1.0777,
        close: 1.07749,
        high: 1.07783,
        low: 1.07729
      },
      {
        timestamp: 1650862800,
        open: 1.07749,
        close: 1.07752,
        high: 1.07772,
        low: 1.07748
      },
      {
        timestamp: 1650863100,
        open: 1.07752,
        close: 1.07752,
        high: 1.07754,
        low: 1.07733
      },
      {
        timestamp: 1650863400,
        open: 1.07752,
        close: 1.07742,
        high: 1.07758,
        low: 1.07729
      },
      {
        timestamp: 1650863700,
        open: 1.07742,
        close: 1.07735,
        high: 1.0775,
        low: 1.0773
      },
      {
        timestamp: 1650864000,
        open: 1.07735,
        close: 1.07734,
        high: 1.07753,
        low: 1.07728
      },
      {
        timestamp: 1650864300,
        open: 1.07733,
        close: 1.07753,
        high: 1.07767,
        low: 1.07733
      },
      {
        timestamp: 1650864600,
        open: 1.07753,
        close: 1.07744,
        high: 1.07765,
        low: 1.07739
      },
      {
        timestamp: 1650864900,
        open: 1.07744,
        close: 1.07738,
        high: 1.0775,
        low: 1.07721
      },
      {
        timestamp: 1650865200,
        open: 1.07738,
        close: 1.07778,
        high: 1.07795,
        low: 1.07736
      },
      {
        timestamp: 1650865500,
        open: 1.07777,
        close: 1.07769,
        high: 1.07784,
        low: 1.07766
      },
      {
        timestamp: 1650865800,
        open: 1.07768,
        close: 1.07731,
        high: 1.07769,
        low: 1.07729
      },
      {
        timestamp: 1650866100,
        open: 1.07732,
        close: 1.07765,
        high: 1.07771,
        low: 1.07689
      },
      {
        timestamp: 1650866400,
        open: 1.07763,
        close: 1.07769,
        high: 1.07786,
        low: 1.0775
      },
      {
        timestamp: 1650866700,
        open: 1.07768,
        close: 1.07685,
        high: 1.0778,
        low: 1.07674
      },
      {
        timestamp: 1650867000,
        open: 1.07684,
        close: 1.07374,
        high: 1.077,
        low: 1.07331
      },
      {
        timestamp: 1650867300,
        open: 1.07374,
        close: 1.07325,
        high: 1.07442,
        low: 1.07296
      },
      {
        timestamp: 1650867600,
        open: 1.07325,
        close: 1.07367,
        high: 1.07393,
        low: 1.07302
      },
      {
        timestamp: 1650867900,
        open: 1.07366,
        close: 1.07249,
        high: 1.07404,
        low: 1.07226
      },
      {
        timestamp: 1650868200,
        open: 1.07247,
        close: 1.07165,
        high: 1.07283,
        low: 1.07155
      },
      {
        timestamp: 1650868500,
        open: 1.07166,
        close: 1.07235,
        high: 1.07248,
        low: 1.07075
      },
      {
        timestamp: 1650868800,
        open: 1.07236,
        close: 1.07229,
        high: 1.07254,
        low: 1.07165
      },
      {
        timestamp: 1650869100,
        open: 1.0723,
        close: 1.07262,
        high: 1.07304,
        low: 1.07229
      },
      {
        timestamp: 1650869400,
        open: 1.07262,
        close: 1.07278,
        high: 1.07308,
        low: 1.07238
      },
      {
        timestamp: 1650869700,
        open: 1.07277,
        close: 1.07152,
        high: 1.07281,
        low: 1.07123
      },
      {
        timestamp: 1650870000,
        open: 1.07153,
        close: 1.07235,
        high: 1.07266,
        low: 1.07152
      },
      {
        timestamp: 1650870300,
        open: 1.07233,
        close: 1.07215,
        high: 1.07274,
        low: 1.07209
      },
      {
        timestamp: 1650870600,
        open: 1.07214,
        close: 1.07211,
        high: 1.07225,
        low: 1.07142
      },
      {
        timestamp: 1650870900,
        open: 1.07212,
        close: 1.07285,
        high: 1.07319,
        low: 1.07184
      },
      {
        timestamp: 1650871200,
        open: 1.07286,
        close: 1.07319,
        high: 1.0733,
        low: 1.07264
      },
      {
        timestamp: 1650871500,
        open: 1.0732,
        close: 1.07336,
        high: 1.07372,
        low: 1.07277
      },
      {
        timestamp: 1650871800,
        open: 1.07334,
        close: 1.0733,
        high: 1.07335,
        low: 1.07295
      },
      {
        timestamp: 1650872100,
        open: 1.0733,
        close: 1.07352,
        high: 1.07359,
        low: 1.073
      },
      {
        timestamp: 1650872400,
        open: 1.07352,
        close: 1.07326,
        high: 1.07391,
        low: 1.07315
      },
      {
        timestamp: 1650872700,
        open: 1.07327,
        close: 1.07373,
        high: 1.0738,
        low: 1.07314
      },
      {
        timestamp: 1650873000,
        open: 1.07372,
        close: 1.07385,
        high: 1.0739,
        low: 1.07324
      },
      {
        timestamp: 1650873300,
        open: 1.07386,
        close: 1.07392,
        high: 1.07418,
        low: 1.0737
      },
      {
        timestamp: 1650873600,
        open: 1.07391,
        close: 1.0749,
        high: 1.07497,
        low: 1.07388
      },
      {
        timestamp: 1650873900,
        open: 1.07489,
        close: 1.07482,
        high: 1.0753,
        low: 1.07464
      },
      {
        timestamp: 1650874200,
        open: 1.07482,
        close: 1.0738,
        high: 1.07511,
        low: 1.07374
      },
      {
        timestamp: 1650874500,
        open: 1.07381,
        close: 1.07442,
        high: 1.07445,
        low: 1.07344
      },
      {
        timestamp: 1650874800,
        open: 1.07443,
        close: 1.07455,
        high: 1.07465,
        low: 1.0741
      },
      {
        timestamp: 1650875100,
        open: 1.07454,
        close: 1.07498,
        high: 1.07501,
        low: 1.07454
      },
      {
        timestamp: 1650875400,
        open: 1.07499,
        close: 1.07529,
        high: 1.07544,
        low: 1.07489
      },
      {
        timestamp: 1650875700,
        open: 1.0753,
        close: 1.07529,
        high: 1.07552,
        low: 1.07501
      },
      {
        timestamp: 1650876000,
        open: 1.07528,
        close: 1.07461,
        high: 1.07531,
        low: 1.07458
      },
      {
        timestamp: 1650876300,
        open: 1.07461,
        close: 1.07441,
        high: 1.07497,
        low: 1.07427
      },
      {
        timestamp: 1650876600,
        open: 1.07441,
        close: 1.07395,
        high: 1.07451,
        low: 1.07386
      },
      {
        timestamp: 1650876900,
        open: 1.07396,
        close: 1.07456,
        high: 1.07479,
        low: 1.0739
      },
      {
        timestamp: 1650877200,
        open: 1.07456,
        close: 1.0744,
        high: 1.07464,
        low: 1.07415
      },
      {
        timestamp: 1650877500,
        open: 1.07441,
        close: 1.0748,
        high: 1.0749,
        low: 1.07413
      },
      {
        timestamp: 1650877800,
        open: 1.07478,
        close: 1.07446,
        high: 1.07497,
        low: 1.07446
      },
      {
        timestamp: 1650878100,
        open: 1.07446,
        close: 1.07384,
        high: 1.0747,
        low: 1.07377
      },
      {
        timestamp: 1650878400,
        open: 1.07384,
        close: 1.07402,
        high: 1.07405,
        low: 1.07364
      },
      {
        timestamp: 1650878700,
        open: 1.07403,
        close: 1.07426,
        high: 1.07456,
        low: 1.07397
      },
      {
        timestamp: 1650879000,
        open: 1.07425,
        close: 1.07396,
        high: 1.07438,
        low: 1.07354
      },
      {
        timestamp: 1650879300,
        open: 1.07397,
        close: 1.07424,
        high: 1.07451,
        low: 1.07397
      },
      {
        timestamp: 1650879600,
        open: 1.07425,
        close: 1.07464,
        high: 1.07467,
        low: 1.07412
      },
      {
        timestamp: 1650879900,
        open: 1.07465,
        close: 1.07409,
        high: 1.07472,
        low: 1.07403
      },
      {
        timestamp: 1650880200,
        open: 1.07409,
        close: 1.07372,
        high: 1.07435,
        low: 1.07348
      },
      {
        timestamp: 1650880500,
        open: 1.07373,
        close: 1.07314,
        high: 1.07387,
        low: 1.07309
      },
      {
        timestamp: 1650880800,
        open: 1.07315,
        close: 1.07278,
        high: 1.07326,
        low: 1.07275
      },
      {
        timestamp: 1650881100,
        open: 1.07279,
        close: 1.07278,
        high: 1.07297,
        low: 1.07247
      },
      {
        timestamp: 1650881400,
        open: 1.07278,
        close: 1.07343,
        high: 1.07343,
        low: 1.07247
      },
      {
        timestamp: 1650881700,
        open: 1.07344,
        close: 1.0732,
        high: 1.07373,
        low: 1.07297
      },
      {
        timestamp: 1650882000,
        open: 1.0732,
        close: 1.0731,
        high: 1.07345,
        low: 1.07277
      },
      {
        timestamp: 1650882300,
        open: 1.07309,
        close: 1.073,
        high: 1.07337,
        low: 1.07258
      },
      {
        timestamp: 1650882600,
        open: 1.07299,
        close: 1.07337,
        high: 1.07359,
        low: 1.07297
      },
      {
        timestamp: 1650882900,
        open: 1.07336,
        close: 1.07315,
        high: 1.07352,
        low: 1.07308
      },
      {
        timestamp: 1650883200,
        open: 1.07315,
        close: 1.07261,
        high: 1.07334,
        low: 1.07259
      },
      {
        timestamp: 1650883500,
        open: 1.0726,
        close: 1.07282,
        high: 1.073,
        low: 1.0726
      },
      {
        timestamp: 1650883800,
        open: 1.07282,
        close: 1.07281,
        high: 1.07307,
        low: 1.0726
      },
      {
        timestamp: 1650884100,
        open: 1.0728,
        close: 1.07249,
        high: 1.07286,
        low: 1.07204
      },
      {
        timestamp: 1650884400,
        open: 1.07249,
        close: 1.07242,
        high: 1.073,
        low: 1.0724
      },
      {
        timestamp: 1650884700,
        open: 1.07242,
        close: 1.0721,
        high: 1.07249,
        low: 1.07207
      },
      {
        timestamp: 1650885000,
        open: 1.0721,
        close: 1.07274,
        high: 1.07287,
        low: 1.0721
      },
      {
        timestamp: 1650885300,
        open: 1.07275,
        close: 1.07266,
        high: 1.07321,
        low: 1.07261
      },
      {
        timestamp: 1650885600,
        open: 1.07266,
        close: 1.07474,
        high: 1.07484,
        low: 1.07228
      },
      {
        timestamp: 1650885900,
        open: 1.07473,
        close: 1.07371,
        high: 1.07477,
        low: 1.07351
      },
      {
        timestamp: 1650886200,
        open: 1.0737,
        close: 1.07428,
        high: 1.07451,
        low: 1.07325
      },
      {
        timestamp: 1650886500,
        open: 1.0743,
        close: 1.0745,
        high: 1.07484,
        low: 1.07423
      },
      {
        timestamp: 1650886800,
        open: 1.07449,
        close: 1.07403,
        high: 1.0745,
        low: 1.07345
      },
      {
        timestamp: 1650887100,
        open: 1.07404,
        close: 1.07492,
        high: 1.0753,
        low: 1.07391
      },
      {
        timestamp: 1650887400,
        open: 1.07492,
        close: 1.07464,
        high: 1.07522,
        low: 1.07453
      },
      {
        timestamp: 1650887700,
        open: 1.07463,
        close: 1.07503,
        high: 1.0753,
        low: 1.07453
      },
      {
        timestamp: 1650888000,
        open: 1.07502,
        close: 1.07528,
        high: 1.07575,
        low: 1.07422
      },
      {
        timestamp: 1650888300,
        open: 1.07528,
        close: 1.07529,
        high: 1.0759,
        low: 1.07483
      },
      {
        timestamp: 1650888600,
        open: 1.07529,
        close: 1.07465,
        high: 1.07541,
        low: 1.0746
      },
      {
        timestamp: 1650888900,
        open: 1.07466,
        close: 1.07516,
        high: 1.07549,
        low: 1.07466
      },
      {
        timestamp: 1650889200,
        open: 1.07515,
        close: 1.07486,
        high: 1.07542,
        low: 1.07458
      },
      {
        timestamp: 1650889500,
        open: 1.07486,
        close: 1.0745,
        high: 1.07528,
        low: 1.07448
      },
      {
        timestamp: 1650889800,
        open: 1.0745,
        close: 1.07456,
        high: 1.0747,
        low: 1.07423
      },
      {
        timestamp: 1650890100,
        open: 1.07456,
        close: 1.07427,
        high: 1.07471,
        low: 1.07416
      },
      {
        timestamp: 1650890400,
        open: 1.07427,
        close: 1.07402,
        high: 1.07447,
        low: 1.07394
      },
      {
        timestamp: 1650890700,
        open: 1.07402,
        close: 1.07396,
        high: 1.0741,
        low: 1.07352
      },
      {
        timestamp: 1650891000,
        open: 1.07396,
        close: 1.07361,
        high: 1.07396,
        low: 1.07327
      },
      {
        timestamp: 1650891300,
        open: 1.07362,
        close: 1.07435,
        high: 1.0747,
        low: 1.07362
      },
      {
        timestamp: 1650891600,
        open: 1.07435,
        close: 1.07357,
        high: 1.07443,
        low: 1.07341
      },
      {
        timestamp: 1650891900,
        open: 1.07358,
        close: 1.0732,
        high: 1.07374,
        low: 1.07313
      },
      {
        timestamp: 1650892200,
        open: 1.0732,
        close: 1.07291,
        high: 1.07344,
        low: 1.07277
      },
      {
        timestamp: 1650892500,
        open: 1.07292,
        close: 1.07298,
        high: 1.07329,
        low: 1.0727
      },
      {
        timestamp: 1650892800,
        open: 1.07298,
        close: 1.07315,
        high: 1.07364,
        low: 1.07273
      },
      {
        timestamp: 1650893100,
        open: 1.07315,
        close: 1.07264,
        high: 1.07316,
        low: 1.07258
      },
      {
        timestamp: 1650893400,
        open: 1.07264,
        close: 1.07289,
        high: 1.07289,
        low: 1.07174
      },
      {
        timestamp: 1650893700,
        open: 1.07287,
        close: 1.07206,
        high: 1.073,
        low: 1.07203
      },
      {
        timestamp: 1650894000,
        open: 1.07206,
        close: 1.07242,
        high: 1.07273,
        low: 1.07193
      },
      {
        timestamp: 1650894300,
        open: 1.0724,
        close: 1.07235,
        high: 1.0729,
        low: 1.07164
      },
      {
        timestamp: 1650894600,
        open: 1.07235,
        close: 1.07253,
        high: 1.07299,
        low: 1.07218
      },
      {
        timestamp: 1650894900,
        open: 1.07254,
        close: 1.07238,
        high: 1.07276,
        low: 1.07199
      },
      {
        timestamp: 1650895200,
        open: 1.07235,
        close: 1.0725,
        high: 1.07259,
        low: 1.07183
      },
      {
        timestamp: 1650895500,
        open: 1.07249,
        close: 1.07266,
        high: 1.07276,
        low: 1.07229
      },
      {
        timestamp: 1650895800,
        open: 1.07267,
        close: 1.0724,
        high: 1.07296,
        low: 1.07158
      },
      {
        timestamp: 1650896100,
        open: 1.07241,
        close: 1.07167,
        high: 1.0726,
        low: 1.07162
      },
      {
        timestamp: 1650896400,
        open: 1.07167,
        close: 1.07194,
        high: 1.07209,
        low: 1.071
      },
      {
        timestamp: 1650896700,
        open: 1.07196,
        close: 1.07155,
        high: 1.07214,
        low: 1.0715
      },
      {
        timestamp: 1650897000,
        open: 1.07154,
        close: 1.07164,
        high: 1.07173,
        low: 1.07112
      },
      {
        timestamp: 1650897300,
        open: 1.07165,
        close: 1.07147,
        high: 1.07206,
        low: 1.07137
      },
      {
        timestamp: 1650897600,
        open: 1.07148,
        close: 1.07134,
        high: 1.07165,
        low: 1.07107
      },
      {
        timestamp: 1650897900,
        open: 1.07134,
        close: 1.0712,
        high: 1.07163,
        low: 1.07086
      },
      {
        timestamp: 1650898200,
        open: 1.0712,
        close: 1.0703,
        high: 1.07131,
        low: 1.06976
      },
      {
        timestamp: 1650898500,
        open: 1.0703,
        close: 1.07079,
        high: 1.07123,
        low: 1.07026
      },
      {
        timestamp: 1650898800,
        open: 1.0708,
        close: 1.07105,
        high: 1.07124,
        low: 1.07071
      },
      {
        timestamp: 1650899100,
        open: 1.07105,
        close: 1.07087,
        high: 1.07146,
        low: 1.07045
      },
      {
        timestamp: 1650899400,
        open: 1.07086,
        close: 1.07114,
        high: 1.07115,
        low: 1.07063
      },
      {
        timestamp: 1650899700,
        open: 1.07115,
        close: 1.07157,
        high: 1.07165,
        low: 1.07076
      },
      {
        timestamp: 1650900000,
        open: 1.07157,
        close: 1.07144,
        high: 1.07171,
        low: 1.07112
      },
      {
        timestamp: 1650900300,
        open: 1.07144,
        close: 1.0709,
        high: 1.07172,
        low: 1.0707
      },
      {
        timestamp: 1650900600,
        open: 1.07091,
        close: 1.07007,
        high: 1.07091,
        low: 1.06994
      },
      {
        timestamp: 1650900900,
        open: 1.07007,
        close: 1.07069,
        high: 1.07087,
        low: 1.07006
      },
      {
        timestamp: 1650901200,
        open: 1.07068,
        close: 1.07056,
        high: 1.07069,
        low: 1.07
      },
      {
        timestamp: 1650901500,
        open: 1.07055,
        close: 1.0708,
        high: 1.07099,
        low: 1.0704
      },
      {
        timestamp: 1650901800,
        open: 1.07081,
        close: 1.07055,
        high: 1.07085,
        low: 1.07053
      },
      {
        timestamp: 1650902100,
        open: 1.07055,
        close: 1.07037,
        high: 1.07085,
        low: 1.07034
      },
      {
        timestamp: 1650902400,
        open: 1.07037,
        close: 1.07072,
        high: 1.07073,
        low: 1.06975
      },
      {
        timestamp: 1650902700,
        open: 1.07072,
        close: 1.0711,
        high: 1.07146,
        low: 1.07068
      },
      {
        timestamp: 1650903000,
        open: 1.0711,
        close: 1.07166,
        high: 1.0718,
        low: 1.0711
      },
      {
        timestamp: 1650903300,
        open: 1.07166,
        close: 1.07151,
        high: 1.07193,
        low: 1.07134
      },
      {
        timestamp: 1650903600,
        open: 1.07151,
        close: 1.07174,
        high: 1.07186,
        low: 1.0713
      },
      {
        timestamp: 1650903900,
        open: 1.07175,
        close: 1.07115,
        high: 1.07185,
        low: 1.07109
      },
      {
        timestamp: 1650904200,
        open: 1.07116,
        close: 1.07123,
        high: 1.07154,
        low: 1.07098
      },
      {
        timestamp: 1650904500,
        open: 1.07124,
        close: 1.07131,
        high: 1.0717,
        low: 1.0712
      },
      {
        timestamp: 1650904800,
        open: 1.07132,
        close: 1.07133,
        high: 1.07151,
        low: 1.07115
      },
      {
        timestamp: 1650905100,
        open: 1.07134,
        close: 1.07175,
        high: 1.07182,
        low: 1.0713
      },
      {
        timestamp: 1650905400,
        open: 1.07176,
        close: 1.0715,
        high: 1.07192,
        low: 1.07145
      },
      {
        timestamp: 1650905700,
        open: 1.0715,
        close: 1.0716,
        high: 1.07176,
        low: 1.07144
      },
      {
        timestamp: 1650906000,
        open: 1.07159,
        close: 1.07143,
        high: 1.07164,
        low: 1.07094
      },
      {
        timestamp: 1650906300,
        open: 1.07144,
        close: 1.07172,
        high: 1.07175,
        low: 1.07126
      },
      {
        timestamp: 1650906600,
        open: 1.07172,
        close: 1.07176,
        high: 1.07178,
        low: 1.0714
      },
      {
        timestamp: 1650906900,
        open: 1.07175,
        close: 1.07155,
        high: 1.07192,
        low: 1.07144
      },
      {
        timestamp: 1650907200,
        open: 1.07157,
        close: 1.07173,
        high: 1.07182,
        low: 1.07144
      },
      {
        timestamp: 1650907500,
        open: 1.07173,
        close: 1.07166,
        high: 1.0718,
        low: 1.07154
      },
      {
        timestamp: 1650907800,
        open: 1.07167,
        close: 1.07172,
        high: 1.0719,
        low: 1.07148
      },
      {
        timestamp: 1650908100,
        open: 1.07171,
        close: 1.07165,
        high: 1.07176,
        low: 1.07147
      },
      {
        timestamp: 1650908400,
        open: 1.07165,
        close: 1.07167,
        high: 1.07189,
        low: 1.0715
      },
      {
        timestamp: 1650908700,
        open: 1.07168,
        close: 1.07164,
        high: 1.0718,
        low: 1.07148
      },
      {
        timestamp: 1650909000,
        open: 1.07164,
        close: 1.07169,
        high: 1.07175,
        low: 1.07128
      },
      {
        timestamp: 1650909300,
        open: 1.07169,
        close: 1.07137,
        high: 1.07173,
        low: 1.07128
      },
      {
        timestamp: 1650909600,
        open: 1.07137,
        close: 1.07104,
        high: 1.0714,
        low: 1.07104
      },
      {
        timestamp: 1650909900,
        open: 1.07105,
        close: 1.07158,
        high: 1.0717,
        low: 1.07105
      },
      {
        timestamp: 1650910200,
        open: 1.07158,
        close: 1.07156,
        high: 1.07173,
        low: 1.07138
      },
      {
        timestamp: 1650910500,
        open: 1.07155,
        close: 1.07122,
        high: 1.07159,
        low: 1.07117
      },
      {
        timestamp: 1650910800,
        open: 1.07122,
        close: 1.07147,
        high: 1.07148,
        low: 1.07108
      },
      {
        timestamp: 1650911100,
        open: 1.07145,
        close: 1.07155,
        high: 1.07156,
        low: 1.07118
      },
      {
        timestamp: 1650911400,
        open: 1.07154,
        close: 1.07181,
        high: 1.07197,
        low: 1.07147
      },
      {
        timestamp: 1650911700,
        open: 1.07181,
        close: 1.0718,
        high: 1.07187,
        low: 1.0716
      },
      {
        timestamp: 1650912000,
        open: 1.0718,
        close: 1.07165,
        high: 1.07182,
        low: 1.07164
      },
      {
        timestamp: 1650912300,
        open: 1.07166,
        close: 1.07162,
        high: 1.07192,
        low: 1.07159
      },
      {
        timestamp: 1650912600,
        open: 1.07163,
        close: 1.07162,
        high: 1.07167,
        low: 1.07139
      },
      {
        timestamp: 1650912900,
        open: 1.07161,
        close: 1.07104,
        high: 1.07173,
        low: 1.07102
      },
      {
        timestamp: 1650913200,
        open: 1.07103,
        close: 1.07072,
        high: 1.07103,
        low: 1.0706
      },
      {
        timestamp: 1650913500,
        open: 1.07074,
        close: 1.07084,
        high: 1.07101,
        low: 1.07065
      },
      {
        timestamp: 1650913800,
        open: 1.07086,
        close: 1.07072,
        high: 1.07087,
        low: 1.07052
      },
      {
        timestamp: 1650914100,
        open: 1.07072,
        close: 1.07121,
        high: 1.07131,
        low: 1.07068
      },
      {
        timestamp: 1650914400,
        open: 1.0712,
        close: 1.07172,
        high: 1.07177,
        low: 1.0712
      },
      {
        timestamp: 1650914700,
        open: 1.07171,
        close: 1.07177,
        high: 1.07183,
        low: 1.07153
      },
      {
        timestamp: 1650915000,
        open: 1.07178,
        close: 1.07179,
        high: 1.07189,
        low: 1.07167
      },
      {
        timestamp: 1650915300,
        open: 1.07178,
        close: 1.07178,
        high: 1.0719,
        low: 1.07167
      },
      {
        timestamp: 1650915600,
        open: 1.07177,
        close: 1.0718,
        high: 1.07181,
        low: 1.07168
      },
      {
        timestamp: 1650915900,
        open: 1.07179,
        close: 1.07167,
        high: 1.07182,
        low: 1.07159
      },
      {
        timestamp: 1650916200,
        open: 1.07166,
        close: 1.07159,
        high: 1.0717,
        low: 1.0715
      },
      {
        timestamp: 1650916500,
        open: 1.07159,
        close: 1.07141,
        high: 1.07159,
        low: 1.07135
      },
      {
        timestamp: 1650916800,
        open: 1.07142,
        close: 1.07126,
        high: 1.07158,
        low: 1.07125
      },
      {
        timestamp: 1650917100,
        open: 1.07126,
        close: 1.0711,
        high: 1.07132,
        low: 1.07109
      },
      {
        timestamp: 1650917400,
        open: 1.0711,
        close: 1.07102,
        high: 1.0711,
        low: 1.07102
      },
      {
        timestamp: 1650917700,
        open: 1.07103,
        close: 1.0714,
        high: 1.0714,
        low: 1.07099
      },
      {
        timestamp: 1650918000,
        open: 1.0714,
        close: 1.0713,
        high: 1.07148,
        low: 1.07124
      },
      {
        timestamp: 1650918300,
        open: 1.0713,
        close: 1.07137,
        high: 1.07145,
        low: 1.07117
      },
      {
        timestamp: 1650918600,
        open: 1.07135,
        close: 1.0712,
        high: 1.0714,
        low: 1.07119
      },
      {
        timestamp: 1650918900,
        open: 1.0712,
        close: 1.07109,
        high: 1.0712,
        low: 1.07104
      },
      {
        timestamp: 1650919200,
        open: 1.07108,
        close: 1.07114,
        high: 1.07115,
        low: 1.07108
      },
      {
        timestamp: 1650919500,
        open: 1.07114,
        close: 1.0712,
        high: 1.07131,
        low: 1.07109
      },
      {
        timestamp: 1650919800,
        open: 1.07121,
        close: 1.0712,
        high: 1.07126,
        low: 1.07119
      },
      {
        timestamp: 1650920100,
        open: 1.0712,
        close: 1.07135,
        high: 1.07135,
        low: 1.07108
      },
      {
        timestamp: 1650920400,
        open: 1.07165,
        close: 1.07155,
        high: 1.07177,
        low: 1.07151
      },
      {
        timestamp: 1650920700,
        open: 1.07155,
        close: 1.07141,
        high: 1.07155,
        low: 1.07141
      },
      {
        timestamp: 1650921000,
        open: 1.0714,
        close: 1.07139,
        high: 1.0714,
        low: 1.07137
      },
      {
        timestamp: 1650921300,
        open: 1.07154,
        close: 1.07144,
        high: 1.07157,
        low: 1.07144
      },
      {
        timestamp: 1650921600,
        open: 1.07143,
        close: 1.07147,
        high: 1.07147,
        low: 1.07143
      },
      {
        timestamp: 1650921900,
        open: 1.07147,
        close: 1.07152,
        high: 1.07153,
        low: 1.07146
      },
      {
        timestamp: 1650922200,
        open: 1.07152,
        close: 1.07145,
        high: 1.07152,
        low: 1.07143
      },
      {
        timestamp: 1650922500,
        open: 1.07146,
        close: 1.07145,
        high: 1.07147,
        low: 1.07144
      },
      {
        timestamp: 1650922800,
        open: 1.07144,
        close: 1.07144,
        high: 1.07146,
        low: 1.0714
      },
      {
        timestamp: 1650923100,
        open: 1.07145,
        close: 1.07139,
        high: 1.07146,
        low: 1.07137
      },
      {
        timestamp: 1650923400,
        open: 1.07144,
        close: 1.07145,
        high: 1.07146,
        low: 1.07137
      },
      {
        timestamp: 1650923700,
        open: 1.07144,
        close: 1.07145,
        high: 1.0715,
        low: 1.07144
      },
      {
        timestamp: 1650924000,
        open: 1.07145,
        close: 1.07139,
        high: 1.07151,
        low: 1.07114
      },
      {
        timestamp: 1650924300,
        open: 1.07139,
        close: 1.07137,
        high: 1.07141,
        low: 1.07135
      },
      {
        timestamp: 1650924600,
        open: 1.07138,
        close: 1.07141,
        high: 1.07148,
        low: 1.07134
      },
      {
        timestamp: 1650924900,
        open: 1.0714,
        close: 1.07126,
        high: 1.07143,
        low: 1.07124
      },
      {
        timestamp: 1650925200,
        open: 1.07128,
        close: 1.07135,
        high: 1.07137,
        low: 1.07115
      },
      {
        timestamp: 1650925500,
        open: 1.07135,
        close: 1.07129,
        high: 1.07143,
        low: 1.07129
      },
      {
        timestamp: 1650925800,
        open: 1.07129,
        close: 1.07132,
        high: 1.07137,
        low: 1.07118
      },
      {
        timestamp: 1650926100,
        open: 1.07132,
        close: 1.07125,
        high: 1.07157,
        low: 1.07121
      },
      {
        timestamp: 1650926400,
        open: 1.07124,
        close: 1.07109,
        high: 1.07128,
        low: 1.07106
      },
      {
        timestamp: 1650926700,
        open: 1.07109,
        close: 1.07128,
        high: 1.07134,
        low: 1.07104
      },
      {
        timestamp: 1650927000,
        open: 1.07129,
        close: 1.07128,
        high: 1.07131,
        low: 1.07113
      },
      {
        timestamp: 1650927300,
        open: 1.07128,
        close: 1.07139,
        high: 1.07147,
        low: 1.07119
      },
      {
        timestamp: 1650927600,
        open: 1.07138,
        close: 1.07124,
        high: 1.07139,
        low: 1.0711
      },
      {
        timestamp: 1650927900,
        open: 1.07123,
        close: 1.07097,
        high: 1.07139,
        low: 1.07086
      },
      {
        timestamp: 1650928200,
        open: 1.07097,
        close: 1.07088,
        high: 1.07098,
        low: 1.07084
      },
      {
        timestamp: 1650928500,
        open: 1.07088,
        close: 1.07101,
        high: 1.07104,
        low: 1.07078
      },
      {
        timestamp: 1650928800,
        open: 1.07102,
        close: 1.07088,
        high: 1.07104,
        low: 1.07084
      },
      {
        timestamp: 1650929100,
        open: 1.07089,
        close: 1.07083,
        high: 1.07092,
        low: 1.07081
      },
      {
        timestamp: 1650929400,
        open: 1.07083,
        close: 1.07104,
        high: 1.07107,
        low: 1.07077
      },
      {
        timestamp: 1650929700,
        open: 1.07105,
        close: 1.07127,
        high: 1.0713,
        low: 1.07105
      },
      {
        timestamp: 1650930000,
        open: 1.07128,
        close: 1.07129,
        high: 1.07131,
        low: 1.07106
      },
      {
        timestamp: 1650930300,
        open: 1.07128,
        close: 1.0715,
        high: 1.07153,
        low: 1.0712
      },
      {
        timestamp: 1650930600,
        open: 1.07148,
        close: 1.07135,
        high: 1.07149,
        low: 1.07132
      },
      {
        timestamp: 1650930900,
        open: 1.07134,
        close: 1.07132,
        high: 1.07151,
        low: 1.07129
      },
      {
        timestamp: 1650931200,
        open: 1.07132,
        close: 1.07147,
        high: 1.0716,
        low: 1.07122
      },
      {
        timestamp: 1650931500,
        open: 1.07146,
        close: 1.07163,
        high: 1.07166,
        low: 1.07143
      },
      {
        timestamp: 1650931800,
        open: 1.07162,
        close: 1.07159,
        high: 1.07176,
        low: 1.0715
      },
      {
        timestamp: 1650932100,
        open: 1.07158,
        close: 1.07174,
        high: 1.07177,
        low: 1.07139
      },
      {
        timestamp: 1650932400,
        open: 1.07174,
        close: 1.07183,
        high: 1.0719,
        low: 1.07163
      },
      {
        timestamp: 1650932700,
        open: 1.07184,
        close: 1.07255,
        high: 1.07255,
        low: 1.07184
      },
      {
        timestamp: 1650933000,
        open: 1.07255,
        close: 1.07263,
        high: 1.0727,
        low: 1.07238
      },
      {
        timestamp: 1650933300,
        open: 1.07263,
        close: 1.0725,
        high: 1.0727,
        low: 1.0724
      },
      {
        timestamp: 1650933600,
        open: 1.0725,
        close: 1.0726,
        high: 1.07266,
        low: 1.07233
      },
      {
        timestamp: 1650933900,
        open: 1.07259,
        close: 1.07248,
        high: 1.07263,
        low: 1.07242
      },
      {
        timestamp: 1650934200,
        open: 1.07249,
        close: 1.07279,
        high: 1.0729,
        low: 1.07235
      },
      {
        timestamp: 1650934500,
        open: 1.07279,
        close: 1.0731,
        high: 1.07321,
        low: 1.07268
      },
      {
        timestamp: 1650934800,
        open: 1.0731,
        close: 1.073,
        high: 1.07324,
        low: 1.07293
      },
      {
        timestamp: 1650935100,
        open: 1.073,
        close: 1.07314,
        high: 1.07319,
        low: 1.07287
      },
      {
        timestamp: 1650935400,
        open: 1.07313,
        close: 1.07341,
        high: 1.07344,
        low: 1.07297
      },
      {
        timestamp: 1650935700,
        open: 1.0734,
        close: 1.07285,
        high: 1.07342,
        low: 1.07284
      },
      {
        timestamp: 1650936000,
        open: 1.07285,
        close: 1.07247,
        high: 1.07288,
        low: 1.07233
      },
      {
        timestamp: 1650936300,
        open: 1.07246,
        close: 1.07244,
        high: 1.07257,
        low: 1.07212
      },
      {
        timestamp: 1650936600,
        open: 1.07244,
        close: 1.07249,
        high: 1.07275,
        low: 1.07238
      },
      {
        timestamp: 1650936900,
        open: 1.07249,
        close: 1.07313,
        high: 1.07315,
        low: 1.07248
      },
      {
        timestamp: 1650937200,
        open: 1.07314,
        close: 1.07314,
        high: 1.07324,
        low: 1.07307
      },
      {
        timestamp: 1650937500,
        open: 1.07314,
        close: 1.07263,
        high: 1.07317,
        low: 1.07258
      },
      {
        timestamp: 1650937800,
        open: 1.07263,
        close: 1.07248,
        high: 1.07269,
        low: 1.07243
      },
      {
        timestamp: 1650938100,
        open: 1.07247,
        close: 1.07257,
        high: 1.0726,
        low: 1.07236
      },
      {
        timestamp: 1650938400,
        open: 1.07258,
        close: 1.07264,
        high: 1.07273,
        low: 1.07246
      },
      {
        timestamp: 1650938700,
        open: 1.07264,
        close: 1.07293,
        high: 1.07295,
        low: 1.07264
      },
      {
        timestamp: 1650939000,
        open: 1.07293,
        close: 1.07258,
        high: 1.07293,
        low: 1.07255
      },
      {
        timestamp: 1650939300,
        open: 1.0726,
        close: 1.07274,
        high: 1.07274,
        low: 1.07246
      },
      {
        timestamp: 1650939600,
        open: 1.07274,
        close: 1.07278,
        high: 1.07287,
        low: 1.0726
      },
      {
        timestamp: 1650939900,
        open: 1.07279,
        close: 1.07309,
        high: 1.07314,
        low: 1.07275
      },
      {
        timestamp: 1650940200,
        open: 1.07311,
        close: 1.0729,
        high: 1.07324,
        low: 1.07288
      },
      {
        timestamp: 1650940500,
        open: 1.07292,
        close: 1.07274,
        high: 1.07299,
        low: 1.07259
      },
      {
        timestamp: 1650940800,
        open: 1.07273,
        close: 1.07279,
        high: 1.07294,
        low: 1.0726
      },
      {
        timestamp: 1650941100,
        open: 1.0728,
        close: 1.07307,
        high: 1.07313,
        low: 1.07272
      },
      {
        timestamp: 1650941400,
        open: 1.07307,
        close: 1.07318,
        high: 1.0732,
        low: 1.07288
      },
      {
        timestamp: 1650941700,
        open: 1.07317,
        close: 1.07305,
        high: 1.07317,
        low: 1.07301
      },
      {
        timestamp: 1650942000,
        open: 1.07306,
        close: 1.07339,
        high: 1.0736,
        low: 1.07303
      },
      {
        timestamp: 1650942300,
        open: 1.07339,
        close: 1.07356,
        high: 1.07363,
        low: 1.07333
      },
      {
        timestamp: 1650942600,
        open: 1.07356,
        close: 1.0733,
        high: 1.07363,
        low: 1.07327
      },
      {
        timestamp: 1650942900,
        open: 1.07331,
        close: 1.07332,
        high: 1.07357,
        low: 1.07326
      },
      {
        timestamp: 1650943200,
        open: 1.07331,
        close: 1.07361,
        high: 1.07363,
        low: 1.07329
      },
      {
        timestamp: 1650943500,
        open: 1.07361,
        close: 1.07374,
        high: 1.07376,
        low: 1.0736
      },
      {
        timestamp: 1650943800,
        open: 1.07375,
        close: 1.07362,
        high: 1.07394,
        low: 1.07362
      },
      {
        timestamp: 1650944100,
        open: 1.07363,
        close: 1.07341,
        high: 1.07364,
        low: 1.07339
      },
      {
        timestamp: 1650944400,
        open: 1.0734,
        close: 1.07351,
        high: 1.07361,
        low: 1.07339
      },
      {
        timestamp: 1650944700,
        open: 1.07351,
        close: 1.07351,
        high: 1.07363,
        low: 1.07318
      },
      {
        timestamp: 1650945000,
        open: 1.0735,
        close: 1.0734,
        high: 1.07354,
        low: 1.07334
      },
      {
        timestamp: 1650945300,
        open: 1.0734,
        close: 1.07328,
        high: 1.07344,
        low: 1.07318
      },
      {
        timestamp: 1650945600,
        open: 1.07331,
        close: 1.0732,
        high: 1.07341,
        low: 1.07319
      },
      {
        timestamp: 1650945900,
        open: 1.0732,
        close: 1.07309,
        high: 1.07331,
        low: 1.07295
      },
      {
        timestamp: 1650946200,
        open: 1.07309,
        close: 1.07318,
        high: 1.07321,
        low: 1.07305
      },
      {
        timestamp: 1650946500,
        open: 1.07319,
        close: 1.07319,
        high: 1.07324,
        low: 1.07313
      },
      {
        timestamp: 1650946800,
        open: 1.07318,
        close: 1.07335,
        high: 1.07335,
        low: 1.07313
      },
      {
        timestamp: 1650947100,
        open: 1.07334,
        close: 1.07299,
        high: 1.07338,
        low: 1.07298
      },
      {
        timestamp: 1650947400,
        open: 1.07301,
        close: 1.07301,
        high: 1.07314,
        low: 1.07287
      },
      {
        timestamp: 1650947700,
        open: 1.073,
        close: 1.07294,
        high: 1.07303,
        low: 1.07283
      },
      {
        timestamp: 1650948000,
        open: 1.07291,
        close: 1.0729,
        high: 1.07292,
        low: 1.07274
      },
      {
        timestamp: 1650948300,
        open: 1.07291,
        close: 1.07276,
        high: 1.07291,
        low: 1.07271
      },
      {
        timestamp: 1650948600,
        open: 1.07276,
        close: 1.07279,
        high: 1.0728,
        low: 1.07273
      },
      {
        timestamp: 1650948900,
        open: 1.07278,
        close: 1.07287,
        high: 1.07292,
        low: 1.07277
      },
      {
        timestamp: 1650949200,
        open: 1.07287,
        close: 1.07264,
        high: 1.07291,
        low: 1.07263
      },
      {
        timestamp: 1650949500,
        open: 1.07264,
        close: 1.07239,
        high: 1.0727,
        low: 1.07233
      },
      {
        timestamp: 1650949800,
        open: 1.07239,
        close: 1.07272,
        high: 1.0728,
        low: 1.07239
      },
      {
        timestamp: 1650950100,
        open: 1.07273,
        close: 1.07251,
        high: 1.07275,
        low: 1.07251
      },
      {
        timestamp: 1650950400,
        open: 1.07251,
        close: 1.07242,
        high: 1.0726,
        low: 1.07237
      },
      {
        timestamp: 1650950700,
        open: 1.07242,
        close: 1.0726,
        high: 1.0726,
        low: 1.07235
      },
      {
        timestamp: 1650951000,
        open: 1.0726,
        close: 1.07332,
        high: 1.0734,
        low: 1.07253
      },
      {
        timestamp: 1650951300,
        open: 1.07332,
        close: 1.07324,
        high: 1.07341,
        low: 1.07313
      },
      {
        timestamp: 1650951600,
        open: 1.07325,
        close: 1.07338,
        high: 1.07342,
        low: 1.07319
      },
      {
        timestamp: 1650951900,
        open: 1.07338,
        close: 1.07303,
        high: 1.07339,
        low: 1.07298
      },
      {
        timestamp: 1650952200,
        open: 1.07302,
        close: 1.07324,
        high: 1.07337,
        low: 1.07301
      },
      {
        timestamp: 1650952500,
        open: 1.07324,
        close: 1.07322,
        high: 1.07324,
        low: 1.07293
      },
      {
        timestamp: 1650952800,
        open: 1.07321,
        close: 1.07286,
        high: 1.07352,
        low: 1.07269
      },
      {
        timestamp: 1650953100,
        open: 1.07286,
        close: 1.07254,
        high: 1.07286,
        low: 1.07232
      },
      {
        timestamp: 1650953400,
        open: 1.07254,
        close: 1.07214,
        high: 1.07284,
        low: 1.07184
      },
      {
        timestamp: 1650953700,
        open: 1.07214,
        close: 1.07175,
        high: 1.07214,
        low: 1.07136
      },
      {
        timestamp: 1650954000,
        open: 1.07175,
        close: 1.07169,
        high: 1.07184,
        low: 1.07156
      },
      {
        timestamp: 1650954300,
        open: 1.0717,
        close: 1.07153,
        high: 1.07175,
        low: 1.07078
      },
      {
        timestamp: 1650954600,
        open: 1.07154,
        close: 1.07096,
        high: 1.07164,
        low: 1.07096
      },
      {
        timestamp: 1650954900,
        open: 1.07096,
        close: 1.07111,
        high: 1.0714,
        low: 1.07093
      },
      {
        timestamp: 1650955200,
        open: 1.0711,
        close: 1.07098,
        high: 1.07145,
        low: 1.07082
      },
      {
        timestamp: 1650955500,
        open: 1.07097,
        close: 1.07111,
        high: 1.07122,
        low: 1.0706
      },
      {
        timestamp: 1650955800,
        open: 1.07111,
        close: 1.0709,
        high: 1.07128,
        low: 1.07064
      },
      {
        timestamp: 1650956100,
        open: 1.07089,
        close: 1.07045,
        high: 1.07094,
        low: 1.07012
      },
      {
        timestamp: 1650956400,
        open: 1.07044,
        close: 1.07063,
        high: 1.07077,
        low: 1.06988
      },
      {
        timestamp: 1650956700,
        open: 1.07064,
        close: 1.0706,
        high: 1.07118,
        low: 1.07056
      },
      {
        timestamp: 1650957000,
        open: 1.0706,
        close: 1.07065,
        high: 1.07099,
        low: 1.07012
      },
      {
        timestamp: 1650957300,
        open: 1.07066,
        close: 1.06948,
        high: 1.07082,
        low: 1.06916
      },
      {
        timestamp: 1650957600,
        open: 1.06948,
        close: 1.06913,
        high: 1.06953,
        low: 1.06869
      },
      {
        timestamp: 1650957900,
        open: 1.06913,
        close: 1.0692,
        high: 1.06951,
        low: 1.06833
      },
      {
        timestamp: 1650958200,
        open: 1.06919,
        close: 1.06957,
        high: 1.06994,
        low: 1.06882
      },
      {
        timestamp: 1650958500,
        open: 1.06958,
        close: 1.06844,
        high: 1.0697,
        low: 1.06842
      },
      {
        timestamp: 1650958800,
        open: 1.06844,
        close: 1.06818,
        high: 1.06853,
        low: 1.06809
      },
      {
        timestamp: 1650959100,
        open: 1.06818,
        close: 1.06848,
        high: 1.06867,
        low: 1.06818
      },
      {
        timestamp: 1650959400,
        open: 1.06847,
        close: 1.06852,
        high: 1.06873,
        low: 1.06844
      },
      {
        timestamp: 1650959700,
        open: 1.06851,
        close: 1.06769,
        high: 1.06865,
        low: 1.0676
      },
      {
        timestamp: 1650960000,
        open: 1.06771,
        close: 1.06762,
        high: 1.06813,
        low: 1.06733
      },
      {
        timestamp: 1650960300,
        open: 1.06762,
        close: 1.06782,
        high: 1.06853,
        low: 1.06762
      },
      {
        timestamp: 1650960600,
        open: 1.06782,
        close: 1.06795,
        high: 1.06802,
        low: 1.06757
      },
      {
        timestamp: 1650960900,
        open: 1.06794,
        close: 1.06844,
        high: 1.06844,
        low: 1.06788
      },
      {
        timestamp: 1650961200,
        open: 1.06843,
        close: 1.06894,
        high: 1.06901,
        low: 1.06837
      },
      {
        timestamp: 1650961500,
        open: 1.06894,
        close: 1.06903,
        high: 1.06928,
        low: 1.06882
      },
      {
        timestamp: 1650961800,
        open: 1.06903,
        close: 1.06873,
        high: 1.0693,
        low: 1.06846
      },
      {
        timestamp: 1650962100,
        open: 1.06873,
        close: 1.06835,
        high: 1.06889,
        low: 1.06833
      },
      {
        timestamp: 1650962400,
        open: 1.06835,
        close: 1.06862,
        high: 1.06893,
        low: 1.06827
      },
      {
        timestamp: 1650962700,
        open: 1.06861,
        close: 1.06858,
        high: 1.06909,
        low: 1.06856
      },
      {
        timestamp: 1650963000,
        open: 1.06856,
        close: 1.06842,
        high: 1.06892,
        low: 1.06828
      },
      {
        timestamp: 1650963300,
        open: 1.06842,
        close: 1.06818,
        high: 1.06843,
        low: 1.06791
      },
      {
        timestamp: 1650963600,
        open: 1.06818,
        close: 1.06885,
        high: 1.06893,
        low: 1.06815
      },
      {
        timestamp: 1650963900,
        open: 1.06885,
        close: 1.06896,
        high: 1.06896,
        low: 1.06846
      },
      {
        timestamp: 1650964200,
        open: 1.06895,
        close: 1.06913,
        high: 1.06922,
        low: 1.06876
      },
      {
        timestamp: 1650964500,
        open: 1.06916,
        close: 1.06891,
        high: 1.06924,
        low: 1.06874
      },
      {
        timestamp: 1650964800,
        open: 1.06891,
        close: 1.06935,
        high: 1.06959,
        low: 1.06875
      },
      {
        timestamp: 1650965100,
        open: 1.06936,
        close: 1.06955,
        high: 1.06959,
        low: 1.06927
      },
      {
        timestamp: 1650965400,
        open: 1.06955,
        close: 1.06964,
        high: 1.06971,
        low: 1.06939
      },
      {
        timestamp: 1650965700,
        open: 1.06963,
        close: 1.06951,
        high: 1.06969,
        low: 1.06927
      },
      {
        timestamp: 1650966000,
        open: 1.06952,
        close: 1.06925,
        high: 1.06968,
        low: 1.06914
      },
      {
        timestamp: 1650966300,
        open: 1.06926,
        close: 1.06912,
        high: 1.06977,
        low: 1.06897
      },
      {
        timestamp: 1650966600,
        open: 1.0691,
        close: 1.06897,
        high: 1.06926,
        low: 1.06881
      },
      {
        timestamp: 1650966900,
        open: 1.06896,
        close: 1.06873,
        high: 1.06897,
        low: 1.06851
      },
      {
        timestamp: 1650967200,
        open: 1.06873,
        close: 1.0686,
        high: 1.0688,
        low: 1.06842
      },
      {
        timestamp: 1650967500,
        open: 1.0686,
        close: 1.06845,
        high: 1.06871,
        low: 1.06841
      },
      {
        timestamp: 1650967800,
        open: 1.06846,
        close: 1.06837,
        high: 1.06861,
        low: 1.0682
      },
      {
        timestamp: 1650968100,
        open: 1.06837,
        close: 1.0683,
        high: 1.06837,
        low: 1.06796
      },
      {
        timestamp: 1650968400,
        open: 1.06832,
        close: 1.06869,
        high: 1.06886,
        low: 1.06832
      },
      {
        timestamp: 1650968700,
        open: 1.06868,
        close: 1.06899,
        high: 1.06916,
        low: 1.06839
      },
      {
        timestamp: 1650969000,
        open: 1.069,
        close: 1.06874,
        high: 1.069,
        low: 1.06868
      },
      {
        timestamp: 1650969300,
        open: 1.06875,
        close: 1.06939,
        high: 1.06941,
        low: 1.06874
      },
      {
        timestamp: 1650969600,
        open: 1.06938,
        close: 1.06911,
        high: 1.06944,
        low: 1.06899
      },
      {
        timestamp: 1650969900,
        open: 1.06911,
        close: 1.06917,
        high: 1.06947,
        low: 1.06911
      },
      {
        timestamp: 1650970200,
        open: 1.06917,
        close: 1.06952,
        high: 1.06981,
        low: 1.06911
      },
      {
        timestamp: 1650970500,
        open: 1.06952,
        close: 1.06901,
        high: 1.06956,
        low: 1.06887
      },
      {
        timestamp: 1650970800,
        open: 1.06902,
        close: 1.06845,
        high: 1.06907,
        low: 1.06845
      },
      {
        timestamp: 1650971100,
        open: 1.06844,
        close: 1.06857,
        high: 1.06866,
        low: 1.06839
      },
      {
        timestamp: 1650971400,
        open: 1.06856,
        close: 1.06865,
        high: 1.06882,
        low: 1.06822
      },
      {
        timestamp: 1650971700,
        open: 1.06864,
        close: 1.06889,
        high: 1.06946,
        low: 1.06862
      },
      {
        timestamp: 1650972000,
        open: 1.06889,
        close: 1.06889,
        high: 1.06898,
        low: 1.06863
      },
      {
        timestamp: 1650972300,
        open: 1.06888,
        close: 1.06869,
        high: 1.06889,
        low: 1.06855
      },
      {
        timestamp: 1650972600,
        open: 1.06869,
        close: 1.06834,
        high: 1.06876,
        low: 1.06822
      },
      {
        timestamp: 1650972900,
        open: 1.06834,
        close: 1.06823,
        high: 1.06845,
        low: 1.06818
      },
      {
        timestamp: 1650973200,
        open: 1.06823,
        close: 1.06845,
        high: 1.06846,
        low: 1.06823
      },
      {
        timestamp: 1650973500,
        open: 1.06846,
        close: 1.06859,
        high: 1.06876,
        low: 1.06837
      },
      {
        timestamp: 1650973800,
        open: 1.06859,
        close: 1.0677,
        high: 1.06859,
        low: 1.06762
      },
      {
        timestamp: 1650974100,
        open: 1.06768,
        close: 1.06713,
        high: 1.06802,
        low: 1.06698
      },
      {
        timestamp: 1650974400,
        open: 1.06714,
        close: 1.06768,
        high: 1.06776,
        low: 1.06713
      },
      {
        timestamp: 1650974700,
        open: 1.06769,
        close: 1.06734,
        high: 1.06777,
        low: 1.06733
      },
      {
        timestamp: 1650975000,
        open: 1.06735,
        close: 1.06749,
        high: 1.06772,
        low: 1.06732
      },
      {
        timestamp: 1650975300,
        open: 1.06749,
        close: 1.06733,
        high: 1.0675,
        low: 1.06712
      },
      {
        timestamp: 1650975600,
        open: 1.06733,
        close: 1.06739,
        high: 1.06759,
        low: 1.0672
      },
      {
        timestamp: 1650975900,
        open: 1.0674,
        close: 1.06751,
        high: 1.06754,
        low: 1.06731
      },
      {
        timestamp: 1650976200,
        open: 1.06752,
        close: 1.06736,
        high: 1.06771,
        low: 1.06734
      },
      {
        timestamp: 1650976500,
        open: 1.06736,
        close: 1.06731,
        high: 1.06761,
        low: 1.06713
      },
      {
        timestamp: 1650976800,
        open: 1.0673,
        close: 1.06753,
        high: 1.06778,
        low: 1.06708
      },
      {
        timestamp: 1650977100,
        open: 1.06753,
        close: 1.06806,
        high: 1.06816,
        low: 1.06747
      },
      {
        timestamp: 1650977400,
        open: 1.06806,
        close: 1.06834,
        high: 1.06837,
        low: 1.06781
      },
      {
        timestamp: 1650977700,
        open: 1.06833,
        close: 1.06795,
        high: 1.06845,
        low: 1.06736
      },
      {
        timestamp: 1650978000,
        open: 1.06795,
        close: 1.06752,
        high: 1.06798,
        low: 1.06737
      },
      {
        timestamp: 1650978300,
        open: 1.06753,
        close: 1.06818,
        high: 1.06831,
        low: 1.0675
      },
      {
        timestamp: 1650978600,
        open: 1.06819,
        close: 1.06734,
        high: 1.0682,
        low: 1.06725
      },
      {
        timestamp: 1650978900,
        open: 1.06733,
        close: 1.06691,
        high: 1.06735,
        low: 1.06644
      },
      {
        timestamp: 1650979200,
        open: 1.06691,
        close: 1.06739,
        high: 1.06742,
        low: 1.06663
      },
      {
        timestamp: 1650979500,
        open: 1.06741,
        close: 1.06819,
        high: 1.06838,
        low: 1.06739
      },
      {
        timestamp: 1650979800,
        open: 1.0682,
        close: 1.06804,
        high: 1.06846,
        low: 1.06796
      },
      {
        timestamp: 1650980100,
        open: 1.06803,
        close: 1.06791,
        high: 1.0686,
        low: 1.06767
      },
      {
        timestamp: 1650980400,
        open: 1.0679,
        close: 1.06813,
        high: 1.06835,
        low: 1.06771
      },
      {
        timestamp: 1650980700,
        open: 1.06812,
        close: 1.06765,
        high: 1.0682,
        low: 1.06763
      },
      {
        timestamp: 1650981000,
        open: 1.06764,
        close: 1.06743,
        high: 1.06772,
        low: 1.0673
      },
      {
        timestamp: 1650981300,
        open: 1.06742,
        close: 1.06754,
        high: 1.06798,
        low: 1.06736
      },
      {
        timestamp: 1650981600,
        open: 1.06752,
        close: 1.0669,
        high: 1.06757,
        low: 1.06675
      },
      {
        timestamp: 1650981900,
        open: 1.0669,
        close: 1.06669,
        high: 1.06711,
        low: 1.0666
      },
      {
        timestamp: 1650982200,
        open: 1.06668,
        close: 1.06731,
        high: 1.06745,
        low: 1.06633
      },
      {
        timestamp: 1650982500,
        open: 1.06731,
        close: 1.06718,
        high: 1.06736,
        low: 1.06692
      },
      {
        timestamp: 1650982800,
        open: 1.0672,
        close: 1.06747,
        high: 1.06754,
        low: 1.06703
      },
      {
        timestamp: 1650983100,
        open: 1.06747,
        close: 1.06765,
        high: 1.06782,
        low: 1.06719
      },
      {
        timestamp: 1650983400,
        open: 1.06764,
        close: 1.06719,
        high: 1.06771,
        low: 1.06716
      },
      {
        timestamp: 1650983700,
        open: 1.0672,
        close: 1.06642,
        high: 1.06725,
        low: 1.06639
      },
      {
        timestamp: 1650984000,
        open: 1.06643,
        close: 1.0658,
        high: 1.06665,
        low: 1.06568
      },
      {
        timestamp: 1650984300,
        open: 1.06577,
        close: 1.06462,
        high: 1.06579,
        low: 1.06455
      },
      {
        timestamp: 1650984600,
        open: 1.06463,
        close: 1.06519,
        high: 1.06519,
        low: 1.06424
      },
      {
        timestamp: 1650984900,
        open: 1.0652,
        close: 1.06516,
        high: 1.06573,
        low: 1.06486
      },
      {
        timestamp: 1650985200,
        open: 1.06515,
        close: 1.06487,
        high: 1.06517,
        low: 1.0645
      },
      {
        timestamp: 1650985500,
        open: 1.06487,
        close: 1.06581,
        high: 1.06632,
        low: 1.06476
      },
      {
        timestamp: 1650985800,
        open: 1.0658,
        close: 1.06558,
        high: 1.06623,
        low: 1.06552
      },
      {
        timestamp: 1650986100,
        open: 1.06558,
        close: 1.06544,
        high: 1.06598,
        low: 1.06535
      },
      {
        timestamp: 1650986400,
        open: 1.06543,
        close: 1.06565,
        high: 1.06599,
        low: 1.06535
      },
      {
        timestamp: 1650986700,
        open: 1.06564,
        close: 1.0662,
        high: 1.0663,
        low: 1.06564
      },
      {
        timestamp: 1650987000,
        open: 1.06619,
        close: 1.06597,
        high: 1.06643,
        low: 1.06597
      },
      {
        timestamp: 1650987300,
        open: 1.06597,
        close: 1.06583,
        high: 1.06616,
        low: 1.06563
      },
      {
        timestamp: 1650987600,
        open: 1.06581,
        close: 1.06627,
        high: 1.06635,
        low: 1.06562
      },
      {
        timestamp: 1650987900,
        open: 1.06626,
        close: 1.06569,
        high: 1.06633,
        low: 1.06568
      },
      {
        timestamp: 1650988200,
        open: 1.06568,
        close: 1.06594,
        high: 1.066,
        low: 1.06564
      },
      {
        timestamp: 1650988500,
        open: 1.06595,
        close: 1.066,
        high: 1.06626,
        low: 1.06558
      },
      {
        timestamp: 1650988800,
        open: 1.066,
        close: 1.06633,
        high: 1.06647,
        low: 1.06596
      },
      {
        timestamp: 1650989100,
        open: 1.06633,
        close: 1.06623,
        high: 1.06638,
        low: 1.06585
      },
      {
        timestamp: 1650989400,
        open: 1.06624,
        close: 1.06614,
        high: 1.06649,
        low: 1.06569
      },
      {
        timestamp: 1650989700,
        open: 1.06614,
        close: 1.06651,
        high: 1.06654,
        low: 1.06609
      },
      {
        timestamp: 1650990000,
        open: 1.06652,
        close: 1.06641,
        high: 1.06658,
        low: 1.06622
      },
      {
        timestamp: 1650990300,
        open: 1.0664,
        close: 1.06653,
        high: 1.06654,
        low: 1.06614
      },
      {
        timestamp: 1650990600,
        open: 1.06652,
        close: 1.06607,
        high: 1.06652,
        low: 1.066
      },
      {
        timestamp: 1650990900,
        open: 1.06608,
        close: 1.06565,
        high: 1.06609,
        low: 1.06552
      },
      {
        timestamp: 1650991200,
        open: 1.06565,
        close: 1.06567,
        high: 1.06574,
        low: 1.06545
      },
      {
        timestamp: 1650991500,
        open: 1.06568,
        close: 1.06571,
        high: 1.06593,
        low: 1.06552
      },
      {
        timestamp: 1650991800,
        open: 1.06571,
        close: 1.06608,
        high: 1.06611,
        low: 1.06559
      },
      {
        timestamp: 1650992100,
        open: 1.06608,
        close: 1.06598,
        high: 1.0663,
        low: 1.06565
      },
      {
        timestamp: 1650992400,
        open: 1.06597,
        close: 1.06593,
        high: 1.06622,
        low: 1.06573
      },
      {
        timestamp: 1650992700,
        open: 1.06593,
        close: 1.06531,
        high: 1.06606,
        low: 1.06522
      },
      {
        timestamp: 1650993000,
        open: 1.06531,
        close: 1.06542,
        high: 1.06559,
        low: 1.06528
      },
      {
        timestamp: 1650993300,
        open: 1.06542,
        close: 1.06542,
        high: 1.06564,
        low: 1.06542
      },
      {
        timestamp: 1650993600,
        open: 1.06541,
        close: 1.06542,
        high: 1.06551,
        low: 1.06525
      },
      {
        timestamp: 1650993900,
        open: 1.06542,
        close: 1.06559,
        high: 1.06574,
        low: 1.06538
      },
      {
        timestamp: 1650994200,
        open: 1.06559,
        close: 1.06535,
        high: 1.06576,
        low: 1.06519
      },
      {
        timestamp: 1650994500,
        open: 1.06535,
        close: 1.06545,
        high: 1.06547,
        low: 1.06523
      },
      {
        timestamp: 1650994800,
        open: 1.06546,
        close: 1.06554,
        high: 1.06568,
        low: 1.06533
      },
      {
        timestamp: 1650995100,
        open: 1.06554,
        close: 1.06544,
        high: 1.06569,
        low: 1.06536
      },
      {
        timestamp: 1650995400,
        open: 1.06544,
        close: 1.06521,
        high: 1.06545,
        low: 1.06515
      },
      {
        timestamp: 1650995700,
        open: 1.06522,
        close: 1.06473,
        high: 1.06526,
        low: 1.06463
      },
      {
        timestamp: 1650996000,
        open: 1.06472,
        close: 1.06477,
        high: 1.06485,
        low: 1.06442
      },
      {
        timestamp: 1650996300,
        open: 1.06478,
        close: 1.06464,
        high: 1.06494,
        low: 1.06463
      },
      {
        timestamp: 1650996600,
        open: 1.06465,
        close: 1.06461,
        high: 1.06476,
        low: 1.06458
      },
      {
        timestamp: 1650996900,
        open: 1.06461,
        close: 1.06466,
        high: 1.06498,
        low: 1.06454
      },
      {
        timestamp: 1650997200,
        open: 1.06465,
        close: 1.0649,
        high: 1.06501,
        low: 1.06453
      },
      {
        timestamp: 1650997500,
        open: 1.06491,
        close: 1.06514,
        high: 1.0652,
        low: 1.06484
      },
      {
        timestamp: 1650997800,
        open: 1.06513,
        close: 1.06456,
        high: 1.06513,
        low: 1.06442
      },
      {
        timestamp: 1650998100,
        open: 1.06456,
        close: 1.06459,
        high: 1.06485,
        low: 1.06455
      },
      {
        timestamp: 1650998400,
        open: 1.06458,
        close: 1.0643,
        high: 1.06458,
        low: 1.06426
      },
      {
        timestamp: 1650998700,
        open: 1.06429,
        close: 1.06445,
        high: 1.06459,
        low: 1.06412
      },
      {
        timestamp: 1650999000,
        open: 1.06445,
        close: 1.06451,
        high: 1.06454,
        low: 1.06429
      },
      {
        timestamp: 1650999300,
        open: 1.06451,
        close: 1.06455,
        high: 1.06464,
        low: 1.06439
      },
      {
        timestamp: 1650999600,
        open: 1.06456,
        close: 1.06475,
        high: 1.06483,
        low: 1.06453
      },
      {
        timestamp: 1650999900,
        open: 1.06473,
        close: 1.06442,
        high: 1.06474,
        low: 1.06441
      },
      {
        timestamp: 1651000200,
        open: 1.06442,
        close: 1.06437,
        high: 1.06452,
        low: 1.06422
      },
      {
        timestamp: 1651000500,
        open: 1.06437,
        close: 1.06442,
        high: 1.06443,
        low: 1.06422
      },
      {
        timestamp: 1651000800,
        open: 1.06444,
        close: 1.06418,
        high: 1.06449,
        low: 1.0641
      },
      {
        timestamp: 1651001100,
        open: 1.06419,
        close: 1.06406,
        high: 1.06424,
        low: 1.06401
      },
      {
        timestamp: 1651001400,
        open: 1.06405,
        close: 1.06402,
        high: 1.06408,
        low: 1.06394
      },
      {
        timestamp: 1651001700,
        open: 1.06403,
        close: 1.06409,
        high: 1.0641,
        low: 1.06394
      },
      {
        timestamp: 1651002000,
        open: 1.0641,
        close: 1.06414,
        high: 1.06415,
        low: 1.06399
      },
      {
        timestamp: 1651002300,
        open: 1.06414,
        close: 1.06399,
        high: 1.06428,
        low: 1.06392
      },
      {
        timestamp: 1651002600,
        open: 1.06398,
        close: 1.06402,
        high: 1.06403,
        low: 1.06389
      },
      {
        timestamp: 1651002900,
        open: 1.06403,
        close: 1.06445,
        high: 1.06456,
        low: 1.064
      },
      {
        timestamp: 1651003200,
        open: 1.06446,
        close: 1.06418,
        high: 1.06446,
        low: 1.0641
      },
      {
        timestamp: 1651003500,
        open: 1.06418,
        close: 1.06398,
        high: 1.06418,
        low: 1.06392
      },
      {
        timestamp: 1651003800,
        open: 1.06399,
        close: 1.064,
        high: 1.06406,
        low: 1.06392
      },
      {
        timestamp: 1651004100,
        open: 1.064,
        close: 1.06395,
        high: 1.06402,
        low: 1.06391
      },
      {
        timestamp: 1651004400,
        open: 1.06394,
        close: 1.06394,
        high: 1.06398,
        low: 1.06382
      },
      {
        timestamp: 1651004700,
        open: 1.06394,
        close: 1.06401,
        high: 1.06406,
        low: 1.06391
      },
      {
        timestamp: 1651005000,
        open: 1.06401,
        close: 1.06389,
        high: 1.06401,
        low: 1.06389
      },
      {
        timestamp: 1651005300,
        open: 1.06389,
        close: 1.06389,
        high: 1.06395,
        low: 1.06388
      },
      {
        timestamp: 1651005600,
        open: 1.0639,
        close: 1.06393,
        high: 1.06407,
        low: 1.06387
      },
      {
        timestamp: 1651005900,
        open: 1.06393,
        close: 1.06383,
        high: 1.06404,
        low: 1.0638
      },
      {
        timestamp: 1651006200,
        open: 1.06383,
        close: 1.06379,
        high: 1.06385,
        low: 1.06369
      },
      {
        timestamp: 1651006500,
        open: 1.06379,
        close: 1.06392,
        high: 1.06393,
        low: 1.06365
      },
      {
        timestamp: 1651006800,
        open: 1.06387,
        close: 1.06375,
        high: 1.06398,
        low: 1.06372
      },
      {
        timestamp: 1651007100,
        open: 1.06373,
        close: 1.0638,
        high: 1.06382,
        low: 1.06368
      },
      {
        timestamp: 1651007400,
        open: 1.0638,
        close: 1.06377,
        high: 1.06384,
        low: 1.06371
      },
      {
        timestamp: 1651007700,
        open: 1.06376,
        close: 1.06409,
        high: 1.06413,
        low: 1.06376
      },
      {
        timestamp: 1651008000,
        open: 1.0641,
        close: 1.06401,
        high: 1.0641,
        low: 1.06401
      },
      {
        timestamp: 1651008300,
        open: 1.064,
        close: 1.06397,
        high: 1.064,
        low: 1.06376
      },
      {
        timestamp: 1651008600,
        open: 1.06398,
        close: 1.064,
        high: 1.064,
        low: 1.06397
      },
      {
        timestamp: 1651008900,
        open: 1.064,
        close: 1.06396,
        high: 1.064,
        low: 1.06395
      },
      {
        timestamp: 1651009200,
        open: 1.06396,
        close: 1.06401,
        high: 1.06401,
        low: 1.06396
      },
      {
        timestamp: 1651009500,
        open: 1.064,
        close: 1.06408,
        high: 1.06418,
        low: 1.06398
      },
      {
        timestamp: 1651009800,
        open: 1.0641,
        close: 1.06411,
        high: 1.06413,
        low: 1.0641
      },
      {
        timestamp: 1651010100,
        open: 1.06412,
        close: 1.06414,
        high: 1.06426,
        low: 1.06402
      },
      {
        timestamp: 1651010400,
        open: 1.06411,
        close: 1.06422,
        high: 1.06431,
        low: 1.06384
      },
      {
        timestamp: 1651010700,
        open: 1.06423,
        close: 1.06428,
        high: 1.06437,
        low: 1.06421
      },
      {
        timestamp: 1651011000,
        open: 1.06427,
        close: 1.06402,
        high: 1.06432,
        low: 1.06397
      },
      {
        timestamp: 1651011300,
        open: 1.06403,
        close: 1.0638,
        high: 1.06407,
        low: 1.0638
      },
      {
        timestamp: 1651011600,
        open: 1.0638,
        close: 1.06361,
        high: 1.06384,
        low: 1.06358
      },
      {
        timestamp: 1651011900,
        open: 1.06359,
        close: 1.06393,
        high: 1.06395,
        low: 1.06358
      },
      {
        timestamp: 1651012200,
        open: 1.06393,
        close: 1.06405,
        high: 1.06426,
        low: 1.06389
      },
      {
        timestamp: 1651012500,
        open: 1.06406,
        close: 1.06428,
        high: 1.06429,
        low: 1.06406
      },
      {
        timestamp: 1651012800,
        open: 1.0643,
        close: 1.06431,
        high: 1.06439,
        low: 1.06423
      },
      {
        timestamp: 1651013100,
        open: 1.0643,
        close: 1.06447,
        high: 1.06448,
        low: 1.06428
      },
      {
        timestamp: 1651013400,
        open: 1.06448,
        close: 1.06452,
        high: 1.06454,
        low: 1.0644
      },
      {
        timestamp: 1651013700,
        open: 1.06452,
        close: 1.06457,
        high: 1.06465,
        low: 1.06448
      },
      {
        timestamp: 1651014000,
        open: 1.06457,
        close: 1.06455,
        high: 1.06466,
        low: 1.06446
      },
      {
        timestamp: 1651014300,
        open: 1.06454,
        close: 1.06455,
        high: 1.0646,
        low: 1.06445
      },
      {
        timestamp: 1651014600,
        open: 1.06456,
        close: 1.06458,
        high: 1.06464,
        low: 1.06446
      },
      {
        timestamp: 1651014900,
        open: 1.06459,
        close: 1.06471,
        high: 1.06479,
        low: 1.06452
      },
      {
        timestamp: 1651015200,
        open: 1.06472,
        close: 1.06453,
        high: 1.06472,
        low: 1.06451
      },
      {
        timestamp: 1651015500,
        open: 1.06452,
        close: 1.06476,
        high: 1.0648,
        low: 1.06451
      },
      {
        timestamp: 1651015800,
        open: 1.06476,
        close: 1.06484,
        high: 1.06486,
        low: 1.06473
      },
      {
        timestamp: 1651016100,
        open: 1.06483,
        close: 1.06475,
        high: 1.06487,
        low: 1.0647
      },
      {
        timestamp: 1651016400,
        open: 1.06474,
        close: 1.06458,
        high: 1.06475,
        low: 1.06456
      },
      {
        timestamp: 1651016700,
        open: 1.06459,
        close: 1.06456,
        high: 1.06474,
        low: 1.06446
      },
      {
        timestamp: 1651017000,
        open: 1.06455,
        close: 1.06457,
        high: 1.06475,
        low: 1.06451
      },
      {
        timestamp: 1651017300,
        open: 1.06456,
        close: 1.06434,
        high: 1.06458,
        low: 1.0643
      },
      {
        timestamp: 1651017600,
        open: 1.06435,
        close: 1.06436,
        high: 1.06471,
        low: 1.06408
      },
      {
        timestamp: 1651017900,
        open: 1.06437,
        close: 1.06463,
        high: 1.06467,
        low: 1.0643
      },
      {
        timestamp: 1651018200,
        open: 1.06462,
        close: 1.06455,
        high: 1.06476,
        low: 1.06443
      },
      {
        timestamp: 1651018500,
        open: 1.06454,
        close: 1.06415,
        high: 1.06456,
        low: 1.06398
      },
      {
        timestamp: 1651018800,
        open: 1.06415,
        close: 1.06404,
        high: 1.06417,
        low: 1.06397
      },
      {
        timestamp: 1651019100,
        open: 1.06402,
        close: 1.06403,
        high: 1.06411,
        low: 1.06388
      },
      {
        timestamp: 1651019400,
        open: 1.06404,
        close: 1.06399,
        high: 1.06408,
        low: 1.06387
      },
      {
        timestamp: 1651019700,
        open: 1.06399,
        close: 1.064,
        high: 1.06403,
        low: 1.06387
      },
      {
        timestamp: 1651020000,
        open: 1.064,
        close: 1.06376,
        high: 1.06402,
        low: 1.06356
      },
      {
        timestamp: 1651020300,
        open: 1.06376,
        close: 1.06398,
        high: 1.06417,
        low: 1.06373
      },
      {
        timestamp: 1651020600,
        open: 1.06398,
        close: 1.06406,
        high: 1.06422,
        low: 1.06392
      },
      {
        timestamp: 1651020900,
        open: 1.06406,
        close: 1.06443,
        high: 1.06444,
        low: 1.06393
      },
      {
        timestamp: 1651021200,
        open: 1.06444,
        close: 1.06398,
        high: 1.06458,
        low: 1.06395
      },
      {
        timestamp: 1651021500,
        open: 1.06398,
        close: 1.06417,
        high: 1.06454,
        low: 1.06391
      },
      {
        timestamp: 1651021800,
        open: 1.06418,
        close: 1.06407,
        high: 1.06425,
        low: 1.06404
      },
      {
        timestamp: 1651022100,
        open: 1.06407,
        close: 1.06417,
        high: 1.06426,
        low: 1.06404
      },
      {
        timestamp: 1651022400,
        open: 1.06418,
        close: 1.06432,
        high: 1.06456,
        low: 1.06409
      },
      {
        timestamp: 1651022700,
        open: 1.06433,
        close: 1.0641,
        high: 1.06441,
        low: 1.06407
      },
      {
        timestamp: 1651023000,
        open: 1.06409,
        close: 1.06426,
        high: 1.06457,
        low: 1.06405
      },
      {
        timestamp: 1651023300,
        open: 1.06426,
        close: 1.06429,
        high: 1.06435,
        low: 1.06387
      },
      {
        timestamp: 1651023600,
        open: 1.06428,
        close: 1.06378,
        high: 1.0643,
        low: 1.06373
      },
      {
        timestamp: 1651023900,
        open: 1.06377,
        close: 1.06437,
        high: 1.0644,
        low: 1.06366
      },
      {
        timestamp: 1651024200,
        open: 1.06437,
        close: 1.06445,
        high: 1.06456,
        low: 1.0643
      },
      {
        timestamp: 1651024500,
        open: 1.06444,
        close: 1.06474,
        high: 1.06476,
        low: 1.0644
      },
      {
        timestamp: 1651024800,
        open: 1.06474,
        close: 1.06519,
        high: 1.06535,
        low: 1.06461
      },
      {
        timestamp: 1651025100,
        open: 1.0652,
        close: 1.06509,
        high: 1.06542,
        low: 1.06497
      },
      {
        timestamp: 1651025400,
        open: 1.06509,
        close: 1.06521,
        high: 1.06529,
        low: 1.06505
      },
      {
        timestamp: 1651025700,
        open: 1.06522,
        close: 1.06488,
        high: 1.06522,
        low: 1.06475
      },
      {
        timestamp: 1651026000,
        open: 1.06488,
        close: 1.06496,
        high: 1.06519,
        low: 1.06477
      },
      {
        timestamp: 1651026300,
        open: 1.06495,
        close: 1.06478,
        high: 1.065,
        low: 1.06467
      },
      {
        timestamp: 1651026600,
        open: 1.06479,
        close: 1.06467,
        high: 1.0648,
        low: 1.0646
      },
      {
        timestamp: 1651026900,
        open: 1.06467,
        close: 1.06464,
        high: 1.06474,
        low: 1.0645
      },
      {
        timestamp: 1651027200,
        open: 1.06464,
        close: 1.06481,
        high: 1.06483,
        low: 1.06464
      },
      {
        timestamp: 1651027500,
        open: 1.06482,
        close: 1.06453,
        high: 1.06484,
        low: 1.06452
      },
      {
        timestamp: 1651027800,
        open: 1.06453,
        close: 1.06475,
        high: 1.06494,
        low: 1.06448
      },
      {
        timestamp: 1651028100,
        open: 1.06476,
        close: 1.06351,
        high: 1.06479,
        low: 1.06349
      },
      {
        timestamp: 1651028400,
        open: 1.06352,
        close: 1.06384,
        high: 1.06418,
        low: 1.06351
      },
      {
        timestamp: 1651028700,
        open: 1.06385,
        close: 1.0639,
        high: 1.06398,
        low: 1.06344
      },
      {
        timestamp: 1651029000,
        open: 1.06389,
        close: 1.06406,
        high: 1.06433,
        low: 1.06388
      },
      {
        timestamp: 1651029300,
        open: 1.06407,
        close: 1.06412,
        high: 1.0643,
        low: 1.06398
      },
      {
        timestamp: 1651029600,
        open: 1.06411,
        close: 1.06414,
        high: 1.06422,
        low: 1.06398
      },
      {
        timestamp: 1651029900,
        open: 1.06415,
        close: 1.06415,
        high: 1.06426,
        low: 1.06404
      },
      {
        timestamp: 1651030200,
        open: 1.06414,
        close: 1.06442,
        high: 1.06447,
        low: 1.06413
      },
      {
        timestamp: 1651030500,
        open: 1.06441,
        close: 1.06422,
        high: 1.06446,
        low: 1.0641
      },
      {
        timestamp: 1651030800,
        open: 1.06422,
        close: 1.06419,
        high: 1.06425,
        low: 1.06403
      },
      {
        timestamp: 1651031100,
        open: 1.06418,
        close: 1.06423,
        high: 1.06428,
        low: 1.06397
      },
      {
        timestamp: 1651031400,
        open: 1.06422,
        close: 1.06412,
        high: 1.06434,
        low: 1.06412
      },
      {
        timestamp: 1651031700,
        open: 1.06413,
        close: 1.06414,
        high: 1.06419,
        low: 1.06405
      },
      {
        timestamp: 1651032000,
        open: 1.06412,
        close: 1.06398,
        high: 1.06426,
        low: 1.06388
      },
      {
        timestamp: 1651032300,
        open: 1.06399,
        close: 1.06407,
        high: 1.06421,
        low: 1.06396
      },
      {
        timestamp: 1651032600,
        open: 1.06407,
        close: 1.06407,
        high: 1.06408,
        low: 1.06386
      },
      {
        timestamp: 1651032900,
        open: 1.06407,
        close: 1.0639,
        high: 1.06408,
        low: 1.06387
      },
      {
        timestamp: 1651033200,
        open: 1.06391,
        close: 1.06398,
        high: 1.06401,
        low: 1.06384
      },
      {
        timestamp: 1651033500,
        open: 1.06397,
        close: 1.06425,
        high: 1.06427,
        low: 1.06397
      },
      {
        timestamp: 1651033800,
        open: 1.06426,
        close: 1.0644,
        high: 1.06461,
        low: 1.06425
      },
      {
        timestamp: 1651034100,
        open: 1.06441,
        close: 1.06433,
        high: 1.06455,
        low: 1.0643
      },
      {
        timestamp: 1651034400,
        open: 1.06434,
        close: 1.06445,
        high: 1.06455,
        low: 1.06427
      },
      {
        timestamp: 1651034700,
        open: 1.06444,
        close: 1.06473,
        high: 1.06473,
        low: 1.06443
      },
      {
        timestamp: 1651035000,
        open: 1.06473,
        close: 1.06458,
        high: 1.06474,
        low: 1.0645
      },
      {
        timestamp: 1651035300,
        open: 1.06458,
        close: 1.06504,
        high: 1.06511,
        low: 1.06453
      },
      {
        timestamp: 1651035600,
        open: 1.06505,
        close: 1.06514,
        high: 1.06518,
        low: 1.06482
      },
      {
        timestamp: 1651035900,
        open: 1.06514,
        close: 1.06504,
        high: 1.06518,
        low: 1.06499
      },
      {
        timestamp: 1651036200,
        open: 1.06504,
        close: 1.06511,
        high: 1.06519,
        low: 1.06489
      },
      {
        timestamp: 1651036500,
        open: 1.06512,
        close: 1.0654,
        high: 1.06544,
        low: 1.06504
      },
      {
        timestamp: 1651036800,
        open: 1.0654,
        close: 1.06537,
        high: 1.06548,
        low: 1.06527
      },
      {
        timestamp: 1651037100,
        open: 1.06536,
        close: 1.06542,
        high: 1.06549,
        low: 1.06529
      },
      {
        timestamp: 1651037400,
        open: 1.06543,
        close: 1.06523,
        high: 1.06553,
        low: 1.06516
      },
      {
        timestamp: 1651037700,
        open: 1.06524,
        close: 1.06511,
        high: 1.06527,
        low: 1.06502
      },
      {
        timestamp: 1651038000,
        open: 1.06511,
        close: 1.06544,
        high: 1.06553,
        low: 1.06511
      },
      {
        timestamp: 1651038300,
        open: 1.06544,
        close: 1.06513,
        high: 1.06545,
        low: 1.06503
      },
      {
        timestamp: 1651038600,
        open: 1.06512,
        close: 1.06514,
        high: 1.06517,
        low: 1.065
      },
      {
        timestamp: 1651038900,
        open: 1.06513,
        close: 1.06513,
        high: 1.06553,
        low: 1.06482
      },
      {
        timestamp: 1651039200,
        open: 1.06512,
        close: 1.06466,
        high: 1.06525,
        low: 1.06463
      },
      {
        timestamp: 1651039500,
        open: 1.06466,
        close: 1.06362,
        high: 1.0648,
        low: 1.06359
      },
      {
        timestamp: 1651039800,
        open: 1.0636,
        close: 1.06286,
        high: 1.06364,
        low: 1.06216
      },
      {
        timestamp: 1651040100,
        open: 1.06285,
        close: 1.06282,
        high: 1.06308,
        low: 1.06263
      },
      {
        timestamp: 1651040400,
        open: 1.06283,
        close: 1.06298,
        high: 1.06353,
        low: 1.06275
      },
      {
        timestamp: 1651040700,
        open: 1.06299,
        close: 1.06246,
        high: 1.06317,
        low: 1.06235
      },
      {
        timestamp: 1651041000,
        open: 1.06245,
        close: 1.06272,
        high: 1.06329,
        low: 1.06245
      },
      {
        timestamp: 1651041300,
        open: 1.06273,
        close: 1.06204,
        high: 1.06274,
        low: 1.06182
      },
      {
        timestamp: 1651041600,
        open: 1.06204,
        close: 1.06246,
        high: 1.06248,
        low: 1.0617
      },
      {
        timestamp: 1651041900,
        open: 1.06246,
        close: 1.06283,
        high: 1.06303,
        low: 1.06243
      },
      {
        timestamp: 1651042200,
        open: 1.06284,
        close: 1.06263,
        high: 1.0629,
        low: 1.06225
      },
      {
        timestamp: 1651042500,
        open: 1.06263,
        close: 1.06208,
        high: 1.06266,
        low: 1.06181
      },
      {
        timestamp: 1651042800,
        open: 1.06209,
        close: 1.06257,
        high: 1.06262,
        low: 1.06201
      },
      {
        timestamp: 1651043100,
        open: 1.06256,
        close: 1.06276,
        high: 1.06294,
        low: 1.06227
      },
      {
        timestamp: 1651043400,
        open: 1.06275,
        close: 1.06201,
        high: 1.06288,
        low: 1.06189
      },
      {
        timestamp: 1651043700,
        open: 1.06201,
        close: 1.06103,
        high: 1.06208,
        low: 1.06101
      },
      {
        timestamp: 1651044000,
        open: 1.06104,
        close: 1.05989,
        high: 1.06108,
        low: 1.05959
      },
      {
        timestamp: 1651044300,
        open: 1.0599,
        close: 1.05893,
        high: 1.0602,
        low: 1.05893
      },
      {
        timestamp: 1651044600,
        open: 1.05893,
        close: 1.06052,
        high: 1.06052,
        low: 1.05887
      },
      {
        timestamp: 1651044900,
        open: 1.06053,
        close: 1.06021,
        high: 1.06066,
        low: 1.05996
      },
      {
        timestamp: 1651045200,
        open: 1.0602,
        close: 1.06099,
        high: 1.06106,
        low: 1.0602
      },
      {
        timestamp: 1651045500,
        open: 1.061,
        close: 1.06168,
        high: 1.06182,
        low: 1.06082
      },
      {
        timestamp: 1651045800,
        open: 1.06169,
        close: 1.06195,
        high: 1.06229,
        low: 1.06153
      },
      {
        timestamp: 1651046100,
        open: 1.06196,
        close: 1.06239,
        high: 1.0625,
        low: 1.06192
      },
      {
        timestamp: 1651046400,
        open: 1.06239,
        close: 1.06179,
        high: 1.06257,
        low: 1.06157
      },
      {
        timestamp: 1651046700,
        open: 1.06178,
        close: 1.06144,
        high: 1.06178,
        low: 1.0612
      },
      {
        timestamp: 1651047000,
        open: 1.06143,
        close: 1.06173,
        high: 1.06176,
        low: 1.06118
      },
      {
        timestamp: 1651047300,
        open: 1.06172,
        close: 1.0624,
        high: 1.06249,
        low: 1.06153
      },
      {
        timestamp: 1651047600,
        open: 1.0624,
        close: 1.06185,
        high: 1.06247,
        low: 1.06184
      },
      {
        timestamp: 1651047900,
        open: 1.06184,
        close: 1.06132,
        high: 1.06184,
        low: 1.06128
      },
      {
        timestamp: 1651048200,
        open: 1.06132,
        close: 1.06068,
        high: 1.06152,
        low: 1.06054
      },
      {
        timestamp: 1651048500,
        open: 1.06067,
        close: 1.06074,
        high: 1.06089,
        low: 1.06051
      },
      {
        timestamp: 1651048800,
        open: 1.06074,
        close: 1.06015,
        high: 1.0608,
        low: 1.06003
      },
      {
        timestamp: 1651049100,
        open: 1.06015,
        close: 1.05977,
        high: 1.06019,
        low: 1.05949
      },
      {
        timestamp: 1651049400,
        open: 1.05978,
        close: 1.05926,
        high: 1.05978,
        low: 1.05891
      },
      {
        timestamp: 1651049700,
        open: 1.05925,
        close: 1.05894,
        high: 1.06113,
        low: 1.05862
      },
      {
        timestamp: 1651050000,
        open: 1.05893,
        close: 1.05935,
        high: 1.0598,
        low: 1.0587
      },
      {
        timestamp: 1651050300,
        open: 1.05936,
        close: 1.05972,
        high: 1.06009,
        low: 1.05919
      },
      {
        timestamp: 1651050600,
        open: 1.05971,
        close: 1.06029,
        high: 1.06055,
        low: 1.05969
      },
      {
        timestamp: 1651050900,
        open: 1.0603,
        close: 1.06029,
        high: 1.06032,
        low: 1.05964
      },
      {
        timestamp: 1651051200,
        open: 1.0603,
        close: 1.06137,
        high: 1.0614,
        low: 1.06001
      },
      {
        timestamp: 1651051500,
        open: 1.06136,
        close: 1.0614,
        high: 1.06167,
        low: 1.06127
      },
      {
        timestamp: 1651051800,
        open: 1.0614,
        close: 1.06083,
        high: 1.06144,
        low: 1.06067
      },
      {
        timestamp: 1651052100,
        open: 1.06082,
        close: 1.06143,
        high: 1.06166,
        low: 1.06082
      },
      {
        timestamp: 1651052400,
        open: 1.06145,
        close: 1.06205,
        high: 1.06206,
        low: 1.06119
      },
      {
        timestamp: 1651052700,
        open: 1.06205,
        close: 1.06177,
        high: 1.06217,
        low: 1.06162
      },
      {
        timestamp: 1651053000,
        open: 1.06176,
        close: 1.06158,
        high: 1.062,
        low: 1.06147
      },
      {
        timestamp: 1651053300,
        open: 1.06158,
        close: 1.06139,
        high: 1.06174,
        low: 1.06129
      },
      {
        timestamp: 1651053600,
        open: 1.06139,
        close: 1.06213,
        high: 1.06214,
        low: 1.0613
      },
      {
        timestamp: 1651053900,
        open: 1.06212,
        close: 1.06101,
        high: 1.06212,
        low: 1.06097
      },
      {
        timestamp: 1651054200,
        open: 1.061,
        close: 1.06091,
        high: 1.06125,
        low: 1.06076
      },
      {
        timestamp: 1651054500,
        open: 1.06091,
        close: 1.06085,
        high: 1.06109,
        low: 1.06063
      },
      {
        timestamp: 1651054800,
        open: 1.06085,
        close: 1.06103,
        high: 1.06133,
        low: 1.06084
      },
      {
        timestamp: 1651055100,
        open: 1.06102,
        close: 1.06232,
        high: 1.06232,
        low: 1.06084
      },
      {
        timestamp: 1651055400,
        open: 1.06232,
        close: 1.06164,
        high: 1.06232,
        low: 1.06163
      },
      {
        timestamp: 1651055700,
        open: 1.06163,
        close: 1.06125,
        high: 1.0617,
        low: 1.06121
      },
      {
        timestamp: 1651056000,
        open: 1.06125,
        close: 1.06146,
        high: 1.06161,
        low: 1.06121
      },
      {
        timestamp: 1651056300,
        open: 1.06147,
        close: 1.06062,
        high: 1.06163,
        low: 1.06041
      },
      {
        timestamp: 1651056600,
        open: 1.06063,
        close: 1.06104,
        high: 1.06114,
        low: 1.06063
      },
      {
        timestamp: 1651056900,
        open: 1.06103,
        close: 1.06112,
        high: 1.06125,
        low: 1.06068
      },
      {
        timestamp: 1651057200,
        open: 1.06111,
        close: 1.06083,
        high: 1.06116,
        low: 1.06068
      },
      {
        timestamp: 1651057500,
        open: 1.06084,
        close: 1.0608,
        high: 1.06101,
        low: 1.06063
      },
      {
        timestamp: 1651057800,
        open: 1.0608,
        close: 1.06063,
        high: 1.06083,
        low: 1.06023
      },
      {
        timestamp: 1651058100,
        open: 1.06062,
        close: 1.06031,
        high: 1.06062,
        low: 1.06027
      },
      {
        timestamp: 1651058400,
        open: 1.06031,
        close: 1.0599,
        high: 1.06036,
        low: 1.05949
      },
      {
        timestamp: 1651058700,
        open: 1.05989,
        close: 1.05939,
        high: 1.06001,
        low: 1.05933
      },
      {
        timestamp: 1651059000,
        open: 1.0594,
        close: 1.05932,
        high: 1.05952,
        low: 1.05893
      },
      {
        timestamp: 1651059300,
        open: 1.05933,
        close: 1.05883,
        high: 1.05935,
        low: 1.0586
      },
      {
        timestamp: 1651059600,
        open: 1.05883,
        close: 1.05896,
        high: 1.05955,
        low: 1.05881
      },
      {
        timestamp: 1651059900,
        open: 1.05896,
        close: 1.05885,
        high: 1.05924,
        low: 1.05882
      },
      {
        timestamp: 1651060200,
        open: 1.05887,
        close: 1.05839,
        high: 1.0589,
        low: 1.05815
      },
      {
        timestamp: 1651060500,
        open: 1.0584,
        close: 1.05853,
        high: 1.05883,
        low: 1.05836
      },
      {
        timestamp: 1651060800,
        open: 1.05853,
        close: 1.0577,
        high: 1.05867,
        low: 1.05748
      },
      {
        timestamp: 1651061100,
        open: 1.05769,
        close: 1.05808,
        high: 1.05815,
        low: 1.05751
      },
      {
        timestamp: 1651061400,
        open: 1.05807,
        close: 1.05839,
        high: 1.05856,
        low: 1.05794
      },
      {
        timestamp: 1651061700,
        open: 1.05841,
        close: 1.05795,
        high: 1.05863,
        low: 1.05782
      },
      {
        timestamp: 1651062000,
        open: 1.05797,
        close: 1.05758,
        high: 1.05815,
        low: 1.05756
      },
      {
        timestamp: 1651062300,
        open: 1.05759,
        close: 1.05747,
        high: 1.05771,
        low: 1.05722
      },
      {
        timestamp: 1651062600,
        open: 1.05746,
        close: 1.05679,
        high: 1.05748,
        low: 1.05631
      },
      {
        timestamp: 1651062900,
        open: 1.05679,
        close: 1.05682,
        high: 1.05705,
        low: 1.05609
      },
      {
        timestamp: 1651063200,
        open: 1.05683,
        close: 1.05557,
        high: 1.05683,
        low: 1.05556
      },
      {
        timestamp: 1651063500,
        open: 1.05556,
        close: 1.05565,
        high: 1.05596,
        low: 1.05543
      },
      {
        timestamp: 1651063800,
        open: 1.05565,
        close: 1.05537,
        high: 1.05565,
        low: 1.05458
      },
      {
        timestamp: 1651064100,
        open: 1.05536,
        close: 1.05559,
        high: 1.05582,
        low: 1.05499
      },
      {
        timestamp: 1651064400,
        open: 1.0556,
        close: 1.05541,
        high: 1.05618,
        low: 1.05531
      },
      {
        timestamp: 1651064700,
        open: 1.05542,
        close: 1.05525,
        high: 1.05561,
        low: 1.05491
      },
      {
        timestamp: 1651065000,
        open: 1.05524,
        close: 1.05429,
        high: 1.0555,
        low: 1.05429
      },
      {
        timestamp: 1651065300,
        open: 1.05429,
        close: 1.05442,
        high: 1.05474,
        low: 1.05399
      },
      {
        timestamp: 1651065600,
        open: 1.05443,
        close: 1.05442,
        high: 1.05465,
        low: 1.05432
      },
      {
        timestamp: 1651065900,
        open: 1.05443,
        close: 1.054,
        high: 1.05445,
        low: 1.05342
      },
      {
        timestamp: 1651066200,
        open: 1.05399,
        close: 1.0546,
        high: 1.05464,
        low: 1.05394
      },
      {
        timestamp: 1651066500,
        open: 1.0546,
        close: 1.05541,
        high: 1.05543,
        low: 1.05458
      },
      {
        timestamp: 1651066800,
        open: 1.05542,
        close: 1.05515,
        high: 1.05554,
        low: 1.05474
      },
      {
        timestamp: 1651067100,
        open: 1.05515,
        close: 1.0545,
        high: 1.05518,
        low: 1.05434
      },
      {
        timestamp: 1651067400,
        open: 1.05449,
        close: 1.0549,
        high: 1.05528,
        low: 1.05431
      },
      {
        timestamp: 1651067700,
        open: 1.05491,
        close: 1.05491,
        high: 1.05514,
        low: 1.0547
      },
      {
        timestamp: 1651068000,
        open: 1.05491,
        close: 1.05463,
        high: 1.0552,
        low: 1.0544
      },
      {
        timestamp: 1651068300,
        open: 1.05463,
        close: 1.0551,
        high: 1.05545,
        low: 1.05446
      },
      {
        timestamp: 1651068600,
        open: 1.05511,
        close: 1.05386,
        high: 1.05538,
        low: 1.0538
      },
      {
        timestamp: 1651068900,
        open: 1.05386,
        close: 1.05409,
        high: 1.05409,
        low: 1.05365
      },
      {
        timestamp: 1651069200,
        open: 1.05409,
        close: 1.05438,
        high: 1.05478,
        low: 1.05394
      },
      {
        timestamp: 1651069500,
        open: 1.05438,
        close: 1.054,
        high: 1.05476,
        low: 1.05381
      },
      {
        timestamp: 1651069800,
        open: 1.05399,
        close: 1.05289,
        high: 1.05406,
        low: 1.05262
      },
      {
        timestamp: 1651070100,
        open: 1.05288,
        close: 1.05325,
        high: 1.05332,
        low: 1.05258
      },
      {
        timestamp: 1651070400,
        open: 1.05325,
        close: 1.0538,
        high: 1.05395,
        low: 1.05315
      },
      {
        timestamp: 1651070700,
        open: 1.0538,
        close: 1.05338,
        high: 1.05401,
        low: 1.05323
      },
      {
        timestamp: 1651071000,
        open: 1.05337,
        close: 1.0533,
        high: 1.05354,
        low: 1.0529
      },
      {
        timestamp: 1651071300,
        open: 1.0533,
        close: 1.0532,
        high: 1.05359,
        low: 1.05311
      },
      {
        timestamp: 1651071600,
        open: 1.0532,
        close: 1.05255,
        high: 1.05338,
        low: 1.0525
      },
      {
        timestamp: 1651071900,
        open: 1.05255,
        close: 1.0529,
        high: 1.05307,
        low: 1.05252
      },
      {
        timestamp: 1651072200,
        open: 1.0529,
        close: 1.05294,
        high: 1.05304,
        low: 1.05233
      },
      {
        timestamp: 1651072500,
        open: 1.05294,
        close: 1.05336,
        high: 1.05344,
        low: 1.05272
      },
      {
        timestamp: 1651072800,
        open: 1.05338,
        close: 1.05374,
        high: 1.05379,
        low: 1.0532
      },
      {
        timestamp: 1651073100,
        open: 1.05373,
        close: 1.0539,
        high: 1.05396,
        low: 1.05353
      },
      {
        timestamp: 1651073400,
        open: 1.05391,
        close: 1.05527,
        high: 1.0553,
        low: 1.0538
      },
      {
        timestamp: 1651073700,
        open: 1.05526,
        close: 1.05526,
        high: 1.05548,
        low: 1.05496
      },
      {
        timestamp: 1651074000,
        open: 1.05526,
        close: 1.0551,
        high: 1.05536,
        low: 1.0549
      },
      {
        timestamp: 1651074300,
        open: 1.05509,
        close: 1.05474,
        high: 1.05548,
        low: 1.05469
      },
      {
        timestamp: 1651074600,
        open: 1.05475,
        close: 1.05459,
        high: 1.05506,
        low: 1.05456
      },
      {
        timestamp: 1651074900,
        open: 1.05459,
        close: 1.0546,
        high: 1.05464,
        low: 1.05444
      },
      {
        timestamp: 1651075200,
        open: 1.05459,
        close: 1.05552,
        high: 1.05555,
        low: 1.05459
      },
      {
        timestamp: 1651075500,
        open: 1.05553,
        close: 1.05578,
        high: 1.05593,
        low: 1.05544
      },
      {
        timestamp: 1651075800,
        open: 1.05577,
        close: 1.05524,
        high: 1.05586,
        low: 1.05523
      },
      {
        timestamp: 1651076100,
        open: 1.05524,
        close: 1.05523,
        high: 1.05564,
        low: 1.0552
      },
      {
        timestamp: 1651076400,
        open: 1.05523,
        close: 1.05588,
        high: 1.05598,
        low: 1.05512
      },
      {
        timestamp: 1651076700,
        open: 1.05587,
        close: 1.05638,
        high: 1.05667,
        low: 1.05582
      },
      {
        timestamp: 1651077000,
        open: 1.0564,
        close: 1.05628,
        high: 1.05661,
        low: 1.05614
      },
      {
        timestamp: 1651077300,
        open: 1.05627,
        close: 1.05619,
        high: 1.05638,
        low: 1.05614
      },
      {
        timestamp: 1651077600,
        open: 1.05619,
        close: 1.05642,
        high: 1.05669,
        low: 1.0561
      },
      {
        timestamp: 1651077900,
        open: 1.05642,
        close: 1.05686,
        high: 1.05709,
        low: 1.05638
      },
      {
        timestamp: 1651078200,
        open: 1.05685,
        close: 1.05669,
        high: 1.0572,
        low: 1.05669
      },
      {
        timestamp: 1651078500,
        open: 1.05669,
        close: 1.05635,
        high: 1.05699,
        low: 1.05635
      },
      {
        timestamp: 1651078800,
        open: 1.05636,
        close: 1.05649,
        high: 1.05653,
        low: 1.0559
      },
      {
        timestamp: 1651079100,
        open: 1.0565,
        close: 1.0565,
        high: 1.05654,
        low: 1.05612
      },
      {
        timestamp: 1651079400,
        open: 1.05649,
        close: 1.05683,
        high: 1.05689,
        low: 1.0563
      },
      {
        timestamp: 1651079700,
        open: 1.05684,
        close: 1.05675,
        high: 1.05697,
        low: 1.05665
      },
      {
        timestamp: 1651080000,
        open: 1.05674,
        close: 1.05665,
        high: 1.05705,
        low: 1.0566
      },
      {
        timestamp: 1651080300,
        open: 1.05665,
        close: 1.05694,
        high: 1.05695,
        low: 1.0563
      },
      {
        timestamp: 1651080600,
        open: 1.05693,
        close: 1.05714,
        high: 1.05723,
        low: 1.05683
      },
      {
        timestamp: 1651080900,
        open: 1.05714,
        close: 1.057,
        high: 1.05729,
        low: 1.05692
      },
      {
        timestamp: 1651081200,
        open: 1.05699,
        close: 1.05689,
        high: 1.05707,
        low: 1.05679
      },
      {
        timestamp: 1651081500,
        open: 1.05689,
        close: 1.05655,
        high: 1.05694,
        low: 1.05653
      },
      {
        timestamp: 1651081800,
        open: 1.05654,
        close: 1.05686,
        high: 1.05719,
        low: 1.05654
      },
      {
        timestamp: 1651082100,
        open: 1.05685,
        close: 1.05711,
        high: 1.05716,
        low: 1.05669
      },
      {
        timestamp: 1651082400,
        open: 1.05711,
        close: 1.05714,
        high: 1.0572,
        low: 1.0569
      },
      {
        timestamp: 1651082700,
        open: 1.05713,
        close: 1.05718,
        high: 1.0573,
        low: 1.05704
      },
      {
        timestamp: 1651083000,
        open: 1.05719,
        close: 1.05719,
        high: 1.05726,
        low: 1.05693
      },
      {
        timestamp: 1651083300,
        open: 1.05719,
        close: 1.05659,
        high: 1.0572,
        low: 1.05645
      },
      {
        timestamp: 1651083600,
        open: 1.0566,
        close: 1.05614,
        high: 1.05664,
        low: 1.0561
      },
      {
        timestamp: 1651083900,
        open: 1.05614,
        close: 1.0562,
        high: 1.05635,
        low: 1.05605
      },
      {
        timestamp: 1651084200,
        open: 1.05619,
        close: 1.05603,
        high: 1.05634,
        low: 1.05602
      },
      {
        timestamp: 1651084500,
        open: 1.05603,
        close: 1.05594,
        high: 1.05608,
        low: 1.05565
      },
      {
        timestamp: 1651084800,
        open: 1.05594,
        close: 1.05624,
        high: 1.05635,
        low: 1.05594
      },
      {
        timestamp: 1651085100,
        open: 1.05625,
        close: 1.05646,
        high: 1.05668,
        low: 1.0561
      },
      {
        timestamp: 1651085400,
        open: 1.05647,
        close: 1.05635,
        high: 1.05654,
        low: 1.05607
      },
      {
        timestamp: 1651085700,
        open: 1.05636,
        close: 1.05614,
        high: 1.05665,
        low: 1.05608
      },
      {
        timestamp: 1651086000,
        open: 1.05613,
        close: 1.05589,
        high: 1.05629,
        low: 1.05575
      },
      {
        timestamp: 1651086300,
        open: 1.05587,
        close: 1.05606,
        high: 1.05616,
        low: 1.05568
      },
      {
        timestamp: 1651086600,
        open: 1.05605,
        close: 1.05606,
        high: 1.05611,
        low: 1.05585
      },
      {
        timestamp: 1651086900,
        open: 1.05605,
        close: 1.05587,
        high: 1.05613,
        low: 1.0558
      },
      {
        timestamp: 1651087200,
        open: 1.05587,
        close: 1.05568,
        high: 1.0559,
        low: 1.05564
      },
      {
        timestamp: 1651087500,
        open: 1.05568,
        close: 1.05558,
        high: 1.0559,
        low: 1.05554
      },
      {
        timestamp: 1651087800,
        open: 1.05558,
        close: 1.05571,
        high: 1.05579,
        low: 1.05555
      },
      {
        timestamp: 1651088100,
        open: 1.0557,
        close: 1.05547,
        high: 1.05575,
        low: 1.05544
      },
      {
        timestamp: 1651088400,
        open: 1.05548,
        close: 1.05585,
        high: 1.05589,
        low: 1.05543
      },
      {
        timestamp: 1651088700,
        open: 1.05585,
        close: 1.05562,
        high: 1.05592,
        low: 1.05548
      },
      {
        timestamp: 1651089000,
        open: 1.05561,
        close: 1.05557,
        high: 1.05583,
        low: 1.05542
      },
      {
        timestamp: 1651089300,
        open: 1.05558,
        close: 1.0559,
        high: 1.05595,
        low: 1.05558
      },
      {
        timestamp: 1651089600,
        open: 1.0559,
        close: 1.05583,
        high: 1.05603,
        low: 1.05582
      },
      {
        timestamp: 1651089900,
        open: 1.05583,
        close: 1.05591,
        high: 1.05603,
        low: 1.0558
      },
      {
        timestamp: 1651090200,
        open: 1.05591,
        close: 1.05593,
        high: 1.05595,
        low: 1.05586
      },
      {
        timestamp: 1651090500,
        open: 1.05595,
        close: 1.05589,
        high: 1.056,
        low: 1.05582
      },
      {
        timestamp: 1651090800,
        open: 1.05589,
        close: 1.05589,
        high: 1.05603,
        low: 1.05589
      },
      {
        timestamp: 1651091100,
        open: 1.05589,
        close: 1.05607,
        high: 1.05609,
        low: 1.05585
      },
      {
        timestamp: 1651091400,
        open: 1.05608,
        close: 1.05603,
        high: 1.05609,
        low: 1.05597
      },
      {
        timestamp: 1651091700,
        open: 1.05602,
        close: 1.056,
        high: 1.05602,
        low: 1.05563
      },
      {
        timestamp: 1651092000,
        open: 1.05599,
        close: 1.05573,
        high: 1.05599,
        low: 1.05572
      },
      {
        timestamp: 1651092300,
        open: 1.05573,
        close: 1.05575,
        high: 1.05585,
        low: 1.05571
      },
      {
        timestamp: 1651092600,
        open: 1.05576,
        close: 1.05613,
        high: 1.05614,
        low: 1.05575
      },
      {
        timestamp: 1651092900,
        open: 1.05614,
        close: 1.056,
        high: 1.05616,
        low: 1.05581
      },
      {
        timestamp: 1651093200,
        open: 1.056,
        close: 1.05615,
        high: 1.05615,
        low: 1.056
      },
      {
        timestamp: 1651093500,
        open: 1.05642,
        close: 1.0567,
        high: 1.0567,
        low: 1.05642
      },
      {
        timestamp: 1651093800,
        open: 1.05651,
        close: 1.0567,
        high: 1.05671,
        low: 1.05646
      },
      {
        timestamp: 1651094100,
        open: 1.05668,
        close: 1.05597,
        high: 1.05668,
        low: 1.05585
      },
      {
        timestamp: 1651094400,
        open: 1.05597,
        close: 1.05589,
        high: 1.05607,
        low: 1.05589
      },
      {
        timestamp: 1651094700,
        open: 1.05591,
        close: 1.05589,
        high: 1.05596,
        low: 1.05553
      },
      {
        timestamp: 1651095000,
        open: 1.05589,
        close: 1.05584,
        high: 1.05589,
        low: 1.05546
      },
      {
        timestamp: 1651095300,
        open: 1.05581,
        close: 1.05565,
        high: 1.05585,
        low: 1.05543
      },
      {
        timestamp: 1651095600,
        open: 1.05564,
        close: 1.05564,
        high: 1.05571,
        low: 1.05563
      },
      {
        timestamp: 1651095900,
        open: 1.05565,
        close: 1.05575,
        high: 1.05578,
        low: 1.05564
      },
      {
        timestamp: 1651096200,
        open: 1.05576,
        close: 1.05569,
        high: 1.05577,
        low: 1.05569
      },
      {
        timestamp: 1651096500,
        open: 1.05572,
        close: 1.05574,
        high: 1.05574,
        low: 1.05567
      },
      {
        timestamp: 1651096800,
        open: 1.05575,
        close: 1.05553,
        high: 1.0559,
        low: 1.05553
      },
      {
        timestamp: 1651097100,
        open: 1.05554,
        close: 1.05547,
        high: 1.05563,
        low: 1.05533
      },
      {
        timestamp: 1651097400,
        open: 1.05547,
        close: 1.05534,
        high: 1.05549,
        low: 1.05525
      },
      {
        timestamp: 1651097700,
        open: 1.05534,
        close: 1.05527,
        high: 1.05542,
        low: 1.05527
      },
      {
        timestamp: 1651098000,
        open: 1.05528,
        close: 1.05532,
        high: 1.05541,
        low: 1.05527
      },
      {
        timestamp: 1651098300,
        open: 1.05533,
        close: 1.05539,
        high: 1.05543,
        low: 1.05533
      },
      {
        timestamp: 1651098600,
        open: 1.0554,
        close: 1.05529,
        high: 1.05541,
        low: 1.05528
      },
      {
        timestamp: 1651098900,
        open: 1.0553,
        close: 1.05542,
        high: 1.05542,
        low: 1.05526
      },
      {
        timestamp: 1651099200,
        open: 1.05542,
        close: 1.05511,
        high: 1.05542,
        low: 1.05508
      },
      {
        timestamp: 1651099500,
        open: 1.05511,
        close: 1.05519,
        high: 1.05527,
        low: 1.0551
      },
      {
        timestamp: 1651099800,
        open: 1.05518,
        close: 1.0552,
        high: 1.0552,
        low: 1.0551
      },
      {
        timestamp: 1651100100,
        open: 1.05517,
        close: 1.05517,
        high: 1.05524,
        low: 1.05515
      },
      {
        timestamp: 1651100400,
        open: 1.05516,
        close: 1.05543,
        high: 1.05544,
        low: 1.05511
      },
      {
        timestamp: 1651100700,
        open: 1.05543,
        close: 1.05514,
        high: 1.05544,
        low: 1.05503
      },
      {
        timestamp: 1651101000,
        open: 1.05514,
        close: 1.05514,
        high: 1.05515,
        low: 1.05503
      },
      {
        timestamp: 1651101300,
        open: 1.05515,
        close: 1.05515,
        high: 1.0552,
        low: 1.05514
      },
      {
        timestamp: 1651101600,
        open: 1.05515,
        close: 1.05518,
        high: 1.0552,
        low: 1.0551
      },
      {
        timestamp: 1651101900,
        open: 1.05518,
        close: 1.05504,
        high: 1.0552,
        low: 1.05503
      },
      {
        timestamp: 1651102200,
        open: 1.05504,
        close: 1.05504,
        high: 1.05507,
        low: 1.05502
      },
      {
        timestamp: 1651102500,
        open: 1.05504,
        close: 1.05516,
        high: 1.05519,
        low: 1.05502
      },
      {
        timestamp: 1651102800,
        open: 1.05515,
        close: 1.05534,
        high: 1.05538,
        low: 1.05513
      },
      {
        timestamp: 1651103100,
        open: 1.05533,
        close: 1.0556,
        high: 1.0557,
        low: 1.05533
      },
      {
        timestamp: 1651103400,
        open: 1.0556,
        close: 1.05563,
        high: 1.05571,
        low: 1.05553
      },
      {
        timestamp: 1651103700,
        open: 1.05564,
        close: 1.0555,
        high: 1.05569,
        low: 1.05542
      },
      {
        timestamp: 1651104000,
        open: 1.05549,
        close: 1.05505,
        high: 1.05549,
        low: 1.05502
      },
      {
        timestamp: 1651104300,
        open: 1.05506,
        close: 1.05532,
        high: 1.05537,
        low: 1.05502
      },
      {
        timestamp: 1651104600,
        open: 1.05531,
        close: 1.05517,
        high: 1.0554,
        low: 1.05517
      },
      {
        timestamp: 1651104900,
        open: 1.05516,
        close: 1.05499,
        high: 1.05517,
        low: 1.05485
      },
      {
        timestamp: 1651105200,
        open: 1.05499,
        close: 1.05478,
        high: 1.05515,
        low: 1.05477
      },
      {
        timestamp: 1651105500,
        open: 1.05477,
        close: 1.05474,
        high: 1.05488,
        low: 1.0547
      },
      {
        timestamp: 1651105800,
        open: 1.05474,
        close: 1.05479,
        high: 1.05479,
        low: 1.05459
      },
      {
        timestamp: 1651106100,
        open: 1.05479,
        close: 1.05482,
        high: 1.05493,
        low: 1.05474
      },
      {
        timestamp: 1651106400,
        open: 1.05482,
        close: 1.05471,
        high: 1.05482,
        low: 1.05462
      },
      {
        timestamp: 1651106700,
        open: 1.05471,
        close: 1.05491,
        high: 1.055,
        low: 1.05465
      },
      {
        timestamp: 1651107000,
        open: 1.05491,
        close: 1.05425,
        high: 1.05493,
        low: 1.05407
      },
      {
        timestamp: 1651107300,
        open: 1.05424,
        close: 1.05392,
        high: 1.05436,
        low: 1.05313
      },
      {
        timestamp: 1651107600,
        open: 1.05392,
        close: 1.0552,
        high: 1.05543,
        low: 1.05392
      },
      {
        timestamp: 1651107900,
        open: 1.0552,
        close: 1.05486,
        high: 1.05537,
        low: 1.05484
      },
      {
        timestamp: 1651108200,
        open: 1.05486,
        close: 1.05411,
        high: 1.05493,
        low: 1.05411
      },
      {
        timestamp: 1651108500,
        open: 1.05411,
        close: 1.05452,
        high: 1.05462,
        low: 1.05404
      },
      {
        timestamp: 1651108800,
        open: 1.05453,
        close: 1.05446,
        high: 1.05467,
        low: 1.05427
      },
      {
        timestamp: 1651109100,
        open: 1.05446,
        close: 1.05446,
        high: 1.05458,
        low: 1.0544
      },
      {
        timestamp: 1651109400,
        open: 1.05446,
        close: 1.0541,
        high: 1.0545,
        low: 1.05409
      },
      {
        timestamp: 1651109700,
        open: 1.0541,
        close: 1.05393,
        high: 1.05413,
        low: 1.05358
      },
      {
        timestamp: 1651110000,
        open: 1.0539,
        close: 1.05385,
        high: 1.054,
        low: 1.05373
      },
      {
        timestamp: 1651110300,
        open: 1.05386,
        close: 1.05431,
        high: 1.05433,
        low: 1.05378
      },
      {
        timestamp: 1651110600,
        open: 1.05432,
        close: 1.0544,
        high: 1.05458,
        low: 1.05431
      },
      {
        timestamp: 1651110900,
        open: 1.05441,
        close: 1.05477,
        high: 1.05487,
        low: 1.05432
      },
      {
        timestamp: 1651111200,
        open: 1.05477,
        close: 1.05456,
        high: 1.05483,
        low: 1.0545
      },
      {
        timestamp: 1651111500,
        open: 1.05455,
        close: 1.05418,
        high: 1.05457,
        low: 1.05413
      },
      {
        timestamp: 1651111800,
        open: 1.05418,
        close: 1.05402,
        high: 1.05434,
        low: 1.05394
      },
      {
        timestamp: 1651112100,
        open: 1.05403,
        close: 1.05388,
        high: 1.05405,
        low: 1.05388
      },
      {
        timestamp: 1651112400,
        open: 1.05387,
        close: 1.05375,
        high: 1.05417,
        low: 1.05364
      },
      {
        timestamp: 1651112700,
        open: 1.05376,
        close: 1.0535,
        high: 1.05382,
        low: 1.05341
      },
      {
        timestamp: 1651113000,
        open: 1.0535,
        close: 1.05338,
        high: 1.05368,
        low: 1.05336
      },
      {
        timestamp: 1651113300,
        open: 1.05339,
        close: 1.05401,
        high: 1.05401,
        low: 1.05339
      },
      {
        timestamp: 1651113600,
        open: 1.05401,
        close: 1.05408,
        high: 1.05439,
        low: 1.05393
      },
      {
        timestamp: 1651113900,
        open: 1.05409,
        close: 1.05414,
        high: 1.05414,
        low: 1.05397
      },
      {
        timestamp: 1651114200,
        open: 1.05415,
        close: 1.05384,
        high: 1.0542,
        low: 1.05379
      },
      {
        timestamp: 1651114500,
        open: 1.05383,
        close: 1.05376,
        high: 1.054,
        low: 1.05369
      },
      {
        timestamp: 1651114800,
        open: 1.05376,
        close: 1.05404,
        high: 1.05404,
        low: 1.05367
      },
      {
        timestamp: 1651115100,
        open: 1.05403,
        close: 1.05363,
        high: 1.05414,
        low: 1.05353
      },
      {
        timestamp: 1651115400,
        open: 1.05362,
        close: 1.05355,
        high: 1.05379,
        low: 1.05342
      },
      {
        timestamp: 1651115700,
        open: 1.05355,
        close: 1.05248,
        high: 1.05375,
        low: 1.05228
      },
      {
        timestamp: 1651116000,
        open: 1.05247,
        close: 1.05179,
        high: 1.05258,
        low: 1.05161
      },
      {
        timestamp: 1651116300,
        open: 1.05178,
        close: 1.05306,
        high: 1.05309,
        low: 1.05178
      },
      {
        timestamp: 1651116600,
        open: 1.05305,
        close: 1.05261,
        high: 1.05321,
        low: 1.05259
      },
      {
        timestamp: 1651116900,
        open: 1.0526,
        close: 1.05234,
        high: 1.05261,
        low: 1.05224
      },
      {
        timestamp: 1651117200,
        open: 1.05235,
        close: 1.05239,
        high: 1.05265,
        low: 1.0523
      },
      {
        timestamp: 1651117500,
        open: 1.05238,
        close: 1.05184,
        high: 1.05247,
        low: 1.05174
      },
      {
        timestamp: 1651117800,
        open: 1.05184,
        close: 1.05138,
        high: 1.052,
        low: 1.05132
      },
      {
        timestamp: 1651118100,
        open: 1.05138,
        close: 1.05098,
        high: 1.05144,
        low: 1.05097
      },
      {
        timestamp: 1651118400,
        open: 1.05099,
        close: 1.05113,
        high: 1.0512,
        low: 1.05098
      },
      {
        timestamp: 1651118700,
        open: 1.05112,
        close: 1.05145,
        high: 1.05166,
        low: 1.05112
      },
      {
        timestamp: 1651119000,
        open: 1.05144,
        close: 1.05162,
        high: 1.05164,
        low: 1.05142
      },
      {
        timestamp: 1651119300,
        open: 1.05162,
        close: 1.05138,
        high: 1.05163,
        low: 1.05128
      },
      {
        timestamp: 1651119600,
        open: 1.05137,
        close: 1.0511,
        high: 1.05139,
        low: 1.05108
      },
      {
        timestamp: 1651119900,
        open: 1.0511,
        close: 1.05117,
        high: 1.05123,
        low: 1.05095
      },
      {
        timestamp: 1651120200,
        open: 1.05117,
        close: 1.05165,
        high: 1.05177,
        low: 1.05106
      },
      {
        timestamp: 1651120500,
        open: 1.05166,
        close: 1.05195,
        high: 1.05195,
        low: 1.05153
      },
      {
        timestamp: 1651120800,
        open: 1.05194,
        close: 1.05252,
        high: 1.05255,
        low: 1.05192
      },
      {
        timestamp: 1651121100,
        open: 1.05251,
        close: 1.05274,
        high: 1.05274,
        low: 1.0525
      },
      {
        timestamp: 1651121400,
        open: 1.05273,
        close: 1.05216,
        high: 1.05274,
        low: 1.05211
      },
      {
        timestamp: 1651121700,
        open: 1.05216,
        close: 1.05182,
        high: 1.05256,
        low: 1.05181
      },
      {
        timestamp: 1651122000,
        open: 1.05182,
        close: 1.05045,
        high: 1.05201,
        low: 1.05045
      },
      {
        timestamp: 1651122300,
        open: 1.05045,
        close: 1.05057,
        high: 1.05072,
        low: 1.05034
      },
      {
        timestamp: 1651122600,
        open: 1.05055,
        close: 1.05062,
        high: 1.05064,
        low: 1.05045
      },
      {
        timestamp: 1651122900,
        open: 1.05062,
        close: 1.05044,
        high: 1.05063,
        low: 1.05016
      },
      {
        timestamp: 1651123200,
        open: 1.05044,
        close: 1.05021,
        high: 1.05051,
        low: 1.05014
      },
      {
        timestamp: 1651123500,
        open: 1.05022,
        close: 1.05019,
        high: 1.05041,
        low: 1.05013
      },
      {
        timestamp: 1651123800,
        open: 1.05019,
        close: 1.05031,
        high: 1.05043,
        low: 1.05016
      },
      {
        timestamp: 1651124100,
        open: 1.05032,
        close: 1.05019,
        high: 1.05046,
        low: 1.05012
      },
      {
        timestamp: 1651124400,
        open: 1.05019,
        close: 1.04928,
        high: 1.05026,
        low: 1.04818
      },
      {
        timestamp: 1651124700,
        open: 1.04929,
        close: 1.0519,
        high: 1.05191,
        low: 1.04923
      },
      {
        timestamp: 1651125000,
        open: 1.05191,
        close: 1.05162,
        high: 1.05267,
        low: 1.05155
      },
      {
        timestamp: 1651125300,
        open: 1.0516,
        close: 1.05131,
        high: 1.05186,
        low: 1.05092
      },
      {
        timestamp: 1651125600,
        open: 1.05131,
        close: 1.05171,
        high: 1.05202,
        low: 1.05073
      },
      {
        timestamp: 1651125900,
        open: 1.05173,
        close: 1.05172,
        high: 1.05187,
        low: 1.05111
      },
      {
        timestamp: 1651126200,
        open: 1.05172,
        close: 1.05152,
        high: 1.05205,
        low: 1.05131
      },
      {
        timestamp: 1651126500,
        open: 1.05153,
        close: 1.05143,
        high: 1.05204,
        low: 1.05135
      },
      {
        timestamp: 1651126800,
        open: 1.05142,
        close: 1.05176,
        high: 1.05221,
        low: 1.0513
      },
      {
        timestamp: 1651127100,
        open: 1.05177,
        close: 1.0516,
        high: 1.05231,
        low: 1.0515
      },
      {
        timestamp: 1651127400,
        open: 1.0516,
        close: 1.05202,
        high: 1.05217,
        low: 1.05113
      },
      {
        timestamp: 1651127700,
        open: 1.05203,
        close: 1.05221,
        high: 1.05243,
        low: 1.05181
      },
      {
        timestamp: 1651128000,
        open: 1.05222,
        close: 1.05247,
        high: 1.05255,
        low: 1.05188
      },
      {
        timestamp: 1651128300,
        open: 1.05246,
        close: 1.05266,
        high: 1.05297,
        low: 1.05241
      },
      {
        timestamp: 1651128600,
        open: 1.05265,
        close: 1.05281,
        high: 1.05316,
        low: 1.05248
      },
      {
        timestamp: 1651128900,
        open: 1.05282,
        close: 1.05255,
        high: 1.05286,
        low: 1.05195
      },
      {
        timestamp: 1651129200,
        open: 1.05251,
        close: 1.05303,
        high: 1.05346,
        low: 1.05215
      },
      {
        timestamp: 1651129500,
        open: 1.05303,
        close: 1.05225,
        high: 1.0531,
        low: 1.05203
      },
      {
        timestamp: 1651129800,
        open: 1.05225,
        close: 1.05213,
        high: 1.0525,
        low: 1.05177
      },
      {
        timestamp: 1651130100,
        open: 1.05212,
        close: 1.05289,
        high: 1.05291,
        low: 1.05158
      },
      {
        timestamp: 1651130400,
        open: 1.0529,
        close: 1.05412,
        high: 1.0544,
        low: 1.05279
      },
      {
        timestamp: 1651130700,
        open: 1.05416,
        close: 1.05394,
        high: 1.05435,
        low: 1.05349
      },
      {
        timestamp: 1651131000,
        open: 1.05394,
        close: 1.0533,
        high: 1.05406,
        low: 1.05261
      },
      {
        timestamp: 1651131300,
        open: 1.05329,
        close: 1.05445,
        high: 1.0549,
        low: 1.05308
      },
      {
        timestamp: 1651131600,
        open: 1.05444,
        close: 1.05476,
        high: 1.05481,
        low: 1.05374
      },
      {
        timestamp: 1651131900,
        open: 1.05476,
        close: 1.05467,
        high: 1.05531,
        low: 1.05427
      },
      {
        timestamp: 1651132200,
        open: 1.05467,
        close: 1.0551,
        high: 1.05557,
        low: 1.05463
      },
      {
        timestamp: 1651132500,
        open: 1.05509,
        close: 1.05526,
        high: 1.05586,
        low: 1.055
      },
      {
        timestamp: 1651132800,
        open: 1.05526,
        close: 1.05597,
        high: 1.05657,
        low: 1.05526
      },
      {
        timestamp: 1651133100,
        open: 1.05597,
        close: 1.05486,
        high: 1.05617,
        low: 1.0548
      },
      {
        timestamp: 1651133400,
        open: 1.05486,
        close: 1.05479,
        high: 1.05525,
        low: 1.05473
      },
      {
        timestamp: 1651133700,
        open: 1.05479,
        close: 1.0539,
        high: 1.05479,
        low: 1.05388
      },
      {
        timestamp: 1651134000,
        open: 1.0539,
        close: 1.05339,
        high: 1.05394,
        low: 1.05287
      },
      {
        timestamp: 1651134300,
        open: 1.0534,
        close: 1.0544,
        high: 1.05456,
        low: 1.05334
      },
      {
        timestamp: 1651134600,
        open: 1.0544,
        close: 1.05389,
        high: 1.05445,
        low: 1.05379
      },
      {
        timestamp: 1651134900,
        open: 1.05389,
        close: 1.05331,
        high: 1.05389,
        low: 1.05321
      },
      {
        timestamp: 1651135200,
        open: 1.05331,
        close: 1.05292,
        high: 1.05371,
        low: 1.05291
      },
      {
        timestamp: 1651135500,
        open: 1.05292,
        close: 1.05255,
        high: 1.053,
        low: 1.05233
      },
      {
        timestamp: 1651135800,
        open: 1.05255,
        close: 1.05233,
        high: 1.05265,
        low: 1.05209
      },
      {
        timestamp: 1651136100,
        open: 1.05234,
        close: 1.05168,
        high: 1.05236,
        low: 1.05155
      },
      {
        timestamp: 1651136400,
        open: 1.05169,
        close: 1.0524,
        high: 1.05245,
        low: 1.05145
      },
      {
        timestamp: 1651136700,
        open: 1.05241,
        close: 1.05299,
        high: 1.05306,
        low: 1.05223
      },
      {
        timestamp: 1651137000,
        open: 1.053,
        close: 1.05308,
        high: 1.05314,
        low: 1.05254
      },
      {
        timestamp: 1651137300,
        open: 1.05307,
        close: 1.05282,
        high: 1.05314,
        low: 1.05237
      },
      {
        timestamp: 1651137600,
        open: 1.05282,
        close: 1.05299,
        high: 1.05303,
        low: 1.05272
      },
      {
        timestamp: 1651137900,
        open: 1.05298,
        close: 1.05335,
        high: 1.05351,
        low: 1.05291
      },
      {
        timestamp: 1651138200,
        open: 1.05337,
        close: 1.0528,
        high: 1.05352,
        low: 1.05279
      },
      {
        timestamp: 1651138500,
        open: 1.05279,
        close: 1.05332,
        high: 1.05396,
        low: 1.05251
      },
      {
        timestamp: 1651138800,
        open: 1.05333,
        close: 1.05243,
        high: 1.05337,
        low: 1.0524
      },
      {
        timestamp: 1651139100,
        open: 1.05242,
        close: 1.05275,
        high: 1.05282,
        low: 1.05214
      },
      {
        timestamp: 1651139400,
        open: 1.05276,
        close: 1.05224,
        high: 1.0528,
        low: 1.05209
      },
      {
        timestamp: 1651139700,
        open: 1.05225,
        close: 1.05211,
        high: 1.05239,
        low: 1.05174
      },
      {
        timestamp: 1651140000,
        open: 1.0521,
        close: 1.05224,
        high: 1.05235,
        low: 1.05179
      },
      {
        timestamp: 1651140300,
        open: 1.05223,
        close: 1.0525,
        high: 1.05304,
        low: 1.05211
      },
      {
        timestamp: 1651140600,
        open: 1.05249,
        close: 1.05188,
        high: 1.05252,
        low: 1.0516
      },
      {
        timestamp: 1651140900,
        open: 1.05187,
        close: 1.05165,
        high: 1.05188,
        low: 1.05132
      },
      {
        timestamp: 1651141200,
        open: 1.05163,
        close: 1.05167,
        high: 1.05179,
        low: 1.05124
      },
      {
        timestamp: 1651141500,
        open: 1.05167,
        close: 1.05136,
        high: 1.05189,
        low: 1.05108
      },
      {
        timestamp: 1651141800,
        open: 1.05137,
        close: 1.05108,
        high: 1.05192,
        low: 1.05075
      },
      {
        timestamp: 1651142100,
        open: 1.05108,
        close: 1.05064,
        high: 1.05118,
        low: 1.05032
      },
      {
        timestamp: 1651142400,
        open: 1.05063,
        close: 1.05091,
        high: 1.05121,
        low: 1.0506
      },
      {
        timestamp: 1651142700,
        open: 1.05092,
        close: 1.05066,
        high: 1.05118,
        low: 1.05051
      },
      {
        timestamp: 1651143000,
        open: 1.05066,
        close: 1.05015,
        high: 1.05073,
        low: 1.05006
      },
      {
        timestamp: 1651143300,
        open: 1.05015,
        close: 1.04985,
        high: 1.05024,
        low: 1.04974
      },
      {
        timestamp: 1651143600,
        open: 1.04984,
        close: 1.05071,
        high: 1.05076,
        low: 1.04956
      },
      {
        timestamp: 1651143900,
        open: 1.05072,
        close: 1.05048,
        high: 1.05089,
        low: 1.05018
      },
      {
        timestamp: 1651144200,
        open: 1.05045,
        close: 1.04999,
        high: 1.05056,
        low: 1.04967
      },
      {
        timestamp: 1651144500,
        open: 1.04998,
        close: 1.04958,
        high: 1.04998,
        low: 1.04934
      },
      {
        timestamp: 1651144800,
        open: 1.04959,
        close: 1.05051,
        high: 1.05083,
        low: 1.04948
      },
      {
        timestamp: 1651145100,
        open: 1.05052,
        close: 1.05053,
        high: 1.05104,
        low: 1.05043
      },
      {
        timestamp: 1651145400,
        open: 1.05052,
        close: 1.05,
        high: 1.05076,
        low: 1.04994
      },
      {
        timestamp: 1651145700,
        open: 1.05,
        close: 1.05024,
        high: 1.05025,
        low: 1.0496
      },
      {
        timestamp: 1651146000,
        open: 1.05023,
        close: 1.04999,
        high: 1.05051,
        low: 1.04968
      },
      {
        timestamp: 1651146300,
        open: 1.05,
        close: 1.05012,
        high: 1.05018,
        low: 1.04965
      },
      {
        timestamp: 1651146600,
        open: 1.05012,
        close: 1.04973,
        high: 1.05015,
        low: 1.04954
      },
      {
        timestamp: 1651146900,
        open: 1.04972,
        close: 1.04912,
        high: 1.0499,
        low: 1.0487
      },
      {
        timestamp: 1651147200,
        open: 1.04911,
        close: 1.04939,
        high: 1.05006,
        low: 1.04911
      },
      {
        timestamp: 1651147500,
        open: 1.04939,
        close: 1.04935,
        high: 1.04981,
        low: 1.04897
      },
      {
        timestamp: 1651147800,
        open: 1.04936,
        close: 1.04859,
        high: 1.0495,
        low: 1.04856
      },
      {
        timestamp: 1651148100,
        open: 1.04858,
        close: 1.04782,
        high: 1.04869,
        low: 1.04777
      },
      {
        timestamp: 1651148400,
        open: 1.04781,
        close: 1.04908,
        high: 1.04911,
        low: 1.04719
      },
      {
        timestamp: 1651148700,
        open: 1.0491,
        close: 1.0487,
        high: 1.04953,
        low: 1.04848
      },
      {
        timestamp: 1651149000,
        open: 1.0487,
        close: 1.05099,
        high: 1.0511,
        low: 1.0487
      },
      {
        timestamp: 1651149300,
        open: 1.05098,
        close: 1.05238,
        high: 1.05248,
        low: 1.05072
      },
      {
        timestamp: 1651149600,
        open: 1.05239,
        close: 1.05107,
        high: 1.05241,
        low: 1.0505
      },
      {
        timestamp: 1651149900,
        open: 1.05106,
        close: 1.05124,
        high: 1.05217,
        low: 1.05084
      },
      {
        timestamp: 1651150200,
        open: 1.05123,
        close: 1.0506,
        high: 1.05152,
        low: 1.05058
      },
      {
        timestamp: 1651150500,
        open: 1.05061,
        close: 1.04937,
        high: 1.05061,
        low: 1.04925
      },
      {
        timestamp: 1651150800,
        open: 1.04937,
        close: 1.04909,
        high: 1.04984,
        low: 1.04909
      },
      {
        timestamp: 1651151100,
        open: 1.0491,
        close: 1.04857,
        high: 1.04942,
        low: 1.04822
      },
      {
        timestamp: 1651151400,
        open: 1.04858,
        close: 1.05034,
        high: 1.05041,
        low: 1.04858
      },
      {
        timestamp: 1651151700,
        open: 1.05034,
        close: 1.05091,
        high: 1.051,
        low: 1.04942
      },
      {
        timestamp: 1651152000,
        open: 1.05091,
        close: 1.05037,
        high: 1.05137,
        low: 1.05019
      },
      {
        timestamp: 1651152300,
        open: 1.05037,
        close: 1.05057,
        high: 1.05104,
        low: 1.0497
      },
      {
        timestamp: 1651152600,
        open: 1.05056,
        close: 1.05041,
        high: 1.05066,
        low: 1.05016
      },
      {
        timestamp: 1651152900,
        open: 1.05041,
        close: 1.05003,
        high: 1.05052,
        low: 1.0497
      },
      {
        timestamp: 1651153200,
        open: 1.05003,
        close: 1.05017,
        high: 1.05033,
        low: 1.04976
      },
      {
        timestamp: 1651153500,
        open: 1.05018,
        close: 1.05008,
        high: 1.05044,
        low: 1.04995
      },
      {
        timestamp: 1651153800,
        open: 1.05007,
        close: 1.05124,
        high: 1.05138,
        low: 1.04995
      },
      {
        timestamp: 1651154100,
        open: 1.05124,
        close: 1.05094,
        high: 1.05132,
        low: 1.05055
      },
      {
        timestamp: 1651154400,
        open: 1.05093,
        close: 1.05077,
        high: 1.05096,
        low: 1.05048
      },
      {
        timestamp: 1651154700,
        open: 1.05077,
        close: 1.05065,
        high: 1.05091,
        low: 1.05048
      },
      {
        timestamp: 1651155000,
        open: 1.05065,
        close: 1.0505,
        high: 1.05091,
        low: 1.05032
      },
      {
        timestamp: 1651155300,
        open: 1.0505,
        close: 1.05013,
        high: 1.05064,
        low: 1.05002
      },
      {
        timestamp: 1651155600,
        open: 1.05012,
        close: 1.0505,
        high: 1.05061,
        low: 1.05004
      },
      {
        timestamp: 1651155900,
        open: 1.0505,
        close: 1.05091,
        high: 1.051,
        low: 1.0505
      },
      {
        timestamp: 1651156200,
        open: 1.05091,
        close: 1.05011,
        high: 1.05125,
        low: 1.05008
      },
      {
        timestamp: 1651156500,
        open: 1.0501,
        close: 1.04926,
        high: 1.05015,
        low: 1.04896
      },
      {
        timestamp: 1651156800,
        open: 1.04927,
        close: 1.04909,
        high: 1.04943,
        low: 1.04886
      },
      {
        timestamp: 1651157100,
        open: 1.04908,
        close: 1.04922,
        high: 1.04946,
        low: 1.04886
      },
      {
        timestamp: 1651157400,
        open: 1.04923,
        close: 1.04988,
        high: 1.05011,
        low: 1.04904
      },
      {
        timestamp: 1651157700,
        open: 1.04989,
        close: 1.05138,
        high: 1.05145,
        low: 1.04974
      },
      {
        timestamp: 1651158000,
        open: 1.05138,
        close: 1.05163,
        high: 1.05205,
        low: 1.05108
      },
      {
        timestamp: 1651158300,
        open: 1.05163,
        close: 1.05296,
        high: 1.0532,
        low: 1.05162
      },
      {
        timestamp: 1651158600,
        open: 1.05295,
        close: 1.05213,
        high: 1.0532,
        low: 1.05208
      },
      {
        timestamp: 1651158900,
        open: 1.05213,
        close: 1.05244,
        high: 1.05286,
        low: 1.05208
      },
      {
        timestamp: 1651159200,
        open: 1.05243,
        close: 1.05248,
        high: 1.0526,
        low: 1.05191
      },
      {
        timestamp: 1651159500,
        open: 1.05247,
        close: 1.05215,
        high: 1.05272,
        low: 1.05194
      },
      {
        timestamp: 1651159800,
        open: 1.05216,
        close: 1.05209,
        high: 1.05248,
        low: 1.0516
      },
      {
        timestamp: 1651160100,
        open: 1.05208,
        close: 1.05153,
        high: 1.05214,
        low: 1.05149
      },
      {
        timestamp: 1651160400,
        open: 1.05154,
        close: 1.05116,
        high: 1.05164,
        low: 1.05112
      },
      {
        timestamp: 1651160700,
        open: 1.05116,
        close: 1.05085,
        high: 1.05141,
        low: 1.05078
      },
      {
        timestamp: 1651161000,
        open: 1.05085,
        close: 1.05039,
        high: 1.05088,
        low: 1.05024
      },
      {
        timestamp: 1651161300,
        open: 1.05039,
        close: 1.05006,
        high: 1.05043,
        low: 1.04977
      },
      {
        timestamp: 1651161600,
        open: 1.05006,
        close: 1.05056,
        high: 1.05075,
        low: 1.04995
      },
      {
        timestamp: 1651161900,
        open: 1.05056,
        close: 1.05017,
        high: 1.05057,
        low: 1.05013
      },
      {
        timestamp: 1651162200,
        open: 1.05016,
        close: 1.04999,
        high: 1.05025,
        low: 1.04978
      },
      {
        timestamp: 1651162500,
        open: 1.04999,
        close: 1.0496,
        high: 1.05043,
        low: 1.04959
      },
      {
        timestamp: 1651162800,
        open: 1.04961,
        close: 1.04975,
        high: 1.04986,
        low: 1.04942
      },
      {
        timestamp: 1651163100,
        open: 1.04976,
        close: 1.04992,
        high: 1.04993,
        low: 1.04958
      },
      {
        timestamp: 1651163400,
        open: 1.04993,
        close: 1.05006,
        high: 1.05007,
        low: 1.04973
      },
      {
        timestamp: 1651163700,
        open: 1.05005,
        close: 1.05056,
        high: 1.05058,
        low: 1.04997
      },
      {
        timestamp: 1651164000,
        open: 1.05056,
        close: 1.05107,
        high: 1.05115,
        low: 1.05048
      },
      {
        timestamp: 1651164300,
        open: 1.05106,
        close: 1.05148,
        high: 1.05159,
        low: 1.05103
      },
      {
        timestamp: 1651164600,
        open: 1.05149,
        close: 1.05091,
        high: 1.05149,
        low: 1.05088
      },
      {
        timestamp: 1651164900,
        open: 1.0509,
        close: 1.05131,
        high: 1.05136,
        low: 1.05088
      },
      {
        timestamp: 1651165200,
        open: 1.05132,
        close: 1.05149,
        high: 1.05155,
        low: 1.05087
      },
      {
        timestamp: 1651165500,
        open: 1.0515,
        close: 1.05104,
        high: 1.05153,
        low: 1.05103
      },
      {
        timestamp: 1651165800,
        open: 1.05103,
        close: 1.05145,
        high: 1.05164,
        low: 1.05092
      },
      {
        timestamp: 1651166100,
        open: 1.05145,
        close: 1.05111,
        high: 1.05152,
        low: 1.05091
      },
      {
        timestamp: 1651166400,
        open: 1.0511,
        close: 1.05087,
        high: 1.05114,
        low: 1.05074
      },
      {
        timestamp: 1651166700,
        open: 1.05087,
        close: 1.05085,
        high: 1.05108,
        low: 1.05077
      },
      {
        timestamp: 1651167000,
        open: 1.05085,
        close: 1.05054,
        high: 1.05094,
        low: 1.05054
      },
      {
        timestamp: 1651167300,
        open: 1.05055,
        close: 1.05073,
        high: 1.05076,
        low: 1.05023
      },
      {
        timestamp: 1651167600,
        open: 1.05074,
        close: 1.05036,
        high: 1.05097,
        low: 1.05021
      },
      {
        timestamp: 1651167900,
        open: 1.05037,
        close: 1.05028,
        high: 1.05046,
        low: 1.04994
      },
      {
        timestamp: 1651168200,
        open: 1.05027,
        close: 1.04998,
        high: 1.05039,
        low: 1.04987
      },
      {
        timestamp: 1651168500,
        open: 1.04999,
        close: 1.05002,
        high: 1.05005,
        low: 1.04979
      },
      {
        timestamp: 1651168800,
        open: 1.05002,
        close: 1.05003,
        high: 1.05006,
        low: 1.04985
      },
      {
        timestamp: 1651169100,
        open: 1.05003,
        close: 1.05005,
        high: 1.05007,
        low: 1.04984
      },
      {
        timestamp: 1651169400,
        open: 1.05004,
        close: 1.05037,
        high: 1.05041,
        low: 1.05004
      },
      {
        timestamp: 1651169700,
        open: 1.05036,
        close: 1.05038,
        high: 1.05055,
        low: 1.05029
      },
      {
        timestamp: 1651170000,
        open: 1.05037,
        close: 1.05096,
        high: 1.05108,
        low: 1.05035
      },
      {
        timestamp: 1651170300,
        open: 1.05095,
        close: 1.05087,
        high: 1.051,
        low: 1.05073
      },
      {
        timestamp: 1651170600,
        open: 1.05088,
        close: 1.05095,
        high: 1.05106,
        low: 1.05082
      },
      {
        timestamp: 1651170900,
        open: 1.05097,
        close: 1.05054,
        high: 1.05105,
        low: 1.05053
      },
      {
        timestamp: 1651171200,
        open: 1.05053,
        close: 1.05045,
        high: 1.05064,
        low: 1.0502
      },
      {
        timestamp: 1651171500,
        open: 1.05045,
        close: 1.05063,
        high: 1.05063,
        low: 1.05043
      },
      {
        timestamp: 1651171800,
        open: 1.05063,
        close: 1.05083,
        high: 1.0511,
        low: 1.05063
      },
      {
        timestamp: 1651172100,
        open: 1.05084,
        close: 1.05058,
        high: 1.05085,
        low: 1.05038
      },
      {
        timestamp: 1651172400,
        open: 1.05059,
        close: 1.05095,
        high: 1.05116,
        low: 1.05043
      },
      {
        timestamp: 1651172700,
        open: 1.05094,
        close: 1.051,
        high: 1.05103,
        low: 1.05083
      },
      {
        timestamp: 1651173000,
        open: 1.05101,
        close: 1.05068,
        high: 1.05107,
        low: 1.05068
      },
      {
        timestamp: 1651173300,
        open: 1.05068,
        close: 1.05091,
        high: 1.05096,
        low: 1.05061
      },
      {
        timestamp: 1651173600,
        open: 1.0509,
        close: 1.05069,
        high: 1.0509,
        low: 1.05069
      },
      {
        timestamp: 1651173900,
        open: 1.05069,
        close: 1.05071,
        high: 1.05095,
        low: 1.05069
      },
      {
        timestamp: 1651174200,
        open: 1.0507,
        close: 1.05094,
        high: 1.05097,
        low: 1.05061
      },
      {
        timestamp: 1651174500,
        open: 1.05095,
        close: 1.0507,
        high: 1.05097,
        low: 1.05065
      },
      {
        timestamp: 1651174800,
        open: 1.0507,
        close: 1.0508,
        high: 1.05081,
        low: 1.05056
      },
      {
        timestamp: 1651175100,
        open: 1.05081,
        close: 1.05049,
        high: 1.05083,
        low: 1.05044
      },
      {
        timestamp: 1651175400,
        open: 1.0505,
        close: 1.05067,
        high: 1.05081,
        low: 1.0504
      },
      {
        timestamp: 1651175700,
        open: 1.05067,
        close: 1.05105,
        high: 1.05105,
        low: 1.05054
      },
      {
        timestamp: 1651176000,
        open: 1.05106,
        close: 1.05062,
        high: 1.05107,
        low: 1.05059
      },
      {
        timestamp: 1651176300,
        open: 1.05062,
        close: 1.05068,
        high: 1.0507,
        low: 1.05042
      },
      {
        timestamp: 1651176600,
        open: 1.05069,
        close: 1.05065,
        high: 1.05078,
        low: 1.05065
      },
      {
        timestamp: 1651176900,
        open: 1.05063,
        close: 1.05054,
        high: 1.05074,
        low: 1.05049
      },
      {
        timestamp: 1651177200,
        open: 1.05053,
        close: 1.05056,
        high: 1.05065,
        low: 1.0505
      },
      {
        timestamp: 1651177500,
        open: 1.05057,
        close: 1.0505,
        high: 1.0506,
        low: 1.0504
      },
      {
        timestamp: 1651177800,
        open: 1.05053,
        close: 1.05046,
        high: 1.05073,
        low: 1.05045
      },
      {
        timestamp: 1651178100,
        open: 1.05047,
        close: 1.05037,
        high: 1.05049,
        low: 1.05036
      },
      {
        timestamp: 1651178400,
        open: 1.05036,
        close: 1.05047,
        high: 1.0505,
        low: 1.05035
      },
      {
        timestamp: 1651178700,
        open: 1.05048,
        close: 1.0502,
        high: 1.05048,
        low: 1.05019
      },
      {
        timestamp: 1651179000,
        open: 1.0502,
        close: 1.04999,
        high: 1.05022,
        low: 1.04996
      },
      {
        timestamp: 1651179300,
        open: 1.04999,
        close: 1.05,
        high: 1.05015,
        low: 1.04987
      },
      {
        timestamp: 1651179600,
        open: 1.04999,
        close: 1.05037,
        high: 1.05085,
        low: 1.04999
      },
      {
        timestamp: 1651179900,
        open: 1.05037,
        close: 1.05009,
        high: 1.05047,
        low: 1.05009
      },
      {
        timestamp: 1651180200,
        open: 1.05009,
        close: 1.05025,
        high: 1.0505,
        low: 1.05001
      },
      {
        timestamp: 1651180500,
        open: 1.05023,
        close: 1.05033,
        high: 1.05036,
        low: 1.05023
      },
      {
        timestamp: 1651180800,
        open: 1.05028,
        close: 1.05002,
        high: 1.05028,
        low: 1.05
      },
      {
        timestamp: 1651181100,
        open: 1.05002,
        close: 1.05001,
        high: 1.05002,
        low: 1.05001
      },
      {
        timestamp: 1651181400,
        open: 1.05001,
        close: 1.04999,
        high: 1.05002,
        low: 1.04999
      },
      {
        timestamp: 1651181700,
        open: 1.04999,
        close: 1.04998,
        high: 1.05028,
        low: 1.04994
      },
      {
        timestamp: 1651182000,
        open: 1.04998,
        close: 1.05001,
        high: 1.05001,
        low: 1.04997
      },
      {
        timestamp: 1651182300,
        open: 1.05002,
        close: 1.05002,
        high: 1.05002,
        low: 1.05002
      },
      {
        timestamp: 1651182600,
        open: 1.05002,
        close: 1.05011,
        high: 1.05014,
        low: 1.04995
      },
      {
        timestamp: 1651182900,
        open: 1.0501,
        close: 1.05012,
        high: 1.05025,
        low: 1.05006
      },
      {
        timestamp: 1651183200,
        open: 1.05015,
        close: 1.05004,
        high: 1.05024,
        low: 1.04989
      },
      {
        timestamp: 1651183500,
        open: 1.05004,
        close: 1.05023,
        high: 1.05033,
        low: 1.05
      },
      {
        timestamp: 1651183800,
        open: 1.05023,
        close: 1.04997,
        high: 1.05023,
        low: 1.04993
      },
      {
        timestamp: 1651184100,
        open: 1.04997,
        close: 1.0501,
        high: 1.0501,
        low: 1.04991
      },
      {
        timestamp: 1651184400,
        open: 1.05009,
        close: 1.05017,
        high: 1.05017,
        low: 1.05
      },
      {
        timestamp: 1651184700,
        open: 1.05017,
        close: 1.05002,
        high: 1.05024,
        low: 1.05
      },
      {
        timestamp: 1651185000,
        open: 1.05002,
        close: 1.05012,
        high: 1.05012,
        low: 1.04994
      },
      {
        timestamp: 1651185300,
        open: 1.05011,
        close: 1.05001,
        high: 1.05012,
        low: 1.04992
      },
      {
        timestamp: 1651185600,
        open: 1.05002,
        close: 1.05018,
        high: 1.05019,
        low: 1.05001
      },
      {
        timestamp: 1651185900,
        open: 1.05019,
        close: 1.04976,
        high: 1.05019,
        low: 1.04975
      },
      {
        timestamp: 1651186200,
        open: 1.04975,
        close: 1.04951,
        high: 1.04975,
        low: 1.04922
      },
      {
        timestamp: 1651186500,
        open: 1.04952,
        close: 1.04979,
        high: 1.04981,
        low: 1.04952
      },
      {
        timestamp: 1651186800,
        open: 1.0498,
        close: 1.0498,
        high: 1.04981,
        low: 1.04957
      },
      {
        timestamp: 1651187100,
        open: 1.04981,
        close: 1.04999,
        high: 1.05006,
        low: 1.04977
      },
      {
        timestamp: 1651187400,
        open: 1.04999,
        close: 1.04993,
        high: 1.05,
        low: 1.0499
      },
      {
        timestamp: 1651187700,
        open: 1.0499,
        close: 1.04992,
        high: 1.04993,
        low: 1.04971
      },
      {
        timestamp: 1651188000,
        open: 1.04991,
        close: 1.04989,
        high: 1.04998,
        low: 1.04972
      },
      {
        timestamp: 1651188300,
        open: 1.0499,
        close: 1.04998,
        high: 1.05001,
        low: 1.04984
      },
      {
        timestamp: 1651188600,
        open: 1.04998,
        close: 1.05029,
        high: 1.05035,
        low: 1.04995
      },
      {
        timestamp: 1651188900,
        open: 1.05029,
        close: 1.04996,
        high: 1.05031,
        low: 1.04993
      },
      {
        timestamp: 1651189200,
        open: 1.04997,
        close: 1.05014,
        high: 1.05014,
        low: 1.04991
      },
      {
        timestamp: 1651189500,
        open: 1.05013,
        close: 1.0502,
        high: 1.05021,
        low: 1.05006
      },
      {
        timestamp: 1651189800,
        open: 1.0502,
        close: 1.05035,
        high: 1.05037,
        low: 1.05008
      },
      {
        timestamp: 1651190100,
        open: 1.05035,
        close: 1.05037,
        high: 1.05043,
        low: 1.05022
      },
      {
        timestamp: 1651190400,
        open: 1.05039,
        close: 1.05068,
        high: 1.05086,
        low: 1.05028
      },
      {
        timestamp: 1651190700,
        open: 1.05068,
        close: 1.05126,
        high: 1.05131,
        low: 1.05067
      },
      {
        timestamp: 1651191000,
        open: 1.05124,
        close: 1.05144,
        high: 1.05159,
        low: 1.05113
      },
      {
        timestamp: 1651191300,
        open: 1.05144,
        close: 1.05157,
        high: 1.05169,
        low: 1.0514
      },
      {
        timestamp: 1651191600,
        open: 1.05158,
        close: 1.05134,
        high: 1.05164,
        low: 1.0512
      },
      {
        timestamp: 1651191900,
        open: 1.05134,
        close: 1.05134,
        high: 1.0515,
        low: 1.05119
      },
      {
        timestamp: 1651192200,
        open: 1.05134,
        close: 1.05114,
        high: 1.05148,
        low: 1.05108
      },
      {
        timestamp: 1651192500,
        open: 1.05114,
        close: 1.05133,
        high: 1.05137,
        low: 1.05114
      },
      {
        timestamp: 1651192800,
        open: 1.05132,
        close: 1.05093,
        high: 1.05133,
        low: 1.05091
      },
      {
        timestamp: 1651193100,
        open: 1.05094,
        close: 1.05113,
        high: 1.05113,
        low: 1.05073
      },
      {
        timestamp: 1651193400,
        open: 1.05118,
        close: 1.05119,
        high: 1.05129,
        low: 1.05112
      },
      {
        timestamp: 1651193700,
        open: 1.05119,
        close: 1.05164,
        high: 1.05181,
        low: 1.05117
      },
      {
        timestamp: 1651194000,
        open: 1.05164,
        close: 1.05193,
        high: 1.05196,
        low: 1.05155
      },
      {
        timestamp: 1651194300,
        open: 1.05194,
        close: 1.05196,
        high: 1.05207,
        low: 1.05178
      },
      {
        timestamp: 1651194600,
        open: 1.05195,
        close: 1.05237,
        high: 1.05255,
        low: 1.05195
      },
      {
        timestamp: 1651194900,
        open: 1.05236,
        close: 1.05259,
        high: 1.05263,
        low: 1.05222
      },
      {
        timestamp: 1651195200,
        open: 1.05258,
        close: 1.05234,
        high: 1.05273,
        low: 1.05233
      },
      {
        timestamp: 1651195500,
        open: 1.05233,
        close: 1.05302,
        high: 1.05305,
        low: 1.05233
      },
      {
        timestamp: 1651195800,
        open: 1.05301,
        close: 1.05301,
        high: 1.05305,
        low: 1.0528
      },
      {
        timestamp: 1651196100,
        open: 1.05301,
        close: 1.0528,
        high: 1.05305,
        low: 1.05276
      },
      {
        timestamp: 1651196400,
        open: 1.0528,
        close: 1.05243,
        high: 1.05293,
        low: 1.05227
      },
      {
        timestamp: 1651196700,
        open: 1.05242,
        close: 1.05279,
        high: 1.05287,
        low: 1.05229
      },
      {
        timestamp: 1651197000,
        open: 1.05278,
        close: 1.05242,
        high: 1.05284,
        low: 1.0524
      },
      {
        timestamp: 1651197300,
        open: 1.05242,
        close: 1.05196,
        high: 1.05243,
        low: 1.0519
      },
      {
        timestamp: 1651197600,
        open: 1.05198,
        close: 1.05232,
        high: 1.05239,
        low: 1.0519
      },
      {
        timestamp: 1651197900,
        open: 1.05232,
        close: 1.05223,
        high: 1.05232,
        low: 1.05209
      },
      {
        timestamp: 1651198200,
        open: 1.05223,
        close: 1.05182,
        high: 1.05231,
        low: 1.05174
      },
      {
        timestamp: 1651198500,
        open: 1.05182,
        close: 1.05181,
        high: 1.05185,
        low: 1.05156
      },
      {
        timestamp: 1651198800,
        open: 1.05181,
        close: 1.05156,
        high: 1.05184,
        low: 1.05154
      },
      {
        timestamp: 1651199100,
        open: 1.05155,
        close: 1.05156,
        high: 1.0516,
        low: 1.05143
      },
      {
        timestamp: 1651199400,
        open: 1.05155,
        close: 1.05129,
        high: 1.05162,
        low: 1.05128
      },
      {
        timestamp: 1651199700,
        open: 1.0513,
        close: 1.05142,
        high: 1.05176,
        low: 1.0513
      },
      {
        timestamp: 1651200000,
        open: 1.05143,
        close: 1.05114,
        high: 1.05145,
        low: 1.0509
      },
      {
        timestamp: 1651200300,
        open: 1.05115,
        close: 1.05074,
        high: 1.05135,
        low: 1.05058
      },
      {
        timestamp: 1651200600,
        open: 1.05074,
        close: 1.05125,
        high: 1.05131,
        low: 1.05073
      },
      {
        timestamp: 1651200900,
        open: 1.05124,
        close: 1.05111,
        high: 1.0513,
        low: 1.05106
      },
      {
        timestamp: 1651201200,
        open: 1.05112,
        close: 1.05144,
        high: 1.05161,
        low: 1.05109
      },
      {
        timestamp: 1651201500,
        open: 1.05143,
        close: 1.05145,
        high: 1.05154,
        low: 1.05118
      },
      {
        timestamp: 1651201800,
        open: 1.05143,
        close: 1.05108,
        high: 1.05149,
        low: 1.05074
      },
      {
        timestamp: 1651202100,
        open: 1.05107,
        close: 1.05134,
        high: 1.05144,
        low: 1.05089
      },
      {
        timestamp: 1651202400,
        open: 1.05135,
        close: 1.05114,
        high: 1.05135,
        low: 1.05114
      },
      {
        timestamp: 1651202700,
        open: 1.05113,
        close: 1.05124,
        high: 1.05153,
        low: 1.05103
      },
      {
        timestamp: 1651203000,
        open: 1.05125,
        close: 1.05182,
        high: 1.05186,
        low: 1.05124
      },
      {
        timestamp: 1651203300,
        open: 1.05182,
        close: 1.05171,
        high: 1.05185,
        low: 1.05159
      },
      {
        timestamp: 1651203600,
        open: 1.05172,
        close: 1.05172,
        high: 1.05185,
        low: 1.05165
      },
      {
        timestamp: 1651203900,
        open: 1.05171,
        close: 1.05197,
        high: 1.05197,
        low: 1.05163
      },
      {
        timestamp: 1651204200,
        open: 1.05198,
        close: 1.05124,
        high: 1.05202,
        low: 1.05123
      },
      {
        timestamp: 1651204500,
        open: 1.05122,
        close: 1.05141,
        high: 1.05142,
        low: 1.05099
      },
      {
        timestamp: 1651204800,
        open: 1.05141,
        close: 1.05118,
        high: 1.05141,
        low: 1.05105
      },
      {
        timestamp: 1651205100,
        open: 1.05118,
        close: 1.05132,
        high: 1.05136,
        low: 1.05095
      },
      {
        timestamp: 1651205400,
        open: 1.05131,
        close: 1.05148,
        high: 1.05156,
        low: 1.05127
      },
      {
        timestamp: 1651205700,
        open: 1.05148,
        close: 1.05147,
        high: 1.05161,
        low: 1.05144
      },
      {
        timestamp: 1651206000,
        open: 1.05147,
        close: 1.0514,
        high: 1.05158,
        low: 1.05121
      },
      {
        timestamp: 1651206300,
        open: 1.05139,
        close: 1.05149,
        high: 1.05159,
        low: 1.05124
      },
      {
        timestamp: 1651206600,
        open: 1.05148,
        close: 1.05129,
        high: 1.05163,
        low: 1.05125
      },
      {
        timestamp: 1651206900,
        open: 1.05129,
        close: 1.05145,
        high: 1.05149,
        low: 1.05127
      },
      {
        timestamp: 1651207200,
        open: 1.05145,
        close: 1.05161,
        high: 1.05163,
        low: 1.05144
      },
      {
        timestamp: 1651207500,
        open: 1.05161,
        close: 1.05163,
        high: 1.05167,
        low: 1.05155
      },
      {
        timestamp: 1651207800,
        open: 1.05164,
        close: 1.05199,
        high: 1.05216,
        low: 1.05159
      },
      {
        timestamp: 1651208100,
        open: 1.052,
        close: 1.05173,
        high: 1.052,
        low: 1.0516
      },
      {
        timestamp: 1651208400,
        open: 1.05173,
        close: 1.05241,
        high: 1.05255,
        low: 1.05173
      },
      {
        timestamp: 1651208700,
        open: 1.05242,
        close: 1.0524,
        high: 1.05259,
        low: 1.05216
      },
      {
        timestamp: 1651209000,
        open: 1.05241,
        close: 1.05274,
        high: 1.05276,
        low: 1.05239
      },
      {
        timestamp: 1651209300,
        open: 1.05275,
        close: 1.05313,
        high: 1.05321,
        low: 1.05263
      },
      {
        timestamp: 1651209600,
        open: 1.05314,
        close: 1.05328,
        high: 1.05347,
        low: 1.05307
      },
      {
        timestamp: 1651209900,
        open: 1.05327,
        close: 1.05312,
        high: 1.05347,
        low: 1.05302
      },
      {
        timestamp: 1651210200,
        open: 1.05312,
        close: 1.05316,
        high: 1.05332,
        low: 1.05296
      },
      {
        timestamp: 1651210500,
        open: 1.05317,
        close: 1.0537,
        high: 1.05404,
        low: 1.05317
      },
      {
        timestamp: 1651210800,
        open: 1.05369,
        close: 1.05364,
        high: 1.05372,
        low: 1.05329
      },
      {
        timestamp: 1651211100,
        open: 1.05364,
        close: 1.05378,
        high: 1.0538,
        low: 1.05336
      },
      {
        timestamp: 1651211400,
        open: 1.05379,
        close: 1.05408,
        high: 1.05428,
        low: 1.05379
      },
      {
        timestamp: 1651211700,
        open: 1.05407,
        close: 1.0538,
        high: 1.05407,
        low: 1.05375
      },
      {
        timestamp: 1651212000,
        open: 1.05379,
        close: 1.05369,
        high: 1.0538,
        low: 1.05318
      },
      {
        timestamp: 1651212300,
        open: 1.05369,
        close: 1.05366,
        high: 1.05397,
        low: 1.05348
      },
      {
        timestamp: 1651212600,
        open: 1.05365,
        close: 1.05386,
        high: 1.05386,
        low: 1.05319
      },
      {
        timestamp: 1651212900,
        open: 1.05385,
        close: 1.05417,
        high: 1.05443,
        low: 1.05378
      },
      {
        timestamp: 1651213200,
        open: 1.05417,
        close: 1.05471,
        high: 1.05494,
        low: 1.05416
      },
      {
        timestamp: 1651213500,
        open: 1.05471,
        close: 1.05467,
        high: 1.05489,
        low: 1.05459
      },
      {
        timestamp: 1651213800,
        open: 1.05467,
        close: 1.0543,
        high: 1.05468,
        low: 1.05426
      },
      {
        timestamp: 1651214100,
        open: 1.05428,
        close: 1.05467,
        high: 1.05488,
        low: 1.05413
      },
      {
        timestamp: 1651214400,
        open: 1.05465,
        close: 1.05418,
        high: 1.05466,
        low: 1.05401
      },
      {
        timestamp: 1651214700,
        open: 1.05418,
        close: 1.05454,
        high: 1.05477,
        low: 1.05418
      },
      {
        timestamp: 1651215000,
        open: 1.05454,
        close: 1.05443,
        high: 1.05455,
        low: 1.05421
      },
      {
        timestamp: 1651215300,
        open: 1.05443,
        close: 1.05445,
        high: 1.05477,
        low: 1.0542
      },
      {
        timestamp: 1651215600,
        open: 1.05444,
        close: 1.05397,
        high: 1.05462,
        low: 1.05378
      },
      {
        timestamp: 1651215900,
        open: 1.05397,
        close: 1.05413,
        high: 1.05432,
        low: 1.05366
      },
      {
        timestamp: 1651216200,
        open: 1.05414,
        close: 1.05498,
        high: 1.05533,
        low: 1.05404
      },
      {
        timestamp: 1651216500,
        open: 1.05498,
        close: 1.05458,
        high: 1.05538,
        low: 1.05453
      },
      {
        timestamp: 1651216800,
        open: 1.05459,
        close: 1.05525,
        high: 1.05533,
        low: 1.05456
      },
      {
        timestamp: 1651217100,
        open: 1.05526,
        close: 1.05577,
        high: 1.05598,
        low: 1.05522
      },
      {
        timestamp: 1651217400,
        open: 1.05576,
        close: 1.05627,
        high: 1.05641,
        low: 1.05574
      },
      {
        timestamp: 1651217700,
        open: 1.05627,
        close: 1.0577,
        high: 1.05795,
        low: 1.05618
      },
      {
        timestamp: 1651218000,
        open: 1.05769,
        close: 1.05742,
        high: 1.05789,
        low: 1.05729
      },
      {
        timestamp: 1651218300,
        open: 1.05742,
        close: 1.05682,
        high: 1.05743,
        low: 1.05681
      },
      {
        timestamp: 1651218600,
        open: 1.05682,
        close: 1.05737,
        high: 1.05738,
        low: 1.0568
      },
      {
        timestamp: 1651218900,
        open: 1.05737,
        close: 1.05626,
        high: 1.05737,
        low: 1.05623
      },
      {
        timestamp: 1651219200,
        open: 1.05626,
        close: 1.05676,
        high: 1.05678,
        low: 1.05612
      },
      {
        timestamp: 1651219500,
        open: 1.05676,
        close: 1.05647,
        high: 1.05697,
        low: 1.05632
      },
      {
        timestamp: 1651219800,
        open: 1.05647,
        close: 1.05611,
        high: 1.05652,
        low: 1.05588
      },
      {
        timestamp: 1651220100,
        open: 1.05611,
        close: 1.05657,
        high: 1.05658,
        low: 1.0561
      },
      {
        timestamp: 1651220400,
        open: 1.05657,
        close: 1.05642,
        high: 1.05674,
        low: 1.05637
      },
      {
        timestamp: 1651220700,
        open: 1.05642,
        close: 1.05754,
        high: 1.05757,
        low: 1.05633
      },
      {
        timestamp: 1651221000,
        open: 1.05755,
        close: 1.05745,
        high: 1.05791,
        low: 1.05728
      },
      {
        timestamp: 1651221300,
        open: 1.05745,
        close: 1.05773,
        high: 1.05803,
        low: 1.05729
      },
      {
        timestamp: 1651221600,
        open: 1.05774,
        close: 1.05894,
        high: 1.05936,
        low: 1.05771
      },
      {
        timestamp: 1651221900,
        open: 1.05893,
        close: 1.05803,
        high: 1.05901,
        low: 1.05787
      },
      {
        timestamp: 1651222200,
        open: 1.05802,
        close: 1.05713,
        high: 1.05802,
        low: 1.05702
      },
      {
        timestamp: 1651222500,
        open: 1.05714,
        close: 1.05698,
        high: 1.05735,
        low: 1.05664
      },
      {
        timestamp: 1651222800,
        open: 1.05699,
        close: 1.05758,
        high: 1.05769,
        low: 1.05667
      },
      {
        timestamp: 1651223100,
        open: 1.05758,
        close: 1.05727,
        high: 1.0576,
        low: 1.05702
      },
      {
        timestamp: 1651223400,
        open: 1.05728,
        close: 1.05709,
        high: 1.05733,
        low: 1.05674
      },
      {
        timestamp: 1651223700,
        open: 1.05709,
        close: 1.05763,
        high: 1.05763,
        low: 1.0568
      },
      {
        timestamp: 1651224000,
        open: 1.05763,
        close: 1.05683,
        high: 1.05768,
        low: 1.05667
      },
      {
        timestamp: 1651224300,
        open: 1.0568,
        close: 1.0572,
        high: 1.05731,
        low: 1.05665
      },
      {
        timestamp: 1651224600,
        open: 1.0572,
        close: 1.05742,
        high: 1.05772,
        low: 1.0572
      },
      {
        timestamp: 1651224900,
        open: 1.05743,
        close: 1.05744,
        high: 1.05765,
        low: 1.0572
      },
      {
        timestamp: 1651225200,
        open: 1.05743,
        close: 1.05704,
        high: 1.05755,
        low: 1.05693
      },
      {
        timestamp: 1651225500,
        open: 1.05704,
        close: 1.05791,
        high: 1.05799,
        low: 1.05691
      },
      {
        timestamp: 1651225800,
        open: 1.0579,
        close: 1.05759,
        high: 1.05809,
        low: 1.05729
      },
      {
        timestamp: 1651226100,
        open: 1.05759,
        close: 1.05788,
        high: 1.05795,
        low: 1.05752
      },
      {
        timestamp: 1651226400,
        open: 1.05788,
        close: 1.0573,
        high: 1.0579,
        low: 1.05716
      },
      {
        timestamp: 1651226700,
        open: 1.05731,
        close: 1.05682,
        high: 1.05738,
        low: 1.05662
      },
      {
        timestamp: 1651227000,
        open: 1.05682,
        close: 1.05646,
        high: 1.05692,
        low: 1.05643
      },
      {
        timestamp: 1651227300,
        open: 1.05647,
        close: 1.0565,
        high: 1.05653,
        low: 1.05622
      },
      {
        timestamp: 1651227600,
        open: 1.05651,
        close: 1.05686,
        high: 1.05697,
        low: 1.0565
      },
      {
        timestamp: 1651227900,
        open: 1.05687,
        close: 1.05736,
        high: 1.05737,
        low: 1.0568
      },
      {
        timestamp: 1651228200,
        open: 1.05735,
        close: 1.05729,
        high: 1.05753,
        low: 1.05669
      },
      {
        timestamp: 1651228500,
        open: 1.0573,
        close: 1.05696,
        high: 1.05731,
        low: 1.05682
      },
      {
        timestamp: 1651228800,
        open: 1.05695,
        close: 1.05715,
        high: 1.05733,
        low: 1.0568
      },
      {
        timestamp: 1651229100,
        open: 1.05716,
        close: 1.05649,
        high: 1.05726,
        low: 1.05649
      },
      {
        timestamp: 1651229400,
        open: 1.05649,
        close: 1.05664,
        high: 1.05686,
        low: 1.05638
      },
      {
        timestamp: 1651229700,
        open: 1.05664,
        close: 1.05652,
        high: 1.05676,
        low: 1.05642
      },
      {
        timestamp: 1651230000,
        open: 1.05653,
        close: 1.05663,
        high: 1.05687,
        low: 1.05641
      },
      {
        timestamp: 1651230300,
        open: 1.05663,
        close: 1.05636,
        high: 1.05695,
        low: 1.05628
      },
      {
        timestamp: 1651230600,
        open: 1.05636,
        close: 1.05595,
        high: 1.0564,
        low: 1.05553
      },
      {
        timestamp: 1651230900,
        open: 1.05595,
        close: 1.05609,
        high: 1.05625,
        low: 1.05563
      },
      {
        timestamp: 1651231200,
        open: 1.05608,
        close: 1.05644,
        high: 1.05662,
        low: 1.05597
      },
      {
        timestamp: 1651231500,
        open: 1.05644,
        close: 1.05768,
        high: 1.058,
        low: 1.05632
      },
      {
        timestamp: 1651231800,
        open: 1.05767,
        close: 1.05631,
        high: 1.05775,
        low: 1.05611
      },
      {
        timestamp: 1651232100,
        open: 1.05629,
        close: 1.05568,
        high: 1.05672,
        low: 1.0554
      },
      {
        timestamp: 1651232400,
        open: 1.05568,
        close: 1.05518,
        high: 1.05596,
        low: 1.05495
      },
      {
        timestamp: 1651232700,
        open: 1.05519,
        close: 1.05452,
        high: 1.05542,
        low: 1.05417
      },
      {
        timestamp: 1651233000,
        open: 1.05452,
        close: 1.05423,
        high: 1.05455,
        low: 1.05404
      },
      {
        timestamp: 1651233300,
        open: 1.05423,
        close: 1.05452,
        high: 1.0549,
        low: 1.0542
      },
      {
        timestamp: 1651233600,
        open: 1.05452,
        close: 1.05453,
        high: 1.05478,
        low: 1.05433
      },
      {
        timestamp: 1651233900,
        open: 1.05453,
        close: 1.05448,
        high: 1.05476,
        low: 1.05439
      },
      {
        timestamp: 1651234200,
        open: 1.05449,
        close: 1.0541,
        high: 1.05457,
        low: 1.05404
      },
      {
        timestamp: 1651234500,
        open: 1.05409,
        close: 1.0533,
        high: 1.05417,
        low: 1.05301
      },
      {
        timestamp: 1651234800,
        open: 1.0533,
        close: 1.05363,
        high: 1.05371,
        low: 1.05313
      },
      {
        timestamp: 1651235100,
        open: 1.05364,
        close: 1.05309,
        high: 1.05367,
        low: 1.05292
      },
      {
        timestamp: 1651235400,
        open: 1.05311,
        close: 1.0528,
        high: 1.05338,
        low: 1.05226
      },
      {
        timestamp: 1651235700,
        open: 1.05281,
        close: 1.05109,
        high: 1.0531,
        low: 1.05103
      },
      {
        timestamp: 1651236000,
        open: 1.05109,
        close: 1.05163,
        high: 1.05238,
        low: 1.05104
      },
      {
        timestamp: 1651236300,
        open: 1.05163,
        close: 1.05231,
        high: 1.05261,
        low: 1.05152
      },
      {
        timestamp: 1651236600,
        open: 1.05232,
        close: 1.05257,
        high: 1.05305,
        low: 1.0521
      },
      {
        timestamp: 1651236900,
        open: 1.05256,
        close: 1.05325,
        high: 1.05393,
        low: 1.05235
      },
      {
        timestamp: 1651237200,
        open: 1.05325,
        close: 1.05324,
        high: 1.05336,
        low: 1.05234
      },
      {
        timestamp: 1651237500,
        open: 1.05325,
        close: 1.05321,
        high: 1.05421,
        low: 1.0532
      },
      {
        timestamp: 1651237800,
        open: 1.05321,
        close: 1.05284,
        high: 1.0538,
        low: 1.05258
      },
      {
        timestamp: 1651238100,
        open: 1.05283,
        close: 1.05344,
        high: 1.05351,
        low: 1.05251
      },
      {
        timestamp: 1651238400,
        open: 1.05343,
        close: 1.05351,
        high: 1.05399,
        low: 1.05332
      },
      {
        timestamp: 1651238700,
        open: 1.05352,
        close: 1.05275,
        high: 1.05358,
        low: 1.05268
      },
      {
        timestamp: 1651239000,
        open: 1.05275,
        close: 1.053,
        high: 1.05312,
        low: 1.05259
      },
      {
        timestamp: 1651239300,
        open: 1.053,
        close: 1.05336,
        high: 1.05351,
        low: 1.0528
      },
      {
        timestamp: 1651239600,
        open: 1.05336,
        close: 1.05303,
        high: 1.05348,
        low: 1.05281
      },
      {
        timestamp: 1651239900,
        open: 1.05303,
        close: 1.05293,
        high: 1.05374,
        low: 1.05277
      },
      {
        timestamp: 1651240200,
        open: 1.05293,
        close: 1.05241,
        high: 1.05295,
        low: 1.05229
      },
      {
        timestamp: 1651240500,
        open: 1.05241,
        close: 1.05292,
        high: 1.05296,
        low: 1.05234
      },
      {
        timestamp: 1651240800,
        open: 1.05293,
        close: 1.0532,
        high: 1.05334,
        low: 1.05293
      },
      {
        timestamp: 1651241100,
        open: 1.05319,
        close: 1.05352,
        high: 1.05359,
        low: 1.05308
      },
      {
        timestamp: 1651241400,
        open: 1.05351,
        close: 1.0534,
        high: 1.05387,
        low: 1.05313
      },
      {
        timestamp: 1651241700,
        open: 1.0534,
        close: 1.05278,
        high: 1.05359,
        low: 1.05272
      },
      {
        timestamp: 1651242000,
        open: 1.05281,
        close: 1.05285,
        high: 1.05319,
        low: 1.05275
      },
      {
        timestamp: 1651242300,
        open: 1.05284,
        close: 1.05295,
        high: 1.05305,
        low: 1.05272
      },
      {
        timestamp: 1651242600,
        open: 1.05295,
        close: 1.05255,
        high: 1.05304,
        low: 1.05255
      },
      {
        timestamp: 1651242900,
        open: 1.05255,
        close: 1.0524,
        high: 1.05273,
        low: 1.0523
      },
      {
        timestamp: 1651243200,
        open: 1.0524,
        close: 1.05296,
        high: 1.05305,
        low: 1.05233
      },
      {
        timestamp: 1651243500,
        open: 1.05295,
        close: 1.05397,
        high: 1.05426,
        low: 1.05214
      },
      {
        timestamp: 1651243800,
        open: 1.05397,
        close: 1.05445,
        high: 1.05458,
        low: 1.05374
      },
      {
        timestamp: 1651244100,
        open: 1.05444,
        close: 1.05436,
        high: 1.05479,
        low: 1.05367
      },
      {
        timestamp: 1651244400,
        open: 1.05437,
        close: 1.05526,
        high: 1.05526,
        low: 1.05436
      },
      {
        timestamp: 1651244700,
        open: 1.05525,
        close: 1.05456,
        high: 1.05551,
        low: 1.05451
      },
      {
        timestamp: 1651245000,
        open: 1.05456,
        close: 1.05476,
        high: 1.05527,
        low: 1.05452
      },
      {
        timestamp: 1651245300,
        open: 1.05475,
        close: 1.05464,
        high: 1.05498,
        low: 1.05448
      },
      {
        timestamp: 1651245600,
        open: 1.05464,
        close: 1.05475,
        high: 1.05492,
        low: 1.05434
      },
      {
        timestamp: 1651245900,
        open: 1.05476,
        close: 1.05358,
        high: 1.05482,
        low: 1.05357
      },
      {
        timestamp: 1651246200,
        open: 1.05357,
        close: 1.0534,
        high: 1.05367,
        low: 1.05334
      },
      {
        timestamp: 1651246500,
        open: 1.05341,
        close: 1.05394,
        high: 1.05408,
        low: 1.05341
      },
      {
        timestamp: 1651246800,
        open: 1.05393,
        close: 1.05379,
        high: 1.0541,
        low: 1.05348
      },
      {
        timestamp: 1651247100,
        open: 1.0538,
        close: 1.05373,
        high: 1.05384,
        low: 1.05344
      },
      {
        timestamp: 1651247400,
        open: 1.05374,
        close: 1.05375,
        high: 1.05399,
        low: 1.05359
      },
      {
        timestamp: 1651247700,
        open: 1.05374,
        close: 1.0544,
        high: 1.05457,
        low: 1.05364
      },
      {
        timestamp: 1651248000,
        open: 1.0544,
        close: 1.05454,
        high: 1.0547,
        low: 1.05435
      },
      {
        timestamp: 1651248300,
        open: 1.05454,
        close: 1.05414,
        high: 1.05454,
        low: 1.05408
      },
      {
        timestamp: 1651248600,
        open: 1.05414,
        close: 1.05379,
        high: 1.05433,
        low: 1.05363
      },
      {
        timestamp: 1651248900,
        open: 1.05378,
        close: 1.05375,
        high: 1.05393,
        low: 1.05344
      },
      {
        timestamp: 1651249200,
        open: 1.05375,
        close: 1.05358,
        high: 1.05378,
        low: 1.05339
      },
      {
        timestamp: 1651249500,
        open: 1.05358,
        close: 1.05361,
        high: 1.05369,
        low: 1.05331
      },
      {
        timestamp: 1651249800,
        open: 1.05361,
        close: 1.05405,
        high: 1.05416,
        low: 1.05355
      },
      {
        timestamp: 1651250100,
        open: 1.05406,
        close: 1.0548,
        high: 1.05491,
        low: 1.05382
      },
      {
        timestamp: 1651250400,
        open: 1.0548,
        close: 1.05405,
        high: 1.05481,
        low: 1.05404
      },
      {
        timestamp: 1651250700,
        open: 1.05405,
        close: 1.0546,
        high: 1.05469,
        low: 1.05402
      },
      {
        timestamp: 1651251000,
        open: 1.05459,
        close: 1.05431,
        high: 1.0546,
        low: 1.05398
      },
      {
        timestamp: 1651251300,
        open: 1.05432,
        close: 1.05465,
        high: 1.05471,
        low: 1.05417
      },
      {
        timestamp: 1651251600,
        open: 1.05465,
        close: 1.05474,
        high: 1.05499,
        low: 1.05447
      },
      {
        timestamp: 1651251900,
        open: 1.05473,
        close: 1.05544,
        high: 1.05544,
        low: 1.0546
      },
      {
        timestamp: 1651252200,
        open: 1.05544,
        close: 1.05644,
        high: 1.0565,
        low: 1.05538
      },
      {
        timestamp: 1651252500,
        open: 1.05643,
        close: 1.05704,
        high: 1.05715,
        low: 1.05638
      },
      {
        timestamp: 1651252800,
        open: 1.05705,
        close: 1.05734,
        high: 1.05752,
        low: 1.05701
      },
      {
        timestamp: 1651253100,
        open: 1.05735,
        close: 1.05735,
        high: 1.05772,
        low: 1.05727
      },
      {
        timestamp: 1651253400,
        open: 1.05735,
        close: 1.05728,
        high: 1.0576,
        low: 1.05727
      },
      {
        timestamp: 1651253700,
        open: 1.05727,
        close: 1.05738,
        high: 1.05746,
        low: 1.05704
      },
      {
        timestamp: 1651254000,
        open: 1.05736,
        close: 1.05749,
        high: 1.05769,
        low: 1.05731
      },
      {
        timestamp: 1651254300,
        open: 1.05748,
        close: 1.05773,
        high: 1.05793,
        low: 1.05729
      },
      {
        timestamp: 1651254600,
        open: 1.05773,
        close: 1.05741,
        high: 1.05795,
        low: 1.05741
      },
      {
        timestamp: 1651254900,
        open: 1.05741,
        close: 1.05734,
        high: 1.05761,
        low: 1.05734
      },
      {
        timestamp: 1651255200,
        open: 1.05734,
        close: 1.05739,
        high: 1.05755,
        low: 1.05717
      },
      {
        timestamp: 1651255500,
        open: 1.05739,
        close: 1.057,
        high: 1.05745,
        low: 1.05685
      },
      {
        timestamp: 1651255800,
        open: 1.05701,
        close: 1.05659,
        high: 1.05713,
        low: 1.05656
      },
      {
        timestamp: 1651256100,
        open: 1.0566,
        close: 1.05723,
        high: 1.05727,
        low: 1.05659
      },
      {
        timestamp: 1651256400,
        open: 1.05722,
        close: 1.05706,
        high: 1.05726,
        low: 1.05674
      },
      {
        timestamp: 1651256700,
        open: 1.05705,
        close: 1.05705,
        high: 1.0574,
        low: 1.05694
      },
      {
        timestamp: 1651257000,
        open: 1.05706,
        close: 1.05683,
        high: 1.05732,
        low: 1.05675
      },
      {
        timestamp: 1651257300,
        open: 1.05683,
        close: 1.05662,
        high: 1.05686,
        low: 1.05652
      },
      {
        timestamp: 1651257600,
        open: 1.05662,
        close: 1.05679,
        high: 1.05687,
        low: 1.05642
      },
      {
        timestamp: 1651257900,
        open: 1.05679,
        close: 1.05707,
        high: 1.05718,
        low: 1.0567
      },
      {
        timestamp: 1651258200,
        open: 1.05707,
        close: 1.05711,
        high: 1.05715,
        low: 1.0568
      },
      {
        timestamp: 1651258500,
        open: 1.0571,
        close: 1.05705,
        high: 1.05739,
        low: 1.05694
      },
      {
        timestamp: 1651258800,
        open: 1.05705,
        close: 1.05686,
        high: 1.05715,
        low: 1.05671
      },
      {
        timestamp: 1651259100,
        open: 1.05686,
        close: 1.05678,
        high: 1.05696,
        low: 1.05673
      },
      {
        timestamp: 1651259400,
        open: 1.05677,
        close: 1.05672,
        high: 1.05681,
        low: 1.05651
      },
      {
        timestamp: 1651259700,
        open: 1.05674,
        close: 1.05691,
        high: 1.05698,
        low: 1.0565
      },
      {
        timestamp: 1651260000,
        open: 1.05692,
        close: 1.05632,
        high: 1.05704,
        low: 1.05615
      },
      {
        timestamp: 1651260300,
        open: 1.05632,
        close: 1.05593,
        high: 1.05633,
        low: 1.05585
      },
      {
        timestamp: 1651260600,
        open: 1.05593,
        close: 1.05552,
        high: 1.05598,
        low: 1.05543
      },
      {
        timestamp: 1651260900,
        open: 1.05552,
        close: 1.05537,
        high: 1.05565,
        low: 1.05531
      },
      {
        timestamp: 1651261200,
        open: 1.05538,
        close: 1.05553,
        high: 1.05563,
        low: 1.05527
      },
      {
        timestamp: 1651261500,
        open: 1.05554,
        close: 1.05534,
        high: 1.0557,
        low: 1.05531
      },
      {
        timestamp: 1651261800,
        open: 1.05533,
        close: 1.05499,
        high: 1.05533,
        low: 1.05468
      },
      {
        timestamp: 1651262100,
        open: 1.05499,
        close: 1.05486,
        high: 1.05514,
        low: 1.0546
      },
      {
        timestamp: 1651262400,
        open: 1.05485,
        close: 1.05492,
        high: 1.05513,
        low: 1.05477
      },
      {
        timestamp: 1651262700,
        open: 1.05492,
        close: 1.05455,
        high: 1.05497,
        low: 1.05452
      },
      {
        timestamp: 1651263000,
        open: 1.05455,
        close: 1.05479,
        high: 1.0548,
        low: 1.05429
      },
      {
        timestamp: 1651263300,
        open: 1.05478,
        close: 1.0551,
        high: 1.05517,
        low: 1.05465
      },
      {
        timestamp: 1651263600,
        open: 1.05509,
        close: 1.05512,
        high: 1.05528,
        low: 1.05504
      },
      {
        timestamp: 1651263900,
        open: 1.0551,
        close: 1.05513,
        high: 1.05516,
        low: 1.05502
      },
      {
        timestamp: 1651264200,
        open: 1.05514,
        close: 1.05447,
        high: 1.05514,
        low: 1.05447
      },
      {
        timestamp: 1651264500,
        open: 1.05447,
        close: 1.0545,
        high: 1.05468,
        low: 1.05446
      },
      {
        timestamp: 1651264800,
        open: 1.05451,
        close: 1.05454,
        high: 1.05459,
        low: 1.05428
      },
      {
        timestamp: 1651265100,
        open: 1.05454,
        close: 1.05453,
        high: 1.0547,
        low: 1.05433
      },
      {
        timestamp: 1651265400,
        open: 1.05456,
        close: 1.05472,
        high: 1.05476,
        low: 1.05445
      },
      {
        timestamp: 1651265700,
        open: 1.05471,
        close: 1.05502,
        high: 1.05507,
        low: 1.0546
      },
      {
        timestamp: 1651439100,
        open: 1.05635,
        close: 1.05513,
        high: 1.05637,
        low: 1.05504
      },
      {
        timestamp: 1651439400,
        open: 1.05501,
        close: 1.05459,
        high: 1.05501,
        low: 1.05459
      },
      {
        timestamp: 1651439700,
        open: 1.05459,
        close: 1.05484,
        high: 1.0549,
        low: 1.05459
      },
      {
        timestamp: 1651440000,
        open: 1.05484,
        close: 1.05477,
        high: 1.05488,
        low: 1.05477
      },
      {
        timestamp: 1651440300,
        open: 1.0548,
        close: 1.05488,
        high: 1.05492,
        low: 1.05475
      },
      {
        timestamp: 1651440600,
        open: 1.05488,
        close: 1.0549,
        high: 1.05496,
        low: 1.05484
      },
      {
        timestamp: 1651440900,
        open: 1.0549,
        close: 1.0549,
        high: 1.0549,
        low: 1.0549
      },
      {
        timestamp: 1651441200,
        open: 1.05494,
        close: 1.05487,
        high: 1.05494,
        low: 1.05486
      },
      {
        timestamp: 1651442100,
        open: 1.05482,
        close: 1.05494,
        high: 1.05494,
        low: 1.05477
      },
      {
        timestamp: 1651442400,
        open: 1.05493,
        close: 1.05507,
        high: 1.05512,
        low: 1.05482
      },
      {
        timestamp: 1651442700,
        open: 1.05506,
        close: 1.05494,
        high: 1.05511,
        low: 1.05478
      },
      {
        timestamp: 1651443000,
        open: 1.05494,
        close: 1.05474,
        high: 1.05534,
        low: 1.05465
      },
      {
        timestamp: 1651443300,
        open: 1.05472,
        close: 1.05486,
        high: 1.05486,
        low: 1.05439
      },
      {
        timestamp: 1651443600,
        open: 1.05488,
        close: 1.05452,
        high: 1.05492,
        low: 1.05451
      },
      {
        timestamp: 1651443900,
        open: 1.05451,
        close: 1.0538,
        high: 1.05452,
        low: 1.05377
      },
      {
        timestamp: 1651444200,
        open: 1.0538,
        close: 1.05402,
        high: 1.05408,
        low: 1.05379
      },
      {
        timestamp: 1651444500,
        open: 1.05403,
        close: 1.05426,
        high: 1.05428,
        low: 1.05389
      },
      {
        timestamp: 1651444800,
        open: 1.05427,
        close: 1.05404,
        high: 1.05428,
        low: 1.05402
      },
      {
        timestamp: 1651445100,
        open: 1.05404,
        close: 1.05448,
        high: 1.05449,
        low: 1.05401
      },
      {
        timestamp: 1651445400,
        open: 1.05448,
        close: 1.05466,
        high: 1.05476,
        low: 1.05442
      },
      {
        timestamp: 1651446000,
        open: 1.05439,
        close: 1.0547,
        high: 1.05475,
        low: 1.05437
      },
      {
        timestamp: 1651446300,
        open: 1.05469,
        close: 1.05491,
        high: 1.05499,
        low: 1.05457
      },
      {
        timestamp: 1651446600,
        open: 1.0549,
        close: 1.0544,
        high: 1.05492,
        low: 1.05427
      },
      {
        timestamp: 1651446900,
        open: 1.05441,
        close: 1.05468,
        high: 1.05483,
        low: 1.05441
      },
      {
        timestamp: 1651447200,
        open: 1.05468,
        close: 1.05448,
        high: 1.05493,
        low: 1.05448
      },
      {
        timestamp: 1651447500,
        open: 1.05449,
        close: 1.05429,
        high: 1.0546,
        low: 1.05406
      },
      {
        timestamp: 1651447800,
        open: 1.0543,
        close: 1.05428,
        high: 1.0546,
        low: 1.05417
      },
      {
        timestamp: 1651448100,
        open: 1.05429,
        close: 1.05459,
        high: 1.05464,
        low: 1.05429
      },
      {
        timestamp: 1651448400,
        open: 1.05459,
        close: 1.05447,
        high: 1.05467,
        low: 1.05439
      },
      {
        timestamp: 1651448700,
        open: 1.05447,
        close: 1.05427,
        high: 1.05452,
        low: 1.05423
      },
      {
        timestamp: 1651449000,
        open: 1.05426,
        close: 1.05408,
        high: 1.05433,
        low: 1.05385
      },
      {
        timestamp: 1651449300,
        open: 1.05408,
        close: 1.05357,
        high: 1.05409,
        low: 1.0535
      },
      {
        timestamp: 1651449600,
        open: 1.05357,
        close: 1.05442,
        high: 1.05458,
        low: 1.05355
      },
      {
        timestamp: 1651449900,
        open: 1.05442,
        close: 1.05508,
        high: 1.05515,
        low: 1.05442
      },
      {
        timestamp: 1651450200,
        open: 1.05508,
        close: 1.05528,
        high: 1.05532,
        low: 1.05502
      },
      {
        timestamp: 1651450500,
        open: 1.05528,
        close: 1.05503,
        high: 1.05532,
        low: 1.05487
      },
      {
        timestamp: 1651450800,
        open: 1.05502,
        close: 1.05529,
        high: 1.05533,
        low: 1.05497
      },
      {
        timestamp: 1651451100,
        open: 1.05529,
        close: 1.05473,
        high: 1.05538,
        low: 1.05473
      },
      {
        timestamp: 1651451400,
        open: 1.05473,
        close: 1.05409,
        high: 1.05474,
        low: 1.05348
      },
      {
        timestamp: 1651451700,
        open: 1.05409,
        close: 1.05376,
        high: 1.05411,
        low: 1.05364
      },
      {
        timestamp: 1651452000,
        open: 1.05376,
        close: 1.0535,
        high: 1.05394,
        low: 1.05349
      },
      {
        timestamp: 1651452300,
        open: 1.05349,
        close: 1.05306,
        high: 1.05355,
        low: 1.05274
      },
      {
        timestamp: 1651452600,
        open: 1.05306,
        close: 1.05324,
        high: 1.05325,
        low: 1.05283
      },
      {
        timestamp: 1651452900,
        open: 1.05324,
        close: 1.05302,
        high: 1.05337,
        low: 1.05281
      },
      {
        timestamp: 1651453200,
        open: 1.05302,
        close: 1.05298,
        high: 1.0534,
        low: 1.05297
      },
      {
        timestamp: 1651453500,
        open: 1.05301,
        close: 1.05328,
        high: 1.05336,
        low: 1.05297
      },
      {
        timestamp: 1651453800,
        open: 1.05327,
        close: 1.05285,
        high: 1.05328,
        low: 1.0528
      },
      {
        timestamp: 1651454100,
        open: 1.05285,
        close: 1.05288,
        high: 1.05293,
        low: 1.05272
      },
      {
        timestamp: 1651454400,
        open: 1.05288,
        close: 1.05278,
        high: 1.0531,
        low: 1.05278
      },
      {
        timestamp: 1651454700,
        open: 1.05278,
        close: 1.0528,
        high: 1.0528,
        low: 1.05243
      },
      {
        timestamp: 1651455000,
        open: 1.0528,
        close: 1.05252,
        high: 1.05281,
        low: 1.05233
      },
      {
        timestamp: 1651455300,
        open: 1.05252,
        close: 1.0526,
        high: 1.05267,
        low: 1.05233
      },
      {
        timestamp: 1651455600,
        open: 1.05258,
        close: 1.05293,
        high: 1.05294,
        low: 1.05254
      },
      {
        timestamp: 1651455900,
        open: 1.05293,
        close: 1.05242,
        high: 1.05299,
        low: 1.05236
      },
      {
        timestamp: 1651456200,
        open: 1.05243,
        close: 1.05243,
        high: 1.05268,
        low: 1.05225
      },
      {
        timestamp: 1651456500,
        open: 1.05243,
        close: 1.05251,
        high: 1.05255,
        low: 1.05222
      },
      {
        timestamp: 1651456800,
        open: 1.05252,
        close: 1.05243,
        high: 1.05274,
        low: 1.05236
      },
      {
        timestamp: 1651457100,
        open: 1.05243,
        close: 1.05217,
        high: 1.0525,
        low: 1.05209
      },
      {
        timestamp: 1651457400,
        open: 1.05218,
        close: 1.05201,
        high: 1.05224,
        low: 1.05188
      },
      {
        timestamp: 1651457700,
        open: 1.05202,
        close: 1.05219,
        high: 1.0523,
        low: 1.05193
      },
      {
        timestamp: 1651458000,
        open: 1.05219,
        close: 1.05225,
        high: 1.05228,
        low: 1.05207
      },
      {
        timestamp: 1651458300,
        open: 1.05225,
        close: 1.05265,
        high: 1.05271,
        low: 1.05224
      },
      {
        timestamp: 1651458600,
        open: 1.05265,
        close: 1.05237,
        high: 1.05277,
        low: 1.05237
      },
      {
        timestamp: 1651458900,
        open: 1.05237,
        close: 1.05268,
        high: 1.05277,
        low: 1.05218
      },
      {
        timestamp: 1651459200,
        open: 1.05269,
        close: 1.05267,
        high: 1.05277,
        low: 1.05251
      },
      {
        timestamp: 1651459500,
        open: 1.05268,
        close: 1.05248,
        high: 1.05283,
        low: 1.05248
      },
      {
        timestamp: 1651459800,
        open: 1.05248,
        close: 1.0523,
        high: 1.05257,
        low: 1.05217
      },
      {
        timestamp: 1651460100,
        open: 1.0523,
        close: 1.05218,
        high: 1.0524,
        low: 1.05218
      },
      {
        timestamp: 1651460400,
        open: 1.05218,
        close: 1.05248,
        high: 1.05269,
        low: 1.05218
      },
      {
        timestamp: 1651460700,
        open: 1.05248,
        close: 1.05236,
        high: 1.05253,
        low: 1.05211
      },
      {
        timestamp: 1651461000,
        open: 1.05236,
        close: 1.05235,
        high: 1.05238,
        low: 1.05221
      },
      {
        timestamp: 1651461300,
        open: 1.05235,
        close: 1.05208,
        high: 1.05245,
        low: 1.05208
      },
      {
        timestamp: 1651461600,
        open: 1.05207,
        close: 1.05222,
        high: 1.0524,
        low: 1.05204
      },
      {
        timestamp: 1651461900,
        open: 1.05223,
        close: 1.05188,
        high: 1.05223,
        low: 1.05181
      },
      {
        timestamp: 1651462200,
        open: 1.05187,
        close: 1.05224,
        high: 1.05236,
        low: 1.05187
      },
      {
        timestamp: 1651462500,
        open: 1.05223,
        close: 1.05228,
        high: 1.05228,
        low: 1.05203
      },
      {
        timestamp: 1651462800,
        open: 1.05227,
        close: 1.05248,
        high: 1.05254,
        low: 1.05227
      },
      {
        timestamp: 1651463100,
        open: 1.05247,
        close: 1.05259,
        high: 1.05264,
        low: 1.05247
      },
      {
        timestamp: 1651463400,
        open: 1.05258,
        close: 1.05266,
        high: 1.05281,
        low: 1.05248
      },
      {
        timestamp: 1651463700,
        open: 1.05267,
        close: 1.05249,
        high: 1.0528,
        low: 1.0524
      },
      {
        timestamp: 1651464000,
        open: 1.05248,
        close: 1.05213,
        high: 1.05251,
        low: 1.05195
      },
      {
        timestamp: 1651464300,
        open: 1.05214,
        close: 1.05209,
        high: 1.05219,
        low: 1.05188
      },
      {
        timestamp: 1651464600,
        open: 1.05204,
        close: 1.05219,
        high: 1.05234,
        low: 1.05193
      },
      {
        timestamp: 1651464900,
        open: 1.0522,
        close: 1.05207,
        high: 1.05223,
        low: 1.05202
      },
      {
        timestamp: 1651465200,
        open: 1.05206,
        close: 1.05203,
        high: 1.05216,
        low: 1.05202
      },
      {
        timestamp: 1651465500,
        open: 1.05203,
        close: 1.0519,
        high: 1.05209,
        low: 1.0519
      },
      {
        timestamp: 1651465800,
        open: 1.0519,
        close: 1.05187,
        high: 1.05199,
        low: 1.05179
      },
      {
        timestamp: 1651466100,
        open: 1.05187,
        close: 1.05184,
        high: 1.05203,
        low: 1.05177
      },
      {
        timestamp: 1651466400,
        open: 1.05183,
        close: 1.0519,
        high: 1.05207,
        low: 1.05179
      },
      {
        timestamp: 1651466700,
        open: 1.0519,
        close: 1.05195,
        high: 1.05207,
        low: 1.05185
      },
      {
        timestamp: 1651467000,
        open: 1.05196,
        close: 1.05191,
        high: 1.05202,
        low: 1.05185
      },
      {
        timestamp: 1651467300,
        open: 1.05192,
        close: 1.05193,
        high: 1.05203,
        low: 1.05189
      },
      {
        timestamp: 1651467600,
        open: 1.05193,
        close: 1.05194,
        high: 1.052,
        low: 1.05168
      },
      {
        timestamp: 1651467900,
        open: 1.05193,
        close: 1.05188,
        high: 1.05203,
        low: 1.05182
      },
      {
        timestamp: 1651468200,
        open: 1.05188,
        close: 1.0519,
        high: 1.05204,
        low: 1.05169
      },
      {
        timestamp: 1651468500,
        open: 1.05191,
        close: 1.0519,
        high: 1.05203,
        low: 1.05179
      },
      {
        timestamp: 1651468800,
        open: 1.05189,
        close: 1.05177,
        high: 1.0519,
        low: 1.05177
      },
      {
        timestamp: 1651469100,
        open: 1.05177,
        close: 1.05178,
        high: 1.05185,
        low: 1.05169
      },
      {
        timestamp: 1651469400,
        open: 1.05178,
        close: 1.05172,
        high: 1.05178,
        low: 1.05162
      },
      {
        timestamp: 1651469700,
        open: 1.05172,
        close: 1.05217,
        high: 1.0522,
        low: 1.05169
      },
      {
        timestamp: 1651470000,
        open: 1.05216,
        close: 1.05177,
        high: 1.05217,
        low: 1.05177
      },
      {
        timestamp: 1651470300,
        open: 1.05178,
        close: 1.05192,
        high: 1.05208,
        low: 1.05176
      },
      {
        timestamp: 1651470600,
        open: 1.05191,
        close: 1.05183,
        high: 1.05217,
        low: 1.05175
      },
      {
        timestamp: 1651470900,
        open: 1.05182,
        close: 1.05141,
        high: 1.05191,
        low: 1.05111
      },
      {
        timestamp: 1651471200,
        open: 1.05141,
        close: 1.05203,
        high: 1.05218,
        low: 1.05136
      },
      {
        timestamp: 1651471500,
        open: 1.052,
        close: 1.05139,
        high: 1.052,
        low: 1.05135
      },
      {
        timestamp: 1651471800,
        open: 1.05138,
        close: 1.05145,
        high: 1.05151,
        low: 1.05108
      },
      {
        timestamp: 1651472100,
        open: 1.05144,
        close: 1.05191,
        high: 1.05191,
        low: 1.05124
      },
      {
        timestamp: 1651472400,
        open: 1.05191,
        close: 1.05195,
        high: 1.05208,
        low: 1.05178
      },
      {
        timestamp: 1651472700,
        open: 1.05195,
        close: 1.0522,
        high: 1.05239,
        low: 1.05189
      },
      {
        timestamp: 1651473000,
        open: 1.05219,
        close: 1.05198,
        high: 1.05234,
        low: 1.05194
      },
      {
        timestamp: 1651473300,
        open: 1.052,
        close: 1.05239,
        high: 1.05239,
        low: 1.05192
      },
      {
        timestamp: 1651473600,
        open: 1.05239,
        close: 1.05253,
        high: 1.05267,
        low: 1.05229
      },
      {
        timestamp: 1651473900,
        open: 1.05254,
        close: 1.05279,
        high: 1.05292,
        low: 1.05242
      },
      {
        timestamp: 1651474200,
        open: 1.05278,
        close: 1.05343,
        high: 1.05345,
        low: 1.05272
      },
      {
        timestamp: 1651474500,
        open: 1.05343,
        close: 1.05492,
        high: 1.05526,
        low: 1.05324
      },
      {
        timestamp: 1651474800,
        open: 1.05493,
        close: 1.05549,
        high: 1.05694,
        low: 1.05459
      },
      {
        timestamp: 1651475100,
        open: 1.05549,
        close: 1.05571,
        high: 1.05571,
        low: 1.05506
      },
      {
        timestamp: 1651475400,
        open: 1.0557,
        close: 1.05561,
        high: 1.05573,
        low: 1.05503
      },
      {
        timestamp: 1651475700,
        open: 1.0556,
        close: 1.055,
        high: 1.05591,
        low: 1.05498
      },
      {
        timestamp: 1651476000,
        open: 1.055,
        close: 1.05456,
        high: 1.05524,
        low: 1.05439
      },
      {
        timestamp: 1651476300,
        open: 1.05457,
        close: 1.05473,
        high: 1.05479,
        low: 1.05429
      },
      {
        timestamp: 1651476600,
        open: 1.05473,
        close: 1.0548,
        high: 1.05487,
        low: 1.05429
      },
      {
        timestamp: 1651476900,
        open: 1.05479,
        close: 1.05468,
        high: 1.05485,
        low: 1.05433
      },
      {
        timestamp: 1651477200,
        open: 1.05468,
        close: 1.05494,
        high: 1.05502,
        low: 1.05449
      },
      {
        timestamp: 1651477500,
        open: 1.05494,
        close: 1.05473,
        high: 1.05511,
        low: 1.05461
      },
      {
        timestamp: 1651477800,
        open: 1.05472,
        close: 1.05411,
        high: 1.0548,
        low: 1.05401
      },
      {
        timestamp: 1651478100,
        open: 1.05412,
        close: 1.05333,
        high: 1.05442,
        low: 1.05327
      },
      {
        timestamp: 1651478400,
        open: 1.05335,
        close: 1.05308,
        high: 1.05368,
        low: 1.05304
      },
      {
        timestamp: 1651478700,
        open: 1.05307,
        close: 1.0533,
        high: 1.05355,
        low: 1.05262
      },
      {
        timestamp: 1651479000,
        open: 1.05329,
        close: 1.05314,
        high: 1.0537,
        low: 1.05314
      },
      {
        timestamp: 1651479300,
        open: 1.05313,
        close: 1.05325,
        high: 1.05332,
        low: 1.053
      },
      {
        timestamp: 1651479600,
        open: 1.05324,
        close: 1.05279,
        high: 1.05332,
        low: 1.0527
      },
      {
        timestamp: 1651479900,
        open: 1.05279,
        close: 1.05305,
        high: 1.05327,
        low: 1.05271
      },
      {
        timestamp: 1651480200,
        open: 1.05306,
        close: 1.05269,
        high: 1.05318,
        low: 1.05269
      },
      {
        timestamp: 1651480500,
        open: 1.0527,
        close: 1.05343,
        high: 1.05346,
        low: 1.05267
      },
      {
        timestamp: 1651480800,
        open: 1.05343,
        close: 1.05312,
        high: 1.05366,
        low: 1.05293
      },
      {
        timestamp: 1651481100,
        open: 1.05312,
        close: 1.05285,
        high: 1.05312,
        low: 1.05267
      },
      {
        timestamp: 1651481400,
        open: 1.05286,
        close: 1.05335,
        high: 1.05351,
        low: 1.05277
      },
      {
        timestamp: 1651481700,
        open: 1.05335,
        close: 1.05297,
        high: 1.05346,
        low: 1.05281
      },
      {
        timestamp: 1651482000,
        open: 1.05297,
        close: 1.05312,
        high: 1.05337,
        low: 1.05291
      },
      {
        timestamp: 1651482300,
        open: 1.05311,
        close: 1.05272,
        high: 1.05313,
        low: 1.05261
      },
      {
        timestamp: 1651482600,
        open: 1.05272,
        close: 1.05246,
        high: 1.05298,
        low: 1.05246
      },
      {
        timestamp: 1651482900,
        open: 1.05246,
        close: 1.05247,
        high: 1.05277,
        low: 1.05241
      },
      {
        timestamp: 1651483200,
        open: 1.05248,
        close: 1.05248,
        high: 1.05256,
        low: 1.05226
      },
      {
        timestamp: 1651483500,
        open: 1.05249,
        close: 1.05276,
        high: 1.05286,
        low: 1.05227
      },
      {
        timestamp: 1651483800,
        open: 1.05277,
        close: 1.05245,
        high: 1.05282,
        low: 1.05195
      },
      {
        timestamp: 1651484100,
        open: 1.05244,
        close: 1.05211,
        high: 1.05255,
        low: 1.05195
      },
      {
        timestamp: 1651484400,
        open: 1.0521,
        close: 1.05244,
        high: 1.05259,
        low: 1.05203
      },
      {
        timestamp: 1651484700,
        open: 1.05244,
        close: 1.05257,
        high: 1.05257,
        low: 1.05208
      },
      {
        timestamp: 1651485000,
        open: 1.05255,
        close: 1.05272,
        high: 1.05281,
        low: 1.05232
      },
      {
        timestamp: 1651485300,
        open: 1.05273,
        close: 1.05275,
        high: 1.05281,
        low: 1.05261
      },
      {
        timestamp: 1651485600,
        open: 1.05275,
        close: 1.0526,
        high: 1.05293,
        low: 1.0524
      },
      {
        timestamp: 1651485900,
        open: 1.05259,
        close: 1.05237,
        high: 1.05274,
        low: 1.05232
      },
      {
        timestamp: 1651486200,
        open: 1.05236,
        close: 1.05213,
        high: 1.05236,
        low: 1.05207
      },
      {
        timestamp: 1651486500,
        open: 1.05213,
        close: 1.05245,
        high: 1.05252,
        low: 1.05206
      },
      {
        timestamp: 1651486800,
        open: 1.05245,
        close: 1.0524,
        high: 1.05259,
        low: 1.05233
      },
      {
        timestamp: 1651487100,
        open: 1.05239,
        close: 1.05227,
        high: 1.05245,
        low: 1.05212
      },
      {
        timestamp: 1651487400,
        open: 1.05227,
        close: 1.05223,
        high: 1.05232,
        low: 1.05208
      },
      {
        timestamp: 1651487700,
        open: 1.05224,
        close: 1.05265,
        high: 1.05269,
        low: 1.05219
      },
      {
        timestamp: 1651488000,
        open: 1.05265,
        close: 1.0525,
        high: 1.05299,
        low: 1.05248
      },
      {
        timestamp: 1651488300,
        open: 1.05251,
        close: 1.05237,
        high: 1.05251,
        low: 1.0523
      },
      {
        timestamp: 1651488600,
        open: 1.05239,
        close: 1.05232,
        high: 1.05276,
        low: 1.05222
      },
      {
        timestamp: 1651488900,
        open: 1.05232,
        close: 1.05271,
        high: 1.05285,
        low: 1.05232
      },
      {
        timestamp: 1651489200,
        open: 1.0527,
        close: 1.05179,
        high: 1.05275,
        low: 1.05172
      },
      {
        timestamp: 1651489500,
        open: 1.05178,
        close: 1.05221,
        high: 1.05236,
        low: 1.05174
      },
      {
        timestamp: 1651489800,
        open: 1.0522,
        close: 1.05249,
        high: 1.0525,
        low: 1.05214
      },
      {
        timestamp: 1651490100,
        open: 1.0525,
        close: 1.05253,
        high: 1.05273,
        low: 1.05217
      },
      {
        timestamp: 1651490400,
        open: 1.05254,
        close: 1.05265,
        high: 1.05274,
        low: 1.05242
      },
      {
        timestamp: 1651490700,
        open: 1.05266,
        close: 1.05278,
        high: 1.05285,
        low: 1.0526
      },
      {
        timestamp: 1651491000,
        open: 1.05277,
        close: 1.05255,
        high: 1.05281,
        low: 1.05231
      },
      {
        timestamp: 1651491300,
        open: 1.05254,
        close: 1.0526,
        high: 1.05273,
        low: 1.0524
      },
      {
        timestamp: 1651491600,
        open: 1.05261,
        close: 1.05212,
        high: 1.05263,
        low: 1.05209
      },
      {
        timestamp: 1651491900,
        open: 1.05213,
        close: 1.05221,
        high: 1.05249,
        low: 1.05212
      },
      {
        timestamp: 1651492200,
        open: 1.0522,
        close: 1.05183,
        high: 1.0522,
        low: 1.05169
      },
      {
        timestamp: 1651492500,
        open: 1.05182,
        close: 1.05173,
        high: 1.05191,
        low: 1.05144
      },
      {
        timestamp: 1651492800,
        open: 1.05172,
        close: 1.05237,
        high: 1.05246,
        low: 1.0517
      },
      {
        timestamp: 1651493100,
        open: 1.05235,
        close: 1.0525,
        high: 1.05272,
        low: 1.05215
      },
      {
        timestamp: 1651493400,
        open: 1.05249,
        close: 1.05245,
        high: 1.05266,
        low: 1.05227
      },
      {
        timestamp: 1651493700,
        open: 1.05245,
        close: 1.0523,
        high: 1.05252,
        low: 1.05211
      },
      {
        timestamp: 1651494000,
        open: 1.0523,
        close: 1.05279,
        high: 1.05291,
        low: 1.05229
      },
      {
        timestamp: 1651494300,
        open: 1.0528,
        close: 1.05304,
        high: 1.05307,
        low: 1.0527
      },
      {
        timestamp: 1651494600,
        open: 1.05304,
        close: 1.0524,
        high: 1.05334,
        low: 1.05233
      },
      {
        timestamp: 1651494900,
        open: 1.0524,
        close: 1.05256,
        high: 1.05261,
        low: 1.05211
      },
      {
        timestamp: 1651495200,
        open: 1.05256,
        close: 1.05259,
        high: 1.05274,
        low: 1.05252
      },
      {
        timestamp: 1651495500,
        open: 1.05258,
        close: 1.05258,
        high: 1.05274,
        low: 1.05253
      },
      {
        timestamp: 1651495800,
        open: 1.05257,
        close: 1.05266,
        high: 1.05271,
        low: 1.05254
      },
      {
        timestamp: 1651496100,
        open: 1.05265,
        close: 1.05112,
        high: 1.05266,
        low: 1.05091
      },
      {
        timestamp: 1651496400,
        open: 1.05112,
        close: 1.05173,
        high: 1.05175,
        low: 1.05111
      },
      {
        timestamp: 1651496700,
        open: 1.05172,
        close: 1.0516,
        high: 1.05195,
        low: 1.05144
      },
      {
        timestamp: 1651497000,
        open: 1.05161,
        close: 1.05155,
        high: 1.0518,
        low: 1.05153
      },
      {
        timestamp: 1651497300,
        open: 1.05154,
        close: 1.05102,
        high: 1.0516,
        low: 1.05099
      },
      {
        timestamp: 1651497600,
        open: 1.05103,
        close: 1.05061,
        high: 1.05107,
        low: 1.05043
      },
      {
        timestamp: 1651497900,
        open: 1.0506,
        close: 1.05063,
        high: 1.05096,
        low: 1.05035
      },
      {
        timestamp: 1651498200,
        open: 1.05064,
        close: 1.05089,
        high: 1.05125,
        low: 1.05058
      },
      {
        timestamp: 1651498500,
        open: 1.05089,
        close: 1.05142,
        high: 1.05143,
        low: 1.05067
      },
      {
        timestamp: 1651498800,
        open: 1.05142,
        close: 1.05229,
        high: 1.0524,
        low: 1.05131
      },
      {
        timestamp: 1651499100,
        open: 1.05229,
        close: 1.05254,
        high: 1.05261,
        low: 1.05185
      },
      {
        timestamp: 1651499400,
        open: 1.05253,
        close: 1.05279,
        high: 1.05285,
        low: 1.05222
      },
      {
        timestamp: 1651499700,
        open: 1.05278,
        close: 1.05248,
        high: 1.05286,
        low: 1.05245
      },
      {
        timestamp: 1651500000,
        open: 1.05248,
        close: 1.05309,
        high: 1.05337,
        low: 1.05239
      },
      {
        timestamp: 1651500300,
        open: 1.05308,
        close: 1.05183,
        high: 1.05308,
        low: 1.05156
      },
      {
        timestamp: 1651500600,
        open: 1.05183,
        close: 1.05257,
        high: 1.05263,
        low: 1.05175
      },
      {
        timestamp: 1651500900,
        open: 1.05256,
        close: 1.05325,
        high: 1.05332,
        low: 1.0522
      },
      {
        timestamp: 1651501200,
        open: 1.05322,
        close: 1.05308,
        high: 1.05332,
        low: 1.05289
      },
      {
        timestamp: 1651501500,
        open: 1.05306,
        close: 1.05345,
        high: 1.0536,
        low: 1.05299
      },
      {
        timestamp: 1651501800,
        open: 1.05346,
        close: 1.05383,
        high: 1.05424,
        low: 1.05335
      },
      {
        timestamp: 1651502100,
        open: 1.05384,
        close: 1.05355,
        high: 1.05395,
        low: 1.05338
      },
      {
        timestamp: 1651502400,
        open: 1.05356,
        close: 1.05307,
        high: 1.05382,
        low: 1.05304
      },
      {
        timestamp: 1651502700,
        open: 1.05308,
        close: 1.05316,
        high: 1.05341,
        low: 1.05302
      },
      {
        timestamp: 1651503000,
        open: 1.05318,
        close: 1.05281,
        high: 1.05326,
        low: 1.05237
      },
      {
        timestamp: 1651503300,
        open: 1.0528,
        close: 1.05232,
        high: 1.05281,
        low: 1.05228
      },
      {
        timestamp: 1651503600,
        open: 1.05231,
        close: 1.0512,
        high: 1.05246,
        low: 1.05115
      },
      {
        timestamp: 1651503900,
        open: 1.05119,
        close: 1.05093,
        high: 1.0513,
        low: 1.05075
      },
      {
        timestamp: 1651504200,
        open: 1.05093,
        close: 1.05065,
        high: 1.05093,
        low: 1.05045
      },
      {
        timestamp: 1651504500,
        open: 1.05063,
        close: 1.05063,
        high: 1.05083,
        low: 1.05049
      },
      {
        timestamp: 1651504800,
        open: 1.05063,
        close: 1.05074,
        high: 1.05078,
        low: 1.05058
      },
      {
        timestamp: 1651505100,
        open: 1.05073,
        close: 1.05073,
        high: 1.0509,
        low: 1.05059
      },
      {
        timestamp: 1651505400,
        open: 1.05074,
        close: 1.05125,
        high: 1.0513,
        low: 1.05057
      },
      {
        timestamp: 1651506000,
        open: 1.05129,
        close: 1.05154,
        high: 1.05164,
        low: 1.05117
      },
      {
        timestamp: 1651506300,
        open: 1.05155,
        close: 1.05129,
        high: 1.05165,
        low: 1.05128
      },
      {
        timestamp: 1651506600,
        open: 1.05128,
        close: 1.05174,
        high: 1.05191,
        low: 1.0512
      },
      {
        timestamp: 1651506900,
        open: 1.05174,
        close: 1.05221,
        high: 1.05224,
        low: 1.05156
      },
      {
        timestamp: 1651507200,
        open: 1.05221,
        close: 1.05223,
        high: 1.05246,
        low: 1.05209
      },
      {
        timestamp: 1651507500,
        open: 1.05222,
        close: 1.05223,
        high: 1.0524,
        low: 1.05213
      },
      {
        timestamp: 1651507800,
        open: 1.05224,
        close: 1.05223,
        high: 1.05238,
        low: 1.05212
      },
      {
        timestamp: 1651508100,
        open: 1.05221,
        close: 1.0522,
        high: 1.05245,
        low: 1.05216
      },
      {
        timestamp: 1651508400,
        open: 1.05221,
        close: 1.05236,
        high: 1.05262,
        low: 1.05214
      },
      {
        timestamp: 1651508700,
        open: 1.05237,
        close: 1.05253,
        high: 1.05264,
        low: 1.05235
      },
      {
        timestamp: 1651509000,
        open: 1.05253,
        close: 1.05233,
        high: 1.0527,
        low: 1.0523
      },
      {
        timestamp: 1651509300,
        open: 1.05233,
        close: 1.05186,
        high: 1.05239,
        low: 1.05184
      },
      {
        timestamp: 1651509600,
        open: 1.05183,
        close: 1.05175,
        high: 1.05195,
        low: 1.05122
      },
      {
        timestamp: 1651509900,
        open: 1.05176,
        close: 1.05213,
        high: 1.05231,
        low: 1.05169
      },
      {
        timestamp: 1651510200,
        open: 1.05212,
        close: 1.05122,
        high: 1.05212,
        low: 1.05103
      },
      {
        timestamp: 1651510500,
        open: 1.05124,
        close: 1.05096,
        high: 1.05134,
        low: 1.0503
      },
      {
        timestamp: 1651510800,
        open: 1.05097,
        close: 1.05055,
        high: 1.05115,
        low: 1.05021
      },
      {
        timestamp: 1651511100,
        open: 1.05053,
        close: 1.05047,
        high: 1.05076,
        low: 1.05024
      },
      {
        timestamp: 1651511400,
        open: 1.05047,
        close: 1.05042,
        high: 1.05075,
        low: 1.05042
      },
      {
        timestamp: 1651511700,
        open: 1.05043,
        close: 1.05062,
        high: 1.05067,
        low: 1.05037
      },
      {
        timestamp: 1651512000,
        open: 1.05063,
        close: 1.05064,
        high: 1.05072,
        low: 1.05046
      },
      {
        timestamp: 1651512300,
        open: 1.05063,
        close: 1.05071,
        high: 1.05089,
        low: 1.05052
      },
      {
        timestamp: 1651512600,
        open: 1.0507,
        close: 1.05041,
        high: 1.0508,
        low: 1.05031
      },
      {
        timestamp: 1651512900,
        open: 1.0504,
        close: 1.05028,
        high: 1.05053,
        low: 1.05022
      },
      {
        timestamp: 1651513200,
        open: 1.05028,
        close: 1.05023,
        high: 1.0504,
        low: 1.05017
      },
      {
        timestamp: 1651513500,
        open: 1.05024,
        close: 1.04965,
        high: 1.05027,
        low: 1.04959
      },
      {
        timestamp: 1651513800,
        open: 1.04964,
        close: 1.04937,
        high: 1.04965,
        low: 1.04935
      },
      {
        timestamp: 1651514100,
        open: 1.04936,
        close: 1.04938,
        high: 1.04963,
        low: 1.04936
      },
      {
        timestamp: 1651514400,
        open: 1.04938,
        close: 1.04978,
        high: 1.04978,
        low: 1.04933
      },
      {
        timestamp: 1651514700,
        open: 1.04978,
        close: 1.04938,
        high: 1.04978,
        low: 1.04934
      },
      {
        timestamp: 1651515000,
        open: 1.04938,
        close: 1.04914,
        high: 1.04952,
        low: 1.04909
      },
      {
        timestamp: 1651515300,
        open: 1.04915,
        close: 1.04944,
        high: 1.04951,
        low: 1.04913
      },
      {
        timestamp: 1651515600,
        open: 1.04945,
        close: 1.04966,
        high: 1.04984,
        low: 1.04941
      },
      {
        timestamp: 1651515900,
        open: 1.04969,
        close: 1.04989,
        high: 1.04993,
        low: 1.04962
      },
      {
        timestamp: 1651516200,
        open: 1.0499,
        close: 1.04981,
        high: 1.05003,
        low: 1.04975
      },
      {
        timestamp: 1651516500,
        open: 1.0498,
        close: 1.0497,
        high: 1.04981,
        low: 1.04951
      },
      {
        timestamp: 1651516800,
        open: 1.04971,
        close: 1.04954,
        high: 1.04978,
        low: 1.04951
      },
      {
        timestamp: 1651517100,
        open: 1.04955,
        close: 1.0497,
        high: 1.04982,
        low: 1.0495
      },
      {
        timestamp: 1651517400,
        open: 1.0497,
        close: 1.04985,
        high: 1.05001,
        low: 1.04961
      },
      {
        timestamp: 1651517700,
        open: 1.04985,
        close: 1.04927,
        high: 1.04994,
        low: 1.04923
      },
      {
        timestamp: 1651518000,
        open: 1.04927,
        close: 1.04946,
        high: 1.04952,
        low: 1.04922
      },
      {
        timestamp: 1651518300,
        open: 1.04945,
        close: 1.0497,
        high: 1.04972,
        low: 1.04937
      },
      {
        timestamp: 1651518600,
        open: 1.04971,
        close: 1.04983,
        high: 1.04993,
        low: 1.04967
      },
      {
        timestamp: 1651518900,
        open: 1.04984,
        close: 1.05034,
        high: 1.05046,
        low: 1.04976
      },
      {
        timestamp: 1651519200,
        open: 1.05035,
        close: 1.05103,
        high: 1.05111,
        low: 1.05035
      },
      {
        timestamp: 1651519500,
        open: 1.05104,
        close: 1.05075,
        high: 1.05106,
        low: 1.0506
      },
      {
        timestamp: 1651519800,
        open: 1.05074,
        close: 1.05114,
        high: 1.05117,
        low: 1.05073
      },
      {
        timestamp: 1651520100,
        open: 1.05113,
        close: 1.05072,
        high: 1.05124,
        low: 1.05072
      },
      {
        timestamp: 1651520400,
        open: 1.05072,
        close: 1.05031,
        high: 1.05105,
        low: 1.05022
      },
      {
        timestamp: 1651520700,
        open: 1.05032,
        close: 1.05082,
        high: 1.05082,
        low: 1.05032
      },
      {
        timestamp: 1651521000,
        open: 1.0508,
        close: 1.05097,
        high: 1.05098,
        low: 1.05052
      },
      {
        timestamp: 1651521300,
        open: 1.05093,
        close: 1.05048,
        high: 1.05103,
        low: 1.05034
      },
      {
        timestamp: 1651521600,
        open: 1.05048,
        close: 1.05048,
        high: 1.05075,
        low: 1.05023
      },
      {
        timestamp: 1651521900,
        open: 1.05047,
        close: 1.05061,
        high: 1.05061,
        low: 1.05027
      },
      {
        timestamp: 1651522200,
        open: 1.0506,
        close: 1.05042,
        high: 1.05061,
        low: 1.05035
      },
      {
        timestamp: 1651522500,
        open: 1.05043,
        close: 1.05049,
        high: 1.05071,
        low: 1.0504
      },
      {
        timestamp: 1651522800,
        open: 1.05049,
        close: 1.05042,
        high: 1.05049,
        low: 1.05024
      },
      {
        timestamp: 1651523100,
        open: 1.05042,
        close: 1.05043,
        high: 1.05055,
        low: 1.05033
      },
      {
        timestamp: 1651523400,
        open: 1.05041,
        close: 1.05049,
        high: 1.05065,
        low: 1.05033
      },
      {
        timestamp: 1651523700,
        open: 1.05048,
        close: 1.05072,
        high: 1.05072,
        low: 1.05048
      },
      {
        timestamp: 1651524000,
        open: 1.05072,
        close: 1.05072,
        high: 1.05078,
        low: 1.0507
      },
      {
        timestamp: 1651524300,
        open: 1.05071,
        close: 1.05078,
        high: 1.05084,
        low: 1.05067
      },
      {
        timestamp: 1651524600,
        open: 1.05079,
        close: 1.05085,
        high: 1.0509,
        low: 1.05072
      },
      {
        timestamp: 1651524900,
        open: 1.05083,
        close: 1.05116,
        high: 1.05117,
        low: 1.05076
      },
      {
        timestamp: 1651525200,
        open: 1.05117,
        close: 1.05105,
        high: 1.05117,
        low: 1.05105
      },
      {
        timestamp: 1651525500,
        open: 1.05105,
        close: 1.05105,
        high: 1.05105,
        low: 1.05105
      },
      {
        timestamp: 1651525800,
        open: 1.05071,
        close: 1.05068,
        high: 1.051,
        low: 1.05064
      },
      {
        timestamp: 1651526100,
        open: 1.05078,
        close: 1.05072,
        high: 1.05078,
        low: 1.05071
      },
      {
        timestamp: 1651526400,
        open: 1.05076,
        close: 1.05077,
        high: 1.05079,
        low: 1.05076
      },
      {
        timestamp: 1651526700,
        open: 1.05078,
        close: 1.05124,
        high: 1.05125,
        low: 1.05077
      },
      {
        timestamp: 1651527000,
        open: 1.05116,
        close: 1.05088,
        high: 1.05123,
        low: 1.05075
      },
      {
        timestamp: 1651527300,
        open: 1.05087,
        close: 1.05075,
        high: 1.05087,
        low: 1.05075
      },
      {
        timestamp: 1651527600,
        open: 1.05075,
        close: 1.05075,
        high: 1.05075,
        low: 1.05075
      },
      {
        timestamp: 1651527900,
        open: 1.05075,
        close: 1.05075,
        high: 1.05075,
        low: 1.05074
      },
      {
        timestamp: 1651528200,
        open: 1.05075,
        close: 1.05075,
        high: 1.05075,
        low: 1.05075
      },
      {
        timestamp: 1651528500,
        open: 1.05075,
        close: 1.05075,
        high: 1.05075,
        low: 1.05075
      },
      {
        timestamp: 1651528800,
        open: 1.05075,
        close: 1.05078,
        high: 1.05086,
        low: 1.05067
      },
      {
        timestamp: 1651529100,
        open: 1.05077,
        close: 1.05069,
        high: 1.05078,
        low: 1.05066
      },
      {
        timestamp: 1651529400,
        open: 1.05068,
        close: 1.05127,
        high: 1.05129,
        low: 1.05067
      },
      {
        timestamp: 1651529700,
        open: 1.05127,
        close: 1.0512,
        high: 1.05143,
        low: 1.05111
      },
      {
        timestamp: 1651530000,
        open: 1.05121,
        close: 1.0511,
        high: 1.05122,
        low: 1.05108
      },
      {
        timestamp: 1651530300,
        open: 1.0511,
        close: 1.05136,
        high: 1.05137,
        low: 1.0511
      },
      {
        timestamp: 1651530600,
        open: 1.05135,
        close: 1.05129,
        high: 1.05136,
        low: 1.05118
      },
      {
        timestamp: 1651530900,
        open: 1.0513,
        close: 1.0512,
        high: 1.0513,
        low: 1.05117
      },
      {
        timestamp: 1651531200,
        open: 1.05119,
        close: 1.05109,
        high: 1.05119,
        low: 1.05094
      },
      {
        timestamp: 1651531500,
        open: 1.0511,
        close: 1.05115,
        high: 1.05121,
        low: 1.05109
      },
      {
        timestamp: 1651531800,
        open: 1.05115,
        close: 1.05131,
        high: 1.05137,
        low: 1.05111
      },
      {
        timestamp: 1651532100,
        open: 1.0513,
        close: 1.05107,
        high: 1.0513,
        low: 1.05106
      },
      {
        timestamp: 1651532400,
        open: 1.05109,
        close: 1.05098,
        high: 1.05115,
        low: 1.05092
      },
      {
        timestamp: 1651532700,
        open: 1.05098,
        close: 1.05087,
        high: 1.05099,
        low: 1.05082
      },
      {
        timestamp: 1651533000,
        open: 1.05085,
        close: 1.05079,
        high: 1.05085,
        low: 1.05073
      },
      {
        timestamp: 1651533300,
        open: 1.05079,
        close: 1.05068,
        high: 1.05079,
        low: 1.05065
      },
      {
        timestamp: 1651533600,
        open: 1.05068,
        close: 1.05074,
        high: 1.05079,
        low: 1.05068
      },
      {
        timestamp: 1651533900,
        open: 1.05074,
        close: 1.05084,
        high: 1.05087,
        low: 1.05073
      },
      {
        timestamp: 1651534200,
        open: 1.05083,
        close: 1.05089,
        high: 1.05094,
        low: 1.05081
      },
      {
        timestamp: 1651534500,
        open: 1.05088,
        close: 1.05092,
        high: 1.05093,
        low: 1.05088
      },
      {
        timestamp: 1651534800,
        open: 1.05091,
        close: 1.05086,
        high: 1.05095,
        low: 1.05079
      },
      {
        timestamp: 1651535100,
        open: 1.05086,
        close: 1.0509,
        high: 1.05095,
        low: 1.05083
      },
      {
        timestamp: 1651535400,
        open: 1.05091,
        close: 1.05089,
        high: 1.05098,
        low: 1.05086
      },
      {
        timestamp: 1651535700,
        open: 1.05088,
        close: 1.05104,
        high: 1.05104,
        low: 1.05085
      },
      {
        timestamp: 1651536000,
        open: 1.05103,
        close: 1.05112,
        high: 1.05143,
        low: 1.05103
      },
      {
        timestamp: 1651536300,
        open: 1.05112,
        close: 1.05142,
        high: 1.05149,
        low: 1.05109
      },
      {
        timestamp: 1651536600,
        open: 1.05142,
        close: 1.05127,
        high: 1.05143,
        low: 1.05121
      },
      {
        timestamp: 1651536900,
        open: 1.05127,
        close: 1.05106,
        high: 1.05128,
        low: 1.05105
      },
      {
        timestamp: 1651537200,
        open: 1.05107,
        close: 1.05105,
        high: 1.0511,
        low: 1.05094
      },
      {
        timestamp: 1651537500,
        open: 1.05104,
        close: 1.05109,
        high: 1.05111,
        low: 1.05093
      },
      {
        timestamp: 1651537800,
        open: 1.05108,
        close: 1.0515,
        high: 1.05165,
        low: 1.05106
      },
      {
        timestamp: 1651538100,
        open: 1.0515,
        close: 1.05184,
        high: 1.05191,
        low: 1.05148
      },
      {
        timestamp: 1651538400,
        open: 1.05185,
        close: 1.052,
        high: 1.0521,
        low: 1.05174
      },
      {
        timestamp: 1651538700,
        open: 1.05201,
        close: 1.0524,
        high: 1.05243,
        low: 1.05195
      },
      {
        timestamp: 1651539000,
        open: 1.05241,
        close: 1.05244,
        high: 1.0526,
        low: 1.0522
      },
      {
        timestamp: 1651539300,
        open: 1.05245,
        close: 1.0526,
        high: 1.05266,
        low: 1.05239
      },
      {
        timestamp: 1651539600,
        open: 1.05259,
        close: 1.05267,
        high: 1.05274,
        low: 1.05238
      },
      {
        timestamp: 1651539900,
        open: 1.05268,
        close: 1.0524,
        high: 1.05269,
        low: 1.05214
      },
      {
        timestamp: 1651540200,
        open: 1.0524,
        close: 1.05199,
        high: 1.05247,
        low: 1.05197
      },
      {
        timestamp: 1651540500,
        open: 1.052,
        close: 1.05199,
        high: 1.05213,
        low: 1.05198
      },
      {
        timestamp: 1651540800,
        open: 1.05199,
        close: 1.05179,
        high: 1.05199,
        low: 1.0517
      },
      {
        timestamp: 1651541100,
        open: 1.05179,
        close: 1.05178,
        high: 1.05186,
        low: 1.05168
      },
      {
        timestamp: 1651541400,
        open: 1.05178,
        close: 1.05175,
        high: 1.0519,
        low: 1.05174
      },
      {
        timestamp: 1651541700,
        open: 1.05175,
        close: 1.05179,
        high: 1.05182,
        low: 1.05156
      },
      {
        timestamp: 1651542000,
        open: 1.0518,
        close: 1.05163,
        high: 1.05181,
        low: 1.05158
      },
      {
        timestamp: 1651542300,
        open: 1.05163,
        close: 1.05185,
        high: 1.05186,
        low: 1.05161
      },
      {
        timestamp: 1651542600,
        open: 1.05184,
        close: 1.0521,
        high: 1.05228,
        low: 1.05184
      },
      {
        timestamp: 1651542900,
        open: 1.05209,
        close: 1.05231,
        high: 1.05242,
        low: 1.05209
      },
      {
        timestamp: 1651543200,
        open: 1.0523,
        close: 1.05248,
        high: 1.05249,
        low: 1.05224
      },
      {
        timestamp: 1651543500,
        open: 1.05248,
        close: 1.05249,
        high: 1.05255,
        low: 1.05234
      },
      {
        timestamp: 1651543800,
        open: 1.05249,
        close: 1.05237,
        high: 1.05254,
        low: 1.05227
      },
      {
        timestamp: 1651544100,
        open: 1.05238,
        close: 1.05257,
        high: 1.05257,
        low: 1.05237
      },
      {
        timestamp: 1651544400,
        open: 1.05256,
        close: 1.05254,
        high: 1.05264,
        low: 1.05248
      },
      {
        timestamp: 1651544700,
        open: 1.05254,
        close: 1.05254,
        high: 1.05265,
        low: 1.05244
      },
      {
        timestamp: 1651545000,
        open: 1.05253,
        close: 1.05228,
        high: 1.05253,
        low: 1.05217
      },
      {
        timestamp: 1651545300,
        open: 1.05228,
        close: 1.05194,
        high: 1.0523,
        low: 1.05193
      },
      {
        timestamp: 1651545600,
        open: 1.05193,
        close: 1.05165,
        high: 1.05193,
        low: 1.05152
      },
      {
        timestamp: 1651545900,
        open: 1.05165,
        close: 1.05158,
        high: 1.05177,
        low: 1.05152
      },
      {
        timestamp: 1651546200,
        open: 1.05159,
        close: 1.05123,
        high: 1.05163,
        low: 1.05121
      },
      {
        timestamp: 1651546500,
        open: 1.05122,
        close: 1.05143,
        high: 1.05157,
        low: 1.05105
      },
      {
        timestamp: 1651546800,
        open: 1.05144,
        close: 1.05104,
        high: 1.05144,
        low: 1.05088
      },
      {
        timestamp: 1651547100,
        open: 1.05103,
        close: 1.05129,
        high: 1.0513,
        low: 1.05085
      },
      {
        timestamp: 1651547400,
        open: 1.05128,
        close: 1.0513,
        high: 1.05139,
        low: 1.05116
      },
      {
        timestamp: 1651547700,
        open: 1.05131,
        close: 1.05165,
        high: 1.05168,
        low: 1.0513
      },
      {
        timestamp: 1651548000,
        open: 1.05164,
        close: 1.0517,
        high: 1.05178,
        low: 1.05161
      },
      {
        timestamp: 1651548300,
        open: 1.05171,
        close: 1.05177,
        high: 1.05182,
        low: 1.05167
      },
      {
        timestamp: 1651548600,
        open: 1.05177,
        close: 1.0515,
        high: 1.05179,
        low: 1.05147
      },
      {
        timestamp: 1651548900,
        open: 1.05151,
        close: 1.05151,
        high: 1.05156,
        low: 1.05146
      },
      {
        timestamp: 1651549200,
        open: 1.05152,
        close: 1.05149,
        high: 1.05161,
        low: 1.05146
      },
      {
        timestamp: 1651549500,
        open: 1.0515,
        close: 1.05184,
        high: 1.05185,
        low: 1.05148
      },
      {
        timestamp: 1651549800,
        open: 1.05185,
        close: 1.05187,
        high: 1.05194,
        low: 1.05184
      },
      {
        timestamp: 1651550100,
        open: 1.05187,
        close: 1.05191,
        high: 1.05202,
        low: 1.05182
      },
      {
        timestamp: 1651550400,
        open: 1.0519,
        close: 1.0515,
        high: 1.05192,
        low: 1.05149
      },
      {
        timestamp: 1651550700,
        open: 1.0515,
        close: 1.05176,
        high: 1.05177,
        low: 1.0515
      },
      {
        timestamp: 1651551000,
        open: 1.05176,
        close: 1.05169,
        high: 1.05177,
        low: 1.05169
      },
      {
        timestamp: 1651551300,
        open: 1.05169,
        close: 1.05172,
        high: 1.05178,
        low: 1.05166
      },
      {
        timestamp: 1651551600,
        open: 1.05173,
        close: 1.05162,
        high: 1.05176,
        low: 1.05156
      },
      {
        timestamp: 1651551900,
        open: 1.05162,
        close: 1.05158,
        high: 1.05167,
        low: 1.05155
      },
      {
        timestamp: 1651552200,
        open: 1.05158,
        close: 1.05215,
        high: 1.05249,
        low: 1.05158
      },
      {
        timestamp: 1651552500,
        open: 1.05215,
        close: 1.05185,
        high: 1.05216,
        low: 1.05176
      },
      {
        timestamp: 1651552800,
        open: 1.05186,
        close: 1.0512,
        high: 1.05186,
        low: 1.05108
      },
      {
        timestamp: 1651553100,
        open: 1.05121,
        close: 1.05122,
        high: 1.05124,
        low: 1.05096
      },
      {
        timestamp: 1651553400,
        open: 1.05123,
        close: 1.05085,
        high: 1.05136,
        low: 1.05084
      },
      {
        timestamp: 1651553700,
        open: 1.05084,
        close: 1.05025,
        high: 1.05088,
        low: 1.05025
      },
      {
        timestamp: 1651554000,
        open: 1.05025,
        close: 1.05053,
        high: 1.05056,
        low: 1.05005
      },
      {
        timestamp: 1651554300,
        open: 1.05052,
        close: 1.05038,
        high: 1.05054,
        low: 1.05025
      },
      {
        timestamp: 1651554600,
        open: 1.05039,
        close: 1.05076,
        high: 1.05082,
        low: 1.05033
      },
      {
        timestamp: 1651554900,
        open: 1.05077,
        close: 1.05103,
        high: 1.05112,
        low: 1.05077
      },
      {
        timestamp: 1651555200,
        open: 1.05102,
        close: 1.05085,
        high: 1.05102,
        low: 1.05073
      },
      {
        timestamp: 1651555500,
        open: 1.05085,
        close: 1.05088,
        high: 1.05115,
        low: 1.05083
      },
      {
        timestamp: 1651555800,
        open: 1.05088,
        close: 1.05094,
        high: 1.05103,
        low: 1.05077
      },
      {
        timestamp: 1651556100,
        open: 1.05093,
        close: 1.05095,
        high: 1.05099,
        low: 1.0508
      },
      {
        timestamp: 1651556400,
        open: 1.05095,
        close: 1.05039,
        high: 1.05098,
        low: 1.0503
      },
      {
        timestamp: 1651556700,
        open: 1.05039,
        close: 1.0501,
        high: 1.05048,
        low: 1.04986
      },
      {
        timestamp: 1651557000,
        open: 1.0501,
        close: 1.05065,
        high: 1.0509,
        low: 1.0501
      },
      {
        timestamp: 1651557300,
        open: 1.05066,
        close: 1.05083,
        high: 1.05089,
        low: 1.05056
      },
      {
        timestamp: 1651557600,
        open: 1.05084,
        close: 1.05075,
        high: 1.05086,
        low: 1.05023
      },
      {
        timestamp: 1651557900,
        open: 1.05074,
        close: 1.05083,
        high: 1.05094,
        low: 1.05067
      },
      {
        timestamp: 1651558200,
        open: 1.05083,
        close: 1.05044,
        high: 1.05089,
        low: 1.0504
      },
      {
        timestamp: 1651558500,
        open: 1.05043,
        close: 1.05032,
        high: 1.05047,
        low: 1.0502
      },
      {
        timestamp: 1651558800,
        open: 1.05032,
        close: 1.05012,
        high: 1.05055,
        low: 1.04997
      },
      {
        timestamp: 1651559100,
        open: 1.05011,
        close: 1.05021,
        high: 1.05035,
        low: 1.04986
      },
      {
        timestamp: 1651559400,
        open: 1.05021,
        close: 1.05073,
        high: 1.05076,
        low: 1.05018
      },
      {
        timestamp: 1651559700,
        open: 1.05073,
        close: 1.05122,
        high: 1.05123,
        low: 1.05062
      },
      {
        timestamp: 1651560000,
        open: 1.05121,
        close: 1.05067,
        high: 1.0513,
        low: 1.05034
      },
      {
        timestamp: 1651560300,
        open: 1.05067,
        close: 1.05059,
        high: 1.05091,
        low: 1.05053
      },
      {
        timestamp: 1651560600,
        open: 1.05059,
        close: 1.05003,
        high: 1.05077,
        low: 1.04995
      },
      {
        timestamp: 1651560900,
        open: 1.05004,
        close: 1.04984,
        high: 1.05016,
        low: 1.0497
      },
      {
        timestamp: 1651561200,
        open: 1.04984,
        close: 1.04996,
        high: 1.05021,
        low: 1.04959
      },
      {
        timestamp: 1651561500,
        open: 1.04996,
        close: 1.05065,
        high: 1.05065,
        low: 1.04951
      },
      {
        timestamp: 1651561800,
        open: 1.05064,
        close: 1.05056,
        high: 1.05068,
        low: 1.05036
      },
      {
        timestamp: 1651562100,
        open: 1.05057,
        close: 1.05073,
        high: 1.05078,
        low: 1.05029
      },
      {
        timestamp: 1651562400,
        open: 1.05072,
        close: 1.05147,
        high: 1.05166,
        low: 1.05041
      },
      {
        timestamp: 1651562700,
        open: 1.05151,
        close: 1.05164,
        high: 1.05181,
        low: 1.05132
      },
      {
        timestamp: 1651563000,
        open: 1.05164,
        close: 1.05209,
        high: 1.05232,
        low: 1.05149
      },
      {
        timestamp: 1651563300,
        open: 1.05208,
        close: 1.05249,
        high: 1.05285,
        low: 1.052
      },
      {
        timestamp: 1651563600,
        open: 1.05248,
        close: 1.0522,
        high: 1.05279,
        low: 1.05216
      },
      {
        timestamp: 1651563900,
        open: 1.0522,
        close: 1.05174,
        high: 1.0522,
        low: 1.05153
      },
      {
        timestamp: 1651564200,
        open: 1.05174,
        close: 1.05251,
        high: 1.0528,
        low: 1.05169
      },
      {
        timestamp: 1651564500,
        open: 1.05251,
        close: 1.05193,
        high: 1.05258,
        low: 1.0519
      },
      {
        timestamp: 1651564800,
        open: 1.05193,
        close: 1.05239,
        high: 1.05248,
        low: 1.05193
      },
      {
        timestamp: 1651565100,
        open: 1.05239,
        close: 1.05228,
        high: 1.05264,
        low: 1.052
      },
      {
        timestamp: 1651565400,
        open: 1.05228,
        close: 1.05209,
        high: 1.05238,
        low: 1.05197
      },
      {
        timestamp: 1651565700,
        open: 1.0521,
        close: 1.05201,
        high: 1.05224,
        low: 1.05194
      },
      {
        timestamp: 1651566000,
        open: 1.052,
        close: 1.052,
        high: 1.05231,
        low: 1.052
      },
      {
        timestamp: 1651566300,
        open: 1.052,
        close: 1.05156,
        high: 1.0521,
        low: 1.0515
      },
      {
        timestamp: 1651566600,
        open: 1.05157,
        close: 1.05119,
        high: 1.05176,
        low: 1.05108
      },
      {
        timestamp: 1651566900,
        open: 1.0512,
        close: 1.05092,
        high: 1.05135,
        low: 1.0509
      },
      {
        timestamp: 1651567200,
        open: 1.05092,
        close: 1.05069,
        high: 1.05097,
        low: 1.05025
      },
      {
        timestamp: 1651567500,
        open: 1.05069,
        close: 1.05071,
        high: 1.0511,
        low: 1.05048
      },
      {
        timestamp: 1651567800,
        open: 1.05072,
        close: 1.05078,
        high: 1.05105,
        low: 1.05047
      },
      {
        timestamp: 1651568100,
        open: 1.05078,
        close: 1.05009,
        high: 1.05081,
        low: 1.05009
      },
      {
        timestamp: 1651568400,
        open: 1.05009,
        close: 1.04962,
        high: 1.05035,
        low: 1.04955
      },
      {
        timestamp: 1651568700,
        open: 1.04963,
        close: 1.05041,
        high: 1.05048,
        low: 1.04929
      },
      {
        timestamp: 1651569000,
        open: 1.0504,
        close: 1.04969,
        high: 1.05053,
        low: 1.04957
      },
      {
        timestamp: 1651569300,
        open: 1.04969,
        close: 1.04968,
        high: 1.05029,
        low: 1.04956
      },
      {
        timestamp: 1651569600,
        open: 1.04967,
        close: 1.04983,
        high: 1.05006,
        low: 1.04967
      },
      {
        timestamp: 1651569900,
        open: 1.04982,
        close: 1.0498,
        high: 1.05001,
        low: 1.0497
      },
      {
        timestamp: 1651570200,
        open: 1.0498,
        close: 1.0504,
        high: 1.05053,
        low: 1.04966
      },
      {
        timestamp: 1651570500,
        open: 1.0504,
        close: 1.05045,
        high: 1.05057,
        low: 1.05014
      },
      {
        timestamp: 1651570800,
        open: 1.05045,
        close: 1.05063,
        high: 1.05097,
        low: 1.05045
      },
      {
        timestamp: 1651571100,
        open: 1.05064,
        close: 1.05098,
        high: 1.05101,
        low: 1.05059
      },
      {
        timestamp: 1651571400,
        open: 1.05098,
        close: 1.05117,
        high: 1.0514,
        low: 1.05086
      },
      {
        timestamp: 1651571700,
        open: 1.05118,
        close: 1.05143,
        high: 1.05147,
        low: 1.05088
      },
      {
        timestamp: 1651572000,
        open: 1.05142,
        close: 1.05125,
        high: 1.05145,
        low: 1.05116
      },
      {
        timestamp: 1651572300,
        open: 1.05126,
        close: 1.0516,
        high: 1.05165,
        low: 1.05106
      },
      {
        timestamp: 1651572600,
        open: 1.05159,
        close: 1.0517,
        high: 1.05172,
        low: 1.05128
      },
      {
        timestamp: 1651572900,
        open: 1.05171,
        close: 1.05224,
        high: 1.05238,
        low: 1.05167
      },
      {
        timestamp: 1651573200,
        open: 1.05224,
        close: 1.05211,
        high: 1.05227,
        low: 1.05165
      },
      {
        timestamp: 1651573500,
        open: 1.05211,
        close: 1.05163,
        high: 1.05212,
        low: 1.05154
      },
      {
        timestamp: 1651573800,
        open: 1.05163,
        close: 1.05112,
        high: 1.05163,
        low: 1.05099
      },
      {
        timestamp: 1651574100,
        open: 1.05112,
        close: 1.05096,
        high: 1.05128,
        low: 1.05079
      },
      {
        timestamp: 1651574400,
        open: 1.05097,
        close: 1.05085,
        high: 1.05137,
        low: 1.05081
      },
      {
        timestamp: 1651574700,
        open: 1.05085,
        close: 1.05081,
        high: 1.05095,
        low: 1.0506
      },
      {
        timestamp: 1651575000,
        open: 1.05081,
        close: 1.05079,
        high: 1.05085,
        low: 1.05058
      },
      {
        timestamp: 1651575300,
        open: 1.0508,
        close: 1.05048,
        high: 1.0509,
        low: 1.05025
      },
      {
        timestamp: 1651575600,
        open: 1.05049,
        close: 1.05019,
        high: 1.05063,
        low: 1.05
      },
      {
        timestamp: 1651575900,
        open: 1.05019,
        close: 1.05045,
        high: 1.05051,
        low: 1.05019
      },
      {
        timestamp: 1651576200,
        open: 1.05044,
        close: 1.05019,
        high: 1.05046,
        low: 1.05002
      },
      {
        timestamp: 1651576500,
        open: 1.0502,
        close: 1.0503,
        high: 1.05055,
        low: 1.04996
      },
      {
        timestamp: 1651576800,
        open: 1.0503,
        close: 1.0504,
        high: 1.05046,
        low: 1.05002
      },
      {
        timestamp: 1651577100,
        open: 1.05041,
        close: 1.05148,
        high: 1.05165,
        low: 1.0504
      },
      {
        timestamp: 1651577400,
        open: 1.05148,
        close: 1.05224,
        high: 1.05259,
        low: 1.05144
      },
      {
        timestamp: 1651577700,
        open: 1.05225,
        close: 1.05201,
        high: 1.05235,
        low: 1.0519
      },
      {
        timestamp: 1651578000,
        open: 1.052,
        close: 1.05175,
        high: 1.05205,
        low: 1.05167
      },
      {
        timestamp: 1651578300,
        open: 1.05174,
        close: 1.0516,
        high: 1.05178,
        low: 1.05147
      },
      {
        timestamp: 1651578600,
        open: 1.0516,
        close: 1.0534,
        high: 1.05341,
        low: 1.0516
      },
      {
        timestamp: 1651578900,
        open: 1.05341,
        close: 1.05385,
        high: 1.05428,
        low: 1.0534
      },
      {
        timestamp: 1651579200,
        open: 1.05385,
        close: 1.05485,
        high: 1.05532,
        low: 1.05379
      },
      {
        timestamp: 1651579500,
        open: 1.05485,
        close: 1.05482,
        high: 1.05489,
        low: 1.05437
      },
      {
        timestamp: 1651579800,
        open: 1.05483,
        close: 1.0556,
        high: 1.0562,
        low: 1.05455
      },
      {
        timestamp: 1651580100,
        open: 1.05559,
        close: 1.05574,
        high: 1.05601,
        low: 1.05534
      },
      {
        timestamp: 1651580400,
        open: 1.05574,
        close: 1.0557,
        high: 1.05579,
        low: 1.05527
      },
      {
        timestamp: 1651580700,
        open: 1.05569,
        close: 1.05608,
        high: 1.05619,
        low: 1.05568
      },
      {
        timestamp: 1651581000,
        open: 1.05608,
        close: 1.05685,
        high: 1.05686,
        low: 1.05595
      },
      {
        timestamp: 1651581300,
        open: 1.05686,
        close: 1.05763,
        high: 1.05767,
        low: 1.0568
      },
      {
        timestamp: 1651581600,
        open: 1.05763,
        close: 1.05667,
        high: 1.05785,
        low: 1.05654
      },
      {
        timestamp: 1651581900,
        open: 1.05665,
        close: 1.0568,
        high: 1.05694,
        low: 1.05656
      },
      {
        timestamp: 1651582200,
        open: 1.0568,
        close: 1.05697,
        high: 1.05723,
        low: 1.05661
      },
      {
        timestamp: 1651582500,
        open: 1.05695,
        close: 1.05682,
        high: 1.05732,
        low: 1.05652
      },
      {
        timestamp: 1651582800,
        open: 1.05682,
        close: 1.05687,
        high: 1.05705,
        low: 1.0564
      },
      {
        timestamp: 1651583100,
        open: 1.05686,
        close: 1.0572,
        high: 1.05729,
        low: 1.05654
      },
      {
        timestamp: 1651583400,
        open: 1.05721,
        close: 1.05656,
        high: 1.0573,
        low: 1.05634
      },
      {
        timestamp: 1651583700,
        open: 1.05653,
        close: 1.056,
        high: 1.05677,
        low: 1.05595
      },
      {
        timestamp: 1651584000,
        open: 1.056,
        close: 1.056,
        high: 1.05629,
        low: 1.05595
      },
      {
        timestamp: 1651584300,
        open: 1.056,
        close: 1.05614,
        high: 1.05653,
        low: 1.05599
      },
      {
        timestamp: 1651584600,
        open: 1.05615,
        close: 1.05644,
        high: 1.05672,
        low: 1.05596
      },
      {
        timestamp: 1651584900,
        open: 1.05644,
        close: 1.05564,
        high: 1.05652,
        low: 1.05556
      },
      {
        timestamp: 1651585200,
        open: 1.05563,
        close: 1.05543,
        high: 1.05593,
        low: 1.0554
      },
      {
        timestamp: 1651585500,
        open: 1.05541,
        close: 1.05518,
        high: 1.05554,
        low: 1.05493
      },
      {
        timestamp: 1651585800,
        open: 1.05518,
        close: 1.05519,
        high: 1.05547,
        low: 1.05495
      },
      {
        timestamp: 1651586100,
        open: 1.0552,
        close: 1.05569,
        high: 1.05582,
        low: 1.05493
      },
      {
        timestamp: 1651586400,
        open: 1.05569,
        close: 1.0548,
        high: 1.05586,
        low: 1.05473
      },
      {
        timestamp: 1651586700,
        open: 1.05481,
        close: 1.05442,
        high: 1.0549,
        low: 1.05429
      },
      {
        timestamp: 1651587000,
        open: 1.05442,
        close: 1.05485,
        high: 1.05495,
        low: 1.05432
      },
      {
        timestamp: 1651587300,
        open: 1.05484,
        close: 1.05484,
        high: 1.05489,
        low: 1.05442
      },
      {
        timestamp: 1651587600,
        open: 1.05484,
        close: 1.05503,
        high: 1.05525,
        low: 1.05482
      },
      {
        timestamp: 1651587900,
        open: 1.05504,
        close: 1.0558,
        high: 1.05593,
        low: 1.05504
      },
      {
        timestamp: 1651588200,
        open: 1.0558,
        close: 1.05564,
        high: 1.05655,
        low: 1.05559
      },
      {
        timestamp: 1651588500,
        open: 1.05564,
        close: 1.05497,
        high: 1.05612,
        low: 1.05497
      },
      {
        timestamp: 1651588800,
        open: 1.05498,
        close: 1.05469,
        high: 1.055,
        low: 1.05438
      },
      {
        timestamp: 1651589100,
        open: 1.05469,
        close: 1.05398,
        high: 1.05492,
        low: 1.05378
      },
      {
        timestamp: 1651589400,
        open: 1.054,
        close: 1.05385,
        high: 1.0541,
        low: 1.05367
      },
      {
        timestamp: 1651589700,
        open: 1.05385,
        close: 1.05388,
        high: 1.05414,
        low: 1.05358
      },
      {
        timestamp: 1651590000,
        open: 1.05389,
        close: 1.05359,
        high: 1.05421,
        low: 1.05353
      },
      {
        timestamp: 1651590300,
        open: 1.05358,
        close: 1.0546,
        high: 1.05465,
        low: 1.05345
      },
      {
        timestamp: 1651590600,
        open: 1.0546,
        close: 1.05452,
        high: 1.05489,
        low: 1.05445
      },
      {
        timestamp: 1651590900,
        open: 1.05453,
        close: 1.0541,
        high: 1.05483,
        low: 1.0539
      },
      {
        timestamp: 1651591200,
        open: 1.0541,
        close: 1.05435,
        high: 1.05459,
        low: 1.05402
      },
      {
        timestamp: 1651591500,
        open: 1.05435,
        close: 1.05405,
        high: 1.05436,
        low: 1.05373
      },
      {
        timestamp: 1651591800,
        open: 1.05405,
        close: 1.05387,
        high: 1.05412,
        low: 1.05372
      },
      {
        timestamp: 1651592100,
        open: 1.05386,
        close: 1.05371,
        high: 1.05404,
        low: 1.05359
      },
      {
        timestamp: 1651592400,
        open: 1.05371,
        close: 1.05353,
        high: 1.05373,
        low: 1.05324
      },
      {
        timestamp: 1651592700,
        open: 1.05354,
        close: 1.05372,
        high: 1.05373,
        low: 1.05338
      },
      {
        timestamp: 1651593000,
        open: 1.05373,
        close: 1.05328,
        high: 1.05373,
        low: 1.05323
      },
      {
        timestamp: 1651593300,
        open: 1.05328,
        close: 1.05325,
        high: 1.05353,
        low: 1.05321
      },
      {
        timestamp: 1651593600,
        open: 1.05326,
        close: 1.05307,
        high: 1.05327,
        low: 1.05288
      },
      {
        timestamp: 1651593900,
        open: 1.05306,
        close: 1.05179,
        high: 1.05306,
        low: 1.05173
      },
      {
        timestamp: 1651594200,
        open: 1.05179,
        close: 1.05153,
        high: 1.0519,
        low: 1.05139
      },
      {
        timestamp: 1651594500,
        open: 1.05152,
        close: 1.05138,
        high: 1.05161,
        low: 1.05122
      },
      {
        timestamp: 1651594800,
        open: 1.05139,
        close: 1.05176,
        high: 1.05204,
        low: 1.05137
      },
      {
        timestamp: 1651595100,
        open: 1.05176,
        close: 1.05181,
        high: 1.05199,
        low: 1.05155
      },
      {
        timestamp: 1651595400,
        open: 1.0518,
        close: 1.05196,
        high: 1.05219,
        low: 1.0514
      },
      {
        timestamp: 1651595700,
        open: 1.05197,
        close: 1.05168,
        high: 1.05235,
        low: 1.05168
      },
      {
        timestamp: 1651596000,
        open: 1.05167,
        close: 1.05184,
        high: 1.05194,
        low: 1.0516
      },
      {
        timestamp: 1651596300,
        open: 1.05184,
        close: 1.05163,
        high: 1.0519,
        low: 1.05146
      },
      {
        timestamp: 1651596600,
        open: 1.0516,
        close: 1.05141,
        high: 1.0516,
        low: 1.05127
      },
      {
        timestamp: 1651596900,
        open: 1.05142,
        close: 1.05163,
        high: 1.0517,
        low: 1.0513
      },
      {
        timestamp: 1651597200,
        open: 1.05162,
        close: 1.05173,
        high: 1.05205,
        low: 1.05151
      },
      {
        timestamp: 1651597500,
        open: 1.05174,
        close: 1.05276,
        high: 1.05313,
        low: 1.05171
      },
      {
        timestamp: 1651597800,
        open: 1.05277,
        close: 1.05339,
        high: 1.05362,
        low: 1.05262
      },
      {
        timestamp: 1651598100,
        open: 1.05339,
        close: 1.05371,
        high: 1.05389,
        low: 1.05337
      },
      {
        timestamp: 1651598400,
        open: 1.05372,
        close: 1.05362,
        high: 1.05412,
        low: 1.05358
      },
      {
        timestamp: 1651598700,
        open: 1.05363,
        close: 1.05295,
        high: 1.05363,
        low: 1.05272
      },
      {
        timestamp: 1651599000,
        open: 1.05296,
        close: 1.05284,
        high: 1.05304,
        low: 1.05247
      },
      {
        timestamp: 1651599300,
        open: 1.05283,
        close: 1.05276,
        high: 1.05299,
        low: 1.05265
      },
      {
        timestamp: 1651599600,
        open: 1.05278,
        close: 1.05326,
        high: 1.05327,
        low: 1.05259
      },
      {
        timestamp: 1651599900,
        open: 1.05325,
        close: 1.05309,
        high: 1.0533,
        low: 1.05301
      },
      {
        timestamp: 1651600200,
        open: 1.05309,
        close: 1.05275,
        high: 1.05315,
        low: 1.05269
      },
      {
        timestamp: 1651600500,
        open: 1.05274,
        close: 1.05298,
        high: 1.05315,
        low: 1.0527
      },
      {
        timestamp: 1651600800,
        open: 1.05299,
        close: 1.05278,
        high: 1.05311,
        low: 1.05263
      },
      {
        timestamp: 1651601100,
        open: 1.05279,
        close: 1.05298,
        high: 1.05302,
        low: 1.05264
      },
      {
        timestamp: 1651601400,
        open: 1.05297,
        close: 1.05278,
        high: 1.05297,
        low: 1.05267
      },
      {
        timestamp: 1651601700,
        open: 1.05277,
        close: 1.05231,
        high: 1.05279,
        low: 1.05225
      },
      {
        timestamp: 1651602000,
        open: 1.0523,
        close: 1.05213,
        high: 1.0523,
        low: 1.05178
      },
      {
        timestamp: 1651602300,
        open: 1.05213,
        close: 1.05212,
        high: 1.05222,
        low: 1.05197
      },
      {
        timestamp: 1651602600,
        open: 1.05213,
        close: 1.05212,
        high: 1.05231,
        low: 1.05206
      },
      {
        timestamp: 1651602900,
        open: 1.05213,
        close: 1.05164,
        high: 1.05227,
        low: 1.05162
      },
      {
        timestamp: 1651603200,
        open: 1.05164,
        close: 1.05157,
        high: 1.05174,
        low: 1.05133
      },
      {
        timestamp: 1651603500,
        open: 1.05157,
        close: 1.05191,
        high: 1.05194,
        low: 1.05142
      },
      {
        timestamp: 1651603800,
        open: 1.05191,
        close: 1.05249,
        high: 1.05253,
        low: 1.05177
      },
      {
        timestamp: 1651604100,
        open: 1.05249,
        close: 1.05262,
        high: 1.05267,
        low: 1.05228
      },
      {
        timestamp: 1651604400,
        open: 1.05262,
        close: 1.05233,
        high: 1.05275,
        low: 1.05231
      },
      {
        timestamp: 1651604700,
        open: 1.05234,
        close: 1.05264,
        high: 1.05274,
        low: 1.05218
      },
      {
        timestamp: 1651605000,
        open: 1.05264,
        close: 1.05274,
        high: 1.05277,
        low: 1.05249
      },
      {
        timestamp: 1651605300,
        open: 1.05274,
        close: 1.05267,
        high: 1.05279,
        low: 1.05253
      },
      {
        timestamp: 1651605600,
        open: 1.05269,
        close: 1.05252,
        high: 1.05287,
        low: 1.05248
      },
      {
        timestamp: 1651605900,
        open: 1.05252,
        close: 1.05215,
        high: 1.05253,
        low: 1.052
      },
      {
        timestamp: 1651606200,
        open: 1.05214,
        close: 1.05224,
        high: 1.05224,
        low: 1.05195
      },
      {
        timestamp: 1651606500,
        open: 1.05225,
        close: 1.05204,
        high: 1.05235,
        low: 1.05203
      },
      {
        timestamp: 1651606800,
        open: 1.05203,
        close: 1.05226,
        high: 1.0524,
        low: 1.05197
      },
      {
        timestamp: 1651607100,
        open: 1.05224,
        close: 1.05215,
        high: 1.05235,
        low: 1.05215
      },
      {
        timestamp: 1651607400,
        open: 1.05215,
        close: 1.05255,
        high: 1.05262,
        low: 1.05201
      },
      {
        timestamp: 1651607700,
        open: 1.05255,
        close: 1.05258,
        high: 1.05266,
        low: 1.05242
      },
      {
        timestamp: 1651608000,
        open: 1.05258,
        close: 1.05229,
        high: 1.05267,
        low: 1.05224
      },
      {
        timestamp: 1651608300,
        open: 1.05231,
        close: 1.05255,
        high: 1.05275,
        low: 1.0523
      },
      {
        timestamp: 1651608600,
        open: 1.05256,
        close: 1.05264,
        high: 1.05269,
        low: 1.05255
      },
      {
        timestamp: 1651608900,
        open: 1.05264,
        close: 1.0526,
        high: 1.05264,
        low: 1.0525
      },
      {
        timestamp: 1651609200,
        open: 1.0526,
        close: 1.05254,
        high: 1.0526,
        low: 1.0525
      },
      {
        timestamp: 1651609500,
        open: 1.05253,
        close: 1.05243,
        high: 1.05254,
        low: 1.05236
      },
      {
        timestamp: 1651609800,
        open: 1.05244,
        close: 1.0524,
        high: 1.05246,
        low: 1.05238
      },
      {
        timestamp: 1651610100,
        open: 1.05239,
        close: 1.0522,
        high: 1.05239,
        low: 1.05217
      },
      {
        timestamp: 1651610400,
        open: 1.0522,
        close: 1.05218,
        high: 1.0522,
        low: 1.05215
      },
      {
        timestamp: 1651610700,
        open: 1.05218,
        close: 1.05226,
        high: 1.05231,
        low: 1.05218
      },
      {
        timestamp: 1651611000,
        open: 1.05225,
        close: 1.05232,
        high: 1.05232,
        low: 1.05214
      },
      {
        timestamp: 1651611300,
        open: 1.05231,
        close: 1.05245,
        high: 1.05245,
        low: 1.052
      },
      {
        timestamp: 1651611600,
        open: 1.05252,
        close: 1.05233,
        high: 1.05252,
        low: 1.05233
      },
      {
        timestamp: 1651611900,
        open: 1.05234,
        close: 1.05227,
        high: 1.05234,
        low: 1.05227
      },
      {
        timestamp: 1651612200,
        open: 1.05232,
        close: 1.05226,
        high: 1.05238,
        low: 1.05226
      },
      {
        timestamp: 1651612500,
        open: 1.05232,
        close: 1.05228,
        high: 1.05234,
        low: 1.05226
      },
      {
        timestamp: 1651612800,
        open: 1.05228,
        close: 1.05228,
        high: 1.05229,
        low: 1.05228
      },
      {
        timestamp: 1651613100,
        open: 1.05227,
        close: 1.05226,
        high: 1.05229,
        low: 1.05224
      },
      {
        timestamp: 1651613400,
        open: 1.05229,
        close: 1.05235,
        high: 1.05235,
        low: 1.05226
      },
      {
        timestamp: 1651613700,
        open: 1.05234,
        close: 1.05236,
        high: 1.0524,
        low: 1.05233
      },
      {
        timestamp: 1651614000,
        open: 1.05238,
        close: 1.05238,
        high: 1.0524,
        low: 1.05237
      },
      {
        timestamp: 1651614300,
        open: 1.05238,
        close: 1.05237,
        high: 1.05238,
        low: 1.05237
      },
      {
        timestamp: 1651614600,
        open: 1.05236,
        close: 1.05239,
        high: 1.05239,
        low: 1.05236
      },
      {
        timestamp: 1651614900,
        open: 1.05238,
        close: 1.05283,
        high: 1.05288,
        low: 1.05238
      },
      {
        timestamp: 1651615200,
        open: 1.05283,
        close: 1.05255,
        high: 1.05289,
        low: 1.05229
      },
      {
        timestamp: 1651615500,
        open: 1.05255,
        close: 1.05244,
        high: 1.05256,
        low: 1.05226
      },
      {
        timestamp: 1651615800,
        open: 1.05245,
        close: 1.05282,
        high: 1.05288,
        low: 1.05244
      },
      {
        timestamp: 1651616100,
        open: 1.05281,
        close: 1.0526,
        high: 1.05284,
        low: 1.0526
      },
      {
        timestamp: 1651616400,
        open: 1.0526,
        close: 1.05258,
        high: 1.0527,
        low: 1.05256
      },
      {
        timestamp: 1651616700,
        open: 1.05259,
        close: 1.05243,
        high: 1.05259,
        low: 1.05234
      },
      {
        timestamp: 1651617000,
        open: 1.05244,
        close: 1.05237,
        high: 1.05254,
        low: 1.05232
      },
      {
        timestamp: 1651617300,
        open: 1.05238,
        close: 1.05237,
        high: 1.05247,
        low: 1.05235
      },
      {
        timestamp: 1651617600,
        open: 1.05236,
        close: 1.05254,
        high: 1.05255,
        low: 1.0523
      },
      {
        timestamp: 1651617900,
        open: 1.05253,
        close: 1.05238,
        high: 1.05256,
        low: 1.05234
      },
      {
        timestamp: 1651618200,
        open: 1.05237,
        close: 1.05253,
        high: 1.05255,
        low: 1.05237
      },
      {
        timestamp: 1651618500,
        open: 1.05254,
        close: 1.05253,
        high: 1.05259,
        low: 1.05245
      },
      {
        timestamp: 1651618800,
        open: 1.05254,
        close: 1.05237,
        high: 1.05268,
        low: 1.05231
      },
      {
        timestamp: 1651619100,
        open: 1.05238,
        close: 1.05238,
        high: 1.05244,
        low: 1.05232
      },
      {
        timestamp: 1651619400,
        open: 1.05238,
        close: 1.05242,
        high: 1.05243,
        low: 1.05229
      },
      {
        timestamp: 1651619700,
        open: 1.05242,
        close: 1.05242,
        high: 1.05246,
        low: 1.05234
      },
      {
        timestamp: 1651620000,
        open: 1.05241,
        close: 1.05228,
        high: 1.05241,
        low: 1.05226
      },
      {
        timestamp: 1651620300,
        open: 1.05229,
        close: 1.05213,
        high: 1.05229,
        low: 1.05212
      },
      {
        timestamp: 1651620600,
        open: 1.05212,
        close: 1.05213,
        high: 1.05227,
        low: 1.05206
      },
      {
        timestamp: 1651620900,
        open: 1.05212,
        close: 1.05209,
        high: 1.05214,
        low: 1.05206
      },
      {
        timestamp: 1651621200,
        open: 1.0521,
        close: 1.05207,
        high: 1.05215,
        low: 1.05206
      },
      {
        timestamp: 1651621500,
        open: 1.05207,
        close: 1.05234,
        high: 1.05242,
        low: 1.05201
      },
      {
        timestamp: 1651621800,
        open: 1.05233,
        close: 1.05241,
        high: 1.05269,
        low: 1.05233
      },
      {
        timestamp: 1651622100,
        open: 1.05244,
        close: 1.05257,
        high: 1.05269,
        low: 1.0524
      },
      {
        timestamp: 1651622400,
        open: 1.05257,
        close: 1.05303,
        high: 1.05348,
        low: 1.05256
      },
      {
        timestamp: 1651622700,
        open: 1.05302,
        close: 1.05283,
        high: 1.05328,
        low: 1.05278
      },
      {
        timestamp: 1651623000,
        open: 1.05282,
        close: 1.05272,
        high: 1.05289,
        low: 1.05269
      },
      {
        timestamp: 1651623300,
        open: 1.05271,
        close: 1.05264,
        high: 1.05277,
        low: 1.05248
      },
      {
        timestamp: 1651623600,
        open: 1.05262,
        close: 1.05223,
        high: 1.05265,
        low: 1.0522
      },
      {
        timestamp: 1651623900,
        open: 1.05224,
        close: 1.05218,
        high: 1.05227,
        low: 1.05208
      },
      {
        timestamp: 1651624200,
        open: 1.05218,
        close: 1.05249,
        high: 1.05256,
        low: 1.05217
      },
      {
        timestamp: 1651624500,
        open: 1.05248,
        close: 1.05253,
        high: 1.05259,
        low: 1.05244
      },
      {
        timestamp: 1651624800,
        open: 1.05253,
        close: 1.05237,
        high: 1.05261,
        low: 1.05225
      },
      {
        timestamp: 1651625100,
        open: 1.05237,
        close: 1.05252,
        high: 1.05257,
        low: 1.05237
      },
      {
        timestamp: 1651625400,
        open: 1.05252,
        close: 1.05272,
        high: 1.05284,
        low: 1.0525
      },
      {
        timestamp: 1651625700,
        open: 1.05272,
        close: 1.05256,
        high: 1.05293,
        low: 1.05252
      },
      {
        timestamp: 1651626000,
        open: 1.05256,
        close: 1.05243,
        high: 1.05265,
        low: 1.05237
      },
      {
        timestamp: 1651626300,
        open: 1.05243,
        close: 1.05207,
        high: 1.05243,
        low: 1.05207
      },
      {
        timestamp: 1651626600,
        open: 1.05207,
        close: 1.05204,
        high: 1.05213,
        low: 1.05192
      },
      {
        timestamp: 1651626900,
        open: 1.05203,
        close: 1.05226,
        high: 1.05243,
        low: 1.05203
      },
      {
        timestamp: 1651627200,
        open: 1.05227,
        close: 1.05233,
        high: 1.05233,
        low: 1.05218
      },
      {
        timestamp: 1651627500,
        open: 1.05233,
        close: 1.05269,
        high: 1.05269,
        low: 1.05228
      },
      {
        timestamp: 1651627800,
        open: 1.05268,
        close: 1.05268,
        high: 1.05292,
        low: 1.05263
      },
      {
        timestamp: 1651628100,
        open: 1.05268,
        close: 1.05254,
        high: 1.05274,
        low: 1.05253
      },
      {
        timestamp: 1651628400,
        open: 1.05255,
        close: 1.0531,
        high: 1.05315,
        low: 1.05252
      },
      {
        timestamp: 1651628700,
        open: 1.05313,
        close: 1.05323,
        high: 1.0534,
        low: 1.053
      },
      {
        timestamp: 1651629000,
        open: 1.05324,
        close: 1.05301,
        high: 1.05324,
        low: 1.05289
      },
      {
        timestamp: 1651629300,
        open: 1.053,
        close: 1.05301,
        high: 1.05309,
        low: 1.05278
      },
      {
        timestamp: 1651629600,
        open: 1.05301,
        close: 1.05293,
        high: 1.05316,
        low: 1.05291
      },
      {
        timestamp: 1651629900,
        open: 1.05293,
        close: 1.0529,
        high: 1.05298,
        low: 1.05283
      },
      {
        timestamp: 1651630200,
        open: 1.05289,
        close: 1.05264,
        high: 1.05291,
        low: 1.05263
      },
      {
        timestamp: 1651630500,
        open: 1.05265,
        close: 1.05306,
        high: 1.05306,
        low: 1.05246
      },
      {
        timestamp: 1651630800,
        open: 1.05305,
        close: 1.05272,
        high: 1.05305,
        low: 1.05269
      },
      {
        timestamp: 1651631100,
        open: 1.05272,
        close: 1.05274,
        high: 1.05278,
        low: 1.05251
      },
      {
        timestamp: 1651631400,
        open: 1.05275,
        close: 1.05253,
        high: 1.05279,
        low: 1.05253
      },
      {
        timestamp: 1651631700,
        open: 1.05253,
        close: 1.05243,
        high: 1.05256,
        low: 1.05243
      },
      {
        timestamp: 1651632000,
        open: 1.05244,
        close: 1.05243,
        high: 1.05254,
        low: 1.05229
      },
      {
        timestamp: 1651632300,
        open: 1.05242,
        close: 1.05229,
        high: 1.05248,
        low: 1.05223
      },
      {
        timestamp: 1651632600,
        open: 1.05229,
        close: 1.05213,
        high: 1.05234,
        low: 1.05212
      },
      {
        timestamp: 1651632900,
        open: 1.05214,
        close: 1.05208,
        high: 1.05231,
        low: 1.05202
      },
      {
        timestamp: 1651633200,
        open: 1.05207,
        close: 1.05234,
        high: 1.05243,
        low: 1.05205
      },
      {
        timestamp: 1651633500,
        open: 1.05233,
        close: 1.05218,
        high: 1.05235,
        low: 1.05217
      },
      {
        timestamp: 1651633800,
        open: 1.05217,
        close: 1.0522,
        high: 1.05221,
        low: 1.05197
      },
      {
        timestamp: 1651634100,
        open: 1.0522,
        close: 1.05194,
        high: 1.0522,
        low: 1.05188
      },
      {
        timestamp: 1651634400,
        open: 1.05193,
        close: 1.05179,
        high: 1.05194,
        low: 1.05173
      },
      {
        timestamp: 1651634700,
        open: 1.05178,
        close: 1.05188,
        high: 1.05195,
        low: 1.05178
      },
      {
        timestamp: 1651635000,
        open: 1.05188,
        close: 1.05162,
        high: 1.05189,
        low: 1.05148
      },
      {
        timestamp: 1651635300,
        open: 1.05162,
        close: 1.05184,
        high: 1.05185,
        low: 1.05152
      },
      {
        timestamp: 1651635600,
        open: 1.05185,
        close: 1.05178,
        high: 1.05189,
        low: 1.05168
      },
      {
        timestamp: 1651635900,
        open: 1.05179,
        close: 1.0517,
        high: 1.05183,
        low: 1.05165
      },
      {
        timestamp: 1651636200,
        open: 1.05169,
        close: 1.05168,
        high: 1.05175,
        low: 1.05165
      },
      {
        timestamp: 1651636500,
        open: 1.05168,
        close: 1.0517,
        high: 1.05181,
        low: 1.05165
      },
      {
        timestamp: 1651636800,
        open: 1.0517,
        close: 1.05183,
        high: 1.05184,
        low: 1.05155
      },
      {
        timestamp: 1651637100,
        open: 1.05184,
        close: 1.0518,
        high: 1.05189,
        low: 1.05179
      },
      {
        timestamp: 1651637400,
        open: 1.0518,
        close: 1.05192,
        high: 1.05194,
        low: 1.05165
      },
      {
        timestamp: 1651637700,
        open: 1.0519,
        close: 1.0521,
        high: 1.0521,
        low: 1.05186
      },
      {
        timestamp: 1651638000,
        open: 1.05209,
        close: 1.05183,
        high: 1.05209,
        low: 1.0518
      },
      {
        timestamp: 1651638300,
        open: 1.05183,
        close: 1.05177,
        high: 1.05185,
        low: 1.05165
      },
      {
        timestamp: 1651638600,
        open: 1.05177,
        close: 1.0518,
        high: 1.05188,
        low: 1.0517
      },
      {
        timestamp: 1651638900,
        open: 1.0518,
        close: 1.05201,
        high: 1.05201,
        low: 1.05176
      },
      {
        timestamp: 1651639200,
        open: 1.052,
        close: 1.05198,
        high: 1.052,
        low: 1.05191
      },
      {
        timestamp: 1651639500,
        open: 1.05197,
        close: 1.0519,
        high: 1.05198,
        low: 1.05188
      },
      {
        timestamp: 1651639800,
        open: 1.05191,
        close: 1.05175,
        high: 1.05195,
        low: 1.05173
      },
      {
        timestamp: 1651640100,
        open: 1.05174,
        close: 1.05174,
        high: 1.05189,
        low: 1.05174
      },
      {
        timestamp: 1651640400,
        open: 1.05174,
        close: 1.05162,
        high: 1.0518,
        low: 1.05145
      },
      {
        timestamp: 1651640700,
        open: 1.05163,
        close: 1.05171,
        high: 1.05186,
        low: 1.05159
      },
      {
        timestamp: 1651641000,
        open: 1.05171,
        close: 1.05173,
        high: 1.05191,
        low: 1.05168
      },
      {
        timestamp: 1651641300,
        open: 1.05172,
        close: 1.05163,
        high: 1.05173,
        low: 1.05149
      },
      {
        timestamp: 1651641600,
        open: 1.05162,
        close: 1.05144,
        high: 1.05165,
        low: 1.05143
      },
      {
        timestamp: 1651641900,
        open: 1.05145,
        close: 1.05133,
        high: 1.05153,
        low: 1.05129
      },
      {
        timestamp: 1651642200,
        open: 1.05133,
        close: 1.05132,
        high: 1.05137,
        low: 1.05128
      },
      {
        timestamp: 1651642500,
        open: 1.05133,
        close: 1.0514,
        high: 1.05156,
        low: 1.0512
      },
      {
        timestamp: 1651642800,
        open: 1.0514,
        close: 1.05161,
        high: 1.05168,
        low: 1.05127
      },
      {
        timestamp: 1651643100,
        open: 1.0516,
        close: 1.05152,
        high: 1.05163,
        low: 1.05142
      },
      {
        timestamp: 1651643400,
        open: 1.05153,
        close: 1.05128,
        high: 1.05157,
        low: 1.0512
      },
      {
        timestamp: 1651643700,
        open: 1.05127,
        close: 1.05131,
        high: 1.05149,
        low: 1.05123
      },
      {
        timestamp: 1651644000,
        open: 1.05131,
        close: 1.05094,
        high: 1.05134,
        low: 1.05067
      },
      {
        timestamp: 1651644300,
        open: 1.05094,
        close: 1.05162,
        high: 1.05164,
        low: 1.05088
      },
      {
        timestamp: 1651644600,
        open: 1.05162,
        close: 1.05182,
        high: 1.05183,
        low: 1.05141
      },
      {
        timestamp: 1651644900,
        open: 1.05183,
        close: 1.05226,
        high: 1.05226,
        low: 1.05173
      },
      {
        timestamp: 1651645200,
        open: 1.05228,
        close: 1.05219,
        high: 1.05244,
        low: 1.0521
      },
      {
        timestamp: 1651645500,
        open: 1.05219,
        close: 1.0518,
        high: 1.05232,
        low: 1.05178
      },
      {
        timestamp: 1651645800,
        open: 1.0518,
        close: 1.05169,
        high: 1.05197,
        low: 1.05159
      },
      {
        timestamp: 1651646100,
        open: 1.05168,
        close: 1.05172,
        high: 1.05182,
        low: 1.05157
      },
      {
        timestamp: 1651646400,
        open: 1.05173,
        close: 1.05143,
        high: 1.05176,
        low: 1.05143
      },
      {
        timestamp: 1651646700,
        open: 1.05142,
        close: 1.05099,
        high: 1.05155,
        low: 1.05084
      },
      {
        timestamp: 1651647000,
        open: 1.051,
        close: 1.05108,
        high: 1.05139,
        low: 1.05094
      },
      {
        timestamp: 1651647300,
        open: 1.05109,
        close: 1.05144,
        high: 1.05146,
        low: 1.05092
      },
      {
        timestamp: 1651647600,
        open: 1.05143,
        close: 1.0527,
        high: 1.05291,
        low: 1.05133
      },
      {
        timestamp: 1651647900,
        open: 1.0527,
        close: 1.05234,
        high: 1.05272,
        low: 1.05233
      },
      {
        timestamp: 1651648200,
        open: 1.05234,
        close: 1.05209,
        high: 1.05243,
        low: 1.05183
      },
      {
        timestamp: 1651648500,
        open: 1.05209,
        close: 1.05113,
        high: 1.05221,
        low: 1.05107
      },
      {
        timestamp: 1651648800,
        open: 1.05112,
        close: 1.05124,
        high: 1.05147,
        low: 1.05083
      },
      {
        timestamp: 1651649100,
        open: 1.05122,
        close: 1.0513,
        high: 1.05135,
        low: 1.05099
      },
      {
        timestamp: 1651649400,
        open: 1.0513,
        close: 1.05195,
        high: 1.05195,
        low: 1.05115
      },
      {
        timestamp: 1651649700,
        open: 1.05195,
        close: 1.05232,
        high: 1.05238,
        low: 1.05158
      },
      {
        timestamp: 1651650000,
        open: 1.05231,
        close: 1.05248,
        high: 1.05274,
        low: 1.05219
      },
      {
        timestamp: 1651650300,
        open: 1.05249,
        close: 1.05254,
        high: 1.0527,
        low: 1.05235
      },
      {
        timestamp: 1651650600,
        open: 1.05252,
        close: 1.05243,
        high: 1.05275,
        low: 1.05227
      },
      {
        timestamp: 1651650900,
        open: 1.05244,
        close: 1.05248,
        high: 1.05259,
        low: 1.05213
      },
      {
        timestamp: 1651651200,
        open: 1.05249,
        close: 1.05219,
        high: 1.05253,
        low: 1.05213
      },
      {
        timestamp: 1651651500,
        open: 1.05219,
        close: 1.05279,
        high: 1.05286,
        low: 1.05194
      },
      {
        timestamp: 1651651800,
        open: 1.0528,
        close: 1.05248,
        high: 1.05289,
        low: 1.05248
      },
      {
        timestamp: 1651652100,
        open: 1.05248,
        close: 1.05204,
        high: 1.05252,
        low: 1.05189
      },
      {
        timestamp: 1651652400,
        open: 1.05204,
        close: 1.05239,
        high: 1.05252,
        low: 1.05177
      },
      {
        timestamp: 1651652700,
        open: 1.05238,
        close: 1.05253,
        high: 1.05273,
        low: 1.05233
      },
      {
        timestamp: 1651653000,
        open: 1.05253,
        close: 1.05281,
        high: 1.05336,
        low: 1.05247
      },
      {
        timestamp: 1651653300,
        open: 1.05281,
        close: 1.05275,
        high: 1.05308,
        low: 1.05257
      },
      {
        timestamp: 1651653600,
        open: 1.05274,
        close: 1.05293,
        high: 1.05306,
        low: 1.05269
      },
      {
        timestamp: 1651653900,
        open: 1.05292,
        close: 1.05334,
        high: 1.05352,
        low: 1.0528
      },
      {
        timestamp: 1651654200,
        open: 1.05335,
        close: 1.05304,
        high: 1.05335,
        low: 1.05301
      },
      {
        timestamp: 1651654500,
        open: 1.05304,
        close: 1.05228,
        high: 1.05304,
        low: 1.05214
      },
      {
        timestamp: 1651654800,
        open: 1.05228,
        close: 1.05229,
        high: 1.05272,
        low: 1.05215
      },
      {
        timestamp: 1651655100,
        open: 1.05229,
        close: 1.0518,
        high: 1.05239,
        low: 1.0517
      },
      {
        timestamp: 1651655400,
        open: 1.0518,
        close: 1.0524,
        high: 1.05246,
        low: 1.05153
      },
      {
        timestamp: 1651655700,
        open: 1.0524,
        close: 1.05247,
        high: 1.05266,
        low: 1.05234
      },
      {
        timestamp: 1651656000,
        open: 1.05247,
        close: 1.05285,
        high: 1.05289,
        low: 1.05237
      },
      {
        timestamp: 1651656300,
        open: 1.05285,
        close: 1.05316,
        high: 1.05316,
        low: 1.05274
      },
      {
        timestamp: 1651656600,
        open: 1.05316,
        close: 1.05294,
        high: 1.05346,
        low: 1.05294
      },
      {
        timestamp: 1651656900,
        open: 1.05294,
        close: 1.05294,
        high: 1.05324,
        low: 1.05284
      },
      {
        timestamp: 1651657200,
        open: 1.05294,
        close: 1.0527,
        high: 1.053,
        low: 1.05268
      },
      {
        timestamp: 1651657500,
        open: 1.05269,
        close: 1.05228,
        high: 1.05271,
        low: 1.05222
      },
      {
        timestamp: 1651657800,
        open: 1.05229,
        close: 1.05294,
        high: 1.05295,
        low: 1.05229
      },
      {
        timestamp: 1651658100,
        open: 1.05293,
        close: 1.05243,
        high: 1.05307,
        low: 1.05238
      },
      {
        timestamp: 1651658400,
        open: 1.05243,
        close: 1.05222,
        high: 1.05263,
        low: 1.05221
      },
      {
        timestamp: 1651658700,
        open: 1.05222,
        close: 1.0527,
        high: 1.0527,
        low: 1.05215
      },
      {
        timestamp: 1651659000,
        open: 1.0527,
        close: 1.05285,
        high: 1.05307,
        low: 1.05262
      },
      {
        timestamp: 1651659300,
        open: 1.05286,
        close: 1.05247,
        high: 1.05296,
        low: 1.05244
      },
      {
        timestamp: 1651659600,
        open: 1.05248,
        close: 1.05255,
        high: 1.05255,
        low: 1.05228
      },
      {
        timestamp: 1651659900,
        open: 1.05256,
        close: 1.05281,
        high: 1.05292,
        low: 1.05255
      },
      {
        timestamp: 1651660200,
        open: 1.0528,
        close: 1.05258,
        high: 1.05285,
        low: 1.05248
      },
      {
        timestamp: 1651660500,
        open: 1.05258,
        close: 1.05293,
        high: 1.053,
        low: 1.05258
      },
      {
        timestamp: 1651660800,
        open: 1.05294,
        close: 1.05248,
        high: 1.05294,
        low: 1.05248
      },
      {
        timestamp: 1651661100,
        open: 1.05247,
        close: 1.05239,
        high: 1.05264,
        low: 1.05226
      },
      {
        timestamp: 1651661400,
        open: 1.05239,
        close: 1.05265,
        high: 1.05281,
        low: 1.0522
      },
      {
        timestamp: 1651661700,
        open: 1.05265,
        close: 1.05304,
        high: 1.05319,
        low: 1.05263
      },
      {
        timestamp: 1651662000,
        open: 1.05305,
        close: 1.05303,
        high: 1.05309,
        low: 1.05272
      },
      {
        timestamp: 1651662300,
        open: 1.05304,
        close: 1.05283,
        high: 1.0533,
        low: 1.05278
      },
      {
        timestamp: 1651662600,
        open: 1.05283,
        close: 1.05277,
        high: 1.05304,
        low: 1.05277
      },
      {
        timestamp: 1651662900,
        open: 1.05276,
        close: 1.05323,
        high: 1.05334,
        low: 1.05268
      },
      {
        timestamp: 1651663200,
        open: 1.05324,
        close: 1.05324,
        high: 1.05338,
        low: 1.05322
      },
      {
        timestamp: 1651663500,
        open: 1.05326,
        close: 1.05348,
        high: 1.05371,
        low: 1.05318
      },
      {
        timestamp: 1651663800,
        open: 1.05348,
        close: 1.05343,
        high: 1.05365,
        low: 1.05343
      },
      {
        timestamp: 1651664100,
        open: 1.05343,
        close: 1.0532,
        high: 1.05347,
        low: 1.05317
      },
      {
        timestamp: 1651664400,
        open: 1.05319,
        close: 1.053,
        high: 1.05322,
        low: 1.05293
      },
      {
        timestamp: 1651664700,
        open: 1.053,
        close: 1.05272,
        high: 1.053,
        low: 1.0524
      },
      {
        timestamp: 1651665000,
        open: 1.05273,
        close: 1.05288,
        high: 1.05319,
        low: 1.05259
      },
      {
        timestamp: 1651665300,
        open: 1.05289,
        close: 1.05313,
        high: 1.05318,
        low: 1.05267
      },
      {
        timestamp: 1651665600,
        open: 1.05313,
        close: 1.05301,
        high: 1.05314,
        low: 1.05262
      },
      {
        timestamp: 1651665900,
        open: 1.05302,
        close: 1.05315,
        high: 1.05328,
        low: 1.05268
      },
      {
        timestamp: 1651666200,
        open: 1.05315,
        close: 1.05309,
        high: 1.05344,
        low: 1.05307
      },
      {
        timestamp: 1651666500,
        open: 1.05308,
        close: 1.05344,
        high: 1.05349,
        low: 1.05276
      },
      {
        timestamp: 1651666800,
        open: 1.05344,
        close: 1.05336,
        high: 1.05371,
        low: 1.05327
      },
      {
        timestamp: 1651667100,
        open: 1.05336,
        close: 1.05416,
        high: 1.05432,
        low: 1.05328
      },
      {
        timestamp: 1651667400,
        open: 1.05416,
        close: 1.05429,
        high: 1.05447,
        low: 1.05404
      },
      {
        timestamp: 1651667700,
        open: 1.05428,
        close: 1.05454,
        high: 1.05469,
        low: 1.05376
      },
      {
        timestamp: 1651668000,
        open: 1.05456,
        close: 1.05527,
        high: 1.05541,
        low: 1.05445
      },
      {
        timestamp: 1651668300,
        open: 1.05529,
        close: 1.05484,
        high: 1.05562,
        low: 1.05469
      },
      {
        timestamp: 1651668600,
        open: 1.05483,
        close: 1.05549,
        high: 1.0555,
        low: 1.05474
      },
      {
        timestamp: 1651668900,
        open: 1.0555,
        close: 1.0554,
        high: 1.05552,
        low: 1.05512
      },
      {
        timestamp: 1651669200,
        open: 1.05538,
        close: 1.05538,
        high: 1.05563,
        low: 1.0551
      },
      {
        timestamp: 1651669500,
        open: 1.05537,
        close: 1.05509,
        high: 1.05554,
        low: 1.05501
      },
      {
        timestamp: 1651669800,
        open: 1.05509,
        close: 1.05544,
        high: 1.05562,
        low: 1.05467
      },
      {
        timestamp: 1651670100,
        open: 1.05544,
        close: 1.05542,
        high: 1.05556,
        low: 1.0551
      },
      {
        timestamp: 1651670400,
        open: 1.05542,
        close: 1.05542,
        high: 1.05553,
        low: 1.05532
      },
      {
        timestamp: 1651670700,
        open: 1.05542,
        close: 1.05527,
        high: 1.05549,
        low: 1.05521
      },
      {
        timestamp: 1651671000,
        open: 1.05526,
        close: 1.05476,
        high: 1.05526,
        low: 1.05458
      },
      {
        timestamp: 1651671300,
        open: 1.05476,
        close: 1.05423,
        high: 1.05482,
        low: 1.0538
      },
      {
        timestamp: 1651671600,
        open: 1.0542,
        close: 1.05451,
        high: 1.05463,
        low: 1.05413
      },
      {
        timestamp: 1651671900,
        open: 1.05453,
        close: 1.05437,
        high: 1.05483,
        low: 1.0541
      },
      {
        timestamp: 1651672200,
        open: 1.05435,
        close: 1.05378,
        high: 1.05435,
        low: 1.05358
      },
      {
        timestamp: 1651672500,
        open: 1.05377,
        close: 1.0533,
        high: 1.0539,
        low: 1.05327
      },
      {
        timestamp: 1651672800,
        open: 1.05331,
        close: 1.0529,
        high: 1.05363,
        low: 1.05276
      },
      {
        timestamp: 1651673100,
        open: 1.05291,
        close: 1.05287,
        high: 1.05302,
        low: 1.05253
      },
      {
        timestamp: 1651673400,
        open: 1.05286,
        close: 1.05315,
        high: 1.0532,
        low: 1.05262
      },
      {
        timestamp: 1651673700,
        open: 1.05315,
        close: 1.05348,
        high: 1.05358,
        low: 1.05276
      },
      {
        timestamp: 1651674000,
        open: 1.05348,
        close: 1.05367,
        high: 1.05388,
        low: 1.0533
      },
      {
        timestamp: 1651674300,
        open: 1.05369,
        close: 1.05398,
        high: 1.05405,
        low: 1.05353
      },
      {
        timestamp: 1651674600,
        open: 1.05397,
        close: 1.05349,
        high: 1.05414,
        low: 1.05318
      },
      {
        timestamp: 1651674900,
        open: 1.05349,
        close: 1.0538,
        high: 1.05393,
        low: 1.05323
      },
      {
        timestamp: 1651675200,
        open: 1.0538,
        close: 1.05369,
        high: 1.05388,
        low: 1.05347
      },
      {
        timestamp: 1651675500,
        open: 1.0537,
        close: 1.05403,
        high: 1.05411,
        low: 1.05343
      },
      {
        timestamp: 1651675800,
        open: 1.05404,
        close: 1.05458,
        high: 1.05465,
        low: 1.05387
      },
      {
        timestamp: 1651676100,
        open: 1.05457,
        close: 1.0547,
        high: 1.05515,
        low: 1.05438
      },
      {
        timestamp: 1651676400,
        open: 1.0547,
        close: 1.05495,
        high: 1.05505,
        low: 1.05458
      },
      {
        timestamp: 1651676700,
        open: 1.05494,
        close: 1.05483,
        high: 1.05499,
        low: 1.05443
      },
      {
        timestamp: 1651677000,
        open: 1.05483,
        close: 1.05528,
        high: 1.05533,
        low: 1.0548
      },
      {
        timestamp: 1651677300,
        open: 1.0553,
        close: 1.0556,
        high: 1.05601,
        low: 1.05523
      },
      {
        timestamp: 1651677600,
        open: 1.05561,
        close: 1.05643,
        high: 1.05651,
        low: 1.0556
      },
      {
        timestamp: 1651677900,
        open: 1.05644,
        close: 1.05608,
        high: 1.05649,
        low: 1.05592
      },
      {
        timestamp: 1651678200,
        open: 1.05609,
        close: 1.05568,
        high: 1.05609,
        low: 1.05557
      },
      {
        timestamp: 1651678500,
        open: 1.05569,
        close: 1.05485,
        high: 1.05574,
        low: 1.05483
      },
      {
        timestamp: 1651678800,
        open: 1.05485,
        close: 1.05473,
        high: 1.05496,
        low: 1.0544
      },
      {
        timestamp: 1651679100,
        open: 1.05474,
        close: 1.05483,
        high: 1.05526,
        low: 1.05445
      },
      {
        timestamp: 1651679400,
        open: 1.05483,
        close: 1.0544,
        high: 1.05485,
        low: 1.05431
      },
      {
        timestamp: 1651679700,
        open: 1.05442,
        close: 1.05409,
        high: 1.05442,
        low: 1.05373
      },
      {
        timestamp: 1651680000,
        open: 1.05409,
        close: 1.05363,
        high: 1.05415,
        low: 1.05351
      },
      {
        timestamp: 1651680300,
        open: 1.05363,
        close: 1.05439,
        high: 1.05445,
        low: 1.05361
      },
      {
        timestamp: 1651680600,
        open: 1.0544,
        close: 1.05396,
        high: 1.05442,
        low: 1.05388
      },
      {
        timestamp: 1651680900,
        open: 1.05398,
        close: 1.054,
        high: 1.05404,
        low: 1.05349
      },
      {
        timestamp: 1651681200,
        open: 1.05398,
        close: 1.05403,
        high: 1.05453,
        low: 1.05394
      },
      {
        timestamp: 1651681500,
        open: 1.05404,
        close: 1.05434,
        high: 1.05449,
        low: 1.05392
      },
      {
        timestamp: 1651681800,
        open: 1.05433,
        close: 1.0543,
        high: 1.05453,
        low: 1.05418
      },
      {
        timestamp: 1651682100,
        open: 1.05431,
        close: 1.05403,
        high: 1.05443,
        low: 1.05403
      },
      {
        timestamp: 1651682400,
        open: 1.05404,
        close: 1.05418,
        high: 1.05436,
        low: 1.05403
      },
      {
        timestamp: 1651682700,
        open: 1.05418,
        close: 1.05449,
        high: 1.05453,
        low: 1.05387
      },
      {
        timestamp: 1651683000,
        open: 1.05448,
        close: 1.05473,
        high: 1.05477,
        low: 1.05437
      },
      {
        timestamp: 1651683300,
        open: 1.05473,
        close: 1.05494,
        high: 1.05495,
        low: 1.05466
      },
      {
        timestamp: 1651683600,
        open: 1.05495,
        close: 1.05528,
        high: 1.05531,
        low: 1.05479
      },
      {
        timestamp: 1651683900,
        open: 1.05527,
        close: 1.05517,
        high: 1.05535,
        low: 1.055
      },
      {
        timestamp: 1651684200,
        open: 1.05517,
        close: 1.05443,
        high: 1.05518,
        low: 1.05428
      },
      {
        timestamp: 1651684500,
        open: 1.05441,
        close: 1.05453,
        high: 1.05463,
        low: 1.05433
      },
      {
        timestamp: 1651684800,
        open: 1.05453,
        close: 1.05448,
        high: 1.05459,
        low: 1.05433
      },
      {
        timestamp: 1651685100,
        open: 1.05448,
        close: 1.05413,
        high: 1.05456,
        low: 1.05403
      },
      {
        timestamp: 1651685400,
        open: 1.05413,
        close: 1.05405,
        high: 1.05429,
        low: 1.05381
      },
      {
        timestamp: 1651685700,
        open: 1.05405,
        close: 1.05378,
        high: 1.05413,
        low: 1.05372
      },
      {
        timestamp: 1651686000,
        open: 1.05379,
        close: 1.05397,
        high: 1.05418,
        low: 1.05376
      },
      {
        timestamp: 1651686300,
        open: 1.05395,
        close: 1.05368,
        high: 1.05397,
        low: 1.05349
      },
      {
        timestamp: 1651686600,
        open: 1.05368,
        close: 1.05418,
        high: 1.05424,
        low: 1.05362
      },
      {
        timestamp: 1651686900,
        open: 1.05419,
        close: 1.05476,
        high: 1.05501,
        low: 1.05376
      },
      {
        timestamp: 1651687200,
        open: 1.05477,
        close: 1.05665,
        high: 1.05705,
        low: 1.05355
      },
      {
        timestamp: 1651687500,
        open: 1.05665,
        close: 1.05673,
        high: 1.05737,
        low: 1.05626
      },
      {
        timestamp: 1651687800,
        open: 1.05673,
        close: 1.05597,
        high: 1.05673,
        low: 1.05548
      },
      {
        timestamp: 1651688100,
        open: 1.05596,
        close: 1.05567,
        high: 1.05598,
        low: 1.05552
      },
      {
        timestamp: 1651689900,
        open: 1.05787,
        close: 1.05854,
        high: 1.05859,
        low: 1.05771
      },
      {
        timestamp: 1651690200,
        open: 1.05854,
        close: 1.05912,
        high: 1.05956,
        low: 1.05837
      },
      {
        timestamp: 1651690500,
        open: 1.05912,
        close: 1.05923,
        high: 1.05936,
        low: 1.05904
      },
      {
        timestamp: 1651690800,
        open: 1.06069,
        close: 1.05947,
        high: 1.06075,
        low: 1.0594
      },
      {
        timestamp: 1651691100,
        open: 1.05947,
        close: 1.05937,
        high: 1.05952,
        low: 1.05826
      },
      {
        timestamp: 1651691400,
        open: 1.05936,
        close: 1.05929,
        high: 1.05958,
        low: 1.05902
      },
      {
        timestamp: 1651691700,
        open: 1.05921,
        close: 1.05927,
        high: 1.0593,
        low: 1.05916
      },
      {
        timestamp: 1651692300,
        open: 1.05934,
        close: 1.06033,
        high: 1.06037,
        low: 1.05895
      },
      {
        timestamp: 1651692600,
        open: 1.06032,
        close: 1.06099,
        high: 1.06131,
        low: 1.06032
      },
      {
        timestamp: 1651692900,
        open: 1.06098,
        close: 1.06159,
        high: 1.06187,
        low: 1.06076
      },
      {
        timestamp: 1651693200,
        open: 1.06159,
        close: 1.05968,
        high: 1.0617,
        low: 1.05952
      },
      {
        timestamp: 1651693500,
        open: 1.0597,
        close: 1.05961,
        high: 1.06007,
        low: 1.05913
      },
      {
        timestamp: 1651693800,
        open: 1.05961,
        close: 1.05991,
        high: 1.06035,
        low: 1.0596
      },
      {
        timestamp: 1651694100,
        open: 1.05991,
        close: 1.05978,
        high: 1.06,
        low: 1.05974
      },
      {
        timestamp: 1651694400,
        open: 1.05978,
        close: 1.06056,
        high: 1.0606,
        low: 1.05974
      },
      {
        timestamp: 1651694700,
        open: 1.06056,
        close: 1.06052,
        high: 1.06077,
        low: 1.0603
      },
      {
        timestamp: 1651695000,
        open: 1.06051,
        close: 1.06049,
        high: 1.06065,
        low: 1.06031
      },
      {
        timestamp: 1651695300,
        open: 1.06049,
        close: 1.06058,
        high: 1.06132,
        low: 1.06048
      },
      {
        timestamp: 1651695600,
        open: 1.06058,
        close: 1.06113,
        high: 1.06124,
        low: 1.0605
      },
      {
        timestamp: 1651695900,
        open: 1.06114,
        close: 1.06035,
        high: 1.06136,
        low: 1.06033
      },
      {
        timestamp: 1651696200,
        open: 1.06035,
        close: 1.06014,
        high: 1.0607,
        low: 1.05993
      },
      {
        timestamp: 1651696500,
        open: 1.06014,
        close: 1.06056,
        high: 1.06056,
        low: 1.05977
      },
      {
        timestamp: 1651696800,
        open: 1.06056,
        close: 1.06075,
        high: 1.06098,
        low: 1.06053
      },
      {
        timestamp: 1651697100,
        open: 1.06076,
        close: 1.06161,
        high: 1.06173,
        low: 1.06062
      },
      {
        timestamp: 1651697400,
        open: 1.06161,
        close: 1.06245,
        high: 1.06245,
        low: 1.0614
      },
      {
        timestamp: 1651697700,
        open: 1.06246,
        close: 1.06266,
        high: 1.063,
        low: 1.0622
      },
      {
        timestamp: 1651698000,
        open: 1.06271,
        close: 1.06271,
        high: 1.06271,
        low: 1.06271
      },
      {
        timestamp: 1651698300,
        open: 1.06271,
        close: 1.06272,
        high: 1.06272,
        low: 1.06265
      },
      {
        timestamp: 1651698600,
        open: 1.06282,
        close: 1.06275,
        high: 1.06282,
        low: 1.06275
      },
      {
        timestamp: 1651698900,
        open: 1.06282,
        close: 1.06285,
        high: 1.06289,
        low: 1.06254
      },
      {
        timestamp: 1651699200,
        open: 1.06287,
        close: 1.0626,
        high: 1.06287,
        low: 1.0626
      },
      {
        timestamp: 1651699500,
        open: 1.0626,
        close: 1.0626,
        high: 1.0626,
        low: 1.06254
      },
      {
        timestamp: 1651699800,
        open: 1.06255,
        close: 1.06256,
        high: 1.06262,
        low: 1.06255
      },
      {
        timestamp: 1651700100,
        open: 1.06256,
        close: 1.06259,
        high: 1.06261,
        low: 1.06256
      },
      {
        timestamp: 1651700400,
        open: 1.06259,
        close: 1.06259,
        high: 1.06261,
        low: 1.06257
      },
      {
        timestamp: 1651700700,
        open: 1.06258,
        close: 1.06259,
        high: 1.06261,
        low: 1.06258
      },
      {
        timestamp: 1651701000,
        open: 1.06259,
        close: 1.06253,
        high: 1.06259,
        low: 1.06253
      },
      {
        timestamp: 1651701300,
        open: 1.06253,
        close: 1.06256,
        high: 1.06262,
        low: 1.06253
      },
      {
        timestamp: 1651701600,
        open: 1.06256,
        close: 1.06244,
        high: 1.06263,
        low: 1.06226
      },
      {
        timestamp: 1651701900,
        open: 1.06243,
        close: 1.06249,
        high: 1.06257,
        low: 1.06226
      },
      {
        timestamp: 1651702200,
        open: 1.06249,
        close: 1.06167,
        high: 1.06249,
        low: 1.06156
      },
      {
        timestamp: 1651702500,
        open: 1.06166,
        close: 1.06195,
        high: 1.06196,
        low: 1.06158
      },
      {
        timestamp: 1651702800,
        open: 1.06194,
        close: 1.06165,
        high: 1.06203,
        low: 1.06159
      },
      {
        timestamp: 1651703100,
        open: 1.06164,
        close: 1.06162,
        high: 1.06174,
        low: 1.06158
      },
      {
        timestamp: 1651703400,
        open: 1.06163,
        close: 1.06139,
        high: 1.06166,
        low: 1.06125
      },
      {
        timestamp: 1651703700,
        open: 1.06138,
        close: 1.06113,
        high: 1.06138,
        low: 1.06109
      },
      {
        timestamp: 1651704000,
        open: 1.06114,
        close: 1.06113,
        high: 1.06115,
        low: 1.06104
      },
      {
        timestamp: 1651704300,
        open: 1.06113,
        close: 1.06085,
        high: 1.06131,
        low: 1.06083
      },
      {
        timestamp: 1651704600,
        open: 1.06085,
        close: 1.06074,
        high: 1.06104,
        low: 1.06073
      },
      {
        timestamp: 1651704900,
        open: 1.06074,
        close: 1.06066,
        high: 1.06091,
        low: 1.06054
      },
      {
        timestamp: 1651705200,
        open: 1.06067,
        close: 1.06079,
        high: 1.06083,
        low: 1.06062
      },
      {
        timestamp: 1651705500,
        open: 1.0608,
        close: 1.06062,
        high: 1.0608,
        low: 1.06053
      },
      {
        timestamp: 1651705800,
        open: 1.06062,
        close: 1.06066,
        high: 1.06072,
        low: 1.06056
      },
      {
        timestamp: 1651706100,
        open: 1.06065,
        close: 1.06062,
        high: 1.06074,
        low: 1.06056
      },
      {
        timestamp: 1651706400,
        open: 1.06061,
        close: 1.06064,
        high: 1.06066,
        low: 1.06057
      },
      {
        timestamp: 1651706700,
        open: 1.06065,
        close: 1.06058,
        high: 1.06077,
        low: 1.06049
      },
      {
        timestamp: 1651707000,
        open: 1.06059,
        close: 1.06071,
        high: 1.06072,
        low: 1.06044
      },
      {
        timestamp: 1651707300,
        open: 1.06072,
        close: 1.06106,
        high: 1.0611,
        low: 1.06069
      },
      {
        timestamp: 1651707600,
        open: 1.06105,
        close: 1.06135,
        high: 1.06141,
        low: 1.06102
      },
      {
        timestamp: 1651707900,
        open: 1.06135,
        close: 1.06167,
        high: 1.0617,
        low: 1.06135
      },
      {
        timestamp: 1651708200,
        open: 1.06166,
        close: 1.06229,
        high: 1.06235,
        low: 1.06157
      },
      {
        timestamp: 1651708500,
        open: 1.06231,
        close: 1.06213,
        high: 1.06235,
        low: 1.062
      },
      {
        timestamp: 1651708800,
        open: 1.06215,
        close: 1.06261,
        high: 1.06269,
        low: 1.062
      },
      {
        timestamp: 1651709100,
        open: 1.06262,
        close: 1.06203,
        high: 1.06275,
        low: 1.06162
      },
      {
        timestamp: 1651709400,
        open: 1.06203,
        close: 1.06173,
        high: 1.06236,
        low: 1.06169
      },
      {
        timestamp: 1651709700,
        open: 1.06174,
        close: 1.06225,
        high: 1.06229,
        low: 1.06169
      },
      {
        timestamp: 1651710000,
        open: 1.06224,
        close: 1.06238,
        high: 1.06284,
        low: 1.06211
      },
      {
        timestamp: 1651710300,
        open: 1.06238,
        close: 1.0612,
        high: 1.06257,
        low: 1.06118
      },
      {
        timestamp: 1651710600,
        open: 1.06122,
        close: 1.06182,
        high: 1.06187,
        low: 1.06117
      },
      {
        timestamp: 1651710900,
        open: 1.06182,
        close: 1.06076,
        high: 1.06188,
        low: 1.06071
      },
      {
        timestamp: 1651711200,
        open: 1.06078,
        close: 1.06077,
        high: 1.0611,
        low: 1.06049
      },
      {
        timestamp: 1651711500,
        open: 1.06078,
        close: 1.06096,
        high: 1.06102,
        low: 1.06058
      },
      {
        timestamp: 1651711800,
        open: 1.06095,
        close: 1.06097,
        high: 1.06116,
        low: 1.06089
      },
      {
        timestamp: 1651712100,
        open: 1.06098,
        close: 1.06143,
        high: 1.06153,
        low: 1.06094
      },
      {
        timestamp: 1651712400,
        open: 1.06144,
        close: 1.06129,
        high: 1.06154,
        low: 1.06112
      },
      {
        timestamp: 1651712700,
        open: 1.06128,
        close: 1.0617,
        high: 1.06186,
        low: 1.06124
      },
      {
        timestamp: 1651713000,
        open: 1.06169,
        close: 1.06147,
        high: 1.06186,
        low: 1.06129
      },
      {
        timestamp: 1651713300,
        open: 1.06145,
        close: 1.06199,
        high: 1.06203,
        low: 1.06144
      },
      {
        timestamp: 1651713600,
        open: 1.06198,
        close: 1.06281,
        high: 1.063,
        low: 1.06195
      },
      {
        timestamp: 1651713900,
        open: 1.0628,
        close: 1.06319,
        high: 1.06332,
        low: 1.06264
      },
      {
        timestamp: 1651714200,
        open: 1.06318,
        close: 1.06341,
        high: 1.06424,
        low: 1.06294
      },
      {
        timestamp: 1651714500,
        open: 1.06338,
        close: 1.06306,
        high: 1.06377,
        low: 1.063
      },
      {
        timestamp: 1651714800,
        open: 1.06305,
        close: 1.06274,
        high: 1.06325,
        low: 1.06226
      },
      {
        timestamp: 1651715100,
        open: 1.06273,
        close: 1.06233,
        high: 1.06279,
        low: 1.06224
      },
      {
        timestamp: 1651715400,
        open: 1.06234,
        close: 1.0623,
        high: 1.06266,
        low: 1.06208
      },
      {
        timestamp: 1651715700,
        open: 1.0623,
        close: 1.06264,
        high: 1.06273,
        low: 1.0623
      },
      {
        timestamp: 1651716000,
        open: 1.06264,
        close: 1.06269,
        high: 1.06294,
        low: 1.06241
      },
      {
        timestamp: 1651716300,
        open: 1.0627,
        close: 1.06233,
        high: 1.06273,
        low: 1.06229
      },
      {
        timestamp: 1651716600,
        open: 1.06233,
        close: 1.06193,
        high: 1.06244,
        low: 1.06193
      },
      {
        timestamp: 1651716900,
        open: 1.06194,
        close: 1.06191,
        high: 1.06212,
        low: 1.06161
      },
      {
        timestamp: 1651717200,
        open: 1.06191,
        close: 1.06183,
        high: 1.06221,
        low: 1.06165
      },
      {
        timestamp: 1651717500,
        open: 1.06182,
        close: 1.06169,
        high: 1.06196,
        low: 1.06149
      },
      {
        timestamp: 1651717800,
        open: 1.06168,
        close: 1.06223,
        high: 1.06229,
        low: 1.06168
      },
      {
        timestamp: 1651718100,
        open: 1.06222,
        close: 1.06252,
        high: 1.06255,
        low: 1.06222
      },
      {
        timestamp: 1651718400,
        open: 1.06252,
        close: 1.06282,
        high: 1.0631,
        low: 1.06247
      },
      {
        timestamp: 1651718700,
        open: 1.06281,
        close: 1.06268,
        high: 1.06294,
        low: 1.0626
      },
      {
        timestamp: 1651719000,
        open: 1.06268,
        close: 1.06258,
        high: 1.06273,
        low: 1.06252
      },
      {
        timestamp: 1651719300,
        open: 1.06257,
        close: 1.06239,
        high: 1.06258,
        low: 1.06234
      },
      {
        timestamp: 1651719600,
        open: 1.06238,
        close: 1.06227,
        high: 1.06239,
        low: 1.06224
      },
      {
        timestamp: 1651719900,
        open: 1.06226,
        close: 1.06207,
        high: 1.0623,
        low: 1.06206
      },
      {
        timestamp: 1651720200,
        open: 1.06207,
        close: 1.06229,
        high: 1.06235,
        low: 1.062
      },
      {
        timestamp: 1651720500,
        open: 1.06228,
        close: 1.0626,
        high: 1.06264,
        low: 1.06226
      },
      {
        timestamp: 1651720800,
        open: 1.06259,
        close: 1.06218,
        high: 1.06259,
        low: 1.06215
      },
      {
        timestamp: 1651721100,
        open: 1.06217,
        close: 1.06232,
        high: 1.06237,
        low: 1.06214
      },
      {
        timestamp: 1651721400,
        open: 1.06232,
        close: 1.06254,
        high: 1.0626,
        low: 1.06229
      },
      {
        timestamp: 1651721700,
        open: 1.06253,
        close: 1.06266,
        high: 1.06267,
        low: 1.06232
      },
      {
        timestamp: 1651722000,
        open: 1.06266,
        close: 1.06231,
        high: 1.06289,
        low: 1.0623
      },
      {
        timestamp: 1651722300,
        open: 1.06231,
        close: 1.06208,
        high: 1.06231,
        low: 1.06181
      },
      {
        timestamp: 1651722600,
        open: 1.06207,
        close: 1.06175,
        high: 1.06214,
        low: 1.06172
      },
      {
        timestamp: 1651722900,
        open: 1.06175,
        close: 1.06184,
        high: 1.06188,
        low: 1.06158
      },
      {
        timestamp: 1651723200,
        open: 1.06185,
        close: 1.06147,
        high: 1.06187,
        low: 1.06147
      },
      {
        timestamp: 1651723500,
        open: 1.06148,
        close: 1.06155,
        high: 1.06159,
        low: 1.06119
      },
      {
        timestamp: 1651723800,
        open: 1.06157,
        close: 1.06144,
        high: 1.06172,
        low: 1.0613
      },
      {
        timestamp: 1651724100,
        open: 1.06145,
        close: 1.06145,
        high: 1.06173,
        low: 1.06139
      },
      {
        timestamp: 1651724400,
        open: 1.06146,
        close: 1.06156,
        high: 1.06173,
        low: 1.06142
      },
      {
        timestamp: 1651724700,
        open: 1.06157,
        close: 1.06109,
        high: 1.06157,
        low: 1.06104
      },
      {
        timestamp: 1651725000,
        open: 1.06109,
        close: 1.0614,
        high: 1.06141,
        low: 1.06104
      },
      {
        timestamp: 1651725300,
        open: 1.06142,
        close: 1.06177,
        high: 1.06186,
        low: 1.06142
      },
      {
        timestamp: 1651725600,
        open: 1.06178,
        close: 1.06133,
        high: 1.06179,
        low: 1.06132
      },
      {
        timestamp: 1651725900,
        open: 1.06134,
        close: 1.06174,
        high: 1.06184,
        low: 1.06133
      },
      {
        timestamp: 1651726200,
        open: 1.06174,
        close: 1.06163,
        high: 1.0618,
        low: 1.06149
      },
      {
        timestamp: 1651726500,
        open: 1.06163,
        close: 1.06132,
        high: 1.06171,
        low: 1.06128
      },
      {
        timestamp: 1651726800,
        open: 1.06132,
        close: 1.06154,
        high: 1.06176,
        low: 1.06132
      },
      {
        timestamp: 1651727100,
        open: 1.06155,
        close: 1.06083,
        high: 1.06155,
        low: 1.06067
      },
      {
        timestamp: 1651727400,
        open: 1.06084,
        close: 1.06132,
        high: 1.06132,
        low: 1.0608
      },
      {
        timestamp: 1651727700,
        open: 1.06133,
        close: 1.06172,
        high: 1.0618,
        low: 1.06116
      },
      {
        timestamp: 1651728000,
        open: 1.06172,
        close: 1.06195,
        high: 1.06196,
        low: 1.06152
      },
      {
        timestamp: 1651728300,
        open: 1.06197,
        close: 1.06169,
        high: 1.062,
        low: 1.06163
      },
      {
        timestamp: 1651728600,
        open: 1.0617,
        close: 1.06138,
        high: 1.06177,
        low: 1.06137
      },
      {
        timestamp: 1651728900,
        open: 1.06138,
        close: 1.06134,
        high: 1.06176,
        low: 1.06133
      },
      {
        timestamp: 1651729200,
        open: 1.06134,
        close: 1.06149,
        high: 1.06168,
        low: 1.06126
      },
      {
        timestamp: 1651729500,
        open: 1.0615,
        close: 1.06148,
        high: 1.0617,
        low: 1.06109
      },
      {
        timestamp: 1651729800,
        open: 1.06149,
        close: 1.06148,
        high: 1.06175,
        low: 1.06092
      },
      {
        timestamp: 1651730100,
        open: 1.06149,
        close: 1.0613,
        high: 1.06155,
        low: 1.06112
      },
      {
        timestamp: 1651730400,
        open: 1.0613,
        close: 1.06082,
        high: 1.06132,
        low: 1.06054
      },
      {
        timestamp: 1651730700,
        open: 1.06081,
        close: 1.06098,
        high: 1.06145,
        low: 1.06045
      },
      {
        timestamp: 1651731000,
        open: 1.06099,
        close: 1.06129,
        high: 1.06162,
        low: 1.06098
      },
      {
        timestamp: 1651731300,
        open: 1.06128,
        close: 1.06173,
        high: 1.06182,
        low: 1.06119
      },
      {
        timestamp: 1651731600,
        open: 1.06174,
        close: 1.06159,
        high: 1.06182,
        low: 1.06136
      },
      {
        timestamp: 1651731900,
        open: 1.0616,
        close: 1.06038,
        high: 1.0616,
        low: 1.06017
      },
      {
        timestamp: 1651732200,
        open: 1.06037,
        close: 1.05976,
        high: 1.06062,
        low: 1.05972
      },
      {
        timestamp: 1651732500,
        open: 1.05976,
        close: 1.06004,
        high: 1.06028,
        low: 1.05946
      },
      {
        timestamp: 1651732800,
        open: 1.06003,
        close: 1.05953,
        high: 1.06017,
        low: 1.05951
      },
      {
        timestamp: 1651733100,
        open: 1.05953,
        close: 1.05969,
        high: 1.06003,
        low: 1.05944
      },
      {
        timestamp: 1651733400,
        open: 1.0597,
        close: 1.0597,
        high: 1.06005,
        low: 1.05959
      },
      {
        timestamp: 1651733700,
        open: 1.0597,
        close: 1.05954,
        high: 1.05998,
        low: 1.05949
      },
      {
        timestamp: 1651734000,
        open: 1.05955,
        close: 1.05995,
        high: 1.06002,
        low: 1.05917
      },
      {
        timestamp: 1651734300,
        open: 1.05995,
        close: 1.05985,
        high: 1.06024,
        low: 1.05939
      },
      {
        timestamp: 1651734600,
        open: 1.05987,
        close: 1.06036,
        high: 1.06074,
        low: 1.05984
      },
      {
        timestamp: 1651734900,
        open: 1.06037,
        close: 1.06028,
        high: 1.06046,
        low: 1.05977
      },
      {
        timestamp: 1651735200,
        open: 1.06029,
        close: 1.05946,
        high: 1.06042,
        low: 1.05946
      },
      {
        timestamp: 1651735500,
        open: 1.05947,
        close: 1.05861,
        high: 1.05948,
        low: 1.05853
      },
      {
        timestamp: 1651735800,
        open: 1.05861,
        close: 1.05924,
        high: 1.05936,
        low: 1.05854
      },
      {
        timestamp: 1651736100,
        open: 1.05923,
        close: 1.05918,
        high: 1.05996,
        low: 1.0591
      },
      {
        timestamp: 1651736400,
        open: 1.05918,
        close: 1.05965,
        high: 1.05967,
        low: 1.05918
      },
      {
        timestamp: 1651736700,
        open: 1.05965,
        close: 1.05984,
        high: 1.05993,
        low: 1.05917
      },
      {
        timestamp: 1651737000,
        open: 1.05984,
        close: 1.05982,
        high: 1.06007,
        low: 1.05958
      },
      {
        timestamp: 1651737300,
        open: 1.05981,
        close: 1.05984,
        high: 1.0602,
        low: 1.05972
      },
      {
        timestamp: 1651737600,
        open: 1.05985,
        close: 1.05913,
        high: 1.05989,
        low: 1.05868
      },
      {
        timestamp: 1651737900,
        open: 1.05912,
        close: 1.0587,
        high: 1.05933,
        low: 1.0585
      },
      {
        timestamp: 1651738200,
        open: 1.05871,
        close: 1.05868,
        high: 1.05893,
        low: 1.05842
      },
      {
        timestamp: 1651738500,
        open: 1.05868,
        close: 1.05859,
        high: 1.05938,
        low: 1.05852
      },
      {
        timestamp: 1651738800,
        open: 1.05859,
        close: 1.05878,
        high: 1.05908,
        low: 1.05856
      },
      {
        timestamp: 1651739100,
        open: 1.05879,
        close: 1.05893,
        high: 1.05902,
        low: 1.05862
      },
      {
        timestamp: 1651739400,
        open: 1.05893,
        close: 1.05877,
        high: 1.05926,
        low: 1.05877
      },
      {
        timestamp: 1651739700,
        open: 1.05877,
        close: 1.05892,
        high: 1.05947,
        low: 1.05873
      },
      {
        timestamp: 1651740000,
        open: 1.05892,
        close: 1.05872,
        high: 1.059,
        low: 1.05823
      },
      {
        timestamp: 1651740300,
        open: 1.05873,
        close: 1.05976,
        high: 1.05992,
        low: 1.05862
      },
      {
        timestamp: 1651740600,
        open: 1.05976,
        close: 1.05963,
        high: 1.0601,
        low: 1.05954
      },
      {
        timestamp: 1651740900,
        open: 1.05964,
        close: 1.0601,
        high: 1.06013,
        low: 1.05953
      },
      {
        timestamp: 1651741200,
        open: 1.06009,
        close: 1.05983,
        high: 1.06022,
        low: 1.05975
      },
      {
        timestamp: 1651741500,
        open: 1.05982,
        close: 1.05998,
        high: 1.06011,
        low: 1.0596
      },
      {
        timestamp: 1651741800,
        open: 1.05996,
        close: 1.05992,
        high: 1.06028,
        low: 1.05976
      },
      {
        timestamp: 1651742100,
        open: 1.05992,
        close: 1.05991,
        high: 1.06013,
        low: 1.05978
      },
      {
        timestamp: 1651742400,
        open: 1.05991,
        close: 1.06003,
        high: 1.06038,
        low: 1.0599
      },
      {
        timestamp: 1651742700,
        open: 1.06003,
        close: 1.05973,
        high: 1.06021,
        low: 1.05957
      },
      {
        timestamp: 1651743000,
        open: 1.05974,
        close: 1.06007,
        high: 1.06008,
        low: 1.05948
      },
      {
        timestamp: 1651743300,
        open: 1.06007,
        close: 1.05981,
        high: 1.0602,
        low: 1.05975
      },
      {
        timestamp: 1651743600,
        open: 1.05982,
        close: 1.05973,
        high: 1.05998,
        low: 1.05957
      },
      {
        timestamp: 1651743900,
        open: 1.05973,
        close: 1.05935,
        high: 1.05974,
        low: 1.05927
      },
      {
        timestamp: 1651744200,
        open: 1.05937,
        close: 1.05983,
        high: 1.06013,
        low: 1.05937
      },
      {
        timestamp: 1651744500,
        open: 1.05982,
        close: 1.05984,
        high: 1.05989,
        low: 1.05958
      },
      {
        timestamp: 1651744800,
        open: 1.05983,
        close: 1.06036,
        high: 1.06061,
        low: 1.05959
      },
      {
        timestamp: 1651745100,
        open: 1.06037,
        close: 1.06036,
        high: 1.0606,
        low: 1.06023
      },
      {
        timestamp: 1651745400,
        open: 1.06036,
        close: 1.06064,
        high: 1.06079,
        low: 1.06022
      },
      {
        timestamp: 1651745700,
        open: 1.06064,
        close: 1.06008,
        high: 1.06084,
        low: 1.06003
      },
      {
        timestamp: 1651746000,
        open: 1.06009,
        close: 1.05999,
        high: 1.06016,
        low: 1.05982
      },
      {
        timestamp: 1651746300,
        open: 1.06,
        close: 1.06032,
        high: 1.06043,
        low: 1.06
      },
      {
        timestamp: 1651746600,
        open: 1.06033,
        close: 1.06041,
        high: 1.06041,
        low: 1.06003
      },
      {
        timestamp: 1651746900,
        open: 1.06043,
        close: 1.05991,
        high: 1.06043,
        low: 1.05973
      },
      {
        timestamp: 1651747200,
        open: 1.05991,
        close: 1.05936,
        high: 1.05995,
        low: 1.05927
      },
      {
        timestamp: 1651747500,
        open: 1.05936,
        close: 1.05917,
        high: 1.05956,
        low: 1.05917
      },
      {
        timestamp: 1651747800,
        open: 1.05917,
        close: 1.05913,
        high: 1.0593,
        low: 1.05874
      },
      {
        timestamp: 1651748100,
        open: 1.05914,
        close: 1.05982,
        high: 1.05988,
        low: 1.05899
      },
      {
        timestamp: 1651748400,
        open: 1.0598,
        close: 1.05916,
        high: 1.06043,
        low: 1.05871
      },
      {
        timestamp: 1651748700,
        open: 1.05915,
        close: 1.0588,
        high: 1.05957,
        low: 1.05848
      },
      {
        timestamp: 1651749000,
        open: 1.05879,
        close: 1.05813,
        high: 1.05937,
        low: 1.05812
      },
      {
        timestamp: 1651749300,
        open: 1.05812,
        close: 1.05809,
        high: 1.05884,
        low: 1.05806
      },
      {
        timestamp: 1651749600,
        open: 1.05809,
        close: 1.05715,
        high: 1.0585,
        low: 1.05668
      },
      {
        timestamp: 1651749900,
        open: 1.05714,
        close: 1.05595,
        high: 1.0572,
        low: 1.05538
      },
      {
        timestamp: 1651750200,
        open: 1.05595,
        close: 1.05638,
        high: 1.05657,
        low: 1.0556
      },
      {
        timestamp: 1651750500,
        open: 1.05639,
        close: 1.05687,
        high: 1.05696,
        low: 1.056
      },
      {
        timestamp: 1651750800,
        open: 1.05687,
        close: 1.05568,
        high: 1.0569,
        low: 1.05568
      },
      {
        timestamp: 1651751100,
        open: 1.05568,
        close: 1.05561,
        high: 1.05601,
        low: 1.05521
      },
      {
        timestamp: 1651751400,
        open: 1.0556,
        close: 1.05469,
        high: 1.05577,
        low: 1.05465
      },
      {
        timestamp: 1651751700,
        open: 1.05469,
        close: 1.05485,
        high: 1.05521,
        low: 1.05462
      },
      {
        timestamp: 1651752000,
        open: 1.05484,
        close: 1.05591,
        high: 1.05591,
        low: 1.05482
      },
      {
        timestamp: 1651752300,
        open: 1.0559,
        close: 1.05667,
        high: 1.05688,
        low: 1.05584
      },
      {
        timestamp: 1651752600,
        open: 1.05668,
        close: 1.05681,
        high: 1.05703,
        low: 1.05625
      },
      {
        timestamp: 1651752900,
        open: 1.05682,
        close: 1.05688,
        high: 1.05688,
        low: 1.05625
      },
      {
        timestamp: 1651753200,
        open: 1.05688,
        close: 1.05635,
        high: 1.05744,
        low: 1.05629
      },
      {
        timestamp: 1651753500,
        open: 1.05633,
        close: 1.05662,
        high: 1.05664,
        low: 1.05605
      },
      {
        timestamp: 1651753800,
        open: 1.05663,
        close: 1.05602,
        high: 1.05691,
        low: 1.05597
      },
      {
        timestamp: 1651754100,
        open: 1.05602,
        close: 1.05582,
        high: 1.05619,
        low: 1.05559
      },
      {
        timestamp: 1651754400,
        open: 1.05582,
        close: 1.05544,
        high: 1.05606,
        low: 1.05527
      },
      {
        timestamp: 1651754700,
        open: 1.05544,
        close: 1.05566,
        high: 1.0557,
        low: 1.05501
      },
      {
        timestamp: 1651755000,
        open: 1.05567,
        close: 1.05521,
        high: 1.05568,
        low: 1.05512
      },
      {
        timestamp: 1651755300,
        open: 1.05521,
        close: 1.0554,
        high: 1.05559,
        low: 1.05495
      },
      {
        timestamp: 1651755600,
        open: 1.0554,
        close: 1.05513,
        high: 1.05568,
        low: 1.05478
      },
      {
        timestamp: 1651755900,
        open: 1.05513,
        close: 1.05526,
        high: 1.05566,
        low: 1.0551
      },
      {
        timestamp: 1651756200,
        open: 1.05525,
        close: 1.0553,
        high: 1.05571,
        low: 1.05514
      },
      {
        timestamp: 1651756500,
        open: 1.0553,
        close: 1.05571,
        high: 1.05621,
        low: 1.05527
      },
      {
        timestamp: 1651756800,
        open: 1.05572,
        close: 1.05631,
        high: 1.05635,
        low: 1.05555
      },
      {
        timestamp: 1651757100,
        open: 1.0563,
        close: 1.05609,
        high: 1.05666,
        low: 1.05603
      },
      {
        timestamp: 1651757400,
        open: 1.05611,
        close: 1.05499,
        high: 1.05628,
        low: 1.05475
      },
      {
        timestamp: 1651757700,
        open: 1.05499,
        close: 1.05543,
        high: 1.05581,
        low: 1.05498
      },
      {
        timestamp: 1651758000,
        open: 1.05544,
        close: 1.0555,
        high: 1.0562,
        low: 1.05544
      },
      {
        timestamp: 1651758300,
        open: 1.05551,
        close: 1.05478,
        high: 1.05565,
        low: 1.05461
      },
      {
        timestamp: 1651758600,
        open: 1.05478,
        close: 1.05501,
        high: 1.05502,
        low: 1.05427
      },
      {
        timestamp: 1651758900,
        open: 1.05503,
        close: 1.05495,
        high: 1.05552,
        low: 1.05478
      },
      {
        timestamp: 1651759200,
        open: 1.05492,
        close: 1.05537,
        high: 1.05572,
        low: 1.05491
      },
      {
        timestamp: 1651759500,
        open: 1.05536,
        close: 1.05433,
        high: 1.05556,
        low: 1.05423
      },
      {
        timestamp: 1651759800,
        open: 1.05433,
        close: 1.05459,
        high: 1.05532,
        low: 1.05428
      },
      {
        timestamp: 1651760100,
        open: 1.05458,
        close: 1.05431,
        high: 1.0551,
        low: 1.05421
      },
      {
        timestamp: 1651760400,
        open: 1.0543,
        close: 1.05489,
        high: 1.055,
        low: 1.05368
      },
      {
        timestamp: 1651760700,
        open: 1.05491,
        close: 1.05485,
        high: 1.05533,
        low: 1.05461
      },
      {
        timestamp: 1651761000,
        open: 1.05484,
        close: 1.05477,
        high: 1.05555,
        low: 1.05464
      },
      {
        timestamp: 1651761300,
        open: 1.05478,
        close: 1.05468,
        high: 1.05521,
        low: 1.05433
      },
      {
        timestamp: 1651761600,
        open: 1.05468,
        close: 1.05431,
        high: 1.05481,
        low: 1.05407
      },
      {
        timestamp: 1651761900,
        open: 1.05431,
        close: 1.05345,
        high: 1.05462,
        low: 1.05326
      },
      {
        timestamp: 1651762200,
        open: 1.05344,
        close: 1.05362,
        high: 1.05405,
        low: 1.05323
      },
      {
        timestamp: 1651762500,
        open: 1.05362,
        close: 1.05323,
        high: 1.05365,
        low: 1.05299
      },
      {
        timestamp: 1651762800,
        open: 1.05324,
        close: 1.05156,
        high: 1.05345,
        low: 1.05145
      },
      {
        timestamp: 1651763100,
        open: 1.05157,
        close: 1.05082,
        high: 1.052,
        low: 1.05071
      },
      {
        timestamp: 1651763400,
        open: 1.05082,
        close: 1.0514,
        high: 1.05141,
        low: 1.0506
      },
      {
        timestamp: 1651763700,
        open: 1.05141,
        close: 1.05157,
        high: 1.05201,
        low: 1.05114
      },
      {
        timestamp: 1651764000,
        open: 1.05158,
        close: 1.05062,
        high: 1.05173,
        low: 1.05061
      },
      {
        timestamp: 1651764300,
        open: 1.05062,
        close: 1.05057,
        high: 1.05094,
        low: 1.05041
      },
      {
        timestamp: 1651764600,
        open: 1.05057,
        close: 1.05122,
        high: 1.05172,
        low: 1.0504
      },
      {
        timestamp: 1651764900,
        open: 1.05121,
        close: 1.05136,
        high: 1.05164,
        low: 1.05107
      },
      {
        timestamp: 1651765200,
        open: 1.05136,
        close: 1.05189,
        high: 1.052,
        low: 1.05136
      },
      {
        timestamp: 1651765500,
        open: 1.05187,
        close: 1.0519,
        high: 1.05192,
        low: 1.05136
      },
      {
        timestamp: 1651765800,
        open: 1.05189,
        close: 1.05095,
        high: 1.05194,
        low: 1.05086
      },
      {
        timestamp: 1651766100,
        open: 1.05096,
        close: 1.05074,
        high: 1.051,
        low: 1.0507
      },
      {
        timestamp: 1651766400,
        open: 1.05075,
        close: 1.05013,
        high: 1.05076,
        low: 1.04985
      },
      {
        timestamp: 1651766700,
        open: 1.05011,
        close: 1.04979,
        high: 1.05024,
        low: 1.04964
      },
      {
        timestamp: 1651767000,
        open: 1.04979,
        close: 1.05015,
        high: 1.0505,
        low: 1.04948
      },
      {
        timestamp: 1651767300,
        open: 1.05016,
        close: 1.05041,
        high: 1.05047,
        low: 1.04985
      },
      {
        timestamp: 1651767600,
        open: 1.0504,
        close: 1.04959,
        high: 1.05056,
        low: 1.04932
      },
      {
        timestamp: 1651767900,
        open: 1.04958,
        close: 1.05096,
        high: 1.05099,
        low: 1.04955
      },
      {
        timestamp: 1651768200,
        open: 1.05095,
        close: 1.05122,
        high: 1.05149,
        low: 1.05061
      },
      {
        timestamp: 1651768500,
        open: 1.05123,
        close: 1.0511,
        high: 1.05124,
        low: 1.05078
      },
      {
        timestamp: 1651768800,
        open: 1.05109,
        close: 1.05134,
        high: 1.05138,
        low: 1.05098
      },
      {
        timestamp: 1651769100,
        open: 1.05135,
        close: 1.05085,
        high: 1.05154,
        low: 1.05085
      },
      {
        timestamp: 1651769400,
        open: 1.05086,
        close: 1.05126,
        high: 1.05159,
        low: 1.05078
      },
      {
        timestamp: 1651769700,
        open: 1.05125,
        close: 1.05116,
        high: 1.05151,
        low: 1.05102
      },
      {
        timestamp: 1651770000,
        open: 1.05116,
        close: 1.05243,
        high: 1.05284,
        low: 1.05111
      },
      {
        timestamp: 1651770300,
        open: 1.05242,
        close: 1.05251,
        high: 1.0529,
        low: 1.05233
      },
      {
        timestamp: 1651770600,
        open: 1.05251,
        close: 1.05173,
        high: 1.05251,
        low: 1.05165
      },
      {
        timestamp: 1651770900,
        open: 1.05172,
        close: 1.05169,
        high: 1.05206,
        low: 1.05145
      },
      {
        timestamp: 1651771200,
        open: 1.05171,
        close: 1.05131,
        high: 1.05174,
        low: 1.05129
      },
      {
        timestamp: 1651771500,
        open: 1.05132,
        close: 1.05097,
        high: 1.05137,
        low: 1.05078
      },
      {
        timestamp: 1651771800,
        open: 1.05098,
        close: 1.05114,
        high: 1.05119,
        low: 1.05057
      },
      {
        timestamp: 1651772100,
        open: 1.05114,
        close: 1.05111,
        high: 1.05159,
        low: 1.05092
      },
      {
        timestamp: 1651772400,
        open: 1.05111,
        close: 1.05121,
        high: 1.0515,
        low: 1.05094
      },
      {
        timestamp: 1651772700,
        open: 1.05122,
        close: 1.05131,
        high: 1.05162,
        low: 1.05112
      },
      {
        timestamp: 1651773000,
        open: 1.05131,
        close: 1.05087,
        high: 1.05133,
        low: 1.05055
      },
      {
        timestamp: 1651773300,
        open: 1.05087,
        close: 1.05047,
        high: 1.05089,
        low: 1.05041
      },
      {
        timestamp: 1651773600,
        open: 1.05047,
        close: 1.05049,
        high: 1.05068,
        low: 1.0503
      },
      {
        timestamp: 1651773900,
        open: 1.0505,
        close: 1.05076,
        high: 1.05077,
        low: 1.05035
      },
      {
        timestamp: 1651774200,
        open: 1.05076,
        close: 1.05118,
        high: 1.05122,
        low: 1.05069
      },
      {
        timestamp: 1651774500,
        open: 1.05116,
        close: 1.05162,
        high: 1.05172,
        low: 1.05107
      },
      {
        timestamp: 1651774800,
        open: 1.05163,
        close: 1.05117,
        high: 1.05177,
        low: 1.05108
      },
      {
        timestamp: 1651775100,
        open: 1.05116,
        close: 1.05092,
        high: 1.05138,
        low: 1.05092
      },
      {
        timestamp: 1651775400,
        open: 1.05092,
        close: 1.05082,
        high: 1.05106,
        low: 1.05071
      },
      {
        timestamp: 1651775700,
        open: 1.05081,
        close: 1.05124,
        high: 1.05132,
        low: 1.05076
      },
      {
        timestamp: 1651776000,
        open: 1.05124,
        close: 1.05119,
        high: 1.05125,
        low: 1.05101
      },
      {
        timestamp: 1651776300,
        open: 1.05119,
        close: 1.05147,
        high: 1.05161,
        low: 1.05111
      },
      {
        timestamp: 1651776600,
        open: 1.05146,
        close: 1.05199,
        high: 1.05214,
        low: 1.05139
      },
      {
        timestamp: 1651776900,
        open: 1.052,
        close: 1.05168,
        high: 1.05206,
        low: 1.05152
      },
      {
        timestamp: 1651777200,
        open: 1.05169,
        close: 1.05204,
        high: 1.05246,
        low: 1.05169
      },
      {
        timestamp: 1651777500,
        open: 1.05208,
        close: 1.05261,
        high: 1.05269,
        low: 1.05208
      },
      {
        timestamp: 1651777800,
        open: 1.05262,
        close: 1.05253,
        high: 1.05277,
        low: 1.05221
      },
      {
        timestamp: 1651778100,
        open: 1.05255,
        close: 1.05339,
        high: 1.0534,
        low: 1.05252
      },
      {
        timestamp: 1651778400,
        open: 1.05339,
        close: 1.05363,
        high: 1.05378,
        low: 1.05328
      },
      {
        timestamp: 1651778700,
        open: 1.05363,
        close: 1.05371,
        high: 1.05395,
        low: 1.05334
      },
      {
        timestamp: 1651779000,
        open: 1.05371,
        close: 1.05433,
        high: 1.05451,
        low: 1.0533
      },
      {
        timestamp: 1651779300,
        open: 1.05434,
        close: 1.05494,
        high: 1.05495,
        low: 1.05434
      },
      {
        timestamp: 1651779600,
        open: 1.05495,
        close: 1.05503,
        high: 1.05508,
        low: 1.05468
      },
      {
        timestamp: 1651779900,
        open: 1.05504,
        close: 1.05435,
        high: 1.05505,
        low: 1.05425
      },
      {
        timestamp: 1651780200,
        open: 1.05435,
        close: 1.0549,
        high: 1.05505,
        low: 1.05433
      },
      {
        timestamp: 1651780500,
        open: 1.0549,
        close: 1.05512,
        high: 1.05515,
        low: 1.05487
      },
      {
        timestamp: 1651780800,
        open: 1.0551,
        close: 1.05482,
        high: 1.0551,
        low: 1.0546
      },
      {
        timestamp: 1651781100,
        open: 1.05482,
        close: 1.05493,
        high: 1.05494,
        low: 1.05465
      },
      {
        timestamp: 1651781400,
        open: 1.05494,
        close: 1.0549,
        high: 1.05503,
        low: 1.05484
      },
      {
        timestamp: 1651781700,
        open: 1.0549,
        close: 1.05456,
        high: 1.05493,
        low: 1.05454
      },
      {
        timestamp: 1651782000,
        open: 1.05456,
        close: 1.05487,
        high: 1.0549,
        low: 1.05456
      },
      {
        timestamp: 1651782300,
        open: 1.05486,
        close: 1.0545,
        high: 1.05488,
        low: 1.05441
      },
      {
        timestamp: 1651782600,
        open: 1.05448,
        close: 1.05417,
        high: 1.0545,
        low: 1.05404
      },
      {
        timestamp: 1651782900,
        open: 1.05416,
        close: 1.05405,
        high: 1.05418,
        low: 1.05384
      },
      {
        timestamp: 1651783200,
        open: 1.05405,
        close: 1.05445,
        high: 1.05446,
        low: 1.05387
      },
      {
        timestamp: 1651783500,
        open: 1.05445,
        close: 1.05422,
        high: 1.05445,
        low: 1.05421
      },
      {
        timestamp: 1651783800,
        open: 1.05422,
        close: 1.05421,
        high: 1.05428,
        low: 1.05414
      },
      {
        timestamp: 1651784100,
        open: 1.05421,
        close: 1.05464,
        high: 1.05464,
        low: 1.05395
      },
      {
        timestamp: 1651784400,
        open: 1.05465,
        close: 1.05408,
        high: 1.05469,
        low: 1.05405
      },
      {
        timestamp: 1651784700,
        open: 1.05413,
        close: 1.05498,
        high: 1.05498,
        low: 1.05408
      },
      {
        timestamp: 1651785000,
        open: 1.0549,
        close: 1.05482,
        high: 1.0549,
        low: 1.05475
      },
      {
        timestamp: 1651785300,
        open: 1.05485,
        close: 1.05483,
        high: 1.05486,
        low: 1.05475
      },
      {
        timestamp: 1651785600,
        open: 1.05483,
        close: 1.05486,
        high: 1.05486,
        low: 1.05483
      },
      {
        timestamp: 1651785900,
        open: 1.05487,
        close: 1.0546,
        high: 1.05488,
        low: 1.05452
      },
      {
        timestamp: 1651786200,
        open: 1.05458,
        close: 1.05428,
        high: 1.05459,
        low: 1.05428
      },
      {
        timestamp: 1651786500,
        open: 1.05429,
        close: 1.05433,
        high: 1.05435,
        low: 1.05428
      },
      {
        timestamp: 1651786800,
        open: 1.05429,
        close: 1.05433,
        high: 1.05437,
        low: 1.05429
      },
      {
        timestamp: 1651787100,
        open: 1.05433,
        close: 1.05437,
        high: 1.05438,
        low: 1.0543
      },
      {
        timestamp: 1651787400,
        open: 1.05436,
        close: 1.05439,
        high: 1.0544,
        low: 1.05436
      },
      {
        timestamp: 1651787700,
        open: 1.05455,
        close: 1.05427,
        high: 1.05459,
        low: 1.05426
      },
      {
        timestamp: 1651788000,
        open: 1.05427,
        close: 1.05426,
        high: 1.05449,
        low: 1.05412
      },
      {
        timestamp: 1651788300,
        open: 1.05427,
        close: 1.0543,
        high: 1.0545,
        low: 1.05424
      },
      {
        timestamp: 1651788600,
        open: 1.05429,
        close: 1.05452,
        high: 1.05458,
        low: 1.05415
      },
      {
        timestamp: 1651788900,
        open: 1.05453,
        close: 1.05436,
        high: 1.05465,
        low: 1.05434
      },
      {
        timestamp: 1651789200,
        open: 1.05437,
        close: 1.05438,
        high: 1.05447,
        low: 1.05397
      },
      {
        timestamp: 1651789500,
        open: 1.05438,
        close: 1.05431,
        high: 1.05438,
        low: 1.05425
      },
      {
        timestamp: 1651789800,
        open: 1.05431,
        close: 1.0547,
        high: 1.0547,
        low: 1.05421
      },
      {
        timestamp: 1651790100,
        open: 1.0547,
        close: 1.05474,
        high: 1.05482,
        low: 1.05467
      },
      {
        timestamp: 1651790400,
        open: 1.05471,
        close: 1.05465,
        high: 1.05476,
        low: 1.05454
      },
      {
        timestamp: 1651790700,
        open: 1.05465,
        close: 1.05471,
        high: 1.05471,
        low: 1.05452
      },
      {
        timestamp: 1651791000,
        open: 1.05471,
        close: 1.05471,
        high: 1.05481,
        low: 1.05465
      },
      {
        timestamp: 1651791300,
        open: 1.05471,
        close: 1.05512,
        high: 1.05512,
        low: 1.05467
      },
      {
        timestamp: 1651791600,
        open: 1.05511,
        close: 1.05458,
        high: 1.05511,
        low: 1.05451
      },
      {
        timestamp: 1651791900,
        open: 1.05457,
        close: 1.05421,
        high: 1.05463,
        low: 1.05416
      },
      {
        timestamp: 1651792200,
        open: 1.0542,
        close: 1.05429,
        high: 1.05429,
        low: 1.05395
      },
      {
        timestamp: 1651792500,
        open: 1.05428,
        close: 1.05406,
        high: 1.05445,
        low: 1.05403
      },
      {
        timestamp: 1651792800,
        open: 1.05407,
        close: 1.05405,
        high: 1.0542,
        low: 1.05402
      },
      {
        timestamp: 1651793100,
        open: 1.05406,
        close: 1.05399,
        high: 1.0541,
        low: 1.05363
      },
      {
        timestamp: 1651793400,
        open: 1.05398,
        close: 1.05384,
        high: 1.0541,
        low: 1.05382
      },
      {
        timestamp: 1651793700,
        open: 1.05385,
        close: 1.05375,
        high: 1.05391,
        low: 1.0537
      },
      {
        timestamp: 1651794000,
        open: 1.05375,
        close: 1.05411,
        high: 1.05416,
        low: 1.0537
      },
      {
        timestamp: 1651794300,
        open: 1.05412,
        close: 1.05401,
        high: 1.05412,
        low: 1.0539
      },
      {
        timestamp: 1651794600,
        open: 1.054,
        close: 1.05429,
        high: 1.05439,
        low: 1.05398
      },
      {
        timestamp: 1651794900,
        open: 1.05429,
        close: 1.05403,
        high: 1.05436,
        low: 1.05392
      },
      {
        timestamp: 1651795200,
        open: 1.05404,
        close: 1.05409,
        high: 1.05424,
        low: 1.05384
      },
      {
        timestamp: 1651795500,
        open: 1.05409,
        close: 1.05374,
        high: 1.0541,
        low: 1.05374
      },
      {
        timestamp: 1651795800,
        open: 1.05375,
        close: 1.05359,
        high: 1.05379,
        low: 1.05345
      },
      {
        timestamp: 1651796100,
        open: 1.0536,
        close: 1.05464,
        high: 1.05466,
        low: 1.05358
      },
      {
        timestamp: 1651796400,
        open: 1.05463,
        close: 1.05484,
        high: 1.05514,
        low: 1.05449
      },
      {
        timestamp: 1651796700,
        open: 1.05485,
        close: 1.05441,
        high: 1.05496,
        low: 1.0544
      },
      {
        timestamp: 1651797000,
        open: 1.0544,
        close: 1.05409,
        high: 1.05442,
        low: 1.05395
      },
      {
        timestamp: 1651797300,
        open: 1.05408,
        close: 1.05409,
        high: 1.05422,
        low: 1.05389
      },
      {
        timestamp: 1651797600,
        open: 1.05408,
        close: 1.05436,
        high: 1.05444,
        low: 1.05399
      },
      {
        timestamp: 1651797900,
        open: 1.05435,
        close: 1.05427,
        high: 1.05453,
        low: 1.05409
      },
      {
        timestamp: 1651798200,
        open: 1.05426,
        close: 1.05468,
        high: 1.05468,
        low: 1.05405
      },
      {
        timestamp: 1651798500,
        open: 1.05467,
        close: 1.05418,
        high: 1.05471,
        low: 1.05387
      },
      {
        timestamp: 1651798800,
        open: 1.05419,
        close: 1.05409,
        high: 1.05437,
        low: 1.05371
      },
      {
        timestamp: 1651799100,
        open: 1.05409,
        close: 1.0546,
        high: 1.05462,
        low: 1.05408
      },
      {
        timestamp: 1651799400,
        open: 1.0546,
        close: 1.05438,
        high: 1.05462,
        low: 1.05421
      },
      {
        timestamp: 1651799700,
        open: 1.05438,
        close: 1.05381,
        high: 1.0544,
        low: 1.05379
      },
      {
        timestamp: 1651800000,
        open: 1.05382,
        close: 1.05303,
        high: 1.05383,
        low: 1.05255
      },
      {
        timestamp: 1651800300,
        open: 1.05303,
        close: 1.0533,
        high: 1.05355,
        low: 1.05295
      },
      {
        timestamp: 1651800600,
        open: 1.0533,
        close: 1.05245,
        high: 1.05355,
        low: 1.05239
      },
      {
        timestamp: 1651800900,
        open: 1.05244,
        close: 1.05246,
        high: 1.05295,
        low: 1.0523
      },
      {
        timestamp: 1651801200,
        open: 1.05245,
        close: 1.05251,
        high: 1.05263,
        low: 1.05219
      },
      {
        timestamp: 1651801500,
        open: 1.0525,
        close: 1.05184,
        high: 1.05258,
        low: 1.05165
      },
      {
        timestamp: 1651801800,
        open: 1.05184,
        close: 1.05282,
        high: 1.05302,
        low: 1.05184
      },
      {
        timestamp: 1651802100,
        open: 1.05281,
        close: 1.05265,
        high: 1.05294,
        low: 1.05226
      },
      {
        timestamp: 1651802400,
        open: 1.05266,
        close: 1.05269,
        high: 1.05309,
        low: 1.05261
      },
      {
        timestamp: 1651802700,
        open: 1.05269,
        close: 1.05235,
        high: 1.05276,
        low: 1.05203
      },
      {
        timestamp: 1651803000,
        open: 1.05236,
        close: 1.05236,
        high: 1.05246,
        low: 1.0521
      },
      {
        timestamp: 1651803300,
        open: 1.05235,
        close: 1.05269,
        high: 1.0527,
        low: 1.05211
      },
      {
        timestamp: 1651803600,
        open: 1.0527,
        close: 1.05244,
        high: 1.0527,
        low: 1.05231
      },
      {
        timestamp: 1651803900,
        open: 1.05243,
        close: 1.0527,
        high: 1.05306,
        low: 1.05242
      },
      {
        timestamp: 1651804200,
        open: 1.05271,
        close: 1.05276,
        high: 1.05298,
        low: 1.05253
      },
      {
        timestamp: 1651804500,
        open: 1.05277,
        close: 1.05276,
        high: 1.05282,
        low: 1.0525
      },
      {
        timestamp: 1651804800,
        open: 1.05275,
        close: 1.05276,
        high: 1.05278,
        low: 1.05247
      },
      {
        timestamp: 1651805100,
        open: 1.05275,
        close: 1.05286,
        high: 1.0529,
        low: 1.0524
      },
      {
        timestamp: 1651805400,
        open: 1.05287,
        close: 1.05323,
        high: 1.05324,
        low: 1.05283
      },
      {
        timestamp: 1651805700,
        open: 1.05324,
        close: 1.05295,
        high: 1.05347,
        low: 1.05281
      },
      {
        timestamp: 1651806000,
        open: 1.05295,
        close: 1.05302,
        high: 1.05316,
        low: 1.05273
      },
      {
        timestamp: 1651806300,
        open: 1.05303,
        close: 1.05329,
        high: 1.05344,
        low: 1.053
      },
      {
        timestamp: 1651806600,
        open: 1.05331,
        close: 1.05351,
        high: 1.05359,
        low: 1.05325
      },
      {
        timestamp: 1651806900,
        open: 1.0535,
        close: 1.05339,
        high: 1.05353,
        low: 1.05327
      },
      {
        timestamp: 1651807200,
        open: 1.0534,
        close: 1.05374,
        high: 1.0538,
        low: 1.05334
      },
      {
        timestamp: 1651807500,
        open: 1.05374,
        close: 1.05358,
        high: 1.05376,
        low: 1.05349
      },
      {
        timestamp: 1651807800,
        open: 1.05358,
        close: 1.0537,
        high: 1.05381,
        low: 1.05358
      },
      {
        timestamp: 1651808100,
        open: 1.05369,
        close: 1.0539,
        high: 1.05403,
        low: 1.05366
      },
      {
        timestamp: 1651808400,
        open: 1.05389,
        close: 1.05371,
        high: 1.05389,
        low: 1.05365
      },
      {
        timestamp: 1651808700,
        open: 1.05371,
        close: 1.05383,
        high: 1.05394,
        low: 1.05371
      },
      {
        timestamp: 1651809000,
        open: 1.05383,
        close: 1.05357,
        high: 1.05392,
        low: 1.05353
      },
      {
        timestamp: 1651809300,
        open: 1.05358,
        close: 1.05338,
        high: 1.05366,
        low: 1.05334
      },
      {
        timestamp: 1651809600,
        open: 1.05337,
        close: 1.05347,
        high: 1.05355,
        low: 1.05337
      },
      {
        timestamp: 1651809900,
        open: 1.05347,
        close: 1.05316,
        high: 1.05352,
        low: 1.05306
      },
      {
        timestamp: 1651810200,
        open: 1.05317,
        close: 1.05287,
        high: 1.05324,
        low: 1.05285
      },
      {
        timestamp: 1651810500,
        open: 1.05287,
        close: 1.05286,
        high: 1.05289,
        low: 1.05266
      },
      {
        timestamp: 1651810800,
        open: 1.05287,
        close: 1.05293,
        high: 1.05295,
        low: 1.0527
      },
      {
        timestamp: 1651811100,
        open: 1.05293,
        close: 1.05284,
        high: 1.05293,
        low: 1.0527
      },
      {
        timestamp: 1651811400,
        open: 1.05284,
        close: 1.05306,
        high: 1.05313,
        low: 1.05269
      },
      {
        timestamp: 1651811700,
        open: 1.05307,
        close: 1.05305,
        high: 1.05318,
        low: 1.05295
      },
      {
        timestamp: 1651812000,
        open: 1.05304,
        close: 1.05306,
        high: 1.05315,
        low: 1.05297
      },
      {
        timestamp: 1651812300,
        open: 1.05305,
        close: 1.05287,
        high: 1.05309,
        low: 1.05286
      },
      {
        timestamp: 1651812600,
        open: 1.05286,
        close: 1.05306,
        high: 1.05309,
        low: 1.05277
      },
      {
        timestamp: 1651812900,
        open: 1.05306,
        close: 1.05329,
        high: 1.05352,
        low: 1.05304
      },
      {
        timestamp: 1651813200,
        open: 1.0533,
        close: 1.05314,
        high: 1.05334,
        low: 1.053
      },
      {
        timestamp: 1651813500,
        open: 1.05315,
        close: 1.0529,
        high: 1.05331,
        low: 1.05285
      },
      {
        timestamp: 1651813800,
        open: 1.05291,
        close: 1.05303,
        high: 1.05308,
        low: 1.05283
      },
      {
        timestamp: 1651814100,
        open: 1.05303,
        close: 1.0531,
        high: 1.05326,
        low: 1.05287
      },
      {
        timestamp: 1651814400,
        open: 1.0531,
        close: 1.05307,
        high: 1.05321,
        low: 1.05296
      },
      {
        timestamp: 1651814700,
        open: 1.05307,
        close: 1.05345,
        high: 1.05346,
        low: 1.05298
      },
      {
        timestamp: 1651815000,
        open: 1.05346,
        close: 1.05303,
        high: 1.05346,
        low: 1.05302
      },
      {
        timestamp: 1651815300,
        open: 1.05303,
        close: 1.05295,
        high: 1.05306,
        low: 1.05283
      },
      {
        timestamp: 1651815600,
        open: 1.05295,
        close: 1.0527,
        high: 1.05303,
        low: 1.05257
      },
      {
        timestamp: 1651815900,
        open: 1.0527,
        close: 1.05241,
        high: 1.0527,
        low: 1.05238
      },
      {
        timestamp: 1651816200,
        open: 1.05242,
        close: 1.05232,
        high: 1.05249,
        low: 1.05221
      },
      {
        timestamp: 1651816500,
        open: 1.05231,
        close: 1.05234,
        high: 1.05244,
        low: 1.05222
      },
      {
        timestamp: 1651816800,
        open: 1.05235,
        close: 1.05259,
        high: 1.05261,
        low: 1.0519
      },
      {
        timestamp: 1651817100,
        open: 1.05258,
        close: 1.05175,
        high: 1.05259,
        low: 1.05159
      },
      {
        timestamp: 1651817400,
        open: 1.05176,
        close: 1.0518,
        high: 1.05196,
        low: 1.05164
      },
      {
        timestamp: 1651817700,
        open: 1.05181,
        close: 1.05138,
        high: 1.05185,
        low: 1.05126
      },
      {
        timestamp: 1651818000,
        open: 1.05137,
        close: 1.05209,
        high: 1.05231,
        low: 1.05135
      },
      {
        timestamp: 1651818300,
        open: 1.0521,
        close: 1.051,
        high: 1.0521,
        low: 1.05095
      },
      {
        timestamp: 1651818600,
        open: 1.051,
        close: 1.05176,
        high: 1.05194,
        low: 1.05093
      },
      {
        timestamp: 1651818900,
        open: 1.05175,
        close: 1.05182,
        high: 1.05204,
        low: 1.05161
      },
      {
        timestamp: 1651819200,
        open: 1.05181,
        close: 1.0517,
        high: 1.05183,
        low: 1.0515
      },
      {
        timestamp: 1651819500,
        open: 1.05171,
        close: 1.05159,
        high: 1.05203,
        low: 1.05158
      },
      {
        timestamp: 1651819800,
        open: 1.05158,
        close: 1.04968,
        high: 1.05174,
        low: 1.04964
      },
      {
        timestamp: 1651820100,
        open: 1.04969,
        close: 1.04869,
        high: 1.0501,
        low: 1.04837
      },
      {
        timestamp: 1651820400,
        open: 1.0487,
        close: 1.04947,
        high: 1.04947,
        low: 1.04869
      },
      {
        timestamp: 1651820700,
        open: 1.04948,
        close: 1.04911,
        high: 1.05004,
        low: 1.049
      },
      {
        timestamp: 1651821000,
        open: 1.0491,
        close: 1.04976,
        high: 1.04982,
        low: 1.04836
      },
      {
        timestamp: 1651821300,
        open: 1.04975,
        close: 1.05008,
        high: 1.05033,
        low: 1.04953
      },
      {
        timestamp: 1651821600,
        open: 1.05007,
        close: 1.05068,
        high: 1.05077,
        low: 1.04952
      },
      {
        timestamp: 1651821900,
        open: 1.05069,
        close: 1.05074,
        high: 1.05125,
        low: 1.05044
      },
      {
        timestamp: 1651822200,
        open: 1.05075,
        close: 1.04983,
        high: 1.05076,
        low: 1.04981
      },
      {
        timestamp: 1651822500,
        open: 1.04986,
        close: 1.05001,
        high: 1.0506,
        low: 1.04971
      },
      {
        timestamp: 1651822800,
        open: 1.05002,
        close: 1.05007,
        high: 1.05028,
        low: 1.04964
      },
      {
        timestamp: 1651823100,
        open: 1.05007,
        close: 1.05014,
        high: 1.0504,
        low: 1.04963
      },
      {
        timestamp: 1651823400,
        open: 1.05015,
        close: 1.05251,
        high: 1.05251,
        low: 1.05
      },
      {
        timestamp: 1651823700,
        open: 1.0525,
        close: 1.05332,
        high: 1.05446,
        low: 1.05194
      },
      {
        timestamp: 1651824000,
        open: 1.05332,
        close: 1.0522,
        high: 1.05356,
        low: 1.05189
      },
      {
        timestamp: 1651824300,
        open: 1.05221,
        close: 1.05262,
        high: 1.05321,
        low: 1.05132
      },
      {
        timestamp: 1651824600,
        open: 1.05264,
        close: 1.05435,
        high: 1.05436,
        low: 1.05256
      },
      {
        timestamp: 1651824900,
        open: 1.05436,
        close: 1.05491,
        high: 1.05559,
        low: 1.05436
      },
      {
        timestamp: 1651825200,
        open: 1.05492,
        close: 1.05373,
        high: 1.05492,
        low: 1.05348
      },
      {
        timestamp: 1651825500,
        open: 1.05375,
        close: 1.05416,
        high: 1.05489,
        low: 1.05329
      },
      {
        timestamp: 1651825800,
        open: 1.05415,
        close: 1.05422,
        high: 1.05515,
        low: 1.05397
      },
      {
        timestamp: 1651826100,
        open: 1.05421,
        close: 1.05734,
        high: 1.05739,
        low: 1.05407
      },
      {
        timestamp: 1651826400,
        open: 1.05734,
        close: 1.05738,
        high: 1.05817,
        low: 1.0566
      },
      {
        timestamp: 1651826700,
        open: 1.05738,
        close: 1.05655,
        high: 1.05754,
        low: 1.05637
      },
      {
        timestamp: 1651827000,
        open: 1.05655,
        close: 1.05522,
        high: 1.05703,
        low: 1.05503
      },
      {
        timestamp: 1651827300,
        open: 1.05522,
        close: 1.05581,
        high: 1.05583,
        low: 1.05435
      },
      {
        timestamp: 1651827600,
        open: 1.05582,
        close: 1.05555,
        high: 1.05582,
        low: 1.05519
      },
      {
        timestamp: 1651827900,
        open: 1.05555,
        close: 1.05593,
        high: 1.05597,
        low: 1.0555
      },
      {
        timestamp: 1651828200,
        open: 1.05594,
        close: 1.05619,
        high: 1.05665,
        low: 1.0558
      },
      {
        timestamp: 1651828500,
        open: 1.05619,
        close: 1.05591,
        high: 1.0562,
        low: 1.05561
      },
      {
        timestamp: 1651828800,
        open: 1.05591,
        close: 1.05587,
        high: 1.05635,
        low: 1.05553
      },
      {
        timestamp: 1651829100,
        open: 1.05588,
        close: 1.05579,
        high: 1.05653,
        low: 1.05559
      },
      {
        timestamp: 1651829400,
        open: 1.05579,
        close: 1.05684,
        high: 1.05684,
        low: 1.05578
      },
      {
        timestamp: 1651829700,
        open: 1.05683,
        close: 1.05751,
        high: 1.05765,
        low: 1.05675
      },
      {
        timestamp: 1651830000,
        open: 1.0575,
        close: 1.05668,
        high: 1.05754,
        low: 1.05654
      },
      {
        timestamp: 1651830300,
        open: 1.05666,
        close: 1.05686,
        high: 1.05728,
        low: 1.05661
      },
      {
        timestamp: 1651830600,
        open: 1.05687,
        close: 1.05712,
        high: 1.0573,
        low: 1.05654
      },
      {
        timestamp: 1651830900,
        open: 1.0571,
        close: 1.05716,
        high: 1.0575,
        low: 1.0567
      },
      {
        timestamp: 1651831200,
        open: 1.05713,
        close: 1.05749,
        high: 1.05767,
        low: 1.05695
      },
      {
        timestamp: 1651831500,
        open: 1.05748,
        close: 1.05801,
        high: 1.05845,
        low: 1.05743
      },
      {
        timestamp: 1651831800,
        open: 1.058,
        close: 1.05791,
        high: 1.05861,
        low: 1.05771
      },
      {
        timestamp: 1651832100,
        open: 1.0579,
        close: 1.05809,
        high: 1.05841,
        low: 1.05759
      },
      {
        timestamp: 1651832400,
        open: 1.0581,
        close: 1.05838,
        high: 1.05852,
        low: 1.05798
      },
      {
        timestamp: 1651832700,
        open: 1.05839,
        close: 1.05916,
        high: 1.0592,
        low: 1.05806
      },
      {
        timestamp: 1651833000,
        open: 1.05915,
        close: 1.05857,
        high: 1.0594,
        low: 1.05848
      },
      {
        timestamp: 1651833300,
        open: 1.05857,
        close: 1.05898,
        high: 1.05926,
        low: 1.05857
      },
      {
        timestamp: 1651833600,
        open: 1.05899,
        close: 1.05891,
        high: 1.0591,
        low: 1.05838
      },
      {
        timestamp: 1651833900,
        open: 1.0589,
        close: 1.05927,
        high: 1.05933,
        low: 1.05879
      },
      {
        timestamp: 1651834200,
        open: 1.05928,
        close: 1.05908,
        high: 1.05994,
        low: 1.05888
      },
      {
        timestamp: 1651834500,
        open: 1.05908,
        close: 1.05897,
        high: 1.05938,
        low: 1.0586
      },
      {
        timestamp: 1651834800,
        open: 1.05897,
        close: 1.05888,
        high: 1.05902,
        low: 1.05842
      },
      {
        timestamp: 1651835100,
        open: 1.05888,
        close: 1.05846,
        high: 1.05918,
        low: 1.05842
      },
      {
        timestamp: 1651835400,
        open: 1.05846,
        close: 1.05868,
        high: 1.05875,
        low: 1.05843
      },
      {
        timestamp: 1651835700,
        open: 1.05868,
        close: 1.05819,
        high: 1.05881,
        low: 1.05808
      },
      {
        timestamp: 1651836000,
        open: 1.05819,
        close: 1.05829,
        high: 1.05883,
        low: 1.05813
      },
      {
        timestamp: 1651836300,
        open: 1.05829,
        close: 1.05829,
        high: 1.05866,
        low: 1.05809
      },
      {
        timestamp: 1651836600,
        open: 1.0583,
        close: 1.059,
        high: 1.05916,
        low: 1.05818
      },
      {
        timestamp: 1651836900,
        open: 1.059,
        close: 1.05864,
        high: 1.05917,
        low: 1.05854
      },
      {
        timestamp: 1651837200,
        open: 1.05864,
        close: 1.05791,
        high: 1.05864,
        low: 1.05762
      },
      {
        timestamp: 1651837500,
        open: 1.05791,
        close: 1.05726,
        high: 1.05799,
        low: 1.05721
      },
      {
        timestamp: 1651837800,
        open: 1.05724,
        close: 1.05712,
        high: 1.05749,
        low: 1.05703
      },
      {
        timestamp: 1651838100,
        open: 1.05712,
        close: 1.05666,
        high: 1.05713,
        low: 1.05604
      },
      {
        timestamp: 1651838400,
        open: 1.05666,
        close: 1.05671,
        high: 1.05675,
        low: 1.05604
      },
      {
        timestamp: 1651838700,
        open: 1.0567,
        close: 1.0574,
        high: 1.0576,
        low: 1.05658
      },
      {
        timestamp: 1651839000,
        open: 1.0574,
        close: 1.05704,
        high: 1.05748,
        low: 1.05702
      },
      {
        timestamp: 1651839300,
        open: 1.05702,
        close: 1.05684,
        high: 1.05716,
        low: 1.05667
      },
      {
        timestamp: 1651839600,
        open: 1.05682,
        close: 1.05732,
        high: 1.05739,
        low: 1.05667
      },
      {
        timestamp: 1651839900,
        open: 1.05733,
        close: 1.0575,
        high: 1.05775,
        low: 1.0566
      },
      {
        timestamp: 1651840200,
        open: 1.05751,
        close: 1.05893,
        high: 1.0598,
        low: 1.05731
      },
      {
        timestamp: 1651840500,
        open: 1.0589,
        close: 1.0582,
        high: 1.05898,
        low: 1.05723
      },
      {
        timestamp: 1651840800,
        open: 1.05821,
        close: 1.05854,
        high: 1.05854,
        low: 1.05753
      },
      {
        timestamp: 1651841100,
        open: 1.05853,
        close: 1.05676,
        high: 1.05853,
        low: 1.0566
      },
      {
        timestamp: 1651841400,
        open: 1.05676,
        close: 1.0568,
        high: 1.05764,
        low: 1.05663
      },
      {
        timestamp: 1651841700,
        open: 1.05678,
        close: 1.05592,
        high: 1.05736,
        low: 1.05569
      },
      {
        timestamp: 1651842000,
        open: 1.05593,
        close: 1.05545,
        high: 1.05624,
        low: 1.05482
      },
      {
        timestamp: 1651842300,
        open: 1.05546,
        close: 1.05632,
        high: 1.05671,
        low: 1.05504
      },
      {
        timestamp: 1651842600,
        open: 1.05629,
        close: 1.05706,
        high: 1.05715,
        low: 1.05557
      },
      {
        timestamp: 1651842900,
        open: 1.05706,
        close: 1.057,
        high: 1.05747,
        low: 1.05623
      },
      {
        timestamp: 1651843200,
        open: 1.05701,
        close: 1.05752,
        high: 1.05767,
        low: 1.05694
      },
      {
        timestamp: 1651843500,
        open: 1.05752,
        close: 1.05824,
        high: 1.05851,
        low: 1.05739
      },
      {
        timestamp: 1651843800,
        open: 1.05824,
        close: 1.0579,
        high: 1.05891,
        low: 1.0577
      },
      {
        timestamp: 1651844100,
        open: 1.0579,
        close: 1.05741,
        high: 1.0585,
        low: 1.05732
      },
      {
        timestamp: 1651844400,
        open: 1.05741,
        close: 1.05728,
        high: 1.05819,
        low: 1.05687
      },
      {
        timestamp: 1651844700,
        open: 1.05729,
        close: 1.05665,
        high: 1.05737,
        low: 1.05636
      },
      {
        timestamp: 1651845000,
        open: 1.05664,
        close: 1.05954,
        high: 1.05956,
        low: 1.05649
      },
      {
        timestamp: 1651845300,
        open: 1.05954,
        close: 1.05848,
        high: 1.05969,
        low: 1.0584
      },
      {
        timestamp: 1651845600,
        open: 1.05848,
        close: 1.05785,
        high: 1.05859,
        low: 1.05759
      },
      {
        timestamp: 1651845900,
        open: 1.05784,
        close: 1.05805,
        high: 1.05807,
        low: 1.05746
      },
      {
        timestamp: 1651846200,
        open: 1.05805,
        close: 1.05797,
        high: 1.0582,
        low: 1.05769
      },
      {
        timestamp: 1651846500,
        open: 1.05796,
        close: 1.05856,
        high: 1.05884,
        low: 1.0578
      },
      {
        timestamp: 1651846800,
        open: 1.05856,
        close: 1.05772,
        high: 1.05865,
        low: 1.05763
      },
      {
        timestamp: 1651847100,
        open: 1.05773,
        close: 1.058,
        high: 1.05822,
        low: 1.0575
      },
      {
        timestamp: 1651847400,
        open: 1.05802,
        close: 1.05772,
        high: 1.05803,
        low: 1.05748
      },
      {
        timestamp: 1651847700,
        open: 1.05771,
        close: 1.05669,
        high: 1.05771,
        low: 1.05663
      },
      {
        timestamp: 1651848000,
        open: 1.0567,
        close: 1.05704,
        high: 1.05709,
        low: 1.05645
      },
      {
        timestamp: 1651848300,
        open: 1.05705,
        close: 1.05678,
        high: 1.05705,
        low: 1.05634
      },
      {
        timestamp: 1651848600,
        open: 1.05679,
        close: 1.05732,
        high: 1.05755,
        low: 1.05662
      },
      {
        timestamp: 1651848900,
        open: 1.05732,
        close: 1.05798,
        high: 1.05822,
        low: 1.05711
      },
      {
        timestamp: 1651849200,
        open: 1.05797,
        close: 1.05844,
        high: 1.05849,
        low: 1.05797
      },
      {
        timestamp: 1651849500,
        open: 1.05841,
        close: 1.05844,
        high: 1.05866,
        low: 1.05813
      },
      {
        timestamp: 1651849800,
        open: 1.05844,
        close: 1.058,
        high: 1.05871,
        low: 1.0579
      },
      {
        timestamp: 1651850100,
        open: 1.058,
        close: 1.05819,
        high: 1.05845,
        low: 1.05791
      },
      {
        timestamp: 1651850400,
        open: 1.05818,
        close: 1.05919,
        high: 1.05926,
        low: 1.05815
      },
      {
        timestamp: 1651850700,
        open: 1.0592,
        close: 1.05902,
        high: 1.05958,
        low: 1.05895
      },
      {
        timestamp: 1651851000,
        open: 1.05904,
        close: 1.05924,
        high: 1.05937,
        low: 1.05883
      },
      {
        timestamp: 1651851300,
        open: 1.05924,
        close: 1.05897,
        high: 1.05937,
        low: 1.05868
      },
      {
        timestamp: 1651851600,
        open: 1.05897,
        close: 1.0585,
        high: 1.05899,
        low: 1.05829
      },
      {
        timestamp: 1651851900,
        open: 1.05849,
        close: 1.05799,
        high: 1.05863,
        low: 1.05793
      },
      {
        timestamp: 1651852200,
        open: 1.058,
        close: 1.05788,
        high: 1.05845,
        low: 1.05775
      },
      {
        timestamp: 1651852500,
        open: 1.05789,
        close: 1.05783,
        high: 1.05838,
        low: 1.05771
      },
      {
        timestamp: 1651852800,
        open: 1.05783,
        close: 1.0574,
        high: 1.05785,
        low: 1.0574
      },
      {
        timestamp: 1651853100,
        open: 1.0574,
        close: 1.05741,
        high: 1.05764,
        low: 1.0571
      },
      {
        timestamp: 1651853400,
        open: 1.0574,
        close: 1.05773,
        high: 1.05811,
        low: 1.05738
      },
      {
        timestamp: 1651853700,
        open: 1.05773,
        close: 1.0578,
        high: 1.05794,
        low: 1.05759
      },
      {
        timestamp: 1651854000,
        open: 1.0578,
        close: 1.05738,
        high: 1.05783,
        low: 1.05732
      },
      {
        timestamp: 1651854300,
        open: 1.05738,
        close: 1.05676,
        high: 1.05746,
        low: 1.05668
      },
      {
        timestamp: 1651854600,
        open: 1.05677,
        close: 1.05707,
        high: 1.05722,
        low: 1.05671
      },
      {
        timestamp: 1651854900,
        open: 1.05706,
        close: 1.05718,
        high: 1.05729,
        low: 1.05694
      },
      {
        timestamp: 1651855200,
        open: 1.05718,
        close: 1.05759,
        high: 1.05765,
        low: 1.05707
      },
      {
        timestamp: 1651855500,
        open: 1.05758,
        close: 1.05733,
        high: 1.0577,
        low: 1.05718
      },
      {
        timestamp: 1651855800,
        open: 1.05733,
        close: 1.05745,
        high: 1.05779,
        low: 1.05724
      },
      {
        timestamp: 1651856100,
        open: 1.05745,
        close: 1.05696,
        high: 1.0576,
        low: 1.0569
      },
      {
        timestamp: 1651856400,
        open: 1.05696,
        close: 1.05694,
        high: 1.05745,
        low: 1.05683
      },
      {
        timestamp: 1651856700,
        open: 1.05694,
        close: 1.05703,
        high: 1.05754,
        low: 1.05674
      },
      {
        timestamp: 1651857000,
        open: 1.05704,
        close: 1.05685,
        high: 1.0572,
        low: 1.05684
      },
      {
        timestamp: 1651857300,
        open: 1.05684,
        close: 1.05663,
        high: 1.057,
        low: 1.05657
      },
      {
        timestamp: 1651857600,
        open: 1.05664,
        close: 1.05683,
        high: 1.05692,
        low: 1.05649
      },
      {
        timestamp: 1651857900,
        open: 1.05683,
        close: 1.05685,
        high: 1.05705,
        low: 1.05668
      },
      {
        timestamp: 1651858200,
        open: 1.05684,
        close: 1.05683,
        high: 1.05702,
        low: 1.05673
      },
      {
        timestamp: 1651858500,
        open: 1.05683,
        close: 1.05637,
        high: 1.05693,
        low: 1.05626
      },
      {
        timestamp: 1651858800,
        open: 1.05638,
        close: 1.05619,
        high: 1.05663,
        low: 1.05605
      },
      {
        timestamp: 1651859100,
        open: 1.0562,
        close: 1.05608,
        high: 1.05649,
        low: 1.05604
      },
      {
        timestamp: 1651859400,
        open: 1.05609,
        close: 1.05573,
        high: 1.05612,
        low: 1.05563
      },
      {
        timestamp: 1651859700,
        open: 1.05572,
        close: 1.05509,
        high: 1.05575,
        low: 1.05508
      },
      {
        timestamp: 1651860000,
        open: 1.05509,
        close: 1.05547,
        high: 1.05566,
        low: 1.05501
      },
      {
        timestamp: 1651860300,
        open: 1.05547,
        close: 1.05553,
        high: 1.05566,
        low: 1.05522
      },
      {
        timestamp: 1651860600,
        open: 1.05552,
        close: 1.05564,
        high: 1.05591,
        low: 1.05549
      },
      {
        timestamp: 1651860900,
        open: 1.05564,
        close: 1.05564,
        high: 1.0559,
        low: 1.05563
      },
      {
        timestamp: 1651861200,
        open: 1.05564,
        close: 1.0555,
        high: 1.05571,
        low: 1.05548
      },
      {
        timestamp: 1651861500,
        open: 1.05549,
        close: 1.05557,
        high: 1.05576,
        low: 1.05535
      },
      {
        timestamp: 1651861800,
        open: 1.05557,
        close: 1.05485,
        high: 1.05557,
        low: 1.05464
      },
      {
        timestamp: 1651862100,
        open: 1.05485,
        close: 1.05457,
        high: 1.05501,
        low: 1.0545
      },
      {
        timestamp: 1651862400,
        open: 1.05458,
        close: 1.05466,
        high: 1.05479,
        low: 1.05442
      },
      {
        timestamp: 1651862700,
        open: 1.05465,
        close: 1.05484,
        high: 1.0549,
        low: 1.05459
      },
      {
        timestamp: 1651863000,
        open: 1.05485,
        close: 1.05525,
        high: 1.05532,
        low: 1.05478
      },
      {
        timestamp: 1651863300,
        open: 1.05524,
        close: 1.0548,
        high: 1.0553,
        low: 1.05474
      },
      {
        timestamp: 1651863600,
        open: 1.0548,
        close: 1.0548,
        high: 1.05486,
        low: 1.05455
      },
      {
        timestamp: 1651863900,
        open: 1.05479,
        close: 1.0546,
        high: 1.05479,
        low: 1.0544
      },
      {
        timestamp: 1651864200,
        open: 1.05461,
        close: 1.05484,
        high: 1.05486,
        low: 1.0546
      },
      {
        timestamp: 1651864500,
        open: 1.05484,
        close: 1.05423,
        high: 1.05491,
        low: 1.05396
      },
      {
        timestamp: 1651864800,
        open: 1.05423,
        close: 1.05374,
        high: 1.05432,
        low: 1.05374
      },
      {
        timestamp: 1651865100,
        open: 1.05374,
        close: 1.05438,
        high: 1.0544,
        low: 1.0537
      },
      {
        timestamp: 1651865400,
        open: 1.05439,
        close: 1.05454,
        high: 1.05479,
        low: 1.05431
      },
      {
        timestamp: 1651865700,
        open: 1.05454,
        close: 1.05507,
        high: 1.05511,
        low: 1.05443
      },
      {
        timestamp: 1651866000,
        open: 1.05506,
        close: 1.05545,
        high: 1.05566,
        low: 1.05494
      },
      {
        timestamp: 1651866300,
        open: 1.05545,
        close: 1.05562,
        high: 1.05571,
        low: 1.0552
      },
      {
        timestamp: 1651866600,
        open: 1.05563,
        close: 1.05499,
        high: 1.05571,
        low: 1.05497
      },
      {
        timestamp: 1651866900,
        open: 1.05498,
        close: 1.05481,
        high: 1.05513,
        low: 1.05464
      },
      {
        timestamp: 1651867200,
        open: 1.05481,
        close: 1.05453,
        high: 1.05488,
        low: 1.05445
      },
      {
        timestamp: 1651867500,
        open: 1.05452,
        close: 1.05461,
        high: 1.05465,
        low: 1.05451
      },
      {
        timestamp: 1651867800,
        open: 1.0546,
        close: 1.0548,
        high: 1.05484,
        low: 1.0546
      },
      {
        timestamp: 1651868100,
        open: 1.0548,
        close: 1.05456,
        high: 1.05484,
        low: 1.05452
      },
      {
        timestamp: 1651868400,
        open: 1.05456,
        close: 1.0545,
        high: 1.05456,
        low: 1.05432
      },
      {
        timestamp: 1651868700,
        open: 1.05449,
        close: 1.05414,
        high: 1.05451,
        low: 1.05413
      },
      {
        timestamp: 1651869000,
        open: 1.05414,
        close: 1.05445,
        high: 1.05445,
        low: 1.05414
      },
      {
        timestamp: 1651869300,
        open: 1.05442,
        close: 1.05466,
        high: 1.05466,
        low: 1.05442
      },
      {
        timestamp: 1651869600,
        open: 1.05466,
        close: 1.05413,
        high: 1.05475,
        low: 1.05413
      },
      {
        timestamp: 1651869900,
        open: 1.05415,
        close: 1.05424,
        high: 1.05442,
        low: 1.05414
      },
      {
        timestamp: 1651870200,
        open: 1.05424,
        close: 1.05449,
        high: 1.05464,
        low: 1.0542
      },
      {
        timestamp: 1651870500,
        open: 1.0545,
        close: 1.05481,
        high: 1.05531,
        low: 1.05449
      },
      {
        timestamp: 1652040000,
        open: 1.05655,
        close: 1.05648,
        high: 1.05685,
        low: 1.05648
      },
      {
        timestamp: 1652040300,
        open: 1.05627,
        close: 1.05627,
        high: 1.05627,
        low: 1.05627
      },
      {
        timestamp: 1652040600,
        open: 1.05648,
        close: 1.05625,
        high: 1.05663,
        low: 1.05587
      },
      {
        timestamp: 1652040900,
        open: 1.05537,
        close: 1.05538,
        high: 1.05557,
        low: 1.05511
      },
      {
        timestamp: 1652041500,
        open: 1.05498,
        close: 1.0551,
        high: 1.0551,
        low: 1.05498
      },
      {
        timestamp: 1652041800,
        open: 1.0551,
        close: 1.05478,
        high: 1.0551,
        low: 1.05477
      },
      {
        timestamp: 1652042100,
        open: 1.0548,
        close: 1.05484,
        high: 1.05493,
        low: 1.05475
      },
      {
        timestamp: 1652042400,
        open: 1.05483,
        close: 1.05483,
        high: 1.05484,
        low: 1.05483
      },
      {
        timestamp: 1652042700,
        open: 1.0548,
        close: 1.05491,
        high: 1.05491,
        low: 1.05407
      },
      {
        timestamp: 1652043000,
        open: 1.05492,
        close: 1.0549,
        high: 1.05492,
        low: 1.0549
      },
      {
        timestamp: 1652043300,
        open: 1.05491,
        close: 1.05503,
        high: 1.05526,
        low: 1.0549
      },
      {
        timestamp: 1652043600,
        open: 1.05485,
        close: 1.05468,
        high: 1.05489,
        low: 1.05468
      },
      {
        timestamp: 1652043900,
        open: 1.05469,
        close: 1.0547,
        high: 1.0549,
        low: 1.05468
      },
      {
        timestamp: 1652044200,
        open: 1.05479,
        close: 1.0549,
        high: 1.0549,
        low: 1.05479
      },
      {
        timestamp: 1652044500,
        open: 1.05474,
        close: 1.05467,
        high: 1.05474,
        low: 1.05466
      },
      {
        timestamp: 1652044800,
        open: 1.0546,
        close: 1.05459,
        high: 1.0546,
        low: 1.05455
      },
      {
        timestamp: 1652045100,
        open: 1.0546,
        close: 1.05458,
        high: 1.0546,
        low: 1.05456
      },
      {
        timestamp: 1652045400,
        open: 1.05459,
        close: 1.0544,
        high: 1.05459,
        low: 1.05426
      },
      {
        timestamp: 1652045700,
        open: 1.05443,
        close: 1.05443,
        high: 1.05451,
        low: 1.05434
      },
      {
        timestamp: 1652046000,
        open: 1.05446,
        close: 1.05416,
        high: 1.05446,
        low: 1.05414
      },
      {
        timestamp: 1652046300,
        open: 1.05415,
        close: 1.05419,
        high: 1.05419,
        low: 1.05415
      },
      {
        timestamp: 1652046600,
        open: 1.05417,
        close: 1.05413,
        high: 1.05421,
        low: 1.05413
      },
      {
        timestamp: 1652046900,
        open: 1.05413,
        close: 1.05435,
        high: 1.05435,
        low: 1.05413
      },
      {
        timestamp: 1652047200,
        open: 1.05435,
        close: 1.05393,
        high: 1.05446,
        low: 1.05388
      },
      {
        timestamp: 1652047500,
        open: 1.05397,
        close: 1.05388,
        high: 1.05397,
        low: 1.05366
      },
      {
        timestamp: 1652047800,
        open: 1.05388,
        close: 1.05382,
        high: 1.05399,
        low: 1.05382
      },
      {
        timestamp: 1652048100,
        open: 1.05381,
        close: 1.05396,
        high: 1.05397,
        low: 1.05377
      },
      {
        timestamp: 1652048400,
        open: 1.05396,
        close: 1.05392,
        high: 1.05396,
        low: 1.05375
      },
      {
        timestamp: 1652048700,
        open: 1.05391,
        close: 1.05399,
        high: 1.05409,
        low: 1.05385
      },
      {
        timestamp: 1652049000,
        open: 1.05399,
        close: 1.05409,
        high: 1.05413,
        low: 1.05393
      },
      {
        timestamp: 1652049300,
        open: 1.05408,
        close: 1.05392,
        high: 1.05408,
        low: 1.05379
      },
      {
        timestamp: 1652049600,
        open: 1.05391,
        close: 1.05393,
        high: 1.05393,
        low: 1.05378
      },
      {
        timestamp: 1652049900,
        open: 1.05393,
        close: 1.05389,
        high: 1.05397,
        low: 1.05384
      },
      {
        timestamp: 1652050200,
        open: 1.05389,
        close: 1.05386,
        high: 1.0539,
        low: 1.05377
      },
      {
        timestamp: 1652050500,
        open: 1.05386,
        close: 1.05386,
        high: 1.05398,
        low: 1.05375
      },
      {
        timestamp: 1652050800,
        open: 1.05386,
        close: 1.05369,
        high: 1.05396,
        low: 1.05366
      },
      {
        timestamp: 1652051100,
        open: 1.05369,
        close: 1.05367,
        high: 1.05389,
        low: 1.05361
      },
      {
        timestamp: 1652051400,
        open: 1.05366,
        close: 1.05358,
        high: 1.05369,
        low: 1.05356
      },
      {
        timestamp: 1652051700,
        open: 1.05359,
        close: 1.05354,
        high: 1.05364,
        low: 1.05353
      },
      {
        timestamp: 1652052000,
        open: 1.05355,
        close: 1.05317,
        high: 1.05359,
        low: 1.05316
      },
      {
        timestamp: 1652052300,
        open: 1.05316,
        close: 1.05343,
        high: 1.05356,
        low: 1.05316
      },
      {
        timestamp: 1652052600,
        open: 1.05342,
        close: 1.05323,
        high: 1.05342,
        low: 1.05321
      },
      {
        timestamp: 1652052900,
        open: 1.05323,
        close: 1.05338,
        high: 1.05344,
        low: 1.05321
      },
      {
        timestamp: 1652053200,
        open: 1.05337,
        close: 1.05361,
        high: 1.05363,
        low: 1.05326
      },
      {
        timestamp: 1652053500,
        open: 1.0536,
        close: 1.05365,
        high: 1.05396,
        low: 1.05358
      },
      {
        timestamp: 1652053800,
        open: 1.05365,
        close: 1.05352,
        high: 1.0538,
        low: 1.05344
      },
      {
        timestamp: 1652054100,
        open: 1.05351,
        close: 1.05329,
        high: 1.05351,
        low: 1.05315
      },
      {
        timestamp: 1652054400,
        open: 1.0533,
        close: 1.05341,
        high: 1.05347,
        low: 1.05313
      },
      {
        timestamp: 1652054700,
        open: 1.0534,
        close: 1.05324,
        high: 1.05363,
        low: 1.05313
      },
      {
        timestamp: 1652055000,
        open: 1.05324,
        close: 1.05306,
        high: 1.05326,
        low: 1.05301
      },
      {
        timestamp: 1652055300,
        open: 1.05306,
        close: 1.05268,
        high: 1.05335,
        low: 1.05263
      },
      {
        timestamp: 1652055600,
        open: 1.05268,
        close: 1.05299,
        high: 1.05317,
        low: 1.05268
      },
      {
        timestamp: 1652055900,
        open: 1.05299,
        close: 1.05322,
        high: 1.05326,
        low: 1.05288
      },
      {
        timestamp: 1652056200,
        open: 1.05322,
        close: 1.05274,
        high: 1.05322,
        low: 1.05272
      },
      {
        timestamp: 1652056500,
        open: 1.05275,
        close: 1.05265,
        high: 1.05291,
        low: 1.05261
      },
      {
        timestamp: 1652056800,
        open: 1.05266,
        close: 1.05277,
        high: 1.05278,
        low: 1.05258
      },
      {
        timestamp: 1652057100,
        open: 1.05276,
        close: 1.05275,
        high: 1.05302,
        low: 1.05265
      },
      {
        timestamp: 1652057400,
        open: 1.05276,
        close: 1.05251,
        high: 1.05276,
        low: 1.05223
      },
      {
        timestamp: 1652057700,
        open: 1.05249,
        close: 1.05202,
        high: 1.05254,
        low: 1.05189
      },
      {
        timestamp: 1652058000,
        open: 1.05201,
        close: 1.05168,
        high: 1.05205,
        low: 1.05142
      },
      {
        timestamp: 1652058300,
        open: 1.05168,
        close: 1.05205,
        high: 1.05258,
        low: 1.05159
      },
      {
        timestamp: 1652058600,
        open: 1.05205,
        close: 1.05233,
        high: 1.05271,
        low: 1.05195
      },
      {
        timestamp: 1652058900,
        open: 1.05235,
        close: 1.05236,
        high: 1.05254,
        low: 1.05216
      },
      {
        timestamp: 1652059200,
        open: 1.05236,
        close: 1.05196,
        high: 1.05243,
        low: 1.05188
      },
      {
        timestamp: 1652059500,
        open: 1.05197,
        close: 1.05169,
        high: 1.05212,
        low: 1.05164
      },
      {
        timestamp: 1652059800,
        open: 1.05168,
        close: 1.05186,
        high: 1.05192,
        low: 1.0516
      },
      {
        timestamp: 1652060100,
        open: 1.05186,
        close: 1.05165,
        high: 1.05191,
        low: 1.0515
      },
      {
        timestamp: 1652060400,
        open: 1.05166,
        close: 1.05225,
        high: 1.05227,
        low: 1.05165
      },
      {
        timestamp: 1652060700,
        open: 1.05226,
        close: 1.05227,
        high: 1.05248,
        low: 1.05212
      },
      {
        timestamp: 1652061000,
        open: 1.05228,
        close: 1.05258,
        high: 1.05264,
        low: 1.05213
      },
      {
        timestamp: 1652061300,
        open: 1.05258,
        close: 1.05246,
        high: 1.05275,
        low: 1.05239
      },
      {
        timestamp: 1652061600,
        open: 1.05247,
        close: 1.05216,
        high: 1.05247,
        low: 1.05202
      },
      {
        timestamp: 1652061900,
        open: 1.05216,
        close: 1.05207,
        high: 1.05225,
        low: 1.05185
      },
      {
        timestamp: 1652062200,
        open: 1.05206,
        close: 1.05162,
        high: 1.0522,
        low: 1.05157
      },
      {
        timestamp: 1652062500,
        open: 1.05163,
        close: 1.05166,
        high: 1.05184,
        low: 1.05162
      },
      {
        timestamp: 1652062800,
        open: 1.05166,
        close: 1.05202,
        high: 1.05205,
        low: 1.05158
      },
      {
        timestamp: 1652063100,
        open: 1.05202,
        close: 1.05186,
        high: 1.05209,
        low: 1.05169
      },
      {
        timestamp: 1652063400,
        open: 1.05187,
        close: 1.05166,
        high: 1.0521,
        low: 1.05166
      },
      {
        timestamp: 1652063700,
        open: 1.05167,
        close: 1.05128,
        high: 1.05171,
        low: 1.05122
      },
      {
        timestamp: 1652064000,
        open: 1.05128,
        close: 1.051,
        high: 1.05143,
        low: 1.05089
      },
      {
        timestamp: 1652064300,
        open: 1.05099,
        close: 1.05095,
        high: 1.05112,
        low: 1.05087
      },
      {
        timestamp: 1652064600,
        open: 1.05094,
        close: 1.05101,
        high: 1.05113,
        low: 1.05093
      },
      {
        timestamp: 1652064900,
        open: 1.05101,
        close: 1.05076,
        high: 1.05116,
        low: 1.05073
      },
      {
        timestamp: 1652065200,
        open: 1.05076,
        close: 1.05082,
        high: 1.05106,
        low: 1.05065
      },
      {
        timestamp: 1652065500,
        open: 1.05082,
        close: 1.05119,
        high: 1.05137,
        low: 1.05073
      },
      {
        timestamp: 1652065800,
        open: 1.05118,
        close: 1.0508,
        high: 1.05118,
        low: 1.05075
      },
      {
        timestamp: 1652066100,
        open: 1.05081,
        close: 1.05066,
        high: 1.05083,
        low: 1.05057
      },
      {
        timestamp: 1652066400,
        open: 1.05067,
        close: 1.05098,
        high: 1.05101,
        low: 1.05056
      },
      {
        timestamp: 1652066700,
        open: 1.05099,
        close: 1.05093,
        high: 1.05109,
        low: 1.0509
      },
      {
        timestamp: 1652067000,
        open: 1.05094,
        close: 1.05127,
        high: 1.0513,
        low: 1.05093
      },
      {
        timestamp: 1652067300,
        open: 1.05127,
        close: 1.05099,
        high: 1.05135,
        low: 1.05094
      },
      {
        timestamp: 1652067600,
        open: 1.05098,
        close: 1.05089,
        high: 1.05106,
        low: 1.05084
      },
      {
        timestamp: 1652067900,
        open: 1.05088,
        close: 1.05045,
        high: 1.05091,
        low: 1.05041
      },
      {
        timestamp: 1652068200,
        open: 1.05044,
        close: 1.05083,
        high: 1.05096,
        low: 1.05035
      },
      {
        timestamp: 1652068500,
        open: 1.05083,
        close: 1.05107,
        high: 1.05109,
        low: 1.05074
      },
      {
        timestamp: 1652068800,
        open: 1.05106,
        close: 1.05111,
        high: 1.05136,
        low: 1.051
      },
      {
        timestamp: 1652069100,
        open: 1.05112,
        close: 1.05094,
        high: 1.05116,
        low: 1.05094
      },
      {
        timestamp: 1652069400,
        open: 1.05095,
        close: 1.05088,
        high: 1.05101,
        low: 1.05064
      },
      {
        timestamp: 1652069700,
        open: 1.05088,
        close: 1.05116,
        high: 1.05123,
        low: 1.05067
      },
      {
        timestamp: 1652070000,
        open: 1.05116,
        close: 1.05126,
        high: 1.05135,
        low: 1.05109
      },
      {
        timestamp: 1652070300,
        open: 1.05125,
        close: 1.05165,
        high: 1.05171,
        low: 1.05121
      },
      {
        timestamp: 1652070600,
        open: 1.05166,
        close: 1.05154,
        high: 1.05172,
        low: 1.05152
      },
      {
        timestamp: 1652070900,
        open: 1.05155,
        close: 1.05114,
        high: 1.05155,
        low: 1.051
      },
      {
        timestamp: 1652071200,
        open: 1.05115,
        close: 1.05127,
        high: 1.05132,
        low: 1.05094
      },
      {
        timestamp: 1652071500,
        open: 1.05126,
        close: 1.05121,
        high: 1.05133,
        low: 1.05115
      },
      {
        timestamp: 1652071800,
        open: 1.05122,
        close: 1.05116,
        high: 1.05127,
        low: 1.05104
      },
      {
        timestamp: 1652072100,
        open: 1.05116,
        close: 1.05132,
        high: 1.05143,
        low: 1.05097
      },
      {
        timestamp: 1652072400,
        open: 1.05131,
        close: 1.05092,
        high: 1.05131,
        low: 1.0508
      },
      {
        timestamp: 1652072700,
        open: 1.05092,
        close: 1.05088,
        high: 1.05111,
        low: 1.05083
      },
      {
        timestamp: 1652073000,
        open: 1.05088,
        close: 1.05085,
        high: 1.05095,
        low: 1.05061
      },
      {
        timestamp: 1652073300,
        open: 1.05085,
        close: 1.05075,
        high: 1.05092,
        low: 1.0506
      },
      {
        timestamp: 1652073600,
        open: 1.05074,
        close: 1.05061,
        high: 1.05076,
        low: 1.0505
      },
      {
        timestamp: 1652073900,
        open: 1.05061,
        close: 1.05073,
        high: 1.05086,
        low: 1.05037
      },
      {
        timestamp: 1652074200,
        open: 1.05072,
        close: 1.05068,
        high: 1.05096,
        low: 1.05068
      },
      {
        timestamp: 1652074500,
        open: 1.05068,
        close: 1.05105,
        high: 1.05114,
        low: 1.05045
      },
      {
        timestamp: 1652074800,
        open: 1.05106,
        close: 1.05085,
        high: 1.05121,
        low: 1.05073
      },
      {
        timestamp: 1652075100,
        open: 1.05085,
        close: 1.05062,
        high: 1.05085,
        low: 1.05048
      },
      {
        timestamp: 1652075400,
        open: 1.05062,
        close: 1.05081,
        high: 1.05082,
        low: 1.05046
      },
      {
        timestamp: 1652075700,
        open: 1.05082,
        close: 1.05083,
        high: 1.05094,
        low: 1.05053
      },
      {
        timestamp: 1652076000,
        open: 1.05082,
        close: 1.05137,
        high: 1.05139,
        low: 1.05069
      },
      {
        timestamp: 1652076300,
        open: 1.05137,
        close: 1.05121,
        high: 1.0515,
        low: 1.05075
      },
      {
        timestamp: 1652076600,
        open: 1.05121,
        close: 1.05095,
        high: 1.05139,
        low: 1.05081
      },
      {
        timestamp: 1652076900,
        open: 1.05095,
        close: 1.05096,
        high: 1.05113,
        low: 1.0507
      },
      {
        timestamp: 1652077200,
        open: 1.05096,
        close: 1.0508,
        high: 1.05096,
        low: 1.05057
      },
      {
        timestamp: 1652077500,
        open: 1.05078,
        close: 1.05109,
        high: 1.05129,
        low: 1.05064
      },
      {
        timestamp: 1652077800,
        open: 1.05109,
        close: 1.0509,
        high: 1.05115,
        low: 1.05076
      },
      {
        timestamp: 1652078100,
        open: 1.05089,
        close: 1.05108,
        high: 1.05126,
        low: 1.05086
      },
      {
        timestamp: 1652078400,
        open: 1.05107,
        close: 1.05059,
        high: 1.05107,
        low: 1.05051
      },
      {
        timestamp: 1652078700,
        open: 1.05059,
        close: 1.05062,
        high: 1.05076,
        low: 1.05044
      },
      {
        timestamp: 1652079000,
        open: 1.05064,
        close: 1.04959,
        high: 1.05064,
        low: 1.04958
      },
      {
        timestamp: 1652079300,
        open: 1.04959,
        close: 1.05035,
        high: 1.05047,
        low: 1.04959
      },
      {
        timestamp: 1652079600,
        open: 1.05033,
        close: 1.05079,
        high: 1.05097,
        low: 1.04984
      },
      {
        timestamp: 1652079900,
        open: 1.0508,
        close: 1.05057,
        high: 1.05092,
        low: 1.05056
      },
      {
        timestamp: 1652080200,
        open: 1.05056,
        close: 1.05178,
        high: 1.05206,
        low: 1.05055
      },
      {
        timestamp: 1652080500,
        open: 1.05177,
        close: 1.05142,
        high: 1.05177,
        low: 1.05115
      },
      {
        timestamp: 1652080800,
        open: 1.05142,
        close: 1.05047,
        high: 1.05178,
        low: 1.05042
      },
      {
        timestamp: 1652081100,
        open: 1.05046,
        close: 1.05176,
        high: 1.05221,
        low: 1.05036
      },
      {
        timestamp: 1652081400,
        open: 1.05176,
        close: 1.05225,
        high: 1.05256,
        low: 1.0512
      },
      {
        timestamp: 1652081700,
        open: 1.05225,
        close: 1.05292,
        high: 1.05294,
        low: 1.05221
      },
      {
        timestamp: 1652082000,
        open: 1.05291,
        close: 1.05174,
        high: 1.05302,
        low: 1.05171
      },
      {
        timestamp: 1652082300,
        open: 1.05174,
        close: 1.05224,
        high: 1.05226,
        low: 1.05169
      },
      {
        timestamp: 1652082600,
        open: 1.05224,
        close: 1.05096,
        high: 1.05227,
        low: 1.05083
      },
      {
        timestamp: 1652082900,
        open: 1.05096,
        close: 1.05126,
        high: 1.0514,
        low: 1.05065
      },
      {
        timestamp: 1652083200,
        open: 1.05125,
        close: 1.05105,
        high: 1.05143,
        low: 1.05083
      },
      {
        timestamp: 1652083500,
        open: 1.05105,
        close: 1.05175,
        high: 1.05211,
        low: 1.05097
      },
      {
        timestamp: 1652083800,
        open: 1.05174,
        close: 1.05164,
        high: 1.05181,
        low: 1.05124
      },
      {
        timestamp: 1652084100,
        open: 1.05163,
        close: 1.05121,
        high: 1.0518,
        low: 1.05118
      },
      {
        timestamp: 1652084400,
        open: 1.05121,
        close: 1.05097,
        high: 1.05143,
        low: 1.05095
      },
      {
        timestamp: 1652084700,
        open: 1.05096,
        close: 1.0517,
        high: 1.05179,
        low: 1.05088
      },
      {
        timestamp: 1652085000,
        open: 1.05169,
        close: 1.05124,
        high: 1.05171,
        low: 1.05091
      },
      {
        timestamp: 1652085300,
        open: 1.05123,
        close: 1.05113,
        high: 1.0515,
        low: 1.05099
      },
      {
        timestamp: 1652085600,
        open: 1.05113,
        close: 1.05075,
        high: 1.05118,
        low: 1.05062
      },
      {
        timestamp: 1652085900,
        open: 1.05075,
        close: 1.05028,
        high: 1.05084,
        low: 1.05027
      },
      {
        timestamp: 1652086200,
        open: 1.05026,
        close: 1.05106,
        high: 1.05106,
        low: 1.05019
      },
      {
        timestamp: 1652086500,
        open: 1.05107,
        close: 1.05081,
        high: 1.0512,
        low: 1.05064
      },
      {
        timestamp: 1652086800,
        open: 1.05081,
        close: 1.05134,
        high: 1.05142,
        low: 1.05055
      },
      {
        timestamp: 1652087100,
        open: 1.05133,
        close: 1.05152,
        high: 1.05181,
        low: 1.0511
      },
      {
        timestamp: 1652087400,
        open: 1.05152,
        close: 1.05159,
        high: 1.05185,
        low: 1.05149
      },
      {
        timestamp: 1652087700,
        open: 1.05156,
        close: 1.05171,
        high: 1.05184,
        low: 1.05119
      },
      {
        timestamp: 1652088000,
        open: 1.0517,
        close: 1.05162,
        high: 1.05181,
        low: 1.05152
      },
      {
        timestamp: 1652088300,
        open: 1.05162,
        close: 1.05092,
        high: 1.05163,
        low: 1.05077
      },
      {
        timestamp: 1652088600,
        open: 1.05093,
        close: 1.0511,
        high: 1.05112,
        low: 1.0508
      },
      {
        timestamp: 1652088900,
        open: 1.05108,
        close: 1.05115,
        high: 1.05133,
        low: 1.05102
      },
      {
        timestamp: 1652089200,
        open: 1.05116,
        close: 1.05169,
        high: 1.0519,
        low: 1.05106
      },
      {
        timestamp: 1652089500,
        open: 1.05169,
        close: 1.05261,
        high: 1.05268,
        low: 1.05163
      },
      {
        timestamp: 1652089800,
        open: 1.05262,
        close: 1.05234,
        high: 1.0528,
        low: 1.05228
      },
      {
        timestamp: 1652090100,
        open: 1.05234,
        close: 1.05288,
        high: 1.05309,
        low: 1.05225
      },
      {
        timestamp: 1652090400,
        open: 1.05289,
        close: 1.05316,
        high: 1.05323,
        low: 1.05268
      },
      {
        timestamp: 1652090700,
        open: 1.05316,
        close: 1.05339,
        high: 1.05362,
        low: 1.05306
      },
      {
        timestamp: 1652091000,
        open: 1.05338,
        close: 1.05422,
        high: 1.05422,
        low: 1.05292
      },
      {
        timestamp: 1652091300,
        open: 1.05423,
        close: 1.05373,
        high: 1.05462,
        low: 1.05355
      },
      {
        timestamp: 1652091600,
        open: 1.05373,
        close: 1.05371,
        high: 1.05383,
        low: 1.0534
      },
      {
        timestamp: 1652091900,
        open: 1.05371,
        close: 1.05347,
        high: 1.05371,
        low: 1.05317
      },
      {
        timestamp: 1652092200,
        open: 1.05347,
        close: 1.05366,
        high: 1.05386,
        low: 1.05335
      },
      {
        timestamp: 1652092500,
        open: 1.05365,
        close: 1.05373,
        high: 1.05381,
        low: 1.05347
      },
      {
        timestamp: 1652092800,
        open: 1.05374,
        close: 1.05384,
        high: 1.05392,
        low: 1.05344
      },
      {
        timestamp: 1652093100,
        open: 1.05384,
        close: 1.05452,
        high: 1.05462,
        low: 1.05371
      },
      {
        timestamp: 1652093400,
        open: 1.05453,
        close: 1.05432,
        high: 1.05471,
        low: 1.0542
      },
      {
        timestamp: 1652093700,
        open: 1.05433,
        close: 1.05457,
        high: 1.05505,
        low: 1.05428
      },
      {
        timestamp: 1652094000,
        open: 1.05457,
        close: 1.05373,
        high: 1.05459,
        low: 1.05366
      },
      {
        timestamp: 1652094300,
        open: 1.05374,
        close: 1.05378,
        high: 1.05386,
        low: 1.05336
      },
      {
        timestamp: 1652094600,
        open: 1.05378,
        close: 1.05393,
        high: 1.05408,
        low: 1.05357
      },
      {
        timestamp: 1652094900,
        open: 1.05394,
        close: 1.054,
        high: 1.05424,
        low: 1.05378
      },
      {
        timestamp: 1652095200,
        open: 1.05399,
        close: 1.05351,
        high: 1.05405,
        low: 1.05344
      },
      {
        timestamp: 1652095500,
        open: 1.05351,
        close: 1.05398,
        high: 1.05417,
        low: 1.05332
      },
      {
        timestamp: 1652095800,
        open: 1.05399,
        close: 1.05426,
        high: 1.05442,
        low: 1.05392
      },
      {
        timestamp: 1652096100,
        open: 1.05426,
        close: 1.05436,
        high: 1.05452,
        low: 1.05408
      },
      {
        timestamp: 1652096400,
        open: 1.05436,
        close: 1.05458,
        high: 1.05467,
        low: 1.0543
      },
      {
        timestamp: 1652096700,
        open: 1.05459,
        close: 1.0541,
        high: 1.0546,
        low: 1.05391
      },
      {
        timestamp: 1652097000,
        open: 1.05411,
        close: 1.05439,
        high: 1.05447,
        low: 1.05407
      },
      {
        timestamp: 1652097300,
        open: 1.05441,
        close: 1.05474,
        high: 1.05532,
        low: 1.0544
      },
      {
        timestamp: 1652097600,
        open: 1.05473,
        close: 1.05535,
        high: 1.05548,
        low: 1.05453
      },
      {
        timestamp: 1652097900,
        open: 1.05537,
        close: 1.05634,
        high: 1.05744,
        low: 1.05537
      },
      {
        timestamp: 1652098200,
        open: 1.05635,
        close: 1.05603,
        high: 1.05652,
        low: 1.05579
      },
      {
        timestamp: 1652098500,
        open: 1.05603,
        close: 1.05579,
        high: 1.05615,
        low: 1.05542
      },
      {
        timestamp: 1652098800,
        open: 1.0558,
        close: 1.05639,
        high: 1.05683,
        low: 1.05579
      },
      {
        timestamp: 1652099100,
        open: 1.05638,
        close: 1.05646,
        high: 1.05673,
        low: 1.05625
      },
      {
        timestamp: 1652099400,
        open: 1.05646,
        close: 1.0571,
        high: 1.0572,
        low: 1.05638
      },
      {
        timestamp: 1652099700,
        open: 1.05709,
        close: 1.05702,
        high: 1.05729,
        low: 1.05679
      },
      {
        timestamp: 1652100000,
        open: 1.05703,
        close: 1.05658,
        high: 1.05734,
        low: 1.05645
      },
      {
        timestamp: 1652100300,
        open: 1.05658,
        close: 1.0566,
        high: 1.05668,
        low: 1.05635
      },
      {
        timestamp: 1652100600,
        open: 1.05659,
        close: 1.0561,
        high: 1.05659,
        low: 1.05579
      },
      {
        timestamp: 1652100900,
        open: 1.0561,
        close: 1.0563,
        high: 1.05668,
        low: 1.0561
      },
      {
        timestamp: 1652101200,
        open: 1.0563,
        close: 1.05602,
        high: 1.05641,
        low: 1.05573
      },
      {
        timestamp: 1652101500,
        open: 1.05603,
        close: 1.05504,
        high: 1.05608,
        low: 1.05501
      },
      {
        timestamp: 1652101800,
        open: 1.05503,
        close: 1.05468,
        high: 1.05504,
        low: 1.0539
      },
      {
        timestamp: 1652102100,
        open: 1.05468,
        close: 1.05418,
        high: 1.05468,
        low: 1.05412
      },
      {
        timestamp: 1652102400,
        open: 1.05419,
        close: 1.05449,
        high: 1.05456,
        low: 1.05394
      },
      {
        timestamp: 1652102700,
        open: 1.05448,
        close: 1.05435,
        high: 1.05455,
        low: 1.05424
      },
      {
        timestamp: 1652103000,
        open: 1.05436,
        close: 1.05417,
        high: 1.05486,
        low: 1.05388
      },
      {
        timestamp: 1652103300,
        open: 1.05416,
        close: 1.05463,
        high: 1.055,
        low: 1.05412
      },
      {
        timestamp: 1652103600,
        open: 1.05463,
        close: 1.05417,
        high: 1.05466,
        low: 1.05334
      },
      {
        timestamp: 1652103900,
        open: 1.05417,
        close: 1.05359,
        high: 1.0545,
        low: 1.05356
      },
      {
        timestamp: 1652104200,
        open: 1.05358,
        close: 1.05386,
        high: 1.05409,
        low: 1.0534
      },
      {
        timestamp: 1652104500,
        open: 1.05385,
        close: 1.05362,
        high: 1.05409,
        low: 1.05358
      },
      {
        timestamp: 1652104800,
        open: 1.05363,
        close: 1.05351,
        high: 1.05403,
        low: 1.05322
      },
      {
        timestamp: 1652105100,
        open: 1.05351,
        close: 1.05336,
        high: 1.05365,
        low: 1.053
      },
      {
        timestamp: 1652105400,
        open: 1.05336,
        close: 1.05322,
        high: 1.0535,
        low: 1.05286
      },
      {
        timestamp: 1652105700,
        open: 1.05321,
        close: 1.05293,
        high: 1.05353,
        low: 1.0529
      },
      {
        timestamp: 1652106000,
        open: 1.05293,
        close: 1.05304,
        high: 1.05309,
        low: 1.05275
      },
      {
        timestamp: 1652106300,
        open: 1.05304,
        close: 1.05244,
        high: 1.0531,
        low: 1.05226
      },
      {
        timestamp: 1652106600,
        open: 1.05245,
        close: 1.05294,
        high: 1.05308,
        low: 1.05239
      },
      {
        timestamp: 1652106900,
        open: 1.05296,
        close: 1.05222,
        high: 1.05299,
        low: 1.05214
      },
      {
        timestamp: 1652107200,
        open: 1.05223,
        close: 1.05268,
        high: 1.05279,
        low: 1.05223
      },
      {
        timestamp: 1652107500,
        open: 1.05269,
        close: 1.05197,
        high: 1.05275,
        low: 1.05178
      },
      {
        timestamp: 1652107800,
        open: 1.05198,
        close: 1.05281,
        high: 1.05309,
        low: 1.05198
      },
      {
        timestamp: 1652108100,
        open: 1.05281,
        close: 1.05284,
        high: 1.05311,
        low: 1.05251
      },
      {
        timestamp: 1652108400,
        open: 1.05284,
        close: 1.05306,
        high: 1.05336,
        low: 1.05275
      },
      {
        timestamp: 1652108700,
        open: 1.05306,
        close: 1.05347,
        high: 1.05355,
        low: 1.05297
      },
      {
        timestamp: 1652109000,
        open: 1.05347,
        close: 1.05352,
        high: 1.05355,
        low: 1.05325
      },
      {
        timestamp: 1652109300,
        open: 1.05352,
        close: 1.05346,
        high: 1.05359,
        low: 1.05327
      },
      {
        timestamp: 1652109600,
        open: 1.05346,
        close: 1.05348,
        high: 1.05359,
        low: 1.05328
      },
      {
        timestamp: 1652109900,
        open: 1.05347,
        close: 1.05338,
        high: 1.05359,
        low: 1.05327
      },
      {
        timestamp: 1652110200,
        open: 1.05338,
        close: 1.05356,
        high: 1.05362,
        low: 1.05326
      },
      {
        timestamp: 1652110500,
        open: 1.05356,
        close: 1.05447,
        high: 1.0545,
        low: 1.0533
      },
      {
        timestamp: 1652110800,
        open: 1.05446,
        close: 1.05451,
        high: 1.05463,
        low: 1.0541
      },
      {
        timestamp: 1652111100,
        open: 1.05452,
        close: 1.05508,
        high: 1.05549,
        low: 1.05446
      },
      {
        timestamp: 1652111400,
        open: 1.0551,
        close: 1.05588,
        high: 1.05602,
        low: 1.0551
      },
      {
        timestamp: 1652111700,
        open: 1.05589,
        close: 1.05576,
        high: 1.05637,
        low: 1.05536
      },
      {
        timestamp: 1652112000,
        open: 1.05575,
        close: 1.05573,
        high: 1.056,
        low: 1.05556
      },
      {
        timestamp: 1652112300,
        open: 1.05572,
        close: 1.05633,
        high: 1.05652,
        low: 1.0557
      },
      {
        timestamp: 1652112600,
        open: 1.05632,
        close: 1.05803,
        high: 1.05805,
        low: 1.05632
      },
      {
        timestamp: 1652112900,
        open: 1.05804,
        close: 1.05828,
        high: 1.05847,
        low: 1.05799
      },
      {
        timestamp: 1652113200,
        open: 1.05827,
        close: 1.05873,
        high: 1.05888,
        low: 1.0581
      },
      {
        timestamp: 1652113500,
        open: 1.05874,
        close: 1.05907,
        high: 1.0593,
        low: 1.05857
      },
      {
        timestamp: 1652113800,
        open: 1.05907,
        close: 1.05905,
        high: 1.0593,
        low: 1.05881
      },
      {
        timestamp: 1652114100,
        open: 1.05905,
        close: 1.05857,
        high: 1.05905,
        low: 1.05852
      },
      {
        timestamp: 1652114400,
        open: 1.05857,
        close: 1.05853,
        high: 1.05878,
        low: 1.05848
      },
      {
        timestamp: 1652114700,
        open: 1.05851,
        close: 1.05812,
        high: 1.05853,
        low: 1.05798
      },
      {
        timestamp: 1652115000,
        open: 1.05812,
        close: 1.05767,
        high: 1.05826,
        low: 1.05767
      },
      {
        timestamp: 1652115300,
        open: 1.05767,
        close: 1.05742,
        high: 1.05779,
        low: 1.05741
      },
      {
        timestamp: 1652115600,
        open: 1.0574,
        close: 1.05746,
        high: 1.05758,
        low: 1.05716
      },
      {
        timestamp: 1652115900,
        open: 1.05746,
        close: 1.05733,
        high: 1.05753,
        low: 1.05726
      },
      {
        timestamp: 1652116200,
        open: 1.05732,
        close: 1.05648,
        high: 1.05732,
        low: 1.05634
      },
      {
        timestamp: 1652116500,
        open: 1.05647,
        close: 1.05673,
        high: 1.05691,
        low: 1.05637
      },
      {
        timestamp: 1652116800,
        open: 1.05671,
        close: 1.05618,
        high: 1.05673,
        low: 1.05612
      },
      {
        timestamp: 1652117100,
        open: 1.05619,
        close: 1.05684,
        high: 1.05695,
        low: 1.05613
      },
      {
        timestamp: 1652117400,
        open: 1.05685,
        close: 1.0567,
        high: 1.05708,
        low: 1.05652
      },
      {
        timestamp: 1652117700,
        open: 1.05668,
        close: 1.05587,
        high: 1.05668,
        low: 1.05585
      },
      {
        timestamp: 1652118000,
        open: 1.05586,
        close: 1.05587,
        high: 1.05613,
        low: 1.05551
      },
      {
        timestamp: 1652118300,
        open: 1.05588,
        close: 1.05575,
        high: 1.05607,
        low: 1.05541
      },
      {
        timestamp: 1652118600,
        open: 1.05575,
        close: 1.05574,
        high: 1.05579,
        low: 1.05534
      },
      {
        timestamp: 1652118900,
        open: 1.05574,
        close: 1.05591,
        high: 1.05643,
        low: 1.05564
      },
      {
        timestamp: 1652119200,
        open: 1.0559,
        close: 1.05622,
        high: 1.05627,
        low: 1.05579
      },
      {
        timestamp: 1652119500,
        open: 1.05623,
        close: 1.05594,
        high: 1.05627,
        low: 1.05589
      },
      {
        timestamp: 1652119800,
        open: 1.05594,
        close: 1.05604,
        high: 1.05615,
        low: 1.05575
      },
      {
        timestamp: 1652120100,
        open: 1.05603,
        close: 1.05637,
        high: 1.05675,
        low: 1.05602
      },
      {
        timestamp: 1652120400,
        open: 1.05636,
        close: 1.05571,
        high: 1.05641,
        low: 1.05567
      },
      {
        timestamp: 1652120700,
        open: 1.05571,
        close: 1.05539,
        high: 1.05571,
        low: 1.0553
      },
      {
        timestamp: 1652121000,
        open: 1.05538,
        close: 1.05555,
        high: 1.05567,
        low: 1.05513
      },
      {
        timestamp: 1652121300,
        open: 1.05556,
        close: 1.05576,
        high: 1.05596,
        low: 1.05552
      },
      {
        timestamp: 1652121600,
        open: 1.05575,
        close: 1.05596,
        high: 1.05612,
        low: 1.05572
      },
      {
        timestamp: 1652121900,
        open: 1.05596,
        close: 1.05578,
        high: 1.05639,
        low: 1.0557
      },
      {
        timestamp: 1652122200,
        open: 1.05578,
        close: 1.05627,
        high: 1.05639,
        low: 1.05562
      },
      {
        timestamp: 1652122500,
        open: 1.05628,
        close: 1.05667,
        high: 1.05674,
        low: 1.05626
      },
      {
        timestamp: 1652122800,
        open: 1.05665,
        close: 1.05663,
        high: 1.05699,
        low: 1.05638
      },
      {
        timestamp: 1652123100,
        open: 1.05663,
        close: 1.05715,
        high: 1.05754,
        low: 1.05659
      },
      {
        timestamp: 1652123400,
        open: 1.05715,
        close: 1.05743,
        high: 1.05764,
        low: 1.05714
      },
      {
        timestamp: 1652123700,
        open: 1.05743,
        close: 1.05677,
        high: 1.05744,
        low: 1.05669
      },
      {
        timestamp: 1652124000,
        open: 1.05677,
        close: 1.05675,
        high: 1.05695,
        low: 1.05665
      },
      {
        timestamp: 1652124300,
        open: 1.05674,
        close: 1.05618,
        high: 1.05674,
        low: 1.05595
      },
      {
        timestamp: 1652124600,
        open: 1.05618,
        close: 1.05624,
        high: 1.05624,
        low: 1.05586
      },
      {
        timestamp: 1652124900,
        open: 1.05623,
        close: 1.05607,
        high: 1.05642,
        low: 1.05598
      },
      {
        timestamp: 1652125200,
        open: 1.05607,
        close: 1.05613,
        high: 1.05631,
        low: 1.05581
      },
      {
        timestamp: 1652125500,
        open: 1.05613,
        close: 1.05636,
        high: 1.05648,
        low: 1.05592
      },
      {
        timestamp: 1652125800,
        open: 1.05637,
        close: 1.0557,
        high: 1.05647,
        low: 1.05568
      },
      {
        timestamp: 1652126100,
        open: 1.0557,
        close: 1.0559,
        high: 1.05627,
        low: 1.05569
      },
      {
        timestamp: 1652126400,
        open: 1.05586,
        close: 1.05611,
        high: 1.0563,
        low: 1.05584
      },
      {
        timestamp: 1652126700,
        open: 1.05611,
        close: 1.05606,
        high: 1.05626,
        low: 1.05604
      },
      {
        timestamp: 1652127000,
        open: 1.05606,
        close: 1.05605,
        high: 1.05624,
        low: 1.05603
      },
      {
        timestamp: 1652127300,
        open: 1.05606,
        close: 1.05607,
        high: 1.05614,
        low: 1.05605
      },
      {
        timestamp: 1652127600,
        open: 1.05606,
        close: 1.0561,
        high: 1.0561,
        low: 1.05603
      },
      {
        timestamp: 1652127900,
        open: 1.0561,
        close: 1.05583,
        high: 1.05614,
        low: 1.05578
      },
      {
        timestamp: 1652128200,
        open: 1.05582,
        close: 1.05598,
        high: 1.05603,
        low: 1.05581
      },
      {
        timestamp: 1652128500,
        open: 1.05598,
        close: 1.0561,
        high: 1.05615,
        low: 1.05598
      },
      {
        timestamp: 1652128800,
        open: 1.05608,
        close: 1.05614,
        high: 1.05621,
        low: 1.05605
      },
      {
        timestamp: 1652129100,
        open: 1.05615,
        close: 1.05615,
        high: 1.05619,
        low: 1.05606
      },
      {
        timestamp: 1652129400,
        open: 1.05614,
        close: 1.05611,
        high: 1.05615,
        low: 1.05605
      },
      {
        timestamp: 1652129700,
        open: 1.05611,
        close: 1.05655,
        high: 1.05655,
        low: 1.05587
      },
      {
        timestamp: 1652130000,
        open: 1.05655,
        close: 1.05644,
        high: 1.05655,
        low: 1.05642
      },
      {
        timestamp: 1652130300,
        open: 1.05639,
        close: 1.0564,
        high: 1.05644,
        low: 1.05635
      },
      {
        timestamp: 1652130600,
        open: 1.0564,
        close: 1.0564,
        high: 1.0566,
        low: 1.05578
      },
      {
        timestamp: 1652130900,
        open: 1.05641,
        close: 1.05646,
        high: 1.05686,
        low: 1.05548
      },
      {
        timestamp: 1652131200,
        open: 1.05646,
        close: 1.05625,
        high: 1.05659,
        low: 1.05624
      },
      {
        timestamp: 1652131500,
        open: 1.05616,
        close: 1.056,
        high: 1.05616,
        low: 1.056
      },
      {
        timestamp: 1652131800,
        open: 1.05596,
        close: 1.056,
        high: 1.05604,
        low: 1.05571
      },
      {
        timestamp: 1652132100,
        open: 1.05597,
        close: 1.056,
        high: 1.056,
        low: 1.05593
      },
      {
        timestamp: 1652132400,
        open: 1.05596,
        close: 1.056,
        high: 1.056,
        low: 1.05591
      },
      {
        timestamp: 1652132700,
        open: 1.056,
        close: 1.05598,
        high: 1.05607,
        low: 1.05591
      },
      {
        timestamp: 1652133000,
        open: 1.05597,
        close: 1.05595,
        high: 1.056,
        low: 1.05591
      },
      {
        timestamp: 1652133300,
        open: 1.05596,
        close: 1.056,
        high: 1.056,
        low: 1.05595
      },
      {
        timestamp: 1652133600,
        open: 1.05601,
        close: 1.05569,
        high: 1.05607,
        low: 1.05569
      },
      {
        timestamp: 1652133900,
        open: 1.05569,
        close: 1.05569,
        high: 1.0558,
        low: 1.05567
      },
      {
        timestamp: 1652134200,
        open: 1.05569,
        close: 1.05552,
        high: 1.05571,
        low: 1.05543
      },
      {
        timestamp: 1652134500,
        open: 1.05551,
        close: 1.0555,
        high: 1.0556,
        low: 1.05533
      },
      {
        timestamp: 1652134800,
        open: 1.0555,
        close: 1.05561,
        high: 1.05561,
        low: 1.0555
      },
      {
        timestamp: 1652135100,
        open: 1.0556,
        close: 1.05565,
        high: 1.05581,
        low: 1.05558
      },
      {
        timestamp: 1652135400,
        open: 1.05566,
        close: 1.05575,
        high: 1.05576,
        low: 1.05559
      },
      {
        timestamp: 1652135700,
        open: 1.05574,
        close: 1.05562,
        high: 1.05578,
        low: 1.05557
      },
      {
        timestamp: 1652136000,
        open: 1.05561,
        close: 1.05569,
        high: 1.05575,
        low: 1.05558
      },
      {
        timestamp: 1652136300,
        open: 1.05568,
        close: 1.05562,
        high: 1.05569,
        low: 1.05556
      },
      {
        timestamp: 1652136600,
        open: 1.05563,
        close: 1.05577,
        high: 1.05581,
        low: 1.0556
      },
      {
        timestamp: 1652136900,
        open: 1.05577,
        close: 1.05576,
        high: 1.05591,
        low: 1.05575
      },
      {
        timestamp: 1652137200,
        open: 1.05575,
        close: 1.05603,
        high: 1.05605,
        low: 1.05575
      },
      {
        timestamp: 1652137500,
        open: 1.05602,
        close: 1.05586,
        high: 1.05602,
        low: 1.05582
      },
      {
        timestamp: 1652137800,
        open: 1.05587,
        close: 1.05591,
        high: 1.05595,
        low: 1.05582
      },
      {
        timestamp: 1652138100,
        open: 1.0559,
        close: 1.05593,
        high: 1.0561,
        low: 1.0559
      },
      {
        timestamp: 1652138400,
        open: 1.05593,
        close: 1.05599,
        high: 1.05608,
        low: 1.05592
      },
      {
        timestamp: 1652138700,
        open: 1.05598,
        close: 1.05598,
        high: 1.05603,
        low: 1.05587
      },
      {
        timestamp: 1652139000,
        open: 1.05597,
        close: 1.05617,
        high: 1.05619,
        low: 1.05589
      },
      {
        timestamp: 1652139300,
        open: 1.05617,
        close: 1.05635,
        high: 1.0564,
        low: 1.05616
      },
      {
        timestamp: 1652139600,
        open: 1.05633,
        close: 1.05691,
        high: 1.05693,
        low: 1.0563
      },
      {
        timestamp: 1652139900,
        open: 1.05691,
        close: 1.05653,
        high: 1.05699,
        low: 1.05653
      },
      {
        timestamp: 1652140200,
        open: 1.05653,
        close: 1.05645,
        high: 1.0566,
        low: 1.05636
      },
      {
        timestamp: 1652140500,
        open: 1.05645,
        close: 1.0564,
        high: 1.05665,
        low: 1.05638
      },
      {
        timestamp: 1652140800,
        open: 1.05641,
        close: 1.05624,
        high: 1.05653,
        low: 1.05563
      },
      {
        timestamp: 1652141100,
        open: 1.05623,
        close: 1.05609,
        high: 1.05652,
        low: 1.05607
      },
      {
        timestamp: 1652141400,
        open: 1.05608,
        close: 1.05571,
        high: 1.05612,
        low: 1.05563
      },
      {
        timestamp: 1652141700,
        open: 1.0557,
        close: 1.0555,
        high: 1.05597,
        low: 1.05539
      },
      {
        timestamp: 1652142000,
        open: 1.0555,
        close: 1.05566,
        high: 1.05592,
        low: 1.05542
      },
      {
        timestamp: 1652142300,
        open: 1.05566,
        close: 1.05613,
        high: 1.05629,
        low: 1.05562
      },
      {
        timestamp: 1652142600,
        open: 1.05612,
        close: 1.0564,
        high: 1.05654,
        low: 1.05608
      },
      {
        timestamp: 1652142900,
        open: 1.0564,
        close: 1.0564,
        high: 1.05659,
        low: 1.05616
      },
      {
        timestamp: 1652143200,
        open: 1.05641,
        close: 1.05608,
        high: 1.0565,
        low: 1.05593
      },
      {
        timestamp: 1652143500,
        open: 1.05607,
        close: 1.05619,
        high: 1.05648,
        low: 1.056
      },
      {
        timestamp: 1652143800,
        open: 1.05619,
        close: 1.05592,
        high: 1.05637,
        low: 1.05582
      },
      {
        timestamp: 1652144100,
        open: 1.05592,
        close: 1.05596,
        high: 1.0561,
        low: 1.05567
      },
      {
        timestamp: 1652144400,
        open: 1.05594,
        close: 1.0558,
        high: 1.05619,
        low: 1.05557
      },
      {
        timestamp: 1652144700,
        open: 1.05579,
        close: 1.0565,
        high: 1.05674,
        low: 1.05574
      },
      {
        timestamp: 1652145000,
        open: 1.0565,
        close: 1.05681,
        high: 1.05684,
        low: 1.05634
      },
      {
        timestamp: 1652145300,
        open: 1.05681,
        close: 1.05686,
        high: 1.05696,
        low: 1.05669
      },
      {
        timestamp: 1652145600,
        open: 1.05685,
        close: 1.05655,
        high: 1.05699,
        low: 1.05636
      },
      {
        timestamp: 1652145900,
        open: 1.05656,
        close: 1.05642,
        high: 1.05665,
        low: 1.05637
      },
      {
        timestamp: 1652146200,
        open: 1.05642,
        close: 1.0565,
        high: 1.05679,
        low: 1.0561
      },
      {
        timestamp: 1652146500,
        open: 1.0565,
        close: 1.05672,
        high: 1.05677,
        low: 1.05632
      },
      {
        timestamp: 1652146800,
        open: 1.05671,
        close: 1.05623,
        high: 1.05682,
        low: 1.05609
      },
      {
        timestamp: 1652147100,
        open: 1.05622,
        close: 1.05636,
        high: 1.05655,
        low: 1.05601
      },
      {
        timestamp: 1652147400,
        open: 1.05636,
        close: 1.0566,
        high: 1.05685,
        low: 1.05636
      },
      {
        timestamp: 1652147700,
        open: 1.0566,
        close: 1.05629,
        high: 1.05685,
        low: 1.05619
      },
      {
        timestamp: 1652148000,
        open: 1.0563,
        close: 1.05646,
        high: 1.05677,
        low: 1.0563
      },
      {
        timestamp: 1652148300,
        open: 1.05647,
        close: 1.05653,
        high: 1.05658,
        low: 1.05604
      },
      {
        timestamp: 1652148600,
        open: 1.05653,
        close: 1.05716,
        high: 1.05723,
        low: 1.05652
      },
      {
        timestamp: 1652148900,
        open: 1.05715,
        close: 1.05764,
        high: 1.05769,
        low: 1.05699
      },
      {
        timestamp: 1652149200,
        open: 1.05765,
        close: 1.05744,
        high: 1.05787,
        low: 1.05727
      },
      {
        timestamp: 1652149500,
        open: 1.05744,
        close: 1.05777,
        high: 1.05787,
        low: 1.05703
      },
      {
        timestamp: 1652149800,
        open: 1.05778,
        close: 1.05733,
        high: 1.0578,
        low: 1.05727
      },
      {
        timestamp: 1652150100,
        open: 1.05733,
        close: 1.05789,
        high: 1.05806,
        low: 1.05723
      },
      {
        timestamp: 1652150400,
        open: 1.05789,
        close: 1.05749,
        high: 1.05789,
        low: 1.05727
      },
      {
        timestamp: 1652150700,
        open: 1.05749,
        close: 1.0577,
        high: 1.05771,
        low: 1.05738
      },
      {
        timestamp: 1652151000,
        open: 1.05771,
        close: 1.05751,
        high: 1.05771,
        low: 1.05722
      },
      {
        timestamp: 1652151300,
        open: 1.05751,
        close: 1.05733,
        high: 1.05756,
        low: 1.05704
      },
      {
        timestamp: 1652151600,
        open: 1.05732,
        close: 1.05729,
        high: 1.05744,
        low: 1.05713
      },
      {
        timestamp: 1652151900,
        open: 1.05728,
        close: 1.05743,
        high: 1.05754,
        low: 1.05714
      },
      {
        timestamp: 1652152200,
        open: 1.05743,
        close: 1.05721,
        high: 1.05743,
        low: 1.0571
      },
      {
        timestamp: 1652152500,
        open: 1.0572,
        close: 1.05728,
        high: 1.05733,
        low: 1.05702
      },
      {
        timestamp: 1652152800,
        open: 1.05727,
        close: 1.0571,
        high: 1.05727,
        low: 1.05708
      },
      {
        timestamp: 1652153100,
        open: 1.0571,
        close: 1.05716,
        high: 1.05726,
        low: 1.05707
      },
      {
        timestamp: 1652153400,
        open: 1.05716,
        close: 1.05731,
        high: 1.05736,
        low: 1.05712
      },
      {
        timestamp: 1652153700,
        open: 1.05732,
        close: 1.05751,
        high: 1.05764,
        low: 1.05705
      },
      {
        timestamp: 1652154000,
        open: 1.0575,
        close: 1.05759,
        high: 1.0577,
        low: 1.05747
      },
      {
        timestamp: 1652154300,
        open: 1.05758,
        close: 1.05764,
        high: 1.05765,
        low: 1.05756
      },
      {
        timestamp: 1652154600,
        open: 1.05764,
        close: 1.05766,
        high: 1.0577,
        low: 1.05746
      },
      {
        timestamp: 1652154900,
        open: 1.05767,
        close: 1.05796,
        high: 1.05803,
        low: 1.05767
      },
      {
        timestamp: 1652155200,
        open: 1.05794,
        close: 1.05799,
        high: 1.058,
        low: 1.0578
      },
      {
        timestamp: 1652155500,
        open: 1.05798,
        close: 1.05795,
        high: 1.05816,
        low: 1.05795
      },
      {
        timestamp: 1652155800,
        open: 1.05795,
        close: 1.05785,
        high: 1.05798,
        low: 1.05778
      },
      {
        timestamp: 1652156100,
        open: 1.05786,
        close: 1.05782,
        high: 1.05787,
        low: 1.05766
      },
      {
        timestamp: 1652156400,
        open: 1.05782,
        close: 1.05782,
        high: 1.05806,
        low: 1.05778
      },
      {
        timestamp: 1652156700,
        open: 1.05782,
        close: 1.0575,
        high: 1.05782,
        low: 1.05749
      },
      {
        timestamp: 1652157000,
        open: 1.0575,
        close: 1.05797,
        high: 1.05812,
        low: 1.05745
      },
      {
        timestamp: 1652157300,
        open: 1.05799,
        close: 1.05803,
        high: 1.05816,
        low: 1.05791
      },
      {
        timestamp: 1652157600,
        open: 1.05802,
        close: 1.05786,
        high: 1.05815,
        low: 1.05786
      },
      {
        timestamp: 1652157900,
        open: 1.05784,
        close: 1.05807,
        high: 1.05808,
        low: 1.05764
      },
      {
        timestamp: 1652158200,
        open: 1.05806,
        close: 1.05786,
        high: 1.05807,
        low: 1.05775
      },
      {
        timestamp: 1652158500,
        open: 1.05785,
        close: 1.05765,
        high: 1.05791,
        low: 1.05763
      },
      {
        timestamp: 1652158800,
        open: 1.05764,
        close: 1.05758,
        high: 1.05786,
        low: 1.05744
      },
      {
        timestamp: 1652159100,
        open: 1.05759,
        close: 1.0577,
        high: 1.05783,
        low: 1.05751
      },
      {
        timestamp: 1652159400,
        open: 1.05769,
        close: 1.05792,
        high: 1.05798,
        low: 1.05769
      },
      {
        timestamp: 1652159700,
        open: 1.05793,
        close: 1.05788,
        high: 1.05793,
        low: 1.05756
      },
      {
        timestamp: 1652160000,
        open: 1.05788,
        close: 1.05762,
        high: 1.05788,
        low: 1.05743
      },
      {
        timestamp: 1652160300,
        open: 1.05762,
        close: 1.05795,
        high: 1.05812,
        low: 1.05752
      },
      {
        timestamp: 1652160600,
        open: 1.05795,
        close: 1.05789,
        high: 1.05803,
        low: 1.05777
      },
      {
        timestamp: 1652160900,
        open: 1.0579,
        close: 1.05802,
        high: 1.05804,
        low: 1.0577
      },
      {
        timestamp: 1652161200,
        open: 1.05803,
        close: 1.05829,
        high: 1.05861,
        low: 1.05793
      },
      {
        timestamp: 1652161500,
        open: 1.0583,
        close: 1.05835,
        high: 1.05838,
        low: 1.05804
      },
      {
        timestamp: 1652161800,
        open: 1.05836,
        close: 1.05826,
        high: 1.05851,
        low: 1.05812
      },
      {
        timestamp: 1652162100,
        open: 1.05827,
        close: 1.05771,
        high: 1.0584,
        low: 1.05766
      },
      {
        timestamp: 1652162400,
        open: 1.0577,
        close: 1.05779,
        high: 1.05799,
        low: 1.05739
      },
      {
        timestamp: 1652162700,
        open: 1.05778,
        close: 1.05723,
        high: 1.05797,
        low: 1.05714
      },
      {
        timestamp: 1652163000,
        open: 1.05723,
        close: 1.05739,
        high: 1.05754,
        low: 1.05705
      },
      {
        timestamp: 1652163300,
        open: 1.05739,
        close: 1.05719,
        high: 1.05759,
        low: 1.057
      },
      {
        timestamp: 1652163600,
        open: 1.0572,
        close: 1.05723,
        high: 1.05743,
        low: 1.05702
      },
      {
        timestamp: 1652163900,
        open: 1.05723,
        close: 1.05673,
        high: 1.05727,
        low: 1.0567
      },
      {
        timestamp: 1652164200,
        open: 1.05672,
        close: 1.0566,
        high: 1.05686,
        low: 1.0565
      },
      {
        timestamp: 1652164500,
        open: 1.0566,
        close: 1.05653,
        high: 1.05661,
        low: 1.05611
      },
      {
        timestamp: 1652164800,
        open: 1.05654,
        close: 1.05644,
        high: 1.05656,
        low: 1.05602
      },
      {
        timestamp: 1652165100,
        open: 1.05643,
        close: 1.05628,
        high: 1.05686,
        low: 1.05622
      },
      {
        timestamp: 1652165400,
        open: 1.05628,
        close: 1.05639,
        high: 1.0566,
        low: 1.05601
      },
      {
        timestamp: 1652165700,
        open: 1.05639,
        close: 1.05609,
        high: 1.05659,
        low: 1.05606
      },
      {
        timestamp: 1652166000,
        open: 1.05608,
        close: 1.05552,
        high: 1.05639,
        low: 1.05517
      },
      {
        timestamp: 1652166300,
        open: 1.05551,
        close: 1.05573,
        high: 1.05624,
        low: 1.0555
      },
      {
        timestamp: 1652166600,
        open: 1.05572,
        close: 1.05601,
        high: 1.05607,
        low: 1.05549
      },
      {
        timestamp: 1652166900,
        open: 1.056,
        close: 1.05571,
        high: 1.05622,
        low: 1.05552
      },
      {
        timestamp: 1652167200,
        open: 1.05572,
        close: 1.05633,
        high: 1.05643,
        low: 1.05559
      },
      {
        timestamp: 1652167500,
        open: 1.05634,
        close: 1.05638,
        high: 1.0568,
        low: 1.05629
      },
      {
        timestamp: 1652167800,
        open: 1.05639,
        close: 1.05662,
        high: 1.05664,
        low: 1.05578
      },
      {
        timestamp: 1652168100,
        open: 1.05662,
        close: 1.05719,
        high: 1.05722,
        low: 1.05636
      },
      {
        timestamp: 1652168400,
        open: 1.05719,
        close: 1.05764,
        high: 1.05793,
        low: 1.05719
      },
      {
        timestamp: 1652168700,
        open: 1.05765,
        close: 1.0567,
        high: 1.05784,
        low: 1.05659
      },
      {
        timestamp: 1652169000,
        open: 1.05671,
        close: 1.05667,
        high: 1.0572,
        low: 1.05661
      },
      {
        timestamp: 1652169300,
        open: 1.05667,
        close: 1.0558,
        high: 1.05672,
        low: 1.05578
      },
      {
        timestamp: 1652169600,
        open: 1.0558,
        close: 1.05669,
        high: 1.05717,
        low: 1.05574
      },
      {
        timestamp: 1652169900,
        open: 1.05668,
        close: 1.05693,
        high: 1.05696,
        low: 1.05634
      },
      {
        timestamp: 1652170200,
        open: 1.05693,
        close: 1.05649,
        high: 1.05711,
        low: 1.05636
      },
      {
        timestamp: 1652170500,
        open: 1.05648,
        close: 1.05675,
        high: 1.05698,
        low: 1.05646
      },
      {
        timestamp: 1652170800,
        open: 1.05675,
        close: 1.05604,
        high: 1.05696,
        low: 1.05602
      },
      {
        timestamp: 1652171100,
        open: 1.05603,
        close: 1.05596,
        high: 1.0561,
        low: 1.05547
      },
      {
        timestamp: 1652171400,
        open: 1.05595,
        close: 1.05639,
        high: 1.05669,
        low: 1.05594
      },
      {
        timestamp: 1652171700,
        open: 1.05639,
        close: 1.0566,
        high: 1.05681,
        low: 1.05633
      },
      {
        timestamp: 1652172000,
        open: 1.0566,
        close: 1.05663,
        high: 1.05669,
        low: 1.05622
      },
      {
        timestamp: 1652172300,
        open: 1.05664,
        close: 1.05684,
        high: 1.05736,
        low: 1.05655
      },
      {
        timestamp: 1652172600,
        open: 1.05685,
        close: 1.05687,
        high: 1.05702,
        low: 1.05658
      },
      {
        timestamp: 1652172900,
        open: 1.05688,
        close: 1.05731,
        high: 1.05763,
        low: 1.05684
      },
      {
        timestamp: 1652173200,
        open: 1.05732,
        close: 1.05723,
        high: 1.05762,
        low: 1.05709
      },
      {
        timestamp: 1652173500,
        open: 1.05721,
        close: 1.0573,
        high: 1.05764,
        low: 1.05718
      },
      {
        timestamp: 1652173800,
        open: 1.0573,
        close: 1.05724,
        high: 1.05756,
        low: 1.05711
      },
      {
        timestamp: 1652174100,
        open: 1.05724,
        close: 1.05648,
        high: 1.05738,
        low: 1.05647
      },
      {
        timestamp: 1652174400,
        open: 1.05649,
        close: 1.05602,
        high: 1.05661,
        low: 1.05591
      },
      {
        timestamp: 1652174700,
        open: 1.05602,
        close: 1.05616,
        high: 1.05632,
        low: 1.05595
      },
      {
        timestamp: 1652175000,
        open: 1.05615,
        close: 1.05631,
        high: 1.05642,
        low: 1.05611
      },
      {
        timestamp: 1652175300,
        open: 1.05632,
        close: 1.05616,
        high: 1.05637,
        low: 1.05584
      },
      {
        timestamp: 1652175600,
        open: 1.05615,
        close: 1.05559,
        high: 1.05619,
        low: 1.05529
      },
      {
        timestamp: 1652175900,
        open: 1.05559,
        close: 1.05528,
        high: 1.05604,
        low: 1.05528
      },
      {
        timestamp: 1652176200,
        open: 1.05528,
        close: 1.05407,
        high: 1.05532,
        low: 1.05388
      },
      {
        timestamp: 1652176500,
        open: 1.05407,
        close: 1.05401,
        high: 1.0543,
        low: 1.05378
      },
      {
        timestamp: 1652176800,
        open: 1.05401,
        close: 1.05382,
        high: 1.05404,
        low: 1.05362
      },
      {
        timestamp: 1652177100,
        open: 1.05381,
        close: 1.05397,
        high: 1.05411,
        low: 1.0538
      },
      {
        timestamp: 1652177400,
        open: 1.05397,
        close: 1.05444,
        high: 1.05445,
        low: 1.05384
      },
      {
        timestamp: 1652177700,
        open: 1.05443,
        close: 1.05489,
        high: 1.05491,
        low: 1.05416
      },
      {
        timestamp: 1652178000,
        open: 1.05489,
        close: 1.05453,
        high: 1.05499,
        low: 1.05442
      },
      {
        timestamp: 1652178300,
        open: 1.05452,
        close: 1.05446,
        high: 1.05468,
        low: 1.05425
      },
      {
        timestamp: 1652178600,
        open: 1.05446,
        close: 1.05461,
        high: 1.05478,
        low: 1.05436
      },
      {
        timestamp: 1652178900,
        open: 1.05461,
        close: 1.05473,
        high: 1.0548,
        low: 1.05449
      },
      {
        timestamp: 1652179200,
        open: 1.05473,
        close: 1.05472,
        high: 1.05516,
        low: 1.0547
      },
      {
        timestamp: 1652179500,
        open: 1.05472,
        close: 1.05436,
        high: 1.05477,
        low: 1.05427
      },
      {
        timestamp: 1652179800,
        open: 1.05435,
        close: 1.054,
        high: 1.05456,
        low: 1.05391
      },
      {
        timestamp: 1652180100,
        open: 1.054,
        close: 1.05539,
        high: 1.05543,
        low: 1.05398
      },
      {
        timestamp: 1652180400,
        open: 1.0554,
        close: 1.05519,
        high: 1.05566,
        low: 1.05514
      },
      {
        timestamp: 1652180700,
        open: 1.0552,
        close: 1.05513,
        high: 1.05541,
        low: 1.05477
      },
      {
        timestamp: 1652181000,
        open: 1.05513,
        close: 1.05575,
        high: 1.05575,
        low: 1.05512
      },
      {
        timestamp: 1652181300,
        open: 1.05574,
        close: 1.05569,
        high: 1.05585,
        low: 1.05558
      },
      {
        timestamp: 1652181600,
        open: 1.05569,
        close: 1.05602,
        high: 1.05631,
        low: 1.05567
      },
      {
        timestamp: 1652181900,
        open: 1.05603,
        close: 1.05622,
        high: 1.05629,
        low: 1.05585
      },
      {
        timestamp: 1652182200,
        open: 1.0562,
        close: 1.05655,
        high: 1.05665,
        low: 1.05612
      },
      {
        timestamp: 1652182500,
        open: 1.05655,
        close: 1.05655,
        high: 1.05673,
        low: 1.05627
      },
      {
        timestamp: 1652182800,
        open: 1.05654,
        close: 1.05579,
        high: 1.05659,
        low: 1.05577
      },
      {
        timestamp: 1652183100,
        open: 1.0558,
        close: 1.05593,
        high: 1.05637,
        low: 1.05578
      },
      {
        timestamp: 1652183400,
        open: 1.05593,
        close: 1.05557,
        high: 1.05638,
        low: 1.05557
      },
      {
        timestamp: 1652183700,
        open: 1.05556,
        close: 1.05539,
        high: 1.05569,
        low: 1.05507
      },
      {
        timestamp: 1652184000,
        open: 1.05538,
        close: 1.05554,
        high: 1.05569,
        low: 1.05526
      },
      {
        timestamp: 1652184300,
        open: 1.05555,
        close: 1.05524,
        high: 1.05565,
        low: 1.05507
      },
      {
        timestamp: 1652184600,
        open: 1.05523,
        close: 1.05551,
        high: 1.05552,
        low: 1.05492
      },
      {
        timestamp: 1652184900,
        open: 1.0555,
        close: 1.05543,
        high: 1.05558,
        low: 1.05521
      },
      {
        timestamp: 1652185200,
        open: 1.05544,
        close: 1.05559,
        high: 1.05561,
        low: 1.05493
      },
      {
        timestamp: 1652185500,
        open: 1.0556,
        close: 1.05575,
        high: 1.05605,
        low: 1.0556
      },
      {
        timestamp: 1652185800,
        open: 1.05577,
        close: 1.0555,
        high: 1.05581,
        low: 1.05518
      },
      {
        timestamp: 1652186100,
        open: 1.05549,
        close: 1.05618,
        high: 1.05619,
        low: 1.05538
      },
      {
        timestamp: 1652186400,
        open: 1.05617,
        close: 1.05543,
        high: 1.05617,
        low: 1.05531
      },
      {
        timestamp: 1652186700,
        open: 1.05544,
        close: 1.05521,
        high: 1.05551,
        low: 1.0549
      },
      {
        timestamp: 1652187000,
        open: 1.05522,
        close: 1.05521,
        high: 1.05564,
        low: 1.05515
      },
      {
        timestamp: 1652187300,
        open: 1.05521,
        close: 1.05521,
        high: 1.05563,
        low: 1.05511
      },
      {
        timestamp: 1652187600,
        open: 1.05522,
        close: 1.05607,
        high: 1.05609,
        low: 1.05511
      },
      {
        timestamp: 1652187900,
        open: 1.05606,
        close: 1.05703,
        high: 1.05705,
        low: 1.05597
      },
      {
        timestamp: 1652188200,
        open: 1.05703,
        close: 1.05663,
        high: 1.05758,
        low: 1.0565
      },
      {
        timestamp: 1652188500,
        open: 1.05663,
        close: 1.05642,
        high: 1.05669,
        low: 1.05616
      },
      {
        timestamp: 1652188800,
        open: 1.0564,
        close: 1.05571,
        high: 1.05643,
        low: 1.05536
      },
      {
        timestamp: 1652189100,
        open: 1.05571,
        close: 1.05589,
        high: 1.0561,
        low: 1.0554
      },
      {
        timestamp: 1652189400,
        open: 1.0559,
        close: 1.05579,
        high: 1.05624,
        low: 1.05569
      },
      {
        timestamp: 1652189700,
        open: 1.05579,
        close: 1.05586,
        high: 1.05622,
        low: 1.05569
      },
      {
        timestamp: 1652190000,
        open: 1.05586,
        close: 1.05572,
        high: 1.0559,
        low: 1.05558
      },
      {
        timestamp: 1652190300,
        open: 1.05572,
        close: 1.05451,
        high: 1.05572,
        low: 1.05442
      },
      {
        timestamp: 1652190600,
        open: 1.05452,
        close: 1.05436,
        high: 1.05472,
        low: 1.05433
      },
      {
        timestamp: 1652190900,
        open: 1.05435,
        close: 1.05497,
        high: 1.05509,
        low: 1.05428
      },
      {
        timestamp: 1652191200,
        open: 1.05497,
        close: 1.05502,
        high: 1.05573,
        low: 1.05486
      },
      {
        timestamp: 1652191500,
        open: 1.05501,
        close: 1.05452,
        high: 1.05516,
        low: 1.05438
      },
      {
        timestamp: 1652191800,
        open: 1.05451,
        close: 1.05478,
        high: 1.05546,
        low: 1.0542
      },
      {
        timestamp: 1652192100,
        open: 1.05481,
        close: 1.05415,
        high: 1.05488,
        low: 1.05358
      },
      {
        timestamp: 1652192400,
        open: 1.05416,
        close: 1.05457,
        high: 1.05471,
        low: 1.05402
      },
      {
        timestamp: 1652192700,
        open: 1.05458,
        close: 1.05441,
        high: 1.05471,
        low: 1.05409
      },
      {
        timestamp: 1652193000,
        open: 1.0544,
        close: 1.05352,
        high: 1.05442,
        low: 1.05352
      },
      {
        timestamp: 1652193300,
        open: 1.05354,
        close: 1.05388,
        high: 1.05394,
        low: 1.05328
      },
      {
        timestamp: 1652193600,
        open: 1.05388,
        close: 1.05429,
        high: 1.05435,
        low: 1.05373
      },
      {
        timestamp: 1652193900,
        open: 1.0543,
        close: 1.05352,
        high: 1.05433,
        low: 1.05352
      },
      {
        timestamp: 1652194200,
        open: 1.05352,
        close: 1.05383,
        high: 1.05399,
        low: 1.05326
      },
      {
        timestamp: 1652194500,
        open: 1.05378,
        close: 1.05381,
        high: 1.05424,
        low: 1.0536
      },
      {
        timestamp: 1652194800,
        open: 1.0538,
        close: 1.05309,
        high: 1.0538,
        low: 1.05309
      },
      {
        timestamp: 1652195100,
        open: 1.05309,
        close: 1.05353,
        high: 1.05376,
        low: 1.05282
      },
      {
        timestamp: 1652195400,
        open: 1.05353,
        close: 1.05437,
        high: 1.0545,
        low: 1.05353
      },
      {
        timestamp: 1652195700,
        open: 1.05436,
        close: 1.05434,
        high: 1.05489,
        low: 1.05427
      },
      {
        timestamp: 1652196000,
        open: 1.05433,
        close: 1.05413,
        high: 1.0545,
        low: 1.05392
      },
      {
        timestamp: 1652196300,
        open: 1.05413,
        close: 1.05448,
        high: 1.05449,
        low: 1.05403
      },
      {
        timestamp: 1652196600,
        open: 1.05448,
        close: 1.0539,
        high: 1.05459,
        low: 1.05389
      },
      {
        timestamp: 1652196900,
        open: 1.05389,
        close: 1.0533,
        high: 1.05389,
        low: 1.0529
      },
      {
        timestamp: 1652197200,
        open: 1.05329,
        close: 1.05266,
        high: 1.05374,
        low: 1.05263
      },
      {
        timestamp: 1652197500,
        open: 1.05265,
        close: 1.05321,
        high: 1.05344,
        low: 1.05262
      },
      {
        timestamp: 1652197800,
        open: 1.05324,
        close: 1.05341,
        high: 1.05344,
        low: 1.05306
      },
      {
        timestamp: 1652198100,
        open: 1.05342,
        close: 1.05333,
        high: 1.05372,
        low: 1.0529
      },
      {
        timestamp: 1652198400,
        open: 1.05333,
        close: 1.0534,
        high: 1.05354,
        low: 1.05305
      },
      {
        timestamp: 1652198700,
        open: 1.0534,
        close: 1.05395,
        high: 1.05419,
        low: 1.05338
      },
      {
        timestamp: 1652199000,
        open: 1.05393,
        close: 1.05392,
        high: 1.05431,
        low: 1.05344
      },
      {
        timestamp: 1652199300,
        open: 1.05391,
        close: 1.05447,
        high: 1.05474,
        low: 1.05381
      },
      {
        timestamp: 1652199600,
        open: 1.05447,
        close: 1.05413,
        high: 1.05465,
        low: 1.05378
      },
      {
        timestamp: 1652199900,
        open: 1.05411,
        close: 1.05421,
        high: 1.05426,
        low: 1.0536
      },
      {
        timestamp: 1652200200,
        open: 1.0542,
        close: 1.05372,
        high: 1.05437,
        low: 1.05355
      },
      {
        timestamp: 1652200500,
        open: 1.05372,
        close: 1.05409,
        high: 1.05411,
        low: 1.0536
      },
      {
        timestamp: 1652200800,
        open: 1.05408,
        close: 1.05418,
        high: 1.05422,
        low: 1.05385
      },
      {
        timestamp: 1652201100,
        open: 1.05417,
        close: 1.05442,
        high: 1.05452,
        low: 1.05408
      },
      {
        timestamp: 1652201400,
        open: 1.05443,
        close: 1.05421,
        high: 1.05475,
        low: 1.0542
      },
      {
        timestamp: 1652201700,
        open: 1.05421,
        close: 1.05353,
        high: 1.05432,
        low: 1.05347
      },
      {
        timestamp: 1652202000,
        open: 1.05353,
        close: 1.05367,
        high: 1.0537,
        low: 1.05313
      },
      {
        timestamp: 1652202300,
        open: 1.05368,
        close: 1.05371,
        high: 1.05384,
        low: 1.05354
      },
      {
        timestamp: 1652202600,
        open: 1.05371,
        close: 1.05333,
        high: 1.05371,
        low: 1.05329
      },
      {
        timestamp: 1652202900,
        open: 1.05333,
        close: 1.05316,
        high: 1.05336,
        low: 1.05284
      },
      {
        timestamp: 1652203200,
        open: 1.05317,
        close: 1.05282,
        high: 1.05327,
        low: 1.05274
      },
      {
        timestamp: 1652203500,
        open: 1.05282,
        close: 1.05328,
        high: 1.05333,
        low: 1.05271
      },
      {
        timestamp: 1652203800,
        open: 1.05329,
        close: 1.05318,
        high: 1.05331,
        low: 1.05291
      },
      {
        timestamp: 1652204100,
        open: 1.05318,
        close: 1.05349,
        high: 1.0537,
        low: 1.05316
      },
      {
        timestamp: 1652204400,
        open: 1.0535,
        close: 1.05355,
        high: 1.05365,
        low: 1.05339
      },
      {
        timestamp: 1652204700,
        open: 1.05354,
        close: 1.05359,
        high: 1.0538,
        low: 1.0535
      },
      {
        timestamp: 1652205000,
        open: 1.05359,
        close: 1.05387,
        high: 1.05413,
        low: 1.05353
      },
      {
        timestamp: 1652205300,
        open: 1.05387,
        close: 1.05382,
        high: 1.05421,
        low: 1.05382
      },
      {
        timestamp: 1652205600,
        open: 1.05382,
        close: 1.05401,
        high: 1.05406,
        low: 1.05372
      },
      {
        timestamp: 1652205900,
        open: 1.05402,
        close: 1.05367,
        high: 1.05408,
        low: 1.05367
      },
      {
        timestamp: 1652206200,
        open: 1.05368,
        close: 1.05377,
        high: 1.05391,
        low: 1.05362
      },
      {
        timestamp: 1652206500,
        open: 1.05377,
        close: 1.05366,
        high: 1.05378,
        low: 1.05357
      },
      {
        timestamp: 1652206800,
        open: 1.05366,
        close: 1.05365,
        high: 1.05388,
        low: 1.05363
      },
      {
        timestamp: 1652207100,
        open: 1.05366,
        close: 1.05332,
        high: 1.05367,
        low: 1.05327
      },
      {
        timestamp: 1652207400,
        open: 1.05331,
        close: 1.05309,
        high: 1.05332,
        low: 1.05292
      },
      {
        timestamp: 1652207700,
        open: 1.0531,
        close: 1.05296,
        high: 1.0532,
        low: 1.05292
      },
      {
        timestamp: 1652208000,
        open: 1.05297,
        close: 1.0535,
        high: 1.05354,
        low: 1.05281
      },
      {
        timestamp: 1652208300,
        open: 1.05351,
        close: 1.05337,
        high: 1.05351,
        low: 1.05315
      },
      {
        timestamp: 1652208600,
        open: 1.05338,
        close: 1.05339,
        high: 1.05359,
        low: 1.05322
      },
      {
        timestamp: 1652208900,
        open: 1.05338,
        close: 1.05313,
        high: 1.0534,
        low: 1.05307
      },
      {
        timestamp: 1652209200,
        open: 1.05314,
        close: 1.05335,
        high: 1.05351,
        low: 1.05311
      },
      {
        timestamp: 1652209500,
        open: 1.05334,
        close: 1.05356,
        high: 1.05394,
        low: 1.05329
      },
      {
        timestamp: 1652209800,
        open: 1.05356,
        close: 1.05361,
        high: 1.05378,
        low: 1.05335
      },
      {
        timestamp: 1652210100,
        open: 1.05361,
        close: 1.05362,
        high: 1.05372,
        low: 1.05327
      },
      {
        timestamp: 1652210400,
        open: 1.05362,
        close: 1.05369,
        high: 1.0538,
        low: 1.05355
      },
      {
        timestamp: 1652210700,
        open: 1.05369,
        close: 1.05342,
        high: 1.05371,
        low: 1.05331
      },
      {
        timestamp: 1652211000,
        open: 1.05342,
        close: 1.05334,
        high: 1.05359,
        low: 1.05325
      },
      {
        timestamp: 1652211300,
        open: 1.05333,
        close: 1.05322,
        high: 1.05339,
        low: 1.05313
      },
      {
        timestamp: 1652211600,
        open: 1.05322,
        close: 1.05306,
        high: 1.05322,
        low: 1.05295
      },
      {
        timestamp: 1652211900,
        open: 1.05306,
        close: 1.05299,
        high: 1.0531,
        low: 1.0529
      },
      {
        timestamp: 1652212200,
        open: 1.05299,
        close: 1.05335,
        high: 1.05337,
        low: 1.05295
      },
      {
        timestamp: 1652212500,
        open: 1.05338,
        close: 1.05362,
        high: 1.05364,
        low: 1.05315
      },
      {
        timestamp: 1652212800,
        open: 1.05361,
        close: 1.05317,
        high: 1.05364,
        low: 1.05315
      },
      {
        timestamp: 1652213100,
        open: 1.05317,
        close: 1.05306,
        high: 1.05332,
        low: 1.05304
      },
      {
        timestamp: 1652213400,
        open: 1.05306,
        close: 1.05291,
        high: 1.05311,
        low: 1.05282
      },
      {
        timestamp: 1652213700,
        open: 1.05291,
        close: 1.05305,
        high: 1.05314,
        low: 1.0529
      },
      {
        timestamp: 1652214000,
        open: 1.05305,
        close: 1.05313,
        high: 1.0532,
        low: 1.05305
      },
      {
        timestamp: 1652214300,
        open: 1.05313,
        close: 1.0531,
        high: 1.05322,
        low: 1.05307
      },
      {
        timestamp: 1652214600,
        open: 1.0531,
        close: 1.05318,
        high: 1.0532,
        low: 1.05308
      },
      {
        timestamp: 1652214900,
        open: 1.05317,
        close: 1.05309,
        high: 1.05319,
        low: 1.05307
      },
      {
        timestamp: 1652215200,
        open: 1.05308,
        close: 1.05308,
        high: 1.05315,
        low: 1.05303
      },
      {
        timestamp: 1652215500,
        open: 1.05308,
        close: 1.05291,
        high: 1.05315,
        low: 1.05291
      },
      {
        timestamp: 1652215800,
        open: 1.05292,
        close: 1.05292,
        high: 1.05297,
        low: 1.05285
      },
      {
        timestamp: 1652216100,
        open: 1.05292,
        close: 1.05307,
        high: 1.05316,
        low: 1.05285
      },
      {
        timestamp: 1652216400,
        open: 1.0531,
        close: 1.05314,
        high: 1.05319,
        low: 1.0531
      },
      {
        timestamp: 1652216700,
        open: 1.05373,
        close: 1.05371,
        high: 1.05373,
        low: 1.05371
      },
      {
        timestamp: 1652217000,
        open: 1.05371,
        close: 1.05332,
        high: 1.05379,
        low: 1.0532
      },
      {
        timestamp: 1652217300,
        open: 1.05331,
        close: 1.05315,
        high: 1.05331,
        low: 1.05314
      },
      {
        timestamp: 1652217600,
        open: 1.05316,
        close: 1.05325,
        high: 1.05332,
        low: 1.05311
      },
      {
        timestamp: 1652217900,
        open: 1.0532,
        close: 1.05321,
        high: 1.05321,
        low: 1.0532
      },
      {
        timestamp: 1652218200,
        open: 1.05315,
        close: 1.05309,
        high: 1.05335,
        low: 1.053
      },
      {
        timestamp: 1652218500,
        open: 1.05316,
        close: 1.05339,
        high: 1.0535,
        low: 1.05309
      },
      {
        timestamp: 1652218800,
        open: 1.0534,
        close: 1.05326,
        high: 1.05344,
        low: 1.05323
      },
      {
        timestamp: 1652219100,
        open: 1.05325,
        close: 1.05319,
        high: 1.05348,
        low: 1.05312
      },
      {
        timestamp: 1652219400,
        open: 1.05318,
        close: 1.05318,
        high: 1.05339,
        low: 1.05317
      },
      {
        timestamp: 1652219700,
        open: 1.05332,
        close: 1.05321,
        high: 1.05344,
        low: 1.05315
      },
      {
        timestamp: 1652220000,
        open: 1.0532,
        close: 1.05318,
        high: 1.05343,
        low: 1.05312
      },
      {
        timestamp: 1652220300,
        open: 1.05321,
        close: 1.0531,
        high: 1.05321,
        low: 1.05309
      },
      {
        timestamp: 1652220600,
        open: 1.0531,
        close: 1.05321,
        high: 1.05322,
        low: 1.0531
      },
      {
        timestamp: 1652220900,
        open: 1.0532,
        close: 1.05326,
        high: 1.05326,
        low: 1.05315
      },
      {
        timestamp: 1652221200,
        open: 1.05326,
        close: 1.05334,
        high: 1.05341,
        low: 1.05323
      },
      {
        timestamp: 1652221500,
        open: 1.05335,
        close: 1.05344,
        high: 1.05347,
        low: 1.05331
      },
      {
        timestamp: 1652221800,
        open: 1.05343,
        close: 1.05344,
        high: 1.05356,
        low: 1.05341
      },
      {
        timestamp: 1652222100,
        open: 1.05344,
        close: 1.05351,
        high: 1.05356,
        low: 1.05341
      },
      {
        timestamp: 1652222400,
        open: 1.0535,
        close: 1.05353,
        high: 1.05358,
        low: 1.05338
      },
      {
        timestamp: 1652222700,
        open: 1.05354,
        close: 1.05354,
        high: 1.05355,
        low: 1.05348
      },
      {
        timestamp: 1652223000,
        open: 1.05354,
        close: 1.05342,
        high: 1.05354,
        low: 1.05339
      },
      {
        timestamp: 1652223300,
        open: 1.05342,
        close: 1.05338,
        high: 1.05347,
        low: 1.05334
      },
      {
        timestamp: 1652223600,
        open: 1.05339,
        close: 1.05338,
        high: 1.05346,
        low: 1.05326
      },
      {
        timestamp: 1652223900,
        open: 1.05338,
        close: 1.05338,
        high: 1.05344,
        low: 1.05337
      },
      {
        timestamp: 1652224200,
        open: 1.05337,
        close: 1.05336,
        high: 1.05343,
        low: 1.05329
      },
      {
        timestamp: 1652224500,
        open: 1.05337,
        close: 1.05333,
        high: 1.05343,
        low: 1.05323
      },
      {
        timestamp: 1652224800,
        open: 1.05332,
        close: 1.05342,
        high: 1.05343,
        low: 1.05329
      },
      {
        timestamp: 1652225100,
        open: 1.05342,
        close: 1.05352,
        high: 1.05352,
        low: 1.05338
      },
      {
        timestamp: 1652225400,
        open: 1.05352,
        close: 1.05343,
        high: 1.05353,
        low: 1.0534
      },
      {
        timestamp: 1652225700,
        open: 1.05343,
        close: 1.05323,
        high: 1.05344,
        low: 1.05318
      },
      {
        timestamp: 1652226000,
        open: 1.05324,
        close: 1.05327,
        high: 1.05328,
        low: 1.05303
      },
      {
        timestamp: 1652226300,
        open: 1.05327,
        close: 1.05305,
        high: 1.05329,
        low: 1.05303
      },
      {
        timestamp: 1652226600,
        open: 1.05306,
        close: 1.05306,
        high: 1.05317,
        low: 1.05302
      },
      {
        timestamp: 1652226900,
        open: 1.05307,
        close: 1.05296,
        high: 1.05315,
        low: 1.05295
      },
      {
        timestamp: 1652227200,
        open: 1.05296,
        close: 1.05279,
        high: 1.05307,
        low: 1.05277
      },
      {
        timestamp: 1652227500,
        open: 1.05279,
        close: 1.05289,
        high: 1.05295,
        low: 1.05277
      },
      {
        timestamp: 1652227800,
        open: 1.05288,
        close: 1.05327,
        high: 1.05337,
        low: 1.05279
      },
      {
        timestamp: 1652228100,
        open: 1.05328,
        close: 1.05324,
        high: 1.05336,
        low: 1.05319
      },
      {
        timestamp: 1652228400,
        open: 1.05324,
        close: 1.05323,
        high: 1.05333,
        low: 1.05317
      },
      {
        timestamp: 1652228700,
        open: 1.05323,
        close: 1.05333,
        high: 1.05345,
        low: 1.0532
      },
      {
        timestamp: 1652229000,
        open: 1.05331,
        close: 1.05371,
        high: 1.05374,
        low: 1.0533
      },
      {
        timestamp: 1652229300,
        open: 1.05371,
        close: 1.05366,
        high: 1.05372,
        low: 1.05354
      },
      {
        timestamp: 1652229600,
        open: 1.05365,
        close: 1.0537,
        high: 1.05371,
        low: 1.05357
      },
      {
        timestamp: 1652229900,
        open: 1.05369,
        close: 1.05352,
        high: 1.05369,
        low: 1.05333
      },
      {
        timestamp: 1652230200,
        open: 1.05352,
        close: 1.05357,
        high: 1.05359,
        low: 1.05335
      },
      {
        timestamp: 1652230500,
        open: 1.05359,
        close: 1.05322,
        high: 1.05359,
        low: 1.05319
      },
      {
        timestamp: 1652230800,
        open: 1.05324,
        close: 1.05322,
        high: 1.05334,
        low: 1.05303
      },
      {
        timestamp: 1652231100,
        open: 1.05322,
        close: 1.0529,
        high: 1.05333,
        low: 1.05275
      },
      {
        timestamp: 1652231400,
        open: 1.05289,
        close: 1.05272,
        high: 1.05304,
        low: 1.05269
      },
      {
        timestamp: 1652231700,
        open: 1.05273,
        close: 1.05266,
        high: 1.053,
        low: 1.05263
      },
      {
        timestamp: 1652232000,
        open: 1.05266,
        close: 1.05279,
        high: 1.053,
        low: 1.05266
      },
      {
        timestamp: 1652232300,
        open: 1.05277,
        close: 1.05316,
        high: 1.05317,
        low: 1.05268
      },
      {
        timestamp: 1652232600,
        open: 1.05317,
        close: 1.05292,
        high: 1.05328,
        low: 1.05289
      },
      {
        timestamp: 1652232900,
        open: 1.05291,
        close: 1.0529,
        high: 1.05297,
        low: 1.05263
      },
      {
        timestamp: 1652233200,
        open: 1.05289,
        close: 1.05305,
        high: 1.05321,
        low: 1.05288
      },
      {
        timestamp: 1652233500,
        open: 1.05306,
        close: 1.05323,
        high: 1.05341,
        low: 1.05304
      },
      {
        timestamp: 1652233800,
        open: 1.05322,
        close: 1.05343,
        high: 1.05344,
        low: 1.05322
      },
      {
        timestamp: 1652234100,
        open: 1.05342,
        close: 1.05344,
        high: 1.05356,
        low: 1.05331
      },
      {
        timestamp: 1652234400,
        open: 1.05345,
        close: 1.05346,
        high: 1.0536,
        low: 1.0533
      },
      {
        timestamp: 1652234700,
        open: 1.05347,
        close: 1.05366,
        high: 1.05384,
        low: 1.05328
      },
      {
        timestamp: 1652235000,
        open: 1.05367,
        close: 1.05374,
        high: 1.05381,
        low: 1.05354
      },
      {
        timestamp: 1652235300,
        open: 1.05374,
        close: 1.05372,
        high: 1.05384,
        low: 1.05351
      },
      {
        timestamp: 1652235600,
        open: 1.05373,
        close: 1.0537,
        high: 1.0538,
        low: 1.05364
      },
      {
        timestamp: 1652235900,
        open: 1.05371,
        close: 1.0535,
        high: 1.05376,
        low: 1.05339
      },
      {
        timestamp: 1652236200,
        open: 1.05349,
        close: 1.05317,
        high: 1.05357,
        low: 1.05312
      },
      {
        timestamp: 1652236500,
        open: 1.05317,
        close: 1.05317,
        high: 1.05325,
        low: 1.05309
      },
      {
        timestamp: 1652236800,
        open: 1.05316,
        close: 1.0534,
        high: 1.05352,
        low: 1.05316
      },
      {
        timestamp: 1652237100,
        open: 1.05339,
        close: 1.05326,
        high: 1.05339,
        low: 1.05298
      },
      {
        timestamp: 1652237400,
        open: 1.05326,
        close: 1.05346,
        high: 1.05353,
        low: 1.05321
      },
      {
        timestamp: 1652237700,
        open: 1.05344,
        close: 1.05354,
        high: 1.05356,
        low: 1.05319
      },
      {
        timestamp: 1652238000,
        open: 1.05354,
        close: 1.05361,
        high: 1.05366,
        low: 1.05333
      },
      {
        timestamp: 1652238300,
        open: 1.0536,
        close: 1.05366,
        high: 1.05374,
        low: 1.05357
      },
      {
        timestamp: 1652238600,
        open: 1.05366,
        close: 1.05366,
        high: 1.05367,
        low: 1.05345
      },
      {
        timestamp: 1652238900,
        open: 1.05365,
        close: 1.05378,
        high: 1.0538,
        low: 1.05354
      },
      {
        timestamp: 1652239200,
        open: 1.05378,
        close: 1.05383,
        high: 1.05386,
        low: 1.05376
      },
      {
        timestamp: 1652239500,
        open: 1.05384,
        close: 1.05385,
        high: 1.05392,
        low: 1.05376
      },
      {
        timestamp: 1652239800,
        open: 1.05385,
        close: 1.05368,
        high: 1.05385,
        low: 1.05368
      },
      {
        timestamp: 1652240100,
        open: 1.05369,
        close: 1.05351,
        high: 1.05369,
        low: 1.05349
      },
      {
        timestamp: 1652240400,
        open: 1.05354,
        close: 1.05347,
        high: 1.05356,
        low: 1.05333
      },
      {
        timestamp: 1652240700,
        open: 1.05347,
        close: 1.05358,
        high: 1.05371,
        low: 1.05343
      },
      {
        timestamp: 1652241000,
        open: 1.05357,
        close: 1.05382,
        high: 1.05391,
        low: 1.05357
      },
      {
        timestamp: 1652241300,
        open: 1.05381,
        close: 1.05375,
        high: 1.05398,
        low: 1.05375
      },
      {
        timestamp: 1652241600,
        open: 1.05375,
        close: 1.05384,
        high: 1.05389,
        low: 1.05372
      },
      {
        timestamp: 1652241900,
        open: 1.05385,
        close: 1.05371,
        high: 1.05391,
        low: 1.05371
      },
      {
        timestamp: 1652242200,
        open: 1.05372,
        close: 1.05354,
        high: 1.05374,
        low: 1.05347
      },
      {
        timestamp: 1652242500,
        open: 1.05354,
        close: 1.0535,
        high: 1.05366,
        low: 1.05346
      },
      {
        timestamp: 1652242800,
        open: 1.05351,
        close: 1.05371,
        high: 1.05376,
        low: 1.05346
      },
      {
        timestamp: 1652243100,
        open: 1.05371,
        close: 1.05375,
        high: 1.05375,
        low: 1.05353
      },
      {
        timestamp: 1652243400,
        open: 1.05375,
        close: 1.05374,
        high: 1.0539,
        low: 1.05357
      },
      {
        timestamp: 1652243700,
        open: 1.05374,
        close: 1.05399,
        high: 1.05399,
        low: 1.05366
      },
      {
        timestamp: 1652244000,
        open: 1.054,
        close: 1.05415,
        high: 1.05421,
        low: 1.054
      },
      {
        timestamp: 1652244300,
        open: 1.05416,
        close: 1.05434,
        high: 1.05434,
        low: 1.0541
      },
      {
        timestamp: 1652244600,
        open: 1.05433,
        close: 1.0543,
        high: 1.05441,
        low: 1.05425
      },
      {
        timestamp: 1652244900,
        open: 1.05429,
        close: 1.05419,
        high: 1.0543,
        low: 1.05415
      },
      {
        timestamp: 1652245200,
        open: 1.05419,
        close: 1.05434,
        high: 1.05435,
        low: 1.05409
      },
      {
        timestamp: 1652245500,
        open: 1.05433,
        close: 1.05413,
        high: 1.05439,
        low: 1.05413
      },
      {
        timestamp: 1652245800,
        open: 1.05414,
        close: 1.05445,
        high: 1.05445,
        low: 1.05407
      },
      {
        timestamp: 1652246100,
        open: 1.05445,
        close: 1.05453,
        high: 1.05454,
        low: 1.0544
      },
      {
        timestamp: 1652246400,
        open: 1.05453,
        close: 1.05445,
        high: 1.05463,
        low: 1.05444
      },
      {
        timestamp: 1652246700,
        open: 1.05445,
        close: 1.05464,
        high: 1.05471,
        low: 1.05445
      },
      {
        timestamp: 1652247000,
        open: 1.05464,
        close: 1.05501,
        high: 1.05504,
        low: 1.05463
      },
      {
        timestamp: 1652247300,
        open: 1.05501,
        close: 1.05473,
        high: 1.05509,
        low: 1.05467
      },
      {
        timestamp: 1652247600,
        open: 1.05472,
        close: 1.0546,
        high: 1.05479,
        low: 1.05458
      },
      {
        timestamp: 1652247900,
        open: 1.0546,
        close: 1.05462,
        high: 1.05468,
        low: 1.05443
      },
      {
        timestamp: 1652248200,
        open: 1.05461,
        close: 1.05432,
        high: 1.0548,
        low: 1.05427
      },
      {
        timestamp: 1652248500,
        open: 1.05432,
        close: 1.05467,
        high: 1.0547,
        low: 1.05401
      },
      {
        timestamp: 1652248800,
        open: 1.05467,
        close: 1.05467,
        high: 1.05482,
        low: 1.05437
      },
      {
        timestamp: 1652249100,
        open: 1.05466,
        close: 1.05475,
        high: 1.05482,
        low: 1.0543
      },
      {
        timestamp: 1652249400,
        open: 1.05474,
        close: 1.05404,
        high: 1.05474,
        low: 1.05393
      },
      {
        timestamp: 1652249700,
        open: 1.05404,
        close: 1.05426,
        high: 1.05433,
        low: 1.05378
      },
      {
        timestamp: 1652250000,
        open: 1.05427,
        close: 1.05484,
        high: 1.05498,
        low: 1.05424
      },
      {
        timestamp: 1652250300,
        open: 1.05484,
        close: 1.05451,
        high: 1.05486,
        low: 1.05433
      },
      {
        timestamp: 1652250600,
        open: 1.05452,
        close: 1.05428,
        high: 1.05452,
        low: 1.05404
      },
      {
        timestamp: 1652250900,
        open: 1.05429,
        close: 1.05441,
        high: 1.05459,
        low: 1.05421
      },
      {
        timestamp: 1652251200,
        open: 1.05442,
        close: 1.05462,
        high: 1.05473,
        low: 1.05437
      },
      {
        timestamp: 1652251500,
        open: 1.05463,
        close: 1.05486,
        high: 1.05486,
        low: 1.05452
      },
      {
        timestamp: 1652251800,
        open: 1.05485,
        close: 1.05448,
        high: 1.05485,
        low: 1.05413
      },
      {
        timestamp: 1652252100,
        open: 1.05447,
        close: 1.05423,
        high: 1.0545,
        low: 1.05404
      },
      {
        timestamp: 1652252400,
        open: 1.05424,
        close: 1.0549,
        high: 1.05516,
        low: 1.05424
      },
      {
        timestamp: 1652252700,
        open: 1.05489,
        close: 1.0551,
        high: 1.0552,
        low: 1.05476
      },
      {
        timestamp: 1652253000,
        open: 1.05509,
        close: 1.05578,
        high: 1.05588,
        low: 1.05503
      },
      {
        timestamp: 1652253300,
        open: 1.05579,
        close: 1.05568,
        high: 1.05607,
        low: 1.05549
      },
      {
        timestamp: 1652253600,
        open: 1.05569,
        close: 1.05521,
        high: 1.05614,
        low: 1.05519
      },
      {
        timestamp: 1652253900,
        open: 1.05521,
        close: 1.05532,
        high: 1.05536,
        low: 1.05459
      },
      {
        timestamp: 1652254200,
        open: 1.05533,
        close: 1.05528,
        high: 1.05542,
        low: 1.055
      },
      {
        timestamp: 1652254500,
        open: 1.05527,
        close: 1.05533,
        high: 1.05559,
        low: 1.05519
      },
      {
        timestamp: 1652254800,
        open: 1.05534,
        close: 1.05553,
        high: 1.05567,
        low: 1.05511
      },
      {
        timestamp: 1652255100,
        open: 1.05554,
        close: 1.05588,
        high: 1.05591,
        low: 1.05541
      },
      {
        timestamp: 1652255400,
        open: 1.05587,
        close: 1.05555,
        high: 1.05589,
        low: 1.05533
      },
      {
        timestamp: 1652255700,
        open: 1.05555,
        close: 1.05529,
        high: 1.05572,
        low: 1.0549
      },
      {
        timestamp: 1652256000,
        open: 1.05528,
        close: 1.0552,
        high: 1.05638,
        low: 1.05502
      },
      {
        timestamp: 1652256300,
        open: 1.05521,
        close: 1.05549,
        high: 1.05585,
        low: 1.05521
      },
      {
        timestamp: 1652256600,
        open: 1.05549,
        close: 1.0558,
        high: 1.05592,
        low: 1.05519
      },
      {
        timestamp: 1652256900,
        open: 1.0558,
        close: 1.05563,
        high: 1.05598,
        low: 1.0555
      },
      {
        timestamp: 1652257200,
        open: 1.05563,
        close: 1.05622,
        high: 1.05636,
        low: 1.05543
      },
      {
        timestamp: 1652257500,
        open: 1.05621,
        close: 1.05584,
        high: 1.05631,
        low: 1.05573
      },
      {
        timestamp: 1652257800,
        open: 1.05584,
        close: 1.05576,
        high: 1.05593,
        low: 1.05524
      },
      {
        timestamp: 1652258100,
        open: 1.05575,
        close: 1.05566,
        high: 1.05583,
        low: 1.05555
      },
      {
        timestamp: 1652258400,
        open: 1.05566,
        close: 1.05624,
        high: 1.05654,
        low: 1.05555
      },
      {
        timestamp: 1652258700,
        open: 1.05625,
        close: 1.05739,
        high: 1.05754,
        low: 1.05612
      },
      {
        timestamp: 1652259000,
        open: 1.05738,
        close: 1.05692,
        high: 1.05752,
        low: 1.05688
      },
      {
        timestamp: 1652259300,
        open: 1.05692,
        close: 1.05685,
        high: 1.0571,
        low: 1.05652
      },
      {
        timestamp: 1652259600,
        open: 1.05685,
        close: 1.05695,
        high: 1.0573,
        low: 1.05672
      },
      {
        timestamp: 1652259900,
        open: 1.05695,
        close: 1.0576,
        high: 1.05764,
        low: 1.05679
      },
      {
        timestamp: 1652260200,
        open: 1.05761,
        close: 1.0575,
        high: 1.05765,
        low: 1.05713
      },
      {
        timestamp: 1652260500,
        open: 1.0575,
        close: 1.05657,
        high: 1.05754,
        low: 1.05644
      },
      {
        timestamp: 1652260800,
        open: 1.05656,
        close: 1.05614,
        high: 1.05669,
        low: 1.05612
      },
      {
        timestamp: 1652261100,
        open: 1.05614,
        close: 1.05635,
        high: 1.05642,
        low: 1.05596
      },
      {
        timestamp: 1652261400,
        open: 1.05635,
        close: 1.05614,
        high: 1.0565,
        low: 1.05598
      },
      {
        timestamp: 1652261700,
        open: 1.05614,
        close: 1.05603,
        high: 1.05615,
        low: 1.05584
      },
      {
        timestamp: 1652262000,
        open: 1.05604,
        close: 1.056,
        high: 1.05624,
        low: 1.05579
      },
      {
        timestamp: 1652262300,
        open: 1.05601,
        close: 1.0561,
        high: 1.05615,
        low: 1.05578
      },
      {
        timestamp: 1652262600,
        open: 1.05611,
        close: 1.05624,
        high: 1.05633,
        low: 1.05601
      },
      {
        timestamp: 1652262900,
        open: 1.05625,
        close: 1.0564,
        high: 1.05648,
        low: 1.05617
      },
      {
        timestamp: 1652263200,
        open: 1.0564,
        close: 1.05646,
        high: 1.0565,
        low: 1.05598
      },
      {
        timestamp: 1652263500,
        open: 1.05646,
        close: 1.05585,
        high: 1.05681,
        low: 1.05576
      },
      {
        timestamp: 1652263800,
        open: 1.05585,
        close: 1.05573,
        high: 1.05613,
        low: 1.05568
      },
      {
        timestamp: 1652264100,
        open: 1.05573,
        close: 1.05569,
        high: 1.05601,
        low: 1.05565
      },
      {
        timestamp: 1652264400,
        open: 1.0557,
        close: 1.0557,
        high: 1.0558,
        low: 1.05561
      },
      {
        timestamp: 1652264700,
        open: 1.0557,
        close: 1.05568,
        high: 1.05584,
        low: 1.05562
      },
      {
        timestamp: 1652265000,
        open: 1.05568,
        close: 1.05549,
        high: 1.05581,
        low: 1.05548
      },
      {
        timestamp: 1652265300,
        open: 1.05548,
        close: 1.05523,
        high: 1.05549,
        low: 1.05503
      },
      {
        timestamp: 1652265600,
        open: 1.05522,
        close: 1.05533,
        high: 1.05534,
        low: 1.05512
      },
      {
        timestamp: 1652265900,
        open: 1.05534,
        close: 1.05533,
        high: 1.05559,
        low: 1.0553
      },
      {
        timestamp: 1652266200,
        open: 1.05532,
        close: 1.05509,
        high: 1.05535,
        low: 1.05495
      },
      {
        timestamp: 1652266500,
        open: 1.05509,
        close: 1.05469,
        high: 1.05537,
        low: 1.05458
      },
      {
        timestamp: 1652266800,
        open: 1.05468,
        close: 1.05499,
        high: 1.05511,
        low: 1.05439
      },
      {
        timestamp: 1652267100,
        open: 1.055,
        close: 1.05548,
        high: 1.0555,
        low: 1.05487
      },
      {
        timestamp: 1652267400,
        open: 1.05548,
        close: 1.05544,
        high: 1.05555,
        low: 1.05525
      },
      {
        timestamp: 1652267700,
        open: 1.05543,
        close: 1.05496,
        high: 1.0556,
        low: 1.05495
      },
      {
        timestamp: 1652268000,
        open: 1.05496,
        close: 1.05492,
        high: 1.05535,
        low: 1.05472
      },
      {
        timestamp: 1652268300,
        open: 1.05492,
        close: 1.05559,
        high: 1.05559,
        low: 1.05458
      },
      {
        timestamp: 1652268600,
        open: 1.05558,
        close: 1.055,
        high: 1.05577,
        low: 1.05498
      },
      {
        timestamp: 1652268900,
        open: 1.05499,
        close: 1.05476,
        high: 1.0552,
        low: 1.05458
      },
      {
        timestamp: 1652269200,
        open: 1.05476,
        close: 1.05468,
        high: 1.05524,
        low: 1.05463
      },
      {
        timestamp: 1652269500,
        open: 1.05468,
        close: 1.05474,
        high: 1.05522,
        low: 1.05458
      },
      {
        timestamp: 1652269800,
        open: 1.05475,
        close: 1.0548,
        high: 1.05511,
        low: 1.05475
      },
      {
        timestamp: 1652270100,
        open: 1.0548,
        close: 1.05456,
        high: 1.05493,
        low: 1.05453
      },
      {
        timestamp: 1652270400,
        open: 1.05455,
        close: 1.05533,
        high: 1.05541,
        low: 1.05455
      },
      {
        timestamp: 1652270700,
        open: 1.05533,
        close: 1.05567,
        high: 1.05569,
        low: 1.05524
      },
      {
        timestamp: 1652271000,
        open: 1.05568,
        close: 1.05537,
        high: 1.05569,
        low: 1.05537
      },
      {
        timestamp: 1652271300,
        open: 1.05537,
        close: 1.05518,
        high: 1.05541,
        low: 1.05453
      },
      {
        timestamp: 1652271600,
        open: 1.05518,
        close: 1.05523,
        high: 1.05545,
        low: 1.05489
      },
      {
        timestamp: 1652271900,
        open: 1.05524,
        close: 1.05503,
        high: 1.05525,
        low: 1.05463
      },
      {
        timestamp: 1652272200,
        open: 1.05503,
        close: 1.05209,
        high: 1.05505,
        low: 1.05118
      },
      {
        timestamp: 1652272500,
        open: 1.05209,
        close: 1.05183,
        high: 1.05236,
        low: 1.05077
      },
      {
        timestamp: 1652272800,
        open: 1.05182,
        close: 1.05192,
        high: 1.05214,
        low: 1.05124
      },
      {
        timestamp: 1652273100,
        open: 1.05191,
        close: 1.05257,
        high: 1.05271,
        low: 1.05174
      },
      {
        timestamp: 1652273400,
        open: 1.05258,
        close: 1.05267,
        high: 1.05356,
        low: 1.0525
      },
      {
        timestamp: 1652273700,
        open: 1.05265,
        close: 1.05156,
        high: 1.05274,
        low: 1.05143
      },
      {
        timestamp: 1652274000,
        open: 1.05155,
        close: 1.05045,
        high: 1.0516,
        low: 1.05025
      }
    ],
	},
	{
		id: 1,
		label: "EUR/USD - 1h",
		value: "EUR/USD - 1h",
		symbol: "EUR/USD",
		period: "1h",
		from: "2021/11/11",
		to: "2022/05/11",
    tradingInstrumentId: 1,
		timestepInSeconds: 1 * 60 * 60,
    data: [
      {
        timestamp: 1636642800,
        open: 1.14697,
        close: 1.14709,
        high: 1.14777,
        low: 1.14625
      },
      {
        timestamp: 1636646400,
        open: 1.14709,
        close: 1.14637,
        high: 1.14714,
        low: 1.14601
      },
      {
        timestamp: 1636650000,
        open: 1.14637,
        close: 1.14518,
        high: 1.1467,
        low: 1.14507
      },
      {
        timestamp: 1636653600,
        open: 1.14518,
        close: 1.14495,
        high: 1.14533,
        low: 1.14468
      },
      {
        timestamp: 1636657200,
        open: 1.14495,
        close: 1.1446,
        high: 1.14519,
        low: 1.14453
      },
      {
        timestamp: 1636660800,
        open: 1.1446,
        close: 1.14477,
        high: 1.14496,
        low: 1.14436
      },
      {
        timestamp: 1636664400,
        open: 1.14477,
        close: 1.1451,
        high: 1.14532,
        low: 1.1446
      },
      {
        timestamp: 1636668000,
        open: 1.1451,
        close: 1.14506,
        high: 1.14566,
        low: 1.14493
      },
      {
        timestamp: 1636671600,
        open: 1.14506,
        close: 1.14482,
        high: 1.14506,
        low: 1.14471
      },
      {
        timestamp: 1636675200,
        open: 1.14482,
        close: 1.14417,
        high: 1.14489,
        low: 1.14412
      },
      {
        timestamp: 1636678800,
        open: 1.14417,
        close: 1.14399,
        high: 1.14438,
        low: 1.14366
      },
      {
        timestamp: 1636682400,
        open: 1.14399,
        close: 1.14412,
        high: 1.14434,
        low: 1.14368
      },
      {
        timestamp: 1636686000,
        open: 1.14412,
        close: 1.14398,
        high: 1.14412,
        low: 1.14372
      },
      {
        timestamp: 1636689600,
        open: 1.14398,
        close: 1.14423,
        high: 1.14424,
        low: 1.14381
      },
      {
        timestamp: 1636693200,
        open: 1.14423,
        close: 1.14445,
        high: 1.14448,
        low: 1.14396
      },
      {
        timestamp: 1636696800,
        open: 1.14445,
        close: 1.1446,
        high: 1.14463,
        low: 1.1441
      },
      {
        timestamp: 1636700400,
        open: 1.1446,
        close: 1.14514,
        high: 1.14566,
        low: 1.14419
      },
      {
        timestamp: 1636704000,
        open: 1.14514,
        close: 1.14548,
        high: 1.14559,
        low: 1.14439
      },
      {
        timestamp: 1636707600,
        open: 1.14548,
        close: 1.14432,
        high: 1.1455,
        low: 1.1438
      },
      {
        timestamp: 1636711200,
        open: 1.14432,
        close: 1.14423,
        high: 1.14494,
        low: 1.14377
      },
      {
        timestamp: 1636714800,
        open: 1.14423,
        close: 1.14505,
        high: 1.14534,
        low: 1.14399
      },
      {
        timestamp: 1636718400,
        open: 1.14505,
        close: 1.14483,
        high: 1.14559,
        low: 1.14465
      },
      {
        timestamp: 1636722000,
        open: 1.14483,
        close: 1.14464,
        high: 1.14542,
        low: 1.14454
      },
      {
        timestamp: 1636725600,
        open: 1.14464,
        close: 1.14376,
        high: 1.14497,
        low: 1.14335
      },
      {
        timestamp: 1636729200,
        open: 1.14376,
        close: 1.14447,
        high: 1.14628,
        low: 1.14376
      },
      {
        timestamp: 1636732800,
        open: 1.14447,
        close: 1.14429,
        high: 1.14525,
        low: 1.14423
      },
      {
        timestamp: 1636736400,
        open: 1.14429,
        close: 1.14497,
        high: 1.14515,
        low: 1.14416
      },
      {
        timestamp: 1636740000,
        open: 1.14497,
        close: 1.14478,
        high: 1.14525,
        low: 1.14471
      },
      {
        timestamp: 1636743600,
        open: 1.14478,
        close: 1.14436,
        high: 1.14501,
        low: 1.14432
      },
      {
        timestamp: 1636747200,
        open: 1.14436,
        close: 1.1447,
        high: 1.14479,
        low: 1.14428
      },
      {
        timestamp: 1636750800,
        open: 1.1447,
        close: 1.14448,
        high: 1.14476,
        low: 1.14424
      },
      {
        timestamp: 1636916400,
        open: 1.14448,
        close: 1.14514,
        high: 1.14595,
        low: 1.14488
      },
      {
        timestamp: 1636920000,
        open: 1.14514,
        close: 1.14515,
        high: 1.14517,
        low: 1.14493
      },
      {
        timestamp: 1636923600,
        open: 1.14515,
        close: 1.14506,
        high: 1.14517,
        low: 1.1449
      },
      {
        timestamp: 1636927200,
        open: 1.14506,
        close: 1.14444,
        high: 1.14514,
        low: 1.1444
      },
      {
        timestamp: 1636930800,
        open: 1.14444,
        close: 1.1442,
        high: 1.14454,
        low: 1.14418
      },
      {
        timestamp: 1636934400,
        open: 1.1442,
        close: 1.14464,
        high: 1.14475,
        low: 1.14418
      },
      {
        timestamp: 1636938000,
        open: 1.14464,
        close: 1.14644,
        high: 1.14646,
        low: 1.14433
      },
      {
        timestamp: 1636941600,
        open: 1.14644,
        close: 1.14602,
        high: 1.14645,
        low: 1.14558
      },
      {
        timestamp: 1636945200,
        open: 1.14602,
        close: 1.14584,
        high: 1.14621,
        low: 1.14574
      },
      {
        timestamp: 1636948800,
        open: 1.14584,
        close: 1.14562,
        high: 1.14597,
        low: 1.14561
      },
      {
        timestamp: 1636952400,
        open: 1.14562,
        close: 1.14608,
        high: 1.14637,
        low: 1.14557
      },
      {
        timestamp: 1636956000,
        open: 1.14608,
        close: 1.14594,
        high: 1.14628,
        low: 1.14541
      },
      {
        timestamp: 1636959600,
        open: 1.14594,
        close: 1.14509,
        high: 1.14619,
        low: 1.14495
      },
      {
        timestamp: 1636963200,
        open: 1.14509,
        close: 1.14493,
        high: 1.1455,
        low: 1.14372
      },
      {
        timestamp: 1636966800,
        open: 1.14493,
        close: 1.1449,
        high: 1.14546,
        low: 1.14458
      },
      {
        timestamp: 1636970400,
        open: 1.1449,
        close: 1.14482,
        high: 1.14572,
        low: 1.1445
      },
      {
        timestamp: 1636974000,
        open: 1.14482,
        close: 1.14507,
        high: 1.14558,
        low: 1.14429
      },
      {
        timestamp: 1636977600,
        open: 1.14507,
        close: 1.14412,
        high: 1.1455,
        low: 1.14407
      },
      {
        timestamp: 1636981200,
        open: 1.14412,
        close: 1.14463,
        high: 1.14488,
        low: 1.14399
      },
      {
        timestamp: 1636984800,
        open: 1.14463,
        close: 1.14382,
        high: 1.14466,
        low: 1.14347
      },
      {
        timestamp: 1636988400,
        open: 1.14382,
        close: 1.14208,
        high: 1.14457,
        low: 1.14185
      },
      {
        timestamp: 1636992000,
        open: 1.14208,
        close: 1.14216,
        high: 1.14248,
        low: 1.14156
      },
      {
        timestamp: 1636995600,
        open: 1.14216,
        close: 1.13863,
        high: 1.14216,
        low: 1.13808
      },
      {
        timestamp: 1636999200,
        open: 1.13863,
        close: 1.1385,
        high: 1.13884,
        low: 1.13785
      },
      {
        timestamp: 1637002800,
        open: 1.1385,
        close: 1.13862,
        high: 1.13906,
        low: 1.13849
      },
      {
        timestamp: 1637006400,
        open: 1.13862,
        close: 1.13606,
        high: 1.13873,
        low: 1.13568
      },
      {
        timestamp: 1637010000,
        open: 1.13606,
        close: 1.13683,
        high: 1.13725,
        low: 1.13605
      },
      {
        timestamp: 1637013600,
        open: 1.13683,
        close: 1.13686,
        high: 1.13721,
        low: 1.13653
      },
      {
        timestamp: 1637017200,
        open: 1.13686,
        close: 1.13678,
        high: 1.13731,
        low: 1.13601
      },
      {
        timestamp: 1637020800,
        open: 1.13678,
        close: 1.13741,
        high: 1.1379,
        low: 1.13667
      },
      {
        timestamp: 1637024400,
        open: 1.13741,
        close: 1.13805,
        high: 1.13809,
        low: 1.13718
      },
      {
        timestamp: 1637028000,
        open: 1.13805,
        close: 1.13837,
        high: 1.13864,
        low: 1.13775
      },
      {
        timestamp: 1637031600,
        open: 1.13837,
        close: 1.13828,
        high: 1.1385,
        low: 1.13794
      },
      {
        timestamp: 1637035200,
        open: 1.13828,
        close: 1.13843,
        high: 1.13845,
        low: 1.13797
      },
      {
        timestamp: 1637038800,
        open: 1.13843,
        close: 1.13778,
        high: 1.1385,
        low: 1.13761
      },
      {
        timestamp: 1637042400,
        open: 1.13778,
        close: 1.13784,
        high: 1.1383,
        low: 1.1375
      },
      {
        timestamp: 1637046000,
        open: 1.13784,
        close: 1.13803,
        high: 1.13805,
        low: 1.13688
      },
      {
        timestamp: 1637049600,
        open: 1.13803,
        close: 1.13756,
        high: 1.13804,
        low: 1.13694
      },
      {
        timestamp: 1637053200,
        open: 1.13756,
        close: 1.13618,
        high: 1.13756,
        low: 1.13584
      },
      {
        timestamp: 1637056800,
        open: 1.13618,
        close: 1.13622,
        high: 1.13639,
        low: 1.13519
      },
      {
        timestamp: 1637060400,
        open: 1.13622,
        close: 1.13678,
        high: 1.13729,
        low: 1.13605
      },
      {
        timestamp: 1637064000,
        open: 1.13678,
        close: 1.13629,
        high: 1.13726,
        low: 1.13592
      },
      {
        timestamp: 1637067600,
        open: 1.13629,
        close: 1.13626,
        high: 1.13754,
        low: 1.13584
      },
      {
        timestamp: 1637071200,
        open: 1.13626,
        close: 1.13446,
        high: 1.13631,
        low: 1.13304
      },
      {
        timestamp: 1637074800,
        open: 1.13446,
        close: 1.13507,
        high: 1.1354,
        low: 1.13413
      },
      {
        timestamp: 1637078400,
        open: 1.13507,
        close: 1.13344,
        high: 1.13563,
        low: 1.13324
      },
      {
        timestamp: 1637082000,
        open: 1.13344,
        close: 1.13281,
        high: 1.13363,
        low: 1.13199
      },
      {
        timestamp: 1637085600,
        open: 1.13281,
        close: 1.13277,
        high: 1.13349,
        low: 1.13213
      },
      {
        timestamp: 1637089200,
        open: 1.13277,
        close: 1.13172,
        high: 1.13318,
        low: 1.13166
      },
      {
        timestamp: 1637092800,
        open: 1.13172,
        close: 1.13133,
        high: 1.13236,
        low: 1.13124
      },
      {
        timestamp: 1637096400,
        open: 1.13133,
        close: 1.1321,
        high: 1.13212,
        low: 1.13094
      },
      {
        timestamp: 1637100000,
        open: 1.1321,
        close: 1.13226,
        high: 1.13261,
        low: 1.13209
      },
      {
        timestamp: 1637103600,
        open: 1.13226,
        close: 1.13186,
        high: 1.13221,
        low: 1.13104
      },
      {
        timestamp: 1637107200,
        open: 1.13186,
        close: 1.13228,
        high: 1.13241,
        low: 1.13145
      },
      {
        timestamp: 1637110800,
        open: 1.13228,
        close: 1.13228,
        high: 1.13274,
        low: 1.13209
      },
      {
        timestamp: 1637114400,
        open: 1.13228,
        close: 1.13224,
        high: 1.13245,
        low: 1.13194
      },
      {
        timestamp: 1637118000,
        open: 1.13224,
        close: 1.12894,
        high: 1.13227,
        low: 1.12644
      },
      {
        timestamp: 1637121600,
        open: 1.12894,
        close: 1.12988,
        high: 1.13021,
        low: 1.12893
      },
      {
        timestamp: 1637125200,
        open: 1.12988,
        close: 1.12997,
        high: 1.13035,
        low: 1.12922
      },
      {
        timestamp: 1637128800,
        open: 1.12997,
        close: 1.13029,
        high: 1.13086,
        low: 1.12961
      },
      {
        timestamp: 1637132400,
        open: 1.13029,
        close: 1.13034,
        high: 1.13181,
        low: 1.12983
      },
      {
        timestamp: 1637136000,
        open: 1.13034,
        close: 1.1318,
        high: 1.13216,
        low: 1.12998
      },
      {
        timestamp: 1637139600,
        open: 1.1318,
        close: 1.13134,
        high: 1.13194,
        low: 1.13084
      },
      {
        timestamp: 1637143200,
        open: 1.13134,
        close: 1.13083,
        high: 1.13173,
        low: 1.13076
      },
      {
        timestamp: 1637146800,
        open: 1.13083,
        close: 1.13253,
        high: 1.13253,
        low: 1.13076
      },
      {
        timestamp: 1637150400,
        open: 1.13253,
        close: 1.13174,
        high: 1.13327,
        low: 1.13163
      },
      {
        timestamp: 1637154000,
        open: 1.13174,
        close: 1.12998,
        high: 1.13225,
        low: 1.12984
      },
      {
        timestamp: 1637157600,
        open: 1.12998,
        close: 1.13188,
        high: 1.13217,
        low: 1.12981
      },
      {
        timestamp: 1637161200,
        open: 1.13188,
        close: 1.13082,
        high: 1.13192,
        low: 1.12939
      },
      {
        timestamp: 1637164800,
        open: 1.13082,
        close: 1.13212,
        high: 1.13228,
        low: 1.13002
      },
      {
        timestamp: 1637168400,
        open: 1.13212,
        close: 1.13196,
        high: 1.13274,
        low: 1.13176
      },
      {
        timestamp: 1637172000,
        open: 1.13196,
        close: 1.13186,
        high: 1.13269,
        low: 1.13145
      },
      {
        timestamp: 1637175600,
        open: 1.13186,
        close: 1.13151,
        high: 1.132,
        low: 1.1313
      },
      {
        timestamp: 1637179200,
        open: 1.13151,
        close: 1.13231,
        high: 1.13237,
        low: 1.13116
      },
      {
        timestamp: 1637182800,
        open: 1.13231,
        close: 1.132,
        high: 1.13239,
        low: 1.13189
      },
      {
        timestamp: 1637186400,
        open: 1.132,
        close: 1.13215,
        high: 1.13265,
        low: 1.13184
      },
      {
        timestamp: 1637190000,
        open: 1.13215,
        close: 1.13185,
        high: 1.13217,
        low: 1.13162
      },
      {
        timestamp: 1637193600,
        open: 1.13185,
        close: 1.13239,
        high: 1.1327,
        low: 1.13169
      },
      {
        timestamp: 1637197200,
        open: 1.13239,
        close: 1.13252,
        high: 1.13289,
        low: 1.1319
      },
      {
        timestamp: 1637200800,
        open: 1.13252,
        close: 1.13349,
        high: 1.13386,
        low: 1.13251
      },
      {
        timestamp: 1637204400,
        open: 1.13349,
        close: 1.13287,
        high: 1.13358,
        low: 1.13276
      },
      {
        timestamp: 1637208000,
        open: 1.13287,
        close: 1.13256,
        high: 1.13311,
        low: 1.13256
      },
      {
        timestamp: 1637211600,
        open: 1.13256,
        close: 1.13187,
        high: 1.13264,
        low: 1.13165
      },
      {
        timestamp: 1637215200,
        open: 1.13187,
        close: 1.13202,
        high: 1.13217,
        low: 1.13143
      },
      {
        timestamp: 1637218800,
        open: 1.13202,
        close: 1.13355,
        high: 1.13391,
        low: 1.1315
      },
      {
        timestamp: 1637222400,
        open: 1.13355,
        close: 1.13343,
        high: 1.13403,
        low: 1.13263
      },
      {
        timestamp: 1637226000,
        open: 1.13343,
        close: 1.13324,
        high: 1.13358,
        low: 1.13246
      },
      {
        timestamp: 1637229600,
        open: 1.13324,
        close: 1.1338,
        high: 1.13391,
        low: 1.13291
      },
      {
        timestamp: 1637233200,
        open: 1.1338,
        close: 1.13449,
        high: 1.13481,
        low: 1.13379
      },
      {
        timestamp: 1637236800,
        open: 1.13449,
        close: 1.13405,
        high: 1.1349,
        low: 1.13378
      },
      {
        timestamp: 1637240400,
        open: 1.13405,
        close: 1.13353,
        high: 1.13481,
        low: 1.13285
      },
      {
        timestamp: 1637244000,
        open: 1.13353,
        close: 1.13422,
        high: 1.1348,
        low: 1.13273
      },
      {
        timestamp: 1637247600,
        open: 1.13422,
        close: 1.13484,
        high: 1.13598,
        low: 1.13367
      },
      {
        timestamp: 1637251200,
        open: 1.13484,
        close: 1.13592,
        high: 1.13595,
        low: 1.13471
      },
      {
        timestamp: 1637254800,
        open: 1.13592,
        close: 1.13705,
        high: 1.13745,
        low: 1.13583
      },
      {
        timestamp: 1637258400,
        open: 1.13705,
        close: 1.13682,
        high: 1.13746,
        low: 1.13674
      },
      {
        timestamp: 1637262000,
        open: 1.13682,
        close: 1.13711,
        high: 1.13727,
        low: 1.13678
      },
      {
        timestamp: 1637265600,
        open: 1.13711,
        close: 1.13733,
        high: 1.13734,
        low: 1.13695
      },
      {
        timestamp: 1637269200,
        open: 1.13733,
        close: 1.13729,
        high: 1.13747,
        low: 1.13712
      },
      {
        timestamp: 1637272800,
        open: 1.13729,
        close: 1.13724,
        high: 1.13744,
        low: 1.13706
      },
      {
        timestamp: 1637276400,
        open: 1.13724,
        close: 1.13686,
        high: 1.13735,
        low: 1.13684
      },
      {
        timestamp: 1637280000,
        open: 1.13686,
        close: 1.13652,
        high: 1.13696,
        low: 1.13631
      },
      {
        timestamp: 1637283600,
        open: 1.13652,
        close: 1.13569,
        high: 1.1366,
        low: 1.13564
      },
      {
        timestamp: 1637287200,
        open: 1.13569,
        close: 1.13549,
        high: 1.13575,
        low: 1.13543
      },
      {
        timestamp: 1637290800,
        open: 1.13549,
        close: 1.13558,
        high: 1.13578,
        low: 1.13526
      },
      {
        timestamp: 1637294400,
        open: 1.13558,
        close: 1.13527,
        high: 1.13576,
        low: 1.13526
      },
      {
        timestamp: 1637298000,
        open: 1.13527,
        close: 1.13533,
        high: 1.13587,
        low: 1.13526
      },
      {
        timestamp: 1637301600,
        open: 1.13533,
        close: 1.13593,
        high: 1.1361,
        low: 1.13518
      },
      {
        timestamp: 1637305200,
        open: 1.13593,
        close: 1.13444,
        high: 1.13594,
        low: 1.13443
      },
      {
        timestamp: 1637308800,
        open: 1.13444,
        close: 1.13244,
        high: 1.1346,
        low: 1.13237
      },
      {
        timestamp: 1637312400,
        open: 1.13244,
        close: 1.12998,
        high: 1.13244,
        low: 1.12835
      },
      {
        timestamp: 1637316000,
        open: 1.12998,
        close: 1.13015,
        high: 1.13057,
        low: 1.12845
      },
      {
        timestamp: 1637319600,
        open: 1.13015,
        close: 1.12906,
        high: 1.13034,
        low: 1.12851
      },
      {
        timestamp: 1637323200,
        open: 1.12906,
        close: 1.12581,
        high: 1.12981,
        low: 1.1253
      },
      {
        timestamp: 1637326800,
        open: 1.12581,
        close: 1.12884,
        high: 1.12949,
        low: 1.12505
      },
      {
        timestamp: 1637330400,
        open: 1.12884,
        close: 1.13078,
        high: 1.13219,
        low: 1.12881
      },
      {
        timestamp: 1637334000,
        open: 1.13078,
        close: 1.13183,
        high: 1.13219,
        low: 1.13006
      },
      {
        timestamp: 1637337600,
        open: 1.13183,
        close: 1.1319,
        high: 1.13227,
        low: 1.13135
      },
      {
        timestamp: 1637341200,
        open: 1.1319,
        close: 1.12989,
        high: 1.13202,
        low: 1.12928
      },
      {
        timestamp: 1637344800,
        open: 1.12989,
        close: 1.12877,
        high: 1.13034,
        low: 1.12873
      },
      {
        timestamp: 1637348400,
        open: 1.12877,
        close: 1.12902,
        high: 1.12928,
        low: 1.12861
      },
      {
        timestamp: 1637352000,
        open: 1.12902,
        close: 1.12891,
        high: 1.12924,
        low: 1.12867
      },
      {
        timestamp: 1637355600,
        open: 1.12891,
        close: 1.12824,
        high: 1.12909,
        low: 1.12814
      },
      {
        timestamp: 1637521200,
        open: 1.12824,
        close: 1.12975,
        high: 1.12975,
        low: 1.12975
      },
      {
        timestamp: 1637524800,
        open: 1.12975,
        close: 1.12967,
        high: 1.12975,
        low: 1.1288
      },
      {
        timestamp: 1637528400,
        open: 1.12967,
        close: 1.12942,
        high: 1.12967,
        low: 1.12907
      },
      {
        timestamp: 1637532000,
        open: 1.12942,
        close: 1.12878,
        high: 1.12945,
        low: 1.1286
      },
      {
        timestamp: 1637535600,
        open: 1.12878,
        close: 1.12759,
        high: 1.12877,
        low: 1.12723
      },
      {
        timestamp: 1637539200,
        open: 1.12759,
        close: 1.12811,
        high: 1.12897,
        low: 1.12744
      },
      {
        timestamp: 1637542800,
        open: 1.12811,
        close: 1.12746,
        high: 1.12903,
        low: 1.12655
      },
      {
        timestamp: 1637546400,
        open: 1.12746,
        close: 1.12722,
        high: 1.12778,
        low: 1.12662
      },
      {
        timestamp: 1637550000,
        open: 1.12722,
        close: 1.12715,
        high: 1.12751,
        low: 1.12655
      },
      {
        timestamp: 1637553600,
        open: 1.12715,
        close: 1.12788,
        high: 1.12795,
        low: 1.12709
      },
      {
        timestamp: 1637557200,
        open: 1.12788,
        close: 1.12782,
        high: 1.12788,
        low: 1.12703
      },
      {
        timestamp: 1637560800,
        open: 1.12782,
        close: 1.12668,
        high: 1.12788,
        low: 1.1266
      },
      {
        timestamp: 1637564400,
        open: 1.12668,
        close: 1.12608,
        high: 1.12749,
        low: 1.12607
      },
      {
        timestamp: 1637568000,
        open: 1.12608,
        close: 1.12658,
        high: 1.12792,
        low: 1.12607
      },
      {
        timestamp: 1637571600,
        open: 1.12658,
        close: 1.12809,
        high: 1.12884,
        low: 1.12655
      },
      {
        timestamp: 1637575200,
        open: 1.12809,
        close: 1.12867,
        high: 1.12912,
        low: 1.1279
      },
      {
        timestamp: 1637578800,
        open: 1.12867,
        close: 1.12774,
        high: 1.12888,
        low: 1.12658
      },
      {
        timestamp: 1637582400,
        open: 1.12774,
        close: 1.1277,
        high: 1.1283,
        low: 1.12727
      },
      {
        timestamp: 1637586000,
        open: 1.1277,
        close: 1.12807,
        high: 1.12812,
        low: 1.12737
      },
      {
        timestamp: 1637589600,
        open: 1.12807,
        close: 1.12583,
        high: 1.12807,
        low: 1.12367
      },
      {
        timestamp: 1637593200,
        open: 1.12583,
        close: 1.12473,
        high: 1.12595,
        low: 1.12425
      },
      {
        timestamp: 1637596800,
        open: 1.12473,
        close: 1.12621,
        high: 1.12661,
        low: 1.12468
      },
      {
        timestamp: 1637600400,
        open: 1.12621,
        close: 1.1249,
        high: 1.12624,
        low: 1.1246
      },
      {
        timestamp: 1637604000,
        open: 1.1249,
        close: 1.12408,
        high: 1.12508,
        low: 1.12384
      },
      {
        timestamp: 1637607600,
        open: 1.12408,
        close: 1.12323,
        high: 1.12415,
        low: 1.12317
      },
      {
        timestamp: 1637611200,
        open: 1.12323,
        close: 1.12372,
        high: 1.12395,
        low: 1.12312
      },
      {
        timestamp: 1637614800,
        open: 1.12372,
        close: 1.12383,
        high: 1.12398,
        low: 1.12336
      },
      {
        timestamp: 1637618400,
        open: 1.12383,
        close: 1.12387,
        high: 1.12422,
        low: 1.12373
      },
      {
        timestamp: 1637622000,
        open: 1.12387,
        close: 1.12423,
        high: 1.12423,
        low: 1.12375
      },
      {
        timestamp: 1637625600,
        open: 1.12423,
        close: 1.1241,
        high: 1.12445,
        low: 1.1239
      },
      {
        timestamp: 1637629200,
        open: 1.1241,
        close: 1.12381,
        high: 1.12445,
        low: 1.12375
      },
      {
        timestamp: 1637632800,
        open: 1.12381,
        close: 1.12367,
        high: 1.12389,
        low: 1.12267
      },
      {
        timestamp: 1637636400,
        open: 1.12367,
        close: 1.12379,
        high: 1.1242,
        low: 1.12357
      },
      {
        timestamp: 1637640000,
        open: 1.12379,
        close: 1.12365,
        high: 1.12384,
        low: 1.12334
      },
      {
        timestamp: 1637643600,
        open: 1.12365,
        close: 1.12348,
        high: 1.12409,
        low: 1.12322
      },
      {
        timestamp: 1637647200,
        open: 1.12348,
        close: 1.12411,
        high: 1.12411,
        low: 1.12328
      },
      {
        timestamp: 1637650800,
        open: 1.12411,
        close: 1.12537,
        high: 1.12648,
        low: 1.12346
      },
      {
        timestamp: 1637654400,
        open: 1.12537,
        close: 1.12678,
        high: 1.12696,
        low: 1.12399
      },
      {
        timestamp: 1637658000,
        open: 1.12678,
        close: 1.12598,
        high: 1.12756,
        low: 1.12557
      },
      {
        timestamp: 1637661600,
        open: 1.12598,
        close: 1.12548,
        high: 1.12697,
        low: 1.12544
      },
      {
        timestamp: 1637665200,
        open: 1.12548,
        close: 1.12438,
        high: 1.1262,
        low: 1.12425
      },
      {
        timestamp: 1637668800,
        open: 1.12438,
        close: 1.12413,
        high: 1.12512,
        low: 1.12349
      },
      {
        timestamp: 1637672400,
        open: 1.12413,
        close: 1.12525,
        high: 1.12601,
        low: 1.12408
      },
      {
        timestamp: 1637676000,
        open: 1.12525,
        close: 1.125,
        high: 1.12552,
        low: 1.12407
      },
      {
        timestamp: 1637679600,
        open: 1.125,
        close: 1.12648,
        high: 1.1266,
        low: 1.1243
      },
      {
        timestamp: 1637683200,
        open: 1.12648,
        close: 1.12645,
        high: 1.1272,
        low: 1.12611
      },
      {
        timestamp: 1637686800,
        open: 1.12645,
        close: 1.12548,
        high: 1.12674,
        low: 1.12542
      },
      {
        timestamp: 1637690400,
        open: 1.12548,
        close: 1.12492,
        high: 1.12556,
        low: 1.1247
      },
      {
        timestamp: 1637694000,
        open: 1.12492,
        close: 1.12509,
        high: 1.12525,
        low: 1.12468
      },
      {
        timestamp: 1637697600,
        open: 1.12509,
        close: 1.12507,
        high: 1.12545,
        low: 1.12488
      },
      {
        timestamp: 1637701200,
        open: 1.12507,
        close: 1.12493,
        high: 1.12522,
        low: 1.12488
      },
      {
        timestamp: 1637704800,
        open: 1.12493,
        close: 1.125,
        high: 1.12572,
        low: 1.12479
      },
      {
        timestamp: 1637708400,
        open: 1.125,
        close: 1.12452,
        high: 1.12504,
        low: 1.12428
      },
      {
        timestamp: 1637712000,
        open: 1.12452,
        close: 1.12426,
        high: 1.12478,
        low: 1.12412
      },
      {
        timestamp: 1637715600,
        open: 1.12426,
        close: 1.12393,
        high: 1.12464,
        low: 1.12386
      },
      {
        timestamp: 1637719200,
        open: 1.12393,
        close: 1.12383,
        high: 1.12424,
        low: 1.12357
      },
      {
        timestamp: 1637722800,
        open: 1.12383,
        close: 1.12361,
        high: 1.12409,
        low: 1.12332
      },
      {
        timestamp: 1637726400,
        open: 1.12361,
        close: 1.12433,
        high: 1.12438,
        low: 1.12338
      },
      {
        timestamp: 1637730000,
        open: 1.12433,
        close: 1.12401,
        high: 1.12456,
        low: 1.12375
      },
      {
        timestamp: 1637733600,
        open: 1.12401,
        close: 1.12476,
        high: 1.12479,
        low: 1.12396
      },
      {
        timestamp: 1637737200,
        open: 1.12476,
        close: 1.12461,
        high: 1.1248,
        low: 1.1241
      },
      {
        timestamp: 1637740800,
        open: 1.12461,
        close: 1.12222,
        high: 1.12559,
        low: 1.12205
      },
      {
        timestamp: 1637744400,
        open: 1.12222,
        close: 1.12086,
        high: 1.12365,
        low: 1.12059
      },
      {
        timestamp: 1637748000,
        open: 1.12086,
        close: 1.12093,
        high: 1.12202,
        low: 1.12039
      },
      {
        timestamp: 1637751600,
        open: 1.12093,
        close: 1.12179,
        high: 1.12208,
        low: 1.12089
      },
      {
        timestamp: 1637755200,
        open: 1.12179,
        close: 1.12081,
        high: 1.12202,
        low: 1.12064
      },
      {
        timestamp: 1637758800,
        open: 1.12081,
        close: 1.12073,
        high: 1.12154,
        low: 1.11927
      },
      {
        timestamp: 1637762400,
        open: 1.12073,
        close: 1.12049,
        high: 1.12166,
        low: 1.11866
      },
      {
        timestamp: 1637766000,
        open: 1.12049,
        close: 1.11968,
        high: 1.12137,
        low: 1.11923
      },
      {
        timestamp: 1637769600,
        open: 1.11968,
        close: 1.1196,
        high: 1.11998,
        low: 1.11881
      },
      {
        timestamp: 1637773200,
        open: 1.1196,
        close: 1.11961,
        high: 1.11971,
        low: 1.11904
      },
      {
        timestamp: 1637776800,
        open: 1.11961,
        close: 1.11935,
        high: 1.11984,
        low: 1.11925
      },
      {
        timestamp: 1637780400,
        open: 1.11935,
        close: 1.11994,
        high: 1.12042,
        low: 1.11891
      },
      {
        timestamp: 1637784000,
        open: 1.11994,
        close: 1.12057,
        high: 1.12061,
        low: 1.11983
      },
      {
        timestamp: 1637787600,
        open: 1.12057,
        close: 1.12029,
        high: 1.12062,
        low: 1.11989
      },
      {
        timestamp: 1637791200,
        open: 1.12029,
        close: 1.12023,
        high: 1.12067,
        low: 1.12019
      },
      {
        timestamp: 1637794800,
        open: 1.12023,
        close: 1.12033,
        high: 1.12052,
        low: 1.12012
      },
      {
        timestamp: 1637798400,
        open: 1.12033,
        close: 1.1211,
        high: 1.1212,
        low: 1.12034
      },
      {
        timestamp: 1637802000,
        open: 1.1211,
        close: 1.12078,
        high: 1.12131,
        low: 1.12064
      },
      {
        timestamp: 1637805600,
        open: 1.12078,
        close: 1.1213,
        high: 1.12146,
        low: 1.12077
      },
      {
        timestamp: 1637809200,
        open: 1.1213,
        close: 1.12146,
        high: 1.12187,
        low: 1.12125
      },
      {
        timestamp: 1637812800,
        open: 1.12146,
        close: 1.1215,
        high: 1.12165,
        low: 1.12132
      },
      {
        timestamp: 1637816400,
        open: 1.1215,
        close: 1.12132,
        high: 1.12168,
        low: 1.12106
      },
      {
        timestamp: 1637820000,
        open: 1.12132,
        close: 1.12147,
        high: 1.12167,
        low: 1.12126
      },
      {
        timestamp: 1637823600,
        open: 1.12147,
        close: 1.12162,
        high: 1.12209,
        low: 1.12133
      },
      {
        timestamp: 1637827200,
        open: 1.12162,
        close: 1.12261,
        high: 1.1227,
        low: 1.12151
      },
      {
        timestamp: 1637830800,
        open: 1.12261,
        close: 1.12237,
        high: 1.1227,
        low: 1.12168
      },
      {
        timestamp: 1637834400,
        open: 1.12237,
        close: 1.12228,
        high: 1.12297,
        low: 1.12186
      },
      {
        timestamp: 1637838000,
        open: 1.12228,
        close: 1.12197,
        high: 1.12251,
        low: 1.12176
      },
      {
        timestamp: 1637841600,
        open: 1.12197,
        close: 1.12164,
        high: 1.12303,
        low: 1.12156
      },
      {
        timestamp: 1637845200,
        open: 1.12164,
        close: 1.12193,
        high: 1.12275,
        low: 1.12159
      },
      {
        timestamp: 1637848800,
        open: 1.12193,
        close: 1.12179,
        high: 1.12227,
        low: 1.12137
      },
      {
        timestamp: 1637852400,
        open: 1.12179,
        close: 1.12102,
        high: 1.12181,
        low: 1.12081
      },
      {
        timestamp: 1637856000,
        open: 1.12102,
        close: 1.12125,
        high: 1.12158,
        low: 1.12092
      },
      {
        timestamp: 1637859600,
        open: 1.12125,
        close: 1.12101,
        high: 1.12129,
        low: 1.12066
      },
      {
        timestamp: 1637863200,
        open: 1.12101,
        close: 1.12081,
        high: 1.12132,
        low: 1.12065
      },
      {
        timestamp: 1637866800,
        open: 1.12081,
        close: 1.121,
        high: 1.12108,
        low: 1.1208
      },
      {
        timestamp: 1637870400,
        open: 1.121,
        close: 1.12085,
        high: 1.1211,
        low: 1.12082
      },
      {
        timestamp: 1637874000,
        open: 1.12085,
        close: 1.12125,
        high: 1.12139,
        low: 1.12065
      },
      {
        timestamp: 1637877600,
        open: 1.12125,
        close: 1.12091,
        high: 1.1216,
        low: 1.12076
      },
      {
        timestamp: 1637881200,
        open: 1.12091,
        close: 1.12098,
        high: 1.12132,
        low: 1.12077
      },
      {
        timestamp: 1637884800,
        open: 1.12098,
        close: 1.12186,
        high: 1.12255,
        low: 1.12075
      },
      {
        timestamp: 1637888400,
        open: 1.12186,
        close: 1.12179,
        high: 1.12255,
        low: 1.12149
      },
      {
        timestamp: 1637892000,
        open: 1.12179,
        close: 1.12243,
        high: 1.12255,
        low: 1.12128
      },
      {
        timestamp: 1637895600,
        open: 1.12243,
        close: 1.12196,
        high: 1.12278,
        low: 1.12179
      },
      {
        timestamp: 1637899200,
        open: 1.12196,
        close: 1.12214,
        high: 1.12248,
        low: 1.12182
      },
      {
        timestamp: 1637902800,
        open: 1.12214,
        close: 1.12245,
        high: 1.12284,
        low: 1.12213
      },
      {
        timestamp: 1637906400,
        open: 1.12245,
        close: 1.12317,
        high: 1.12335,
        low: 1.12214
      },
      {
        timestamp: 1637910000,
        open: 1.12317,
        close: 1.12343,
        high: 1.12451,
        low: 1.12283
      },
      {
        timestamp: 1637913600,
        open: 1.12343,
        close: 1.12488,
        high: 1.12796,
        low: 1.12307
      },
      {
        timestamp: 1637917200,
        open: 1.12488,
        close: 1.12651,
        high: 1.12757,
        low: 1.12479
      },
      {
        timestamp: 1637920800,
        open: 1.12651,
        close: 1.12849,
        high: 1.12858,
        low: 1.12588
      },
      {
        timestamp: 1637924400,
        open: 1.12849,
        close: 1.12786,
        high: 1.12961,
        low: 1.1277
      },
      {
        timestamp: 1637928000,
        open: 1.12786,
        close: 1.12925,
        high: 1.1296,
        low: 1.12746
      },
      {
        timestamp: 1637931600,
        open: 1.12925,
        close: 1.12937,
        high: 1.12976,
        low: 1.12789
      },
      {
        timestamp: 1637935200,
        open: 1.12937,
        close: 1.12941,
        high: 1.12962,
        low: 1.12833
      },
      {
        timestamp: 1637938800,
        open: 1.12941,
        close: 1.13173,
        high: 1.13217,
        low: 1.12898
      },
      {
        timestamp: 1637942400,
        open: 1.13173,
        close: 1.13018,
        high: 1.13218,
        low: 1.13007
      },
      {
        timestamp: 1637946000,
        open: 1.13018,
        close: 1.13042,
        high: 1.13067,
        low: 1.12911
      },
      {
        timestamp: 1637949600,
        open: 1.13042,
        close: 1.13093,
        high: 1.13124,
        low: 1.13
      },
      {
        timestamp: 1637953200,
        open: 1.13093,
        close: 1.13167,
        high: 1.13238,
        low: 1.13093
      },
      {
        timestamp: 1637956800,
        open: 1.13167,
        close: 1.13248,
        high: 1.13251,
        low: 1.13152
      },
      {
        timestamp: 1637960400,
        open: 1.13248,
        close: 1.13157,
        high: 1.13336,
        low: 1.13124
      },
      {
        timestamp: 1638126000,
        open: 1.13157,
        close: 1.13087,
        high: 1.13098,
        low: 1.13071
      },
      {
        timestamp: 1638129600,
        open: 1.13087,
        close: 1.13054,
        high: 1.1312,
        low: 1.13025
      },
      {
        timestamp: 1638133200,
        open: 1.13054,
        close: 1.13044,
        high: 1.13078,
        low: 1.12993
      },
      {
        timestamp: 1638136800,
        open: 1.13044,
        close: 1.13014,
        high: 1.13184,
        low: 1.12988
      },
      {
        timestamp: 1638140400,
        open: 1.13014,
        close: 1.12932,
        high: 1.13037,
        low: 1.12813
      },
      {
        timestamp: 1638144000,
        open: 1.12932,
        close: 1.129,
        high: 1.12976,
        low: 1.1287
      },
      {
        timestamp: 1638147600,
        open: 1.129,
        close: 1.12832,
        high: 1.12911,
        low: 1.12829
      },
      {
        timestamp: 1638151200,
        open: 1.12832,
        close: 1.12833,
        high: 1.12844,
        low: 1.12776
      },
      {
        timestamp: 1638154800,
        open: 1.12833,
        close: 1.12816,
        high: 1.12856,
        low: 1.12787
      },
      {
        timestamp: 1638158400,
        open: 1.12816,
        close: 1.12734,
        high: 1.12816,
        low: 1.12734
      },
      {
        timestamp: 1638162000,
        open: 1.12734,
        close: 1.12786,
        high: 1.12838,
        low: 1.12733
      },
      {
        timestamp: 1638165600,
        open: 1.12786,
        close: 1.1273,
        high: 1.12858,
        low: 1.12721
      },
      {
        timestamp: 1638169200,
        open: 1.1273,
        close: 1.12648,
        high: 1.1273,
        low: 1.1262
      },
      {
        timestamp: 1638172800,
        open: 1.12648,
        close: 1.12771,
        high: 1.12771,
        low: 1.12609
      },
      {
        timestamp: 1638176400,
        open: 1.12771,
        close: 1.12867,
        high: 1.12911,
        low: 1.12758
      },
      {
        timestamp: 1638180000,
        open: 1.12867,
        close: 1.12854,
        high: 1.12946,
        low: 1.12822
      },
      {
        timestamp: 1638183600,
        open: 1.12854,
        close: 1.12928,
        high: 1.12956,
        low: 1.12854
      },
      {
        timestamp: 1638187200,
        open: 1.12928,
        close: 1.12803,
        high: 1.12947,
        low: 1.12793
      },
      {
        timestamp: 1638190800,
        open: 1.12803,
        close: 1.12629,
        high: 1.1285,
        low: 1.12623
      },
      {
        timestamp: 1638194400,
        open: 1.12629,
        close: 1.12615,
        high: 1.1276,
        low: 1.1259
      },
      {
        timestamp: 1638198000,
        open: 1.12615,
        close: 1.12668,
        high: 1.12763,
        low: 1.12604
      },
      {
        timestamp: 1638201600,
        open: 1.12668,
        close: 1.12628,
        high: 1.12721,
        low: 1.12594
      },
      {
        timestamp: 1638205200,
        open: 1.12628,
        close: 1.12687,
        high: 1.12708,
        low: 1.12592
      },
      {
        timestamp: 1638208800,
        open: 1.12687,
        close: 1.12681,
        high: 1.12761,
        low: 1.1268
      },
      {
        timestamp: 1638212400,
        open: 1.12681,
        close: 1.12739,
        high: 1.12761,
        low: 1.12661
      },
      {
        timestamp: 1638216000,
        open: 1.12739,
        close: 1.12799,
        high: 1.12825,
        low: 1.12739
      },
      {
        timestamp: 1638219600,
        open: 1.12799,
        close: 1.12928,
        high: 1.12943,
        low: 1.12793
      },
      {
        timestamp: 1638223200,
        open: 1.12928,
        close: 1.12907,
        high: 1.12947,
        low: 1.12881
      },
      {
        timestamp: 1638226800,
        open: 1.12907,
        close: 1.12933,
        high: 1.13011,
        low: 1.12897
      },
      {
        timestamp: 1638230400,
        open: 1.12933,
        close: 1.12901,
        high: 1.12944,
        low: 1.12854
      },
      {
        timestamp: 1638234000,
        open: 1.12901,
        close: 1.12947,
        high: 1.12969,
        low: 1.12877
      },
      {
        timestamp: 1638237600,
        open: 1.12947,
        close: 1.13048,
        high: 1.13053,
        low: 1.12933
      },
      {
        timestamp: 1638241200,
        open: 1.13048,
        close: 1.12993,
        high: 1.13052,
        low: 1.12993
      },
      {
        timestamp: 1638244800,
        open: 1.12993,
        close: 1.12969,
        high: 1.13003,
        low: 1.1296
      },
      {
        timestamp: 1638248400,
        open: 1.12969,
        close: 1.1312,
        high: 1.13139,
        low: 1.12961
      },
      {
        timestamp: 1638252000,
        open: 1.1312,
        close: 1.13233,
        high: 1.1328,
        low: 1.13082
      },
      {
        timestamp: 1638255600,
        open: 1.13233,
        close: 1.13294,
        high: 1.13331,
        low: 1.13128
      },
      {
        timestamp: 1638259200,
        open: 1.13294,
        close: 1.13495,
        high: 1.1352,
        low: 1.13289
      },
      {
        timestamp: 1638262800,
        open: 1.13495,
        close: 1.13642,
        high: 1.13671,
        low: 1.13491
      },
      {
        timestamp: 1638266400,
        open: 1.13642,
        close: 1.1363,
        high: 1.13734,
        low: 1.13577
      },
      {
        timestamp: 1638270000,
        open: 1.1363,
        close: 1.13481,
        high: 1.13644,
        low: 1.13448
      },
      {
        timestamp: 1638273600,
        open: 1.13481,
        close: 1.13552,
        high: 1.13576,
        low: 1.13417
      },
      {
        timestamp: 1638277200,
        open: 1.13552,
        close: 1.13665,
        high: 1.13717,
        low: 1.13545
      },
      {
        timestamp: 1638280800,
        open: 1.13665,
        close: 1.13788,
        high: 1.13835,
        low: 1.13624
      },
      {
        timestamp: 1638284400,
        open: 1.13788,
        close: 1.126,
        high: 1.13834,
        low: 1.12508
      },
      {
        timestamp: 1638288000,
        open: 1.126,
        close: 1.12881,
        high: 1.12905,
        low: 1.12358
      },
      {
        timestamp: 1638291600,
        open: 1.12881,
        close: 1.1312,
        high: 1.13189,
        low: 1.128
      },
      {
        timestamp: 1638295200,
        open: 1.1312,
        close: 1.13109,
        high: 1.13204,
        low: 1.13106
      },
      {
        timestamp: 1638298800,
        open: 1.13109,
        close: 1.13253,
        high: 1.13254,
        low: 1.1308
      },
      {
        timestamp: 1638302400,
        open: 1.13253,
        close: 1.13424,
        high: 1.13427,
        low: 1.13195
      },
      {
        timestamp: 1638306000,
        open: 1.13424,
        close: 1.13414,
        high: 1.13461,
        low: 1.13352
      },
      {
        timestamp: 1638309600,
        open: 1.13414,
        close: 1.13397,
        high: 1.13456,
        low: 1.13355
      },
      {
        timestamp: 1638313200,
        open: 1.13397,
        close: 1.13306,
        high: 1.13434,
        low: 1.13303
      },
      {
        timestamp: 1638316800,
        open: 1.13306,
        close: 1.13321,
        high: 1.13368,
        low: 1.13288
      },
      {
        timestamp: 1638320400,
        open: 1.13321,
        close: 1.13213,
        high: 1.13338,
        low: 1.13188
      },
      {
        timestamp: 1638324000,
        open: 1.13213,
        close: 1.13222,
        high: 1.13244,
        low: 1.13171
      },
      {
        timestamp: 1638327600,
        open: 1.13222,
        close: 1.13287,
        high: 1.13321,
        low: 1.13222
      },
      {
        timestamp: 1638331200,
        open: 1.13287,
        close: 1.13323,
        high: 1.13324,
        low: 1.13266
      },
      {
        timestamp: 1638334800,
        open: 1.13323,
        close: 1.13376,
        high: 1.13378,
        low: 1.13293
      },
      {
        timestamp: 1638338400,
        open: 1.13376,
        close: 1.13327,
        high: 1.13379,
        low: 1.13302
      },
      {
        timestamp: 1638342000,
        open: 1.13327,
        close: 1.13413,
        high: 1.13445,
        low: 1.13177
      },
      {
        timestamp: 1638345600,
        open: 1.13413,
        close: 1.13091,
        high: 1.13605,
        low: 1.13032
      },
      {
        timestamp: 1638349200,
        open: 1.13091,
        close: 1.13262,
        high: 1.13331,
        low: 1.13085
      },
      {
        timestamp: 1638352800,
        open: 1.13262,
        close: 1.13267,
        high: 1.13402,
        low: 1.13191
      },
      {
        timestamp: 1638356400,
        open: 1.13267,
        close: 1.13217,
        high: 1.13347,
        low: 1.13203
      },
      {
        timestamp: 1638360000,
        open: 1.13217,
        close: 1.13137,
        high: 1.13261,
        low: 1.13136
      },
      {
        timestamp: 1638363600,
        open: 1.13137,
        close: 1.1338,
        high: 1.13413,
        low: 1.13117
      },
      {
        timestamp: 1638367200,
        open: 1.1338,
        close: 1.13536,
        high: 1.13565,
        low: 1.13352
      },
      {
        timestamp: 1638370800,
        open: 1.13536,
        close: 1.1335,
        high: 1.13584,
        low: 1.1334
      },
      {
        timestamp: 1638374400,
        open: 1.1335,
        close: 1.13241,
        high: 1.13359,
        low: 1.132
      },
      {
        timestamp: 1638378000,
        open: 1.13241,
        close: 1.13242,
        high: 1.13291,
        low: 1.13159
      },
      {
        timestamp: 1638381600,
        open: 1.13242,
        close: 1.13285,
        high: 1.13289,
        low: 1.13186
      },
      {
        timestamp: 1638385200,
        open: 1.13285,
        close: 1.13181,
        high: 1.13305,
        low: 1.13167
      },
      {
        timestamp: 1638388800,
        open: 1.13181,
        close: 1.13115,
        high: 1.13191,
        low: 1.13098
      },
      {
        timestamp: 1638392400,
        open: 1.13115,
        close: 1.13208,
        high: 1.13229,
        low: 1.13114
      },
      {
        timestamp: 1638396000,
        open: 1.13208,
        close: 1.132,
        high: 1.13286,
        low: 1.13185
      },
      {
        timestamp: 1638399600,
        open: 1.132,
        close: 1.13199,
        high: 1.1322,
        low: 1.13184
      },
      {
        timestamp: 1638403200,
        open: 1.13199,
        close: 1.13162,
        high: 1.13208,
        low: 1.13156
      },
      {
        timestamp: 1638406800,
        open: 1.13162,
        close: 1.13273,
        high: 1.13289,
        low: 1.13162
      },
      {
        timestamp: 1638410400,
        open: 1.13273,
        close: 1.13337,
        high: 1.13398,
        low: 1.13273
      },
      {
        timestamp: 1638414000,
        open: 1.13337,
        close: 1.13312,
        high: 1.13359,
        low: 1.13311
      },
      {
        timestamp: 1638417600,
        open: 1.13312,
        close: 1.1323,
        high: 1.13343,
        low: 1.13229
      },
      {
        timestamp: 1638421200,
        open: 1.1323,
        close: 1.13187,
        high: 1.13233,
        low: 1.13173
      },
      {
        timestamp: 1638424800,
        open: 1.13187,
        close: 1.13224,
        high: 1.1327,
        low: 1.13187
      },
      {
        timestamp: 1638428400,
        open: 1.13224,
        close: 1.13105,
        high: 1.13236,
        low: 1.13078
      },
      {
        timestamp: 1638432000,
        open: 1.13105,
        close: 1.13256,
        high: 1.13281,
        low: 1.13104
      },
      {
        timestamp: 1638435600,
        open: 1.13256,
        close: 1.1325,
        high: 1.13264,
        low: 1.13138
      },
      {
        timestamp: 1638439200,
        open: 1.1325,
        close: 1.13328,
        high: 1.13348,
        low: 1.13234
      },
      {
        timestamp: 1638442800,
        open: 1.13328,
        close: 1.13406,
        high: 1.13447,
        low: 1.13325
      },
      {
        timestamp: 1638446400,
        open: 1.13406,
        close: 1.13429,
        high: 1.13483,
        low: 1.1334
      },
      {
        timestamp: 1638450000,
        open: 1.13429,
        close: 1.13347,
        high: 1.1348,
        low: 1.13286
      },
      {
        timestamp: 1638453600,
        open: 1.13347,
        close: 1.13386,
        high: 1.13479,
        low: 1.13318
      },
      {
        timestamp: 1638457200,
        open: 1.13386,
        close: 1.13181,
        high: 1.13399,
        low: 1.13135
      },
      {
        timestamp: 1638460800,
        open: 1.13181,
        close: 1.13084,
        high: 1.1321,
        low: 1.13058
      },
      {
        timestamp: 1638464400,
        open: 1.13084,
        close: 1.1303,
        high: 1.13108,
        low: 1.12963
      },
      {
        timestamp: 1638468000,
        open: 1.1303,
        close: 1.13044,
        high: 1.13047,
        low: 1.12963
      },
      {
        timestamp: 1638471600,
        open: 1.13044,
        close: 1.12974,
        high: 1.13087,
        low: 1.12958
      },
      {
        timestamp: 1638475200,
        open: 1.12974,
        close: 1.12989,
        high: 1.13057,
        low: 1.12971
      },
      {
        timestamp: 1638478800,
        open: 1.12989,
        close: 1.13021,
        high: 1.1303,
        low: 1.12981
      },
      {
        timestamp: 1638482400,
        open: 1.13021,
        close: 1.13022,
        high: 1.1303,
        low: 1.13018
      },
      {
        timestamp: 1638486000,
        open: 1.13022,
        close: 1.13065,
        high: 1.13072,
        low: 1.12984
      },
      {
        timestamp: 1638489600,
        open: 1.13065,
        close: 1.13017,
        high: 1.13082,
        low: 1.13011
      },
      {
        timestamp: 1638493200,
        open: 1.13017,
        close: 1.12988,
        high: 1.13029,
        low: 1.12913
      },
      {
        timestamp: 1638496800,
        open: 1.12988,
        close: 1.12959,
        high: 1.12999,
        low: 1.12932
      },
      {
        timestamp: 1638500400,
        open: 1.12959,
        close: 1.12945,
        high: 1.13018,
        low: 1.12935
      },
      {
        timestamp: 1638504000,
        open: 1.12945,
        close: 1.12981,
        high: 1.13005,
        low: 1.12936
      },
      {
        timestamp: 1638507600,
        open: 1.12981,
        close: 1.13,
        high: 1.13031,
        low: 1.12972
      },
      {
        timestamp: 1638511200,
        open: 1.13,
        close: 1.12833,
        high: 1.13004,
        low: 1.12828
      },
      {
        timestamp: 1638514800,
        open: 1.12833,
        close: 1.12929,
        high: 1.12944,
        low: 1.12823
      },
      {
        timestamp: 1638518400,
        open: 1.12929,
        close: 1.12916,
        high: 1.13002,
        low: 1.12857
      },
      {
        timestamp: 1638522000,
        open: 1.12916,
        close: 1.12981,
        high: 1.12985,
        low: 1.12862
      },
      {
        timestamp: 1638525600,
        open: 1.12981,
        close: 1.13088,
        high: 1.13151,
        low: 1.12955
      },
      {
        timestamp: 1638529200,
        open: 1.13088,
        close: 1.13091,
        high: 1.13167,
        low: 1.1302
      },
      {
        timestamp: 1638532800,
        open: 1.13091,
        close: 1.12971,
        high: 1.13141,
        low: 1.1295
      },
      {
        timestamp: 1638536400,
        open: 1.12971,
        close: 1.13107,
        high: 1.13346,
        low: 1.12887
      },
      {
        timestamp: 1638540000,
        open: 1.13107,
        close: 1.12941,
        high: 1.13112,
        low: 1.12848
      },
      {
        timestamp: 1638543600,
        open: 1.12941,
        close: 1.12797,
        high: 1.12937,
        low: 1.12671
      },
      {
        timestamp: 1638547200,
        open: 1.12797,
        close: 1.13097,
        high: 1.13105,
        low: 1.12772
      },
      {
        timestamp: 1638550800,
        open: 1.13097,
        close: 1.13115,
        high: 1.1315,
        low: 1.13018
      },
      {
        timestamp: 1638554400,
        open: 1.13115,
        close: 1.13252,
        high: 1.13277,
        low: 1.13087
      },
      {
        timestamp: 1638558000,
        open: 1.13252,
        close: 1.13124,
        high: 1.1327,
        low: 1.13123
      },
      {
        timestamp: 1638561600,
        open: 1.13124,
        close: 1.13099,
        high: 1.13157,
        low: 1.13019
      },
      {
        timestamp: 1638565200,
        open: 1.13099,
        close: 1.13131,
        high: 1.1319,
        low: 1.1307
      },
      {
        timestamp: 1638730800,
        open: 1.13131,
        close: 1.13055,
        high: 1.13228,
        low: 1.13055
      },
      {
        timestamp: 1638738000,
        open: 1.13055,
        close: 1.13115,
        high: 1.13226,
        low: 1.13086
      },
      {
        timestamp: 1638741600,
        open: 1.13115,
        close: 1.13121,
        high: 1.13197,
        low: 1.13069
      },
      {
        timestamp: 1638745200,
        open: 1.13121,
        close: 1.13048,
        high: 1.13148,
        low: 1.13024
      },
      {
        timestamp: 1638748800,
        open: 1.13048,
        close: 1.1294,
        high: 1.13048,
        low: 1.12917
      },
      {
        timestamp: 1638752400,
        open: 1.1294,
        close: 1.1295,
        high: 1.12962,
        low: 1.1289
      },
      {
        timestamp: 1638756000,
        open: 1.1295,
        close: 1.1292,
        high: 1.12973,
        low: 1.12917
      },
      {
        timestamp: 1638759600,
        open: 1.1292,
        close: 1.12931,
        high: 1.12938,
        low: 1.12892
      },
      {
        timestamp: 1638763200,
        open: 1.12931,
        close: 1.12885,
        high: 1.1294,
        low: 1.12884
      },
      {
        timestamp: 1638766800,
        open: 1.12885,
        close: 1.12825,
        high: 1.12895,
        low: 1.12823
      },
      {
        timestamp: 1638770400,
        open: 1.12825,
        close: 1.12812,
        high: 1.1287,
        low: 1.12807
      },
      {
        timestamp: 1638774000,
        open: 1.12812,
        close: 1.12759,
        high: 1.1289,
        low: 1.12754
      },
      {
        timestamp: 1638777600,
        open: 1.12759,
        close: 1.12934,
        high: 1.12937,
        low: 1.12753
      },
      {
        timestamp: 1638781200,
        open: 1.12934,
        close: 1.12979,
        high: 1.13002,
        low: 1.12858
      },
      {
        timestamp: 1638784800,
        open: 1.12979,
        close: 1.12988,
        high: 1.13108,
        low: 1.12967
      },
      {
        timestamp: 1638788400,
        open: 1.12988,
        close: 1.12932,
        high: 1.13037,
        low: 1.12927
      },
      {
        timestamp: 1638792000,
        open: 1.12932,
        close: 1.12922,
        high: 1.13028,
        low: 1.12909
      },
      {
        timestamp: 1638795600,
        open: 1.12922,
        close: 1.12976,
        high: 1.12985,
        low: 1.12857
      },
      {
        timestamp: 1638799200,
        open: 1.12976,
        close: 1.12916,
        high: 1.13084,
        low: 1.12838
      },
      {
        timestamp: 1638802800,
        open: 1.12916,
        close: 1.1273,
        high: 1.12917,
        low: 1.12668
      },
      {
        timestamp: 1638806400,
        open: 1.1273,
        close: 1.12823,
        high: 1.12888,
        low: 1.12701
      },
      {
        timestamp: 1638810000,
        open: 1.12823,
        close: 1.12795,
        high: 1.12845,
        low: 1.12767
      },
      {
        timestamp: 1638813600,
        open: 1.12795,
        close: 1.12834,
        high: 1.12835,
        low: 1.12732
      },
      {
        timestamp: 1638817200,
        open: 1.12834,
        close: 1.12794,
        high: 1.12845,
        low: 1.12789
      },
      {
        timestamp: 1638820800,
        open: 1.12794,
        close: 1.12834,
        high: 1.1286,
        low: 1.12787
      },
      {
        timestamp: 1638824400,
        open: 1.12834,
        close: 1.12863,
        high: 1.12877,
        low: 1.12818
      },
      {
        timestamp: 1638828000,
        open: 1.12863,
        close: 1.12844,
        high: 1.12907,
        low: 1.12839
      },
      {
        timestamp: 1638831600,
        open: 1.12844,
        close: 1.12843,
        high: 1.12877,
        low: 1.12828
      },
      {
        timestamp: 1638835200,
        open: 1.12843,
        close: 1.12804,
        high: 1.1285,
        low: 1.12797
      },
      {
        timestamp: 1638838800,
        open: 1.12804,
        close: 1.12825,
        high: 1.12831,
        low: 1.12764
      },
      {
        timestamp: 1638842400,
        open: 1.12825,
        close: 1.12924,
        high: 1.12934,
        low: 1.12815
      },
      {
        timestamp: 1638846000,
        open: 1.12924,
        close: 1.12912,
        high: 1.12928,
        low: 1.12899
      },
      {
        timestamp: 1638849600,
        open: 1.12912,
        close: 1.12886,
        high: 1.12935,
        low: 1.12871
      },
      {
        timestamp: 1638853200,
        open: 1.12886,
        close: 1.12877,
        high: 1.12916,
        low: 1.12877
      },
      {
        timestamp: 1638856800,
        open: 1.12877,
        close: 1.12946,
        high: 1.12972,
        low: 1.12877
      },
      {
        timestamp: 1638860400,
        open: 1.12946,
        close: 1.12893,
        high: 1.12991,
        low: 1.12875
      },
      {
        timestamp: 1638864000,
        open: 1.12893,
        close: 1.12757,
        high: 1.12954,
        low: 1.12746
      },
      {
        timestamp: 1638867600,
        open: 1.12757,
        close: 1.12754,
        high: 1.12791,
        low: 1.1254
      },
      {
        timestamp: 1638871200,
        open: 1.12754,
        close: 1.12611,
        high: 1.12789,
        low: 1.1258
      },
      {
        timestamp: 1638874800,
        open: 1.12611,
        close: 1.12581,
        high: 1.12676,
        low: 1.12552
      },
      {
        timestamp: 1638878400,
        open: 1.12581,
        close: 1.12531,
        high: 1.12606,
        low: 1.12516
      },
      {
        timestamp: 1638882000,
        open: 1.12531,
        close: 1.12406,
        high: 1.126,
        low: 1.12282
      },
      {
        timestamp: 1638885600,
        open: 1.12406,
        close: 1.12462,
        high: 1.12488,
        low: 1.1236
      },
      {
        timestamp: 1638889200,
        open: 1.12462,
        close: 1.12463,
        high: 1.12554,
        low: 1.1241
      },
      {
        timestamp: 1638892800,
        open: 1.12463,
        close: 1.12477,
        high: 1.12511,
        low: 1.1237
      },
      {
        timestamp: 1638896400,
        open: 1.12477,
        close: 1.12445,
        high: 1.12525,
        low: 1.1242
      },
      {
        timestamp: 1638900000,
        open: 1.12445,
        close: 1.12533,
        high: 1.1255,
        low: 1.12423
      },
      {
        timestamp: 1638903600,
        open: 1.12533,
        close: 1.1262,
        high: 1.1262,
        low: 1.12512
      },
      {
        timestamp: 1638907200,
        open: 1.1262,
        close: 1.12682,
        high: 1.12684,
        low: 1.12597
      },
      {
        timestamp: 1638910800,
        open: 1.12682,
        close: 1.12696,
        high: 1.12745,
        low: 1.12658
      },
      {
        timestamp: 1638914400,
        open: 1.12696,
        close: 1.12696,
        high: 1.12742,
        low: 1.12695
      },
      {
        timestamp: 1638918000,
        open: 1.12696,
        close: 1.12733,
        high: 1.12745,
        low: 1.12696
      },
      {
        timestamp: 1638921600,
        open: 1.12733,
        close: 1.12744,
        high: 1.12783,
        low: 1.12731
      },
      {
        timestamp: 1638925200,
        open: 1.12744,
        close: 1.1281,
        high: 1.12822,
        low: 1.12736
      },
      {
        timestamp: 1638928800,
        open: 1.1281,
        close: 1.12917,
        high: 1.1292,
        low: 1.12803
      },
      {
        timestamp: 1638932400,
        open: 1.12917,
        close: 1.12923,
        high: 1.12942,
        low: 1.12892
      },
      {
        timestamp: 1638936000,
        open: 1.12923,
        close: 1.12912,
        high: 1.12927,
        low: 1.12908
      },
      {
        timestamp: 1638939600,
        open: 1.12912,
        close: 1.12942,
        high: 1.12963,
        low: 1.12895
      },
      {
        timestamp: 1638943200,
        open: 1.12942,
        close: 1.12892,
        high: 1.12961,
        low: 1.1288
      },
      {
        timestamp: 1638946800,
        open: 1.12892,
        close: 1.12868,
        high: 1.12945,
        low: 1.12842
      },
      {
        timestamp: 1638950400,
        open: 1.12868,
        close: 1.1284,
        high: 1.12942,
        low: 1.12791
      },
      {
        timestamp: 1638954000,
        open: 1.1284,
        close: 1.12857,
        high: 1.12882,
        low: 1.12825
      },
      {
        timestamp: 1638957600,
        open: 1.12857,
        close: 1.12928,
        high: 1.13009,
        low: 1.12731
      },
      {
        timestamp: 1638961200,
        open: 1.12928,
        close: 1.1277,
        high: 1.12949,
        low: 1.1269
      },
      {
        timestamp: 1638964800,
        open: 1.1277,
        close: 1.12917,
        high: 1.1303,
        low: 1.12671
      },
      {
        timestamp: 1638968400,
        open: 1.12917,
        close: 1.13169,
        high: 1.13181,
        low: 1.12886
      },
      {
        timestamp: 1638972000,
        open: 1.13169,
        close: 1.13044,
        high: 1.13172,
        low: 1.13008
      },
      {
        timestamp: 1638975600,
        open: 1.13044,
        close: 1.13332,
        high: 1.13353,
        low: 1.13037
      },
      {
        timestamp: 1638979200,
        open: 1.13332,
        close: 1.13289,
        high: 1.13394,
        low: 1.13256
      },
      {
        timestamp: 1638982800,
        open: 1.13289,
        close: 1.1349,
        high: 1.13501,
        low: 1.13289
      },
      {
        timestamp: 1638986400,
        open: 1.1349,
        close: 1.1346,
        high: 1.13504,
        low: 1.13448
      },
      {
        timestamp: 1638990000,
        open: 1.1346,
        close: 1.135,
        high: 1.13514,
        low: 1.13459
      },
      {
        timestamp: 1638993600,
        open: 1.135,
        close: 1.13491,
        high: 1.13554,
        low: 1.13475
      },
      {
        timestamp: 1638997200,
        open: 1.13491,
        close: 1.13433,
        high: 1.13504,
        low: 1.13421
      },
      {
        timestamp: 1639000800,
        open: 1.13433,
        close: 1.13427,
        high: 1.13494,
        low: 1.13415
      },
      {
        timestamp: 1639004400,
        open: 1.13427,
        close: 1.13449,
        high: 1.13461,
        low: 1.13409
      },
      {
        timestamp: 1639008000,
        open: 1.13449,
        close: 1.13403,
        high: 1.13449,
        low: 1.13371
      },
      {
        timestamp: 1639011600,
        open: 1.13403,
        close: 1.13346,
        high: 1.13404,
        low: 1.13332
      },
      {
        timestamp: 1639015200,
        open: 1.13346,
        close: 1.1334,
        high: 1.13369,
        low: 1.13332
      },
      {
        timestamp: 1639018800,
        open: 1.1334,
        close: 1.13352,
        high: 1.13354,
        low: 1.13299
      },
      {
        timestamp: 1639022400,
        open: 1.13352,
        close: 1.13367,
        high: 1.13374,
        low: 1.13328
      },
      {
        timestamp: 1639026000,
        open: 1.13367,
        close: 1.13274,
        high: 1.13367,
        low: 1.13273
      },
      {
        timestamp: 1639029600,
        open: 1.13274,
        close: 1.13285,
        high: 1.13345,
        low: 1.13272
      },
      {
        timestamp: 1639033200,
        open: 1.13285,
        close: 1.13208,
        high: 1.13317,
        low: 1.13177
      },
      {
        timestamp: 1639036800,
        open: 1.13208,
        close: 1.13229,
        high: 1.13275,
        low: 1.13172
      },
      {
        timestamp: 1639040400,
        open: 1.13229,
        close: 1.13198,
        high: 1.13244,
        low: 1.13161
      },
      {
        timestamp: 1639044000,
        open: 1.13198,
        close: 1.13146,
        high: 1.13243,
        low: 1.13113
      },
      {
        timestamp: 1639047600,
        open: 1.13146,
        close: 1.13174,
        high: 1.13233,
        low: 1.13132
      },
      {
        timestamp: 1639051200,
        open: 1.13174,
        close: 1.13103,
        high: 1.13175,
        low: 1.13075
      },
      {
        timestamp: 1639054800,
        open: 1.13103,
        close: 1.13172,
        high: 1.13214,
        low: 1.13091
      },
      {
        timestamp: 1639058400,
        open: 1.13172,
        close: 1.13027,
        high: 1.132,
        low: 1.13007
      },
      {
        timestamp: 1639062000,
        open: 1.13027,
        close: 1.12845,
        high: 1.13054,
        low: 1.12791
      },
      {
        timestamp: 1639065600,
        open: 1.12845,
        close: 1.12858,
        high: 1.12938,
        low: 1.12789
      },
      {
        timestamp: 1639069200,
        open: 1.12858,
        close: 1.12904,
        high: 1.12949,
        low: 1.12847
      },
      {
        timestamp: 1639072800,
        open: 1.12904,
        close: 1.12944,
        high: 1.12945,
        low: 1.12829
      },
      {
        timestamp: 1639076400,
        open: 1.12944,
        close: 1.12878,
        high: 1.12946,
        low: 1.12875
      },
      {
        timestamp: 1639080000,
        open: 1.12878,
        close: 1.12952,
        high: 1.12962,
        low: 1.12876
      },
      {
        timestamp: 1639083600,
        open: 1.12952,
        close: 1.12938,
        high: 1.12952,
        low: 1.12899
      },
      {
        timestamp: 1639087200,
        open: 1.12938,
        close: 1.12944,
        high: 1.1296,
        low: 1.12919
      },
      {
        timestamp: 1639090800,
        open: 1.12944,
        close: 1.12943,
        high: 1.12988,
        low: 1.12932
      },
      {
        timestamp: 1639094400,
        open: 1.12943,
        close: 1.12895,
        high: 1.12965,
        low: 1.12889
      },
      {
        timestamp: 1639098000,
        open: 1.12895,
        close: 1.12975,
        high: 1.12978,
        low: 1.12875
      },
      {
        timestamp: 1639101600,
        open: 1.12975,
        close: 1.13022,
        high: 1.13022,
        low: 1.12955
      },
      {
        timestamp: 1639105200,
        open: 1.13022,
        close: 1.1299,
        high: 1.13023,
        low: 1.1298
      },
      {
        timestamp: 1639108800,
        open: 1.1299,
        close: 1.12996,
        high: 1.12997,
        low: 1.12961
      },
      {
        timestamp: 1639112400,
        open: 1.12996,
        close: 1.12954,
        high: 1.13035,
        low: 1.12953
      },
      {
        timestamp: 1639116000,
        open: 1.12954,
        close: 1.12983,
        high: 1.13017,
        low: 1.12953
      },
      {
        timestamp: 1639119600,
        open: 1.12983,
        close: 1.12947,
        high: 1.12985,
        low: 1.1287
      },
      {
        timestamp: 1639123200,
        open: 1.12947,
        close: 1.12865,
        high: 1.12979,
        low: 1.12853
      },
      {
        timestamp: 1639126800,
        open: 1.12865,
        close: 1.12828,
        high: 1.12889,
        low: 1.12811
      },
      {
        timestamp: 1639130400,
        open: 1.12828,
        close: 1.12691,
        high: 1.12832,
        low: 1.12657
      },
      {
        timestamp: 1639134000,
        open: 1.12691,
        close: 1.12683,
        high: 1.1275,
        low: 1.12673
      },
      {
        timestamp: 1639137600,
        open: 1.12683,
        close: 1.12798,
        high: 1.128,
        low: 1.12682
      },
      {
        timestamp: 1639141200,
        open: 1.12798,
        close: 1.12942,
        high: 1.13103,
        low: 1.12676
      },
      {
        timestamp: 1639144800,
        open: 1.12942,
        close: 1.12869,
        high: 1.13022,
        low: 1.12706
      },
      {
        timestamp: 1639148400,
        open: 1.12869,
        close: 1.13022,
        high: 1.13077,
        low: 1.12843
      },
      {
        timestamp: 1639152000,
        open: 1.13022,
        close: 1.13128,
        high: 1.13247,
        low: 1.13009
      },
      {
        timestamp: 1639155600,
        open: 1.13128,
        close: 1.13194,
        high: 1.13223,
        low: 1.13113
      },
      {
        timestamp: 1639159200,
        open: 1.13194,
        close: 1.13158,
        high: 1.13235,
        low: 1.13147
      },
      {
        timestamp: 1639162800,
        open: 1.13158,
        close: 1.13118,
        high: 1.13175,
        low: 1.13104
      },
      {
        timestamp: 1639166400,
        open: 1.13118,
        close: 1.13179,
        high: 1.1319,
        low: 1.13112
      },
      {
        timestamp: 1639170000,
        open: 1.13179,
        close: 1.13177,
        high: 1.13202,
        low: 1.13154
      },
      {
        timestamp: 1639335600,
        open: 1.13177,
        close: 1.13148,
        high: 1.13148,
        low: 1.13121
      },
      {
        timestamp: 1639339200,
        open: 1.13148,
        close: 1.13186,
        high: 1.13206,
        low: 1.13186
      },
      {
        timestamp: 1639342800,
        open: 1.13186,
        close: 1.13191,
        high: 1.13191,
        low: 1.13186
      },
      {
        timestamp: 1639346400,
        open: 1.13191,
        close: 1.13131,
        high: 1.13202,
        low: 1.13115
      },
      {
        timestamp: 1639350000,
        open: 1.13131,
        close: 1.13114,
        high: 1.13201,
        low: 1.13105
      },
      {
        timestamp: 1639353600,
        open: 1.13114,
        close: 1.13089,
        high: 1.13144,
        low: 1.13084
      },
      {
        timestamp: 1639357200,
        open: 1.13089,
        close: 1.13057,
        high: 1.13138,
        low: 1.13034
      },
      {
        timestamp: 1639360800,
        open: 1.13057,
        close: 1.12959,
        high: 1.13064,
        low: 1.12959
      },
      {
        timestamp: 1639364400,
        open: 1.12959,
        close: 1.12986,
        high: 1.12991,
        low: 1.12942
      },
      {
        timestamp: 1639368000,
        open: 1.12986,
        close: 1.12954,
        high: 1.12999,
        low: 1.12929
      },
      {
        timestamp: 1639371600,
        open: 1.12954,
        close: 1.12978,
        high: 1.13004,
        low: 1.12949
      },
      {
        timestamp: 1639375200,
        open: 1.12978,
        close: 1.12857,
        high: 1.12982,
        low: 1.12856
      },
      {
        timestamp: 1639378800,
        open: 1.12857,
        close: 1.12911,
        high: 1.12923,
        low: 1.12844
      },
      {
        timestamp: 1639382400,
        open: 1.12911,
        close: 1.12747,
        high: 1.12935,
        low: 1.12746
      },
      {
        timestamp: 1639386000,
        open: 1.12747,
        close: 1.12689,
        high: 1.12759,
        low: 1.12607
      },
      {
        timestamp: 1639389600,
        open: 1.12689,
        close: 1.12712,
        high: 1.12734,
        low: 1.12631
      },
      {
        timestamp: 1639393200,
        open: 1.12712,
        close: 1.12749,
        high: 1.12803,
        low: 1.1264
      },
      {
        timestamp: 1639396800,
        open: 1.12749,
        close: 1.12768,
        high: 1.12886,
        low: 1.12731
      },
      {
        timestamp: 1639400400,
        open: 1.12768,
        close: 1.12848,
        high: 1.12928,
        low: 1.12764
      },
      {
        timestamp: 1639404000,
        open: 1.12848,
        close: 1.12877,
        high: 1.12983,
        low: 1.12801
      },
      {
        timestamp: 1639407600,
        open: 1.12877,
        close: 1.12761,
        high: 1.12924,
        low: 1.12738
      },
      {
        timestamp: 1639411200,
        open: 1.12761,
        close: 1.12977,
        high: 1.12985,
        low: 1.12749
      },
      {
        timestamp: 1639414800,
        open: 1.12977,
        close: 1.12938,
        high: 1.13078,
        low: 1.12937
      },
      {
        timestamp: 1639418400,
        open: 1.12938,
        close: 1.12955,
        high: 1.13006,
        low: 1.12919
      },
      {
        timestamp: 1639422000,
        open: 1.12955,
        close: 1.12878,
        high: 1.12956,
        low: 1.12878
      },
      {
        timestamp: 1639425600,
        open: 1.12878,
        close: 1.12847,
        high: 1.12917,
        low: 1.1283
      },
      {
        timestamp: 1639429200,
        open: 1.12847,
        close: 1.12859,
        high: 1.12882,
        low: 1.12823
      },
      {
        timestamp: 1639432800,
        open: 1.12859,
        close: 1.12837,
        high: 1.12877,
        low: 1.12832
      },
      {
        timestamp: 1639436400,
        open: 1.12837,
        close: 1.1284,
        high: 1.12847,
        low: 1.12819
      },
      {
        timestamp: 1639440000,
        open: 1.1284,
        close: 1.12785,
        high: 1.12855,
        low: 1.12743
      },
      {
        timestamp: 1639443600,
        open: 1.12785,
        close: 1.12827,
        high: 1.12839,
        low: 1.12757
      },
      {
        timestamp: 1639447200,
        open: 1.12827,
        close: 1.12835,
        high: 1.12842,
        low: 1.12791
      },
      {
        timestamp: 1639450800,
        open: 1.12835,
        close: 1.1274,
        high: 1.12844,
        low: 1.1272
      },
      {
        timestamp: 1639454400,
        open: 1.1274,
        close: 1.12781,
        high: 1.12783,
        low: 1.1273
      },
      {
        timestamp: 1639458000,
        open: 1.12781,
        close: 1.12785,
        high: 1.1282,
        low: 1.12766
      },
      {
        timestamp: 1639461600,
        open: 1.12785,
        close: 1.1278,
        high: 1.12839,
        low: 1.12758
      },
      {
        timestamp: 1639465200,
        open: 1.1278,
        close: 1.12713,
        high: 1.12783,
        low: 1.12711
      },
      {
        timestamp: 1639468800,
        open: 1.12713,
        close: 1.1283,
        high: 1.12845,
        low: 1.12668
      },
      {
        timestamp: 1639472400,
        open: 1.1283,
        close: 1.13019,
        high: 1.13048,
        low: 1.12791
      },
      {
        timestamp: 1639476000,
        open: 1.13019,
        close: 1.13161,
        high: 1.13173,
        low: 1.13013
      },
      {
        timestamp: 1639479600,
        open: 1.13161,
        close: 1.13193,
        high: 1.13244,
        low: 1.13131
      },
      {
        timestamp: 1639483200,
        open: 1.13193,
        close: 1.131,
        high: 1.13209,
        low: 1.13093
      },
      {
        timestamp: 1639486800,
        open: 1.131,
        close: 1.1309,
        high: 1.13181,
        low: 1.13053
      },
      {
        timestamp: 1639490400,
        open: 1.1309,
        close: 1.13012,
        high: 1.13094,
        low: 1.1296
      },
      {
        timestamp: 1639494000,
        open: 1.13012,
        close: 1.12732,
        high: 1.13029,
        low: 1.12718
      },
      {
        timestamp: 1639497600,
        open: 1.12732,
        close: 1.12669,
        high: 1.12769,
        low: 1.12653
      },
      {
        timestamp: 1639501200,
        open: 1.12669,
        close: 1.12723,
        high: 1.12775,
        low: 1.1267
      },
      {
        timestamp: 1639504800,
        open: 1.12723,
        close: 1.12655,
        high: 1.12724,
        low: 1.12647
      },
      {
        timestamp: 1639508400,
        open: 1.12655,
        close: 1.12564,
        high: 1.12659,
        low: 1.12563
      },
      {
        timestamp: 1639512000,
        open: 1.12564,
        close: 1.12547,
        high: 1.12601,
        low: 1.12543
      },
      {
        timestamp: 1639515600,
        open: 1.12547,
        close: 1.12597,
        high: 1.12601,
        low: 1.12547
      },
      {
        timestamp: 1639519200,
        open: 1.12597,
        close: 1.12584,
        high: 1.12666,
        low: 1.12578
      },
      {
        timestamp: 1639522800,
        open: 1.12584,
        close: 1.12583,
        high: 1.12598,
        low: 1.12545
      },
      {
        timestamp: 1639526400,
        open: 1.12583,
        close: 1.12657,
        high: 1.12688,
        low: 1.12576
      },
      {
        timestamp: 1639530000,
        open: 1.12657,
        close: 1.12689,
        high: 1.12706,
        low: 1.12649
      },
      {
        timestamp: 1639533600,
        open: 1.12689,
        close: 1.12627,
        high: 1.12697,
        low: 1.12619
      },
      {
        timestamp: 1639537200,
        open: 1.12627,
        close: 1.12604,
        high: 1.12634,
        low: 1.12582
      },
      {
        timestamp: 1639540800,
        open: 1.12604,
        close: 1.12646,
        high: 1.12655,
        low: 1.12603
      },
      {
        timestamp: 1639544400,
        open: 1.12646,
        close: 1.12697,
        high: 1.12732,
        low: 1.12639
      },
      {
        timestamp: 1639548000,
        open: 1.12697,
        close: 1.12758,
        high: 1.12774,
        low: 1.12693
      },
      {
        timestamp: 1639551600,
        open: 1.12758,
        close: 1.12646,
        high: 1.12771,
        low: 1.12634
      },
      {
        timestamp: 1639555200,
        open: 1.12646,
        close: 1.12731,
        high: 1.12761,
        low: 1.12642
      },
      {
        timestamp: 1639558800,
        open: 1.12731,
        close: 1.12724,
        high: 1.12778,
        low: 1.12703
      },
      {
        timestamp: 1639562400,
        open: 1.12724,
        close: 1.12679,
        high: 1.12776,
        low: 1.12639
      },
      {
        timestamp: 1639566000,
        open: 1.12679,
        close: 1.12705,
        high: 1.12705,
        low: 1.1263
      },
      {
        timestamp: 1639569600,
        open: 1.12705,
        close: 1.12638,
        high: 1.12765,
        low: 1.12608
      },
      {
        timestamp: 1639573200,
        open: 1.12638,
        close: 1.12741,
        high: 1.12749,
        low: 1.12565
      },
      {
        timestamp: 1639576800,
        open: 1.12741,
        close: 1.12593,
        high: 1.12751,
        low: 1.12507
      },
      {
        timestamp: 1639580400,
        open: 1.12593,
        close: 1.12524,
        high: 1.12635,
        low: 1.12487
      },
      {
        timestamp: 1639584000,
        open: 1.12524,
        close: 1.12603,
        high: 1.12683,
        low: 1.12506
      },
      {
        timestamp: 1639587600,
        open: 1.12603,
        close: 1.12608,
        high: 1.12663,
        low: 1.12585
      },
      {
        timestamp: 1639591200,
        open: 1.12608,
        close: 1.12603,
        high: 1.12623,
        low: 1.12542
      },
      {
        timestamp: 1639594800,
        open: 1.12603,
        close: 1.12731,
        high: 1.12759,
        low: 1.12222
      },
      {
        timestamp: 1639598400,
        open: 1.12731,
        close: 1.12884,
        high: 1.12999,
        low: 1.12641
      },
      {
        timestamp: 1639602000,
        open: 1.12884,
        close: 1.12957,
        high: 1.12965,
        low: 1.12879
      },
      {
        timestamp: 1639605600,
        open: 1.12957,
        close: 1.12949,
        high: 1.12977,
        low: 1.12936
      },
      {
        timestamp: 1639609200,
        open: 1.12949,
        close: 1.12911,
        high: 1.12993,
        low: 1.12902
      },
      {
        timestamp: 1639612800,
        open: 1.12911,
        close: 1.12836,
        high: 1.12928,
        low: 1.12818
      },
      {
        timestamp: 1639616400,
        open: 1.12836,
        close: 1.12872,
        high: 1.12898,
        low: 1.12824
      },
      {
        timestamp: 1639620000,
        open: 1.12872,
        close: 1.12897,
        high: 1.12913,
        low: 1.12846
      },
      {
        timestamp: 1639623600,
        open: 1.12897,
        close: 1.12862,
        high: 1.12905,
        low: 1.12862
      },
      {
        timestamp: 1639627200,
        open: 1.12862,
        close: 1.12871,
        high: 1.12903,
        low: 1.12841
      },
      {
        timestamp: 1639630800,
        open: 1.12871,
        close: 1.12862,
        high: 1.12885,
        low: 1.12855
      },
      {
        timestamp: 1639634400,
        open: 1.12862,
        close: 1.12923,
        high: 1.12936,
        low: 1.12842
      },
      {
        timestamp: 1639638000,
        open: 1.12923,
        close: 1.13058,
        high: 1.13128,
        low: 1.12908
      },
      {
        timestamp: 1639641600,
        open: 1.13058,
        close: 1.13185,
        high: 1.13196,
        low: 1.13021
      },
      {
        timestamp: 1639645200,
        open: 1.13185,
        close: 1.13139,
        high: 1.13198,
        low: 1.13122
      },
      {
        timestamp: 1639648800,
        open: 1.13139,
        close: 1.13003,
        high: 1.13153,
        low: 1.12968
      },
      {
        timestamp: 1639652400,
        open: 1.13003,
        close: 1.12966,
        high: 1.13008,
        low: 1.1291
      },
      {
        timestamp: 1639656000,
        open: 1.12966,
        close: 1.13285,
        high: 1.13443,
        low: 1.12923
      },
      {
        timestamp: 1639659600,
        open: 1.13285,
        close: 1.13597,
        high: 1.136,
        low: 1.13251
      },
      {
        timestamp: 1639663200,
        open: 1.13597,
        close: 1.13276,
        high: 1.13609,
        low: 1.13239
      },
      {
        timestamp: 1639666800,
        open: 1.13276,
        close: 1.13076,
        high: 1.13371,
        low: 1.1299
      },
      {
        timestamp: 1639670400,
        open: 1.13076,
        close: 1.13109,
        high: 1.13135,
        low: 1.13018
      },
      {
        timestamp: 1639674000,
        open: 1.13109,
        close: 1.13186,
        high: 1.13203,
        low: 1.1303
      },
      {
        timestamp: 1639677600,
        open: 1.13186,
        close: 1.13214,
        high: 1.13225,
        low: 1.13157
      },
      {
        timestamp: 1639681200,
        open: 1.13214,
        close: 1.13254,
        high: 1.13269,
        low: 1.13191
      },
      {
        timestamp: 1639684800,
        open: 1.13254,
        close: 1.13337,
        high: 1.13373,
        low: 1.13196
      },
      {
        timestamp: 1639688400,
        open: 1.13337,
        close: 1.13313,
        high: 1.13347,
        low: 1.13292
      },
      {
        timestamp: 1639692000,
        open: 1.13313,
        close: 1.13328,
        high: 1.13379,
        low: 1.13314
      },
      {
        timestamp: 1639695600,
        open: 1.13328,
        close: 1.13343,
        high: 1.13357,
        low: 1.13291
      },
      {
        timestamp: 1639699200,
        open: 1.13343,
        close: 1.13292,
        high: 1.13358,
        low: 1.13279
      },
      {
        timestamp: 1639702800,
        open: 1.13292,
        close: 1.13217,
        high: 1.13325,
        low: 1.13212
      },
      {
        timestamp: 1639706400,
        open: 1.13217,
        close: 1.13255,
        high: 1.13262,
        low: 1.1321
      },
      {
        timestamp: 1639710000,
        open: 1.13255,
        close: 1.13277,
        high: 1.13281,
        low: 1.13238
      },
      {
        timestamp: 1639713600,
        open: 1.13277,
        close: 1.1336,
        high: 1.13366,
        low: 1.13273
      },
      {
        timestamp: 1639717200,
        open: 1.1336,
        close: 1.13488,
        high: 1.13495,
        low: 1.13358
      },
      {
        timestamp: 1639720800,
        open: 1.13488,
        close: 1.13345,
        high: 1.13492,
        low: 1.13339
      },
      {
        timestamp: 1639724400,
        open: 1.13345,
        close: 1.13187,
        high: 1.13364,
        low: 1.13178
      },
      {
        timestamp: 1639728000,
        open: 1.13187,
        close: 1.13363,
        high: 1.13383,
        low: 1.13182
      },
      {
        timestamp: 1639731600,
        open: 1.13363,
        close: 1.1335,
        high: 1.13402,
        low: 1.13275
      },
      {
        timestamp: 1639735200,
        open: 1.1335,
        close: 1.13259,
        high: 1.1335,
        low: 1.13227
      },
      {
        timestamp: 1639738800,
        open: 1.13259,
        close: 1.13189,
        high: 1.1326,
        low: 1.13121
      },
      {
        timestamp: 1639742400,
        open: 1.13189,
        close: 1.13312,
        high: 1.13334,
        low: 1.1313
      },
      {
        timestamp: 1639746000,
        open: 1.13312,
        close: 1.1308,
        high: 1.13334,
        low: 1.13057
      },
      {
        timestamp: 1639749600,
        open: 1.1308,
        close: 1.13071,
        high: 1.13164,
        low: 1.12931
      },
      {
        timestamp: 1639753200,
        open: 1.13071,
        close: 1.12745,
        high: 1.13081,
        low: 1.12742
      },
      {
        timestamp: 1639756800,
        open: 1.12745,
        close: 1.12775,
        high: 1.128,
        low: 1.12673
      },
      {
        timestamp: 1639760400,
        open: 1.12775,
        close: 1.12687,
        high: 1.12776,
        low: 1.12673
      },
      {
        timestamp: 1639764000,
        open: 1.12687,
        close: 1.12538,
        high: 1.12718,
        low: 1.12534
      },
      {
        timestamp: 1639767600,
        open: 1.12538,
        close: 1.12527,
        high: 1.12595,
        low: 1.12516
      },
      {
        timestamp: 1639771200,
        open: 1.12527,
        close: 1.12373,
        high: 1.12535,
        low: 1.12373
      },
      {
        timestamp: 1639774800,
        open: 1.12373,
        close: 1.1238,
        high: 1.12439,
        low: 1.12362
      },
      {
        timestamp: 1639940400,
        open: 1.1238,
        close: 1.12455,
        high: 1.12455,
        low: 1.12437
      },
      {
        timestamp: 1639944000,
        open: 1.12455,
        close: 1.12455,
        high: 1.12455,
        low: 1.12437
      },
      {
        timestamp: 1639947600,
        open: 1.12455,
        close: 1.12451,
        high: 1.12455,
        low: 1.12437
      },
      {
        timestamp: 1639951200,
        open: 1.12451,
        close: 1.12386,
        high: 1.12443,
        low: 1.12378
      },
      {
        timestamp: 1639954800,
        open: 1.12386,
        close: 1.12436,
        high: 1.12448,
        low: 1.12352
      },
      {
        timestamp: 1639958400,
        open: 1.12436,
        close: 1.12507,
        high: 1.12509,
        low: 1.12416
      },
      {
        timestamp: 1639962000,
        open: 1.12507,
        close: 1.12391,
        high: 1.12506,
        low: 1.12381
      },
      {
        timestamp: 1639965600,
        open: 1.12391,
        close: 1.12488,
        high: 1.12503,
        low: 1.1237
      },
      {
        timestamp: 1639969200,
        open: 1.12488,
        close: 1.1249,
        high: 1.1251,
        low: 1.12472
      },
      {
        timestamp: 1639972800,
        open: 1.1249,
        close: 1.125,
        high: 1.1252,
        low: 1.12451
      },
      {
        timestamp: 1639976400,
        open: 1.125,
        close: 1.12549,
        high: 1.12552,
        low: 1.12484
      },
      {
        timestamp: 1639980000,
        open: 1.12549,
        close: 1.12528,
        high: 1.12568,
        low: 1.12487
      },
      {
        timestamp: 1639983600,
        open: 1.12528,
        close: 1.12557,
        high: 1.12619,
        low: 1.12479
      },
      {
        timestamp: 1639987200,
        open: 1.12557,
        close: 1.12683,
        high: 1.12712,
        low: 1.12507
      },
      {
        timestamp: 1639990800,
        open: 1.12683,
        close: 1.12628,
        high: 1.12749,
        low: 1.12503
      },
      {
        timestamp: 1639994400,
        open: 1.12628,
        close: 1.12673,
        high: 1.12726,
        low: 1.12576
      },
      {
        timestamp: 1639998000,
        open: 1.12673,
        close: 1.1271,
        high: 1.12774,
        low: 1.12653
      },
      {
        timestamp: 1640001600,
        open: 1.1271,
        close: 1.12692,
        high: 1.12771,
        low: 1.12688
      },
      {
        timestamp: 1640005200,
        open: 1.12692,
        close: 1.12938,
        high: 1.1299,
        low: 1.12692
      },
      {
        timestamp: 1640008800,
        open: 1.12938,
        close: 1.12995,
        high: 1.13017,
        low: 1.12913
      },
      {
        timestamp: 1640012400,
        open: 1.12995,
        close: 1.12894,
        high: 1.13043,
        low: 1.12875
      },
      {
        timestamp: 1640016000,
        open: 1.12894,
        close: 1.12969,
        high: 1.13043,
        low: 1.12888
      },
      {
        timestamp: 1640019600,
        open: 1.12969,
        close: 1.12848,
        high: 1.12996,
        low: 1.12789
      },
      {
        timestamp: 1640023200,
        open: 1.12848,
        close: 1.12814,
        high: 1.12878,
        low: 1.12808
      },
      {
        timestamp: 1640026800,
        open: 1.12814,
        close: 1.12746,
        high: 1.12866,
        low: 1.12746
      },
      {
        timestamp: 1640030400,
        open: 1.12746,
        close: 1.12785,
        high: 1.12786,
        low: 1.12726
      },
      {
        timestamp: 1640034000,
        open: 1.12785,
        close: 1.12829,
        high: 1.12829,
        low: 1.12751
      },
      {
        timestamp: 1640037600,
        open: 1.12829,
        close: 1.12804,
        high: 1.12866,
        low: 1.12789
      },
      {
        timestamp: 1640041200,
        open: 1.12804,
        close: 1.12779,
        high: 1.12808,
        low: 1.12754
      },
      {
        timestamp: 1640044800,
        open: 1.12779,
        close: 1.12787,
        high: 1.12792,
        low: 1.12738
      },
      {
        timestamp: 1640048400,
        open: 1.12787,
        close: 1.12829,
        high: 1.12854,
        low: 1.12745
      },
      {
        timestamp: 1640052000,
        open: 1.12829,
        close: 1.12871,
        high: 1.12876,
        low: 1.12829
      },
      {
        timestamp: 1640055600,
        open: 1.12871,
        close: 1.12831,
        high: 1.1287,
        low: 1.12826
      },
      {
        timestamp: 1640059200,
        open: 1.12831,
        close: 1.12811,
        high: 1.12831,
        low: 1.12789
      },
      {
        timestamp: 1640062800,
        open: 1.12811,
        close: 1.12812,
        high: 1.12831,
        low: 1.12769
      },
      {
        timestamp: 1640066400,
        open: 1.12812,
        close: 1.12789,
        high: 1.12817,
        low: 1.12768
      },
      {
        timestamp: 1640070000,
        open: 1.12789,
        close: 1.12884,
        high: 1.12963,
        low: 1.12767
      },
      {
        timestamp: 1640073600,
        open: 1.12884,
        close: 1.13012,
        high: 1.13032,
        low: 1.12852
      },
      {
        timestamp: 1640077200,
        open: 1.13012,
        close: 1.12958,
        high: 1.13033,
        low: 1.12924
      },
      {
        timestamp: 1640080800,
        open: 1.12958,
        close: 1.12912,
        high: 1.12985,
        low: 1.12889
      },
      {
        timestamp: 1640084400,
        open: 1.12912,
        close: 1.12861,
        high: 1.12913,
        low: 1.12764
      },
      {
        timestamp: 1640088000,
        open: 1.12861,
        close: 1.1295,
        high: 1.12968,
        low: 1.12833
      },
      {
        timestamp: 1640091600,
        open: 1.1295,
        close: 1.12912,
        high: 1.13021,
        low: 1.1289
      },
      {
        timestamp: 1640095200,
        open: 1.12912,
        close: 1.12737,
        high: 1.12926,
        low: 1.12713
      },
      {
        timestamp: 1640098800,
        open: 1.12737,
        close: 1.12646,
        high: 1.12842,
        low: 1.12615
      },
      {
        timestamp: 1640102400,
        open: 1.12646,
        close: 1.1271,
        high: 1.12738,
        low: 1.12613
      },
      {
        timestamp: 1640106000,
        open: 1.1271,
        close: 1.12677,
        high: 1.12744,
        low: 1.12664
      },
      {
        timestamp: 1640109600,
        open: 1.12677,
        close: 1.12741,
        high: 1.12745,
        low: 1.12646
      },
      {
        timestamp: 1640113200,
        open: 1.12741,
        close: 1.1282,
        high: 1.12825,
        low: 1.12732
      },
      {
        timestamp: 1640116800,
        open: 1.1282,
        close: 1.12833,
        high: 1.12839,
        low: 1.12798
      },
      {
        timestamp: 1640120400,
        open: 1.12833,
        close: 1.12895,
        high: 1.12898,
        low: 1.12797
      },
      {
        timestamp: 1640124000,
        open: 1.12895,
        close: 1.12826,
        high: 1.12939,
        low: 1.12771
      },
      {
        timestamp: 1640127600,
        open: 1.12826,
        close: 1.12878,
        high: 1.12879,
        low: 1.12815
      },
      {
        timestamp: 1640131200,
        open: 1.12878,
        close: 1.1283,
        high: 1.12901,
        low: 1.12819
      },
      {
        timestamp: 1640134800,
        open: 1.1283,
        close: 1.12861,
        high: 1.12947,
        low: 1.12828
      },
      {
        timestamp: 1640138400,
        open: 1.12861,
        close: 1.1278,
        high: 1.12863,
        low: 1.12741
      },
      {
        timestamp: 1640142000,
        open: 1.1278,
        close: 1.1277,
        high: 1.12788,
        low: 1.12757
      },
      {
        timestamp: 1640145600,
        open: 1.1277,
        close: 1.12744,
        high: 1.12768,
        low: 1.12714
      },
      {
        timestamp: 1640149200,
        open: 1.12744,
        close: 1.12715,
        high: 1.12756,
        low: 1.12689
      },
      {
        timestamp: 1640152800,
        open: 1.12715,
        close: 1.12673,
        high: 1.12753,
        low: 1.12653
      },
      {
        timestamp: 1640156400,
        open: 1.12673,
        close: 1.12681,
        high: 1.12719,
        low: 1.12648
      },
      {
        timestamp: 1640160000,
        open: 1.12681,
        close: 1.12737,
        high: 1.1277,
        low: 1.12663
      },
      {
        timestamp: 1640163600,
        open: 1.12737,
        close: 1.12837,
        high: 1.1286,
        low: 1.12715
      },
      {
        timestamp: 1640167200,
        open: 1.12837,
        close: 1.12935,
        high: 1.13007,
        low: 1.12817
      },
      {
        timestamp: 1640170800,
        open: 1.12935,
        close: 1.13017,
        high: 1.13114,
        low: 1.12931
      },
      {
        timestamp: 1640174400,
        open: 1.13017,
        close: 1.13046,
        high: 1.13102,
        low: 1.13007
      },
      {
        timestamp: 1640178000,
        open: 1.13046,
        close: 1.13124,
        high: 1.13157,
        low: 1.12985
      },
      {
        timestamp: 1640181600,
        open: 1.13124,
        close: 1.1309,
        high: 1.13209,
        low: 1.13057
      },
      {
        timestamp: 1640185200,
        open: 1.1309,
        close: 1.13219,
        high: 1.13249,
        low: 1.13075
      },
      {
        timestamp: 1640188800,
        open: 1.13219,
        close: 1.1325,
        high: 1.1343,
        low: 1.13194
      },
      {
        timestamp: 1640192400,
        open: 1.1325,
        close: 1.13297,
        high: 1.13323,
        low: 1.13232
      },
      {
        timestamp: 1640196000,
        open: 1.13297,
        close: 1.13262,
        high: 1.13312,
        low: 1.13244
      },
      {
        timestamp: 1640199600,
        open: 1.13262,
        close: 1.13331,
        high: 1.13335,
        low: 1.13233
      },
      {
        timestamp: 1640203200,
        open: 1.13331,
        close: 1.13388,
        high: 1.13395,
        low: 1.13312
      },
      {
        timestamp: 1640206800,
        open: 1.13388,
        close: 1.13281,
        high: 1.13392,
        low: 1.13274
      },
      {
        timestamp: 1640210400,
        open: 1.13281,
        close: 1.13247,
        high: 1.13354,
        low: 1.13205
      },
      {
        timestamp: 1640214000,
        open: 1.13247,
        close: 1.13272,
        high: 1.13278,
        low: 1.13233
      },
      {
        timestamp: 1640217600,
        open: 1.13272,
        close: 1.13381,
        high: 1.13408,
        low: 1.13263
      },
      {
        timestamp: 1640221200,
        open: 1.13381,
        close: 1.1342,
        high: 1.13429,
        low: 1.13346
      },
      {
        timestamp: 1640224800,
        open: 1.1342,
        close: 1.13417,
        high: 1.13424,
        low: 1.1339
      },
      {
        timestamp: 1640228400,
        open: 1.13417,
        close: 1.13395,
        high: 1.13417,
        low: 1.13385
      },
      {
        timestamp: 1640232000,
        open: 1.13395,
        close: 1.13398,
        high: 1.13409,
        low: 1.13387
      },
      {
        timestamp: 1640235600,
        open: 1.13398,
        close: 1.1338,
        high: 1.13425,
        low: 1.13379
      },
      {
        timestamp: 1640239200,
        open: 1.1338,
        close: 1.13366,
        high: 1.13412,
        low: 1.1335
      },
      {
        timestamp: 1640242800,
        open: 1.13366,
        close: 1.13289,
        high: 1.13399,
        low: 1.13269
      },
      {
        timestamp: 1640246400,
        open: 1.13289,
        close: 1.13151,
        high: 1.13292,
        low: 1.13125
      },
      {
        timestamp: 1640250000,
        open: 1.13151,
        close: 1.13213,
        high: 1.13214,
        low: 1.13112
      },
      {
        timestamp: 1640253600,
        open: 1.13213,
        close: 1.13272,
        high: 1.1331,
        low: 1.13201
      },
      {
        timestamp: 1640257200,
        open: 1.13272,
        close: 1.13245,
        high: 1.13302,
        low: 1.13187
      },
      {
        timestamp: 1640260800,
        open: 1.13245,
        close: 1.13077,
        high: 1.13268,
        low: 1.13035
      },
      {
        timestamp: 1640264400,
        open: 1.13077,
        close: 1.12997,
        high: 1.1314,
        low: 1.12983
      },
      {
        timestamp: 1640268000,
        open: 1.12997,
        close: 1.13094,
        high: 1.13097,
        low: 1.12906
      },
      {
        timestamp: 1640271600,
        open: 1.13094,
        close: 1.13091,
        high: 1.13286,
        low: 1.13064
      },
      {
        timestamp: 1640275200,
        open: 1.13091,
        close: 1.13203,
        high: 1.13216,
        low: 1.13067
      },
      {
        timestamp: 1640278800,
        open: 1.13203,
        close: 1.13372,
        high: 1.13373,
        low: 1.13189
      },
      {
        timestamp: 1640282400,
        open: 1.13372,
        close: 1.1332,
        high: 1.13385,
        low: 1.13305
      },
      {
        timestamp: 1640286000,
        open: 1.1332,
        close: 1.13348,
        high: 1.13361,
        low: 1.13289
      },
      {
        timestamp: 1640289600,
        open: 1.13348,
        close: 1.13347,
        high: 1.1336,
        low: 1.13327
      },
      {
        timestamp: 1640293200,
        open: 1.13347,
        close: 1.13309,
        high: 1.13359,
        low: 1.1326
      },
      {
        timestamp: 1640296800,
        open: 1.13309,
        close: 1.13305,
        high: 1.13334,
        low: 1.13289
      },
      {
        timestamp: 1640300400,
        open: 1.13305,
        close: 1.13238,
        high: 1.13321,
        low: 1.13236
      },
      {
        timestamp: 1640304000,
        open: 1.13238,
        close: 1.13264,
        high: 1.13283,
        low: 1.13229
      },
      {
        timestamp: 1640307600,
        open: 1.13264,
        close: 1.13261,
        high: 1.13265,
        low: 1.1324
      },
      {
        timestamp: 1640311200,
        open: 1.13261,
        close: 1.13274,
        high: 1.13277,
        low: 1.13245
      },
      {
        timestamp: 1640314800,
        open: 1.13274,
        close: 1.13291,
        high: 1.13318,
        low: 1.13255
      },
      {
        timestamp: 1640318400,
        open: 1.13291,
        close: 1.13351,
        high: 1.13355,
        low: 1.1329
      },
      {
        timestamp: 1640322000,
        open: 1.13351,
        close: 1.1331,
        high: 1.13358,
        low: 1.1327
      },
      {
        timestamp: 1640325600,
        open: 1.1331,
        close: 1.13302,
        high: 1.13325,
        low: 1.13288
      },
      {
        timestamp: 1640329200,
        open: 1.13302,
        close: 1.13321,
        high: 1.13326,
        low: 1.133
      },
      {
        timestamp: 1640332800,
        open: 1.13321,
        close: 1.13369,
        high: 1.13419,
        low: 1.13316
      },
      {
        timestamp: 1640336400,
        open: 1.13369,
        close: 1.13411,
        high: 1.13422,
        low: 1.13365
      },
      {
        timestamp: 1640340000,
        open: 1.13411,
        close: 1.1342,
        high: 1.13443,
        low: 1.13367
      },
      {
        timestamp: 1640343600,
        open: 1.1342,
        close: 1.13281,
        high: 1.1342,
        low: 1.13271
      },
      {
        timestamp: 1640347200,
        open: 1.13281,
        close: 1.13228,
        high: 1.13332,
        low: 1.13141
      },
      {
        timestamp: 1640350800,
        open: 1.13228,
        close: 1.13219,
        high: 1.13362,
        low: 1.13168
      },
      {
        timestamp: 1640354400,
        open: 1.13219,
        close: 1.13264,
        high: 1.13294,
        low: 1.13195
      },
      {
        timestamp: 1640358000,
        open: 1.13264,
        close: 1.13088,
        high: 1.13275,
        low: 1.1308
      },
      {
        timestamp: 1640361600,
        open: 1.13088,
        close: 1.13131,
        high: 1.13156,
        low: 1.13078
      },
      {
        timestamp: 1640365200,
        open: 1.13131,
        close: 1.13189,
        high: 1.13195,
        low: 1.13097
      },
      {
        timestamp: 1640368800,
        open: 1.13189,
        close: 1.1323,
        high: 1.13311,
        low: 1.13106
      },
      {
        timestamp: 1640372400,
        open: 1.1323,
        close: 1.13223,
        high: 1.13304,
        low: 1.13223
      },
      {
        timestamp: 1640556000,
        open: 1.13223,
        close: 1.13173,
        high: 1.13202,
        low: 1.13125
      },
      {
        timestamp: 1640559600,
        open: 1.13173,
        close: 1.1324,
        high: 1.1327,
        low: 1.1318
      },
      {
        timestamp: 1640563200,
        open: 1.1324,
        close: 1.13187,
        high: 1.13241,
        low: 1.13143
      },
      {
        timestamp: 1640566800,
        open: 1.13187,
        close: 1.13192,
        high: 1.13234,
        low: 1.13168
      },
      {
        timestamp: 1640570400,
        open: 1.13192,
        close: 1.13195,
        high: 1.13207,
        low: 1.13173
      },
      {
        timestamp: 1640574000,
        open: 1.13195,
        close: 1.13243,
        high: 1.13243,
        low: 1.13192
      },
      {
        timestamp: 1640577600,
        open: 1.13243,
        close: 1.13193,
        high: 1.13255,
        low: 1.13187
      },
      {
        timestamp: 1640581200,
        open: 1.13193,
        close: 1.13158,
        high: 1.13194,
        low: 1.13147
      },
      {
        timestamp: 1640584800,
        open: 1.13158,
        close: 1.13171,
        high: 1.13208,
        low: 1.13138
      },
      {
        timestamp: 1640588400,
        open: 1.13171,
        close: 1.13304,
        high: 1.13313,
        low: 1.13171
      },
      {
        timestamp: 1640592000,
        open: 1.13304,
        close: 1.1313,
        high: 1.13353,
        low: 1.13108
      },
      {
        timestamp: 1640595600,
        open: 1.1313,
        close: 1.13092,
        high: 1.13136,
        low: 1.13036
      },
      {
        timestamp: 1640599200,
        open: 1.13092,
        close: 1.13214,
        high: 1.13239,
        low: 1.13077
      },
      {
        timestamp: 1640602800,
        open: 1.13214,
        close: 1.13156,
        high: 1.13216,
        low: 1.13125
      },
      {
        timestamp: 1640606400,
        open: 1.13156,
        close: 1.13143,
        high: 1.13189,
        low: 1.13042
      },
      {
        timestamp: 1640610000,
        open: 1.13143,
        close: 1.13124,
        high: 1.13148,
        low: 1.13067
      },
      {
        timestamp: 1640613600,
        open: 1.13124,
        close: 1.13304,
        high: 1.1332,
        low: 1.13112
      },
      {
        timestamp: 1640617200,
        open: 1.13304,
        close: 1.13192,
        high: 1.1331,
        low: 1.13133
      },
      {
        timestamp: 1640620800,
        open: 1.13192,
        close: 1.13289,
        high: 1.13321,
        low: 1.13183
      },
      {
        timestamp: 1640624400,
        open: 1.13289,
        close: 1.13274,
        high: 1.13297,
        low: 1.13239
      },
      {
        timestamp: 1640628000,
        open: 1.13274,
        close: 1.13261,
        high: 1.13303,
        low: 1.13252
      },
      {
        timestamp: 1640631600,
        open: 1.13261,
        close: 1.1326,
        high: 1.13284,
        low: 1.13223
      },
      {
        timestamp: 1640635200,
        open: 1.1326,
        close: 1.13281,
        high: 1.1331,
        low: 1.13243
      },
      {
        timestamp: 1640638800,
        open: 1.13281,
        close: 1.13305,
        high: 1.13305,
        low: 1.13245
      },
      {
        timestamp: 1640642400,
        open: 1.13305,
        close: 1.13287,
        high: 1.13327,
        low: 1.13266
      },
      {
        timestamp: 1640646000,
        open: 1.13287,
        close: 1.13302,
        high: 1.13303,
        low: 1.13271
      },
      {
        timestamp: 1640649600,
        open: 1.13302,
        close: 1.13314,
        high: 1.13318,
        low: 1.13252
      },
      {
        timestamp: 1640653200,
        open: 1.13314,
        close: 1.13205,
        high: 1.1332,
        low: 1.13203
      },
      {
        timestamp: 1640656800,
        open: 1.13205,
        close: 1.1324,
        high: 1.13277,
        low: 1.13188
      },
      {
        timestamp: 1640660400,
        open: 1.1324,
        close: 1.13261,
        high: 1.13271,
        low: 1.13203
      },
      {
        timestamp: 1640664000,
        open: 1.13261,
        close: 1.13275,
        high: 1.13278,
        low: 1.1324
      },
      {
        timestamp: 1640667600,
        open: 1.13275,
        close: 1.13287,
        high: 1.13294,
        low: 1.13263
      },
      {
        timestamp: 1640671200,
        open: 1.13287,
        close: 1.13261,
        high: 1.13295,
        low: 1.13258
      },
      {
        timestamp: 1640674800,
        open: 1.13261,
        close: 1.13181,
        high: 1.13279,
        low: 1.13169
      },
      {
        timestamp: 1640678400,
        open: 1.13181,
        close: 1.1324,
        high: 1.13317,
        low: 1.13163
      },
      {
        timestamp: 1640682000,
        open: 1.1324,
        close: 1.13313,
        high: 1.13314,
        low: 1.13215
      },
      {
        timestamp: 1640685600,
        open: 1.13313,
        close: 1.13312,
        high: 1.13338,
        low: 1.13289
      },
      {
        timestamp: 1640689200,
        open: 1.13312,
        close: 1.13333,
        high: 1.13339,
        low: 1.13268
      },
      {
        timestamp: 1640692800,
        open: 1.13333,
        close: 1.13288,
        high: 1.13339,
        low: 1.13272
      },
      {
        timestamp: 1640696400,
        open: 1.13288,
        close: 1.1322,
        high: 1.13332,
        low: 1.1322
      },
      {
        timestamp: 1640700000,
        open: 1.1322,
        close: 1.13072,
        high: 1.13236,
        low: 1.12928
      },
      {
        timestamp: 1640703600,
        open: 1.13072,
        close: 1.12958,
        high: 1.13089,
        low: 1.12902
      },
      {
        timestamp: 1640707200,
        open: 1.12958,
        close: 1.13139,
        high: 1.13213,
        low: 1.12953
      },
      {
        timestamp: 1640710800,
        open: 1.13139,
        close: 1.13112,
        high: 1.13216,
        low: 1.13096
      },
      {
        timestamp: 1640714400,
        open: 1.13112,
        close: 1.13038,
        high: 1.13112,
        low: 1.13014
      },
      {
        timestamp: 1640718000,
        open: 1.13038,
        close: 1.13031,
        high: 1.13038,
        low: 1.12996
      },
      {
        timestamp: 1640721600,
        open: 1.13031,
        close: 1.13122,
        high: 1.13128,
        low: 1.13027
      },
      {
        timestamp: 1640725200,
        open: 1.13122,
        close: 1.13116,
        high: 1.13147,
        low: 1.13085
      },
      {
        timestamp: 1640728800,
        open: 1.13116,
        close: 1.13085,
        high: 1.13185,
        low: 1.13078
      },
      {
        timestamp: 1640732400,
        open: 1.13085,
        close: 1.13137,
        high: 1.1315,
        low: 1.13083
      },
      {
        timestamp: 1640736000,
        open: 1.13137,
        close: 1.13107,
        high: 1.13141,
        low: 1.13073
      },
      {
        timestamp: 1640739600,
        open: 1.13107,
        close: 1.13007,
        high: 1.13124,
        low: 1.12981
      },
      {
        timestamp: 1640743200,
        open: 1.13007,
        close: 1.13076,
        high: 1.1308,
        low: 1.12986
      },
      {
        timestamp: 1640746800,
        open: 1.13076,
        close: 1.1307,
        high: 1.131,
        low: 1.13054
      },
      {
        timestamp: 1640750400,
        open: 1.1307,
        close: 1.13048,
        high: 1.13075,
        low: 1.13038
      },
      {
        timestamp: 1640754000,
        open: 1.13048,
        close: 1.13057,
        high: 1.13079,
        low: 1.13039
      },
      {
        timestamp: 1640757600,
        open: 1.13057,
        close: 1.1301,
        high: 1.13067,
        low: 1.13006
      },
      {
        timestamp: 1640761200,
        open: 1.1301,
        close: 1.13008,
        high: 1.13033,
        low: 1.12979
      },
      {
        timestamp: 1640764800,
        open: 1.13008,
        close: 1.12877,
        high: 1.13014,
        low: 1.12823
      },
      {
        timestamp: 1640768400,
        open: 1.12877,
        close: 1.12803,
        high: 1.12885,
        low: 1.12743
      },
      {
        timestamp: 1640772000,
        open: 1.12803,
        close: 1.12843,
        high: 1.12891,
        low: 1.12791
      },
      {
        timestamp: 1640775600,
        open: 1.12843,
        close: 1.13015,
        high: 1.1304,
        low: 1.1279
      },
      {
        timestamp: 1640779200,
        open: 1.13015,
        close: 1.12975,
        high: 1.1304,
        low: 1.12925
      },
      {
        timestamp: 1640782800,
        open: 1.12975,
        close: 1.13188,
        high: 1.13227,
        low: 1.12967
      },
      {
        timestamp: 1640786400,
        open: 1.13188,
        close: 1.13474,
        high: 1.13509,
        low: 1.13134
      },
      {
        timestamp: 1640790000,
        open: 1.13474,
        close: 1.13497,
        high: 1.13697,
        low: 1.13463
      },
      {
        timestamp: 1640793600,
        open: 1.13497,
        close: 1.13373,
        high: 1.1354,
        low: 1.13362
      },
      {
        timestamp: 1640797200,
        open: 1.13373,
        close: 1.13378,
        high: 1.13457,
        low: 1.13363
      },
      {
        timestamp: 1640800800,
        open: 1.13378,
        close: 1.13411,
        high: 1.13429,
        low: 1.1335
      },
      {
        timestamp: 1640804400,
        open: 1.13411,
        close: 1.13436,
        high: 1.13442,
        low: 1.13385
      },
      {
        timestamp: 1640808000,
        open: 1.13436,
        close: 1.1349,
        high: 1.13507,
        low: 1.1341
      },
      {
        timestamp: 1640811600,
        open: 1.1349,
        close: 1.13502,
        high: 1.1353,
        low: 1.1347
      },
      {
        timestamp: 1640815200,
        open: 1.13502,
        close: 1.13493,
        high: 1.1355,
        low: 1.13485
      },
      {
        timestamp: 1640818800,
        open: 1.13493,
        close: 1.13603,
        high: 1.13605,
        low: 1.13485
      },
      {
        timestamp: 1640822400,
        open: 1.13603,
        close: 1.1352,
        high: 1.13604,
        low: 1.13493
      },
      {
        timestamp: 1640826000,
        open: 1.1352,
        close: 1.13457,
        high: 1.13522,
        low: 1.13444
      },
      {
        timestamp: 1640829600,
        open: 1.13457,
        close: 1.13457,
        high: 1.13474,
        low: 1.13434
      },
      {
        timestamp: 1640833200,
        open: 1.13457,
        close: 1.13384,
        high: 1.13481,
        low: 1.13377
      },
      {
        timestamp: 1640836800,
        open: 1.13384,
        close: 1.13333,
        high: 1.13384,
        low: 1.13327
      },
      {
        timestamp: 1640840400,
        open: 1.13333,
        close: 1.13264,
        high: 1.13333,
        low: 1.13253
      },
      {
        timestamp: 1640844000,
        open: 1.13264,
        close: 1.13176,
        high: 1.13274,
        low: 1.13153
      },
      {
        timestamp: 1640847600,
        open: 1.13176,
        close: 1.13182,
        high: 1.13243,
        low: 1.13157
      },
      {
        timestamp: 1640851200,
        open: 1.13182,
        close: 1.13024,
        high: 1.13186,
        low: 1.13007
      },
      {
        timestamp: 1640854800,
        open: 1.13024,
        close: 1.13022,
        high: 1.13081,
        low: 1.13003
      },
      {
        timestamp: 1640858400,
        open: 1.13022,
        close: 1.13172,
        high: 1.1318,
        low: 1.13007
      },
      {
        timestamp: 1640862000,
        open: 1.13172,
        close: 1.13265,
        high: 1.13265,
        low: 1.13143
      },
      {
        timestamp: 1640865600,
        open: 1.13265,
        close: 1.13438,
        high: 1.13452,
        low: 1.13196
      },
      {
        timestamp: 1640869200,
        open: 1.13438,
        close: 1.13045,
        high: 1.13443,
        low: 1.13037
      },
      {
        timestamp: 1640872800,
        open: 1.13045,
        close: 1.13347,
        high: 1.13391,
        low: 1.12989
      },
      {
        timestamp: 1640876400,
        open: 1.13347,
        close: 1.13312,
        high: 1.13407,
        low: 1.13086
      },
      {
        timestamp: 1640880000,
        open: 1.13312,
        close: 1.13175,
        high: 1.13327,
        low: 1.13159
      },
      {
        timestamp: 1640883600,
        open: 1.13175,
        close: 1.13156,
        high: 1.13223,
        low: 1.13101
      },
      {
        timestamp: 1640887200,
        open: 1.13156,
        close: 1.13065,
        high: 1.1317,
        low: 1.13044
      },
      {
        timestamp: 1640890800,
        open: 1.13065,
        close: 1.13279,
        high: 1.13307,
        low: 1.13048
      },
      {
        timestamp: 1640894400,
        open: 1.13279,
        close: 1.13199,
        high: 1.13311,
        low: 1.13189
      },
      {
        timestamp: 1640898000,
        open: 1.13199,
        close: 1.13287,
        high: 1.13287,
        low: 1.13199
      },
      {
        timestamp: 1640901600,
        open: 1.13287,
        close: 1.13277,
        high: 1.13297,
        low: 1.13245
      },
      {
        timestamp: 1640905200,
        open: 1.13277,
        close: 1.1323,
        high: 1.13276,
        low: 1.13196
      },
      {
        timestamp: 1640908800,
        open: 1.1323,
        close: 1.13276,
        high: 1.13282,
        low: 1.13221
      },
      {
        timestamp: 1640912400,
        open: 1.13276,
        close: 1.13205,
        high: 1.13294,
        low: 1.13205
      },
      {
        timestamp: 1640916000,
        open: 1.13205,
        close: 1.13196,
        high: 1.13211,
        low: 1.13167
      },
      {
        timestamp: 1640919600,
        open: 1.13196,
        close: 1.13166,
        high: 1.13208,
        low: 1.13149
      },
      {
        timestamp: 1640923200,
        open: 1.13166,
        close: 1.13182,
        high: 1.13196,
        low: 1.13161
      },
      {
        timestamp: 1640926800,
        open: 1.13182,
        close: 1.13115,
        high: 1.13262,
        low: 1.13101
      },
      {
        timestamp: 1640930400,
        open: 1.13115,
        close: 1.13162,
        high: 1.13162,
        low: 1.13087
      },
      {
        timestamp: 1640934000,
        open: 1.13162,
        close: 1.13056,
        high: 1.13175,
        low: 1.13046
      },
      {
        timestamp: 1640937600,
        open: 1.13056,
        close: 1.13191,
        high: 1.13196,
        low: 1.13039
      },
      {
        timestamp: 1640941200,
        open: 1.13191,
        close: 1.13292,
        high: 1.13368,
        low: 1.13156
      },
      {
        timestamp: 1640944800,
        open: 1.13292,
        close: 1.13256,
        high: 1.1337,
        low: 1.13192
      },
      {
        timestamp: 1640948400,
        open: 1.13256,
        close: 1.13194,
        high: 1.1335,
        low: 1.13183
      },
      {
        timestamp: 1640952000,
        open: 1.13194,
        close: 1.13261,
        high: 1.13356,
        low: 1.13127
      },
      {
        timestamp: 1640955600,
        open: 1.13261,
        close: 1.13489,
        high: 1.1354,
        low: 1.13198
      },
      {
        timestamp: 1640959200,
        open: 1.13489,
        close: 1.13445,
        high: 1.13537,
        low: 1.13388
      },
      {
        timestamp: 1640962800,
        open: 1.13445,
        close: 1.13727,
        high: 1.13762,
        low: 1.13387
      },
      {
        timestamp: 1640966400,
        open: 1.13727,
        close: 1.13798,
        high: 1.13803,
        low: 1.13596
      },
      {
        timestamp: 1640970000,
        open: 1.13798,
        close: 1.13869,
        high: 1.1387,
        low: 1.13779
      },
      {
        timestamp: 1640973600,
        open: 1.13869,
        close: 1.13853,
        high: 1.1387,
        low: 1.13754
      },
      {
        timestamp: 1640977200,
        open: 1.13853,
        close: 1.13834,
        high: 1.13865,
        low: 1.13805
      },
      {
        timestamp: 1640980800,
        open: 1.13834,
        close: 1.13844,
        high: 1.13848,
        low: 1.13831
      },
      {
        timestamp: 1641160800,
        open: 1.13844,
        close: 1.13769,
        high: 1.13792,
        low: 1.13691
      },
      {
        timestamp: 1641164400,
        open: 1.13769,
        close: 1.13735,
        high: 1.138,
        low: 1.13699
      },
      {
        timestamp: 1641168000,
        open: 1.13735,
        close: 1.13625,
        high: 1.13763,
        low: 1.13616
      },
      {
        timestamp: 1641171600,
        open: 1.13625,
        close: 1.13576,
        high: 1.13659,
        low: 1.13506
      },
      {
        timestamp: 1641175200,
        open: 1.13576,
        close: 1.13432,
        high: 1.13577,
        low: 1.1343
      },
      {
        timestamp: 1641178800,
        open: 1.13432,
        close: 1.1349,
        high: 1.13505,
        low: 1.13417
      },
      {
        timestamp: 1641182400,
        open: 1.1349,
        close: 1.13411,
        high: 1.13497,
        low: 1.13393
      },
      {
        timestamp: 1641186000,
        open: 1.13411,
        close: 1.13385,
        high: 1.13418,
        low: 1.13366
      },
      {
        timestamp: 1641189600,
        open: 1.13385,
        close: 1.13438,
        high: 1.13468,
        low: 1.13384
      },
      {
        timestamp: 1641193200,
        open: 1.13438,
        close: 1.13489,
        high: 1.13508,
        low: 1.13356
      },
      {
        timestamp: 1641196800,
        open: 1.13489,
        close: 1.13472,
        high: 1.13488,
        low: 1.1336
      },
      {
        timestamp: 1641200400,
        open: 1.13472,
        close: 1.13524,
        high: 1.1357,
        low: 1.13363
      },
      {
        timestamp: 1641204000,
        open: 1.13524,
        close: 1.13559,
        high: 1.13605,
        low: 1.13512
      },
      {
        timestamp: 1641207600,
        open: 1.13559,
        close: 1.13563,
        high: 1.13669,
        low: 1.13522
      },
      {
        timestamp: 1641211200,
        open: 1.13563,
        close: 1.13594,
        high: 1.13601,
        low: 1.1347
      },
      {
        timestamp: 1641214800,
        open: 1.13594,
        close: 1.13513,
        high: 1.13648,
        low: 1.13466
      },
      {
        timestamp: 1641218400,
        open: 1.13513,
        close: 1.13067,
        high: 1.13524,
        low: 1.13007
      },
      {
        timestamp: 1641222000,
        open: 1.13067,
        close: 1.12923,
        high: 1.13101,
        low: 1.12909
      },
      {
        timestamp: 1641225600,
        open: 1.12923,
        close: 1.12893,
        high: 1.12996,
        low: 1.12834
      },
      {
        timestamp: 1641229200,
        open: 1.12893,
        close: 1.12844,
        high: 1.12906,
        low: 1.12802
      },
      {
        timestamp: 1641232800,
        open: 1.12844,
        close: 1.12955,
        high: 1.12975,
        low: 1.12839
      },
      {
        timestamp: 1641236400,
        open: 1.12955,
        close: 1.12979,
        high: 1.13003,
        low: 1.12942
      },
      {
        timestamp: 1641240000,
        open: 1.12979,
        close: 1.1297,
        high: 1.13009,
        low: 1.12943
      },
      {
        timestamp: 1641243600,
        open: 1.1297,
        close: 1.1299,
        high: 1.12999,
        low: 1.12951
      },
      {
        timestamp: 1641247200,
        open: 1.1299,
        close: 1.12971,
        high: 1.13014,
        low: 1.1295
      },
      {
        timestamp: 1641250800,
        open: 1.12971,
        close: 1.13024,
        high: 1.13031,
        low: 1.12963
      },
      {
        timestamp: 1641254400,
        open: 1.13024,
        close: 1.13063,
        high: 1.13073,
        low: 1.1302
      },
      {
        timestamp: 1641258000,
        open: 1.13063,
        close: 1.13071,
        high: 1.13095,
        low: 1.1302
      },
      {
        timestamp: 1641261600,
        open: 1.13071,
        close: 1.13029,
        high: 1.13101,
        low: 1.13004
      },
      {
        timestamp: 1641265200,
        open: 1.13029,
        close: 1.12984,
        high: 1.1305,
        low: 1.12959
      },
      {
        timestamp: 1641268800,
        open: 1.12984,
        close: 1.13013,
        high: 1.13062,
        low: 1.1297
      },
      {
        timestamp: 1641272400,
        open: 1.13013,
        close: 1.13054,
        high: 1.13064,
        low: 1.13012
      },
      {
        timestamp: 1641276000,
        open: 1.13054,
        close: 1.12939,
        high: 1.13078,
        low: 1.12926
      },
      {
        timestamp: 1641279600,
        open: 1.12939,
        close: 1.12922,
        high: 1.1295,
        low: 1.1284
      },
      {
        timestamp: 1641283200,
        open: 1.12922,
        close: 1.12902,
        high: 1.1301,
        low: 1.12878
      },
      {
        timestamp: 1641286800,
        open: 1.12902,
        close: 1.13025,
        high: 1.13088,
        low: 1.12838
      },
      {
        timestamp: 1641290400,
        open: 1.13025,
        close: 1.12953,
        high: 1.13062,
        low: 1.12951
      },
      {
        timestamp: 1641294000,
        open: 1.12953,
        close: 1.12767,
        high: 1.12971,
        low: 1.12767
      },
      {
        timestamp: 1641297600,
        open: 1.12767,
        close: 1.12736,
        high: 1.12842,
        low: 1.12729
      },
      {
        timestamp: 1641301200,
        open: 1.12736,
        close: 1.12806,
        high: 1.12904,
        low: 1.12736
      },
      {
        timestamp: 1641304800,
        open: 1.12806,
        close: 1.12856,
        high: 1.12886,
        low: 1.1278
      },
      {
        timestamp: 1641308400,
        open: 1.12856,
        close: 1.13096,
        high: 1.13231,
        low: 1.12841
      },
      {
        timestamp: 1641312000,
        open: 1.13096,
        close: 1.12952,
        high: 1.13164,
        low: 1.12861
      },
      {
        timestamp: 1641315600,
        open: 1.12952,
        close: 1.12943,
        high: 1.12967,
        low: 1.12889
      },
      {
        timestamp: 1641319200,
        open: 1.12943,
        close: 1.12808,
        high: 1.12949,
        low: 1.12799
      },
      {
        timestamp: 1641322800,
        open: 1.12808,
        close: 1.12883,
        high: 1.12937,
        low: 1.12784
      },
      {
        timestamp: 1641326400,
        open: 1.12883,
        close: 1.12853,
        high: 1.12901,
        low: 1.12815
      },
      {
        timestamp: 1641330000,
        open: 1.12853,
        close: 1.12878,
        high: 1.12889,
        low: 1.1282
      },
      {
        timestamp: 1641333600,
        open: 1.12878,
        close: 1.12876,
        high: 1.12899,
        low: 1.12865
      },
      {
        timestamp: 1641337200,
        open: 1.12876,
        close: 1.12836,
        high: 1.12887,
        low: 1.12825
      },
      {
        timestamp: 1641340800,
        open: 1.12836,
        close: 1.12778,
        high: 1.12864,
        low: 1.12776
      },
      {
        timestamp: 1641344400,
        open: 1.12778,
        close: 1.12872,
        high: 1.12893,
        low: 1.12778
      },
      {
        timestamp: 1641348000,
        open: 1.12872,
        close: 1.12928,
        high: 1.12949,
        low: 1.12859
      },
      {
        timestamp: 1641351600,
        open: 1.12928,
        close: 1.12965,
        high: 1.12975,
        low: 1.12919
      },
      {
        timestamp: 1641355200,
        open: 1.12965,
        close: 1.12894,
        high: 1.12965,
        low: 1.12889
      },
      {
        timestamp: 1641358800,
        open: 1.12894,
        close: 1.12945,
        high: 1.12953,
        low: 1.12879
      },
      {
        timestamp: 1641362400,
        open: 1.12945,
        close: 1.12965,
        high: 1.13018,
        low: 1.12935
      },
      {
        timestamp: 1641366000,
        open: 1.12965,
        close: 1.12993,
        high: 1.13032,
        low: 1.12953
      },
      {
        timestamp: 1641369600,
        open: 1.12993,
        close: 1.13037,
        high: 1.13116,
        low: 1.12972
      },
      {
        timestamp: 1641373200,
        open: 1.13037,
        close: 1.13069,
        high: 1.13094,
        low: 1.13006
      },
      {
        timestamp: 1641376800,
        open: 1.13069,
        close: 1.13087,
        high: 1.13147,
        low: 1.13049
      },
      {
        timestamp: 1641380400,
        open: 1.13087,
        close: 1.13196,
        high: 1.13243,
        low: 1.13071
      },
      {
        timestamp: 1641384000,
        open: 1.13196,
        close: 1.13084,
        high: 1.13266,
        low: 1.13054
      },
      {
        timestamp: 1641387600,
        open: 1.13084,
        close: 1.13244,
        high: 1.13293,
        low: 1.13038
      },
      {
        timestamp: 1641391200,
        open: 1.13244,
        close: 1.13365,
        high: 1.13394,
        low: 1.13217
      },
      {
        timestamp: 1641394800,
        open: 1.13365,
        close: 1.13375,
        high: 1.13448,
        low: 1.13329
      },
      {
        timestamp: 1641398400,
        open: 1.13375,
        close: 1.13347,
        high: 1.13401,
        low: 1.13234
      },
      {
        timestamp: 1641402000,
        open: 1.13347,
        close: 1.13419,
        high: 1.13471,
        low: 1.13338
      },
      {
        timestamp: 1641405600,
        open: 1.13419,
        close: 1.13381,
        high: 1.13452,
        low: 1.13362
      },
      {
        timestamp: 1641409200,
        open: 1.13381,
        close: 1.1311,
        high: 1.13405,
        low: 1.13086
      },
      {
        timestamp: 1641412800,
        open: 1.1311,
        close: 1.13116,
        high: 1.13139,
        low: 1.13027
      },
      {
        timestamp: 1641416400,
        open: 1.13116,
        close: 1.13166,
        high: 1.13166,
        low: 1.13084
      },
      {
        timestamp: 1641420000,
        open: 1.13166,
        close: 1.13123,
        high: 1.13187,
        low: 1.13097
      },
      {
        timestamp: 1641423600,
        open: 1.13123,
        close: 1.13113,
        high: 1.1315,
        low: 1.13094
      },
      {
        timestamp: 1641427200,
        open: 1.13113,
        close: 1.13087,
        high: 1.13138,
        low: 1.13082
      },
      {
        timestamp: 1641430800,
        open: 1.13087,
        close: 1.13161,
        high: 1.13194,
        low: 1.13054
      },
      {
        timestamp: 1641434400,
        open: 1.13161,
        close: 1.13198,
        high: 1.13211,
        low: 1.13126
      },
      {
        timestamp: 1641438000,
        open: 1.13198,
        close: 1.13158,
        high: 1.13197,
        low: 1.13093
      },
      {
        timestamp: 1641441600,
        open: 1.13158,
        close: 1.13129,
        high: 1.13171,
        low: 1.13118
      },
      {
        timestamp: 1641445200,
        open: 1.13129,
        close: 1.13097,
        high: 1.13129,
        low: 1.13077
      },
      {
        timestamp: 1641448800,
        open: 1.13097,
        close: 1.12977,
        high: 1.13098,
        low: 1.12968
      },
      {
        timestamp: 1641452400,
        open: 1.12977,
        close: 1.12902,
        high: 1.12981,
        low: 1.12852
      },
      {
        timestamp: 1641456000,
        open: 1.12902,
        close: 1.12944,
        high: 1.13101,
        low: 1.12853
      },
      {
        timestamp: 1641459600,
        open: 1.12944,
        close: 1.13057,
        high: 1.1309,
        low: 1.12892
      },
      {
        timestamp: 1641463200,
        open: 1.13057,
        close: 1.13129,
        high: 1.13274,
        low: 1.13051
      },
      {
        timestamp: 1641466800,
        open: 1.13129,
        close: 1.13138,
        high: 1.13221,
        low: 1.13027
      },
      {
        timestamp: 1641470400,
        open: 1.13138,
        close: 1.1314,
        high: 1.1319,
        low: 1.13054
      },
      {
        timestamp: 1641474000,
        open: 1.1314,
        close: 1.13128,
        high: 1.13206,
        low: 1.13036
      },
      {
        timestamp: 1641477600,
        open: 1.13128,
        close: 1.13233,
        high: 1.13325,
        low: 1.13068
      },
      {
        timestamp: 1641481200,
        open: 1.13233,
        close: 1.12964,
        high: 1.13311,
        low: 1.12935
      },
      {
        timestamp: 1641484800,
        open: 1.12964,
        close: 1.13077,
        high: 1.13098,
        low: 1.12908
      },
      {
        timestamp: 1641488400,
        open: 1.13077,
        close: 1.12964,
        high: 1.13097,
        low: 1.12938
      },
      {
        timestamp: 1641492000,
        open: 1.12964,
        close: 1.12923,
        high: 1.12984,
        low: 1.12887
      },
      {
        timestamp: 1641495600,
        open: 1.12923,
        close: 1.12876,
        high: 1.13004,
        low: 1.12872
      },
      {
        timestamp: 1641499200,
        open: 1.12876,
        close: 1.12918,
        high: 1.12946,
        low: 1.12867
      },
      {
        timestamp: 1641502800,
        open: 1.12918,
        close: 1.13014,
        high: 1.13014,
        low: 1.12878
      },
      {
        timestamp: 1641506400,
        open: 1.13014,
        close: 1.12982,
        high: 1.13017,
        low: 1.12971
      },
      {
        timestamp: 1641510000,
        open: 1.12982,
        close: 1.12955,
        high: 1.1299,
        low: 1.12945
      },
      {
        timestamp: 1641513600,
        open: 1.12955,
        close: 1.12965,
        high: 1.12984,
        low: 1.12905
      },
      {
        timestamp: 1641517200,
        open: 1.12965,
        close: 1.13017,
        high: 1.13017,
        low: 1.12965
      },
      {
        timestamp: 1641520800,
        open: 1.13017,
        close: 1.12962,
        high: 1.13023,
        low: 1.12958
      },
      {
        timestamp: 1641524400,
        open: 1.12962,
        close: 1.12997,
        high: 1.13024,
        low: 1.12962
      },
      {
        timestamp: 1641528000,
        open: 1.12997,
        close: 1.13013,
        high: 1.13038,
        low: 1.12985
      },
      {
        timestamp: 1641531600,
        open: 1.13013,
        close: 1.13027,
        high: 1.1304,
        low: 1.13005
      },
      {
        timestamp: 1641535200,
        open: 1.13027,
        close: 1.12998,
        high: 1.13039,
        low: 1.12989
      },
      {
        timestamp: 1641538800,
        open: 1.12998,
        close: 1.13062,
        high: 1.13068,
        low: 1.12987
      },
      {
        timestamp: 1641542400,
        open: 1.13062,
        close: 1.13127,
        high: 1.13158,
        low: 1.13021
      },
      {
        timestamp: 1641546000,
        open: 1.13127,
        close: 1.13157,
        high: 1.13181,
        low: 1.131
      },
      {
        timestamp: 1641549600,
        open: 1.13157,
        close: 1.13067,
        high: 1.13198,
        low: 1.1304
      },
      {
        timestamp: 1641553200,
        open: 1.13067,
        close: 1.13051,
        high: 1.13089,
        low: 1.13014
      },
      {
        timestamp: 1641556800,
        open: 1.13051,
        close: 1.1298,
        high: 1.13056,
        low: 1.12966
      },
      {
        timestamp: 1641560400,
        open: 1.1298,
        close: 1.13023,
        high: 1.13234,
        low: 1.12931
      },
      {
        timestamp: 1641564000,
        open: 1.13023,
        close: 1.13308,
        high: 1.13351,
        low: 1.12994
      },
      {
        timestamp: 1641567600,
        open: 1.13308,
        close: 1.13477,
        high: 1.13499,
        low: 1.13301
      },
      {
        timestamp: 1641571200,
        open: 1.13477,
        close: 1.1357,
        high: 1.13577,
        low: 1.13382
      },
      {
        timestamp: 1641574800,
        open: 1.1357,
        close: 1.13573,
        high: 1.13592,
        low: 1.135
      },
      {
        timestamp: 1641578400,
        open: 1.13573,
        close: 1.13581,
        high: 1.13628,
        low: 1.13553
      },
      {
        timestamp: 1641582000,
        open: 1.13581,
        close: 1.13635,
        high: 1.13654,
        low: 1.13573
      },
      {
        timestamp: 1641585600,
        open: 1.13635,
        close: 1.13621,
        high: 1.13648,
        low: 1.13579
      },
      {
        timestamp: 1641589200,
        open: 1.13621,
        close: 1.13624,
        high: 1.13631,
        low: 1.13592
      },
      {
        timestamp: 1641754800,
        open: 1.13624,
        close: 1.13565,
        high: 1.13565,
        low: 1.13556
      },
      {
        timestamp: 1641758400,
        open: 1.13565,
        close: 1.13565,
        high: 1.13566,
        low: 1.13565
      },
      {
        timestamp: 1641762000,
        open: 1.13565,
        close: 1.13574,
        high: 1.13628,
        low: 1.1356
      },
      {
        timestamp: 1641765600,
        open: 1.13574,
        close: 1.13604,
        high: 1.13631,
        low: 1.13558
      },
      {
        timestamp: 1641769200,
        open: 1.13604,
        close: 1.13541,
        high: 1.13619,
        low: 1.13502
      },
      {
        timestamp: 1641772800,
        open: 1.13541,
        close: 1.13557,
        high: 1.13587,
        low: 1.1352
      },
      {
        timestamp: 1641776400,
        open: 1.13557,
        close: 1.13508,
        high: 1.13571,
        low: 1.13505
      },
      {
        timestamp: 1641780000,
        open: 1.13508,
        close: 1.13402,
        high: 1.13524,
        low: 1.13398
      },
      {
        timestamp: 1641783600,
        open: 1.13402,
        close: 1.13381,
        high: 1.13402,
        low: 1.13343
      },
      {
        timestamp: 1641787200,
        open: 1.13381,
        close: 1.13348,
        high: 1.13412,
        low: 1.13339
      },
      {
        timestamp: 1641790800,
        open: 1.13348,
        close: 1.13354,
        high: 1.1336,
        low: 1.13326
      },
      {
        timestamp: 1641794400,
        open: 1.13354,
        close: 1.13339,
        high: 1.13359,
        low: 1.1327
      },
      {
        timestamp: 1641798000,
        open: 1.13339,
        close: 1.13297,
        high: 1.1337,
        low: 1.13277
      },
      {
        timestamp: 1641801600,
        open: 1.13297,
        close: 1.13362,
        high: 1.13367,
        low: 1.13212
      },
      {
        timestamp: 1641805200,
        open: 1.13362,
        close: 1.13272,
        high: 1.13452,
        low: 1.13263
      },
      {
        timestamp: 1641808800,
        open: 1.13272,
        close: 1.13303,
        high: 1.1335,
        low: 1.1323
      },
      {
        timestamp: 1641812400,
        open: 1.13303,
        close: 1.1326,
        high: 1.13369,
        low: 1.13228
      },
      {
        timestamp: 1641816000,
        open: 1.1326,
        close: 1.13168,
        high: 1.13301,
        low: 1.13123
      },
      {
        timestamp: 1641819600,
        open: 1.13168,
        close: 1.12976,
        high: 1.13248,
        low: 1.12955
      },
      {
        timestamp: 1641823200,
        open: 1.12976,
        close: 1.12979,
        high: 1.12985,
        low: 1.12854
      },
      {
        timestamp: 1641826800,
        open: 1.12979,
        close: 1.13192,
        high: 1.13277,
        low: 1.12933
      },
      {
        timestamp: 1641830400,
        open: 1.13192,
        close: 1.13281,
        high: 1.13294,
        low: 1.13167
      },
      {
        timestamp: 1641834000,
        open: 1.13281,
        close: 1.13291,
        high: 1.13355,
        low: 1.1328
      },
      {
        timestamp: 1641837600,
        open: 1.13291,
        close: 1.13229,
        high: 1.13318,
        low: 1.13216
      },
      {
        timestamp: 1641841200,
        open: 1.13229,
        close: 1.13238,
        high: 1.13276,
        low: 1.13206
      },
      {
        timestamp: 1641844800,
        open: 1.13238,
        close: 1.13268,
        high: 1.13307,
        low: 1.13228
      },
      {
        timestamp: 1641848400,
        open: 1.13268,
        close: 1.13284,
        high: 1.13308,
        low: 1.13256
      },
      {
        timestamp: 1641852000,
        open: 1.13284,
        close: 1.1328,
        high: 1.13325,
        low: 1.13237
      },
      {
        timestamp: 1641855600,
        open: 1.1328,
        close: 1.13306,
        high: 1.13332,
        low: 1.1328
      },
      {
        timestamp: 1641859200,
        open: 1.13306,
        close: 1.13335,
        high: 1.13354,
        low: 1.13291
      },
      {
        timestamp: 1641862800,
        open: 1.13335,
        close: 1.13365,
        high: 1.13423,
        low: 1.13329
      },
      {
        timestamp: 1641866400,
        open: 1.13365,
        close: 1.13387,
        high: 1.13442,
        low: 1.1335
      },
      {
        timestamp: 1641870000,
        open: 1.13387,
        close: 1.13421,
        high: 1.13431,
        low: 1.13377
      },
      {
        timestamp: 1641873600,
        open: 1.13421,
        close: 1.13414,
        high: 1.13422,
        low: 1.13382
      },
      {
        timestamp: 1641877200,
        open: 1.13414,
        close: 1.13395,
        high: 1.13449,
        low: 1.13391
      },
      {
        timestamp: 1641880800,
        open: 1.13395,
        close: 1.13367,
        high: 1.13442,
        low: 1.13321
      },
      {
        timestamp: 1641884400,
        open: 1.13367,
        close: 1.1342,
        high: 1.13451,
        low: 1.13355
      },
      {
        timestamp: 1641888000,
        open: 1.1342,
        close: 1.13499,
        high: 1.13522,
        low: 1.13398
      },
      {
        timestamp: 1641891600,
        open: 1.13499,
        close: 1.13338,
        high: 1.13525,
        low: 1.13293
      },
      {
        timestamp: 1641895200,
        open: 1.13338,
        close: 1.13309,
        high: 1.13395,
        low: 1.13269
      },
      {
        timestamp: 1641898800,
        open: 1.13309,
        close: 1.13393,
        high: 1.13442,
        low: 1.13265
      },
      {
        timestamp: 1641902400,
        open: 1.13393,
        close: 1.13318,
        high: 1.13428,
        low: 1.13312
      },
      {
        timestamp: 1641906000,
        open: 1.13318,
        close: 1.13265,
        high: 1.13392,
        low: 1.13237
      },
      {
        timestamp: 1641909600,
        open: 1.13265,
        close: 1.1324,
        high: 1.1331,
        low: 1.13134
      },
      {
        timestamp: 1641913200,
        open: 1.1324,
        close: 1.1341,
        high: 1.1346,
        low: 1.13235
      },
      {
        timestamp: 1641916800,
        open: 1.1341,
        close: 1.13626,
        high: 1.13693,
        low: 1.13409
      },
      {
        timestamp: 1641920400,
        open: 1.13626,
        close: 1.13547,
        high: 1.13639,
        low: 1.13534
      },
      {
        timestamp: 1641924000,
        open: 1.13547,
        close: 1.13671,
        high: 1.1369,
        low: 1.13526
      },
      {
        timestamp: 1641927600,
        open: 1.13671,
        close: 1.13674,
        high: 1.13759,
        low: 1.13634
      },
      {
        timestamp: 1641931200,
        open: 1.13674,
        close: 1.13663,
        high: 1.13701,
        low: 1.13646
      },
      {
        timestamp: 1641934800,
        open: 1.13663,
        close: 1.1369,
        high: 1.13712,
        low: 1.13655
      },
      {
        timestamp: 1641938400,
        open: 1.1369,
        close: 1.13694,
        high: 1.13731,
        low: 1.13689
      },
      {
        timestamp: 1641942000,
        open: 1.13694,
        close: 1.13666,
        high: 1.13717,
        low: 1.13663
      },
      {
        timestamp: 1641945600,
        open: 1.13666,
        close: 1.13715,
        high: 1.1374,
        low: 1.13664
      },
      {
        timestamp: 1641949200,
        open: 1.13715,
        close: 1.13711,
        high: 1.13727,
        low: 1.13648
      },
      {
        timestamp: 1641952800,
        open: 1.13711,
        close: 1.13725,
        high: 1.13742,
        low: 1.13684
      },
      {
        timestamp: 1641956400,
        open: 1.13725,
        close: 1.13753,
        high: 1.13762,
        low: 1.13719
      },
      {
        timestamp: 1641960000,
        open: 1.13753,
        close: 1.13732,
        high: 1.13757,
        low: 1.13731
      },
      {
        timestamp: 1641963600,
        open: 1.13732,
        close: 1.13738,
        high: 1.13787,
        low: 1.13732
      },
      {
        timestamp: 1641967200,
        open: 1.13738,
        close: 1.13648,
        high: 1.13757,
        low: 1.13648
      },
      {
        timestamp: 1641970800,
        open: 1.13648,
        close: 1.1359,
        high: 1.13681,
        low: 1.13558
      },
      {
        timestamp: 1641974400,
        open: 1.1359,
        close: 1.13675,
        high: 1.13699,
        low: 1.13556
      },
      {
        timestamp: 1641978000,
        open: 1.13675,
        close: 1.13679,
        high: 1.13741,
        low: 1.1363
      },
      {
        timestamp: 1641981600,
        open: 1.13679,
        close: 1.1358,
        high: 1.13687,
        low: 1.13552
      },
      {
        timestamp: 1641985200,
        open: 1.1358,
        close: 1.13621,
        high: 1.13658,
        low: 1.13568
      },
      {
        timestamp: 1641988800,
        open: 1.13621,
        close: 1.13641,
        high: 1.13673,
        low: 1.13603
      },
      {
        timestamp: 1641992400,
        open: 1.13641,
        close: 1.14104,
        high: 1.14159,
        low: 1.1362
      },
      {
        timestamp: 1641996000,
        open: 1.14104,
        close: 1.14163,
        high: 1.14188,
        low: 1.13952
      },
      {
        timestamp: 1641999600,
        open: 1.14163,
        close: 1.14213,
        high: 1.14258,
        low: 1.14128
      },
      {
        timestamp: 1642003200,
        open: 1.14213,
        close: 1.14319,
        high: 1.14326,
        low: 1.14194
      },
      {
        timestamp: 1642006800,
        open: 1.14319,
        close: 1.14445,
        high: 1.14459,
        low: 1.14291
      },
      {
        timestamp: 1642010400,
        open: 1.14445,
        close: 1.14394,
        high: 1.14459,
        low: 1.14391
      },
      {
        timestamp: 1642014000,
        open: 1.14394,
        close: 1.14506,
        high: 1.14534,
        low: 1.1439
      },
      {
        timestamp: 1642017600,
        open: 1.14506,
        close: 1.14504,
        high: 1.14515,
        low: 1.14449
      },
      {
        timestamp: 1642021200,
        open: 1.14504,
        close: 1.14444,
        high: 1.14511,
        low: 1.14403
      },
      {
        timestamp: 1642024800,
        open: 1.14444,
        close: 1.14418,
        high: 1.14457,
        low: 1.14416
      },
      {
        timestamp: 1642028400,
        open: 1.14418,
        close: 1.14401,
        high: 1.14456,
        low: 1.14401
      },
      {
        timestamp: 1642032000,
        open: 1.14401,
        close: 1.14419,
        high: 1.14488,
        low: 1.14398
      },
      {
        timestamp: 1642035600,
        open: 1.14419,
        close: 1.14395,
        high: 1.14455,
        low: 1.14364
      },
      {
        timestamp: 1642039200,
        open: 1.14395,
        close: 1.14427,
        high: 1.14465,
        low: 1.14388
      },
      {
        timestamp: 1642042800,
        open: 1.14427,
        close: 1.14428,
        high: 1.14439,
        low: 1.14363
      },
      {
        timestamp: 1642046400,
        open: 1.14428,
        close: 1.1443,
        high: 1.1448,
        low: 1.14428
      },
      {
        timestamp: 1642050000,
        open: 1.1443,
        close: 1.14417,
        high: 1.14468,
        low: 1.14411
      },
      {
        timestamp: 1642053600,
        open: 1.14417,
        close: 1.14393,
        high: 1.14435,
        low: 1.14389
      },
      {
        timestamp: 1642057200,
        open: 1.14393,
        close: 1.14655,
        high: 1.1467,
        low: 1.14392
      },
      {
        timestamp: 1642060800,
        open: 1.14655,
        close: 1.14669,
        high: 1.14789,
        low: 1.14628
      },
      {
        timestamp: 1642064400,
        open: 1.14669,
        close: 1.1472,
        high: 1.14755,
        low: 1.1459
      },
      {
        timestamp: 1642068000,
        open: 1.1472,
        close: 1.14613,
        high: 1.14782,
        low: 1.1458
      },
      {
        timestamp: 1642071600,
        open: 1.14613,
        close: 1.14632,
        high: 1.14704,
        low: 1.1459
      },
      {
        timestamp: 1642075200,
        open: 1.14632,
        close: 1.14592,
        high: 1.14704,
        low: 1.14537
      },
      {
        timestamp: 1642078800,
        open: 1.14592,
        close: 1.14726,
        high: 1.14726,
        low: 1.14584
      },
      {
        timestamp: 1642082400,
        open: 1.14726,
        close: 1.14743,
        high: 1.14823,
        low: 1.14687
      },
      {
        timestamp: 1642086000,
        open: 1.14743,
        close: 1.14613,
        high: 1.14782,
        low: 1.14478
      },
      {
        timestamp: 1642089600,
        open: 1.14613,
        close: 1.1466,
        high: 1.14725,
        low: 1.14574
      },
      {
        timestamp: 1642093200,
        open: 1.1466,
        close: 1.14618,
        high: 1.14679,
        low: 1.14557
      },
      {
        timestamp: 1642096800,
        open: 1.14618,
        close: 1.14591,
        high: 1.14669,
        low: 1.14582
      },
      {
        timestamp: 1642100400,
        open: 1.14591,
        close: 1.14623,
        high: 1.14659,
        low: 1.1458
      },
      {
        timestamp: 1642104000,
        open: 1.14623,
        close: 1.14529,
        high: 1.14629,
        low: 1.14515
      },
      {
        timestamp: 1642107600,
        open: 1.14529,
        close: 1.14553,
        high: 1.14564,
        low: 1.14516
      },
      {
        timestamp: 1642111200,
        open: 1.14553,
        close: 1.1458,
        high: 1.14645,
        low: 1.14562
      },
      {
        timestamp: 1642114800,
        open: 1.1458,
        close: 1.14573,
        high: 1.14601,
        low: 1.14557
      },
      {
        timestamp: 1642118400,
        open: 1.14573,
        close: 1.1457,
        high: 1.14593,
        low: 1.14519
      },
      {
        timestamp: 1642122000,
        open: 1.1457,
        close: 1.14667,
        high: 1.14687,
        low: 1.14568
      },
      {
        timestamp: 1642125600,
        open: 1.14667,
        close: 1.14719,
        high: 1.1472,
        low: 1.14631
      },
      {
        timestamp: 1642129200,
        open: 1.14719,
        close: 1.14744,
        high: 1.14762,
        low: 1.14696
      },
      {
        timestamp: 1642132800,
        open: 1.14744,
        close: 1.14743,
        high: 1.14772,
        low: 1.14716
      },
      {
        timestamp: 1642136400,
        open: 1.14743,
        close: 1.14795,
        high: 1.14833,
        low: 1.14742
      },
      {
        timestamp: 1642140000,
        open: 1.14795,
        close: 1.14705,
        high: 1.14813,
        low: 1.14698
      },
      {
        timestamp: 1642143600,
        open: 1.14705,
        close: 1.14664,
        high: 1.14728,
        low: 1.14608
      },
      {
        timestamp: 1642147200,
        open: 1.14664,
        close: 1.14689,
        high: 1.14752,
        low: 1.1463
      },
      {
        timestamp: 1642150800,
        open: 1.14689,
        close: 1.14587,
        high: 1.14696,
        low: 1.14578
      },
      {
        timestamp: 1642154400,
        open: 1.14587,
        close: 1.14571,
        high: 1.14615,
        low: 1.14546
      },
      {
        timestamp: 1642158000,
        open: 1.14571,
        close: 1.14527,
        high: 1.14599,
        low: 1.1449
      },
      {
        timestamp: 1642161600,
        open: 1.14527,
        close: 1.14443,
        high: 1.14568,
        low: 1.14425
      },
      {
        timestamp: 1642165200,
        open: 1.14443,
        close: 1.14388,
        high: 1.14557,
        low: 1.14339
      },
      {
        timestamp: 1642168800,
        open: 1.14388,
        close: 1.14427,
        high: 1.14505,
        low: 1.14308
      },
      {
        timestamp: 1642172400,
        open: 1.14427,
        close: 1.14339,
        high: 1.1443,
        low: 1.14245
      },
      {
        timestamp: 1642176000,
        open: 1.14339,
        close: 1.14148,
        high: 1.14365,
        low: 1.14095
      },
      {
        timestamp: 1642179600,
        open: 1.14148,
        close: 1.14028,
        high: 1.14148,
        low: 1.13991
      },
      {
        timestamp: 1642183200,
        open: 1.14028,
        close: 1.14103,
        high: 1.14119,
        low: 1.1402
      },
      {
        timestamp: 1642186800,
        open: 1.14103,
        close: 1.14159,
        high: 1.14182,
        low: 1.14075
      },
      {
        timestamp: 1642190400,
        open: 1.14159,
        close: 1.14153,
        high: 1.14183,
        low: 1.14137
      },
      {
        timestamp: 1642194000,
        open: 1.14153,
        close: 1.14164,
        high: 1.14179,
        low: 1.14127
      },
      {
        timestamp: 1642359600,
        open: 1.14164,
        close: 1.14145,
        high: 1.14159,
        low: 1.14144
      },
      {
        timestamp: 1642363200,
        open: 1.14145,
        close: 1.14179,
        high: 1.1421,
        low: 1.14145
      },
      {
        timestamp: 1642366800,
        open: 1.14179,
        close: 1.14206,
        high: 1.1421,
        low: 1.14179
      },
      {
        timestamp: 1642370400,
        open: 1.14206,
        close: 1.14177,
        high: 1.14208,
        low: 1.14166
      },
      {
        timestamp: 1642374000,
        open: 1.14177,
        close: 1.1412,
        high: 1.14177,
        low: 1.14064
      },
      {
        timestamp: 1642377600,
        open: 1.1412,
        close: 1.14081,
        high: 1.14122,
        low: 1.1404
      },
      {
        timestamp: 1642381200,
        open: 1.14081,
        close: 1.14075,
        high: 1.14091,
        low: 1.14007
      },
      {
        timestamp: 1642384800,
        open: 1.14075,
        close: 1.14163,
        high: 1.14184,
        low: 1.14044
      },
      {
        timestamp: 1642388400,
        open: 1.14163,
        close: 1.1419,
        high: 1.14204,
        low: 1.14149
      },
      {
        timestamp: 1642392000,
        open: 1.1419,
        close: 1.14195,
        high: 1.1422,
        low: 1.1417
      },
      {
        timestamp: 1642395600,
        open: 1.14195,
        close: 1.14195,
        high: 1.14233,
        low: 1.14171
      },
      {
        timestamp: 1642399200,
        open: 1.14195,
        close: 1.14132,
        high: 1.14212,
        low: 1.14125
      },
      {
        timestamp: 1642402800,
        open: 1.14132,
        close: 1.14258,
        high: 1.14287,
        low: 1.1413
      },
      {
        timestamp: 1642406400,
        open: 1.14258,
        close: 1.14291,
        high: 1.1435,
        low: 1.14222
      },
      {
        timestamp: 1642410000,
        open: 1.14291,
        close: 1.14243,
        high: 1.14336,
        low: 1.14176
      },
      {
        timestamp: 1642413600,
        open: 1.14243,
        close: 1.14206,
        high: 1.14261,
        low: 1.14154
      },
      {
        timestamp: 1642417200,
        open: 1.14206,
        close: 1.14148,
        high: 1.14271,
        low: 1.1407
      },
      {
        timestamp: 1642420800,
        open: 1.14148,
        close: 1.13971,
        high: 1.14172,
        low: 1.13958
      },
      {
        timestamp: 1642424400,
        open: 1.13971,
        close: 1.14021,
        high: 1.14067,
        low: 1.13923
      },
      {
        timestamp: 1642428000,
        open: 1.14021,
        close: 1.14123,
        high: 1.14153,
        low: 1.13961
      },
      {
        timestamp: 1642431600,
        open: 1.14123,
        close: 1.14046,
        high: 1.14123,
        low: 1.13975
      },
      {
        timestamp: 1642435200,
        open: 1.14046,
        close: 1.14102,
        high: 1.14104,
        low: 1.14013
      },
      {
        timestamp: 1642438800,
        open: 1.14102,
        close: 1.14125,
        high: 1.14163,
        low: 1.14092
      },
      {
        timestamp: 1642442400,
        open: 1.14125,
        close: 1.14085,
        high: 1.14141,
        low: 1.14085
      },
      {
        timestamp: 1642446000,
        open: 1.14085,
        close: 1.14056,
        high: 1.14085,
        low: 1.14052
      },
      {
        timestamp: 1642449600,
        open: 1.14056,
        close: 1.14073,
        high: 1.141,
        low: 1.14056
      },
      {
        timestamp: 1642453200,
        open: 1.14073,
        close: 1.14105,
        high: 1.14105,
        low: 1.14053
      },
      {
        timestamp: 1642456800,
        open: 1.14105,
        close: 1.14097,
        high: 1.14133,
        low: 1.14088
      },
      {
        timestamp: 1642460400,
        open: 1.14097,
        close: 1.14108,
        high: 1.14108,
        low: 1.14061
      },
      {
        timestamp: 1642464000,
        open: 1.14108,
        close: 1.14194,
        high: 1.14196,
        low: 1.14085
      },
      {
        timestamp: 1642467600,
        open: 1.14194,
        close: 1.14202,
        high: 1.14214,
        low: 1.14161
      },
      {
        timestamp: 1642471200,
        open: 1.14202,
        close: 1.14192,
        high: 1.14218,
        low: 1.14145
      },
      {
        timestamp: 1642474800,
        open: 1.14192,
        close: 1.1393,
        high: 1.14221,
        low: 1.13856
      },
      {
        timestamp: 1642478400,
        open: 1.1393,
        close: 1.1402,
        high: 1.14027,
        low: 1.13918
      },
      {
        timestamp: 1642482000,
        open: 1.1402,
        close: 1.13976,
        high: 1.14021,
        low: 1.1393
      },
      {
        timestamp: 1642485600,
        open: 1.13976,
        close: 1.14018,
        high: 1.14051,
        low: 1.13944
      },
      {
        timestamp: 1642489200,
        open: 1.14018,
        close: 1.13979,
        high: 1.14052,
        low: 1.13927
      },
      {
        timestamp: 1642492800,
        open: 1.13979,
        close: 1.13954,
        high: 1.14064,
        low: 1.13876
      },
      {
        timestamp: 1642496400,
        open: 1.13954,
        close: 1.13956,
        high: 1.14018,
        low: 1.13865
      },
      {
        timestamp: 1642500000,
        open: 1.13956,
        close: 1.13907,
        high: 1.14001,
        low: 1.13906
      },
      {
        timestamp: 1642503600,
        open: 1.13907,
        close: 1.13871,
        high: 1.13928,
        low: 1.13839
      },
      {
        timestamp: 1642507200,
        open: 1.13871,
        close: 1.13823,
        high: 1.13924,
        low: 1.13819
      },
      {
        timestamp: 1642510800,
        open: 1.13823,
        close: 1.13789,
        high: 1.13835,
        low: 1.13635
      },
      {
        timestamp: 1642514400,
        open: 1.13789,
        close: 1.13676,
        high: 1.13793,
        low: 1.13572
      },
      {
        timestamp: 1642518000,
        open: 1.13676,
        close: 1.13437,
        high: 1.13678,
        low: 1.13436
      },
      {
        timestamp: 1642521600,
        open: 1.13437,
        close: 1.1335,
        high: 1.13448,
        low: 1.1327
      },
      {
        timestamp: 1642525200,
        open: 1.1335,
        close: 1.13231,
        high: 1.13361,
        low: 1.13209
      },
      {
        timestamp: 1642528800,
        open: 1.13231,
        close: 1.13278,
        high: 1.13308,
        low: 1.13195
      },
      {
        timestamp: 1642532400,
        open: 1.13278,
        close: 1.13277,
        high: 1.13353,
        low: 1.1326
      },
      {
        timestamp: 1642536000,
        open: 1.13277,
        close: 1.13178,
        high: 1.1333,
        low: 1.13153
      },
      {
        timestamp: 1642539600,
        open: 1.13178,
        close: 1.13303,
        high: 1.13303,
        low: 1.13174
      },
      {
        timestamp: 1642543200,
        open: 1.13303,
        close: 1.13284,
        high: 1.13305,
        low: 1.13272
      },
      {
        timestamp: 1642546800,
        open: 1.13284,
        close: 1.13294,
        high: 1.13314,
        low: 1.13256
      },
      {
        timestamp: 1642550400,
        open: 1.13294,
        close: 1.13298,
        high: 1.13304,
        low: 1.13204
      },
      {
        timestamp: 1642554000,
        open: 1.13298,
        close: 1.13217,
        high: 1.13305,
        low: 1.13195
      },
      {
        timestamp: 1642557600,
        open: 1.13217,
        close: 1.13231,
        high: 1.13286,
        low: 1.13208
      },
      {
        timestamp: 1642561200,
        open: 1.13231,
        close: 1.13283,
        high: 1.13306,
        low: 1.13215
      },
      {
        timestamp: 1642564800,
        open: 1.13283,
        close: 1.13307,
        high: 1.13321,
        low: 1.13263
      },
      {
        timestamp: 1642568400,
        open: 1.13307,
        close: 1.13306,
        high: 1.13341,
        low: 1.1329
      },
      {
        timestamp: 1642572000,
        open: 1.13306,
        close: 1.1335,
        high: 1.1337,
        low: 1.13275
      },
      {
        timestamp: 1642575600,
        open: 1.1335,
        close: 1.13313,
        high: 1.13435,
        low: 1.13295
      },
      {
        timestamp: 1642579200,
        open: 1.13313,
        close: 1.13339,
        high: 1.1339,
        low: 1.13301
      },
      {
        timestamp: 1642582800,
        open: 1.13339,
        close: 1.13405,
        high: 1.13489,
        low: 1.13325
      },
      {
        timestamp: 1642586400,
        open: 1.13405,
        close: 1.13475,
        high: 1.13504,
        low: 1.13373
      },
      {
        timestamp: 1642590000,
        open: 1.13475,
        close: 1.13385,
        high: 1.13493,
        low: 1.13384
      },
      {
        timestamp: 1642593600,
        open: 1.13385,
        close: 1.13347,
        high: 1.13434,
        low: 1.13337
      },
      {
        timestamp: 1642597200,
        open: 1.13347,
        close: 1.13446,
        high: 1.13514,
        low: 1.13347
      },
      {
        timestamp: 1642600800,
        open: 1.13446,
        close: 1.13447,
        high: 1.13548,
        low: 1.13374
      },
      {
        timestamp: 1642604400,
        open: 1.13447,
        close: 1.13453,
        high: 1.13533,
        low: 1.13367
      },
      {
        timestamp: 1642608000,
        open: 1.13453,
        close: 1.13457,
        high: 1.13577,
        low: 1.13432
      },
      {
        timestamp: 1642611600,
        open: 1.13457,
        close: 1.13445,
        high: 1.13511,
        low: 1.13407
      },
      {
        timestamp: 1642615200,
        open: 1.13445,
        close: 1.13489,
        high: 1.13516,
        low: 1.13423
      },
      {
        timestamp: 1642618800,
        open: 1.13489,
        close: 1.13518,
        high: 1.13539,
        low: 1.13455
      },
      {
        timestamp: 1642622400,
        open: 1.13518,
        close: 1.13463,
        high: 1.13553,
        low: 1.13458
      },
      {
        timestamp: 1642626000,
        open: 1.13463,
        close: 1.13437,
        high: 1.13489,
        low: 1.13427
      },
      {
        timestamp: 1642629600,
        open: 1.13437,
        close: 1.13431,
        high: 1.13517,
        low: 1.13426
      },
      {
        timestamp: 1642633200,
        open: 1.13431,
        close: 1.13436,
        high: 1.13442,
        low: 1.1341
      },
      {
        timestamp: 1642636800,
        open: 1.13436,
        close: 1.13516,
        high: 1.13527,
        low: 1.13436
      },
      {
        timestamp: 1642640400,
        open: 1.13516,
        close: 1.13616,
        high: 1.13659,
        low: 1.135
      },
      {
        timestamp: 1642644000,
        open: 1.13616,
        close: 1.13555,
        high: 1.13617,
        low: 1.13515
      },
      {
        timestamp: 1642647600,
        open: 1.13555,
        close: 1.13541,
        high: 1.13568,
        low: 1.1351
      },
      {
        timestamp: 1642651200,
        open: 1.13541,
        close: 1.13512,
        high: 1.13542,
        low: 1.1349
      },
      {
        timestamp: 1642654800,
        open: 1.13512,
        close: 1.13504,
        high: 1.13535,
        low: 1.1346
      },
      {
        timestamp: 1642658400,
        open: 1.13504,
        close: 1.13505,
        high: 1.13534,
        low: 1.1348
      },
      {
        timestamp: 1642662000,
        open: 1.13505,
        close: 1.13603,
        high: 1.13674,
        low: 1.13491
      },
      {
        timestamp: 1642665600,
        open: 1.13603,
        close: 1.13553,
        high: 1.13695,
        low: 1.13529
      },
      {
        timestamp: 1642669200,
        open: 1.13553,
        close: 1.13481,
        high: 1.13576,
        low: 1.1344
      },
      {
        timestamp: 1642672800,
        open: 1.13481,
        close: 1.13464,
        high: 1.13521,
        low: 1.13422
      },
      {
        timestamp: 1642676400,
        open: 1.13464,
        close: 1.13407,
        high: 1.13475,
        low: 1.13336
      },
      {
        timestamp: 1642680000,
        open: 1.13407,
        close: 1.13396,
        high: 1.13419,
        low: 1.13318
      },
      {
        timestamp: 1642683600,
        open: 1.13396,
        close: 1.13522,
        high: 1.13584,
        low: 1.1337
      },
      {
        timestamp: 1642687200,
        open: 1.13522,
        close: 1.13453,
        high: 1.13582,
        low: 1.13327
      },
      {
        timestamp: 1642690800,
        open: 1.13453,
        close: 1.13484,
        high: 1.13538,
        low: 1.13291
      },
      {
        timestamp: 1642694400,
        open: 1.13484,
        close: 1.13359,
        high: 1.13538,
        low: 1.13326
      },
      {
        timestamp: 1642698000,
        open: 1.13359,
        close: 1.13277,
        high: 1.13423,
        low: 1.1327
      },
      {
        timestamp: 1642701600,
        open: 1.13277,
        close: 1.13185,
        high: 1.133,
        low: 1.13153
      },
      {
        timestamp: 1642705200,
        open: 1.13185,
        close: 1.1313,
        high: 1.13202,
        low: 1.1312
      },
      {
        timestamp: 1642708800,
        open: 1.1313,
        close: 1.13045,
        high: 1.13193,
        low: 1.13037
      },
      {
        timestamp: 1642712400,
        open: 1.13045,
        close: 1.13134,
        high: 1.13139,
        low: 1.13046
      },
      {
        timestamp: 1642716000,
        open: 1.13134,
        close: 1.13146,
        high: 1.13157,
        low: 1.13114
      },
      {
        timestamp: 1642719600,
        open: 1.13146,
        close: 1.13137,
        high: 1.13147,
        low: 1.13084
      },
      {
        timestamp: 1642723200,
        open: 1.13137,
        close: 1.13044,
        high: 1.13176,
        low: 1.13028
      },
      {
        timestamp: 1642726800,
        open: 1.13044,
        close: 1.13177,
        high: 1.13184,
        low: 1.13015
      },
      {
        timestamp: 1642730400,
        open: 1.13177,
        close: 1.13351,
        high: 1.13356,
        low: 1.13162
      },
      {
        timestamp: 1642734000,
        open: 1.13351,
        close: 1.13265,
        high: 1.1335,
        low: 1.13256
      },
      {
        timestamp: 1642737600,
        open: 1.13265,
        close: 1.13252,
        high: 1.13276,
        low: 1.1324
      },
      {
        timestamp: 1642741200,
        open: 1.13252,
        close: 1.13259,
        high: 1.13265,
        low: 1.1323
      },
      {
        timestamp: 1642744800,
        open: 1.13259,
        close: 1.13297,
        high: 1.13314,
        low: 1.13254
      },
      {
        timestamp: 1642748400,
        open: 1.13297,
        close: 1.13293,
        high: 1.13373,
        low: 1.13232
      },
      {
        timestamp: 1642752000,
        open: 1.13293,
        close: 1.13364,
        high: 1.13408,
        low: 1.13286
      },
      {
        timestamp: 1642755600,
        open: 1.13364,
        close: 1.13398,
        high: 1.13459,
        low: 1.13318
      },
      {
        timestamp: 1642759200,
        open: 1.13398,
        close: 1.13394,
        high: 1.13421,
        low: 1.13326
      },
      {
        timestamp: 1642762800,
        open: 1.13394,
        close: 1.13303,
        high: 1.13399,
        low: 1.13262
      },
      {
        timestamp: 1642766400,
        open: 1.13303,
        close: 1.13388,
        high: 1.13453,
        low: 1.13267
      },
      {
        timestamp: 1642770000,
        open: 1.13388,
        close: 1.13505,
        high: 1.13523,
        low: 1.13377
      },
      {
        timestamp: 1642773600,
        open: 1.13505,
        close: 1.13506,
        high: 1.13604,
        low: 1.13411
      },
      {
        timestamp: 1642777200,
        open: 1.13506,
        close: 1.13467,
        high: 1.1354,
        low: 1.13376
      },
      {
        timestamp: 1642780800,
        open: 1.13467,
        close: 1.13461,
        high: 1.1354,
        low: 1.1341
      },
      {
        timestamp: 1642784400,
        open: 1.13461,
        close: 1.13461,
        high: 1.13507,
        low: 1.13402
      },
      {
        timestamp: 1642788000,
        open: 1.13461,
        close: 1.1345,
        high: 1.13497,
        low: 1.13422
      },
      {
        timestamp: 1642791600,
        open: 1.1345,
        close: 1.13421,
        high: 1.13473,
        low: 1.13413
      },
      {
        timestamp: 1642795200,
        open: 1.13421,
        close: 1.13418,
        high: 1.13457,
        low: 1.13389
      },
      {
        timestamp: 1642798800,
        open: 1.13418,
        close: 1.13461,
        high: 1.13464,
        low: 1.13398
      },
      {
        timestamp: 1642964400,
        open: 1.13461,
        close: 1.13478,
        high: 1.13478,
        low: 1.13468
      },
      {
        timestamp: 1642968000,
        open: 1.13478,
        close: 1.13477,
        high: 1.13508,
        low: 1.13477
      },
      {
        timestamp: 1642971600,
        open: 1.13477,
        close: 1.13485,
        high: 1.13485,
        low: 1.13472
      },
      {
        timestamp: 1642975200,
        open: 1.13485,
        close: 1.13477,
        high: 1.13491,
        low: 1.13428
      },
      {
        timestamp: 1642978800,
        open: 1.13477,
        close: 1.13419,
        high: 1.13477,
        low: 1.13374
      },
      {
        timestamp: 1642982400,
        open: 1.13419,
        close: 1.13391,
        high: 1.13418,
        low: 1.13332
      },
      {
        timestamp: 1642986000,
        open: 1.13391,
        close: 1.13375,
        high: 1.13401,
        low: 1.1334
      },
      {
        timestamp: 1642989600,
        open: 1.13375,
        close: 1.13329,
        high: 1.13376,
        low: 1.13327
      },
      {
        timestamp: 1642993200,
        open: 1.13329,
        close: 1.13296,
        high: 1.13345,
        low: 1.13281
      },
      {
        timestamp: 1642996800,
        open: 1.13296,
        close: 1.13303,
        high: 1.13319,
        low: 1.13284
      },
      {
        timestamp: 1643000400,
        open: 1.13303,
        close: 1.13187,
        high: 1.13306,
        low: 1.13175
      },
      {
        timestamp: 1643004000,
        open: 1.13187,
        close: 1.13238,
        high: 1.13238,
        low: 1.13153
      },
      {
        timestamp: 1643007600,
        open: 1.13238,
        close: 1.13243,
        high: 1.13301,
        low: 1.13204
      },
      {
        timestamp: 1643011200,
        open: 1.13243,
        close: 1.13222,
        high: 1.13338,
        low: 1.13102
      },
      {
        timestamp: 1643014800,
        open: 1.13222,
        close: 1.1326,
        high: 1.1337,
        low: 1.13215
      },
      {
        timestamp: 1643018400,
        open: 1.1326,
        close: 1.13192,
        high: 1.13289,
        low: 1.13145
      },
      {
        timestamp: 1643022000,
        open: 1.13192,
        close: 1.13049,
        high: 1.13224,
        low: 1.13019
      },
      {
        timestamp: 1643025600,
        open: 1.13049,
        close: 1.12981,
        high: 1.13107,
        low: 1.12973
      },
      {
        timestamp: 1643029200,
        open: 1.12981,
        close: 1.12962,
        high: 1.13077,
        low: 1.12954
      },
      {
        timestamp: 1643032800,
        open: 1.12962,
        close: 1.12993,
        high: 1.13042,
        low: 1.1291
      },
      {
        timestamp: 1643036400,
        open: 1.12993,
        close: 1.13095,
        high: 1.13253,
        low: 1.12918
      },
      {
        timestamp: 1643040000,
        open: 1.13095,
        close: 1.13112,
        high: 1.13174,
        low: 1.1305
      },
      {
        timestamp: 1643043600,
        open: 1.13112,
        close: 1.13285,
        high: 1.13299,
        low: 1.13099
      },
      {
        timestamp: 1643047200,
        open: 1.13285,
        close: 1.13288,
        high: 1.13357,
        low: 1.1328
      },
      {
        timestamp: 1643050800,
        open: 1.13288,
        close: 1.13203,
        high: 1.13344,
        low: 1.13183
      },
      {
        timestamp: 1643054400,
        open: 1.13203,
        close: 1.13212,
        high: 1.13249,
        low: 1.1319
      },
      {
        timestamp: 1643058000,
        open: 1.13212,
        close: 1.13288,
        high: 1.13292,
        low: 1.13187
      },
      {
        timestamp: 1643061600,
        open: 1.13288,
        close: 1.13283,
        high: 1.13317,
        low: 1.13242
      },
      {
        timestamp: 1643065200,
        open: 1.13283,
        close: 1.13245,
        high: 1.13298,
        low: 1.13223
      },
      {
        timestamp: 1643068800,
        open: 1.13245,
        close: 1.13205,
        high: 1.13247,
        low: 1.13179
      },
      {
        timestamp: 1643072400,
        open: 1.13205,
        close: 1.13167,
        high: 1.13242,
        low: 1.13167
      },
      {
        timestamp: 1643076000,
        open: 1.13167,
        close: 1.13133,
        high: 1.1319,
        low: 1.13103
      },
      {
        timestamp: 1643079600,
        open: 1.13133,
        close: 1.13131,
        high: 1.13146,
        low: 1.13095
      },
      {
        timestamp: 1643083200,
        open: 1.13131,
        close: 1.13124,
        high: 1.13172,
        low: 1.13105
      },
      {
        timestamp: 1643086800,
        open: 1.13124,
        close: 1.13107,
        high: 1.13146,
        low: 1.13087
      },
      {
        timestamp: 1643090400,
        open: 1.13107,
        close: 1.13065,
        high: 1.1312,
        low: 1.13057
      },
      {
        timestamp: 1643094000,
        open: 1.13065,
        close: 1.13028,
        high: 1.13164,
        low: 1.1302
      },
      {
        timestamp: 1643097600,
        open: 1.13028,
        close: 1.12985,
        high: 1.13163,
        low: 1.12924
      },
      {
        timestamp: 1643101200,
        open: 1.12985,
        close: 1.12883,
        high: 1.1301,
        low: 1.1285
      },
      {
        timestamp: 1643104800,
        open: 1.12883,
        close: 1.12808,
        high: 1.12916,
        low: 1.12805
      },
      {
        timestamp: 1643108400,
        open: 1.12808,
        close: 1.12724,
        high: 1.12838,
        low: 1.12691
      },
      {
        timestamp: 1643112000,
        open: 1.12724,
        close: 1.12683,
        high: 1.12789,
        low: 1.12669
      },
      {
        timestamp: 1643115600,
        open: 1.12683,
        close: 1.12764,
        high: 1.12809,
        low: 1.12678
      },
      {
        timestamp: 1643119200,
        open: 1.12764,
        close: 1.12742,
        high: 1.12814,
        low: 1.12638
      },
      {
        timestamp: 1643122800,
        open: 1.12742,
        close: 1.12748,
        high: 1.12819,
        low: 1.12697
      },
      {
        timestamp: 1643126400,
        open: 1.12748,
        close: 1.1285,
        high: 1.12875,
        low: 1.1272
      },
      {
        timestamp: 1643130000,
        open: 1.1285,
        close: 1.12883,
        high: 1.12897,
        low: 1.12826
      },
      {
        timestamp: 1643133600,
        open: 1.12883,
        close: 1.12972,
        high: 1.12982,
        low: 1.12869
      },
      {
        timestamp: 1643137200,
        open: 1.12972,
        close: 1.13043,
        high: 1.13054,
        low: 1.12945
      },
      {
        timestamp: 1643140800,
        open: 1.13043,
        close: 1.13051,
        high: 1.13077,
        low: 1.1297
      },
      {
        timestamp: 1643144400,
        open: 1.13051,
        close: 1.13033,
        high: 1.13053,
        low: 1.13005
      },
      {
        timestamp: 1643148000,
        open: 1.13033,
        close: 1.13053,
        high: 1.13067,
        low: 1.12966
      },
      {
        timestamp: 1643151600,
        open: 1.13053,
        close: 1.13038,
        high: 1.13068,
        low: 1.13008
      },
      {
        timestamp: 1643155200,
        open: 1.13038,
        close: 1.13037,
        high: 1.13065,
        low: 1.13019
      },
      {
        timestamp: 1643158800,
        open: 1.13037,
        close: 1.13059,
        high: 1.13059,
        low: 1.13023
      },
      {
        timestamp: 1643162400,
        open: 1.13059,
        close: 1.13069,
        high: 1.13113,
        low: 1.13051
      },
      {
        timestamp: 1643166000,
        open: 1.13069,
        close: 1.13065,
        high: 1.13101,
        low: 1.13039
      },
      {
        timestamp: 1643169600,
        open: 1.13065,
        close: 1.13021,
        high: 1.13066,
        low: 1.13014
      },
      {
        timestamp: 1643173200,
        open: 1.13021,
        close: 1.13051,
        high: 1.1306,
        low: 1.13013
      },
      {
        timestamp: 1643176800,
        open: 1.13051,
        close: 1.1294,
        high: 1.13058,
        low: 1.12938
      },
      {
        timestamp: 1643180400,
        open: 1.1294,
        close: 1.12918,
        high: 1.12972,
        low: 1.12898
      },
      {
        timestamp: 1643184000,
        open: 1.12918,
        close: 1.12886,
        high: 1.1298,
        low: 1.12849
      },
      {
        timestamp: 1643187600,
        open: 1.12886,
        close: 1.12873,
        high: 1.12922,
        low: 1.12839
      },
      {
        timestamp: 1643191200,
        open: 1.12873,
        close: 1.1282,
        high: 1.12888,
        low: 1.12768
      },
      {
        timestamp: 1643194800,
        open: 1.1282,
        close: 1.12795,
        high: 1.12844,
        low: 1.12759
      },
      {
        timestamp: 1643198400,
        open: 1.12795,
        close: 1.12769,
        high: 1.12866,
        low: 1.12758
      },
      {
        timestamp: 1643202000,
        open: 1.12769,
        close: 1.12828,
        high: 1.12837,
        low: 1.12722
      },
      {
        timestamp: 1643205600,
        open: 1.12828,
        close: 1.12845,
        high: 1.1297,
        low: 1.1282
      },
      {
        timestamp: 1643209200,
        open: 1.12845,
        close: 1.12812,
        high: 1.12883,
        low: 1.12744
      },
      {
        timestamp: 1643212800,
        open: 1.12812,
        close: 1.12795,
        high: 1.12877,
        low: 1.12786
      },
      {
        timestamp: 1643216400,
        open: 1.12795,
        close: 1.12885,
        high: 1.12889,
        low: 1.12789
      },
      {
        timestamp: 1643220000,
        open: 1.12885,
        close: 1.12859,
        high: 1.12951,
        low: 1.12851
      },
      {
        timestamp: 1643223600,
        open: 1.12859,
        close: 1.12558,
        high: 1.1302,
        low: 1.1248
      },
      {
        timestamp: 1643227200,
        open: 1.12558,
        close: 1.1241,
        high: 1.12559,
        low: 1.12359
      },
      {
        timestamp: 1643230800,
        open: 1.1241,
        close: 1.12427,
        high: 1.12446,
        low: 1.12362
      },
      {
        timestamp: 1643234400,
        open: 1.12427,
        close: 1.12421,
        high: 1.12445,
        low: 1.12393
      },
      {
        timestamp: 1643238000,
        open: 1.12421,
        close: 1.12414,
        high: 1.12432,
        low: 1.12362
      },
      {
        timestamp: 1643241600,
        open: 1.12414,
        close: 1.12345,
        high: 1.1244,
        low: 1.12328
      },
      {
        timestamp: 1643245200,
        open: 1.12345,
        close: 1.12324,
        high: 1.12352,
        low: 1.1231
      },
      {
        timestamp: 1643248800,
        open: 1.12324,
        close: 1.12271,
        high: 1.12341,
        low: 1.12268
      },
      {
        timestamp: 1643252400,
        open: 1.12271,
        close: 1.12218,
        high: 1.1229,
        low: 1.12216
      },
      {
        timestamp: 1643256000,
        open: 1.12218,
        close: 1.1223,
        high: 1.12275,
        low: 1.12193
      },
      {
        timestamp: 1643259600,
        open: 1.1223,
        close: 1.12205,
        high: 1.1223,
        low: 1.1216
      },
      {
        timestamp: 1643263200,
        open: 1.12205,
        close: 1.12199,
        high: 1.12221,
        low: 1.12157
      },
      {
        timestamp: 1643266800,
        open: 1.12199,
        close: 1.11994,
        high: 1.122,
        low: 1.1197
      },
      {
        timestamp: 1643270400,
        open: 1.11994,
        close: 1.12018,
        high: 1.1207,
        low: 1.11935
      },
      {
        timestamp: 1643274000,
        open: 1.12018,
        close: 1.1197,
        high: 1.12042,
        low: 1.11957
      },
      {
        timestamp: 1643277600,
        open: 1.1197,
        close: 1.11881,
        high: 1.12017,
        low: 1.1188
      },
      {
        timestamp: 1643281200,
        open: 1.11881,
        close: 1.11595,
        high: 1.11922,
        low: 1.11529
      },
      {
        timestamp: 1643284800,
        open: 1.11595,
        close: 1.11581,
        high: 1.11695,
        low: 1.11548
      },
      {
        timestamp: 1643288400,
        open: 1.11581,
        close: 1.115,
        high: 1.11627,
        low: 1.11355
      },
      {
        timestamp: 1643292000,
        open: 1.115,
        close: 1.11616,
        high: 1.11628,
        low: 1.1146
      },
      {
        timestamp: 1643295600,
        open: 1.11616,
        close: 1.115,
        high: 1.11623,
        low: 1.11319
      },
      {
        timestamp: 1643299200,
        open: 1.115,
        close: 1.11429,
        high: 1.11623,
        low: 1.11417
      },
      {
        timestamp: 1643302800,
        open: 1.11429,
        close: 1.11396,
        high: 1.11522,
        low: 1.1137
      },
      {
        timestamp: 1643306400,
        open: 1.11396,
        close: 1.11451,
        high: 1.11474,
        low: 1.11363
      },
      {
        timestamp: 1643310000,
        open: 1.11451,
        close: 1.1141,
        high: 1.11476,
        low: 1.11393
      },
      {
        timestamp: 1643313600,
        open: 1.1141,
        close: 1.11431,
        high: 1.11499,
        low: 1.11405
      },
      {
        timestamp: 1643317200,
        open: 1.11431,
        close: 1.11467,
        high: 1.11483,
        low: 1.11422
      },
      {
        timestamp: 1643320800,
        open: 1.11467,
        close: 1.11462,
        high: 1.11511,
        low: 1.11403
      },
      {
        timestamp: 1643324400,
        open: 1.11462,
        close: 1.1145,
        high: 1.11472,
        low: 1.11424
      },
      {
        timestamp: 1643328000,
        open: 1.1145,
        close: 1.11474,
        high: 1.1148,
        low: 1.11386
      },
      {
        timestamp: 1643331600,
        open: 1.11474,
        close: 1.11489,
        high: 1.11492,
        low: 1.11439
      },
      {
        timestamp: 1643335200,
        open: 1.11489,
        close: 1.11528,
        high: 1.11529,
        low: 1.1147
      },
      {
        timestamp: 1643338800,
        open: 1.11528,
        close: 1.1154,
        high: 1.11564,
        low: 1.11524
      },
      {
        timestamp: 1643342400,
        open: 1.1154,
        close: 1.11525,
        high: 1.11546,
        low: 1.11498
      },
      {
        timestamp: 1643346000,
        open: 1.11525,
        close: 1.11548,
        high: 1.11557,
        low: 1.11501
      },
      {
        timestamp: 1643349600,
        open: 1.11548,
        close: 1.11476,
        high: 1.11554,
        low: 1.11474
      },
      {
        timestamp: 1643353200,
        open: 1.11476,
        close: 1.11354,
        high: 1.11479,
        low: 1.11266
      },
      {
        timestamp: 1643356800,
        open: 1.11354,
        close: 1.11367,
        high: 1.11389,
        low: 1.1122
      },
      {
        timestamp: 1643360400,
        open: 1.11367,
        close: 1.11332,
        high: 1.11403,
        low: 1.11261
      },
      {
        timestamp: 1643364000,
        open: 1.11332,
        close: 1.11376,
        high: 1.11446,
        low: 1.113
      },
      {
        timestamp: 1643367600,
        open: 1.11376,
        close: 1.1134,
        high: 1.11375,
        low: 1.11283
      },
      {
        timestamp: 1643371200,
        open: 1.1134,
        close: 1.11275,
        high: 1.11421,
        low: 1.1126
      },
      {
        timestamp: 1643374800,
        open: 1.11275,
        close: 1.11692,
        high: 1.11694,
        low: 1.11274
      },
      {
        timestamp: 1643378400,
        open: 1.11692,
        close: 1.11584,
        high: 1.11744,
        low: 1.11441
      },
      {
        timestamp: 1643382000,
        open: 1.11584,
        close: 1.11648,
        high: 1.11679,
        low: 1.11388
      },
      {
        timestamp: 1643385600,
        open: 1.11648,
        close: 1.11611,
        high: 1.11701,
        low: 1.1156
      },
      {
        timestamp: 1643389200,
        open: 1.11611,
        close: 1.11518,
        high: 1.11641,
        low: 1.11516
      },
      {
        timestamp: 1643392800,
        open: 1.11518,
        close: 1.11526,
        high: 1.11568,
        low: 1.11478
      },
      {
        timestamp: 1643396400,
        open: 1.11526,
        close: 1.11449,
        high: 1.11546,
        low: 1.1144
      },
      {
        timestamp: 1643400000,
        open: 1.11449,
        close: 1.1151,
        high: 1.1151,
        low: 1.11418
      },
      {
        timestamp: 1643403600,
        open: 1.1151,
        close: 1.11495,
        high: 1.11523,
        low: 1.11467
      },
      {
        timestamp: 1643569200,
        open: 1.11479,
        close: 1.1148,
        high: 1.11482,
        low: 1.11456
      },
      {
        timestamp: 1643572800,
        open: 1.1148,
        close: 1.11448,
        high: 1.11483,
        low: 1.11396
      },
      {
        timestamp: 1643576400,
        open: 1.11483,
        close: 1.11473,
        high: 1.11493,
        low: 1.11446
      },
      {
        timestamp: 1643580000,
        open: 1.11476,
        close: 1.11471,
        high: 1.11488,
        low: 1.11435
      },
      {
        timestamp: 1643583600,
        open: 1.11473,
        close: 1.11525,
        high: 1.11531,
        low: 1.11388
      },
      {
        timestamp: 1643587200,
        open: 1.11528,
        close: 1.11457,
        high: 1.11549,
        low: 1.11447
      },
      {
        timestamp: 1643590800,
        open: 1.11457,
        close: 1.11584,
        high: 1.11587,
        low: 1.11453
      },
      {
        timestamp: 1643594400,
        open: 1.11583,
        close: 1.116,
        high: 1.11611,
        low: 1.11553
      },
      {
        timestamp: 1643598000,
        open: 1.11601,
        close: 1.11617,
        high: 1.11636,
        low: 1.11579
      },
      {
        timestamp: 1643601600,
        open: 1.11616,
        close: 1.11624,
        high: 1.11644,
        low: 1.11607
      },
      {
        timestamp: 1643605200,
        open: 1.11624,
        close: 1.11656,
        high: 1.11665,
        low: 1.11615
      },
      {
        timestamp: 1643608800,
        open: 1.11656,
        close: 1.11671,
        high: 1.11681,
        low: 1.11628
      },
      {
        timestamp: 1643612400,
        open: 1.11671,
        close: 1.11717,
        high: 1.11721,
        low: 1.11613
      },
      {
        timestamp: 1643616000,
        open: 1.11718,
        close: 1.11714,
        high: 1.1183,
        low: 1.11645
      },
      {
        timestamp: 1643619600,
        open: 1.11715,
        close: 1.11755,
        high: 1.11788,
        low: 1.11665
      },
      {
        timestamp: 1643623200,
        open: 1.11754,
        close: 1.11682,
        high: 1.11827,
        low: 1.11682
      },
      {
        timestamp: 1643626800,
        open: 1.11683,
        close: 1.11599,
        high: 1.11686,
        low: 1.11537
      },
      {
        timestamp: 1643630400,
        open: 1.11599,
        close: 1.11569,
        high: 1.11627,
        low: 1.11468
      },
      {
        timestamp: 1643634000,
        open: 1.11569,
        close: 1.11938,
        high: 1.11945,
        low: 1.11533
      },
      {
        timestamp: 1643637600,
        open: 1.11937,
        close: 1.11897,
        high: 1.11993,
        low: 1.11851
      },
      {
        timestamp: 1643641200,
        open: 1.11898,
        close: 1.12095,
        high: 1.12184,
        low: 1.1184
      },
      {
        timestamp: 1643644800,
        open: 1.12095,
        close: 1.12107,
        high: 1.12144,
        low: 1.11994
      },
      {
        timestamp: 1643648400,
        open: 1.12108,
        close: 1.12198,
        high: 1.12203,
        low: 1.12099
      },
      {
        timestamp: 1643652000,
        open: 1.12197,
        close: 1.12338,
        high: 1.12351,
        low: 1.1219
      },
      {
        timestamp: 1643655600,
        open: 1.12337,
        close: 1.12438,
        high: 1.12484,
        low: 1.12328
      },
      {
        timestamp: 1643659200,
        open: 1.12439,
        close: 1.12358,
        high: 1.12462,
        low: 1.12337
      },
      {
        timestamp: 1643662800,
        open: 1.12357,
        close: 1.12394,
        high: 1.12394,
        low: 1.12311
      },
      {
        timestamp: 1643666400,
        open: 1.12392,
        close: 1.12321,
        high: 1.12392,
        low: 1.12319
      },
      {
        timestamp: 1643670000,
        open: 1.1232,
        close: 1.12299,
        high: 1.12338,
        low: 1.12299
      },
      {
        timestamp: 1643673600,
        open: 1.12299,
        close: 1.12247,
        high: 1.12311,
        low: 1.12214
      },
      {
        timestamp: 1643677200,
        open: 1.12246,
        close: 1.12269,
        high: 1.12292,
        low: 1.12224
      },
      {
        timestamp: 1643680800,
        open: 1.12269,
        close: 1.12333,
        high: 1.12336,
        low: 1.12261
      },
      {
        timestamp: 1643684400,
        open: 1.12334,
        close: 1.12338,
        high: 1.12346,
        low: 1.12265
      },
      {
        timestamp: 1643688000,
        open: 1.1234,
        close: 1.12437,
        high: 1.12453,
        low: 1.12339
      },
      {
        timestamp: 1643691600,
        open: 1.12437,
        close: 1.12408,
        high: 1.12458,
        low: 1.12401
      },
      {
        timestamp: 1643695200,
        open: 1.12408,
        close: 1.12354,
        high: 1.12431,
        low: 1.12348
      },
      {
        timestamp: 1643698800,
        open: 1.12353,
        close: 1.12672,
        high: 1.12687,
        low: 1.12353
      },
      {
        timestamp: 1643702400,
        open: 1.12672,
        close: 1.12579,
        high: 1.12701,
        low: 1.12524
      },
      {
        timestamp: 1643706000,
        open: 1.12579,
        close: 1.12619,
        high: 1.12679,
        low: 1.12542
      },
      {
        timestamp: 1643709600,
        open: 1.12619,
        close: 1.12628,
        high: 1.1268,
        low: 1.12559
      },
      {
        timestamp: 1643713200,
        open: 1.12627,
        close: 1.12739,
        high: 1.12794,
        low: 1.12599
      },
      {
        timestamp: 1643716800,
        open: 1.12738,
        close: 1.12623,
        high: 1.12743,
        low: 1.12583
      },
      {
        timestamp: 1643720400,
        open: 1.12624,
        close: 1.12664,
        high: 1.1269,
        low: 1.12539
      },
      {
        timestamp: 1643724000,
        open: 1.12664,
        close: 1.12643,
        high: 1.12751,
        low: 1.12604
      },
      {
        timestamp: 1643727600,
        open: 1.12644,
        close: 1.12482,
        high: 1.12663,
        low: 1.12459
      },
      {
        timestamp: 1643731200,
        open: 1.12481,
        close: 1.12398,
        high: 1.12549,
        low: 1.12349
      },
      {
        timestamp: 1643734800,
        open: 1.12397,
        close: 1.12455,
        high: 1.12499,
        low: 1.12365
      },
      {
        timestamp: 1643738400,
        open: 1.12458,
        close: 1.12491,
        high: 1.12507,
        low: 1.12435
      },
      {
        timestamp: 1643742000,
        open: 1.12492,
        close: 1.12536,
        high: 1.12582,
        low: 1.12436
      },
      {
        timestamp: 1643745600,
        open: 1.12537,
        close: 1.12693,
        high: 1.12712,
        low: 1.12526
      },
      {
        timestamp: 1643749200,
        open: 1.1269,
        close: 1.12741,
        high: 1.1275,
        low: 1.12666
      },
      {
        timestamp: 1643752800,
        open: 1.12744,
        close: 1.12722,
        high: 1.12755,
        low: 1.12717
      },
      {
        timestamp: 1643756400,
        open: 1.12723,
        close: 1.12737,
        high: 1.12744,
        low: 1.12699
      },
      {
        timestamp: 1643760000,
        open: 1.12738,
        close: 1.12717,
        high: 1.12754,
        low: 1.12689
      },
      {
        timestamp: 1643763600,
        open: 1.12717,
        close: 1.12769,
        high: 1.12775,
        low: 1.12715
      },
      {
        timestamp: 1643767200,
        open: 1.1277,
        close: 1.12783,
        high: 1.12786,
        low: 1.12755
      },
      {
        timestamp: 1643770800,
        open: 1.12784,
        close: 1.12713,
        high: 1.12784,
        low: 1.12713
      },
      {
        timestamp: 1643774400,
        open: 1.12714,
        close: 1.12752,
        high: 1.12765,
        low: 1.12714
      },
      {
        timestamp: 1643778000,
        open: 1.12751,
        close: 1.12738,
        high: 1.12764,
        low: 1.12716
      },
      {
        timestamp: 1643781600,
        open: 1.12739,
        close: 1.12698,
        high: 1.12753,
        low: 1.12673
      },
      {
        timestamp: 1643785200,
        open: 1.12699,
        close: 1.12822,
        high: 1.12851,
        low: 1.12688
      },
      {
        timestamp: 1643788800,
        open: 1.12822,
        close: 1.12902,
        high: 1.12949,
        low: 1.12802
      },
      {
        timestamp: 1643792400,
        open: 1.12903,
        close: 1.12951,
        high: 1.12952,
        low: 1.12791
      },
      {
        timestamp: 1643796000,
        open: 1.12952,
        close: 1.13095,
        high: 1.13151,
        low: 1.12906
      },
      {
        timestamp: 1643799600,
        open: 1.13095,
        close: 1.13171,
        high: 1.13206,
        low: 1.13062
      },
      {
        timestamp: 1643803200,
        open: 1.1317,
        close: 1.13257,
        high: 1.13292,
        low: 1.1315
      },
      {
        timestamp: 1643806800,
        open: 1.13256,
        close: 1.13119,
        high: 1.13318,
        low: 1.13117
      },
      {
        timestamp: 1643810400,
        open: 1.1312,
        close: 1.13054,
        high: 1.13218,
        low: 1.13038
      },
      {
        timestamp: 1643814000,
        open: 1.13053,
        close: 1.12973,
        high: 1.13107,
        low: 1.12931
      },
      {
        timestamp: 1643817600,
        open: 1.12973,
        close: 1.12999,
        high: 1.13026,
        low: 1.12936
      },
      {
        timestamp: 1643821200,
        open: 1.12999,
        close: 1.13118,
        high: 1.13129,
        low: 1.12989
      },
      {
        timestamp: 1643824800,
        open: 1.13118,
        close: 1.13099,
        high: 1.13149,
        low: 1.13066
      },
      {
        timestamp: 1643828400,
        open: 1.13098,
        close: 1.13108,
        high: 1.1313,
        low: 1.13066
      },
      {
        timestamp: 1643832000,
        open: 1.13108,
        close: 1.1306,
        high: 1.13146,
        low: 1.13044
      },
      {
        timestamp: 1643835600,
        open: 1.13061,
        close: 1.13057,
        high: 1.13088,
        low: 1.13027
      },
      {
        timestamp: 1643839200,
        open: 1.13061,
        close: 1.13054,
        high: 1.13099,
        low: 1.13038
      },
      {
        timestamp: 1643842800,
        open: 1.13056,
        close: 1.13001,
        high: 1.13061,
        low: 1.1299
      },
      {
        timestamp: 1643846400,
        open: 1.13002,
        close: 1.1297,
        high: 1.13006,
        low: 1.12957
      },
      {
        timestamp: 1643850000,
        open: 1.12971,
        close: 1.13007,
        high: 1.13011,
        low: 1.12938
      },
      {
        timestamp: 1643853600,
        open: 1.13008,
        close: 1.12995,
        high: 1.13042,
        low: 1.12982
      },
      {
        timestamp: 1643857200,
        open: 1.12995,
        close: 1.13053,
        high: 1.1309,
        low: 1.12983
      },
      {
        timestamp: 1643860800,
        open: 1.13054,
        close: 1.12976,
        high: 1.1306,
        low: 1.12952
      },
      {
        timestamp: 1643864400,
        open: 1.12973,
        close: 1.13013,
        high: 1.13025,
        low: 1.1297
      },
      {
        timestamp: 1643868000,
        open: 1.13013,
        close: 1.12919,
        high: 1.1302,
        low: 1.12909
      },
      {
        timestamp: 1643871600,
        open: 1.1292,
        close: 1.12981,
        high: 1.12983,
        low: 1.12887
      },
      {
        timestamp: 1643875200,
        open: 1.12981,
        close: 1.12958,
        high: 1.13032,
        low: 1.1289
      },
      {
        timestamp: 1643878800,
        open: 1.12958,
        close: 1.1285,
        high: 1.12976,
        low: 1.12845
      },
      {
        timestamp: 1643882400,
        open: 1.12849,
        close: 1.12798,
        high: 1.12866,
        low: 1.12769
      },
      {
        timestamp: 1643886000,
        open: 1.12798,
        close: 1.12809,
        high: 1.12836,
        low: 1.1276
      },
      {
        timestamp: 1643889600,
        open: 1.12811,
        close: 1.12919,
        high: 1.12997,
        low: 1.1268
      },
      {
        timestamp: 1643893200,
        open: 1.1292,
        close: 1.13665,
        high: 1.13665,
        low: 1.128
      },
      {
        timestamp: 1643896800,
        open: 1.13666,
        close: 1.13927,
        high: 1.14123,
        low: 1.13624
      },
      {
        timestamp: 1643900400,
        open: 1.13925,
        close: 1.1418,
        high: 1.14245,
        low: 1.13868
      },
      {
        timestamp: 1643904000,
        open: 1.14181,
        close: 1.14265,
        high: 1.1452,
        low: 1.14151
      },
      {
        timestamp: 1643907600,
        open: 1.14263,
        close: 1.14446,
        high: 1.1446,
        low: 1.14204
      },
      {
        timestamp: 1643911200,
        open: 1.14446,
        close: 1.14434,
        high: 1.1445,
        low: 1.14268
      },
      {
        timestamp: 1643914800,
        open: 1.14433,
        close: 1.14341,
        high: 1.14504,
        low: 1.14307
      },
      {
        timestamp: 1643918400,
        open: 1.14341,
        close: 1.14311,
        high: 1.14371,
        low: 1.14287
      },
      {
        timestamp: 1643922000,
        open: 1.1431,
        close: 1.14436,
        high: 1.14437,
        low: 1.14298
      },
      {
        timestamp: 1643925600,
        open: 1.14438,
        close: 1.14414,
        high: 1.14438,
        low: 1.14377
      },
      {
        timestamp: 1643929200,
        open: 1.14414,
        close: 1.14361,
        high: 1.14429,
        low: 1.14358
      },
      {
        timestamp: 1643932800,
        open: 1.1436,
        close: 1.14465,
        high: 1.14467,
        low: 1.14325
      },
      {
        timestamp: 1643936400,
        open: 1.14465,
        close: 1.14527,
        high: 1.14549,
        low: 1.14454
      },
      {
        timestamp: 1643940000,
        open: 1.14528,
        close: 1.14639,
        high: 1.14643,
        low: 1.14496
      },
      {
        timestamp: 1643943600,
        open: 1.14638,
        close: 1.14629,
        high: 1.14695,
        low: 1.146
      },
      {
        timestamp: 1643947200,
        open: 1.14629,
        close: 1.14666,
        high: 1.14724,
        low: 1.146
      },
      {
        timestamp: 1643950800,
        open: 1.14667,
        close: 1.14607,
        high: 1.14693,
        low: 1.14601
      },
      {
        timestamp: 1643954400,
        open: 1.14607,
        close: 1.14574,
        high: 1.14621,
        low: 1.14546
      },
      {
        timestamp: 1643958000,
        open: 1.14576,
        close: 1.14678,
        high: 1.14693,
        low: 1.1452
      },
      {
        timestamp: 1643961600,
        open: 1.14677,
        close: 1.14608,
        high: 1.14678,
        low: 1.14404
      },
      {
        timestamp: 1643965200,
        open: 1.14609,
        close: 1.1464,
        high: 1.14661,
        low: 1.145
      },
      {
        timestamp: 1643968800,
        open: 1.14639,
        close: 1.14636,
        high: 1.14759,
        low: 1.14597
      },
      {
        timestamp: 1643972400,
        open: 1.14636,
        close: 1.14792,
        high: 1.14844,
        low: 1.14576
      },
      {
        timestamp: 1643976000,
        open: 1.14792,
        close: 1.1466,
        high: 1.14827,
        low: 1.14657
      },
      {
        timestamp: 1643979600,
        open: 1.14661,
        close: 1.14368,
        high: 1.14688,
        low: 1.14225
      },
      {
        timestamp: 1643983200,
        open: 1.14369,
        close: 1.14164,
        high: 1.14587,
        low: 1.14159
      },
      {
        timestamp: 1643986800,
        open: 1.14165,
        close: 1.14427,
        high: 1.14438,
        low: 1.14122
      },
      {
        timestamp: 1643990400,
        open: 1.14428,
        close: 1.14526,
        high: 1.14563,
        low: 1.14365
      },
      {
        timestamp: 1643994000,
        open: 1.14527,
        close: 1.14549,
        high: 1.14631,
        low: 1.14526
      },
      {
        timestamp: 1643997600,
        open: 1.1455,
        close: 1.14554,
        high: 1.14647,
        low: 1.14521
      },
      {
        timestamp: 1644001200,
        open: 1.14555,
        close: 1.14487,
        high: 1.14587,
        low: 1.14481
      },
      {
        timestamp: 1644004800,
        open: 1.14488,
        close: 1.14553,
        high: 1.14605,
        low: 1.14483
      },
      {
        timestamp: 1644008400,
        open: 1.14552,
        close: 1.14518,
        high: 1.14566,
        low: 1.14498
      },
      {
        timestamp: 1644174000,
        open: 1.14698,
        close: 1.14617,
        high: 1.14698,
        low: 1.14608
      },
      {
        timestamp: 1644177600,
        open: 1.14638,
        close: 1.14681,
        high: 1.14731,
        low: 1.14626
      },
      {
        timestamp: 1644181200,
        open: 1.14685,
        close: 1.14685,
        high: 1.14699,
        low: 1.14643
      },
      {
        timestamp: 1644184800,
        open: 1.14679,
        close: 1.14577,
        high: 1.14685,
        low: 1.14577
      },
      {
        timestamp: 1644188400,
        open: 1.14577,
        close: 1.14554,
        high: 1.14593,
        low: 1.1452
      },
      {
        timestamp: 1644192000,
        open: 1.14553,
        close: 1.14536,
        high: 1.14567,
        low: 1.1451
      },
      {
        timestamp: 1644195600,
        open: 1.14537,
        close: 1.14421,
        high: 1.14545,
        low: 1.14401
      },
      {
        timestamp: 1644199200,
        open: 1.14421,
        close: 1.14284,
        high: 1.14433,
        low: 1.14278
      },
      {
        timestamp: 1644202800,
        open: 1.14286,
        close: 1.14335,
        high: 1.14342,
        low: 1.14253
      },
      {
        timestamp: 1644206400,
        open: 1.14336,
        close: 1.14393,
        high: 1.14413,
        low: 1.14327
      },
      {
        timestamp: 1644210000,
        open: 1.14393,
        close: 1.14312,
        high: 1.1441,
        low: 1.14307
      },
      {
        timestamp: 1644213600,
        open: 1.14312,
        close: 1.1432,
        high: 1.14367,
        low: 1.14297
      },
      {
        timestamp: 1644217200,
        open: 1.1432,
        close: 1.1433,
        high: 1.14359,
        low: 1.14262
      },
      {
        timestamp: 1644220800,
        open: 1.14331,
        close: 1.14346,
        high: 1.14499,
        low: 1.14274
      },
      {
        timestamp: 1644224400,
        open: 1.14346,
        close: 1.14241,
        high: 1.14352,
        low: 1.14158
      },
      {
        timestamp: 1644228000,
        open: 1.14242,
        close: 1.14218,
        high: 1.14313,
        low: 1.14169
      },
      {
        timestamp: 1644231600,
        open: 1.14217,
        close: 1.14439,
        high: 1.1447,
        low: 1.14212
      },
      {
        timestamp: 1644235200,
        open: 1.14439,
        close: 1.14557,
        high: 1.14593,
        low: 1.14428
      },
      {
        timestamp: 1644238800,
        open: 1.14557,
        close: 1.14403,
        high: 1.14573,
        low: 1.143
      },
      {
        timestamp: 1644242400,
        open: 1.14403,
        close: 1.14388,
        high: 1.14469,
        low: 1.14211
      },
      {
        timestamp: 1644246000,
        open: 1.14387,
        close: 1.1444,
        high: 1.14518,
        low: 1.14303
      },
      {
        timestamp: 1644249600,
        open: 1.1444,
        close: 1.14347,
        high: 1.14516,
        low: 1.14208
      },
      {
        timestamp: 1644253200,
        open: 1.14346,
        close: 1.14192,
        high: 1.14348,
        low: 1.1418
      },
      {
        timestamp: 1644256800,
        open: 1.14193,
        close: 1.14271,
        high: 1.14274,
        low: 1.14177
      },
      {
        timestamp: 1644260400,
        open: 1.14271,
        close: 1.14438,
        high: 1.14445,
        low: 1.1426
      },
      {
        timestamp: 1644264000,
        open: 1.14438,
        close: 1.1439,
        high: 1.14438,
        low: 1.14365
      },
      {
        timestamp: 1644267600,
        open: 1.1439,
        close: 1.14437,
        high: 1.14437,
        low: 1.1437
      },
      {
        timestamp: 1644271200,
        open: 1.14443,
        close: 1.14427,
        high: 1.14449,
        low: 1.14407
      },
      {
        timestamp: 1644274800,
        open: 1.14427,
        close: 1.14419,
        high: 1.14438,
        low: 1.14341
      },
      {
        timestamp: 1644278400,
        open: 1.1442,
        close: 1.14434,
        high: 1.14437,
        low: 1.14367
      },
      {
        timestamp: 1644282000,
        open: 1.14434,
        close: 1.14364,
        high: 1.14495,
        low: 1.14361
      },
      {
        timestamp: 1644285600,
        open: 1.14363,
        close: 1.14309,
        high: 1.14365,
        low: 1.14275
      },
      {
        timestamp: 1644289200,
        open: 1.14309,
        close: 1.14307,
        high: 1.14329,
        low: 1.14297
      },
      {
        timestamp: 1644292800,
        open: 1.14307,
        close: 1.14244,
        high: 1.14316,
        low: 1.14223
      },
      {
        timestamp: 1644296400,
        open: 1.14244,
        close: 1.14176,
        high: 1.14258,
        low: 1.14159
      },
      {
        timestamp: 1644300000,
        open: 1.14176,
        close: 1.14205,
        high: 1.14241,
        low: 1.14144
      },
      {
        timestamp: 1644303600,
        open: 1.14205,
        close: 1.13996,
        high: 1.14215,
        low: 1.13969
      },
      {
        timestamp: 1644307200,
        open: 1.13996,
        close: 1.14057,
        high: 1.14098,
        low: 1.13985
      },
      {
        timestamp: 1644310800,
        open: 1.14056,
        close: 1.14064,
        high: 1.14094,
        low: 1.13987
      },
      {
        timestamp: 1644314400,
        open: 1.14062,
        close: 1.14287,
        high: 1.14292,
        low: 1.1403
      },
      {
        timestamp: 1644318000,
        open: 1.14287,
        close: 1.14268,
        high: 1.14342,
        low: 1.14229
      },
      {
        timestamp: 1644321600,
        open: 1.14269,
        close: 1.14185,
        high: 1.14279,
        low: 1.14159
      },
      {
        timestamp: 1644325200,
        open: 1.14184,
        close: 1.14094,
        high: 1.14184,
        low: 1.14008
      },
      {
        timestamp: 1644328800,
        open: 1.14093,
        close: 1.14092,
        high: 1.14245,
        low: 1.1406
      },
      {
        timestamp: 1644332400,
        open: 1.14092,
        close: 1.14189,
        high: 1.14224,
        low: 1.14038
      },
      {
        timestamp: 1644336000,
        open: 1.14189,
        close: 1.14154,
        high: 1.14215,
        low: 1.14104
      },
      {
        timestamp: 1644339600,
        open: 1.14155,
        close: 1.14214,
        high: 1.1424,
        low: 1.14153
      },
      {
        timestamp: 1644343200,
        open: 1.14212,
        close: 1.14232,
        high: 1.14236,
        low: 1.1402
      },
      {
        timestamp: 1644346800,
        open: 1.14233,
        close: 1.14152,
        high: 1.14244,
        low: 1.14121
      },
      {
        timestamp: 1644350400,
        open: 1.1415,
        close: 1.14194,
        high: 1.14212,
        low: 1.14137
      },
      {
        timestamp: 1644354000,
        open: 1.14195,
        close: 1.14187,
        high: 1.14215,
        low: 1.1417
      },
      {
        timestamp: 1644357600,
        open: 1.14187,
        close: 1.14193,
        high: 1.14215,
        low: 1.14168
      },
      {
        timestamp: 1644361200,
        open: 1.14195,
        close: 1.14214,
        high: 1.14218,
        low: 1.14156
      },
      {
        timestamp: 1644364800,
        open: 1.14215,
        close: 1.14139,
        high: 1.14241,
        low: 1.14128
      },
      {
        timestamp: 1644368400,
        open: 1.14139,
        close: 1.14236,
        high: 1.14237,
        low: 1.14128
      },
      {
        timestamp: 1644372000,
        open: 1.14235,
        close: 1.14325,
        high: 1.14326,
        low: 1.14233
      },
      {
        timestamp: 1644375600,
        open: 1.14325,
        close: 1.1431,
        high: 1.14331,
        low: 1.14283
      },
      {
        timestamp: 1644379200,
        open: 1.14309,
        close: 1.14276,
        high: 1.14321,
        low: 1.1426
      },
      {
        timestamp: 1644382800,
        open: 1.14275,
        close: 1.1423,
        high: 1.14283,
        low: 1.14224
      },
      {
        timestamp: 1644386400,
        open: 1.14229,
        close: 1.14184,
        high: 1.14267,
        low: 1.14181
      },
      {
        timestamp: 1644390000,
        open: 1.14186,
        close: 1.14097,
        high: 1.1422,
        low: 1.14032
      },
      {
        timestamp: 1644393600,
        open: 1.14096,
        close: 1.14228,
        high: 1.14284,
        low: 1.14089
      },
      {
        timestamp: 1644397200,
        open: 1.14229,
        close: 1.14272,
        high: 1.14284,
        low: 1.14185
      },
      {
        timestamp: 1644400800,
        open: 1.14271,
        close: 1.14423,
        high: 1.14456,
        low: 1.14239
      },
      {
        timestamp: 1644404400,
        open: 1.14422,
        close: 1.14301,
        high: 1.14423,
        low: 1.14287
      },
      {
        timestamp: 1644408000,
        open: 1.14302,
        close: 1.14381,
        high: 1.14419,
        low: 1.143
      },
      {
        timestamp: 1644411600,
        open: 1.14382,
        close: 1.14363,
        high: 1.14466,
        low: 1.14303
      },
      {
        timestamp: 1644415200,
        open: 1.14363,
        close: 1.14323,
        high: 1.14382,
        low: 1.14261
      },
      {
        timestamp: 1644418800,
        open: 1.14322,
        close: 1.14288,
        high: 1.1442,
        low: 1.14278
      },
      {
        timestamp: 1644422400,
        open: 1.14289,
        close: 1.1435,
        high: 1.14418,
        low: 1.14267
      },
      {
        timestamp: 1644426000,
        open: 1.1435,
        close: 1.14382,
        high: 1.14484,
        low: 1.14263
      },
      {
        timestamp: 1644429600,
        open: 1.14381,
        close: 1.1433,
        high: 1.14418,
        low: 1.14287
      },
      {
        timestamp: 1644433200,
        open: 1.14331,
        close: 1.14329,
        high: 1.1434,
        low: 1.14275
      },
      {
        timestamp: 1644436800,
        open: 1.14328,
        close: 1.14259,
        high: 1.14342,
        low: 1.14207
      },
      {
        timestamp: 1644440400,
        open: 1.14259,
        close: 1.14263,
        high: 1.14277,
        low: 1.14225
      },
      {
        timestamp: 1644444000,
        open: 1.14281,
        close: 1.14223,
        high: 1.14307,
        low: 1.14205
      },
      {
        timestamp: 1644447600,
        open: 1.14223,
        close: 1.14216,
        high: 1.1424,
        low: 1.14209
      },
      {
        timestamp: 1644451200,
        open: 1.14216,
        close: 1.14179,
        high: 1.14234,
        low: 1.14137
      },
      {
        timestamp: 1644454800,
        open: 1.14179,
        close: 1.14201,
        high: 1.14214,
        low: 1.14177
      },
      {
        timestamp: 1644458400,
        open: 1.14201,
        close: 1.14298,
        high: 1.14302,
        low: 1.14184
      },
      {
        timestamp: 1644462000,
        open: 1.14297,
        close: 1.14293,
        high: 1.14306,
        low: 1.14257
      },
      {
        timestamp: 1644465600,
        open: 1.14293,
        close: 1.14257,
        high: 1.14322,
        low: 1.14234
      },
      {
        timestamp: 1644469200,
        open: 1.14256,
        close: 1.14265,
        high: 1.14276,
        low: 1.14241
      },
      {
        timestamp: 1644472800,
        open: 1.14266,
        close: 1.14233,
        high: 1.14286,
        low: 1.1421
      },
      {
        timestamp: 1644476400,
        open: 1.14233,
        close: 1.14295,
        high: 1.14295,
        low: 1.142
      },
      {
        timestamp: 1644480000,
        open: 1.14295,
        close: 1.1434,
        high: 1.14341,
        low: 1.14233
      },
      {
        timestamp: 1644483600,
        open: 1.14339,
        close: 1.1444,
        high: 1.1447,
        low: 1.14332
      },
      {
        timestamp: 1644487200,
        open: 1.1444,
        close: 1.14365,
        high: 1.14462,
        low: 1.1435
      },
      {
        timestamp: 1644490800,
        open: 1.14365,
        close: 1.14325,
        high: 1.14413,
        low: 1.14318
      },
      {
        timestamp: 1644494400,
        open: 1.14326,
        close: 1.14371,
        high: 1.14402,
        low: 1.14306
      },
      {
        timestamp: 1644498000,
        open: 1.14372,
        close: 1.14087,
        high: 1.14422,
        low: 1.13827
      },
      {
        timestamp: 1644501600,
        open: 1.14087,
        close: 1.14081,
        high: 1.14106,
        low: 1.13756
      },
      {
        timestamp: 1644505200,
        open: 1.1408,
        close: 1.14674,
        high: 1.14745,
        low: 1.14079
      },
      {
        timestamp: 1644508800,
        open: 1.14674,
        close: 1.14896,
        high: 1.14958,
        low: 1.14659
      },
      {
        timestamp: 1644512400,
        open: 1.14895,
        close: 1.14653,
        high: 1.14895,
        low: 1.1448
      },
      {
        timestamp: 1644516000,
        open: 1.14652,
        close: 1.14655,
        high: 1.14757,
        low: 1.1461
      },
      {
        timestamp: 1644519600,
        open: 1.14655,
        close: 1.14502,
        high: 1.14665,
        low: 1.14474
      },
      {
        timestamp: 1644523200,
        open: 1.14502,
        close: 1.1432,
        high: 1.14558,
        low: 1.14317
      },
      {
        timestamp: 1644526800,
        open: 1.14319,
        close: 1.143,
        high: 1.1434,
        low: 1.14077
      },
      {
        timestamp: 1644530400,
        open: 1.14297,
        close: 1.14286,
        high: 1.14329,
        low: 1.14284
      },
      {
        timestamp: 1644534000,
        open: 1.14287,
        close: 1.14119,
        high: 1.14287,
        low: 1.14024
      },
      {
        timestamp: 1644537600,
        open: 1.1412,
        close: 1.14002,
        high: 1.14126,
        low: 1.1386
      },
      {
        timestamp: 1644541200,
        open: 1.14003,
        close: 1.14031,
        high: 1.14067,
        low: 1.1395
      },
      {
        timestamp: 1644544800,
        open: 1.1403,
        close: 1.14038,
        high: 1.14076,
        low: 1.13964
      },
      {
        timestamp: 1644548400,
        open: 1.14037,
        close: 1.13936,
        high: 1.14038,
        low: 1.13932
      },
      {
        timestamp: 1644552000,
        open: 1.13935,
        close: 1.13816,
        high: 1.13936,
        low: 1.13755
      },
      {
        timestamp: 1644555600,
        open: 1.13816,
        close: 1.13873,
        high: 1.13893,
        low: 1.13811
      },
      {
        timestamp: 1644559200,
        open: 1.13873,
        close: 1.13768,
        high: 1.1388,
        low: 1.13706
      },
      {
        timestamp: 1644562800,
        open: 1.13769,
        close: 1.13858,
        high: 1.13929,
        low: 1.13734
      },
      {
        timestamp: 1644566400,
        open: 1.13858,
        close: 1.13938,
        high: 1.13987,
        low: 1.13789
      },
      {
        timestamp: 1644570000,
        open: 1.13938,
        close: 1.13869,
        high: 1.13963,
        low: 1.13828
      },
      {
        timestamp: 1644573600,
        open: 1.13868,
        close: 1.13987,
        high: 1.14003,
        low: 1.13844
      },
      {
        timestamp: 1644577200,
        open: 1.13987,
        close: 1.13953,
        high: 1.13998,
        low: 1.13909
      },
      {
        timestamp: 1644580800,
        open: 1.13954,
        close: 1.14068,
        high: 1.14154,
        low: 1.13923
      },
      {
        timestamp: 1644584400,
        open: 1.14067,
        close: 1.13913,
        high: 1.14188,
        low: 1.13903
      },
      {
        timestamp: 1644588000,
        open: 1.13914,
        close: 1.13892,
        high: 1.14009,
        low: 1.13804
      },
      {
        timestamp: 1644591600,
        open: 1.13893,
        close: 1.14027,
        high: 1.14059,
        low: 1.13842
      },
      {
        timestamp: 1644595200,
        open: 1.14026,
        close: 1.14055,
        high: 1.14088,
        low: 1.1393
      },
      {
        timestamp: 1644598800,
        open: 1.14055,
        close: 1.13973,
        high: 1.14084,
        low: 1.13942
      },
      {
        timestamp: 1644602400,
        open: 1.13972,
        close: 1.13506,
        high: 1.14,
        low: 1.13422
      },
      {
        timestamp: 1644606000,
        open: 1.13505,
        close: 1.13344,
        high: 1.13651,
        low: 1.13304
      },
      {
        timestamp: 1644609600,
        open: 1.13344,
        close: 1.13428,
        high: 1.13532,
        low: 1.13318
      },
      {
        timestamp: 1644613200,
        open: 1.13424,
        close: 1.13542,
        high: 1.13547,
        low: 1.13409
      },
      {
        timestamp: 1644778800,
        open: 1.13507,
        close: 1.13505,
        high: 1.13507,
        low: 1.13495
      },
      {
        timestamp: 1644782400,
        open: 1.13457,
        close: 1.13406,
        high: 1.13488,
        low: 1.13387
      },
      {
        timestamp: 1644786000,
        open: 1.13406,
        close: 1.13409,
        high: 1.13428,
        low: 1.13354
      },
      {
        timestamp: 1644789600,
        open: 1.13408,
        close: 1.13451,
        high: 1.13523,
        low: 1.13397
      },
      {
        timestamp: 1644793200,
        open: 1.1345,
        close: 1.13652,
        high: 1.13678,
        low: 1.13447
      },
      {
        timestamp: 1644796800,
        open: 1.13652,
        close: 1.13631,
        high: 1.13698,
        low: 1.13598
      },
      {
        timestamp: 1644800400,
        open: 1.1363,
        close: 1.13542,
        high: 1.13656,
        low: 1.13527
      },
      {
        timestamp: 1644804000,
        open: 1.13543,
        close: 1.13439,
        high: 1.13543,
        low: 1.13412
      },
      {
        timestamp: 1644807600,
        open: 1.13439,
        close: 1.13437,
        high: 1.13488,
        low: 1.13418
      },
      {
        timestamp: 1644811200,
        open: 1.13437,
        close: 1.13459,
        high: 1.13492,
        low: 1.13418
      },
      {
        timestamp: 1644814800,
        open: 1.13459,
        close: 1.13522,
        high: 1.13531,
        low: 1.13426
      },
      {
        timestamp: 1644818400,
        open: 1.13523,
        close: 1.1345,
        high: 1.13529,
        low: 1.13394
      },
      {
        timestamp: 1644822000,
        open: 1.1345,
        close: 1.13389,
        high: 1.13505,
        low: 1.13366
      },
      {
        timestamp: 1644825600,
        open: 1.13388,
        close: 1.13097,
        high: 1.1344,
        low: 1.13045
      },
      {
        timestamp: 1644829200,
        open: 1.13098,
        close: 1.13127,
        high: 1.13223,
        low: 1.13085
      },
      {
        timestamp: 1644832800,
        open: 1.13128,
        close: 1.13142,
        high: 1.13188,
        low: 1.13019
      },
      {
        timestamp: 1644836400,
        open: 1.13143,
        close: 1.13117,
        high: 1.13149,
        low: 1.13014
      },
      {
        timestamp: 1644840000,
        open: 1.13116,
        close: 1.13278,
        high: 1.13432,
        low: 1.13027
      },
      {
        timestamp: 1644843600,
        open: 1.13277,
        close: 1.13158,
        high: 1.13317,
        low: 1.13119
      },
      {
        timestamp: 1644847200,
        open: 1.13157,
        close: 1.13206,
        high: 1.13219,
        low: 1.13054
      },
      {
        timestamp: 1644850800,
        open: 1.13207,
        close: 1.13064,
        high: 1.13207,
        low: 1.12923
      },
      {
        timestamp: 1644854400,
        open: 1.13064,
        close: 1.13069,
        high: 1.13149,
        low: 1.12969
      },
      {
        timestamp: 1644858000,
        open: 1.13068,
        close: 1.13111,
        high: 1.13143,
        low: 1.12993
      },
      {
        timestamp: 1644861600,
        open: 1.13111,
        close: 1.12981,
        high: 1.13149,
        low: 1.12975
      },
      {
        timestamp: 1644865200,
        open: 1.12982,
        close: 1.12954,
        high: 1.13023,
        low: 1.12807
      },
      {
        timestamp: 1644868800,
        open: 1.12953,
        close: 1.12947,
        high: 1.13054,
        low: 1.12936
      },
      {
        timestamp: 1644872400,
        open: 1.12948,
        close: 1.13078,
        high: 1.13087,
        low: 1.12947
      },
      {
        timestamp: 1644876000,
        open: 1.13093,
        close: 1.13064,
        high: 1.13098,
        low: 1.13036
      },
      {
        timestamp: 1644879600,
        open: 1.13064,
        close: 1.13066,
        high: 1.13082,
        low: 1.13046
      },
      {
        timestamp: 1644883200,
        open: 1.13067,
        close: 1.13126,
        high: 1.13138,
        low: 1.13039
      },
      {
        timestamp: 1644886800,
        open: 1.13127,
        close: 1.13113,
        high: 1.13154,
        low: 1.13091
      },
      {
        timestamp: 1644890400,
        open: 1.13112,
        close: 1.13196,
        high: 1.13232,
        low: 1.13099
      },
      {
        timestamp: 1644894000,
        open: 1.13195,
        close: 1.13182,
        high: 1.13231,
        low: 1.13158
      },
      {
        timestamp: 1644897600,
        open: 1.13181,
        close: 1.13197,
        high: 1.13213,
        low: 1.13171
      },
      {
        timestamp: 1644901200,
        open: 1.13197,
        close: 1.13181,
        high: 1.13216,
        low: 1.13156
      },
      {
        timestamp: 1644904800,
        open: 1.13182,
        close: 1.13226,
        high: 1.13245,
        low: 1.13143
      },
      {
        timestamp: 1644908400,
        open: 1.13225,
        close: 1.13132,
        high: 1.13288,
        low: 1.13122
      },
      {
        timestamp: 1644912000,
        open: 1.13133,
        close: 1.13468,
        high: 1.13496,
        low: 1.131
      },
      {
        timestamp: 1644915600,
        open: 1.13469,
        close: 1.13469,
        high: 1.1351,
        low: 1.13365
      },
      {
        timestamp: 1644919200,
        open: 1.13468,
        close: 1.13488,
        high: 1.13544,
        low: 1.13393
      },
      {
        timestamp: 1644922800,
        open: 1.13488,
        close: 1.13465,
        high: 1.13535,
        low: 1.13415
      },
      {
        timestamp: 1644926400,
        open: 1.13465,
        close: 1.13413,
        high: 1.13513,
        low: 1.13373
      },
      {
        timestamp: 1644930000,
        open: 1.13413,
        close: 1.13566,
        high: 1.13607,
        low: 1.13413
      },
      {
        timestamp: 1644933600,
        open: 1.13567,
        close: 1.13224,
        high: 1.13598,
        low: 1.13209
      },
      {
        timestamp: 1644937200,
        open: 1.13225,
        close: 1.13555,
        high: 1.13582,
        low: 1.13223
      },
      {
        timestamp: 1644940800,
        open: 1.13554,
        close: 1.13632,
        high: 1.13689,
        low: 1.13553
      },
      {
        timestamp: 1644944400,
        open: 1.13631,
        close: 1.13594,
        high: 1.13638,
        low: 1.13467
      },
      {
        timestamp: 1644948000,
        open: 1.13593,
        close: 1.13562,
        high: 1.13649,
        low: 1.1356
      },
      {
        timestamp: 1644951600,
        open: 1.13562,
        close: 1.136,
        high: 1.13645,
        low: 1.13552
      },
      {
        timestamp: 1644955200,
        open: 1.136,
        close: 1.13591,
        high: 1.13644,
        low: 1.13553
      },
      {
        timestamp: 1644958800,
        open: 1.1359,
        close: 1.13605,
        high: 1.13606,
        low: 1.13565
      },
      {
        timestamp: 1644962400,
        open: 1.13605,
        close: 1.13593,
        high: 1.13646,
        low: 1.13581
      },
      {
        timestamp: 1644966000,
        open: 1.13595,
        close: 1.13559,
        high: 1.13604,
        low: 1.13558
      },
      {
        timestamp: 1644969600,
        open: 1.13558,
        close: 1.13579,
        high: 1.13603,
        low: 1.1355
      },
      {
        timestamp: 1644973200,
        open: 1.13578,
        close: 1.13527,
        high: 1.13578,
        low: 1.13494
      },
      {
        timestamp: 1644976800,
        open: 1.13527,
        close: 1.13479,
        high: 1.13542,
        low: 1.13457
      },
      {
        timestamp: 1644980400,
        open: 1.1348,
        close: 1.13465,
        high: 1.1349,
        low: 1.13457
      },
      {
        timestamp: 1644984000,
        open: 1.13466,
        close: 1.13485,
        high: 1.13496,
        low: 1.1346
      },
      {
        timestamp: 1644987600,
        open: 1.13485,
        close: 1.13544,
        high: 1.13561,
        low: 1.13484
      },
      {
        timestamp: 1644991200,
        open: 1.13543,
        close: 1.13641,
        high: 1.13643,
        low: 1.13538
      },
      {
        timestamp: 1644994800,
        open: 1.1364,
        close: 1.13759,
        high: 1.13793,
        low: 1.13639
      },
      {
        timestamp: 1644998400,
        open: 1.13759,
        close: 1.13905,
        high: 1.13961,
        low: 1.13737
      },
      {
        timestamp: 1645002000,
        open: 1.13905,
        close: 1.13783,
        high: 1.13906,
        low: 1.13717
      },
      {
        timestamp: 1645005600,
        open: 1.13782,
        close: 1.13811,
        high: 1.13872,
        low: 1.13739
      },
      {
        timestamp: 1645009200,
        open: 1.1381,
        close: 1.13857,
        high: 1.1389,
        low: 1.13749
      },
      {
        timestamp: 1645012800,
        open: 1.13858,
        close: 1.13662,
        high: 1.13874,
        low: 1.13651
      },
      {
        timestamp: 1645016400,
        open: 1.13661,
        close: 1.13637,
        high: 1.1375,
        low: 1.13554
      },
      {
        timestamp: 1645020000,
        open: 1.13638,
        close: 1.13659,
        high: 1.13735,
        low: 1.13538
      },
      {
        timestamp: 1645023600,
        open: 1.13658,
        close: 1.13662,
        high: 1.13741,
        low: 1.13605
      },
      {
        timestamp: 1645027200,
        open: 1.13661,
        close: 1.13814,
        high: 1.13818,
        low: 1.13613
      },
      {
        timestamp: 1645030800,
        open: 1.13815,
        close: 1.13749,
        high: 1.13835,
        low: 1.13742
      },
      {
        timestamp: 1645034400,
        open: 1.1375,
        close: 1.13775,
        high: 1.1383,
        low: 1.13718
      },
      {
        timestamp: 1645038000,
        open: 1.13775,
        close: 1.1391,
        high: 1.13966,
        low: 1.13709
      },
      {
        timestamp: 1645041600,
        open: 1.1391,
        close: 1.13826,
        high: 1.13941,
        low: 1.13811
      },
      {
        timestamp: 1645045200,
        open: 1.13826,
        close: 1.13739,
        high: 1.13831,
        low: 1.13729
      },
      {
        timestamp: 1645048800,
        open: 1.13753,
        close: 1.13775,
        high: 1.1382,
        low: 1.13747
      },
      {
        timestamp: 1645052400,
        open: 1.13774,
        close: 1.13734,
        high: 1.13857,
        low: 1.13678
      },
      {
        timestamp: 1645056000,
        open: 1.13733,
        close: 1.13802,
        high: 1.13835,
        low: 1.13733
      },
      {
        timestamp: 1645059600,
        open: 1.13802,
        close: 1.13824,
        high: 1.13828,
        low: 1.13745
      },
      {
        timestamp: 1645063200,
        open: 1.13824,
        close: 1.13834,
        high: 1.13857,
        low: 1.13796
      },
      {
        timestamp: 1645066800,
        open: 1.13835,
        close: 1.13375,
        high: 1.13853,
        low: 1.13298
      },
      {
        timestamp: 1645070400,
        open: 1.13374,
        close: 1.1343,
        high: 1.13651,
        low: 1.13239
      },
      {
        timestamp: 1645074000,
        open: 1.1343,
        close: 1.13571,
        high: 1.13602,
        low: 1.13429
      },
      {
        timestamp: 1645077600,
        open: 1.13571,
        close: 1.1361,
        high: 1.13748,
        low: 1.13523
      },
      {
        timestamp: 1645081200,
        open: 1.1361,
        close: 1.13758,
        high: 1.13761,
        low: 1.13579
      },
      {
        timestamp: 1645084800,
        open: 1.13758,
        close: 1.13592,
        high: 1.13869,
        low: 1.13504
      },
      {
        timestamp: 1645088400,
        open: 1.13591,
        close: 1.13622,
        high: 1.13651,
        low: 1.13434
      },
      {
        timestamp: 1645092000,
        open: 1.13621,
        close: 1.13761,
        high: 1.13773,
        low: 1.13557
      },
      {
        timestamp: 1645095600,
        open: 1.13761,
        close: 1.13664,
        high: 1.13799,
        low: 1.13631
      },
      {
        timestamp: 1645099200,
        open: 1.13664,
        close: 1.13784,
        high: 1.13798,
        low: 1.13661
      },
      {
        timestamp: 1645102800,
        open: 1.13784,
        close: 1.136,
        high: 1.13797,
        low: 1.13523
      },
      {
        timestamp: 1645106400,
        open: 1.13601,
        close: 1.13589,
        high: 1.13767,
        low: 1.1358
      },
      {
        timestamp: 1645110000,
        open: 1.13589,
        close: 1.13693,
        high: 1.13759,
        low: 1.13554
      },
      {
        timestamp: 1645113600,
        open: 1.13693,
        close: 1.13633,
        high: 1.13763,
        low: 1.13629
      },
      {
        timestamp: 1645117200,
        open: 1.13634,
        close: 1.13599,
        high: 1.13703,
        low: 1.1359
      },
      {
        timestamp: 1645120800,
        open: 1.13599,
        close: 1.13634,
        high: 1.13653,
        low: 1.13577
      },
      {
        timestamp: 1645124400,
        open: 1.13634,
        close: 1.13643,
        high: 1.1368,
        low: 1.13611
      },
      {
        timestamp: 1645128000,
        open: 1.13643,
        close: 1.13605,
        high: 1.13656,
        low: 1.13575
      },
      {
        timestamp: 1645131600,
        open: 1.13606,
        close: 1.13644,
        high: 1.13644,
        low: 1.13597
      },
      {
        timestamp: 1645135200,
        open: 1.13652,
        close: 1.1365,
        high: 1.13677,
        low: 1.1363
      },
      {
        timestamp: 1645138800,
        open: 1.13647,
        close: 1.13644,
        high: 1.13705,
        low: 1.13627
      },
      {
        timestamp: 1645142400,
        open: 1.13643,
        close: 1.13602,
        high: 1.13665,
        low: 1.13586
      },
      {
        timestamp: 1645146000,
        open: 1.13603,
        close: 1.13687,
        high: 1.1372,
        low: 1.13585
      },
      {
        timestamp: 1645149600,
        open: 1.13686,
        close: 1.13677,
        high: 1.13721,
        low: 1.13633
      },
      {
        timestamp: 1645153200,
        open: 1.13676,
        close: 1.1368,
        high: 1.13708,
        low: 1.13647
      },
      {
        timestamp: 1645156800,
        open: 1.1368,
        close: 1.13645,
        high: 1.13695,
        low: 1.13635
      },
      {
        timestamp: 1645160400,
        open: 1.13647,
        close: 1.13664,
        high: 1.13679,
        low: 1.13619
      },
      {
        timestamp: 1645164000,
        open: 1.13663,
        close: 1.13716,
        high: 1.13726,
        low: 1.13663
      },
      {
        timestamp: 1645167600,
        open: 1.13716,
        close: 1.13706,
        high: 1.13744,
        low: 1.1368
      },
      {
        timestamp: 1645171200,
        open: 1.13705,
        close: 1.13764,
        high: 1.13777,
        low: 1.13666
      },
      {
        timestamp: 1645174800,
        open: 1.13765,
        close: 1.13716,
        high: 1.13777,
        low: 1.13646
      },
      {
        timestamp: 1645178400,
        open: 1.13717,
        close: 1.13616,
        high: 1.13731,
        low: 1.13613
      },
      {
        timestamp: 1645182000,
        open: 1.13616,
        close: 1.13631,
        high: 1.13643,
        low: 1.13474
      },
      {
        timestamp: 1645185600,
        open: 1.13631,
        close: 1.13608,
        high: 1.1369,
        low: 1.13581
      },
      {
        timestamp: 1645189200,
        open: 1.13609,
        close: 1.13497,
        high: 1.13624,
        low: 1.13486
      },
      {
        timestamp: 1645192800,
        open: 1.13496,
        close: 1.13444,
        high: 1.13523,
        low: 1.13394
      },
      {
        timestamp: 1645196400,
        open: 1.13444,
        close: 1.13388,
        high: 1.13492,
        low: 1.13279
      },
      {
        timestamp: 1645200000,
        open: 1.13388,
        close: 1.13265,
        high: 1.1345,
        low: 1.13155
      },
      {
        timestamp: 1645203600,
        open: 1.13266,
        close: 1.1323,
        high: 1.133,
        low: 1.13153
      },
      {
        timestamp: 1645207200,
        open: 1.13229,
        close: 1.13307,
        high: 1.1333,
        low: 1.13225
      },
      {
        timestamp: 1645210800,
        open: 1.13308,
        close: 1.13317,
        high: 1.13363,
        low: 1.13284
      },
      {
        timestamp: 1645214400,
        open: 1.13317,
        close: 1.13253,
        high: 1.13326,
        low: 1.13246
      },
      {
        timestamp: 1645218000,
        open: 1.13252,
        close: 1.13283,
        high: 1.13303,
        low: 1.1324
      },
      {
        timestamp: 1645383600,
        open: 1.13179,
        close: 1.13189,
        high: 1.13189,
        low: 1.13179
      },
      {
        timestamp: 1645387200,
        open: 1.13181,
        close: 1.13219,
        high: 1.13219,
        low: 1.13118
      },
      {
        timestamp: 1645390800,
        open: 1.13201,
        close: 1.13251,
        high: 1.13251,
        low: 1.13186
      },
      {
        timestamp: 1645394400,
        open: 1.13246,
        close: 1.1321,
        high: 1.13284,
        low: 1.132
      },
      {
        timestamp: 1645398000,
        open: 1.13218,
        close: 1.13171,
        high: 1.13249,
        low: 1.13139
      },
      {
        timestamp: 1645401600,
        open: 1.13172,
        close: 1.13333,
        high: 1.13362,
        low: 1.13138
      },
      {
        timestamp: 1645405200,
        open: 1.13332,
        close: 1.13542,
        high: 1.13585,
        low: 1.13289
      },
      {
        timestamp: 1645408800,
        open: 1.13542,
        close: 1.13578,
        high: 1.13588,
        low: 1.13536
      },
      {
        timestamp: 1645412400,
        open: 1.13578,
        close: 1.13614,
        high: 1.13686,
        low: 1.13575
      },
      {
        timestamp: 1645416000,
        open: 1.13613,
        close: 1.13627,
        high: 1.13633,
        low: 1.13589
      },
      {
        timestamp: 1645419600,
        open: 1.13628,
        close: 1.13632,
        high: 1.13651,
        low: 1.13614
      },
      {
        timestamp: 1645423200,
        open: 1.13631,
        close: 1.13697,
        high: 1.13704,
        low: 1.13631
      },
      {
        timestamp: 1645426800,
        open: 1.13697,
        close: 1.13743,
        high: 1.13795,
        low: 1.13688
      },
      {
        timestamp: 1645430400,
        open: 1.13742,
        close: 1.13576,
        high: 1.13913,
        low: 1.13569
      },
      {
        timestamp: 1645434000,
        open: 1.13569,
        close: 1.13641,
        high: 1.13689,
        low: 1.13546
      },
      {
        timestamp: 1645437600,
        open: 1.13641,
        close: 1.13571,
        high: 1.13672,
        low: 1.13558
      },
      {
        timestamp: 1645441200,
        open: 1.13571,
        close: 1.13484,
        high: 1.13621,
        low: 1.13465
      },
      {
        timestamp: 1645444800,
        open: 1.13483,
        close: 1.1335,
        high: 1.13509,
        low: 1.13336
      },
      {
        timestamp: 1645448400,
        open: 1.1335,
        close: 1.13307,
        high: 1.13417,
        low: 1.13213
      },
      {
        timestamp: 1645452000,
        open: 1.13307,
        close: 1.13342,
        high: 1.13465,
        low: 1.13259
      },
      {
        timestamp: 1645455600,
        open: 1.13342,
        close: 1.13368,
        high: 1.13452,
        low: 1.13265
      },
      {
        timestamp: 1645459200,
        open: 1.13368,
        close: 1.13471,
        high: 1.13478,
        low: 1.13356
      },
      {
        timestamp: 1645462800,
        open: 1.13471,
        close: 1.13371,
        high: 1.13499,
        low: 1.13363
      },
      {
        timestamp: 1645466400,
        open: 1.13372,
        close: 1.1318,
        high: 1.13437,
        low: 1.13178
      },
      {
        timestamp: 1645470000,
        open: 1.13179,
        close: 1.13194,
        high: 1.13214,
        low: 1.13073
      },
      {
        timestamp: 1645473600,
        open: 1.13194,
        close: 1.13156,
        high: 1.13194,
        low: 1.13081
      },
      {
        timestamp: 1645477200,
        open: 1.13155,
        close: 1.13117,
        high: 1.13198,
        low: 1.13089
      },
      {
        timestamp: 1645480800,
        open: 1.13124,
        close: 1.13081,
        high: 1.1318,
        low: 1.13075
      },
      {
        timestamp: 1645484400,
        open: 1.13081,
        close: 1.13098,
        high: 1.131,
        low: 1.1297
      },
      {
        timestamp: 1645488000,
        open: 1.13097,
        close: 1.13146,
        high: 1.13204,
        low: 1.13078
      },
      {
        timestamp: 1645491600,
        open: 1.13145,
        close: 1.13046,
        high: 1.1317,
        low: 1.13003
      },
      {
        timestamp: 1645495200,
        open: 1.13047,
        close: 1.13053,
        high: 1.13102,
        low: 1.12986
      },
      {
        timestamp: 1645498800,
        open: 1.13053,
        close: 1.12931,
        high: 1.13055,
        low: 1.12887
      },
      {
        timestamp: 1645502400,
        open: 1.1293,
        close: 1.12994,
        high: 1.1301,
        low: 1.1293
      },
      {
        timestamp: 1645506000,
        open: 1.12993,
        close: 1.13074,
        high: 1.13101,
        low: 1.12982
      },
      {
        timestamp: 1645509600,
        open: 1.13075,
        close: 1.1305,
        high: 1.13133,
        low: 1.13005
      },
      {
        timestamp: 1645513200,
        open: 1.13049,
        close: 1.12974,
        high: 1.13066,
        low: 1.12933
      },
      {
        timestamp: 1645516800,
        open: 1.12973,
        close: 1.13265,
        high: 1.13286,
        low: 1.12937
      },
      {
        timestamp: 1645520400,
        open: 1.13268,
        close: 1.13202,
        high: 1.13374,
        low: 1.13154
      },
      {
        timestamp: 1645524000,
        open: 1.13202,
        close: 1.1353,
        high: 1.13674,
        low: 1.1319
      },
      {
        timestamp: 1645527600,
        open: 1.1353,
        close: 1.13466,
        high: 1.13594,
        low: 1.13378
      },
      {
        timestamp: 1645531200,
        open: 1.13467,
        close: 1.13517,
        high: 1.13599,
        low: 1.13439
      },
      {
        timestamp: 1645534800,
        open: 1.13517,
        close: 1.1342,
        high: 1.13541,
        low: 1.13378
      },
      {
        timestamp: 1645538400,
        open: 1.1342,
        close: 1.13471,
        high: 1.13487,
        low: 1.13363
      },
      {
        timestamp: 1645542000,
        open: 1.13471,
        close: 1.13362,
        high: 1.13511,
        low: 1.13312
      },
      {
        timestamp: 1645545600,
        open: 1.13361,
        close: 1.13441,
        high: 1.13548,
        low: 1.13318
      },
      {
        timestamp: 1645549200,
        open: 1.13439,
        close: 1.13342,
        high: 1.13439,
        low: 1.13255
      },
      {
        timestamp: 1645552800,
        open: 1.13342,
        close: 1.13294,
        high: 1.13388,
        low: 1.13259
      },
      {
        timestamp: 1645556400,
        open: 1.13293,
        close: 1.13353,
        high: 1.13417,
        low: 1.13227
      },
      {
        timestamp: 1645560000,
        open: 1.13354,
        close: 1.13251,
        high: 1.13418,
        low: 1.13243
      },
      {
        timestamp: 1645563600,
        open: 1.13249,
        close: 1.13256,
        high: 1.13301,
        low: 1.13247
      },
      {
        timestamp: 1645567200,
        open: 1.1326,
        close: 1.13277,
        high: 1.13307,
        low: 1.1326
      },
      {
        timestamp: 1645570800,
        open: 1.13279,
        close: 1.13296,
        high: 1.13324,
        low: 1.13271
      },
      {
        timestamp: 1645574400,
        open: 1.13296,
        close: 1.13339,
        high: 1.13363,
        low: 1.13292
      },
      {
        timestamp: 1645578000,
        open: 1.13339,
        close: 1.13357,
        high: 1.13367,
        low: 1.133
      },
      {
        timestamp: 1645581600,
        open: 1.13356,
        close: 1.1323,
        high: 1.13395,
        low: 1.1321
      },
      {
        timestamp: 1645585200,
        open: 1.13229,
        close: 1.13266,
        high: 1.13272,
        low: 1.13205
      },
      {
        timestamp: 1645588800,
        open: 1.13265,
        close: 1.13262,
        high: 1.1327,
        low: 1.13239
      },
      {
        timestamp: 1645592400,
        open: 1.13261,
        close: 1.13261,
        high: 1.13308,
        low: 1.13225
      },
      {
        timestamp: 1645596000,
        open: 1.13261,
        close: 1.13231,
        high: 1.13312,
        low: 1.13229
      },
      {
        timestamp: 1645599600,
        open: 1.1323,
        close: 1.13406,
        high: 1.13435,
        low: 1.13167
      },
      {
        timestamp: 1645603200,
        open: 1.13405,
        close: 1.13481,
        high: 1.13481,
        low: 1.13346
      },
      {
        timestamp: 1645606800,
        open: 1.1348,
        close: 1.13562,
        high: 1.13596,
        low: 1.13385
      },
      {
        timestamp: 1645610400,
        open: 1.13563,
        close: 1.13518,
        high: 1.13589,
        low: 1.13506
      },
      {
        timestamp: 1645614000,
        open: 1.13519,
        close: 1.13411,
        high: 1.13537,
        low: 1.13408
      },
      {
        timestamp: 1645617600,
        open: 1.1341,
        close: 1.13382,
        high: 1.13495,
        low: 1.13377
      },
      {
        timestamp: 1645621200,
        open: 1.13383,
        close: 1.13522,
        high: 1.1353,
        low: 1.13371
      },
      {
        timestamp: 1645624800,
        open: 1.13522,
        close: 1.13341,
        high: 1.13525,
        low: 1.13305
      },
      {
        timestamp: 1645628400,
        open: 1.13341,
        close: 1.13251,
        high: 1.13384,
        low: 1.13214
      },
      {
        timestamp: 1645632000,
        open: 1.13252,
        close: 1.13147,
        high: 1.13268,
        low: 1.13066
      },
      {
        timestamp: 1645635600,
        open: 1.13146,
        close: 1.1313,
        high: 1.13192,
        low: 1.13083
      },
      {
        timestamp: 1645639200,
        open: 1.1313,
        close: 1.13097,
        high: 1.1313,
        low: 1.13043
      },
      {
        timestamp: 1645642800,
        open: 1.13098,
        close: 1.13083,
        high: 1.13126,
        low: 1.13045
      },
      {
        timestamp: 1645646400,
        open: 1.13082,
        close: 1.13059,
        high: 1.13113,
        low: 1.13018
      },
      {
        timestamp: 1645650000,
        open: 1.13058,
        close: 1.13108,
        high: 1.13108,
        low: 1.13034
      },
      {
        timestamp: 1645653600,
        open: 1.13129,
        close: 1.13052,
        high: 1.13146,
        low: 1.1305
      },
      {
        timestamp: 1645657200,
        open: 1.13055,
        close: 1.1306,
        high: 1.13093,
        low: 1.13008
      },
      {
        timestamp: 1645660800,
        open: 1.13061,
        close: 1.12768,
        high: 1.1307,
        low: 1.12758
      },
      {
        timestamp: 1645664400,
        open: 1.12768,
        close: 1.12938,
        high: 1.12949,
        low: 1.12768
      },
      {
        timestamp: 1645668000,
        open: 1.12939,
        close: 1.12738,
        high: 1.12947,
        low: 1.12657
      },
      {
        timestamp: 1645671600,
        open: 1.12738,
        close: 1.12389,
        high: 1.12797,
        low: 1.12357
      },
      {
        timestamp: 1645675200,
        open: 1.12388,
        close: 1.12461,
        high: 1.12496,
        low: 1.12093
      },
      {
        timestamp: 1645678800,
        open: 1.12461,
        close: 1.12384,
        high: 1.12468,
        low: 1.12215
      },
      {
        timestamp: 1645682400,
        open: 1.12385,
        close: 1.12459,
        high: 1.12541,
        low: 1.12329
      },
      {
        timestamp: 1645686000,
        open: 1.12458,
        close: 1.12459,
        high: 1.12563,
        low: 1.1236
      },
      {
        timestamp: 1645689600,
        open: 1.12458,
        close: 1.12404,
        high: 1.1263,
        low: 1.12338
      },
      {
        timestamp: 1645693200,
        open: 1.12406,
        close: 1.1212,
        high: 1.12514,
        low: 1.12096
      },
      {
        timestamp: 1645696800,
        open: 1.12119,
        close: 1.11789,
        high: 1.12128,
        low: 1.1164
      },
      {
        timestamp: 1645700400,
        open: 1.11788,
        close: 1.11748,
        high: 1.11815,
        low: 1.11558
      },
      {
        timestamp: 1645704000,
        open: 1.11748,
        close: 1.11711,
        high: 1.11856,
        low: 1.1162
      },
      {
        timestamp: 1645707600,
        open: 1.1171,
        close: 1.11627,
        high: 1.11808,
        low: 1.11534
      },
      {
        timestamp: 1645711200,
        open: 1.11627,
        close: 1.1148,
        high: 1.11722,
        low: 1.11459
      },
      {
        timestamp: 1645714800,
        open: 1.1148,
        close: 1.11123,
        high: 1.1151,
        low: 1.11121
      },
      {
        timestamp: 1645718400,
        open: 1.11123,
        close: 1.11508,
        high: 1.11577,
        low: 1.11071
      },
      {
        timestamp: 1645722000,
        open: 1.11508,
        close: 1.11672,
        high: 1.11794,
        low: 1.11415
      },
      {
        timestamp: 1645725600,
        open: 1.11674,
        close: 1.11896,
        high: 1.12074,
        low: 1.11606
      },
      {
        timestamp: 1645729200,
        open: 1.11896,
        close: 1.1188,
        high: 1.12129,
        low: 1.1185
      },
      {
        timestamp: 1645732800,
        open: 1.1188,
        close: 1.12005,
        high: 1.12216,
        low: 1.1185
      },
      {
        timestamp: 1645736400,
        open: 1.12005,
        close: 1.11944,
        high: 1.12058,
        low: 1.1192
      },
      {
        timestamp: 1645740000,
        open: 1.11954,
        close: 1.11983,
        high: 1.12057,
        low: 1.11939
      },
      {
        timestamp: 1645743600,
        open: 1.11983,
        close: 1.11912,
        high: 1.12011,
        low: 1.11905
      },
      {
        timestamp: 1645747200,
        open: 1.11914,
        close: 1.12022,
        high: 1.12102,
        low: 1.1187
      },
      {
        timestamp: 1645750800,
        open: 1.12021,
        close: 1.12042,
        high: 1.12063,
        low: 1.11903
      },
      {
        timestamp: 1645754400,
        open: 1.12041,
        close: 1.12143,
        high: 1.12147,
        low: 1.11993
      },
      {
        timestamp: 1645758000,
        open: 1.12142,
        close: 1.1218,
        high: 1.12187,
        low: 1.1206
      },
      {
        timestamp: 1645761600,
        open: 1.12179,
        close: 1.12221,
        high: 1.12292,
        low: 1.12167
      },
      {
        timestamp: 1645765200,
        open: 1.12222,
        close: 1.12261,
        high: 1.12263,
        low: 1.12149
      },
      {
        timestamp: 1645768800,
        open: 1.12261,
        close: 1.12027,
        high: 1.12262,
        low: 1.12016
      },
      {
        timestamp: 1645772400,
        open: 1.12028,
        close: 1.12107,
        high: 1.12231,
        low: 1.11977
      },
      {
        timestamp: 1645776000,
        open: 1.12107,
        close: 1.11783,
        high: 1.1216,
        low: 1.11708
      },
      {
        timestamp: 1645779600,
        open: 1.11784,
        close: 1.11822,
        high: 1.11891,
        low: 1.11698
      },
      {
        timestamp: 1645783200,
        open: 1.11822,
        close: 1.11808,
        high: 1.11841,
        low: 1.11667
      },
      {
        timestamp: 1645786800,
        open: 1.11807,
        close: 1.11861,
        high: 1.11878,
        low: 1.11688
      },
      {
        timestamp: 1645790400,
        open: 1.1186,
        close: 1.12264,
        high: 1.12379,
        low: 1.11843
      },
      {
        timestamp: 1645794000,
        open: 1.12264,
        close: 1.12375,
        high: 1.12422,
        low: 1.12126
      },
      {
        timestamp: 1645797600,
        open: 1.12374,
        close: 1.12335,
        high: 1.12503,
        low: 1.12192
      },
      {
        timestamp: 1645801200,
        open: 1.12336,
        close: 1.12472,
        high: 1.12515,
        low: 1.12325
      },
      {
        timestamp: 1645804800,
        open: 1.12472,
        close: 1.12597,
        high: 1.12675,
        low: 1.12394
      },
      {
        timestamp: 1645808400,
        open: 1.12598,
        close: 1.12413,
        high: 1.12625,
        low: 1.12356
      },
      {
        timestamp: 1645812000,
        open: 1.12413,
        close: 1.12575,
        high: 1.12613,
        low: 1.12413
      },
      {
        timestamp: 1645815600,
        open: 1.12575,
        close: 1.12641,
        high: 1.12666,
        low: 1.12504
      },
      {
        timestamp: 1645819200,
        open: 1.12641,
        close: 1.12663,
        high: 1.12719,
        low: 1.12588
      },
      {
        timestamp: 1645822800,
        open: 1.12663,
        close: 1.12731,
        high: 1.12749,
        low: 1.12654
      },
      {
        timestamp: 1645992000,
        open: 1.11597,
        close: 1.1139,
        high: 1.11597,
        low: 1.11332
      },
      {
        timestamp: 1645995600,
        open: 1.11398,
        close: 1.11276,
        high: 1.11516,
        low: 1.11236
      },
      {
        timestamp: 1645999200,
        open: 1.11294,
        close: 1.11549,
        high: 1.11622,
        low: 1.11284
      },
      {
        timestamp: 1646002800,
        open: 1.11556,
        close: 1.11811,
        high: 1.11888,
        low: 1.11507
      },
      {
        timestamp: 1646006400,
        open: 1.11812,
        close: 1.11825,
        high: 1.11952,
        low: 1.11784
      },
      {
        timestamp: 1646010000,
        open: 1.11826,
        close: 1.11718,
        high: 1.11867,
        low: 1.116
      },
      {
        timestamp: 1646013600,
        open: 1.11719,
        close: 1.1158,
        high: 1.11747,
        low: 1.11468
      },
      {
        timestamp: 1646017200,
        open: 1.11579,
        close: 1.11516,
        high: 1.11615,
        low: 1.11498
      },
      {
        timestamp: 1646020800,
        open: 1.11516,
        close: 1.11465,
        high: 1.11537,
        low: 1.11429
      },
      {
        timestamp: 1646024400,
        open: 1.11467,
        close: 1.11572,
        high: 1.11594,
        low: 1.11453
      },
      {
        timestamp: 1646028000,
        open: 1.11572,
        close: 1.11595,
        high: 1.11689,
        low: 1.11552
      },
      {
        timestamp: 1646031600,
        open: 1.11595,
        close: 1.11906,
        high: 1.12006,
        low: 1.11563
      },
      {
        timestamp: 1646035200,
        open: 1.11905,
        close: 1.11793,
        high: 1.11972,
        low: 1.11646
      },
      {
        timestamp: 1646038800,
        open: 1.11793,
        close: 1.11787,
        high: 1.1186,
        low: 1.11656
      },
      {
        timestamp: 1646042400,
        open: 1.11787,
        close: 1.12306,
        high: 1.12331,
        low: 1.11786
      },
      {
        timestamp: 1646046000,
        open: 1.12308,
        close: 1.11996,
        high: 1.12311,
        low: 1.11898
      },
      {
        timestamp: 1646049600,
        open: 1.11996,
        close: 1.11935,
        high: 1.12089,
        low: 1.1192
      },
      {
        timestamp: 1646053200,
        open: 1.11936,
        close: 1.12131,
        high: 1.12173,
        low: 1.11797
      },
      {
        timestamp: 1646056800,
        open: 1.12134,
        close: 1.12226,
        high: 1.12302,
        low: 1.12055
      },
      {
        timestamp: 1646060400,
        open: 1.12226,
        close: 1.12324,
        high: 1.12371,
        low: 1.12013
      },
      {
        timestamp: 1646064000,
        open: 1.12323,
        close: 1.12249,
        high: 1.12473,
        low: 1.12174
      },
      {
        timestamp: 1646067600,
        open: 1.12249,
        close: 1.12057,
        high: 1.12291,
        low: 1.1198
      },
      {
        timestamp: 1646071200,
        open: 1.12056,
        close: 1.12157,
        high: 1.12166,
        low: 1.12042
      },
      {
        timestamp: 1646074800,
        open: 1.12157,
        close: 1.12229,
        high: 1.12243,
        low: 1.12043
      },
      {
        timestamp: 1646078400,
        open: 1.1223,
        close: 1.12113,
        high: 1.12236,
        low: 1.12096
      },
      {
        timestamp: 1646082000,
        open: 1.12114,
        close: 1.12207,
        high: 1.12208,
        low: 1.12107
      },
      {
        timestamp: 1646085600,
        open: 1.12232,
        close: 1.12213,
        high: 1.12262,
        low: 1.12201
      },
      {
        timestamp: 1646089200,
        open: 1.12213,
        close: 1.12149,
        high: 1.12213,
        low: 1.12149
      },
      {
        timestamp: 1646092800,
        open: 1.12149,
        close: 1.12063,
        high: 1.12153,
        low: 1.1206
      },
      {
        timestamp: 1646096400,
        open: 1.12062,
        close: 1.12026,
        high: 1.12112,
        low: 1.12024
      },
      {
        timestamp: 1646100000,
        open: 1.12026,
        close: 1.11971,
        high: 1.1206,
        low: 1.11936
      },
      {
        timestamp: 1646103600,
        open: 1.1197,
        close: 1.11928,
        high: 1.12018,
        low: 1.11928
      },
      {
        timestamp: 1646107200,
        open: 1.11928,
        close: 1.1197,
        high: 1.12003,
        low: 1.11913
      },
      {
        timestamp: 1646110800,
        open: 1.11969,
        close: 1.11968,
        high: 1.12036,
        low: 1.11944
      },
      {
        timestamp: 1646114400,
        open: 1.11968,
        close: 1.12065,
        high: 1.12083,
        low: 1.11952
      },
      {
        timestamp: 1646118000,
        open: 1.12064,
        close: 1.12303,
        high: 1.12305,
        low: 1.12056
      },
      {
        timestamp: 1646121600,
        open: 1.12304,
        close: 1.12272,
        high: 1.12337,
        low: 1.12123
      },
      {
        timestamp: 1646125200,
        open: 1.12272,
        close: 1.11972,
        high: 1.12302,
        low: 1.11906
      },
      {
        timestamp: 1646128800,
        open: 1.11971,
        close: 1.11821,
        high: 1.11993,
        low: 1.11669
      },
      {
        timestamp: 1646132400,
        open: 1.11821,
        close: 1.11764,
        high: 1.11868,
        low: 1.11738
      },
      {
        timestamp: 1646136000,
        open: 1.11764,
        close: 1.11607,
        high: 1.11813,
        low: 1.11569
      },
      {
        timestamp: 1646139600,
        open: 1.11607,
        close: 1.11608,
        high: 1.11708,
        low: 1.1141
      },
      {
        timestamp: 1646143200,
        open: 1.11608,
        close: 1.11527,
        high: 1.11626,
        low: 1.11434
      },
      {
        timestamp: 1646146800,
        open: 1.11526,
        close: 1.1141,
        high: 1.11526,
        low: 1.11288
      },
      {
        timestamp: 1646150400,
        open: 1.11409,
        close: 1.11041,
        high: 1.1145,
        low: 1.10997
      },
      {
        timestamp: 1646154000,
        open: 1.1104,
        close: 1.11098,
        high: 1.11271,
        low: 1.10903
      },
      {
        timestamp: 1646157600,
        open: 1.11097,
        close: 1.11232,
        high: 1.11268,
        low: 1.10991
      },
      {
        timestamp: 1646161200,
        open: 1.11231,
        close: 1.11228,
        high: 1.11357,
        low: 1.11139
      },
      {
        timestamp: 1646164800,
        open: 1.11229,
        close: 1.11311,
        high: 1.11338,
        low: 1.11173
      },
      {
        timestamp: 1646168400,
        open: 1.11311,
        close: 1.11292,
        high: 1.11387,
        low: 1.11239
      },
      {
        timestamp: 1646172000,
        open: 1.11304,
        close: 1.11207,
        high: 1.11314,
        low: 1.1119
      },
      {
        timestamp: 1646175600,
        open: 1.11207,
        close: 1.11331,
        high: 1.11349,
        low: 1.11194
      },
      {
        timestamp: 1646179200,
        open: 1.11328,
        close: 1.11271,
        high: 1.11345,
        low: 1.11224
      },
      {
        timestamp: 1646182800,
        open: 1.11272,
        close: 1.11289,
        high: 1.11362,
        low: 1.11233
      },
      {
        timestamp: 1646186400,
        open: 1.1129,
        close: 1.11261,
        high: 1.11326,
        low: 1.11119
      },
      {
        timestamp: 1646190000,
        open: 1.1126,
        close: 1.11198,
        high: 1.11297,
        low: 1.11187
      },
      {
        timestamp: 1646193600,
        open: 1.11198,
        close: 1.11236,
        high: 1.11245,
        low: 1.11183
      },
      {
        timestamp: 1646197200,
        open: 1.11236,
        close: 1.11157,
        high: 1.11237,
        low: 1.11139
      },
      {
        timestamp: 1646200800,
        open: 1.11156,
        close: 1.10982,
        high: 1.11187,
        low: 1.10974
      },
      {
        timestamp: 1646204400,
        open: 1.10983,
        close: 1.10939,
        high: 1.11132,
        low: 1.10844
      },
      {
        timestamp: 1646208000,
        open: 1.1094,
        close: 1.10863,
        high: 1.11047,
        low: 1.10803
      },
      {
        timestamp: 1646211600,
        open: 1.10862,
        close: 1.10831,
        high: 1.10994,
        low: 1.10594
      },
      {
        timestamp: 1646215200,
        open: 1.1083,
        close: 1.10983,
        high: 1.11028,
        low: 1.10765
      },
      {
        timestamp: 1646218800,
        open: 1.10983,
        close: 1.11002,
        high: 1.11038,
        low: 1.10858
      },
      {
        timestamp: 1646222400,
        open: 1.11002,
        close: 1.11178,
        high: 1.11202,
        low: 1.10951
      },
      {
        timestamp: 1646226000,
        open: 1.11178,
        close: 1.11054,
        high: 1.11181,
        low: 1.10894
      },
      {
        timestamp: 1646229600,
        open: 1.11053,
        close: 1.10944,
        high: 1.11054,
        low: 1.10767
      },
      {
        timestamp: 1646233200,
        open: 1.10943,
        close: 1.10657,
        high: 1.10989,
        low: 1.10581
      },
      {
        timestamp: 1646236800,
        open: 1.10656,
        close: 1.11032,
        high: 1.11446,
        low: 1.10641
      },
      {
        timestamp: 1646240400,
        open: 1.11033,
        close: 1.11032,
        high: 1.11044,
        low: 1.10792
      },
      {
        timestamp: 1646244000,
        open: 1.11031,
        close: 1.11287,
        high: 1.11321,
        low: 1.11004
      },
      {
        timestamp: 1646247600,
        open: 1.11287,
        close: 1.11248,
        high: 1.1135,
        low: 1.11171
      },
      {
        timestamp: 1646251200,
        open: 1.11249,
        close: 1.11279,
        high: 1.11312,
        low: 1.11181
      },
      {
        timestamp: 1646254800,
        open: 1.11279,
        close: 1.11197,
        high: 1.11298,
        low: 1.11194
      },
      {
        timestamp: 1646258400,
        open: 1.11197,
        close: 1.11204,
        high: 1.11263,
        low: 1.11157
      },
      {
        timestamp: 1646262000,
        open: 1.11206,
        close: 1.1113,
        high: 1.11237,
        low: 1.11111
      },
      {
        timestamp: 1646265600,
        open: 1.11129,
        close: 1.11028,
        high: 1.11162,
        low: 1.11014
      },
      {
        timestamp: 1646269200,
        open: 1.11029,
        close: 1.10983,
        high: 1.11046,
        low: 1.10948
      },
      {
        timestamp: 1646272800,
        open: 1.10983,
        close: 1.11067,
        high: 1.11075,
        low: 1.10908
      },
      {
        timestamp: 1646276400,
        open: 1.11067,
        close: 1.11004,
        high: 1.11075,
        low: 1.10962
      },
      {
        timestamp: 1646280000,
        open: 1.11005,
        close: 1.10984,
        high: 1.11039,
        low: 1.10977
      },
      {
        timestamp: 1646283600,
        open: 1.10984,
        close: 1.11023,
        high: 1.11053,
        low: 1.10984
      },
      {
        timestamp: 1646287200,
        open: 1.11022,
        close: 1.11051,
        high: 1.11071,
        low: 1.10945
      },
      {
        timestamp: 1646290800,
        open: 1.11052,
        close: 1.10982,
        high: 1.11066,
        low: 1.10918
      },
      {
        timestamp: 1646294400,
        open: 1.10981,
        close: 1.10921,
        high: 1.11009,
        low: 1.10832
      },
      {
        timestamp: 1646298000,
        open: 1.10921,
        close: 1.10806,
        high: 1.1096,
        low: 1.10724
      },
      {
        timestamp: 1646301600,
        open: 1.10807,
        close: 1.10872,
        high: 1.10949,
        low: 1.10785
      },
      {
        timestamp: 1646305200,
        open: 1.10872,
        close: 1.10875,
        high: 1.10883,
        low: 1.10788
      },
      {
        timestamp: 1646308800,
        open: 1.10874,
        close: 1.10759,
        high: 1.10976,
        low: 1.10732
      },
      {
        timestamp: 1646312400,
        open: 1.1076,
        close: 1.10863,
        high: 1.10882,
        low: 1.10651
      },
      {
        timestamp: 1646316000,
        open: 1.10862,
        close: 1.10807,
        high: 1.10957,
        low: 1.10753
      },
      {
        timestamp: 1646319600,
        open: 1.10807,
        close: 1.10626,
        high: 1.10883,
        low: 1.10584
      },
      {
        timestamp: 1646323200,
        open: 1.10625,
        close: 1.10549,
        high: 1.10659,
        low: 1.10383
      },
      {
        timestamp: 1646326800,
        open: 1.1055,
        close: 1.10516,
        high: 1.10682,
        low: 1.10341
      },
      {
        timestamp: 1646330400,
        open: 1.10517,
        close: 1.10631,
        high: 1.10672,
        low: 1.1048
      },
      {
        timestamp: 1646334000,
        open: 1.10632,
        close: 1.10584,
        high: 1.10664,
        low: 1.10558
      },
      {
        timestamp: 1646337600,
        open: 1.10585,
        close: 1.10638,
        high: 1.10644,
        low: 1.10571
      },
      {
        timestamp: 1646341200,
        open: 1.10638,
        close: 1.10677,
        high: 1.10677,
        low: 1.1063
      },
      {
        timestamp: 1646344800,
        open: 1.10682,
        close: 1.10675,
        high: 1.10683,
        low: 1.10652
      },
      {
        timestamp: 1646348400,
        open: 1.10675,
        close: 1.10664,
        high: 1.10687,
        low: 1.10637
      },
      {
        timestamp: 1646352000,
        open: 1.10664,
        close: 1.10133,
        high: 1.10675,
        low: 1.10108
      },
      {
        timestamp: 1646355600,
        open: 1.10133,
        close: 1.105,
        high: 1.10529,
        low: 1.10121
      },
      {
        timestamp: 1646359200,
        open: 1.10497,
        close: 1.10299,
        high: 1.10505,
        low: 1.10263
      },
      {
        timestamp: 1646362800,
        open: 1.10298,
        close: 1.10263,
        high: 1.10322,
        low: 1.10202
      },
      {
        timestamp: 1646366400,
        open: 1.10263,
        close: 1.10363,
        high: 1.10376,
        low: 1.10242
      },
      {
        timestamp: 1646370000,
        open: 1.10363,
        close: 1.10293,
        high: 1.10402,
        low: 1.10241
      },
      {
        timestamp: 1646373600,
        open: 1.10292,
        close: 1.10162,
        high: 1.10325,
        low: 1.10154
      },
      {
        timestamp: 1646377200,
        open: 1.10161,
        close: 1.10127,
        high: 1.10246,
        low: 1.1009
      },
      {
        timestamp: 1646380800,
        open: 1.10128,
        close: 1.10128,
        high: 1.10178,
        low: 1.1009
      },
      {
        timestamp: 1646384400,
        open: 1.10127,
        close: 1.10149,
        high: 1.10249,
        low: 1.10126
      },
      {
        timestamp: 1646388000,
        open: 1.10149,
        close: 1.10019,
        high: 1.10206,
        low: 1.10005
      },
      {
        timestamp: 1646391600,
        open: 1.10019,
        close: 1.09763,
        high: 1.10074,
        low: 1.09737
      },
      {
        timestamp: 1646395200,
        open: 1.09763,
        close: 1.0938,
        high: 1.09828,
        low: 1.0935
      },
      {
        timestamp: 1646398800,
        open: 1.09381,
        close: 1.09096,
        high: 1.09456,
        low: 1.08863
      },
      {
        timestamp: 1646402400,
        open: 1.09095,
        close: 1.09041,
        high: 1.09289,
        low: 1.08969
      },
      {
        timestamp: 1646406000,
        open: 1.09041,
        close: 1.09194,
        high: 1.09245,
        low: 1.09019
      },
      {
        timestamp: 1646409600,
        open: 1.09194,
        close: 1.09093,
        high: 1.09236,
        low: 1.0909
      },
      {
        timestamp: 1646413200,
        open: 1.0909,
        close: 1.09268,
        high: 1.09272,
        low: 1.09086
      },
      {
        timestamp: 1646416800,
        open: 1.09267,
        close: 1.09341,
        high: 1.09344,
        low: 1.09223
      },
      {
        timestamp: 1646420400,
        open: 1.09341,
        close: 1.09139,
        high: 1.09376,
        low: 1.09105
      },
      {
        timestamp: 1646424000,
        open: 1.09135,
        close: 1.09349,
        high: 1.09369,
        low: 1.09132
      },
      {
        timestamp: 1646427600,
        open: 1.09349,
        close: 1.09352,
        high: 1.09428,
        low: 1.09262
      },
      {
        timestamp: 1646593200,
        open: 1.09446,
        close: 1.09452,
        high: 1.09459,
        low: 1.09321
      },
      {
        timestamp: 1646596800,
        open: 1.09452,
        close: 1.09293,
        high: 1.09453,
        low: 1.09194
      },
      {
        timestamp: 1646600400,
        open: 1.09297,
        close: 1.09298,
        high: 1.09318,
        low: 1.09215
      },
      {
        timestamp: 1646604000,
        open: 1.0929,
        close: 1.09275,
        high: 1.09328,
        low: 1.09235
      },
      {
        timestamp: 1646607600,
        open: 1.09278,
        close: 1.08744,
        high: 1.09282,
        low: 1.08646
      },
      {
        timestamp: 1646611200,
        open: 1.08743,
        close: 1.08626,
        high: 1.08855,
        low: 1.08626
      },
      {
        timestamp: 1646614800,
        open: 1.08625,
        close: 1.08442,
        high: 1.08671,
        low: 1.08222
      },
      {
        timestamp: 1646618400,
        open: 1.08441,
        close: 1.08464,
        high: 1.08546,
        low: 1.08364
      },
      {
        timestamp: 1646622000,
        open: 1.08464,
        close: 1.08717,
        high: 1.08872,
        low: 1.08417
      },
      {
        timestamp: 1646625600,
        open: 1.08717,
        close: 1.08803,
        high: 1.0881,
        low: 1.08636
      },
      {
        timestamp: 1646629200,
        open: 1.088,
        close: 1.08777,
        high: 1.08883,
        low: 1.08672
      },
      {
        timestamp: 1646632800,
        open: 1.08778,
        close: 1.08742,
        high: 1.08902,
        low: 1.08718
      },
      {
        timestamp: 1646636400,
        open: 1.08743,
        close: 1.08718,
        high: 1.08985,
        low: 1.08673
      },
      {
        timestamp: 1646640000,
        open: 1.08717,
        close: 1.08633,
        high: 1.08915,
        low: 1.08546
      },
      {
        timestamp: 1646643600,
        open: 1.08632,
        close: 1.08589,
        high: 1.08716,
        low: 1.08509
      },
      {
        timestamp: 1646647200,
        open: 1.08589,
        close: 1.08335,
        high: 1.08603,
        low: 1.08067
      },
      {
        timestamp: 1646650800,
        open: 1.08335,
        close: 1.086,
        high: 1.08653,
        low: 1.08267
      },
      {
        timestamp: 1646654400,
        open: 1.08601,
        close: 1.08881,
        high: 1.09258,
        low: 1.08563
      },
      {
        timestamp: 1646658000,
        open: 1.08881,
        close: 1.08984,
        high: 1.09221,
        low: 1.08857
      },
      {
        timestamp: 1646661600,
        open: 1.08982,
        close: 1.08921,
        high: 1.09334,
        low: 1.08903
      },
      {
        timestamp: 1646665200,
        open: 1.0892,
        close: 1.08769,
        high: 1.08972,
        low: 1.08585
      },
      {
        timestamp: 1646668800,
        open: 1.08768,
        close: 1.08606,
        high: 1.08781,
        low: 1.08492
      },
      {
        timestamp: 1646672400,
        open: 1.08606,
        close: 1.08759,
        high: 1.08908,
        low: 1.08591
      },
      {
        timestamp: 1646676000,
        open: 1.08759,
        close: 1.08809,
        high: 1.09012,
        low: 1.08723
      },
      {
        timestamp: 1646679600,
        open: 1.08809,
        close: 1.08487,
        high: 1.08915,
        low: 1.08461
      },
      {
        timestamp: 1646683200,
        open: 1.08487,
        close: 1.08712,
        high: 1.08724,
        low: 1.08472
      },
      {
        timestamp: 1646686800,
        open: 1.08709,
        close: 1.08576,
        high: 1.08724,
        low: 1.08525
      },
      {
        timestamp: 1646690400,
        open: 1.08577,
        close: 1.08558,
        high: 1.08622,
        low: 1.0852
      },
      {
        timestamp: 1646694000,
        open: 1.08558,
        close: 1.0867,
        high: 1.0867,
        low: 1.08529
      },
      {
        timestamp: 1646697600,
        open: 1.08671,
        close: 1.08581,
        high: 1.08705,
        low: 1.08499
      },
      {
        timestamp: 1646701200,
        open: 1.08581,
        close: 1.08756,
        high: 1.08815,
        low: 1.08539
      },
      {
        timestamp: 1646704800,
        open: 1.08757,
        close: 1.08757,
        high: 1.08857,
        low: 1.08713
      },
      {
        timestamp: 1646708400,
        open: 1.08755,
        close: 1.08685,
        high: 1.08801,
        low: 1.08682
      },
      {
        timestamp: 1646712000,
        open: 1.08684,
        close: 1.08707,
        high: 1.08736,
        low: 1.08657
      },
      {
        timestamp: 1646715600,
        open: 1.08707,
        close: 1.08605,
        high: 1.08711,
        low: 1.08564
      },
      {
        timestamp: 1646719200,
        open: 1.08605,
        close: 1.08613,
        high: 1.08672,
        low: 1.08528
      },
      {
        timestamp: 1646722800,
        open: 1.08614,
        close: 1.08621,
        high: 1.08847,
        low: 1.08519
      },
      {
        timestamp: 1646726400,
        open: 1.08621,
        close: 1.08976,
        high: 1.09227,
        low: 1.08567
      },
      {
        timestamp: 1646730000,
        open: 1.08976,
        close: 1.08884,
        high: 1.09064,
        low: 1.08664
      },
      {
        timestamp: 1646733600,
        open: 1.08884,
        close: 1.08989,
        high: 1.09056,
        low: 1.0866
      },
      {
        timestamp: 1646737200,
        open: 1.0899,
        close: 1.08918,
        high: 1.09044,
        low: 1.08822
      },
      {
        timestamp: 1646740800,
        open: 1.08919,
        close: 1.0906,
        high: 1.09218,
        low: 1.08919
      },
      {
        timestamp: 1646744400,
        open: 1.09059,
        close: 1.09164,
        high: 1.09184,
        low: 1.08842
      },
      {
        timestamp: 1646748000,
        open: 1.09161,
        close: 1.09065,
        high: 1.09251,
        low: 1.09025
      },
      {
        timestamp: 1646751600,
        open: 1.09065,
        close: 1.0877,
        high: 1.0907,
        low: 1.08728
      },
      {
        timestamp: 1646755200,
        open: 1.08769,
        close: 1.08814,
        high: 1.08936,
        low: 1.08756
      },
      {
        timestamp: 1646758800,
        open: 1.08813,
        close: 1.09441,
        high: 1.09569,
        low: 1.08783
      },
      {
        timestamp: 1646762400,
        open: 1.0944,
        close: 1.09179,
        high: 1.09591,
        low: 1.0905
      },
      {
        timestamp: 1646766000,
        open: 1.09175,
        close: 1.09072,
        high: 1.09179,
        low: 1.09029
      },
      {
        timestamp: 1646769600,
        open: 1.09071,
        close: 1.09072,
        high: 1.09142,
        low: 1.09001
      },
      {
        timestamp: 1646773200,
        open: 1.09073,
        close: 1.0904,
        high: 1.09103,
        low: 1.0895
      },
      {
        timestamp: 1646776800,
        open: 1.09019,
        close: 1.09029,
        high: 1.0907,
        low: 1.08995
      },
      {
        timestamp: 1646780400,
        open: 1.09027,
        close: 1.08989,
        high: 1.09058,
        low: 1.08906
      },
      {
        timestamp: 1646784000,
        open: 1.08989,
        close: 1.09148,
        high: 1.09155,
        low: 1.08974
      },
      {
        timestamp: 1646787600,
        open: 1.09149,
        close: 1.09147,
        high: 1.09211,
        low: 1.0911
      },
      {
        timestamp: 1646791200,
        open: 1.09147,
        close: 1.09169,
        high: 1.0921,
        low: 1.09132
      },
      {
        timestamp: 1646794800,
        open: 1.09168,
        close: 1.09081,
        high: 1.09183,
        low: 1.09059
      },
      {
        timestamp: 1646798400,
        open: 1.0908,
        close: 1.09176,
        high: 1.09199,
        low: 1.09077
      },
      {
        timestamp: 1646802000,
        open: 1.09176,
        close: 1.09168,
        high: 1.09305,
        low: 1.09151
      },
      {
        timestamp: 1646805600,
        open: 1.09167,
        close: 1.09182,
        high: 1.09287,
        low: 1.09147
      },
      {
        timestamp: 1646809200,
        open: 1.09181,
        close: 1.09171,
        high: 1.09263,
        low: 1.091
      },
      {
        timestamp: 1646812800,
        open: 1.09171,
        close: 1.09503,
        high: 1.09567,
        low: 1.09114
      },
      {
        timestamp: 1646816400,
        open: 1.09503,
        close: 1.09705,
        high: 1.09736,
        low: 1.09406
      },
      {
        timestamp: 1646820000,
        open: 1.09706,
        close: 1.09674,
        high: 1.09933,
        low: 1.0966
      },
      {
        timestamp: 1646823600,
        open: 1.09676,
        close: 1.09719,
        high: 1.09727,
        low: 1.09426
      },
      {
        timestamp: 1646827200,
        open: 1.09719,
        close: 1.09862,
        high: 1.09895,
        low: 1.09642
      },
      {
        timestamp: 1646830800,
        open: 1.09861,
        close: 1.10148,
        high: 1.10173,
        low: 1.0984
      },
      {
        timestamp: 1646834400,
        open: 1.10149,
        close: 1.10318,
        high: 1.10511,
        low: 1.10049
      },
      {
        timestamp: 1646838000,
        open: 1.10318,
        close: 1.10448,
        high: 1.10487,
        low: 1.10121
      },
      {
        timestamp: 1646841600,
        open: 1.10448,
        close: 1.1071,
        high: 1.10925,
        low: 1.10436
      },
      {
        timestamp: 1646845200,
        open: 1.1071,
        close: 1.10927,
        high: 1.1096,
        low: 1.10657
      },
      {
        timestamp: 1646848800,
        open: 1.10926,
        close: 1.10664,
        high: 1.10957,
        low: 1.1064
      },
      {
        timestamp: 1646852400,
        open: 1.10664,
        close: 1.1078,
        high: 1.108,
        low: 1.10623
      },
      {
        timestamp: 1646856000,
        open: 1.1078,
        close: 1.10711,
        high: 1.10842,
        low: 1.1063
      },
      {
        timestamp: 1646859600,
        open: 1.10712,
        close: 1.10777,
        high: 1.10797,
        low: 1.10655
      },
      {
        timestamp: 1646863200,
        open: 1.10777,
        close: 1.10702,
        high: 1.10787,
        low: 1.10688
      },
      {
        timestamp: 1646866800,
        open: 1.10702,
        close: 1.10742,
        high: 1.10791,
        low: 1.1066
      },
      {
        timestamp: 1646870400,
        open: 1.10744,
        close: 1.10549,
        high: 1.10744,
        low: 1.10477
      },
      {
        timestamp: 1646874000,
        open: 1.10546,
        close: 1.10521,
        high: 1.10588,
        low: 1.10423
      },
      {
        timestamp: 1646877600,
        open: 1.1052,
        close: 1.10531,
        high: 1.10585,
        low: 1.1048
      },
      {
        timestamp: 1646881200,
        open: 1.1053,
        close: 1.10596,
        high: 1.10602,
        low: 1.1046
      },
      {
        timestamp: 1646884800,
        open: 1.10595,
        close: 1.10547,
        high: 1.10634,
        low: 1.10521
      },
      {
        timestamp: 1646888400,
        open: 1.10547,
        close: 1.10597,
        high: 1.10601,
        low: 1.10528
      },
      {
        timestamp: 1646892000,
        open: 1.10597,
        close: 1.1057,
        high: 1.10685,
        low: 1.1053
      },
      {
        timestamp: 1646895600,
        open: 1.10569,
        close: 1.10658,
        high: 1.10727,
        low: 1.10539
      },
      {
        timestamp: 1646899200,
        open: 1.10658,
        close: 1.10335,
        high: 1.10659,
        low: 1.1027
      },
      {
        timestamp: 1646902800,
        open: 1.10334,
        close: 1.10422,
        high: 1.10813,
        low: 1.10334
      },
      {
        timestamp: 1646906400,
        open: 1.10422,
        close: 1.10356,
        high: 1.10499,
        low: 1.10263
      },
      {
        timestamp: 1646910000,
        open: 1.10355,
        close: 1.10408,
        high: 1.10539,
        low: 1.10308
      },
      {
        timestamp: 1646913600,
        open: 1.10407,
        close: 1.10994,
        high: 1.11222,
        low: 1.10338
      },
      {
        timestamp: 1646917200,
        open: 1.10995,
        close: 1.10648,
        high: 1.11088,
        low: 1.10522
      },
      {
        timestamp: 1646920800,
        open: 1.10646,
        close: 1.10172,
        high: 1.10712,
        low: 1.10008
      },
      {
        timestamp: 1646924400,
        open: 1.10173,
        close: 1.10194,
        high: 1.10245,
        low: 1.09808
      },
      {
        timestamp: 1646928000,
        open: 1.10193,
        close: 1.10073,
        high: 1.10205,
        low: 1.09964
      },
      {
        timestamp: 1646931600,
        open: 1.10074,
        close: 1.0991,
        high: 1.10093,
        low: 1.09907
      },
      {
        timestamp: 1646935200,
        open: 1.0991,
        close: 1.09874,
        high: 1.09939,
        low: 1.09873
      },
      {
        timestamp: 1646938800,
        open: 1.09872,
        close: 1.09858,
        high: 1.09948,
        low: 1.09836
      },
      {
        timestamp: 1646942400,
        open: 1.09856,
        close: 1.0978,
        high: 1.09916,
        low: 1.0977
      },
      {
        timestamp: 1646946000,
        open: 1.0978,
        close: 1.09902,
        high: 1.09902,
        low: 1.09769
      },
      {
        timestamp: 1646949600,
        open: 1.099,
        close: 1.099,
        high: 1.09956,
        low: 1.09876
      },
      {
        timestamp: 1646953200,
        open: 1.09897,
        close: 1.10141,
        high: 1.10158,
        low: 1.0988
      },
      {
        timestamp: 1646956800,
        open: 1.10137,
        close: 1.09987,
        high: 1.10198,
        low: 1.09985
      },
      {
        timestamp: 1646960400,
        open: 1.09988,
        close: 1.09883,
        high: 1.10026,
        low: 1.09813
      },
      {
        timestamp: 1646964000,
        open: 1.09884,
        close: 1.09952,
        high: 1.10038,
        low: 1.0988
      },
      {
        timestamp: 1646967600,
        open: 1.09951,
        close: 1.10053,
        high: 1.10055,
        low: 1.09919
      },
      {
        timestamp: 1646971200,
        open: 1.10052,
        close: 1.10019,
        high: 1.10112,
        low: 1.10009
      },
      {
        timestamp: 1646974800,
        open: 1.10024,
        close: 1.09998,
        high: 1.10047,
        low: 1.09941
      },
      {
        timestamp: 1646978400,
        open: 1.09998,
        close: 1.10052,
        high: 1.10085,
        low: 1.09969
      },
      {
        timestamp: 1646982000,
        open: 1.10052,
        close: 1.09844,
        high: 1.10096,
        low: 1.09816
      },
      {
        timestamp: 1646985600,
        open: 1.09845,
        close: 1.09788,
        high: 1.0993,
        low: 1.09665
      },
      {
        timestamp: 1646989200,
        open: 1.09787,
        close: 1.09681,
        high: 1.09865,
        low: 1.09608
      },
      {
        timestamp: 1646992800,
        open: 1.09681,
        close: 1.09798,
        high: 1.09857,
        low: 1.09665
      },
      {
        timestamp: 1646996400,
        open: 1.09798,
        close: 1.10117,
        high: 1.10443,
        low: 1.09798
      },
      {
        timestamp: 1647000000,
        open: 1.10118,
        close: 1.09874,
        high: 1.10331,
        low: 1.09743
      },
      {
        timestamp: 1647003600,
        open: 1.09873,
        close: 1.09991,
        high: 1.10117,
        low: 1.0986
      },
      {
        timestamp: 1647007200,
        open: 1.0999,
        close: 1.09826,
        high: 1.10024,
        low: 1.09738
      },
      {
        timestamp: 1647010800,
        open: 1.09827,
        close: 1.09658,
        high: 1.09833,
        low: 1.09467
      },
      {
        timestamp: 1647014400,
        open: 1.09658,
        close: 1.09365,
        high: 1.09706,
        low: 1.09292
      },
      {
        timestamp: 1647018000,
        open: 1.09365,
        close: 1.09169,
        high: 1.09374,
        low: 1.09134
      },
      {
        timestamp: 1647021600,
        open: 1.09168,
        close: 1.09039,
        high: 1.09223,
        low: 1.09026
      },
      {
        timestamp: 1647025200,
        open: 1.09039,
        close: 1.09099,
        high: 1.09142,
        low: 1.09035
      },
      {
        timestamp: 1647028800,
        open: 1.09098,
        close: 1.09113,
        high: 1.09146,
        low: 1.09061
      },
      {
        timestamp: 1647032400,
        open: 1.09112,
        close: 1.09135,
        high: 1.09197,
        low: 1.09095
      },
      {
        timestamp: 1647194400,
        open: 1.09392,
        close: 1.0942,
        high: 1.09438,
        low: 1.09325
      },
      {
        timestamp: 1647198000,
        open: 1.09424,
        close: 1.09254,
        high: 1.0947,
        low: 1.09207
      },
      {
        timestamp: 1647201600,
        open: 1.09256,
        close: 1.09247,
        high: 1.09347,
        low: 1.09117
      },
      {
        timestamp: 1647205200,
        open: 1.09257,
        close: 1.0921,
        high: 1.09307,
        low: 1.0919
      },
      {
        timestamp: 1647208800,
        open: 1.09211,
        close: 1.09283,
        high: 1.0937,
        low: 1.09131
      },
      {
        timestamp: 1647212400,
        open: 1.09285,
        close: 1.09323,
        high: 1.09398,
        low: 1.09264
      },
      {
        timestamp: 1647216000,
        open: 1.09325,
        close: 1.09299,
        high: 1.09335,
        low: 1.09208
      },
      {
        timestamp: 1647219600,
        open: 1.093,
        close: 1.09181,
        high: 1.09383,
        low: 1.09136
      },
      {
        timestamp: 1647223200,
        open: 1.0918,
        close: 1.09102,
        high: 1.09189,
        low: 1.09013
      },
      {
        timestamp: 1647226800,
        open: 1.09101,
        close: 1.09052,
        high: 1.0913,
        low: 1.09049
      },
      {
        timestamp: 1647230400,
        open: 1.09053,
        close: 1.09181,
        high: 1.09182,
        low: 1.09018
      },
      {
        timestamp: 1647234000,
        open: 1.0918,
        close: 1.09119,
        high: 1.09189,
        low: 1.09093
      },
      {
        timestamp: 1647237600,
        open: 1.09119,
        close: 1.09181,
        high: 1.09238,
        low: 1.091
      },
      {
        timestamp: 1647241200,
        open: 1.09181,
        close: 1.09325,
        high: 1.09412,
        low: 1.09113
      },
      {
        timestamp: 1647244800,
        open: 1.09325,
        close: 1.09572,
        high: 1.09606,
        low: 1.09309
      },
      {
        timestamp: 1647248400,
        open: 1.0957,
        close: 1.0983,
        high: 1.09907,
        low: 1.09523
      },
      {
        timestamp: 1647252000,
        open: 1.0983,
        close: 1.09613,
        high: 1.0985,
        low: 1.09599
      },
      {
        timestamp: 1647255600,
        open: 1.09613,
        close: 1.09438,
        high: 1.09675,
        low: 1.09405
      },
      {
        timestamp: 1647259200,
        open: 1.0944,
        close: 1.09584,
        high: 1.09657,
        low: 1.09434
      },
      {
        timestamp: 1647262800,
        open: 1.09581,
        close: 1.09429,
        high: 1.09627,
        low: 1.09357
      },
      {
        timestamp: 1647266400,
        open: 1.0943,
        close: 1.09658,
        high: 1.09726,
        low: 1.094
      },
      {
        timestamp: 1647270000,
        open: 1.09658,
        close: 1.09799,
        high: 1.09872,
        low: 1.09621
      },
      {
        timestamp: 1647273600,
        open: 1.09799,
        close: 1.09749,
        high: 1.09946,
        low: 1.09688
      },
      {
        timestamp: 1647277200,
        open: 1.09748,
        close: 1.09756,
        high: 1.09799,
        low: 1.09645
      },
      {
        timestamp: 1647280800,
        open: 1.09756,
        close: 1.09584,
        high: 1.0977,
        low: 1.09575
      },
      {
        timestamp: 1647284400,
        open: 1.09585,
        close: 1.09501,
        high: 1.09669,
        low: 1.09464
      },
      {
        timestamp: 1647288000,
        open: 1.09501,
        close: 1.09429,
        high: 1.09544,
        low: 1.094
      },
      {
        timestamp: 1647291600,
        open: 1.09424,
        close: 1.09464,
        high: 1.09594,
        low: 1.09337
      },
      {
        timestamp: 1647295200,
        open: 1.09464,
        close: 1.09368,
        high: 1.09497,
        low: 1.09342
      },
      {
        timestamp: 1647298800,
        open: 1.09368,
        close: 1.09435,
        high: 1.09461,
        low: 1.09313
      },
      {
        timestamp: 1647302400,
        open: 1.09435,
        close: 1.0951,
        high: 1.09561,
        low: 1.09435
      },
      {
        timestamp: 1647306000,
        open: 1.09509,
        close: 1.09553,
        high: 1.09567,
        low: 1.09382
      },
      {
        timestamp: 1647309600,
        open: 1.09553,
        close: 1.09721,
        high: 1.09776,
        low: 1.09544
      },
      {
        timestamp: 1647313200,
        open: 1.09721,
        close: 1.09783,
        high: 1.09814,
        low: 1.09693
      },
      {
        timestamp: 1647316800,
        open: 1.09782,
        close: 1.09831,
        high: 1.0986,
        low: 1.09754
      },
      {
        timestamp: 1647320400,
        open: 1.09831,
        close: 1.09838,
        high: 1.09857,
        low: 1.09788
      },
      {
        timestamp: 1647324000,
        open: 1.09837,
        close: 1.09716,
        high: 1.09845,
        low: 1.09707
      },
      {
        timestamp: 1647327600,
        open: 1.09718,
        close: 1.10059,
        high: 1.10207,
        low: 1.09676
      },
      {
        timestamp: 1647331200,
        open: 1.10055,
        close: 1.10044,
        high: 1.10201,
        low: 1.09883
      },
      {
        timestamp: 1647334800,
        open: 1.10044,
        close: 1.10063,
        high: 1.10083,
        low: 1.09917
      },
      {
        timestamp: 1647338400,
        open: 1.10063,
        close: 1.09958,
        high: 1.10063,
        low: 1.09933
      },
      {
        timestamp: 1647342000,
        open: 1.09959,
        close: 1.09846,
        high: 1.09979,
        low: 1.09787
      },
      {
        timestamp: 1647345600,
        open: 1.09845,
        close: 1.09912,
        high: 1.10049,
        low: 1.09845
      },
      {
        timestamp: 1647349200,
        open: 1.09911,
        close: 1.0998,
        high: 1.10007,
        low: 1.09745
      },
      {
        timestamp: 1647352800,
        open: 1.0998,
        close: 1.09835,
        high: 1.10005,
        low: 1.09778
      },
      {
        timestamp: 1647356400,
        open: 1.09835,
        close: 1.0976,
        high: 1.09875,
        low: 1.09636
      },
      {
        timestamp: 1647360000,
        open: 1.09761,
        close: 1.09521,
        high: 1.09798,
        low: 1.09387
      },
      {
        timestamp: 1647363600,
        open: 1.09522,
        close: 1.09389,
        high: 1.09544,
        low: 1.09374
      },
      {
        timestamp: 1647367200,
        open: 1.09389,
        close: 1.09414,
        high: 1.09438,
        low: 1.09268
      },
      {
        timestamp: 1647370800,
        open: 1.09413,
        close: 1.09533,
        high: 1.0954,
        low: 1.09401
      },
      {
        timestamp: 1647374400,
        open: 1.09533,
        close: 1.09579,
        high: 1.09608,
        low: 1.09425
      },
      {
        timestamp: 1647378000,
        open: 1.09592,
        close: 1.0951,
        high: 1.09595,
        low: 1.09493
      },
      {
        timestamp: 1647381600,
        open: 1.0951,
        close: 1.09659,
        high: 1.09659,
        low: 1.09508
      },
      {
        timestamp: 1647385200,
        open: 1.09658,
        close: 1.09677,
        high: 1.09751,
        low: 1.09585
      },
      {
        timestamp: 1647388800,
        open: 1.09677,
        close: 1.09578,
        high: 1.0973,
        low: 1.09559
      },
      {
        timestamp: 1647392400,
        open: 1.09577,
        close: 1.09678,
        high: 1.09705,
        low: 1.09568
      },
      {
        timestamp: 1647396000,
        open: 1.09677,
        close: 1.09719,
        high: 1.09781,
        low: 1.09663
      },
      {
        timestamp: 1647399600,
        open: 1.09718,
        close: 1.09678,
        high: 1.09734,
        low: 1.09674
      },
      {
        timestamp: 1647403200,
        open: 1.09678,
        close: 1.09685,
        high: 1.09704,
        low: 1.09664
      },
      {
        timestamp: 1647406800,
        open: 1.09686,
        close: 1.09778,
        high: 1.09787,
        low: 1.09653
      },
      {
        timestamp: 1647410400,
        open: 1.09778,
        close: 1.09796,
        high: 1.0983,
        low: 1.09717
      },
      {
        timestamp: 1647414000,
        open: 1.09797,
        close: 1.09589,
        high: 1.09806,
        low: 1.09566
      },
      {
        timestamp: 1647417600,
        open: 1.0959,
        close: 1.09916,
        high: 1.10062,
        low: 1.09589
      },
      {
        timestamp: 1647421200,
        open: 1.09914,
        close: 1.09875,
        high: 1.10019,
        low: 1.09807
      },
      {
        timestamp: 1647424800,
        open: 1.09874,
        close: 1.1,
        high: 1.10156,
        low: 1.09851
      },
      {
        timestamp: 1647428400,
        open: 1.09997,
        close: 1.10127,
        high: 1.10253,
        low: 1.0999
      },
      {
        timestamp: 1647432000,
        open: 1.10126,
        close: 1.09899,
        high: 1.10145,
        low: 1.0983
      },
      {
        timestamp: 1647435600,
        open: 1.099,
        close: 1.10007,
        high: 1.10064,
        low: 1.09862
      },
      {
        timestamp: 1647439200,
        open: 1.10008,
        close: 1.10121,
        high: 1.10408,
        low: 1.09875
      },
      {
        timestamp: 1647442800,
        open: 1.1012,
        close: 1.09925,
        high: 1.10245,
        low: 1.09911
      },
      {
        timestamp: 1647446400,
        open: 1.09926,
        close: 1.10015,
        high: 1.10142,
        low: 1.09923
      },
      {
        timestamp: 1647450000,
        open: 1.10016,
        close: 1.0983,
        high: 1.10026,
        low: 1.0983
      },
      {
        timestamp: 1647453600,
        open: 1.09851,
        close: 1.09735,
        high: 1.10013,
        low: 1.096
      },
      {
        timestamp: 1647457200,
        open: 1.10023,
        close: 1.10455,
        high: 1.10457,
        low: 1.09948
      },
      {
        timestamp: 1647460800,
        open: 1.10456,
        close: 1.10378,
        high: 1.10476,
        low: 1.10324
      },
      {
        timestamp: 1647464400,
        open: 1.10397,
        close: 1.10331,
        high: 1.10397,
        low: 1.10311
      },
      {
        timestamp: 1647468000,
        open: 1.10331,
        close: 1.10191,
        high: 1.10331,
        low: 1.10084
      },
      {
        timestamp: 1647471600,
        open: 1.1019,
        close: 1.10222,
        high: 1.10259,
        low: 1.10169
      },
      {
        timestamp: 1647475200,
        open: 1.10222,
        close: 1.10521,
        high: 1.1053,
        low: 1.1021
      },
      {
        timestamp: 1647478800,
        open: 1.10522,
        close: 1.10369,
        high: 1.10527,
        low: 1.10306
      },
      {
        timestamp: 1647482400,
        open: 1.10369,
        close: 1.10428,
        high: 1.10435,
        low: 1.10312
      },
      {
        timestamp: 1647486000,
        open: 1.10429,
        close: 1.10358,
        high: 1.1043,
        low: 1.10333
      },
      {
        timestamp: 1647489600,
        open: 1.10358,
        close: 1.10352,
        high: 1.10393,
        low: 1.10309
      },
      {
        timestamp: 1647493200,
        open: 1.10352,
        close: 1.10241,
        high: 1.10352,
        low: 1.1024
      },
      {
        timestamp: 1647496800,
        open: 1.10241,
        close: 1.10369,
        high: 1.10397,
        low: 1.10228
      },
      {
        timestamp: 1647500400,
        open: 1.1037,
        close: 1.10454,
        high: 1.10469,
        low: 1.10308
      },
      {
        timestamp: 1647504000,
        open: 1.10455,
        close: 1.10537,
        high: 1.1068,
        low: 1.10403
      },
      {
        timestamp: 1647507600,
        open: 1.10537,
        close: 1.10437,
        high: 1.10545,
        low: 1.10414
      },
      {
        timestamp: 1647511200,
        open: 1.1055,
        close: 1.10475,
        high: 1.10551,
        low: 1.10239
      },
      {
        timestamp: 1647514800,
        open: 1.10475,
        close: 1.10516,
        high: 1.10591,
        low: 1.1047
      },
      {
        timestamp: 1647518400,
        open: 1.10518,
        close: 1.10466,
        high: 1.10663,
        low: 1.1041
      },
      {
        timestamp: 1647522000,
        open: 1.10466,
        close: 1.10709,
        high: 1.10716,
        low: 1.1045
      },
      {
        timestamp: 1647525600,
        open: 1.10709,
        close: 1.10834,
        high: 1.10923,
        low: 1.1068
      },
      {
        timestamp: 1647529200,
        open: 1.10834,
        close: 1.11106,
        high: 1.11121,
        low: 1.10807
      },
      {
        timestamp: 1647532800,
        open: 1.11106,
        close: 1.11317,
        high: 1.11345,
        low: 1.11053
      },
      {
        timestamp: 1647536400,
        open: 1.11317,
        close: 1.11275,
        high: 1.11383,
        low: 1.11238
      },
      {
        timestamp: 1647540000,
        open: 1.11276,
        close: 1.11009,
        high: 1.1128,
        low: 1.10994
      },
      {
        timestamp: 1647543600,
        open: 1.11008,
        close: 1.10993,
        high: 1.11009,
        low: 1.10936
      },
      {
        timestamp: 1647547200,
        open: 1.10993,
        close: 1.10924,
        high: 1.11008,
        low: 1.10902
      },
      {
        timestamp: 1647550800,
        open: 1.10932,
        close: 1.10923,
        high: 1.10965,
        low: 1.10918
      },
      {
        timestamp: 1647554400,
        open: 1.10923,
        close: 1.10944,
        high: 1.10968,
        low: 1.10911
      },
      {
        timestamp: 1647558000,
        open: 1.10943,
        close: 1.10997,
        high: 1.11008,
        low: 1.10901
      },
      {
        timestamp: 1647561600,
        open: 1.10998,
        close: 1.11005,
        high: 1.11194,
        low: 1.10972
      },
      {
        timestamp: 1647565200,
        open: 1.11005,
        close: 1.10882,
        high: 1.11045,
        low: 1.10864
      },
      {
        timestamp: 1647568800,
        open: 1.10882,
        close: 1.10863,
        high: 1.10913,
        low: 1.10821
      },
      {
        timestamp: 1647572400,
        open: 1.10863,
        close: 1.10845,
        high: 1.10906,
        low: 1.10778
      },
      {
        timestamp: 1647576000,
        open: 1.10845,
        close: 1.10774,
        high: 1.10879,
        low: 1.10772
      },
      {
        timestamp: 1647579600,
        open: 1.10774,
        close: 1.10848,
        high: 1.10862,
        low: 1.10763
      },
      {
        timestamp: 1647583200,
        open: 1.10848,
        close: 1.10841,
        high: 1.10951,
        low: 1.10828
      },
      {
        timestamp: 1647586800,
        open: 1.10842,
        close: 1.10795,
        high: 1.10902,
        low: 1.10718
      },
      {
        timestamp: 1647590400,
        open: 1.10793,
        close: 1.10685,
        high: 1.10814,
        low: 1.10561
      },
      {
        timestamp: 1647594000,
        open: 1.10685,
        close: 1.10579,
        high: 1.10727,
        low: 1.10538
      },
      {
        timestamp: 1647597600,
        open: 1.1058,
        close: 1.10389,
        high: 1.10609,
        low: 1.10321
      },
      {
        timestamp: 1647601200,
        open: 1.1039,
        close: 1.10231,
        high: 1.10422,
        low: 1.10215
      },
      {
        timestamp: 1647604800,
        open: 1.10232,
        close: 1.10211,
        high: 1.1037,
        low: 1.1016
      },
      {
        timestamp: 1647608400,
        open: 1.10212,
        close: 1.1026,
        high: 1.1027,
        low: 1.10037
      },
      {
        timestamp: 1647612000,
        open: 1.10261,
        close: 1.10305,
        high: 1.10446,
        low: 1.10251
      },
      {
        timestamp: 1647615600,
        open: 1.10305,
        close: 1.10487,
        high: 1.10544,
        low: 1.10276
      },
      {
        timestamp: 1647619200,
        open: 1.10487,
        close: 1.10614,
        high: 1.10731,
        low: 1.1043
      },
      {
        timestamp: 1647622800,
        open: 1.10614,
        close: 1.10593,
        high: 1.1065,
        low: 1.10513
      },
      {
        timestamp: 1647626400,
        open: 1.10592,
        close: 1.10493,
        high: 1.10598,
        low: 1.10429
      },
      {
        timestamp: 1647630000,
        open: 1.10492,
        close: 1.10528,
        high: 1.10577,
        low: 1.10469
      },
      {
        timestamp: 1647633600,
        open: 1.1053,
        close: 1.10524,
        high: 1.10547,
        low: 1.10481
      },
      {
        timestamp: 1647806400,
        open: 1.10652,
        close: 1.10637,
        high: 1.10663,
        low: 1.10637
      },
      {
        timestamp: 1647810000,
        open: 1.10633,
        close: 1.10533,
        high: 1.10641,
        low: 1.10524
      },
      {
        timestamp: 1647813600,
        open: 1.10533,
        close: 1.10458,
        high: 1.10584,
        low: 1.10442
      },
      {
        timestamp: 1647817200,
        open: 1.10457,
        close: 1.10434,
        high: 1.10485,
        low: 1.10418
      },
      {
        timestamp: 1647820800,
        open: 1.10432,
        close: 1.1046,
        high: 1.10485,
        low: 1.10384
      },
      {
        timestamp: 1647824400,
        open: 1.10459,
        close: 1.10457,
        high: 1.10545,
        low: 1.10451
      },
      {
        timestamp: 1647828000,
        open: 1.10457,
        close: 1.10498,
        high: 1.10512,
        low: 1.10436
      },
      {
        timestamp: 1647831600,
        open: 1.10499,
        close: 1.10462,
        high: 1.10526,
        low: 1.10458
      },
      {
        timestamp: 1647835200,
        open: 1.10462,
        close: 1.10417,
        high: 1.10462,
        low: 1.10413
      },
      {
        timestamp: 1647838800,
        open: 1.10417,
        close: 1.10426,
        high: 1.10445,
        low: 1.10385
      },
      {
        timestamp: 1647842400,
        open: 1.10426,
        close: 1.10392,
        high: 1.10477,
        low: 1.10352
      },
      {
        timestamp: 1647846000,
        open: 1.1039,
        close: 1.10518,
        high: 1.10567,
        low: 1.10358
      },
      {
        timestamp: 1647849600,
        open: 1.10519,
        close: 1.1057,
        high: 1.10635,
        low: 1.1047
      },
      {
        timestamp: 1647853200,
        open: 1.10571,
        close: 1.10577,
        high: 1.10711,
        low: 1.10521
      },
      {
        timestamp: 1647856800,
        open: 1.10576,
        close: 1.10461,
        high: 1.1059,
        low: 1.10457
      },
      {
        timestamp: 1647860400,
        open: 1.1046,
        close: 1.10321,
        high: 1.10463,
        low: 1.10262
      },
      {
        timestamp: 1647864000,
        open: 1.10319,
        close: 1.10343,
        high: 1.10445,
        low: 1.10298
      },
      {
        timestamp: 1647867600,
        open: 1.10344,
        close: 1.10343,
        high: 1.10449,
        low: 1.10332
      },
      {
        timestamp: 1647871200,
        open: 1.10342,
        close: 1.10427,
        high: 1.10463,
        low: 1.10293
      },
      {
        timestamp: 1647874800,
        open: 1.10427,
        close: 1.10456,
        high: 1.10516,
        low: 1.10382
      },
      {
        timestamp: 1647878400,
        open: 1.10458,
        close: 1.10426,
        high: 1.1053,
        low: 1.10269
      },
      {
        timestamp: 1647882000,
        open: 1.10427,
        close: 1.10313,
        high: 1.10444,
        low: 1.10187
      },
      {
        timestamp: 1647885600,
        open: 1.10313,
        close: 1.10166,
        high: 1.10316,
        low: 1.10154
      },
      {
        timestamp: 1647889200,
        open: 1.10166,
        close: 1.10147,
        high: 1.10204,
        low: 1.10108
      },
      {
        timestamp: 1647892800,
        open: 1.10148,
        close: 1.10183,
        high: 1.10183,
        low: 1.10131
      },
      {
        timestamp: 1647896400,
        open: 1.10193,
        close: 1.10213,
        high: 1.10221,
        low: 1.10172
      },
      {
        timestamp: 1647900000,
        open: 1.10209,
        close: 1.10213,
        high: 1.10266,
        low: 1.1018
      },
      {
        timestamp: 1647903600,
        open: 1.10214,
        close: 1.10174,
        high: 1.10228,
        low: 1.10153
      },
      {
        timestamp: 1647907200,
        open: 1.10174,
        close: 1.09874,
        high: 1.10181,
        low: 1.09818
      },
      {
        timestamp: 1647910800,
        open: 1.09873,
        close: 1.09964,
        high: 1.09977,
        low: 1.09847
      },
      {
        timestamp: 1647914400,
        open: 1.09965,
        close: 1.10026,
        high: 1.10034,
        low: 1.09914
      },
      {
        timestamp: 1647918000,
        open: 1.10027,
        close: 1.10031,
        high: 1.10058,
        low: 1.09973
      },
      {
        timestamp: 1647921600,
        open: 1.10033,
        close: 1.0986,
        high: 1.10035,
        low: 1.09847
      },
      {
        timestamp: 1647925200,
        open: 1.0986,
        close: 1.09824,
        high: 1.09918,
        low: 1.09815
      },
      {
        timestamp: 1647928800,
        open: 1.09825,
        close: 1.09685,
        high: 1.09869,
        low: 1.09681
      },
      {
        timestamp: 1647932400,
        open: 1.09686,
        close: 1.0983,
        high: 1.09839,
        low: 1.09617
      },
      {
        timestamp: 1647936000,
        open: 1.09829,
        close: 1.10079,
        high: 1.10123,
        low: 1.09815
      },
      {
        timestamp: 1647939600,
        open: 1.10079,
        close: 1.09971,
        high: 1.10151,
        low: 1.09928
      },
      {
        timestamp: 1647943200,
        open: 1.0997,
        close: 1.10042,
        high: 1.10074,
        low: 1.09879
      },
      {
        timestamp: 1647946800,
        open: 1.10041,
        close: 1.10001,
        high: 1.10061,
        low: 1.09921
      },
      {
        timestamp: 1647950400,
        open: 1.10002,
        close: 1.10164,
        high: 1.10355,
        low: 1.10002
      },
      {
        timestamp: 1647954000,
        open: 1.10164,
        close: 1.10419,
        high: 1.10441,
        low: 1.10159
      },
      {
        timestamp: 1647957600,
        open: 1.1042,
        close: 1.10344,
        high: 1.10464,
        low: 1.10292
      },
      {
        timestamp: 1647961200,
        open: 1.10344,
        close: 1.10225,
        high: 1.1035,
        low: 1.10157
      },
      {
        timestamp: 1647964800,
        open: 1.10226,
        close: 1.10183,
        high: 1.10245,
        low: 1.10121
      },
      {
        timestamp: 1647968400,
        open: 1.10183,
        close: 1.10239,
        high: 1.10304,
        low: 1.10153
      },
      {
        timestamp: 1647972000,
        open: 1.10239,
        close: 1.10228,
        high: 1.10268,
        low: 1.1021
      },
      {
        timestamp: 1647975600,
        open: 1.10229,
        close: 1.10309,
        high: 1.10327,
        low: 1.1021
      },
      {
        timestamp: 1647979200,
        open: 1.10309,
        close: 1.10335,
        high: 1.1034,
        low: 1.10276
      },
      {
        timestamp: 1647982800,
        open: 1.10327,
        close: 1.10314,
        high: 1.10328,
        low: 1.10271
      },
      {
        timestamp: 1647986400,
        open: 1.10317,
        close: 1.10325,
        high: 1.10447,
        low: 1.10312
      },
      {
        timestamp: 1647990000,
        open: 1.10325,
        close: 1.10302,
        high: 1.10352,
        low: 1.10269
      },
      {
        timestamp: 1647993600,
        open: 1.10302,
        close: 1.1026,
        high: 1.10329,
        low: 1.10216
      },
      {
        timestamp: 1647997200,
        open: 1.1026,
        close: 1.10266,
        high: 1.1033,
        low: 1.10218
      },
      {
        timestamp: 1648000800,
        open: 1.10266,
        close: 1.10331,
        high: 1.1035,
        low: 1.10263
      },
      {
        timestamp: 1648004400,
        open: 1.1033,
        close: 1.10271,
        high: 1.10356,
        low: 1.10264
      },
      {
        timestamp: 1648008000,
        open: 1.1027,
        close: 1.10283,
        high: 1.10296,
        low: 1.10247
      },
      {
        timestamp: 1648011600,
        open: 1.10283,
        close: 1.10378,
        high: 1.10405,
        low: 1.10275
      },
      {
        timestamp: 1648015200,
        open: 1.10378,
        close: 1.10318,
        high: 1.10406,
        low: 1.10314
      },
      {
        timestamp: 1648018800,
        open: 1.10318,
        close: 1.10185,
        high: 1.10367,
        low: 1.10152
      },
      {
        timestamp: 1648022400,
        open: 1.10185,
        close: 1.10168,
        high: 1.10244,
        low: 1.10111
      },
      {
        timestamp: 1648026000,
        open: 1.10169,
        close: 1.0998,
        high: 1.10181,
        low: 1.09892
      },
      {
        timestamp: 1648029600,
        open: 1.09979,
        close: 1.10026,
        high: 1.10077,
        low: 1.09966
      },
      {
        timestamp: 1648033200,
        open: 1.10025,
        close: 1.09838,
        high: 1.10073,
        low: 1.09756
      },
      {
        timestamp: 1648036800,
        open: 1.09839,
        close: 1.09873,
        high: 1.09952,
        low: 1.09818
      },
      {
        timestamp: 1648040400,
        open: 1.09876,
        close: 1.0967,
        high: 1.09916,
        low: 1.09648
      },
      {
        timestamp: 1648044000,
        open: 1.0967,
        close: 1.09785,
        high: 1.09889,
        low: 1.09667
      },
      {
        timestamp: 1648047600,
        open: 1.09787,
        close: 1.10023,
        high: 1.10063,
        low: 1.09772
      },
      {
        timestamp: 1648051200,
        open: 1.10024,
        close: 1.10023,
        high: 1.10078,
        low: 1.09986
      },
      {
        timestamp: 1648054800,
        open: 1.10023,
        close: 1.10065,
        high: 1.10079,
        low: 1.09978
      },
      {
        timestamp: 1648058400,
        open: 1.10063,
        close: 1.10098,
        high: 1.10117,
        low: 1.10051
      },
      {
        timestamp: 1648062000,
        open: 1.10098,
        close: 1.10055,
        high: 1.10126,
        low: 1.10033
      },
      {
        timestamp: 1648065600,
        open: 1.10056,
        close: 1.10058,
        high: 1.10114,
        low: 1.10047
      },
      {
        timestamp: 1648069200,
        open: 1.10066,
        close: 1.10095,
        high: 1.10147,
        low: 1.10066
      },
      {
        timestamp: 1648072800,
        open: 1.10095,
        close: 1.10034,
        high: 1.10106,
        low: 1.10017
      },
      {
        timestamp: 1648076400,
        open: 1.10036,
        close: 1.10068,
        high: 1.10079,
        low: 1.10036
      },
      {
        timestamp: 1648080000,
        open: 1.10067,
        close: 1.10031,
        high: 1.10144,
        low: 1.1002
      },
      {
        timestamp: 1648083600,
        open: 1.10031,
        close: 1.09889,
        high: 1.10038,
        low: 1.0983
      },
      {
        timestamp: 1648087200,
        open: 1.09889,
        close: 1.0986,
        high: 1.09905,
        low: 1.09832
      },
      {
        timestamp: 1648090800,
        open: 1.09859,
        close: 1.0986,
        high: 1.09923,
        low: 1.09814
      },
      {
        timestamp: 1648094400,
        open: 1.09859,
        close: 1.09863,
        high: 1.09882,
        low: 1.09812
      },
      {
        timestamp: 1648098000,
        open: 1.09863,
        close: 1.09785,
        high: 1.09869,
        low: 1.09772
      },
      {
        timestamp: 1648101600,
        open: 1.09784,
        close: 1.09867,
        high: 1.09899,
        low: 1.09761
      },
      {
        timestamp: 1648105200,
        open: 1.09866,
        close: 1.09788,
        high: 1.0988,
        low: 1.09759
      },
      {
        timestamp: 1648108800,
        open: 1.09789,
        close: 1.09786,
        high: 1.10017,
        low: 1.09728
      },
      {
        timestamp: 1648112400,
        open: 1.09787,
        close: 1.09887,
        high: 1.09974,
        low: 1.09783
      },
      {
        timestamp: 1648116000,
        open: 1.09887,
        close: 1.09888,
        high: 1.09939,
        low: 1.09824
      },
      {
        timestamp: 1648119600,
        open: 1.09889,
        close: 1.09883,
        high: 1.10019,
        low: 1.0973
      },
      {
        timestamp: 1648123200,
        open: 1.09884,
        close: 1.09838,
        high: 1.09961,
        low: 1.09753
      },
      {
        timestamp: 1648126800,
        open: 1.09838,
        close: 1.09744,
        high: 1.09848,
        low: 1.09661
      },
      {
        timestamp: 1648130400,
        open: 1.09745,
        close: 1.09964,
        high: 1.09992,
        low: 1.0972
      },
      {
        timestamp: 1648134000,
        open: 1.09963,
        close: 1.10069,
        high: 1.10142,
        low: 1.09944
      },
      {
        timestamp: 1648137600,
        open: 1.10068,
        close: 1.09863,
        high: 1.10093,
        low: 1.09858
      },
      {
        timestamp: 1648141200,
        open: 1.09865,
        close: 1.09944,
        high: 1.09996,
        low: 1.0983
      },
      {
        timestamp: 1648144800,
        open: 1.09944,
        close: 1.09988,
        high: 1.10015,
        low: 1.09915
      },
      {
        timestamp: 1648148400,
        open: 1.09987,
        close: 1.1,
        high: 1.10065,
        low: 1.09971
      },
      {
        timestamp: 1648152000,
        open: 1.09999,
        close: 1.09983,
        high: 1.1001,
        low: 1.09967
      },
      {
        timestamp: 1648155600,
        open: 1.09983,
        close: 1.1,
        high: 1.10038,
        low: 1.09965
      },
      {
        timestamp: 1648159200,
        open: 1.09999,
        close: 1.10068,
        high: 1.10074,
        low: 1.09999
      },
      {
        timestamp: 1648162800,
        open: 1.10069,
        close: 1.10093,
        high: 1.10108,
        low: 1.10044
      },
      {
        timestamp: 1648166400,
        open: 1.10092,
        close: 1.10098,
        high: 1.1012,
        low: 1.1004
      },
      {
        timestamp: 1648170000,
        open: 1.10096,
        close: 1.10333,
        high: 1.10361,
        low: 1.10086
      },
      {
        timestamp: 1648173600,
        open: 1.10332,
        close: 1.10293,
        high: 1.10365,
        low: 1.10242
      },
      {
        timestamp: 1648177200,
        open: 1.10292,
        close: 1.10283,
        high: 1.10351,
        low: 1.10267
      },
      {
        timestamp: 1648180800,
        open: 1.10282,
        close: 1.10327,
        high: 1.10385,
        low: 1.10267
      },
      {
        timestamp: 1648184400,
        open: 1.10326,
        close: 1.10295,
        high: 1.10347,
        low: 1.10257
      },
      {
        timestamp: 1648188000,
        open: 1.10295,
        close: 1.10287,
        high: 1.10297,
        low: 1.10198
      },
      {
        timestamp: 1648191600,
        open: 1.10286,
        close: 1.1024,
        high: 1.10314,
        low: 1.10221
      },
      {
        timestamp: 1648195200,
        open: 1.10239,
        close: 1.10169,
        high: 1.10274,
        low: 1.10116
      },
      {
        timestamp: 1648198800,
        open: 1.1017,
        close: 1.10058,
        high: 1.1021,
        low: 1.10014
      },
      {
        timestamp: 1648202400,
        open: 1.10059,
        close: 1.1015,
        high: 1.10154,
        low: 1.10023
      },
      {
        timestamp: 1648206000,
        open: 1.10149,
        close: 1.10192,
        high: 1.1023,
        low: 1.10113
      },
      {
        timestamp: 1648209600,
        open: 1.1019,
        close: 1.10041,
        high: 1.1019,
        low: 1.10034
      },
      {
        timestamp: 1648213200,
        open: 1.10042,
        close: 1.10126,
        high: 1.10195,
        low: 1.09929
      },
      {
        timestamp: 1648216800,
        open: 1.10126,
        close: 1.10186,
        high: 1.10252,
        low: 1.10078
      },
      {
        timestamp: 1648220400,
        open: 1.10185,
        close: 1.0983,
        high: 1.10224,
        low: 1.09827
      },
      {
        timestamp: 1648224000,
        open: 1.0983,
        close: 1.09885,
        high: 1.09924,
        low: 1.09817
      },
      {
        timestamp: 1648227600,
        open: 1.09885,
        close: 1.09915,
        high: 1.0994,
        low: 1.09848
      },
      {
        timestamp: 1648231200,
        open: 1.09915,
        close: 1.09877,
        high: 1.09974,
        low: 1.09841
      },
      {
        timestamp: 1648234800,
        open: 1.09876,
        close: 1.09841,
        high: 1.09912,
        low: 1.0983
      },
      {
        timestamp: 1648238400,
        open: 1.09841,
        close: 1.09854,
        high: 1.09882,
        low: 1.09828
      },
      {
        timestamp: 1648404000,
        open: 1.09929,
        close: 1.09945,
        high: 1.09964,
        low: 1.09929
      },
      {
        timestamp: 1648407600,
        open: 1.09889,
        close: 1.09894,
        high: 1.09915,
        low: 1.09883
      },
      {
        timestamp: 1648411200,
        open: 1.09895,
        close: 1.09888,
        high: 1.09922,
        low: 1.09888
      },
      {
        timestamp: 1648414800,
        open: 1.09901,
        close: 1.09869,
        high: 1.09901,
        low: 1.09834
      },
      {
        timestamp: 1648418400,
        open: 1.0987,
        close: 1.09822,
        high: 1.0987,
        low: 1.09801
      },
      {
        timestamp: 1648422000,
        open: 1.09821,
        close: 1.09821,
        high: 1.09852,
        low: 1.09783
      },
      {
        timestamp: 1648425600,
        open: 1.09822,
        close: 1.09715,
        high: 1.09852,
        low: 1.09681
      },
      {
        timestamp: 1648429200,
        open: 1.09713,
        close: 1.09549,
        high: 1.09754,
        low: 1.09545
      },
      {
        timestamp: 1648432800,
        open: 1.09548,
        close: 1.09562,
        high: 1.09629,
        low: 1.09517
      },
      {
        timestamp: 1648436400,
        open: 1.09562,
        close: 1.09563,
        high: 1.09598,
        low: 1.09511
      },
      {
        timestamp: 1648440000,
        open: 1.09563,
        close: 1.09548,
        high: 1.09571,
        low: 1.09517
      },
      {
        timestamp: 1648443600,
        open: 1.09548,
        close: 1.0952,
        high: 1.09548,
        low: 1.09454
      },
      {
        timestamp: 1648447200,
        open: 1.09519,
        close: 1.09559,
        high: 1.09662,
        low: 1.09489
      },
      {
        timestamp: 1648450800,
        open: 1.09559,
        close: 1.09523,
        high: 1.09616,
        low: 1.09482
      },
      {
        timestamp: 1648454400,
        open: 1.09523,
        close: 1.09916,
        high: 1.10001,
        low: 1.09452
      },
      {
        timestamp: 1648458000,
        open: 1.09915,
        close: 1.098,
        high: 1.09922,
        low: 1.09709
      },
      {
        timestamp: 1648461600,
        open: 1.09802,
        close: 1.0989,
        high: 1.09913,
        low: 1.09752
      },
      {
        timestamp: 1648465200,
        open: 1.09891,
        close: 1.09641,
        high: 1.09941,
        low: 1.09598
      },
      {
        timestamp: 1648468800,
        open: 1.09642,
        close: 1.09574,
        high: 1.09709,
        low: 1.09518
      },
      {
        timestamp: 1648472400,
        open: 1.09573,
        close: 1.09693,
        high: 1.09733,
        low: 1.09473
      },
      {
        timestamp: 1648476000,
        open: 1.09692,
        close: 1.09704,
        high: 1.09795,
        low: 1.09646
      },
      {
        timestamp: 1648479600,
        open: 1.09704,
        close: 1.09769,
        high: 1.09771,
        low: 1.09668
      },
      {
        timestamp: 1648483200,
        open: 1.0977,
        close: 1.09947,
        high: 1.10002,
        low: 1.09747
      },
      {
        timestamp: 1648486800,
        open: 1.09947,
        close: 1.09837,
        high: 1.09999,
        low: 1.09836
      },
      {
        timestamp: 1648490400,
        open: 1.09837,
        close: 1.09909,
        high: 1.09912,
        low: 1.09779
      },
      {
        timestamp: 1648494000,
        open: 1.09908,
        close: 1.09883,
        high: 1.09911,
        low: 1.09752
      },
      {
        timestamp: 1648497600,
        open: 1.09884,
        close: 1.09872,
        high: 1.09907,
        low: 1.09846
      },
      {
        timestamp: 1648501200,
        open: 1.09882,
        close: 1.09875,
        high: 1.09888,
        low: 1.09857
      },
      {
        timestamp: 1648504800,
        open: 1.09877,
        close: 1.09892,
        high: 1.09949,
        low: 1.09861
      },
      {
        timestamp: 1648508400,
        open: 1.09893,
        close: 1.0992,
        high: 1.09985,
        low: 1.0989
      },
      {
        timestamp: 1648512000,
        open: 1.09921,
        close: 1.09744,
        high: 1.0998,
        low: 1.09695
      },
      {
        timestamp: 1648515600,
        open: 1.09744,
        close: 1.0993,
        high: 1.09959,
        low: 1.0974
      },
      {
        timestamp: 1648519200,
        open: 1.0993,
        close: 1.0993,
        high: 1.09957,
        low: 1.0988
      },
      {
        timestamp: 1648522800,
        open: 1.09929,
        close: 1.09935,
        high: 1.09948,
        low: 1.09851
      },
      {
        timestamp: 1648526400,
        open: 1.09934,
        close: 1.0986,
        high: 1.09981,
        low: 1.09859
      },
      {
        timestamp: 1648530000,
        open: 1.0986,
        close: 1.09836,
        high: 1.09891,
        low: 1.09799
      },
      {
        timestamp: 1648533600,
        open: 1.09837,
        close: 1.10041,
        high: 1.10098,
        low: 1.09833
      },
      {
        timestamp: 1648537200,
        open: 1.10041,
        close: 1.10005,
        high: 1.1019,
        low: 1.09982
      },
      {
        timestamp: 1648540800,
        open: 1.10004,
        close: 1.09856,
        high: 1.10062,
        low: 1.09798
      },
      {
        timestamp: 1648544400,
        open: 1.09855,
        close: 1.10382,
        high: 1.10468,
        low: 1.09757
      },
      {
        timestamp: 1648548000,
        open: 1.10381,
        close: 1.10481,
        high: 1.10586,
        low: 1.10355
      },
      {
        timestamp: 1648551600,
        open: 1.1048,
        close: 1.10854,
        high: 1.11033,
        low: 1.10325
      },
      {
        timestamp: 1648555200,
        open: 1.10853,
        close: 1.11137,
        high: 1.11298,
        low: 1.10796
      },
      {
        timestamp: 1648558800,
        open: 1.11137,
        close: 1.11306,
        high: 1.11379,
        low: 1.10978
      },
      {
        timestamp: 1648562400,
        open: 1.11306,
        close: 1.11005,
        high: 1.1133,
        low: 1.10909
      },
      {
        timestamp: 1648566000,
        open: 1.11005,
        close: 1.11074,
        high: 1.11178,
        low: 1.1099
      },
      {
        timestamp: 1648569600,
        open: 1.11074,
        close: 1.10808,
        high: 1.11075,
        low: 1.10786
      },
      {
        timestamp: 1648573200,
        open: 1.10809,
        close: 1.10783,
        high: 1.10834,
        low: 1.10722
      },
      {
        timestamp: 1648576800,
        open: 1.10782,
        close: 1.10896,
        high: 1.10917,
        low: 1.10742
      },
      {
        timestamp: 1648580400,
        open: 1.10895,
        close: 1.10888,
        high: 1.10951,
        low: 1.1084
      },
      {
        timestamp: 1648584000,
        open: 1.10887,
        close: 1.10868,
        high: 1.10892,
        low: 1.10846
      },
      {
        timestamp: 1648587600,
        open: 1.10886,
        close: 1.10895,
        high: 1.10912,
        low: 1.1083
      },
      {
        timestamp: 1648591200,
        open: 1.10898,
        close: 1.10925,
        high: 1.11001,
        low: 1.1088
      },
      {
        timestamp: 1648594800,
        open: 1.10927,
        close: 1.10918,
        high: 1.10956,
        low: 1.10909
      },
      {
        timestamp: 1648598400,
        open: 1.10918,
        close: 1.10983,
        high: 1.10989,
        low: 1.10887
      },
      {
        timestamp: 1648602000,
        open: 1.10983,
        close: 1.11052,
        high: 1.11109,
        low: 1.10977
      },
      {
        timestamp: 1648605600,
        open: 1.11053,
        close: 1.11132,
        high: 1.11168,
        low: 1.11017
      },
      {
        timestamp: 1648609200,
        open: 1.11132,
        close: 1.11159,
        high: 1.11214,
        low: 1.11098
      },
      {
        timestamp: 1648612800,
        open: 1.11159,
        close: 1.11114,
        high: 1.11221,
        low: 1.11102
      },
      {
        timestamp: 1648616400,
        open: 1.11114,
        close: 1.11137,
        high: 1.1114,
        low: 1.11084
      },
      {
        timestamp: 1648620000,
        open: 1.11138,
        close: 1.11129,
        high: 1.11217,
        low: 1.11063
      },
      {
        timestamp: 1648623600,
        open: 1.11129,
        close: 1.11297,
        high: 1.11355,
        low: 1.11129
      },
      {
        timestamp: 1648627200,
        open: 1.11297,
        close: 1.11571,
        high: 1.11575,
        low: 1.11151
      },
      {
        timestamp: 1648630800,
        open: 1.11571,
        close: 1.11469,
        high: 1.11626,
        low: 1.1144
      },
      {
        timestamp: 1648634400,
        open: 1.1147,
        close: 1.11299,
        high: 1.11471,
        low: 1.1117
      },
      {
        timestamp: 1648638000,
        open: 1.11299,
        close: 1.11393,
        high: 1.11484,
        low: 1.11263
      },
      {
        timestamp: 1648641600,
        open: 1.11395,
        close: 1.11258,
        high: 1.11409,
        low: 1.11125
      },
      {
        timestamp: 1648645200,
        open: 1.11258,
        close: 1.11648,
        high: 1.11665,
        low: 1.11201
      },
      {
        timestamp: 1648648800,
        open: 1.11647,
        close: 1.11631,
        high: 1.11719,
        low: 1.11512
      },
      {
        timestamp: 1648652400,
        open: 1.11631,
        close: 1.11637,
        high: 1.11694,
        low: 1.11562
      },
      {
        timestamp: 1648656000,
        open: 1.11637,
        close: 1.11578,
        high: 1.11662,
        low: 1.11527
      },
      {
        timestamp: 1648659600,
        open: 1.11579,
        close: 1.1149,
        high: 1.1158,
        low: 1.1149
      },
      {
        timestamp: 1648663200,
        open: 1.11488,
        close: 1.11616,
        high: 1.11631,
        low: 1.11484
      },
      {
        timestamp: 1648666800,
        open: 1.11618,
        close: 1.11526,
        high: 1.11633,
        low: 1.11521
      },
      {
        timestamp: 1648670400,
        open: 1.11525,
        close: 1.11611,
        high: 1.11611,
        low: 1.11525
      },
      {
        timestamp: 1648674000,
        open: 1.11609,
        close: 1.11598,
        high: 1.11622,
        low: 1.11591
      },
      {
        timestamp: 1648677600,
        open: 1.11599,
        close: 1.11595,
        high: 1.11655,
        low: 1.11578
      },
      {
        timestamp: 1648681200,
        open: 1.11592,
        close: 1.1162,
        high: 1.11626,
        low: 1.11584
      },
      {
        timestamp: 1648684800,
        open: 1.11619,
        close: 1.11747,
        high: 1.11754,
        low: 1.11568
      },
      {
        timestamp: 1648688400,
        open: 1.11746,
        close: 1.11768,
        high: 1.11855,
        low: 1.11719
      },
      {
        timestamp: 1648692000,
        open: 1.11768,
        close: 1.11672,
        high: 1.11768,
        low: 1.11666
      },
      {
        timestamp: 1648695600,
        open: 1.11673,
        close: 1.11648,
        high: 1.11703,
        low: 1.11622
      },
      {
        timestamp: 1648699200,
        open: 1.11648,
        close: 1.11612,
        high: 1.11649,
        low: 1.11582
      },
      {
        timestamp: 1648702800,
        open: 1.11613,
        close: 1.11668,
        high: 1.11686,
        low: 1.11583
      },
      {
        timestamp: 1648706400,
        open: 1.11671,
        close: 1.11695,
        high: 1.11739,
        low: 1.11519
      },
      {
        timestamp: 1648710000,
        open: 1.11695,
        close: 1.11511,
        high: 1.11763,
        low: 1.11506
      },
      {
        timestamp: 1648713600,
        open: 1.1151,
        close: 1.11302,
        high: 1.11523,
        low: 1.11134
      },
      {
        timestamp: 1648717200,
        open: 1.11303,
        close: 1.11123,
        high: 1.11325,
        low: 1.11068
      },
      {
        timestamp: 1648720800,
        open: 1.11124,
        close: 1.10944,
        high: 1.11162,
        low: 1.10888
      },
      {
        timestamp: 1648724400,
        open: 1.10944,
        close: 1.11036,
        high: 1.11093,
        low: 1.10933
      },
      {
        timestamp: 1648728000,
        open: 1.11035,
        close: 1.11028,
        high: 1.11141,
        low: 1.10949
      },
      {
        timestamp: 1648731600,
        open: 1.11028,
        close: 1.10931,
        high: 1.1108,
        low: 1.10689
      },
      {
        timestamp: 1648735200,
        open: 1.10933,
        close: 1.11328,
        high: 1.11332,
        low: 1.10712
      },
      {
        timestamp: 1648738800,
        open: 1.11327,
        close: 1.10933,
        high: 1.11336,
        low: 1.10933
      },
      {
        timestamp: 1648742400,
        open: 1.10933,
        close: 1.10825,
        high: 1.10946,
        low: 1.10766
      },
      {
        timestamp: 1648746000,
        open: 1.10825,
        close: 1.10819,
        high: 1.10945,
        low: 1.10799
      },
      {
        timestamp: 1648749600,
        open: 1.10819,
        close: 1.10704,
        high: 1.10819,
        low: 1.10646
      },
      {
        timestamp: 1648753200,
        open: 1.10706,
        close: 1.10648,
        high: 1.1076,
        low: 1.10616
      },
      {
        timestamp: 1648756800,
        open: 1.10647,
        close: 1.10689,
        high: 1.10729,
        low: 1.10647
      },
      {
        timestamp: 1648760400,
        open: 1.10694,
        close: 1.10695,
        high: 1.10702,
        low: 1.10662
      },
      {
        timestamp: 1648764000,
        open: 1.10697,
        close: 1.10742,
        high: 1.1077,
        low: 1.10675
      },
      {
        timestamp: 1648767600,
        open: 1.10745,
        close: 1.10733,
        high: 1.10753,
        low: 1.1068
      },
      {
        timestamp: 1648771200,
        open: 1.10734,
        close: 1.1068,
        high: 1.10751,
        low: 1.10602
      },
      {
        timestamp: 1648774800,
        open: 1.1068,
        close: 1.10726,
        high: 1.1074,
        low: 1.10639
      },
      {
        timestamp: 1648778400,
        open: 1.10724,
        close: 1.10654,
        high: 1.10736,
        low: 1.10635
      },
      {
        timestamp: 1648782000,
        open: 1.10655,
        close: 1.10658,
        high: 1.10696,
        low: 1.10622
      },
      {
        timestamp: 1648785600,
        open: 1.10658,
        close: 1.10646,
        high: 1.10691,
        low: 1.10636
      },
      {
        timestamp: 1648789200,
        open: 1.10647,
        close: 1.10687,
        high: 1.10752,
        low: 1.10642
      },
      {
        timestamp: 1648792800,
        open: 1.10687,
        close: 1.10742,
        high: 1.10766,
        low: 1.10529
      },
      {
        timestamp: 1648796400,
        open: 1.10744,
        close: 1.10535,
        high: 1.10764,
        low: 1.10437
      },
      {
        timestamp: 1648800000,
        open: 1.10535,
        close: 1.10615,
        high: 1.1075,
        low: 1.10491
      },
      {
        timestamp: 1648803600,
        open: 1.10615,
        close: 1.10564,
        high: 1.10615,
        low: 1.10423
      },
      {
        timestamp: 1648807200,
        open: 1.10564,
        close: 1.1068,
        high: 1.10681,
        low: 1.10483
      },
      {
        timestamp: 1648810800,
        open: 1.10681,
        close: 1.1049,
        high: 1.10685,
        low: 1.10486
      },
      {
        timestamp: 1648814400,
        open: 1.1049,
        close: 1.10447,
        high: 1.106,
        low: 1.10374
      },
      {
        timestamp: 1648818000,
        open: 1.10448,
        close: 1.10453,
        high: 1.10546,
        low: 1.10393
      },
      {
        timestamp: 1648821600,
        open: 1.10454,
        close: 1.10378,
        high: 1.10505,
        low: 1.10287
      },
      {
        timestamp: 1648825200,
        open: 1.1038,
        close: 1.10448,
        high: 1.10453,
        low: 1.10304
      },
      {
        timestamp: 1648828800,
        open: 1.10449,
        close: 1.10408,
        high: 1.10496,
        low: 1.10396
      },
      {
        timestamp: 1648832400,
        open: 1.10408,
        close: 1.10403,
        high: 1.10433,
        low: 1.10332
      },
      {
        timestamp: 1648836000,
        open: 1.10403,
        close: 1.10396,
        high: 1.10486,
        low: 1.10393
      },
      {
        timestamp: 1648839600,
        open: 1.10396,
        close: 1.10486,
        high: 1.10486,
        low: 1.10395
      },
      {
        timestamp: 1648843200,
        open: 1.10486,
        close: 1.10494,
        high: 1.10532,
        low: 1.10453
      },
      {
        timestamp: 1649012400,
        open: 1.1044,
        close: 1.10397,
        high: 1.1044,
        low: 1.1039
      },
      {
        timestamp: 1649016000,
        open: 1.10448,
        close: 1.10387,
        high: 1.10448,
        low: 1.10349
      },
      {
        timestamp: 1649019600,
        open: 1.10387,
        close: 1.10409,
        high: 1.10449,
        low: 1.10387
      },
      {
        timestamp: 1649023200,
        open: 1.10412,
        close: 1.10443,
        high: 1.10449,
        low: 1.10389
      },
      {
        timestamp: 1649026800,
        open: 1.10443,
        close: 1.10463,
        high: 1.10464,
        low: 1.10416
      },
      {
        timestamp: 1649030400,
        open: 1.10463,
        close: 1.105,
        high: 1.10545,
        low: 1.10452
      },
      {
        timestamp: 1649034000,
        open: 1.10499,
        close: 1.10498,
        high: 1.10552,
        low: 1.10442
      },
      {
        timestamp: 1649037600,
        open: 1.10496,
        close: 1.10497,
        high: 1.10524,
        low: 1.10473
      },
      {
        timestamp: 1649041200,
        open: 1.10495,
        close: 1.10485,
        high: 1.10541,
        low: 1.10479
      },
      {
        timestamp: 1649044800,
        open: 1.10486,
        close: 1.10486,
        high: 1.10503,
        low: 1.10468
      },
      {
        timestamp: 1649048400,
        open: 1.10487,
        close: 1.10519,
        high: 1.10527,
        low: 1.10472
      },
      {
        timestamp: 1649052000,
        open: 1.10518,
        close: 1.10402,
        high: 1.10546,
        low: 1.1037
      },
      {
        timestamp: 1649055600,
        open: 1.10401,
        close: 1.1032,
        high: 1.10434,
        low: 1.10281
      },
      {
        timestamp: 1649059200,
        open: 1.10321,
        close: 1.10231,
        high: 1.10391,
        low: 1.102
      },
      {
        timestamp: 1649062800,
        open: 1.10231,
        close: 1.10105,
        high: 1.10329,
        low: 1.10096
      },
      {
        timestamp: 1649066400,
        open: 1.10106,
        close: 1.10018,
        high: 1.10145,
        low: 1.10013
      },
      {
        timestamp: 1649070000,
        open: 1.10019,
        close: 1.09974,
        high: 1.10091,
        low: 1.09967
      },
      {
        timestamp: 1649073600,
        open: 1.09976,
        close: 1.09999,
        high: 1.10097,
        low: 1.09965
      },
      {
        timestamp: 1649077200,
        open: 1.1,
        close: 1.09931,
        high: 1.10036,
        low: 1.09819
      },
      {
        timestamp: 1649080800,
        open: 1.09931,
        close: 1.09953,
        high: 1.09993,
        low: 1.09799
      },
      {
        timestamp: 1649084400,
        open: 1.09954,
        close: 1.09936,
        high: 1.10019,
        low: 1.09912
      },
      {
        timestamp: 1649088000,
        open: 1.09936,
        close: 1.09754,
        high: 1.09936,
        low: 1.0975
      },
      {
        timestamp: 1649091600,
        open: 1.09755,
        close: 1.09645,
        high: 1.09778,
        low: 1.09635
      },
      {
        timestamp: 1649095200,
        open: 1.09645,
        close: 1.09719,
        high: 1.09735,
        low: 1.09611
      },
      {
        timestamp: 1649098800,
        open: 1.0972,
        close: 1.09703,
        high: 1.09762,
        low: 1.09698
      },
      {
        timestamp: 1649102400,
        open: 1.09704,
        close: 1.09748,
        high: 1.09762,
        low: 1.09699
      },
      {
        timestamp: 1649106000,
        open: 1.09764,
        close: 1.09757,
        high: 1.09772,
        low: 1.09745
      },
      {
        timestamp: 1649109600,
        open: 1.09757,
        close: 1.09735,
        high: 1.09761,
        low: 1.09726
      },
      {
        timestamp: 1649113200,
        open: 1.09735,
        close: 1.09756,
        high: 1.09768,
        low: 1.09719
      },
      {
        timestamp: 1649116800,
        open: 1.09757,
        close: 1.09768,
        high: 1.09808,
        low: 1.09711
      },
      {
        timestamp: 1649120400,
        open: 1.09769,
        close: 1.09691,
        high: 1.09776,
        low: 1.09682
      },
      {
        timestamp: 1649124000,
        open: 1.09693,
        close: 1.09699,
        high: 1.09738,
        low: 1.0966
      },
      {
        timestamp: 1649127600,
        open: 1.09699,
        close: 1.09732,
        high: 1.09733,
        low: 1.09688
      },
      {
        timestamp: 1649131200,
        open: 1.09731,
        close: 1.09649,
        high: 1.09733,
        low: 1.0963
      },
      {
        timestamp: 1649134800,
        open: 1.0965,
        close: 1.09696,
        high: 1.09729,
        low: 1.0963
      },
      {
        timestamp: 1649138400,
        open: 1.09695,
        close: 1.09763,
        high: 1.09815,
        low: 1.09646
      },
      {
        timestamp: 1649142000,
        open: 1.09765,
        close: 1.09826,
        high: 1.09896,
        low: 1.09722
      },
      {
        timestamp: 1649145600,
        open: 1.09826,
        close: 1.09684,
        high: 1.09856,
        low: 1.09626
      },
      {
        timestamp: 1649149200,
        open: 1.09684,
        close: 1.09789,
        high: 1.09862,
        low: 1.09676
      },
      {
        timestamp: 1649152800,
        open: 1.09789,
        close: 1.09703,
        high: 1.09789,
        low: 1.09569
      },
      {
        timestamp: 1649156400,
        open: 1.09703,
        close: 1.0968,
        high: 1.09784,
        low: 1.09634
      },
      {
        timestamp: 1649160000,
        open: 1.09679,
        close: 1.09651,
        high: 1.09725,
        low: 1.09569
      },
      {
        timestamp: 1649163600,
        open: 1.09651,
        close: 1.09703,
        high: 1.09764,
        low: 1.09593
      },
      {
        timestamp: 1649167200,
        open: 1.09702,
        close: 1.0939,
        high: 1.09734,
        low: 1.09317
      },
      {
        timestamp: 1649170800,
        open: 1.0939,
        close: 1.09214,
        high: 1.09424,
        low: 1.09198
      },
      {
        timestamp: 1649174400,
        open: 1.09214,
        close: 1.09148,
        high: 1.0924,
        low: 1.0913
      },
      {
        timestamp: 1649178000,
        open: 1.09149,
        close: 1.09138,
        high: 1.09219,
        low: 1.09118
      },
      {
        timestamp: 1649181600,
        open: 1.09139,
        close: 1.09049,
        high: 1.09159,
        low: 1.09037
      },
      {
        timestamp: 1649185200,
        open: 1.0905,
        close: 1.09044,
        high: 1.09063,
        low: 1.09006
      },
      {
        timestamp: 1649188800,
        open: 1.09044,
        close: 1.09068,
        high: 1.09071,
        low: 1.09035
      },
      {
        timestamp: 1649192400,
        open: 1.09075,
        close: 1.09086,
        high: 1.09087,
        low: 1.09056
      },
      {
        timestamp: 1649196000,
        open: 1.09088,
        close: 1.09078,
        high: 1.09089,
        low: 1.09055
      },
      {
        timestamp: 1649199600,
        open: 1.09077,
        close: 1.09066,
        high: 1.09091,
        low: 1.09056
      },
      {
        timestamp: 1649203200,
        open: 1.09066,
        close: 1.08951,
        high: 1.0907,
        low: 1.08947
      },
      {
        timestamp: 1649206800,
        open: 1.08952,
        close: 1.08923,
        high: 1.0897,
        low: 1.08914
      },
      {
        timestamp: 1649210400,
        open: 1.08923,
        close: 1.08971,
        high: 1.09042,
        low: 1.08918
      },
      {
        timestamp: 1649214000,
        open: 1.08972,
        close: 1.09011,
        high: 1.09037,
        low: 1.08964
      },
      {
        timestamp: 1649217600,
        open: 1.09011,
        close: 1.08975,
        high: 1.09022,
        low: 1.08938
      },
      {
        timestamp: 1649221200,
        open: 1.08976,
        close: 1.08844,
        high: 1.09015,
        low: 1.08842
      },
      {
        timestamp: 1649224800,
        open: 1.08843,
        close: 1.08887,
        high: 1.08892,
        low: 1.0875
      },
      {
        timestamp: 1649228400,
        open: 1.08888,
        close: 1.08963,
        high: 1.09029,
        low: 1.08844
      },
      {
        timestamp: 1649232000,
        open: 1.08963,
        close: 1.09238,
        high: 1.09278,
        low: 1.08919
      },
      {
        timestamp: 1649235600,
        open: 1.09238,
        close: 1.09133,
        high: 1.09265,
        low: 1.09078
      },
      {
        timestamp: 1649239200,
        open: 1.09134,
        close: 1.0913,
        high: 1.09145,
        low: 1.09
      },
      {
        timestamp: 1649242800,
        open: 1.09129,
        close: 1.09176,
        high: 1.09195,
        low: 1.09051
      },
      {
        timestamp: 1649246400,
        open: 1.09176,
        close: 1.0934,
        high: 1.09388,
        low: 1.09176
      },
      {
        timestamp: 1649250000,
        open: 1.09341,
        close: 1.09136,
        high: 1.09379,
        low: 1.09132
      },
      {
        timestamp: 1649253600,
        open: 1.09138,
        close: 1.09204,
        high: 1.09245,
        low: 1.09062
      },
      {
        timestamp: 1649257200,
        open: 1.09203,
        close: 1.09112,
        high: 1.09233,
        low: 1.09034
      },
      {
        timestamp: 1649260800,
        open: 1.09113,
        close: 1.09088,
        high: 1.09164,
        low: 1.09045
      },
      {
        timestamp: 1649264400,
        open: 1.09088,
        close: 1.09014,
        high: 1.09112,
        low: 1.09005
      },
      {
        timestamp: 1649268000,
        open: 1.09015,
        close: 1.0898,
        high: 1.09297,
        low: 1.08795
      },
      {
        timestamp: 1649271600,
        open: 1.08981,
        close: 1.09012,
        high: 1.09071,
        low: 1.08922
      },
      {
        timestamp: 1649275200,
        open: 1.09012,
        close: 1.09006,
        high: 1.0903,
        low: 1.08955
      },
      {
        timestamp: 1649278800,
        open: 1.08977,
        close: 1.08993,
        high: 1.09059,
        low: 1.08963
      },
      {
        timestamp: 1649282400,
        open: 1.08993,
        close: 1.08968,
        high: 1.09004,
        low: 1.08953
      },
      {
        timestamp: 1649286000,
        open: 1.08968,
        close: 1.08984,
        high: 1.09003,
        low: 1.0896
      },
      {
        timestamp: 1649289600,
        open: 1.08985,
        close: 1.09128,
        high: 1.09129,
        low: 1.08975
      },
      {
        timestamp: 1649293200,
        open: 1.09128,
        close: 1.09113,
        high: 1.09137,
        low: 1.09051
      },
      {
        timestamp: 1649296800,
        open: 1.09112,
        close: 1.09125,
        high: 1.09125,
        low: 1.09053
      },
      {
        timestamp: 1649300400,
        open: 1.09124,
        close: 1.09079,
        high: 1.09131,
        low: 1.0906
      },
      {
        timestamp: 1649304000,
        open: 1.0908,
        close: 1.0915,
        high: 1.09165,
        low: 1.09068
      },
      {
        timestamp: 1649307600,
        open: 1.09149,
        close: 1.09196,
        high: 1.09211,
        low: 1.09104
      },
      {
        timestamp: 1649311200,
        open: 1.09195,
        close: 1.09178,
        high: 1.0935,
        low: 1.09134
      },
      {
        timestamp: 1649314800,
        open: 1.09179,
        close: 1.08986,
        high: 1.09196,
        low: 1.08953
      },
      {
        timestamp: 1649318400,
        open: 1.08986,
        close: 1.08786,
        high: 1.09017,
        low: 1.08762
      },
      {
        timestamp: 1649322000,
        open: 1.08787,
        close: 1.08928,
        high: 1.0898,
        low: 1.08652
      },
      {
        timestamp: 1649325600,
        open: 1.08928,
        close: 1.08948,
        high: 1.08994,
        low: 1.08874
      },
      {
        timestamp: 1649329200,
        open: 1.08948,
        close: 1.09118,
        high: 1.09224,
        low: 1.08914
      },
      {
        timestamp: 1649332800,
        open: 1.09119,
        close: 1.09142,
        high: 1.09275,
        low: 1.09103
      },
      {
        timestamp: 1649336400,
        open: 1.09143,
        close: 1.09149,
        high: 1.09393,
        low: 1.09078
      },
      {
        timestamp: 1649340000,
        open: 1.0915,
        close: 1.09118,
        high: 1.09243,
        low: 1.08987
      },
      {
        timestamp: 1649343600,
        open: 1.09119,
        close: 1.08993,
        high: 1.09161,
        low: 1.0896
      },
      {
        timestamp: 1649347200,
        open: 1.08991,
        close: 1.08777,
        high: 1.09007,
        low: 1.08769
      },
      {
        timestamp: 1649350800,
        open: 1.08777,
        close: 1.08839,
        high: 1.0893,
        low: 1.08772
      },
      {
        timestamp: 1649354400,
        open: 1.08838,
        close: 1.08809,
        high: 1.08892,
        low: 1.08744
      },
      {
        timestamp: 1649358000,
        open: 1.08808,
        close: 1.08729,
        high: 1.0883,
        low: 1.08685
      },
      {
        timestamp: 1649361600,
        open: 1.08728,
        close: 1.08814,
        high: 1.0884,
        low: 1.08706
      },
      {
        timestamp: 1649365200,
        open: 1.08817,
        close: 1.08811,
        high: 1.08855,
        low: 1.08803
      },
      {
        timestamp: 1649368800,
        open: 1.08814,
        close: 1.08756,
        high: 1.08829,
        low: 1.08733
      },
      {
        timestamp: 1649372400,
        open: 1.08759,
        close: 1.08648,
        high: 1.08762,
        low: 1.08609
      },
      {
        timestamp: 1649376000,
        open: 1.08648,
        close: 1.08628,
        high: 1.08727,
        low: 1.08586
      },
      {
        timestamp: 1649379600,
        open: 1.08628,
        close: 1.08663,
        high: 1.08704,
        low: 1.08593
      },
      {
        timestamp: 1649383200,
        open: 1.08664,
        close: 1.08595,
        high: 1.08691,
        low: 1.08576
      },
      {
        timestamp: 1649386800,
        open: 1.08596,
        close: 1.08648,
        high: 1.08656,
        low: 1.08573
      },
      {
        timestamp: 1649390400,
        open: 1.08646,
        close: 1.08556,
        high: 1.08646,
        low: 1.08555
      },
      {
        timestamp: 1649394000,
        open: 1.08555,
        close: 1.08685,
        high: 1.08686,
        low: 1.08554
      },
      {
        timestamp: 1649397600,
        open: 1.08685,
        close: 1.08625,
        high: 1.087,
        low: 1.08592
      },
      {
        timestamp: 1649401200,
        open: 1.08624,
        close: 1.08681,
        high: 1.08686,
        low: 1.08487
      },
      {
        timestamp: 1649404800,
        open: 1.0868,
        close: 1.08674,
        high: 1.08799,
        low: 1.08613
      },
      {
        timestamp: 1649408400,
        open: 1.08674,
        close: 1.08698,
        high: 1.08802,
        low: 1.08652
      },
      {
        timestamp: 1649412000,
        open: 1.08699,
        close: 1.08868,
        high: 1.08924,
        low: 1.08639
      },
      {
        timestamp: 1649415600,
        open: 1.08869,
        close: 1.08643,
        high: 1.08888,
        low: 1.08594
      },
      {
        timestamp: 1649419200,
        open: 1.08641,
        close: 1.08537,
        high: 1.08671,
        low: 1.08508
      },
      {
        timestamp: 1649422800,
        open: 1.08536,
        close: 1.08407,
        high: 1.08584,
        low: 1.08371
      },
      {
        timestamp: 1649426400,
        open: 1.08408,
        close: 1.08733,
        high: 1.08824,
        low: 1.08396
      },
      {
        timestamp: 1649430000,
        open: 1.08733,
        close: 1.08744,
        high: 1.08822,
        low: 1.08704
      },
      {
        timestamp: 1649433600,
        open: 1.08743,
        close: 1.08729,
        high: 1.08775,
        low: 1.08684
      },
      {
        timestamp: 1649437200,
        open: 1.08729,
        close: 1.08725,
        high: 1.08763,
        low: 1.08657
      },
      {
        timestamp: 1649440800,
        open: 1.08724,
        close: 1.08844,
        high: 1.08859,
        low: 1.08704
      },
      {
        timestamp: 1649444400,
        open: 1.08844,
        close: 1.08788,
        high: 1.08856,
        low: 1.08757
      },
      {
        timestamp: 1649448000,
        open: 1.08789,
        close: 1.08778,
        high: 1.088,
        low: 1.08729
      },
      {
        timestamp: 1649613600,
        open: 1.09272,
        close: 1.09272,
        high: 1.09272,
        low: 1.09272
      },
      {
        timestamp: 1649617200,
        open: 1.09179,
        close: 1.09395,
        high: 1.09566,
        low: 1.09179
      },
      {
        timestamp: 1649620800,
        open: 1.09383,
        close: 1.09262,
        high: 1.09383,
        low: 1.09237
      },
      {
        timestamp: 1649624400,
        open: 1.09248,
        close: 1.0911,
        high: 1.09262,
        low: 1.09062
      },
      {
        timestamp: 1649628000,
        open: 1.09126,
        close: 1.08926,
        high: 1.09147,
        low: 1.08868
      },
      {
        timestamp: 1649631600,
        open: 1.08925,
        close: 1.08852,
        high: 1.08949,
        low: 1.0885
      },
      {
        timestamp: 1649635200,
        open: 1.08851,
        close: 1.08887,
        high: 1.08932,
        low: 1.0881
      },
      {
        timestamp: 1649638800,
        open: 1.08887,
        close: 1.08911,
        high: 1.08956,
        low: 1.08859
      },
      {
        timestamp: 1649642400,
        open: 1.0891,
        close: 1.08773,
        high: 1.08917,
        low: 1.0876
      },
      {
        timestamp: 1649646000,
        open: 1.08773,
        close: 1.08838,
        high: 1.08875,
        low: 1.0877
      },
      {
        timestamp: 1649649600,
        open: 1.08839,
        close: 1.08808,
        high: 1.08848,
        low: 1.08806
      },
      {
        timestamp: 1649653200,
        open: 1.08859,
        close: 1.08934,
        high: 1.08937,
        low: 1.08829
      },
      {
        timestamp: 1649656800,
        open: 1.08933,
        close: 1.08831,
        high: 1.08937,
        low: 1.08746
      },
      {
        timestamp: 1649660400,
        open: 1.0883,
        close: 1.09107,
        high: 1.09143,
        low: 1.08784
      },
      {
        timestamp: 1649664000,
        open: 1.09107,
        close: 1.09078,
        high: 1.09202,
        low: 1.09042
      },
      {
        timestamp: 1649667600,
        open: 1.09079,
        close: 1.0926,
        high: 1.09289,
        low: 1.09044
      },
      {
        timestamp: 1649671200,
        open: 1.0926,
        close: 1.09134,
        high: 1.09339,
        low: 1.09055
      },
      {
        timestamp: 1649674800,
        open: 1.09133,
        close: 1.09018,
        high: 1.09143,
        low: 1.08986
      },
      {
        timestamp: 1649678400,
        open: 1.0902,
        close: 1.09058,
        high: 1.09067,
        low: 1.08928
      },
      {
        timestamp: 1649682000,
        open: 1.09059,
        close: 1.0882,
        high: 1.09066,
        low: 1.08797
      },
      {
        timestamp: 1649685600,
        open: 1.08819,
        close: 1.08963,
        high: 1.08991,
        low: 1.08785
      },
      {
        timestamp: 1649689200,
        open: 1.08964,
        close: 1.08763,
        high: 1.09002,
        low: 1.08743
      },
      {
        timestamp: 1649692800,
        open: 1.08765,
        close: 1.08785,
        high: 1.08856,
        low: 1.08734
      },
      {
        timestamp: 1649696400,
        open: 1.08784,
        close: 1.08829,
        high: 1.08894,
        low: 1.08782
      },
      {
        timestamp: 1649700000,
        open: 1.0883,
        close: 1.08909,
        high: 1.08955,
        low: 1.08812
      },
      {
        timestamp: 1649703600,
        open: 1.08907,
        close: 1.08843,
        high: 1.08915,
        low: 1.08823
      },
      {
        timestamp: 1649707200,
        open: 1.08841,
        close: 1.08844,
        high: 1.0886,
        low: 1.08809
      },
      {
        timestamp: 1649710800,
        open: 1.08847,
        close: 1.08844,
        high: 1.08915,
        low: 1.08832
      },
      {
        timestamp: 1649714400,
        open: 1.08846,
        close: 1.08812,
        high: 1.0887,
        low: 1.08806
      },
      {
        timestamp: 1649718000,
        open: 1.08812,
        close: 1.08777,
        high: 1.08863,
        low: 1.08775
      },
      {
        timestamp: 1649721600,
        open: 1.08777,
        close: 1.08752,
        high: 1.0888,
        low: 1.08717
      },
      {
        timestamp: 1649725200,
        open: 1.08752,
        close: 1.08717,
        high: 1.08752,
        low: 1.0867
      },
      {
        timestamp: 1649728800,
        open: 1.08718,
        close: 1.08721,
        high: 1.08754,
        low: 1.08675
      },
      {
        timestamp: 1649732400,
        open: 1.08722,
        close: 1.08752,
        high: 1.08776,
        low: 1.08718
      },
      {
        timestamp: 1649736000,
        open: 1.08755,
        close: 1.08802,
        high: 1.0884,
        low: 1.08754
      },
      {
        timestamp: 1649739600,
        open: 1.08802,
        close: 1.08715,
        high: 1.08827,
        low: 1.08708
      },
      {
        timestamp: 1649743200,
        open: 1.08714,
        close: 1.08611,
        high: 1.0873,
        low: 1.08608
      },
      {
        timestamp: 1649746800,
        open: 1.08611,
        close: 1.08715,
        high: 1.08737,
        low: 1.08541
      },
      {
        timestamp: 1649750400,
        open: 1.08715,
        close: 1.08628,
        high: 1.08721,
        low: 1.08558
      },
      {
        timestamp: 1649754000,
        open: 1.08628,
        close: 1.08742,
        high: 1.08745,
        low: 1.08605
      },
      {
        timestamp: 1649757600,
        open: 1.08741,
        close: 1.08676,
        high: 1.08744,
        low: 1.08661
      },
      {
        timestamp: 1649761200,
        open: 1.08676,
        close: 1.0863,
        high: 1.08692,
        low: 1.08552
      },
      {
        timestamp: 1649764800,
        open: 1.0863,
        close: 1.08965,
        high: 1.09044,
        low: 1.08518
      },
      {
        timestamp: 1649768400,
        open: 1.08964,
        close: 1.08708,
        high: 1.0904,
        low: 1.08696
      },
      {
        timestamp: 1649772000,
        open: 1.08708,
        close: 1.08734,
        high: 1.08891,
        low: 1.08707
      },
      {
        timestamp: 1649775600,
        open: 1.08735,
        close: 1.08535,
        high: 1.08761,
        low: 1.0852
      },
      {
        timestamp: 1649779200,
        open: 1.08534,
        close: 1.08419,
        high: 1.08657,
        low: 1.08328
      },
      {
        timestamp: 1649782800,
        open: 1.08418,
        close: 1.08293,
        high: 1.08455,
        low: 1.08283
      },
      {
        timestamp: 1649786400,
        open: 1.08292,
        close: 1.08285,
        high: 1.08327,
        low: 1.08219
      },
      {
        timestamp: 1649790000,
        open: 1.08283,
        close: 1.08312,
        high: 1.08343,
        low: 1.08283
      },
      {
        timestamp: 1649793600,
        open: 1.08313,
        close: 1.08357,
        high: 1.08357,
        low: 1.08266
      },
      {
        timestamp: 1649797200,
        open: 1.08315,
        close: 1.08298,
        high: 1.0834,
        low: 1.08279
      },
      {
        timestamp: 1649800800,
        open: 1.08303,
        close: 1.08291,
        high: 1.08324,
        low: 1.08268
      },
      {
        timestamp: 1649804400,
        open: 1.08292,
        close: 1.08266,
        high: 1.08297,
        low: 1.0824
      },
      {
        timestamp: 1649808000,
        open: 1.08266,
        close: 1.08166,
        high: 1.083,
        low: 1.0812
      },
      {
        timestamp: 1649811600,
        open: 1.08166,
        close: 1.08307,
        high: 1.08315,
        low: 1.08151
      },
      {
        timestamp: 1649815200,
        open: 1.08307,
        close: 1.08358,
        high: 1.08394,
        low: 1.08289
      },
      {
        timestamp: 1649818800,
        open: 1.08358,
        close: 1.08381,
        high: 1.08417,
        low: 1.08335
      },
      {
        timestamp: 1649822400,
        open: 1.0838,
        close: 1.08342,
        high: 1.08396,
        low: 1.08302
      },
      {
        timestamp: 1649826000,
        open: 1.08342,
        close: 1.08443,
        high: 1.08448,
        low: 1.08302
      },
      {
        timestamp: 1649829600,
        open: 1.08444,
        close: 1.0823,
        high: 1.08456,
        low: 1.08221
      },
      {
        timestamp: 1649833200,
        open: 1.08231,
        close: 1.08389,
        high: 1.08408,
        low: 1.08158
      },
      {
        timestamp: 1649836800,
        open: 1.08388,
        close: 1.08376,
        high: 1.08396,
        low: 1.08277
      },
      {
        timestamp: 1649840400,
        open: 1.08374,
        close: 1.08355,
        high: 1.08376,
        low: 1.08258
      },
      {
        timestamp: 1649844000,
        open: 1.08356,
        close: 1.08274,
        high: 1.08362,
        low: 1.08238
      },
      {
        timestamp: 1649847600,
        open: 1.08274,
        close: 1.08317,
        high: 1.08485,
        low: 1.08266
      },
      {
        timestamp: 1649851200,
        open: 1.08317,
        close: 1.08188,
        high: 1.08355,
        low: 1.08159
      },
      {
        timestamp: 1649854800,
        open: 1.08189,
        close: 1.08176,
        high: 1.0828,
        low: 1.08096
      },
      {
        timestamp: 1649858400,
        open: 1.08177,
        close: 1.08482,
        high: 1.08495,
        low: 1.08152
      },
      {
        timestamp: 1649862000,
        open: 1.0848,
        close: 1.08722,
        high: 1.08742,
        low: 1.08458
      },
      {
        timestamp: 1649865600,
        open: 1.08723,
        close: 1.08827,
        high: 1.08951,
        low: 1.08723
      },
      {
        timestamp: 1649869200,
        open: 1.08827,
        close: 1.08756,
        high: 1.08868,
        low: 1.0874
      },
      {
        timestamp: 1649872800,
        open: 1.08754,
        close: 1.08862,
        high: 1.08879,
        low: 1.08724
      },
      {
        timestamp: 1649876400,
        open: 1.08863,
        close: 1.08836,
        high: 1.08915,
        low: 1.08833
      },
      {
        timestamp: 1649880000,
        open: 1.08836,
        close: 1.08926,
        high: 1.08939,
        low: 1.08836
      },
      {
        timestamp: 1649883600,
        open: 1.08918,
        close: 1.08921,
        high: 1.08952,
        low: 1.08909
      },
      {
        timestamp: 1649887200,
        open: 1.08917,
        close: 1.08902,
        high: 1.08948,
        low: 1.08876
      },
      {
        timestamp: 1649890800,
        open: 1.08903,
        close: 1.08958,
        high: 1.08982,
        low: 1.08901
      },
      {
        timestamp: 1649894400,
        open: 1.08958,
        close: 1.08854,
        high: 1.09006,
        low: 1.08834
      },
      {
        timestamp: 1649898000,
        open: 1.08853,
        close: 1.0907,
        high: 1.09096,
        low: 1.08835
      },
      {
        timestamp: 1649901600,
        open: 1.0907,
        close: 1.09027,
        high: 1.09089,
        low: 1.09025
      },
      {
        timestamp: 1649905200,
        open: 1.09028,
        close: 1.0908,
        high: 1.09102,
        low: 1.09027
      },
      {
        timestamp: 1649908800,
        open: 1.09081,
        close: 1.09131,
        high: 1.09137,
        low: 1.09043
      },
      {
        timestamp: 1649912400,
        open: 1.09131,
        close: 1.0911,
        high: 1.09188,
        low: 1.09101
      },
      {
        timestamp: 1649916000,
        open: 1.09109,
        close: 1.09192,
        high: 1.09241,
        low: 1.09108
      },
      {
        timestamp: 1649919600,
        open: 1.09192,
        close: 1.09091,
        high: 1.09205,
        low: 1.0904
      },
      {
        timestamp: 1649923200,
        open: 1.0909,
        close: 1.09088,
        high: 1.09133,
        low: 1.09037
      },
      {
        timestamp: 1649926800,
        open: 1.09089,
        close: 1.09146,
        high: 1.09183,
        low: 1.09023
      },
      {
        timestamp: 1649930400,
        open: 1.09145,
        close: 1.09062,
        high: 1.09179,
        low: 1.09019
      },
      {
        timestamp: 1649934000,
        open: 1.09063,
        close: 1.08661,
        high: 1.09191,
        low: 1.08639
      },
      {
        timestamp: 1649937600,
        open: 1.08663,
        close: 1.08483,
        high: 1.08915,
        low: 1.08443
      },
      {
        timestamp: 1649941200,
        open: 1.08483,
        close: 1.07718,
        high: 1.08498,
        low: 1.07581
      },
      {
        timestamp: 1649944800,
        open: 1.07718,
        close: 1.07785,
        high: 1.07992,
        low: 1.07695
      },
      {
        timestamp: 1649948400,
        open: 1.07786,
        close: 1.07981,
        high: 1.08183,
        low: 1.07744
      },
      {
        timestamp: 1649952000,
        open: 1.07981,
        close: 1.08168,
        high: 1.08323,
        low: 1.07954
      },
      {
        timestamp: 1649955600,
        open: 1.08168,
        close: 1.08248,
        high: 1.08276,
        low: 1.08096
      },
      {
        timestamp: 1649959200,
        open: 1.08248,
        close: 1.08307,
        high: 1.08318,
        low: 1.08215
      },
      {
        timestamp: 1649962800,
        open: 1.08308,
        close: 1.0825,
        high: 1.08356,
        low: 1.08244
      },
      {
        timestamp: 1649966400,
        open: 1.08249,
        close: 1.08309,
        high: 1.08352,
        low: 1.08247
      },
      {
        timestamp: 1649970000,
        open: 1.08317,
        close: 1.08305,
        high: 1.08319,
        low: 1.0829
      },
      {
        timestamp: 1649973600,
        open: 1.08301,
        close: 1.08249,
        high: 1.08301,
        low: 1.0823
      },
      {
        timestamp: 1649977200,
        open: 1.08249,
        close: 1.08223,
        high: 1.08272,
        low: 1.08155
      },
      {
        timestamp: 1649980800,
        open: 1.08229,
        close: 1.08117,
        high: 1.08249,
        low: 1.08002
      },
      {
        timestamp: 1649984400,
        open: 1.08117,
        close: 1.08135,
        high: 1.08164,
        low: 1.0808
      },
      {
        timestamp: 1649988000,
        open: 1.08135,
        close: 1.07979,
        high: 1.08152,
        low: 1.07975
      },
      {
        timestamp: 1649991600,
        open: 1.07978,
        close: 1.0806,
        high: 1.08093,
        low: 1.07973
      },
      {
        timestamp: 1649995200,
        open: 1.0806,
        close: 1.08065,
        high: 1.0808,
        low: 1.08034
      },
      {
        timestamp: 1649998800,
        open: 1.08066,
        close: 1.08068,
        high: 1.08085,
        low: 1.08052
      },
      {
        timestamp: 1650002400,
        open: 1.08068,
        close: 1.08038,
        high: 1.08087,
        low: 1.08031
      },
      {
        timestamp: 1650006000,
        open: 1.08038,
        close: 1.08129,
        high: 1.08134,
        low: 1.08038
      },
      {
        timestamp: 1650009600,
        open: 1.08128,
        close: 1.08198,
        high: 1.08243,
        low: 1.08107
      },
      {
        timestamp: 1650013200,
        open: 1.08197,
        close: 1.08149,
        high: 1.08204,
        low: 1.08137
      },
      {
        timestamp: 1650016800,
        open: 1.08148,
        close: 1.08094,
        high: 1.08185,
        low: 1.08079
      },
      {
        timestamp: 1650020400,
        open: 1.08091,
        close: 1.08166,
        high: 1.08174,
        low: 1.08058
      },
      {
        timestamp: 1650024000,
        open: 1.08166,
        close: 1.08141,
        high: 1.08204,
        low: 1.08131
      },
      {
        timestamp: 1650027600,
        open: 1.0814,
        close: 1.08135,
        high: 1.08148,
        low: 1.08076
      },
      {
        timestamp: 1650031200,
        open: 1.08135,
        close: 1.08091,
        high: 1.08136,
        low: 1.08074
      },
      {
        timestamp: 1650034800,
        open: 1.0809,
        close: 1.08094,
        high: 1.08125,
        low: 1.08066
      },
      {
        timestamp: 1650038400,
        open: 1.08093,
        close: 1.0807,
        high: 1.08123,
        low: 1.0807
      },
      {
        timestamp: 1650042000,
        open: 1.08079,
        close: 1.08107,
        high: 1.08132,
        low: 1.08066
      },
      {
        timestamp: 1650045600,
        open: 1.08117,
        close: 1.08107,
        high: 1.08123,
        low: 1.08088
      },
      {
        timestamp: 1650049200,
        open: 1.08107,
        close: 1.08108,
        high: 1.08126,
        low: 1.08068
      },
      {
        timestamp: 1650052800,
        open: 1.08108,
        close: 1.08176,
        high: 1.0819,
        low: 1.08095
      },
      {
        timestamp: 1650222000,
        open: 1.08122,
        close: 1.08032,
        high: 1.08122,
        low: 1.08018
      },
      {
        timestamp: 1650225600,
        open: 1.08032,
        close: 1.08093,
        high: 1.08104,
        low: 1.08032
      },
      {
        timestamp: 1650229200,
        open: 1.08096,
        close: 1.08098,
        high: 1.08099,
        low: 1.08084
      },
      {
        timestamp: 1650232800,
        open: 1.08103,
        close: 1.08214,
        high: 1.08222,
        low: 1.08043
      },
      {
        timestamp: 1650236400,
        open: 1.08213,
        close: 1.08149,
        high: 1.08224,
        low: 1.08134
      },
      {
        timestamp: 1650240000,
        open: 1.08149,
        close: 1.08018,
        high: 1.08166,
        low: 1.07943
      },
      {
        timestamp: 1650243600,
        open: 1.08017,
        close: 1.08075,
        high: 1.08128,
        low: 1.07965
      },
      {
        timestamp: 1650247200,
        open: 1.08075,
        close: 1.08026,
        high: 1.081,
        low: 1.07966
      },
      {
        timestamp: 1650250800,
        open: 1.08027,
        close: 1.07937,
        high: 1.08036,
        low: 1.07914
      },
      {
        timestamp: 1650254400,
        open: 1.07938,
        close: 1.07933,
        high: 1.07975,
        low: 1.07901
      },
      {
        timestamp: 1650258000,
        open: 1.07933,
        close: 1.07951,
        high: 1.07957,
        low: 1.07893
      },
      {
        timestamp: 1650261600,
        open: 1.0795,
        close: 1.07944,
        high: 1.08008,
        low: 1.07913
      },
      {
        timestamp: 1650265200,
        open: 1.07944,
        close: 1.07921,
        high: 1.08044,
        low: 1.07879
      },
      {
        timestamp: 1650268800,
        open: 1.07921,
        close: 1.07925,
        high: 1.07952,
        low: 1.07839
      },
      {
        timestamp: 1650272400,
        open: 1.07925,
        close: 1.07936,
        high: 1.07991,
        low: 1.07903
      },
      {
        timestamp: 1650276000,
        open: 1.07935,
        close: 1.08015,
        high: 1.08022,
        low: 1.07909
      },
      {
        timestamp: 1650279600,
        open: 1.08015,
        close: 1.08101,
        high: 1.08144,
        low: 1.08014
      },
      {
        timestamp: 1650283200,
        open: 1.08101,
        close: 1.08058,
        high: 1.08152,
        low: 1.07998
      },
      {
        timestamp: 1650286800,
        open: 1.08057,
        close: 1.08011,
        high: 1.08115,
        low: 1.07973
      },
      {
        timestamp: 1650290400,
        open: 1.08011,
        close: 1.07933,
        high: 1.08055,
        low: 1.07916
      },
      {
        timestamp: 1650294000,
        open: 1.07933,
        close: 1.07843,
        high: 1.07987,
        low: 1.07838
      },
      {
        timestamp: 1650297600,
        open: 1.07845,
        close: 1.07735,
        high: 1.07874,
        low: 1.07706
      },
      {
        timestamp: 1650301200,
        open: 1.07736,
        close: 1.07837,
        high: 1.07845,
        low: 1.07722
      },
      {
        timestamp: 1650304800,
        open: 1.07837,
        close: 1.07848,
        high: 1.07872,
        low: 1.07832
      },
      {
        timestamp: 1650308400,
        open: 1.07848,
        close: 1.07838,
        high: 1.0785,
        low: 1.07788
      },
      {
        timestamp: 1650312000,
        open: 1.07839,
        close: 1.07853,
        high: 1.07853,
        low: 1.07814
      },
      {
        timestamp: 1650315600,
        open: 1.07857,
        close: 1.07797,
        high: 1.07857,
        low: 1.07797
      },
      {
        timestamp: 1650319200,
        open: 1.07797,
        close: 1.0782,
        high: 1.0789,
        low: 1.07795
      },
      {
        timestamp: 1650322800,
        open: 1.07819,
        close: 1.07809,
        high: 1.07857,
        low: 1.07762
      },
      {
        timestamp: 1650326400,
        open: 1.07809,
        close: 1.07798,
        high: 1.07884,
        low: 1.07737
      },
      {
        timestamp: 1650330000,
        open: 1.07798,
        close: 1.07778,
        high: 1.07852,
        low: 1.07761
      },
      {
        timestamp: 1650333600,
        open: 1.07778,
        close: 1.07693,
        high: 1.07786,
        low: 1.07617
      },
      {
        timestamp: 1650337200,
        open: 1.07693,
        close: 1.07732,
        high: 1.0776,
        low: 1.07677
      },
      {
        timestamp: 1650340800,
        open: 1.07732,
        close: 1.07729,
        high: 1.07825,
        low: 1.07681
      },
      {
        timestamp: 1650344400,
        open: 1.07728,
        close: 1.07752,
        high: 1.07781,
        low: 1.07673
      },
      {
        timestamp: 1650348000,
        open: 1.07751,
        close: 1.07834,
        high: 1.07885,
        low: 1.07681
      },
      {
        timestamp: 1650351600,
        open: 1.07834,
        close: 1.08024,
        high: 1.08035,
        low: 1.07819
      },
      {
        timestamp: 1650355200,
        open: 1.08025,
        close: 1.08116,
        high: 1.08153,
        low: 1.07939
      },
      {
        timestamp: 1650358800,
        open: 1.08116,
        close: 1.07991,
        high: 1.08122,
        low: 1.07944
      },
      {
        timestamp: 1650362400,
        open: 1.07991,
        close: 1.07946,
        high: 1.07991,
        low: 1.07852
      },
      {
        timestamp: 1650366000,
        open: 1.07944,
        close: 1.07964,
        high: 1.07981,
        low: 1.07858
      },
      {
        timestamp: 1650369600,
        open: 1.07964,
        close: 1.07946,
        high: 1.0805,
        low: 1.07895
      },
      {
        timestamp: 1650373200,
        open: 1.07947,
        close: 1.08014,
        high: 1.08091,
        low: 1.07865
      },
      {
        timestamp: 1650376800,
        open: 1.0801,
        close: 1.07862,
        high: 1.08079,
        low: 1.07856
      },
      {
        timestamp: 1650380400,
        open: 1.07862,
        close: 1.079,
        high: 1.07969,
        low: 1.07826
      },
      {
        timestamp: 1650384000,
        open: 1.079,
        close: 1.07971,
        high: 1.07988,
        low: 1.07845
      },
      {
        timestamp: 1650387600,
        open: 1.07971,
        close: 1.07896,
        high: 1.08042,
        low: 1.07893
      },
      {
        timestamp: 1650391200,
        open: 1.07896,
        close: 1.07908,
        high: 1.07955,
        low: 1.07831
      },
      {
        timestamp: 1650394800,
        open: 1.07907,
        close: 1.07896,
        high: 1.07925,
        low: 1.07836
      },
      {
        timestamp: 1650398400,
        open: 1.07895,
        close: 1.07883,
        high: 1.07929,
        low: 1.07856
      },
      {
        timestamp: 1650402000,
        open: 1.07883,
        close: 1.07917,
        high: 1.07935,
        low: 1.0788
      },
      {
        timestamp: 1650405600,
        open: 1.07923,
        close: 1.07909,
        high: 1.07936,
        low: 1.07881
      },
      {
        timestamp: 1650409200,
        open: 1.0791,
        close: 1.0795,
        high: 1.07961,
        low: 1.0788
      },
      {
        timestamp: 1650412800,
        open: 1.07952,
        close: 1.07972,
        high: 1.07998,
        low: 1.07879
      },
      {
        timestamp: 1650416400,
        open: 1.07973,
        close: 1.07977,
        high: 1.08017,
        low: 1.07848
      },
      {
        timestamp: 1650420000,
        open: 1.07976,
        close: 1.08078,
        high: 1.08105,
        low: 1.07933
      },
      {
        timestamp: 1650423600,
        open: 1.08079,
        close: 1.08196,
        high: 1.08236,
        low: 1.08069
      },
      {
        timestamp: 1650427200,
        open: 1.08195,
        close: 1.0821,
        high: 1.08229,
        low: 1.08156
      },
      {
        timestamp: 1650430800,
        open: 1.08209,
        close: 1.08173,
        high: 1.08209,
        low: 1.08136
      },
      {
        timestamp: 1650434400,
        open: 1.08172,
        close: 1.08137,
        high: 1.08191,
        low: 1.08086
      },
      {
        timestamp: 1650438000,
        open: 1.08139,
        close: 1.081,
        high: 1.08195,
        low: 1.08077
      },
      {
        timestamp: 1650441600,
        open: 1.08101,
        close: 1.08405,
        high: 1.08419,
        low: 1.08064
      },
      {
        timestamp: 1650445200,
        open: 1.08405,
        close: 1.0855,
        high: 1.08677,
        low: 1.08303
      },
      {
        timestamp: 1650448800,
        open: 1.0855,
        close: 1.08594,
        high: 1.08664,
        low: 1.08519
      },
      {
        timestamp: 1650452400,
        open: 1.08594,
        close: 1.08285,
        high: 1.08598,
        low: 1.08257
      },
      {
        timestamp: 1650456000,
        open: 1.08284,
        close: 1.08298,
        high: 1.08337,
        low: 1.08213
      },
      {
        timestamp: 1650459600,
        open: 1.08297,
        close: 1.08355,
        high: 1.08398,
        low: 1.08226
      },
      {
        timestamp: 1650463200,
        open: 1.08354,
        close: 1.08596,
        high: 1.08648,
        low: 1.0834
      },
      {
        timestamp: 1650466800,
        open: 1.08596,
        close: 1.08554,
        high: 1.0867,
        low: 1.08473
      },
      {
        timestamp: 1650470400,
        open: 1.08554,
        close: 1.08436,
        high: 1.08606,
        low: 1.08415
      },
      {
        timestamp: 1650474000,
        open: 1.08435,
        close: 1.08524,
        high: 1.08532,
        low: 1.08405
      },
      {
        timestamp: 1650477600,
        open: 1.08524,
        close: 1.08425,
        high: 1.0858,
        low: 1.08407
      },
      {
        timestamp: 1650481200,
        open: 1.08424,
        close: 1.0854,
        high: 1.08553,
        low: 1.08414
      },
      {
        timestamp: 1650484800,
        open: 1.08541,
        close: 1.08552,
        high: 1.08563,
        low: 1.08485
      },
      {
        timestamp: 1650488400,
        open: 1.08559,
        close: 1.08536,
        high: 1.08596,
        low: 1.08504
      },
      {
        timestamp: 1650492000,
        open: 1.08537,
        close: 1.08537,
        high: 1.08566,
        low: 1.08467
      },
      {
        timestamp: 1650495600,
        open: 1.08538,
        close: 1.08502,
        high: 1.08556,
        low: 1.08478
      },
      {
        timestamp: 1650499200,
        open: 1.085,
        close: 1.08365,
        high: 1.08514,
        low: 1.08318
      },
      {
        timestamp: 1650502800,
        open: 1.08365,
        close: 1.08385,
        high: 1.08445,
        low: 1.08354
      },
      {
        timestamp: 1650506400,
        open: 1.08385,
        close: 1.08267,
        high: 1.08386,
        low: 1.08247
      },
      {
        timestamp: 1650510000,
        open: 1.08266,
        close: 1.08312,
        high: 1.08325,
        low: 1.08243
      },
      {
        timestamp: 1650513600,
        open: 1.08314,
        close: 1.08329,
        high: 1.08362,
        low: 1.08297
      },
      {
        timestamp: 1650517200,
        open: 1.0833,
        close: 1.08477,
        high: 1.08525,
        low: 1.08323
      },
      {
        timestamp: 1650520800,
        open: 1.08476,
        close: 1.09029,
        high: 1.09041,
        low: 1.08468
      },
      {
        timestamp: 1650524400,
        open: 1.09029,
        close: 1.09277,
        high: 1.09371,
        low: 1.08885
      },
      {
        timestamp: 1650528000,
        open: 1.09278,
        close: 1.09223,
        high: 1.09308,
        low: 1.09119
      },
      {
        timestamp: 1650531600,
        open: 1.09224,
        close: 1.09148,
        high: 1.09261,
        low: 1.09089
      },
      {
        timestamp: 1650535200,
        open: 1.09149,
        close: 1.0901,
        high: 1.09224,
        low: 1.09006
      },
      {
        timestamp: 1650538800,
        open: 1.09011,
        close: 1.08805,
        high: 1.09019,
        low: 1.08752
      },
      {
        timestamp: 1650542400,
        open: 1.08807,
        close: 1.08918,
        high: 1.08941,
        low: 1.08763
      },
      {
        timestamp: 1650546000,
        open: 1.08918,
        close: 1.08731,
        high: 1.08968,
        low: 1.08723
      },
      {
        timestamp: 1650549600,
        open: 1.08731,
        close: 1.08438,
        high: 1.08765,
        low: 1.08418
      },
      {
        timestamp: 1650553200,
        open: 1.08437,
        close: 1.08427,
        high: 1.08605,
        low: 1.08374
      },
      {
        timestamp: 1650556800,
        open: 1.08428,
        close: 1.08442,
        high: 1.08508,
        low: 1.08337
      },
      {
        timestamp: 1650560400,
        open: 1.08442,
        close: 1.08504,
        high: 1.08592,
        low: 1.0838
      },
      {
        timestamp: 1650564000,
        open: 1.08504,
        close: 1.0839,
        high: 1.0852,
        low: 1.08385
      },
      {
        timestamp: 1650567600,
        open: 1.08391,
        close: 1.08398,
        high: 1.08429,
        low: 1.08312
      },
      {
        timestamp: 1650571200,
        open: 1.08397,
        close: 1.08377,
        high: 1.08429,
        low: 1.08315
      },
      {
        timestamp: 1650574800,
        open: 1.08427,
        close: 1.08408,
        high: 1.08438,
        low: 1.0836
      },
      {
        timestamp: 1650578400,
        open: 1.08381,
        close: 1.08404,
        high: 1.08407,
        low: 1.08335
      },
      {
        timestamp: 1650582000,
        open: 1.08405,
        close: 1.08358,
        high: 1.0841,
        low: 1.08345
      },
      {
        timestamp: 1650585600,
        open: 1.08359,
        close: 1.08379,
        high: 1.08437,
        low: 1.08327
      },
      {
        timestamp: 1650589200,
        open: 1.08379,
        close: 1.08412,
        high: 1.08474,
        low: 1.08367
      },
      {
        timestamp: 1650592800,
        open: 1.08413,
        close: 1.08415,
        high: 1.08453,
        low: 1.08355
      },
      {
        timestamp: 1650596400,
        open: 1.08415,
        close: 1.08421,
        high: 1.08426,
        low: 1.08316
      },
      {
        timestamp: 1650600000,
        open: 1.0842,
        close: 1.08465,
        high: 1.08514,
        low: 1.08415
      },
      {
        timestamp: 1650603600,
        open: 1.08464,
        close: 1.08466,
        high: 1.08523,
        low: 1.08396
      },
      {
        timestamp: 1650607200,
        open: 1.08467,
        close: 1.08273,
        high: 1.08522,
        low: 1.08257
      },
      {
        timestamp: 1650610800,
        open: 1.08273,
        close: 1.08092,
        high: 1.08295,
        low: 1.08051
      },
      {
        timestamp: 1650614400,
        open: 1.08092,
        close: 1.0799,
        high: 1.08135,
        low: 1.07909
      },
      {
        timestamp: 1650618000,
        open: 1.0799,
        close: 1.08064,
        high: 1.08094,
        low: 1.07928
      },
      {
        timestamp: 1650621600,
        open: 1.08065,
        close: 1.08166,
        high: 1.08166,
        low: 1.08002
      },
      {
        timestamp: 1650625200,
        open: 1.08165,
        close: 1.08219,
        high: 1.08259,
        low: 1.08149
      },
      {
        timestamp: 1650628800,
        open: 1.08218,
        close: 1.08202,
        high: 1.08241,
        low: 1.08157
      },
      {
        timestamp: 1650632400,
        open: 1.082,
        close: 1.08176,
        high: 1.08456,
        low: 1.08094
      },
      {
        timestamp: 1650636000,
        open: 1.08176,
        close: 1.0786,
        high: 1.08183,
        low: 1.0784
      },
      {
        timestamp: 1650639600,
        open: 1.07862,
        close: 1.07884,
        high: 1.07929,
        low: 1.07734
      },
      {
        timestamp: 1650643200,
        open: 1.07885,
        close: 1.07803,
        high: 1.07927,
        low: 1.07769
      },
      {
        timestamp: 1650646800,
        open: 1.07802,
        close: 1.07885,
        high: 1.07958,
        low: 1.0779
      },
      {
        timestamp: 1650650400,
        open: 1.07884,
        close: 1.07868,
        high: 1.07958,
        low: 1.07854
      },
      {
        timestamp: 1650654000,
        open: 1.07868,
        close: 1.07937,
        high: 1.08012,
        low: 1.07866
      },
      {
        timestamp: 1650657600,
        open: 1.07936,
        close: 1.08007,
        high: 1.08037,
        low: 1.07918
      },
      {
        timestamp: 1650823200,
        open: 1.08532,
        close: 1.08531,
        high: 1.08532,
        low: 1.08531
      },
      {
        timestamp: 1650826800,
        open: 1.08557,
        close: 1.08382,
        high: 1.08557,
        low: 1.08324
      },
      {
        timestamp: 1650830400,
        open: 1.08374,
        close: 1.0809,
        high: 1.08374,
        low: 1.08088
      },
      {
        timestamp: 1650834000,
        open: 1.08167,
        close: 1.08084,
        high: 1.08169,
        low: 1.07992
      },
      {
        timestamp: 1650837600,
        open: 1.0809,
        close: 1.08073,
        high: 1.08173,
        low: 1.07968
      },
      {
        timestamp: 1650841200,
        open: 1.08073,
        close: 1.08102,
        high: 1.0813,
        low: 1.0805
      },
      {
        timestamp: 1650844800,
        open: 1.08102,
        close: 1.08028,
        high: 1.08128,
        low: 1.08007
      },
      {
        timestamp: 1650848400,
        open: 1.08028,
        close: 1.07878,
        high: 1.08052,
        low: 1.07844
      },
      {
        timestamp: 1650852000,
        open: 1.07878,
        close: 1.0782,
        high: 1.07896,
        low: 1.07781
      },
      {
        timestamp: 1650855600,
        open: 1.07821,
        close: 1.0782,
        high: 1.07891,
        low: 1.07807
      },
      {
        timestamp: 1650859200,
        open: 1.07821,
        close: 1.07749,
        high: 1.07861,
        low: 1.07729
      },
      {
        timestamp: 1650862800,
        open: 1.07749,
        close: 1.07763,
        high: 1.07795,
        low: 1.07689
      },
      {
        timestamp: 1650866400,
        open: 1.07763,
        close: 1.07152,
        high: 1.07786,
        low: 1.07075
      },
      {
        timestamp: 1650870000,
        open: 1.07153,
        close: 1.07392,
        high: 1.07418,
        low: 1.07142
      },
      {
        timestamp: 1650873600,
        open: 1.07391,
        close: 1.07456,
        high: 1.07552,
        low: 1.07344
      },
      {
        timestamp: 1650877200,
        open: 1.07456,
        close: 1.07317,
        high: 1.07497,
        low: 1.07309
      },
      {
        timestamp: 1650880800,
        open: 1.07317,
        close: 1.07249,
        high: 1.07373,
        low: 1.07204
      },
      {
        timestamp: 1650884400,
        open: 1.07249,
        close: 1.07502,
        high: 1.0753,
        low: 1.07207
      },
      {
        timestamp: 1650888000,
        open: 1.07502,
        close: 1.07435,
        high: 1.0759,
        low: 1.07327
      },
      {
        timestamp: 1650891600,
        open: 1.07436,
        close: 1.07238,
        high: 1.07443,
        low: 1.07164
      },
      {
        timestamp: 1650895200,
        open: 1.07235,
        close: 1.07079,
        high: 1.07296,
        low: 1.06976
      },
      {
        timestamp: 1650898800,
        open: 1.0708,
        close: 1.07037,
        high: 1.07172,
        low: 1.06994
      },
      {
        timestamp: 1650902400,
        open: 1.07038,
        close: 1.07159,
        high: 1.07193,
        low: 1.06975
      },
      {
        timestamp: 1650906000,
        open: 1.07159,
        close: 1.07137,
        high: 1.07192,
        low: 1.07094
      },
      {
        timestamp: 1650909600,
        open: 1.07137,
        close: 1.07104,
        high: 1.07197,
        low: 1.07102
      },
      {
        timestamp: 1650913200,
        open: 1.07103,
        close: 1.07142,
        high: 1.0719,
        low: 1.07052
      },
      {
        timestamp: 1650916800,
        open: 1.07142,
        close: 1.07177,
        high: 1.07177,
        low: 1.07099
      },
      {
        timestamp: 1650920400,
        open: 1.07155,
        close: 1.07145,
        high: 1.07162,
        low: 1.07137
      },
      {
        timestamp: 1650924000,
        open: 1.0715,
        close: 1.07139,
        high: 1.07157,
        low: 1.07104
      },
      {
        timestamp: 1650927600,
        open: 1.07138,
        close: 1.07132,
        high: 1.07153,
        low: 1.07077
      },
      {
        timestamp: 1650931200,
        open: 1.07132,
        close: 1.0731,
        high: 1.07321,
        low: 1.07122
      },
      {
        timestamp: 1650934800,
        open: 1.0731,
        close: 1.07257,
        high: 1.07344,
        low: 1.07212
      },
      {
        timestamp: 1650938400,
        open: 1.07258,
        close: 1.07305,
        high: 1.07324,
        low: 1.07246
      },
      {
        timestamp: 1650942000,
        open: 1.07306,
        close: 1.07328,
        high: 1.07394,
        low: 1.07303
      },
      {
        timestamp: 1650945600,
        open: 1.07331,
        close: 1.07287,
        high: 1.07341,
        low: 1.07271
      },
      {
        timestamp: 1650949200,
        open: 1.07287,
        close: 1.07322,
        high: 1.07342,
        low: 1.07233
      },
      {
        timestamp: 1650952800,
        open: 1.07321,
        close: 1.07045,
        high: 1.07352,
        low: 1.07012
      },
      {
        timestamp: 1650956400,
        open: 1.07044,
        close: 1.06769,
        high: 1.07118,
        low: 1.0676
      },
      {
        timestamp: 1650960000,
        open: 1.06771,
        close: 1.06818,
        high: 1.0693,
        low: 1.06733
      },
      {
        timestamp: 1650963600,
        open: 1.06818,
        close: 1.06873,
        high: 1.06977,
        low: 1.06815
      },
      {
        timestamp: 1650967200,
        open: 1.06873,
        close: 1.06902,
        high: 1.06981,
        low: 1.06796
      },
      {
        timestamp: 1650970800,
        open: 1.06902,
        close: 1.06713,
        high: 1.06946,
        low: 1.06698
      },
      {
        timestamp: 1650974400,
        open: 1.06714,
        close: 1.06795,
        high: 1.06845,
        low: 1.06708
      },
      {
        timestamp: 1650978000,
        open: 1.06795,
        close: 1.06754,
        high: 1.0686,
        low: 1.06644
      },
      {
        timestamp: 1650981600,
        open: 1.06752,
        close: 1.06516,
        high: 1.06782,
        low: 1.06424
      },
      {
        timestamp: 1650985200,
        open: 1.06515,
        close: 1.066,
        high: 1.06643,
        low: 1.0645
      },
      {
        timestamp: 1650988800,
        open: 1.066,
        close: 1.06598,
        high: 1.06658,
        low: 1.06545
      },
      {
        timestamp: 1650992400,
        open: 1.06597,
        close: 1.06473,
        high: 1.06622,
        low: 1.06463
      },
      {
        timestamp: 1650996000,
        open: 1.06472,
        close: 1.06455,
        high: 1.0652,
        low: 1.06412
      },
      {
        timestamp: 1650999600,
        open: 1.06456,
        close: 1.06445,
        high: 1.06483,
        low: 1.06389
      },
      {
        timestamp: 1651003200,
        open: 1.06446,
        close: 1.06392,
        high: 1.06446,
        low: 1.06365
      },
      {
        timestamp: 1651006800,
        open: 1.06387,
        close: 1.06413,
        high: 1.06426,
        low: 1.06368
      },
      {
        timestamp: 1651010400,
        open: 1.06414,
        close: 1.06457,
        high: 1.06465,
        low: 1.06358
      },
      {
        timestamp: 1651014000,
        open: 1.06457,
        close: 1.06434,
        high: 1.06487,
        low: 1.0643
      },
      {
        timestamp: 1651017600,
        open: 1.06435,
        close: 1.06443,
        high: 1.06476,
        low: 1.06356
      },
      {
        timestamp: 1651021200,
        open: 1.06444,
        close: 1.06474,
        high: 1.06476,
        low: 1.06366
      },
      {
        timestamp: 1651024800,
        open: 1.06474,
        close: 1.06351,
        high: 1.06542,
        low: 1.06349
      },
      {
        timestamp: 1651028400,
        open: 1.06352,
        close: 1.06414,
        high: 1.06447,
        low: 1.06344
      },
      {
        timestamp: 1651032000,
        open: 1.06412,
        close: 1.06504,
        high: 1.06511,
        low: 1.06384
      },
      {
        timestamp: 1651035600,
        open: 1.06505,
        close: 1.06513,
        high: 1.06553,
        low: 1.06482
      },
      {
        timestamp: 1651039200,
        open: 1.06512,
        close: 1.06208,
        high: 1.06525,
        low: 1.0617
      },
      {
        timestamp: 1651042800,
        open: 1.06209,
        close: 1.06239,
        high: 1.06294,
        low: 1.05887
      },
      {
        timestamp: 1651046400,
        open: 1.06239,
        close: 1.05894,
        high: 1.06257,
        low: 1.05862
      },
      {
        timestamp: 1651050000,
        open: 1.05893,
        close: 1.06139,
        high: 1.06217,
        low: 1.0587
      },
      {
        timestamp: 1651053600,
        open: 1.06139,
        close: 1.06112,
        high: 1.06232,
        low: 1.06041
      },
      {
        timestamp: 1651057200,
        open: 1.06111,
        close: 1.05853,
        high: 1.06116,
        low: 1.05815
      },
      {
        timestamp: 1651060800,
        open: 1.05853,
        close: 1.05559,
        high: 1.05867,
        low: 1.05458
      },
      {
        timestamp: 1651064400,
        open: 1.0556,
        close: 1.05491,
        high: 1.05618,
        low: 1.05342
      },
      {
        timestamp: 1651068000,
        open: 1.05491,
        close: 1.0532,
        high: 1.05545,
        low: 1.05258
      },
      {
        timestamp: 1651071600,
        open: 1.0532,
        close: 1.0546,
        high: 1.05548,
        low: 1.05233
      },
      {
        timestamp: 1651075200,
        open: 1.05459,
        close: 1.05635,
        high: 1.0572,
        low: 1.05459
      },
      {
        timestamp: 1651078800,
        open: 1.05636,
        close: 1.05711,
        high: 1.05729,
        low: 1.0559
      },
      {
        timestamp: 1651082400,
        open: 1.05711,
        close: 1.05614,
        high: 1.0573,
        low: 1.05565
      },
      {
        timestamp: 1651086000,
        open: 1.05613,
        close: 1.0559,
        high: 1.05629,
        low: 1.05542
      },
      {
        timestamp: 1651089600,
        open: 1.0559,
        close: 1.056,
        high: 1.05616,
        low: 1.05563
      },
      {
        timestamp: 1651093200,
        open: 1.056,
        close: 1.05574,
        high: 1.05671,
        low: 1.05543
      },
      {
        timestamp: 1651096800,
        open: 1.05575,
        close: 1.05517,
        high: 1.0559,
        low: 1.05508
      },
      {
        timestamp: 1651100400,
        open: 1.05516,
        close: 1.05549,
        high: 1.05571,
        low: 1.05502
      },
      {
        timestamp: 1651104000,
        open: 1.05548,
        close: 1.05392,
        high: 1.05548,
        low: 1.05313
      },
      {
        timestamp: 1651107600,
        open: 1.05392,
        close: 1.05477,
        high: 1.05543,
        low: 1.05358
      },
      {
        timestamp: 1651111200,
        open: 1.05477,
        close: 1.05376,
        high: 1.05483,
        low: 1.05336
      },
      {
        timestamp: 1651114800,
        open: 1.05376,
        close: 1.05098,
        high: 1.05414,
        low: 1.05097
      },
      {
        timestamp: 1651118400,
        open: 1.05099,
        close: 1.05182,
        high: 1.05274,
        low: 1.05095
      },
      {
        timestamp: 1651122000,
        open: 1.05182,
        close: 1.05131,
        high: 1.05267,
        low: 1.04818
      },
      {
        timestamp: 1651125600,
        open: 1.05131,
        close: 1.05255,
        high: 1.05316,
        low: 1.05073
      },
      {
        timestamp: 1651129200,
        open: 1.05251,
        close: 1.05526,
        high: 1.05586,
        low: 1.05158
      },
      {
        timestamp: 1651132800,
        open: 1.05526,
        close: 1.05168,
        high: 1.05657,
        low: 1.05155
      },
      {
        timestamp: 1651136400,
        open: 1.05169,
        close: 1.05211,
        high: 1.05396,
        low: 1.05145
      },
      {
        timestamp: 1651140000,
        open: 1.0521,
        close: 1.04985,
        high: 1.05304,
        low: 1.04974
      },
      {
        timestamp: 1651143600,
        open: 1.04984,
        close: 1.04912,
        high: 1.05104,
        low: 1.0487
      },
      {
        timestamp: 1651147200,
        open: 1.04911,
        close: 1.04937,
        high: 1.05248,
        low: 1.04719
      },
      {
        timestamp: 1651150800,
        open: 1.04936,
        close: 1.05094,
        high: 1.05138,
        low: 1.04822
      },
      {
        timestamp: 1651154400,
        open: 1.05093,
        close: 1.05138,
        high: 1.05145,
        low: 1.04886
      },
      {
        timestamp: 1651158000,
        open: 1.05138,
        close: 1.05006,
        high: 1.0532,
        low: 1.04977
      },
      {
        timestamp: 1651161600,
        open: 1.05006,
        close: 1.05131,
        high: 1.05159,
        low: 1.04942
      },
      {
        timestamp: 1651165200,
        open: 1.05132,
        close: 1.05002,
        high: 1.05164,
        low: 1.04979
      },
      {
        timestamp: 1651168800,
        open: 1.05002,
        close: 1.05058,
        high: 1.0511,
        low: 1.04984
      },
      {
        timestamp: 1651172400,
        open: 1.05059,
        close: 1.05105,
        high: 1.05116,
        low: 1.0504
      },
      {
        timestamp: 1651176000,
        open: 1.05106,
        close: 1.05003,
        high: 1.05107,
        low: 1.04987
      },
      {
        timestamp: 1651179600,
        open: 1.05002,
        close: 1.05019,
        high: 1.05085,
        low: 1.04994
      },
      {
        timestamp: 1651183200,
        open: 1.05019,
        close: 1.04977,
        high: 1.05033,
        low: 1.04922
      },
      {
        timestamp: 1651186800,
        open: 1.04976,
        close: 1.05037,
        high: 1.05043,
        low: 1.04957
      },
      {
        timestamp: 1651190400,
        open: 1.05039,
        close: 1.05164,
        high: 1.05181,
        low: 1.05028
      },
      {
        timestamp: 1651194000,
        open: 1.05164,
        close: 1.05196,
        high: 1.05305,
        low: 1.05155
      },
      {
        timestamp: 1651197600,
        open: 1.05198,
        close: 1.05111,
        high: 1.05239,
        low: 1.05058
      },
      {
        timestamp: 1651201200,
        open: 1.05112,
        close: 1.05141,
        high: 1.05202,
        low: 1.05074
      },
      {
        timestamp: 1651204800,
        open: 1.05141,
        close: 1.05173,
        high: 1.05216,
        low: 1.05095
      },
      {
        timestamp: 1651208400,
        open: 1.05173,
        close: 1.0538,
        high: 1.05428,
        low: 1.05173
      },
      {
        timestamp: 1651212000,
        open: 1.05379,
        close: 1.05445,
        high: 1.05494,
        low: 1.05318
      },
      {
        timestamp: 1651215600,
        open: 1.05444,
        close: 1.05626,
        high: 1.05795,
        low: 1.05366
      },
      {
        timestamp: 1651219200,
        open: 1.05626,
        close: 1.05698,
        high: 1.05936,
        low: 1.05588
      },
      {
        timestamp: 1651222800,
        open: 1.05699,
        close: 1.05788,
        high: 1.05809,
        low: 1.05665
      },
      {
        timestamp: 1651226400,
        open: 1.05788,
        close: 1.05653,
        high: 1.0579,
        low: 1.05622
      },
      {
        timestamp: 1651230000,
        open: 1.05653,
        close: 1.05452,
        high: 1.058,
        low: 1.05404
      },
      {
        timestamp: 1651233600,
        open: 1.05452,
        close: 1.05325,
        high: 1.05478,
        low: 1.05103
      },
      {
        timestamp: 1651237200,
        open: 1.05325,
        close: 1.05292,
        high: 1.05421,
        low: 1.05229
      },
      {
        timestamp: 1651240800,
        open: 1.05293,
        close: 1.05437,
        high: 1.05479,
        low: 1.05214
      },
      {
        timestamp: 1651244400,
        open: 1.05436,
        close: 1.0544,
        high: 1.05551,
        low: 1.05334
      },
      {
        timestamp: 1651248000,
        open: 1.0544,
        close: 1.05465,
        high: 1.05491,
        low: 1.05331
      },
      {
        timestamp: 1651251600,
        open: 1.05466,
        close: 1.05734,
        high: 1.05795,
        low: 1.05447
      },
      {
        timestamp: 1651255200,
        open: 1.05734,
        close: 1.05705,
        high: 1.05755,
        low: 1.05642
      },
      {
        timestamp: 1651258800,
        open: 1.05705,
        close: 1.05486,
        high: 1.05715,
        low: 1.0546
      },
      {
        timestamp: 1651262400,
        open: 1.05485,
        close: 1.05502,
        high: 1.05528,
        low: 1.05428
      },
      {
        timestamp: 1651438800,
        open: 1.05653,
        close: 1.05494,
        high: 1.05653,
        low: 1.05459
      },
      {
        timestamp: 1651442400,
        open: 1.05493,
        close: 1.0544,
        high: 1.05534,
        low: 1.05377
      },
      {
        timestamp: 1651446000,
        open: 1.05439,
        close: 1.05357,
        high: 1.05499,
        low: 1.0535
      },
      {
        timestamp: 1651449600,
        open: 1.05357,
        close: 1.05302,
        high: 1.05538,
        low: 1.05274
      },
      {
        timestamp: 1651453200,
        open: 1.05302,
        close: 1.05251,
        high: 1.0534,
        low: 1.05222
      },
      {
        timestamp: 1651456800,
        open: 1.05252,
        close: 1.05218,
        high: 1.05283,
        low: 1.05188
      },
      {
        timestamp: 1651460400,
        open: 1.05218,
        close: 1.05249,
        high: 1.05281,
        low: 1.05181
      },
      {
        timestamp: 1651464000,
        open: 1.05248,
        close: 1.05193,
        high: 1.05251,
        low: 1.05177
      },
      {
        timestamp: 1651467600,
        open: 1.05193,
        close: 1.05141,
        high: 1.0522,
        low: 1.05111
      },
      {
        timestamp: 1651471200,
        open: 1.05141,
        close: 1.05492,
        high: 1.05526,
        low: 1.05108
      },
      {
        timestamp: 1651474800,
        open: 1.05493,
        close: 1.05333,
        high: 1.05694,
        low: 1.05327
      },
      {
        timestamp: 1651478400,
        open: 1.05335,
        close: 1.05297,
        high: 1.0537,
        low: 1.05262
      },
      {
        timestamp: 1651482000,
        open: 1.05297,
        close: 1.05275,
        high: 1.05337,
        low: 1.05195
      },
      {
        timestamp: 1651485600,
        open: 1.05275,
        close: 1.05271,
        high: 1.05299,
        low: 1.05206
      },
      {
        timestamp: 1651489200,
        open: 1.0527,
        close: 1.05173,
        high: 1.05285,
        low: 1.05144
      },
      {
        timestamp: 1651492800,
        open: 1.05172,
        close: 1.05112,
        high: 1.05334,
        low: 1.05091
      },
      {
        timestamp: 1651496400,
        open: 1.05112,
        close: 1.05248,
        high: 1.05286,
        low: 1.05035
      },
      {
        timestamp: 1651500000,
        open: 1.05248,
        close: 1.05232,
        high: 1.05424,
        low: 1.05156
      },
      {
        timestamp: 1651503600,
        open: 1.05231,
        close: 1.05221,
        high: 1.05246,
        low: 1.05045
      },
      {
        timestamp: 1651507200,
        open: 1.05221,
        close: 1.05096,
        high: 1.0527,
        low: 1.0503
      },
      {
        timestamp: 1651510800,
        open: 1.05097,
        close: 1.04938,
        high: 1.05115,
        low: 1.04935
      },
      {
        timestamp: 1651514400,
        open: 1.04938,
        close: 1.04927,
        high: 1.05003,
        low: 1.04909
      },
      {
        timestamp: 1651518000,
        open: 1.04927,
        close: 1.05048,
        high: 1.05124,
        low: 1.04922
      },
      {
        timestamp: 1651521600,
        open: 1.05048,
        close: 1.05117,
        high: 1.05117,
        low: 1.05023
      },
      {
        timestamp: 1651525200,
        open: 1.0511,
        close: 1.05075,
        high: 1.05125,
        low: 1.05064
      },
      {
        timestamp: 1651528800,
        open: 1.05075,
        close: 1.05107,
        high: 1.05143,
        low: 1.05066
      },
      {
        timestamp: 1651532400,
        open: 1.05109,
        close: 1.05103,
        high: 1.05115,
        low: 1.05065
      },
      {
        timestamp: 1651536000,
        open: 1.05104,
        close: 1.05259,
        high: 1.05266,
        low: 1.05093
      },
      {
        timestamp: 1651539600,
        open: 1.0526,
        close: 1.0523,
        high: 1.05274,
        low: 1.05156
      },
      {
        timestamp: 1651543200,
        open: 1.0523,
        close: 1.05143,
        high: 1.05265,
        low: 1.05105
      },
      {
        timestamp: 1651546800,
        open: 1.05144,
        close: 1.05191,
        high: 1.05202,
        low: 1.05085
      },
      {
        timestamp: 1651550400,
        open: 1.0519,
        close: 1.05025,
        high: 1.05249,
        low: 1.05025
      },
      {
        timestamp: 1651554000,
        open: 1.05025,
        close: 1.05083,
        high: 1.05115,
        low: 1.04986
      },
      {
        timestamp: 1651557600,
        open: 1.05084,
        close: 1.04984,
        high: 1.0513,
        low: 1.0497
      },
      {
        timestamp: 1651561200,
        open: 1.04984,
        close: 1.05193,
        high: 1.05285,
        low: 1.04951
      },
      {
        timestamp: 1651564800,
        open: 1.05193,
        close: 1.05009,
        high: 1.05264,
        low: 1.05009
      },
      {
        timestamp: 1651568400,
        open: 1.05009,
        close: 1.05143,
        high: 1.05147,
        low: 1.04929
      },
      {
        timestamp: 1651572000,
        open: 1.05142,
        close: 1.05048,
        high: 1.05238,
        low: 1.05025
      },
      {
        timestamp: 1651575600,
        open: 1.05049,
        close: 1.05385,
        high: 1.05428,
        low: 1.04996
      },
      {
        timestamp: 1651579200,
        open: 1.05385,
        close: 1.05682,
        high: 1.05785,
        low: 1.05379
      },
      {
        timestamp: 1651582800,
        open: 1.05682,
        close: 1.05565,
        high: 1.0573,
        low: 1.05493
      },
      {
        timestamp: 1651586400,
        open: 1.05567,
        close: 1.05388,
        high: 1.05655,
        low: 1.05358
      },
      {
        timestamp: 1651590000,
        open: 1.05389,
        close: 1.05326,
        high: 1.05489,
        low: 1.05321
      },
      {
        timestamp: 1651593600,
        open: 1.05326,
        close: 1.05163,
        high: 1.05327,
        low: 1.05122
      },
      {
        timestamp: 1651597200,
        open: 1.05162,
        close: 1.05298,
        high: 1.05412,
        low: 1.05151
      },
      {
        timestamp: 1651600800,
        open: 1.05299,
        close: 1.05262,
        high: 1.05311,
        low: 1.05133
      },
      {
        timestamp: 1651604400,
        open: 1.05262,
        close: 1.05258,
        high: 1.05287,
        low: 1.05195
      },
      {
        timestamp: 1651608000,
        open: 1.05259,
        close: 1.05245,
        high: 1.05275,
        low: 1.052
      },
      {
        timestamp: 1651611600,
        open: 1.05252,
        close: 1.05283,
        high: 1.05288,
        low: 1.05224
      },
      {
        timestamp: 1651615200,
        open: 1.05283,
        close: 1.05253,
        high: 1.05289,
        low: 1.05226
      },
      {
        timestamp: 1651618800,
        open: 1.05254,
        close: 1.05257,
        high: 1.05269,
        low: 1.05201
      },
      {
        timestamp: 1651622400,
        open: 1.05257,
        close: 1.05256,
        high: 1.05348,
        low: 1.05208
      },
      {
        timestamp: 1651626000,
        open: 1.05256,
        close: 1.05301,
        high: 1.0534,
        low: 1.05192
      },
      {
        timestamp: 1651629600,
        open: 1.05301,
        close: 1.05208,
        high: 1.05316,
        low: 1.05202
      },
      {
        timestamp: 1651633200,
        open: 1.05207,
        close: 1.0517,
        high: 1.05243,
        low: 1.05148
      },
      {
        timestamp: 1651636800,
        open: 1.0517,
        close: 1.05174,
        high: 1.0521,
        low: 1.05155
      },
      {
        timestamp: 1651640400,
        open: 1.05174,
        close: 1.05131,
        high: 1.05191,
        low: 1.0512
      },
      {
        timestamp: 1651644000,
        open: 1.05131,
        close: 1.05144,
        high: 1.05244,
        low: 1.05067
      },
      {
        timestamp: 1651647600,
        open: 1.05143,
        close: 1.05248,
        high: 1.05291,
        low: 1.05083
      },
      {
        timestamp: 1651651200,
        open: 1.05249,
        close: 1.05228,
        high: 1.05352,
        low: 1.05177
      },
      {
        timestamp: 1651654800,
        open: 1.05228,
        close: 1.05243,
        high: 1.05346,
        low: 1.05153
      },
      {
        timestamp: 1651658400,
        open: 1.05243,
        close: 1.05304,
        high: 1.05319,
        low: 1.05215
      },
      {
        timestamp: 1651662000,
        open: 1.05305,
        close: 1.05313,
        high: 1.05371,
        low: 1.0524
      },
      {
        timestamp: 1651665600,
        open: 1.05314,
        close: 1.0554,
        high: 1.05562,
        low: 1.05262
      },
      {
        timestamp: 1651669200,
        open: 1.05538,
        close: 1.0533,
        high: 1.05563,
        low: 1.05327
      },
      {
        timestamp: 1651672800,
        open: 1.05331,
        close: 1.0547,
        high: 1.05515,
        low: 1.05253
      },
      {
        timestamp: 1651676400,
        open: 1.0547,
        close: 1.05409,
        high: 1.05651,
        low: 1.05373
      },
      {
        timestamp: 1651680000,
        open: 1.05409,
        close: 1.05494,
        high: 1.05495,
        low: 1.05349
      },
      {
        timestamp: 1651683600,
        open: 1.05495,
        close: 1.05476,
        high: 1.05535,
        low: 1.05349
      },
      {
        timestamp: 1651687200,
        open: 1.05477,
        close: 1.05923,
        high: 1.05956,
        low: 1.05355
      },
      {
        timestamp: 1651690800,
        open: 1.06069,
        close: 1.05978,
        high: 1.06187,
        low: 1.05826
      },
      {
        timestamp: 1651694400,
        open: 1.05978,
        close: 1.06266,
        high: 1.063,
        low: 1.05974
      },
      {
        timestamp: 1651698000,
        open: 1.06271,
        close: 1.06256,
        high: 1.06289,
        low: 1.06253
      },
      {
        timestamp: 1651701600,
        open: 1.06256,
        close: 1.06066,
        high: 1.06263,
        low: 1.06054
      },
      {
        timestamp: 1651705200,
        open: 1.06066,
        close: 1.06213,
        high: 1.06235,
        low: 1.06044
      },
      {
        timestamp: 1651708800,
        open: 1.06215,
        close: 1.06143,
        high: 1.06284,
        low: 1.06049
      },
      {
        timestamp: 1651712400,
        open: 1.06144,
        close: 1.06264,
        high: 1.06424,
        low: 1.06112
      },
      {
        timestamp: 1651716000,
        open: 1.06264,
        close: 1.06239,
        high: 1.0631,
        low: 1.06149
      },
      {
        timestamp: 1651719600,
        open: 1.06238,
        close: 1.06184,
        high: 1.06289,
        low: 1.06158
      },
      {
        timestamp: 1651723200,
        open: 1.06185,
        close: 1.06132,
        high: 1.06187,
        low: 1.06104
      },
      {
        timestamp: 1651726800,
        open: 1.06132,
        close: 1.0613,
        high: 1.062,
        low: 1.06067
      },
      {
        timestamp: 1651730400,
        open: 1.0613,
        close: 1.05954,
        high: 1.06182,
        low: 1.05944
      },
      {
        timestamp: 1651734000,
        open: 1.05955,
        close: 1.05984,
        high: 1.06074,
        low: 1.05853
      },
      {
        timestamp: 1651737600,
        open: 1.05985,
        close: 1.0601,
        high: 1.06013,
        low: 1.05823
      },
      {
        timestamp: 1651741200,
        open: 1.06009,
        close: 1.05984,
        high: 1.06038,
        low: 1.05927
      },
      {
        timestamp: 1651744800,
        open: 1.05983,
        close: 1.05982,
        high: 1.06084,
        low: 1.05874
      },
      {
        timestamp: 1651748400,
        open: 1.0598,
        close: 1.05485,
        high: 1.06043,
        low: 1.05462
      },
      {
        timestamp: 1651752000,
        open: 1.05484,
        close: 1.0554,
        high: 1.05744,
        low: 1.05482
      },
      {
        timestamp: 1651755600,
        open: 1.0554,
        close: 1.05495,
        high: 1.05666,
        low: 1.05427
      },
      {
        timestamp: 1651759200,
        open: 1.05492,
        close: 1.05323,
        high: 1.05572,
        low: 1.05299
      },
      {
        timestamp: 1651762800,
        open: 1.05324,
        close: 1.05074,
        high: 1.05345,
        low: 1.0504
      },
      {
        timestamp: 1651766400,
        open: 1.05075,
        close: 1.05116,
        high: 1.05159,
        low: 1.04932
      },
      {
        timestamp: 1651770000,
        open: 1.05116,
        close: 1.05047,
        high: 1.0529,
        low: 1.05041
      },
      {
        timestamp: 1651773600,
        open: 1.05047,
        close: 1.05169,
        high: 1.05214,
        low: 1.0503
      },
      {
        timestamp: 1651777200,
        open: 1.0517,
        close: 1.05507,
        high: 1.05515,
        low: 1.0517
      },
      {
        timestamp: 1651780800,
        open: 1.05508,
        close: 1.05464,
        high: 1.0551,
        low: 1.05384
      },
      {
        timestamp: 1651784400,
        open: 1.05465,
        close: 1.05421,
        high: 1.05498,
        low: 1.05405
      },
      {
        timestamp: 1651788000,
        open: 1.05421,
        close: 1.05512,
        high: 1.05512,
        low: 1.05397
      },
      {
        timestamp: 1651791600,
        open: 1.05511,
        close: 1.05403,
        high: 1.05511,
        low: 1.05363
      },
      {
        timestamp: 1651795200,
        open: 1.05404,
        close: 1.05418,
        high: 1.05514,
        low: 1.05345
      },
      {
        timestamp: 1651798800,
        open: 1.05419,
        close: 1.05265,
        high: 1.05462,
        low: 1.05165
      },
      {
        timestamp: 1651802400,
        open: 1.05266,
        close: 1.05295,
        high: 1.05347,
        low: 1.05203
      },
      {
        timestamp: 1651806000,
        open: 1.05295,
        close: 1.05338,
        high: 1.05403,
        low: 1.05273
      },
      {
        timestamp: 1651809600,
        open: 1.05337,
        close: 1.0533,
        high: 1.05355,
        low: 1.05266
      },
      {
        timestamp: 1651813200,
        open: 1.05332,
        close: 1.05234,
        high: 1.05346,
        low: 1.05221
      },
      {
        timestamp: 1651816800,
        open: 1.05235,
        close: 1.04869,
        high: 1.05261,
        low: 1.04837
      },
      {
        timestamp: 1651820400,
        open: 1.0487,
        close: 1.05332,
        high: 1.05446,
        low: 1.04836
      },
      {
        timestamp: 1651824000,
        open: 1.05332,
        close: 1.05581,
        high: 1.05817,
        low: 1.05132
      },
      {
        timestamp: 1651827600,
        open: 1.05582,
        close: 1.05716,
        high: 1.05765,
        low: 1.05519
      },
      {
        timestamp: 1651831200,
        open: 1.05713,
        close: 1.05897,
        high: 1.05994,
        low: 1.05695
      },
      {
        timestamp: 1651834800,
        open: 1.05897,
        close: 1.05666,
        high: 1.05918,
        low: 1.05604
      },
      {
        timestamp: 1651838400,
        open: 1.05666,
        close: 1.0559,
        high: 1.0598,
        low: 1.05569
      },
      {
        timestamp: 1651842000,
        open: 1.0559,
        close: 1.05848,
        high: 1.05969,
        low: 1.05482
      },
      {
        timestamp: 1651845600,
        open: 1.05848,
        close: 1.05798,
        high: 1.05884,
        low: 1.05634
      },
      {
        timestamp: 1651849200,
        open: 1.05797,
        close: 1.05783,
        high: 1.05958,
        low: 1.05771
      },
      {
        timestamp: 1651852800,
        open: 1.05783,
        close: 1.05696,
        high: 1.05811,
        low: 1.05668
      },
      {
        timestamp: 1651856400,
        open: 1.05696,
        close: 1.05509,
        high: 1.05754,
        low: 1.05508
      },
      {
        timestamp: 1651860000,
        open: 1.05509,
        close: 1.0548,
        high: 1.05591,
        low: 1.05442
      },
      {
        timestamp: 1651863600,
        open: 1.0548,
        close: 1.05481,
        high: 1.05571,
        low: 1.0537
      },
      {
        timestamp: 1651867200,
        open: 1.05481,
        close: 1.05481,
        high: 1.05531,
        low: 1.05413
      },
      {
        timestamp: 1652040000,
        open: 1.05655,
        close: 1.05503,
        high: 1.05685,
        low: 1.05407
      },
      {
        timestamp: 1652043600,
        open: 1.05485,
        close: 1.05433,
        high: 1.0549,
        low: 1.05413
      },
      {
        timestamp: 1652047200,
        open: 1.0543,
        close: 1.05386,
        high: 1.05446,
        low: 1.05366
      },
      {
        timestamp: 1652050800,
        open: 1.05382,
        close: 1.05329,
        high: 1.05396,
        low: 1.05315
      },
      {
        timestamp: 1652054400,
        open: 1.0533,
        close: 1.05201,
        high: 1.05363,
        low: 1.05189
      },
      {
        timestamp: 1652058000,
        open: 1.05201,
        close: 1.05246,
        high: 1.05275,
        low: 1.05142
      },
      {
        timestamp: 1652061600,
        open: 1.05247,
        close: 1.05076,
        high: 1.05247,
        low: 1.05073
      },
      {
        timestamp: 1652065200,
        open: 1.05076,
        close: 1.05107,
        high: 1.05137,
        low: 1.05035
      },
      {
        timestamp: 1652068800,
        open: 1.05106,
        close: 1.05132,
        high: 1.05172,
        low: 1.05064
      },
      {
        timestamp: 1652072400,
        open: 1.05131,
        close: 1.05081,
        high: 1.05131,
        low: 1.05037
      },
      {
        timestamp: 1652076000,
        open: 1.05082,
        close: 1.05033,
        high: 1.0515,
        low: 1.04958
      },
      {
        timestamp: 1652079600,
        open: 1.05034,
        close: 1.05125,
        high: 1.05302,
        low: 1.04984
      },
      {
        timestamp: 1652083200,
        open: 1.05125,
        close: 1.05081,
        high: 1.05211,
        low: 1.05019
      },
      {
        timestamp: 1652086800,
        open: 1.0508,
        close: 1.05288,
        high: 1.05309,
        low: 1.05055
      },
      {
        timestamp: 1652090400,
        open: 1.05289,
        close: 1.05457,
        high: 1.05505,
        low: 1.05268
      },
      {
        timestamp: 1652094000,
        open: 1.05458,
        close: 1.05472,
        high: 1.05532,
        low: 1.05332
      },
      {
        timestamp: 1652097600,
        open: 1.05471,
        close: 1.0563,
        high: 1.05744,
        low: 1.05453
      },
      {
        timestamp: 1652101200,
        open: 1.0563,
        close: 1.05362,
        high: 1.05641,
        low: 1.05334
      },
      {
        timestamp: 1652104800,
        open: 1.05363,
        close: 1.05283,
        high: 1.05403,
        low: 1.05178
      },
      {
        timestamp: 1652108400,
        open: 1.05284,
        close: 1.05575,
        high: 1.05637,
        low: 1.05275
      },
      {
        timestamp: 1652112000,
        open: 1.05575,
        close: 1.05742,
        high: 1.0593,
        low: 1.05556
      },
      {
        timestamp: 1652115600,
        open: 1.0574,
        close: 1.05591,
        high: 1.05758,
        low: 1.05534
      },
      {
        timestamp: 1652119200,
        open: 1.0559,
        close: 1.05667,
        high: 1.05675,
        low: 1.05513
      },
      {
        timestamp: 1652122800,
        open: 1.05665,
        close: 1.05586,
        high: 1.05764,
        low: 1.05568
      },
      {
        timestamp: 1652126400,
        open: 1.05585,
        close: 1.05655,
        high: 1.05655,
        low: 1.05578
      },
      {
        timestamp: 1652130000,
        open: 1.05655,
        close: 1.05606,
        high: 1.05686,
        low: 1.05548
      },
      {
        timestamp: 1652133600,
        open: 1.05606,
        close: 1.05576,
        high: 1.05607,
        low: 1.05533
      },
      {
        timestamp: 1652137200,
        open: 1.05575,
        close: 1.0564,
        high: 1.05699,
        low: 1.05575
      },
      {
        timestamp: 1652140800,
        open: 1.05641,
        close: 1.05596,
        high: 1.05659,
        low: 1.05539
      },
      {
        timestamp: 1652144400,
        open: 1.05594,
        close: 1.05629,
        high: 1.05699,
        low: 1.05557
      },
      {
        timestamp: 1652148000,
        open: 1.0563,
        close: 1.05733,
        high: 1.05806,
        low: 1.05604
      },
      {
        timestamp: 1652151600,
        open: 1.05732,
        close: 1.05796,
        high: 1.05803,
        low: 1.05702
      },
      {
        timestamp: 1652155200,
        open: 1.05794,
        close: 1.05765,
        high: 1.05816,
        low: 1.05745
      },
      {
        timestamp: 1652158800,
        open: 1.05764,
        close: 1.05771,
        high: 1.05861,
        low: 1.05743
      },
      {
        timestamp: 1652162400,
        open: 1.0577,
        close: 1.05609,
        high: 1.05799,
        low: 1.05601
      },
      {
        timestamp: 1652166000,
        open: 1.05608,
        close: 1.0558,
        high: 1.05793,
        low: 1.05517
      },
      {
        timestamp: 1652169600,
        open: 1.0558,
        close: 1.05731,
        high: 1.05763,
        low: 1.05547
      },
      {
        timestamp: 1652173200,
        open: 1.05732,
        close: 1.05401,
        high: 1.05764,
        low: 1.05378
      },
      {
        timestamp: 1652176800,
        open: 1.05401,
        close: 1.05539,
        high: 1.05543,
        low: 1.05362
      },
      {
        timestamp: 1652180400,
        open: 1.0554,
        close: 1.05539,
        high: 1.05673,
        low: 1.05477
      },
      {
        timestamp: 1652184000,
        open: 1.05538,
        close: 1.05521,
        high: 1.05619,
        low: 1.0549
      },
      {
        timestamp: 1652187600,
        open: 1.05522,
        close: 1.05497,
        high: 1.05758,
        low: 1.05428
      },
      {
        timestamp: 1652191200,
        open: 1.05497,
        close: 1.05381,
        high: 1.05573,
        low: 1.05326
      },
      {
        timestamp: 1652194800,
        open: 1.0538,
        close: 1.05333,
        high: 1.05489,
        low: 1.05262
      },
      {
        timestamp: 1652198400,
        open: 1.05333,
        close: 1.05353,
        high: 1.05475,
        low: 1.05305
      },
      {
        timestamp: 1652202000,
        open: 1.05353,
        close: 1.05382,
        high: 1.05421,
        low: 1.05271
      },
      {
        timestamp: 1652205600,
        open: 1.05382,
        close: 1.05313,
        high: 1.05408,
        low: 1.05281
      },
      {
        timestamp: 1652209200,
        open: 1.05314,
        close: 1.05362,
        high: 1.05394,
        low: 1.0529
      },
      {
        timestamp: 1652212800,
        open: 1.05361,
        close: 1.05314,
        high: 1.05364,
        low: 1.05282
      },
      {
        timestamp: 1652216400,
        open: 1.05314,
        close: 1.0532,
        high: 1.05379,
        low: 1.053
      },
      {
        timestamp: 1652220000,
        open: 1.05327,
        close: 1.05339,
        high: 1.05358,
        low: 1.05309
      },
      {
        timestamp: 1652223600,
        open: 1.05342,
        close: 1.05296,
        high: 1.05353,
        low: 1.05295
      },
      {
        timestamp: 1652227200,
        open: 1.05296,
        close: 1.05322,
        high: 1.05374,
        low: 1.05277
      },
      {
        timestamp: 1652230800,
        open: 1.05324,
        close: 1.05344,
        high: 1.05356,
        low: 1.05263
      },
      {
        timestamp: 1652234400,
        open: 1.05345,
        close: 1.05354,
        high: 1.05384,
        low: 1.05298
      },
      {
        timestamp: 1652238000,
        open: 1.05354,
        close: 1.05375,
        high: 1.05398,
        low: 1.05333
      },
      {
        timestamp: 1652241600,
        open: 1.05375,
        close: 1.05419,
        high: 1.05441,
        low: 1.05346
      },
      {
        timestamp: 1652245200,
        open: 1.05419,
        close: 1.05467,
        high: 1.05509,
        low: 1.05401
      },
      {
        timestamp: 1652248800,
        open: 1.05466,
        close: 1.05423,
        high: 1.05498,
        low: 1.05378
      },
      {
        timestamp: 1652252400,
        open: 1.05424,
        close: 1.05529,
        high: 1.05614,
        low: 1.05424
      },
      {
        timestamp: 1652256000,
        open: 1.05528,
        close: 1.05685,
        high: 1.05754,
        low: 1.05502
      },
      {
        timestamp: 1652259600,
        open: 1.05685,
        close: 1.0564,
        high: 1.05765,
        low: 1.05578
      },
      {
        timestamp: 1652263200,
        open: 1.0564,
        close: 1.0547,
        high: 1.05681,
        low: 1.05458
      },
      {
        timestamp: 1652266800,
        open: 1.0547,
        close: 1.05456,
        high: 1.05577,
        low: 1.05439
      },
      {
        timestamp: 1652270400,
        open: 1.05455,
        close: 1.05156,
        high: 1.05569,
        low: 1.05077
      },
      {
        timestamp: 1652274000,
        open: 1.05155,
        close: 1.05513,
        high: 1.05516,
        low: 1.05025
      }
    ]
	},
  {
    id: 2,
		label: "EUR/USD - 24h",
		value: "EUR/USD - 24h",
		symbol: "EUR/USD",
		period: "24h",
		from: "2015/01/01",
		to: "2022/05/11",
    tradingInstrumentId: 1,
		timestepInSeconds: 24 * 60 * 60,
		data: [
      {
        timestamp: 1420236000,
        open: 1.21008,
        close: 1.20037,
        high: 1.20707,
        low: 1.20019
      },
      {
        timestamp: 1420408800,
        open: 1.20037,
        close: 1.19564,
        high: 1.201,
        low: 1.19524
      },
      {
        timestamp: 1420495200,
        open: 1.19564,
        close: 1.19346,
        high: 1.19773,
        low: 1.18746
      },
      {
        timestamp: 1420581600,
        open: 1.19346,
        close: 1.18927,
        high: 1.19696,
        low: 1.1885
      },
      {
        timestamp: 1420668000,
        open: 1.18927,
        close: 1.18421,
        high: 1.18974,
        low: 1.18028
      },
      {
        timestamp: 1420754400,
        open: 1.18421,
        close: 1.17957,
        high: 1.18484,
        low: 1.1755
      },
      {
        timestamp: 1420840800,
        open: 1.17957,
        close: 1.18458,
        high: 1.18479,
        low: 1.17633
      },
      {
        timestamp: 1421013600,
        open: 1.18458,
        close: 1.18537,
        high: 1.18578,
        low: 1.18425
      },
      {
        timestamp: 1421100000,
        open: 1.18537,
        close: 1.18346,
        high: 1.18718,
        low: 1.17871
      },
      {
        timestamp: 1421186400,
        open: 1.18346,
        close: 1.1775,
        high: 1.18605,
        low: 1.17539
      },
      {
        timestamp: 1421272800,
        open: 1.1775,
        close: 1.17902,
        high: 1.18471,
        low: 1.17281
      },
      {
        timestamp: 1421359200,
        open: 1.17902,
        close: 1.16338,
        high: 1.1794,
        low: 1.15685
      },
      {
        timestamp: 1421445600,
        open: 1.16338,
        close: 1.15674,
        high: 1.16499,
        low: 1.14609
      },
      {
        timestamp: 1421618400,
        open: 1.15674,
        close: 1.1558,
        high: 1.15614,
        low: 1.15472
      },
      {
        timestamp: 1421704800,
        open: 1.1558,
        close: 1.16059,
        high: 1.16398,
        low: 1.15523
      },
      {
        timestamp: 1421791200,
        open: 1.16059,
        close: 1.15512,
        high: 1.16168,
        low: 1.15413
      },
      {
        timestamp: 1421877600,
        open: 1.15512,
        close: 1.16127,
        high: 1.16825,
        low: 1.15423
      },
      {
        timestamp: 1421964000,
        open: 1.16127,
        close: 1.13667,
        high: 1.16734,
        low: 1.13172
      },
      {
        timestamp: 1422050400,
        open: 1.13667,
        close: 1.12086,
        high: 1.13749,
        low: 1.11154
      },
      {
        timestamp: 1422223200,
        open: 1.12086,
        close: 1.11569,
        high: 1.11728,
        low: 1.1141
      },
      {
        timestamp: 1422309600,
        open: 1.11569,
        close: 1.12397,
        high: 1.12966,
        low: 1.10987
      },
      {
        timestamp: 1422396000,
        open: 1.12397,
        close: 1.1382,
        high: 1.14238,
        low: 1.12247
      },
      {
        timestamp: 1422482400,
        open: 1.1382,
        close: 1.12896,
        high: 1.13843,
        low: 1.12768
      },
      {
        timestamp: 1422568800,
        open: 1.12896,
        close: 1.13207,
        high: 1.13688,
        low: 1.12626
      },
      {
        timestamp: 1422655200,
        open: 1.13207,
        close: 1.12941,
        high: 1.13645,
        low: 1.12798
      },
      {
        timestamp: 1422828000,
        open: 1.12941,
        close: 1.13163,
        high: 1.13299,
        low: 1.12941
      },
      {
        timestamp: 1422914400,
        open: 1.13163,
        close: 1.13444,
        high: 1.13629,
        low: 1.12933
      },
      {
        timestamp: 1423000800,
        open: 1.13444,
        close: 1.14839,
        high: 1.15352,
        low: 1.13132
      },
      {
        timestamp: 1423087200,
        open: 1.14839,
        close: 1.13487,
        high: 1.14858,
        low: 1.13165
      },
      {
        timestamp: 1423173600,
        open: 1.13487,
        close: 1.14796,
        high: 1.14996,
        low: 1.13049
      },
      {
        timestamp: 1423260000,
        open: 1.14796,
        close: 1.13154,
        high: 1.1487,
        low: 1.13126
      },
      {
        timestamp: 1423432800,
        open: 1.13154,
        close: 1.12973,
        high: 1.1323,
        low: 1.1287
      },
      {
        timestamp: 1423519200,
        open: 1.12973,
        close: 1.1326,
        high: 1.13603,
        low: 1.12711
      },
      {
        timestamp: 1423605600,
        open: 1.1326,
        close: 1.13219,
        high: 1.13461,
        low: 1.12736
      },
      {
        timestamp: 1423692000,
        open: 1.13219,
        close: 1.1343,
        high: 1.13481,
        low: 1.12805
      },
      {
        timestamp: 1423778400,
        open: 1.1343,
        close: 1.14057,
        high: 1.1424,
        low: 1.1303
      },
      {
        timestamp: 1423864800,
        open: 1.14057,
        close: 1.1391,
        high: 1.1444,
        low: 1.13804
      },
      {
        timestamp: 1424037600,
        open: 1.1391,
        close: 1.1402,
        high: 1.14285,
        low: 1.1391
      },
      {
        timestamp: 1424124000,
        open: 1.1402,
        close: 1.1358,
        high: 1.14298,
        low: 1.13204
      },
      {
        timestamp: 1424210400,
        open: 1.1358,
        close: 1.14117,
        high: 1.14502,
        low: 1.13227
      },
      {
        timestamp: 1424296800,
        open: 1.14117,
        close: 1.13978,
        high: 1.14168,
        low: 1.13347
      },
      {
        timestamp: 1424383200,
        open: 1.13978,
        close: 1.13689,
        high: 1.14511,
        low: 1.13563
      },
      {
        timestamp: 1424469600,
        open: 1.13689,
        close: 1.13797,
        high: 1.1431,
        low: 1.12795
      },
      {
        timestamp: 1424642400,
        open: 1.13797,
        close: 1.13974,
        high: 1.14142,
        low: 1.13797
      },
      {
        timestamp: 1424728800,
        open: 1.13974,
        close: 1.13354,
        high: 1.13982,
        low: 1.1296
      },
      {
        timestamp: 1424815200,
        open: 1.13354,
        close: 1.13423,
        high: 1.1359,
        low: 1.12891
      },
      {
        timestamp: 1424901600,
        open: 1.13423,
        close: 1.1362,
        high: 1.13896,
        low: 1.13365
      },
      {
        timestamp: 1424988000,
        open: 1.1362,
        close: 1.11988,
        high: 1.13807,
        low: 1.11845
      },
      {
        timestamp: 1425074400,
        open: 1.11988,
        close: 1.11995,
        high: 1.12459,
        low: 1.11766
      },
      {
        timestamp: 1425247200,
        open: 1.11995,
        close: 1.11838,
        high: 1.11894,
        low: 1.11754
      },
      {
        timestamp: 1425333600,
        open: 1.11838,
        close: 1.11861,
        high: 1.12414,
        low: 1.11608
      },
      {
        timestamp: 1425420000,
        open: 1.11861,
        close: 1.11771,
        high: 1.12183,
        low: 1.11554
      },
      {
        timestamp: 1425506400,
        open: 1.11771,
        close: 1.10806,
        high: 1.11862,
        low: 1.10623
      },
      {
        timestamp: 1425592800,
        open: 1.10806,
        close: 1.10343,
        high: 1.11155,
        low: 1.09883
      },
      {
        timestamp: 1425679200,
        open: 1.10343,
        close: 1.08471,
        high: 1.1034,
        low: 1.08407
      },
      {
        timestamp: 1425848400,
        open: 1.08471,
        close: 1.08335,
        high: 1.08402,
        low: 1.08284
      },
      {
        timestamp: 1425934800,
        open: 1.08335,
        close: 1.08527,
        high: 1.09073,
        low: 1.08234
      },
      {
        timestamp: 1426021200,
        open: 1.08527,
        close: 1.06985,
        high: 1.08562,
        low: 1.06934
      },
      {
        timestamp: 1426107600,
        open: 1.06985,
        close: 1.05484,
        high: 1.07179,
        low: 1.05119
      },
      {
        timestamp: 1426194000,
        open: 1.05484,
        close: 1.06364,
        high: 1.06847,
        low: 1.04949
      },
      {
        timestamp: 1426280400,
        open: 1.06364,
        close: 1.05045,
        high: 1.06374,
        low: 1.04631
      },
      {
        timestamp: 1426453200,
        open: 1.05045,
        close: 1.04715,
        high: 1.04847,
        low: 1.04588
      },
      {
        timestamp: 1426539600,
        open: 1.04715,
        close: 1.0569,
        high: 1.06204,
        low: 1.04709
      },
      {
        timestamp: 1426626000,
        open: 1.0569,
        close: 1.05981,
        high: 1.06517,
        low: 1.05522
      },
      {
        timestamp: 1426712400,
        open: 1.05981,
        close: 1.08722,
        high: 1.1053,
        low: 1.0581
      },
      {
        timestamp: 1426798800,
        open: 1.08722,
        close: 1.06608,
        high: 1.09206,
        low: 1.06138
      },
      {
        timestamp: 1426885200,
        open: 1.06608,
        close: 1.08263,
        high: 1.08832,
        low: 1.06502
      },
      {
        timestamp: 1427058000,
        open: 1.08263,
        close: 1.08435,
        high: 1.08483,
        low: 1.08263
      },
      {
        timestamp: 1427144400,
        open: 1.08435,
        close: 1.09468,
        high: 1.09721,
        low: 1.07683
      },
      {
        timestamp: 1427230800,
        open: 1.09468,
        close: 1.09258,
        high: 1.10304,
        low: 1.08912
      },
      {
        timestamp: 1427317200,
        open: 1.09258,
        close: 1.09709,
        high: 1.10154,
        low: 1.09015
      },
      {
        timestamp: 1427403600,
        open: 1.09709,
        close: 1.08849,
        high: 1.10532,
        low: 1.08572
      },
      {
        timestamp: 1427490000,
        open: 1.08849,
        close: 1.08942,
        high: 1.09497,
        low: 1.08019
      },
      {
        timestamp: 1427662800,
        open: 1.08942,
        close: 1.08882,
        high: 1.09002,
        low: 1.08828
      },
      {
        timestamp: 1427749200,
        open: 1.08882,
        close: 1.08335,
        high: 1.08968,
        low: 1.08108
      },
      {
        timestamp: 1427835600,
        open: 1.08335,
        close: 1.0733,
        high: 1.08462,
        low: 1.07136
      },
      {
        timestamp: 1427922000,
        open: 1.0733,
        close: 1.0766,
        high: 1.08008,
        low: 1.07194
      },
      {
        timestamp: 1428008400,
        open: 1.0766,
        close: 1.08809,
        high: 1.09065,
        low: 1.07509
      },
      {
        timestamp: 1428094800,
        open: 1.08809,
        close: 1.09808,
        high: 1.1028,
        low: 1.08647
      },
      {
        timestamp: 1428267600,
        open: 1.09808,
        close: 1.10048,
        high: 1.10094,
        low: 1.09808
      },
      {
        timestamp: 1428354000,
        open: 1.10048,
        close: 1.09242,
        high: 1.10365,
        low: 1.09111
      },
      {
        timestamp: 1428440400,
        open: 1.09242,
        close: 1.08162,
        high: 1.09557,
        low: 1.08042
      },
      {
        timestamp: 1428526800,
        open: 1.08162,
        close: 1.07811,
        high: 1.08884,
        low: 1.07643
      },
      {
        timestamp: 1428613200,
        open: 1.07811,
        close: 1.06604,
        high: 1.07895,
        low: 1.06384
      },
      {
        timestamp: 1428699600,
        open: 1.06604,
        close: 1.06053,
        high: 1.06846,
        low: 1.05686
      },
      {
        timestamp: 1428872400,
        open: 1.06053,
        close: 1.05905,
        high: 1.06099,
        low: 1.05861
      },
      {
        timestamp: 1428958800,
        open: 1.05905,
        close: 1.05697,
        high: 1.06201,
        low: 1.05208
      },
      {
        timestamp: 1429045200,
        open: 1.05697,
        close: 1.0656,
        high: 1.07083,
        low: 1.05322
      },
      {
        timestamp: 1429131600,
        open: 1.0656,
        close: 1.06861,
        high: 1.07033,
        low: 1.05714
      },
      {
        timestamp: 1429218000,
        open: 1.06861,
        close: 1.07622,
        high: 1.08189,
        low: 1.06253
      },
      {
        timestamp: 1429304400,
        open: 1.07622,
        close: 1.08132,
        high: 1.08496,
        low: 1.07344
      },
      {
        timestamp: 1429477200,
        open: 1.08132,
        close: 1.08255,
        high: 1.08277,
        low: 1.08132
      },
      {
        timestamp: 1429563600,
        open: 1.08255,
        close: 1.07394,
        high: 1.08247,
        low: 1.07129
      },
      {
        timestamp: 1429650000,
        open: 1.07394,
        close: 1.07363,
        high: 1.07821,
        low: 1.06607
      },
      {
        timestamp: 1429736400,
        open: 1.07363,
        close: 1.07272,
        high: 1.08017,
        low: 1.07092
      },
      {
        timestamp: 1429822800,
        open: 1.07272,
        close: 1.08253,
        high: 1.08467,
        low: 1.0667
      },
      {
        timestamp: 1429909200,
        open: 1.08253,
        close: 1.08796,
        high: 1.0901,
        low: 1.07855
      },
      {
        timestamp: 1430082000,
        open: 1.08796,
        close: 1.08694,
        high: 1.08734,
        low: 1.0859
      },
      {
        timestamp: 1430168400,
        open: 1.08694,
        close: 1.08948,
        high: 1.09272,
        low: 1.08203
      },
      {
        timestamp: 1430254800,
        open: 1.08948,
        close: 1.09822,
        high: 1.09919,
        low: 1.0861
      },
      {
        timestamp: 1430341200,
        open: 1.09822,
        close: 1.11293,
        high: 1.11891,
        low: 1.09602
      },
      {
        timestamp: 1430427600,
        open: 1.11293,
        close: 1.1225,
        high: 1.12673,
        low: 1.10721
      },
      {
        timestamp: 1430514000,
        open: 1.1225,
        close: 1.11991,
        high: 1.12924,
        low: 1.11755
      },
      {
        timestamp: 1430686800,
        open: 1.11991,
        close: 1.1198,
        high: 1.12001,
        low: 1.11889
      },
      {
        timestamp: 1430773200,
        open: 1.1198,
        close: 1.11474,
        high: 1.12255,
        low: 1.11236
      },
      {
        timestamp: 1430859600,
        open: 1.11474,
        close: 1.11882,
        high: 1.1224,
        low: 1.1067
      },
      {
        timestamp: 1430946000,
        open: 1.11882,
        close: 1.13484,
        high: 1.13711,
        low: 1.11757
      },
      {
        timestamp: 1431032400,
        open: 1.13484,
        close: 1.12678,
        high: 1.13927,
        low: 1.1238
      },
      {
        timestamp: 1431118800,
        open: 1.12678,
        close: 1.12151,
        high: 1.12955,
        low: 1.11801
      },
      {
        timestamp: 1431291600,
        open: 1.12151,
        close: 1.12051,
        high: 1.12109,
        low: 1.11968
      },
      {
        timestamp: 1431378000,
        open: 1.12051,
        close: 1.1157,
        high: 1.12094,
        low: 1.11318
      },
      {
        timestamp: 1431464400,
        open: 1.1157,
        close: 1.12137,
        high: 1.12799,
        low: 1.11352
      },
      {
        timestamp: 1431550800,
        open: 1.12137,
        close: 1.13554,
        high: 1.13835,
        low: 1.12033
      },
      {
        timestamp: 1431637200,
        open: 1.13554,
        close: 1.14107,
        high: 1.1448,
        low: 1.13414
      },
      {
        timestamp: 1431723600,
        open: 1.14107,
        close: 1.14514,
        high: 1.14678,
        low: 1.13246
      },
      {
        timestamp: 1431896400,
        open: 1.14514,
        close: 1.1449,
        high: 1.14574,
        low: 1.14332
      },
      {
        timestamp: 1431982800,
        open: 1.1449,
        close: 1.13167,
        high: 1.14521,
        low: 1.12994
      },
      {
        timestamp: 1432069200,
        open: 1.13167,
        close: 1.11513,
        high: 1.13273,
        low: 1.11193
      },
      {
        timestamp: 1432155600,
        open: 1.11513,
        close: 1.10954,
        high: 1.11565,
        low: 1.1063
      },
      {
        timestamp: 1432242000,
        open: 1.10954,
        close: 1.11129,
        high: 1.11824,
        low: 1.10806
      },
      {
        timestamp: 1432328400,
        open: 1.11129,
        close: 1.10141,
        high: 1.12096,
        low: 1.1003
      },
      {
        timestamp: 1432501200,
        open: 1.10141,
        close: 1.10055,
        high: 1.10107,
        low: 1.10013
      },
      {
        timestamp: 1432587600,
        open: 1.10055,
        close: 1.09795,
        high: 1.10114,
        low: 1.09599
      },
      {
        timestamp: 1432674000,
        open: 1.09795,
        close: 1.08791,
        high: 1.09832,
        low: 1.08643
      },
      {
        timestamp: 1432760400,
        open: 1.08791,
        close: 1.09057,
        high: 1.09301,
        low: 1.08199
      },
      {
        timestamp: 1432846800,
        open: 1.09057,
        close: 1.09499,
        high: 1.09599,
        low: 1.08682
      },
      {
        timestamp: 1432933200,
        open: 1.09499,
        close: 1.09934,
        high: 1.10074,
        low: 1.09265
      },
      {
        timestamp: 1433106000,
        open: 1.09934,
        close: 1.09814,
        high: 1.09936,
        low: 1.09804
      },
      {
        timestamp: 1433192400,
        open: 1.09814,
        close: 1.09294,
        high: 1.09936,
        low: 1.0888
      },
      {
        timestamp: 1433278800,
        open: 1.09294,
        close: 1.1152,
        high: 1.11961,
        low: 1.09168
      },
      {
        timestamp: 1433365200,
        open: 1.1152,
        close: 1.12757,
        high: 1.12858,
        low: 1.10802
      },
      {
        timestamp: 1433451600,
        open: 1.12757,
        close: 1.12397,
        high: 1.13809,
        low: 1.12234
      },
      {
        timestamp: 1433538000,
        open: 1.12397,
        close: 1.11212,
        high: 1.12904,
        low: 1.10501
      },
      {
        timestamp: 1433710800,
        open: 1.11212,
        close: 1.10989,
        high: 1.11164,
        low: 1.1083
      },
      {
        timestamp: 1433797200,
        open: 1.10989,
        close: 1.12927,
        high: 1.13077,
        low: 1.10852
      },
      {
        timestamp: 1433883600,
        open: 1.12927,
        close: 1.12837,
        high: 1.13468,
        low: 1.12149
      },
      {
        timestamp: 1433970000,
        open: 1.12837,
        close: 1.1325,
        high: 1.13877,
        low: 1.12608
      },
      {
        timestamp: 1434056400,
        open: 1.1325,
        close: 1.12595,
        high: 1.1333,
        low: 1.11825
      },
      {
        timestamp: 1434142800,
        open: 1.12595,
        close: 1.1272,
        high: 1.12974,
        low: 1.11521
      },
      {
        timestamp: 1434315600,
        open: 1.1272,
        close: 1.12137,
        high: 1.12471,
        low: 1.1212
      },
      {
        timestamp: 1434402000,
        open: 1.12137,
        close: 1.12878,
        high: 1.12954,
        low: 1.11901
      },
      {
        timestamp: 1434488400,
        open: 1.12878,
        close: 1.12488,
        high: 1.13307,
        low: 1.12057
      },
      {
        timestamp: 1434574800,
        open: 1.12488,
        close: 1.13402,
        high: 1.13589,
        low: 1.1206
      },
      {
        timestamp: 1434661200,
        open: 1.13402,
        close: 1.13616,
        high: 1.14373,
        low: 1.13312
      },
      {
        timestamp: 1434747600,
        open: 1.13616,
        close: 1.13527,
        high: 1.14028,
        low: 1.12927
      },
      {
        timestamp: 1434920400,
        open: 1.13527,
        close: 1.1378,
        high: 1.13845,
        low: 1.13527
      },
      {
        timestamp: 1435006800,
        open: 1.1378,
        close: 1.13415,
        high: 1.14112,
        low: 1.13126
      },
      {
        timestamp: 1435093200,
        open: 1.13415,
        close: 1.11693,
        high: 1.13483,
        low: 1.11358
      },
      {
        timestamp: 1435179600,
        open: 1.11693,
        close: 1.12066,
        high: 1.12356,
        low: 1.11557
      },
      {
        timestamp: 1435266000,
        open: 1.12066,
        close: 1.1207,
        high: 1.12291,
        low: 1.11546
      },
      {
        timestamp: 1435352400,
        open: 1.1207,
        close: 1.11657,
        high: 1.12206,
        low: 1.11308
      },
      {
        timestamp: 1435525200,
        open: 1.11657,
        close: 1.10094,
        high: 1.10483,
        low: 1.0997
      },
      {
        timestamp: 1435611600,
        open: 1.10094,
        close: 1.1237,
        high: 1.12795,
        low: 1.0955
      },
      {
        timestamp: 1435698000,
        open: 1.1237,
        close: 1.11548,
        high: 1.12453,
        low: 1.11128
      },
      {
        timestamp: 1435784400,
        open: 1.11548,
        close: 1.10553,
        high: 1.1172,
        low: 1.10436
      },
      {
        timestamp: 1435870800,
        open: 1.10553,
        close: 1.10852,
        high: 1.11222,
        low: 1.10331
      },
      {
        timestamp: 1435957200,
        open: 1.10852,
        close: 1.11202,
        high: 1.11202,
        low: 1.10658
      },
      {
        timestamp: 1436130000,
        open: 1.11202,
        close: 1.09976,
        high: 1.10218,
        low: 1.09802
      },
      {
        timestamp: 1436216400,
        open: 1.09976,
        close: 1.10571,
        high: 1.10969,
        low: 1.09707
      },
      {
        timestamp: 1436302800,
        open: 1.10571,
        close: 1.1012,
        high: 1.106,
        low: 1.0917
      },
      {
        timestamp: 1436389200,
        open: 1.1012,
        close: 1.10776,
        high: 1.10939,
        low: 1.0975
      },
      {
        timestamp: 1436475600,
        open: 1.10776,
        close: 1.10406,
        high: 1.11265,
        low: 1.09924
      },
      {
        timestamp: 1436562000,
        open: 1.10406,
        close: 1.11657,
        high: 1.1217,
        low: 1.1032
      },
      {
        timestamp: 1436734800,
        open: 1.11657,
        close: 1.1096,
        high: 1.11279,
        low: 1.10903
      },
      {
        timestamp: 1436821200,
        open: 1.1096,
        close: 1.10084,
        high: 1.11978,
        low: 1.09962
      },
      {
        timestamp: 1436907600,
        open: 1.10084,
        close: 1.10136,
        high: 1.10892,
        low: 1.09662
      },
      {
        timestamp: 1436994000,
        open: 1.10136,
        close: 1.09512,
        high: 1.10365,
        low: 1.09307
      },
      {
        timestamp: 1437080400,
        open: 1.09512,
        close: 1.08829,
        high: 1.09637,
        low: 1.08561
      },
      {
        timestamp: 1437166800,
        open: 1.08829,
        close: 1.08304,
        high: 1.09078,
        low: 1.08296
      },
      {
        timestamp: 1437339600,
        open: 1.08304,
        close: 1.08338,
        high: 1.08454,
        low: 1.08304
      },
      {
        timestamp: 1437426000,
        open: 1.08338,
        close: 1.08262,
        high: 1.08711,
        low: 1.08097
      },
      {
        timestamp: 1437512400,
        open: 1.08262,
        close: 1.09362,
        high: 1.09698,
        low: 1.08126
      },
      {
        timestamp: 1437598800,
        open: 1.09362,
        close: 1.09296,
        high: 1.09677,
        low: 1.08701
      },
      {
        timestamp: 1437685200,
        open: 1.09296,
        close: 1.099,
        high: 1.10192,
        low: 1.09226
      },
      {
        timestamp: 1437771600,
        open: 1.099,
        close: 1.09793,
        high: 1.09968,
        low: 1.0926
      },
      {
        timestamp: 1437944400,
        open: 1.09793,
        close: 1.09844,
        high: 1.09906,
        low: 1.09744
      },
      {
        timestamp: 1438030800,
        open: 1.09844,
        close: 1.10914,
        high: 1.11302,
        low: 1.09699
      },
      {
        timestamp: 1438117200,
        open: 1.10914,
        close: 1.10617,
        high: 1.11005,
        low: 1.10227
      },
      {
        timestamp: 1438203600,
        open: 1.10617,
        close: 1.09867,
        high: 1.10849,
        low: 1.09678
      },
      {
        timestamp: 1438290000,
        open: 1.09867,
        close: 1.09336,
        high: 1.09903,
        low: 1.08942
      },
      {
        timestamp: 1438376400,
        open: 1.09336,
        close: 1.09878,
        high: 1.1115,
        low: 1.0922
      },
      {
        timestamp: 1438549200,
        open: 1.09878,
        close: 1.0978,
        high: 1.09934,
        low: 1.09726
      },
      {
        timestamp: 1438635600,
        open: 1.0978,
        close: 1.09521,
        high: 1.09969,
        low: 1.09419
      },
      {
        timestamp: 1438722000,
        open: 1.09521,
        close: 1.08824,
        high: 1.09884,
        low: 1.08801
      },
      {
        timestamp: 1438808400,
        open: 1.08824,
        close: 1.0907,
        high: 1.09393,
        low: 1.08488
      },
      {
        timestamp: 1438894800,
        open: 1.0907,
        close: 1.09288,
        high: 1.09458,
        low: 1.08742
      },
      {
        timestamp: 1438981200,
        open: 1.09288,
        close: 1.09701,
        high: 1.09792,
        low: 1.08562
      },
      {
        timestamp: 1439154000,
        open: 1.09701,
        close: 1.09697,
        high: 1.09782,
        low: 1.09665
      },
      {
        timestamp: 1439240400,
        open: 1.09697,
        close: 1.10209,
        high: 1.10424,
        low: 1.09261
      },
      {
        timestamp: 1439326800,
        open: 1.10209,
        close: 1.10432,
        high: 1.10893,
        low: 1.09615
      },
      {
        timestamp: 1439413200,
        open: 1.10432,
        close: 1.11611,
        high: 1.12149,
        low: 1.10251
      },
      {
        timestamp: 1439499600,
        open: 1.11611,
        close: 1.11518,
        high: 1.11899,
        low: 1.10807
      },
      {
        timestamp: 1439586000,
        open: 1.11518,
        close: 1.11119,
        high: 1.11894,
        low: 1.10986
      },
      {
        timestamp: 1439758800,
        open: 1.11119,
        close: 1.11256,
        high: 1.1128,
        low: 1.11083
      },
      {
        timestamp: 1439845200,
        open: 1.11256,
        close: 1.10825,
        high: 1.11257,
        low: 1.10594
      },
      {
        timestamp: 1439931600,
        open: 1.10825,
        close: 1.10268,
        high: 1.10946,
        low: 1.10179
      },
      {
        timestamp: 1440018000,
        open: 1.10268,
        close: 1.11249,
        high: 1.11352,
        low: 1.10185
      },
      {
        timestamp: 1440104400,
        open: 1.11249,
        close: 1.12452,
        high: 1.12456,
        low: 1.1108
      },
      {
        timestamp: 1440190800,
        open: 1.12452,
        close: 1.13944,
        high: 1.13951,
        low: 1.12303
      },
      {
        timestamp: 1440363600,
        open: 1.13944,
        close: 1.13817,
        high: 1.13892,
        low: 1.13706
      },
      {
        timestamp: 1440450000,
        open: 1.13817,
        close: 1.16218,
        high: 1.17195,
        low: 1.13707
      },
      {
        timestamp: 1440536400,
        open: 1.16218,
        close: 1.15183,
        high: 1.16234,
        low: 1.1397
      },
      {
        timestamp: 1440622800,
        open: 1.15183,
        close: 1.13163,
        high: 1.15624,
        low: 1.12928
      },
      {
        timestamp: 1440709200,
        open: 1.13163,
        close: 1.1248,
        high: 1.13653,
        low: 1.12037
      },
      {
        timestamp: 1440795600,
        open: 1.1248,
        close: 1.11897,
        high: 1.13106,
        low: 1.11566
      },
      {
        timestamp: 1440968400,
        open: 1.11897,
        close: 1.11792,
        high: 1.11887,
        low: 1.11625
      },
      {
        timestamp: 1441054800,
        open: 1.11792,
        close: 1.12125,
        high: 1.12635,
        low: 1.11719
      },
      {
        timestamp: 1441141200,
        open: 1.12125,
        close: 1.13177,
        high: 1.1333,
        low: 1.12092
      },
      {
        timestamp: 1441227600,
        open: 1.13177,
        close: 1.12289,
        high: 1.13206,
        low: 1.12174
      },
      {
        timestamp: 1441314000,
        open: 1.12289,
        close: 1.11241,
        high: 1.12443,
        low: 1.1088
      },
      {
        timestamp: 1441400400,
        open: 1.11241,
        close: 1.11517,
        high: 1.1192,
        low: 1.10895
      },
      {
        timestamp: 1441573200,
        open: 1.11517,
        close: 1.11654,
        high: 1.11696,
        low: 1.11517
      },
      {
        timestamp: 1441659600,
        open: 1.11654,
        close: 1.11714,
        high: 1.11785,
        low: 1.11224
      },
      {
        timestamp: 1441746000,
        open: 1.11714,
        close: 1.12038,
        high: 1.12304,
        low: 1.11532
      },
      {
        timestamp: 1441832400,
        open: 1.12038,
        close: 1.12084,
        high: 1.12172,
        low: 1.11325
      },
      {
        timestamp: 1441918800,
        open: 1.12084,
        close: 1.12813,
        high: 1.12963,
        low: 1.11724
      },
      {
        timestamp: 1442005200,
        open: 1.12813,
        close: 1.13407,
        high: 1.13504,
        low: 1.1255
      },
      {
        timestamp: 1442178000,
        open: 1.13407,
        close: 1.13347,
        high: 1.13479,
        low: 1.1333
      },
      {
        timestamp: 1442264400,
        open: 1.13347,
        close: 1.13182,
        high: 1.13741,
        low: 1.12842
      },
      {
        timestamp: 1442350800,
        open: 1.13182,
        close: 1.12712,
        high: 1.13298,
        low: 1.12593
      },
      {
        timestamp: 1442437200,
        open: 1.12712,
        close: 1.12978,
        high: 1.13215,
        low: 1.12147
      },
      {
        timestamp: 1442523600,
        open: 1.12978,
        close: 1.14362,
        high: 1.14422,
        low: 1.12852
      },
      {
        timestamp: 1442610000,
        open: 1.14362,
        close: 1.13079,
        high: 1.14612,
        low: 1.12705
      },
      {
        timestamp: 1442782800,
        open: 1.13079,
        close: 1.12861,
        high: 1.12936,
        low: 1.12668
      },
      {
        timestamp: 1442869200,
        open: 1.12861,
        close: 1.11908,
        high: 1.1331,
        low: 1.11819
      },
      {
        timestamp: 1442955600,
        open: 1.11908,
        close: 1.1122,
        high: 1.12087,
        low: 1.11142
      },
      {
        timestamp: 1443042000,
        open: 1.1122,
        close: 1.11867,
        high: 1.12144,
        low: 1.11061
      },
      {
        timestamp: 1443128400,
        open: 1.11867,
        close: 1.12314,
        high: 1.12967,
        low: 1.11652
      },
      {
        timestamp: 1443214800,
        open: 1.12314,
        close: 1.12039,
        high: 1.12359,
        low: 1.11171
      },
      {
        timestamp: 1443387600,
        open: 1.12039,
        close: 1.11934,
        high: 1.1198,
        low: 1.11767
      },
      {
        timestamp: 1443474000,
        open: 1.11934,
        close: 1.1246,
        high: 1.12487,
        low: 1.11471
      },
      {
        timestamp: 1443560400,
        open: 1.1246,
        close: 1.12528,
        high: 1.12822,
        low: 1.11947
      },
      {
        timestamp: 1443646800,
        open: 1.12528,
        close: 1.1178,
        high: 1.12622,
        low: 1.1158
      },
      {
        timestamp: 1443733200,
        open: 1.1178,
        close: 1.1197,
        high: 1.12098,
        low: 1.11359
      },
      {
        timestamp: 1443819600,
        open: 1.1197,
        close: 1.1216,
        high: 1.13195,
        low: 1.11511
      },
      {
        timestamp: 1443992400,
        open: 1.1216,
        close: 1.12221,
        high: 1.12353,
        low: 1.12105
      },
      {
        timestamp: 1444078800,
        open: 1.12221,
        close: 1.11922,
        high: 1.12902,
        low: 1.11742
      },
      {
        timestamp: 1444165200,
        open: 1.11922,
        close: 1.12758,
        high: 1.12804,
        low: 1.1173
      },
      {
        timestamp: 1444251600,
        open: 1.12758,
        close: 1.12391,
        high: 1.12855,
        low: 1.12123
      },
      {
        timestamp: 1444338000,
        open: 1.12391,
        close: 1.12797,
        high: 1.13286,
        low: 1.12355
      },
      {
        timestamp: 1444424400,
        open: 1.12797,
        close: 1.13657,
        high: 1.13878,
        low: 1.1268
      },
      {
        timestamp: 1444597200,
        open: 1.13657,
        close: 1.13686,
        high: 1.13694,
        low: 1.13583
      },
      {
        timestamp: 1444683600,
        open: 1.13686,
        close: 1.13595,
        high: 1.13977,
        low: 1.13552
      },
      {
        timestamp: 1444770000,
        open: 1.13595,
        close: 1.13804,
        high: 1.14118,
        low: 1.13451
      },
      {
        timestamp: 1444856400,
        open: 1.13804,
        close: 1.14754,
        high: 1.14899,
        low: 1.13788
      },
      {
        timestamp: 1444942800,
        open: 1.14754,
        close: 1.13954,
        high: 1.14961,
        low: 1.1364
      },
      {
        timestamp: 1445029200,
        open: 1.13954,
        close: 1.13504,
        high: 1.1401,
        low: 1.13352
      },
      {
        timestamp: 1445202000,
        open: 1.13504,
        close: 1.13651,
        high: 1.13682,
        low: 1.1347
      },
      {
        timestamp: 1445288400,
        open: 1.13651,
        close: 1.13293,
        high: 1.13798,
        low: 1.13065
      },
      {
        timestamp: 1445374800,
        open: 1.13293,
        close: 1.1347,
        high: 1.13879,
        low: 1.13249
      },
      {
        timestamp: 1445461200,
        open: 1.1347,
        close: 1.13405,
        high: 1.13784,
        low: 1.13348
      },
      {
        timestamp: 1445547600,
        open: 1.13405,
        close: 1.11098,
        high: 1.13521,
        low: 1.11009
      },
      {
        timestamp: 1445634000,
        open: 1.11098,
        close: 1.10246,
        high: 1.11406,
        low: 1.09974
      },
      {
        timestamp: 1445806800,
        open: 1.10246,
        close: 1.10053,
        high: 1.10103,
        low: 1.09901
      },
      {
        timestamp: 1445893200,
        open: 1.10053,
        close: 1.10594,
        high: 1.10691,
        low: 1.10044
      },
      {
        timestamp: 1445979600,
        open: 1.10594,
        close: 1.10517,
        high: 1.10796,
        low: 1.10311
      },
      {
        timestamp: 1446066000,
        open: 1.10517,
        close: 1.09259,
        high: 1.10966,
        low: 1.08977
      },
      {
        timestamp: 1446152400,
        open: 1.09259,
        close: 1.09813,
        high: 1.09872,
        low: 1.09031
      },
      {
        timestamp: 1446238800,
        open: 1.09813,
        close: 1.10139,
        high: 1.10734,
        low: 1.09664
      },
      {
        timestamp: 1446415200,
        open: 1.10139,
        close: 1.10376,
        high: 1.10465,
        low: 1.10139
      },
      {
        timestamp: 1446501600,
        open: 1.10376,
        close: 1.10204,
        high: 1.10536,
        low: 1.10012
      },
      {
        timestamp: 1446588000,
        open: 1.10204,
        close: 1.09697,
        high: 1.10308,
        low: 1.09366
      },
      {
        timestamp: 1446674400,
        open: 1.09697,
        close: 1.08684,
        high: 1.09689,
        low: 1.08449
      },
      {
        timestamp: 1446760800,
        open: 1.08684,
        close: 1.08855,
        high: 1.08986,
        low: 1.08344
      },
      {
        timestamp: 1446847200,
        open: 1.08855,
        close: 1.07473,
        high: 1.08947,
        low: 1.07065
      },
      {
        timestamp: 1447020000,
        open: 1.07473,
        close: 1.07339,
        high: 1.07408,
        low: 1.07241
      },
      {
        timestamp: 1447106400,
        open: 1.07339,
        close: 1.07526,
        high: 1.07908,
        low: 1.07203
      },
      {
        timestamp: 1447192800,
        open: 1.07526,
        close: 1.07258,
        high: 1.07648,
        low: 1.06751
      },
      {
        timestamp: 1447279200,
        open: 1.07258,
        close: 1.07484,
        high: 1.07746,
        low: 1.07063
      },
      {
        timestamp: 1447365600,
        open: 1.07484,
        close: 1.08163,
        high: 1.08318,
        low: 1.0692
      },
      {
        timestamp: 1447452000,
        open: 1.08163,
        close: 1.07762,
        high: 1.08183,
        low: 1.0715
      },
      {
        timestamp: 1447624800,
        open: 1.07762,
        close: 1.07425,
        high: 1.07451,
        low: 1.07244
      },
      {
        timestamp: 1447711200,
        open: 1.07425,
        close: 1.06882,
        high: 1.07591,
        low: 1.06753
      },
      {
        timestamp: 1447797600,
        open: 1.06882,
        close: 1.06438,
        high: 1.06916,
        low: 1.06313
      },
      {
        timestamp: 1447884000,
        open: 1.06438,
        close: 1.06614,
        high: 1.06934,
        low: 1.06176
      },
      {
        timestamp: 1447970400,
        open: 1.06614,
        close: 1.07363,
        high: 1.07637,
        low: 1.06564
      },
      {
        timestamp: 1448056800,
        open: 1.07363,
        close: 1.06548,
        high: 1.07396,
        low: 1.0641
      },
      {
        timestamp: 1448229600,
        open: 1.06548,
        close: 1.06416,
        high: 1.06516,
        low: 1.0637
      },
      {
        timestamp: 1448316000,
        open: 1.06416,
        close: 1.06378,
        high: 1.06578,
        low: 1.05934
      },
      {
        timestamp: 1448402400,
        open: 1.06378,
        close: 1.06443,
        high: 1.06743,
        low: 1.06205
      },
      {
        timestamp: 1448488800,
        open: 1.06443,
        close: 1.06261,
        high: 1.06902,
        low: 1.05668
      },
      {
        timestamp: 1448575200,
        open: 1.06261,
        close: 1.06125,
        high: 1.06284,
        low: 1.06008
      },
      {
        timestamp: 1448661600,
        open: 1.06125,
        close: 1.06015,
        high: 1.06389,
        low: 1.05692
      },
      {
        timestamp: 1448834400,
        open: 1.06015,
        close: 1.05924,
        high: 1.05959,
        low: 1.05893
      },
      {
        timestamp: 1448920800,
        open: 1.05924,
        close: 1.05665,
        high: 1.05959,
        low: 1.05586
      },
      {
        timestamp: 1449007200,
        open: 1.05665,
        close: 1.06341,
        high: 1.06379,
        low: 1.05641
      },
      {
        timestamp: 1449093600,
        open: 1.06341,
        close: 1.0617,
        high: 1.06386,
        low: 1.05514
      },
      {
        timestamp: 1449180000,
        open: 1.0617,
        close: 1.09423,
        high: 1.09817,
        low: 1.05262
      },
      {
        timestamp: 1449266400,
        open: 1.09423,
        close: 1.08823,
        high: 1.09572,
        low: 1.08371
      },
      {
        timestamp: 1449439200,
        open: 1.08823,
        close: 1.088,
        high: 1.088,
        low: 1.0869
      },
      {
        timestamp: 1449525600,
        open: 1.088,
        close: 1.08386,
        high: 1.08883,
        low: 1.07968
      },
      {
        timestamp: 1449612000,
        open: 1.08386,
        close: 1.08935,
        high: 1.09037,
        low: 1.08311
      },
      {
        timestamp: 1449698400,
        open: 1.08935,
        close: 1.10265,
        high: 1.10437,
        low: 1.088
      },
      {
        timestamp: 1449784800,
        open: 1.10265,
        close: 1.09424,
        high: 1.10283,
        low: 1.09259
      },
      {
        timestamp: 1449871200,
        open: 1.09424,
        close: 1.09972,
        high: 1.10317,
        low: 1.09273
      },
      {
        timestamp: 1450044000,
        open: 1.09972,
        close: 1.09874,
        high: 1.10004,
        low: 1.09795
      },
      {
        timestamp: 1450130400,
        open: 1.09874,
        close: 1.09932,
        high: 1.10495,
        low: 1.09463
      },
      {
        timestamp: 1450216800,
        open: 1.09932,
        close: 1.09356,
        high: 1.10605,
        low: 1.09053
      },
      {
        timestamp: 1450303200,
        open: 1.09356,
        close: 1.09147,
        high: 1.10131,
        low: 1.08888
      },
      {
        timestamp: 1450389600,
        open: 1.09147,
        close: 1.08271,
        high: 1.09169,
        low: 1.08034
      },
      {
        timestamp: 1450476000,
        open: 1.08271,
        close: 1.08722,
        high: 1.08755,
        low: 1.08056
      },
      {
        timestamp: 1450648800,
        open: 1.08722,
        close: 1.0861,
        high: 1.08746,
        low: 1.08551
      },
      {
        timestamp: 1450735200,
        open: 1.0861,
        close: 1.09165,
        high: 1.094,
        low: 1.0849
      },
      {
        timestamp: 1450821600,
        open: 1.09165,
        close: 1.0958,
        high: 1.0985,
        low: 1.09032
      },
      {
        timestamp: 1450908000,
        open: 1.0958,
        close: 1.09123,
        high: 1.09584,
        low: 1.08706
      },
      {
        timestamp: 1450994400,
        open: 1.09123,
        close: 1.09561,
        high: 1.09684,
        low: 1.09052
      },
      {
        timestamp: 1451340000,
        open: 1.09561,
        close: 1.09705,
        high: 1.09932,
        low: 1.09567
      },
      {
        timestamp: 1451426400,
        open: 1.09705,
        close: 1.09212,
        high: 1.09925,
        low: 1.08997
      },
      {
        timestamp: 1451512800,
        open: 1.09212,
        close: 1.09333,
        high: 1.09447,
        low: 1.09029
      },
      {
        timestamp: 1451599200,
        open: 1.09333,
        close: 1.08683,
        high: 1.0938,
        low: 1.08537
      },
      {
        timestamp: 1451858400,
        open: 1.08683,
        close: 1.08755,
        high: 1.08841,
        low: 1.08585
      },
      {
        timestamp: 1451944800,
        open: 1.08755,
        close: 1.08351,
        high: 1.09472,
        low: 1.0782
      },
      {
        timestamp: 1452031200,
        open: 1.08351,
        close: 1.07493,
        high: 1.08398,
        low: 1.07115
      },
      {
        timestamp: 1452117600,
        open: 1.07493,
        close: 1.07834,
        high: 1.08003,
        low: 1.07169
      },
      {
        timestamp: 1452204000,
        open: 1.07834,
        close: 1.09353,
        high: 1.09406,
        low: 1.07718
      },
      {
        timestamp: 1452290400,
        open: 1.09353,
        close: 1.0932,
        high: 1.09365,
        low: 1.0804
      },
      {
        timestamp: 1452463200,
        open: 1.0932,
        close: 1.09239,
        high: 1.09297,
        low: 1.09138
      },
      {
        timestamp: 1452549600,
        open: 1.09239,
        close: 1.08598,
        high: 1.09712,
        low: 1.08486
      },
      {
        timestamp: 1452636000,
        open: 1.08598,
        close: 1.08604,
        high: 1.0901,
        low: 1.08202
      },
      {
        timestamp: 1452722400,
        open: 1.08604,
        close: 1.08789,
        high: 1.08887,
        low: 1.08057
      },
      {
        timestamp: 1452808800,
        open: 1.08789,
        close: 1.08663,
        high: 1.09441,
        low: 1.08355
      },
      {
        timestamp: 1452895200,
        open: 1.08663,
        close: 1.09195,
        high: 1.09854,
        low: 1.08552
      },
      {
        timestamp: 1453068000,
        open: 1.09195,
        close: 1.09301,
        high: 1.0944,
        low: 1.09195
      },
      {
        timestamp: 1453154400,
        open: 1.09301,
        close: 1.08954,
        high: 1.09309,
        low: 1.08697
      },
      {
        timestamp: 1453240800,
        open: 1.08954,
        close: 1.09083,
        high: 1.09399,
        low: 1.08603
      },
      {
        timestamp: 1453327200,
        open: 1.09083,
        close: 1.08952,
        high: 1.09768,
        low: 1.08782
      },
      {
        timestamp: 1453413600,
        open: 1.08952,
        close: 1.08749,
        high: 1.09229,
        low: 1.07786
      },
      {
        timestamp: 1453500000,
        open: 1.08749,
        close: 1.08055,
        high: 1.08775,
        low: 1.07898
      },
      {
        timestamp: 1453672800,
        open: 1.08055,
        close: 1.07957,
        high: 1.08073,
        low: 1.07904
      },
      {
        timestamp: 1453759200,
        open: 1.07957,
        close: 1.08505,
        high: 1.08605,
        low: 1.07902
      },
      {
        timestamp: 1453845600,
        open: 1.08505,
        close: 1.08723,
        high: 1.08753,
        low: 1.08195
      },
      {
        timestamp: 1453932000,
        open: 1.08723,
        close: 1.08935,
        high: 1.09173,
        low: 1.08516
      },
      {
        timestamp: 1454018400,
        open: 1.08935,
        close: 1.09422,
        high: 1.09685,
        low: 1.08705
      },
      {
        timestamp: 1454104800,
        open: 1.09422,
        close: 1.08336,
        high: 1.09496,
        low: 1.08107
      },
      {
        timestamp: 1454277600,
        open: 1.08336,
        close: 1.08349,
        high: 1.08402,
        low: 1.08282
      },
      {
        timestamp: 1454364000,
        open: 1.08349,
        close: 1.08887,
        high: 1.09136,
        low: 1.08156
      },
      {
        timestamp: 1454450400,
        open: 1.08887,
        close: 1.09211,
        high: 1.09406,
        low: 1.08864
      },
      {
        timestamp: 1454536800,
        open: 1.09211,
        close: 1.11103,
        high: 1.11465,
        low: 1.09045
      },
      {
        timestamp: 1454623200,
        open: 1.11103,
        close: 1.12105,
        high: 1.12398,
        low: 1.10704
      },
      {
        timestamp: 1454709600,
        open: 1.12105,
        close: 1.11647,
        high: 1.12526,
        low: 1.11096
      },
      {
        timestamp: 1454882400,
        open: 1.11647,
        close: 1.11456,
        high: 1.11558,
        low: 1.11424
      },
      {
        timestamp: 1454968800,
        open: 1.11456,
        close: 1.11966,
        high: 1.12168,
        low: 1.10872
      },
      {
        timestamp: 1455055200,
        open: 1.11966,
        close: 1.12942,
        high: 1.13389,
        low: 1.1163
      },
      {
        timestamp: 1455141600,
        open: 1.12942,
        close: 1.1296,
        high: 1.13123,
        low: 1.11615
      },
      {
        timestamp: 1455228000,
        open: 1.1296,
        close: 1.13255,
        high: 1.13775,
        low: 1.12748
      },
      {
        timestamp: 1455314400,
        open: 1.13255,
        close: 1.12607,
        high: 1.13349,
        low: 1.12149
      },
      {
        timestamp: 1455487200,
        open: 1.12607,
        close: 1.12416,
        high: 1.12454,
        low: 1.12325
      },
      {
        timestamp: 1455573600,
        open: 1.12416,
        close: 1.11564,
        high: 1.12555,
        low: 1.11287
      },
      {
        timestamp: 1455660000,
        open: 1.11564,
        close: 1.11445,
        high: 1.11938,
        low: 1.1125
      },
      {
        timestamp: 1455746400,
        open: 1.11445,
        close: 1.11303,
        high: 1.11801,
        low: 1.11062
      },
      {
        timestamp: 1455832800,
        open: 1.11303,
        close: 1.11088,
        high: 1.11505,
        low: 1.10718
      },
      {
        timestamp: 1455919200,
        open: 1.11088,
        close: 1.11332,
        high: 1.11402,
        low: 1.10675
      },
      {
        timestamp: 1456092000,
        open: 1.11332,
        close: 1.11148,
        high: 1.11278,
        low: 1.11095
      },
      {
        timestamp: 1456178400,
        open: 1.11148,
        close: 1.10309,
        high: 1.1126,
        low: 1.10039
      },
      {
        timestamp: 1456264800,
        open: 1.10309,
        close: 1.1022,
        high: 1.10537,
        low: 1.09908
      },
      {
        timestamp: 1456351200,
        open: 1.1022,
        close: 1.10145,
        high: 1.10474,
        low: 1.09581
      },
      {
        timestamp: 1456437600,
        open: 1.10145,
        close: 1.10191,
        high: 1.10506,
        low: 1.09875
      },
      {
        timestamp: 1456524000,
        open: 1.10191,
        close: 1.09368,
        high: 1.10692,
        low: 1.09126
      },
      {
        timestamp: 1456696800,
        open: 1.09368,
        close: 1.09185,
        high: 1.09318,
        low: 1.09148
      },
      {
        timestamp: 1456783200,
        open: 1.09185,
        close: 1.0874,
        high: 1.09632,
        low: 1.08598
      },
      {
        timestamp: 1456869600,
        open: 1.0874,
        close: 1.08708,
        high: 1.08945,
        low: 1.0835
      },
      {
        timestamp: 1456956000,
        open: 1.08708,
        close: 1.08695,
        high: 1.08819,
        low: 1.08264
      },
      {
        timestamp: 1457042400,
        open: 1.08695,
        close: 1.09578,
        high: 1.09738,
        low: 1.08543
      },
      {
        timestamp: 1457128800,
        open: 1.09578,
        close: 1.1014,
        high: 1.10443,
        low: 1.09043
      },
      {
        timestamp: 1457301600,
        open: 1.1014,
        close: 1.09945,
        high: 1.10015,
        low: 1.09843
      },
      {
        timestamp: 1457388000,
        open: 1.09945,
        close: 1.10155,
        high: 1.10266,
        low: 1.09408
      },
      {
        timestamp: 1457474400,
        open: 1.10155,
        close: 1.10118,
        high: 1.10587,
        low: 1.09943
      },
      {
        timestamp: 1457560800,
        open: 1.10118,
        close: 1.10036,
        high: 1.10357,
        low: 1.09468
      },
      {
        timestamp: 1457647200,
        open: 1.10036,
        close: 1.1185,
        high: 1.1219,
        low: 1.08223
      },
      {
        timestamp: 1457733600,
        open: 1.1185,
        close: 1.11511,
        high: 1.12106,
        low: 1.10813
      },
      {
        timestamp: 1457902800,
        open: 1.11511,
        close: 1.11411,
        high: 1.11489,
        low: 1.11343
      },
      {
        timestamp: 1457989200,
        open: 1.11411,
        close: 1.11068,
        high: 1.11777,
        low: 1.10785
      },
      {
        timestamp: 1458075600,
        open: 1.11068,
        close: 1.11131,
        high: 1.11256,
        low: 1.10727
      },
      {
        timestamp: 1458162000,
        open: 1.11131,
        close: 1.12255,
        high: 1.12435,
        low: 1.10586
      },
      {
        timestamp: 1458248400,
        open: 1.12255,
        close: 1.13202,
        high: 1.13434,
        low: 1.12057
      },
      {
        timestamp: 1458334800,
        open: 1.13202,
        close: 1.12744,
        high: 1.13378,
        low: 1.12569
      },
      {
        timestamp: 1458507600,
        open: 1.12744,
        close: 1.12699,
        high: 1.12699,
        low: 1.12576
      },
      {
        timestamp: 1458594000,
        open: 1.12699,
        close: 1.12442,
        high: 1.12856,
        low: 1.12351
      },
      {
        timestamp: 1458680400,
        open: 1.12442,
        close: 1.12183,
        high: 1.12608,
        low: 1.11894
      },
      {
        timestamp: 1458766800,
        open: 1.12183,
        close: 1.11863,
        high: 1.12245,
        low: 1.11603
      },
      {
        timestamp: 1458853200,
        open: 1.11863,
        close: 1.11777,
        high: 1.11886,
        low: 1.11449
      },
      {
        timestamp: 1458939600,
        open: 1.11777,
        close: 1.11723,
        high: 1.11834,
        low: 1.11548
      },
      {
        timestamp: 1459112400,
        open: 1.11723,
        close: 1.11665,
        high: 1.11732,
        low: 1.11621
      },
      {
        timestamp: 1459198800,
        open: 1.11665,
        close: 1.11982,
        high: 1.12208,
        low: 1.11538
      },
      {
        timestamp: 1459285200,
        open: 1.11982,
        close: 1.12927,
        high: 1.13042,
        low: 1.11699
      },
      {
        timestamp: 1459371600,
        open: 1.12927,
        close: 1.13428,
        high: 1.13661,
        low: 1.12847
      },
      {
        timestamp: 1459458000,
        open: 1.13428,
        close: 1.1384,
        high: 1.14128,
        low: 1.1311
      },
      {
        timestamp: 1459544400,
        open: 1.1384,
        close: 1.14002,
        high: 1.14386,
        low: 1.13354
      },
      {
        timestamp: 1459717200,
        open: 1.14002,
        close: 1.14017,
        high: 1.14048,
        low: 1.13899
      },
      {
        timestamp: 1459803600,
        open: 1.14017,
        close: 1.13941,
        high: 1.14136,
        low: 1.13582
      },
      {
        timestamp: 1459890000,
        open: 1.13941,
        close: 1.13913,
        high: 1.14061,
        low: 1.13367
      },
      {
        timestamp: 1459976400,
        open: 1.13913,
        close: 1.14012,
        high: 1.14327,
        low: 1.13276
      },
      {
        timestamp: 1460062800,
        open: 1.14012,
        close: 1.13802,
        high: 1.14552,
        low: 1.13383
      },
      {
        timestamp: 1460149200,
        open: 1.13802,
        close: 1.1406,
        high: 1.14203,
        low: 1.13499
      },
      {
        timestamp: 1460322000,
        open: 1.1406,
        close: 1.14193,
        high: 1.14236,
        low: 1.1406
      },
      {
        timestamp: 1460408400,
        open: 1.14193,
        close: 1.14116,
        high: 1.14484,
        low: 1.13732
      },
      {
        timestamp: 1460494800,
        open: 1.14116,
        close: 1.13882,
        high: 1.14658,
        low: 1.13464
      },
      {
        timestamp: 1460581200,
        open: 1.13882,
        close: 1.12764,
        high: 1.13934,
        low: 1.12692
      },
      {
        timestamp: 1460667600,
        open: 1.12764,
        close: 1.12699,
        high: 1.12959,
        low: 1.12348
      },
      {
        timestamp: 1460754000,
        open: 1.12699,
        close: 1.1288,
        high: 1.13178,
        low: 1.12468
      },
      {
        timestamp: 1460926800,
        open: 1.1288,
        close: 1.1307,
        high: 1.13236,
        low: 1.1288
      },
      {
        timestamp: 1461013200,
        open: 1.1307,
        close: 1.13162,
        high: 1.1333,
        low: 1.12746
      },
      {
        timestamp: 1461099600,
        open: 1.13162,
        close: 1.13589,
        high: 1.13855,
        low: 1.13043
      },
      {
        timestamp: 1461186000,
        open: 1.13589,
        close: 1.12979,
        high: 1.13893,
        low: 1.12913
      },
      {
        timestamp: 1461272400,
        open: 1.12979,
        close: 1.1289,
        high: 1.14006,
        low: 1.12705
      },
      {
        timestamp: 1461358800,
        open: 1.1289,
        close: 1.12318,
        high: 1.13096,
        low: 1.1219
      },
      {
        timestamp: 1461531600,
        open: 1.12318,
        close: 1.12204,
        high: 1.12356,
        low: 1.12186
      },
      {
        timestamp: 1461618000,
        open: 1.12204,
        close: 1.12702,
        high: 1.12791,
        low: 1.12209
      },
      {
        timestamp: 1461704400,
        open: 1.12702,
        close: 1.13012,
        high: 1.13404,
        low: 1.12572
      },
      {
        timestamp: 1461790800,
        open: 1.13012,
        close: 1.13305,
        high: 1.13631,
        low: 1.12729
      },
      {
        timestamp: 1461877200,
        open: 1.13305,
        close: 1.13553,
        high: 1.13689,
        low: 1.1297
      },
      {
        timestamp: 1461963600,
        open: 1.13553,
        close: 1.14566,
        high: 1.14602,
        low: 1.13501
      },
      {
        timestamp: 1462136400,
        open: 1.14566,
        close: 1.14648,
        high: 1.14648,
        low: 1.14335
      },
      {
        timestamp: 1462222800,
        open: 1.14648,
        close: 1.1535,
        high: 1.15371,
        low: 1.14491
      },
      {
        timestamp: 1462309200,
        open: 1.1535,
        close: 1.14985,
        high: 1.16175,
        low: 1.14964
      },
      {
        timestamp: 1462395600,
        open: 1.14985,
        close: 1.14903,
        high: 1.15306,
        low: 1.14671
      },
      {
        timestamp: 1462482000,
        open: 1.14903,
        close: 1.14071,
        high: 1.14947,
        low: 1.13866
      },
      {
        timestamp: 1462568400,
        open: 1.14071,
        close: 1.14114,
        high: 1.14843,
        low: 1.13875
      },
      {
        timestamp: 1462741200,
        open: 1.14114,
        close: 1.13995,
        high: 1.14107,
        low: 1.13951
      },
      {
        timestamp: 1462827600,
        open: 1.13995,
        close: 1.13843,
        high: 1.14208,
        low: 1.13761
      },
      {
        timestamp: 1462914000,
        open: 1.13843,
        close: 1.13742,
        high: 1.14105,
        low: 1.13593
      },
      {
        timestamp: 1463000400,
        open: 1.13742,
        close: 1.1428,
        high: 1.14474,
        low: 1.13698
      },
      {
        timestamp: 1463086800,
        open: 1.1428,
        close: 1.13803,
        high: 1.14302,
        low: 1.13713
      },
      {
        timestamp: 1463173200,
        open: 1.13803,
        close: 1.13145,
        high: 1.13806,
        low: 1.12838
      },
      {
        timestamp: 1463346000,
        open: 1.13145,
        close: 1.13146,
        high: 1.13183,
        low: 1.13055
      },
      {
        timestamp: 1463432400,
        open: 1.13146,
        close: 1.13223,
        high: 1.13432,
        low: 1.13035
      },
      {
        timestamp: 1463518800,
        open: 1.13223,
        close: 1.13165,
        high: 1.13505,
        low: 1.13028
      },
      {
        timestamp: 1463605200,
        open: 1.13165,
        close: 1.12185,
        high: 1.13177,
        low: 1.12151
      },
      {
        timestamp: 1463691600,
        open: 1.12185,
        close: 1.12038,
        high: 1.12308,
        low: 1.11806
      },
      {
        timestamp: 1463778000,
        open: 1.12038,
        close: 1.123,
        high: 1.12388,
        low: 1.11972
      },
      {
        timestamp: 1463950800,
        open: 1.123,
        close: 1.12118,
        high: 1.12237,
        low: 1.12091
      },
      {
        timestamp: 1464037200,
        open: 1.12118,
        close: 1.12224,
        high: 1.12441,
        low: 1.11883
      },
      {
        timestamp: 1464123600,
        open: 1.12224,
        close: 1.11431,
        high: 1.12281,
        low: 1.11333
      },
      {
        timestamp: 1464210000,
        open: 1.11431,
        close: 1.11558,
        high: 1.11678,
        low: 1.11297
      },
      {
        timestamp: 1464296400,
        open: 1.11558,
        close: 1.11956,
        high: 1.12176,
        low: 1.11504
      },
      {
        timestamp: 1464382800,
        open: 1.11956,
        close: 1.11184,
        high: 1.12017,
        low: 1.11119
      },
      {
        timestamp: 1464555600,
        open: 1.11184,
        close: 1.11158,
        high: 1.11237,
        low: 1.11184
      },
      {
        timestamp: 1464642000,
        open: 1.11158,
        close: 1.11404,
        high: 1.11456,
        low: 1.10986
      },
      {
        timestamp: 1464728400,
        open: 1.11404,
        close: 1.1134,
        high: 1.11742,
        low: 1.11231
      },
      {
        timestamp: 1464814800,
        open: 1.1134,
        close: 1.11908,
        high: 1.11948,
        low: 1.1115
      },
      {
        timestamp: 1464901200,
        open: 1.11908,
        close: 1.11519,
        high: 1.12211,
        low: 1.11463
      },
      {
        timestamp: 1464987600,
        open: 1.11519,
        close: 1.13726,
        high: 1.13753,
        low: 1.11374
      },
      {
        timestamp: 1465160400,
        open: 1.13726,
        close: 1.13616,
        high: 1.13707,
        low: 1.13419
      },
      {
        timestamp: 1465246800,
        open: 1.13616,
        close: 1.13569,
        high: 1.13937,
        low: 1.13271
      },
      {
        timestamp: 1465333200,
        open: 1.13569,
        close: 1.13609,
        high: 1.13813,
        low: 1.13394
      },
      {
        timestamp: 1465419600,
        open: 1.13609,
        close: 1.13993,
        high: 1.14115,
        low: 1.13553
      },
      {
        timestamp: 1465506000,
        open: 1.13993,
        close: 1.13194,
        high: 1.14164,
        low: 1.13064
      },
      {
        timestamp: 1465592400,
        open: 1.13194,
        close: 1.12593,
        high: 1.13224,
        low: 1.12461
      },
      {
        timestamp: 1465765200,
        open: 1.12593,
        close: 1.12562,
        high: 1.12686,
        low: 1.12492
      },
      {
        timestamp: 1465851600,
        open: 1.12562,
        close: 1.12953,
        high: 1.1304,
        low: 1.12332
      },
      {
        timestamp: 1465938000,
        open: 1.12953,
        close: 1.12108,
        high: 1.12994,
        low: 1.11898
      },
      {
        timestamp: 1466024400,
        open: 1.12108,
        close: 1.12622,
        high: 1.12999,
        low: 1.11907
      },
      {
        timestamp: 1466110800,
        open: 1.12622,
        close: 1.12264,
        high: 1.12958,
        low: 1.11317
      },
      {
        timestamp: 1466197200,
        open: 1.12264,
        close: 1.12825,
        high: 1.12972,
        low: 1.12237
      },
      {
        timestamp: 1466370000,
        open: 1.12825,
        close: 1.13381,
        high: 1.13475,
        low: 1.12825
      },
      {
        timestamp: 1466456400,
        open: 1.13381,
        close: 1.13182,
        high: 1.13834,
        low: 1.1303
      },
      {
        timestamp: 1466542800,
        open: 1.13182,
        close: 1.1243,
        high: 1.13507,
        low: 1.12427
      },
      {
        timestamp: 1466629200,
        open: 1.1243,
        close: 1.12974,
        high: 1.13385,
        low: 1.12375
      },
      {
        timestamp: 1466715600,
        open: 1.12974,
        close: 1.13911,
        high: 1.14226,
        low: 1.12974
      },
      {
        timestamp: 1466802000,
        open: 1.13911,
        close: 1.11257,
        high: 1.14293,
        low: 1.09136
      },
      {
        timestamp: 1466974800,
        open: 1.11257,
        close: 1.10156,
        high: 1.10836,
        low: 1.10033
      },
      {
        timestamp: 1467061200,
        open: 1.10156,
        close: 1.10286,
        high: 1.10848,
        low: 1.09717
      },
      {
        timestamp: 1467147600,
        open: 1.10286,
        close: 1.10675,
        high: 1.11126,
        low: 1.10112
      },
      {
        timestamp: 1467234000,
        open: 1.10675,
        close: 1.11268,
        high: 1.11314,
        low: 1.10501
      },
      {
        timestamp: 1467320400,
        open: 1.11268,
        close: 1.1109,
        high: 1.11554,
        low: 1.10249
      },
      {
        timestamp: 1467406800,
        open: 1.1109,
        close: 1.11438,
        high: 1.11704,
        low: 1.1073
      },
      {
        timestamp: 1467579600,
        open: 1.11438,
        close: 1.11357,
        high: 1.11412,
        low: 1.11195
      },
      {
        timestamp: 1467666000,
        open: 1.11357,
        close: 1.11568,
        high: 1.11605,
        low: 1.10987
      },
      {
        timestamp: 1467752400,
        open: 1.11568,
        close: 1.1077,
        high: 1.11876,
        low: 1.10631
      },
      {
        timestamp: 1467838800,
        open: 1.1077,
        close: 1.11019,
        high: 1.11126,
        low: 1.10298
      },
      {
        timestamp: 1467925200,
        open: 1.11019,
        close: 1.10659,
        high: 1.11081,
        low: 1.10534
      },
      {
        timestamp: 1468011600,
        open: 1.10659,
        close: 1.1053,
        high: 1.11232,
        low: 1.10026
      },
      {
        timestamp: 1468184400,
        open: 1.1053,
        close: 1.1055,
        high: 1.10566,
        low: 1.10471
      },
      {
        timestamp: 1468270800,
        open: 1.1055,
        close: 1.10588,
        high: 1.10755,
        low: 1.10164
      },
      {
        timestamp: 1468357200,
        open: 1.10588,
        close: 1.10616,
        high: 1.11268,
        low: 1.10531
      },
      {
        timestamp: 1468443600,
        open: 1.10616,
        close: 1.10907,
        high: 1.11206,
        low: 1.10428
      },
      {
        timestamp: 1468530000,
        open: 1.10907,
        close: 1.11232,
        high: 1.11657,
        low: 1.10907
      },
      {
        timestamp: 1468616400,
        open: 1.11232,
        close: 1.10417,
        high: 1.11498,
        low: 1.10259
      },
      {
        timestamp: 1468789200,
        open: 1.10417,
        close: 1.10518,
        high: 1.10807,
        low: 1.10417
      },
      {
        timestamp: 1468875600,
        open: 1.10518,
        close: 1.10766,
        high: 1.10853,
        low: 1.10382
      },
      {
        timestamp: 1468962000,
        open: 1.10766,
        close: 1.1023,
        high: 1.10813,
        low: 1.10006
      },
      {
        timestamp: 1469048400,
        open: 1.1023,
        close: 1.10178,
        high: 1.10312,
        low: 1.09822
      },
      {
        timestamp: 1469134800,
        open: 1.10178,
        close: 1.10289,
        high: 1.10615,
        low: 1.09801
      },
      {
        timestamp: 1469221200,
        open: 1.10289,
        close: 1.09833,
        high: 1.10419,
        low: 1.09563
      },
      {
        timestamp: 1469394000,
        open: 1.09833,
        close: 1.09797,
        high: 1.09923,
        low: 1.0976
      },
      {
        timestamp: 1469480400,
        open: 1.09797,
        close: 1.09978,
        high: 1.10002,
        low: 1.09529
      },
      {
        timestamp: 1469566800,
        open: 1.09978,
        close: 1.09878,
        high: 1.10314,
        low: 1.0979
      },
      {
        timestamp: 1469653200,
        open: 1.09878,
        close: 1.106,
        high: 1.10658,
        low: 1.09623
      },
      {
        timestamp: 1469739600,
        open: 1.106,
        close: 1.10791,
        high: 1.11204,
        low: 1.10533
      },
      {
        timestamp: 1469826000,
        open: 1.10791,
        close: 1.11792,
        high: 1.11987,
        low: 1.1074
      },
      {
        timestamp: 1469998800,
        open: 1.11792,
        close: 1.11787,
        high: 1.11843,
        low: 1.11792
      },
      {
        timestamp: 1470085200,
        open: 1.11787,
        close: 1.11672,
        high: 1.11853,
        low: 1.11559
      },
      {
        timestamp: 1470171600,
        open: 1.11672,
        close: 1.12261,
        high: 1.12346,
        low: 1.116
      },
      {
        timestamp: 1470258000,
        open: 1.12261,
        close: 1.11507,
        high: 1.12289,
        low: 1.11415
      },
      {
        timestamp: 1470344400,
        open: 1.11507,
        close: 1.11314,
        high: 1.11574,
        low: 1.11148
      },
      {
        timestamp: 1470430800,
        open: 1.11314,
        close: 1.1094,
        high: 1.11652,
        low: 1.10465
      },
      {
        timestamp: 1470603600,
        open: 1.1094,
        close: 1.1086,
        high: 1.10954,
        low: 1.10772
      },
      {
        timestamp: 1470690000,
        open: 1.1086,
        close: 1.10886,
        high: 1.11059,
        low: 1.10728
      },
      {
        timestamp: 1470776400,
        open: 1.10886,
        close: 1.11183,
        high: 1.11235,
        low: 1.10714
      },
      {
        timestamp: 1470862800,
        open: 1.11183,
        close: 1.11863,
        high: 1.1191,
        low: 1.11133
      },
      {
        timestamp: 1470949200,
        open: 1.11863,
        close: 1.1138,
        high: 1.11926,
        low: 1.11363
      },
      {
        timestamp: 1471035600,
        open: 1.1138,
        close: 1.11703,
        high: 1.12223,
        low: 1.11322
      },
      {
        timestamp: 1471208400,
        open: 1.11703,
        close: 1.11723,
        high: 1.11787,
        low: 1.11703
      },
      {
        timestamp: 1471294800,
        open: 1.11723,
        close: 1.11855,
        high: 1.12045,
        low: 1.11543
      },
      {
        timestamp: 1471381200,
        open: 1.11855,
        close: 1.12802,
        high: 1.13235,
        low: 1.11783
      },
      {
        timestamp: 1471467600,
        open: 1.12802,
        close: 1.1295,
        high: 1.1317,
        low: 1.12426
      },
      {
        timestamp: 1471554000,
        open: 1.1295,
        close: 1.13564,
        high: 1.13672,
        low: 1.12881
      },
      {
        timestamp: 1471640400,
        open: 1.13564,
        close: 1.13321,
        high: 1.13612,
        low: 1.1305
      },
      {
        timestamp: 1471813200,
        open: 1.13321,
        close: 1.13123,
        high: 1.13357,
        low: 1.13074
      },
      {
        timestamp: 1471899600,
        open: 1.13123,
        close: 1.13215,
        high: 1.13314,
        low: 1.12717
      },
      {
        timestamp: 1471986000,
        open: 1.13215,
        close: 1.13079,
        high: 1.1356,
        low: 1.1304
      },
      {
        timestamp: 1472072400,
        open: 1.13079,
        close: 1.1266,
        high: 1.13124,
        low: 1.1246
      },
      {
        timestamp: 1472158800,
        open: 1.1266,
        close: 1.1287,
        high: 1.12984,
        low: 1.12599
      },
      {
        timestamp: 1472245200,
        open: 1.1287,
        close: 1.11977,
        high: 1.13421,
        low: 1.11814
      },
      {
        timestamp: 1472418000,
        open: 1.11977,
        close: 1.11804,
        high: 1.12033,
        low: 1.11756
      },
      {
        timestamp: 1472504400,
        open: 1.11804,
        close: 1.11906,
        high: 1.12087,
        low: 1.11589
      },
      {
        timestamp: 1472590800,
        open: 1.11906,
        close: 1.11445,
        high: 1.11939,
        low: 1.11328
      },
      {
        timestamp: 1472677200,
        open: 1.11445,
        close: 1.11601,
        high: 1.11663,
        low: 1.11239
      },
      {
        timestamp: 1472763600,
        open: 1.11601,
        close: 1.1198,
        high: 1.1206,
        low: 1.11284
      },
      {
        timestamp: 1472850000,
        open: 1.1198,
        close: 1.11577,
        high: 1.12534,
        low: 1.1151
      },
      {
        timestamp: 1473022800,
        open: 1.11577,
        close: 1.11595,
        high: 1.11629,
        low: 1.11477
      },
      {
        timestamp: 1473109200,
        open: 1.11595,
        close: 1.11485,
        high: 1.11833,
        low: 1.11399
      },
      {
        timestamp: 1473195600,
        open: 1.11485,
        close: 1.12574,
        high: 1.12639,
        low: 1.11407
      },
      {
        timestamp: 1473282000,
        open: 1.12574,
        close: 1.12399,
        high: 1.12728,
        low: 1.12295
      },
      {
        timestamp: 1473368400,
        open: 1.12399,
        close: 1.12603,
        high: 1.13281,
        low: 1.12352
      },
      {
        timestamp: 1473454800,
        open: 1.12603,
        close: 1.12339,
        high: 1.12858,
        low: 1.11992
      },
      {
        timestamp: 1473627600,
        open: 1.12339,
        close: 1.12385,
        high: 1.12468,
        low: 1.12342
      },
      {
        timestamp: 1473714000,
        open: 1.12385,
        close: 1.12367,
        high: 1.12697,
        low: 1.1211
      },
      {
        timestamp: 1473800400,
        open: 1.12367,
        close: 1.12224,
        high: 1.12613,
        low: 1.12046
      },
      {
        timestamp: 1473886800,
        open: 1.12224,
        close: 1.12528,
        high: 1.1275,
        low: 1.12114
      },
      {
        timestamp: 1473973200,
        open: 1.12528,
        close: 1.12444,
        high: 1.12861,
        low: 1.12199
      },
      {
        timestamp: 1474059600,
        open: 1.12444,
        close: 1.11607,
        high: 1.1251,
        low: 1.11499
      },
      {
        timestamp: 1474232400,
        open: 1.11607,
        close: 1.11541,
        high: 1.11703,
        low: 1.11505
      },
      {
        timestamp: 1474318800,
        open: 1.11541,
        close: 1.11786,
        high: 1.11986,
        low: 1.11517
      },
      {
        timestamp: 1474405200,
        open: 1.11786,
        close: 1.11518,
        high: 1.12144,
        low: 1.11504
      },
      {
        timestamp: 1474491600,
        open: 1.11518,
        close: 1.11931,
        high: 1.11976,
        low: 1.11237
      },
      {
        timestamp: 1474578000,
        open: 1.11931,
        close: 1.12086,
        high: 1.12581,
        low: 1.11856
      },
      {
        timestamp: 1474664400,
        open: 1.12086,
        close: 1.123,
        high: 1.12414,
        low: 1.11946
      },
      {
        timestamp: 1474837200,
        open: 1.123,
        close: 1.12334,
        high: 1.12342,
        low: 1.12271
      },
      {
        timestamp: 1474923600,
        open: 1.12334,
        close: 1.12555,
        high: 1.12798,
        low: 1.1222
      },
      {
        timestamp: 1475010000,
        open: 1.12555,
        close: 1.12195,
        high: 1.12597,
        low: 1.11916
      },
      {
        timestamp: 1475096400,
        open: 1.12195,
        close: 1.12182,
        high: 1.12377,
        low: 1.11827
      },
      {
        timestamp: 1475182800,
        open: 1.12182,
        close: 1.12249,
        high: 1.12506,
        low: 1.11976
      },
      {
        timestamp: 1475269200,
        open: 1.12249,
        close: 1.12447,
        high: 1.12518,
        low: 1.11541
      },
      {
        timestamp: 1475442000,
        open: 1.12447,
        close: 1.12342,
        high: 1.12423,
        low: 1.12251
      },
      {
        timestamp: 1475528400,
        open: 1.12342,
        close: 1.1214,
        high: 1.12465,
        low: 1.1206
      },
      {
        timestamp: 1475614800,
        open: 1.1214,
        close: 1.1208,
        high: 1.12397,
        low: 1.11385
      },
      {
        timestamp: 1475701200,
        open: 1.1208,
        close: 1.12074,
        high: 1.1234,
        low: 1.11904
      },
      {
        timestamp: 1475787600,
        open: 1.12074,
        close: 1.11533,
        high: 1.12134,
        low: 1.11406
      },
      {
        timestamp: 1475874000,
        open: 1.11533,
        close: 1.12049,
        high: 1.12057,
        low: 1.11052
      },
      {
        timestamp: 1476046800,
        open: 1.12049,
        close: 1.11804,
        high: 1.12014,
        low: 1.11742
      },
      {
        timestamp: 1476133200,
        open: 1.11804,
        close: 1.11411,
        high: 1.12057,
        low: 1.11321
      },
      {
        timestamp: 1476219600,
        open: 1.11411,
        close: 1.10551,
        high: 1.11432,
        low: 1.10496
      },
      {
        timestamp: 1476306000,
        open: 1.10551,
        close: 1.101,
        high: 1.10686,
        low: 1.10053
      },
      {
        timestamp: 1476392400,
        open: 1.101,
        close: 1.10575,
        high: 1.10586,
        low: 1.09861
      },
      {
        timestamp: 1476478800,
        open: 1.10575,
        close: 1.09735,
        high: 1.10606,
        low: 1.09714
      },
      {
        timestamp: 1476651600,
        open: 1.09735,
        close: 1.09706,
        high: 1.09777,
        low: 1.09705
      },
      {
        timestamp: 1476738000,
        open: 1.09706,
        close: 1.10013,
        high: 1.10087,
        low: 1.09648
      },
      {
        timestamp: 1476824400,
        open: 1.10013,
        close: 1.09824,
        high: 1.10272,
        low: 1.09709
      },
      {
        timestamp: 1476910800,
        open: 1.09824,
        close: 1.09751,
        high: 1.10057,
        low: 1.09556
      },
      {
        timestamp: 1476997200,
        open: 1.09751,
        close: 1.09306,
        high: 1.10405,
        low: 1.09167
      },
      {
        timestamp: 1477083600,
        open: 1.09306,
        close: 1.08879,
        high: 1.09322,
        low: 1.08602
      },
      {
        timestamp: 1477256400,
        open: 1.08879,
        close: 1.08848,
        high: 1.08899,
        low: 1.08797
      },
      {
        timestamp: 1477342800,
        open: 1.08848,
        close: 1.08838,
        high: 1.09004,
        low: 1.08605
      },
      {
        timestamp: 1477429200,
        open: 1.08838,
        close: 1.08919,
        high: 1.09058,
        low: 1.08518
      },
      {
        timestamp: 1477515600,
        open: 1.08919,
        close: 1.09108,
        high: 1.09476,
        low: 1.08753
      },
      {
        timestamp: 1477602000,
        open: 1.09108,
        close: 1.09004,
        high: 1.09431,
        low: 1.08834
      },
      {
        timestamp: 1477688400,
        open: 1.09004,
        close: 1.09913,
        high: 1.09946,
        low: 1.0894
      },
      {
        timestamp: 1477861200,
        open: 1.09913,
        close: 1.09935,
        high: 1.09965,
        low: 1.09856
      },
      {
        timestamp: 1477947600,
        open: 1.09935,
        close: 1.09822,
        high: 1.09935,
        low: 1.09366
      },
      {
        timestamp: 1478034000,
        open: 1.09822,
        close: 1.10564,
        high: 1.107,
        low: 1.09609
      },
      {
        timestamp: 1478120400,
        open: 1.10564,
        close: 1.10983,
        high: 1.11239,
        low: 1.10505
      },
      {
        timestamp: 1478206800,
        open: 1.10983,
        close: 1.11073,
        high: 1.11267,
        low: 1.10605
      },
      {
        timestamp: 1478293200,
        open: 1.11073,
        close: 1.11431,
        high: 1.11434,
        low: 1.10807
      },
      {
        timestamp: 1478469600,
        open: 1.11431,
        close: 1.10652,
        high: 1.1122,
        low: 1.10462
      },
      {
        timestamp: 1478556000,
        open: 1.10652,
        close: 1.10413,
        high: 1.11112,
        low: 1.10282
      },
      {
        timestamp: 1478642400,
        open: 1.10413,
        close: 1.10285,
        high: 1.10679,
        low: 1.10096
      },
      {
        timestamp: 1478728800,
        open: 1.10285,
        close: 1.09122,
        high: 1.13007,
        low: 1.09079
      },
      {
        timestamp: 1478815200,
        open: 1.09122,
        close: 1.08962,
        high: 1.09546,
        low: 1.08657
      },
      {
        timestamp: 1478901600,
        open: 1.08962,
        close: 1.08628,
        high: 1.09242,
        low: 1.08312
      },
      {
        timestamp: 1479074400,
        open: 1.08628,
        close: 1.08354,
        high: 1.08395,
        low: 1.08279
      },
      {
        timestamp: 1479160800,
        open: 1.08354,
        close: 1.07386,
        high: 1.08427,
        low: 1.071
      },
      {
        timestamp: 1479247200,
        open: 1.07386,
        close: 1.07251,
        high: 1.08176,
        low: 1.07148
      },
      {
        timestamp: 1479333600,
        open: 1.07251,
        close: 1.06927,
        high: 1.07604,
        low: 1.06668
      },
      {
        timestamp: 1479420000,
        open: 1.06927,
        close: 1.06274,
        high: 1.07464,
        low: 1.06207
      },
      {
        timestamp: 1479506400,
        open: 1.06274,
        close: 1.05917,
        high: 1.06437,
        low: 1.05701
      },
      {
        timestamp: 1479679200,
        open: 1.05917,
        close: 1.05955,
        high: 1.06086,
        low: 1.05917
      },
      {
        timestamp: 1479765600,
        open: 1.05955,
        close: 1.06343,
        high: 1.06499,
        low: 1.05799
      },
      {
        timestamp: 1479852000,
        open: 1.06343,
        close: 1.06305,
        high: 1.06587,
        low: 1.05843
      },
      {
        timestamp: 1479938400,
        open: 1.06305,
        close: 1.05578,
        high: 1.06445,
        low: 1.0527
      },
      {
        timestamp: 1480024800,
        open: 1.05578,
        close: 1.05595,
        high: 1.05863,
        low: 1.05189
      },
      {
        timestamp: 1480111200,
        open: 1.05595,
        close: 1.05996,
        high: 1.06282,
        low: 1.05394
      },
      {
        timestamp: 1480284000,
        open: 1.05996,
        close: 1.06135,
        high: 1.06312,
        low: 1.05996
      },
      {
        timestamp: 1480370400,
        open: 1.06135,
        close: 1.06164,
        high: 1.06869,
        low: 1.05645
      },
      {
        timestamp: 1480456800,
        open: 1.06164,
        close: 1.06508,
        high: 1.06553,
        low: 1.05657
      },
      {
        timestamp: 1480543200,
        open: 1.06508,
        close: 1.05902,
        high: 1.06674,
        low: 1.05534
      },
      {
        timestamp: 1480629600,
        open: 1.05902,
        close: 1.06629,
        high: 1.06695,
        low: 1.05852
      },
      {
        timestamp: 1480716000,
        open: 1.06629,
        close: 1.06697,
        high: 1.0691,
        low: 1.06261
      },
      {
        timestamp: 1480888800,
        open: 1.06697,
        close: 1.0669,
        high: 1.0669,
        low: 1.06304
      },
      {
        timestamp: 1480975200,
        open: 1.0669,
        close: 1.07653,
        high: 1.07972,
        low: 1.05074
      },
      {
        timestamp: 1481061600,
        open: 1.07653,
        close: 1.07197,
        high: 1.07862,
        low: 1.06993
      },
      {
        timestamp: 1481148000,
        open: 1.07197,
        close: 1.07539,
        high: 1.07691,
        low: 1.07106
      },
      {
        timestamp: 1481234400,
        open: 1.07539,
        close: 1.06174,
        high: 1.08753,
        low: 1.05981
      },
      {
        timestamp: 1481320800,
        open: 1.06174,
        close: 1.05637,
        high: 1.06305,
        low: 1.05316
      },
      {
        timestamp: 1481493600,
        open: 1.05637,
        close: 1.05354,
        high: 1.05588,
        low: 1.053
      },
      {
        timestamp: 1481580000,
        open: 1.05354,
        close: 1.06354,
        high: 1.06525,
        low: 1.05262
      },
      {
        timestamp: 1481666400,
        open: 1.06354,
        close: 1.06271,
        high: 1.0668,
        low: 1.06046
      },
      {
        timestamp: 1481752800,
        open: 1.06271,
        close: 1.05377,
        high: 1.06709,
        low: 1.04969
      },
      {
        timestamp: 1481839200,
        open: 1.05377,
        close: 1.04157,
        high: 1.05404,
        low: 1.03673
      },
      {
        timestamp: 1481925600,
        open: 1.04157,
        close: 1.04589,
        high: 1.04752,
        low: 1.04015
      },
      {
        timestamp: 1482098400,
        open: 1.04589,
        close: 1.04448,
        high: 1.04493,
        low: 1.04308
      },
      {
        timestamp: 1482184800,
        open: 1.04448,
        close: 1.04045,
        high: 1.04804,
        low: 1.03934
      },
      {
        timestamp: 1482271200,
        open: 1.04045,
        close: 1.03915,
        high: 1.0419,
        low: 1.0353
      },
      {
        timestamp: 1482357600,
        open: 1.03915,
        close: 1.04256,
        high: 1.0452,
        low: 1.03833
      },
      {
        timestamp: 1482444000,
        open: 1.04256,
        close: 1.04389,
        high: 1.05005,
        low: 1.04245
      },
      {
        timestamp: 1482530400,
        open: 1.04389,
        close: 1.04607,
        high: 1.04701,
        low: 1.04274
      },
      {
        timestamp: 1482876000,
        open: 1.04607,
        close: 1.04599,
        high: 1.04643,
        low: 1.04333
      },
      {
        timestamp: 1482962400,
        open: 1.04599,
        close: 1.04188,
        high: 1.04808,
        low: 1.0373
      },
      {
        timestamp: 1483048800,
        open: 1.04188,
        close: 1.04922,
        high: 1.04949,
        low: 1.04096
      },
      {
        timestamp: 1483135200,
        open: 1.04922,
        close: 1.05301,
        high: 1.06613,
        low: 1.04858
      },
      {
        timestamp: 1483308000,
        open: 1.05301,
        close: 1.0525,
        high: 1.05258,
        low: 1.05218
      },
      {
        timestamp: 1483480800,
        open: 1.0525,
        close: 1.04071,
        high: 1.0525,
        low: 1.03412
      },
      {
        timestamp: 1483567200,
        open: 1.04071,
        close: 1.04928,
        high: 1.0501,
        low: 1.03903
      },
      {
        timestamp: 1483653600,
        open: 1.04928,
        close: 1.06091,
        high: 1.06161,
        low: 1.04817
      },
      {
        timestamp: 1483740000,
        open: 1.06091,
        close: 1.05378,
        high: 1.06247,
        low: 1.05257
      },
      {
        timestamp: 1483912800,
        open: 1.05378,
        close: 1.05325,
        high: 1.05375,
        low: 1.05306
      },
      {
        timestamp: 1483999200,
        open: 1.05325,
        close: 1.05773,
        high: 1.05842,
        low: 1.05116
      },
      {
        timestamp: 1484085600,
        open: 1.05773,
        close: 1.05553,
        high: 1.06282,
        low: 1.05515
      },
      {
        timestamp: 1484172000,
        open: 1.05553,
        close: 1.05842,
        high: 1.06241,
        low: 1.04547
      },
      {
        timestamp: 1484258400,
        open: 1.05842,
        close: 1.06142,
        high: 1.06856,
        low: 1.05723
      },
      {
        timestamp: 1484344800,
        open: 1.06142,
        close: 1.06507,
        high: 1.06768,
        low: 1.05968
      },
      {
        timestamp: 1484517600,
        open: 1.06507,
        close: 1.06074,
        high: 1.06234,
        low: 1.05943
      },
      {
        timestamp: 1484604000,
        open: 1.06074,
        close: 1.06059,
        high: 1.06367,
        low: 1.05799
      },
      {
        timestamp: 1484690400,
        open: 1.06059,
        close: 1.07147,
        high: 1.07202,
        low: 1.05987
      },
      {
        timestamp: 1484776800,
        open: 1.07147,
        close: 1.06319,
        high: 1.07168,
        low: 1.06293
      },
      {
        timestamp: 1484863200,
        open: 1.06319,
        close: 1.06659,
        high: 1.06775,
        low: 1.05897
      },
      {
        timestamp: 1484949600,
        open: 1.06659,
        close: 1.07077,
        high: 1.07104,
        low: 1.06259
      },
      {
        timestamp: 1485122400,
        open: 1.07077,
        close: 1.07045,
        high: 1.07147,
        low: 1.07077
      },
      {
        timestamp: 1485208800,
        open: 1.07045,
        close: 1.07664,
        high: 1.07703,
        low: 1.0697
      },
      {
        timestamp: 1485295200,
        open: 1.07664,
        close: 1.07333,
        high: 1.07758,
        low: 1.07209
      },
      {
        timestamp: 1485381600,
        open: 1.07333,
        close: 1.07504,
        high: 1.07707,
        low: 1.07121
      },
      {
        timestamp: 1485468000,
        open: 1.07504,
        close: 1.06838,
        high: 1.07664,
        low: 1.06584
      },
      {
        timestamp: 1485554400,
        open: 1.06838,
        close: 1.07016,
        high: 1.07258,
        low: 1.0659
      },
      {
        timestamp: 1485727200,
        open: 1.07016,
        close: 1.07254,
        high: 1.07337,
        low: 1.07016
      },
      {
        timestamp: 1485813600,
        open: 1.07254,
        close: 1.06991,
        high: 1.07413,
        low: 1.06209
      },
      {
        timestamp: 1485900000,
        open: 1.06991,
        close: 1.07989,
        high: 1.08132,
        low: 1.06852
      },
      {
        timestamp: 1485986400,
        open: 1.07989,
        close: 1.07694,
        high: 1.08083,
        low: 1.07335
      },
      {
        timestamp: 1486072800,
        open: 1.07694,
        close: 1.07602,
        high: 1.08296,
        low: 1.07565
      },
      {
        timestamp: 1486159200,
        open: 1.07602,
        close: 1.07917,
        high: 1.07985,
        low: 1.0713
      },
      {
        timestamp: 1486332000,
        open: 1.07917,
        close: 1.07915,
        high: 1.08056,
        low: 1.07917
      },
      {
        timestamp: 1486418400,
        open: 1.07915,
        close: 1.07521,
        high: 1.0795,
        low: 1.07063
      },
      {
        timestamp: 1486504800,
        open: 1.07521,
        close: 1.06849,
        high: 1.07525,
        low: 1.06567
      },
      {
        timestamp: 1486591200,
        open: 1.06849,
        close: 1.06999,
        high: 1.0715,
        low: 1.06414
      },
      {
        timestamp: 1486677600,
        open: 1.06999,
        close: 1.06625,
        high: 1.07102,
        low: 1.06516
      },
      {
        timestamp: 1486764000,
        open: 1.06625,
        close: 1.06475,
        high: 1.06685,
        low: 1.06084
      },
      {
        timestamp: 1486936800,
        open: 1.06475,
        close: 1.06301,
        high: 1.06325,
        low: 1.06267
      },
      {
        timestamp: 1487023200,
        open: 1.06301,
        close: 1.06027,
        high: 1.06596,
        low: 1.05927
      },
      {
        timestamp: 1487109600,
        open: 1.06027,
        close: 1.05858,
        high: 1.06344,
        low: 1.05618
      },
      {
        timestamp: 1487196000,
        open: 1.05858,
        close: 1.06015,
        high: 1.06106,
        low: 1.05222
      },
      {
        timestamp: 1487282400,
        open: 1.06015,
        close: 1.06753,
        high: 1.06802,
        low: 1.05921
      },
      {
        timestamp: 1487368800,
        open: 1.06753,
        close: 1.06138,
        high: 1.06778,
        low: 1.06062
      },
      {
        timestamp: 1487541600,
        open: 1.06138,
        close: 1.06109,
        high: 1.06163,
        low: 1.06094
      },
      {
        timestamp: 1487628000,
        open: 1.06109,
        close: 1.06143,
        high: 1.0634,
        low: 1.06036
      },
      {
        timestamp: 1487714400,
        open: 1.06143,
        close: 1.05429,
        high: 1.06191,
        low: 1.05265
      },
      {
        timestamp: 1487800800,
        open: 1.05429,
        close: 1.05598,
        high: 1.05753,
        low: 1.04943
      },
      {
        timestamp: 1487887200,
        open: 1.05598,
        close: 1.05843,
        high: 1.05962,
        low: 1.05383
      },
      {
        timestamp: 1487973600,
        open: 1.05843,
        close: 1.0564,
        high: 1.06193,
        low: 1.05576
      },
      {
        timestamp: 1488146400,
        open: 1.0564,
        close: 1.05673,
        high: 1.05724,
        low: 1.05629
      },
      {
        timestamp: 1488232800,
        open: 1.05673,
        close: 1.05883,
        high: 1.06321,
        low: 1.05522
      },
      {
        timestamp: 1488319200,
        open: 1.05883,
        close: 1.05792,
        high: 1.06306,
        low: 1.05689
      },
      {
        timestamp: 1488405600,
        open: 1.05792,
        close: 1.0549,
        high: 1.05904,
        low: 1.0515
      },
      {
        timestamp: 1488492000,
        open: 1.0549,
        close: 1.05078,
        high: 1.0553,
        low: 1.04956
      },
      {
        timestamp: 1488578400,
        open: 1.05078,
        close: 1.06243,
        high: 1.06252,
        low: 1.05031
      },
      {
        timestamp: 1488751200,
        open: 1.06243,
        close: 1.06126,
        high: 1.06211,
        low: 1.06047
      },
      {
        timestamp: 1488837600,
        open: 1.06126,
        close: 1.05835,
        high: 1.06415,
        low: 1.05752
      },
      {
        timestamp: 1488924000,
        open: 1.05835,
        close: 1.05674,
        high: 1.06036,
        low: 1.05589
      },
      {
        timestamp: 1489010400,
        open: 1.05674,
        close: 1.05438,
        high: 1.0575,
        low: 1.05355
      },
      {
        timestamp: 1489096800,
        open: 1.05438,
        close: 1.05793,
        high: 1.06166,
        low: 1.05258
      },
      {
        timestamp: 1489179600,
        open: 1.05793,
        close: 1.06792,
        high: 1.06999,
        low: 1.05735
      },
      {
        timestamp: 1489352400,
        open: 1.06792,
        close: 1.06821,
        high: 1.06901,
        low: 1.06761
      },
      {
        timestamp: 1489438800,
        open: 1.06821,
        close: 1.06536,
        high: 1.07152,
        low: 1.06529
      },
      {
        timestamp: 1489525200,
        open: 1.06536,
        close: 1.0607,
        high: 1.06636,
        low: 1.06006
      },
      {
        timestamp: 1489611600,
        open: 1.0607,
        close: 1.07347,
        high: 1.07413,
        low: 1.06045
      },
      {
        timestamp: 1489698000,
        open: 1.07347,
        close: 1.07667,
        high: 1.07712,
        low: 1.07064
      },
      {
        timestamp: 1489784400,
        open: 1.07667,
        close: 1.07415,
        high: 1.07833,
        low: 1.07283
      },
      {
        timestamp: 1489957200,
        open: 1.07415,
        close: 1.07351,
        high: 1.07431,
        low: 1.07338
      },
      {
        timestamp: 1490043600,
        open: 1.07351,
        close: 1.07422,
        high: 1.07783,
        low: 1.07258
      },
      {
        timestamp: 1490130000,
        open: 1.07422,
        close: 1.08127,
        high: 1.08202,
        low: 1.07199
      },
      {
        timestamp: 1490216400,
        open: 1.08127,
        close: 1.07997,
        high: 1.08256,
        low: 1.07766
      },
      {
        timestamp: 1490302800,
        open: 1.07997,
        close: 1.07866,
        high: 1.0806,
        low: 1.07687
      },
      {
        timestamp: 1490389200,
        open: 1.07866,
        close: 1.08013,
        high: 1.08189,
        low: 1.0761
      },
      {
        timestamp: 1490562000,
        open: 1.08013,
        close: 1.08438,
        high: 1.08561,
        low: 1.08013
      },
      {
        timestamp: 1490648400,
        open: 1.08438,
        close: 1.08635,
        high: 1.09072,
        low: 1.08291
      },
      {
        timestamp: 1490734800,
        open: 1.08635,
        close: 1.08159,
        high: 1.08734,
        low: 1.07995
      },
      {
        timestamp: 1490821200,
        open: 1.08159,
        close: 1.07668,
        high: 1.08274,
        low: 1.07406
      },
      {
        timestamp: 1490907600,
        open: 1.07668,
        close: 1.06763,
        high: 1.0771,
        low: 1.06725
      },
      {
        timestamp: 1490994000,
        open: 1.06763,
        close: 1.06607,
        high: 1.07026,
        low: 1.06524
      },
      {
        timestamp: 1491166800,
        open: 1.06607,
        close: 1.06666,
        high: 1.06689,
        low: 1.06618
      },
      {
        timestamp: 1491253200,
        open: 1.06666,
        close: 1.06704,
        high: 1.0682,
        low: 1.06432
      },
      {
        timestamp: 1491339600,
        open: 1.06704,
        close: 1.06742,
        high: 1.06783,
        low: 1.06365
      },
      {
        timestamp: 1491426000,
        open: 1.06742,
        close: 1.06642,
        high: 1.06898,
        low: 1.06367
      },
      {
        timestamp: 1491512400,
        open: 1.06642,
        close: 1.06457,
        high: 1.0685,
        low: 1.06297
      },
      {
        timestamp: 1491598800,
        open: 1.06457,
        close: 1.05927,
        high: 1.06709,
        low: 1.05813
      },
      {
        timestamp: 1491771600,
        open: 1.05927,
        close: 1.05903,
        high: 1.0595,
        low: 1.05847
      },
      {
        timestamp: 1491858000,
        open: 1.05903,
        close: 1.05976,
        high: 1.06075,
        low: 1.05706
      },
      {
        timestamp: 1491944400,
        open: 1.05976,
        close: 1.06052,
        high: 1.06308,
        low: 1.05794
      },
      {
        timestamp: 1492030800,
        open: 1.06052,
        close: 1.06671,
        high: 1.06759,
        low: 1.05896
      },
      {
        timestamp: 1492117200,
        open: 1.06671,
        close: 1.06145,
        high: 1.06784,
        low: 1.061
      },
      {
        timestamp: 1492203600,
        open: 1.06145,
        close: 1.06151,
        high: 1.06312,
        low: 1.06068
      },
      {
        timestamp: 1492376400,
        open: 1.06151,
        close: 1.06251,
        high: 1.06261,
        low: 1.06151
      },
      {
        timestamp: 1492462800,
        open: 1.06251,
        close: 1.06458,
        high: 1.06714,
        low: 1.06041
      },
      {
        timestamp: 1492549200,
        open: 1.06458,
        close: 1.07316,
        high: 1.07369,
        low: 1.06379
      },
      {
        timestamp: 1492635600,
        open: 1.07316,
        close: 1.07131,
        high: 1.07385,
        low: 1.07006
      },
      {
        timestamp: 1492722000,
        open: 1.07131,
        close: 1.07179,
        high: 1.07783,
        low: 1.07101
      },
      {
        timestamp: 1492808400,
        open: 1.07179,
        close: 1.0727,
        high: 1.0739,
        low: 1.06829
      },
      {
        timestamp: 1492981200,
        open: 1.0727,
        close: 1.09242,
        high: 1.09419,
        low: 1.0727
      },
      {
        timestamp: 1493067600,
        open: 1.09242,
        close: 1.08675,
        high: 1.09227,
        low: 1.08215
      },
      {
        timestamp: 1493154000,
        open: 1.08675,
        close: 1.09274,
        high: 1.0951,
        low: 1.08521
      },
      {
        timestamp: 1493240400,
        open: 1.09274,
        close: 1.09063,
        high: 1.09516,
        low: 1.08564
      },
      {
        timestamp: 1493326800,
        open: 1.09063,
        close: 1.08727,
        high: 1.0934,
        low: 1.08524
      },
      {
        timestamp: 1493413200,
        open: 1.08727,
        close: 1.09003,
        high: 1.09483,
        low: 1.0858
      },
      {
        timestamp: 1493586000,
        open: 1.09003,
        close: 1.09179,
        high: 1.09214,
        low: 1.09003
      },
      {
        timestamp: 1493672400,
        open: 1.09179,
        close: 1.09021,
        high: 1.09247,
        low: 1.08849
      },
      {
        timestamp: 1493758800,
        open: 1.09021,
        close: 1.09341,
        high: 1.09342,
        low: 1.0889
      },
      {
        timestamp: 1493845200,
        open: 1.09341,
        close: 1.08863,
        high: 1.09376,
        low: 1.08837
      },
      {
        timestamp: 1493931600,
        open: 1.08863,
        close: 1.09849,
        high: 1.0988,
        low: 1.08757
      },
      {
        timestamp: 1494018000,
        open: 1.09849,
        close: 1.10014,
        high: 1.10022,
        low: 1.0953
      },
      {
        timestamp: 1494190800,
        open: 1.10014,
        close: 1.10232,
        high: 1.10249,
        low: 1.10014
      },
      {
        timestamp: 1494277200,
        open: 1.10232,
        close: 1.09261,
        high: 1.10245,
        low: 1.09171
      },
      {
        timestamp: 1494363600,
        open: 1.09261,
        close: 1.08752,
        high: 1.09342,
        low: 1.08642
      },
      {
        timestamp: 1494450000,
        open: 1.08752,
        close: 1.08686,
        high: 1.0899,
        low: 1.08539
      },
      {
        timestamp: 1494536400,
        open: 1.08686,
        close: 1.08632,
        high: 1.08939,
        low: 1.08398
      },
      {
        timestamp: 1494622800,
        open: 1.08632,
        close: 1.09349,
        high: 1.09355,
        low: 1.08566
      },
      {
        timestamp: 1494795600,
        open: 1.09349,
        close: 1.09317,
        high: 1.0936,
        low: 1.09289
      },
      {
        timestamp: 1494882000,
        open: 1.09317,
        close: 1.09769,
        high: 1.09903,
        low: 1.09235
      },
      {
        timestamp: 1494968400,
        open: 1.09769,
        close: 1.10854,
        high: 1.1098,
        low: 1.09757
      },
      {
        timestamp: 1495054800,
        open: 1.10854,
        close: 1.11611,
        high: 1.1163,
        low: 1.10808
      },
      {
        timestamp: 1495141200,
        open: 1.11611,
        close: 1.11042,
        high: 1.11728,
        low: 1.10767
      },
      {
        timestamp: 1495227600,
        open: 1.11042,
        close: 1.12088,
        high: 1.12126,
        low: 1.10979
      },
      {
        timestamp: 1495400400,
        open: 1.12088,
        close: 1.11997,
        high: 1.12108,
        low: 1.11872
      },
      {
        timestamp: 1495486800,
        open: 1.11997,
        close: 1.124,
        high: 1.12643,
        low: 1.11623
      },
      {
        timestamp: 1495573200,
        open: 1.124,
        close: 1.11838,
        high: 1.12699,
        low: 1.11757
      },
      {
        timestamp: 1495659600,
        open: 1.11838,
        close: 1.12193,
        high: 1.12208,
        low: 1.11691
      },
      {
        timestamp: 1495746000,
        open: 1.12193,
        close: 1.12117,
        high: 1.12513,
        low: 1.11943
      },
      {
        timestamp: 1495832400,
        open: 1.12117,
        close: 1.11809,
        high: 1.12353,
        low: 1.11614
      },
      {
        timestamp: 1496005200,
        open: 1.11809,
        close: 1.11789,
        high: 1.11798,
        low: 1.11744
      },
      {
        timestamp: 1496091600,
        open: 1.11789,
        close: 1.11661,
        high: 1.11907,
        low: 1.11627
      },
      {
        timestamp: 1496178000,
        open: 1.11661,
        close: 1.11883,
        high: 1.12065,
        low: 1.11101
      },
      {
        timestamp: 1496264400,
        open: 1.11883,
        close: 1.12435,
        high: 1.12531,
        low: 1.11652
      },
      {
        timestamp: 1496350800,
        open: 1.12435,
        close: 1.12134,
        high: 1.12575,
        low: 1.12028
      },
      {
        timestamp: 1496437200,
        open: 1.12134,
        close: 1.12824,
        high: 1.12866,
        low: 1.12057
      },
      {
        timestamp: 1496610000,
        open: 1.12824,
        close: 1.12785,
        high: 1.12873,
        low: 1.12737
      },
      {
        timestamp: 1496696400,
        open: 1.12785,
        close: 1.12549,
        high: 1.12867,
        low: 1.1235
      },
      {
        timestamp: 1496782800,
        open: 1.12549,
        close: 1.12784,
        high: 1.1285,
        low: 1.12412
      },
      {
        timestamp: 1496869200,
        open: 1.12784,
        close: 1.1258,
        high: 1.12833,
        low: 1.12048
      },
      {
        timestamp: 1496955600,
        open: 1.1258,
        close: 1.12147,
        high: 1.12713,
        low: 1.11954
      },
      {
        timestamp: 1497042000,
        open: 1.12147,
        close: 1.11984,
        high: 1.12398,
        low: 1.11671
      },
      {
        timestamp: 1497214800,
        open: 1.11984,
        close: 1.12113,
        high: 1.12175,
        low: 1.11995
      },
      {
        timestamp: 1497301200,
        open: 1.12113,
        close: 1.12056,
        high: 1.12329,
        low: 1.11927
      },
      {
        timestamp: 1497387600,
        open: 1.12056,
        close: 1.12115,
        high: 1.12257,
        low: 1.1186
      },
      {
        timestamp: 1497474000,
        open: 1.12115,
        close: 1.12204,
        high: 1.12966,
        low: 1.11938
      },
      {
        timestamp: 1497560400,
        open: 1.12204,
        close: 1.11484,
        high: 1.12294,
        low: 1.11328
      },
      {
        timestamp: 1497646800,
        open: 1.11484,
        close: 1.11985,
        high: 1.12025,
        low: 1.11391
      },
      {
        timestamp: 1497819600,
        open: 1.11985,
        close: 1.12113,
        high: 1.12171,
        low: 1.11985
      },
      {
        timestamp: 1497906000,
        open: 1.12113,
        close: 1.11492,
        high: 1.12161,
        low: 1.11438
      },
      {
        timestamp: 1497992400,
        open: 1.11492,
        close: 1.11352,
        high: 1.11657,
        low: 1.11196
      },
      {
        timestamp: 1498078800,
        open: 1.11352,
        close: 1.11706,
        high: 1.11707,
        low: 1.11281
      },
      {
        timestamp: 1498165200,
        open: 1.11706,
        close: 1.11533,
        high: 1.11787,
        low: 1.11401
      },
      {
        timestamp: 1498251600,
        open: 1.11533,
        close: 1.11941,
        high: 1.12097,
        low: 1.1146
      },
      {
        timestamp: 1498424400,
        open: 1.11941,
        close: 1.1202,
        high: 1.1205,
        low: 1.11949
      },
      {
        timestamp: 1498510800,
        open: 1.1202,
        close: 1.11825,
        high: 1.12207,
        low: 1.11725
      },
      {
        timestamp: 1498597200,
        open: 1.11825,
        close: 1.13403,
        high: 1.13503,
        low: 1.11797
      },
      {
        timestamp: 1498683600,
        open: 1.13403,
        close: 1.13777,
        high: 1.1392,
        low: 1.12922
      },
      {
        timestamp: 1498770000,
        open: 1.13777,
        close: 1.14409,
        high: 1.14462,
        low: 1.13754
      },
      {
        timestamp: 1498856400,
        open: 1.14409,
        close: 1.14249,
        high: 1.14462,
        low: 1.13928
      },
      {
        timestamp: 1499029200,
        open: 1.14249,
        close: 1.1422,
        high: 1.14274,
        low: 1.14175
      },
      {
        timestamp: 1499115600,
        open: 1.1422,
        close: 1.13652,
        high: 1.14291,
        low: 1.13557
      },
      {
        timestamp: 1499202000,
        open: 1.13652,
        close: 1.13493,
        high: 1.13778,
        low: 1.13368
      },
      {
        timestamp: 1499288400,
        open: 1.13493,
        close: 1.13524,
        high: 1.13694,
        low: 1.13132
      },
      {
        timestamp: 1499374800,
        open: 1.13524,
        close: 1.14249,
        high: 1.14263,
        low: 1.13306
      },
      {
        timestamp: 1499461200,
        open: 1.14249,
        close: 1.14022,
        high: 1.14407,
        low: 1.13803
      },
      {
        timestamp: 1499634000,
        open: 1.14022,
        close: 1.14002,
        high: 1.14034,
        low: 1.13955
      },
      {
        timestamp: 1499720400,
        open: 1.14002,
        close: 1.14003,
        high: 1.14189,
        low: 1.13824
      },
      {
        timestamp: 1499806800,
        open: 1.14003,
        close: 1.14679,
        high: 1.14804,
        low: 1.13835
      },
      {
        timestamp: 1499893200,
        open: 1.14679,
        close: 1.14127,
        high: 1.14904,
        low: 1.13924
      },
      {
        timestamp: 1499979600,
        open: 1.14127,
        close: 1.13983,
        high: 1.14567,
        low: 1.1371
      },
      {
        timestamp: 1500066000,
        open: 1.13983,
        close: 1.14697,
        high: 1.14727,
        low: 1.13923
      },
      {
        timestamp: 1500238800,
        open: 1.14697,
        close: 1.14756,
        high: 1.14777,
        low: 1.14681
      },
      {
        timestamp: 1500325200,
        open: 1.14756,
        close: 1.14798,
        high: 1.1488,
        low: 1.14355
      },
      {
        timestamp: 1500411600,
        open: 1.14798,
        close: 1.15544,
        high: 1.15841,
        low: 1.14723
      },
      {
        timestamp: 1500498000,
        open: 1.15544,
        close: 1.15166,
        high: 1.15598,
        low: 1.15106
      },
      {
        timestamp: 1500584400,
        open: 1.15166,
        close: 1.1632,
        high: 1.16597,
        low: 1.14799
      },
      {
        timestamp: 1500670800,
        open: 1.1632,
        close: 1.16653,
        high: 1.16838,
        low: 1.162
      },
      {
        timestamp: 1500843600,
        open: 1.16653,
        close: 1.16687,
        high: 1.16733,
        low: 1.16636
      },
      {
        timestamp: 1500930000,
        open: 1.16687,
        close: 1.16419,
        high: 1.1685,
        low: 1.16266
      },
      {
        timestamp: 1501016400,
        open: 1.16419,
        close: 1.1648,
        high: 1.17131,
        low: 1.16315
      },
      {
        timestamp: 1501102800,
        open: 1.1648,
        close: 1.17371,
        high: 1.17415,
        low: 1.16134
      },
      {
        timestamp: 1501189200,
        open: 1.17371,
        close: 1.16792,
        high: 1.17777,
        low: 1.16509
      },
      {
        timestamp: 1501275600,
        open: 1.16792,
        close: 1.17508,
        high: 1.17648,
        low: 1.16719
      },
      {
        timestamp: 1501448400,
        open: 1.17508,
        close: 1.17518,
        high: 1.17578,
        low: 1.17404
      },
      {
        timestamp: 1501534800,
        open: 1.17518,
        close: 1.18423,
        high: 1.18468,
        low: 1.17239
      },
      {
        timestamp: 1501621200,
        open: 1.18423,
        close: 1.18028,
        high: 1.18459,
        low: 1.17859
      },
      {
        timestamp: 1501707600,
        open: 1.18028,
        close: 1.18578,
        high: 1.19116,
        low: 1.17945
      },
      {
        timestamp: 1501794000,
        open: 1.18578,
        close: 1.18696,
        high: 1.18939,
        low: 1.18312
      },
      {
        timestamp: 1501880400,
        open: 1.18696,
        close: 1.17752,
        high: 1.189,
        low: 1.1729
      },
      {
        timestamp: 1502053200,
        open: 1.17752,
        close: 1.17763,
        high: 1.17866,
        low: 1.17729
      },
      {
        timestamp: 1502139600,
        open: 1.17763,
        close: 1.17964,
        high: 1.1815,
        low: 1.1772
      },
      {
        timestamp: 1502226000,
        open: 1.17964,
        close: 1.17544,
        high: 1.18247,
        low: 1.1716
      },
      {
        timestamp: 1502312400,
        open: 1.17544,
        close: 1.17588,
        high: 1.17647,
        low: 1.16898
      },
      {
        timestamp: 1502398800,
        open: 1.17588,
        close: 1.17741,
        high: 1.1786,
        low: 1.17049
      },
      {
        timestamp: 1502485200,
        open: 1.17741,
        close: 1.18228,
        high: 1.18486,
        low: 1.17491
      },
      {
        timestamp: 1502658000,
        open: 1.18228,
        close: 1.18243,
        high: 1.18309,
        low: 1.1816
      },
      {
        timestamp: 1502744400,
        open: 1.18243,
        close: 1.17815,
        high: 1.18392,
        low: 1.17705
      },
      {
        timestamp: 1502830800,
        open: 1.17815,
        close: 1.17359,
        high: 1.17933,
        low: 1.16879
      },
      {
        timestamp: 1502917200,
        open: 1.17359,
        close: 1.1768,
        high: 1.17795,
        low: 1.16817
      },
      {
        timestamp: 1503003600,
        open: 1.1768,
        close: 1.17253,
        high: 1.17906,
        low: 1.16629
      },
      {
        timestamp: 1503090000,
        open: 1.17253,
        close: 1.17608,
        high: 1.17755,
        low: 1.17093
      },
      {
        timestamp: 1503262800,
        open: 1.17608,
        close: 1.17636,
        high: 1.17683,
        low: 1.17596
      },
      {
        timestamp: 1503349200,
        open: 1.17636,
        close: 1.18154,
        high: 1.18289,
        low: 1.17318
      },
      {
        timestamp: 1503435600,
        open: 1.18154,
        close: 1.1763,
        high: 1.18252,
        low: 1.17458
      },
      {
        timestamp: 1503522000,
        open: 1.1763,
        close: 1.18073,
        high: 1.18241,
        low: 1.1741
      },
      {
        timestamp: 1503608400,
        open: 1.18073,
        close: 1.17998,
        high: 1.18188,
        low: 1.17849
      },
      {
        timestamp: 1503694800,
        open: 1.17998,
        close: 1.19235,
        high: 1.19426,
        low: 1.17739
      },
      {
        timestamp: 1503867600,
        open: 1.19235,
        close: 1.19505,
        high: 1.19685,
        low: 1.19235
      },
      {
        timestamp: 1503954000,
        open: 1.19505,
        close: 1.19795,
        high: 1.19845,
        low: 1.19178
      },
      {
        timestamp: 1504040400,
        open: 1.19795,
        close: 1.19732,
        high: 1.20711,
        low: 1.19468
      },
      {
        timestamp: 1504126800,
        open: 1.19732,
        close: 1.18849,
        high: 1.1985,
        low: 1.18814
      },
      {
        timestamp: 1504213200,
        open: 1.18849,
        close: 1.19105,
        high: 1.19136,
        low: 1.18237
      },
      {
        timestamp: 1504299600,
        open: 1.19105,
        close: 1.18635,
        high: 1.19805,
        low: 1.18504
      },
      {
        timestamp: 1504472400,
        open: 1.18635,
        close: 1.18857,
        high: 1.18927,
        low: 1.18546
      },
      {
        timestamp: 1504558800,
        open: 1.18857,
        close: 1.1902,
        high: 1.19228,
        low: 1.18754
      },
      {
        timestamp: 1504645200,
        open: 1.1902,
        close: 1.19165,
        high: 1.19415,
        low: 1.18689
      },
      {
        timestamp: 1504731600,
        open: 1.19165,
        close: 1.19178,
        high: 1.19509,
        low: 1.19037
      },
      {
        timestamp: 1504818000,
        open: 1.19178,
        close: 1.20275,
        high: 1.20603,
        low: 1.19148
      },
      {
        timestamp: 1504904400,
        open: 1.20275,
        close: 1.20416,
        high: 1.20932,
        low: 1.20152
      },
      {
        timestamp: 1505077200,
        open: 1.20416,
        close: 1.20227,
        high: 1.20307,
        low: 1.20137
      },
      {
        timestamp: 1505163600,
        open: 1.20227,
        close: 1.19536,
        high: 1.20302,
        low: 1.19484
      },
      {
        timestamp: 1505250000,
        open: 1.19536,
        close: 1.19676,
        high: 1.19789,
        low: 1.19269
      },
      {
        timestamp: 1505336400,
        open: 1.19676,
        close: 1.18864,
        high: 1.19957,
        low: 1.18737
      },
      {
        timestamp: 1505422800,
        open: 1.18864,
        close: 1.19192,
        high: 1.1923,
        low: 1.1838
      },
      {
        timestamp: 1505509200,
        open: 1.19192,
        close: 1.19473,
        high: 1.19883,
        low: 1.19017
      },
      {
        timestamp: 1505682000,
        open: 1.19473,
        close: 1.1922,
        high: 1.19456,
        low: 1.19219
      },
      {
        timestamp: 1505768400,
        open: 1.1922,
        close: 1.1955,
        high: 1.19701,
        low: 1.19154
      },
      {
        timestamp: 1505854800,
        open: 1.1955,
        close: 1.19951,
        high: 1.20075,
        low: 1.1955
      },
      {
        timestamp: 1505941200,
        open: 1.19951,
        close: 1.18953,
        high: 1.2037,
        low: 1.1862
      },
      {
        timestamp: 1506027600,
        open: 1.18953,
        close: 1.19422,
        high: 1.19545,
        low: 1.18664
      },
      {
        timestamp: 1506114000,
        open: 1.19422,
        close: 1.19484,
        high: 1.20053,
        low: 1.19378
      },
      {
        timestamp: 1506286800,
        open: 1.19484,
        close: 1.18999,
        high: 1.19329,
        low: 1.18977
      },
      {
        timestamp: 1506373200,
        open: 1.18999,
        close: 1.18494,
        high: 1.19373,
        low: 1.18326
      },
      {
        timestamp: 1506459600,
        open: 1.18494,
        close: 1.17944,
        high: 1.18623,
        low: 1.17578
      },
      {
        timestamp: 1506546000,
        open: 1.17944,
        close: 1.17456,
        high: 1.17961,
        low: 1.17176
      },
      {
        timestamp: 1506632400,
        open: 1.17456,
        close: 1.17869,
        high: 1.18049,
        low: 1.17219
      },
      {
        timestamp: 1506718800,
        open: 1.17869,
        close: 1.18192,
        high: 1.18332,
        low: 1.17732
      },
      {
        timestamp: 1506891600,
        open: 1.18192,
        close: 1.17952,
        high: 1.18009,
        low: 1.17768
      },
      {
        timestamp: 1506978000,
        open: 1.17952,
        close: 1.17339,
        high: 1.18165,
        low: 1.17308
      },
      {
        timestamp: 1507064400,
        open: 1.17339,
        close: 1.17454,
        high: 1.17744,
        low: 1.16965
      },
      {
        timestamp: 1507150800,
        open: 1.17454,
        close: 1.17619,
        high: 1.17892,
        low: 1.17363
      },
      {
        timestamp: 1507237200,
        open: 1.17619,
        close: 1.17124,
        high: 1.17797,
        low: 1.16998
      },
      {
        timestamp: 1507323600,
        open: 1.17124,
        close: 1.17374,
        high: 1.17395,
        low: 1.167
      },
      {
        timestamp: 1507496400,
        open: 1.17374,
        close: 1.17317,
        high: 1.17397,
        low: 1.17198
      },
      {
        timestamp: 1507582800,
        open: 1.17317,
        close: 1.17415,
        high: 1.17566,
        low: 1.17202
      },
      {
        timestamp: 1507669200,
        open: 1.17415,
        close: 1.18093,
        high: 1.18262,
        low: 1.17401
      },
      {
        timestamp: 1507755600,
        open: 1.18093,
        close: 1.18613,
        high: 1.18699,
        low: 1.17959
      },
      {
        timestamp: 1507842000,
        open: 1.18613,
        close: 1.18312,
        high: 1.18805,
        low: 1.18277
      },
      {
        timestamp: 1507928400,
        open: 1.18312,
        close: 1.18256,
        high: 1.18756,
        low: 1.1806
      },
      {
        timestamp: 1508101200,
        open: 1.18256,
        close: 1.18158,
        high: 1.18218,
        low: 1.18149
      },
      {
        timestamp: 1508187600,
        open: 1.18158,
        close: 1.17972,
        high: 1.18204,
        low: 1.1781
      },
      {
        timestamp: 1508274000,
        open: 1.17972,
        close: 1.17674,
        high: 1.18011,
        low: 1.17368
      },
      {
        timestamp: 1508360400,
        open: 1.17674,
        close: 1.17884,
        high: 1.18061,
        low: 1.17308
      },
      {
        timestamp: 1508446800,
        open: 1.17884,
        close: 1.18545,
        high: 1.18587,
        low: 1.17687
      },
      {
        timestamp: 1508533200,
        open: 1.18545,
        close: 1.17828,
        high: 1.18587,
        low: 1.17631
      },
      {
        timestamp: 1508706000,
        open: 1.17828,
        close: 1.17661,
        high: 1.17661,
        low: 1.17603
      },
      {
        timestamp: 1508792400,
        open: 1.17661,
        close: 1.17501,
        high: 1.1778,
        low: 1.17254
      },
      {
        timestamp: 1508878800,
        open: 1.17501,
        close: 1.17628,
        high: 1.17936,
        low: 1.17435
      },
      {
        timestamp: 1508965200,
        open: 1.17628,
        close: 1.18138,
        high: 1.18184,
        low: 1.17538
      },
      {
        timestamp: 1509051600,
        open: 1.18138,
        close: 1.16511,
        high: 1.18375,
        low: 1.16411
      },
      {
        timestamp: 1509138000,
        open: 1.16511,
        close: 1.16133,
        high: 1.16582,
        low: 1.15745
      },
      {
        timestamp: 1509310800,
        open: 1.16133,
        close: 1.16146,
        high: 1.16192,
        low: 1.16049
      },
      {
        timestamp: 1509397200,
        open: 1.16146,
        close: 1.16515,
        high: 1.16586,
        low: 1.15946
      },
      {
        timestamp: 1509483600,
        open: 1.16515,
        close: 1.16471,
        high: 1.16622,
        low: 1.16256
      },
      {
        timestamp: 1509570000,
        open: 1.16471,
        close: 1.16203,
        high: 1.16579,
        low: 1.16069
      },
      {
        timestamp: 1509656400,
        open: 1.16203,
        close: 1.16592,
        high: 1.16879,
        low: 1.16141
      },
      {
        timestamp: 1509746400,
        open: 1.16592,
        close: 1.16117,
        high: 1.16969,
        low: 1.15998
      },
      {
        timestamp: 1509919200,
        open: 1.16117,
        close: 1.16187,
        high: 1.16212,
        low: 1.16117
      },
      {
        timestamp: 1510005600,
        open: 1.16187,
        close: 1.16118,
        high: 1.16248,
        low: 1.15809
      },
      {
        timestamp: 1510092000,
        open: 1.16118,
        close: 1.15876,
        high: 1.16175,
        low: 1.15544
      },
      {
        timestamp: 1510178400,
        open: 1.15876,
        close: 1.1597,
        high: 1.16119,
        low: 1.15796
      },
      {
        timestamp: 1510264800,
        open: 1.1597,
        close: 1.16426,
        high: 1.16555,
        low: 1.15863
      },
      {
        timestamp: 1510351200,
        open: 1.16426,
        close: 1.16671,
        high: 1.16786,
        low: 1.16234
      },
      {
        timestamp: 1510524000,
        open: 1.16671,
        close: 1.16609,
        high: 1.16713,
        low: 1.16572
      },
      {
        timestamp: 1510610400,
        open: 1.16609,
        close: 1.16679,
        high: 1.16759,
        low: 1.16382
      },
      {
        timestamp: 1510696800,
        open: 1.16679,
        close: 1.17986,
        high: 1.18059,
        low: 1.16622
      },
      {
        timestamp: 1510783200,
        open: 1.17986,
        close: 1.17924,
        high: 1.18616,
        low: 1.17855
      },
      {
        timestamp: 1510869600,
        open: 1.17924,
        close: 1.17713,
        high: 1.18017,
        low: 1.17573
      },
      {
        timestamp: 1510956000,
        open: 1.17713,
        close: 1.17954,
        high: 1.18225,
        low: 1.17661
      },
      {
        timestamp: 1511128800,
        open: 1.17954,
        close: 1.17899,
        high: 1.17935,
        low: 1.17899
      },
      {
        timestamp: 1511215200,
        open: 1.17899,
        close: 1.17332,
        high: 1.18094,
        low: 1.17231
      },
      {
        timestamp: 1511301600,
        open: 1.17332,
        close: 1.17388,
        high: 1.17584,
        low: 1.17134
      },
      {
        timestamp: 1511388000,
        open: 1.17388,
        close: 1.18236,
        high: 1.18277,
        low: 1.17336
      },
      {
        timestamp: 1511474400,
        open: 1.18236,
        close: 1.18528,
        high: 1.18568,
        low: 1.18139
      },
      {
        timestamp: 1511560800,
        open: 1.18528,
        close: 1.19374,
        high: 1.1945,
        low: 1.18375
      },
      {
        timestamp: 1511733600,
        open: 1.19374,
        close: 1.19302,
        high: 1.19452,
        low: 1.19296
      },
      {
        timestamp: 1511820000,
        open: 1.19302,
        close: 1.18991,
        high: 1.19619,
        low: 1.18963
      },
      {
        timestamp: 1511906400,
        open: 1.18991,
        close: 1.18434,
        high: 1.19206,
        low: 1.18277
      },
      {
        timestamp: 1511992800,
        open: 1.18434,
        close: 1.18479,
        high: 1.18836,
        low: 1.18177
      },
      {
        timestamp: 1512079200,
        open: 1.18479,
        close: 1.19046,
        high: 1.19322,
        low: 1.18096
      },
      {
        timestamp: 1512165600,
        open: 1.19046,
        close: 1.19026,
        high: 1.19411,
        low: 1.18515
      },
      {
        timestamp: 1512338400,
        open: 1.19026,
        close: 1.18652,
        high: 1.18696,
        low: 1.18517
      },
      {
        timestamp: 1512424800,
        open: 1.18652,
        close: 1.18673,
        high: 1.18793,
        low: 1.18299
      },
      {
        timestamp: 1512511200,
        open: 1.18673,
        close: 1.1827,
        high: 1.18774,
        low: 1.18011
      },
      {
        timestamp: 1512597600,
        open: 1.1827,
        close: 1.17967,
        high: 1.18488,
        low: 1.17813
      },
      {
        timestamp: 1512684000,
        open: 1.17967,
        close: 1.17736,
        high: 1.18154,
        low: 1.17726
      },
      {
        timestamp: 1512770400,
        open: 1.17736,
        close: 1.17778,
        high: 1.17778,
        low: 1.17307
      },
      {
        timestamp: 1512943200,
        open: 1.17778,
        close: 1.17677,
        high: 1.17712,
        low: 1.17655
      },
      {
        timestamp: 1513029600,
        open: 1.17677,
        close: 1.17695,
        high: 1.18124,
        low: 1.17646
      },
      {
        timestamp: 1513116000,
        open: 1.17695,
        close: 1.17427,
        high: 1.17935,
        low: 1.17182
      },
      {
        timestamp: 1513202400,
        open: 1.17427,
        close: 1.18267,
        high: 1.18324,
        low: 1.17301
      },
      {
        timestamp: 1513288800,
        open: 1.18267,
        close: 1.1779,
        high: 1.18633,
        low: 1.17714
      },
      {
        timestamp: 1513375200,
        open: 1.1779,
        close: 1.17549,
        high: 1.1813,
        low: 1.17508
      },
      {
        timestamp: 1513548000,
        open: 1.17549,
        close: 1.17473,
        high: 1.17568,
        low: 1.17405
      },
      {
        timestamp: 1513634400,
        open: 1.17473,
        close: 1.17829,
        high: 1.18349,
        low: 1.17391
      },
      {
        timestamp: 1513720800,
        open: 1.17829,
        close: 1.1841,
        high: 1.18495,
        low: 1.17771
      },
      {
        timestamp: 1513807200,
        open: 1.1841,
        close: 1.18724,
        high: 1.19024,
        low: 1.18296
      },
      {
        timestamp: 1513893600,
        open: 1.18724,
        close: 1.18758,
        high: 1.18902,
        low: 1.18499
      },
      {
        timestamp: 1513980000,
        open: 1.18758,
        close: 1.18673,
        high: 1.18764,
        low: 1.18177
      },
      {
        timestamp: 1514325600,
        open: 1.18673,
        close: 1.18593,
        high: 1.18797,
        low: 1.18473
      },
      {
        timestamp: 1514412000,
        open: 1.18593,
        close: 1.18891,
        high: 1.1911,
        low: 1.18558
      },
      {
        timestamp: 1514498400,
        open: 1.18891,
        close: 1.19432,
        high: 1.19598,
        low: 1.18899
      },
      {
        timestamp: 1514584800,
        open: 1.19432,
        close: 1.20098,
        high: 1.20262,
        low: 1.19375
      },
      {
        timestamp: 1514844000,
        open: 1.20098,
        close: 1.2015,
        high: 1.2015,
        low: 1.2
      },
      {
        timestamp: 1514930400,
        open: 1.2015,
        close: 1.20591,
        high: 1.20819,
        low: 1.20045
      },
      {
        timestamp: 1515016800,
        open: 1.20591,
        close: 1.20152,
        high: 1.20669,
        low: 1.20018
      },
      {
        timestamp: 1515103200,
        open: 1.20152,
        close: 1.20693,
        high: 1.20897,
        low: 1.20051
      },
      {
        timestamp: 1515189600,
        open: 1.20693,
        close: 1.20358,
        high: 1.20838,
        low: 1.20213
      },
      {
        timestamp: 1515362400,
        open: 1.20358,
        close: 1.20324,
        high: 1.20357,
        low: 1.20227
      },
      {
        timestamp: 1515448800,
        open: 1.20324,
        close: 1.19681,
        high: 1.20531,
        low: 1.19564
      },
      {
        timestamp: 1515535200,
        open: 1.19681,
        close: 1.19377,
        high: 1.19764,
        low: 1.19161
      },
      {
        timestamp: 1515621600,
        open: 1.19377,
        close: 1.19489,
        high: 1.20187,
        low: 1.19239
      },
      {
        timestamp: 1515708000,
        open: 1.19489,
        close: 1.20326,
        high: 1.20596,
        low: 1.19299
      },
      {
        timestamp: 1515794400,
        open: 1.20326,
        close: 1.22094,
        high: 1.22202,
        low: 1.20316
      },
      {
        timestamp: 1515967200,
        open: 1.22094,
        close: 1.21977,
        high: 1.22061,
        low: 1.21917
      },
      {
        timestamp: 1516053600,
        open: 1.21977,
        close: 1.22648,
        high: 1.22973,
        low: 1.21882
      },
      {
        timestamp: 1516140000,
        open: 1.22648,
        close: 1.22606,
        high: 1.22841,
        low: 1.21958
      },
      {
        timestamp: 1516226400,
        open: 1.22606,
        close: 1.21872,
        high: 1.23239,
        low: 1.21781
      },
      {
        timestamp: 1516312800,
        open: 1.21872,
        close: 1.22402,
        high: 1.22656,
        low: 1.21661
      },
      {
        timestamp: 1516399200,
        open: 1.22402,
        close: 1.222,
        high: 1.22962,
        low: 1.22155
      },
      {
        timestamp: 1516572000,
        open: 1.222,
        close: 1.22722,
        high: 1.22757,
        low: 1.222
      },
      {
        timestamp: 1516658400,
        open: 1.22722,
        close: 1.22628,
        high: 1.22752,
        low: 1.22147
      },
      {
        timestamp: 1516744800,
        open: 1.22628,
        close: 1.22993,
        high: 1.23069,
        low: 1.22237
      },
      {
        timestamp: 1516831200,
        open: 1.22993,
        close: 1.24096,
        high: 1.24157,
        low: 1.2292
      },
      {
        timestamp: 1516917600,
        open: 1.24096,
        close: 1.23987,
        high: 1.25387,
        low: 1.23648
      },
      {
        timestamp: 1517004000,
        open: 1.23987,
        close: 1.24334,
        high: 1.24945,
        low: 1.23708
      },
      {
        timestamp: 1517176800,
        open: 1.24334,
        close: 1.24299,
        high: 1.24396,
        low: 1.24247
      },
      {
        timestamp: 1517263200,
        open: 1.24299,
        close: 1.23841,
        high: 1.2433,
        low: 1.23374
      },
      {
        timestamp: 1517349600,
        open: 1.23841,
        close: 1.24033,
        high: 1.24547,
        low: 1.23353
      },
      {
        timestamp: 1517436000,
        open: 1.24033,
        close: 1.24143,
        high: 1.24756,
        low: 1.23874
      },
      {
        timestamp: 1517522400,
        open: 1.24143,
        close: 1.25132,
        high: 1.25235,
        low: 1.23858
      },
      {
        timestamp: 1517608800,
        open: 1.25132,
        close: 1.24599,
        high: 1.25188,
        low: 1.24096
      },
      {
        timestamp: 1517781600,
        open: 1.24599,
        close: 1.24367,
        high: 1.24417,
        low: 1.24278
      },
      {
        timestamp: 1517868000,
        open: 1.24367,
        close: 1.23684,
        high: 1.24755,
        low: 1.23634
      },
      {
        timestamp: 1517954400,
        open: 1.23684,
        close: 1.23784,
        high: 1.24353,
        low: 1.23145
      },
      {
        timestamp: 1518040800,
        open: 1.23784,
        close: 1.22664,
        high: 1.24069,
        low: 1.22462
      },
      {
        timestamp: 1518127200,
        open: 1.22664,
        close: 1.22482,
        high: 1.22958,
        low: 1.22127
      },
      {
        timestamp: 1518213600,
        open: 1.22482,
        close: 1.22558,
        high: 1.2288,
        low: 1.22064
      },
      {
        timestamp: 1518386400,
        open: 1.22558,
        close: 1.22512,
        high: 1.22532,
        low: 1.22479
      },
      {
        timestamp: 1518472800,
        open: 1.22512,
        close: 1.22935,
        high: 1.22983,
        low: 1.22357
      },
      {
        timestamp: 1518559200,
        open: 1.22935,
        close: 1.23536,
        high: 1.23719,
        low: 1.22853
      },
      {
        timestamp: 1518645600,
        open: 1.23536,
        close: 1.24525,
        high: 1.24657,
        low: 1.22766
      },
      {
        timestamp: 1518732000,
        open: 1.24525,
        close: 1.25075,
        high: 1.25111,
        low: 1.24488
      },
      {
        timestamp: 1518818400,
        open: 1.25075,
        close: 1.24136,
        high: 1.25561,
        low: 1.23939
      },
      {
        timestamp: 1518991200,
        open: 1.24136,
        close: 1.24058,
        high: 1.24063,
        low: 1.24005
      },
      {
        timestamp: 1519077600,
        open: 1.24058,
        close: 1.24083,
        high: 1.24361,
        low: 1.23697
      },
      {
        timestamp: 1519164000,
        open: 1.24083,
        close: 1.23383,
        high: 1.24133,
        low: 1.23203
      },
      {
        timestamp: 1519250400,
        open: 1.23383,
        close: 1.22853,
        high: 1.23607,
        low: 1.22818
      },
      {
        timestamp: 1519336800,
        open: 1.22853,
        close: 1.23325,
        high: 1.23528,
        low: 1.22599
      },
      {
        timestamp: 1519423200,
        open: 1.23325,
        close: 1.22993,
        high: 1.23384,
        low: 1.22804
      },
      {
        timestamp: 1519596000,
        open: 1.22993,
        close: 1.22954,
        high: 1.23055,
        low: 1.22875
      },
      {
        timestamp: 1519682400,
        open: 1.22954,
        close: 1.23182,
        high: 1.2356,
        low: 1.22783
      },
      {
        timestamp: 1519768800,
        open: 1.23182,
        close: 1.22333,
        high: 1.2347,
        low: 1.22221
      },
      {
        timestamp: 1519855200,
        open: 1.22333,
        close: 1.21957,
        high: 1.22424,
        low: 1.21885
      },
      {
        timestamp: 1519941600,
        open: 1.21957,
        close: 1.22681,
        high: 1.22737,
        low: 1.21551
      },
      {
        timestamp: 1520028000,
        open: 1.22681,
        close: 1.2322,
        high: 1.23366,
        low: 1.22522
      },
      {
        timestamp: 1520200800,
        open: 1.2322,
        close: 1.23347,
        high: 1.23597,
        low: 1.2322
      },
      {
        timestamp: 1520287200,
        open: 1.23347,
        close: 1.23368,
        high: 1.23667,
        low: 1.22697
      },
      {
        timestamp: 1520373600,
        open: 1.23368,
        close: 1.24044,
        high: 1.2421,
        low: 1.23287
      },
      {
        timestamp: 1520460000,
        open: 1.24044,
        close: 1.24123,
        high: 1.24456,
        low: 1.23855
      },
      {
        timestamp: 1520546400,
        open: 1.24123,
        close: 1.23126,
        high: 1.2447,
        low: 1.22987
      },
      {
        timestamp: 1520629200,
        open: 1.23126,
        close: 1.23092,
        high: 1.2335,
        low: 1.22738
      },
      {
        timestamp: 1520802000,
        open: 1.23092,
        close: 1.23142,
        high: 1.23197,
        low: 1.23092
      },
      {
        timestamp: 1520888400,
        open: 1.23142,
        close: 1.2335,
        high: 1.23463,
        low: 1.22911
      },
      {
        timestamp: 1520974800,
        open: 1.2335,
        close: 1.23901,
        high: 1.24081,
        low: 1.23153
      },
      {
        timestamp: 1521061200,
        open: 1.23901,
        close: 1.23688,
        high: 1.24134,
        low: 1.23479
      },
      {
        timestamp: 1521147600,
        open: 1.23688,
        close: 1.23053,
        high: 1.23845,
        low: 1.2301
      },
      {
        timestamp: 1521234000,
        open: 1.23053,
        close: 1.22911,
        high: 1.2337,
        low: 1.22608
      },
      {
        timestamp: 1521406800,
        open: 1.22911,
        close: 1.2286,
        high: 1.22972,
        low: 1.22844
      },
      {
        timestamp: 1521493200,
        open: 1.2286,
        close: 1.23362,
        high: 1.23595,
        low: 1.22588
      },
      {
        timestamp: 1521579600,
        open: 1.23362,
        close: 1.22424,
        high: 1.23553,
        low: 1.22402
      },
      {
        timestamp: 1521666000,
        open: 1.22424,
        close: 1.23389,
        high: 1.23508,
        low: 1.22418
      },
      {
        timestamp: 1521752400,
        open: 1.23389,
        close: 1.23033,
        high: 1.23892,
        low: 1.22859
      },
      {
        timestamp: 1521838800,
        open: 1.23033,
        close: 1.23548,
        high: 1.23743,
        low: 1.2302
      },
      {
        timestamp: 1522011600,
        open: 1.23548,
        close: 1.23545,
        high: 1.23562,
        low: 1.23435
      },
      {
        timestamp: 1522098000,
        open: 1.23545,
        close: 1.24451,
        high: 1.24624,
        low: 1.23525
      },
      {
        timestamp: 1522184400,
        open: 1.24451,
        close: 1.24037,
        high: 1.24772,
        low: 1.2373
      },
      {
        timestamp: 1522270800,
        open: 1.24037,
        close: 1.23106,
        high: 1.24224,
        low: 1.23007
      },
      {
        timestamp: 1522357200,
        open: 1.23106,
        close: 1.23016,
        high: 1.23366,
        low: 1.22846
      },
      {
        timestamp: 1522443600,
        open: 1.23016,
        close: 1.23265,
        high: 1.2332,
        low: 1.22907
      },
      {
        timestamp: 1522616400,
        open: 1.23265,
        close: 1.23247,
        high: 1.23326,
        low: 1.23247
      },
      {
        timestamp: 1522702800,
        open: 1.23247,
        close: 1.23034,
        high: 1.23457,
        low: 1.22827
      },
      {
        timestamp: 1522789200,
        open: 1.23034,
        close: 1.22721,
        high: 1.23367,
        low: 1.22543
      },
      {
        timestamp: 1522875600,
        open: 1.22721,
        close: 1.22793,
        high: 1.23155,
        low: 1.22577
      },
      {
        timestamp: 1522962000,
        open: 1.22793,
        close: 1.22417,
        high: 1.22909,
        low: 1.2219
      },
      {
        timestamp: 1523048400,
        open: 1.22417,
        close: 1.22839,
        high: 1.22916,
        low: 1.22165
      },
      {
        timestamp: 1523221200,
        open: 1.22839,
        close: 1.22893,
        high: 1.22904,
        low: 1.22792
      },
      {
        timestamp: 1523307600,
        open: 1.22893,
        close: 1.23217,
        high: 1.23315,
        low: 1.22612
      },
      {
        timestamp: 1523394000,
        open: 1.23217,
        close: 1.2357,
        high: 1.23785,
        low: 1.23035
      },
      {
        timestamp: 1523480400,
        open: 1.2357,
        close: 1.23685,
        high: 1.2399,
        low: 1.23478
      },
      {
        timestamp: 1523566800,
        open: 1.23685,
        close: 1.23281,
        high: 1.23805,
        low: 1.22999
      },
      {
        timestamp: 1523653200,
        open: 1.23281,
        close: 1.2334,
        high: 1.23467,
        low: 1.23078
      },
      {
        timestamp: 1523826000,
        open: 1.2334,
        close: 1.23372,
        high: 1.23429,
        low: 1.23307
      },
      {
        timestamp: 1523912400,
        open: 1.23372,
        close: 1.23808,
        high: 1.23954,
        low: 1.23247
      },
      {
        timestamp: 1523998800,
        open: 1.23808,
        close: 1.23715,
        high: 1.24145,
        low: 1.23368
      },
      {
        timestamp: 1524085200,
        open: 1.23715,
        close: 1.23748,
        high: 1.2398,
        low: 1.23426
      },
      {
        timestamp: 1524171600,
        open: 1.23748,
        close: 1.23469,
        high: 1.24009,
        low: 1.233
      },
      {
        timestamp: 1524258000,
        open: 1.23469,
        close: 1.22903,
        high: 1.23537,
        low: 1.22505
      },
      {
        timestamp: 1524430800,
        open: 1.22903,
        close: 1.22796,
        high: 1.22798,
        low: 1.22659
      },
      {
        timestamp: 1524517200,
        open: 1.22796,
        close: 1.221,
        high: 1.22902,
        low: 1.21981
      },
      {
        timestamp: 1524603600,
        open: 1.221,
        close: 1.22344,
        high: 1.22455,
        low: 1.21825
      },
      {
        timestamp: 1524690000,
        open: 1.22344,
        close: 1.21625,
        high: 1.22396,
        low: 1.21612
      },
      {
        timestamp: 1524776400,
        open: 1.21625,
        close: 1.21041,
        high: 1.22108,
        low: 1.2097
      },
      {
        timestamp: 1524862800,
        open: 1.21041,
        close: 1.21325,
        high: 1.21346,
        low: 1.20561
      },
      {
        timestamp: 1525035600,
        open: 1.21325,
        close: 1.21255,
        high: 1.21316,
        low: 1.21209
      },
      {
        timestamp: 1525122000,
        open: 1.21255,
        close: 1.20784,
        high: 1.21395,
        low: 1.2065
      },
      {
        timestamp: 1525208400,
        open: 1.20784,
        close: 1.19932,
        high: 1.2085,
        low: 1.19818
      },
      {
        timestamp: 1525294800,
        open: 1.19932,
        close: 1.19521,
        high: 1.20324,
        low: 1.19386
      },
      {
        timestamp: 1525381200,
        open: 1.19521,
        close: 1.199,
        high: 1.20096,
        low: 1.19485
      },
      {
        timestamp: 1525467600,
        open: 1.199,
        close: 1.19648,
        high: 1.19962,
        low: 1.19111
      },
      {
        timestamp: 1525640400,
        open: 1.19648,
        close: 1.19644,
        high: 1.1968,
        low: 1.19506
      },
      {
        timestamp: 1525726800,
        open: 1.19644,
        close: 1.19229,
        high: 1.19788,
        low: 1.18982
      },
      {
        timestamp: 1525813200,
        open: 1.19229,
        close: 1.18659,
        high: 1.19396,
        low: 1.18386
      },
      {
        timestamp: 1525899600,
        open: 1.18659,
        close: 1.18514,
        high: 1.18978,
        low: 1.18234
      },
      {
        timestamp: 1525986000,
        open: 1.18514,
        close: 1.1917,
        high: 1.19474,
        low: 1.18438
      },
      {
        timestamp: 1526072400,
        open: 1.1917,
        close: 1.19445,
        high: 1.19687,
        low: 1.18919
      },
      {
        timestamp: 1526245200,
        open: 1.19445,
        close: 1.19423,
        high: 1.1955,
        low: 1.19412
      },
      {
        timestamp: 1526331600,
        open: 1.19423,
        close: 1.19281,
        high: 1.19969,
        low: 1.1927
      },
      {
        timestamp: 1526418000,
        open: 1.19281,
        close: 1.18384,
        high: 1.19393,
        low: 1.1821
      },
      {
        timestamp: 1526504400,
        open: 1.18384,
        close: 1.18082,
        high: 1.18548,
        low: 1.17639
      },
      {
        timestamp: 1526590800,
        open: 1.18082,
        close: 1.17959,
        high: 1.18383,
        low: 1.17771
      },
      {
        timestamp: 1526677200,
        open: 1.17959,
        close: 1.17749,
        high: 1.18229,
        low: 1.17504
      },
      {
        timestamp: 1526850000,
        open: 1.17749,
        close: 1.1774,
        high: 1.17806,
        low: 1.17694
      },
      {
        timestamp: 1526936400,
        open: 1.1774,
        close: 1.17923,
        high: 1.17963,
        low: 1.17174
      },
      {
        timestamp: 1527022800,
        open: 1.17923,
        close: 1.17804,
        high: 1.18305,
        low: 1.17573
      },
      {
        timestamp: 1527109200,
        open: 1.17804,
        close: 1.16975,
        high: 1.17904,
        low: 1.16765
      },
      {
        timestamp: 1527195600,
        open: 1.16975,
        close: 1.17201,
        high: 1.17511,
        low: 1.16916
      },
      {
        timestamp: 1527282000,
        open: 1.17201,
        close: 1.16524,
        high: 1.17344,
        low: 1.16468
      },
      {
        timestamp: 1527454800,
        open: 1.16524,
        close: 1.16881,
        high: 1.16902,
        low: 1.16524
      },
      {
        timestamp: 1527541200,
        open: 1.16881,
        close: 1.16255,
        high: 1.17292,
        low: 1.1608
      },
      {
        timestamp: 1527627600,
        open: 1.16255,
        close: 1.15403,
        high: 1.16404,
        low: 1.15108
      },
      {
        timestamp: 1527714000,
        open: 1.15403,
        close: 1.16668,
        high: 1.16769,
        low: 1.15194
      },
      {
        timestamp: 1527800400,
        open: 1.16668,
        close: 1.16958,
        high: 1.17249,
        low: 1.16418
      },
      {
        timestamp: 1527886800,
        open: 1.16958,
        close: 1.1662,
        high: 1.17189,
        low: 1.16185
      },
      {
        timestamp: 1528059600,
        open: 1.1662,
        close: 1.16646,
        high: 1.16651,
        low: 1.16604
      },
      {
        timestamp: 1528146000,
        open: 1.16646,
        close: 1.16998,
        high: 1.17454,
        low: 1.1661
      },
      {
        timestamp: 1528232400,
        open: 1.16998,
        close: 1.17188,
        high: 1.1733,
        low: 1.16533
      },
      {
        timestamp: 1528318800,
        open: 1.17188,
        close: 1.17751,
        high: 1.17964,
        low: 1.17143
      },
      {
        timestamp: 1528405200,
        open: 1.17751,
        close: 1.18018,
        high: 1.18406,
        low: 1.17741
      },
      {
        timestamp: 1528491600,
        open: 1.18018,
        close: 1.17713,
        high: 1.18111,
        low: 1.17279
      },
      {
        timestamp: 1528664400,
        open: 1.17713,
        close: 1.17777,
        high: 1.1781,
        low: 1.17554
      },
      {
        timestamp: 1528750800,
        open: 1.17777,
        close: 1.17857,
        high: 1.18213,
        low: 1.1772
      },
      {
        timestamp: 1528837200,
        open: 1.17857,
        close: 1.1747,
        high: 1.18099,
        low: 1.17337
      },
      {
        timestamp: 1528923600,
        open: 1.1747,
        close: 1.17923,
        high: 1.18017,
        low: 1.17262
      },
      {
        timestamp: 1529010000,
        open: 1.17923,
        close: 1.15711,
        high: 1.1853,
        low: 1.15639
      },
      {
        timestamp: 1529096400,
        open: 1.15711,
        close: 1.16103,
        high: 1.16278,
        low: 1.15439
      },
      {
        timestamp: 1529269200,
        open: 1.16103,
        close: 1.15915,
        high: 1.16156,
        low: 1.15858
      },
      {
        timestamp: 1529355600,
        open: 1.15915,
        close: 1.16239,
        high: 1.16251,
        low: 1.1566
      },
      {
        timestamp: 1529442000,
        open: 1.16239,
        close: 1.1592,
        high: 1.16453,
        low: 1.15312
      },
      {
        timestamp: 1529528400,
        open: 1.1592,
        close: 1.15734,
        high: 1.16007,
        low: 1.15376
      },
      {
        timestamp: 1529614800,
        open: 1.15734,
        close: 1.16048,
        high: 1.16344,
        low: 1.15089
      },
      {
        timestamp: 1529701200,
        open: 1.16048,
        close: 1.16599,
        high: 1.16758,
        low: 1.16014
      },
      {
        timestamp: 1529874000,
        open: 1.16599,
        close: 1.16675,
        high: 1.16712,
        low: 1.16572
      },
      {
        timestamp: 1529960400,
        open: 1.16675,
        close: 1.17053,
        high: 1.17138,
        low: 1.16291
      },
      {
        timestamp: 1530046800,
        open: 1.17053,
        close: 1.16499,
        high: 1.17213,
        low: 1.16355
      },
      {
        timestamp: 1530133200,
        open: 1.16499,
        close: 1.15564,
        high: 1.1673,
        low: 1.15412
      },
      {
        timestamp: 1530219600,
        open: 1.15564,
        close: 1.15695,
        high: 1.16015,
        low: 1.15279
      },
      {
        timestamp: 1530306000,
        open: 1.15695,
        close: 1.16867,
        high: 1.16913,
        low: 1.15592
      },
      {
        timestamp: 1530478800,
        open: 1.16867,
        close: 1.16807,
        high: 1.16957,
        low: 1.16327
      },
      {
        timestamp: 1530565200,
        open: 1.16807,
        close: 1.164,
        high: 1.16953,
        low: 1.15919
      },
      {
        timestamp: 1530651600,
        open: 1.164,
        close: 1.16601,
        high: 1.16738,
        low: 1.16212
      },
      {
        timestamp: 1530738000,
        open: 1.16601,
        close: 1.16582,
        high: 1.16825,
        low: 1.16313
      },
      {
        timestamp: 1530824400,
        open: 1.16582,
        close: 1.16924,
        high: 1.17209,
        low: 1.16505
      },
      {
        timestamp: 1530910800,
        open: 1.16924,
        close: 1.17461,
        high: 1.17681,
        low: 1.16807
      },
      {
        timestamp: 1531083600,
        open: 1.17461,
        close: 1.17452,
        high: 1.17576,
        low: 1.17386
      },
      {
        timestamp: 1531170000,
        open: 1.17452,
        close: 1.17521,
        high: 1.17915,
        low: 1.17332
      },
      {
        timestamp: 1531256400,
        open: 1.17521,
        close: 1.17455,
        high: 1.17634,
        low: 1.16911
      },
      {
        timestamp: 1531342800,
        open: 1.17455,
        close: 1.16742,
        high: 1.1759,
        low: 1.16661
      },
      {
        timestamp: 1531429200,
        open: 1.16742,
        close: 1.16722,
        high: 1.16964,
        low: 1.16503
      },
      {
        timestamp: 1531515600,
        open: 1.16722,
        close: 1.16874,
        high: 1.16878,
        low: 1.16137
      },
      {
        timestamp: 1531688400,
        open: 1.16874,
        close: 1.16911,
        high: 1.16956,
        low: 1.1686
      },
      {
        timestamp: 1531774800,
        open: 1.16911,
        close: 1.17121,
        high: 1.17262,
        low: 1.16764
      },
      {
        timestamp: 1531861200,
        open: 1.17121,
        close: 1.16623,
        high: 1.17454,
        low: 1.16499
      },
      {
        timestamp: 1531947600,
        open: 1.16623,
        close: 1.16396,
        high: 1.16657,
        low: 1.16026
      },
      {
        timestamp: 1532034000,
        open: 1.16396,
        close: 1.1643,
        high: 1.16789,
        low: 1.15756
      },
      {
        timestamp: 1532120400,
        open: 1.1643,
        close: 1.17245,
        high: 1.17397,
        low: 1.1627
      },
      {
        timestamp: 1532293200,
        open: 1.17245,
        close: 1.17291,
        high: 1.17376,
        low: 1.17245
      },
      {
        timestamp: 1532379600,
        open: 1.17291,
        close: 1.16934,
        high: 1.17512,
        low: 1.16844
      },
      {
        timestamp: 1532466000,
        open: 1.16934,
        close: 1.16854,
        high: 1.17176,
        low: 1.16553
      },
      {
        timestamp: 1532552400,
        open: 1.16854,
        close: 1.17291,
        high: 1.17394,
        low: 1.16645
      },
      {
        timestamp: 1532638800,
        open: 1.17291,
        close: 1.16439,
        high: 1.17443,
        low: 1.16409
      },
      {
        timestamp: 1532725200,
        open: 1.16439,
        close: 1.16586,
        high: 1.16651,
        low: 1.16212
      },
      {
        timestamp: 1532898000,
        open: 1.16586,
        close: 1.16606,
        high: 1.16651,
        low: 1.16541
      },
      {
        timestamp: 1532984400,
        open: 1.16606,
        close: 1.17069,
        high: 1.17195,
        low: 1.16486
      },
      {
        timestamp: 1533070800,
        open: 1.17069,
        close: 1.16924,
        high: 1.17465,
        low: 1.16849
      },
      {
        timestamp: 1533157200,
        open: 1.16924,
        close: 1.16607,
        high: 1.17004,
        low: 1.1658
      },
      {
        timestamp: 1533243600,
        open: 1.16607,
        close: 1.15852,
        high: 1.16683,
        low: 1.15826
      },
      {
        timestamp: 1533330000,
        open: 1.15852,
        close: 1.15715,
        high: 1.16114,
        low: 1.15608
      },
      {
        timestamp: 1533502800,
        open: 1.15715,
        close: 1.15736,
        high: 1.15739,
        low: 1.15687
      },
      {
        timestamp: 1533589200,
        open: 1.15736,
        close: 1.15544,
        high: 1.15732,
        low: 1.15307
      },
      {
        timestamp: 1533675600,
        open: 1.15544,
        close: 1.15996,
        high: 1.16089,
        low: 1.15519
      },
      {
        timestamp: 1533762000,
        open: 1.15996,
        close: 1.16107,
        high: 1.1629,
        low: 1.15739
      },
      {
        timestamp: 1533848400,
        open: 1.16107,
        close: 1.15277,
        high: 1.16202,
        low: 1.15266
      },
      {
        timestamp: 1533934800,
        open: 1.15277,
        close: 1.14157,
        high: 1.15372,
        low: 1.13887
      },
      {
        timestamp: 1534107600,
        open: 1.14157,
        close: 1.13738,
        high: 1.13944,
        low: 1.13698
      },
      {
        timestamp: 1534194000,
        open: 1.13738,
        close: 1.14115,
        high: 1.14336,
        low: 1.13658
      },
      {
        timestamp: 1534280400,
        open: 1.14115,
        close: 1.13453,
        high: 1.14301,
        low: 1.13308
      },
      {
        timestamp: 1534366800,
        open: 1.13453,
        close: 1.13463,
        high: 1.13556,
        low: 1.13017
      },
      {
        timestamp: 1534453200,
        open: 1.13463,
        close: 1.1378,
        high: 1.14098,
        low: 1.13366
      },
      {
        timestamp: 1534539600,
        open: 1.1378,
        close: 1.14422,
        high: 1.14456,
        low: 1.13671
      },
      {
        timestamp: 1534712400,
        open: 1.14422,
        close: 1.14423,
        high: 1.14426,
        low: 1.14356
      },
      {
        timestamp: 1534798800,
        open: 1.14423,
        close: 1.14831,
        high: 1.14857,
        low: 1.13951
      },
      {
        timestamp: 1534885200,
        open: 1.14831,
        close: 1.15714,
        high: 1.1602,
        low: 1.14821
      },
      {
        timestamp: 1534971600,
        open: 1.15714,
        close: 1.15978,
        high: 1.16236,
        low: 1.15539
      },
      {
        timestamp: 1535058000,
        open: 1.15978,
        close: 1.15413,
        high: 1.16004,
        low: 1.15304
      },
      {
        timestamp: 1535144400,
        open: 1.15413,
        close: 1.16244,
        high: 1.16405,
        low: 1.15356
      },
      {
        timestamp: 1535317200,
        open: 1.16244,
        close: 1.16189,
        high: 1.16301,
        low: 1.1611
      },
      {
        timestamp: 1535403600,
        open: 1.16189,
        close: 1.16786,
        high: 1.16944,
        low: 1.15953
      },
      {
        timestamp: 1535490000,
        open: 1.16786,
        close: 1.16955,
        high: 1.17344,
        low: 1.16632
      },
      {
        timestamp: 1535576400,
        open: 1.16955,
        close: 1.17088,
        high: 1.17106,
        low: 1.16525
      },
      {
        timestamp: 1535662800,
        open: 1.17088,
        close: 1.16718,
        high: 1.1719,
        low: 1.16422
      },
      {
        timestamp: 1535749200,
        open: 1.16718,
        close: 1.16058,
        high: 1.16908,
        low: 1.15853
      },
      {
        timestamp: 1535922000,
        open: 1.16058,
        close: 1.15985,
        high: 1.16105,
        low: 1.15923
      },
      {
        timestamp: 1536008400,
        open: 1.15985,
        close: 1.1623,
        high: 1.16286,
        low: 1.15896
      },
      {
        timestamp: 1536094800,
        open: 1.1623,
        close: 1.15833,
        high: 1.16235,
        low: 1.15309
      },
      {
        timestamp: 1536181200,
        open: 1.15833,
        close: 1.16312,
        high: 1.16413,
        low: 1.15432
      },
      {
        timestamp: 1536267600,
        open: 1.16312,
        close: 1.16244,
        high: 1.16599,
        low: 1.16062
      },
      {
        timestamp: 1536354000,
        open: 1.16244,
        close: 1.15559,
        high: 1.16502,
        low: 1.15513
      },
      {
        timestamp: 1536526800,
        open: 1.15559,
        close: 1.15518,
        high: 1.15568,
        low: 1.15505
      },
      {
        timestamp: 1536613200,
        open: 1.15518,
        close: 1.15963,
        high: 1.1617,
        low: 1.15263
      },
      {
        timestamp: 1536699600,
        open: 1.15963,
        close: 1.16078,
        high: 1.16449,
        low: 1.1566
      },
      {
        timestamp: 1536786000,
        open: 1.16078,
        close: 1.16271,
        high: 1.16505,
        low: 1.15708
      },
      {
        timestamp: 1536872400,
        open: 1.16271,
        close: 1.16908,
        high: 1.17018,
        low: 1.16097
      },
      {
        timestamp: 1536958800,
        open: 1.16908,
        close: 1.16247,
        high: 1.17224,
        low: 1.16214
      },
      {
        timestamp: 1537131600,
        open: 1.16247,
        close: 1.16286,
        high: 1.16318,
        low: 1.1619
      },
      {
        timestamp: 1537218000,
        open: 1.16286,
        close: 1.16844,
        high: 1.16989,
        low: 1.16187
      },
      {
        timestamp: 1537304400,
        open: 1.16844,
        close: 1.16702,
        high: 1.17253,
        low: 1.16529
      },
      {
        timestamp: 1537390800,
        open: 1.16702,
        close: 1.16734,
        high: 1.17156,
        low: 1.16507
      },
      {
        timestamp: 1537477200,
        open: 1.16734,
        close: 1.17778,
        high: 1.17854,
        low: 1.16697
      },
      {
        timestamp: 1537563600,
        open: 1.17778,
        close: 1.17511,
        high: 1.18035,
        low: 1.17336
      },
      {
        timestamp: 1537736400,
        open: 1.17511,
        close: 1.17532,
        high: 1.17552,
        low: 1.17405
      },
      {
        timestamp: 1537822800,
        open: 1.17532,
        close: 1.17488,
        high: 1.18159,
        low: 1.17249
      },
      {
        timestamp: 1537909200,
        open: 1.17488,
        close: 1.17683,
        high: 1.17934,
        low: 1.17316
      },
      {
        timestamp: 1537995600,
        open: 1.17683,
        close: 1.17424,
        high: 1.17986,
        low: 1.17266
      },
      {
        timestamp: 1538082000,
        open: 1.17424,
        close: 1.16414,
        high: 1.17579,
        low: 1.16397
      },
      {
        timestamp: 1538168400,
        open: 1.16414,
        close: 1.16054,
        high: 1.1652,
        low: 1.15702
      },
      {
        timestamp: 1538341200,
        open: 1.16054,
        close: 1.16216,
        high: 1.16291,
        low: 1.16054
      },
      {
        timestamp: 1538427600,
        open: 1.16216,
        close: 1.15788,
        high: 1.16257,
        low: 1.15643
      },
      {
        timestamp: 1538514000,
        open: 1.15788,
        close: 1.15483,
        high: 1.15812,
        low: 1.15059
      },
      {
        timestamp: 1538600400,
        open: 1.15483,
        close: 1.14798,
        high: 1.15945,
        low: 1.14651
      },
      {
        timestamp: 1538686800,
        open: 1.14798,
        close: 1.15154,
        high: 1.15435,
        low: 1.14641
      },
      {
        timestamp: 1538773200,
        open: 1.15154,
        close: 1.15208,
        high: 1.15505,
        low: 1.14847
      },
      {
        timestamp: 1538946000,
        open: 1.15208,
        close: 1.15256,
        high: 1.15354,
        low: 1.15223
      },
      {
        timestamp: 1539032400,
        open: 1.15256,
        close: 1.14923,
        high: 1.15303,
        low: 1.14606
      },
      {
        timestamp: 1539118800,
        open: 1.14923,
        close: 1.14918,
        high: 1.1504,
        low: 1.14329
      },
      {
        timestamp: 1539205200,
        open: 1.14918,
        close: 1.15219,
        high: 1.15461,
        low: 1.14806
      },
      {
        timestamp: 1539291600,
        open: 1.15219,
        close: 1.1594,
        high: 1.16001,
        low: 1.15217
      },
      {
        timestamp: 1539378000,
        open: 1.1594,
        close: 1.15616,
        high: 1.16112,
        low: 1.1535
      },
      {
        timestamp: 1539550800,
        open: 1.15616,
        close: 1.1545,
        high: 1.15607,
        low: 1.15365
      },
      {
        timestamp: 1539637200,
        open: 1.1545,
        close: 1.15805,
        high: 1.16069,
        low: 1.1545
      },
      {
        timestamp: 1539723600,
        open: 1.15805,
        close: 1.15759,
        high: 1.16222,
        low: 1.15667
      },
      {
        timestamp: 1539810000,
        open: 1.15759,
        close: 1.15015,
        high: 1.15813,
        low: 1.14967
      },
      {
        timestamp: 1539896400,
        open: 1.15015,
        close: 1.1454,
        high: 1.1528,
        low: 1.14498
      },
      {
        timestamp: 1539982800,
        open: 1.1454,
        close: 1.15155,
        high: 1.15355,
        low: 1.14337
      },
      {
        timestamp: 1540155600,
        open: 1.15155,
        close: 1.15186,
        high: 1.15212,
        low: 1.15136
      },
      {
        timestamp: 1540242000,
        open: 1.15186,
        close: 1.14661,
        high: 1.15509,
        low: 1.14565
      },
      {
        timestamp: 1540328400,
        open: 1.14661,
        close: 1.14733,
        high: 1.14945,
        low: 1.144
      },
      {
        timestamp: 1540414800,
        open: 1.14733,
        close: 1.13931,
        high: 1.14775,
        low: 1.13794
      },
      {
        timestamp: 1540501200,
        open: 1.13931,
        close: 1.13755,
        high: 1.14335,
        low: 1.13568
      },
      {
        timestamp: 1540587600,
        open: 1.13755,
        close: 1.14057,
        high: 1.1422,
        low: 1.13364
      },
      {
        timestamp: 1540760400,
        open: 1.14057,
        close: 1.13947,
        high: 1.14041,
        low: 1.13885
      },
      {
        timestamp: 1540846800,
        open: 1.13947,
        close: 1.13736,
        high: 1.14172,
        low: 1.13613
      },
      {
        timestamp: 1540933200,
        open: 1.13736,
        close: 1.13458,
        high: 1.13886,
        low: 1.13412
      },
      {
        timestamp: 1541019600,
        open: 1.13458,
        close: 1.13138,
        high: 1.1361,
        low: 1.13028
      },
      {
        timestamp: 1541106000,
        open: 1.13138,
        close: 1.14097,
        high: 1.14249,
        low: 1.1309
      },
      {
        timestamp: 1541196000,
        open: 1.14097,
        close: 1.13897,
        high: 1.14566,
        low: 1.13728
      },
      {
        timestamp: 1541368800,
        open: 1.13897,
        close: 1.13888,
        high: 1.14056,
        low: 1.13884
      },
      {
        timestamp: 1541455200,
        open: 1.13888,
        close: 1.14087,
        high: 1.14245,
        low: 1.13541
      },
      {
        timestamp: 1541541600,
        open: 1.14087,
        close: 1.14282,
        high: 1.14385,
        low: 1.1392
      },
      {
        timestamp: 1541628000,
        open: 1.14282,
        close: 1.14273,
        high: 1.15005,
        low: 1.13954
      },
      {
        timestamp: 1541714400,
        open: 1.14273,
        close: 1.13641,
        high: 1.14475,
        low: 1.13522
      },
      {
        timestamp: 1541800800,
        open: 1.13641,
        close: 1.13366,
        high: 1.13696,
        low: 1.1317
      },
      {
        timestamp: 1541973600,
        open: 1.13366,
        close: 1.13251,
        high: 1.13266,
        low: 1.13136
      },
      {
        timestamp: 1542060000,
        open: 1.13251,
        close: 1.12194,
        high: 1.13326,
        low: 1.12163
      },
      {
        timestamp: 1542146400,
        open: 1.12194,
        close: 1.1293,
        high: 1.12942,
        low: 1.12181
      },
      {
        timestamp: 1542232800,
        open: 1.1293,
        close: 1.13127,
        high: 1.13485,
        low: 1.12637
      },
      {
        timestamp: 1542319200,
        open: 1.13127,
        close: 1.13308,
        high: 1.13633,
        low: 1.12715
      },
      {
        timestamp: 1542405600,
        open: 1.13308,
        close: 1.14189,
        high: 1.14218,
        low: 1.13223
      },
      {
        timestamp: 1542578400,
        open: 1.14189,
        close: 1.14161,
        high: 1.14194,
        low: 1.14004
      },
      {
        timestamp: 1542664800,
        open: 1.14161,
        close: 1.14569,
        high: 1.14653,
        low: 1.13948
      },
      {
        timestamp: 1542751200,
        open: 1.14569,
        close: 1.13711,
        high: 1.14731,
        low: 1.13595
      },
      {
        timestamp: 1542837600,
        open: 1.13711,
        close: 1.1387,
        high: 1.14256,
        low: 1.13663
      },
      {
        timestamp: 1542924000,
        open: 1.1387,
        close: 1.14065,
        high: 1.14348,
        low: 1.13829
      },
      {
        timestamp: 1543010400,
        open: 1.14065,
        close: 1.13409,
        high: 1.14221,
        low: 1.13289
      },
      {
        timestamp: 1543183200,
        open: 1.13409,
        close: 1.13438,
        high: 1.13457,
        low: 1.13409
      },
      {
        timestamp: 1543269600,
        open: 1.13438,
        close: 1.1329,
        high: 1.13845,
        low: 1.13256
      },
      {
        timestamp: 1543356000,
        open: 1.1329,
        close: 1.12928,
        high: 1.13445,
        low: 1.1278
      },
      {
        timestamp: 1543442400,
        open: 1.12928,
        close: 1.13666,
        high: 1.13883,
        low: 1.12678
      },
      {
        timestamp: 1543528800,
        open: 1.13666,
        close: 1.13953,
        high: 1.14024,
        low: 1.13495
      },
      {
        timestamp: 1543615200,
        open: 1.13953,
        close: 1.13223,
        high: 1.14011,
        low: 1.13062
      },
      {
        timestamp: 1543788000,
        open: 1.13223,
        close: 1.13557,
        high: 1.13687,
        low: 1.13223
      },
      {
        timestamp: 1543874400,
        open: 1.13557,
        close: 1.13552,
        high: 1.13805,
        low: 1.13201
      },
      {
        timestamp: 1543960800,
        open: 1.13552,
        close: 1.13448,
        high: 1.14195,
        low: 1.13192
      },
      {
        timestamp: 1544047200,
        open: 1.13448,
        close: 1.13453,
        high: 1.13621,
        low: 1.13117
      },
      {
        timestamp: 1544133600,
        open: 1.13453,
        close: 1.13796,
        high: 1.14133,
        low: 1.13218
      },
      {
        timestamp: 1544220000,
        open: 1.13796,
        close: 1.13969,
        high: 1.14245,
        low: 1.13612
      },
      {
        timestamp: 1544392800,
        open: 1.13969,
        close: 1.13945,
        high: 1.14024,
        low: 1.13829
      },
      {
        timestamp: 1544479200,
        open: 1.13945,
        close: 1.13576,
        high: 1.14435,
        low: 1.13512
      },
      {
        timestamp: 1544565600,
        open: 1.13576,
        close: 1.1318,
        high: 1.14011,
        low: 1.13069
      },
      {
        timestamp: 1544652000,
        open: 1.1318,
        close: 1.1369,
        high: 1.13882,
        low: 1.13158
      },
      {
        timestamp: 1544738400,
        open: 1.1369,
        close: 1.13597,
        high: 1.13943,
        low: 1.13318
      },
      {
        timestamp: 1544824800,
        open: 1.13597,
        close: 1.13072,
        high: 1.13665,
        low: 1.12705
      },
      {
        timestamp: 1544997600,
        open: 1.13072,
        close: 1.13057,
        high: 1.13078,
        low: 1.13023
      },
      {
        timestamp: 1545084000,
        open: 1.13057,
        close: 1.13481,
        high: 1.13585,
        low: 1.13022
      },
      {
        timestamp: 1545170400,
        open: 1.13481,
        close: 1.13622,
        high: 1.1403,
        low: 1.13375
      },
      {
        timestamp: 1545256800,
        open: 1.13622,
        close: 1.1378,
        high: 1.14401,
        low: 1.13621
      },
      {
        timestamp: 1545343200,
        open: 1.1378,
        close: 1.14474,
        high: 1.14867,
        low: 1.13712
      },
      {
        timestamp: 1545429600,
        open: 1.14474,
        close: 1.13728,
        high: 1.14748,
        low: 1.13567
      },
      {
        timestamp: 1545602400,
        open: 1.13728,
        close: 1.13741,
        high: 1.13749,
        low: 1.13601
      },
      {
        timestamp: 1545688800,
        open: 1.13741,
        close: 1.14042,
        high: 1.14393,
        low: 1.13629
      },
      {
        timestamp: 1545861600,
        open: 1.14042,
        close: 1.13547,
        high: 1.1415,
        low: 1.13438
      },
      {
        timestamp: 1545948000,
        open: 1.13547,
        close: 1.14336,
        high: 1.1455,
        low: 1.13539
      },
      {
        timestamp: 1546034400,
        open: 1.14336,
        close: 1.14454,
        high: 1.14735,
        low: 1.14289
      },
      {
        timestamp: 1546207200,
        open: 1.14454,
        close: 1.14404,
        high: 1.14502,
        low: 1.14383
      },
      {
        timestamp: 1546293600,
        open: 1.14404,
        close: 1.14459,
        high: 1.14669,
        low: 1.14222
      },
      {
        timestamp: 1546466400,
        open: 1.14459,
        close: 1.13448,
        high: 1.14975,
        low: 1.13258
      },
      {
        timestamp: 1546552800,
        open: 1.13448,
        close: 1.13946,
        high: 1.14119,
        low: 1.13103
      },
      {
        timestamp: 1546639200,
        open: 1.13946,
        close: 1.13998,
        high: 1.14198,
        low: 1.13464
      },
      {
        timestamp: 1546812000,
        open: 1.13998,
        close: 1.14008,
        high: 1.14055,
        low: 1.13987
      },
      {
        timestamp: 1546898400,
        open: 1.14008,
        close: 1.14757,
        high: 1.14833,
        low: 1.13981
      },
      {
        timestamp: 1546984800,
        open: 1.14757,
        close: 1.14426,
        high: 1.14854,
        low: 1.14227
      },
      {
        timestamp: 1547071200,
        open: 1.14426,
        close: 1.15435,
        high: 1.15589,
        low: 1.14383
      },
      {
        timestamp: 1547157600,
        open: 1.15435,
        close: 1.15007,
        high: 1.15706,
        low: 1.1485
      },
      {
        timestamp: 1547244000,
        open: 1.15007,
        close: 1.14722,
        high: 1.15414,
        low: 1.14588
      },
      {
        timestamp: 1547416800,
        open: 1.14722,
        close: 1.14631,
        high: 1.14631,
        low: 1.14425
      },
      {
        timestamp: 1547503200,
        open: 1.14631,
        close: 1.14697,
        high: 1.14828,
        low: 1.14513
      },
      {
        timestamp: 1547589600,
        open: 1.14697,
        close: 1.14143,
        high: 1.14905,
        low: 1.13826
      },
      {
        timestamp: 1547676000,
        open: 1.14143,
        close: 1.13935,
        high: 1.14256,
        low: 1.13785
      },
      {
        timestamp: 1547762400,
        open: 1.13935,
        close: 1.13895,
        high: 1.14068,
        low: 1.13708
      },
      {
        timestamp: 1547848800,
        open: 1.13895,
        close: 1.13662,
        high: 1.14119,
        low: 1.1354
      },
      {
        timestamp: 1548021600,
        open: 1.13662,
        close: 1.13636,
        high: 1.13703,
        low: 1.13631
      },
      {
        timestamp: 1548108000,
        open: 1.13636,
        close: 1.13679,
        high: 1.13922,
        low: 1.13573
      },
      {
        timestamp: 1548194400,
        open: 1.13679,
        close: 1.13608,
        high: 1.13746,
        low: 1.13367
      },
      {
        timestamp: 1548280800,
        open: 1.13608,
        close: 1.13821,
        high: 1.13952,
        low: 1.13514
      },
      {
        timestamp: 1548367200,
        open: 1.13821,
        close: 1.13084,
        high: 1.13923,
        low: 1.12901
      },
      {
        timestamp: 1548453600,
        open: 1.13084,
        close: 1.14094,
        high: 1.14185,
        low: 1.13016
      },
      {
        timestamp: 1548626400,
        open: 1.14094,
        close: 1.14051,
        high: 1.14112,
        low: 1.14007
      },
      {
        timestamp: 1548712800,
        open: 1.14051,
        close: 1.1428,
        high: 1.14444,
        low: 1.13908
      },
      {
        timestamp: 1548799200,
        open: 1.1428,
        close: 1.14352,
        high: 1.1451,
        low: 1.14118
      },
      {
        timestamp: 1548885600,
        open: 1.14352,
        close: 1.1481,
        high: 1.15026,
        low: 1.14068
      },
      {
        timestamp: 1548972000,
        open: 1.1481,
        close: 1.14485,
        high: 1.15151,
        low: 1.14364
      },
      {
        timestamp: 1549058400,
        open: 1.14485,
        close: 1.14575,
        high: 1.14892,
        low: 1.14351
      },
      {
        timestamp: 1549231200,
        open: 1.14575,
        close: 1.14546,
        high: 1.14605,
        low: 1.14485
      },
      {
        timestamp: 1549317600,
        open: 1.14546,
        close: 1.14378,
        high: 1.14611,
        low: 1.1425
      },
      {
        timestamp: 1549404000,
        open: 1.14378,
        close: 1.14062,
        high: 1.14426,
        low: 1.14017
      },
      {
        timestamp: 1549490400,
        open: 1.14062,
        close: 1.13631,
        high: 1.14104,
        low: 1.13617
      },
      {
        timestamp: 1549576800,
        open: 1.13631,
        close: 1.1343,
        high: 1.13704,
        low: 1.13248
      },
      {
        timestamp: 1549663200,
        open: 1.1343,
        close: 1.13241,
        high: 1.13516,
        low: 1.13218
      },
      {
        timestamp: 1549836000,
        open: 1.13241,
        close: 1.13195,
        high: 1.13268,
        low: 1.13177
      },
      {
        timestamp: 1549922400,
        open: 1.13195,
        close: 1.12772,
        high: 1.13308,
        low: 1.12681
      },
      {
        timestamp: 1550008800,
        open: 1.12772,
        close: 1.13277,
        high: 1.13404,
        low: 1.12583
      },
      {
        timestamp: 1550095200,
        open: 1.13277,
        close: 1.12617,
        high: 1.13423,
        low: 1.12609
      },
      {
        timestamp: 1550181600,
        open: 1.12617,
        close: 1.12962,
        high: 1.13105,
        low: 1.12498
      },
      {
        timestamp: 1550268000,
        open: 1.12962,
        close: 1.12942,
        high: 1.1307,
        low: 1.12348
      },
      {
        timestamp: 1550440800,
        open: 1.12942,
        close: 1.12952,
        high: 1.13025,
        low: 1.12935
      },
      {
        timestamp: 1550527200,
        open: 1.12952,
        close: 1.13134,
        high: 1.13347,
        low: 1.12901
      },
      {
        timestamp: 1550613600,
        open: 1.13134,
        close: 1.1341,
        high: 1.13584,
        low: 1.12763
      },
      {
        timestamp: 1550700000,
        open: 1.1341,
        close: 1.13399,
        high: 1.13719,
        low: 1.13254
      },
      {
        timestamp: 1550786400,
        open: 1.13399,
        close: 1.13381,
        high: 1.13674,
        low: 1.13203
      },
      {
        timestamp: 1550872800,
        open: 1.13381,
        close: 1.13365,
        high: 1.13564,
        low: 1.13168
      },
      {
        timestamp: 1551045600,
        open: 1.13365,
        close: 1.13396,
        high: 1.13492,
        low: 1.13365
      },
      {
        timestamp: 1551132000,
        open: 1.13396,
        close: 1.13589,
        high: 1.13683,
        low: 1.133
      },
      {
        timestamp: 1551218400,
        open: 1.13589,
        close: 1.13897,
        high: 1.14035,
        low: 1.13459
      },
      {
        timestamp: 1551304800,
        open: 1.13897,
        close: 1.13706,
        high: 1.14044,
        low: 1.13631
      },
      {
        timestamp: 1551391200,
        open: 1.13706,
        close: 1.13717,
        high: 1.14203,
        low: 1.136
      },
      {
        timestamp: 1551477600,
        open: 1.13717,
        close: 1.13678,
        high: 1.14095,
        low: 1.1354
      },
      {
        timestamp: 1551650400,
        open: 1.13678,
        close: 1.13837,
        high: 1.13914,
        low: 1.13678
      },
      {
        timestamp: 1551736800,
        open: 1.13837,
        close: 1.13411,
        high: 1.13839,
        low: 1.13097
      },
      {
        timestamp: 1551823200,
        open: 1.13411,
        close: 1.13091,
        high: 1.13427,
        low: 1.12903
      },
      {
        timestamp: 1551909600,
        open: 1.13091,
        close: 1.13077,
        high: 1.13254,
        low: 1.12863
      },
      {
        timestamp: 1551996000,
        open: 1.13077,
        close: 1.11948,
        high: 1.13211,
        low: 1.1177
      },
      {
        timestamp: 1552082400,
        open: 1.11948,
        close: 1.12379,
        high: 1.12469,
        low: 1.11859
      },
      {
        timestamp: 1552251600,
        open: 1.12379,
        close: 1.12372,
        high: 1.12466,
        low: 1.12347
      },
      {
        timestamp: 1552338000,
        open: 1.12372,
        close: 1.12463,
        high: 1.12592,
        low: 1.12224
      },
      {
        timestamp: 1552424400,
        open: 1.12463,
        close: 1.12897,
        high: 1.13056,
        low: 1.12457
      },
      {
        timestamp: 1552510800,
        open: 1.12897,
        close: 1.13296,
        high: 1.13394,
        low: 1.12782
      },
      {
        timestamp: 1552597200,
        open: 1.13296,
        close: 1.13051,
        high: 1.13384,
        low: 1.12948
      },
      {
        timestamp: 1552683600,
        open: 1.13051,
        close: 1.13263,
        high: 1.13451,
        low: 1.13007
      },
      {
        timestamp: 1552856400,
        open: 1.13263,
        close: 1.13257,
        high: 1.13303,
        low: 1.13217
      },
      {
        timestamp: 1552942800,
        open: 1.13257,
        close: 1.13391,
        high: 1.13597,
        low: 1.13198
      },
      {
        timestamp: 1553029200,
        open: 1.13391,
        close: 1.1353,
        high: 1.13624,
        low: 1.13345
      },
      {
        timestamp: 1553115600,
        open: 1.1353,
        close: 1.14142,
        high: 1.1449,
        low: 1.13362
      },
      {
        timestamp: 1553202000,
        open: 1.14142,
        close: 1.13753,
        high: 1.14382,
        low: 1.13432
      },
      {
        timestamp: 1553288400,
        open: 1.13753,
        close: 1.13026,
        high: 1.13914,
        low: 1.12739
      },
      {
        timestamp: 1553461200,
        open: 1.13026,
        close: 1.12952,
        high: 1.12982,
        low: 1.12861
      },
      {
        timestamp: 1553547600,
        open: 1.12952,
        close: 1.13134,
        high: 1.13322,
        low: 1.12896
      },
      {
        timestamp: 1553634000,
        open: 1.13134,
        close: 1.12668,
        high: 1.13267,
        low: 1.12641
      },
      {
        timestamp: 1553720400,
        open: 1.12668,
        close: 1.12449,
        high: 1.12864,
        low: 1.12429
      },
      {
        timestamp: 1553806800,
        open: 1.12449,
        close: 1.12235,
        high: 1.1262,
        low: 1.12143
      },
      {
        timestamp: 1553893200,
        open: 1.12235,
        close: 1.12191,
        high: 1.12474,
        low: 1.12104
      },
      {
        timestamp: 1554066000,
        open: 1.12191,
        close: 1.12346,
        high: 1.12421,
        low: 1.12191
      },
      {
        timestamp: 1554152400,
        open: 1.12346,
        close: 1.12141,
        high: 1.12507,
        low: 1.12047
      },
      {
        timestamp: 1554238800,
        open: 1.12141,
        close: 1.12053,
        high: 1.12169,
        low: 1.11843
      },
      {
        timestamp: 1554325200,
        open: 1.12053,
        close: 1.12333,
        high: 1.12554,
        low: 1.12015
      },
      {
        timestamp: 1554411600,
        open: 1.12333,
        close: 1.12219,
        high: 1.12484,
        low: 1.12067
      },
      {
        timestamp: 1554498000,
        open: 1.12219,
        close: 1.12167,
        high: 1.12491,
        low: 1.12107
      },
      {
        timestamp: 1554670800,
        open: 1.12167,
        close: 1.12245,
        high: 1.12245,
        low: 1.12245
      },
      {
        timestamp: 1554757200,
        open: 1.12245,
        close: 1.1265,
        high: 1.12753,
        low: 1.1214
      },
      {
        timestamp: 1554843600,
        open: 1.1265,
        close: 1.1264,
        high: 1.1285,
        low: 1.12559
      },
      {
        timestamp: 1554930000,
        open: 1.1264,
        close: 1.12747,
        high: 1.12891,
        low: 1.12303
      },
      {
        timestamp: 1555016400,
        open: 1.12747,
        close: 1.12542,
        high: 1.12882,
        low: 1.12505
      },
      {
        timestamp: 1555102800,
        open: 1.12542,
        close: 1.13003,
        high: 1.13246,
        low: 1.12541
      },
      {
        timestamp: 1555275600,
        open: 1.13003,
        close: 1.13077,
        high: 1.13084,
        low: 1.12975
      },
      {
        timestamp: 1555362000,
        open: 1.13077,
        close: 1.1305,
        high: 1.13218,
        low: 1.12983
      },
      {
        timestamp: 1555448400,
        open: 1.1305,
        close: 1.12819,
        high: 1.13147,
        low: 1.12803
      },
      {
        timestamp: 1555534800,
        open: 1.12819,
        close: 1.12981,
        high: 1.13245,
        low: 1.12797
      },
      {
        timestamp: 1555621200,
        open: 1.12981,
        close: 1.12342,
        high: 1.13048,
        low: 1.12269
      },
      {
        timestamp: 1555707600,
        open: 1.12342,
        close: 1.1246,
        high: 1.12494,
        low: 1.12299
      },
      {
        timestamp: 1555880400,
        open: 1.1246,
        close: 1.12515,
        high: 1.12515,
        low: 1.1246
      },
      {
        timestamp: 1555966800,
        open: 1.12515,
        close: 1.12582,
        high: 1.12628,
        low: 1.12366
      },
      {
        timestamp: 1556053200,
        open: 1.12582,
        close: 1.12282,
        high: 1.1262,
        low: 1.1193
      },
      {
        timestamp: 1556139600,
        open: 1.12282,
        close: 1.1159,
        high: 1.12296,
        low: 1.11413
      },
      {
        timestamp: 1556226000,
        open: 1.1159,
        close: 1.1134,
        high: 1.11632,
        low: 1.11188
      },
      {
        timestamp: 1556312400,
        open: 1.1134,
        close: 1.11453,
        high: 1.11746,
        low: 1.11112
      },
      {
        timestamp: 1556485200,
        open: 1.11453,
        close: 1.11486,
        high: 1.11591,
        low: 1.11453
      },
      {
        timestamp: 1556571600,
        open: 1.11486,
        close: 1.11866,
        high: 1.11881,
        low: 1.11458
      },
      {
        timestamp: 1556658000,
        open: 1.11866,
        close: 1.12162,
        high: 1.12298,
        low: 1.11765
      },
      {
        timestamp: 1556744400,
        open: 1.12162,
        close: 1.11992,
        high: 1.12655,
        low: 1.11879
      },
      {
        timestamp: 1556830800,
        open: 1.11992,
        close: 1.11738,
        high: 1.12198,
        low: 1.11715
      },
      {
        timestamp: 1556917200,
        open: 1.11738,
        close: 1.12037,
        high: 1.12054,
        low: 1.11359
      },
      {
        timestamp: 1557090000,
        open: 1.12037,
        close: 1.11807,
        high: 1.11884,
        low: 1.11585
      },
      {
        timestamp: 1557176400,
        open: 1.11807,
        close: 1.12012,
        high: 1.12101,
        low: 1.11739
      },
      {
        timestamp: 1557262800,
        open: 1.12012,
        close: 1.11915,
        high: 1.12185,
        low: 1.11673
      },
      {
        timestamp: 1557349200,
        open: 1.11915,
        close: 1.11927,
        high: 1.12145,
        low: 1.11835
      },
      {
        timestamp: 1557435600,
        open: 1.11927,
        close: 1.12171,
        high: 1.12517,
        low: 1.11742
      },
      {
        timestamp: 1557522000,
        open: 1.12171,
        close: 1.12363,
        high: 1.12543,
        low: 1.12154
      },
      {
        timestamp: 1557694800,
        open: 1.12363,
        close: 1.12352,
        high: 1.12402,
        low: 1.12328
      },
      {
        timestamp: 1557781200,
        open: 1.12352,
        close: 1.1223,
        high: 1.12642,
        low: 1.12228
      },
      {
        timestamp: 1557867600,
        open: 1.1223,
        close: 1.12049,
        high: 1.12447,
        low: 1.12019
      },
      {
        timestamp: 1557954000,
        open: 1.12049,
        close: 1.12021,
        high: 1.12255,
        low: 1.11787
      },
      {
        timestamp: 1558040400,
        open: 1.12021,
        close: 1.11758,
        high: 1.12246,
        low: 1.11668
      },
      {
        timestamp: 1558126800,
        open: 1.11758,
        close: 1.11584,
        high: 1.11851,
        low: 1.11558
      },
      {
        timestamp: 1558299600,
        open: 1.11584,
        close: 1.11618,
        high: 1.11637,
        low: 1.11536
      },
      {
        timestamp: 1558386000,
        open: 1.11618,
        close: 1.11671,
        high: 1.11755,
        low: 1.11514
      },
      {
        timestamp: 1558472400,
        open: 1.11671,
        close: 1.11621,
        high: 1.11884,
        low: 1.11427
      },
      {
        timestamp: 1558558800,
        open: 1.11621,
        close: 1.11516,
        high: 1.1181,
        low: 1.11493
      },
      {
        timestamp: 1558645200,
        open: 1.11516,
        close: 1.11825,
        high: 1.11882,
        low: 1.11076
      },
      {
        timestamp: 1558731600,
        open: 1.11825,
        close: 1.12062,
        high: 1.12128,
        low: 1.11771
      },
      {
        timestamp: 1558904400,
        open: 1.12062,
        close: 1.12108,
        high: 1.12134,
        low: 1.11905
      },
      {
        timestamp: 1558990800,
        open: 1.12108,
        close: 1.11972,
        high: 1.12158,
        low: 1.11876
      },
      {
        timestamp: 1559077200,
        open: 1.11972,
        close: 1.11613,
        high: 1.1199,
        low: 1.11599
      },
      {
        timestamp: 1559163600,
        open: 1.11613,
        close: 1.11321,
        high: 1.11731,
        low: 1.11249
      },
      {
        timestamp: 1559250000,
        open: 1.11321,
        close: 1.11299,
        high: 1.11439,
        low: 1.11166
      },
      {
        timestamp: 1559336400,
        open: 1.11299,
        close: 1.11704,
        high: 1.11804,
        low: 1.11258
      },
      {
        timestamp: 1559509200,
        open: 1.11704,
        close: 1.11712,
        high: 1.11712,
        low: 1.11582
      },
      {
        timestamp: 1559595600,
        open: 1.11712,
        close: 1.12417,
        high: 1.12626,
        low: 1.11608
      },
      {
        timestamp: 1559682000,
        open: 1.12417,
        close: 1.12524,
        high: 1.1278,
        low: 1.1227
      },
      {
        timestamp: 1559768400,
        open: 1.12524,
        close: 1.12218,
        high: 1.13075,
        low: 1.12203
      },
      {
        timestamp: 1559854800,
        open: 1.12218,
        close: 1.12763,
        high: 1.13096,
        low: 1.12016
      },
      {
        timestamp: 1559941200,
        open: 1.12763,
        close: 1.13343,
        high: 1.13483,
        low: 1.12519
      },
      {
        timestamp: 1560114000,
        open: 1.13343,
        close: 1.13252,
        high: 1.13311,
        low: 1.13105
      },
      {
        timestamp: 1560200400,
        open: 1.13252,
        close: 1.13122,
        high: 1.13321,
        low: 1.12906
      },
      {
        timestamp: 1560286800,
        open: 1.13122,
        close: 1.1327,
        high: 1.13381,
        low: 1.13019
      },
      {
        timestamp: 1560373200,
        open: 1.1327,
        close: 1.12879,
        high: 1.13441,
        low: 1.1283
      },
      {
        timestamp: 1560459600,
        open: 1.12879,
        close: 1.1277,
        high: 1.13044,
        low: 1.12689
      },
      {
        timestamp: 1560546000,
        open: 1.1277,
        close: 1.12129,
        high: 1.12898,
        low: 1.12031
      },
      {
        timestamp: 1560718800,
        open: 1.12129,
        close: 1.12125,
        high: 1.12175,
        low: 1.12054
      },
      {
        timestamp: 1560805200,
        open: 1.12125,
        close: 1.12189,
        high: 1.12479,
        low: 1.12043
      },
      {
        timestamp: 1560891600,
        open: 1.12189,
        close: 1.11944,
        high: 1.12434,
        low: 1.1182
      },
      {
        timestamp: 1560978000,
        open: 1.11944,
        close: 1.12273,
        high: 1.12551,
        low: 1.11878
      },
      {
        timestamp: 1561064400,
        open: 1.12273,
        close: 1.12937,
        high: 1.13183,
        low: 1.12268
      },
      {
        timestamp: 1561150800,
        open: 1.12937,
        close: 1.13706,
        high: 1.13785,
        low: 1.12837
      },
      {
        timestamp: 1561323600,
        open: 1.13706,
        close: 1.13736,
        high: 1.1379,
        low: 1.13686
      },
      {
        timestamp: 1561410000,
        open: 1.13736,
        close: 1.1399,
        high: 1.14047,
        low: 1.13689
      },
      {
        timestamp: 1561496400,
        open: 1.1399,
        close: 1.13677,
        high: 1.14127,
        low: 1.13448
      },
      {
        timestamp: 1561582800,
        open: 1.13677,
        close: 1.13707,
        high: 1.1392,
        low: 1.13481
      },
      {
        timestamp: 1561669200,
        open: 1.13707,
        close: 1.13702,
        high: 1.13822,
        low: 1.13484
      },
      {
        timestamp: 1561755600,
        open: 1.13702,
        close: 1.13727,
        high: 1.13942,
        low: 1.13512
      },
      {
        timestamp: 1561928400,
        open: 1.13727,
        close: 1.13617,
        high: 1.13709,
        low: 1.13599
      },
      {
        timestamp: 1562014800,
        open: 1.13617,
        close: 1.12862,
        high: 1.13718,
        low: 1.12816
      },
      {
        timestamp: 1562101200,
        open: 1.12862,
        close: 1.1286,
        high: 1.13228,
        low: 1.12759
      },
      {
        timestamp: 1562187600,
        open: 1.1286,
        close: 1.1279,
        high: 1.13123,
        low: 1.12691
      },
      {
        timestamp: 1562274000,
        open: 1.1279,
        close: 1.12856,
        high: 1.12958,
        low: 1.12737
      },
      {
        timestamp: 1562360400,
        open: 1.12856,
        close: 1.12279,
        high: 1.12885,
        low: 1.1208
      },
      {
        timestamp: 1562533200,
        open: 1.12279,
        close: 1.12285,
        high: 1.12295,
        low: 1.12195
      },
      {
        timestamp: 1562619600,
        open: 1.12285,
        close: 1.1215,
        high: 1.12351,
        low: 1.12076
      },
      {
        timestamp: 1562706000,
        open: 1.1215,
        close: 1.12085,
        high: 1.12194,
        low: 1.11939
      },
      {
        timestamp: 1562792400,
        open: 1.12085,
        close: 1.12517,
        high: 1.12649,
        low: 1.12024
      },
      {
        timestamp: 1562878800,
        open: 1.12517,
        close: 1.12546,
        high: 1.12864,
        low: 1.12458
      },
      {
        timestamp: 1562965200,
        open: 1.12546,
        close: 1.12726,
        high: 1.12755,
        low: 1.12388
      },
      {
        timestamp: 1563138000,
        open: 1.12726,
        close: 1.12754,
        high: 1.12754,
        low: 1.1269
      },
      {
        timestamp: 1563224400,
        open: 1.12754,
        close: 1.12587,
        high: 1.12845,
        low: 1.12538
      },
      {
        timestamp: 1563310800,
        open: 1.12587,
        close: 1.12114,
        high: 1.12641,
        low: 1.12025
      },
      {
        timestamp: 1563397200,
        open: 1.12114,
        close: 1.1225,
        high: 1.12342,
        low: 1.12003
      },
      {
        timestamp: 1563483600,
        open: 1.1225,
        close: 1.12779,
        high: 1.12811,
        low: 1.12058
      },
      {
        timestamp: 1563570000,
        open: 1.12779,
        close: 1.12228,
        high: 1.12826,
        low: 1.12041
      },
      {
        timestamp: 1563742800,
        open: 1.12228,
        close: 1.12155,
        high: 1.12197,
        low: 1.12155
      },
      {
        timestamp: 1563829200,
        open: 1.12155,
        close: 1.12095,
        high: 1.12261,
        low: 1.12068
      },
      {
        timestamp: 1563915600,
        open: 1.12095,
        close: 1.11526,
        high: 1.12107,
        low: 1.11465
      },
      {
        timestamp: 1564002000,
        open: 1.11526,
        close: 1.11409,
        high: 1.11568,
        low: 1.11278
      },
      {
        timestamp: 1564088400,
        open: 1.11409,
        close: 1.1148,
        high: 1.11883,
        low: 1.11019
      },
      {
        timestamp: 1564174800,
        open: 1.1148,
        close: 1.11299,
        high: 1.11517,
        low: 1.11126
      },
      {
        timestamp: 1564347600,
        open: 1.11299,
        close: 1.11285,
        high: 1.11335,
        low: 1.11262
      },
      {
        timestamp: 1564434000,
        open: 1.11285,
        close: 1.11454,
        high: 1.11515,
        low: 1.11136
      },
      {
        timestamp: 1564520400,
        open: 1.11454,
        close: 1.11557,
        high: 1.11618,
        low: 1.11326
      },
      {
        timestamp: 1564606800,
        open: 1.11557,
        close: 1.10759,
        high: 1.11627,
        low: 1.10608
      },
      {
        timestamp: 1564693200,
        open: 1.10759,
        close: 1.10859,
        high: 1.10967,
        low: 1.10275
      },
      {
        timestamp: 1564779600,
        open: 1.10859,
        close: 1.11122,
        high: 1.11171,
        low: 1.10704
      },
      {
        timestamp: 1564952400,
        open: 1.11122,
        close: 1.11103,
        high: 1.11147,
        low: 1.1105
      },
      {
        timestamp: 1565038800,
        open: 1.11103,
        close: 1.12036,
        high: 1.12138,
        low: 1.11052
      },
      {
        timestamp: 1565125200,
        open: 1.12036,
        close: 1.12012,
        high: 1.12502,
        low: 1.11681
      },
      {
        timestamp: 1565211600,
        open: 1.12012,
        close: 1.12007,
        high: 1.12425,
        low: 1.11796
      },
      {
        timestamp: 1565298000,
        open: 1.12007,
        close: 1.11811,
        high: 1.12336,
        low: 1.11777
      },
      {
        timestamp: 1565384400,
        open: 1.11811,
        close: 1.12014,
        high: 1.12235,
        low: 1.11807
      },
      {
        timestamp: 1565557200,
        open: 1.12014,
        close: 1.12019,
        high: 1.12052,
        low: 1.1195
      },
      {
        timestamp: 1565643600,
        open: 1.12019,
        close: 1.12147,
        high: 1.12314,
        low: 1.11626
      },
      {
        timestamp: 1565730000,
        open: 1.12147,
        close: 1.11719,
        high: 1.12291,
        low: 1.11707
      },
      {
        timestamp: 1565816400,
        open: 1.11719,
        close: 1.11393,
        high: 1.11915,
        low: 1.11311
      },
      {
        timestamp: 1565902800,
        open: 1.11393,
        close: 1.11077,
        high: 1.1159,
        low: 1.10918
      },
      {
        timestamp: 1565989200,
        open: 1.11077,
        close: 1.10931,
        high: 1.11145,
        low: 1.10669
      },
      {
        timestamp: 1566162000,
        open: 1.10931,
        close: 1.10959,
        high: 1.10959,
        low: 1.10927
      },
      {
        timestamp: 1566248400,
        open: 1.10959,
        close: 1.10792,
        high: 1.11143,
        low: 1.10768
      },
      {
        timestamp: 1566334800,
        open: 1.10792,
        close: 1.11015,
        high: 1.11073,
        low: 1.10663
      },
      {
        timestamp: 1566421200,
        open: 1.11015,
        close: 1.10851,
        high: 1.11078,
        low: 1.10811
      },
      {
        timestamp: 1566507600,
        open: 1.10851,
        close: 1.10805,
        high: 1.11139,
        low: 1.10642
      },
      {
        timestamp: 1566594000,
        open: 1.10805,
        close: 1.11433,
        high: 1.11538,
        low: 1.10523
      },
      {
        timestamp: 1566766800,
        open: 1.11433,
        close: 1.11527,
        high: 1.11653,
        low: 1.11433
      },
      {
        timestamp: 1566853200,
        open: 1.11527,
        close: 1.11042,
        high: 1.1165,
        low: 1.10949
      },
      {
        timestamp: 1566939600,
        open: 1.11042,
        close: 1.10912,
        high: 1.11167,
        low: 1.10863
      },
      {
        timestamp: 1567026000,
        open: 1.10912,
        close: 1.10787,
        high: 1.10987,
        low: 1.10738
      },
      {
        timestamp: 1567112400,
        open: 1.10787,
        close: 1.10574,
        high: 1.10943,
        low: 1.10427
      },
      {
        timestamp: 1567198800,
        open: 1.10574,
        close: 1.09931,
        high: 1.10611,
        low: 1.09636
      },
      {
        timestamp: 1567371600,
        open: 1.09931,
        close: 1.09935,
        high: 1.09997,
        low: 1.09884
      },
      {
        timestamp: 1567458000,
        open: 1.09935,
        close: 1.09733,
        high: 1.09975,
        low: 1.09586
      },
      {
        timestamp: 1567544400,
        open: 1.09733,
        close: 1.09752,
        high: 1.09796,
        low: 1.09266
      },
      {
        timestamp: 1567630800,
        open: 1.09752,
        close: 1.10367,
        high: 1.10391,
        low: 1.09692
      },
      {
        timestamp: 1567717200,
        open: 1.10367,
        close: 1.10356,
        high: 1.10853,
        low: 1.10173
      },
      {
        timestamp: 1567803600,
        open: 1.10356,
        close: 1.10288,
        high: 1.1058,
        low: 1.10208
      },
      {
        timestamp: 1567976400,
        open: 1.10288,
        close: 1.10293,
        high: 1.10326,
        low: 1.10264
      },
      {
        timestamp: 1568062800,
        open: 1.10293,
        close: 1.10488,
        high: 1.10687,
        low: 1.10161
      },
      {
        timestamp: 1568149200,
        open: 1.10488,
        close: 1.10443,
        high: 1.106,
        low: 1.10312
      },
      {
        timestamp: 1568235600,
        open: 1.10443,
        close: 1.10112,
        high: 1.10562,
        low: 1.09859
      },
      {
        timestamp: 1568322000,
        open: 1.10112,
        close: 1.10667,
        high: 1.10879,
        low: 1.09274
      },
      {
        timestamp: 1568408400,
        open: 1.10667,
        close: 1.10779,
        high: 1.11102,
        low: 1.10561
      },
      {
        timestamp: 1568581200,
        open: 1.10779,
        close: 1.10756,
        high: 1.1091,
        low: 1.1075
      },
      {
        timestamp: 1568667600,
        open: 1.10756,
        close: 1.10048,
        high: 1.10871,
        low: 1.09939
      },
      {
        timestamp: 1568754000,
        open: 1.10048,
        close: 1.10737,
        high: 1.10751,
        low: 1.09909
      },
      {
        timestamp: 1568840400,
        open: 1.10737,
        close: 1.10305,
        high: 1.10764,
        low: 1.10143
      },
      {
        timestamp: 1568926800,
        open: 1.10305,
        close: 1.10418,
        high: 1.10742,
        low: 1.10235
      },
      {
        timestamp: 1569013200,
        open: 1.10418,
        close: 1.1023,
        high: 1.10682,
        low: 1.09969
      },
      {
        timestamp: 1569186000,
        open: 1.1023,
        close: 1.10205,
        high: 1.10215,
        low: 1.10138
      },
      {
        timestamp: 1569272400,
        open: 1.10205,
        close: 1.0994,
        high: 1.10261,
        low: 1.09667
      },
      {
        timestamp: 1569358800,
        open: 1.0994,
        close: 1.10209,
        high: 1.10247,
        low: 1.09844
      },
      {
        timestamp: 1569445200,
        open: 1.10209,
        close: 1.0944,
        high: 1.10248,
        low: 1.0938
      },
      {
        timestamp: 1569531600,
        open: 1.0944,
        close: 1.09238,
        high: 1.09681,
        low: 1.09093
      },
      {
        timestamp: 1569618000,
        open: 1.09238,
        close: 1.09429,
        high: 1.09594,
        low: 1.09054
      },
      {
        timestamp: 1569790800,
        open: 1.09429,
        close: 1.09445,
        high: 1.09474,
        low: 1.09406
      },
      {
        timestamp: 1569877200,
        open: 1.09445,
        close: 1.09002,
        high: 1.09486,
        low: 1.08853
      },
      {
        timestamp: 1569963600,
        open: 1.09002,
        close: 1.09334,
        high: 1.09434,
        low: 1.08797
      },
      {
        timestamp: 1570050000,
        open: 1.09334,
        close: 1.09597,
        high: 1.09642,
        low: 1.09046
      },
      {
        timestamp: 1570136400,
        open: 1.09597,
        close: 1.09663,
        high: 1.1,
        low: 1.09415
      },
      {
        timestamp: 1570222800,
        open: 1.09663,
        close: 1.09815,
        high: 1.10009,
        low: 1.09578
      },
      {
        timestamp: 1570395600,
        open: 1.09815,
        close: 1.09841,
        high: 1.09861,
        low: 1.09724
      },
      {
        timestamp: 1570482000,
        open: 1.09841,
        close: 1.09713,
        high: 1.10012,
        low: 1.09625
      },
      {
        timestamp: 1570568400,
        open: 1.09713,
        close: 1.09574,
        high: 1.09967,
        low: 1.09416
      },
      {
        timestamp: 1570654800,
        open: 1.09574,
        close: 1.09723,
        high: 1.09906,
        low: 1.09545
      },
      {
        timestamp: 1570741200,
        open: 1.09723,
        close: 1.10059,
        high: 1.10346,
        low: 1.0972
      },
      {
        timestamp: 1570827600,
        open: 1.10059,
        close: 1.10416,
        high: 1.10634,
        low: 1.10014
      },
      {
        timestamp: 1571000400,
        open: 1.10416,
        close: 1.10354,
        high: 1.10457,
        low: 1.1033
      },
      {
        timestamp: 1571086800,
        open: 1.10354,
        close: 1.10271,
        high: 1.10435,
        low: 1.10132
      },
      {
        timestamp: 1571173200,
        open: 1.10271,
        close: 1.10338,
        high: 1.10468,
        low: 1.0992
      },
      {
        timestamp: 1571259600,
        open: 1.10338,
        close: 1.10724,
        high: 1.10862,
        low: 1.10231
      },
      {
        timestamp: 1571346000,
        open: 1.10724,
        close: 1.11253,
        high: 1.11405,
        low: 1.10657
      },
      {
        timestamp: 1571432400,
        open: 1.11253,
        close: 1.11727,
        high: 1.11733,
        low: 1.11153
      },
      {
        timestamp: 1571605200,
        open: 1.11727,
        close: 1.11639,
        high: 1.11686,
        low: 1.11482
      },
      {
        timestamp: 1571691600,
        open: 1.11639,
        close: 1.115,
        high: 1.11801,
        low: 1.11391
      },
      {
        timestamp: 1571778000,
        open: 1.115,
        close: 1.11257,
        high: 1.11574,
        low: 1.11182
      },
      {
        timestamp: 1571864400,
        open: 1.11257,
        close: 1.11313,
        high: 1.1141,
        low: 1.11067
      },
      {
        timestamp: 1571950800,
        open: 1.11313,
        close: 1.11051,
        high: 1.11635,
        low: 1.10937
      },
      {
        timestamp: 1572037200,
        open: 1.11051,
        close: 1.10821,
        high: 1.11235,
        low: 1.10737
      },
      {
        timestamp: 1572210000,
        open: 1.10821,
        close: 1.10825,
        high: 1.10855,
        low: 1.10818
      },
      {
        timestamp: 1572296400,
        open: 1.10825,
        close: 1.11003,
        high: 1.11072,
        low: 1.10768
      },
      {
        timestamp: 1572382800,
        open: 1.11003,
        close: 1.11128,
        high: 1.1119,
        low: 1.1074
      },
      {
        timestamp: 1572469200,
        open: 1.11128,
        close: 1.11515,
        high: 1.11522,
        low: 1.10806
      },
      {
        timestamp: 1572555600,
        open: 1.11515,
        close: 1.11543,
        high: 1.11763,
        low: 1.1132
      },
      {
        timestamp: 1572642000,
        open: 1.11543,
        close: 1.11669,
        high: 1.11724,
        low: 1.11288
      },
      {
        timestamp: 1572818400,
        open: 1.11669,
        close: 1.11717,
        high: 1.11727,
        low: 1.11669
      },
      {
        timestamp: 1572904800,
        open: 1.11717,
        close: 1.11288,
        high: 1.11761,
        low: 1.11254
      },
      {
        timestamp: 1572991200,
        open: 1.11288,
        close: 1.1075,
        high: 1.11409,
        low: 1.10641
      },
      {
        timestamp: 1573077600,
        open: 1.1075,
        close: 1.10672,
        high: 1.10933,
        low: 1.10655
      },
      {
        timestamp: 1573164000,
        open: 1.10672,
        close: 1.10505,
        high: 1.10922,
        low: 1.10364
      },
      {
        timestamp: 1573250400,
        open: 1.10505,
        close: 1.1021,
        high: 1.1056,
        low: 1.10172
      },
      {
        timestamp: 1573423200,
        open: 1.1021,
        close: 1.10261,
        high: 1.10285,
        low: 1.1021
      },
      {
        timestamp: 1573509600,
        open: 1.10261,
        close: 1.10339,
        high: 1.10437,
        low: 1.1017
      },
      {
        timestamp: 1573596000,
        open: 1.10339,
        close: 1.10098,
        high: 1.10393,
        low: 1.1003
      },
      {
        timestamp: 1573682400,
        open: 1.10098,
        close: 1.10072,
        high: 1.10209,
        low: 1.09959
      },
      {
        timestamp: 1573768800,
        open: 1.10072,
        close: 1.10237,
        high: 1.10282,
        low: 1.09897
      },
      {
        timestamp: 1573855200,
        open: 1.10237,
        close: 1.10541,
        high: 1.10575,
        low: 1.10151
      },
      {
        timestamp: 1574028000,
        open: 1.10541,
        close: 1.10538,
        high: 1.10557,
        low: 1.10495
      },
      {
        timestamp: 1574114400,
        open: 1.10538,
        close: 1.10729,
        high: 1.10906,
        low: 1.10538
      },
      {
        timestamp: 1574200800,
        open: 1.10729,
        close: 1.1079,
        high: 1.10844,
        low: 1.10631
      },
      {
        timestamp: 1574287200,
        open: 1.1079,
        close: 1.10728,
        high: 1.10846,
        low: 1.10537
      },
      {
        timestamp: 1574373600,
        open: 1.10728,
        close: 1.10595,
        high: 1.10976,
        low: 1.10528
      },
      {
        timestamp: 1574460000,
        open: 1.10595,
        close: 1.10215,
        high: 1.10882,
        low: 1.1015
      },
      {
        timestamp: 1574632800,
        open: 1.10215,
        close: 1.102,
        high: 1.10227,
        low: 1.10154
      },
      {
        timestamp: 1574719200,
        open: 1.102,
        close: 1.10151,
        high: 1.10328,
        low: 1.10041
      },
      {
        timestamp: 1574805600,
        open: 1.10151,
        close: 1.10218,
        high: 1.10262,
        low: 1.10076
      },
      {
        timestamp: 1574892000,
        open: 1.10218,
        close: 1.10001,
        high: 1.10256,
        low: 1.09928
      },
      {
        timestamp: 1574978400,
        open: 1.10001,
        close: 1.10112,
        high: 1.10184,
        low: 1.10002
      },
      {
        timestamp: 1575064800,
        open: 1.10112,
        close: 1.10211,
        high: 1.10288,
        low: 1.09817
      },
      {
        timestamp: 1575237600,
        open: 1.10211,
        close: 1.10255,
        high: 1.10267,
        low: 1.10211
      },
      {
        timestamp: 1575324000,
        open: 1.10255,
        close: 1.10796,
        high: 1.10904,
        low: 1.10035
      },
      {
        timestamp: 1575410400,
        open: 1.10796,
        close: 1.10828,
        high: 1.10941,
        low: 1.10666
      },
      {
        timestamp: 1575496800,
        open: 1.10828,
        close: 1.10783,
        high: 1.11168,
        low: 1.10673
      },
      {
        timestamp: 1575583200,
        open: 1.10783,
        close: 1.11047,
        high: 1.11086,
        low: 1.10782
      },
      {
        timestamp: 1575669600,
        open: 1.11047,
        close: 1.10632,
        high: 1.11104,
        low: 1.10404
      },
      {
        timestamp: 1575842400,
        open: 1.10632,
        close: 1.1061,
        high: 1.10622,
        low: 1.10597
      },
      {
        timestamp: 1575928800,
        open: 1.1061,
        close: 1.10646,
        high: 1.10786,
        low: 1.10539
      },
      {
        timestamp: 1576015200,
        open: 1.10646,
        close: 1.10935,
        high: 1.10984,
        low: 1.10634
      },
      {
        timestamp: 1576101600,
        open: 1.10935,
        close: 1.11303,
        high: 1.11454,
        low: 1.10709
      },
      {
        timestamp: 1576188000,
        open: 1.11303,
        close: 1.11324,
        high: 1.11549,
        low: 1.11036
      },
      {
        timestamp: 1576274400,
        open: 1.11324,
        close: 1.11226,
        high: 1.12005,
        low: 1.11057
      },
      {
        timestamp: 1576447200,
        open: 1.11226,
        close: 1.11319,
        high: 1.11356,
        low: 1.11226
      },
      {
        timestamp: 1576533600,
        open: 1.11319,
        close: 1.11448,
        high: 1.11586,
        low: 1.11233
      },
      {
        timestamp: 1576620000,
        open: 1.11448,
        close: 1.11518,
        high: 1.11753,
        low: 1.11298
      },
      {
        timestamp: 1576706400,
        open: 1.11518,
        close: 1.1114,
        high: 1.11552,
        low: 1.1111
      },
      {
        timestamp: 1576792800,
        open: 1.1114,
        close: 1.11231,
        high: 1.11448,
        low: 1.11078
      },
      {
        timestamp: 1576879200,
        open: 1.11231,
        close: 1.10811,
        high: 1.1127,
        low: 1.10668
      },
      {
        timestamp: 1577052000,
        open: 1.10811,
        close: 1.10846,
        high: 1.10861,
        low: 1.10811
      },
      {
        timestamp: 1577138400,
        open: 1.10846,
        close: 1.10898,
        high: 1.10965,
        low: 1.10705
      },
      {
        timestamp: 1577224800,
        open: 1.10898,
        close: 1.10899,
        high: 1.10945,
        low: 1.10698
      },
      {
        timestamp: 1577397600,
        open: 1.10899,
        close: 1.11009,
        high: 1.11096,
        low: 1.10829
      },
      {
        timestamp: 1577484000,
        open: 1.11009,
        close: 1.11787,
        high: 1.11885,
        low: 1.10954
      },
      {
        timestamp: 1577656800,
        open: 1.11787,
        close: 1.11819,
        high: 1.11835,
        low: 1.1181
      },
      {
        timestamp: 1577743200,
        open: 1.11819,
        close: 1.11994,
        high: 1.12214,
        low: 1.11733
      },
      {
        timestamp: 1577829600,
        open: 1.11994,
        close: 1.12234,
        high: 1.12399,
        low: 1.11992
      },
      {
        timestamp: 1578002400,
        open: 1.12234,
        close: 1.11729,
        high: 1.12147,
        low: 1.11641
      },
      {
        timestamp: 1578088800,
        open: 1.11729,
        close: 1.11615,
        high: 1.11805,
        low: 1.11255
      },
      {
        timestamp: 1578261600,
        open: 1.11615,
        close: 1.11719,
        high: 1.11719,
        low: 1.11615
      },
      {
        timestamp: 1578348000,
        open: 1.11719,
        close: 1.1198,
        high: 1.12061,
        low: 1.11578
      },
      {
        timestamp: 1578434400,
        open: 1.1198,
        close: 1.11551,
        high: 1.11982,
        low: 1.11342
      },
      {
        timestamp: 1578520800,
        open: 1.11551,
        close: 1.11062,
        high: 1.11689,
        low: 1.11024
      },
      {
        timestamp: 1578607200,
        open: 1.11062,
        close: 1.11069,
        high: 1.11211,
        low: 1.10928
      },
      {
        timestamp: 1578693600,
        open: 1.11069,
        close: 1.11235,
        high: 1.11297,
        low: 1.10856
      },
      {
        timestamp: 1578866400,
        open: 1.11235,
        close: 1.11193,
        high: 1.11232,
        low: 1.11192
      },
      {
        timestamp: 1578952800,
        open: 1.11193,
        close: 1.11346,
        high: 1.11475,
        low: 1.11131
      },
      {
        timestamp: 1579039200,
        open: 1.11346,
        close: 1.11294,
        high: 1.11451,
        low: 1.11048
      },
      {
        timestamp: 1579125600,
        open: 1.11294,
        close: 1.11503,
        high: 1.1164,
        low: 1.11191
      },
      {
        timestamp: 1579212000,
        open: 1.11503,
        close: 1.1138,
        high: 1.11734,
        low: 1.11284
      },
      {
        timestamp: 1579298400,
        open: 1.1138,
        close: 1.10914,
        high: 1.11432,
        low: 1.10868
      },
      {
        timestamp: 1579471200,
        open: 1.10914,
        close: 1.10968,
        high: 1.10975,
        low: 1.10914
      },
      {
        timestamp: 1579557600,
        open: 1.10968,
        close: 1.10957,
        high: 1.1103,
        low: 1.10773
      },
      {
        timestamp: 1579644000,
        open: 1.10957,
        close: 1.1083,
        high: 1.11186,
        low: 1.10817
      },
      {
        timestamp: 1579730400,
        open: 1.1083,
        close: 1.10937,
        high: 1.1099,
        low: 1.10707
      },
      {
        timestamp: 1579816800,
        open: 1.10937,
        close: 1.10565,
        high: 1.11094,
        low: 1.10368
      },
      {
        timestamp: 1579903200,
        open: 1.10565,
        close: 1.10275,
        high: 1.10626,
        low: 1.10201
      },
      {
        timestamp: 1580076000,
        open: 1.10275,
        close: 1.10364,
        high: 1.10369,
        low: 1.10275
      },
      {
        timestamp: 1580162400,
        open: 1.10364,
        close: 1.10196,
        high: 1.10382,
        low: 1.10102
      },
      {
        timestamp: 1580248800,
        open: 1.10196,
        close: 1.10229,
        high: 1.10259,
        low: 1.09983
      },
      {
        timestamp: 1580335200,
        open: 1.10229,
        close: 1.10135,
        high: 1.10282,
        low: 1.09929
      },
      {
        timestamp: 1580421600,
        open: 1.10135,
        close: 1.10327,
        high: 1.10399,
        low: 1.10073
      },
      {
        timestamp: 1580508000,
        open: 1.10327,
        close: 1.10968,
        high: 1.10968,
        low: 1.10175
      },
      {
        timestamp: 1580680800,
        open: 1.10968,
        close: 1.10963,
        high: 1.10966,
        low: 1.10823
      },
      {
        timestamp: 1580767200,
        open: 1.10963,
        close: 1.10618,
        high: 1.10965,
        low: 1.10364
      },
      {
        timestamp: 1580853600,
        open: 1.10618,
        close: 1.10453,
        high: 1.10648,
        low: 1.10334
      },
      {
        timestamp: 1580940000,
        open: 1.10453,
        close: 1.09996,
        high: 1.10486,
        low: 1.09942
      },
      {
        timestamp: 1581026400,
        open: 1.09996,
        close: 1.0983,
        high: 1.10147,
        low: 1.0965
      },
      {
        timestamp: 1581112800,
        open: 1.0983,
        close: 1.09482,
        high: 1.09871,
        low: 1.09426
      },
      {
        timestamp: 1581285600,
        open: 1.09482,
        close: 1.09475,
        high: 1.09513,
        low: 1.09458
      },
      {
        timestamp: 1581372000,
        open: 1.09475,
        close: 1.09118,
        high: 1.09581,
        low: 1.09083
      },
      {
        timestamp: 1581458400,
        open: 1.09118,
        close: 1.09165,
        high: 1.09254,
        low: 1.08917
      },
      {
        timestamp: 1581544800,
        open: 1.09165,
        close: 1.08745,
        high: 1.09262,
        low: 1.08658
      },
      {
        timestamp: 1581631200,
        open: 1.08745,
        close: 1.08425,
        high: 1.08894,
        low: 1.08347
      },
      {
        timestamp: 1581717600,
        open: 1.08425,
        close: 1.08326,
        high: 1.08618,
        low: 1.08279
      },
      {
        timestamp: 1581890400,
        open: 1.08326,
        close: 1.08453,
        high: 1.0846,
        low: 1.08343
      },
      {
        timestamp: 1581976800,
        open: 1.08453,
        close: 1.08365,
        high: 1.08516,
        low: 1.08298
      },
      {
        timestamp: 1582063200,
        open: 1.08365,
        close: 1.07924,
        high: 1.08392,
        low: 1.07859
      },
      {
        timestamp: 1582149600,
        open: 1.07924,
        close: 1.08071,
        high: 1.08123,
        low: 1.07828
      },
      {
        timestamp: 1582236000,
        open: 1.08071,
        close: 1.0786,
        high: 1.08217,
        low: 1.07785
      },
      {
        timestamp: 1582322400,
        open: 1.0786,
        close: 1.08528,
        high: 1.0864,
        low: 1.07843
      },
      {
        timestamp: 1582495200,
        open: 1.08528,
        close: 1.08165,
        high: 1.08345,
        low: 1.08112
      },
      {
        timestamp: 1582581600,
        open: 1.08165,
        close: 1.08543,
        high: 1.08728,
        low: 1.08056
      },
      {
        timestamp: 1582668000,
        open: 1.08543,
        close: 1.0882,
        high: 1.08908,
        low: 1.08306
      },
      {
        timestamp: 1582754400,
        open: 1.0882,
        close: 1.08812,
        high: 1.09095,
        low: 1.08556
      },
      {
        timestamp: 1582840800,
        open: 1.08812,
        close: 1.10016,
        high: 1.10072,
        low: 1.08802
      },
      {
        timestamp: 1582927200,
        open: 1.10016,
        close: 1.103,
        high: 1.10536,
        low: 1.09516
      },
      {
        timestamp: 1583100000,
        open: 1.103,
        close: 1.10505,
        high: 1.10538,
        low: 1.103
      },
      {
        timestamp: 1583186400,
        open: 1.10505,
        close: 1.11344,
        high: 1.11853,
        low: 1.1037
      },
      {
        timestamp: 1583272800,
        open: 1.11344,
        close: 1.11752,
        high: 1.1214,
        low: 1.10958
      },
      {
        timestamp: 1583359200,
        open: 1.11752,
        close: 1.11391,
        high: 1.11881,
        low: 1.10961
      },
      {
        timestamp: 1583445600,
        open: 1.11391,
        close: 1.12415,
        high: 1.12458,
        low: 1.11202
      },
      {
        timestamp: 1583532000,
        open: 1.12415,
        close: 1.12892,
        high: 1.13553,
        low: 1.12113
      },
      {
        timestamp: 1583701200,
        open: 1.12892,
        close: 1.13554,
        high: 1.13592,
        low: 1.12892
      },
      {
        timestamp: 1583787600,
        open: 1.13554,
        close: 1.14436,
        high: 1.14974,
        low: 1.13365
      },
      {
        timestamp: 1583874000,
        open: 1.14436,
        close: 1.12834,
        high: 1.14612,
        low: 1.12754
      },
      {
        timestamp: 1583960400,
        open: 1.12834,
        close: 1.12705,
        high: 1.13673,
        low: 1.12578
      },
      {
        timestamp: 1584046800,
        open: 1.12705,
        close: 1.1186,
        high: 1.13343,
        low: 1.10554
      },
      {
        timestamp: 1584133200,
        open: 1.1186,
        close: 1.11092,
        high: 1.1222,
        low: 1.10557
      },
      {
        timestamp: 1584306000,
        open: 1.11092,
        close: 1.10705,
        high: 1.10765,
        low: 1.10587
      },
      {
        timestamp: 1584392400,
        open: 1.10705,
        close: 1.11846,
        high: 1.12374,
        low: 1.10705
      },
      {
        timestamp: 1584478800,
        open: 1.11846,
        close: 1.09987,
        high: 1.119,
        low: 1.09556
      },
      {
        timestamp: 1584565200,
        open: 1.09987,
        close: 1.09154,
        high: 1.10461,
        low: 1.08026
      },
      {
        timestamp: 1584651600,
        open: 1.09154,
        close: 1.06937,
        high: 1.09831,
        low: 1.06558
      },
      {
        timestamp: 1584738000,
        open: 1.06937,
        close: 1.07039,
        high: 1.08316,
        low: 1.06382
      },
      {
        timestamp: 1584910800,
        open: 1.07039,
        close: 1.07097,
        high: 1.07331,
        low: 1.06947
      },
      {
        timestamp: 1584997200,
        open: 1.07097,
        close: 1.07302,
        high: 1.08287,
        low: 1.0637
      },
      {
        timestamp: 1585083600,
        open: 1.07302,
        close: 1.07888,
        high: 1.08888,
        low: 1.0723
      },
      {
        timestamp: 1585170000,
        open: 1.07888,
        close: 1.08845,
        high: 1.08946,
        low: 1.07611
      },
      {
        timestamp: 1585256400,
        open: 1.08845,
        close: 1.10317,
        high: 1.10596,
        low: 1.08711
      },
      {
        timestamp: 1585342800,
        open: 1.10317,
        close: 1.11384,
        high: 1.11479,
        low: 1.09541
      },
      {
        timestamp: 1585515600,
        open: 1.11384,
        close: 1.11223,
        high: 1.11409,
        low: 1.11147
      },
      {
        timestamp: 1585602000,
        open: 1.11223,
        close: 1.1049,
        high: 1.11451,
        low: 1.10106
      },
      {
        timestamp: 1585688400,
        open: 1.1049,
        close: 1.10344,
        high: 1.10575,
        low: 1.09274
      },
      {
        timestamp: 1585774800,
        open: 1.10344,
        close: 1.09665,
        high: 1.10401,
        low: 1.09033
      },
      {
        timestamp: 1585861200,
        open: 1.09665,
        close: 1.08582,
        high: 1.09696,
        low: 1.08214
      },
      {
        timestamp: 1585947600,
        open: 1.08582,
        close: 1.08125,
        high: 1.08661,
        low: 1.07734
      },
      {
        timestamp: 1586120400,
        open: 1.08125,
        close: 1.08163,
        high: 1.08264,
        low: 1.08125
      },
      {
        timestamp: 1586206800,
        open: 1.08163,
        close: 1.07935,
        high: 1.08362,
        low: 1.07689
      },
      {
        timestamp: 1586293200,
        open: 1.07935,
        close: 1.08933,
        high: 1.09273,
        low: 1.07846
      },
      {
        timestamp: 1586379600,
        open: 1.08933,
        close: 1.08609,
        high: 1.09028,
        low: 1.08307
      },
      {
        timestamp: 1586466000,
        open: 1.08609,
        close: 1.09302,
        high: 1.09525,
        low: 1.08415
      },
      {
        timestamp: 1586552400,
        open: 1.09302,
        close: 1.09371,
        high: 1.09545,
        low: 1.09208
      },
      {
        timestamp: 1586725200,
        open: 1.09371,
        close: 1.09506,
        high: 1.09525,
        low: 1.09371
      },
      {
        timestamp: 1586811600,
        open: 1.09506,
        close: 1.09126,
        high: 1.09686,
        low: 1.08932
      },
      {
        timestamp: 1586898000,
        open: 1.09126,
        close: 1.09839,
        high: 1.09878,
        low: 1.09054
      },
      {
        timestamp: 1586984400,
        open: 1.09839,
        close: 1.09137,
        high: 1.09922,
        low: 1.0857
      },
      {
        timestamp: 1587070800,
        open: 1.09137,
        close: 1.08421,
        high: 1.0914,
        low: 1.08173
      },
      {
        timestamp: 1587157200,
        open: 1.08421,
        close: 1.08762,
        high: 1.08932,
        low: 1.08127
      },
      {
        timestamp: 1587330000,
        open: 1.08762,
        close: 1.08713,
        high: 1.08713,
        low: 1.08662
      },
      {
        timestamp: 1587416400,
        open: 1.08713,
        close: 1.08641,
        high: 1.08977,
        low: 1.08416
      },
      {
        timestamp: 1587502800,
        open: 1.08641,
        close: 1.086,
        high: 1.0881,
        low: 1.08168
      },
      {
        timestamp: 1587589200,
        open: 1.086,
        close: 1.08242,
        high: 1.08859,
        low: 1.08038
      },
      {
        timestamp: 1587675600,
        open: 1.08242,
        close: 1.07792,
        high: 1.08475,
        low: 1.07565
      },
      {
        timestamp: 1587762000,
        open: 1.07792,
        close: 1.08284,
        high: 1.08313,
        low: 1.07276
      },
      {
        timestamp: 1587934800,
        open: 1.08284,
        close: 1.08252,
        high: 1.08295,
        low: 1.08098
      },
      {
        timestamp: 1588021200,
        open: 1.08252,
        close: 1.08315,
        high: 1.08609,
        low: 1.0812
      },
      {
        timestamp: 1588107600,
        open: 1.08315,
        close: 1.08208,
        high: 1.08892,
        low: 1.081
      },
      {
        timestamp: 1588194000,
        open: 1.08208,
        close: 1.0879,
        high: 1.08863,
        low: 1.08199
      },
      {
        timestamp: 1588280400,
        open: 1.0879,
        close: 1.09598,
        high: 1.09735,
        low: 1.08335
      },
      {
        timestamp: 1588366800,
        open: 1.09598,
        close: 1.09846,
        high: 1.10196,
        low: 1.09352
      },
      {
        timestamp: 1588539600,
        open: 1.09846,
        close: 1.09747,
        high: 1.0981,
        low: 1.0974
      },
      {
        timestamp: 1588626000,
        open: 1.09747,
        close: 1.09096,
        high: 1.09781,
        low: 1.08962
      },
      {
        timestamp: 1588712400,
        open: 1.09096,
        close: 1.08421,
        high: 1.09267,
        low: 1.08265
      },
      {
        timestamp: 1588798800,
        open: 1.08421,
        close: 1.07971,
        high: 1.08518,
        low: 1.07824
      },
      {
        timestamp: 1588885200,
        open: 1.07971,
        close: 1.0835,
        high: 1.08359,
        low: 1.07672
      },
      {
        timestamp: 1588971600,
        open: 1.0835,
        close: 1.08425,
        high: 1.08763,
        low: 1.08157
      },
      {
        timestamp: 1589144400,
        open: 1.08425,
        close: 1.08347,
        high: 1.08405,
        low: 1.08223
      },
      {
        timestamp: 1589230800,
        open: 1.08347,
        close: 1.08097,
        high: 1.08511,
        low: 1.08012
      },
      {
        timestamp: 1589317200,
        open: 1.08097,
        close: 1.08489,
        high: 1.08854,
        low: 1.0785
      },
      {
        timestamp: 1589403600,
        open: 1.08489,
        close: 1.08195,
        high: 1.08973,
        low: 1.08122
      },
      {
        timestamp: 1589490000,
        open: 1.08195,
        close: 1.08057,
        high: 1.08246,
        low: 1.07753
      },
      {
        timestamp: 1589576400,
        open: 1.08057,
        close: 1.08229,
        high: 1.08519,
        low: 1.07895
      },
      {
        timestamp: 1589749200,
        open: 1.08229,
        close: 1.0814,
        high: 1.0814,
        low: 1.08035
      },
      {
        timestamp: 1589835600,
        open: 1.0814,
        close: 1.09165,
        high: 1.09279,
        low: 1.08004
      },
      {
        timestamp: 1589922000,
        open: 1.09165,
        close: 1.09253,
        high: 1.09767,
        low: 1.09028
      },
      {
        timestamp: 1590008400,
        open: 1.09253,
        close: 1.09832,
        high: 1.09995,
        low: 1.09194
      },
      {
        timestamp: 1590094800,
        open: 1.09832,
        close: 1.09551,
        high: 1.10091,
        low: 1.09378
      },
      {
        timestamp: 1590181200,
        open: 1.09551,
        close: 1.09054,
        high: 1.09554,
        low: 1.0886
      },
      {
        timestamp: 1590354000,
        open: 1.09054,
        close: 1.08989,
        high: 1.09021,
        low: 1.08905
      },
      {
        timestamp: 1590440400,
        open: 1.08989,
        close: 1.09006,
        high: 1.09152,
        low: 1.08711
      },
      {
        timestamp: 1590526800,
        open: 1.09006,
        close: 1.0983,
        high: 1.09966,
        low: 1.08931
      },
      {
        timestamp: 1590613200,
        open: 1.0983,
        close: 1.10102,
        high: 1.10315,
        low: 1.09343
      },
      {
        timestamp: 1590699600,
        open: 1.10102,
        close: 1.10786,
        high: 1.10943,
        low: 1.09923
      },
      {
        timestamp: 1590786000,
        open: 1.10786,
        close: 1.11094,
        high: 1.11458,
        low: 1.10705
      },
      {
        timestamp: 1590958800,
        open: 1.11094,
        close: 1.11215,
        high: 1.1125,
        low: 1.11094
      },
      {
        timestamp: 1591045200,
        open: 1.11215,
        close: 1.11374,
        high: 1.11548,
        low: 1.1101
      },
      {
        timestamp: 1591131600,
        open: 1.11374,
        close: 1.11744,
        high: 1.11966,
        low: 1.11157
      },
      {
        timestamp: 1591218000,
        open: 1.11744,
        close: 1.12344,
        high: 1.12585,
        low: 1.11673
      },
      {
        timestamp: 1591304400,
        open: 1.12344,
        close: 1.13405,
        high: 1.13633,
        low: 1.11953
      },
      {
        timestamp: 1591390800,
        open: 1.13405,
        close: 1.12945,
        high: 1.13844,
        low: 1.12791
      },
      {
        timestamp: 1591563600,
        open: 1.12945,
        close: 1.1297,
        high: 1.13055,
        low: 1.1293
      },
      {
        timestamp: 1591650000,
        open: 1.1297,
        close: 1.12951,
        high: 1.13204,
        low: 1.12688
      },
      {
        timestamp: 1591736400,
        open: 1.12951,
        close: 1.13443,
        high: 1.13643,
        low: 1.12415
      },
      {
        timestamp: 1591822800,
        open: 1.13443,
        close: 1.13777,
        high: 1.14233,
        low: 1.13225
      },
      {
        timestamp: 1591909200,
        open: 1.13777,
        close: 1.13004,
        high: 1.14044,
        low: 1.12893
      },
      {
        timestamp: 1591995600,
        open: 1.13004,
        close: 1.12595,
        high: 1.13413,
        low: 1.12131
      },
      {
        timestamp: 1592168400,
        open: 1.12595,
        close: 1.12344,
        high: 1.12495,
        low: 1.12323
      },
      {
        timestamp: 1592254800,
        open: 1.12344,
        close: 1.13238,
        high: 1.13331,
        low: 1.12275
      },
      {
        timestamp: 1592341200,
        open: 1.13238,
        close: 1.12643,
        high: 1.13539,
        low: 1.12284
      },
      {
        timestamp: 1592427600,
        open: 1.12643,
        close: 1.12448,
        high: 1.12946,
        low: 1.12077
      },
      {
        timestamp: 1592514000,
        open: 1.12448,
        close: 1.12068,
        high: 1.1262,
        low: 1.11863
      },
      {
        timestamp: 1592600400,
        open: 1.12068,
        close: 1.11818,
        high: 1.12551,
        low: 1.11688
      },
      {
        timestamp: 1592773200,
        open: 1.11818,
        close: 1.11889,
        high: 1.1198,
        low: 1.11818
      },
      {
        timestamp: 1592859600,
        open: 1.11889,
        close: 1.12614,
        high: 1.12706,
        low: 1.11692
      },
      {
        timestamp: 1592946000,
        open: 1.12614,
        close: 1.13092,
        high: 1.13491,
        low: 1.12337
      },
      {
        timestamp: 1593032400,
        open: 1.13092,
        close: 1.12518,
        high: 1.13265,
        low: 1.12488
      },
      {
        timestamp: 1593118800,
        open: 1.12518,
        close: 1.12189,
        high: 1.12603,
        low: 1.1191
      },
      {
        timestamp: 1593205200,
        open: 1.12189,
        close: 1.1222,
        high: 1.12401,
        low: 1.11958
      },
      {
        timestamp: 1593378000,
        open: 1.1222,
        close: 1.12226,
        high: 1.12339,
        low: 1.12226
      },
      {
        timestamp: 1593464400,
        open: 1.12226,
        close: 1.12423,
        high: 1.12885,
        low: 1.1218
      },
      {
        timestamp: 1593550800,
        open: 1.12423,
        close: 1.1235,
        high: 1.12625,
        low: 1.11915
      },
      {
        timestamp: 1593637200,
        open: 1.1235,
        close: 1.12522,
        high: 1.12757,
        low: 1.11854
      },
      {
        timestamp: 1593723600,
        open: 1.12522,
        close: 1.12404,
        high: 1.13032,
        low: 1.12239
      },
      {
        timestamp: 1593810000,
        open: 1.12404,
        close: 1.12498,
        high: 1.12544,
        low: 1.12197
      },
      {
        timestamp: 1593982800,
        open: 1.12498,
        close: 1.12478,
        high: 1.12514,
        low: 1.12462
      },
      {
        timestamp: 1594069200,
        open: 1.12478,
        close: 1.131,
        high: 1.13461,
        low: 1.12478
      },
      {
        timestamp: 1594155600,
        open: 1.131,
        close: 1.12748,
        high: 1.13331,
        low: 1.12594
      },
      {
        timestamp: 1594242000,
        open: 1.12748,
        close: 1.13309,
        high: 1.13522,
        low: 1.12629
      },
      {
        timestamp: 1594328400,
        open: 1.13309,
        close: 1.12856,
        high: 1.13713,
        low: 1.12808
      },
      {
        timestamp: 1594414800,
        open: 1.12856,
        close: 1.1302,
        high: 1.13254,
        low: 1.12553
      },
      {
        timestamp: 1594587600,
        open: 1.1302,
        close: 1.13079,
        high: 1.1312,
        low: 1.13025
      },
      {
        timestamp: 1594674000,
        open: 1.13079,
        close: 1.13451,
        high: 1.13755,
        low: 1.13015
      },
      {
        timestamp: 1594760400,
        open: 1.13451,
        close: 1.14003,
        high: 1.14093,
        low: 1.13258
      },
      {
        timestamp: 1594846800,
        open: 1.14003,
        close: 1.14124,
        high: 1.14527,
        low: 1.13916
      },
      {
        timestamp: 1594933200,
        open: 1.14124,
        close: 1.13843,
        high: 1.14423,
        low: 1.13711
      },
      {
        timestamp: 1595019600,
        open: 1.13843,
        close: 1.14299,
        high: 1.14442,
        low: 1.13782
      },
      {
        timestamp: 1595192400,
        open: 1.14299,
        close: 1.142,
        high: 1.14327,
        low: 1.14037
      },
      {
        timestamp: 1595278800,
        open: 1.142,
        close: 1.14483,
        high: 1.14683,
        low: 1.14029
      },
      {
        timestamp: 1595365200,
        open: 1.14483,
        close: 1.15276,
        high: 1.15403,
        low: 1.14236
      },
      {
        timestamp: 1595451600,
        open: 1.15276,
        close: 1.15704,
        high: 1.16021,
        low: 1.15074
      },
      {
        timestamp: 1595538000,
        open: 1.15704,
        close: 1.15968,
        high: 1.16275,
        low: 1.15409
      },
      {
        timestamp: 1595624400,
        open: 1.15968,
        close: 1.16589,
        high: 1.16591,
        low: 1.15818
      },
      {
        timestamp: 1595797200,
        open: 1.16589,
        close: 1.16456,
        high: 1.16587,
        low: 1.16405
      },
      {
        timestamp: 1595883600,
        open: 1.16456,
        close: 1.17523,
        high: 1.17819,
        low: 1.16429
      },
      {
        timestamp: 1595970000,
        open: 1.17523,
        close: 1.17168,
        high: 1.17744,
        low: 1.16993
      },
      {
        timestamp: 1596056400,
        open: 1.17168,
        close: 1.17923,
        high: 1.18069,
        low: 1.17147
      },
      {
        timestamp: 1596142800,
        open: 1.17923,
        close: 1.18479,
        high: 1.18489,
        low: 1.17314
      },
      {
        timestamp: 1596229200,
        open: 1.18479,
        close: 1.17829,
        high: 1.19093,
        low: 1.17618
      },
      {
        timestamp: 1596402000,
        open: 1.17829,
        close: 1.17778,
        high: 1.1788,
        low: 1.17735
      },
      {
        timestamp: 1596488400,
        open: 1.17778,
        close: 1.17628,
        high: 1.17975,
        low: 1.16961
      },
      {
        timestamp: 1596574800,
        open: 1.17628,
        close: 1.18035,
        high: 1.18069,
        low: 1.17219
      },
      {
        timestamp: 1596661200,
        open: 1.18035,
        close: 1.18638,
        high: 1.19055,
        low: 1.17935
      },
      {
        timestamp: 1596747600,
        open: 1.18638,
        close: 1.18775,
        high: 1.19171,
        low: 1.18185
      },
      {
        timestamp: 1596834000,
        open: 1.18775,
        close: 1.1788,
        high: 1.18838,
        low: 1.17558
      },
      {
        timestamp: 1597006800,
        open: 1.1788,
        close: 1.17938,
        high: 1.18017,
        low: 1.1788
      },
      {
        timestamp: 1597093200,
        open: 1.17938,
        close: 1.17393,
        high: 1.18013,
        low: 1.17366
      },
      {
        timestamp: 1597179600,
        open: 1.17393,
        close: 1.17405,
        high: 1.18085,
        low: 1.17227
      },
      {
        timestamp: 1597266000,
        open: 1.17405,
        close: 1.17851,
        high: 1.18179,
        low: 1.17117
      },
      {
        timestamp: 1597352400,
        open: 1.17851,
        close: 1.1814,
        high: 1.18649,
        low: 1.17831
      },
      {
        timestamp: 1597438800,
        open: 1.1814,
        close: 1.18453,
        high: 1.18511,
        low: 1.17822
      },
      {
        timestamp: 1597611600,
        open: 1.18453,
        close: 1.18448,
        high: 1.18477,
        low: 1.1843
      },
      {
        timestamp: 1597698000,
        open: 1.18448,
        close: 1.1871,
        high: 1.18815,
        low: 1.18293
      },
      {
        timestamp: 1597784400,
        open: 1.1871,
        close: 1.19322,
        high: 1.19663,
        low: 1.18684
      },
      {
        timestamp: 1597870800,
        open: 1.19322,
        close: 1.18407,
        high: 1.19534,
        low: 1.1831
      },
      {
        timestamp: 1597957200,
        open: 1.18407,
        close: 1.18614,
        high: 1.18694,
        low: 1.18027
      },
      {
        timestamp: 1598043600,
        open: 1.18614,
        close: 1.17986,
        high: 1.18835,
        low: 1.17546
      },
      {
        timestamp: 1598216400,
        open: 1.17986,
        close: 1.17986,
        high: 1.18004,
        low: 1.1794
      },
      {
        timestamp: 1598302800,
        open: 1.17986,
        close: 1.17887,
        high: 1.18501,
        low: 1.17849
      },
      {
        timestamp: 1598389200,
        open: 1.17887,
        close: 1.18355,
        high: 1.18444,
        low: 1.17848
      },
      {
        timestamp: 1598475600,
        open: 1.18355,
        close: 1.1831,
        high: 1.184,
        low: 1.17729
      },
      {
        timestamp: 1598562000,
        open: 1.1831,
        close: 1.18234,
        high: 1.19026,
        low: 1.1763
      },
      {
        timestamp: 1598648400,
        open: 1.18234,
        close: 1.19076,
        high: 1.19203,
        low: 1.18116
      },
      {
        timestamp: 1598821200,
        open: 1.19076,
        close: 1.19081,
        high: 1.19114,
        low: 1.1904
      },
      {
        timestamp: 1598907600,
        open: 1.19081,
        close: 1.19374,
        high: 1.19668,
        low: 1.18847
      },
      {
        timestamp: 1598994000,
        open: 1.19374,
        close: 1.19126,
        high: 1.20122,
        low: 1.19019
      },
      {
        timestamp: 1599080400,
        open: 1.19126,
        close: 1.18562,
        high: 1.19294,
        low: 1.18226
      },
      {
        timestamp: 1599166800,
        open: 1.18562,
        close: 1.18524,
        high: 1.18653,
        low: 1.17896
      },
      {
        timestamp: 1599253200,
        open: 1.18524,
        close: 1.18412,
        high: 1.1866,
        low: 1.17814
      },
      {
        timestamp: 1599426000,
        open: 1.18412,
        close: 1.18485,
        high: 1.18519,
        low: 1.18401
      },
      {
        timestamp: 1599512400,
        open: 1.18485,
        close: 1.18171,
        high: 1.18499,
        low: 1.18126
      },
      {
        timestamp: 1599598800,
        open: 1.18171,
        close: 1.17822,
        high: 1.18282,
        low: 1.17661
      },
      {
        timestamp: 1599685200,
        open: 1.17822,
        close: 1.18039,
        high: 1.18347,
        low: 1.17533
      },
      {
        timestamp: 1599771600,
        open: 1.18039,
        close: 1.18163,
        high: 1.19179,
        low: 1.18012
      },
      {
        timestamp: 1599858000,
        open: 1.18163,
        close: 1.18484,
        high: 1.18748,
        low: 1.18152
      },
      {
        timestamp: 1600030800,
        open: 1.18484,
        close: 1.18376,
        high: 1.18404,
        low: 1.18348
      },
      {
        timestamp: 1600117200,
        open: 1.18376,
        close: 1.18686,
        high: 1.18886,
        low: 1.18325
      },
      {
        timestamp: 1600203600,
        open: 1.18686,
        close: 1.18473,
        high: 1.19013,
        low: 1.18401
      },
      {
        timestamp: 1600290000,
        open: 1.18473,
        close: 1.18168,
        high: 1.18831,
        low: 1.1788
      },
      {
        timestamp: 1600376400,
        open: 1.18168,
        close: 1.18494,
        high: 1.18531,
        low: 1.17378
      },
      {
        timestamp: 1600462800,
        open: 1.18494,
        close: 1.18431,
        high: 1.18709,
        low: 1.18268
      },
      {
        timestamp: 1600635600,
        open: 1.18431,
        close: 1.18445,
        high: 1.18472,
        low: 1.18445
      },
      {
        timestamp: 1600722000,
        open: 1.18445,
        close: 1.17717,
        high: 1.18724,
        low: 1.17322
      },
      {
        timestamp: 1600808400,
        open: 1.17717,
        close: 1.17091,
        high: 1.17758,
        low: 1.16925
      },
      {
        timestamp: 1600894800,
        open: 1.17091,
        close: 1.1661,
        high: 1.17197,
        low: 1.16518
      },
      {
        timestamp: 1600981200,
        open: 1.1661,
        close: 1.16762,
        high: 1.16875,
        low: 1.1627
      },
      {
        timestamp: 1601067600,
        open: 1.16762,
        close: 1.16337,
        high: 1.16858,
        low: 1.16127
      },
      {
        timestamp: 1601240400,
        open: 1.16337,
        close: 1.16305,
        high: 1.16355,
        low: 1.16183
      },
      {
        timestamp: 1601326800,
        open: 1.16305,
        close: 1.16694,
        high: 1.16805,
        low: 1.16158
      },
      {
        timestamp: 1601413200,
        open: 1.16694,
        close: 1.17447,
        high: 1.17462,
        low: 1.16626
      },
      {
        timestamp: 1601499600,
        open: 1.17447,
        close: 1.17221,
        high: 1.17557,
        low: 1.16851
      },
      {
        timestamp: 1601586000,
        open: 1.17221,
        close: 1.17519,
        high: 1.17704,
        low: 1.17179
      },
      {
        timestamp: 1601672400,
        open: 1.17519,
        close: 1.17192,
        high: 1.17515,
        low: 1.16966
      },
      {
        timestamp: 1601845200,
        open: 1.17192,
        close: 1.17162,
        high: 1.17199,
        low: 1.17148
      },
      {
        timestamp: 1601931600,
        open: 1.17162,
        close: 1.17844,
        high: 1.17982,
        low: 1.17074
      },
      {
        timestamp: 1602018000,
        open: 1.17844,
        close: 1.17356,
        high: 1.18084,
        low: 1.17325
      },
      {
        timestamp: 1602104400,
        open: 1.17356,
        close: 1.17667,
        high: 1.17825,
        low: 1.17256
      },
      {
        timestamp: 1602190800,
        open: 1.17667,
        close: 1.17619,
        high: 1.17823,
        low: 1.17333
      },
      {
        timestamp: 1602277200,
        open: 1.17619,
        close: 1.18325,
        high: 1.18326,
        low: 1.17581
      },
      {
        timestamp: 1602450000,
        open: 1.18325,
        close: 1.18149,
        high: 1.18186,
        low: 1.17978
      },
      {
        timestamp: 1602536400,
        open: 1.18149,
        close: 1.18147,
        high: 1.18275,
        low: 1.17873
      },
      {
        timestamp: 1602622800,
        open: 1.18147,
        close: 1.17488,
        high: 1.18177,
        low: 1.17307
      },
      {
        timestamp: 1602709200,
        open: 1.17488,
        close: 1.17464,
        high: 1.17717,
        low: 1.17203
      },
      {
        timestamp: 1602795600,
        open: 1.17464,
        close: 1.17086,
        high: 1.17584,
        low: 1.1689
      },
      {
        timestamp: 1602882000,
        open: 1.17086,
        close: 1.17202,
        high: 1.17467,
        low: 1.16946
      },
      {
        timestamp: 1603054800,
        open: 1.17202,
        close: 1.17248,
        high: 1.17291,
        low: 1.17183
      },
      {
        timestamp: 1603141200,
        open: 1.17248,
        close: 1.17712,
        high: 1.17943,
        low: 1.17038
      },
      {
        timestamp: 1603227600,
        open: 1.17712,
        close: 1.1824,
        high: 1.18414,
        low: 1.17606
      },
      {
        timestamp: 1603314000,
        open: 1.1824,
        close: 1.18649,
        high: 1.18814,
        low: 1.18231
      },
      {
        timestamp: 1603400400,
        open: 1.18649,
        close: 1.18189,
        high: 1.18694,
        low: 1.1812
      },
      {
        timestamp: 1603486800,
        open: 1.18189,
        close: 1.1863,
        high: 1.18657,
        low: 1.17874
      },
      {
        timestamp: 1603659600,
        open: 1.1863,
        close: 1.18579,
        high: 1.18614,
        low: 1.18455
      },
      {
        timestamp: 1603746000,
        open: 1.18579,
        close: 1.18118,
        high: 1.18616,
        low: 1.18039
      },
      {
        timestamp: 1603832400,
        open: 1.18118,
        close: 1.17968,
        high: 1.18393,
        low: 1.17931
      },
      {
        timestamp: 1603918800,
        open: 1.17968,
        close: 1.17475,
        high: 1.18001,
        low: 1.17184
      },
      {
        timestamp: 1604005200,
        open: 1.17475,
        close: 1.16758,
        high: 1.17595,
        low: 1.16508
      },
      {
        timestamp: 1604091600,
        open: 1.16758,
        close: 1.16495,
        high: 1.17049,
        low: 1.16406
      },
      {
        timestamp: 1604268000,
        open: 1.16495,
        close: 1.16534,
        high: 1.16568,
        low: 1.16495
      },
      {
        timestamp: 1604354400,
        open: 1.16534,
        close: 1.1643,
        high: 1.16564,
        low: 1.16224
      },
      {
        timestamp: 1604440800,
        open: 1.1643,
        close: 1.17205,
        high: 1.17404,
        low: 1.16379
      },
      {
        timestamp: 1604527200,
        open: 1.17205,
        close: 1.17303,
        high: 1.17716,
        low: 1.16031
      },
      {
        timestamp: 1604613600,
        open: 1.17303,
        close: 1.18303,
        high: 1.18604,
        low: 1.17114
      },
      {
        timestamp: 1604700000,
        open: 1.18303,
        close: 1.18763,
        high: 1.18916,
        low: 1.1796
      },
      {
        timestamp: 1604872800,
        open: 1.18763,
        close: 1.18917,
        high: 1.18921,
        low: 1.18856
      },
      {
        timestamp: 1604959200,
        open: 1.18917,
        close: 1.18137,
        high: 1.19205,
        low: 1.1796
      },
      {
        timestamp: 1605045600,
        open: 1.18137,
        close: 1.18175,
        high: 1.18441,
        low: 1.17803
      },
      {
        timestamp: 1605132000,
        open: 1.18175,
        close: 1.1778,
        high: 1.18334,
        low: 1.1746
      },
      {
        timestamp: 1605218400,
        open: 1.1778,
        close: 1.18097,
        high: 1.18238,
        low: 1.17593
      },
      {
        timestamp: 1605304800,
        open: 1.18097,
        close: 1.1836,
        high: 1.1838,
        low: 1.17994
      },
      {
        timestamp: 1605477600,
        open: 1.1836,
        close: 1.18451,
        high: 1.1847,
        low: 1.1838
      },
      {
        timestamp: 1605564000,
        open: 1.18451,
        close: 1.18543,
        high: 1.18696,
        low: 1.18147
      },
      {
        timestamp: 1605650400,
        open: 1.18543,
        close: 1.18643,
        high: 1.18943,
        low: 1.1843
      },
      {
        timestamp: 1605736800,
        open: 1.18643,
        close: 1.18548,
        high: 1.18916,
        low: 1.18498
      },
      {
        timestamp: 1605823200,
        open: 1.18548,
        close: 1.18775,
        high: 1.18832,
        low: 1.18169
      },
      {
        timestamp: 1605909600,
        open: 1.18775,
        close: 1.18582,
        high: 1.18914,
        low: 1.18504
      },
      {
        timestamp: 1606082400,
        open: 1.18582,
        close: 1.1857,
        high: 1.18607,
        low: 1.18531
      },
      {
        timestamp: 1606168800,
        open: 1.1857,
        close: 1.18416,
        high: 1.19067,
        low: 1.18005
      },
      {
        timestamp: 1606255200,
        open: 1.18416,
        close: 1.18954,
        high: 1.18968,
        low: 1.18388
      },
      {
        timestamp: 1606341600,
        open: 1.18954,
        close: 1.19203,
        high: 1.19304,
        low: 1.18824
      },
      {
        timestamp: 1606428000,
        open: 1.19203,
        close: 1.19151,
        high: 1.19414,
        low: 1.18859
      },
      {
        timestamp: 1606514400,
        open: 1.19151,
        close: 1.19651,
        high: 1.1967,
        low: 1.19083
      },
      {
        timestamp: 1606687200,
        open: 1.19651,
        close: 1.1964,
        high: 1.19666,
        low: 1.19602
      },
      {
        timestamp: 1606773600,
        open: 1.1964,
        close: 1.19303,
        high: 1.20038,
        low: 1.19244
      },
      {
        timestamp: 1606860000,
        open: 1.19303,
        close: 1.20748,
        high: 1.20774,
        low: 1.19275
      },
      {
        timestamp: 1606946400,
        open: 1.20748,
        close: 1.21178,
        high: 1.21192,
        low: 1.20406
      },
      {
        timestamp: 1607032800,
        open: 1.21178,
        close: 1.21487,
        high: 1.21752,
        low: 1.21016
      },
      {
        timestamp: 1607119200,
        open: 1.21487,
        close: 1.21242,
        high: 1.2178,
        low: 1.21108
      },
      {
        timestamp: 1607292000,
        open: 1.21242,
        close: 1.21356,
        high: 1.21367,
        low: 1.21165
      },
      {
        timestamp: 1607378400,
        open: 1.21356,
        close: 1.21127,
        high: 1.21668,
        low: 1.20791
      },
      {
        timestamp: 1607464800,
        open: 1.21127,
        close: 1.21065,
        high: 1.21345,
        low: 1.2096
      },
      {
        timestamp: 1607551200,
        open: 1.21065,
        close: 1.20851,
        high: 1.21484,
        low: 1.20592
      },
      {
        timestamp: 1607637600,
        open: 1.20851,
        close: 1.21415,
        high: 1.21598,
        low: 1.2076
      },
      {
        timestamp: 1607724000,
        open: 1.21415,
        close: 1.21171,
        high: 1.21636,
        low: 1.2106
      },
      {
        timestamp: 1607896800,
        open: 1.21171,
        close: 1.2142,
        high: 1.21435,
        low: 1.21334
      },
      {
        timestamp: 1607983200,
        open: 1.2142,
        close: 1.21445,
        high: 1.21774,
        low: 1.2116
      },
      {
        timestamp: 1608069600,
        open: 1.21445,
        close: 1.21528,
        high: 1.21696,
        low: 1.21217
      },
      {
        timestamp: 1608156000,
        open: 1.21528,
        close: 1.22024,
        high: 1.22128,
        low: 1.21256
      },
      {
        timestamp: 1608242400,
        open: 1.22024,
        close: 1.22713,
        high: 1.22734,
        low: 1.21905
      },
      {
        timestamp: 1608328800,
        open: 1.22713,
        close: 1.22608,
        high: 1.22735,
        low: 1.22261
      },
      {
        timestamp: 1608501600,
        open: 1.22608,
        close: 1.22275,
        high: 1.22308,
        low: 1.22138
      },
      {
        timestamp: 1608588000,
        open: 1.22275,
        close: 1.22479,
        high: 1.22536,
        low: 1.21301
      },
      {
        timestamp: 1608674400,
        open: 1.22479,
        close: 1.21661,
        high: 1.22577,
        low: 1.21529
      },
      {
        timestamp: 1608760800,
        open: 1.21661,
        close: 1.2192,
        high: 1.22217,
        low: 1.21547
      },
      {
        timestamp: 1608847200,
        open: 1.2192,
        close: 1.21841,
        high: 1.22164,
        low: 1.2178
      },
      {
        timestamp: 1609192800,
        open: 1.21841,
        close: 1.22189,
        high: 1.2251,
        low: 1.21817
      },
      {
        timestamp: 1609279200,
        open: 1.22189,
        close: 1.22528,
        high: 1.22759,
        low: 1.22093
      },
      {
        timestamp: 1609365600,
        open: 1.22528,
        close: 1.23018,
        high: 1.23106,
        low: 1.2251
      },
      {
        timestamp: 1609452000,
        open: 1.23018,
        close: 1.22169,
        high: 1.231,
        low: 1.22156
      },
      {
        timestamp: 1609711200,
        open: 1.22169,
        close: 1.22416,
        high: 1.22469,
        low: 1.22335
      },
      {
        timestamp: 1609797600,
        open: 1.22416,
        close: 1.22517,
        high: 1.23105,
        low: 1.22318
      },
      {
        timestamp: 1609884000,
        open: 1.22517,
        close: 1.22994,
        high: 1.23063,
        low: 1.22477
      },
      {
        timestamp: 1609970400,
        open: 1.22994,
        close: 1.23301,
        high: 1.23502,
        low: 1.22662
      },
      {
        timestamp: 1610056800,
        open: 1.23301,
        close: 1.22742,
        high: 1.2345,
        low: 1.22457
      },
      {
        timestamp: 1610143200,
        open: 1.22742,
        close: 1.22267,
        high: 1.22853,
        low: 1.21936
      },
      {
        timestamp: 1610316000,
        open: 1.22267,
        close: 1.22254,
        high: 1.22294,
        low: 1.22198
      },
      {
        timestamp: 1610402400,
        open: 1.22254,
        close: 1.21531,
        high: 1.22276,
        low: 1.21329
      },
      {
        timestamp: 1610488800,
        open: 1.21531,
        close: 1.22094,
        high: 1.22103,
        low: 1.21378
      },
      {
        timestamp: 1610575200,
        open: 1.22094,
        close: 1.21586,
        high: 1.22234,
        low: 1.21403
      },
      {
        timestamp: 1610661600,
        open: 1.21586,
        close: 1.21593,
        high: 1.21793,
        low: 1.21116
      },
      {
        timestamp: 1610748000,
        open: 1.21593,
        close: 1.20761,
        high: 1.21632,
        low: 1.20755
      },
      {
        timestamp: 1610920800,
        open: 1.20761,
        close: 1.20874,
        high: 1.20947,
        low: 1.20845
      },
      {
        timestamp: 1611007200,
        open: 1.20874,
        close: 1.20804,
        high: 1.20885,
        low: 1.20541
      },
      {
        timestamp: 1611093600,
        open: 1.20804,
        close: 1.21294,
        high: 1.21456,
        low: 1.20745
      },
      {
        timestamp: 1611180000,
        open: 1.21294,
        close: 1.21082,
        high: 1.2159,
        low: 1.2077
      },
      {
        timestamp: 1611266400,
        open: 1.21082,
        close: 1.21679,
        high: 1.21739,
        low: 1.21048
      },
      {
        timestamp: 1611352800,
        open: 1.21679,
        close: 1.21725,
        high: 1.21902,
        low: 1.21522
      },
      {
        timestamp: 1611525600,
        open: 1.21725,
        close: 1.21766,
        high: 1.21776,
        low: 1.21715
      },
      {
        timestamp: 1611612000,
        open: 1.21766,
        close: 1.21422,
        high: 1.21841,
        low: 1.21165
      },
      {
        timestamp: 1611698400,
        open: 1.21422,
        close: 1.21606,
        high: 1.21765,
        low: 1.21083
      },
      {
        timestamp: 1611784800,
        open: 1.21606,
        close: 1.21132,
        high: 1.21705,
        low: 1.2059
      },
      {
        timestamp: 1611871200,
        open: 1.21132,
        close: 1.21251,
        high: 1.21433,
        low: 1.20814
      },
      {
        timestamp: 1611957600,
        open: 1.21251,
        close: 1.2142,
        high: 1.21565,
        low: 1.2095
      },
      {
        timestamp: 1612130400,
        open: 1.2142,
        close: 1.21345,
        high: 1.2146,
        low: 1.2129
      },
      {
        timestamp: 1612216800,
        open: 1.21345,
        close: 1.20619,
        high: 1.2137,
        low: 1.20566
      },
      {
        timestamp: 1612303200,
        open: 1.20619,
        close: 1.20447,
        high: 1.20885,
        low: 1.20121
      },
      {
        timestamp: 1612389600,
        open: 1.20447,
        close: 1.20366,
        high: 1.20509,
        low: 1.20046
      },
      {
        timestamp: 1612476000,
        open: 1.20366,
        close: 1.19656,
        high: 1.20434,
        low: 1.19578
      },
      {
        timestamp: 1612562400,
        open: 1.19656,
        close: 1.20503,
        high: 1.20508,
        low: 1.19527
      },
      {
        timestamp: 1612735200,
        open: 1.20503,
        close: 1.20404,
        high: 1.20476,
        low: 1.20379
      },
      {
        timestamp: 1612821600,
        open: 1.20404,
        close: 1.20527,
        high: 1.20665,
        low: 1.20202
      },
      {
        timestamp: 1612908000,
        open: 1.20527,
        close: 1.21211,
        high: 1.21225,
        low: 1.20478
      },
      {
        timestamp: 1612994400,
        open: 1.21211,
        close: 1.212,
        high: 1.21446,
        low: 1.21095
      },
      {
        timestamp: 1613080800,
        open: 1.212,
        close: 1.21322,
        high: 1.215,
        low: 1.21142
      },
      {
        timestamp: 1613167200,
        open: 1.21322,
        close: 1.21208,
        high: 1.21376,
        low: 1.20821
      },
      {
        timestamp: 1613340000,
        open: 1.21208,
        close: 1.21265,
        high: 1.21266,
        low: 1.2123
      },
      {
        timestamp: 1613426400,
        open: 1.21265,
        close: 1.2132,
        high: 1.21459,
        low: 1.21174
      },
      {
        timestamp: 1613512800,
        open: 1.2132,
        close: 1.2109,
        high: 1.21702,
        low: 1.20957
      },
      {
        timestamp: 1613599200,
        open: 1.2109,
        close: 1.20418,
        high: 1.21138,
        low: 1.20239
      },
      {
        timestamp: 1613685600,
        open: 1.20418,
        close: 1.20939,
        high: 1.20953,
        low: 1.20362
      },
      {
        timestamp: 1613772000,
        open: 1.20939,
        close: 1.21218,
        high: 1.21452,
        low: 1.20829
      },
      {
        timestamp: 1613944800,
        open: 1.21218,
        close: 1.21181,
        high: 1.21279,
        low: 1.21131
      },
      {
        timestamp: 1614031200,
        open: 1.21181,
        close: 1.21583,
        high: 1.217,
        low: 1.20918
      },
      {
        timestamp: 1614117600,
        open: 1.21583,
        close: 1.21505,
        high: 1.21807,
        low: 1.21359
      },
      {
        timestamp: 1614204000,
        open: 1.21505,
        close: 1.21709,
        high: 1.21754,
        low: 1.21096
      },
      {
        timestamp: 1614290400,
        open: 1.21709,
        close: 1.21762,
        high: 1.22439,
        low: 1.21567
      },
      {
        timestamp: 1614376800,
        open: 1.21762,
        close: 1.20753,
        high: 1.21876,
        low: 1.20628
      },
      {
        timestamp: 1614549600,
        open: 1.20753,
        close: 1.20729,
        high: 1.20772,
        low: 1.20726
      },
      {
        timestamp: 1614636000,
        open: 1.20729,
        close: 1.20502,
        high: 1.21017,
        low: 1.20281
      },
      {
        timestamp: 1614722400,
        open: 1.20502,
        close: 1.20938,
        high: 1.2095,
        low: 1.19923
      },
      {
        timestamp: 1614808800,
        open: 1.20938,
        close: 1.20652,
        high: 1.21141,
        low: 1.20433
      },
      {
        timestamp: 1614895200,
        open: 1.20652,
        close: 1.19728,
        high: 1.2069,
        low: 1.19622
      },
      {
        timestamp: 1614981600,
        open: 1.19728,
        close: 1.19152,
        high: 1.1979,
        low: 1.18938
      },
      {
        timestamp: 1615154400,
        open: 1.19152,
        close: 1.19204,
        high: 1.1924,
        low: 1.1918
      },
      {
        timestamp: 1615240800,
        open: 1.19204,
        close: 1.18483,
        high: 1.1933,
        low: 1.18448
      },
      {
        timestamp: 1615327200,
        open: 1.18483,
        close: 1.19027,
        high: 1.19165,
        low: 1.18361
      },
      {
        timestamp: 1615413600,
        open: 1.19027,
        close: 1.19302,
        high: 1.19304,
        low: 1.18694
      },
      {
        timestamp: 1615500000,
        open: 1.19302,
        close: 1.19887,
        high: 1.19905,
        low: 1.19161
      },
      {
        timestamp: 1615586400,
        open: 1.19887,
        close: 1.19566,
        high: 1.19895,
        low: 1.19104
      },
      {
        timestamp: 1615755600,
        open: 1.19566,
        close: 1.19527,
        high: 1.19535,
        low: 1.19527
      },
      {
        timestamp: 1615842000,
        open: 1.19527,
        close: 1.19334,
        high: 1.19682,
        low: 1.19116
      },
      {
        timestamp: 1615928400,
        open: 1.19334,
        close: 1.19041,
        high: 1.19524,
        low: 1.18825
      },
      {
        timestamp: 1616014800,
        open: 1.19041,
        close: 1.19802,
        high: 1.19863,
        low: 1.18867
      },
      {
        timestamp: 1616101200,
        open: 1.19802,
        close: 1.19174,
        high: 1.19895,
        low: 1.19069
      },
      {
        timestamp: 1616187600,
        open: 1.19174,
        close: 1.19087,
        high: 1.19377,
        low: 1.18744
      },
      {
        timestamp: 1616360400,
        open: 1.19087,
        close: 1.18804,
        high: 1.18916,
        low: 1.18731
      },
      {
        timestamp: 1616446800,
        open: 1.18804,
        close: 1.1933,
        high: 1.19474,
        low: 1.18742
      },
      {
        timestamp: 1616533200,
        open: 1.1933,
        close: 1.18504,
        high: 1.19413,
        low: 1.18427
      },
      {
        timestamp: 1616619600,
        open: 1.18504,
        close: 1.18142,
        high: 1.18555,
        low: 1.18102
      },
      {
        timestamp: 1616706000,
        open: 1.18142,
        close: 1.1765,
        high: 1.18284,
        low: 1.17623
      },
      {
        timestamp: 1616792400,
        open: 1.1765,
        close: 1.1796,
        high: 1.18057,
        low: 1.17649
      },
      {
        timestamp: 1616965200,
        open: 1.1796,
        close: 1.17951,
        high: 1.1801,
        low: 1.17905
      },
      {
        timestamp: 1617051600,
        open: 1.17951,
        close: 1.17667,
        high: 1.17956,
        low: 1.17613
      },
      {
        timestamp: 1617138000,
        open: 1.17667,
        close: 1.17179,
        high: 1.17774,
        low: 1.17116
      },
      {
        timestamp: 1617224400,
        open: 1.17179,
        close: 1.17314,
        high: 1.17604,
        low: 1.17047
      },
      {
        timestamp: 1617310800,
        open: 1.17314,
        close: 1.17786,
        high: 1.17807,
        low: 1.17131
      },
      {
        timestamp: 1617397200,
        open: 1.17786,
        close: 1.1766,
        high: 1.17874,
        low: 1.17497
      },
      {
        timestamp: 1617570000,
        open: 1.1766,
        close: 1.17621,
        high: 1.1768,
        low: 1.17598
      },
      {
        timestamp: 1617656400,
        open: 1.17621,
        close: 1.18151,
        high: 1.18202,
        low: 1.1738
      },
      {
        timestamp: 1617742800,
        open: 1.18151,
        close: 1.18779,
        high: 1.18785,
        low: 1.17956
      },
      {
        timestamp: 1617829200,
        open: 1.18779,
        close: 1.18705,
        high: 1.19154,
        low: 1.18614
      },
      {
        timestamp: 1617915600,
        open: 1.18705,
        close: 1.19169,
        high: 1.19282,
        low: 1.18614
      },
      {
        timestamp: 1618002000,
        open: 1.19169,
        close: 1.19035,
        high: 1.19217,
        low: 1.18675
      },
      {
        timestamp: 1618174800,
        open: 1.19035,
        close: 1.18922,
        high: 1.1898,
        low: 1.18922
      },
      {
        timestamp: 1618261200,
        open: 1.18922,
        close: 1.19117,
        high: 1.19198,
        low: 1.18717
      },
      {
        timestamp: 1618347600,
        open: 1.19117,
        close: 1.19489,
        high: 1.19564,
        low: 1.18787
      },
      {
        timestamp: 1618434000,
        open: 1.19489,
        close: 1.19815,
        high: 1.1988,
        low: 1.19485
      },
      {
        timestamp: 1618520400,
        open: 1.19815,
        close: 1.19708,
        high: 1.19939,
        low: 1.19567
      },
      {
        timestamp: 1618606800,
        open: 1.19708,
        close: 1.19836,
        high: 1.19955,
        low: 1.19511
      },
      {
        timestamp: 1618779600,
        open: 1.19836,
        close: 1.19754,
        high: 1.19839,
        low: 1.19741
      },
      {
        timestamp: 1618866000,
        open: 1.19754,
        close: 1.20395,
        high: 1.20485,
        low: 1.19431
      },
      {
        timestamp: 1618952400,
        open: 1.20395,
        close: 1.20377,
        high: 1.20804,
        low: 1.2023
      },
      {
        timestamp: 1619038800,
        open: 1.20377,
        close: 1.20361,
        high: 1.20443,
        low: 1.1999
      },
      {
        timestamp: 1619125200,
        open: 1.20361,
        close: 1.20167,
        high: 1.20704,
        low: 1.19943
      },
      {
        timestamp: 1619211600,
        open: 1.20167,
        close: 1.20991,
        high: 1.21006,
        low: 1.20135
      },
      {
        timestamp: 1619384400,
        open: 1.20991,
        close: 1.20946,
        high: 1.20976,
        low: 1.20946
      },
      {
        timestamp: 1619470800,
        open: 1.20946,
        close: 1.20893,
        high: 1.21176,
        low: 1.20617
      },
      {
        timestamp: 1619557200,
        open: 1.20893,
        close: 1.20935,
        high: 1.20937,
        low: 1.20574
      },
      {
        timestamp: 1619643600,
        open: 1.20935,
        close: 1.21277,
        high: 1.21353,
        low: 1.20565
      },
      {
        timestamp: 1619730000,
        open: 1.21277,
        close: 1.21239,
        high: 1.21506,
        low: 1.21026
      },
      {
        timestamp: 1619816400,
        open: 1.21239,
        close: 1.20248,
        high: 1.2129,
        low: 1.20172
      },
      {
        timestamp: 1619989200,
        open: 1.20248,
        close: 1.20356,
        high: 1.20393,
        low: 1.2032
      },
      {
        timestamp: 1620075600,
        open: 1.20356,
        close: 1.20657,
        high: 1.20768,
        low: 1.20136
      },
      {
        timestamp: 1620162000,
        open: 1.20657,
        close: 1.20171,
        high: 1.20673,
        low: 1.19996
      },
      {
        timestamp: 1620248400,
        open: 1.20171,
        close: 1.2006,
        high: 1.20272,
        low: 1.19865
      },
      {
        timestamp: 1620334800,
        open: 1.2006,
        close: 1.20667,
        high: 1.20722,
        low: 1.19936
      },
      {
        timestamp: 1620421200,
        open: 1.20667,
        close: 1.21658,
        high: 1.2172,
        low: 1.20534
      },
      {
        timestamp: 1620594000,
        open: 1.21658,
        close: 1.21705,
        high: 1.21707,
        low: 1.21688
      },
      {
        timestamp: 1620680400,
        open: 1.21705,
        close: 1.2131,
        high: 1.21788,
        low: 1.21285
      },
      {
        timestamp: 1620766800,
        open: 1.2131,
        close: 1.21498,
        high: 1.21823,
        low: 1.21236
      },
      {
        timestamp: 1620853200,
        open: 1.21498,
        close: 1.20764,
        high: 1.21529,
        low: 1.20659
      },
      {
        timestamp: 1620939600,
        open: 1.20764,
        close: 1.20833,
        high: 1.21067,
        low: 1.20519
      },
      {
        timestamp: 1621026000,
        open: 1.20833,
        close: 1.21469,
        high: 1.21485,
        low: 1.20714
      },
      {
        timestamp: 1621198800,
        open: 1.21469,
        close: 1.2149,
        high: 1.21516,
        low: 1.21462
      },
      {
        timestamp: 1621285200,
        open: 1.2149,
        close: 1.21571,
        high: 1.21695,
        low: 1.21267
      },
      {
        timestamp: 1621371600,
        open: 1.21571,
        close: 1.22234,
        high: 1.22346,
        low: 1.21519
      },
      {
        timestamp: 1621458000,
        open: 1.22234,
        close: 1.21792,
        high: 1.22461,
        low: 1.21603
      },
      {
        timestamp: 1621544400,
        open: 1.21792,
        close: 1.22287,
        high: 1.22297,
        low: 1.21696
      },
      {
        timestamp: 1621630800,
        open: 1.22287,
        close: 1.21827,
        high: 1.22404,
        low: 1.21614
      },
      {
        timestamp: 1621803600,
        open: 1.21827,
        close: 1.21838,
        high: 1.21838,
        low: 1.21835
      },
      {
        timestamp: 1621890000,
        open: 1.21838,
        close: 1.22172,
        high: 1.22305,
        low: 1.21727
      },
      {
        timestamp: 1621976400,
        open: 1.22172,
        close: 1.2254,
        high: 1.22669,
        low: 1.22121
      },
      {
        timestamp: 1622062800,
        open: 1.2254,
        close: 1.21928,
        high: 1.22634,
        low: 1.21825
      },
      {
        timestamp: 1622149200,
        open: 1.21928,
        close: 1.21996,
        high: 1.22156,
        low: 1.21758
      },
      {
        timestamp: 1622235600,
        open: 1.21996,
        close: 1.21935,
        high: 1.22056,
        low: 1.21331
      },
      {
        timestamp: 1622408400,
        open: 1.21935,
        close: 1.22031,
        high: 1.22031,
        low: 1.2197
      },
      {
        timestamp: 1622494800,
        open: 1.22031,
        close: 1.22298,
        high: 1.22319,
        low: 1.21839
      },
      {
        timestamp: 1622581200,
        open: 1.22298,
        close: 1.22141,
        high: 1.22552,
        low: 1.22124
      },
      {
        timestamp: 1622667600,
        open: 1.22141,
        close: 1.22132,
        high: 1.22272,
        low: 1.21647
      },
      {
        timestamp: 1622754000,
        open: 1.22132,
        close: 1.21285,
        high: 1.22157,
        low: 1.21189
      },
      {
        timestamp: 1622840400,
        open: 1.21285,
        close: 1.21679,
        high: 1.21864,
        low: 1.21045
      },
      {
        timestamp: 1623013200,
        open: 1.21679,
        close: 1.2165,
        high: 1.21695,
        low: 1.21644
      },
      {
        timestamp: 1623099600,
        open: 1.2165,
        close: 1.21901,
        high: 1.22026,
        low: 1.21455
      },
      {
        timestamp: 1623186000,
        open: 1.21901,
        close: 1.21737,
        high: 1.21955,
        low: 1.21648
      },
      {
        timestamp: 1623272400,
        open: 1.21737,
        close: 1.2183,
        high: 1.22187,
        low: 1.21719
      },
      {
        timestamp: 1623358800,
        open: 1.2183,
        close: 1.21765,
        high: 1.21955,
        low: 1.21437
      },
      {
        timestamp: 1623445200,
        open: 1.21765,
        close: 1.21096,
        high: 1.21936,
        low: 1.20933
      },
      {
        timestamp: 1623704400,
        open: 1.21096,
        close: 1.21233,
        high: 1.21311,
        low: 1.20948
      },
      {
        timestamp: 1623790800,
        open: 1.21233,
        close: 1.21269,
        high: 1.2148,
        low: 1.21018
      },
      {
        timestamp: 1623877200,
        open: 1.21269,
        close: 1.19963,
        high: 1.21353,
        low: 1.19956
      },
      {
        timestamp: 1623963600,
        open: 1.19963,
        close: 1.19091,
        high: 1.20069,
        low: 1.18923
      },
      {
        timestamp: 1624050000,
        open: 1.19091,
        close: 1.18654,
        high: 1.19257,
        low: 1.18479
      },
      {
        timestamp: 1624222800,
        open: 1.18654,
        close: 1.18596,
        high: 1.18609,
        low: 1.18579
      },
      {
        timestamp: 1624309200,
        open: 1.18596,
        close: 1.19212,
        high: 1.1922,
        low: 1.18482
      },
      {
        timestamp: 1624395600,
        open: 1.19212,
        close: 1.19435,
        high: 1.19533,
        low: 1.18817
      },
      {
        timestamp: 1624482000,
        open: 1.19435,
        close: 1.19268,
        high: 1.19709,
        low: 1.19119
      },
      {
        timestamp: 1624568400,
        open: 1.19268,
        close: 1.19325,
        high: 1.1957,
        low: 1.19182
      },
      {
        timestamp: 1624654800,
        open: 1.19325,
        close: 1.19368,
        high: 1.19759,
        low: 1.19267
      },
      {
        timestamp: 1624827600,
        open: 1.19368,
        close: 1.19391,
        high: 1.1942,
        low: 1.1936
      },
      {
        timestamp: 1624914000,
        open: 1.19391,
        close: 1.19302,
        high: 1.19452,
        low: 1.19029
      },
      {
        timestamp: 1625000400,
        open: 1.19302,
        close: 1.1899,
        high: 1.19335,
        low: 1.18784
      },
      {
        timestamp: 1625086800,
        open: 1.1899,
        close: 1.18608,
        high: 1.19097,
        low: 1.18456
      },
      {
        timestamp: 1625173200,
        open: 1.18608,
        close: 1.18517,
        high: 1.18849,
        low: 1.1838
      },
      {
        timestamp: 1625259600,
        open: 1.18517,
        close: 1.18663,
        high: 1.18748,
        low: 1.18079
      },
      {
        timestamp: 1625432400,
        open: 1.18663,
        close: 1.18743,
        high: 1.18746,
        low: 1.18739
      },
      {
        timestamp: 1625518800,
        open: 1.18743,
        close: 1.18667,
        high: 1.18811,
        low: 1.18521
      },
      {
        timestamp: 1625605200,
        open: 1.18667,
        close: 1.18243,
        high: 1.18959,
        low: 1.18068
      },
      {
        timestamp: 1625691600,
        open: 1.18243,
        close: 1.17917,
        high: 1.18369,
        low: 1.17819
      },
      {
        timestamp: 1625778000,
        open: 1.17917,
        close: 1.18478,
        high: 1.18685,
        low: 1.17841
      },
      {
        timestamp: 1625864400,
        open: 1.18478,
        close: 1.18789,
        high: 1.18817,
        low: 1.18256
      },
      {
        timestamp: 1626037200,
        open: 1.18789,
        close: 1.1879,
        high: 1.18797,
        low: 1.18782
      },
      {
        timestamp: 1626123600,
        open: 1.1879,
        close: 1.18625,
        high: 1.18817,
        low: 1.18367
      },
      {
        timestamp: 1626210000,
        open: 1.18625,
        close: 1.17774,
        high: 1.18758,
        low: 1.17727
      },
      {
        timestamp: 1626296400,
        open: 1.17774,
        close: 1.18383,
        high: 1.18395,
        low: 1.17726
      },
      {
        timestamp: 1626382800,
        open: 1.18383,
        close: 1.18147,
        high: 1.18512,
        low: 1.17969
      },
      {
        timestamp: 1626469200,
        open: 1.18147,
        close: 1.18099,
        high: 1.18231,
        low: 1.17928
      },
      {
        timestamp: 1626642000,
        open: 1.18099,
        close: 1.18134,
        high: 1.1817,
        low: 1.18098
      },
      {
        timestamp: 1626728400,
        open: 1.18134,
        close: 1.18021,
        high: 1.18252,
        low: 1.17643
      },
      {
        timestamp: 1626814800,
        open: 1.18021,
        close: 1.17856,
        high: 1.18064,
        low: 1.17562
      },
      {
        timestamp: 1626901200,
        open: 1.17856,
        close: 1.17982,
        high: 1.18054,
        low: 1.17522
      },
      {
        timestamp: 1626987600,
        open: 1.17982,
        close: 1.17715,
        high: 1.18311,
        low: 1.1758
      },
      {
        timestamp: 1627074000,
        open: 1.17715,
        close: 1.17761,
        high: 1.17871,
        low: 1.1755
      },
      {
        timestamp: 1627246800,
        open: 1.17761,
        close: 1.17807,
        high: 1.17807,
        low: 1.17779
      },
      {
        timestamp: 1627333200,
        open: 1.17807,
        close: 1.18037,
        high: 1.18178,
        low: 1.1764
      },
      {
        timestamp: 1627419600,
        open: 1.18037,
        close: 1.18173,
        high: 1.18418,
        low: 1.17705
      },
      {
        timestamp: 1627506000,
        open: 1.18173,
        close: 1.18472,
        high: 1.18503,
        low: 1.17727
      },
      {
        timestamp: 1627592400,
        open: 1.18472,
        close: 1.18883,
        high: 1.18934,
        low: 1.18413
      },
      {
        timestamp: 1627678800,
        open: 1.18883,
        close: 1.18698,
        high: 1.19093,
        low: 1.18521
      },
      {
        timestamp: 1627938000,
        open: 1.18698,
        close: 1.18722,
        high: 1.18976,
        low: 1.18595
      },
      {
        timestamp: 1628024400,
        open: 1.18722,
        close: 1.18663,
        high: 1.18939,
        low: 1.18541
      },
      {
        timestamp: 1628110800,
        open: 1.18663,
        close: 1.18376,
        high: 1.19003,
        low: 1.18333
      },
      {
        timestamp: 1628197200,
        open: 1.18376,
        close: 1.18364,
        high: 1.18579,
        low: 1.18287
      },
      {
        timestamp: 1628283600,
        open: 1.18364,
        close: 1.17645,
        high: 1.18388,
        low: 1.17548
      },
      {
        timestamp: 1628542800,
        open: 1.17645,
        close: 1.17367,
        high: 1.17696,
        low: 1.17355
      },
      {
        timestamp: 1628629200,
        open: 1.17367,
        close: 1.17231,
        high: 1.17436,
        low: 1.171
      },
      {
        timestamp: 1628715600,
        open: 1.17231,
        close: 1.17422,
        high: 1.17549,
        low: 1.17066
      },
      {
        timestamp: 1628802000,
        open: 1.17422,
        close: 1.17316,
        high: 1.17489,
        low: 1.17242
      },
      {
        timestamp: 1628888400,
        open: 1.17316,
        close: 1.17965,
        high: 1.18054,
        low: 1.17303
      },
      {
        timestamp: 1629061200,
        open: 1.17965,
        close: 1.17992,
        high: 1.18015,
        low: 1.17992
      },
      {
        timestamp: 1629147600,
        open: 1.17992,
        close: 1.1781,
        high: 1.18022,
        low: 1.17679
      },
      {
        timestamp: 1629234000,
        open: 1.1781,
        close: 1.17105,
        high: 1.17853,
        low: 1.17087
      },
      {
        timestamp: 1629320400,
        open: 1.17105,
        close: 1.17141,
        high: 1.17435,
        low: 1.16942
      },
      {
        timestamp: 1629406800,
        open: 1.17141,
        close: 1.16768,
        high: 1.17227,
        low: 1.16663
      },
      {
        timestamp: 1629493200,
        open: 1.16768,
        close: 1.17009,
        high: 1.17052,
        low: 1.16644
      },
      {
        timestamp: 1629752400,
        open: 1.17009,
        close: 1.17484,
        high: 1.17509,
        low: 1.16937
      },
      {
        timestamp: 1629838800,
        open: 1.17484,
        close: 1.1759,
        high: 1.17658,
        low: 1.17278
      },
      {
        timestamp: 1629925200,
        open: 1.1759,
        close: 1.17729,
        high: 1.17753,
        low: 1.17267
      },
      {
        timestamp: 1630011600,
        open: 1.17729,
        close: 1.17537,
        high: 1.17795,
        low: 1.17466
      },
      {
        timestamp: 1630098000,
        open: 1.17537,
        close: 1.17977,
        high: 1.1803,
        low: 1.17355
      },
      {
        timestamp: 1630270800,
        open: 1.17977,
        close: 1.17985,
        high: 1.18005,
        low: 1.1797
      },
      {
        timestamp: 1630357200,
        open: 1.17985,
        close: 1.17973,
        high: 1.18105,
        low: 1.17833
      },
      {
        timestamp: 1630443600,
        open: 1.17973,
        close: 1.18098,
        high: 1.18456,
        low: 1.17958
      },
      {
        timestamp: 1630530000,
        open: 1.18098,
        close: 1.1841,
        high: 1.18579,
        low: 1.17942
      },
      {
        timestamp: 1630616400,
        open: 1.1841,
        close: 1.1876,
        high: 1.18769,
        low: 1.18348
      },
      {
        timestamp: 1630702800,
        open: 1.1876,
        close: 1.18798,
        high: 1.19097,
        low: 1.18664
      },
      {
        timestamp: 1630875600,
        open: 1.18798,
        close: 1.18856,
        high: 1.18877,
        low: 1.18845
      },
      {
        timestamp: 1630962000,
        open: 1.18856,
        close: 1.18726,
        high: 1.18871,
        low: 1.18563
      },
      {
        timestamp: 1631048400,
        open: 1.18726,
        close: 1.18425,
        high: 1.18859,
        low: 1.1838
      },
      {
        timestamp: 1631134800,
        open: 1.18425,
        close: 1.18199,
        high: 1.18517,
        low: 1.18026
      },
      {
        timestamp: 1631221200,
        open: 1.18199,
        close: 1.18288,
        high: 1.1842,
        low: 1.18057
      },
      {
        timestamp: 1631307600,
        open: 1.18288,
        close: 1.18136,
        high: 1.18518,
        low: 1.18104
      },
      {
        timestamp: 1631480400,
        open: 1.18136,
        close: 1.18191,
        high: 1.18195,
        low: 1.18133
      },
      {
        timestamp: 1631566800,
        open: 1.18191,
        close: 1.1812,
        high: 1.18196,
        low: 1.17707
      },
      {
        timestamp: 1631653200,
        open: 1.1812,
        close: 1.18043,
        high: 1.18464,
        low: 1.18006
      },
      {
        timestamp: 1631739600,
        open: 1.18043,
        close: 1.18185,
        high: 1.18326,
        low: 1.17998
      },
      {
        timestamp: 1631826000,
        open: 1.18185,
        close: 1.1769,
        high: 1.18212,
        low: 1.1751
      },
      {
        timestamp: 1631912400,
        open: 1.1769,
        close: 1.17262,
        high: 1.17895,
        low: 1.17251
      },
      {
        timestamp: 1632085200,
        open: 1.17262,
        close: 1.17345,
        high: 1.17371,
        low: 1.17314
      },
      {
        timestamp: 1632171600,
        open: 1.17345,
        close: 1.1728,
        high: 1.17371,
        low: 1.17008
      },
      {
        timestamp: 1632258000,
        open: 1.1728,
        close: 1.17298,
        high: 1.17493,
        low: 1.17154
      },
      {
        timestamp: 1632344400,
        open: 1.17298,
        close: 1.16889,
        high: 1.17562,
        low: 1.16849
      },
      {
        timestamp: 1632430800,
        open: 1.16889,
        close: 1.17391,
        high: 1.17507,
        low: 1.16838
      },
      {
        timestamp: 1632517200,
        open: 1.17391,
        close: 1.17221,
        high: 1.17483,
        low: 1.17012
      },
      {
        timestamp: 1632690000,
        open: 1.17221,
        close: 1.17305,
        high: 1.17349,
        low: 1.1725
      },
      {
        timestamp: 1632776400,
        open: 1.17305,
        close: 1.16965,
        high: 1.17306,
        low: 1.16851
      },
      {
        timestamp: 1632862800,
        open: 1.16965,
        close: 1.16842,
        high: 1.17043,
        low: 1.16687
      },
      {
        timestamp: 1632949200,
        open: 1.16842,
        close: 1.1598,
        high: 1.16908,
        low: 1.15899
      },
      {
        timestamp: 1633035600,
        open: 1.1598,
        close: 1.15815,
        high: 1.16104,
        low: 1.15631
      },
      {
        timestamp: 1633122000,
        open: 1.15815,
        close: 1.15944,
        high: 1.16079,
        low: 1.15636
      },
      {
        timestamp: 1633294800,
        open: 1.15944,
        close: 1.16016,
        high: 1.16038,
        low: 1.15926
      },
      {
        timestamp: 1633381200,
        open: 1.16016,
        close: 1.16234,
        high: 1.16407,
        low: 1.15882
      },
      {
        timestamp: 1633467600,
        open: 1.16234,
        close: 1.15987,
        high: 1.16265,
        low: 1.15811
      },
      {
        timestamp: 1633554000,
        open: 1.15987,
        close: 1.15582,
        high: 1.16054,
        low: 1.15297
      },
      {
        timestamp: 1633640400,
        open: 1.15582,
        close: 1.15532,
        high: 1.15728,
        low: 1.15478
      },
      {
        timestamp: 1633726800,
        open: 1.15532,
        close: 1.15782,
        high: 1.15867,
        low: 1.15419
      },
      {
        timestamp: 1633899600,
        open: 1.15782,
        close: 1.15744,
        high: 1.15843,
        low: 1.15732
      },
      {
        timestamp: 1633986000,
        open: 1.15744,
        close: 1.15535,
        high: 1.15874,
        low: 1.15496
      },
      {
        timestamp: 1634072400,
        open: 1.15535,
        close: 1.15308,
        high: 1.15708,
        low: 1.15249
      },
      {
        timestamp: 1634158800,
        open: 1.15308,
        close: 1.15965,
        high: 1.1598,
        low: 1.15291
      },
      {
        timestamp: 1634245200,
        open: 1.15965,
        close: 1.15982,
        high: 1.16249,
        low: 1.15846
      },
      {
        timestamp: 1634331600,
        open: 1.15982,
        close: 1.16035,
        high: 1.16194,
        low: 1.15887
      },
      {
        timestamp: 1634504400,
        open: 1.16035,
        close: 1.15953,
        high: 1.16053,
        low: 1.15933
      },
      {
        timestamp: 1634590800,
        open: 1.15953,
        close: 1.16115,
        high: 1.1623,
        low: 1.15723
      },
      {
        timestamp: 1634677200,
        open: 1.16115,
        close: 1.16356,
        high: 1.16701,
        low: 1.16091
      },
      {
        timestamp: 1634763600,
        open: 1.16356,
        close: 1.16562,
        high: 1.16591,
        low: 1.16174
      },
      {
        timestamp: 1634850000,
        open: 1.16562,
        close: 1.1625,
        high: 1.1668,
        low: 1.16201
      },
      {
        timestamp: 1634936400,
        open: 1.1625,
        close: 1.16459,
        high: 1.16563,
        low: 1.16217
      },
      {
        timestamp: 1635109200,
        open: 1.16459,
        close: 1.1645,
        high: 1.16499,
        low: 1.16356
      },
      {
        timestamp: 1635195600,
        open: 1.1645,
        close: 1.16103,
        high: 1.16658,
        low: 1.15912
      },
      {
        timestamp: 1635282000,
        open: 1.16103,
        close: 1.15971,
        high: 1.16264,
        low: 1.15854
      },
      {
        timestamp: 1635368400,
        open: 1.15971,
        close: 1.16038,
        high: 1.16268,
        low: 1.15853
      },
      {
        timestamp: 1635454800,
        open: 1.16038,
        close: 1.16836,
        high: 1.16928,
        low: 1.15827
      },
      {
        timestamp: 1635541200,
        open: 1.16836,
        close: 1.1563,
        high: 1.16908,
        low: 1.15357
      },
      {
        timestamp: 1635714000,
        open: 1.1563,
        close: 1.15622,
        high: 1.1568,
        low: 1.15539
      },
      {
        timestamp: 1635800400,
        open: 1.15622,
        close: 1.16069,
        high: 1.16097,
        low: 1.15466
      },
      {
        timestamp: 1635886800,
        open: 1.16069,
        close: 1.158,
        high: 1.1614,
        low: 1.15758
      },
      {
        timestamp: 1635973200,
        open: 1.158,
        close: 1.16128,
        high: 1.1617,
        low: 1.15628
      },
      {
        timestamp: 1636059600,
        open: 1.16128,
        close: 1.15556,
        high: 1.16174,
        low: 1.15286
      },
      {
        timestamp: 1636146000,
        open: 1.15556,
        close: 1.1569,
        high: 1.1574,
        low: 1.15137
      },
      {
        timestamp: 1636322400,
        open: 1.1569,
        close: 1.1562,
        high: 1.1562,
        low: 1.15549
      },
      {
        timestamp: 1636408800,
        open: 1.1562,
        close: 1.15893,
        high: 1.15956,
        low: 1.15512
      },
      {
        timestamp: 1636495200,
        open: 1.15893,
        close: 1.15946,
        high: 1.16094,
        low: 1.15703
      },
      {
        timestamp: 1636581600,
        open: 1.15946,
        close: 1.14794,
        high: 1.15977,
        low: 1.14768
      },
      {
        timestamp: 1636668000,
        open: 1.14794,
        close: 1.1451,
        high: 1.14885,
        low: 1.14436
      },
      {
        timestamp: 1636754400,
        open: 1.1451,
        close: 1.14448,
        high: 1.14628,
        low: 1.14335
      },
      {
        timestamp: 1636927200,
        open: 1.14448,
        close: 1.14506,
        high: 1.14595,
        low: 1.14488
      },
      {
        timestamp: 1637013600,
        open: 1.14506,
        close: 1.13683,
        high: 1.14646,
        low: 1.13568
      },
      {
        timestamp: 1637100000,
        open: 1.13683,
        close: 1.1321,
        high: 1.13864,
        low: 1.13094
      },
      {
        timestamp: 1637186400,
        open: 1.1321,
        close: 1.132,
        high: 1.13327,
        low: 1.12644
      },
      {
        timestamp: 1637272800,
        open: 1.132,
        close: 1.13729,
        high: 1.13747,
        low: 1.13143
      },
      {
        timestamp: 1637359200,
        open: 1.13729,
        close: 1.12824,
        high: 1.13744,
        low: 1.12505
      },
      {
        timestamp: 1637532000,
        open: 1.12824,
        close: 1.12942,
        high: 1.12975,
        low: 1.1288
      },
      {
        timestamp: 1637618400,
        open: 1.12942,
        close: 1.12383,
        high: 1.12945,
        low: 1.12312
      },
      {
        timestamp: 1637704800,
        open: 1.12383,
        close: 1.12493,
        high: 1.12756,
        low: 1.12267
      },
      {
        timestamp: 1637791200,
        open: 1.12493,
        close: 1.12029,
        high: 1.12572,
        low: 1.11866
      },
      {
        timestamp: 1637877600,
        open: 1.12029,
        close: 1.12125,
        high: 1.12303,
        low: 1.12012
      },
      {
        timestamp: 1637964000,
        open: 1.12125,
        close: 1.13157,
        high: 1.13336,
        low: 1.12075
      },
      {
        timestamp: 1638136800,
        open: 1.13157,
        close: 1.13044,
        high: 1.1312,
        low: 1.12993
      },
      {
        timestamp: 1638223200,
        open: 1.13044,
        close: 1.12928,
        high: 1.13184,
        low: 1.1259
      },
      {
        timestamp: 1638309600,
        open: 1.12928,
        close: 1.13414,
        high: 1.13835,
        low: 1.12358
      },
      {
        timestamp: 1638396000,
        open: 1.13414,
        close: 1.13208,
        high: 1.13605,
        low: 1.13032
      },
      {
        timestamp: 1638482400,
        open: 1.13208,
        close: 1.13021,
        high: 1.13483,
        low: 1.12958
      },
      {
        timestamp: 1638568800,
        open: 1.13021,
        close: 1.13131,
        high: 1.13346,
        low: 1.12671
      },
      {
        timestamp: 1638741600,
        open: 1.13131,
        close: 1.13115,
        high: 1.13228,
        low: 1.13055
      },
      {
        timestamp: 1638828000,
        open: 1.13115,
        close: 1.12863,
        high: 1.13197,
        low: 1.12668
      },
      {
        timestamp: 1638914400,
        open: 1.12863,
        close: 1.12696,
        high: 1.12991,
        low: 1.12282
      },
      {
        timestamp: 1639000800,
        open: 1.12696,
        close: 1.13433,
        high: 1.13554,
        low: 1.12671
      },
      {
        timestamp: 1639087200,
        open: 1.13433,
        close: 1.12938,
        high: 1.13494,
        low: 1.12789
      },
      {
        timestamp: 1639173600,
        open: 1.12938,
        close: 1.13177,
        high: 1.13247,
        low: 1.12657
      },
      {
        timestamp: 1639346400,
        open: 1.13177,
        close: 1.13191,
        high: 1.13206,
        low: 1.13121
      },
      {
        timestamp: 1639432800,
        open: 1.13191,
        close: 1.12859,
        high: 1.13202,
        low: 1.12607
      },
      {
        timestamp: 1639519200,
        open: 1.12859,
        close: 1.12597,
        high: 1.13244,
        low: 1.12543
      },
      {
        timestamp: 1639605600,
        open: 1.12597,
        close: 1.12957,
        high: 1.12999,
        low: 1.12222
      },
      {
        timestamp: 1639692000,
        open: 1.12957,
        close: 1.13313,
        high: 1.13609,
        low: 1.12818
      },
      {
        timestamp: 1639778400,
        open: 1.13313,
        close: 1.1238,
        high: 1.13495,
        low: 1.12362
      },
      {
        timestamp: 1639951200,
        open: 1.1238,
        close: 1.12451,
        high: 1.12455,
        low: 1.12437
      },
      {
        timestamp: 1640037600,
        open: 1.12451,
        close: 1.12829,
        high: 1.13043,
        low: 1.12352
      },
      {
        timestamp: 1640124000,
        open: 1.12829,
        close: 1.12895,
        high: 1.13033,
        low: 1.12613
      },
      {
        timestamp: 1640210400,
        open: 1.12895,
        close: 1.13281,
        high: 1.1343,
        low: 1.12648
      },
      {
        timestamp: 1640296800,
        open: 1.13281,
        close: 1.13309,
        high: 1.13429,
        low: 1.12906
      },
      {
        timestamp: 1640383200,
        open: 1.13309,
        close: 1.13223,
        high: 1.13443,
        low: 1.13078
      },
      {
        timestamp: 1640642400,
        open: 1.13223,
        close: 1.13305,
        high: 1.13353,
        low: 1.13036
      },
      {
        timestamp: 1640728800,
        open: 1.13305,
        close: 1.13116,
        high: 1.13339,
        low: 1.12902
      },
      {
        timestamp: 1640815200,
        open: 1.13116,
        close: 1.13502,
        high: 1.13697,
        low: 1.12743
      },
      {
        timestamp: 1640901600,
        open: 1.13502,
        close: 1.13287,
        high: 1.13605,
        low: 1.12989
      },
      {
        timestamp: 1640988000,
        open: 1.13287,
        close: 1.13844,
        high: 1.1387,
        low: 1.13039
      },
      {
        timestamp: 1641247200,
        open: 1.13844,
        close: 1.1299,
        high: 1.138,
        low: 1.12802
      },
      {
        timestamp: 1641333600,
        open: 1.1299,
        close: 1.12878,
        high: 1.13231,
        low: 1.12729
      },
      {
        timestamp: 1641420000,
        open: 1.12878,
        close: 1.13166,
        high: 1.13471,
        low: 1.12776
      },
      {
        timestamp: 1641506400,
        open: 1.13166,
        close: 1.13014,
        high: 1.13325,
        low: 1.12852
      },
      {
        timestamp: 1641592800,
        open: 1.13014,
        close: 1.13624,
        high: 1.13654,
        low: 1.12905
      },
      {
        timestamp: 1641765600,
        open: 1.13624,
        close: 1.13574,
        high: 1.13628,
        low: 1.13556
      },
      {
        timestamp: 1641852000,
        open: 1.13574,
        close: 1.13284,
        high: 1.13631,
        low: 1.12854
      },
      {
        timestamp: 1641938400,
        open: 1.13284,
        close: 1.1369,
        high: 1.13759,
        low: 1.13134
      },
      {
        timestamp: 1642024800,
        open: 1.1369,
        close: 1.14444,
        high: 1.14534,
        low: 1.13552
      },
      {
        timestamp: 1642111200,
        open: 1.14444,
        close: 1.14553,
        high: 1.14823,
        low: 1.14363
      },
      {
        timestamp: 1642197600,
        open: 1.14553,
        close: 1.14164,
        high: 1.14833,
        low: 1.13991
      },
      {
        timestamp: 1642370400,
        open: 1.14164,
        close: 1.14206,
        high: 1.1421,
        low: 1.14144
      },
      {
        timestamp: 1642456800,
        open: 1.14206,
        close: 1.14105,
        high: 1.1435,
        low: 1.13923
      },
      {
        timestamp: 1642543200,
        open: 1.14105,
        close: 1.13303,
        high: 1.14221,
        low: 1.13153
      },
      {
        timestamp: 1642629600,
        open: 1.13303,
        close: 1.13437,
        high: 1.13577,
        low: 1.13195
      },
      {
        timestamp: 1642716000,
        open: 1.13437,
        close: 1.13134,
        high: 1.13695,
        low: 1.13037
      },
      {
        timestamp: 1642802400,
        open: 1.13134,
        close: 1.13461,
        high: 1.13604,
        low: 1.13015
      },
      {
        timestamp: 1642975200,
        open: 1.13461,
        close: 1.13485,
        high: 1.13508,
        low: 1.13468
      },
      {
        timestamp: 1643148000,
        open: 1.13288,
        close: 1.13033,
        high: 1.13317,
        low: 1.12638
      },
      {
        timestamp: 1643234400,
        open: 1.13033,
        close: 1.12427,
        high: 1.13113,
        low: 1.12359
      },
      {
        timestamp: 1643320800,
        open: 1.12427,
        close: 1.11467,
        high: 1.12445,
        low: 1.11319
      },
      {
        timestamp: 1643407200,
        open: 1.11467,
        close: 1.11495,
        high: 1.11744,
        low: 1.1122
      },
      {
        timestamp: 1643580000,
        open: 1.11479,
        close: 1.11473,
        high: 1.11493,
        low: 1.11396
      },
      {
        timestamp: 1643666400,
        open: 1.11476,
        close: 1.12394,
        high: 1.12484,
        low: 1.11388
      },
      {
        timestamp: 1643752800,
        open: 1.12392,
        close: 1.12741,
        high: 1.12794,
        low: 1.12214
      },
      {
        timestamp: 1643839200,
        open: 1.12744,
        close: 1.13061,
        high: 1.13318,
        low: 1.12673
      },
      {
        timestamp: 1643925600,
        open: 1.13076,
        close: 1.14436,
        high: 1.1452,
        low: 1.1268
      },
      {
        timestamp: 1644012000,
        open: 1.14438,
        close: 1.14518,
        high: 1.14844,
        low: 1.14122
      },
      {
        timestamp: 1644184800,
        open: 1.14698,
        close: 1.14685,
        high: 1.14731,
        low: 1.14608
      },
      {
        timestamp: 1644271200,
        open: 1.14679,
        close: 1.14437,
        high: 1.14685,
        low: 1.14158
      },
      {
        timestamp: 1644357600,
        open: 1.14443,
        close: 1.14187,
        high: 1.14495,
        low: 1.13969
      },
      {
        timestamp: 1644444000,
        open: 1.14187,
        close: 1.14263,
        high: 1.14484,
        low: 1.14032
      },
      {
        timestamp: 1644530400,
        open: 1.14281,
        close: 1.143,
        high: 1.14958,
        low: 1.13756
      },
      {
        timestamp: 1644616800,
        open: 1.14297,
        close: 1.13542,
        high: 1.14329,
        low: 1.13304
      },
      {
        timestamp: 1644789600,
        open: 1.13507,
        close: 1.13409,
        high: 1.13507,
        low: 1.13354
      },
      {
        timestamp: 1644876000,
        open: 1.13408,
        close: 1.13093,
        high: 1.13698,
        low: 1.12807
      },
      {
        timestamp: 1644962400,
        open: 1.13098,
        close: 1.13605,
        high: 1.13689,
        low: 1.13036
      },
      {
        timestamp: 1645048800,
        open: 1.13605,
        close: 1.13739,
        high: 1.13966,
        low: 1.13457
      },
      {
        timestamp: 1645135200,
        open: 1.13753,
        close: 1.13644,
        high: 1.13869,
        low: 1.13239
      },
      {
        timestamp: 1645221600,
        open: 1.13652,
        close: 1.13283,
        high: 1.13777,
        low: 1.13153
      },
      {
        timestamp: 1645394400,
        open: 1.13179,
        close: 1.13251,
        high: 1.13251,
        low: 1.13118
      },
      {
        timestamp: 1645480800,
        open: 1.13246,
        close: 1.13117,
        high: 1.13913,
        low: 1.13073
      },
      {
        timestamp: 1645567200,
        open: 1.13124,
        close: 1.1326,
        high: 1.13674,
        low: 1.12887
      },
      {
        timestamp: 1645653600,
        open: 1.13307,
        close: 1.13108,
        high: 1.13596,
        low: 1.13018
      },
      {
        timestamp: 1645740000,
        open: 1.13129,
        close: 1.11944,
        high: 1.13146,
        low: 1.11071
      },
      {
        timestamp: 1645826400,
        open: 1.11954,
        close: 1.12731,
        high: 1.12749,
        low: 1.11667
      },
      {
        timestamp: 1645999200,
        open: 1.11597,
        close: 1.11276,
        high: 1.11597,
        low: 1.11236
      },
      {
        timestamp: 1646085600,
        open: 1.11294,
        close: 1.12232,
        high: 1.12473,
        low: 1.11284
      },
      {
        timestamp: 1646172000,
        open: 1.1226,
        close: 1.11292,
        high: 1.12337,
        low: 1.10903
      },
      {
        timestamp: 1646258400,
        open: 1.11304,
        close: 1.11197,
        high: 1.11446,
        low: 1.10581
      },
      {
        timestamp: 1646344800,
        open: 1.11197,
        close: 1.10677,
        high: 1.11263,
        low: 1.10341
      },
      {
        timestamp: 1646431200,
        open: 1.10682,
        close: 1.09352,
        high: 1.10687,
        low: 1.08863
      },
      {
        timestamp: 1646604000,
        open: 1.09446,
        close: 1.09298,
        high: 1.09459,
        low: 1.09194
      },
      {
        timestamp: 1646690400,
        open: 1.0929,
        close: 1.08576,
        high: 1.09334,
        low: 1.08067
      },
      {
        timestamp: 1646776800,
        open: 1.08577,
        close: 1.0904,
        high: 1.09591,
        low: 1.08499
      },
      {
        timestamp: 1646863200,
        open: 1.09019,
        close: 1.10777,
        high: 1.1096,
        low: 1.08906
      },
      {
        timestamp: 1646949600,
        open: 1.10777,
        close: 1.099,
        high: 1.11222,
        low: 1.09769
      },
      {
        timestamp: 1647036000,
        open: 1.09896,
        close: 1.09135,
        high: 1.10443,
        low: 1.09026
      },
      {
        timestamp: 1647208800,
        open: 1.09392,
        close: 1.09247,
        high: 1.0947,
        low: 1.09117
      },
      {
        timestamp: 1647291600,
        open: 1.09257,
        close: 1.09429,
        high: 1.09946,
        low: 1.09013
      },
      {
        timestamp: 1647378000,
        open: 1.09424,
        close: 1.09579,
        high: 1.10207,
        low: 1.09268
      },
      {
        timestamp: 1647464400,
        open: 1.09592,
        close: 1.10378,
        high: 1.10476,
        low: 1.09493
      },
      {
        timestamp: 1647550800,
        open: 1.10397,
        close: 1.10924,
        high: 1.11093,
        low: 1.10084
      },
      {
        timestamp: 1647637200,
        open: 1.10932,
        close: 1.10524,
        high: 1.11194,
        low: 1.10037
      },
      {
        timestamp: 1647810000,
        open: 1.10652,
        close: 1.10637,
        high: 1.10663,
        low: 1.10637
      },
      {
        timestamp: 1647896400,
        open: 1.10633,
        close: 1.10193,
        high: 1.10711,
        low: 1.10108
      },
      {
        timestamp: 1647982800,
        open: 1.10221,
        close: 1.10335,
        high: 1.10464,
        low: 1.09617
      },
      {
        timestamp: 1648069200,
        open: 1.10327,
        close: 1.10058,
        high: 1.10447,
        low: 1.09648
      },
      {
        timestamp: 1648155600,
        open: 1.10066,
        close: 1.09983,
        high: 1.10147,
        low: 1.09661
      },
      {
        timestamp: 1648242000,
        open: 1.09983,
        close: 1.09854,
        high: 1.10385,
        low: 1.09817
      },
      {
        timestamp: 1648414800,
        open: 1.09929,
        close: 1.09888,
        high: 1.09964,
        low: 1.09883
      },
      {
        timestamp: 1648501200,
        open: 1.09901,
        close: 1.09872,
        high: 1.10002,
        low: 1.09452
      },
      {
        timestamp: 1648587600,
        open: 1.09882,
        close: 1.10868,
        high: 1.11379,
        low: 1.09695
      },
      {
        timestamp: 1648674000,
        open: 1.10886,
        close: 1.11611,
        high: 1.11719,
        low: 1.1083
      },
      {
        timestamp: 1648760400,
        open: 1.11609,
        close: 1.10689,
        high: 1.11855,
        low: 1.10616
      },
      {
        timestamp: 1648846800,
        open: 1.10694,
        close: 1.10494,
        high: 1.1077,
        low: 1.10287
      },
      {
        timestamp: 1649019600,
        open: 1.1044,
        close: 1.10387,
        high: 1.10448,
        low: 1.10349
      },
      {
        timestamp: 1649106000,
        open: 1.10387,
        close: 1.09748,
        high: 1.10552,
        low: 1.09611
      },
      {
        timestamp: 1649192400,
        open: 1.09764,
        close: 1.09068,
        high: 1.09896,
        low: 1.09006
      },
      {
        timestamp: 1649278800,
        open: 1.09075,
        close: 1.09006,
        high: 1.09388,
        low: 1.0875
      },
      {
        timestamp: 1649365200,
        open: 1.08977,
        close: 1.08814,
        high: 1.09393,
        low: 1.08652
      },
      {
        timestamp: 1649451600,
        open: 1.08817,
        close: 1.08778,
        high: 1.08924,
        low: 1.08371
      },
      {
        timestamp: 1649624400,
        open: 1.09272,
        close: 1.09262,
        high: 1.09566,
        low: 1.09179
      },
      {
        timestamp: 1649710800,
        open: 1.09248,
        close: 1.08844,
        high: 1.09339,
        low: 1.08734
      },
      {
        timestamp: 1649797200,
        open: 1.08847,
        close: 1.08357,
        high: 1.09044,
        low: 1.08219
      },
      {
        timestamp: 1649883600,
        open: 1.08315,
        close: 1.08926,
        high: 1.08951,
        low: 1.08096
      },
      {
        timestamp: 1649970000,
        open: 1.08918,
        close: 1.08309,
        high: 1.09241,
        low: 1.07581
      },
      {
        timestamp: 1650056400,
        open: 1.08317,
        close: 1.08176,
        high: 1.08319,
        low: 1.07973
      },
      {
        timestamp: 1650229200,
        open: 1.08122,
        close: 1.08093,
        high: 1.08122,
        low: 1.08018
      },
      {
        timestamp: 1650315600,
        open: 1.08096,
        close: 1.07853,
        high: 1.08224,
        low: 1.07706
      },
      {
        timestamp: 1650402000,
        open: 1.07857,
        close: 1.07883,
        high: 1.08153,
        low: 1.07617
      },
      {
        timestamp: 1650488400,
        open: 1.07883,
        close: 1.08552,
        high: 1.08677,
        low: 1.07848
      },
      {
        timestamp: 1650574800,
        open: 1.08559,
        close: 1.08377,
        high: 1.09371,
        low: 1.08243
      },
      {
        timestamp: 1650661200,
        open: 1.08427,
        close: 1.08007,
        high: 1.08523,
        low: 1.07734
      },
      {
        timestamp: 1650834000,
        open: 1.08532,
        close: 1.0809,
        high: 1.08557,
        low: 1.08088
      },
      {
        timestamp: 1650920400,
        open: 1.08167,
        close: 1.07177,
        high: 1.08173,
        low: 1.06975
      },
      {
        timestamp: 1651006800,
        open: 1.07155,
        close: 1.06392,
        high: 1.07394,
        low: 1.06365
      },
      {
        timestamp: 1651093200,
        open: 1.06387,
        close: 1.056,
        high: 1.06553,
        low: 1.05233
      },
      {
        timestamp: 1651179600,
        open: 1.056,
        close: 1.05002,
        high: 1.05671,
        low: 1.04719
      },
      {
        timestamp: 1651266000,
        open: 1.05001,
        close: 1.05502,
        high: 1.05936,
        low: 1.04922
      },
      {
        timestamp: 1651525200,
        open: 1.05653,
        close: 1.05117,
        high: 1.05694,
        low: 1.04909
      },
      {
        timestamp: 1651611600,
        open: 1.0511,
        close: 1.05245,
        high: 1.05785,
        low: 1.04929
      },
      {
        timestamp: 1651698000,
        open: 1.05252,
        close: 1.06266,
        high: 1.063,
        low: 1.05067
      },
      {
        timestamp: 1651784400,
        open: 1.06271,
        close: 1.05464,
        high: 1.06424,
        low: 1.04932
      },
      {
        timestamp: 1651870800,
        open: 1.05465,
        close: 1.05481,
        high: 1.05994,
        low: 1.04836
      },
      {
        timestamp: 1652043600,
        open: 1.05655,
        close: 1.05503,
        high: 1.05685,
        low: 1.05407
      },
      {
        timestamp: 1652130000,
        open: 1.05485,
        close: 1.05655,
        high: 1.0593,
        low: 1.04958
      },
      {
        timestamp: 1652216400,
        open: 1.05655,
        close: 1.05314,
        high: 1.05861,
        low: 1.05262
      },
      {
        timestamp: 1652302800,
        open: 1.05314,
        close: 1.0563,
        high: 1.0563,
        low: 1.05263
      }
    ]
  },	
  {
		id: 3,
		label: "EUR/USD - Excel",
		value: "EUR/USD - Excel",
		symbol: "EUR/USD",
		period: "24h",
		from: "2012/05/24",
		to: "2019/06/22",
    tradingInstrumentId: 1,
		timestepInSeconds: 24 * 60 * 60,
		data: [
      {
        timestamp: 1337979600,
        open: 1.25341,
        close: 1.2518,
        high: 1.26037,
        low: 1.24971
      },
      {
        timestamp: 1338238800,
        open: 1.2518,
        close: 1.25422,
        high: 1.26252,
        low: 1.2518
      },
      {
        timestamp: 1338325200,
        open: 1.25422,
        close: 1.25038,
        high: 1.25755,
        low: 1.24621
      },
      {
        timestamp: 1338411600,
        open: 1.25038,
        close: 1.23675,
        high: 1.25068,
        low: 1.23624
      },
      {
        timestamp: 1338498000,
        open: 1.23675,
        close: 1.23667,
        high: 1.24292,
        low: 1.23373
      },
      {
        timestamp: 1338584400,
        open: 1.23667,
        close: 1.24361,
        high: 1.24599,
        low: 1.22889
      },
      {
        timestamp: 1338843600,
        open: 1.24361,
        close: 1.25006,
        high: 1.25107,
        low: 1.23867
      },
      {
        timestamp: 1338930000,
        open: 1.25006,
        close: 1.24531,
        high: 1.25436,
        low: 1.2411
      },
      {
        timestamp: 1339016400,
        open: 1.24531,
        close: 1.25827,
        high: 1.25866,
        low: 1.24419
      },
      {
        timestamp: 1339102800,
        open: 1.25827,
        close: 1.2561,
        high: 1.26261,
        low: 1.25408
      },
      {
        timestamp: 1339189200,
        open: 1.2561,
        close: 1.25198,
        high: 1.25757,
        low: 1.24363
      },
      {
        timestamp: 1339448400,
        open: 1.25198,
        close: 1.24834,
        high: 1.26698,
        low: 1.24814
      },
      {
        timestamp: 1339534800,
        open: 1.24834,
        close: 1.25043,
        high: 1.25304,
        low: 1.24435
      },
      {
        timestamp: 1339621200,
        open: 1.25043,
        close: 1.25576,
        high: 1.26113,
        low: 1.24745
      },
      {
        timestamp: 1339707600,
        open: 1.25576,
        close: 1.26341,
        high: 1.26377,
        low: 1.25429
      },
      {
        timestamp: 1339794000,
        open: 1.26341,
        close: 1.26441,
        high: 1.26662,
        low: 1.2593
      },
      {
        timestamp: 1340053200,
        open: 1.26441,
        close: 1.25782,
        high: 1.27487,
        low: 1.25581
      },
      {
        timestamp: 1340139600,
        open: 1.25782,
        close: 1.26864,
        high: 1.27314,
        low: 1.25696
      },
      {
        timestamp: 1340226000,
        open: 1.26864,
        close: 1.27091,
        high: 1.27444,
        low: 1.26386
      },
      {
        timestamp: 1340312400,
        open: 1.27091,
        close: 1.25413,
        high: 1.27096,
        low: 1.25325
      },
      {
        timestamp: 1340398800,
        open: 1.25413,
        close: 1.25737,
        high: 1.25843,
        low: 1.25203
      },
      {
        timestamp: 1340658000,
        open: 1.25737,
        close: 1.25052,
        high: 1.25737,
        low: 1.24722
      },
      {
        timestamp: 1340744400,
        open: 1.25052,
        close: 1.24995,
        high: 1.25315,
        low: 1.24429
      },
      {
        timestamp: 1340830800,
        open: 1.24995,
        close: 1.24692,
        high: 1.25091,
        low: 1.24462
      },
      {
        timestamp: 1340917200,
        open: 1.24692,
        close: 1.24446,
        high: 1.25254,
        low: 1.24081
      },
      {
        timestamp: 1341003600,
        open: 1.24446,
        close: 1.26649,
        high: 1.26937,
        low: 1.24339
      },
      {
        timestamp: 1341262800,
        open: 1.26649,
        close: 1.25791,
        high: 1.26883,
        low: 1.25691
      },
      {
        timestamp: 1341349200,
        open: 1.25791,
        close: 1.26086,
        high: 1.26283,
        low: 1.25603
      },
      {
        timestamp: 1341435600,
        open: 1.26086,
        close: 1.25283,
        high: 1.26099,
        low: 1.25089
      },
      {
        timestamp: 1341522000,
        open: 1.25283,
        close: 1.23915,
        high: 1.25395,
        low: 1.23648
      },
      {
        timestamp: 1341608400,
        open: 1.23915,
        close: 1.229,
        high: 1.24025,
        low: 1.22611
      },
      {
        timestamp: 1341867600,
        open: 1.229,
        close: 1.23136,
        high: 1.23254,
        low: 1.22569
      },
      {
        timestamp: 1341954000,
        open: 1.23136,
        close: 1.22511,
        high: 1.23346,
        low: 1.22363
      },
      {
        timestamp: 1342040400,
        open: 1.22511,
        close: 1.22407,
        high: 1.22977,
        low: 1.22137
      },
      {
        timestamp: 1342126800,
        open: 1.22407,
        close: 1.22043,
        high: 1.22498,
        low: 1.21676
      },
      {
        timestamp: 1342213200,
        open: 1.22043,
        close: 1.22523,
        high: 1.22576,
        low: 1.21632
      },
      {
        timestamp: 1342472400,
        open: 1.22523,
        close: 1.22738,
        high: 1.2291,
        low: 1.21799
      },
      {
        timestamp: 1342558800,
        open: 1.22738,
        close: 1.22951,
        high: 1.23179,
        low: 1.21827
      },
      {
        timestamp: 1342645200,
        open: 1.22951,
        close: 1.22845,
        high: 1.23073,
        low: 1.22179
      },
      {
        timestamp: 1342731600,
        open: 1.22845,
        close: 1.22825,
        high: 1.23251,
        low: 1.223
      },
      {
        timestamp: 1342818000,
        open: 1.22825,
        close: 1.21628,
        high: 1.22881,
        low: 1.21452
      },
      {
        timestamp: 1343077200,
        open: 1.21628,
        close: 1.21178,
        high: 1.21628,
        low: 1.20681
      },
      {
        timestamp: 1343163600,
        open: 1.21178,
        close: 1.20638,
        high: 1.2139,
        low: 1.2043
      },
      {
        timestamp: 1343250000,
        open: 1.20638,
        close: 1.21578,
        high: 1.21714,
        low: 1.20544
      },
      {
        timestamp: 1343336400,
        open: 1.21578,
        close: 1.22844,
        high: 1.23302,
        low: 1.21182
      },
      {
        timestamp: 1343422800,
        open: 1.22844,
        close: 1.23259,
        high: 1.23907,
        low: 1.22424
      },
      {
        timestamp: 1343682000,
        open: 1.23259,
        close: 1.2261,
        high: 1.23259,
        low: 1.2226
      },
      {
        timestamp: 1343768400,
        open: 1.2261,
        close: 1.23053,
        high: 1.23307,
        low: 1.22497
      },
      {
        timestamp: 1343854800,
        open: 1.23053,
        close: 1.22258,
        high: 1.23374,
        low: 1.22189
      },
      {
        timestamp: 1343941200,
        open: 1.22258,
        close: 1.2181,
        high: 1.2406,
        low: 1.21343
      },
      {
        timestamp: 1344027600,
        open: 1.2181,
        close: 1.2391,
        high: 1.23931,
        low: 1.21677
      },
      {
        timestamp: 1344286800,
        open: 1.2391,
        close: 1.24018,
        high: 1.24445,
        low: 1.23425
      },
      {
        timestamp: 1344373200,
        open: 1.24018,
        close: 1.24022,
        high: 1.24428,
        low: 1.23767
      },
      {
        timestamp: 1344459600,
        open: 1.24022,
        close: 1.2367,
        high: 1.24067,
        low: 1.23276
      },
      {
        timestamp: 1344546000,
        open: 1.2367,
        close: 1.23079,
        high: 1.2388,
        low: 1.22676
      },
      {
        timestamp: 1344632400,
        open: 1.23079,
        close: 1.2291,
        high: 1.23175,
        low: 1.22423
      },
      {
        timestamp: 1344891600,
        open: 1.2291,
        close: 1.23354,
        high: 1.23744,
        low: 1.2262
      },
      {
        timestamp: 1344978000,
        open: 1.23354,
        close: 1.23259,
        high: 1.23864,
        low: 1.2318
      },
      {
        timestamp: 1345064400,
        open: 1.23259,
        close: 1.22937,
        high: 1.23445,
        low: 1.22651
      },
      {
        timestamp: 1345150800,
        open: 1.22937,
        close: 1.23594,
        high: 1.23736,
        low: 1.22568
      },
      {
        timestamp: 1345237200,
        open: 1.23594,
        close: 1.23361,
        high: 1.23831,
        low: 1.22895
      },
      {
        timestamp: 1345496400,
        open: 1.23361,
        close: 1.23488,
        high: 1.23697,
        low: 1.22963
      },
      {
        timestamp: 1345582800,
        open: 1.23488,
        close: 1.24745,
        high: 1.24884,
        low: 1.23438
      },
      {
        timestamp: 1345669200,
        open: 1.24745,
        close: 1.25323,
        high: 1.25391,
        low: 1.24321
      },
      {
        timestamp: 1345755600,
        open: 1.25323,
        close: 1.25683,
        high: 1.25903,
        low: 1.25246
      },
      {
        timestamp: 1345842000,
        open: 1.25683,
        close: 1.2514,
        high: 1.25698,
        low: 1.24823
      },
      {
        timestamp: 1346101200,
        open: 1.2514,
        close: 1.25007,
        high: 1.25366,
        low: 1.24908
      },
      {
        timestamp: 1346187600,
        open: 1.25007,
        close: 1.25663,
        high: 1.25773,
        low: 1.24664
      },
      {
        timestamp: 1346274000,
        open: 1.25663,
        close: 1.25326,
        high: 1.25742,
        low: 1.25194
      },
      {
        timestamp: 1346360400,
        open: 1.25326,
        close: 1.2509,
        high: 1.25646,
        low: 1.24881
      },
      {
        timestamp: 1346446800,
        open: 1.2509,
        close: 1.258,
        high: 1.26383,
        low: 1.24945
      },
      {
        timestamp: 1346706000,
        open: 1.258,
        close: 1.25973,
        high: 1.26122,
        low: 1.2561
      },
      {
        timestamp: 1346792400,
        open: 1.25973,
        close: 1.25687,
        high: 1.26285,
        low: 1.25557
      },
      {
        timestamp: 1346878800,
        open: 1.25687,
        close: 1.26029,
        high: 1.26252,
        low: 1.25026
      },
      {
        timestamp: 1346965200,
        open: 1.26029,
        close: 1.26329,
        high: 1.26527,
        low: 1.25622
      },
      {
        timestamp: 1347051600,
        open: 1.26329,
        close: 1.2819,
        high: 1.28193,
        low: 1.26273
      },
      {
        timestamp: 1347310800,
        open: 1.2819,
        close: 1.27596,
        high: 1.2819,
        low: 1.27565
      },
      {
        timestamp: 1347397200,
        open: 1.27596,
        close: 1.28555,
        high: 1.28722,
        low: 1.27556
      },
      {
        timestamp: 1347483600,
        open: 1.28555,
        close: 1.29007,
        high: 1.29374,
        low: 1.2816
      },
      {
        timestamp: 1347570000,
        open: 1.29007,
        close: 1.29894,
        high: 1.30026,
        low: 1.28587
      },
      {
        timestamp: 1347656400,
        open: 1.29894,
        close: 1.31319,
        high: 1.31698,
        low: 1.2981
      },
      {
        timestamp: 1347915600,
        open: 1.31319,
        close: 1.31172,
        high: 1.31729,
        low: 1.30842
      },
      {
        timestamp: 1348002000,
        open: 1.31172,
        close: 1.30487,
        high: 1.31238,
        low: 1.30303
      },
      {
        timestamp: 1348088400,
        open: 1.30487,
        close: 1.30493,
        high: 1.30861,
        low: 1.29941
      },
      {
        timestamp: 1348174800,
        open: 1.30493,
        close: 1.29689,
        high: 1.30599,
        low: 1.29202
      },
      {
        timestamp: 1348261200,
        open: 1.29689,
        close: 1.29809,
        high: 1.30488,
        low: 1.29563
      },
      {
        timestamp: 1348520400,
        open: 1.29809,
        close: 1.29308,
        high: 1.29923,
        low: 1.28917
      },
      {
        timestamp: 1348606800,
        open: 1.29308,
        close: 1.29027,
        high: 1.29716,
        low: 1.28871
      },
      {
        timestamp: 1348693200,
        open: 1.29027,
        close: 1.28715,
        high: 1.29137,
        low: 1.28361
      },
      {
        timestamp: 1348779600,
        open: 1.28715,
        close: 1.29129,
        high: 1.29291,
        low: 1.28295
      },
      {
        timestamp: 1348866000,
        open: 1.29129,
        close: 1.2858,
        high: 1.29603,
        low: 1.28393
      },
      {
        timestamp: 1349125200,
        open: 1.2858,
        close: 1.28887,
        high: 1.29394,
        low: 1.28047
      },
      {
        timestamp: 1349211600,
        open: 1.28887,
        close: 1.29216,
        high: 1.29687,
        low: 1.28812
      },
      {
        timestamp: 1349298000,
        open: 1.29216,
        close: 1.29068,
        high: 1.2938,
        low: 1.28779
      },
      {
        timestamp: 1349384400,
        open: 1.29068,
        close: 1.30194,
        high: 1.30324,
        low: 1.29033
      },
      {
        timestamp: 1349470800,
        open: 1.30194,
        close: 1.30331,
        high: 1.30723,
        low: 1.29949
      },
      {
        timestamp: 1349730000,
        open: 1.30331,
        close: 1.29696,
        high: 1.30331,
        low: 1.29382
      },
      {
        timestamp: 1349816400,
        open: 1.29696,
        close: 1.28848,
        high: 1.29914,
        low: 1.28594
      },
      {
        timestamp: 1349902800,
        open: 1.28848,
        close: 1.28755,
        high: 1.29139,
        low: 1.28363
      },
      {
        timestamp: 1349989200,
        open: 1.28755,
        close: 1.29273,
        high: 1.29524,
        low: 1.28264
      },
      {
        timestamp: 1350075600,
        open: 1.29273,
        close: 1.29556,
        high: 1.29927,
        low: 1.29233
      },
      {
        timestamp: 1350334800,
        open: 1.29556,
        close: 1.29503,
        high: 1.29795,
        low: 1.28917
      },
      {
        timestamp: 1350421200,
        open: 1.29503,
        close: 1.30555,
        high: 1.30616,
        low: 1.29437
      },
      {
        timestamp: 1350507600,
        open: 1.30555,
        close: 1.31204,
        high: 1.31403,
        low: 1.3054
      },
      {
        timestamp: 1350594000,
        open: 1.31204,
        close: 1.3068,
        high: 1.31294,
        low: 1.30565
      },
      {
        timestamp: 1350680400,
        open: 1.3068,
        close: 1.30237,
        high: 1.3078,
        low: 1.30139
      },
      {
        timestamp: 1350939600,
        open: 1.30237,
        close: 1.30591,
        high: 1.30841,
        low: 1.30166
      },
      {
        timestamp: 1351026000,
        open: 1.30591,
        close: 1.29862,
        high: 1.30762,
        low: 1.29528
      },
      {
        timestamp: 1351112400,
        open: 1.29862,
        close: 1.29746,
        high: 1.29976,
        low: 1.29212
      },
      {
        timestamp: 1351198800,
        open: 1.29746,
        close: 1.29334,
        high: 1.30238,
        low: 1.29279
      },
      {
        timestamp: 1351285200,
        open: 1.29334,
        close: 1.29423,
        high: 1.29572,
        low: 1.28834
      },
      {
        timestamp: 1351544400,
        open: 1.29423,
        close: 1.29046,
        high: 1.2948,
        low: 1.2886
      },
      {
        timestamp: 1351630800,
        open: 1.29046,
        close: 1.296,
        high: 1.29843,
        low: 1.28868
      },
      {
        timestamp: 1351717200,
        open: 1.296,
        close: 1.296,
        high: 1.30211,
        low: 1.29472
      },
      {
        timestamp: 1351803600,
        open: 1.296,
        close: 1.29433,
        high: 1.29834,
        low: 1.29254
      },
      {
        timestamp: 1351890000,
        open: 1.29433,
        close: 1.28393,
        high: 1.29524,
        low: 1.28221
      },
      {
        timestamp: 1352152800,
        open: 1.28393,
        close: 1.27964,
        high: 1.28433,
        low: 1.27683
      },
      {
        timestamp: 1352239200,
        open: 1.27964,
        close: 1.2815,
        high: 1.28273,
        low: 1.27643
      },
      {
        timestamp: 1352325600,
        open: 1.2815,
        close: 1.27733,
        high: 1.28771,
        low: 1.27371
      },
      {
        timestamp: 1352412000,
        open: 1.27733,
        close: 1.2749,
        high: 1.2782,
        low: 1.27179
      },
      {
        timestamp: 1352498400,
        open: 1.2749,
        close: 1.27164,
        high: 1.27908,
        low: 1.26908
      },
      {
        timestamp: 1352757600,
        open: 1.27164,
        close: 1.27095,
        high: 1.27397,
        low: 1.26983
      },
      {
        timestamp: 1352844000,
        open: 1.27095,
        close: 1.27054,
        high: 1.27293,
        low: 1.26624
      },
      {
        timestamp: 1352930400,
        open: 1.27054,
        close: 1.27359,
        high: 1.27794,
        low: 1.27011
      },
      {
        timestamp: 1353016800,
        open: 1.27359,
        close: 1.27822,
        high: 1.28029,
        low: 1.27185
      },
      {
        timestamp: 1353103200,
        open: 1.27822,
        close: 1.27439,
        high: 1.27856,
        low: 1.26913
      },
      {
        timestamp: 1353362400,
        open: 1.27439,
        close: 1.28144,
        high: 1.28207,
        low: 1.27412
      },
      {
        timestamp: 1353448800,
        open: 1.28144,
        close: 1.28187,
        high: 1.28306,
        low: 1.27657
      },
      {
        timestamp: 1353535200,
        open: 1.28187,
        close: 1.28306,
        high: 1.28342,
        low: 1.27372
      },
      {
        timestamp: 1353621600,
        open: 1.28306,
        close: 1.28856,
        high: 1.28996,
        low: 1.28276
      },
      {
        timestamp: 1353708000,
        open: 1.28856,
        close: 1.2979,
        high: 1.29916,
        low: 1.28692
      },
      {
        timestamp: 1353967200,
        open: 1.2979,
        close: 1.2973,
        high: 1.29853,
        low: 1.29451
      },
      {
        timestamp: 1354053600,
        open: 1.2973,
        close: 1.2944,
        high: 1.30094,
        low: 1.29162
      },
      {
        timestamp: 1354140000,
        open: 1.2944,
        close: 1.29557,
        high: 1.29563,
        low: 1.28809
      },
      {
        timestamp: 1354226400,
        open: 1.29557,
        close: 1.29793,
        high: 1.3015,
        low: 1.29397
      },
      {
        timestamp: 1354312800,
        open: 1.29793,
        close: 1.29876,
        high: 1.30283,
        low: 1.29689
      },
      {
        timestamp: 1354572000,
        open: 1.29876,
        close: 1.30536,
        high: 1.30765,
        low: 1.29855
      },
      {
        timestamp: 1354658400,
        open: 1.30536,
        close: 1.30968,
        high: 1.31092,
        low: 1.30466
      },
      {
        timestamp: 1354744800,
        open: 1.30968,
        close: 1.30671,
        high: 1.31273,
        low: 1.3061
      },
      {
        timestamp: 1354831200,
        open: 1.30671,
        close: 1.29696,
        high: 1.30873,
        low: 1.29512
      },
      {
        timestamp: 1354917600,
        open: 1.29696,
        close: 1.29287,
        high: 1.29735,
        low: 1.28771
      },
      {
        timestamp: 1355176800,
        open: 1.29287,
        close: 1.29416,
        high: 1.29473,
        low: 1.28868
      },
      {
        timestamp: 1355263200,
        open: 1.29416,
        close: 1.30072,
        high: 1.30156,
        low: 1.29295
      },
      {
        timestamp: 1355349600,
        open: 1.30072,
        close: 1.3075,
        high: 1.30982,
        low: 1.29965
      },
      {
        timestamp: 1355436000,
        open: 1.3075,
        close: 1.30785,
        high: 1.31012,
        low: 1.30416
      },
      {
        timestamp: 1355522400,
        open: 1.30785,
        close: 1.3163,
        high: 1.31739,
        low: 1.30671
      },
      {
        timestamp: 1355781600,
        open: 1.3163,
        close: 1.3164,
        high: 1.31978,
        low: 1.31449
      },
      {
        timestamp: 1355868000,
        open: 1.3164,
        close: 1.32294,
        high: 1.32391,
        low: 1.31566
      },
      {
        timestamp: 1355954400,
        open: 1.32294,
        close: 1.32283,
        high: 1.33091,
        low: 1.32239
      },
      {
        timestamp: 1356040800,
        open: 1.32283,
        close: 1.32455,
        high: 1.32958,
        low: 1.31896
      },
      {
        timestamp: 1356127200,
        open: 1.32455,
        close: 1.31854,
        high: 1.32535,
        low: 1.31594
      },
      {
        timestamp: 1356386400,
        open: 1.31854,
        close: 1.31776,
        high: 1.32346,
        low: 1.31723
      },
      {
        timestamp: 1356559200,
        open: 1.31776,
        close: 1.3225,
        high: 1.3255,
        low: 1.31743
      },
      {
        timestamp: 1356645600,
        open: 1.3225,
        close: 1.32379,
        high: 1.32845,
        low: 1.32022
      },
      {
        timestamp: 1356732000,
        open: 1.32379,
        close: 1.322,
        high: 1.32575,
        low: 1.31668
      },
      {
        timestamp: 1356991200,
        open: 1.322,
        close: 1.31859,
        high: 1.3236,
        low: 1.31729
      },
      {
        timestamp: 1357164000,
        open: 1.31859,
        close: 1.31866,
        high: 1.32946,
        low: 1.31577
      },
      {
        timestamp: 1357250400,
        open: 1.31866,
        close: 1.30507,
        high: 1.31916,
        low: 1.30477
      },
      {
        timestamp: 1357336800,
        open: 1.30507,
        close: 1.30703,
        high: 1.30904,
        low: 1.29984
      },
      {
        timestamp: 1357596000,
        open: 1.30703,
        close: 1.31172,
        high: 1.31202,
        low: 1.30174
      },
      {
        timestamp: 1357682400,
        open: 1.31172,
        close: 1.30821,
        high: 1.31406,
        low: 1.30571
      },
      {
        timestamp: 1357768800,
        open: 1.30821,
        close: 1.30645,
        high: 1.30968,
        low: 1.30374
      },
      {
        timestamp: 1357855200,
        open: 1.30645,
        close: 1.32796,
        high: 1.328,
        low: 1.30397
      },
      {
        timestamp: 1357941600,
        open: 1.32796,
        close: 1.33443,
        high: 1.33666,
        low: 1.32492
      },
      {
        timestamp: 1358200800,
        open: 1.33443,
        close: 1.33838,
        high: 1.34049,
        low: 1.3337
      },
      {
        timestamp: 1358287200,
        open: 1.33838,
        close: 1.33065,
        high: 1.33943,
        low: 1.3264
      },
      {
        timestamp: 1358373600,
        open: 1.33065,
        close: 1.32892,
        high: 1.33255,
        low: 1.32569
      },
      {
        timestamp: 1358460000,
        open: 1.32892,
        close: 1.3377,
        high: 1.33878,
        low: 1.32705
      },
      {
        timestamp: 1358546400,
        open: 1.3377,
        close: 1.332,
        high: 1.33991,
        low: 1.32813
      },
      {
        timestamp: 1358805600,
        open: 1.332,
        close: 1.3314,
        high: 1.33331,
        low: 1.33008
      },
      {
        timestamp: 1358892000,
        open: 1.3314,
        close: 1.33234,
        high: 1.33724,
        low: 1.32675
      },
      {
        timestamp: 1358978400,
        open: 1.33234,
        close: 1.33189,
        high: 1.33553,
        low: 1.32652
      },
      {
        timestamp: 1359064800,
        open: 1.33189,
        close: 1.33775,
        high: 1.33934,
        low: 1.32868
      },
      {
        timestamp: 1359151200,
        open: 1.33775,
        close: 1.34568,
        high: 1.348,
        low: 1.33504
      },
      {
        timestamp: 1359410400,
        open: 1.34568,
        close: 1.3456,
        high: 1.34779,
        low: 1.34255
      },
      {
        timestamp: 1359496800,
        open: 1.3456,
        close: 1.34926,
        high: 1.34979,
        low: 1.34151
      },
      {
        timestamp: 1359583200,
        open: 1.34926,
        close: 1.35678,
        high: 1.35886,
        low: 1.34829
      },
      {
        timestamp: 1359669600,
        open: 1.35678,
        close: 1.35785,
        high: 1.35951,
        low: 1.3542
      },
      {
        timestamp: 1359756000,
        open: 1.35785,
        close: 1.36433,
        high: 1.37117,
        low: 1.35745
      },
      {
        timestamp: 1359928800,
        open: 1.36433,
        close: 1.36544,
        high: 1.36551,
        low: 1.3634
      },
      {
        timestamp: 1360015200,
        open: 1.36544,
        close: 1.3516,
        high: 1.36611,
        low: 1.35058
      },
      {
        timestamp: 1360101600,
        open: 1.3516,
        close: 1.3584,
        high: 1.35981,
        low: 1.34592
      },
      {
        timestamp: 1360188000,
        open: 1.3584,
        close: 1.35229,
        high: 1.35967,
        low: 1.34943
      },
      {
        timestamp: 1360274400,
        open: 1.35229,
        close: 1.33992,
        high: 1.35781,
        low: 1.33715
      },
      {
        timestamp: 1360360800,
        open: 1.33992,
        close: 1.33642,
        high: 1.34301,
        low: 1.33541
      },
      {
        timestamp: 1360533600,
        open: 1.33642,
        close: 1.33789,
        high: 1.33841,
        low: 1.33564
      },
      {
        timestamp: 1360620000,
        open: 1.33789,
        close: 1.34048,
        high: 1.34283,
        low: 1.3358
      },
      {
        timestamp: 1360706400,
        open: 1.34048,
        close: 1.34541,
        high: 1.34763,
        low: 1.33647
      },
      {
        timestamp: 1360792800,
        open: 1.34541,
        close: 1.34518,
        high: 1.35207,
        low: 1.34271
      },
      {
        timestamp: 1360879200,
        open: 1.34518,
        close: 1.33653,
        high: 1.34567,
        low: 1.33161
      },
      {
        timestamp: 1360965600,
        open: 1.33653,
        close: 1.33678,
        high: 1.33942,
        low: 1.33072
      },
      {
        timestamp: 1361138400,
        open: 1.33678,
        close: 1.33501,
        high: 1.33678,
        low: 1.33476
      },
      {
        timestamp: 1361224800,
        open: 1.33501,
        close: 1.33524,
        high: 1.33803,
        low: 1.33223
      },
      {
        timestamp: 1361311200,
        open: 1.33524,
        close: 1.3389,
        high: 1.33967,
        low: 1.33298
      },
      {
        timestamp: 1361397600,
        open: 1.3389,
        close: 1.32823,
        high: 1.34349,
        low: 1.32716
      },
      {
        timestamp: 1361484000,
        open: 1.32823,
        close: 1.31898,
        high: 1.32924,
        low: 1.31615
      },
      {
        timestamp: 1361570400,
        open: 1.31898,
        close: 1.31903,
        high: 1.32469,
        low: 1.31458
      },
      {
        timestamp: 1361743200,
        open: 1.31903,
        close: 1.3217,
        high: 1.32396,
        low: 1.31903
      },
      {
        timestamp: 1361829600,
        open: 1.3217,
        close: 1.30664,
        high: 1.33196,
        low: 1.30487
      },
      {
        timestamp: 1361916000,
        open: 1.30664,
        close: 1.30641,
        high: 1.31239,
        low: 1.30191
      },
      {
        timestamp: 1362002400,
        open: 1.30641,
        close: 1.31369,
        high: 1.31478,
        low: 1.30422
      },
      {
        timestamp: 1362088800,
        open: 1.31369,
        close: 1.30584,
        high: 1.31627,
        low: 1.3054
      },
      {
        timestamp: 1362175200,
        open: 1.30584,
        close: 1.30218,
        high: 1.31018,
        low: 1.29673
      },
      {
        timestamp: 1362348000,
        open: 1.30218,
        close: 1.30138,
        high: 1.30218,
        low: 1.30087
      },
      {
        timestamp: 1362434400,
        open: 1.30138,
        close: 1.3027,
        high: 1.3032,
        low: 1.29831
      },
      {
        timestamp: 1362520800,
        open: 1.3027,
        close: 1.30518,
        high: 1.30761,
        low: 1.30111
      },
      {
        timestamp: 1362607200,
        open: 1.30518,
        close: 1.29705,
        high: 1.30712,
        low: 1.29673
      },
      {
        timestamp: 1362693600,
        open: 1.29705,
        close: 1.31093,
        high: 1.31191,
        low: 1.29682
      },
      {
        timestamp: 1362780000,
        open: 1.31093,
        close: 1.30033,
        high: 1.31352,
        low: 1.29562
      },
      {
        timestamp: 1362949200,
        open: 1.30033,
        close: 1.29879,
        high: 1.30048,
        low: 1.29878
      },
      {
        timestamp: 1363035600,
        open: 1.29879,
        close: 1.30469,
        high: 1.30545,
        low: 1.29802
      },
      {
        timestamp: 1363122000,
        open: 1.30469,
        close: 1.30344,
        high: 1.30754,
        low: 1.29919
      },
      {
        timestamp: 1363208400,
        open: 1.30344,
        close: 1.29625,
        high: 1.30655,
        low: 1.29241
      },
      {
        timestamp: 1363294800,
        open: 1.29625,
        close: 1.30057,
        high: 1.30333,
        low: 1.29123
      },
      {
        timestamp: 1363381200,
        open: 1.30057,
        close: 1.30767,
        high: 1.31079,
        low: 1.30003
      },
      {
        timestamp: 1363554000,
        open: 1.30767,
        close: 1.29086,
        high: 1.30767,
        low: 1.29078
      },
      {
        timestamp: 1363640400,
        open: 1.29086,
        close: 1.29578,
        high: 1.29966,
        low: 1.28828
      },
      {
        timestamp: 1363726800,
        open: 1.29578,
        close: 1.28835,
        high: 1.29703,
        low: 1.28445
      },
      {
        timestamp: 1363813200,
        open: 1.28835,
        close: 1.29358,
        high: 1.29796,
        low: 1.28574
      },
      {
        timestamp: 1363899600,
        open: 1.29358,
        close: 1.29,
        high: 1.2956,
        low: 1.28805
      },
      {
        timestamp: 1363986000,
        open: 1.29,
        close: 1.29913,
        high: 1.30108,
        low: 1.2889
      },
      {
        timestamp: 1364158800,
        open: 1.29913,
        close: 1.29466,
        high: 1.29913,
        low: 1.29457
      },
      {
        timestamp: 1364245200,
        open: 1.29466,
        close: 1.28539,
        high: 1.30504,
        low: 1.28304
      },
      {
        timestamp: 1364331600,
        open: 1.28539,
        close: 1.28625,
        high: 1.28906,
        low: 1.28292
      },
      {
        timestamp: 1364418000,
        open: 1.28625,
        close: 1.27815,
        high: 1.28678,
        low: 1.27513
      },
      {
        timestamp: 1364504400,
        open: 1.27815,
        close: 1.28188,
        high: 1.28452,
        low: 1.27559
      },
      {
        timestamp: 1364590800,
        open: 1.28188,
        close: 1.28198,
        high: 1.28382,
        low: 1.2794
      },
      {
        timestamp: 1364763600,
        open: 1.28198,
        close: 1.28108,
        high: 1.28198,
        low: 1.28063
      },
      {
        timestamp: 1364850000,
        open: 1.28108,
        close: 1.28505,
        high: 1.28688,
        low: 1.27722
      },
      {
        timestamp: 1364936400,
        open: 1.28505,
        close: 1.2819,
        high: 1.28783,
        low: 1.28099
      },
      {
        timestamp: 1365022800,
        open: 1.2819,
        close: 1.28472,
        high: 1.28651,
        low: 1.27905
      },
      {
        timestamp: 1365109200,
        open: 1.28472,
        close: 1.29358,
        high: 1.29497,
        low: 1.27459
      },
      {
        timestamp: 1365195600,
        open: 1.29358,
        close: 1.29983,
        high: 1.30404,
        low: 1.29013
      },
      {
        timestamp: 1365368400,
        open: 1.29983,
        close: 1.2979,
        high: 1.29983,
        low: 1.29653
      },
      {
        timestamp: 1365454800,
        open: 1.2979,
        close: 1.30084,
        high: 1.30387,
        low: 1.29692
      },
      {
        timestamp: 1365541200,
        open: 1.30084,
        close: 1.30842,
        high: 1.31039,
        low: 1.30058
      },
      {
        timestamp: 1365627600,
        open: 1.30842,
        close: 1.30722,
        high: 1.31227,
        low: 1.30534
      },
      {
        timestamp: 1365714000,
        open: 1.30722,
        close: 1.31037,
        high: 1.3139,
        low: 1.30446
      },
      {
        timestamp: 1365800400,
        open: 1.31037,
        close: 1.31101,
        high: 1.31282,
        low: 1.30373
      },
      {
        timestamp: 1365973200,
        open: 1.31101,
        close: 1.31193,
        high: 1.31416,
        low: 1.31066
      },
      {
        timestamp: 1366059600,
        open: 1.31193,
        close: 1.30367,
        high: 1.31193,
        low: 1.30226
      },
      {
        timestamp: 1366146000,
        open: 1.30367,
        close: 1.31772,
        high: 1.32024,
        low: 1.3029
      },
      {
        timestamp: 1366232400,
        open: 1.31772,
        close: 1.30336,
        high: 1.32004,
        low: 1.30019
      },
      {
        timestamp: 1366318800,
        open: 1.30336,
        close: 1.30523,
        high: 1.30969,
        low: 1.30218
      },
      {
        timestamp: 1366405200,
        open: 1.30523,
        close: 1.30535,
        high: 1.31296,
        low: 1.30453
      },
      {
        timestamp: 1366578000,
        open: 1.30535,
        close: 1.30826,
        high: 1.30905,
        low: 1.30535
      },
      {
        timestamp: 1366664400,
        open: 1.30826,
        close: 1.3066,
        high: 1.30848,
        low: 1.3016
      },
      {
        timestamp: 1366750800,
        open: 1.3066,
        close: 1.30042,
        high: 1.30848,
        low: 1.29737
      },
      {
        timestamp: 1366837200,
        open: 1.30042,
        close: 1.30184,
        high: 1.3035,
        low: 1.29554
      },
      {
        timestamp: 1366923600,
        open: 1.30184,
        close: 1.30116,
        high: 1.30942,
        low: 1.29895
      },
      {
        timestamp: 1367010000,
        open: 1.30116,
        close: 1.30279,
        high: 1.30485,
        low: 1.29914
      },
      {
        timestamp: 1367182800,
        open: 1.30279,
        close: 1.30552,
        high: 1.30606,
        low: 1.30279
      },
      {
        timestamp: 1367269200,
        open: 1.30552,
        close: 1.31005,
        high: 1.31172,
        low: 1.30328
      },
      {
        timestamp: 1367355600,
        open: 1.31005,
        close: 1.31686,
        high: 1.31866,
        low: 1.30551
      },
      {
        timestamp: 1367442000,
        open: 1.31686,
        close: 1.31816,
        high: 1.32433,
        low: 1.31615
      },
      {
        timestamp: 1367528400,
        open: 1.31816,
        close: 1.30665,
        high: 1.32193,
        low: 1.3038
      },
      {
        timestamp: 1367614800,
        open: 1.30665,
        close: 1.31175,
        high: 1.31602,
        low: 1.30339
      },
      {
        timestamp: 1367787600,
        open: 1.31175,
        close: 1.31283,
        high: 1.31304,
        low: 1.31175
      },
      {
        timestamp: 1367874000,
        open: 1.31283,
        close: 1.30775,
        high: 1.31421,
        low: 1.30543
      },
      {
        timestamp: 1367960400,
        open: 1.30775,
        close: 1.30798,
        high: 1.31328,
        low: 1.30686
      },
      {
        timestamp: 1368046800,
        open: 1.30798,
        close: 1.31539,
        high: 1.3195,
        low: 1.30727
      },
      {
        timestamp: 1368133200,
        open: 1.31539,
        close: 1.30436,
        high: 1.31778,
        low: 1.30105
      },
      {
        timestamp: 1368219600,
        open: 1.30436,
        close: 1.29942,
        high: 1.30518,
        low: 1.29361
      },
      {
        timestamp: 1368392400,
        open: 1.29942,
        close: 1.29733,
        high: 1.29942,
        low: 1.29586
      },
      {
        timestamp: 1368478800,
        open: 1.29733,
        close: 1.29766,
        high: 1.30006,
        low: 1.29422
      },
      {
        timestamp: 1368565200,
        open: 1.29766,
        close: 1.2925,
        high: 1.30297,
        low: 1.29132
      },
      {
        timestamp: 1368651600,
        open: 1.2925,
        close: 1.28883,
        high: 1.29435,
        low: 1.2844
      },
      {
        timestamp: 1368738000,
        open: 1.28883,
        close: 1.28834,
        high: 1.29305,
        low: 1.28471
      },
      {
        timestamp: 1368824400,
        open: 1.28834,
        close: 1.28373,
        high: 1.28905,
        low: 1.27971
      },
      {
        timestamp: 1368997200,
        open: 1.28373,
        close: 1.28434,
        high: 1.28459,
        low: 1.28261
      },
      {
        timestamp: 1369083600,
        open: 1.28434,
        close: 1.28836,
        high: 1.29017,
        low: 1.28202
      },
      {
        timestamp: 1369170000,
        open: 1.28836,
        close: 1.2906,
        high: 1.29343,
        low: 1.28418
      },
      {
        timestamp: 1369256400,
        open: 1.2906,
        close: 1.28594,
        high: 1.29988,
        low: 1.28343
      },
      {
        timestamp: 1369342800,
        open: 1.28594,
        close: 1.29349,
        high: 1.29577,
        low: 1.28223
      },
      {
        timestamp: 1369429200,
        open: 1.29349,
        close: 1.29384,
        high: 1.29941,
        low: 1.29051
      },
      {
        timestamp: 1369602000,
        open: 1.29384,
        close: 1.29375,
        high: 1.29449,
        low: 1.29289
      },
      {
        timestamp: 1369688400,
        open: 1.29375,
        close: 1.29324,
        high: 1.29494,
        low: 1.29161
      },
      {
        timestamp: 1369774800,
        open: 1.29324,
        close: 1.28568,
        high: 1.29505,
        low: 1.28505
      },
      {
        timestamp: 1369861200,
        open: 1.28568,
        close: 1.29433,
        high: 1.29782,
        low: 1.28386
      },
      {
        timestamp: 1369947600,
        open: 1.29433,
        close: 1.30479,
        high: 1.30622,
        low: 1.29343
      },
      {
        timestamp: 1370034000,
        open: 1.30479,
        close: 1.29986,
        high: 1.30603,
        low: 1.2945
      },
      {
        timestamp: 1370206800,
        open: 1.29986,
        close: 1.30012,
        high: 1.30012,
        low: 1.29874
      },
      {
        timestamp: 1370293200,
        open: 1.30012,
        close: 1.30773,
        high: 1.31084,
        low: 1.29561
      },
      {
        timestamp: 1370379600,
        open: 1.30773,
        close: 1.30816,
        high: 1.31021,
        low: 1.3043
      },
      {
        timestamp: 1370466000,
        open: 1.30816,
        close: 1.30941,
        high: 1.31171,
        low: 1.30538
      },
      {
        timestamp: 1370552400,
        open: 1.30941,
        close: 1.3248,
        high: 1.33072,
        low: 1.30756
      },
      {
        timestamp: 1370638800,
        open: 1.3248,
        close: 1.32163,
        high: 1.32863,
        low: 1.31922
      },
      {
        timestamp: 1370811600,
        open: 1.32163,
        close: 1.31983,
        high: 1.32113,
        low: 1.3191
      },
      {
        timestamp: 1370898000,
        open: 1.31983,
        close: 1.32573,
        high: 1.327,
        low: 1.31784
      },
      {
        timestamp: 1370984400,
        open: 1.32573,
        close: 1.3315,
        high: 1.33182,
        low: 1.32326
      },
      {
        timestamp: 1371070800,
        open: 1.3315,
        close: 1.3338,
        high: 1.33601,
        low: 1.32663
      },
      {
        timestamp: 1371157200,
        open: 1.3338,
        close: 1.33763,
        high: 1.33911,
        low: 1.32793
      },
      {
        timestamp: 1371243600,
        open: 1.33763,
        close: 1.33455,
        high: 1.33763,
        low: 1.32955
      },
      {
        timestamp: 1371416400,
        open: 1.33455,
        close: 1.33514,
        high: 1.33562,
        low: 1.33454
      },
      {
        timestamp: 1371502800,
        open: 1.33514,
        close: 1.33672,
        high: 1.33824,
        low: 1.33192
      },
      {
        timestamp: 1371589200,
        open: 1.33672,
        close: 1.33935,
        high: 1.34166,
        low: 1.33267
      },
      {
        timestamp: 1371675600,
        open: 1.33935,
        close: 1.32956,
        high: 1.3421,
        low: 1.32632
      },
      {
        timestamp: 1371762000,
        open: 1.32956,
        close: 1.32212,
        high: 1.33025,
        low: 1.31622
      },
      {
        timestamp: 1371848400,
        open: 1.32212,
        close: 1.31233,
        high: 1.32548,
        low: 1.30993
      },
      {
        timestamp: 1372021200,
        open: 1.31233,
        close: 1.30945,
        high: 1.31044,
        low: 1.309
      },
      {
        timestamp: 1372107600,
        open: 1.30945,
        close: 1.31216,
        high: 1.31447,
        low: 1.30597
      },
      {
        timestamp: 1372194000,
        open: 1.31216,
        close: 1.30793,
        high: 1.31514,
        low: 1.30657
      },
      {
        timestamp: 1372280400,
        open: 1.30793,
        close: 1.30127,
        high: 1.3088,
        low: 1.29853
      },
      {
        timestamp: 1372366800,
        open: 1.30127,
        close: 1.30402,
        high: 1.3058,
        low: 1.30006
      },
      {
        timestamp: 1372453200,
        open: 1.30402,
        close: 1.30125,
        high: 1.3104,
        low: 1.2992
      },
      {
        timestamp: 1372626000,
        open: 1.30125,
        close: 1.30197,
        high: 1.30284,
        low: 1.30072
      },
      {
        timestamp: 1372712400,
        open: 1.30197,
        close: 1.30651,
        high: 1.30675,
        low: 1.30065
      },
      {
        timestamp: 1372798800,
        open: 1.30651,
        close: 1.298,
        high: 1.30788,
        low: 1.29643
      },
      {
        timestamp: 1372885200,
        open: 1.298,
        close: 1.30117,
        high: 1.30333,
        low: 1.29238
      },
      {
        timestamp: 1372971600,
        open: 1.30117,
        close: 1.29148,
        high: 1.30242,
        low: 1.28837
      },
      {
        timestamp: 1373058000,
        open: 1.29148,
        close: 1.28338,
        high: 1.29179,
        low: 1.28068
      },
      {
        timestamp: 1373230800,
        open: 1.28338,
        close: 1.28146,
        high: 1.28404,
        low: 1.28119
      },
      {
        timestamp: 1373317200,
        open: 1.28146,
        close: 1.28723,
        high: 1.28826,
        low: 1.28125
      },
      {
        timestamp: 1373403600,
        open: 1.28723,
        close: 1.27826,
        high: 1.28987,
        low: 1.27562
      },
      {
        timestamp: 1373490000,
        open: 1.27826,
        close: 1.29826,
        high: 1.29864,
        low: 1.27655
      },
      {
        timestamp: 1373576400,
        open: 1.29826,
        close: 1.30976,
        high: 1.32066,
        low: 1.29635
      },
      {
        timestamp: 1373662800,
        open: 1.30976,
        close: 1.30684,
        high: 1.3101,
        low: 1.29999
      },
      {
        timestamp: 1373835600,
        open: 1.30684,
        close: 1.30844,
        high: 1.30894,
        low: 1.30709
      },
      {
        timestamp: 1373922000,
        open: 1.30844,
        close: 1.3063,
        high: 1.30841,
        low: 1.2994
      },
      {
        timestamp: 1374008400,
        open: 1.3063,
        close: 1.3165,
        high: 1.31751,
        low: 1.3051
      },
      {
        timestamp: 1374094800,
        open: 1.3165,
        close: 1.31257,
        high: 1.31793,
        low: 1.3084
      },
      {
        timestamp: 1374181200,
        open: 1.31257,
        close: 1.31091,
        high: 1.31289,
        low: 1.30667
      },
      {
        timestamp: 1374267600,
        open: 1.31091,
        close: 1.31393,
        high: 1.31554,
        low: 1.30902
      },
      {
        timestamp: 1374440400,
        open: 1.31393,
        close: 1.31455,
        high: 1.31566,
        low: 1.31372
      },
      {
        timestamp: 1374526800,
        open: 1.31455,
        close: 1.31865,
        high: 1.32188,
        low: 1.31455
      },
      {
        timestamp: 1374613200,
        open: 1.31865,
        close: 1.32255,
        high: 1.32395,
        low: 1.31641
      },
      {
        timestamp: 1374699600,
        open: 1.32255,
        close: 1.32,
        high: 1.32567,
        low: 1.31773
      },
      {
        timestamp: 1374786000,
        open: 1.32,
        close: 1.32786,
        high: 1.32967,
        low: 1.31664
      },
      {
        timestamp: 1374872400,
        open: 1.32786,
        close: 1.32797,
        high: 1.32976,
        low: 1.32535
      },
      {
        timestamp: 1375045200,
        open: 1.32797,
        close: 1.32899,
        high: 1.32944,
        low: 1.32838
      },
      {
        timestamp: 1375131600,
        open: 1.32899,
        close: 1.32448,
        high: 1.32956,
        low: 1.3242
      },
      {
        timestamp: 1375218000,
        open: 1.32638,
        close: 1.32637,
        high: 1.33025,
        low: 1.32351
      },
      {
        timestamp: 1375304400,
        open: 1.32637,
        close: 1.33033,
        high: 1.33457,
        low: 1.32103
      },
      {
        timestamp: 1375390800,
        open: 1.33033,
        close: 1.32094,
        high: 1.33114,
        low: 1.31931
      },
      {
        timestamp: 1375477200,
        open: 1.32094,
        close: 1.32839,
        high: 1.3295,
        low: 1.31903
      },
      {
        timestamp: 1375650000,
        open: 1.32839,
        close: 1.32903,
        high: 1.32931,
        low: 1.32785
      },
      {
        timestamp: 1375736400,
        open: 1.32903,
        close: 1.32592,
        high: 1.33012,
        low: 1.32335
      },
      {
        timestamp: 1375822800,
        open: 1.32592,
        close: 1.33073,
        high: 1.33237,
        low: 1.32469
      },
      {
        timestamp: 1375909200,
        open: 1.33073,
        close: 1.33374,
        high: 1.33462,
        low: 1.32664
      },
      {
        timestamp: 1375995600,
        open: 1.33374,
        close: 1.33814,
        high: 1.34012,
        low: 1.33289
      },
      {
        timestamp: 1376082000,
        open: 1.33814,
        close: 1.33443,
        high: 1.33911,
        low: 1.33334
      },
      {
        timestamp: 1376254800,
        open: 1.33443,
        close: 1.33243,
        high: 1.33465,
        low: 1.33173
      },
      {
        timestamp: 1376341200,
        open: 1.33243,
        close: 1.33015,
        high: 1.33447,
        low: 1.32779
      },
      {
        timestamp: 1376427600,
        open: 1.33015,
        close: 1.32632,
        high: 1.33177,
        low: 1.32342
      },
      {
        timestamp: 1376514000,
        open: 1.32632,
        close: 1.32557,
        high: 1.32819,
        low: 1.32398
      },
      {
        timestamp: 1376600400,
        open: 1.32557,
        close: 1.33464,
        high: 1.33638,
        low: 1.32061
      },
      {
        timestamp: 1376686800,
        open: 1.33464,
        close: 1.333,
        high: 1.33809,
        low: 1.33118
      },
      {
        timestamp: 1376859600,
        open: 1.333,
        close: 1.33413,
        high: 1.33445,
        low: 1.33265
      },
      {
        timestamp: 1376946000,
        open: 1.33413,
        close: 1.33357,
        high: 1.33753,
        low: 1.33159
      },
      {
        timestamp: 1377032400,
        open: 1.33357,
        close: 1.34187,
        high: 1.34527,
        low: 1.33241
      },
      {
        timestamp: 1377118800,
        open: 1.34187,
        close: 1.33575,
        high: 1.34279,
        low: 1.33361
      },
      {
        timestamp: 1377205200,
        open: 1.33575,
        close: 1.33566,
        high: 1.3374,
        low: 1.3299
      },
      {
        timestamp: 1377291600,
        open: 1.33566,
        close: 1.33794,
        high: 1.34105,
        low: 1.33341
      },
      {
        timestamp: 1377464400,
        open: 1.33794,
        close: 1.33921,
        high: 1.34023,
        low: 1.33815
      },
      {
        timestamp: 1377550800,
        open: 1.33921,
        close: 1.33705,
        high: 1.33949,
        low: 1.33573
      },
      {
        timestamp: 1377637200,
        open: 1.33705,
        close: 1.33938,
        high: 1.33994,
        low: 1.33232
      },
      {
        timestamp: 1377723600,
        open: 1.33938,
        close: 1.33404,
        high: 1.33986,
        low: 1.33056
      },
      {
        timestamp: 1377810000,
        open: 1.33404,
        close: 1.3242,
        high: 1.33437,
        low: 1.32201
      },
      {
        timestamp: 1377896400,
        open: 1.3242,
        close: 1.32257,
        high: 1.32559,
        low: 1.31741
      },
      {
        timestamp: 1378069200,
        open: 1.32257,
        close: 1.32178,
        high: 1.324,
        low: 1.32152
      },
      {
        timestamp: 1378155600,
        open: 1.32178,
        close: 1.31939,
        high: 1.32282,
        low: 1.31845
      },
      {
        timestamp: 1378242000,
        open: 1.31939,
        close: 1.31712,
        high: 1.31985,
        low: 1.31394
      },
      {
        timestamp: 1378328400,
        open: 1.31712,
        close: 1.32079,
        high: 1.32186,
        low: 1.31576
      },
      {
        timestamp: 1378414800,
        open: 1.32079,
        close: 1.31214,
        high: 1.32239,
        low: 1.31112
      },
      {
        timestamp: 1378501200,
        open: 1.31214,
        close: 1.3181,
        high: 1.31903,
        low: 1.31056
      },
      {
        timestamp: 1378674000,
        open: 1.3181,
        close: 1.31663,
        high: 1.31828,
        low: 1.31613
      },
      {
        timestamp: 1378760400,
        open: 1.31663,
        close: 1.32541,
        high: 1.32814,
        low: 1.31663
      },
      {
        timestamp: 1378846800,
        open: 1.32541,
        close: 1.32674,
        high: 1.32765,
        low: 1.32311
      },
      {
        timestamp: 1378933200,
        open: 1.32674,
        close: 1.33114,
        high: 1.33254,
        low: 1.32444
      },
      {
        timestamp: 1379019600,
        open: 1.33114,
        close: 1.33006,
        high: 1.33254,
        low: 1.32567
      },
      {
        timestamp: 1379106000,
        open: 1.33006,
        close: 1.33012,
        high: 1.33222,
        low: 1.32543
      },
      {
        timestamp: 1379278800,
        open: 1.33012,
        close: 1.33613,
        high: 1.33803,
        low: 1.33009
      },
      {
        timestamp: 1379365200,
        open: 1.33613,
        close: 1.33349,
        high: 1.33863,
        low: 1.33309
      },
      {
        timestamp: 1379451600,
        open: 1.33349,
        close: 1.3358,
        high: 1.33715,
        low: 1.3326
      },
      {
        timestamp: 1379538000,
        open: 1.3358,
        close: 1.35231,
        high: 1.35431,
        low: 1.3339
      },
      {
        timestamp: 1379624400,
        open: 1.35231,
        close: 1.35303,
        high: 1.35693,
        low: 1.35021
      },
      {
        timestamp: 1379710800,
        open: 1.35303,
        close: 1.35277,
        high: 1.35494,
        low: 1.34986
      },
      {
        timestamp: 1379883600,
        open: 1.35277,
        close: 1.35518,
        high: 1.35593,
        low: 1.35277
      },
      {
        timestamp: 1379970000,
        open: 1.35518,
        close: 1.34939,
        high: 1.35616,
        low: 1.34803
      },
      {
        timestamp: 1380056400,
        open: 1.34939,
        close: 1.34732,
        high: 1.35198,
        low: 1.3465
      },
      {
        timestamp: 1380142800,
        open: 1.34732,
        close: 1.35266,
        high: 1.3538,
        low: 1.34626
      },
      {
        timestamp: 1380229200,
        open: 1.35266,
        close: 1.34892,
        high: 1.35364,
        low: 1.34729
      },
      {
        timestamp: 1380315600,
        open: 1.34892,
        close: 1.35244,
        high: 1.35652,
        low: 1.3475
      },
      {
        timestamp: 1380488400,
        open: 1.35244,
        close: 1.34981,
        high: 1.35082,
        low: 1.34716
      },
      {
        timestamp: 1380574800,
        open: 1.34981,
        close: 1.35264,
        high: 1.35569,
        low: 1.34783
      },
      {
        timestamp: 1380661200,
        open: 1.35264,
        close: 1.35271,
        high: 1.35886,
        low: 1.35176
      },
      {
        timestamp: 1380747600,
        open: 1.35271,
        close: 1.35794,
        high: 1.3608,
        low: 1.35053
      },
      {
        timestamp: 1380834000,
        open: 1.35794,
        close: 1.36191,
        high: 1.3647,
        low: 1.35781
      },
      {
        timestamp: 1380920400,
        open: 1.36191,
        close: 1.35546,
        high: 1.36326,
        low: 1.3539
      },
      {
        timestamp: 1381093200,
        open: 1.35546,
        close: 1.35671,
        high: 1.35768,
        low: 1.35575
      },
      {
        timestamp: 1381179600,
        open: 1.35671,
        close: 1.35823,
        high: 1.35919,
        low: 1.35433
      },
      {
        timestamp: 1381266000,
        open: 1.35823,
        close: 1.35743,
        high: 1.3608,
        low: 1.35584
      },
      {
        timestamp: 1381352400,
        open: 1.35743,
        close: 1.35225,
        high: 1.36054,
        low: 1.34865
      },
      {
        timestamp: 1381438800,
        open: 1.35225,
        close: 1.35208,
        high: 1.35481,
        low: 1.34883
      },
      {
        timestamp: 1381525200,
        open: 1.35208,
        close: 1.35416,
        high: 1.35825,
        low: 1.35189
      },
      {
        timestamp: 1381698000,
        open: 1.35416,
        close: 1.35656,
        high: 1.3573,
        low: 1.35416
      },
      {
        timestamp: 1381784400,
        open: 1.35656,
        close: 1.3562,
        high: 1.35986,
        low: 1.35463
      },
      {
        timestamp: 1381870800,
        open: 1.3562,
        close: 1.35256,
        high: 1.35718,
        low: 1.34801
      },
      {
        timestamp: 1381957200,
        open: 1.35256,
        close: 1.3532,
        high: 1.35682,
        low: 1.34735
      },
      {
        timestamp: 1382043600,
        open: 1.3532,
        close: 1.36761,
        high: 1.36826,
        low: 1.35166
      },
      {
        timestamp: 1382130000,
        open: 1.36761,
        close: 1.36879,
        high: 1.37048,
        low: 1.36601
      },
      {
        timestamp: 1382302800,
        open: 1.36879,
        close: 1.36857,
        high: 1.36959,
        low: 1.36836
      },
      {
        timestamp: 1382389200,
        open: 1.36857,
        close: 1.36804,
        high: 1.36894,
        low: 1.36519
      },
      {
        timestamp: 1382475600,
        open: 1.36804,
        close: 1.37824,
        high: 1.37928,
        low: 1.36626
      },
      {
        timestamp: 1382562000,
        open: 1.37824,
        close: 1.37775,
        high: 1.37936,
        low: 1.37422
      },
      {
        timestamp: 1382648400,
        open: 1.37775,
        close: 1.38015,
        high: 1.38261,
        low: 1.37652
      },
      {
        timestamp: 1382734800,
        open: 1.38015,
        close: 1.38054,
        high: 1.3833,
        low: 1.37752
      },
      {
        timestamp: 1382907600,
        open: 1.38054,
        close: 1.38113,
        high: 1.38161,
        low: 1.3801
      },
      {
        timestamp: 1382994000,
        open: 1.38113,
        close: 1.37864,
        high: 1.38185,
        low: 1.37758
      },
      {
        timestamp: 1383080400,
        open: 1.37864,
        close: 1.37466,
        high: 1.3814,
        low: 1.37373
      },
      {
        timestamp: 1383166800,
        open: 1.37466,
        close: 1.37364,
        high: 1.37862,
        low: 1.36966
      },
      {
        timestamp: 1383253200,
        open: 1.37364,
        close: 1.3585,
        high: 1.37394,
        low: 1.3576
      },
      {
        timestamp: 1383339600,
        open: 1.3585,
        close: 1.34913,
        high: 1.35903,
        low: 1.34807
      },
      {
        timestamp: 1383516000,
        open: 1.34913,
        close: 1.34911,
        high: 1.3495,
        low: 1.34846
      },
      {
        timestamp: 1383602400,
        open: 1.34911,
        close: 1.3515,
        high: 1.3525,
        low: 1.34427
      },
      {
        timestamp: 1383688800,
        open: 1.3515,
        close: 1.34762,
        high: 1.35231,
        low: 1.34498
      },
      {
        timestamp: 1383775200,
        open: 1.34762,
        close: 1.35156,
        high: 1.35484,
        low: 1.34683
      },
      {
        timestamp: 1383861600,
        open: 1.35156,
        close: 1.34204,
        high: 1.35298,
        low: 1.32954
      },
      {
        timestamp: 1383948000,
        open: 1.34204,
        close: 1.33691,
        high: 1.34387,
        low: 1.33184
      },
      {
        timestamp: 1384120800,
        open: 1.33691,
        close: 1.33584,
        high: 1.33663,
        low: 1.33526
      },
      {
        timestamp: 1384207200,
        open: 1.33584,
        close: 1.34071,
        high: 1.34166,
        low: 1.33455
      },
      {
        timestamp: 1384293600,
        open: 1.34071,
        close: 1.3436,
        high: 1.3457,
        low: 1.336
      },
      {
        timestamp: 1384380000,
        open: 1.3436,
        close: 1.34881,
        high: 1.34964,
        low: 1.33909
      },
      {
        timestamp: 1384466400,
        open: 1.34881,
        close: 1.34582,
        high: 1.34984,
        low: 1.34193
      },
      {
        timestamp: 1384552800,
        open: 1.34582,
        close: 1.34977,
        high: 1.35061,
        low: 1.34329
      },
      {
        timestamp: 1384725600,
        open: 1.34977,
        close: 1.35001,
        high: 1.35036,
        low: 1.34926
      },
      {
        timestamp: 1384812000,
        open: 1.35001,
        close: 1.35076,
        high: 1.35424,
        low: 1.34752
      },
      {
        timestamp: 1384898400,
        open: 1.35076,
        close: 1.35386,
        high: 1.3548,
        low: 1.34886
      },
      {
        timestamp: 1384984800,
        open: 1.35386,
        close: 1.34398,
        high: 1.35833,
        low: 1.3416
      },
      {
        timestamp: 1385071200,
        open: 1.34398,
        close: 1.34816,
        high: 1.34873,
        low: 1.34002
      },
      {
        timestamp: 1385157600,
        open: 1.34816,
        close: 1.35578,
        high: 1.35579,
        low: 1.3463
      },
      {
        timestamp: 1385330400,
        open: 1.35578,
        close: 1.35529,
        high: 1.35564,
        low: 1.3547
      },
      {
        timestamp: 1385416800,
        open: 1.35529,
        close: 1.35177,
        high: 1.35608,
        low: 1.34908
      },
      {
        timestamp: 1385503200,
        open: 1.35177,
        close: 1.35733,
        high: 1.35756,
        low: 1.35163
      },
      {
        timestamp: 1385589600,
        open: 1.35733,
        close: 1.35798,
        high: 1.36135,
        low: 1.35584
      },
      {
        timestamp: 1385676000,
        open: 1.35798,
        close: 1.36061,
        high: 1.36195,
        low: 1.35645
      },
      {
        timestamp: 1385762400,
        open: 1.36061,
        close: 1.35941,
        high: 1.36224,
        low: 1.35811
      },
      {
        timestamp: 1385935200,
        open: 1.35941,
        close: 1.35932,
        high: 1.35954,
        low: 1.35829
      },
      {
        timestamp: 1386021600,
        open: 1.35932,
        close: 1.35424,
        high: 1.36167,
        low: 1.35266
      },
      {
        timestamp: 1386108000,
        open: 1.35424,
        close: 1.35905,
        high: 1.36145,
        low: 1.35252
      },
      {
        timestamp: 1386194400,
        open: 1.35905,
        close: 1.35939,
        high: 1.36061,
        low: 1.3529
      },
      {
        timestamp: 1386280800,
        open: 1.35939,
        close: 1.36678,
        high: 1.3678,
        low: 1.35438
      },
      {
        timestamp: 1386367200,
        open: 1.36678,
        close: 1.37059,
        high: 1.37072,
        low: 1.36213
      },
      {
        timestamp: 1386540000,
        open: 1.37059,
        close: 1.37164,
        high: 1.37211,
        low: 1.37017
      },
      {
        timestamp: 1386626400,
        open: 1.37164,
        close: 1.37394,
        high: 1.37473,
        low: 1.36949
      },
      {
        timestamp: 1386712800,
        open: 1.37394,
        close: 1.37617,
        high: 1.37957,
        low: 1.37347
      },
      {
        timestamp: 1386799200,
        open: 1.37617,
        close: 1.37871,
        high: 1.38115,
        low: 1.37409
      },
      {
        timestamp: 1386885600,
        open: 1.37871,
        close: 1.37541,
        high: 1.38039,
        low: 1.37382
      },
      {
        timestamp: 1386972000,
        open: 1.37541,
        close: 1.37449,
        high: 1.37699,
        low: 1.37094
      },
      {
        timestamp: 1387144800,
        open: 1.37449,
        close: 1.373,
        high: 1.37392,
        low: 1.37281
      },
      {
        timestamp: 1387231200,
        open: 1.373,
        close: 1.37622,
        high: 1.37995,
        low: 1.37299
      },
      {
        timestamp: 1387317600,
        open: 1.37622,
        close: 1.37681,
        high: 1.37831,
        low: 1.37237
      },
      {
        timestamp: 1387404000,
        open: 1.37681,
        close: 1.3686,
        high: 1.38125,
        low: 1.36753
      },
      {
        timestamp: 1387490400,
        open: 1.3686,
        close: 1.36622,
        high: 1.36946,
        low: 1.36502
      },
      {
        timestamp: 1387576800,
        open: 1.36622,
        close: 1.36766,
        high: 1.37102,
        low: 1.36257
      },
      {
        timestamp: 1387749600,
        open: 1.36766,
        close: 1.36763,
        high: 1.3682,
        low: 1.36704
      },
      {
        timestamp: 1387836000,
        open: 1.36763,
        close: 1.36969,
        high: 1.37177,
        low: 1.3669
      },
      {
        timestamp: 1387922400,
        open: 1.36969,
        close: 1.36765,
        high: 1.37157,
        low: 1.3656
      },
      {
        timestamp: 1388095200,
        open: 1.36765,
        close: 1.36922,
        high: 1.37032,
        low: 1.36758
      },
      {
        timestamp: 1388181600,
        open: 1.36922,
        close: 1.37499,
        high: 1.3895,
        low: 1.36883
      },
      {
        timestamp: 1388354400,
        open: 1.37499,
        close: 1.37603,
        high: 1.37621,
        low: 1.37517
      },
      {
        timestamp: 1388440800,
        open: 1.37603,
        close: 1.38025,
        high: 1.38197,
        low: 1.37291
      },
      {
        timestamp: 1388527200,
        open: 1.38025,
        close: 1.37796,
        high: 1.38135,
        low: 1.37604
      },
      {
        timestamp: 1388700000,
        open: 1.37796,
        close: 1.36713,
        high: 1.37594,
        low: 1.36304
      },
      {
        timestamp: 1388786400,
        open: 1.36713,
        close: 1.35932,
        high: 1.36749,
        low: 1.35832
      },
      {
        timestamp: 1388959200,
        open: 1.35932,
        close: 1.35966,
        high: 1.35973,
        low: 1.35902
      },
      {
        timestamp: 1389045600,
        open: 1.35966,
        close: 1.36287,
        high: 1.36539,
        low: 1.35723
      },
      {
        timestamp: 1389132000,
        open: 1.36287,
        close: 1.36164,
        high: 1.36571,
        low: 1.35973
      },
      {
        timestamp: 1389218400,
        open: 1.36164,
        close: 1.35756,
        high: 1.36358,
        low: 1.35542
      },
      {
        timestamp: 1389304800,
        open: 1.35756,
        close: 1.36069,
        high: 1.3634,
        low: 1.35487
      },
      {
        timestamp: 1389391200,
        open: 1.36069,
        close: 1.36702,
        high: 1.36881,
        low: 1.35742
      },
      {
        timestamp: 1389564000,
        open: 1.36702,
        close: 1.36816,
        high: 1.36859,
        low: 1.36702
      },
      {
        timestamp: 1389650400,
        open: 1.36816,
        close: 1.36719,
        high: 1.36855,
        low: 1.3638
      },
      {
        timestamp: 1389736800,
        open: 1.36719,
        close: 1.36798,
        high: 1.37003,
        low: 1.36497
      },
      {
        timestamp: 1389823200,
        open: 1.36798,
        close: 1.3606,
        high: 1.36816,
        low: 1.3582
      },
      {
        timestamp: 1389909600,
        open: 1.3606,
        close: 1.36207,
        high: 1.36504,
        low: 1.35837
      },
      {
        timestamp: 1389996000,
        open: 1.36207,
        close: 1.35415,
        high: 1.3622,
        low: 1.35178
      },
      {
        timestamp: 1390168800,
        open: 1.35415,
        close: 1.35419,
        high: 1.35471,
        low: 1.35367
      },
      {
        timestamp: 1390255200,
        open: 1.35419,
        close: 1.35533,
        high: 1.35687,
        low: 1.35083
      },
      {
        timestamp: 1390341600,
        open: 1.35533,
        close: 1.35617,
        high: 1.35698,
        low: 1.35171
      },
      {
        timestamp: 1390428000,
        open: 1.35617,
        close: 1.35468,
        high: 1.35846,
        low: 1.35352
      },
      {
        timestamp: 1390514400,
        open: 1.35468,
        close: 1.36967,
        high: 1.3699,
        low: 1.35308
      },
      {
        timestamp: 1390600800,
        open: 1.36967,
        close: 1.36809,
        high: 1.37403,
        low: 1.36634
      },
      {
        timestamp: 1390773600,
        open: 1.36809,
        close: 1.36831,
        high: 1.36899,
        low: 1.3671
      },
      {
        timestamp: 1390860000,
        open: 1.36831,
        close: 1.36743,
        high: 1.37174,
        low: 1.3654
      },
      {
        timestamp: 1390946400,
        open: 1.36743,
        close: 1.36705,
        high: 1.36892,
        low: 1.36295
      },
      {
        timestamp: 1391032800,
        open: 1.36705,
        close: 1.36656,
        high: 1.36854,
        low: 1.36038
      },
      {
        timestamp: 1391119200,
        open: 1.36656,
        close: 1.35552,
        high: 1.36665,
        low: 1.35441
      },
      {
        timestamp: 1391205600,
        open: 1.35552,
        close: 1.34937,
        high: 1.35748,
        low: 1.34799
      },
      {
        timestamp: 1391378400,
        open: 1.34937,
        close: 1.34848,
        high: 1.34895,
        low: 1.34804
      },
      {
        timestamp: 1391464800,
        open: 1.34848,
        close: 1.35263,
        high: 1.35364,
        low: 1.34778
      },
      {
        timestamp: 1391551200,
        open: 1.35263,
        close: 1.35185,
        high: 1.35397,
        low: 1.3494
      },
      {
        timestamp: 1391637600,
        open: 1.35185,
        close: 1.35342,
        high: 1.35564,
        low: 1.34995
      },
      {
        timestamp: 1391724000,
        open: 1.35342,
        close: 1.35909,
        high: 1.36197,
        low: 1.3483
      },
      {
        timestamp: 1391810400,
        open: 1.35909,
        close: 1.36376,
        high: 1.36475,
        low: 1.35526
      },
      {
        timestamp: 1391983200,
        open: 1.36376,
        close: 1.36169,
        high: 1.36333,
        low: 1.361
      },
      {
        timestamp: 1392069600,
        open: 1.36169,
        close: 1.36447,
        high: 1.36522,
        low: 1.36169
      },
      {
        timestamp: 1392156000,
        open: 1.36447,
        close: 1.36391,
        high: 1.36838,
        low: 1.36301
      },
      {
        timestamp: 1392242400,
        open: 1.36391,
        close: 1.35941,
        high: 1.36534,
        low: 1.35629
      },
      {
        timestamp: 1392328800,
        open: 1.35941,
        close: 1.36814,
        high: 1.36927,
        low: 1.35854
      },
      {
        timestamp: 1392415200,
        open: 1.36814,
        close: 1.36959,
        high: 1.37156,
        low: 1.36743
      },
      {
        timestamp: 1392588000,
        open: 1.36959,
        close: 1.37105,
        high: 1.37181,
        low: 1.36959
      },
      {
        timestamp: 1392674400,
        open: 1.37105,
        close: 1.37104,
        high: 1.3725,
        low: 1.36928
      },
      {
        timestamp: 1392760800,
        open: 1.37104,
        close: 1.37606,
        high: 1.37714,
        low: 1.36954
      },
      {
        timestamp: 1392847200,
        open: 1.37606,
        close: 1.3734,
        high: 1.37738,
        low: 1.37252
      },
      {
        timestamp: 1392933600,
        open: 1.3734,
        close: 1.37195,
        high: 1.37634,
        low: 1.36861
      },
      {
        timestamp: 1393020000,
        open: 1.37195,
        close: 1.37404,
        high: 1.37594,
        low: 1.37027
      },
      {
        timestamp: 1393192800,
        open: 1.37404,
        close: 1.37393,
        high: 1.37433,
        low: 1.37294
      },
      {
        timestamp: 1393279200,
        open: 1.37393,
        close: 1.37358,
        high: 1.37729,
        low: 1.37091
      },
      {
        timestamp: 1393365600,
        open: 1.37358,
        close: 1.37464,
        high: 1.37679,
        low: 1.37162
      },
      {
        timestamp: 1393452000,
        open: 1.37464,
        close: 1.36875,
        high: 1.37579,
        low: 1.36624
      },
      {
        timestamp: 1393538400,
        open: 1.36875,
        close: 1.37119,
        high: 1.37274,
        low: 1.36436
      },
      {
        timestamp: 1393624800,
        open: 1.37119,
        close: 1.38011,
        high: 1.38256,
        low: 1.36945
      },
      {
        timestamp: 1393797600,
        open: 1.38011,
        close: 1.37605,
        high: 1.37707,
        low: 1.37553
      },
      {
        timestamp: 1393884000,
        open: 1.37605,
        close: 1.37359,
        high: 1.37935,
        low: 1.37271
      },
      {
        timestamp: 1393970400,
        open: 1.37359,
        close: 1.37442,
        high: 1.37823,
        low: 1.37215
      },
      {
        timestamp: 1394056800,
        open: 1.37442,
        close: 1.37343,
        high: 1.37504,
        low: 1.37082
      },
      {
        timestamp: 1394143200,
        open: 1.37343,
        close: 1.38618,
        high: 1.38739,
        low: 1.3722
      },
      {
        timestamp: 1394226000,
        open: 1.38618,
        close: 1.38823,
        high: 1.39157,
        low: 1.38529
      },
      {
        timestamp: 1394398800,
        open: 1.38823,
        close: 1.38766,
        high: 1.38806,
        low: 1.38686
      },
      {
        timestamp: 1394485200,
        open: 1.38766,
        close: 1.38774,
        high: 1.38985,
        low: 1.38623
      },
      {
        timestamp: 1394571600,
        open: 1.38774,
        close: 1.38609,
        high: 1.38797,
        low: 1.38345
      },
      {
        timestamp: 1394658000,
        open: 1.38609,
        close: 1.39054,
        high: 1.39152,
        low: 1.38442
      },
      {
        timestamp: 1394744400,
        open: 1.39054,
        close: 1.38706,
        high: 1.39675,
        low: 1.38463
      },
      {
        timestamp: 1394830800,
        open: 1.38706,
        close: 1.39185,
        high: 1.39385,
        low: 1.38485
      },
      {
        timestamp: 1395003600,
        open: 1.39185,
        close: 1.39137,
        high: 1.39167,
        low: 1.38924
      },
      {
        timestamp: 1395090000,
        open: 1.39137,
        close: 1.39229,
        high: 1.39486,
        low: 1.38797
      },
      {
        timestamp: 1395176400,
        open: 1.39229,
        close: 1.39347,
        high: 1.39441,
        low: 1.38806
      },
      {
        timestamp: 1395262800,
        open: 1.39347,
        close: 1.38339,
        high: 1.39353,
        low: 1.38104
      },
      {
        timestamp: 1395349200,
        open: 1.38339,
        close: 1.37792,
        high: 1.38457,
        low: 1.37501
      },
      {
        timestamp: 1395435600,
        open: 1.37792,
        close: 1.38035,
        high: 1.38115,
        low: 1.37664
      },
      {
        timestamp: 1395608400,
        open: 1.38035,
        close: 1.37989,
        high: 1.38068,
        low: 1.38035
      },
      {
        timestamp: 1395694800,
        open: 1.37989,
        close: 1.384,
        high: 1.38768,
        low: 1.3761
      },
      {
        timestamp: 1395781200,
        open: 1.384,
        close: 1.38277,
        high: 1.38478,
        low: 1.37498
      },
      {
        timestamp: 1395867600,
        open: 1.38277,
        close: 1.37801,
        high: 1.38284,
        low: 1.37772
      },
      {
        timestamp: 1395954000,
        open: 1.37801,
        close: 1.37416,
        high: 1.37977,
        low: 1.37293
      },
      {
        timestamp: 1396040400,
        open: 1.37416,
        close: 1.37539,
        high: 1.37738,
        low: 1.37053
      },
      {
        timestamp: 1396213200,
        open: 1.37539,
        close: 1.37665,
        high: 1.37698,
        low: 1.37539
      },
      {
        timestamp: 1396299600,
        open: 1.37665,
        close: 1.37702,
        high: 1.381,
        low: 1.37233
      },
      {
        timestamp: 1396386000,
        open: 1.37702,
        close: 1.3795,
        high: 1.38164,
        low: 1.37693
      },
      {
        timestamp: 1396472400,
        open: 1.3795,
        close: 1.37688,
        high: 1.3821,
        low: 1.37539
      },
      {
        timestamp: 1396558800,
        open: 1.37688,
        close: 1.37216,
        high: 1.38083,
        low: 1.36989
      },
      {
        timestamp: 1396645200,
        open: 1.37216,
        close: 1.37039,
        high: 1.37322,
        low: 1.36734
      },
      {
        timestamp: 1396818000,
        open: 1.37039,
        close: 1.37027,
        high: 1.37043,
        low: 1.36964
      },
      {
        timestamp: 1396904400,
        open: 1.37027,
        close: 1.37428,
        high: 1.37494,
        low: 1.36972
      },
      {
        timestamp: 1396990800,
        open: 1.37428,
        close: 1.37983,
        high: 1.38125,
        low: 1.37382
      },
      {
        timestamp: 1397077200,
        open: 1.37983,
        close: 1.38561,
        high: 1.38628,
        low: 1.37806
      },
      {
        timestamp: 1397163600,
        open: 1.38561,
        close: 1.38872,
        high: 1.39,
        low: 1.38367
      },
      {
        timestamp: 1397250000,
        open: 1.38872,
        close: 1.38859,
        high: 1.39066,
        low: 1.38642
      },
      {
        timestamp: 1397422800,
        open: 1.38859,
        close: 1.38463,
        high: 1.38494,
        low: 1.38339
      },
      {
        timestamp: 1397509200,
        open: 1.38463,
        close: 1.38229,
        high: 1.38638,
        low: 1.3809
      },
      {
        timestamp: 1397595600,
        open: 1.38229,
        close: 1.38155,
        high: 1.38341,
        low: 1.3791
      },
      {
        timestamp: 1397682000,
        open: 1.38155,
        close: 1.38164,
        high: 1.38518,
        low: 1.38045
      },
      {
        timestamp: 1397768400,
        open: 1.38164,
        close: 1.38147,
        high: 1.38655,
        low: 1.38118
      },
      {
        timestamp: 1397854800,
        open: 1.38147,
        close: 1.38207,
        high: 1.3824,
        low: 1.38092
      },
      {
        timestamp: 1398027600,
        open: 1.38207,
        close: 1.38217,
        high: 1.38229,
        low: 1.38114
      },
      {
        timestamp: 1398114000,
        open: 1.38217,
        close: 1.37942,
        high: 1.38313,
        low: 1.3788
      },
      {
        timestamp: 1398200400,
        open: 1.37942,
        close: 1.38067,
        high: 1.38255,
        low: 1.37856
      },
      {
        timestamp: 1398286800,
        open: 1.38067,
        close: 1.3818,
        high: 1.38555,
        low: 1.38008
      },
      {
        timestamp: 1398373200,
        open: 1.3818,
        close: 1.38331,
        high: 1.38462,
        low: 1.37921
      },
      {
        timestamp: 1398459600,
        open: 1.38331,
        close: 1.38357,
        high: 1.38491,
        low: 1.38275
      },
      {
        timestamp: 1398632400,
        open: 1.38357,
        close: 1.38478,
        high: 1.38509,
        low: 1.38379
      },
      {
        timestamp: 1398718800,
        open: 1.38478,
        close: 1.38524,
        high: 1.38803,
        low: 1.38155
      },
      {
        timestamp: 1398805200,
        open: 1.38524,
        close: 1.38134,
        high: 1.388,
        low: 1.38061
      },
      {
        timestamp: 1398891600,
        open: 1.38134,
        close: 1.38694,
        high: 1.38774,
        low: 1.3773
      },
      {
        timestamp: 1398978000,
        open: 1.38694,
        close: 1.38715,
        high: 1.38899,
        low: 1.38635
      },
      {
        timestamp: 1399064400,
        open: 1.38715,
        close: 1.38737,
        high: 1.38825,
        low: 1.3813
      },
      {
        timestamp: 1399237200,
        open: 1.38737,
        close: 1.3889,
        high: 1.38898,
        low: 1.38756
      },
      {
        timestamp: 1399323600,
        open: 1.3889,
        close: 1.38764,
        high: 1.38886,
        low: 1.38655
      },
      {
        timestamp: 1399410000,
        open: 1.38764,
        close: 1.39293,
        high: 1.39521,
        low: 1.3873
      },
      {
        timestamp: 1399496400,
        open: 1.39293,
        close: 1.39111,
        high: 1.39396,
        low: 1.3911
      },
      {
        timestamp: 1399582800,
        open: 1.39111,
        close: 1.3841,
        high: 1.39945,
        low: 1.38335
      },
      {
        timestamp: 1399669200,
        open: 1.3841,
        close: 1.37624,
        high: 1.38452,
        low: 1.37456
      },
      {
        timestamp: 1399842000,
        open: 1.37624,
        close: 1.37546,
        high: 1.37642,
        low: 1.37518
      },
      {
        timestamp: 1399928400,
        open: 1.37546,
        close: 1.37585,
        high: 1.37757,
        low: 1.37501
      },
      {
        timestamp: 1400014800,
        open: 1.37585,
        close: 1.37042,
        high: 1.37722,
        low: 1.36897
      },
      {
        timestamp: 1400101200,
        open: 1.37042,
        close: 1.37155,
        high: 1.37316,
        low: 1.36987
      },
      {
        timestamp: 1400187600,
        open: 1.37155,
        close: 1.37103,
        high: 1.37331,
        low: 1.36491
      },
      {
        timestamp: 1400274000,
        open: 1.37103,
        close: 1.36986,
        high: 1.37276,
        low: 1.36861
      },
      {
        timestamp: 1400446800,
        open: 1.36986,
        close: 1.36951,
        high: 1.37113,
        low: 1.36917
      },
      {
        timestamp: 1400533200,
        open: 1.36951,
        close: 1.371,
        high: 1.3735,
        low: 1.3694
      },
      {
        timestamp: 1400619600,
        open: 1.371,
        close: 1.37026,
        high: 1.37146,
        low: 1.36787
      },
      {
        timestamp: 1400706000,
        open: 1.37026,
        close: 1.36877,
        high: 1.37243,
        low: 1.36352
      },
      {
        timestamp: 1400792400,
        open: 1.36877,
        close: 1.36566,
        high: 1.36889,
        low: 1.36461
      },
      {
        timestamp: 1400878800,
        open: 1.36566,
        close: 1.36354,
        high: 1.36605,
        low: 1.36164
      },
      {
        timestamp: 1401051600,
        open: 1.36354,
        close: 1.36231,
        high: 1.36364,
        low: 1.36199
      },
      {
        timestamp: 1401138000,
        open: 1.36231,
        close: 1.36467,
        high: 1.36552,
        low: 1.36163
      },
      {
        timestamp: 1401224400,
        open: 1.36467,
        close: 1.36355,
        high: 1.36694,
        low: 1.36132
      },
      {
        timestamp: 1401310800,
        open: 1.36355,
        close: 1.35918,
        high: 1.36385,
        low: 1.35893
      },
      {
        timestamp: 1401397200,
        open: 1.35918,
        close: 1.36027,
        high: 1.36279,
        low: 1.35869
      },
      {
        timestamp: 1401483600,
        open: 1.36027,
        close: 1.36358,
        high: 1.3651,
        low: 1.35993
      },
      {
        timestamp: 1401656400,
        open: 1.36358,
        close: 1.36402,
        high: 1.36471,
        low: 1.36371
      },
      {
        timestamp: 1401742800,
        open: 1.36402,
        close: 1.35976,
        high: 1.36421,
        low: 1.35885
      },
      {
        timestamp: 1401829200,
        open: 1.35976,
        close: 1.36285,
        high: 1.36486,
        low: 1.35876
      },
      {
        timestamp: 1401915600,
        open: 1.36285,
        close: 1.35997,
        high: 1.364,
        low: 1.35972
      },
      {
        timestamp: 1402002000,
        open: 1.35997,
        close: 1.36613,
        high: 1.36707,
        low: 1.35037
      },
      {
        timestamp: 1402088400,
        open: 1.36613,
        close: 1.36457,
        high: 1.36778,
        low: 1.36218
      },
      {
        timestamp: 1402261200,
        open: 1.36457,
        close: 1.36472,
        high: 1.36513,
        low: 1.3643
      },
      {
        timestamp: 1402347600,
        open: 1.36472,
        close: 1.35946,
        high: 1.36694,
        low: 1.35832
      },
      {
        timestamp: 1402434000,
        open: 1.35946,
        close: 1.35496,
        high: 1.36028,
        low: 1.35343
      },
      {
        timestamp: 1402520400,
        open: 1.35496,
        close: 1.35323,
        high: 1.35579,
        low: 1.35223
      },
      {
        timestamp: 1402606800,
        open: 1.35323,
        close: 1.35531,
        high: 1.35726,
        low: 1.3513
      },
      {
        timestamp: 1402693200,
        open: 1.35531,
        close: 1.35421,
        high: 1.35797,
        low: 1.35218
      },
      {
        timestamp: 1402866000,
        open: 1.35421,
        close: 1.3544,
        high: 1.35453,
        low: 1.35345
      },
      {
        timestamp: 1402952400,
        open: 1.3544,
        close: 1.35743,
        high: 1.35802,
        low: 1.35138
      },
      {
        timestamp: 1403038800,
        open: 1.35743,
        close: 1.3548,
        high: 1.35878,
        low: 1.35368
      },
      {
        timestamp: 1403125200,
        open: 1.3548,
        close: 1.35963,
        high: 1.36007,
        low: 1.35424
      },
      {
        timestamp: 1403211600,
        open: 1.35963,
        close: 1.36093,
        high: 1.36443,
        low: 1.35851
      },
      {
        timestamp: 1403298000,
        open: 1.36093,
        close: 1.36019,
        high: 1.36348,
        low: 1.35651
      },
      {
        timestamp: 1403470800,
        open: 1.36019,
        close: 1.35908,
        high: 1.36009,
        low: 1.35891
      },
      {
        timestamp: 1403557200,
        open: 1.35908,
        close: 1.36055,
        high: 1.36143,
        low: 1.35749
      },
      {
        timestamp: 1403643600,
        open: 1.36055,
        close: 1.36069,
        high: 1.36285,
        low: 1.3584
      },
      {
        timestamp: 1403730000,
        open: 1.36069,
        close: 1.36299,
        high: 1.36521,
        low: 1.36011
      },
      {
        timestamp: 1403816400,
        open: 1.36299,
        close: 1.36131,
        high: 1.36421,
        low: 1.35767
      },
      {
        timestamp: 1403902800,
        open: 1.36131,
        close: 1.36508,
        high: 1.36517,
        low: 1.36103
      },
      {
        timestamp: 1404075600,
        open: 1.36508,
        close: 1.365,
        high: 1.36527,
        low: 1.36455
      },
      {
        timestamp: 1404162000,
        open: 1.365,
        close: 1.36929,
        high: 1.36986,
        low: 1.365
      },
      {
        timestamp: 1404248400,
        open: 1.36929,
        close: 1.368,
        high: 1.37014,
        low: 1.36763
      },
      {
        timestamp: 1404334800,
        open: 1.368,
        close: 1.36601,
        high: 1.36833,
        low: 1.36427
      },
      {
        timestamp: 1404421200,
        open: 1.36601,
        close: 1.3611,
        high: 1.36653,
        low: 1.35968
      },
      {
        timestamp: 1404507600,
        open: 1.3611,
        close: 1.35958,
        high: 1.36122,
        low: 1.35859
      },
      {
        timestamp: 1404680400,
        open: 1.35958,
        close: 1.35978,
        high: 1.3606,
        low: 1.35943
      },
      {
        timestamp: 1404766800,
        open: 1.35978,
        close: 1.36055,
        high: 1.36099,
        low: 1.35768
      },
      {
        timestamp: 1404853200,
        open: 1.36055,
        close: 1.36131,
        high: 1.3618,
        low: 1.35889
      },
      {
        timestamp: 1404939600,
        open: 1.36131,
        close: 1.36428,
        high: 1.36493,
        low: 1.3603
      },
      {
        timestamp: 1405026000,
        open: 1.36428,
        close: 1.361,
        high: 1.36515,
        low: 1.35898
      },
      {
        timestamp: 1405112400,
        open: 1.361,
        close: 1.36089,
        high: 1.36255,
        low: 1.35925
      },
      {
        timestamp: 1405285200,
        open: 1.36089,
        close: 1.36013,
        high: 1.36088,
        low: 1.36008
      },
      {
        timestamp: 1405371600,
        open: 1.36013,
        close: 1.362,
        high: 1.3641,
        low: 1.35983
      },
      {
        timestamp: 1405458000,
        open: 1.362,
        close: 1.35693,
        high: 1.36288,
        low: 1.35627
      },
      {
        timestamp: 1405544400,
        open: 1.35693,
        close: 1.35279,
        high: 1.35731,
        low: 1.35217
      },
      {
        timestamp: 1405630800,
        open: 1.35279,
        close: 1.35294,
        high: 1.3541,
        low: 1.35171
      },
      {
        timestamp: 1405717200,
        open: 1.35294,
        close: 1.35273,
        high: 1.35366,
        low: 1.34915
      },
      {
        timestamp: 1405890000,
        open: 1.35273,
        close: 1.35326,
        high: 1.35326,
        low: 1.35273
      },
      {
        timestamp: 1405976400,
        open: 1.35326,
        close: 1.35244,
        high: 1.35499,
        low: 1.35137
      },
      {
        timestamp: 1406062800,
        open: 1.35244,
        close: 1.34666,
        high: 1.35304,
        low: 1.34594
      },
      {
        timestamp: 1406149200,
        open: 1.34666,
        close: 1.34653,
        high: 1.34749,
        low: 1.34558
      },
      {
        timestamp: 1406235600,
        open: 1.34653,
        close: 1.34648,
        high: 1.34857,
        low: 1.34389
      },
      {
        timestamp: 1406322000,
        open: 1.34648,
        close: 1.34316,
        high: 1.34764,
        low: 1.3422
      },
      {
        timestamp: 1406494800,
        open: 1.34316,
        close: 1.34333,
        high: 1.34383,
        low: 1.343
      },
      {
        timestamp: 1406581200,
        open: 1.34333,
        close: 1.34408,
        high: 1.34447,
        low: 1.34277
      },
      {
        timestamp: 1406667600,
        open: 1.34408,
        close: 1.34097,
        high: 1.34452,
        low: 1.34049
      },
      {
        timestamp: 1406754000,
        open: 1.34097,
        close: 1.33981,
        high: 1.3416,
        low: 1.33677
      },
      {
        timestamp: 1406840400,
        open: 1.33981,
        close: 1.33913,
        high: 1.34013,
        low: 1.3372
      },
      {
        timestamp: 1406926800,
        open: 1.33913,
        close: 1.34342,
        high: 1.34456,
        low: 1.33792
      },
      {
        timestamp: 1407099600,
        open: 1.34342,
        close: 1.34379,
        high: 1.34379,
        low: 1.34246
      },
      {
        timestamp: 1407186000,
        open: 1.34379,
        close: 1.34227,
        high: 1.34366,
        low: 1.34102
      },
      {
        timestamp: 1407272400,
        open: 1.34227,
        close: 1.33771,
        high: 1.34255,
        low: 1.33591
      },
      {
        timestamp: 1407358800,
        open: 1.33771,
        close: 1.33832,
        high: 1.33878,
        low: 1.33338
      },
      {
        timestamp: 1407445200,
        open: 1.33832,
        close: 1.33643,
        high: 1.33947,
        low: 1.33379
      },
      {
        timestamp: 1407531600,
        open: 1.33643,
        close: 1.34135,
        high: 1.34336,
        low: 1.33443
      },
      {
        timestamp: 1407704400,
        open: 1.34135,
        close: 1.34129,
        high: 1.34196,
        low: 1.34022
      },
      {
        timestamp: 1407790800,
        open: 1.34129,
        close: 1.33859,
        high: 1.34166,
        low: 1.33809
      },
      {
        timestamp: 1407877200,
        open: 1.33859,
        close: 1.33701,
        high: 1.33869,
        low: 1.33368
      },
      {
        timestamp: 1407963600,
        open: 1.33701,
        close: 1.33651,
        high: 1.34159,
        low: 1.3343
      },
      {
        timestamp: 1408050000,
        open: 1.33651,
        close: 1.33661,
        high: 1.34085,
        low: 1.33492
      },
      {
        timestamp: 1408136400,
        open: 1.33661,
        close: 1.34027,
        high: 1.34126,
        low: 1.33595
      },
      {
        timestamp: 1408309200,
        open: 1.34027,
        close: 1.33946,
        high: 1.33992,
        low: 1.33836
      },
      {
        timestamp: 1408395600,
        open: 1.33946,
        close: 1.33643,
        high: 1.34006,
        low: 1.33537
      },
      {
        timestamp: 1408482000,
        open: 1.33643,
        close: 1.33208,
        high: 1.33653,
        low: 1.33139
      },
      {
        timestamp: 1408568400,
        open: 1.33208,
        close: 1.32604,
        high: 1.33251,
        low: 1.3256
      },
      {
        timestamp: 1408654800,
        open: 1.32604,
        close: 1.32819,
        high: 1.32892,
        low: 1.32427
      },
      {
        timestamp: 1408741200,
        open: 1.32819,
        close: 1.32462,
        high: 1.32973,
        low: 1.32215
      },
      {
        timestamp: 1408914000,
        open: 1.32462,
        close: 1.31979,
        high: 1.32292,
        low: 1.31843
      },
      {
        timestamp: 1409000400,
        open: 1.31979,
        close: 1.3193,
        high: 1.32112,
        low: 1.31846
      },
      {
        timestamp: 1409086800,
        open: 1.3193,
        close: 1.31686,
        high: 1.32156,
        low: 1.31653
      },
      {
        timestamp: 1409173200,
        open: 1.31686,
        close: 1.31938,
        high: 1.32106,
        low: 1.31535
      },
      {
        timestamp: 1409259600,
        open: 1.31938,
        close: 1.31829,
        high: 1.32216,
        low: 1.31604
      },
      {
        timestamp: 1409346000,
        open: 1.31829,
        close: 1.31332,
        high: 1.31969,
        low: 1.3133
      },
      {
        timestamp: 1409518800,
        open: 1.31332,
        close: 1.31296,
        high: 1.31434,
        low: 1.31281
      },
      {
        timestamp: 1409605200,
        open: 1.31296,
        close: 1.31295,
        high: 1.31462,
        low: 1.31198
      },
      {
        timestamp: 1409691600,
        open: 1.31295,
        close: 1.3134,
        high: 1.31375,
        low: 1.31107
      },
      {
        timestamp: 1409778000,
        open: 1.3134,
        close: 1.31513,
        high: 1.31608,
        low: 1.31229
      },
      {
        timestamp: 1409864400,
        open: 1.31513,
        close: 1.29448,
        high: 1.31549,
        low: 1.29209
      },
      {
        timestamp: 1409950800,
        open: 1.29448,
        close: 1.29548,
        high: 1.29901,
        low: 1.29227
      },
      {
        timestamp: 1410123600,
        open: 1.29548,
        close: 1.29621,
        high: 1.29637,
        low: 1.29326
      },
      {
        timestamp: 1410210000,
        open: 1.29621,
        close: 1.28958,
        high: 1.29633,
        low: 1.28821
      },
      {
        timestamp: 1410296400,
        open: 1.28958,
        close: 1.29386,
        high: 1.29582,
        low: 1.28601
      },
      {
        timestamp: 1410382800,
        open: 1.29386,
        close: 1.29188,
        high: 1.29637,
        low: 1.28845
      },
      {
        timestamp: 1410469200,
        open: 1.29188,
        close: 1.29257,
        high: 1.29526,
        low: 1.28978
      },
      {
        timestamp: 1410555600,
        open: 1.29257,
        close: 1.29709,
        high: 1.298,
        low: 1.29095
      },
      {
        timestamp: 1410728400,
        open: 1.29709,
        close: 1.29763,
        high: 1.29814,
        low: 1.29709
      },
      {
        timestamp: 1410814800,
        open: 1.29763,
        close: 1.2947,
        high: 1.29791,
        low: 1.29094
      },
      {
        timestamp: 1410901200,
        open: 1.2947,
        close: 1.2964,
        high: 1.2996,
        low: 1.2923
      },
      {
        timestamp: 1410987600,
        open: 1.2964,
        close: 1.28671,
        high: 1.29837,
        low: 1.2853
      },
      {
        timestamp: 1411074000,
        open: 1.28671,
        close: 1.29246,
        high: 1.29314,
        low: 1.2835
      },
      {
        timestamp: 1411160400,
        open: 1.29246,
        close: 1.28296,
        high: 1.29297,
        low: 1.28295
      },
      {
        timestamp: 1411333200,
        open: 1.28296,
        close: 1.28382,
        high: 1.28382,
        low: 1.28291
      },
      {
        timestamp: 1411419600,
        open: 1.28382,
        close: 1.28522,
        high: 1.28688,
        low: 1.2817
      },
      {
        timestamp: 1411506000,
        open: 1.28522,
        close: 1.28482,
        high: 1.29019,
        low: 1.28433
      },
      {
        timestamp: 1411592400,
        open: 1.28482,
        close: 1.27838,
        high: 1.28647,
        low: 1.27748
      },
      {
        timestamp: 1411678800,
        open: 1.27838,
        close: 1.27532,
        high: 1.2784,
        low: 1.26975
      },
      {
        timestamp: 1411765200,
        open: 1.27532,
        close: 1.26849,
        high: 1.27615,
        low: 1.26776
      },
      {
        timestamp: 1411938000,
        open: 1.26849,
        close: 1.26849,
        high: 1.27028,
        low: 1.26823
      },
      {
        timestamp: 1412024400,
        open: 1.26849,
        close: 1.2687,
        high: 1.27158,
        low: 1.26645
      },
      {
        timestamp: 1412110800,
        open: 1.2687,
        close: 1.26319,
        high: 1.27032,
        low: 1.25719
      },
      {
        timestamp: 1412197200,
        open: 1.26319,
        close: 1.26241,
        high: 1.26406,
        low: 1.25844
      },
      {
        timestamp: 1412283600,
        open: 1.26241,
        close: 1.26714,
        high: 1.26997,
        low: 1.26144
      },
      {
        timestamp: 1412370000,
        open: 1.26714,
        close: 1.25152,
        high: 1.26759,
        low: 1.25013
      },
      {
        timestamp: 1412542800,
        open: 1.25152,
        close: 1.25137,
        high: 1.25176,
        low: 1.25102
      },
      {
        timestamp: 1412629200,
        open: 1.25137,
        close: 1.26565,
        high: 1.26759,
        low: 1.25098
      },
      {
        timestamp: 1412715600,
        open: 1.26565,
        close: 1.26701,
        high: 1.26829,
        low: 1.25844
      },
      {
        timestamp: 1412802000,
        open: 1.26701,
        close: 1.27371,
        high: 1.27498,
        low: 1.26234
      },
      {
        timestamp: 1412888400,
        open: 1.27371,
        close: 1.26939,
        high: 1.27923,
        low: 1.26649
      },
      {
        timestamp: 1412974800,
        open: 1.26939,
        close: 1.26323,
        high: 1.27169,
        low: 1.26063
      },
      {
        timestamp: 1413147600,
        open: 1.26323,
        close: 1.26319,
        high: 1.26542,
        low: 1.26307
      },
      {
        timestamp: 1413234000,
        open: 1.26319,
        close: 1.27564,
        high: 1.27622,
        low: 1.26219
      },
      {
        timestamp: 1413320400,
        open: 1.27564,
        close: 1.26595,
        high: 1.27697,
        low: 1.26407
      },
      {
        timestamp: 1413406800,
        open: 1.26595,
        close: 1.28386,
        high: 1.28882,
        low: 1.26254
      },
      {
        timestamp: 1413493200,
        open: 1.28386,
        close: 1.28102,
        high: 1.28461,
        low: 1.27068
      },
      {
        timestamp: 1413579600,
        open: 1.28102,
        close: 1.27625,
        high: 1.28378,
        low: 1.27447
      },
      {
        timestamp: 1413752400,
        open: 1.27625,
        close: 1.27518,
        high: 1.27688,
        low: 1.27457
      },
      {
        timestamp: 1413838800,
        open: 1.27518,
        close: 1.28011,
        high: 1.28176,
        low: 1.27321
      },
      {
        timestamp: 1413925200,
        open: 1.28011,
        close: 1.27172,
        high: 1.28409,
        low: 1.27153
      },
      {
        timestamp: 1414011600,
        open: 1.27172,
        close: 1.26563,
        high: 1.27405,
        low: 1.26381
      },
      {
        timestamp: 1414098000,
        open: 1.26563,
        close: 1.26479,
        high: 1.26776,
        low: 1.26145
      },
      {
        timestamp: 1414184400,
        open: 1.26479,
        close: 1.26736,
        high: 1.26966,
        low: 1.26357
      },
      {
        timestamp: 1414357200,
        open: 1.26736,
        close: 1.26791,
        high: 1.26998,
        low: 1.26736
      },
      {
        timestamp: 1414443600,
        open: 1.26791,
        close: 1.26996,
        high: 1.27241,
        low: 1.26664
      },
      {
        timestamp: 1414530000,
        open: 1.26996,
        close: 1.27354,
        high: 1.27657,
        low: 1.26854
      },
      {
        timestamp: 1414616400,
        open: 1.27354,
        close: 1.26329,
        high: 1.27715,
        low: 1.26316
      },
      {
        timestamp: 1414702800,
        open: 1.26329,
        close: 1.26148,
        high: 1.26401,
        low: 1.25479
      },
      {
        timestamp: 1414789200,
        open: 1.26148,
        close: 1.25246,
        high: 1.26182,
        low: 1.24863
      },
      {
        timestamp: 1414965600,
        open: 1.25246,
        close: 1.2511,
        high: 1.25123,
        low: 1.25015
      },
      {
        timestamp: 1415052000,
        open: 1.2511,
        close: 1.24828,
        high: 1.25169,
        low: 1.24402
      },
      {
        timestamp: 1415138400,
        open: 1.24828,
        close: 1.25494,
        high: 1.25785,
        low: 1.24828
      },
      {
        timestamp: 1415224800,
        open: 1.25494,
        close: 1.24867,
        high: 1.2568,
        low: 1.24581
      },
      {
        timestamp: 1415311200,
        open: 1.24867,
        close: 1.23767,
        high: 1.25343,
        low: 1.23647
      },
      {
        timestamp: 1415397600,
        open: 1.23767,
        close: 1.24571,
        high: 1.24708,
        low: 1.23588
      },
      {
        timestamp: 1415570400,
        open: 1.24571,
        close: 1.24739,
        high: 1.24874,
        low: 1.24571
      },
      {
        timestamp: 1415656800,
        open: 1.24739,
        close: 1.24216,
        high: 1.25105,
        low: 1.24195
      },
      {
        timestamp: 1415743200,
        open: 1.24216,
        close: 1.24768,
        high: 1.25002,
        low: 1.2395
      },
      {
        timestamp: 1415829600,
        open: 1.24768,
        close: 1.24407,
        high: 1.24986,
        low: 1.242
      },
      {
        timestamp: 1415916000,
        open: 1.24407,
        close: 1.24776,
        high: 1.24926,
        low: 1.24274
      },
      {
        timestamp: 1416002400,
        open: 1.24776,
        close: 1.25275,
        high: 1.25474,
        low: 1.23991
      },
      {
        timestamp: 1416175200,
        open: 1.25275,
        close: 1.25335,
        high: 1.25402,
        low: 1.25275
      },
      {
        timestamp: 1416261600,
        open: 1.25335,
        close: 1.24523,
        high: 1.25786,
        low: 1.24458
      },
      {
        timestamp: 1416348000,
        open: 1.24523,
        close: 1.25373,
        high: 1.25461,
        low: 1.24443
      },
      {
        timestamp: 1416434400,
        open: 1.25373,
        close: 1.2556,
        high: 1.26017,
        low: 1.25132
      },
      {
        timestamp: 1416520800,
        open: 1.2556,
        close: 1.25426,
        high: 1.25762,
        low: 1.25051
      },
      {
        timestamp: 1416607200,
        open: 1.25426,
        close: 1.23925,
        high: 1.25691,
        low: 1.23756
      },
      {
        timestamp: 1416780000,
        open: 1.23925,
        close: 1.2364,
        high: 1.23751,
        low: 1.23602
      },
      {
        timestamp: 1416866400,
        open: 1.2364,
        close: 1.24437,
        high: 1.24455,
        low: 1.23626
      },
      {
        timestamp: 1416952800,
        open: 1.24437,
        close: 1.24768,
        high: 1.24879,
        low: 1.24026
      },
      {
        timestamp: 1417039200,
        open: 1.24768,
        close: 1.25073,
        high: 1.25324,
        low: 1.24445
      },
      {
        timestamp: 1417125600,
        open: 1.25073,
        close: 1.24687,
        high: 1.25244,
        low: 1.24654
      },
      {
        timestamp: 1417212000,
        open: 1.24687,
        close: 1.24556,
        high: 1.24911,
        low: 1.24272
      },
      {
        timestamp: 1417384800,
        open: 1.24556,
        close: 1.24652,
        high: 1.2476,
        low: 1.24556
      },
      {
        timestamp: 1417471200,
        open: 1.24652,
        close: 1.24706,
        high: 1.25075,
        low: 1.242
      },
      {
        timestamp: 1417557600,
        open: 1.24706,
        close: 1.23841,
        high: 1.24769,
        low: 1.23776
      },
      {
        timestamp: 1417644000,
        open: 1.23841,
        close: 1.2312,
        high: 1.23914,
        low: 1.2302
      },
      {
        timestamp: 1417730400,
        open: 1.2312,
        close: 1.23799,
        high: 1.24573,
        low: 1.22814
      },
      {
        timestamp: 1417816800,
        open: 1.23799,
        close: 1.22882,
        high: 1.23939,
        low: 1.22716
      },
      {
        timestamp: 1417989600,
        open: 1.22882,
        close: 1.22907,
        high: 1.22928,
        low: 1.22804
      },
      {
        timestamp: 1418076000,
        open: 1.22907,
        close: 1.23186,
        high: 1.23451,
        low: 1.2248
      },
      {
        timestamp: 1418162400,
        open: 1.23186,
        close: 1.2377,
        high: 1.24492,
        low: 1.22928
      },
      {
        timestamp: 1418248800,
        open: 1.2377,
        close: 1.245,
        high: 1.24509,
        low: 1.2363
      },
      {
        timestamp: 1418335200,
        open: 1.245,
        close: 1.24137,
        high: 1.24961,
        low: 1.23709
      },
      {
        timestamp: 1418421600,
        open: 1.24137,
        close: 1.24626,
        high: 1.24861,
        low: 1.23852
      },
      {
        timestamp: 1418594400,
        open: 1.24626,
        close: 1.24797,
        high: 1.24885,
        low: 1.24626
      },
      {
        timestamp: 1418680800,
        open: 1.24797,
        close: 1.24396,
        high: 1.24801,
        low: 1.24153
      },
      {
        timestamp: 1418767200,
        open: 1.24396,
        close: 1.25123,
        high: 1.25711,
        low: 1.24354
      },
      {
        timestamp: 1418853600,
        open: 1.25123,
        close: 1.23442,
        high: 1.25167,
        low: 1.23214
      },
      {
        timestamp: 1418940000,
        open: 1.23442,
        close: 1.22883,
        high: 1.23538,
        low: 1.22666
      },
      {
        timestamp: 1419026400,
        open: 1.22883,
        close: 1.22337,
        high: 1.2303,
        low: 1.22211
      },
      {
        timestamp: 1419199200,
        open: 1.22337,
        close: 1.22263,
        high: 1.2227,
        low: 1.22221
      },
      {
        timestamp: 1419285600,
        open: 1.22263,
        close: 1.22315,
        high: 1.22735,
        low: 1.22174
      },
      {
        timestamp: 1419372000,
        open: 1.22315,
        close: 1.21751,
        high: 1.22466,
        low: 1.21656
      },
      {
        timestamp: 1419458400,
        open: 1.21751,
        close: 1.22045,
        high: 1.22212,
        low: 1.21703
      },
      {
        timestamp: 1419631200,
        open: 1.22045,
        close: 1.21854,
        high: 1.22221,
        low: 1.21693
      },
      {
        timestamp: 1419804000,
        open: 1.21854,
        close: 1.21845,
        high: 1.21921,
        low: 1.21818
      },
      {
        timestamp: 1419890400,
        open: 1.21845,
        close: 1.21544,
        high: 1.22222,
        low: 1.21434
      },
      {
        timestamp: 1419976800,
        open: 1.21544,
        close: 1.21688,
        high: 1.21885,
        low: 1.21249
      },
      {
        timestamp: 1420063200,
        open: 1.21688,
        close: 1.21008,
        high: 1.21706,
        low: 1.20983
      },
      {
        timestamp: 1420236000,
        open: 1.21008,
        close: 1.20037,
        high: 1.20707,
        low: 1.20019
      },
      {
        timestamp: 1420408800,
        open: 1.20037,
        close: 1.19564,
        high: 1.201,
        low: 1.19524
      },
      {
        timestamp: 1420495200,
        open: 1.19564,
        close: 1.19346,
        high: 1.19773,
        low: 1.18746
      },
      {
        timestamp: 1420581600,
        open: 1.19346,
        close: 1.18927,
        high: 1.19696,
        low: 1.1885
      },
      {
        timestamp: 1420668000,
        open: 1.18927,
        close: 1.18421,
        high: 1.18974,
        low: 1.18028
      },
      {
        timestamp: 1420754400,
        open: 1.18421,
        close: 1.17957,
        high: 1.18484,
        low: 1.1755
      },
      {
        timestamp: 1420840800,
        open: 1.17957,
        close: 1.18458,
        high: 1.18479,
        low: 1.17633
      },
      {
        timestamp: 1421013600,
        open: 1.18458,
        close: 1.18537,
        high: 1.18578,
        low: 1.18425
      },
      {
        timestamp: 1421100000,
        open: 1.18537,
        close: 1.18346,
        high: 1.18718,
        low: 1.17871
      },
      {
        timestamp: 1421186400,
        open: 1.18346,
        close: 1.1775,
        high: 1.18605,
        low: 1.17539
      },
      {
        timestamp: 1421272800,
        open: 1.1775,
        close: 1.17902,
        high: 1.18471,
        low: 1.17281
      },
      {
        timestamp: 1421359200,
        open: 1.17902,
        close: 1.16338,
        high: 1.1794,
        low: 1.15685
      },
      {
        timestamp: 1421445600,
        open: 1.16338,
        close: 1.15674,
        high: 1.16499,
        low: 1.14609
      },
      {
        timestamp: 1421618400,
        open: 1.15674,
        close: 1.1558,
        high: 1.15614,
        low: 1.15472
      },
      {
        timestamp: 1421704800,
        open: 1.1558,
        close: 1.16059,
        high: 1.16398,
        low: 1.15523
      },
      {
        timestamp: 1421791200,
        open: 1.16059,
        close: 1.15512,
        high: 1.16168,
        low: 1.15413
      },
      {
        timestamp: 1421877600,
        open: 1.15512,
        close: 1.16127,
        high: 1.16825,
        low: 1.15423
      },
      {
        timestamp: 1421964000,
        open: 1.16127,
        close: 1.13667,
        high: 1.16734,
        low: 1.13172
      },
      {
        timestamp: 1422050400,
        open: 1.13667,
        close: 1.12086,
        high: 1.13749,
        low: 1.11154
      },
      {
        timestamp: 1422223200,
        open: 1.12086,
        close: 1.11569,
        high: 1.11728,
        low: 1.1141
      },
      {
        timestamp: 1422309600,
        open: 1.11569,
        close: 1.12397,
        high: 1.12966,
        low: 1.10987
      },
      {
        timestamp: 1422396000,
        open: 1.12397,
        close: 1.1382,
        high: 1.14238,
        low: 1.12247
      },
      {
        timestamp: 1422482400,
        open: 1.1382,
        close: 1.12896,
        high: 1.13843,
        low: 1.12768
      },
      {
        timestamp: 1422568800,
        open: 1.12896,
        close: 1.13207,
        high: 1.13688,
        low: 1.12626
      },
      {
        timestamp: 1422655200,
        open: 1.13207,
        close: 1.12941,
        high: 1.13645,
        low: 1.12798
      },
      {
        timestamp: 1422828000,
        open: 1.12941,
        close: 1.13163,
        high: 1.13299,
        low: 1.12941
      },
      {
        timestamp: 1422914400,
        open: 1.13163,
        close: 1.13444,
        high: 1.13629,
        low: 1.12933
      },
      {
        timestamp: 1423000800,
        open: 1.13444,
        close: 1.14839,
        high: 1.15352,
        low: 1.13132
      },
      {
        timestamp: 1423087200,
        open: 1.14839,
        close: 1.13487,
        high: 1.14858,
        low: 1.13165
      },
      {
        timestamp: 1423173600,
        open: 1.13487,
        close: 1.14796,
        high: 1.14996,
        low: 1.13049
      },
      {
        timestamp: 1423260000,
        open: 1.14796,
        close: 1.13154,
        high: 1.1487,
        low: 1.13126
      },
      {
        timestamp: 1423432800,
        open: 1.13154,
        close: 1.12973,
        high: 1.1323,
        low: 1.1287
      },
      {
        timestamp: 1423519200,
        open: 1.12973,
        close: 1.1326,
        high: 1.13603,
        low: 1.12711
      },
      {
        timestamp: 1423605600,
        open: 1.1326,
        close: 1.13219,
        high: 1.13461,
        low: 1.12736
      },
      {
        timestamp: 1423692000,
        open: 1.13219,
        close: 1.1343,
        high: 1.13481,
        low: 1.12805
      },
      {
        timestamp: 1423778400,
        open: 1.1343,
        close: 1.14057,
        high: 1.1424,
        low: 1.1303
      },
      {
        timestamp: 1423864800,
        open: 1.14057,
        close: 1.1391,
        high: 1.1444,
        low: 1.13804
      },
      {
        timestamp: 1424037600,
        open: 1.1391,
        close: 1.1402,
        high: 1.14285,
        low: 1.1391
      },
      {
        timestamp: 1424124000,
        open: 1.1402,
        close: 1.1358,
        high: 1.14298,
        low: 1.13204
      },
      {
        timestamp: 1424210400,
        open: 1.1358,
        close: 1.14117,
        high: 1.14502,
        low: 1.13227
      },
      {
        timestamp: 1424296800,
        open: 1.14117,
        close: 1.13978,
        high: 1.14168,
        low: 1.13347
      },
      {
        timestamp: 1424383200,
        open: 1.13978,
        close: 1.13689,
        high: 1.14511,
        low: 1.13563
      },
      {
        timestamp: 1424469600,
        open: 1.13689,
        close: 1.13797,
        high: 1.1431,
        low: 1.12795
      },
      {
        timestamp: 1424642400,
        open: 1.13797,
        close: 1.13974,
        high: 1.14142,
        low: 1.13797
      },
      {
        timestamp: 1424728800,
        open: 1.13974,
        close: 1.13354,
        high: 1.13982,
        low: 1.1296
      },
      {
        timestamp: 1424815200,
        open: 1.13354,
        close: 1.13423,
        high: 1.1359,
        low: 1.12891
      },
      {
        timestamp: 1424901600,
        open: 1.13423,
        close: 1.1362,
        high: 1.13896,
        low: 1.13365
      },
      {
        timestamp: 1424988000,
        open: 1.1362,
        close: 1.11988,
        high: 1.13807,
        low: 1.11845
      },
      {
        timestamp: 1425074400,
        open: 1.11988,
        close: 1.11995,
        high: 1.12459,
        low: 1.11766
      },
      {
        timestamp: 1425247200,
        open: 1.11995,
        close: 1.11838,
        high: 1.11894,
        low: 1.11754
      },
      {
        timestamp: 1425333600,
        open: 1.11838,
        close: 1.11861,
        high: 1.12414,
        low: 1.11608
      },
      {
        timestamp: 1425420000,
        open: 1.11861,
        close: 1.11771,
        high: 1.12183,
        low: 1.11554
      },
      {
        timestamp: 1425506400,
        open: 1.11771,
        close: 1.10806,
        high: 1.11862,
        low: 1.10623
      },
      {
        timestamp: 1425592800,
        open: 1.10806,
        close: 1.10343,
        high: 1.11155,
        low: 1.09883
      },
      {
        timestamp: 1425679200,
        open: 1.10343,
        close: 1.08471,
        high: 1.1034,
        low: 1.08407
      },
      {
        timestamp: 1425848400,
        open: 1.08471,
        close: 1.08335,
        high: 1.08402,
        low: 1.08284
      },
      {
        timestamp: 1425934800,
        open: 1.08335,
        close: 1.08527,
        high: 1.09073,
        low: 1.08234
      },
      {
        timestamp: 1426021200,
        open: 1.08527,
        close: 1.06985,
        high: 1.08562,
        low: 1.06934
      },
      {
        timestamp: 1426107600,
        open: 1.06985,
        close: 1.05484,
        high: 1.07179,
        low: 1.05119
      },
      {
        timestamp: 1426194000,
        open: 1.05484,
        close: 1.06364,
        high: 1.06847,
        low: 1.04949
      },
      {
        timestamp: 1426280400,
        open: 1.06364,
        close: 1.05045,
        high: 1.06374,
        low: 1.04631
      },
      {
        timestamp: 1426453200,
        open: 1.05045,
        close: 1.04715,
        high: 1.04847,
        low: 1.04588
      },
      {
        timestamp: 1426539600,
        open: 1.04715,
        close: 1.0569,
        high: 1.06204,
        low: 1.04709
      },
      {
        timestamp: 1426626000,
        open: 1.0569,
        close: 1.05981,
        high: 1.06517,
        low: 1.05522
      },
      {
        timestamp: 1426712400,
        open: 1.05981,
        close: 1.08722,
        high: 1.1053,
        low: 1.0581
      },
      {
        timestamp: 1426798800,
        open: 1.08722,
        close: 1.06608,
        high: 1.09206,
        low: 1.06138
      },
      {
        timestamp: 1426885200,
        open: 1.06608,
        close: 1.08263,
        high: 1.08832,
        low: 1.06502
      },
      {
        timestamp: 1427058000,
        open: 1.08263,
        close: 1.08435,
        high: 1.08483,
        low: 1.08263
      },
      {
        timestamp: 1427144400,
        open: 1.08435,
        close: 1.09468,
        high: 1.09721,
        low: 1.07683
      },
      {
        timestamp: 1427230800,
        open: 1.09468,
        close: 1.09258,
        high: 1.10304,
        low: 1.08912
      },
      {
        timestamp: 1427317200,
        open: 1.09258,
        close: 1.09709,
        high: 1.10154,
        low: 1.09015
      },
      {
        timestamp: 1427403600,
        open: 1.09709,
        close: 1.08849,
        high: 1.10532,
        low: 1.08572
      },
      {
        timestamp: 1427490000,
        open: 1.08849,
        close: 1.08942,
        high: 1.09497,
        low: 1.08019
      },
      {
        timestamp: 1427662800,
        open: 1.08942,
        close: 1.08882,
        high: 1.09002,
        low: 1.08828
      },
      {
        timestamp: 1427749200,
        open: 1.08882,
        close: 1.08335,
        high: 1.08968,
        low: 1.08108
      },
      {
        timestamp: 1427835600,
        open: 1.08335,
        close: 1.0733,
        high: 1.08462,
        low: 1.07136
      },
      {
        timestamp: 1427922000,
        open: 1.0733,
        close: 1.0766,
        high: 1.08008,
        low: 1.07194
      },
      {
        timestamp: 1428008400,
        open: 1.0766,
        close: 1.08809,
        high: 1.09065,
        low: 1.07509
      },
      {
        timestamp: 1428094800,
        open: 1.08809,
        close: 1.09808,
        high: 1.1028,
        low: 1.08647
      },
      {
        timestamp: 1428267600,
        open: 1.09808,
        close: 1.10048,
        high: 1.10094,
        low: 1.09808
      },
      {
        timestamp: 1428354000,
        open: 1.10048,
        close: 1.09242,
        high: 1.10365,
        low: 1.09111
      },
      {
        timestamp: 1428440400,
        open: 1.09242,
        close: 1.08162,
        high: 1.09557,
        low: 1.08042
      },
      {
        timestamp: 1428526800,
        open: 1.08162,
        close: 1.07811,
        high: 1.08884,
        low: 1.07643
      },
      {
        timestamp: 1428613200,
        open: 1.07811,
        close: 1.06604,
        high: 1.07895,
        low: 1.06384
      },
      {
        timestamp: 1428699600,
        open: 1.06604,
        close: 1.06053,
        high: 1.06846,
        low: 1.05686
      },
      {
        timestamp: 1428872400,
        open: 1.06053,
        close: 1.05905,
        high: 1.06099,
        low: 1.05861
      },
      {
        timestamp: 1428958800,
        open: 1.05905,
        close: 1.05697,
        high: 1.06201,
        low: 1.05208
      },
      {
        timestamp: 1429045200,
        open: 1.05697,
        close: 1.0656,
        high: 1.07083,
        low: 1.05322
      },
      {
        timestamp: 1429131600,
        open: 1.0656,
        close: 1.06861,
        high: 1.07033,
        low: 1.05714
      },
      {
        timestamp: 1429218000,
        open: 1.06861,
        close: 1.07622,
        high: 1.08189,
        low: 1.06253
      },
      {
        timestamp: 1429304400,
        open: 1.07622,
        close: 1.08132,
        high: 1.08496,
        low: 1.07344
      },
      {
        timestamp: 1429477200,
        open: 1.08132,
        close: 1.08255,
        high: 1.08277,
        low: 1.08132
      },
      {
        timestamp: 1429563600,
        open: 1.08255,
        close: 1.07394,
        high: 1.08247,
        low: 1.07129
      },
      {
        timestamp: 1429650000,
        open: 1.07394,
        close: 1.07363,
        high: 1.07821,
        low: 1.06607
      },
      {
        timestamp: 1429736400,
        open: 1.07363,
        close: 1.07272,
        high: 1.08017,
        low: 1.07092
      },
      {
        timestamp: 1429822800,
        open: 1.07272,
        close: 1.08253,
        high: 1.08467,
        low: 1.0667
      },
      {
        timestamp: 1429909200,
        open: 1.08253,
        close: 1.08796,
        high: 1.0901,
        low: 1.07855
      },
      {
        timestamp: 1430082000,
        open: 1.08796,
        close: 1.08694,
        high: 1.08734,
        low: 1.0859
      },
      {
        timestamp: 1430168400,
        open: 1.08694,
        close: 1.08948,
        high: 1.09272,
        low: 1.08203
      },
      {
        timestamp: 1430254800,
        open: 1.08948,
        close: 1.09822,
        high: 1.09919,
        low: 1.0861
      },
      {
        timestamp: 1430341200,
        open: 1.09822,
        close: 1.11293,
        high: 1.11891,
        low: 1.09602
      },
      {
        timestamp: 1430427600,
        open: 1.11293,
        close: 1.1225,
        high: 1.12673,
        low: 1.10721
      },
      {
        timestamp: 1430514000,
        open: 1.1225,
        close: 1.11991,
        high: 1.12924,
        low: 1.11755
      },
      {
        timestamp: 1430686800,
        open: 1.11991,
        close: 1.1198,
        high: 1.12001,
        low: 1.11889
      },
      {
        timestamp: 1430773200,
        open: 1.1198,
        close: 1.11474,
        high: 1.12255,
        low: 1.11236
      },
      {
        timestamp: 1430859600,
        open: 1.11474,
        close: 1.11882,
        high: 1.1224,
        low: 1.1067
      },
      {
        timestamp: 1430946000,
        open: 1.11882,
        close: 1.13484,
        high: 1.13711,
        low: 1.11757
      },
      {
        timestamp: 1431032400,
        open: 1.13484,
        close: 1.12678,
        high: 1.13927,
        low: 1.1238
      },
      {
        timestamp: 1431118800,
        open: 1.12678,
        close: 1.12151,
        high: 1.12955,
        low: 1.11801
      },
      {
        timestamp: 1431291600,
        open: 1.12151,
        close: 1.12051,
        high: 1.12109,
        low: 1.11968
      },
      {
        timestamp: 1431378000,
        open: 1.12051,
        close: 1.1157,
        high: 1.12094,
        low: 1.11318
      },
      {
        timestamp: 1431464400,
        open: 1.1157,
        close: 1.12137,
        high: 1.12799,
        low: 1.11352
      },
      {
        timestamp: 1431550800,
        open: 1.12137,
        close: 1.13554,
        high: 1.13835,
        low: 1.12033
      },
      {
        timestamp: 1431637200,
        open: 1.13554,
        close: 1.14107,
        high: 1.1448,
        low: 1.13414
      },
      {
        timestamp: 1431723600,
        open: 1.14107,
        close: 1.14514,
        high: 1.14678,
        low: 1.13246
      },
      {
        timestamp: 1431896400,
        open: 1.14514,
        close: 1.1449,
        high: 1.14574,
        low: 1.14332
      },
      {
        timestamp: 1431982800,
        open: 1.1449,
        close: 1.13167,
        high: 1.14521,
        low: 1.12994
      },
      {
        timestamp: 1432069200,
        open: 1.13167,
        close: 1.11513,
        high: 1.13273,
        low: 1.11193
      },
      {
        timestamp: 1432155600,
        open: 1.11513,
        close: 1.10954,
        high: 1.11565,
        low: 1.1063
      },
      {
        timestamp: 1432242000,
        open: 1.10954,
        close: 1.11129,
        high: 1.11824,
        low: 1.10806
      },
      {
        timestamp: 1432328400,
        open: 1.11129,
        close: 1.10141,
        high: 1.12096,
        low: 1.1003
      },
      {
        timestamp: 1432501200,
        open: 1.10141,
        close: 1.10055,
        high: 1.10107,
        low: 1.10013
      },
      {
        timestamp: 1432587600,
        open: 1.10055,
        close: 1.09795,
        high: 1.10114,
        low: 1.09599
      },
      {
        timestamp: 1432674000,
        open: 1.09795,
        close: 1.08791,
        high: 1.09832,
        low: 1.08643
      },
      {
        timestamp: 1432760400,
        open: 1.08791,
        close: 1.09057,
        high: 1.09301,
        low: 1.08199
      },
      {
        timestamp: 1432846800,
        open: 1.09057,
        close: 1.09499,
        high: 1.09599,
        low: 1.08682
      },
      {
        timestamp: 1432933200,
        open: 1.09499,
        close: 1.09934,
        high: 1.10074,
        low: 1.09265
      },
      {
        timestamp: 1433106000,
        open: 1.09934,
        close: 1.09814,
        high: 1.09936,
        low: 1.09804
      },
      {
        timestamp: 1433192400,
        open: 1.09814,
        close: 1.09294,
        high: 1.09936,
        low: 1.0888
      },
      {
        timestamp: 1433278800,
        open: 1.09294,
        close: 1.1152,
        high: 1.11961,
        low: 1.09168
      },
      {
        timestamp: 1433365200,
        open: 1.1152,
        close: 1.12757,
        high: 1.12858,
        low: 1.10802
      },
      {
        timestamp: 1433451600,
        open: 1.12757,
        close: 1.12397,
        high: 1.13809,
        low: 1.12234
      },
      {
        timestamp: 1433538000,
        open: 1.12397,
        close: 1.11212,
        high: 1.12904,
        low: 1.10501
      },
      {
        timestamp: 1433710800,
        open: 1.11212,
        close: 1.10989,
        high: 1.11164,
        low: 1.1083
      },
      {
        timestamp: 1433797200,
        open: 1.10989,
        close: 1.12927,
        high: 1.13077,
        low: 1.10852
      },
      {
        timestamp: 1433883600,
        open: 1.12927,
        close: 1.12837,
        high: 1.13468,
        low: 1.12149
      },
      {
        timestamp: 1433970000,
        open: 1.12837,
        close: 1.1325,
        high: 1.13877,
        low: 1.12608
      },
      {
        timestamp: 1434056400,
        open: 1.1325,
        close: 1.12595,
        high: 1.1333,
        low: 1.11825
      },
      {
        timestamp: 1434142800,
        open: 1.12595,
        close: 1.1272,
        high: 1.12974,
        low: 1.11521
      },
      {
        timestamp: 1434315600,
        open: 1.1272,
        close: 1.12137,
        high: 1.12471,
        low: 1.1212
      },
      {
        timestamp: 1434402000,
        open: 1.12137,
        close: 1.12878,
        high: 1.12954,
        low: 1.11901
      },
      {
        timestamp: 1434488400,
        open: 1.12878,
        close: 1.12488,
        high: 1.13307,
        low: 1.12057
      },
      {
        timestamp: 1434574800,
        open: 1.12488,
        close: 1.13402,
        high: 1.13589,
        low: 1.1206
      },
      {
        timestamp: 1434661200,
        open: 1.13402,
        close: 1.13616,
        high: 1.14373,
        low: 1.13312
      },
      {
        timestamp: 1434747600,
        open: 1.13616,
        close: 1.13527,
        high: 1.14028,
        low: 1.12927
      },
      {
        timestamp: 1434920400,
        open: 1.13527,
        close: 1.1378,
        high: 1.13845,
        low: 1.13527
      },
      {
        timestamp: 1435006800,
        open: 1.1378,
        close: 1.13415,
        high: 1.14112,
        low: 1.13126
      },
      {
        timestamp: 1435093200,
        open: 1.13415,
        close: 1.11693,
        high: 1.13483,
        low: 1.11358
      },
      {
        timestamp: 1435179600,
        open: 1.11693,
        close: 1.12066,
        high: 1.12356,
        low: 1.11557
      },
      {
        timestamp: 1435266000,
        open: 1.12066,
        close: 1.1207,
        high: 1.12291,
        low: 1.11546
      },
      {
        timestamp: 1435352400,
        open: 1.1207,
        close: 1.11657,
        high: 1.12206,
        low: 1.11308
      },
      {
        timestamp: 1435525200,
        open: 1.11657,
        close: 1.10094,
        high: 1.10483,
        low: 1.0997
      },
      {
        timestamp: 1435611600,
        open: 1.10094,
        close: 1.1237,
        high: 1.12795,
        low: 1.0955
      },
      {
        timestamp: 1435698000,
        open: 1.1237,
        close: 1.11548,
        high: 1.12453,
        low: 1.11128
      },
      {
        timestamp: 1435784400,
        open: 1.11548,
        close: 1.10553,
        high: 1.1172,
        low: 1.10436
      },
      {
        timestamp: 1435870800,
        open: 1.10553,
        close: 1.10852,
        high: 1.11222,
        low: 1.10331
      },
      {
        timestamp: 1435957200,
        open: 1.10852,
        close: 1.11202,
        high: 1.11202,
        low: 1.10658
      },
      {
        timestamp: 1436130000,
        open: 1.11202,
        close: 1.09976,
        high: 1.10218,
        low: 1.09802
      },
      {
        timestamp: 1436216400,
        open: 1.09976,
        close: 1.10571,
        high: 1.10969,
        low: 1.09707
      },
      {
        timestamp: 1436302800,
        open: 1.10571,
        close: 1.1012,
        high: 1.106,
        low: 1.0917
      },
      {
        timestamp: 1436389200,
        open: 1.1012,
        close: 1.10776,
        high: 1.10939,
        low: 1.0975
      },
      {
        timestamp: 1436475600,
        open: 1.10776,
        close: 1.10406,
        high: 1.11265,
        low: 1.09924
      },
      {
        timestamp: 1436562000,
        open: 1.10406,
        close: 1.11657,
        high: 1.1217,
        low: 1.1032
      },
      {
        timestamp: 1436734800,
        open: 1.11657,
        close: 1.1096,
        high: 1.11279,
        low: 1.10903
      },
      {
        timestamp: 1436821200,
        open: 1.1096,
        close: 1.10084,
        high: 1.11978,
        low: 1.09962
      },
      {
        timestamp: 1436907600,
        open: 1.10084,
        close: 1.10136,
        high: 1.10892,
        low: 1.09662
      },
      {
        timestamp: 1436994000,
        open: 1.10136,
        close: 1.09512,
        high: 1.10365,
        low: 1.09307
      },
      {
        timestamp: 1437080400,
        open: 1.09512,
        close: 1.08829,
        high: 1.09637,
        low: 1.08561
      },
      {
        timestamp: 1437166800,
        open: 1.08829,
        close: 1.08304,
        high: 1.09078,
        low: 1.08296
      },
      {
        timestamp: 1437339600,
        open: 1.08304,
        close: 1.08338,
        high: 1.08454,
        low: 1.08304
      },
      {
        timestamp: 1437426000,
        open: 1.08338,
        close: 1.08262,
        high: 1.08711,
        low: 1.08097
      },
      {
        timestamp: 1437512400,
        open: 1.08262,
        close: 1.09362,
        high: 1.09698,
        low: 1.08126
      },
      {
        timestamp: 1437598800,
        open: 1.09362,
        close: 1.09296,
        high: 1.09677,
        low: 1.08701
      },
      {
        timestamp: 1437685200,
        open: 1.09296,
        close: 1.099,
        high: 1.10192,
        low: 1.09226
      },
      {
        timestamp: 1437771600,
        open: 1.099,
        close: 1.09793,
        high: 1.09968,
        low: 1.0926
      },
      {
        timestamp: 1437944400,
        open: 1.09793,
        close: 1.09844,
        high: 1.09906,
        low: 1.09744
      },
      {
        timestamp: 1438030800,
        open: 1.09844,
        close: 1.10914,
        high: 1.11302,
        low: 1.09699
      },
      {
        timestamp: 1438117200,
        open: 1.10914,
        close: 1.10617,
        high: 1.11005,
        low: 1.10227
      },
      {
        timestamp: 1438203600,
        open: 1.10617,
        close: 1.09867,
        high: 1.10849,
        low: 1.09678
      },
      {
        timestamp: 1438290000,
        open: 1.09867,
        close: 1.09336,
        high: 1.09903,
        low: 1.08942
      },
      {
        timestamp: 1438376400,
        open: 1.09336,
        close: 1.09878,
        high: 1.1115,
        low: 1.0922
      },
      {
        timestamp: 1438549200,
        open: 1.09878,
        close: 1.0978,
        high: 1.09934,
        low: 1.09726
      },
      {
        timestamp: 1438635600,
        open: 1.0978,
        close: 1.09521,
        high: 1.09969,
        low: 1.09419
      },
      {
        timestamp: 1438722000,
        open: 1.09521,
        close: 1.08824,
        high: 1.09884,
        low: 1.08801
      },
      {
        timestamp: 1438808400,
        open: 1.08824,
        close: 1.0907,
        high: 1.09393,
        low: 1.08488
      },
      {
        timestamp: 1438894800,
        open: 1.0907,
        close: 1.09288,
        high: 1.09458,
        low: 1.08742
      },
      {
        timestamp: 1438981200,
        open: 1.09288,
        close: 1.09701,
        high: 1.09792,
        low: 1.08562
      },
      {
        timestamp: 1439154000,
        open: 1.09701,
        close: 1.09697,
        high: 1.09782,
        low: 1.09665
      },
      {
        timestamp: 1439240400,
        open: 1.09697,
        close: 1.10209,
        high: 1.10424,
        low: 1.09261
      },
      {
        timestamp: 1439326800,
        open: 1.10209,
        close: 1.10432,
        high: 1.10893,
        low: 1.09615
      },
      {
        timestamp: 1439413200,
        open: 1.10432,
        close: 1.11611,
        high: 1.12149,
        low: 1.10251
      },
      {
        timestamp: 1439499600,
        open: 1.11611,
        close: 1.11518,
        high: 1.11899,
        low: 1.10807
      },
      {
        timestamp: 1439586000,
        open: 1.11518,
        close: 1.11119,
        high: 1.11894,
        low: 1.10986
      },
      {
        timestamp: 1439758800,
        open: 1.11119,
        close: 1.11256,
        high: 1.1128,
        low: 1.11083
      },
      {
        timestamp: 1439845200,
        open: 1.11256,
        close: 1.10825,
        high: 1.11257,
        low: 1.10594
      },
      {
        timestamp: 1439931600,
        open: 1.10825,
        close: 1.10268,
        high: 1.10946,
        low: 1.10179
      },
      {
        timestamp: 1440018000,
        open: 1.10268,
        close: 1.11249,
        high: 1.11352,
        low: 1.10185
      },
      {
        timestamp: 1440104400,
        open: 1.11249,
        close: 1.12452,
        high: 1.12456,
        low: 1.1108
      },
      {
        timestamp: 1440190800,
        open: 1.12452,
        close: 1.13944,
        high: 1.13951,
        low: 1.12303
      },
      {
        timestamp: 1440363600,
        open: 1.13944,
        close: 1.13817,
        high: 1.13892,
        low: 1.13706
      },
      {
        timestamp: 1440450000,
        open: 1.13817,
        close: 1.16218,
        high: 1.17195,
        low: 1.13707
      },
      {
        timestamp: 1440536400,
        open: 1.16218,
        close: 1.15183,
        high: 1.16234,
        low: 1.1397
      },
      {
        timestamp: 1440622800,
        open: 1.15183,
        close: 1.13163,
        high: 1.15624,
        low: 1.12928
      },
      {
        timestamp: 1440709200,
        open: 1.13163,
        close: 1.1248,
        high: 1.13653,
        low: 1.12037
      },
      {
        timestamp: 1440795600,
        open: 1.1248,
        close: 1.11897,
        high: 1.13106,
        low: 1.11566
      },
      {
        timestamp: 1440968400,
        open: 1.11897,
        close: 1.11792,
        high: 1.11887,
        low: 1.11625
      },
      {
        timestamp: 1441054800,
        open: 1.11792,
        close: 1.12125,
        high: 1.12635,
        low: 1.11719
      },
      {
        timestamp: 1441141200,
        open: 1.12125,
        close: 1.13177,
        high: 1.1333,
        low: 1.12092
      },
      {
        timestamp: 1441227600,
        open: 1.13177,
        close: 1.12289,
        high: 1.13206,
        low: 1.12174
      },
      {
        timestamp: 1441314000,
        open: 1.12289,
        close: 1.11241,
        high: 1.12443,
        low: 1.1088
      },
      {
        timestamp: 1441400400,
        open: 1.11241,
        close: 1.11517,
        high: 1.1192,
        low: 1.10895
      },
      {
        timestamp: 1441573200,
        open: 1.11517,
        close: 1.11654,
        high: 1.11696,
        low: 1.11517
      },
      {
        timestamp: 1441659600,
        open: 1.11654,
        close: 1.11714,
        high: 1.11785,
        low: 1.11224
      },
      {
        timestamp: 1441746000,
        open: 1.11714,
        close: 1.12038,
        high: 1.12304,
        low: 1.11532
      },
      {
        timestamp: 1441832400,
        open: 1.12038,
        close: 1.12084,
        high: 1.12172,
        low: 1.11325
      },
      {
        timestamp: 1441918800,
        open: 1.12084,
        close: 1.12813,
        high: 1.12963,
        low: 1.11724
      },
      {
        timestamp: 1442005200,
        open: 1.12813,
        close: 1.13407,
        high: 1.13504,
        low: 1.1255
      },
      {
        timestamp: 1442178000,
        open: 1.13407,
        close: 1.13347,
        high: 1.13479,
        low: 1.1333
      },
      {
        timestamp: 1442264400,
        open: 1.13347,
        close: 1.13182,
        high: 1.13741,
        low: 1.12842
      },
      {
        timestamp: 1442350800,
        open: 1.13182,
        close: 1.12712,
        high: 1.13298,
        low: 1.12593
      },
      {
        timestamp: 1442437200,
        open: 1.12712,
        close: 1.12978,
        high: 1.13215,
        low: 1.12147
      },
      {
        timestamp: 1442523600,
        open: 1.12978,
        close: 1.14362,
        high: 1.14422,
        low: 1.12852
      },
      {
        timestamp: 1442610000,
        open: 1.14362,
        close: 1.13079,
        high: 1.14612,
        low: 1.12705
      },
      {
        timestamp: 1442782800,
        open: 1.13079,
        close: 1.12861,
        high: 1.12936,
        low: 1.12668
      },
      {
        timestamp: 1442869200,
        open: 1.12861,
        close: 1.11908,
        high: 1.1331,
        low: 1.11819
      },
      {
        timestamp: 1442955600,
        open: 1.11908,
        close: 1.1122,
        high: 1.12087,
        low: 1.11142
      },
      {
        timestamp: 1443042000,
        open: 1.1122,
        close: 1.11867,
        high: 1.12144,
        low: 1.11061
      },
      {
        timestamp: 1443128400,
        open: 1.11867,
        close: 1.12314,
        high: 1.12967,
        low: 1.11652
      },
      {
        timestamp: 1443214800,
        open: 1.12314,
        close: 1.12039,
        high: 1.12359,
        low: 1.11171
      },
      {
        timestamp: 1443387600,
        open: 1.12039,
        close: 1.11934,
        high: 1.1198,
        low: 1.11767
      },
      {
        timestamp: 1443474000,
        open: 1.11934,
        close: 1.1246,
        high: 1.12487,
        low: 1.11471
      },
      {
        timestamp: 1443560400,
        open: 1.1246,
        close: 1.12528,
        high: 1.12822,
        low: 1.11947
      },
      {
        timestamp: 1443646800,
        open: 1.12528,
        close: 1.1178,
        high: 1.12622,
        low: 1.1158
      },
      {
        timestamp: 1443733200,
        open: 1.1178,
        close: 1.1197,
        high: 1.12098,
        low: 1.11359
      },
      {
        timestamp: 1443819600,
        open: 1.1197,
        close: 1.1216,
        high: 1.13195,
        low: 1.11511
      },
      {
        timestamp: 1443992400,
        open: 1.1216,
        close: 1.12221,
        high: 1.12353,
        low: 1.12105
      },
      {
        timestamp: 1444078800,
        open: 1.12221,
        close: 1.11922,
        high: 1.12902,
        low: 1.11742
      },
      {
        timestamp: 1444165200,
        open: 1.11922,
        close: 1.12758,
        high: 1.12804,
        low: 1.1173
      },
      {
        timestamp: 1444251600,
        open: 1.12758,
        close: 1.12391,
        high: 1.12855,
        low: 1.12123
      },
      {
        timestamp: 1444338000,
        open: 1.12391,
        close: 1.12797,
        high: 1.13286,
        low: 1.12355
      },
      {
        timestamp: 1444424400,
        open: 1.12797,
        close: 1.13657,
        high: 1.13878,
        low: 1.1268
      },
      {
        timestamp: 1444597200,
        open: 1.13657,
        close: 1.13686,
        high: 1.13694,
        low: 1.13583
      },
      {
        timestamp: 1444683600,
        open: 1.13686,
        close: 1.13595,
        high: 1.13977,
        low: 1.13552
      },
      {
        timestamp: 1444770000,
        open: 1.13595,
        close: 1.13804,
        high: 1.14118,
        low: 1.13451
      },
      {
        timestamp: 1444856400,
        open: 1.13804,
        close: 1.14754,
        high: 1.14899,
        low: 1.13788
      },
      {
        timestamp: 1444942800,
        open: 1.14754,
        close: 1.13954,
        high: 1.14961,
        low: 1.1364
      },
      {
        timestamp: 1445029200,
        open: 1.13954,
        close: 1.13504,
        high: 1.1401,
        low: 1.13352
      },
      {
        timestamp: 1445202000,
        open: 1.13504,
        close: 1.13651,
        high: 1.13682,
        low: 1.1347
      },
      {
        timestamp: 1445288400,
        open: 1.13651,
        close: 1.13293,
        high: 1.13798,
        low: 1.13065
      },
      {
        timestamp: 1445374800,
        open: 1.13293,
        close: 1.1347,
        high: 1.13879,
        low: 1.13249
      },
      {
        timestamp: 1445461200,
        open: 1.1347,
        close: 1.13405,
        high: 1.13784,
        low: 1.13348
      },
      {
        timestamp: 1445547600,
        open: 1.13405,
        close: 1.11098,
        high: 1.13521,
        low: 1.11009
      },
      {
        timestamp: 1445634000,
        open: 1.11098,
        close: 1.10246,
        high: 1.11406,
        low: 1.09974
      },
      {
        timestamp: 1445806800,
        open: 1.10246,
        close: 1.10053,
        high: 1.10103,
        low: 1.09901
      },
      {
        timestamp: 1445893200,
        open: 1.10053,
        close: 1.10594,
        high: 1.10691,
        low: 1.10044
      },
      {
        timestamp: 1445979600,
        open: 1.10594,
        close: 1.10517,
        high: 1.10796,
        low: 1.10311
      },
      {
        timestamp: 1446066000,
        open: 1.10517,
        close: 1.09259,
        high: 1.10966,
        low: 1.08977
      },
      {
        timestamp: 1446152400,
        open: 1.09259,
        close: 1.09813,
        high: 1.09872,
        low: 1.09031
      },
      {
        timestamp: 1446238800,
        open: 1.09813,
        close: 1.10139,
        high: 1.10734,
        low: 1.09664
      },
      {
        timestamp: 1446415200,
        open: 1.10139,
        close: 1.10376,
        high: 1.10465,
        low: 1.10139
      },
      {
        timestamp: 1446501600,
        open: 1.10376,
        close: 1.10204,
        high: 1.10536,
        low: 1.10012
      },
      {
        timestamp: 1446588000,
        open: 1.10204,
        close: 1.09697,
        high: 1.10308,
        low: 1.09366
      },
      {
        timestamp: 1446674400,
        open: 1.09697,
        close: 1.08684,
        high: 1.09689,
        low: 1.08449
      },
      {
        timestamp: 1446760800,
        open: 1.08684,
        close: 1.08855,
        high: 1.08986,
        low: 1.08344
      },
      {
        timestamp: 1446847200,
        open: 1.08855,
        close: 1.07473,
        high: 1.08947,
        low: 1.07065
      },
      {
        timestamp: 1447020000,
        open: 1.07473,
        close: 1.07339,
        high: 1.07408,
        low: 1.07241
      },
      {
        timestamp: 1447106400,
        open: 1.07339,
        close: 1.07526,
        high: 1.07908,
        low: 1.07203
      },
      {
        timestamp: 1447192800,
        open: 1.07526,
        close: 1.07258,
        high: 1.07648,
        low: 1.06751
      },
      {
        timestamp: 1447279200,
        open: 1.07258,
        close: 1.07484,
        high: 1.07746,
        low: 1.07063
      },
      {
        timestamp: 1447365600,
        open: 1.07484,
        close: 1.08163,
        high: 1.08318,
        low: 1.0692
      },
      {
        timestamp: 1447452000,
        open: 1.08163,
        close: 1.07762,
        high: 1.08183,
        low: 1.0715
      },
      {
        timestamp: 1447624800,
        open: 1.07762,
        close: 1.07425,
        high: 1.07451,
        low: 1.07244
      },
      {
        timestamp: 1447711200,
        open: 1.07425,
        close: 1.06882,
        high: 1.07591,
        low: 1.06753
      },
      {
        timestamp: 1447797600,
        open: 1.06882,
        close: 1.06438,
        high: 1.06916,
        low: 1.06313
      },
      {
        timestamp: 1447884000,
        open: 1.06438,
        close: 1.06614,
        high: 1.06934,
        low: 1.06176
      },
      {
        timestamp: 1447970400,
        open: 1.06614,
        close: 1.07363,
        high: 1.07637,
        low: 1.06564
      },
      {
        timestamp: 1448056800,
        open: 1.07363,
        close: 1.06548,
        high: 1.07396,
        low: 1.0641
      },
      {
        timestamp: 1448229600,
        open: 1.06548,
        close: 1.06416,
        high: 1.06516,
        low: 1.0637
      },
      {
        timestamp: 1448316000,
        open: 1.06416,
        close: 1.06378,
        high: 1.06578,
        low: 1.05934
      },
      {
        timestamp: 1448402400,
        open: 1.06378,
        close: 1.06443,
        high: 1.06743,
        low: 1.06205
      },
      {
        timestamp: 1448488800,
        open: 1.06443,
        close: 1.06261,
        high: 1.06902,
        low: 1.05668
      },
      {
        timestamp: 1448575200,
        open: 1.06261,
        close: 1.06125,
        high: 1.06284,
        low: 1.06008
      },
      {
        timestamp: 1448661600,
        open: 1.06125,
        close: 1.06015,
        high: 1.06389,
        low: 1.05692
      },
      {
        timestamp: 1448834400,
        open: 1.06015,
        close: 1.05924,
        high: 1.05959,
        low: 1.05893
      },
      {
        timestamp: 1448920800,
        open: 1.05924,
        close: 1.05665,
        high: 1.05959,
        low: 1.05586
      },
      {
        timestamp: 1449007200,
        open: 1.05665,
        close: 1.06341,
        high: 1.06379,
        low: 1.05641
      },
      {
        timestamp: 1449093600,
        open: 1.06341,
        close: 1.0617,
        high: 1.06386,
        low: 1.05514
      },
      {
        timestamp: 1449180000,
        open: 1.0617,
        close: 1.09423,
        high: 1.09817,
        low: 1.05262
      },
      {
        timestamp: 1449266400,
        open: 1.09423,
        close: 1.08823,
        high: 1.09572,
        low: 1.08371
      },
      {
        timestamp: 1449439200,
        open: 1.08823,
        close: 1.088,
        high: 1.088,
        low: 1.0869
      },
      {
        timestamp: 1449525600,
        open: 1.088,
        close: 1.08386,
        high: 1.08883,
        low: 1.07968
      },
      {
        timestamp: 1449612000,
        open: 1.08386,
        close: 1.08935,
        high: 1.09037,
        low: 1.08311
      },
      {
        timestamp: 1449698400,
        open: 1.08935,
        close: 1.10265,
        high: 1.10437,
        low: 1.088
      },
      {
        timestamp: 1449784800,
        open: 1.10265,
        close: 1.09424,
        high: 1.10283,
        low: 1.09259
      },
      {
        timestamp: 1449871200,
        open: 1.09424,
        close: 1.09972,
        high: 1.10317,
        low: 1.09273
      },
      {
        timestamp: 1450044000,
        open: 1.09972,
        close: 1.09874,
        high: 1.10004,
        low: 1.09795
      },
      {
        timestamp: 1450130400,
        open: 1.09874,
        close: 1.09932,
        high: 1.10495,
        low: 1.09463
      },
      {
        timestamp: 1450216800,
        open: 1.09932,
        close: 1.09356,
        high: 1.10605,
        low: 1.09053
      },
      {
        timestamp: 1450303200,
        open: 1.09356,
        close: 1.09147,
        high: 1.10131,
        low: 1.08888
      },
      {
        timestamp: 1450389600,
        open: 1.09147,
        close: 1.08271,
        high: 1.09169,
        low: 1.08034
      },
      {
        timestamp: 1450476000,
        open: 1.08271,
        close: 1.08722,
        high: 1.08755,
        low: 1.08056
      },
      {
        timestamp: 1450648800,
        open: 1.08722,
        close: 1.0861,
        high: 1.08746,
        low: 1.08551
      },
      {
        timestamp: 1450735200,
        open: 1.0861,
        close: 1.09165,
        high: 1.094,
        low: 1.0849
      },
      {
        timestamp: 1450821600,
        open: 1.09165,
        close: 1.0958,
        high: 1.0985,
        low: 1.09032
      },
      {
        timestamp: 1450908000,
        open: 1.0958,
        close: 1.09123,
        high: 1.09584,
        low: 1.08706
      },
      {
        timestamp: 1450994400,
        open: 1.09123,
        close: 1.09561,
        high: 1.09684,
        low: 1.09052
      },
      {
        timestamp: 1451340000,
        open: 1.09561,
        close: 1.09705,
        high: 1.09932,
        low: 1.09567
      },
      {
        timestamp: 1451426400,
        open: 1.09705,
        close: 1.09212,
        high: 1.09925,
        low: 1.08997
      },
      {
        timestamp: 1451512800,
        open: 1.09212,
        close: 1.09333,
        high: 1.09447,
        low: 1.09029
      },
      {
        timestamp: 1451599200,
        open: 1.09333,
        close: 1.08683,
        high: 1.0938,
        low: 1.08537
      },
      {
        timestamp: 1451858400,
        open: 1.08683,
        close: 1.08755,
        high: 1.08841,
        low: 1.08585
      },
      {
        timestamp: 1451944800,
        open: 1.08755,
        close: 1.08351,
        high: 1.09472,
        low: 1.0782
      },
      {
        timestamp: 1452031200,
        open: 1.08351,
        close: 1.07493,
        high: 1.08398,
        low: 1.07115
      },
      {
        timestamp: 1452117600,
        open: 1.07493,
        close: 1.07834,
        high: 1.08003,
        low: 1.07169
      },
      {
        timestamp: 1452204000,
        open: 1.07834,
        close: 1.09353,
        high: 1.09406,
        low: 1.07718
      },
      {
        timestamp: 1452290400,
        open: 1.09353,
        close: 1.0932,
        high: 1.09365,
        low: 1.0804
      },
      {
        timestamp: 1452463200,
        open: 1.0932,
        close: 1.09239,
        high: 1.09297,
        low: 1.09138
      },
      {
        timestamp: 1452549600,
        open: 1.09239,
        close: 1.08598,
        high: 1.09712,
        low: 1.08486
      },
      {
        timestamp: 1452636000,
        open: 1.08598,
        close: 1.08604,
        high: 1.0901,
        low: 1.08202
      },
      {
        timestamp: 1452722400,
        open: 1.08604,
        close: 1.08789,
        high: 1.08887,
        low: 1.08057
      },
      {
        timestamp: 1452808800,
        open: 1.08789,
        close: 1.08663,
        high: 1.09441,
        low: 1.08355
      },
      {
        timestamp: 1452895200,
        open: 1.08663,
        close: 1.09195,
        high: 1.09854,
        low: 1.08552
      },
      {
        timestamp: 1453068000,
        open: 1.09195,
        close: 1.09301,
        high: 1.0944,
        low: 1.09195
      },
      {
        timestamp: 1453154400,
        open: 1.09301,
        close: 1.08954,
        high: 1.09309,
        low: 1.08697
      },
      {
        timestamp: 1453240800,
        open: 1.08954,
        close: 1.09083,
        high: 1.09399,
        low: 1.08603
      },
      {
        timestamp: 1453327200,
        open: 1.09083,
        close: 1.08952,
        high: 1.09768,
        low: 1.08782
      },
      {
        timestamp: 1453413600,
        open: 1.08952,
        close: 1.08749,
        high: 1.09229,
        low: 1.07786
      },
      {
        timestamp: 1453500000,
        open: 1.08749,
        close: 1.08055,
        high: 1.08775,
        low: 1.07898
      },
      {
        timestamp: 1453672800,
        open: 1.08055,
        close: 1.07957,
        high: 1.08073,
        low: 1.07904
      },
      {
        timestamp: 1453759200,
        open: 1.07957,
        close: 1.08505,
        high: 1.08605,
        low: 1.07902
      },
      {
        timestamp: 1453845600,
        open: 1.08505,
        close: 1.08723,
        high: 1.08753,
        low: 1.08195
      },
      {
        timestamp: 1453932000,
        open: 1.08723,
        close: 1.08935,
        high: 1.09173,
        low: 1.08516
      },
      {
        timestamp: 1454018400,
        open: 1.08935,
        close: 1.09422,
        high: 1.09685,
        low: 1.08705
      },
      {
        timestamp: 1454104800,
        open: 1.09422,
        close: 1.08336,
        high: 1.09496,
        low: 1.08107
      },
      {
        timestamp: 1454277600,
        open: 1.08336,
        close: 1.08349,
        high: 1.08402,
        low: 1.08282
      },
      {
        timestamp: 1454364000,
        open: 1.08349,
        close: 1.08887,
        high: 1.09136,
        low: 1.08156
      },
      {
        timestamp: 1454450400,
        open: 1.08887,
        close: 1.09211,
        high: 1.09406,
        low: 1.08864
      },
      {
        timestamp: 1454536800,
        open: 1.09211,
        close: 1.11103,
        high: 1.11465,
        low: 1.09045
      },
      {
        timestamp: 1454623200,
        open: 1.11103,
        close: 1.12105,
        high: 1.12398,
        low: 1.10704
      },
      {
        timestamp: 1454709600,
        open: 1.12105,
        close: 1.11647,
        high: 1.12526,
        low: 1.11096
      },
      {
        timestamp: 1454882400,
        open: 1.11647,
        close: 1.11456,
        high: 1.11558,
        low: 1.11424
      },
      {
        timestamp: 1454968800,
        open: 1.11456,
        close: 1.11966,
        high: 1.12168,
        low: 1.10872
      },
      {
        timestamp: 1455055200,
        open: 1.11966,
        close: 1.12942,
        high: 1.13389,
        low: 1.1163
      },
      {
        timestamp: 1455141600,
        open: 1.12942,
        close: 1.1296,
        high: 1.13123,
        low: 1.11615
      },
      {
        timestamp: 1455228000,
        open: 1.1296,
        close: 1.13255,
        high: 1.13775,
        low: 1.12748
      },
      {
        timestamp: 1455314400,
        open: 1.13255,
        close: 1.12607,
        high: 1.13349,
        low: 1.12149
      },
      {
        timestamp: 1455487200,
        open: 1.12607,
        close: 1.12416,
        high: 1.12454,
        low: 1.12325
      },
      {
        timestamp: 1455573600,
        open: 1.12416,
        close: 1.11564,
        high: 1.12555,
        low: 1.11287
      },
      {
        timestamp: 1455660000,
        open: 1.11564,
        close: 1.11445,
        high: 1.11938,
        low: 1.1125
      },
      {
        timestamp: 1455746400,
        open: 1.11445,
        close: 1.11303,
        high: 1.11801,
        low: 1.11062
      },
      {
        timestamp: 1455832800,
        open: 1.11303,
        close: 1.11088,
        high: 1.11505,
        low: 1.10718
      },
      {
        timestamp: 1455919200,
        open: 1.11088,
        close: 1.11332,
        high: 1.11402,
        low: 1.10675
      },
      {
        timestamp: 1456092000,
        open: 1.11332,
        close: 1.11148,
        high: 1.11278,
        low: 1.11095
      },
      {
        timestamp: 1456178400,
        open: 1.11148,
        close: 1.10309,
        high: 1.1126,
        low: 1.10039
      },
      {
        timestamp: 1456264800,
        open: 1.10309,
        close: 1.1022,
        high: 1.10537,
        low: 1.09908
      },
      {
        timestamp: 1456351200,
        open: 1.1022,
        close: 1.10145,
        high: 1.10474,
        low: 1.09581
      },
      {
        timestamp: 1456437600,
        open: 1.10145,
        close: 1.10191,
        high: 1.10506,
        low: 1.09875
      },
      {
        timestamp: 1456524000,
        open: 1.10191,
        close: 1.09368,
        high: 1.10692,
        low: 1.09126
      },
      {
        timestamp: 1456696800,
        open: 1.09368,
        close: 1.09185,
        high: 1.09318,
        low: 1.09148
      },
      {
        timestamp: 1456783200,
        open: 1.09185,
        close: 1.0874,
        high: 1.09632,
        low: 1.08598
      },
      {
        timestamp: 1456869600,
        open: 1.0874,
        close: 1.08708,
        high: 1.08945,
        low: 1.0835
      },
      {
        timestamp: 1456956000,
        open: 1.08708,
        close: 1.08695,
        high: 1.08819,
        low: 1.08264
      },
      {
        timestamp: 1457042400,
        open: 1.08695,
        close: 1.09578,
        high: 1.09738,
        low: 1.08543
      },
      {
        timestamp: 1457128800,
        open: 1.09578,
        close: 1.1014,
        high: 1.10443,
        low: 1.09043
      },
      {
        timestamp: 1457301600,
        open: 1.1014,
        close: 1.09945,
        high: 1.10015,
        low: 1.09843
      },
      {
        timestamp: 1457388000,
        open: 1.09945,
        close: 1.10155,
        high: 1.10266,
        low: 1.09408
      },
      {
        timestamp: 1457474400,
        open: 1.10155,
        close: 1.10118,
        high: 1.10587,
        low: 1.09943
      },
      {
        timestamp: 1457560800,
        open: 1.10118,
        close: 1.10036,
        high: 1.10357,
        low: 1.09468
      },
      {
        timestamp: 1457647200,
        open: 1.10036,
        close: 1.1185,
        high: 1.1219,
        low: 1.08223
      },
      {
        timestamp: 1457733600,
        open: 1.1185,
        close: 1.11511,
        high: 1.12106,
        low: 1.10813
      },
      {
        timestamp: 1457902800,
        open: 1.11511,
        close: 1.11411,
        high: 1.11489,
        low: 1.11343
      },
      {
        timestamp: 1457989200,
        open: 1.11411,
        close: 1.11068,
        high: 1.11777,
        low: 1.10785
      },
      {
        timestamp: 1458075600,
        open: 1.11068,
        close: 1.11131,
        high: 1.11256,
        low: 1.10727
      },
      {
        timestamp: 1458162000,
        open: 1.11131,
        close: 1.12255,
        high: 1.12435,
        low: 1.10586
      },
      {
        timestamp: 1458248400,
        open: 1.12255,
        close: 1.13202,
        high: 1.13434,
        low: 1.12057
      },
      {
        timestamp: 1458334800,
        open: 1.13202,
        close: 1.12744,
        high: 1.13378,
        low: 1.12569
      },
      {
        timestamp: 1458507600,
        open: 1.12744,
        close: 1.12699,
        high: 1.12699,
        low: 1.12576
      },
      {
        timestamp: 1458594000,
        open: 1.12699,
        close: 1.12442,
        high: 1.12856,
        low: 1.12351
      },
      {
        timestamp: 1458680400,
        open: 1.12442,
        close: 1.12183,
        high: 1.12608,
        low: 1.11894
      },
      {
        timestamp: 1458766800,
        open: 1.12183,
        close: 1.11863,
        high: 1.12245,
        low: 1.11603
      },
      {
        timestamp: 1458853200,
        open: 1.11863,
        close: 1.11777,
        high: 1.11886,
        low: 1.11449
      },
      {
        timestamp: 1458939600,
        open: 1.11777,
        close: 1.11723,
        high: 1.11834,
        low: 1.11548
      },
      {
        timestamp: 1459112400,
        open: 1.11723,
        close: 1.11665,
        high: 1.11732,
        low: 1.11621
      },
      {
        timestamp: 1459198800,
        open: 1.11665,
        close: 1.11982,
        high: 1.12208,
        low: 1.11538
      },
      {
        timestamp: 1459285200,
        open: 1.11982,
        close: 1.12927,
        high: 1.13042,
        low: 1.11699
      },
      {
        timestamp: 1459371600,
        open: 1.12927,
        close: 1.13428,
        high: 1.13661,
        low: 1.12847
      },
      {
        timestamp: 1459458000,
        open: 1.13428,
        close: 1.1384,
        high: 1.14128,
        low: 1.1311
      },
      {
        timestamp: 1459544400,
        open: 1.1384,
        close: 1.14002,
        high: 1.14386,
        low: 1.13354
      },
      {
        timestamp: 1459717200,
        open: 1.14002,
        close: 1.14017,
        high: 1.14048,
        low: 1.13899
      },
      {
        timestamp: 1459803600,
        open: 1.14017,
        close: 1.13941,
        high: 1.14136,
        low: 1.13582
      },
      {
        timestamp: 1459890000,
        open: 1.13941,
        close: 1.13913,
        high: 1.14061,
        low: 1.13367
      },
      {
        timestamp: 1459976400,
        open: 1.13913,
        close: 1.14012,
        high: 1.14327,
        low: 1.13276
      },
      {
        timestamp: 1460062800,
        open: 1.14012,
        close: 1.13802,
        high: 1.14552,
        low: 1.13383
      },
      {
        timestamp: 1460149200,
        open: 1.13802,
        close: 1.1406,
        high: 1.14203,
        low: 1.13499
      },
      {
        timestamp: 1460322000,
        open: 1.1406,
        close: 1.14193,
        high: 1.14236,
        low: 1.1406
      },
      {
        timestamp: 1460408400,
        open: 1.14193,
        close: 1.14116,
        high: 1.14484,
        low: 1.13732
      },
      {
        timestamp: 1460494800,
        open: 1.14116,
        close: 1.13882,
        high: 1.14658,
        low: 1.13464
      },
      {
        timestamp: 1460581200,
        open: 1.13882,
        close: 1.12764,
        high: 1.13934,
        low: 1.12692
      },
      {
        timestamp: 1460667600,
        open: 1.12764,
        close: 1.12699,
        high: 1.12959,
        low: 1.12348
      },
      {
        timestamp: 1460754000,
        open: 1.12699,
        close: 1.1288,
        high: 1.13178,
        low: 1.12468
      },
      {
        timestamp: 1460926800,
        open: 1.1288,
        close: 1.1307,
        high: 1.13236,
        low: 1.1288
      },
      {
        timestamp: 1461013200,
        open: 1.1307,
        close: 1.13162,
        high: 1.1333,
        low: 1.12746
      },
      {
        timestamp: 1461099600,
        open: 1.13162,
        close: 1.13589,
        high: 1.13855,
        low: 1.13043
      },
      {
        timestamp: 1461186000,
        open: 1.13589,
        close: 1.12979,
        high: 1.13893,
        low: 1.12913
      },
      {
        timestamp: 1461272400,
        open: 1.12979,
        close: 1.1289,
        high: 1.14006,
        low: 1.12705
      },
      {
        timestamp: 1461358800,
        open: 1.1289,
        close: 1.12318,
        high: 1.13096,
        low: 1.1219
      },
      {
        timestamp: 1461531600,
        open: 1.12318,
        close: 1.12204,
        high: 1.12356,
        low: 1.12186
      },
      {
        timestamp: 1461618000,
        open: 1.12204,
        close: 1.12702,
        high: 1.12791,
        low: 1.12209
      },
      {
        timestamp: 1461704400,
        open: 1.12702,
        close: 1.13012,
        high: 1.13404,
        low: 1.12572
      },
      {
        timestamp: 1461790800,
        open: 1.13012,
        close: 1.13305,
        high: 1.13631,
        low: 1.12729
      },
      {
        timestamp: 1461877200,
        open: 1.13305,
        close: 1.13553,
        high: 1.13689,
        low: 1.1297
      },
      {
        timestamp: 1461963600,
        open: 1.13553,
        close: 1.14566,
        high: 1.14602,
        low: 1.13501
      },
      {
        timestamp: 1462136400,
        open: 1.14566,
        close: 1.14648,
        high: 1.14648,
        low: 1.14335
      },
      {
        timestamp: 1462222800,
        open: 1.14648,
        close: 1.1535,
        high: 1.15371,
        low: 1.14491
      },
      {
        timestamp: 1462309200,
        open: 1.1535,
        close: 1.14985,
        high: 1.16175,
        low: 1.14964
      },
      {
        timestamp: 1462395600,
        open: 1.14985,
        close: 1.14903,
        high: 1.15306,
        low: 1.14671
      },
      {
        timestamp: 1462482000,
        open: 1.14903,
        close: 1.14071,
        high: 1.14947,
        low: 1.13866
      },
      {
        timestamp: 1462568400,
        open: 1.14071,
        close: 1.14114,
        high: 1.14843,
        low: 1.13875
      },
      {
        timestamp: 1462741200,
        open: 1.14114,
        close: 1.13995,
        high: 1.14107,
        low: 1.13951
      },
      {
        timestamp: 1462827600,
        open: 1.13995,
        close: 1.13843,
        high: 1.14208,
        low: 1.13761
      },
      {
        timestamp: 1462914000,
        open: 1.13843,
        close: 1.13742,
        high: 1.14105,
        low: 1.13593
      },
      {
        timestamp: 1463000400,
        open: 1.13742,
        close: 1.1428,
        high: 1.14474,
        low: 1.13698
      },
      {
        timestamp: 1463086800,
        open: 1.1428,
        close: 1.13803,
        high: 1.14302,
        low: 1.13713
      },
      {
        timestamp: 1463173200,
        open: 1.13803,
        close: 1.13145,
        high: 1.13806,
        low: 1.12838
      },
      {
        timestamp: 1463346000,
        open: 1.13145,
        close: 1.13146,
        high: 1.13183,
        low: 1.13055
      },
      {
        timestamp: 1463432400,
        open: 1.13146,
        close: 1.13223,
        high: 1.13432,
        low: 1.13035
      },
      {
        timestamp: 1463518800,
        open: 1.13223,
        close: 1.13165,
        high: 1.13505,
        low: 1.13028
      },
      {
        timestamp: 1463605200,
        open: 1.13165,
        close: 1.12185,
        high: 1.13177,
        low: 1.12151
      },
      {
        timestamp: 1463691600,
        open: 1.12185,
        close: 1.12038,
        high: 1.12308,
        low: 1.11806
      },
      {
        timestamp: 1463778000,
        open: 1.12038,
        close: 1.123,
        high: 1.12388,
        low: 1.11972
      },
      {
        timestamp: 1463950800,
        open: 1.123,
        close: 1.12118,
        high: 1.12237,
        low: 1.12091
      },
      {
        timestamp: 1464037200,
        open: 1.12118,
        close: 1.12224,
        high: 1.12441,
        low: 1.11883
      },
      {
        timestamp: 1464123600,
        open: 1.12224,
        close: 1.11431,
        high: 1.12281,
        low: 1.11333
      },
      {
        timestamp: 1464210000,
        open: 1.11431,
        close: 1.11558,
        high: 1.11678,
        low: 1.11297
      },
      {
        timestamp: 1464296400,
        open: 1.11558,
        close: 1.11956,
        high: 1.12176,
        low: 1.11504
      },
      {
        timestamp: 1464382800,
        open: 1.11956,
        close: 1.11184,
        high: 1.12017,
        low: 1.11119
      },
      {
        timestamp: 1464555600,
        open: 1.11184,
        close: 1.11158,
        high: 1.11237,
        low: 1.11184
      },
      {
        timestamp: 1464642000,
        open: 1.11158,
        close: 1.11404,
        high: 1.11456,
        low: 1.10986
      },
      {
        timestamp: 1464728400,
        open: 1.11404,
        close: 1.1134,
        high: 1.11742,
        low: 1.11231
      },
      {
        timestamp: 1464814800,
        open: 1.1134,
        close: 1.11908,
        high: 1.11948,
        low: 1.1115
      },
      {
        timestamp: 1464901200,
        open: 1.11908,
        close: 1.11519,
        high: 1.12211,
        low: 1.11463
      },
      {
        timestamp: 1464987600,
        open: 1.11519,
        close: 1.13726,
        high: 1.13753,
        low: 1.11374
      },
      {
        timestamp: 1465160400,
        open: 1.13726,
        close: 1.13616,
        high: 1.13707,
        low: 1.13419
      },
      {
        timestamp: 1465246800,
        open: 1.13616,
        close: 1.13569,
        high: 1.13937,
        low: 1.13271
      },
      {
        timestamp: 1465333200,
        open: 1.13569,
        close: 1.13609,
        high: 1.13813,
        low: 1.13394
      },
      {
        timestamp: 1465419600,
        open: 1.13609,
        close: 1.13993,
        high: 1.14115,
        low: 1.13553
      },
      {
        timestamp: 1465506000,
        open: 1.13993,
        close: 1.13194,
        high: 1.14164,
        low: 1.13064
      },
      {
        timestamp: 1465592400,
        open: 1.13194,
        close: 1.12593,
        high: 1.13224,
        low: 1.12461
      },
      {
        timestamp: 1465765200,
        open: 1.12593,
        close: 1.12562,
        high: 1.12686,
        low: 1.12492
      },
      {
        timestamp: 1465851600,
        open: 1.12562,
        close: 1.12953,
        high: 1.1304,
        low: 1.12332
      },
      {
        timestamp: 1465938000,
        open: 1.12953,
        close: 1.12108,
        high: 1.12994,
        low: 1.11898
      },
      {
        timestamp: 1466024400,
        open: 1.12108,
        close: 1.12622,
        high: 1.12999,
        low: 1.11907
      },
      {
        timestamp: 1466110800,
        open: 1.12622,
        close: 1.12264,
        high: 1.12958,
        low: 1.11317
      },
      {
        timestamp: 1466197200,
        open: 1.12264,
        close: 1.12825,
        high: 1.12972,
        low: 1.12237
      },
      {
        timestamp: 1466370000,
        open: 1.12825,
        close: 1.13381,
        high: 1.13475,
        low: 1.12825
      },
      {
        timestamp: 1466456400,
        open: 1.13381,
        close: 1.13182,
        high: 1.13834,
        low: 1.1303
      },
      {
        timestamp: 1466542800,
        open: 1.13182,
        close: 1.1243,
        high: 1.13507,
        low: 1.12427
      },
      {
        timestamp: 1466629200,
        open: 1.1243,
        close: 1.12974,
        high: 1.13385,
        low: 1.12375
      },
      {
        timestamp: 1466715600,
        open: 1.12974,
        close: 1.13911,
        high: 1.14226,
        low: 1.12974
      },
      {
        timestamp: 1466802000,
        open: 1.13911,
        close: 1.11257,
        high: 1.14293,
        low: 1.09136
      },
      {
        timestamp: 1466974800,
        open: 1.11257,
        close: 1.10156,
        high: 1.10836,
        low: 1.10033
      },
      {
        timestamp: 1467061200,
        open: 1.10156,
        close: 1.10286,
        high: 1.10848,
        low: 1.09717
      },
      {
        timestamp: 1467147600,
        open: 1.10286,
        close: 1.10675,
        high: 1.11126,
        low: 1.10112
      },
      {
        timestamp: 1467234000,
        open: 1.10675,
        close: 1.11268,
        high: 1.11314,
        low: 1.10501
      },
      {
        timestamp: 1467320400,
        open: 1.11268,
        close: 1.1109,
        high: 1.11554,
        low: 1.10249
      },
      {
        timestamp: 1467406800,
        open: 1.1109,
        close: 1.11438,
        high: 1.11704,
        low: 1.1073
      },
      {
        timestamp: 1467579600,
        open: 1.11438,
        close: 1.11357,
        high: 1.11412,
        low: 1.11195
      },
      {
        timestamp: 1467666000,
        open: 1.11357,
        close: 1.11568,
        high: 1.11605,
        low: 1.10987
      },
      {
        timestamp: 1467752400,
        open: 1.11568,
        close: 1.1077,
        high: 1.11876,
        low: 1.10631
      },
      {
        timestamp: 1467838800,
        open: 1.1077,
        close: 1.11019,
        high: 1.11126,
        low: 1.10298
      },
      {
        timestamp: 1467925200,
        open: 1.11019,
        close: 1.10659,
        high: 1.11081,
        low: 1.10534
      },
      {
        timestamp: 1468011600,
        open: 1.10659,
        close: 1.1053,
        high: 1.11232,
        low: 1.10026
      },
      {
        timestamp: 1468184400,
        open: 1.1053,
        close: 1.1055,
        high: 1.10566,
        low: 1.10471
      },
      {
        timestamp: 1468270800,
        open: 1.1055,
        close: 1.10588,
        high: 1.10755,
        low: 1.10164
      },
      {
        timestamp: 1468357200,
        open: 1.10588,
        close: 1.10616,
        high: 1.11268,
        low: 1.10531
      },
      {
        timestamp: 1468443600,
        open: 1.10616,
        close: 1.10907,
        high: 1.11206,
        low: 1.10428
      },
      {
        timestamp: 1468530000,
        open: 1.10907,
        close: 1.11232,
        high: 1.11657,
        low: 1.10907
      },
      {
        timestamp: 1468616400,
        open: 1.11232,
        close: 1.10417,
        high: 1.11498,
        low: 1.10259
      },
      {
        timestamp: 1468789200,
        open: 1.10417,
        close: 1.10518,
        high: 1.10807,
        low: 1.10417
      },
      {
        timestamp: 1468875600,
        open: 1.10518,
        close: 1.10766,
        high: 1.10853,
        low: 1.10382
      },
      {
        timestamp: 1468962000,
        open: 1.10766,
        close: 1.1023,
        high: 1.10813,
        low: 1.10006
      },
      {
        timestamp: 1469048400,
        open: 1.1023,
        close: 1.10178,
        high: 1.10312,
        low: 1.09822
      },
      {
        timestamp: 1469134800,
        open: 1.10178,
        close: 1.10289,
        high: 1.10615,
        low: 1.09801
      },
      {
        timestamp: 1469221200,
        open: 1.10289,
        close: 1.09833,
        high: 1.10419,
        low: 1.09563
      },
      {
        timestamp: 1469394000,
        open: 1.09833,
        close: 1.09797,
        high: 1.09923,
        low: 1.0976
      },
      {
        timestamp: 1469480400,
        open: 1.09797,
        close: 1.09978,
        high: 1.10002,
        low: 1.09529
      },
      {
        timestamp: 1469566800,
        open: 1.09978,
        close: 1.09878,
        high: 1.10314,
        low: 1.0979
      },
      {
        timestamp: 1469653200,
        open: 1.09878,
        close: 1.106,
        high: 1.10658,
        low: 1.09623
      },
      {
        timestamp: 1469739600,
        open: 1.106,
        close: 1.10791,
        high: 1.11204,
        low: 1.10533
      },
      {
        timestamp: 1469826000,
        open: 1.10791,
        close: 1.11792,
        high: 1.11987,
        low: 1.1074
      },
      {
        timestamp: 1469998800,
        open: 1.11792,
        close: 1.11787,
        high: 1.11843,
        low: 1.11792
      },
      {
        timestamp: 1470085200,
        open: 1.11787,
        close: 1.11672,
        high: 1.11853,
        low: 1.11559
      },
      {
        timestamp: 1470171600,
        open: 1.11672,
        close: 1.12261,
        high: 1.12346,
        low: 1.116
      },
      {
        timestamp: 1470258000,
        open: 1.12261,
        close: 1.11507,
        high: 1.12289,
        low: 1.11415
      },
      {
        timestamp: 1470344400,
        open: 1.11507,
        close: 1.11314,
        high: 1.11574,
        low: 1.11148
      },
      {
        timestamp: 1470430800,
        open: 1.11314,
        close: 1.1094,
        high: 1.11652,
        low: 1.10465
      },
      {
        timestamp: 1470603600,
        open: 1.1094,
        close: 1.1086,
        high: 1.10954,
        low: 1.10772
      },
      {
        timestamp: 1470690000,
        open: 1.1086,
        close: 1.10886,
        high: 1.11059,
        low: 1.10728
      },
      {
        timestamp: 1470776400,
        open: 1.10886,
        close: 1.11183,
        high: 1.11235,
        low: 1.10714
      },
      {
        timestamp: 1470862800,
        open: 1.11183,
        close: 1.11863,
        high: 1.1191,
        low: 1.11133
      },
      {
        timestamp: 1470949200,
        open: 1.11863,
        close: 1.1138,
        high: 1.11926,
        low: 1.11363
      },
      {
        timestamp: 1471035600,
        open: 1.1138,
        close: 1.11703,
        high: 1.12223,
        low: 1.11322
      },
      {
        timestamp: 1471208400,
        open: 1.11703,
        close: 1.11723,
        high: 1.11787,
        low: 1.11703
      },
      {
        timestamp: 1471294800,
        open: 1.11723,
        close: 1.11855,
        high: 1.12045,
        low: 1.11543
      },
      {
        timestamp: 1471381200,
        open: 1.11855,
        close: 1.12802,
        high: 1.13235,
        low: 1.11783
      },
      {
        timestamp: 1471467600,
        open: 1.12802,
        close: 1.1295,
        high: 1.1317,
        low: 1.12426
      },
      {
        timestamp: 1471554000,
        open: 1.1295,
        close: 1.13564,
        high: 1.13672,
        low: 1.12881
      },
      {
        timestamp: 1471640400,
        open: 1.13564,
        close: 1.13321,
        high: 1.13612,
        low: 1.1305
      },
      {
        timestamp: 1471813200,
        open: 1.13321,
        close: 1.13123,
        high: 1.13357,
        low: 1.13074
      },
      {
        timestamp: 1471899600,
        open: 1.13123,
        close: 1.13215,
        high: 1.13314,
        low: 1.12717
      },
      {
        timestamp: 1471986000,
        open: 1.13215,
        close: 1.13079,
        high: 1.1356,
        low: 1.1304
      },
      {
        timestamp: 1472072400,
        open: 1.13079,
        close: 1.1266,
        high: 1.13124,
        low: 1.1246
      },
      {
        timestamp: 1472158800,
        open: 1.1266,
        close: 1.1287,
        high: 1.12984,
        low: 1.12599
      },
      {
        timestamp: 1472245200,
        open: 1.1287,
        close: 1.11977,
        high: 1.13421,
        low: 1.11814
      },
      {
        timestamp: 1472418000,
        open: 1.11977,
        close: 1.11804,
        high: 1.12033,
        low: 1.11756
      },
      {
        timestamp: 1472504400,
        open: 1.11804,
        close: 1.11906,
        high: 1.12087,
        low: 1.11589
      },
      {
        timestamp: 1472590800,
        open: 1.11906,
        close: 1.11445,
        high: 1.11939,
        low: 1.11328
      },
      {
        timestamp: 1472677200,
        open: 1.11445,
        close: 1.11601,
        high: 1.11663,
        low: 1.11239
      },
      {
        timestamp: 1472763600,
        open: 1.11601,
        close: 1.1198,
        high: 1.1206,
        low: 1.11284
      },
      {
        timestamp: 1472850000,
        open: 1.1198,
        close: 1.11577,
        high: 1.12534,
        low: 1.1151
      },
      {
        timestamp: 1473022800,
        open: 1.11577,
        close: 1.11595,
        high: 1.11629,
        low: 1.11477
      },
      {
        timestamp: 1473109200,
        open: 1.11595,
        close: 1.11485,
        high: 1.11833,
        low: 1.11399
      },
      {
        timestamp: 1473195600,
        open: 1.11485,
        close: 1.12574,
        high: 1.12639,
        low: 1.11407
      },
      {
        timestamp: 1473282000,
        open: 1.12574,
        close: 1.12399,
        high: 1.12728,
        low: 1.12295
      },
      {
        timestamp: 1473368400,
        open: 1.12399,
        close: 1.12603,
        high: 1.13281,
        low: 1.12352
      },
      {
        timestamp: 1473454800,
        open: 1.12603,
        close: 1.12339,
        high: 1.12858,
        low: 1.11992
      },
      {
        timestamp: 1473627600,
        open: 1.12339,
        close: 1.12385,
        high: 1.12468,
        low: 1.12342
      },
      {
        timestamp: 1473714000,
        open: 1.12385,
        close: 1.12367,
        high: 1.12697,
        low: 1.1211
      },
      {
        timestamp: 1473800400,
        open: 1.12367,
        close: 1.12224,
        high: 1.12613,
        low: 1.12046
      },
      {
        timestamp: 1473886800,
        open: 1.12224,
        close: 1.12528,
        high: 1.1275,
        low: 1.12114
      },
      {
        timestamp: 1473973200,
        open: 1.12528,
        close: 1.12444,
        high: 1.12861,
        low: 1.12199
      },
      {
        timestamp: 1474059600,
        open: 1.12444,
        close: 1.11607,
        high: 1.1251,
        low: 1.11499
      },
      {
        timestamp: 1474232400,
        open: 1.11607,
        close: 1.11541,
        high: 1.11703,
        low: 1.11505
      },
      {
        timestamp: 1474318800,
        open: 1.11541,
        close: 1.11786,
        high: 1.11986,
        low: 1.11517
      },
      {
        timestamp: 1474405200,
        open: 1.11786,
        close: 1.11518,
        high: 1.12144,
        low: 1.11504
      },
      {
        timestamp: 1474491600,
        open: 1.11518,
        close: 1.11931,
        high: 1.11976,
        low: 1.11237
      },
      {
        timestamp: 1474578000,
        open: 1.11931,
        close: 1.12086,
        high: 1.12581,
        low: 1.11856
      },
      {
        timestamp: 1474664400,
        open: 1.12086,
        close: 1.123,
        high: 1.12414,
        low: 1.11946
      },
      {
        timestamp: 1474837200,
        open: 1.123,
        close: 1.12334,
        high: 1.12342,
        low: 1.12271
      },
      {
        timestamp: 1474923600,
        open: 1.12334,
        close: 1.12555,
        high: 1.12798,
        low: 1.1222
      },
      {
        timestamp: 1475010000,
        open: 1.12555,
        close: 1.12195,
        high: 1.12597,
        low: 1.11916
      },
      {
        timestamp: 1475096400,
        open: 1.12195,
        close: 1.12182,
        high: 1.12377,
        low: 1.11827
      },
      {
        timestamp: 1475182800,
        open: 1.12182,
        close: 1.12249,
        high: 1.12506,
        low: 1.11976
      },
      {
        timestamp: 1475269200,
        open: 1.12249,
        close: 1.12447,
        high: 1.12518,
        low: 1.11541
      },
      {
        timestamp: 1475442000,
        open: 1.12447,
        close: 1.12342,
        high: 1.12423,
        low: 1.12251
      },
      {
        timestamp: 1475528400,
        open: 1.12342,
        close: 1.1214,
        high: 1.12465,
        low: 1.1206
      },
      {
        timestamp: 1475614800,
        open: 1.1214,
        close: 1.1208,
        high: 1.12397,
        low: 1.11385
      },
      {
        timestamp: 1475701200,
        open: 1.1208,
        close: 1.12074,
        high: 1.1234,
        low: 1.11904
      },
      {
        timestamp: 1475787600,
        open: 1.12074,
        close: 1.11533,
        high: 1.12134,
        low: 1.11406
      },
      {
        timestamp: 1475874000,
        open: 1.11533,
        close: 1.12049,
        high: 1.12057,
        low: 1.11052
      },
      {
        timestamp: 1476046800,
        open: 1.12049,
        close: 1.11804,
        high: 1.12014,
        low: 1.11742
      },
      {
        timestamp: 1476133200,
        open: 1.11804,
        close: 1.11411,
        high: 1.12057,
        low: 1.11321
      },
      {
        timestamp: 1476219600,
        open: 1.11411,
        close: 1.10551,
        high: 1.11432,
        low: 1.10496
      },
      {
        timestamp: 1476306000,
        open: 1.10551,
        close: 1.101,
        high: 1.10686,
        low: 1.10053
      },
      {
        timestamp: 1476392400,
        open: 1.101,
        close: 1.10575,
        high: 1.10586,
        low: 1.09861
      },
      {
        timestamp: 1476478800,
        open: 1.10575,
        close: 1.09735,
        high: 1.10606,
        low: 1.09714
      },
      {
        timestamp: 1476651600,
        open: 1.09735,
        close: 1.09706,
        high: 1.09777,
        low: 1.09705
      },
      {
        timestamp: 1476738000,
        open: 1.09706,
        close: 1.10013,
        high: 1.10087,
        low: 1.09648
      },
      {
        timestamp: 1476824400,
        open: 1.10013,
        close: 1.09824,
        high: 1.10272,
        low: 1.09709
      },
      {
        timestamp: 1476910800,
        open: 1.09824,
        close: 1.09751,
        high: 1.10057,
        low: 1.09556
      },
      {
        timestamp: 1476997200,
        open: 1.09751,
        close: 1.09306,
        high: 1.10405,
        low: 1.09167
      },
      {
        timestamp: 1477083600,
        open: 1.09306,
        close: 1.08879,
        high: 1.09322,
        low: 1.08602
      },
      {
        timestamp: 1477256400,
        open: 1.08879,
        close: 1.08848,
        high: 1.08899,
        low: 1.08797
      },
      {
        timestamp: 1477342800,
        open: 1.08848,
        close: 1.08838,
        high: 1.09004,
        low: 1.08605
      },
      {
        timestamp: 1477429200,
        open: 1.08838,
        close: 1.08919,
        high: 1.09058,
        low: 1.08518
      },
      {
        timestamp: 1477515600,
        open: 1.08919,
        close: 1.09108,
        high: 1.09476,
        low: 1.08753
      },
      {
        timestamp: 1477602000,
        open: 1.09108,
        close: 1.09004,
        high: 1.09431,
        low: 1.08834
      },
      {
        timestamp: 1477688400,
        open: 1.09004,
        close: 1.09913,
        high: 1.09946,
        low: 1.0894
      },
      {
        timestamp: 1477861200,
        open: 1.09913,
        close: 1.09935,
        high: 1.09965,
        low: 1.09856
      },
      {
        timestamp: 1477947600,
        open: 1.09935,
        close: 1.09822,
        high: 1.09935,
        low: 1.09366
      },
      {
        timestamp: 1478034000,
        open: 1.09822,
        close: 1.10564,
        high: 1.107,
        low: 1.09609
      },
      {
        timestamp: 1478120400,
        open: 1.10564,
        close: 1.10983,
        high: 1.11239,
        low: 1.10505
      },
      {
        timestamp: 1478206800,
        open: 1.10983,
        close: 1.11073,
        high: 1.11267,
        low: 1.10605
      },
      {
        timestamp: 1478293200,
        open: 1.11073,
        close: 1.11431,
        high: 1.11434,
        low: 1.10807
      },
      {
        timestamp: 1478469600,
        open: 1.11431,
        close: 1.10652,
        high: 1.1122,
        low: 1.10462
      },
      {
        timestamp: 1478556000,
        open: 1.10652,
        close: 1.10413,
        high: 1.11112,
        low: 1.10282
      },
      {
        timestamp: 1478642400,
        open: 1.10413,
        close: 1.10285,
        high: 1.10679,
        low: 1.10096
      },
      {
        timestamp: 1478728800,
        open: 1.10285,
        close: 1.09122,
        high: 1.13007,
        low: 1.09079
      },
      {
        timestamp: 1478815200,
        open: 1.09122,
        close: 1.08962,
        high: 1.09546,
        low: 1.08657
      },
      {
        timestamp: 1478901600,
        open: 1.08962,
        close: 1.08628,
        high: 1.09242,
        low: 1.08312
      },
      {
        timestamp: 1479074400,
        open: 1.08628,
        close: 1.08354,
        high: 1.08395,
        low: 1.08279
      },
      {
        timestamp: 1479160800,
        open: 1.08354,
        close: 1.07386,
        high: 1.08427,
        low: 1.071
      },
      {
        timestamp: 1479247200,
        open: 1.07386,
        close: 1.07251,
        high: 1.08176,
        low: 1.07148
      },
      {
        timestamp: 1479333600,
        open: 1.07251,
        close: 1.06927,
        high: 1.07604,
        low: 1.06668
      },
      {
        timestamp: 1479420000,
        open: 1.06927,
        close: 1.06274,
        high: 1.07464,
        low: 1.06207
      },
      {
        timestamp: 1479506400,
        open: 1.06274,
        close: 1.05917,
        high: 1.06437,
        low: 1.05701
      },
      {
        timestamp: 1479679200,
        open: 1.05917,
        close: 1.05955,
        high: 1.06086,
        low: 1.05917
      },
      {
        timestamp: 1479765600,
        open: 1.05955,
        close: 1.06343,
        high: 1.06499,
        low: 1.05799
      },
      {
        timestamp: 1479852000,
        open: 1.06343,
        close: 1.06305,
        high: 1.06587,
        low: 1.05843
      },
      {
        timestamp: 1479938400,
        open: 1.06305,
        close: 1.05578,
        high: 1.06445,
        low: 1.0527
      },
      {
        timestamp: 1480024800,
        open: 1.05578,
        close: 1.05595,
        high: 1.05863,
        low: 1.05189
      },
      {
        timestamp: 1480111200,
        open: 1.05595,
        close: 1.05996,
        high: 1.06282,
        low: 1.05394
      },
      {
        timestamp: 1480284000,
        open: 1.05996,
        close: 1.06135,
        high: 1.06312,
        low: 1.05996
      },
      {
        timestamp: 1480370400,
        open: 1.06135,
        close: 1.06164,
        high: 1.06869,
        low: 1.05645
      },
      {
        timestamp: 1480456800,
        open: 1.06164,
        close: 1.06508,
        high: 1.06553,
        low: 1.05657
      },
      {
        timestamp: 1480543200,
        open: 1.06508,
        close: 1.05902,
        high: 1.06674,
        low: 1.05534
      },
      {
        timestamp: 1480629600,
        open: 1.05902,
        close: 1.06629,
        high: 1.06695,
        low: 1.05852
      },
      {
        timestamp: 1480716000,
        open: 1.06629,
        close: 1.06697,
        high: 1.0691,
        low: 1.06261
      },
      {
        timestamp: 1480888800,
        open: 1.06697,
        close: 1.0669,
        high: 1.0669,
        low: 1.06304
      },
      {
        timestamp: 1480975200,
        open: 1.0669,
        close: 1.07653,
        high: 1.07972,
        low: 1.05074
      },
      {
        timestamp: 1481061600,
        open: 1.07653,
        close: 1.07197,
        high: 1.07862,
        low: 1.06993
      },
      {
        timestamp: 1481148000,
        open: 1.07197,
        close: 1.07539,
        high: 1.07691,
        low: 1.07106
      },
      {
        timestamp: 1481234400,
        open: 1.07539,
        close: 1.06174,
        high: 1.08753,
        low: 1.05981
      },
      {
        timestamp: 1481320800,
        open: 1.06174,
        close: 1.05637,
        high: 1.06305,
        low: 1.05316
      },
      {
        timestamp: 1481493600,
        open: 1.05637,
        close: 1.05354,
        high: 1.05588,
        low: 1.053
      },
      {
        timestamp: 1481580000,
        open: 1.05354,
        close: 1.06354,
        high: 1.06525,
        low: 1.05262
      },
      {
        timestamp: 1481666400,
        open: 1.06354,
        close: 1.06271,
        high: 1.0668,
        low: 1.06046
      },
      {
        timestamp: 1481752800,
        open: 1.06271,
        close: 1.05377,
        high: 1.06709,
        low: 1.04969
      },
      {
        timestamp: 1481839200,
        open: 1.05377,
        close: 1.04157,
        high: 1.05404,
        low: 1.03673
      },
      {
        timestamp: 1481925600,
        open: 1.04157,
        close: 1.04589,
        high: 1.04752,
        low: 1.04015
      },
      {
        timestamp: 1482098400,
        open: 1.04589,
        close: 1.04448,
        high: 1.04493,
        low: 1.04308
      },
      {
        timestamp: 1482184800,
        open: 1.04448,
        close: 1.04045,
        high: 1.04804,
        low: 1.03934
      },
      {
        timestamp: 1482271200,
        open: 1.04045,
        close: 1.03915,
        high: 1.0419,
        low: 1.0353
      },
      {
        timestamp: 1482357600,
        open: 1.03915,
        close: 1.04256,
        high: 1.0452,
        low: 1.03833
      },
      {
        timestamp: 1482444000,
        open: 1.04256,
        close: 1.04389,
        high: 1.05005,
        low: 1.04245
      },
      {
        timestamp: 1482530400,
        open: 1.04389,
        close: 1.04607,
        high: 1.04701,
        low: 1.04274
      },
      {
        timestamp: 1482876000,
        open: 1.04607,
        close: 1.04599,
        high: 1.04643,
        low: 1.04333
      },
      {
        timestamp: 1482962400,
        open: 1.04599,
        close: 1.04188,
        high: 1.04808,
        low: 1.0373
      },
      {
        timestamp: 1483048800,
        open: 1.04188,
        close: 1.04922,
        high: 1.04949,
        low: 1.04096
      },
      {
        timestamp: 1483135200,
        open: 1.04922,
        close: 1.05301,
        high: 1.06613,
        low: 1.04858
      },
      {
        timestamp: 1483308000,
        open: 1.05301,
        close: 1.0525,
        high: 1.05258,
        low: 1.05218
      },
      {
        timestamp: 1483480800,
        open: 1.0525,
        close: 1.04071,
        high: 1.0525,
        low: 1.03412
      },
      {
        timestamp: 1483567200,
        open: 1.04071,
        close: 1.04928,
        high: 1.0501,
        low: 1.03903
      },
      {
        timestamp: 1483653600,
        open: 1.04928,
        close: 1.06091,
        high: 1.06161,
        low: 1.04817
      },
      {
        timestamp: 1483740000,
        open: 1.06091,
        close: 1.05378,
        high: 1.06247,
        low: 1.05257
      },
      {
        timestamp: 1483912800,
        open: 1.05378,
        close: 1.05325,
        high: 1.05375,
        low: 1.05306
      },
      {
        timestamp: 1483999200,
        open: 1.05325,
        close: 1.05773,
        high: 1.05842,
        low: 1.05116
      },
      {
        timestamp: 1484085600,
        open: 1.05773,
        close: 1.05553,
        high: 1.06282,
        low: 1.05515
      },
      {
        timestamp: 1484172000,
        open: 1.05553,
        close: 1.05842,
        high: 1.06241,
        low: 1.04547
      },
      {
        timestamp: 1484258400,
        open: 1.05842,
        close: 1.06142,
        high: 1.06856,
        low: 1.05723
      },
      {
        timestamp: 1484344800,
        open: 1.06142,
        close: 1.06507,
        high: 1.06768,
        low: 1.05968
      },
      {
        timestamp: 1484517600,
        open: 1.06507,
        close: 1.06074,
        high: 1.06234,
        low: 1.05943
      },
      {
        timestamp: 1484604000,
        open: 1.06074,
        close: 1.06059,
        high: 1.06367,
        low: 1.05799
      },
      {
        timestamp: 1484690400,
        open: 1.06059,
        close: 1.07147,
        high: 1.07202,
        low: 1.05987
      },
      {
        timestamp: 1484776800,
        open: 1.07147,
        close: 1.06319,
        high: 1.07168,
        low: 1.06293
      },
      {
        timestamp: 1484863200,
        open: 1.06319,
        close: 1.06659,
        high: 1.06775,
        low: 1.05897
      },
      {
        timestamp: 1484949600,
        open: 1.06659,
        close: 1.07077,
        high: 1.07104,
        low: 1.06259
      },
      {
        timestamp: 1485122400,
        open: 1.07077,
        close: 1.07045,
        high: 1.07147,
        low: 1.07077
      },
      {
        timestamp: 1485208800,
        open: 1.07045,
        close: 1.07664,
        high: 1.07703,
        low: 1.0697
      },
      {
        timestamp: 1485295200,
        open: 1.07664,
        close: 1.07333,
        high: 1.07758,
        low: 1.07209
      },
      {
        timestamp: 1485381600,
        open: 1.07333,
        close: 1.07504,
        high: 1.07707,
        low: 1.07121
      },
      {
        timestamp: 1485468000,
        open: 1.07504,
        close: 1.06838,
        high: 1.07664,
        low: 1.06584
      },
      {
        timestamp: 1485554400,
        open: 1.06838,
        close: 1.07016,
        high: 1.07258,
        low: 1.0659
      },
      {
        timestamp: 1485727200,
        open: 1.07016,
        close: 1.07254,
        high: 1.07337,
        low: 1.07016
      },
      {
        timestamp: 1485813600,
        open: 1.07254,
        close: 1.06991,
        high: 1.07413,
        low: 1.06209
      },
      {
        timestamp: 1485900000,
        open: 1.06991,
        close: 1.07989,
        high: 1.08132,
        low: 1.06852
      },
      {
        timestamp: 1485986400,
        open: 1.07989,
        close: 1.07694,
        high: 1.08083,
        low: 1.07335
      },
      {
        timestamp: 1486072800,
        open: 1.07694,
        close: 1.07602,
        high: 1.08296,
        low: 1.07565
      },
      {
        timestamp: 1486159200,
        open: 1.07602,
        close: 1.07917,
        high: 1.07985,
        low: 1.0713
      },
      {
        timestamp: 1486332000,
        open: 1.07917,
        close: 1.07915,
        high: 1.08056,
        low: 1.07917
      },
      {
        timestamp: 1486418400,
        open: 1.07915,
        close: 1.07521,
        high: 1.0795,
        low: 1.07063
      },
      {
        timestamp: 1486504800,
        open: 1.07521,
        close: 1.06849,
        high: 1.07525,
        low: 1.06567
      },
      {
        timestamp: 1486591200,
        open: 1.06849,
        close: 1.06999,
        high: 1.0715,
        low: 1.06414
      },
      {
        timestamp: 1486677600,
        open: 1.06999,
        close: 1.06625,
        high: 1.07102,
        low: 1.06516
      },
      {
        timestamp: 1486764000,
        open: 1.06625,
        close: 1.06475,
        high: 1.06685,
        low: 1.06084
      },
      {
        timestamp: 1486936800,
        open: 1.06475,
        close: 1.06301,
        high: 1.06325,
        low: 1.06267
      },
      {
        timestamp: 1487023200,
        open: 1.06301,
        close: 1.06027,
        high: 1.06596,
        low: 1.05927
      },
      {
        timestamp: 1487109600,
        open: 1.06027,
        close: 1.05858,
        high: 1.06344,
        low: 1.05618
      },
      {
        timestamp: 1487196000,
        open: 1.05858,
        close: 1.06015,
        high: 1.06106,
        low: 1.05222
      },
      {
        timestamp: 1487282400,
        open: 1.06015,
        close: 1.06753,
        high: 1.06802,
        low: 1.05921
      },
      {
        timestamp: 1487368800,
        open: 1.06753,
        close: 1.06138,
        high: 1.06778,
        low: 1.06062
      },
      {
        timestamp: 1487541600,
        open: 1.06138,
        close: 1.06109,
        high: 1.06163,
        low: 1.06094
      },
      {
        timestamp: 1487628000,
        open: 1.06109,
        close: 1.06143,
        high: 1.0634,
        low: 1.06036
      },
      {
        timestamp: 1487714400,
        open: 1.06143,
        close: 1.05429,
        high: 1.06191,
        low: 1.05265
      },
      {
        timestamp: 1487800800,
        open: 1.05429,
        close: 1.05598,
        high: 1.05753,
        low: 1.04943
      },
      {
        timestamp: 1487887200,
        open: 1.05598,
        close: 1.05843,
        high: 1.05962,
        low: 1.05383
      },
      {
        timestamp: 1487973600,
        open: 1.05843,
        close: 1.0564,
        high: 1.06193,
        low: 1.05576
      },
      {
        timestamp: 1488146400,
        open: 1.0564,
        close: 1.05673,
        high: 1.05724,
        low: 1.05629
      },
      {
        timestamp: 1488232800,
        open: 1.05673,
        close: 1.05883,
        high: 1.06321,
        low: 1.05522
      },
      {
        timestamp: 1488319200,
        open: 1.05883,
        close: 1.05792,
        high: 1.06306,
        low: 1.05689
      },
      {
        timestamp: 1488405600,
        open: 1.05792,
        close: 1.0549,
        high: 1.05904,
        low: 1.0515
      },
      {
        timestamp: 1488492000,
        open: 1.0549,
        close: 1.05078,
        high: 1.0553,
        low: 1.04956
      },
      {
        timestamp: 1488578400,
        open: 1.05078,
        close: 1.06243,
        high: 1.06252,
        low: 1.05031
      },
      {
        timestamp: 1488751200,
        open: 1.06243,
        close: 1.06126,
        high: 1.06211,
        low: 1.06047
      },
      {
        timestamp: 1488837600,
        open: 1.06126,
        close: 1.05835,
        high: 1.06415,
        low: 1.05752
      },
      {
        timestamp: 1488924000,
        open: 1.05835,
        close: 1.05674,
        high: 1.06036,
        low: 1.05589
      },
      {
        timestamp: 1489010400,
        open: 1.05674,
        close: 1.05438,
        high: 1.0575,
        low: 1.05355
      },
      {
        timestamp: 1489096800,
        open: 1.05438,
        close: 1.05793,
        high: 1.06166,
        low: 1.05258
      },
      {
        timestamp: 1489179600,
        open: 1.05793,
        close: 1.06792,
        high: 1.06999,
        low: 1.05735
      },
      {
        timestamp: 1489352400,
        open: 1.06792,
        close: 1.06821,
        high: 1.06901,
        low: 1.06761
      },
      {
        timestamp: 1489438800,
        open: 1.06821,
        close: 1.06536,
        high: 1.07152,
        low: 1.06529
      },
      {
        timestamp: 1489525200,
        open: 1.06536,
        close: 1.0607,
        high: 1.06636,
        low: 1.06006
      },
      {
        timestamp: 1489611600,
        open: 1.0607,
        close: 1.07347,
        high: 1.07413,
        low: 1.06045
      },
      {
        timestamp: 1489698000,
        open: 1.07347,
        close: 1.07667,
        high: 1.07712,
        low: 1.07064
      },
      {
        timestamp: 1489784400,
        open: 1.07667,
        close: 1.07415,
        high: 1.07833,
        low: 1.07283
      },
      {
        timestamp: 1489957200,
        open: 1.07415,
        close: 1.07351,
        high: 1.07431,
        low: 1.07338
      },
      {
        timestamp: 1490043600,
        open: 1.07351,
        close: 1.07422,
        high: 1.07783,
        low: 1.07258
      },
      {
        timestamp: 1490130000,
        open: 1.07422,
        close: 1.08127,
        high: 1.08202,
        low: 1.07199
      },
      {
        timestamp: 1490216400,
        open: 1.08127,
        close: 1.07997,
        high: 1.08256,
        low: 1.07766
      },
      {
        timestamp: 1490302800,
        open: 1.07997,
        close: 1.07866,
        high: 1.0806,
        low: 1.07687
      },
      {
        timestamp: 1490389200,
        open: 1.07866,
        close: 1.08013,
        high: 1.08189,
        low: 1.0761
      },
      {
        timestamp: 1490562000,
        open: 1.08013,
        close: 1.08438,
        high: 1.08561,
        low: 1.08013
      },
      {
        timestamp: 1490648400,
        open: 1.08438,
        close: 1.08635,
        high: 1.09072,
        low: 1.08291
      },
      {
        timestamp: 1490734800,
        open: 1.08635,
        close: 1.08159,
        high: 1.08734,
        low: 1.07995
      },
      {
        timestamp: 1490821200,
        open: 1.08159,
        close: 1.07668,
        high: 1.08274,
        low: 1.07406
      },
      {
        timestamp: 1490907600,
        open: 1.07668,
        close: 1.06763,
        high: 1.0771,
        low: 1.06725
      },
      {
        timestamp: 1490994000,
        open: 1.06763,
        close: 1.06607,
        high: 1.07026,
        low: 1.06524
      },
      {
        timestamp: 1491166800,
        open: 1.06607,
        close: 1.06666,
        high: 1.06689,
        low: 1.06618
      },
      {
        timestamp: 1491253200,
        open: 1.06666,
        close: 1.06704,
        high: 1.0682,
        low: 1.06432
      },
      {
        timestamp: 1491339600,
        open: 1.06704,
        close: 1.06742,
        high: 1.06783,
        low: 1.06365
      },
      {
        timestamp: 1491426000,
        open: 1.06742,
        close: 1.06642,
        high: 1.06898,
        low: 1.06367
      },
      {
        timestamp: 1491512400,
        open: 1.06642,
        close: 1.06457,
        high: 1.0685,
        low: 1.06297
      },
      {
        timestamp: 1491598800,
        open: 1.06457,
        close: 1.05927,
        high: 1.06709,
        low: 1.05813
      },
      {
        timestamp: 1491771600,
        open: 1.05927,
        close: 1.05903,
        high: 1.0595,
        low: 1.05847
      },
      {
        timestamp: 1491858000,
        open: 1.05903,
        close: 1.05976,
        high: 1.06075,
        low: 1.05706
      },
      {
        timestamp: 1491944400,
        open: 1.05976,
        close: 1.06052,
        high: 1.06308,
        low: 1.05794
      },
      {
        timestamp: 1492030800,
        open: 1.06052,
        close: 1.06671,
        high: 1.06759,
        low: 1.05896
      },
      {
        timestamp: 1492117200,
        open: 1.06671,
        close: 1.06145,
        high: 1.06784,
        low: 1.061
      },
      {
        timestamp: 1492203600,
        open: 1.06145,
        close: 1.06151,
        high: 1.06312,
        low: 1.06068
      },
      {
        timestamp: 1492376400,
        open: 1.06151,
        close: 1.06251,
        high: 1.06261,
        low: 1.06151
      },
      {
        timestamp: 1492462800,
        open: 1.06251,
        close: 1.06458,
        high: 1.06714,
        low: 1.06041
      },
      {
        timestamp: 1492549200,
        open: 1.06458,
        close: 1.07316,
        high: 1.07369,
        low: 1.06379
      },
      {
        timestamp: 1492635600,
        open: 1.07316,
        close: 1.07131,
        high: 1.07385,
        low: 1.07006
      },
      {
        timestamp: 1492722000,
        open: 1.07131,
        close: 1.07179,
        high: 1.07783,
        low: 1.07101
      },
      {
        timestamp: 1492808400,
        open: 1.07179,
        close: 1.0727,
        high: 1.0739,
        low: 1.06829
      },
      {
        timestamp: 1492981200,
        open: 1.0727,
        close: 1.09242,
        high: 1.09419,
        low: 1.0727
      },
      {
        timestamp: 1493067600,
        open: 1.09242,
        close: 1.08675,
        high: 1.09227,
        low: 1.08215
      },
      {
        timestamp: 1493154000,
        open: 1.08675,
        close: 1.09274,
        high: 1.0951,
        low: 1.08521
      },
      {
        timestamp: 1493240400,
        open: 1.09274,
        close: 1.09063,
        high: 1.09516,
        low: 1.08564
      },
      {
        timestamp: 1493326800,
        open: 1.09063,
        close: 1.08727,
        high: 1.0934,
        low: 1.08524
      },
      {
        timestamp: 1493413200,
        open: 1.08727,
        close: 1.09003,
        high: 1.09483,
        low: 1.0858
      },
      {
        timestamp: 1493586000,
        open: 1.09003,
        close: 1.09179,
        high: 1.09214,
        low: 1.09003
      },
      {
        timestamp: 1493672400,
        open: 1.09179,
        close: 1.09021,
        high: 1.09247,
        low: 1.08849
      },
      {
        timestamp: 1493758800,
        open: 1.09021,
        close: 1.09341,
        high: 1.09342,
        low: 1.0889
      },
      {
        timestamp: 1493845200,
        open: 1.09341,
        close: 1.08863,
        high: 1.09376,
        low: 1.08837
      },
      {
        timestamp: 1493931600,
        open: 1.08863,
        close: 1.09849,
        high: 1.0988,
        low: 1.08757
      },
      {
        timestamp: 1494018000,
        open: 1.09849,
        close: 1.10014,
        high: 1.10022,
        low: 1.0953
      },
      {
        timestamp: 1494190800,
        open: 1.10014,
        close: 1.10232,
        high: 1.10249,
        low: 1.10014
      },
      {
        timestamp: 1494277200,
        open: 1.10232,
        close: 1.09261,
        high: 1.10245,
        low: 1.09171
      },
      {
        timestamp: 1494363600,
        open: 1.09261,
        close: 1.08752,
        high: 1.09342,
        low: 1.08642
      },
      {
        timestamp: 1494450000,
        open: 1.08752,
        close: 1.08686,
        high: 1.0899,
        low: 1.08539
      },
      {
        timestamp: 1494536400,
        open: 1.08686,
        close: 1.08632,
        high: 1.08939,
        low: 1.08398
      },
      {
        timestamp: 1494622800,
        open: 1.08632,
        close: 1.09349,
        high: 1.09355,
        low: 1.08566
      },
      {
        timestamp: 1494795600,
        open: 1.09349,
        close: 1.09317,
        high: 1.0936,
        low: 1.09289
      },
      {
        timestamp: 1494882000,
        open: 1.09317,
        close: 1.09769,
        high: 1.09903,
        low: 1.09235
      },
      {
        timestamp: 1494968400,
        open: 1.09769,
        close: 1.10854,
        high: 1.1098,
        low: 1.09757
      },
      {
        timestamp: 1495054800,
        open: 1.10854,
        close: 1.11611,
        high: 1.1163,
        low: 1.10808
      },
      {
        timestamp: 1495141200,
        open: 1.11611,
        close: 1.11042,
        high: 1.11728,
        low: 1.10767
      },
      {
        timestamp: 1495227600,
        open: 1.11042,
        close: 1.12088,
        high: 1.12126,
        low: 1.10979
      },
      {
        timestamp: 1495400400,
        open: 1.12088,
        close: 1.11997,
        high: 1.12108,
        low: 1.11872
      },
      {
        timestamp: 1495486800,
        open: 1.11997,
        close: 1.124,
        high: 1.12643,
        low: 1.11623
      },
      {
        timestamp: 1495573200,
        open: 1.124,
        close: 1.11838,
        high: 1.12699,
        low: 1.11757
      },
      {
        timestamp: 1495659600,
        open: 1.11838,
        close: 1.12193,
        high: 1.12208,
        low: 1.11691
      },
      {
        timestamp: 1495746000,
        open: 1.12193,
        close: 1.12117,
        high: 1.12513,
        low: 1.11943
      },
      {
        timestamp: 1495832400,
        open: 1.12117,
        close: 1.11809,
        high: 1.12353,
        low: 1.11614
      },
      {
        timestamp: 1496005200,
        open: 1.11809,
        close: 1.11789,
        high: 1.11798,
        low: 1.11744
      },
      {
        timestamp: 1496091600,
        open: 1.11789,
        close: 1.11661,
        high: 1.11907,
        low: 1.11627
      },
      {
        timestamp: 1496178000,
        open: 1.11661,
        close: 1.11883,
        high: 1.12065,
        low: 1.11101
      },
      {
        timestamp: 1496264400,
        open: 1.11883,
        close: 1.12435,
        high: 1.12531,
        low: 1.11652
      },
      {
        timestamp: 1496350800,
        open: 1.12435,
        close: 1.12134,
        high: 1.12575,
        low: 1.12028
      },
      {
        timestamp: 1496437200,
        open: 1.12134,
        close: 1.12824,
        high: 1.12866,
        low: 1.12057
      },
      {
        timestamp: 1496610000,
        open: 1.12824,
        close: 1.12785,
        high: 1.12873,
        low: 1.12737
      },
      {
        timestamp: 1496696400,
        open: 1.12785,
        close: 1.12549,
        high: 1.12867,
        low: 1.1235
      },
      {
        timestamp: 1496782800,
        open: 1.12549,
        close: 1.12784,
        high: 1.1285,
        low: 1.12412
      },
      {
        timestamp: 1496869200,
        open: 1.12784,
        close: 1.1258,
        high: 1.12833,
        low: 1.12048
      },
      {
        timestamp: 1496955600,
        open: 1.1258,
        close: 1.12147,
        high: 1.12713,
        low: 1.11954
      },
      {
        timestamp: 1497042000,
        open: 1.12147,
        close: 1.11984,
        high: 1.12398,
        low: 1.11671
      },
      {
        timestamp: 1497214800,
        open: 1.11984,
        close: 1.12113,
        high: 1.12175,
        low: 1.11995
      },
      {
        timestamp: 1497301200,
        open: 1.12113,
        close: 1.12056,
        high: 1.12329,
        low: 1.11927
      },
      {
        timestamp: 1497387600,
        open: 1.12056,
        close: 1.12115,
        high: 1.12257,
        low: 1.1186
      },
      {
        timestamp: 1497474000,
        open: 1.12115,
        close: 1.12204,
        high: 1.12966,
        low: 1.11938
      },
      {
        timestamp: 1497560400,
        open: 1.12204,
        close: 1.11484,
        high: 1.12294,
        low: 1.11328
      },
      {
        timestamp: 1497646800,
        open: 1.11484,
        close: 1.11985,
        high: 1.12025,
        low: 1.11391
      },
      {
        timestamp: 1497819600,
        open: 1.11985,
        close: 1.12113,
        high: 1.12171,
        low: 1.11985
      },
      {
        timestamp: 1497906000,
        open: 1.12113,
        close: 1.11492,
        high: 1.12161,
        low: 1.11438
      },
      {
        timestamp: 1497992400,
        open: 1.11492,
        close: 1.11352,
        high: 1.11657,
        low: 1.11196
      },
      {
        timestamp: 1498078800,
        open: 1.11352,
        close: 1.11706,
        high: 1.11707,
        low: 1.11281
      },
      {
        timestamp: 1498165200,
        open: 1.11706,
        close: 1.11533,
        high: 1.11787,
        low: 1.11401
      },
      {
        timestamp: 1498251600,
        open: 1.11533,
        close: 1.11941,
        high: 1.12097,
        low: 1.1146
      },
      {
        timestamp: 1498424400,
        open: 1.11941,
        close: 1.1202,
        high: 1.1205,
        low: 1.11949
      },
      {
        timestamp: 1498510800,
        open: 1.1202,
        close: 1.11825,
        high: 1.12207,
        low: 1.11725
      },
      {
        timestamp: 1498597200,
        open: 1.11825,
        close: 1.13403,
        high: 1.13503,
        low: 1.11797
      },
      {
        timestamp: 1498683600,
        open: 1.13403,
        close: 1.13777,
        high: 1.1392,
        low: 1.12922
      },
      {
        timestamp: 1498770000,
        open: 1.13777,
        close: 1.14409,
        high: 1.14462,
        low: 1.13754
      },
      {
        timestamp: 1498856400,
        open: 1.14409,
        close: 1.14249,
        high: 1.14462,
        low: 1.13928
      },
      {
        timestamp: 1499029200,
        open: 1.14249,
        close: 1.1422,
        high: 1.14274,
        low: 1.14175
      },
      {
        timestamp: 1499115600,
        open: 1.1422,
        close: 1.13652,
        high: 1.14291,
        low: 1.13557
      },
      {
        timestamp: 1499202000,
        open: 1.13652,
        close: 1.13493,
        high: 1.13778,
        low: 1.13368
      },
      {
        timestamp: 1499288400,
        open: 1.13493,
        close: 1.13524,
        high: 1.13694,
        low: 1.13132
      },
      {
        timestamp: 1499374800,
        open: 1.13524,
        close: 1.14249,
        high: 1.14263,
        low: 1.13306
      },
      {
        timestamp: 1499461200,
        open: 1.14249,
        close: 1.14022,
        high: 1.14407,
        low: 1.13803
      },
      {
        timestamp: 1499634000,
        open: 1.14022,
        close: 1.14002,
        high: 1.14034,
        low: 1.13955
      },
      {
        timestamp: 1499720400,
        open: 1.14002,
        close: 1.14003,
        high: 1.14189,
        low: 1.13824
      },
      {
        timestamp: 1499806800,
        open: 1.14003,
        close: 1.14679,
        high: 1.14804,
        low: 1.13835
      },
      {
        timestamp: 1499893200,
        open: 1.14679,
        close: 1.14127,
        high: 1.14904,
        low: 1.13924
      },
      {
        timestamp: 1499979600,
        open: 1.14127,
        close: 1.13983,
        high: 1.14567,
        low: 1.1371
      },
      {
        timestamp: 1500066000,
        open: 1.13983,
        close: 1.14697,
        high: 1.14727,
        low: 1.13923
      },
      {
        timestamp: 1500238800,
        open: 1.14697,
        close: 1.14756,
        high: 1.14777,
        low: 1.14681
      },
      {
        timestamp: 1500325200,
        open: 1.14756,
        close: 1.14798,
        high: 1.1488,
        low: 1.14355
      },
      {
        timestamp: 1500411600,
        open: 1.14798,
        close: 1.15544,
        high: 1.15841,
        low: 1.14723
      },
      {
        timestamp: 1500498000,
        open: 1.15544,
        close: 1.15166,
        high: 1.15598,
        low: 1.15106
      },
      {
        timestamp: 1500584400,
        open: 1.15166,
        close: 1.1632,
        high: 1.16597,
        low: 1.14799
      },
      {
        timestamp: 1500670800,
        open: 1.1632,
        close: 1.16653,
        high: 1.16838,
        low: 1.162
      },
      {
        timestamp: 1500843600,
        open: 1.16653,
        close: 1.16687,
        high: 1.16733,
        low: 1.16636
      },
      {
        timestamp: 1500930000,
        open: 1.16687,
        close: 1.16419,
        high: 1.1685,
        low: 1.16266
      },
      {
        timestamp: 1501016400,
        open: 1.16419,
        close: 1.1648,
        high: 1.17131,
        low: 1.16315
      },
      {
        timestamp: 1501102800,
        open: 1.1648,
        close: 1.17371,
        high: 1.17415,
        low: 1.16134
      },
      {
        timestamp: 1501189200,
        open: 1.17371,
        close: 1.16792,
        high: 1.17777,
        low: 1.16509
      },
      {
        timestamp: 1501275600,
        open: 1.16792,
        close: 1.17508,
        high: 1.17648,
        low: 1.16719
      },
      {
        timestamp: 1501448400,
        open: 1.17508,
        close: 1.17518,
        high: 1.17578,
        low: 1.17404
      },
      {
        timestamp: 1501534800,
        open: 1.17518,
        close: 1.18423,
        high: 1.18468,
        low: 1.17239
      },
      {
        timestamp: 1501621200,
        open: 1.18423,
        close: 1.18028,
        high: 1.18459,
        low: 1.17859
      },
      {
        timestamp: 1501707600,
        open: 1.18028,
        close: 1.18578,
        high: 1.19116,
        low: 1.17945
      },
      {
        timestamp: 1501794000,
        open: 1.18578,
        close: 1.18696,
        high: 1.18939,
        low: 1.18312
      },
      {
        timestamp: 1501880400,
        open: 1.18696,
        close: 1.17752,
        high: 1.189,
        low: 1.1729
      },
      {
        timestamp: 1502053200,
        open: 1.17752,
        close: 1.17763,
        high: 1.17866,
        low: 1.17729
      },
      {
        timestamp: 1502139600,
        open: 1.17763,
        close: 1.17964,
        high: 1.1815,
        low: 1.1772
      },
      {
        timestamp: 1502226000,
        open: 1.17964,
        close: 1.17544,
        high: 1.18247,
        low: 1.1716
      },
      {
        timestamp: 1502312400,
        open: 1.17544,
        close: 1.17588,
        high: 1.17647,
        low: 1.16898
      },
      {
        timestamp: 1502398800,
        open: 1.17588,
        close: 1.17741,
        high: 1.1786,
        low: 1.17049
      },
      {
        timestamp: 1502485200,
        open: 1.17741,
        close: 1.18228,
        high: 1.18486,
        low: 1.17491
      },
      {
        timestamp: 1502658000,
        open: 1.18228,
        close: 1.18243,
        high: 1.18309,
        low: 1.1816
      },
      {
        timestamp: 1502744400,
        open: 1.18243,
        close: 1.17815,
        high: 1.18392,
        low: 1.17705
      },
      {
        timestamp: 1502830800,
        open: 1.17815,
        close: 1.17359,
        high: 1.17933,
        low: 1.16879
      },
      {
        timestamp: 1502917200,
        open: 1.17359,
        close: 1.1768,
        high: 1.17795,
        low: 1.16817
      },
      {
        timestamp: 1503003600,
        open: 1.1768,
        close: 1.17253,
        high: 1.17906,
        low: 1.16629
      },
      {
        timestamp: 1503090000,
        open: 1.17253,
        close: 1.17608,
        high: 1.17755,
        low: 1.17093
      },
      {
        timestamp: 1503262800,
        open: 1.17608,
        close: 1.17636,
        high: 1.17683,
        low: 1.17596
      },
      {
        timestamp: 1503349200,
        open: 1.17636,
        close: 1.18154,
        high: 1.18289,
        low: 1.17318
      },
      {
        timestamp: 1503435600,
        open: 1.18154,
        close: 1.1763,
        high: 1.18252,
        low: 1.17458
      },
      {
        timestamp: 1503522000,
        open: 1.1763,
        close: 1.18073,
        high: 1.18241,
        low: 1.1741
      },
      {
        timestamp: 1503608400,
        open: 1.18073,
        close: 1.17998,
        high: 1.18188,
        low: 1.17849
      },
      {
        timestamp: 1503694800,
        open: 1.17998,
        close: 1.19235,
        high: 1.19426,
        low: 1.17739
      },
      {
        timestamp: 1503867600,
        open: 1.19235,
        close: 1.19505,
        high: 1.19685,
        low: 1.19235
      },
      {
        timestamp: 1503954000,
        open: 1.19505,
        close: 1.19795,
        high: 1.19845,
        low: 1.19178
      },
      {
        timestamp: 1504040400,
        open: 1.19795,
        close: 1.19732,
        high: 1.20711,
        low: 1.19468
      },
      {
        timestamp: 1504126800,
        open: 1.19732,
        close: 1.18849,
        high: 1.1985,
        low: 1.18814
      },
      {
        timestamp: 1504213200,
        open: 1.18849,
        close: 1.19105,
        high: 1.19136,
        low: 1.18237
      },
      {
        timestamp: 1504299600,
        open: 1.19105,
        close: 1.18635,
        high: 1.19805,
        low: 1.18504
      },
      {
        timestamp: 1504472400,
        open: 1.18635,
        close: 1.18857,
        high: 1.18927,
        low: 1.18546
      },
      {
        timestamp: 1504558800,
        open: 1.18857,
        close: 1.1902,
        high: 1.19228,
        low: 1.18754
      },
      {
        timestamp: 1504645200,
        open: 1.1902,
        close: 1.19165,
        high: 1.19415,
        low: 1.18689
      },
      {
        timestamp: 1504731600,
        open: 1.19165,
        close: 1.19178,
        high: 1.19509,
        low: 1.19037
      },
      {
        timestamp: 1504818000,
        open: 1.19178,
        close: 1.20275,
        high: 1.20603,
        low: 1.19148
      },
      {
        timestamp: 1504904400,
        open: 1.20275,
        close: 1.20416,
        high: 1.20932,
        low: 1.20152
      },
      {
        timestamp: 1505077200,
        open: 1.20416,
        close: 1.20227,
        high: 1.20307,
        low: 1.20137
      },
      {
        timestamp: 1505163600,
        open: 1.20227,
        close: 1.19536,
        high: 1.20302,
        low: 1.19484
      },
      {
        timestamp: 1505250000,
        open: 1.19536,
        close: 1.19676,
        high: 1.19789,
        low: 1.19269
      },
      {
        timestamp: 1505336400,
        open: 1.19676,
        close: 1.18864,
        high: 1.19957,
        low: 1.18737
      },
      {
        timestamp: 1505422800,
        open: 1.18864,
        close: 1.19192,
        high: 1.1923,
        low: 1.1838
      },
      {
        timestamp: 1505509200,
        open: 1.19192,
        close: 1.19473,
        high: 1.19883,
        low: 1.19017
      },
      {
        timestamp: 1505682000,
        open: 1.19473,
        close: 1.1922,
        high: 1.19456,
        low: 1.19219
      },
      {
        timestamp: 1505768400,
        open: 1.1922,
        close: 1.1955,
        high: 1.19701,
        low: 1.19154
      },
      {
        timestamp: 1505854800,
        open: 1.1955,
        close: 1.19951,
        high: 1.20075,
        low: 1.1955
      },
      {
        timestamp: 1505941200,
        open: 1.19951,
        close: 1.18953,
        high: 1.2037,
        low: 1.1862
      },
      {
        timestamp: 1506027600,
        open: 1.18953,
        close: 1.19422,
        high: 1.19545,
        low: 1.18664
      },
      {
        timestamp: 1506114000,
        open: 1.19422,
        close: 1.19484,
        high: 1.20053,
        low: 1.19378
      },
      {
        timestamp: 1506286800,
        open: 1.19484,
        close: 1.18999,
        high: 1.19329,
        low: 1.18977
      },
      {
        timestamp: 1506373200,
        open: 1.18999,
        close: 1.18494,
        high: 1.19373,
        low: 1.18326
      },
      {
        timestamp: 1506459600,
        open: 1.18494,
        close: 1.17944,
        high: 1.18623,
        low: 1.17578
      },
      {
        timestamp: 1506546000,
        open: 1.17944,
        close: 1.17456,
        high: 1.17961,
        low: 1.17176
      },
      {
        timestamp: 1506632400,
        open: 1.17456,
        close: 1.17869,
        high: 1.18049,
        low: 1.17219
      },
      {
        timestamp: 1506718800,
        open: 1.17869,
        close: 1.18192,
        high: 1.18332,
        low: 1.17732
      },
      {
        timestamp: 1506891600,
        open: 1.18192,
        close: 1.17952,
        high: 1.18009,
        low: 1.17768
      },
      {
        timestamp: 1506978000,
        open: 1.17952,
        close: 1.17339,
        high: 1.18165,
        low: 1.17308
      },
      {
        timestamp: 1507064400,
        open: 1.17339,
        close: 1.17454,
        high: 1.17744,
        low: 1.16965
      },
      {
        timestamp: 1507150800,
        open: 1.17454,
        close: 1.17619,
        high: 1.17892,
        low: 1.17363
      },
      {
        timestamp: 1507237200,
        open: 1.17619,
        close: 1.17124,
        high: 1.17797,
        low: 1.16998
      },
      {
        timestamp: 1507323600,
        open: 1.17124,
        close: 1.17374,
        high: 1.17395,
        low: 1.167
      },
      {
        timestamp: 1507496400,
        open: 1.17374,
        close: 1.17317,
        high: 1.17397,
        low: 1.17198
      },
      {
        timestamp: 1507582800,
        open: 1.17317,
        close: 1.17415,
        high: 1.17566,
        low: 1.17202
      },
      {
        timestamp: 1507669200,
        open: 1.17415,
        close: 1.18093,
        high: 1.18262,
        low: 1.17401
      },
      {
        timestamp: 1507755600,
        open: 1.18093,
        close: 1.18613,
        high: 1.18699,
        low: 1.17959
      },
      {
        timestamp: 1507842000,
        open: 1.18613,
        close: 1.18312,
        high: 1.18805,
        low: 1.18277
      },
      {
        timestamp: 1507928400,
        open: 1.18312,
        close: 1.18256,
        high: 1.18756,
        low: 1.1806
      },
      {
        timestamp: 1508101200,
        open: 1.18256,
        close: 1.18158,
        high: 1.18218,
        low: 1.18149
      },
      {
        timestamp: 1508187600,
        open: 1.18158,
        close: 1.17972,
        high: 1.18204,
        low: 1.1781
      },
      {
        timestamp: 1508274000,
        open: 1.17972,
        close: 1.17674,
        high: 1.18011,
        low: 1.17368
      },
      {
        timestamp: 1508360400,
        open: 1.17674,
        close: 1.17884,
        high: 1.18061,
        low: 1.17308
      },
      {
        timestamp: 1508446800,
        open: 1.17884,
        close: 1.18545,
        high: 1.18587,
        low: 1.17687
      },
      {
        timestamp: 1508533200,
        open: 1.18545,
        close: 1.17828,
        high: 1.18587,
        low: 1.17631
      },
      {
        timestamp: 1508706000,
        open: 1.17828,
        close: 1.17661,
        high: 1.17661,
        low: 1.17603
      },
      {
        timestamp: 1508792400,
        open: 1.17661,
        close: 1.17501,
        high: 1.1778,
        low: 1.17254
      },
      {
        timestamp: 1508878800,
        open: 1.17501,
        close: 1.17628,
        high: 1.17936,
        low: 1.17435
      },
      {
        timestamp: 1508965200,
        open: 1.17628,
        close: 1.18138,
        high: 1.18184,
        low: 1.17538
      },
      {
        timestamp: 1509051600,
        open: 1.18138,
        close: 1.16511,
        high: 1.18375,
        low: 1.16411
      },
      {
        timestamp: 1509138000,
        open: 1.16511,
        close: 1.16133,
        high: 1.16582,
        low: 1.15745
      },
      {
        timestamp: 1509310800,
        open: 1.16133,
        close: 1.16146,
        high: 1.16192,
        low: 1.16049
      },
      {
        timestamp: 1509397200,
        open: 1.16146,
        close: 1.16515,
        high: 1.16586,
        low: 1.15946
      },
      {
        timestamp: 1509483600,
        open: 1.16515,
        close: 1.16471,
        high: 1.16622,
        low: 1.16256
      },
      {
        timestamp: 1509570000,
        open: 1.16471,
        close: 1.16203,
        high: 1.16579,
        low: 1.16069
      },
      {
        timestamp: 1509656400,
        open: 1.16203,
        close: 1.16592,
        high: 1.16879,
        low: 1.16141
      },
      {
        timestamp: 1509746400,
        open: 1.16592,
        close: 1.16117,
        high: 1.16969,
        low: 1.15998
      },
      {
        timestamp: 1509919200,
        open: 1.16117,
        close: 1.16187,
        high: 1.16212,
        low: 1.16117
      },
      {
        timestamp: 1510005600,
        open: 1.16187,
        close: 1.16118,
        high: 1.16248,
        low: 1.15809
      },
      {
        timestamp: 1510092000,
        open: 1.16118,
        close: 1.15876,
        high: 1.16175,
        low: 1.15544
      },
      {
        timestamp: 1510178400,
        open: 1.15876,
        close: 1.1597,
        high: 1.16119,
        low: 1.15796
      },
      {
        timestamp: 1510264800,
        open: 1.1597,
        close: 1.16426,
        high: 1.16555,
        low: 1.15863
      },
      {
        timestamp: 1510351200,
        open: 1.16426,
        close: 1.16671,
        high: 1.16786,
        low: 1.16234
      },
      {
        timestamp: 1510524000,
        open: 1.16671,
        close: 1.16609,
        high: 1.16713,
        low: 1.16572
      },
      {
        timestamp: 1510610400,
        open: 1.16609,
        close: 1.16679,
        high: 1.16759,
        low: 1.16382
      },
      {
        timestamp: 1510696800,
        open: 1.16679,
        close: 1.17986,
        high: 1.18059,
        low: 1.16622
      },
      {
        timestamp: 1510783200,
        open: 1.17986,
        close: 1.17924,
        high: 1.18616,
        low: 1.17855
      },
      {
        timestamp: 1510869600,
        open: 1.17924,
        close: 1.17713,
        high: 1.18017,
        low: 1.17573
      },
      {
        timestamp: 1510956000,
        open: 1.17713,
        close: 1.17954,
        high: 1.18225,
        low: 1.17661
      },
      {
        timestamp: 1511128800,
        open: 1.17954,
        close: 1.17899,
        high: 1.17935,
        low: 1.17899
      },
      {
        timestamp: 1511215200,
        open: 1.17899,
        close: 1.17332,
        high: 1.18094,
        low: 1.17231
      },
      {
        timestamp: 1511301600,
        open: 1.17332,
        close: 1.17388,
        high: 1.17584,
        low: 1.17134
      },
      {
        timestamp: 1511388000,
        open: 1.17388,
        close: 1.18236,
        high: 1.18277,
        low: 1.17336
      },
      {
        timestamp: 1511474400,
        open: 1.18236,
        close: 1.18528,
        high: 1.18568,
        low: 1.18139
      },
      {
        timestamp: 1511560800,
        open: 1.18528,
        close: 1.19374,
        high: 1.1945,
        low: 1.18375
      },
      {
        timestamp: 1511733600,
        open: 1.19374,
        close: 1.19302,
        high: 1.19452,
        low: 1.19296
      },
      {
        timestamp: 1511820000,
        open: 1.19302,
        close: 1.18991,
        high: 1.19619,
        low: 1.18963
      },
      {
        timestamp: 1511906400,
        open: 1.18991,
        close: 1.18434,
        high: 1.19206,
        low: 1.18277
      },
      {
        timestamp: 1511992800,
        open: 1.18434,
        close: 1.18479,
        high: 1.18836,
        low: 1.18177
      },
      {
        timestamp: 1512079200,
        open: 1.18479,
        close: 1.19046,
        high: 1.19322,
        low: 1.18096
      },
      {
        timestamp: 1512165600,
        open: 1.19046,
        close: 1.19026,
        high: 1.19411,
        low: 1.18515
      },
      {
        timestamp: 1512338400,
        open: 1.19026,
        close: 1.18652,
        high: 1.18696,
        low: 1.18517
      },
      {
        timestamp: 1512424800,
        open: 1.18652,
        close: 1.18673,
        high: 1.18793,
        low: 1.18299
      },
      {
        timestamp: 1512511200,
        open: 1.18673,
        close: 1.1827,
        high: 1.18774,
        low: 1.18011
      },
      {
        timestamp: 1512597600,
        open: 1.1827,
        close: 1.17967,
        high: 1.18488,
        low: 1.17813
      },
      {
        timestamp: 1512684000,
        open: 1.17967,
        close: 1.17736,
        high: 1.18154,
        low: 1.17726
      },
      {
        timestamp: 1512770400,
        open: 1.17736,
        close: 1.17778,
        high: 1.17778,
        low: 1.17307
      },
      {
        timestamp: 1512943200,
        open: 1.17778,
        close: 1.17677,
        high: 1.17712,
        low: 1.17655
      },
      {
        timestamp: 1513029600,
        open: 1.17677,
        close: 1.17695,
        high: 1.18124,
        low: 1.17646
      },
      {
        timestamp: 1513116000,
        open: 1.17695,
        close: 1.17427,
        high: 1.17935,
        low: 1.17182
      },
      {
        timestamp: 1513202400,
        open: 1.17427,
        close: 1.18267,
        high: 1.18324,
        low: 1.17301
      },
      {
        timestamp: 1513288800,
        open: 1.18267,
        close: 1.1779,
        high: 1.18633,
        low: 1.17714
      },
      {
        timestamp: 1513375200,
        open: 1.1779,
        close: 1.17549,
        high: 1.1813,
        low: 1.17508
      },
      {
        timestamp: 1513548000,
        open: 1.17549,
        close: 1.17473,
        high: 1.17568,
        low: 1.17405
      },
      {
        timestamp: 1513634400,
        open: 1.17473,
        close: 1.17829,
        high: 1.18349,
        low: 1.17391
      },
      {
        timestamp: 1513720800,
        open: 1.17829,
        close: 1.1841,
        high: 1.18495,
        low: 1.17771
      },
      {
        timestamp: 1513807200,
        open: 1.1841,
        close: 1.18724,
        high: 1.19024,
        low: 1.18296
      },
      {
        timestamp: 1513893600,
        open: 1.18724,
        close: 1.18758,
        high: 1.18902,
        low: 1.18499
      },
      {
        timestamp: 1513980000,
        open: 1.18758,
        close: 1.18673,
        high: 1.18764,
        low: 1.18177
      },
      {
        timestamp: 1514325600,
        open: 1.18673,
        close: 1.18593,
        high: 1.18797,
        low: 1.18473
      },
      {
        timestamp: 1514412000,
        open: 1.18593,
        close: 1.18891,
        high: 1.1911,
        low: 1.18558
      },
      {
        timestamp: 1514498400,
        open: 1.18891,
        close: 1.19432,
        high: 1.19598,
        low: 1.18899
      },
      {
        timestamp: 1514584800,
        open: 1.19432,
        close: 1.20098,
        high: 1.20262,
        low: 1.19375
      },
      {
        timestamp: 1514844000,
        open: 1.20098,
        close: 1.2015,
        high: 1.2015,
        low: 1.2
      },
      {
        timestamp: 1514930400,
        open: 1.2015,
        close: 1.20591,
        high: 1.20819,
        low: 1.20045
      },
      {
        timestamp: 1515016800,
        open: 1.20591,
        close: 1.20152,
        high: 1.20669,
        low: 1.20018
      },
      {
        timestamp: 1515103200,
        open: 1.20152,
        close: 1.20693,
        high: 1.20897,
        low: 1.20051
      },
      {
        timestamp: 1515189600,
        open: 1.20693,
        close: 1.20358,
        high: 1.20838,
        low: 1.20213
      },
      {
        timestamp: 1515362400,
        open: 1.20358,
        close: 1.20324,
        high: 1.20357,
        low: 1.20227
      },
      {
        timestamp: 1515448800,
        open: 1.20324,
        close: 1.19681,
        high: 1.20531,
        low: 1.19564
      },
      {
        timestamp: 1515535200,
        open: 1.19681,
        close: 1.19377,
        high: 1.19764,
        low: 1.19161
      },
      {
        timestamp: 1515621600,
        open: 1.19377,
        close: 1.19489,
        high: 1.20187,
        low: 1.19239
      },
      {
        timestamp: 1515708000,
        open: 1.19489,
        close: 1.20326,
        high: 1.20596,
        low: 1.19299
      },
      {
        timestamp: 1515794400,
        open: 1.20326,
        close: 1.22094,
        high: 1.22202,
        low: 1.20316
      },
      {
        timestamp: 1515967200,
        open: 1.22094,
        close: 1.21977,
        high: 1.22061,
        low: 1.21917
      },
      {
        timestamp: 1516053600,
        open: 1.21977,
        close: 1.22648,
        high: 1.22973,
        low: 1.21882
      },
      {
        timestamp: 1516140000,
        open: 1.22648,
        close: 1.22606,
        high: 1.22841,
        low: 1.21958
      },
      {
        timestamp: 1516226400,
        open: 1.22606,
        close: 1.21872,
        high: 1.23239,
        low: 1.21781
      },
      {
        timestamp: 1516312800,
        open: 1.21872,
        close: 1.22402,
        high: 1.22656,
        low: 1.21661
      },
      {
        timestamp: 1516399200,
        open: 1.22402,
        close: 1.222,
        high: 1.22962,
        low: 1.22155
      },
      {
        timestamp: 1516572000,
        open: 1.222,
        close: 1.22722,
        high: 1.22757,
        low: 1.222
      },
      {
        timestamp: 1516658400,
        open: 1.22722,
        close: 1.22628,
        high: 1.22752,
        low: 1.22147
      },
      {
        timestamp: 1516744800,
        open: 1.22628,
        close: 1.22993,
        high: 1.23069,
        low: 1.22237
      },
      {
        timestamp: 1516831200,
        open: 1.22993,
        close: 1.24096,
        high: 1.24157,
        low: 1.2292
      },
      {
        timestamp: 1516917600,
        open: 1.24096,
        close: 1.23987,
        high: 1.25387,
        low: 1.23648
      },
      {
        timestamp: 1517004000,
        open: 1.23987,
        close: 1.24334,
        high: 1.24945,
        low: 1.23708
      },
      {
        timestamp: 1517176800,
        open: 1.24334,
        close: 1.24299,
        high: 1.24396,
        low: 1.24247
      },
      {
        timestamp: 1517263200,
        open: 1.24299,
        close: 1.23841,
        high: 1.2433,
        low: 1.23374
      },
      {
        timestamp: 1517349600,
        open: 1.23841,
        close: 1.24033,
        high: 1.24547,
        low: 1.23353
      },
      {
        timestamp: 1517436000,
        open: 1.24033,
        close: 1.24143,
        high: 1.24756,
        low: 1.23874
      },
      {
        timestamp: 1517522400,
        open: 1.24143,
        close: 1.25132,
        high: 1.25235,
        low: 1.23858
      },
      {
        timestamp: 1517608800,
        open: 1.25132,
        close: 1.24599,
        high: 1.25188,
        low: 1.24096
      },
      {
        timestamp: 1517781600,
        open: 1.24599,
        close: 1.24367,
        high: 1.24417,
        low: 1.24278
      },
      {
        timestamp: 1517868000,
        open: 1.24367,
        close: 1.23684,
        high: 1.24755,
        low: 1.23634
      },
      {
        timestamp: 1517954400,
        open: 1.23684,
        close: 1.23784,
        high: 1.24353,
        low: 1.23145
      },
      {
        timestamp: 1518040800,
        open: 1.23784,
        close: 1.22664,
        high: 1.24069,
        low: 1.22462
      },
      {
        timestamp: 1518127200,
        open: 1.22664,
        close: 1.22482,
        high: 1.22958,
        low: 1.22127
      },
      {
        timestamp: 1518213600,
        open: 1.22482,
        close: 1.22558,
        high: 1.2288,
        low: 1.22064
      },
      {
        timestamp: 1518386400,
        open: 1.22558,
        close: 1.22512,
        high: 1.22532,
        low: 1.22479
      },
      {
        timestamp: 1518472800,
        open: 1.22512,
        close: 1.22935,
        high: 1.22983,
        low: 1.22357
      },
      {
        timestamp: 1518559200,
        open: 1.22935,
        close: 1.23536,
        high: 1.23719,
        low: 1.22853
      },
      {
        timestamp: 1518645600,
        open: 1.23536,
        close: 1.24525,
        high: 1.24657,
        low: 1.22766
      },
      {
        timestamp: 1518732000,
        open: 1.24525,
        close: 1.25075,
        high: 1.25111,
        low: 1.24488
      },
      {
        timestamp: 1518818400,
        open: 1.25075,
        close: 1.24136,
        high: 1.25561,
        low: 1.23939
      },
      {
        timestamp: 1518991200,
        open: 1.24136,
        close: 1.24058,
        high: 1.24063,
        low: 1.24005
      },
      {
        timestamp: 1519077600,
        open: 1.24058,
        close: 1.24083,
        high: 1.24361,
        low: 1.23697
      },
      {
        timestamp: 1519164000,
        open: 1.24083,
        close: 1.23383,
        high: 1.24133,
        low: 1.23203
      },
      {
        timestamp: 1519250400,
        open: 1.23383,
        close: 1.22853,
        high: 1.23607,
        low: 1.22818
      },
      {
        timestamp: 1519336800,
        open: 1.22853,
        close: 1.23325,
        high: 1.23528,
        low: 1.22599
      },
      {
        timestamp: 1519423200,
        open: 1.23325,
        close: 1.22993,
        high: 1.23384,
        low: 1.22804
      },
      {
        timestamp: 1519596000,
        open: 1.22993,
        close: 1.22954,
        high: 1.23055,
        low: 1.22875
      },
      {
        timestamp: 1519682400,
        open: 1.22954,
        close: 1.23182,
        high: 1.2356,
        low: 1.22783
      },
      {
        timestamp: 1519768800,
        open: 1.23182,
        close: 1.22333,
        high: 1.2347,
        low: 1.22221
      },
      {
        timestamp: 1519855200,
        open: 1.22333,
        close: 1.21957,
        high: 1.22424,
        low: 1.21885
      },
      {
        timestamp: 1519941600,
        open: 1.21957,
        close: 1.22681,
        high: 1.22737,
        low: 1.21551
      },
      {
        timestamp: 1520028000,
        open: 1.22681,
        close: 1.2322,
        high: 1.23366,
        low: 1.22522
      },
      {
        timestamp: 1520200800,
        open: 1.2322,
        close: 1.23347,
        high: 1.23597,
        low: 1.2322
      },
      {
        timestamp: 1520287200,
        open: 1.23347,
        close: 1.23368,
        high: 1.23667,
        low: 1.22697
      },
      {
        timestamp: 1520373600,
        open: 1.23368,
        close: 1.24044,
        high: 1.2421,
        low: 1.23287
      },
      {
        timestamp: 1520460000,
        open: 1.24044,
        close: 1.24123,
        high: 1.24456,
        low: 1.23855
      },
      {
        timestamp: 1520546400,
        open: 1.24123,
        close: 1.23126,
        high: 1.2447,
        low: 1.22987
      },
      {
        timestamp: 1520629200,
        open: 1.23126,
        close: 1.23092,
        high: 1.2335,
        low: 1.22738
      },
      {
        timestamp: 1520802000,
        open: 1.23092,
        close: 1.23142,
        high: 1.23197,
        low: 1.23092
      },
      {
        timestamp: 1520888400,
        open: 1.23142,
        close: 1.2335,
        high: 1.23463,
        low: 1.22911
      },
      {
        timestamp: 1520974800,
        open: 1.2335,
        close: 1.23901,
        high: 1.24081,
        low: 1.23153
      },
      {
        timestamp: 1521061200,
        open: 1.23901,
        close: 1.23688,
        high: 1.24134,
        low: 1.23479
      },
      {
        timestamp: 1521147600,
        open: 1.23688,
        close: 1.23053,
        high: 1.23845,
        low: 1.2301
      },
      {
        timestamp: 1521234000,
        open: 1.23053,
        close: 1.22911,
        high: 1.2337,
        low: 1.22608
      },
      {
        timestamp: 1521406800,
        open: 1.22911,
        close: 1.2286,
        high: 1.22972,
        low: 1.22844
      },
      {
        timestamp: 1521493200,
        open: 1.2286,
        close: 1.23362,
        high: 1.23595,
        low: 1.22588
      },
      {
        timestamp: 1521579600,
        open: 1.23362,
        close: 1.22424,
        high: 1.23553,
        low: 1.22402
      },
      {
        timestamp: 1521666000,
        open: 1.22424,
        close: 1.23389,
        high: 1.23508,
        low: 1.22418
      },
      {
        timestamp: 1521752400,
        open: 1.23389,
        close: 1.23033,
        high: 1.23892,
        low: 1.22859
      },
      {
        timestamp: 1521838800,
        open: 1.23033,
        close: 1.23548,
        high: 1.23743,
        low: 1.2302
      },
      {
        timestamp: 1522011600,
        open: 1.23548,
        close: 1.23545,
        high: 1.23562,
        low: 1.23435
      },
      {
        timestamp: 1522098000,
        open: 1.23545,
        close: 1.24451,
        high: 1.24624,
        low: 1.23525
      },
      {
        timestamp: 1522184400,
        open: 1.24451,
        close: 1.24037,
        high: 1.24772,
        low: 1.2373
      },
      {
        timestamp: 1522270800,
        open: 1.24037,
        close: 1.23106,
        high: 1.24224,
        low: 1.23007
      },
      {
        timestamp: 1522357200,
        open: 1.23106,
        close: 1.23016,
        high: 1.23366,
        low: 1.22846
      },
      {
        timestamp: 1522443600,
        open: 1.23016,
        close: 1.23265,
        high: 1.2332,
        low: 1.22907
      },
      {
        timestamp: 1522616400,
        open: 1.23265,
        close: 1.23247,
        high: 1.23326,
        low: 1.23247
      },
      {
        timestamp: 1522702800,
        open: 1.23247,
        close: 1.23034,
        high: 1.23457,
        low: 1.22827
      },
      {
        timestamp: 1522789200,
        open: 1.23034,
        close: 1.22721,
        high: 1.23367,
        low: 1.22543
      },
      {
        timestamp: 1522875600,
        open: 1.22721,
        close: 1.22793,
        high: 1.23155,
        low: 1.22577
      },
      {
        timestamp: 1522962000,
        open: 1.22793,
        close: 1.22417,
        high: 1.22909,
        low: 1.2219
      },
      {
        timestamp: 1523048400,
        open: 1.22417,
        close: 1.22839,
        high: 1.22916,
        low: 1.22165
      },
      {
        timestamp: 1523221200,
        open: 1.22839,
        close: 1.22893,
        high: 1.22904,
        low: 1.22792
      },
      {
        timestamp: 1523307600,
        open: 1.22893,
        close: 1.23217,
        high: 1.23315,
        low: 1.22612
      },
      {
        timestamp: 1523394000,
        open: 1.23217,
        close: 1.2357,
        high: 1.23785,
        low: 1.23035
      },
      {
        timestamp: 1523480400,
        open: 1.2357,
        close: 1.23685,
        high: 1.2399,
        low: 1.23478
      },
      {
        timestamp: 1523566800,
        open: 1.23685,
        close: 1.23281,
        high: 1.23805,
        low: 1.22999
      },
      {
        timestamp: 1523653200,
        open: 1.23281,
        close: 1.2334,
        high: 1.23467,
        low: 1.23078
      },
      {
        timestamp: 1523826000,
        open: 1.2334,
        close: 1.23372,
        high: 1.23429,
        low: 1.23307
      },
      {
        timestamp: 1523912400,
        open: 1.23372,
        close: 1.23808,
        high: 1.23954,
        low: 1.23247
      },
      {
        timestamp: 1523998800,
        open: 1.23808,
        close: 1.23715,
        high: 1.24145,
        low: 1.23368
      },
      {
        timestamp: 1524085200,
        open: 1.23715,
        close: 1.23748,
        high: 1.2398,
        low: 1.23426
      },
      {
        timestamp: 1524171600,
        open: 1.23748,
        close: 1.23469,
        high: 1.24009,
        low: 1.233
      },
      {
        timestamp: 1524258000,
        open: 1.23469,
        close: 1.22903,
        high: 1.23537,
        low: 1.22505
      },
      {
        timestamp: 1524430800,
        open: 1.22903,
        close: 1.22796,
        high: 1.22798,
        low: 1.22659
      },
      {
        timestamp: 1524517200,
        open: 1.22796,
        close: 1.221,
        high: 1.22902,
        low: 1.21981
      },
      {
        timestamp: 1524603600,
        open: 1.221,
        close: 1.22344,
        high: 1.22455,
        low: 1.21825
      },
      {
        timestamp: 1524690000,
        open: 1.22344,
        close: 1.21625,
        high: 1.22396,
        low: 1.21612
      },
      {
        timestamp: 1524776400,
        open: 1.21625,
        close: 1.21041,
        high: 1.22108,
        low: 1.2097
      },
      {
        timestamp: 1524862800,
        open: 1.21041,
        close: 1.21325,
        high: 1.21346,
        low: 1.20561
      },
      {
        timestamp: 1525035600,
        open: 1.21325,
        close: 1.21255,
        high: 1.21316,
        low: 1.21209
      },
      {
        timestamp: 1525122000,
        open: 1.21255,
        close: 1.20784,
        high: 1.21395,
        low: 1.2065
      },
      {
        timestamp: 1525208400,
        open: 1.20784,
        close: 1.19932,
        high: 1.2085,
        low: 1.19818
      },
      {
        timestamp: 1525294800,
        open: 1.19932,
        close: 1.19521,
        high: 1.20324,
        low: 1.19386
      },
      {
        timestamp: 1525381200,
        open: 1.19521,
        close: 1.199,
        high: 1.20096,
        low: 1.19485
      },
      {
        timestamp: 1525467600,
        open: 1.199,
        close: 1.19648,
        high: 1.19962,
        low: 1.19111
      },
      {
        timestamp: 1525640400,
        open: 1.19648,
        close: 1.19644,
        high: 1.1968,
        low: 1.19506
      },
      {
        timestamp: 1525726800,
        open: 1.19644,
        close: 1.19229,
        high: 1.19788,
        low: 1.18982
      },
      {
        timestamp: 1525813200,
        open: 1.19229,
        close: 1.18659,
        high: 1.19396,
        low: 1.18386
      },
      {
        timestamp: 1525899600,
        open: 1.18659,
        close: 1.18514,
        high: 1.18978,
        low: 1.18234
      },
      {
        timestamp: 1525986000,
        open: 1.18514,
        close: 1.1917,
        high: 1.19474,
        low: 1.18438
      },
      {
        timestamp: 1526072400,
        open: 1.1917,
        close: 1.19445,
        high: 1.19687,
        low: 1.18919
      },
      {
        timestamp: 1526245200,
        open: 1.19445,
        close: 1.19423,
        high: 1.1955,
        low: 1.19412
      },
      {
        timestamp: 1526331600,
        open: 1.19423,
        close: 1.19281,
        high: 1.19969,
        low: 1.1927
      },
      {
        timestamp: 1526418000,
        open: 1.19281,
        close: 1.18384,
        high: 1.19393,
        low: 1.1821
      },
      {
        timestamp: 1526504400,
        open: 1.18384,
        close: 1.18082,
        high: 1.18548,
        low: 1.17639
      },
      {
        timestamp: 1526590800,
        open: 1.18082,
        close: 1.17959,
        high: 1.18383,
        low: 1.17771
      },
      {
        timestamp: 1526677200,
        open: 1.17959,
        close: 1.17749,
        high: 1.18229,
        low: 1.17504
      },
      {
        timestamp: 1526850000,
        open: 1.17749,
        close: 1.1774,
        high: 1.17806,
        low: 1.17694
      },
      {
        timestamp: 1526936400,
        open: 1.1774,
        close: 1.17923,
        high: 1.17963,
        low: 1.17174
      },
      {
        timestamp: 1527022800,
        open: 1.17923,
        close: 1.17804,
        high: 1.18305,
        low: 1.17573
      },
      {
        timestamp: 1527109200,
        open: 1.17804,
        close: 1.16975,
        high: 1.17904,
        low: 1.16765
      },
      {
        timestamp: 1527195600,
        open: 1.16975,
        close: 1.17201,
        high: 1.17511,
        low: 1.16916
      },
      {
        timestamp: 1527282000,
        open: 1.17201,
        close: 1.16524,
        high: 1.17344,
        low: 1.16468
      },
      {
        timestamp: 1527454800,
        open: 1.16524,
        close: 1.16881,
        high: 1.16902,
        low: 1.16524
      },
      {
        timestamp: 1527541200,
        open: 1.16881,
        close: 1.16255,
        high: 1.17292,
        low: 1.1608
      },
      {
        timestamp: 1527627600,
        open: 1.16255,
        close: 1.15403,
        high: 1.16404,
        low: 1.15108
      },
      {
        timestamp: 1527714000,
        open: 1.15403,
        close: 1.16668,
        high: 1.16769,
        low: 1.15194
      },
      {
        timestamp: 1527800400,
        open: 1.16668,
        close: 1.16958,
        high: 1.17249,
        low: 1.16418
      },
      {
        timestamp: 1527886800,
        open: 1.16958,
        close: 1.1662,
        high: 1.17189,
        low: 1.16185
      },
      {
        timestamp: 1528059600,
        open: 1.1662,
        close: 1.16646,
        high: 1.16651,
        low: 1.16604
      },
      {
        timestamp: 1528146000,
        open: 1.16646,
        close: 1.16998,
        high: 1.17454,
        low: 1.1661
      },
      {
        timestamp: 1528232400,
        open: 1.16998,
        close: 1.17188,
        high: 1.1733,
        low: 1.16533
      },
      {
        timestamp: 1528318800,
        open: 1.17188,
        close: 1.17751,
        high: 1.17964,
        low: 1.17143
      },
      {
        timestamp: 1528405200,
        open: 1.17751,
        close: 1.18018,
        high: 1.18406,
        low: 1.17741
      },
      {
        timestamp: 1528491600,
        open: 1.18018,
        close: 1.17713,
        high: 1.18111,
        low: 1.17279
      },
      {
        timestamp: 1528664400,
        open: 1.17713,
        close: 1.17777,
        high: 1.1781,
        low: 1.17554
      },
      {
        timestamp: 1528750800,
        open: 1.17777,
        close: 1.17857,
        high: 1.18213,
        low: 1.1772
      },
      {
        timestamp: 1528837200,
        open: 1.17857,
        close: 1.1747,
        high: 1.18099,
        low: 1.17337
      },
      {
        timestamp: 1528923600,
        open: 1.1747,
        close: 1.17923,
        high: 1.18017,
        low: 1.17262
      },
      {
        timestamp: 1529010000,
        open: 1.17923,
        close: 1.15711,
        high: 1.1853,
        low: 1.15639
      },
      {
        timestamp: 1529096400,
        open: 1.15711,
        close: 1.16103,
        high: 1.16278,
        low: 1.15439
      },
      {
        timestamp: 1529269200,
        open: 1.16103,
        close: 1.15915,
        high: 1.16156,
        low: 1.15858
      },
      {
        timestamp: 1529355600,
        open: 1.15915,
        close: 1.16239,
        high: 1.16251,
        low: 1.1566
      },
      {
        timestamp: 1529442000,
        open: 1.16239,
        close: 1.1592,
        high: 1.16453,
        low: 1.15312
      },
      {
        timestamp: 1529528400,
        open: 1.1592,
        close: 1.15734,
        high: 1.16007,
        low: 1.15376
      },
      {
        timestamp: 1529614800,
        open: 1.15734,
        close: 1.16048,
        high: 1.16344,
        low: 1.15089
      },
      {
        timestamp: 1529701200,
        open: 1.16048,
        close: 1.16599,
        high: 1.16758,
        low: 1.16014
      },
      {
        timestamp: 1529874000,
        open: 1.16599,
        close: 1.16675,
        high: 1.16712,
        low: 1.16572
      },
      {
        timestamp: 1529960400,
        open: 1.16675,
        close: 1.17053,
        high: 1.17138,
        low: 1.16291
      },
      {
        timestamp: 1530046800,
        open: 1.17053,
        close: 1.16499,
        high: 1.17213,
        low: 1.16355
      },
      {
        timestamp: 1530133200,
        open: 1.16499,
        close: 1.15564,
        high: 1.1673,
        low: 1.15412
      },
      {
        timestamp: 1530219600,
        open: 1.15564,
        close: 1.15695,
        high: 1.16015,
        low: 1.15279
      },
      {
        timestamp: 1530306000,
        open: 1.15695,
        close: 1.16867,
        high: 1.16913,
        low: 1.15592
      },
      {
        timestamp: 1530478800,
        open: 1.16867,
        close: 1.16807,
        high: 1.16957,
        low: 1.16327
      },
      {
        timestamp: 1530565200,
        open: 1.16807,
        close: 1.164,
        high: 1.16953,
        low: 1.15919
      },
      {
        timestamp: 1530651600,
        open: 1.164,
        close: 1.16601,
        high: 1.16738,
        low: 1.16212
      },
      {
        timestamp: 1530738000,
        open: 1.16601,
        close: 1.16582,
        high: 1.16825,
        low: 1.16313
      },
      {
        timestamp: 1530824400,
        open: 1.16582,
        close: 1.16924,
        high: 1.17209,
        low: 1.16505
      },
      {
        timestamp: 1530910800,
        open: 1.16924,
        close: 1.17461,
        high: 1.17681,
        low: 1.16807
      },
      {
        timestamp: 1531083600,
        open: 1.17461,
        close: 1.17452,
        high: 1.17576,
        low: 1.17386
      },
      {
        timestamp: 1531170000,
        open: 1.17452,
        close: 1.17521,
        high: 1.17915,
        low: 1.17332
      },
      {
        timestamp: 1531256400,
        open: 1.17521,
        close: 1.17455,
        high: 1.17634,
        low: 1.16911
      },
      {
        timestamp: 1531342800,
        open: 1.17455,
        close: 1.16742,
        high: 1.1759,
        low: 1.16661
      },
      {
        timestamp: 1531429200,
        open: 1.16742,
        close: 1.16722,
        high: 1.16964,
        low: 1.16503
      },
      {
        timestamp: 1531515600,
        open: 1.16722,
        close: 1.16874,
        high: 1.16878,
        low: 1.16137
      },
      {
        timestamp: 1531688400,
        open: 1.16874,
        close: 1.16911,
        high: 1.16956,
        low: 1.1686
      },
      {
        timestamp: 1531774800,
        open: 1.16911,
        close: 1.17121,
        high: 1.17262,
        low: 1.16764
      },
      {
        timestamp: 1531861200,
        open: 1.17121,
        close: 1.16623,
        high: 1.17454,
        low: 1.16499
      },
      {
        timestamp: 1531947600,
        open: 1.16623,
        close: 1.16396,
        high: 1.16657,
        low: 1.16026
      },
      {
        timestamp: 1532034000,
        open: 1.16396,
        close: 1.1643,
        high: 1.16789,
        low: 1.15756
      },
      {
        timestamp: 1532120400,
        open: 1.1643,
        close: 1.17245,
        high: 1.17397,
        low: 1.1627
      },
      {
        timestamp: 1532293200,
        open: 1.17245,
        close: 1.17291,
        high: 1.17376,
        low: 1.17245
      },
      {
        timestamp: 1532379600,
        open: 1.17291,
        close: 1.16934,
        high: 1.17512,
        low: 1.16844
      },
      {
        timestamp: 1532466000,
        open: 1.16934,
        close: 1.16854,
        high: 1.17176,
        low: 1.16553
      },
      {
        timestamp: 1532552400,
        open: 1.16854,
        close: 1.17291,
        high: 1.17394,
        low: 1.16645
      },
      {
        timestamp: 1532638800,
        open: 1.17291,
        close: 1.16439,
        high: 1.17443,
        low: 1.16409
      },
      {
        timestamp: 1532725200,
        open: 1.16439,
        close: 1.16586,
        high: 1.16651,
        low: 1.16212
      },
      {
        timestamp: 1532898000,
        open: 1.16586,
        close: 1.16606,
        high: 1.16651,
        low: 1.16541
      },
      {
        timestamp: 1532984400,
        open: 1.16606,
        close: 1.17069,
        high: 1.17195,
        low: 1.16486
      },
      {
        timestamp: 1533070800,
        open: 1.17069,
        close: 1.16924,
        high: 1.17465,
        low: 1.16849
      },
      {
        timestamp: 1533157200,
        open: 1.16924,
        close: 1.16607,
        high: 1.17004,
        low: 1.1658
      },
      {
        timestamp: 1533243600,
        open: 1.16607,
        close: 1.15852,
        high: 1.16683,
        low: 1.15826
      },
      {
        timestamp: 1533330000,
        open: 1.15852,
        close: 1.15715,
        high: 1.16114,
        low: 1.15608
      },
      {
        timestamp: 1533502800,
        open: 1.15715,
        close: 1.15736,
        high: 1.15739,
        low: 1.15687
      },
      {
        timestamp: 1533589200,
        open: 1.15736,
        close: 1.15544,
        high: 1.15732,
        low: 1.15307
      },
      {
        timestamp: 1533675600,
        open: 1.15544,
        close: 1.15996,
        high: 1.16089,
        low: 1.15519
      },
      {
        timestamp: 1533762000,
        open: 1.15996,
        close: 1.16107,
        high: 1.1629,
        low: 1.15739
      },
      {
        timestamp: 1533848400,
        open: 1.16107,
        close: 1.15277,
        high: 1.16202,
        low: 1.15266
      },
      {
        timestamp: 1533934800,
        open: 1.15277,
        close: 1.14157,
        high: 1.15372,
        low: 1.13887
      },
      {
        timestamp: 1534107600,
        open: 1.14157,
        close: 1.13738,
        high: 1.13944,
        low: 1.13698
      },
      {
        timestamp: 1534194000,
        open: 1.13738,
        close: 1.14115,
        high: 1.14336,
        low: 1.13658
      },
      {
        timestamp: 1534280400,
        open: 1.14115,
        close: 1.13453,
        high: 1.14301,
        low: 1.13308
      },
      {
        timestamp: 1534366800,
        open: 1.13453,
        close: 1.13463,
        high: 1.13556,
        low: 1.13017
      },
      {
        timestamp: 1534453200,
        open: 1.13463,
        close: 1.1378,
        high: 1.14098,
        low: 1.13366
      },
      {
        timestamp: 1534539600,
        open: 1.1378,
        close: 1.14422,
        high: 1.14456,
        low: 1.13671
      },
      {
        timestamp: 1534712400,
        open: 1.14422,
        close: 1.14423,
        high: 1.14426,
        low: 1.14356
      },
      {
        timestamp: 1534798800,
        open: 1.14423,
        close: 1.14831,
        high: 1.14857,
        low: 1.13951
      },
      {
        timestamp: 1534885200,
        open: 1.14831,
        close: 1.15714,
        high: 1.1602,
        low: 1.14821
      },
      {
        timestamp: 1534971600,
        open: 1.15714,
        close: 1.15978,
        high: 1.16236,
        low: 1.15539
      },
      {
        timestamp: 1535058000,
        open: 1.15978,
        close: 1.15413,
        high: 1.16004,
        low: 1.15304
      },
      {
        timestamp: 1535144400,
        open: 1.15413,
        close: 1.16244,
        high: 1.16405,
        low: 1.15356
      },
      {
        timestamp: 1535317200,
        open: 1.16244,
        close: 1.16189,
        high: 1.16301,
        low: 1.1611
      },
      {
        timestamp: 1535403600,
        open: 1.16189,
        close: 1.16786,
        high: 1.16944,
        low: 1.15953
      },
      {
        timestamp: 1535490000,
        open: 1.16786,
        close: 1.16955,
        high: 1.17344,
        low: 1.16632
      },
      {
        timestamp: 1535576400,
        open: 1.16955,
        close: 1.17088,
        high: 1.17106,
        low: 1.16525
      },
      {
        timestamp: 1535662800,
        open: 1.17088,
        close: 1.16718,
        high: 1.1719,
        low: 1.16422
      },
      {
        timestamp: 1535749200,
        open: 1.16718,
        close: 1.16058,
        high: 1.16908,
        low: 1.15853
      },
      {
        timestamp: 1535922000,
        open: 1.16058,
        close: 1.15985,
        high: 1.16105,
        low: 1.15923
      },
      {
        timestamp: 1536008400,
        open: 1.15985,
        close: 1.1623,
        high: 1.16286,
        low: 1.15896
      },
      {
        timestamp: 1536094800,
        open: 1.1623,
        close: 1.15833,
        high: 1.16235,
        low: 1.15309
      },
      {
        timestamp: 1536181200,
        open: 1.15833,
        close: 1.16312,
        high: 1.16413,
        low: 1.15432
      },
      {
        timestamp: 1536267600,
        open: 1.16312,
        close: 1.16244,
        high: 1.16599,
        low: 1.16062
      },
      {
        timestamp: 1536354000,
        open: 1.16244,
        close: 1.15559,
        high: 1.16502,
        low: 1.15513
      },
      {
        timestamp: 1536526800,
        open: 1.15559,
        close: 1.15518,
        high: 1.15568,
        low: 1.15505
      },
      {
        timestamp: 1536613200,
        open: 1.15518,
        close: 1.15963,
        high: 1.1617,
        low: 1.15263
      },
      {
        timestamp: 1536699600,
        open: 1.15963,
        close: 1.16078,
        high: 1.16449,
        low: 1.1566
      },
      {
        timestamp: 1536786000,
        open: 1.16078,
        close: 1.16271,
        high: 1.16505,
        low: 1.15708
      },
      {
        timestamp: 1536872400,
        open: 1.16271,
        close: 1.16908,
        high: 1.17018,
        low: 1.16097
      },
      {
        timestamp: 1536958800,
        open: 1.16908,
        close: 1.16247,
        high: 1.17224,
        low: 1.16214
      },
      {
        timestamp: 1537131600,
        open: 1.16247,
        close: 1.16286,
        high: 1.16318,
        low: 1.1619
      },
      {
        timestamp: 1537218000,
        open: 1.16286,
        close: 1.16844,
        high: 1.16989,
        low: 1.16187
      },
      {
        timestamp: 1537304400,
        open: 1.16844,
        close: 1.16702,
        high: 1.17253,
        low: 1.16529
      },
      {
        timestamp: 1537390800,
        open: 1.16702,
        close: 1.16734,
        high: 1.17156,
        low: 1.16507
      },
      {
        timestamp: 1537477200,
        open: 1.16734,
        close: 1.17778,
        high: 1.17854,
        low: 1.16697
      },
      {
        timestamp: 1537563600,
        open: 1.17778,
        close: 1.17511,
        high: 1.18035,
        low: 1.17336
      },
      {
        timestamp: 1537736400,
        open: 1.17511,
        close: 1.17532,
        high: 1.17552,
        low: 1.17405
      },
      {
        timestamp: 1537822800,
        open: 1.17532,
        close: 1.17488,
        high: 1.18159,
        low: 1.17249
      },
      {
        timestamp: 1537909200,
        open: 1.17488,
        close: 1.17683,
        high: 1.17934,
        low: 1.17316
      },
      {
        timestamp: 1537995600,
        open: 1.17683,
        close: 1.17424,
        high: 1.17986,
        low: 1.17266
      },
      {
        timestamp: 1538082000,
        open: 1.17424,
        close: 1.16414,
        high: 1.17579,
        low: 1.16397
      },
      {
        timestamp: 1538168400,
        open: 1.16414,
        close: 1.16054,
        high: 1.1652,
        low: 1.15702
      },
      {
        timestamp: 1538341200,
        open: 1.16054,
        close: 1.16216,
        high: 1.16291,
        low: 1.16054
      },
      {
        timestamp: 1538427600,
        open: 1.16216,
        close: 1.15788,
        high: 1.16257,
        low: 1.15643
      },
      {
        timestamp: 1538514000,
        open: 1.15788,
        close: 1.15483,
        high: 1.15812,
        low: 1.15059
      },
      {
        timestamp: 1538600400,
        open: 1.15483,
        close: 1.14798,
        high: 1.15945,
        low: 1.14651
      },
      {
        timestamp: 1538686800,
        open: 1.14798,
        close: 1.15154,
        high: 1.15435,
        low: 1.14641
      },
      {
        timestamp: 1538773200,
        open: 1.15154,
        close: 1.15208,
        high: 1.15505,
        low: 1.14847
      },
      {
        timestamp: 1538946000,
        open: 1.15208,
        close: 1.15256,
        high: 1.15354,
        low: 1.15223
      },
      {
        timestamp: 1539032400,
        open: 1.15256,
        close: 1.14923,
        high: 1.15303,
        low: 1.14606
      },
      {
        timestamp: 1539118800,
        open: 1.14923,
        close: 1.14918,
        high: 1.1504,
        low: 1.14329
      },
      {
        timestamp: 1539205200,
        open: 1.14918,
        close: 1.15219,
        high: 1.15461,
        low: 1.14806
      },
      {
        timestamp: 1539291600,
        open: 1.15219,
        close: 1.1594,
        high: 1.16001,
        low: 1.15217
      },
      {
        timestamp: 1539378000,
        open: 1.1594,
        close: 1.15616,
        high: 1.16112,
        low: 1.1535
      },
      {
        timestamp: 1539550800,
        open: 1.15616,
        close: 1.1545,
        high: 1.15607,
        low: 1.15365
      },
      {
        timestamp: 1539637200,
        open: 1.1545,
        close: 1.15805,
        high: 1.16069,
        low: 1.1545
      },
      {
        timestamp: 1539723600,
        open: 1.15805,
        close: 1.15759,
        high: 1.16222,
        low: 1.15667
      },
      {
        timestamp: 1539810000,
        open: 1.15759,
        close: 1.15015,
        high: 1.15813,
        low: 1.14967
      },
      {
        timestamp: 1539896400,
        open: 1.15015,
        close: 1.1454,
        high: 1.1528,
        low: 1.14498
      },
      {
        timestamp: 1539982800,
        open: 1.1454,
        close: 1.15155,
        high: 1.15355,
        low: 1.14337
      },
      {
        timestamp: 1540155600,
        open: 1.15155,
        close: 1.15186,
        high: 1.15212,
        low: 1.15136
      },
      {
        timestamp: 1540242000,
        open: 1.15186,
        close: 1.14661,
        high: 1.15509,
        low: 1.14565
      },
      {
        timestamp: 1540328400,
        open: 1.14661,
        close: 1.14733,
        high: 1.14945,
        low: 1.144
      },
      {
        timestamp: 1540414800,
        open: 1.14733,
        close: 1.13931,
        high: 1.14775,
        low: 1.13794
      },
      {
        timestamp: 1540501200,
        open: 1.13931,
        close: 1.13755,
        high: 1.14335,
        low: 1.13568
      },
      {
        timestamp: 1540587600,
        open: 1.13755,
        close: 1.14057,
        high: 1.1422,
        low: 1.13364
      },
      {
        timestamp: 1540760400,
        open: 1.14057,
        close: 1.13947,
        high: 1.14041,
        low: 1.13885
      },
      {
        timestamp: 1540846800,
        open: 1.13947,
        close: 1.13736,
        high: 1.14172,
        low: 1.13613
      },
      {
        timestamp: 1540933200,
        open: 1.13736,
        close: 1.13458,
        high: 1.13886,
        low: 1.13412
      },
      {
        timestamp: 1541019600,
        open: 1.13458,
        close: 1.13138,
        high: 1.1361,
        low: 1.13028
      },
      {
        timestamp: 1541106000,
        open: 1.13138,
        close: 1.14097,
        high: 1.14249,
        low: 1.1309
      },
      {
        timestamp: 1541196000,
        open: 1.14097,
        close: 1.13897,
        high: 1.14566,
        low: 1.13728
      },
      {
        timestamp: 1541368800,
        open: 1.13897,
        close: 1.13888,
        high: 1.14056,
        low: 1.13884
      },
      {
        timestamp: 1541455200,
        open: 1.13888,
        close: 1.14087,
        high: 1.14245,
        low: 1.13541
      },
      {
        timestamp: 1541541600,
        open: 1.14087,
        close: 1.14282,
        high: 1.14385,
        low: 1.1392
      },
      {
        timestamp: 1541628000,
        open: 1.14282,
        close: 1.14273,
        high: 1.15005,
        low: 1.13954
      },
      {
        timestamp: 1541714400,
        open: 1.14273,
        close: 1.13641,
        high: 1.14475,
        low: 1.13522
      },
      {
        timestamp: 1541800800,
        open: 1.13641,
        close: 1.13366,
        high: 1.13696,
        low: 1.1317
      },
      {
        timestamp: 1541973600,
        open: 1.13366,
        close: 1.13251,
        high: 1.13266,
        low: 1.13136
      },
      {
        timestamp: 1542060000,
        open: 1.13251,
        close: 1.12194,
        high: 1.13326,
        low: 1.12163
      },
      {
        timestamp: 1542146400,
        open: 1.12194,
        close: 1.1293,
        high: 1.12942,
        low: 1.12181
      },
      {
        timestamp: 1542232800,
        open: 1.1293,
        close: 1.13127,
        high: 1.13485,
        low: 1.12637
      },
      {
        timestamp: 1542319200,
        open: 1.13127,
        close: 1.13308,
        high: 1.13633,
        low: 1.12715
      },
      {
        timestamp: 1542405600,
        open: 1.13308,
        close: 1.14189,
        high: 1.14218,
        low: 1.13223
      },
      {
        timestamp: 1542578400,
        open: 1.14189,
        close: 1.14161,
        high: 1.14194,
        low: 1.14004
      },
      {
        timestamp: 1542664800,
        open: 1.14161,
        close: 1.14569,
        high: 1.14653,
        low: 1.13948
      },
      {
        timestamp: 1542751200,
        open: 1.14569,
        close: 1.13711,
        high: 1.14731,
        low: 1.13595
      },
      {
        timestamp: 1542837600,
        open: 1.13711,
        close: 1.1387,
        high: 1.14256,
        low: 1.13663
      },
      {
        timestamp: 1542924000,
        open: 1.1387,
        close: 1.14065,
        high: 1.14348,
        low: 1.13829
      },
      {
        timestamp: 1543010400,
        open: 1.14065,
        close: 1.13409,
        high: 1.14221,
        low: 1.13289
      },
      {
        timestamp: 1543183200,
        open: 1.13409,
        close: 1.13438,
        high: 1.13457,
        low: 1.13409
      },
      {
        timestamp: 1543269600,
        open: 1.13438,
        close: 1.1329,
        high: 1.13845,
        low: 1.13256
      },
      {
        timestamp: 1543356000,
        open: 1.1329,
        close: 1.12928,
        high: 1.13445,
        low: 1.1278
      },
      {
        timestamp: 1543442400,
        open: 1.12928,
        close: 1.13666,
        high: 1.13883,
        low: 1.12678
      },
      {
        timestamp: 1543528800,
        open: 1.13666,
        close: 1.13953,
        high: 1.14024,
        low: 1.13495
      },
      {
        timestamp: 1543615200,
        open: 1.13953,
        close: 1.13223,
        high: 1.14011,
        low: 1.13062
      },
      {
        timestamp: 1543788000,
        open: 1.13223,
        close: 1.13557,
        high: 1.13687,
        low: 1.13223
      },
      {
        timestamp: 1543874400,
        open: 1.13557,
        close: 1.13552,
        high: 1.13805,
        low: 1.13201
      },
      {
        timestamp: 1543960800,
        open: 1.13552,
        close: 1.13448,
        high: 1.14195,
        low: 1.13192
      },
      {
        timestamp: 1544047200,
        open: 1.13448,
        close: 1.13453,
        high: 1.13621,
        low: 1.13117
      },
      {
        timestamp: 1544133600,
        open: 1.13453,
        close: 1.13796,
        high: 1.14133,
        low: 1.13218
      },
      {
        timestamp: 1544220000,
        open: 1.13796,
        close: 1.13969,
        high: 1.14245,
        low: 1.13612
      },
      {
        timestamp: 1544392800,
        open: 1.13969,
        close: 1.13945,
        high: 1.14024,
        low: 1.13829
      },
      {
        timestamp: 1544479200,
        open: 1.13945,
        close: 1.13576,
        high: 1.14435,
        low: 1.13512
      },
      {
        timestamp: 1544565600,
        open: 1.13576,
        close: 1.1318,
        high: 1.14011,
        low: 1.13069
      },
      {
        timestamp: 1544652000,
        open: 1.1318,
        close: 1.1369,
        high: 1.13882,
        low: 1.13158
      },
      {
        timestamp: 1544738400,
        open: 1.1369,
        close: 1.13597,
        high: 1.13943,
        low: 1.13318
      },
      {
        timestamp: 1544824800,
        open: 1.13597,
        close: 1.13072,
        high: 1.13665,
        low: 1.12705
      },
      {
        timestamp: 1544997600,
        open: 1.13072,
        close: 1.13057,
        high: 1.13078,
        low: 1.13023
      },
      {
        timestamp: 1545084000,
        open: 1.13057,
        close: 1.13481,
        high: 1.13585,
        low: 1.13022
      },
      {
        timestamp: 1545170400,
        open: 1.13481,
        close: 1.13622,
        high: 1.1403,
        low: 1.13375
      },
      {
        timestamp: 1545256800,
        open: 1.13622,
        close: 1.1378,
        high: 1.14401,
        low: 1.13621
      },
      {
        timestamp: 1545343200,
        open: 1.1378,
        close: 1.14474,
        high: 1.14867,
        low: 1.13712
      },
      {
        timestamp: 1545429600,
        open: 1.14474,
        close: 1.13728,
        high: 1.14748,
        low: 1.13567
      },
      {
        timestamp: 1545602400,
        open: 1.13728,
        close: 1.13741,
        high: 1.13749,
        low: 1.13601
      },
      {
        timestamp: 1545688800,
        open: 1.13741,
        close: 1.14042,
        high: 1.14393,
        low: 1.13629
      },
      {
        timestamp: 1545861600,
        open: 1.14042,
        close: 1.13547,
        high: 1.1415,
        low: 1.13438
      },
      {
        timestamp: 1545948000,
        open: 1.13547,
        close: 1.14336,
        high: 1.1455,
        low: 1.13539
      },
      {
        timestamp: 1546034400,
        open: 1.14336,
        close: 1.14454,
        high: 1.14735,
        low: 1.14289
      },
      {
        timestamp: 1546207200,
        open: 1.14454,
        close: 1.14404,
        high: 1.14502,
        low: 1.14383
      },
      {
        timestamp: 1546293600,
        open: 1.14404,
        close: 1.14459,
        high: 1.14669,
        low: 1.14222
      },
      {
        timestamp: 1546466400,
        open: 1.14459,
        close: 1.13448,
        high: 1.14975,
        low: 1.13258
      },
      {
        timestamp: 1546552800,
        open: 1.13448,
        close: 1.13946,
        high: 1.14119,
        low: 1.13103
      },
      {
        timestamp: 1546639200,
        open: 1.13946,
        close: 1.13998,
        high: 1.14198,
        low: 1.13464
      },
      {
        timestamp: 1546812000,
        open: 1.13998,
        close: 1.14008,
        high: 1.14055,
        low: 1.13987
      },
      {
        timestamp: 1546898400,
        open: 1.14008,
        close: 1.14757,
        high: 1.14833,
        low: 1.13981
      },
      {
        timestamp: 1546984800,
        open: 1.14757,
        close: 1.14426,
        high: 1.14854,
        low: 1.14227
      },
      {
        timestamp: 1547071200,
        open: 1.14426,
        close: 1.15435,
        high: 1.15589,
        low: 1.14383
      },
      {
        timestamp: 1547157600,
        open: 1.15435,
        close: 1.15007,
        high: 1.15706,
        low: 1.1485
      },
      {
        timestamp: 1547244000,
        open: 1.15007,
        close: 1.14722,
        high: 1.15414,
        low: 1.14588
      },
      {
        timestamp: 1547416800,
        open: 1.14722,
        close: 1.14631,
        high: 1.14631,
        low: 1.14425
      },
      {
        timestamp: 1547503200,
        open: 1.14631,
        close: 1.14697,
        high: 1.14828,
        low: 1.14513
      },
      {
        timestamp: 1547589600,
        open: 1.14697,
        close: 1.14143,
        high: 1.14905,
        low: 1.13826
      },
      {
        timestamp: 1547676000,
        open: 1.14143,
        close: 1.13935,
        high: 1.14256,
        low: 1.13785
      },
      {
        timestamp: 1547762400,
        open: 1.13935,
        close: 1.13895,
        high: 1.14068,
        low: 1.13708
      },
      {
        timestamp: 1547848800,
        open: 1.13895,
        close: 1.13662,
        high: 1.14119,
        low: 1.1354
      },
      {
        timestamp: 1548021600,
        open: 1.13662,
        close: 1.13636,
        high: 1.13703,
        low: 1.13631
      },
      {
        timestamp: 1548108000,
        open: 1.13636,
        close: 1.13679,
        high: 1.13922,
        low: 1.13573
      },
      {
        timestamp: 1548194400,
        open: 1.13679,
        close: 1.13608,
        high: 1.13746,
        low: 1.13367
      },
      {
        timestamp: 1548280800,
        open: 1.13608,
        close: 1.13821,
        high: 1.13952,
        low: 1.13514
      },
      {
        timestamp: 1548367200,
        open: 1.13821,
        close: 1.13084,
        high: 1.13923,
        low: 1.12901
      },
      {
        timestamp: 1548453600,
        open: 1.13084,
        close: 1.14094,
        high: 1.14185,
        low: 1.13016
      },
      {
        timestamp: 1548626400,
        open: 1.14094,
        close: 1.14051,
        high: 1.14112,
        low: 1.14007
      },
      {
        timestamp: 1548712800,
        open: 1.14051,
        close: 1.1428,
        high: 1.14444,
        low: 1.13908
      },
      {
        timestamp: 1548799200,
        open: 1.1428,
        close: 1.14352,
        high: 1.1451,
        low: 1.14118
      },
      {
        timestamp: 1548885600,
        open: 1.14352,
        close: 1.1481,
        high: 1.15026,
        low: 1.14068
      },
      {
        timestamp: 1548972000,
        open: 1.1481,
        close: 1.14485,
        high: 1.15151,
        low: 1.14364
      },
      {
        timestamp: 1549058400,
        open: 1.14485,
        close: 1.14575,
        high: 1.14892,
        low: 1.14351
      },
      {
        timestamp: 1549231200,
        open: 1.14575,
        close: 1.14546,
        high: 1.14605,
        low: 1.14485
      },
      {
        timestamp: 1549317600,
        open: 1.14546,
        close: 1.14378,
        high: 1.14611,
        low: 1.1425
      },
      {
        timestamp: 1549404000,
        open: 1.14378,
        close: 1.14062,
        high: 1.14426,
        low: 1.14017
      },
      {
        timestamp: 1549490400,
        open: 1.14062,
        close: 1.13631,
        high: 1.14104,
        low: 1.13617
      },
      {
        timestamp: 1549576800,
        open: 1.13631,
        close: 1.1343,
        high: 1.13704,
        low: 1.13248
      },
      {
        timestamp: 1549663200,
        open: 1.1343,
        close: 1.13241,
        high: 1.13516,
        low: 1.13218
      },
      {
        timestamp: 1549836000,
        open: 1.13241,
        close: 1.13195,
        high: 1.13268,
        low: 1.13177
      },
      {
        timestamp: 1549922400,
        open: 1.13195,
        close: 1.12772,
        high: 1.13308,
        low: 1.12681
      },
      {
        timestamp: 1550008800,
        open: 1.12772,
        close: 1.13277,
        high: 1.13404,
        low: 1.12583
      },
      {
        timestamp: 1550095200,
        open: 1.13277,
        close: 1.12617,
        high: 1.13423,
        low: 1.12609
      },
      {
        timestamp: 1550181600,
        open: 1.12617,
        close: 1.12962,
        high: 1.13105,
        low: 1.12498
      },
      {
        timestamp: 1550268000,
        open: 1.12962,
        close: 1.12942,
        high: 1.1307,
        low: 1.12348
      },
      {
        timestamp: 1550440800,
        open: 1.12942,
        close: 1.12952,
        high: 1.13025,
        low: 1.12935
      },
      {
        timestamp: 1550527200,
        open: 1.12952,
        close: 1.13134,
        high: 1.13347,
        low: 1.12901
      },
      {
        timestamp: 1550613600,
        open: 1.13134,
        close: 1.1341,
        high: 1.13584,
        low: 1.12763
      },
      {
        timestamp: 1550700000,
        open: 1.1341,
        close: 1.13399,
        high: 1.13719,
        low: 1.13254
      },
      {
        timestamp: 1550786400,
        open: 1.13399,
        close: 1.13381,
        high: 1.13674,
        low: 1.13203
      },
      {
        timestamp: 1550872800,
        open: 1.13381,
        close: 1.13365,
        high: 1.13564,
        low: 1.13168
      },
      {
        timestamp: 1551045600,
        open: 1.13365,
        close: 1.13396,
        high: 1.13492,
        low: 1.13365
      },
      {
        timestamp: 1551132000,
        open: 1.13396,
        close: 1.13589,
        high: 1.13683,
        low: 1.133
      },
      {
        timestamp: 1551218400,
        open: 1.13589,
        close: 1.13897,
        high: 1.14035,
        low: 1.13459
      },
      {
        timestamp: 1551304800,
        open: 1.13897,
        close: 1.13706,
        high: 1.14044,
        low: 1.13631
      },
      {
        timestamp: 1551391200,
        open: 1.13706,
        close: 1.13717,
        high: 1.14203,
        low: 1.136
      },
      {
        timestamp: 1551477600,
        open: 1.13717,
        close: 1.13678,
        high: 1.14095,
        low: 1.1354
      },
      {
        timestamp: 1551650400,
        open: 1.13678,
        close: 1.13837,
        high: 1.13914,
        low: 1.13678
      },
      {
        timestamp: 1551736800,
        open: 1.13837,
        close: 1.13411,
        high: 1.13839,
        low: 1.13097
      },
      {
        timestamp: 1551823200,
        open: 1.13411,
        close: 1.13091,
        high: 1.13427,
        low: 1.12903
      },
      {
        timestamp: 1551909600,
        open: 1.13091,
        close: 1.13077,
        high: 1.13254,
        low: 1.12863
      },
      {
        timestamp: 1551996000,
        open: 1.13077,
        close: 1.11948,
        high: 1.13211,
        low: 1.1177
      },
      {
        timestamp: 1552082400,
        open: 1.11948,
        close: 1.12379,
        high: 1.12469,
        low: 1.11859
      },
      {
        timestamp: 1552251600,
        open: 1.12379,
        close: 1.12372,
        high: 1.12466,
        low: 1.12347
      },
      {
        timestamp: 1552338000,
        open: 1.12372,
        close: 1.12463,
        high: 1.12592,
        low: 1.12224
      },
      {
        timestamp: 1552424400,
        open: 1.12463,
        close: 1.12897,
        high: 1.13056,
        low: 1.12457
      },
      {
        timestamp: 1552510800,
        open: 1.12897,
        close: 1.13296,
        high: 1.13394,
        low: 1.12782
      },
      {
        timestamp: 1552597200,
        open: 1.13296,
        close: 1.13051,
        high: 1.13384,
        low: 1.12948
      },
      {
        timestamp: 1552683600,
        open: 1.13051,
        close: 1.13263,
        high: 1.13451,
        low: 1.13007
      },
      {
        timestamp: 1552856400,
        open: 1.13263,
        close: 1.13257,
        high: 1.13303,
        low: 1.13217
      },
      {
        timestamp: 1552942800,
        open: 1.13257,
        close: 1.13391,
        high: 1.13597,
        low: 1.13198
      },
      {
        timestamp: 1553029200,
        open: 1.13391,
        close: 1.1353,
        high: 1.13624,
        low: 1.13345
      },
      {
        timestamp: 1553115600,
        open: 1.1353,
        close: 1.14142,
        high: 1.1449,
        low: 1.13362
      },
      {
        timestamp: 1553202000,
        open: 1.14142,
        close: 1.13753,
        high: 1.14382,
        low: 1.13432
      },
      {
        timestamp: 1553288400,
        open: 1.13753,
        close: 1.13026,
        high: 1.13914,
        low: 1.12739
      },
      {
        timestamp: 1553461200,
        open: 1.13026,
        close: 1.12952,
        high: 1.12982,
        low: 1.12861
      },
      {
        timestamp: 1553547600,
        open: 1.12952,
        close: 1.13134,
        high: 1.13322,
        low: 1.12896
      },
      {
        timestamp: 1553634000,
        open: 1.13134,
        close: 1.12668,
        high: 1.13267,
        low: 1.12641
      },
      {
        timestamp: 1553720400,
        open: 1.12668,
        close: 1.12449,
        high: 1.12864,
        low: 1.12429
      },
      {
        timestamp: 1553806800,
        open: 1.12449,
        close: 1.12235,
        high: 1.1262,
        low: 1.12143
      },
      {
        timestamp: 1553893200,
        open: 1.12235,
        close: 1.12191,
        high: 1.12474,
        low: 1.12104
      },
      {
        timestamp: 1554066000,
        open: 1.12191,
        close: 1.12346,
        high: 1.12421,
        low: 1.12191
      },
      {
        timestamp: 1554152400,
        open: 1.12346,
        close: 1.12141,
        high: 1.12507,
        low: 1.12047
      },
      {
        timestamp: 1554238800,
        open: 1.12141,
        close: 1.12053,
        high: 1.12169,
        low: 1.11843
      },
      {
        timestamp: 1554325200,
        open: 1.12053,
        close: 1.12333,
        high: 1.12554,
        low: 1.12015
      },
      {
        timestamp: 1554411600,
        open: 1.12333,
        close: 1.12219,
        high: 1.12484,
        low: 1.12067
      },
      {
        timestamp: 1554498000,
        open: 1.12219,
        close: 1.12167,
        high: 1.12491,
        low: 1.12107
      },
      {
        timestamp: 1554670800,
        open: 1.12167,
        close: 1.12245,
        high: 1.12245,
        low: 1.12245
      },
      {
        timestamp: 1554757200,
        open: 1.12245,
        close: 1.1265,
        high: 1.12753,
        low: 1.1214
      },
      {
        timestamp: 1554843600,
        open: 1.1265,
        close: 1.1264,
        high: 1.1285,
        low: 1.12559
      },
      {
        timestamp: 1554930000,
        open: 1.1264,
        close: 1.12747,
        high: 1.12891,
        low: 1.12303
      },
      {
        timestamp: 1555016400,
        open: 1.12747,
        close: 1.12542,
        high: 1.12882,
        low: 1.12505
      },
      {
        timestamp: 1555102800,
        open: 1.12542,
        close: 1.13003,
        high: 1.13246,
        low: 1.12541
      },
      {
        timestamp: 1555275600,
        open: 1.13003,
        close: 1.13077,
        high: 1.13084,
        low: 1.12975
      },
      {
        timestamp: 1555362000,
        open: 1.13077,
        close: 1.1305,
        high: 1.13218,
        low: 1.12983
      },
      {
        timestamp: 1555448400,
        open: 1.1305,
        close: 1.12819,
        high: 1.13147,
        low: 1.12803
      },
      {
        timestamp: 1555534800,
        open: 1.12819,
        close: 1.12981,
        high: 1.13245,
        low: 1.12797
      },
      {
        timestamp: 1555621200,
        open: 1.12981,
        close: 1.12342,
        high: 1.13048,
        low: 1.12269
      },
      {
        timestamp: 1555707600,
        open: 1.12342,
        close: 1.1246,
        high: 1.12494,
        low: 1.12299
      },
      {
        timestamp: 1555880400,
        open: 1.1246,
        close: 1.12515,
        high: 1.12515,
        low: 1.1246
      },
      {
        timestamp: 1555966800,
        open: 1.12515,
        close: 1.12582,
        high: 1.12628,
        low: 1.12366
      },
      {
        timestamp: 1556053200,
        open: 1.12582,
        close: 1.12282,
        high: 1.1262,
        low: 1.1193
      },
      {
        timestamp: 1556139600,
        open: 1.12282,
        close: 1.1159,
        high: 1.12296,
        low: 1.11413
      },
      {
        timestamp: 1556226000,
        open: 1.1159,
        close: 1.1134,
        high: 1.11632,
        low: 1.11188
      },
      {
        timestamp: 1556312400,
        open: 1.1134,
        close: 1.11453,
        high: 1.11746,
        low: 1.11112
      },
      {
        timestamp: 1556485200,
        open: 1.11453,
        close: 1.11486,
        high: 1.11591,
        low: 1.11453
      },
      {
        timestamp: 1556571600,
        open: 1.11486,
        close: 1.11866,
        high: 1.11881,
        low: 1.11458
      },
      {
        timestamp: 1556658000,
        open: 1.11866,
        close: 1.12162,
        high: 1.12298,
        low: 1.11765
      },
      {
        timestamp: 1556744400,
        open: 1.12162,
        close: 1.11992,
        high: 1.12655,
        low: 1.11879
      },
      {
        timestamp: 1556830800,
        open: 1.11992,
        close: 1.11738,
        high: 1.12198,
        low: 1.11715
      },
      {
        timestamp: 1556917200,
        open: 1.11738,
        close: 1.12037,
        high: 1.12054,
        low: 1.11359
      },
      {
        timestamp: 1557090000,
        open: 1.12037,
        close: 1.11807,
        high: 1.11884,
        low: 1.11585
      },
      {
        timestamp: 1557176400,
        open: 1.11807,
        close: 1.12012,
        high: 1.12101,
        low: 1.11739
      },
      {
        timestamp: 1557262800,
        open: 1.12012,
        close: 1.11915,
        high: 1.12185,
        low: 1.11673
      },
      {
        timestamp: 1557349200,
        open: 1.11915,
        close: 1.11927,
        high: 1.12145,
        low: 1.11835
      },
      {
        timestamp: 1557435600,
        open: 1.11927,
        close: 1.12171,
        high: 1.12517,
        low: 1.11742
      },
      {
        timestamp: 1557522000,
        open: 1.12171,
        close: 1.12363,
        high: 1.12543,
        low: 1.12154
      },
      {
        timestamp: 1557694800,
        open: 1.12363,
        close: 1.12352,
        high: 1.12402,
        low: 1.12328
      },
      {
        timestamp: 1557781200,
        open: 1.12352,
        close: 1.1223,
        high: 1.12642,
        low: 1.12228
      },
      {
        timestamp: 1557867600,
        open: 1.1223,
        close: 1.12049,
        high: 1.12447,
        low: 1.12019
      },
      {
        timestamp: 1557954000,
        open: 1.12049,
        close: 1.12021,
        high: 1.12255,
        low: 1.11787
      },
      {
        timestamp: 1558040400,
        open: 1.12021,
        close: 1.11758,
        high: 1.12246,
        low: 1.11668
      },
      {
        timestamp: 1558126800,
        open: 1.11758,
        close: 1.11584,
        high: 1.11851,
        low: 1.11558
      },
      {
        timestamp: 1558299600,
        open: 1.11584,
        close: 1.11618,
        high: 1.11637,
        low: 1.11536
      },
      {
        timestamp: 1558386000,
        open: 1.11618,
        close: 1.11671,
        high: 1.11755,
        low: 1.11514
      },
      {
        timestamp: 1558472400,
        open: 1.11671,
        close: 1.11621,
        high: 1.11884,
        low: 1.11427
      },
      {
        timestamp: 1558558800,
        open: 1.11621,
        close: 1.11516,
        high: 1.1181,
        low: 1.11493
      },
      {
        timestamp: 1558645200,
        open: 1.11516,
        close: 1.11825,
        high: 1.11882,
        low: 1.11076
      },
      {
        timestamp: 1558731600,
        open: 1.11825,
        close: 1.12062,
        high: 1.12128,
        low: 1.11771
      },
      {
        timestamp: 1558904400,
        open: 1.12062,
        close: 1.12108,
        high: 1.12134,
        low: 1.11905
      },
      {
        timestamp: 1558990800,
        open: 1.12108,
        close: 1.11972,
        high: 1.12158,
        low: 1.11876
      },
      {
        timestamp: 1559077200,
        open: 1.11972,
        close: 1.11613,
        high: 1.1199,
        low: 1.11599
      },
      {
        timestamp: 1559163600,
        open: 1.11613,
        close: 1.11321,
        high: 1.11731,
        low: 1.11249
      },
      {
        timestamp: 1559250000,
        open: 1.11321,
        close: 1.11299,
        high: 1.11439,
        low: 1.11166
      },
      {
        timestamp: 1559336400,
        open: 1.11299,
        close: 1.11704,
        high: 1.11804,
        low: 1.11258
      },
      {
        timestamp: 1559509200,
        open: 1.11704,
        close: 1.11712,
        high: 1.11712,
        low: 1.11582
      },
      {
        timestamp: 1559595600,
        open: 1.11712,
        close: 1.12417,
        high: 1.12626,
        low: 1.11608
      },
      {
        timestamp: 1559682000,
        open: 1.12417,
        close: 1.12524,
        high: 1.1278,
        low: 1.1227
      },
      {
        timestamp: 1559768400,
        open: 1.12524,
        close: 1.12218,
        high: 1.13075,
        low: 1.12203
      },
      {
        timestamp: 1559854800,
        open: 1.12218,
        close: 1.12763,
        high: 1.13096,
        low: 1.12016
      },
      {
        timestamp: 1559941200,
        open: 1.12763,
        close: 1.13343,
        high: 1.13483,
        low: 1.12519
      },
      {
        timestamp: 1560114000,
        open: 1.13343,
        close: 1.13252,
        high: 1.13311,
        low: 1.13105
      },
      {
        timestamp: 1560200400,
        open: 1.13252,
        close: 1.13122,
        high: 1.13321,
        low: 1.12906
      },
      {
        timestamp: 1560286800,
        open: 1.13122,
        close: 1.1327,
        high: 1.13381,
        low: 1.13019
      },
      {
        timestamp: 1560373200,
        open: 1.1327,
        close: 1.12879,
        high: 1.13441,
        low: 1.1283
      },
      {
        timestamp: 1560459600,
        open: 1.12879,
        close: 1.1277,
        high: 1.13044,
        low: 1.12689
      },
      {
        timestamp: 1560546000,
        open: 1.1277,
        close: 1.12129,
        high: 1.12898,
        low: 1.12031
      },
      {
        timestamp: 1560718800,
        open: 1.12129,
        close: 1.12125,
        high: 1.12175,
        low: 1.12054
      },
      {
        timestamp: 1560805200,
        open: 1.12125,
        close: 1.12189,
        high: 1.12479,
        low: 1.12043
      },
      {
        timestamp: 1560891600,
        open: 1.12189,
        close: 1.11944,
        high: 1.12434,
        low: 1.1182
      },
      {
        timestamp: 1560978000,
        open: 1.11944,
        close: 1.12273,
        high: 1.12551,
        low: 1.11878
      },
      {
        timestamp: 1561064400,
        open: 1.12273,
        close: 1.12937,
        high: 1.13183,
        low: 1.12268
      },
      {
        timestamp: 1561150800,
        open: 1.12937,
        close: 1.13706,
        high: 1.13785,
        low: 1.12837
      }
    ]
  }
];


export const historicalSeriesWhithoutData = historicalSeries.map((h) => {
  const hCopy = { ...h};
  delete hCopy.data;
  return hCopy;
});