/** @jsxImportSource @emotion/react */
import tw from "twin.macro";
import { PrimaryButton } from "../shared/Button";
import { DatePickerCustom } from "../shared/DatePicker";
import { FloatingLabel, NumberInput } from "../shared/Form";
import {
  historicalSeries,
  historicalSeriesWhithoutData,
} from "../trading-series/HistoricalSeries";
import { HistoricalSeriesSelect } from "../trading-series/HistoricalSeriesSelect";
import { CustomSelect } from "./CustomSelect";
import { TrendConfigurationContext } from "./TrendConfigurationProvider";
import { useContext, useEffect, useState } from "react";
import { useImportExcel } from "../shared/ImportXLSX";
import { Spinner } from "../shared/Spinner";

export const OFFSET_CHOICES = [
  {
    id: 0,
    label: "T + 30",
    value: "T + 30",
  },
  {
    id: 1,
    label: "T + 35",
    value: "T + 35",
  },
];

const TrendConfiguration = () => {
  const { trendConfig, setTrendConfig } = useContext(TrendConfigurationContext);
  const [trendConfigBeforeExecute, setTrendConfigBeforeExecute] =
    useState(trendConfig);

  const [useCustomSerie, setUseCustomSerie] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [loadingCalculations, setLoadingCalculations] = useState(false);

  const { mutateAsync: importExcel } = useImportExcel();

  useEffect(() => {
    setLoadingCalculations(false);
  }, [trendConfig]);

  const updateTrendConfigField = (field, value) => {
    setTrendConfigBeforeExecute({
      ...trendConfig,
      [field]: value,
    });
  };

  const execute = () => {
    setLoadingCalculations(true);
    setTrendConfig({ ...trendConfigBeforeExecute });
  };

  const dateExtremes = (() => {
    if (trendConfigBeforeExecute.historicalSerie) {
      const data =
        trendConfigBeforeExecute.historicalSerie.data ??
        historicalSeries[trendConfigBeforeExecute.historicalSerie.id].data;
      const firstTimestamp = data[0].timestamp;
      const lastTimestamp = data[data.length - 1].timestamp;
      return {
        min: firstTimestamp ? new Date(firstTimestamp * 1000) : null,
        max: lastTimestamp ? new Date(lastTimestamp * 1000) : null,
      };
    }
  })();

  return (
    <div tw="flex flex-col mt-4 gap-6">
      <div tw="pb-8 border-b border-gray-300">
        <div tw="flex flex-col gap-y-1 mb-4">
          <div tw="text-xs text-gray-900 font-medium">Serie</div>
          <div tw="w-full flex items-center justify-center gap-x-4">
            <button
              tw="w-full text-sm text-gray-900 border border-gray-200 px-4 py-2 rounded-md"
              css={[!useCustomSerie && tw`border-2 border-indigo-700`]}
              onClick={() => {
                setUseCustomSerie(false);
              }}
            >
              SELECT
            </button>
            <button
              tw="w-full text-sm text-gray-900 border border-gray-200 px-4 py-2 rounded-md"
              css={[useCustomSerie && tw`border-2 border-indigo-700`]}
              onClick={() => {
                setUseCustomSerie(true);
              }}
            >
              IMPORT
            </button>
          </div>
        </div>

        {useCustomSerie ? (
          <div tw="relative">
            <label
              for="series-file-input"
              tw="w-full cursor-pointer text-xs px-3 py-1 [word-wrap: break-word]"
            >
              <span tw="border rounded-sm bg-gray-50 hover:bg-gray-100 border-gray-700 px-2 py-1">
                Import&nbsp;a&nbsp;file
              </span>
              <span tw="ml-2">
                {selectedFile ? selectedFile.name : "No file selected"}
              </span>
            </label>
            <input
              id="series-file-input"
              tw="hidden"
              type="file"
              name="Serie"
              accept=".xlsx, .xls"
              onChange={async (e) => {
                if (e.target.files[0]) {
                  setSelectedFile(e.target.files[0]);
                  const reader = new FileReader();
                  reader.readAsArrayBuffer(e.target.files[0]);
                  reader.onload = async () => {
                    const series = await importExcel({ result: reader.result });
                    const fromDate = series[0]?.timestamp
                      ? new Date(series[0].timestamp * 1000)
                      : undefined;
                    const toDate = series[series.length - 1]?.timestamp
                      ? new Date(series[series.length - 1].timestamp * 1000)
                      : undefined;

                    updateTrendConfigField("previsionStart", fromDate);

                    updateTrendConfigField("historicalSerie", {
                      label: "CUSTOM",
                      data: series,
                      from: fromDate
                        ? fromDate.toISOString().split("T")[0]
                        : undefined,
                      to: toDate
                        ? toDate.toISOString().split("T")[0]
                        : undefined,
                    });
                  };
                }
              }}
            />
          </div>
        ) : (
          <>
            <div tw="relative">
              <HistoricalSeriesSelect
                options={historicalSeriesWhithoutData}
                value={trendConfigBeforeExecute.historicalSerie}
                onChange={(e) => {
                  updateTrendConfigField("historicalSerie", e);
                }}
              />
            </div>
          </>
        )}

        {((trendConfigBeforeExecute.historicalSerie && !useCustomSerie) ||
          (trendConfigBeforeExecute.historicalSerie &&
            useCustomSerie &&
            selectedFile)) && (
          <div tw="pt-1 pl-2 text-xs font-medium">
            {trendConfigBeforeExecute.historicalSerie.from ?? "n.c"} -{" "}
            {trendConfigBeforeExecute.historicalSerie.to ?? "n.c"}
          </div>
        )}
      </div>

      {/*<div tw="flex space-x-4">
        <FormGroup tw="w-full">
          <FloatingLabel>Scale</FloatingLabel>
          <ScaleSelect
            name="scale"
            options={scale}
          />
        </FormGroup> 

        <FormGroup tw="w-full">
          <FloatingLabel>Offset</FloatingLabel>
          <ScaleSelect name="offset" options={scale} />
        </FormGroup> 
      </div>

       <FormGroup tw="">
        <FloatingLabel>From</FloatingLabel>
        <DatePickerCustom name="from" control={formProps.control} />
      </FormGroup>
      <FormGroup tw="">
        <FloatingLabel>To</FloatingLabel>
        <DatePickerCustom name="to" control={formProps.control} />
      </FormGroup> */}

      <div tw="mt-4 relative">
        <FloatingLabel>Prevision start</FloatingLabel>
        <DatePickerCustom
          value={trendConfigBeforeExecute.previsionStart}
          dateExtremes={dateExtremes}
          onChange={(e) => {
            updateTrendConfigField("previsionStart", e);
          }}
        />
      </div>

      <div tw="relative w-full">
        <FloatingLabel>Offset</FloatingLabel>
        <CustomSelect
          options={OFFSET_CHOICES}
          value={trendConfigBeforeExecute.offset}
          onChange={(e) => {
            updateTrendConfigField("offset", e);
          }}
        />
      </div>

      <div tw="relative">
        <FloatingLabel>Weight coefficient</FloatingLabel>
        <NumberInput
          min={0}
          value={trendConfigBeforeExecute.coefficient}
          onChange={(e) => {
            updateTrendConfigField("coefficient", e.target.value);
          }}
        />
      </div>

      <div tw="mt-1 w-full pt-4 flex justify-end">
        <PrimaryButton tw="w-min" onClick={execute}>
          {loadingCalculations ? <Spinner tw="w-4 h-4 text-white" /> : "Execute"}
        </PrimaryButton>
      </div>
    </div>
  );
};

export default TrendConfiguration;
