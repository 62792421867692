/** @jsxImportSource @emotion/react */
import { useEffect } from "react";
import "twin.macro";
import { ButtonGroup } from "../shared/ButtonGroup";
import { Phase2Charts } from "./Phase2Charts";
import { formatPercentage } from "../shared/formatPercentage";
import { useContext } from "react";
import { ChartManagerContext } from "./ChartManagerProvider";

const CHART_MANAGER_KEY = "phase2";

const Phase2 = ({ data, isInterpretation, iterationIdx }) => {
  const { chartManager, updateChartIteration } =
    useContext(ChartManagerContext);

  const TAB_KEY = isInterpretation ? "interpretation" : "results";

  const baseBtns = [
    {
      label: isInterpretation ? "B" : "T",
      color: "black",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartTActive ?? true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartTActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartTActive
        );
      },
    },
  ];

  const computePredictionsBtns = [
    {
      label: "1",
      color: "#0166C1",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chart1Active ?? true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chart1Active",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chart1Active
        );
      },
    },
    {
      label: "2",
      color: "#d03eaa",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chart2Active ?? true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chart2Active",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chart2Active
        );
      },
    },
  ];

  const stats = isInterpretation
    ? [
        data?.statsPhase2?.reliabilities?.method1ResultsReliabilityBase,
        data?.statsPhase2?.reliabilities?.method2ResultsReliabilityBase,
      ]
    : [
        data?.statsPhase2?.reliabilities?.method1ResultsReliabilityT,
        data?.statsPhase2?.reliabilities?.method2ResultsReliabilityT,
      ];

  return (
    <div tw="h-full">
      <div tw="flex justify-between items-center mb-2 pr-14">
        <ButtonGroup buttons={baseBtns} />
        <div tw="flex flex-col items-center">
          {data?.statsPhase2?.reliabilities && (
            <div tw="flex divide-x">
              {stats.map((el, idx) => (
                <div
                  key={`stats-percentage-${idx}`}
                  tw="text-xs first:pl-0 last:pr-0 px-1"
                >
                  {formatPercentage(el)}%
                </div>
              ))}
            </div>
          )}
          <div tw="px-2">
            <ButtonGroup buttons={computePredictionsBtns} />
          </div>
        </div>
      </div>

      <div tw="[height: calc(80vh - 13rem)] w-full">
        {data && chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx] ? (
          <Phase2Charts
            data={data?.resultsMethods}
            chartManager={chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]}
            stats={data?.statsPhase2?.reliabilities}
            isInterpretation={isInterpretation}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Phase2;
