import { Auth0Provider } from "@auth0/auth0-react";
import "inter-ui/inter.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { FormProvider, useForm } from "react-hook-form";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";
import { GlobalStyles } from "twin.macro";
import "./app.css";
import { AppRoutes } from "./Routes";
import { Layout } from "./shared/Layout";
import { historicalSeriesWhithoutData } from "./trading-series/HistoricalSeries";
import { OFFSET_CHOICES } from "./trend-config/TrendConfiguration";
import { TrendConfigurationProvider } from "./trend-config/TrendConfigurationProvider";
import { SeriesCalculationsProvider } from "./trending-series-calculations/SeriesCalculationsProvider";
import { ChartManagerProvider } from "./Results/ChartManagerProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
    },
  },
});

/** @type {import("react").FC} */
// @ts-ignore
const AppProviders = ({ children }) => {
  return (
    <Auth0Provider
      domain="trade-copilot.eu.auth0.com"
      clientId="CKbhLzyE1wAoYWa9pslkeCQMMk8kEsmU"
      redirectUri={window.location.origin}
      audience="https://trade-copilot.io/"
    >
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet titleTemplate="%s | Trend Copilot" />
          <GlobalStyles />
          <BrowserRouter>{children}</BrowserRouter>
        </HelmetProvider>
      </QueryClientProvider>
    </Auth0Provider>
  );
};

/** @type {import("react").FC} */
// @ts-ignore
export const App = () => {
  return (
    <AppProviders>
      <TrendConfigurationProvider>
        <Layout>
          <SeriesCalculationsProvider>
            <ChartManagerProvider>
              <AppRoutes />
            </ChartManagerProvider>
          </SeriesCalculationsProvider>
        </Layout>
      </TrendConfigurationProvider>
    </AppProviders>
  );
};
