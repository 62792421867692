import { withAuthenticationRequired } from "@auth0/auth0-react";
import { lazy, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Route, Routes, useLocation } from "react-router-dom";
import { ErrorFallback } from "./shared/ErrorFallback";
import Results from "./Results/Results";
import Dashboard from "./Results/Dashboard";

const NotFoundRoute = lazy(() => import("./shared/NotFoundRoute"));

const AuthenticatedRoutes = withAuthenticationRequired(() => {
  const location = useLocation();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} key={location.pathname}>
      <Suspense fallback={null}>
        <Routes>
          <Route path="/" element={<Dashboard/>} />
          <Route path="*" element={<NotFoundRoute/>} />
        </Routes>
      </Suspense>
    </ErrorBoundary>
  );
});

/** @type {import("react").FC} */
export const AppRoutes = () => {
  return <AuthenticatedRoutes />;
};
