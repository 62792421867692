import { computeStochasticOscillators } from "../trading-prediction-series-calculations/computeStochasticOscillators";
import { leastSquares } from "../trading-prediction-series-calculations/leastSquares";

/**
 * Function to get the spy serie
 * Correspond to Serie Spy tab of the file
 * @param ohlcSerie the base serie
 * @param weightParameterSpy the weight parameter
 * @param indexParameter the index to start
 * @param offsetValue the offset value
 * @returns ohlcSpySerie, consolidatedSpySeries, serieVariations, othersValuesSpySeries, g1SpySerie, h1SpySerie, h2SpySerie calulations results
 */
export const getSpySerie = (ohlcSerie, weightParameterSpy, indexParameter, offsetValue) => {
  const ohlcSpySerie = [];
  const consolidatedSpySeries = [];
  const gValues = [];
  const rValues = [];
  const oValues = [];
  const tValues = [];
  const othersValuesSpySeries = [];
  let count = 0;
  let sumG = 0;

  for (let i = 0; i < indexParameter + offsetValue; ++i) {
    // Ri = i > 1 && (Ei - E(i-1)) / E(i-1)
    const r =
      i > 0 &&
      (ohlcSerie[i].close - ohlcSerie[i - 1].close) / ohlcSerie[i - 1].close;

    // Gi = i>1 && absolute(Ri)
    i > 0 && gValues.push(Math.abs(r));
    
    if(i > 0 && i < indexParameter - 1) {
      sumG += Math.abs(r);
      count ++;
    }
  }

  // G1 = AVERAGE(G3 - G1500)
  const g1 = sumG / count;

  // H1 = 0.5
  // TODO: is h1 parameter ?
  const h1 = 0.5;

  // H2 = H1/G1
  const h2 = h1 / g1;

  for (let i = 0 && i < indexParameter; i < ohlcSerie.length; ++i) {
    // Ii = random (amplitude)
    const colI = Math.random();

    // Ji = Ii/H2
    const j = colI / h2;

    // Ki = random (for the sign)
    const k = Math.random();

    // Mi = i > 1 && (Ei - E(i-1)) / E(i-1)
    // TODO: 1506 is the future, so remove this check
    const m =
      i > 0 && i < indexParameter
        ? (ohlcSerie[i].close - ohlcSerie[i - 1].close) / ohlcSerie[i - 1].close
        : 0;

    // Li = Ki > 0.5 && i > 1 ? Ji / P1505 : -Ji / P1505
    const l = k > 0.5 ? j / weightParameterSpy : -j / weightParameterSpy;

    // Ni = Li + Mi
    const n = l + m;

    // Oi = i === 1 ? Ei : O(i-1) * (1*Ni)
    const o = i === 0 ? ohlcSerie[i].close : oValues[i - 1] * (1 + n);

    oValues.push(o);
    ohlcSpySerie.push({ open: o, high: o, low: o, close: o });

    // Part 2 for TC attached file
    // Qi = Li
    const q = l;

    // Ri = (Ei - E(i-1)) / E(i-1)
    const r =
      i > 0 &&
      (ohlcSerie[i].close - ohlcSerie[i - 1].close) / ohlcSerie[i - 1].close;

    rValues.push(r);

    // Si = Qi + Ri
    const s = q + r;

    // Ti = i === 1 ? Oi : T(i-1) * (1+Si)
    const t = i === 0 ? o : tValues[i - 1] * (1 + s);
    tValues.push(t);

    othersValuesSpySeries.push({
      g: gValues[i],
      random1: colI,
      j: j,
      random2: k,
      l: l,
      m: m,
      n: n,
      r: r,
      s: s,
      q: q
    });
    consolidatedSpySeries.push({ open: t, high: t, low: t, close: t });
  }

  return {
    ohlcSpySerie,
    consolidatedSpySeries,
    serieVariations: rValues,
    othersValuesSpySeries: othersValuesSpySeries,
    g1SpySerie: g1,
    h1SpySerie: h1,
    h2SpySerie: h2,
  };
};

/**
 * Function to get OHLC derivative serie with random values
 * @param serie the base serie
 * @returns derivativeSerie the derivative serie
 */
export const getOhlcDerivative = (serie) => {
  let derivativeSerie = [];
  for (let i = 0; i < serie.length; ++i) {
    // random value
    const randomOHLCDerivative = Math.random();

    // openDerivative[i] = i === 0 ? open[i] : closeDerivative[i - 1];
    const openDerivative =
      i === 0 ? serie[i].open : derivativeSerie[i - 1]?.close;

    // highDerivative[i] = i === 0 ? high[i] : (randomOHLCDerivative > 0.5 ? openDerivative[i]+(high[i]-open[i]) : openDerivative[i]+(open[i]-low[i]));
    const highDerivative =
      i === 0
        ? serie[i].high
        : randomOHLCDerivative > 0.5
        ? openDerivative + (serie[i].high - serie[i].open)
        : openDerivative + (serie[i].open - serie[i].low);

    // lowDerivative[i] = i === 0 ? low[i] : (randomOHLCDerivative > 0.5 ? openDerivative[i]-(open[i]-low[i]) : openDerivative[i]-(high[i]-open[i]));
    const lowDerivative =
      i === 0
        ? serie[i].low
        : randomOHLCDerivative > 0.5
        ? openDerivative - (serie[i].open - serie[i].low)
        : openDerivative - (serie[i].high - serie[i].open);

    // closeDerivative[i] = i === 0 ? close[i] : (randomOHLCDerivative > 0.5 ? closeDerivative[i-1]+(close[i]-close[i-1]) : closeDerivative[i-1]-(close[i]-close[i-1]));
    const closeDerivative =
      i === 0
        ? serie[i].close
        : randomOHLCDerivative > 0.5
        ? derivativeSerie[i - 1].close + (serie[i].close - serie[i - 1].close)
        : derivativeSerie[i - 1].close - (serie[i].close - serie[i - 1].close);

    derivativeSerie.push({
      random: randomOHLCDerivative,
      open: openDerivative,
      high: highDerivative,
      low: lowDerivative,
      close: closeDerivative,
    });
  }
  return derivativeSerie;
};

// compute alpha prediction
/**
 * Function to calculate alpha prediction of a serie
 * @param ohlcSerie the base serie
 * @param {range, serieCoefficient} the params of the calculation function
 * @returns predictionSerie the prediction serie
 */
export const computeAlphaPredictionSerie = (
  ohlcSerie,
  { range, serieCoefficient }
) => {
  const extendedOHLCSerie = Array.from(
    { length: ohlcSerie.length + serieCoefficient + range },
    (_, index) => {
      if (index < ohlcSerie.length) {
        return ohlcSerie[index];
      } else {
        return ohlcSerie[ohlcSerie.length - 1];
      }
    }
  );
  const stochasticOscillators = computeStochasticOscillators(
    extendedOHLCSerie,
    range
  );

  const predictionSerie = [];
  for (
    let i = serieCoefficient + range;
    i < stochasticOscillators.length + range - 1;
    ++i
  ) {
    const latestStochasticOscillators = stochasticOscillators.slice(
      i - range + 1,
      i + 1
    );
    const { m, b } = leastSquares(latestStochasticOscillators);

    predictionSerie.push({
      stochasticOscillator: stochasticOscillators[i],
      m: m,
      b: b,
      value: m * serieCoefficient + b + m,
      index: i,
    });
  }

  return predictionSerie;
};

/**
 * Function to compute derivated calculation
 * Correspond to Feuil1 of TC Attached Serie Spy file
 * @param serieVariations the serie varations
 * @param consolidatedSpySeries the consolidated spy serie (T)
 * @param alphaPredictionSerie the alpha prediction serie
 * @param ohlcDerivative the OHLC derivative serie
 * @param indexParameter the index parameter to start
 * @returns a, b, c, d, e, f, g, h, index, dataIndex, h3 the results values of the calculation
 */
const computeDerivated = (
  serieVariations,
  consolidatedSpySeries,
  alphaPredictionSerie,
  ohlcDerivative,
  indexParameter
) => {
  const eValues = [];
  const bValues = [];
  const fValues = [];
  const gValues = [];
  const values = [];

  // TODO : specific value ?
  const indexToDBefore = 206;
  const indexToH3Before = 301;
  const fixedIndexParameter = indexParameter;

  for (
    let i = 0;
    i < consolidatedSpySeries.length &&
    indexParameter < consolidatedSpySeries.length;
    ++i
  ) {
    // Ai = spySerie-Ri
    const a = serieVariations[indexParameter];

    // Bi = spySerie-Ti
    const b = consolidatedSpySeries[indexParameter]?.close;
    bValues.push(b);

    // Ci = Historic-H2
    const c = ohlcDerivative[indexParameter]?.random;

    // Di = ABS(SpySerie-Ti - SpySerie-T(i-1))
    // const d = Math.abs(consolidatedSpySeries[i] - consolidatedSpySeries[i - 1]);
    const d = Math.abs(
      consolidatedSpySeries[indexParameter - indexToDBefore]?.close -
        consolidatedSpySeries[indexParameter - indexToDBefore - 1]?.close
    );

    // Ei = Ci>0.5 ? E(i-1)+(Bi-B(i-1)) : E(i-1)-(Bi-B(i-1))
    const e =
      i === 0
        ? b
        : c > 0.5
        ? eValues[i - 1] + (b - bValues[i - 1])
        : eValues[i - 1] - (b - bValues[i - 1]);
    eValues.push(e);

    // Fi =SI(Ci>0,5;F(i-1)+Di;F(i-1)-Di)
    const f =
      i === 0
        ? e
        : c > 0.5
        ? fValues[i - 1] + d
        : fValues[i - 1] - d;
    fValues.push(f);

    // Gi = SI(Ci<0,5;G(i-1)+Di;G(i-1)-Di)
    const g =
      i === 0
        ? f
        : c < 0.5
        ? gValues[i - 1] + d
        : gValues[i - 1] - d;
    gValues.push(g);

    // H3 = =SI(EURUSD simple'!$AB1544 > EURUSD simple'!$AB1513;1;-1)
    // TODO +44 and +13 specific values ?
    const h3 =
      alphaPredictionSerie[fixedIndexParameter + 44 - indexToH3Before]?.value >
      alphaPredictionSerie[fixedIndexParameter + 13 - indexToH3Before]?.value
        ? 1
        : -1;

    // Hi = SI(H$3=1;Fi;Gi)
    const h = h3 === 1 ? f : g;

    values.push({
      a: a,
      b: b,
      c: c,
      d: d,
      e: e,
      f: f,
      g: g,
      h: h,
      index: i,
      dataIndex: indexParameter,
      h3,
      h3FisrtValue: alphaPredictionSerie[fixedIndexParameter + 44 - indexToH3Before]?.value, 
      h3SecondValue: alphaPredictionSerie[fixedIndexParameter + 13 - indexToH3Before]?.value
    });

    ++indexParameter;
  }
  return values;
};

/**
 * Function to compute preview series
 * @param ohlcSerie the base serie
 * @param weightParameterSpy the weight parameter
 * @param indexParameter the index to start
 * @returns trendSerie the trend series result
 */
const computePredictionTrendSeriePreview = (
  ohlcSerie,
  weightParameterSpy,
  indexParameter,
  offsetValue
) => {
  // Fist file of serie spy calculation
  const {
    ohlcSpySerie,
    consolidatedSpySeries,
    serieVariations,
    othersValuesSpySeries,
    g1SpySerie,
    h1SpySerie,
    h2SpySerie,
  } = getSpySerie(ohlcSerie, weightParameterSpy, indexParameter, offsetValue);

  // Historique H2 tab
  const ohlcDerivative = getOhlcDerivative(ohlcSpySerie);

  // EURUSD simple tab
  // TODO specific paramters ?
  const serieCoefficient = 100;
  const range = 100;

  const alphaPredictionSerie = computeAlphaPredictionSerie(ohlcDerivative, {
    range: range,
    serieCoefficient: serieCoefficient,
  });

  // Feuil1 of TC Attached Serie Spy file first columns
  const derivatedValues = computeDerivated(
    serieVariations,
    consolidatedSpySeries,
    alphaPredictionSerie,
    ohlcDerivative,
    indexParameter
  );

  const to = ohlcSerie[ohlcSerie.length - 1]
    ? ohlcSerie[ohlcSerie.length - 1].timestamp
    : null;

  if (to) {
    const trendSerie = [];
    for (let i = 0; i < ohlcSerie.length; ++i) {
      trendSerie.push({
        // init values
        timestamp: ohlcSerie[i].timestamp,
        open: ohlcSerie[i].open,
        high: ohlcSerie[i].high,
        low: ohlcSerie[i].low,
        close: ohlcSerie[i].close,

        // Série Spy tab - O column
        SpySerieOpen: ohlcSpySerie[i].open,
        SpySerieHigh: ohlcSpySerie[i].high,
        SpySerieLow: ohlcSpySerie[i].low,
        SpySerieClose: ohlcSpySerie[i].close,

        // T column
        consolidatedSpySeriesOpen: consolidatedSpySeries[i].open,
        consolidatedSpySeriesHigh: consolidatedSpySeries[i].high,
        consolidatedSpySeriesLow: consolidatedSpySeries[i].low,
        consolidatedSpySeriesClose: consolidatedSpySeries[i].close,

        // R column
        serieVariations: serieVariations[i],

        // Others columns
        spyGColumn: othersValuesSpySeries[i - 1]?.g,
        random1: othersValuesSpySeries[i].random1,
        spyJColumn: othersValuesSpySeries[i].j,
        random2: othersValuesSpySeries[i].random2,
        spyLColumn: othersValuesSpySeries[i].l,
        spyMColumn: othersValuesSpySeries[i].m,
        spyNColumn: othersValuesSpySeries[i].n,
        spySColumn: othersValuesSpySeries[i].s,
        spyG1Column: g1SpySerie,
        spyH1Column: h1SpySerie,
        spyH2Column: h2SpySerie,

        // Historique H2 tab
        // F - G - H - I
        ohlcDerivativeRandom: ohlcDerivative[i].random,
        ohlcDerivativeOpen: ohlcDerivative[i].open,
        ohlcDerivativeHigh: ohlcDerivative[i].high,
        ohlcDerivativeLow: ohlcDerivative[i].low,
        ohlcDerivativeClose: ohlcDerivative[i].close,

        // EURUSD simple tab
        // AB
        alphaPredictionSerieValue:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]
            ?.value,

        // Y
        alphaPredictionMValue:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]?.m,
        // Z

        alphaPredictionBValue:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]?.b,

        // X
        alphaPredictionStochOscill:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]
            ?.stochasticOscillator,

        // TC Attache - Feuill1
        // A - B - C - D - E - F - G - H - H3 column
        derivatedValuesA: derivatedValues[i - indexParameter]?.a,
        derivatedValuesB: derivatedValues[i - indexParameter]?.b,
        derivatedValuesC: derivatedValues[i - indexParameter]?.c,
        derivatedValuesD: derivatedValues[i - indexParameter]?.d,
        derivatedValuesE: derivatedValues[i - indexParameter]?.e,
        derivatedValuesF: derivatedValues[i - indexParameter]?.f,
        derivatedValuesG: derivatedValues[i - indexParameter]?.g,
        derivatedValuesH: derivatedValues[i - indexParameter]?.h,
        h3: derivatedValues[0]?.h3,
        h3FirstValue: derivatedValues[0]?.h3FisrtValue,
        h3SecondValue: derivatedValues[0]?.h3SecondValue,
        index: i,
      });
    }

    const starterParams = {
      consolidatedSpySeries,
      ohlcSpySerie,
      serieVariations,
      othersValuesSpySeries,
      g1SpySerie,
      h1SpySerie,
      h2SpySerie,
      ohlcDerivative,
      alphaPredictionSerie,
      range,
      serieCoefficient,
      derivatedValues,
    };

    return {
      trendSerie,
      starterParams,
    };
  } else {
    return [];
  }
};

/**
 * Function called by the front
 * @param ohlcSerie the base serie
 * @param weightParameterSpy the weight parameter
 * @param indexParameter the index to start
 * @returns predictionTrendSerie the trend series result
 */
export function calculateTrendPhasePreview(
  ohlcSerie,
  indexParameter,
  weightCoefficient,
  offsetValue
) {
  const predictionTrendSerie = computePredictionTrendSeriePreview(
    ohlcSerie,
    weightCoefficient,
    indexParameter,
    offsetValue
  );
  return predictionTrendSerie;
}
