

export const filterZeroValues = (el) => {
    const attrToCheck = [
      "stade1Results1ReportIndicators",
      "stade1Results2ReportIndicators",
      "stade1Results3ReportIndicators",
      "stade1Results4ReportIndicators",
      "stade1Results5ReportIndicators",
      "stade2Results1ReportIndicators",
      "stade2Results2ReportIndicators",
      "stade2Results3ReportIndicators",
      "stade2Results4ReportIndicators",
      "stade2Results5ReportIndicators",
      "stade3Results1ReportIndicators",
      "stade3Results2ReportIndicators",
      "stade3Results3ReportIndicators",
      "stade3Results4ReportIndicators",
      "stade3Results5ReportIndicators",
      "stade4Results1ReportIndicators",
    ];

    let elToReturn = { ...el };

    attrToCheck.forEach((attr) => {
      if (elToReturn[attr] === 0) {
        elToReturn = {
          ...elToReturn,
          [attr]: undefined,
        };
      }
    });

    return elToReturn;
  };