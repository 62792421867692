/** @jsxImportSource @emotion/react */
import { useController, useFormContext } from "react-hook-form";
import { useFormGroupId } from "../shared/Form";
import { Select } from "../shared/Select";
import tw from "twin.macro";

const getOptionValue = (option) => option.id;

/** @type {import("react").FC<import("react-hook-form").UseControllerProps & import("react").ComponentProps<typeof Select>>} */
export const HistoricalSeriesSelect = ({
  id,
  options,
  ...props
}) => {

  return (
    <Select
      id={id}
      classNamePrefix="historical-select"
      options={options}
      getOptionValue={getOptionValue}
      {...props}
      css={{
        "& .historical-select__menu": tw`!z-50`
      }}
    />
  );
};
