import { PrimaryButton } from "./Button";
import { Container } from "./Container";
import {
  EmptyState,
  EmptyStateActions,
  EmptyStateDescription,
  EmptyStateTitle,
} from "./EmptyState";
import { Page } from "./Page";

/** @type {import("react").FC<import("react-error-boundary").FallbackProps>} */
export const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <Page>
      <Container>
        <EmptyState>
          <EmptyStateTitle>Whoops: there was an error.</EmptyStateTitle>
          <EmptyStateDescription>
            We encountered an issue while trying to load a page or perform an
            action. You can try again later or send the following technical
            information to the support team.
          </EmptyStateDescription>
          <EmptyStateActions>
            <PrimaryButton onClick={resetErrorBoundary}>Retry</PrimaryButton>
          </EmptyStateActions>
        </EmptyState>
      </Container>
    </Page>
  );
};
