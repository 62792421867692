/** @jsxImportSource @emotion/react */
import { useContext } from "react";
import "twin.macro";
import { Phase1Charts } from "./Phase1Charts";
import { ButtonGroup } from "../shared/ButtonGroup";
import { formatPercentage } from "../shared/formatPercentage";
import { ChartManagerContext } from "./ChartManagerProvider";

const CHART_MANAGER_KEY = "phase1";

const Phase1 = ({ data, stats, isInterpretation, iterationIdx }) => {
  const { chartManager, updateChartIteration } =
    useContext(ChartManagerContext);

  const TAB_KEY = isInterpretation ? "interpretation" : "results";

  const baseBtns = [
    {
      label: isInterpretation ? "B" : "T",
      color: "black",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]
          ?.chartSpyActive ?? true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartSpyActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx].chartSpyActive
        );
      },
    },
  ];

  const computePredictionsBtns = [
    {
      label: "A",
      color: "gray",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartAActive ??
        true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartAActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx].chartAActive
        );
      },
    },
    {
      label: "B",
      color: "yellow",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartBActive ??
        true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartBActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx].chartBActive
        );
      },
    },
    {
      label: "C",
      color: "blue",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartCActive ??
        true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartCActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx].chartCActive
        );
      },
    },
    {
      label: "D",
      color: "red",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartDActive ??
        true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartDActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx].chartDActive
        );
      },
    },
  ];

  const statsAverage = isInterpretation
    ? [
        stats.reliabilities.averageStade1Base,
        stats.reliabilities.averageStade2Base,
        stats.reliabilities.averageStade3Base,
        stats.reliabilities.stade4Results1ReliabilityBase,
      ]
    : [
        stats.reliabilities.averageStade1T,
        stats.reliabilities.averageStade2T,
        stats.reliabilities.averageStade3T,
        stats.reliabilities.stade4Results1ReliabilityT,
      ];

  return (
    <div tw="h-full">
      <div tw="flex justify-between items-center mb-2 pr-14">
        <ButtonGroup buttons={baseBtns} />
        <div tw="flex flex-col items-center">
          {stats && (
            <div tw="flex divide-x">
              {statsAverage.map((el, idx) => (
                <div
                  key={`stats-percentage-${idx}`}
                  tw="text-xs first:pl-0 last:pr-0 px-1"
                >
                  {formatPercentage(el)}%
                </div>
              ))}
            </div>
          )}
          <div tw="px-2">
            <ButtonGroup buttons={computePredictionsBtns} />
          </div>
        </div>
      </div>

      <div tw="[height: calc(80vh - 13rem)] w-full">
        {data && chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx] ? (
          <Phase1Charts
            data={data}
            chartManager={
              chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]
            }
            stats={stats}
            isInterpretation={isInterpretation}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Phase1;
