// @ts-check
export const computeStochasticOscillators = (
  /** @type {{ open: number; high: number; low: number; close: number }[]} */ ohlcSerie,
  /** @type {number} */ range
) => {
  const stochasticOscillators = [];
  for (let i = range - 1; i < ohlcSerie.length; i++) {
    const price = ohlcSerie[i].close;
    let lowN = Infinity;
    let highN = -Infinity;
    for (let j = i - range + 1; j <= i; j++) {
      const previousPricePoint = ohlcSerie[j];
      const lowest = Math.min(
        previousPricePoint.close,
        previousPricePoint.high,
        previousPricePoint.low,
        previousPricePoint.open
      );
      const highest = Math.max(
        previousPricePoint.close,
        previousPricePoint.high,
        previousPricePoint.low,
        previousPricePoint.open
      );
      if (lowest < lowN) {
        lowN = lowest;
      }
      if (highest > highN) {
        highN = highest;
      }
    }
    const stochasticOscillator = ((price - lowN) / (highN - lowN)) * 100;
    stochasticOscillators.push(stochasticOscillator);
  }
  return stochasticOscillators;
};

export const computeStochasticOscillatorsBeta = (
  /** @type {{ open: number; high: number; low: number; close: number }[]} */ ohlcSerie,
  /** @type {number} */ range
) => {
  const stochasticOscillators = [];
  for (let i = 0; i < ohlcSerie.length; i++) {
    const price = ohlcSerie[i]?.close;
    let lowN = Infinity;
    let highN = -Infinity;
    for (let j = i - range + 1; j <= i; j++) {
      const previousPricePoint = ohlcSerie[j];
      const lowest = Math.min(
        previousPricePoint?.close,
        previousPricePoint?.high,
        previousPricePoint?.low,
        previousPricePoint?.open
      );
      const highest = Math.max(
        previousPricePoint?.close,
        previousPricePoint?.high,
        previousPricePoint?.low,
        previousPricePoint?.open
      );
      if (lowest < lowN) {
        lowN = lowest;
      }
      if (highest > highN) {
        highN = highest;
      }
    }
    const stochasticOscillator = ((price - lowN) / (highN - lowN)) * 100;
    stochasticOscillators.push(stochasticOscillator);
  }
  return stochasticOscillators;
};

