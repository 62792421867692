/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import tw from "twin.macro";

const DEFAULT_BUTTONS = [
  {
    label: "Button A",
    color: "white",
    defaultActive: false,
    action: () => {},
  },
  {
    label: "Button B",
    color: "white",
    defaultActive: false,
    action: () => {},
  },
  {
    label: "Button C",
    color: "white",
    defaultActive: false,
    action: () => {},
  },
];

const bgColors = (color) => {
  switch (color) {
    case "black":
      return tw`border-b-gray-800`;
    case "gray":
      return tw`border-b-gray-400`;
    case "yellow":
      return tw`[border-color: #fde047]`;
    case "blue":
      return tw`[border-color: blue]`;
    case "red":
      return tw`[border-color: red]`;
    case "#0166C1":
      return tw`[border-color: #0166C1]`;
    case "#d03eaa":
      return tw`[border-color: #d03eaa]`;
    case "#009A3D":
      return tw`[border-color: #009A3D]`;
    case "#D3BA00":
      return tw`[border-color: #D3BA00]`;
    case "#00CFD3":
      return tw`[border-color: #00CFD3]`;
    default:
      return tw`bg-white`;
  }
};

const ButtonGroupBtn = ({ button }) => {
  const [active, setActive] = useState(button.defaultActive);

  useEffect(() => {
    setActive(button.defaultActive);
  }, [button.defaultActive])
  

  return (
    <button
      tw="rounded-md border-b-8 border-r border-l border-t font-medium border-gray-300 text-sm py-1 px-3 2xl:(text-base py-2 px-4)"
      css={[
        active && tw`border-b-8 border-b-gray-600 font-bold`,
        active && bgColors(button.color),
      ]}
      onClick={() => {
        setActive(!active);
        if (button.action) {
          button.action();
        }
      }}
    >
      {button.label}
    </button>
  );
};

export const ButtonGroup = ({ buttons = DEFAULT_BUTTONS, ...props }) => {
  return (
    <div tw="text-sm w-fit" {...props}>
      {buttons.map((b) => (
        <ButtonGroupBtn button={b} />
      ))}
    </div>
  );
};
