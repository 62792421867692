// @ts-nocheck
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { fromUnixTime } from "date-fns";
import { memo, useContext } from "react";
import {
  ChartCanvas,
  CrossHairCursor,
  discontinuousTimeScaleProviderBuilder,
  last,
  withSize,
} from "react-financial-charts";
import tw from "twin.macro";
import { Spinner } from "../shared/Spinner";
import { TrendConfigurationContext } from "../trend-config/TrendConfigurationProvider";
import { getIndexByDate, getOffsetBySelectOffsetValue } from "./Dashboard";
import { getChartExtremes } from "../shared/getChartExtremes";
import { Phase3ComputationCharts } from "../trading-ohlc-series/Phase3ComputationCharts";

export const TradingSeriesChartSkeleton = () => {
  return (
    <div tw="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-[5]">
      <Spinner />
    </div>
  );
};

const xScaleProvider =
  discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d.date);
const margin = { left: 0, right: 50, top: 0, bottom: 24 };

const TradingSeriesChartInner = memo(function TradingSeriesChartInner({
  data: tradingSerie,
  width,
  height,
  chartManager: { chartBaseActive, chartUniversalActive, chartPActive },
  isInterpretation,
}) {
  const { trendConfig } = useContext(TrendConfigurationContext);
  const { previsionStart, offset } = trendConfig;
  const offsetValue = getOffsetBySelectOffsetValue(offset);

  const initialDataWithDates = tradingSerie.phase3Results?.map(
    ({ timestamp, ...values }) => ({
      date: fromUnixTime(timestamp),
      timestamp: timestamp,
      ...values,
    })
  );

  const indexParamter = getIndexByDate(previsionStart, initialDataWithDates);

  const slicedData = initialDataWithDates.slice(
    indexParamter,
    indexParamter + offsetValue
  );

  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(slicedData);

  const xExtents = [xAccessor(0), xAccessor(last(data))];

  const buildStatsToDisplay = () => {
    return {
      "#009A3D": chartPActive
        ? [
            { label: "U : ", field: "phase3ReliabilityUniversal" },
            { label: "B : ", field: "phase3ReliabilityBase" },
          ]
        : undefined,
    };
  };

  const statsToDisplay = buildStatsToDisplay();

  const buildLineToDisplay = () => {
    return [
      ...(chartBaseActive
        ? [
            {
              lines: [{ color: "#9ca3af", field: "base" }],
            },
          ]
        : []),
      ...(chartUniversalActive
        ? [
            {
              lines: [{ color: "blue", field: "univSerie" }],
            },
          ]
        : []),
      ...(chartPActive
        ? [
            {
              lines: [{ color: "#009A3D", field: "plannedSerie" }],
            },
          ]
        : []),
    ];
  };

  const lineToDisplay = buildLineToDisplay();

  const AllYExtents = [];
  lineToDisplay.forEach((el) =>
    el.lines.forEach((line) => {
      AllYExtents.push(line.field);
    })
  );

  const chartExtremes = getChartExtremes(data, AllYExtents);

  const offsetToBase = data[0]?.univSerie
    ? data[0]?.univSerie - data[0].base
    : undefined;

  const dataWithOffsetShift = data.map((d) => {
    return {
      ...d,
      univSerie: d.univSerie - offsetToBase,
      plannedSerie: d.plannedSerie - offsetToBase,
    };
  });

  return (
    <>
      <ChartCanvas
        height={height}
        width={width}
        ratio={window.devicePixelRatio}
        margin={margin}
        data={dataWithOffsetShift}
        displayXAccessor={displayXAccessor}
        xScale={xScale}
        xAccessor={xAccessor}
        // yAccessor={(data) => data["stade3Results1ReportIndicators"]}
        // xExtents={() => [indexParamter - 1, indexParamter + offsetValue + 1]}
        xExtents={xExtents}
        zoomEnabled={true}
      >
        <Phase3ComputationCharts
          id={0}
          origin={[0, 0]}
          displayDateXAxis={true}
          data={dataWithOffsetShift}
          yExtents={(d) => AllYExtents.map((el) => d[el])}
          lineToDisplay={lineToDisplay}
          chartExtremes={chartExtremes}
          stats={tradingSerie.reliabilities}
          statsToDisplay={statsToDisplay}
        />

        <CrossHairCursor />
      </ChartCanvas>
    </>
  );
});

/** @type {import("react").FC<Omit<TradingSeriesChartProps, "width" | "height">>} */
// @ts-ignore
export const Phase3Charts = withSize()(TradingSeriesChartInner);

export const TradingSeriesChartContainer = styled("div")(tw`flex-1 relative`);
