/** @jsxImportSource @emotion/react */
import { Menu, Transition } from "@headlessui/react";
import { useContext } from "react";
import tw from "twin.macro";
import { SeriesCalculationsContext } from "../trending-series-calculations/SeriesCalculationsProvider";
import { useExportExcelTemplate } from "../shared/excelTemplate/writeToTemplateXLSX";
import { ChevronDown, DocumentDownload } from "../shared/Icons";
import { useExportDataToXSLXPhase3Mutation } from "../shared/ExportDataToXLSXPhase3";
import { useExportDataToXSLXPhase1Mutation } from "../shared/ExportDataToXLSXPhase1";
import { useExportDataToXSLXPhase2Mutation } from "../shared/ExportDataToXLSXPhase2";
import { useExportExcelTemplatePhase3 } from "../shared/excelTemplate/writeToTemplatePhase3XLSX";

export const ExportDropdown = () => {
  const {
    previewData,
    phase1Calculations,
    phase2Calculation,
    phase3Calculation,
  } = useContext(SeriesCalculationsContext);

  const { mutateAsync: exportExcelTemplate } = useExportExcelTemplate();
  const { isLoadingExportPhase1, mutate: exportDataToXLSXPhase1 } =
    useExportDataToXSLXPhase1Mutation();
  const { isLoadingExportPhase2, mutate: exportDataToXLSXPhase2 } =
    useExportDataToXSLXPhase2Mutation();
  const { isLoadingExportPhase3, mutate: exportDataToXLSXPhase3 } =
    useExportDataToXSLXPhase3Mutation();
    const { mutateAsync: exportExcelTemplatePhase3 } = useExportExcelTemplatePhase3();

  return (
    <Menu
      as="div"
      tw="absolute right-0 -top-20 xl:-top-14 2xl:-top-16 mt-0.5 flex flex-col justify-center items-end"
    >
      {({ open }) => (
        <>
          <Menu.Button tw="flex items-center justify-center border-2 bg-white border-gray-100 rounded-md px-4 py-2 text-sm">
            <span tw="font-medium">Options</span>
            <ChevronDown tw="ml-1.5 w-4 h-4" />
          </Menu.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Menu.Items tw="z-10 mt-2.5 w-full bg-white text-sm border-2 border-gray-100 rounded-md divide-y-2">
              <Menu.Item tw="px-4 pt-2 pb-2 w-full">
                {({ active }) => (
                  <button
                    tw="flex items-center justify-start space-x-1.5 font-medium text-gray-600"
                    css={[active && tw`bg-gray-100`]}
                    onClick={() =>
                      !isLoadingExportPhase1
                        ? exportDataToXLSXPhase1({
                            previewData: previewData?.tradingSerie,
                            phase1: phase1Calculations,
                          })
                        : null
                    }
                  >
                    <DocumentDownload tw="h-6 w-6 text-gray-400 hover:text-gray-900" />
                    <span>Export data phase 1</span>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item tw="px-4 pt-2 pb-2 w-full">
                {({ active }) => (
                  <button
                    tw="flex items-center justify-start space-x-1.5 font-medium text-gray-600"
                    css={[active && tw`bg-gray-100`]}
                    onClick={() =>
                      !isLoadingExportPhase2
                        ? exportDataToXLSXPhase2({
                            phase2: phase2Calculation,
                          })
                        : null
                    }
                  >
                    <DocumentDownload tw="h-6 w-6 text-gray-400 hover:text-gray-900" />
                    <span>Export data phase 2</span>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item tw="px-4 pt-2 pb-2 w-full">
                {({ active }) => (
                  <button
                    tw="flex items-center justify-start space-x-1.5 font-medium text-gray-600"
                    css={[active && tw`bg-gray-100`]}
                    onClick={() => {
                      if (!isLoadingExportPhase3) {
                        exportDataToXLSXPhase3({
                          phase3: phase3Calculation ? phase3Calculation[0] : {},
                        });
                      }
                    }}
                  >
                    <DocumentDownload tw="h-6 w-6 text-gray-400 hover:text-gray-900" />
                    <span>Export data phase 3</span>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item tw="px-4 pb-2 pt-2 w-full">
                {({ active }) => (
                  <button
                    tw="flex items-center justify-start space-x-1.5 font-medium text-gray-600"
                    css={[active && tw`bg-gray-100`]}
                    onClick={async () => {
                      await exportExcelTemplate({
                        phase1Data:
                          phase1Calculations,
                        phase2Data: phase2Calculation,
                        phase3Data: phase3Calculation,
                      });
                    }}
                  >
                    <DocumentDownload tw="h-6 w-6 text-gray-400 hover:text-gray-900" />
                    <span>Export to template</span>
                  </button>
                )}
              </Menu.Item>
              <Menu.Item tw="px-4 pb-2 pt-2 w-full">
                {({ active }) => (
                  <button
                    tw="flex items-center justify-start space-x-1.5 font-medium text-gray-600"
                    css={[active && tw`bg-gray-100`]}
                    onClick={async () => {
                      await exportExcelTemplatePhase3({
                        phase3Data: phase3Calculation,
                      });
                    }}
                  >
                    <DocumentDownload tw="h-6 w-6 text-gray-400 hover:text-gray-900" />
                    <span>Export to template phase 3</span>
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
};
