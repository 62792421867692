/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import Phase1 from "./Phase1";
import { useEffect, useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { FloatingLabel, NumberInput } from "../shared/Form";
import { PrimaryButton } from "../shared/Button";
import { calculateTrendPhase2 } from "../trending-series-calculations/computePhase2";
import { TrendConfigurationContext } from "../trend-config/TrendConfigurationProvider";
import { historicalSeries } from "../trading-series/HistoricalSeries";
import { SeriesCalculationsContext } from "../trending-series-calculations/SeriesCalculationsProvider";
import { calculateTrendPhase3 } from "../trending-series-calculations/computePhase3";
import Phase3 from "./Phase3";
import Phase2 from "./Phase2";
import { ChartManagerContext } from "./ChartManagerProvider";
import { ExportDropdown } from "./ExportDropdown";
import { formatPercentage } from "../shared/formatPercentage";
import { PlusIcon } from "@heroicons/react/solid";
import { ResultBlock, ResultChildBlock } from "./ResultBlock";
import { calculatePhase3AvgStats } from "../shared/calculatePhase3AvgStats";
import { Spinner } from "../shared/Spinner";
import { calculateTrendPhase1 } from "../trending-series-calculations/computePhase1";
import { filterZeroValues } from "../shared/dataFilter";

export const ResultsDisplayContext = createContext();

const Results = ({ isInterpretation = false }) => {
  const phase1IterationNumber = 1;
  const [maximizedBlock, setMaximizedBlock] = useState();
  const [phasesToDisplay, setPhasesToDisplay] = useState([]);
  const [loading, setLoading] = useState({
    phase3: false,
    phase3Iteration: false,
  });

  const { trendConfig, configForCalculations } = useContext(
    TrendConfigurationContext
  );
  const { historicalSerie, coefficient } = trendConfig;
  const {
    lastUpdated,
    starterParams,
    phase1Calculations,
    setPhase1Calculations,
    phase2IterationNumber,
    setPhase2IterationNumber,
    phase2Calculation,
    setPhase2Calculation,
    phase3IterationNumber,
    setPhase3IterationNumber,
    phase3Calculation,
    setPhase3Calculation,
  } = useContext(SeriesCalculationsContext);

  const { initalizeChartIteration, addChartIteration } =
    useContext(ChartManagerContext);

  const phase3AvgStats = calculatePhase3AvgStats(phase3Calculation);

  console.log("🚀 - phase1Calculations:", phase1Calculations);

  useEffect(() => {
    const initPhases = async () => {
      const p = await (async () => {
        console.log("INIT PHASES");

        const p3 = phase3Calculation
          ? await buildPhase3Iterations()
          : undefined;

        return [
          {
            name: "STAGE 1",
            iterations: buildPhase1Iterations(),
          },
          {
            name: "STAGE 2",
            iterations: phase2Calculation ? buildPhase2Iterations() : undefined,
          },
          {
            name: "STAGE 3",
            iterations: p3,
          },
        ];
      })();
      setPhasesToDisplay(p);
    };
    initPhases().then(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastUpdated]);

  const buildPhase1Iterations = () => {
    if (!phase1Calculations) {
      initalizeChartIteration("phase1", phase1IterationNumber);
    }

    return [
      ...Array(
        phase1Calculations ? phase1Calculations.length : phase1IterationNumber
      ),
    ].map((_p, idx) => (
      <ResultChildBlock phaseIdx={0} iterationIdx={idx}>
        <Phase1
          data={phase1Calculations[idx].phase1Data}
          stats={phase1Calculations[idx].phase1Stats}
          isInterpretation={isInterpretation}
          iterationIdx={idx}
        />
      </ResultChildBlock>
    ));
  };

  const buildPhase2Iterations = () => {
    if (!phase2Calculation) {
      initalizeChartIteration("phase2", phase2IterationNumber);
    }
    const phase2AllCalculations = phase2Calculation ? phase2Calculation : [];
    const phase2Iterations = [
      ...Array(
        phase2Calculation ? phase2Calculation.length : phase2IterationNumber
      ),
    ].map((_p, idx) => {
      const phase2CalculationIteration = phase2Calculation
        ? phase2Calculation[idx]
        : calculateTrendPhase2(
            historicalSerie.data ?? historicalSeries[historicalSerie.id].data,
            phase1Calculations[phase1Calculations.length - 1].phase1Params,
            configForCalculations.indexParamter,
            configForCalculations.offsetValue,
            starterParams
          );
      if (!phase2Calculation) {
        phase2AllCalculations.push(phase2CalculationIteration);
      }

      return (
        <ResultChildBlock phaseIdx={1} iterationIdx={idx}>
          <Phase2
            data={phase2CalculationIteration}
            isInterpretation={isInterpretation}
            iterationIdx={idx}
          />
        </ResultChildBlock>
      );
    });

    if (!phase2Calculation) {
      setPhase2Calculation(phase2AllCalculations);
    }
    return phase2Iterations;
  };

  const runPhase2 = () => {
    const phase2Iterations = buildPhase2Iterations();
    const phases = [...phasesToDisplay];
    phases[1] = {
      name: "STAGE 2",
      iterations: phase2Iterations,
    };
    setPhasesToDisplay(phases);
  };

  const buildPhase3Iterations = async () => {
    if (!phase3Calculation) {
      initalizeChartIteration("phase3", phase3IterationNumber);
    }
    setLoading({
      ...loading,
      phase3: true,
    });
    const iterations = await new Promise((resolve) => {
      setTimeout(() => {
        const phase3AllCalculations = phase3Calculation
          ? phase3Calculation
          : [];
        const phase3Iterations = [
          ...Array(
            phase3Calculation ? phase3Calculation.length : phase3IterationNumber
          ),
        ].map((_p, idx) => {
          const phase3CalculationIteration = phase3Calculation
            ? phase3Calculation[idx]
            : calculateTrendPhase3(
                historicalSerie.data ??
                  historicalSeries[historicalSerie.id].data,
                configForCalculations.indexParamter,
                configForCalculations.offsetValue,
                coefficient
              );
          if (!phase3Calculation) {
            phase3AllCalculations.push(phase3CalculationIteration);
          }

          return (
            <ResultChildBlock phaseIdx={2} iterationIdx={idx}>
              <Phase3
                data={phase3CalculationIteration.trendSerie[0]}
                isInterpretation={isInterpretation}
                iterationIdx={idx}
              />
            </ResultChildBlock>
          );
        });

        if (!phase3Calculation) {
          setPhase3Calculation(phase3AllCalculations);
        }

        setLoading({
          ...loading,
          phase3: false,
        });

        resolve(phase3Iterations);
      }, 500);
    });

    return iterations;
  };

  const runPhase3 = async () => {
    const phase3Iterations = await buildPhase3Iterations();
    const phases = [...phasesToDisplay];
    phases[2] = {
      name: "STAGE 3",
      iterations: phase3Iterations,
    };
    setPhasesToDisplay(phases);
  };

  const addPhase1 = () => {
    console.log("addPhase1");
    addChartIteration("phase1");

    const {
      trendSerie: trendSeriePhase1,
      phase1Params,
      statsPhase1,
    } = calculateTrendPhase1(
      historicalSerie.data ?? historicalSeries[historicalSerie.id].data,
      configForCalculations.indexParamter,
      configForCalculations.offsetValue,
      coefficient,
      starterParams
    );
    const phase1Data = {
      seriesName: JSON.stringify({
        label: historicalSerie.label,
        a: Math.random(),
      }),
      tradingSerie: trendSeriePhase1.map((el) => filterZeroValues(el)),
    };

    setPhase1Calculations([
      ...phase1Calculations,
      {
        phase1Data,
        phase1Params,
        phase1Stats: statsPhase1,
      },
    ]);

    const phases = [...phasesToDisplay];
    const newIteration = (
      <ResultChildBlock phaseIdx={0} iterationIdx={phases[0].iterations.length}>
        <Phase1
          data={phase1Data}
          stats={statsPhase1}
          isInterpretation={isInterpretation}
          iterationIdx={phases[0].iterations.length}
        />
      </ResultChildBlock>
    );
    const phase1Iterations = [...phases[0].iterations, newIteration];

    phases[0] = { ...phases[0], name: "STAGE 1", iterations: phase1Iterations };
    setPhasesToDisplay(phases);
  };

  const addPhase2 = () => {
    addChartIteration("phase2");
    const phase2AllCalculations = phase2Calculation ? phase2Calculation : [];
    const phase2CalculationIteration = calculateTrendPhase2(
      historicalSerie.data ?? historicalSeries[historicalSerie.id].data,
      phase1Calculations[phase1Calculations.length - 1].phase1Params,
      configForCalculations.indexParamter,
      configForCalculations.offsetValue,
      starterParams
    );
    setPhase2Calculation([
      ...phase2AllCalculations,
      phase2CalculationIteration,
    ]);

    const phases = [...phasesToDisplay];
    const newIteration = (
      <ResultChildBlock phaseIdx={1} iterationIdx={phases[1].iterations.length}>
        <Phase2
          data={phase2CalculationIteration}
          isInterpretation={isInterpretation}
          iterationIdx={phases[1].iterations.length}
        />
      </ResultChildBlock>
    );
    const phase2Iterations = [...phases[1].iterations, newIteration];

    phases[1] = { ...phases[1], name: "STAGE 2", iterations: phase2Iterations };
    setPhasesToDisplay(phases);
  };

  const addPhase3 = async () => {
    addChartIteration("phase3");
    setLoading({
      ...loading,
      phase3Iteration: true,
    });
    const phases = await new Promise((resolve) => {
      setTimeout(() => {
        const phase3AllCalculations = phase3Calculation
          ? phase3Calculation
          : [];
        const phase3CalculationIteration = calculateTrendPhase3(
          historicalSerie.data ?? historicalSeries[historicalSerie.id].data,
          configForCalculations.indexParamter,
          configForCalculations.offsetValue,
          coefficient
        );

        setPhase3Calculation([
          ...phase3AllCalculations,
          phase3CalculationIteration,
        ]);

        const phases = [...phasesToDisplay];
        const newIteration = (
          <ResultChildBlock
            phaseIdx={2}
            iterationIdx={phases[2].iterations.length}
          >
            <Phase3
              data={phase3CalculationIteration.trendSerie[0]}
              isInterpretation={isInterpretation}
              iterationIdx={phases[2].iterations.length}
            />
          </ResultChildBlock>
        );
        const phase3Iterations = [...phases[2].iterations, newIteration];

        phases[2] = {
          ...phases[2],
          name: "STAGE 3",
          iterations: phase3Iterations,
        };

        resolve(phases);
      }, 500);
    });

    setLoading({
      ...loading,
      phase3Iteration: false,
    });

    setPhasesToDisplay(phases);
  };

  const buildPhase = async (phaseIdx) => {
    switch (phaseIdx) {
      case 1:
        return runPhase2();
      case 2:
        return await runPhase3();
      default:
        return () => {};
    }
  };

  const addIteration = (phaseIdx) => {
    switch (phaseIdx) {
      case 0:
        return addPhase1();
      case 1:
        return addPhase2();
      case 2:
        return addPhase3();
      default:
        return () => {};
    }
  };

  return (
    <div tw="relative h-full">
      <div tw="flex xl:flex-row flex-col w-full py-4 space-x-0 space-y-8 xl:(space-x-4 space-y-0)">
        <ResultsDisplayContext.Provider
          value={{ maximizedBlock, setMaximizedBlock }}
        >
          {phasesToDisplay.map((p, phaseIdx) => {
            return (
              <ResultBlock
                key={`phase-${phaseIdx}`}
                css={[
                  maximizedBlock?.phaseIdx !== undefined &&
                    maximizedBlock?.phaseIdx !== phaseIdx &&
                    tw`hidden`,
                  maximizedBlock?.phaseIdx === phaseIdx && tw`!w-full`,
                ]}
              >
                {p.iterations ? (
                  <>
                    <div tw="text-center font-bold p-4">{p.name}</div>
                    {phaseIdx === 2 && phase3AvgStats && (
                      <div tw="absolute top-1 right-1 flex flex-col items-center justify-center text-xs [line-height: 0.85rem] text-gray-500">
                        <div>
                          Ai2:&nbsp;
                          {formatPercentage(phase3AvgStats.ai2Avg)}%
                        </div>
                        <div>
                          Ao2:&nbsp;
                          {formatPercentage(phase3AvgStats.ao2Avg)}%
                        </div>
                        <div>
                          Ap2:&nbsp;
                          {formatPercentage(phase3AvgStats.ap2Avg)}%
                        </div>
                      </div>
                    )}
                    {p.iterations.map((iteration, iterationIdx) =>
                      maximizedBlock?.iterationIdx === iterationIdx ? (
                        <div tw="z-50 w-full" key={`iteration-${phaseIdx}`}>
                          {iteration}
                        </div>
                      ) : (
                        <div
                          tw="relative"
                          css={[
                            maximizedBlock?.iterationIdx !== undefined &&
                              tw`hidden`,
                          ]}
                        >
                          {iteration}
                        </div>
                      )
                    )}
                    {maximizedBlock?.phaseIdx !== phaseIdx && (
                      <button
                        tw="absolute bottom-0 right-0 px-2 py-2 bg-gray-100 rounded-md"
                        onClick={() => {
                          addIteration(phaseIdx);
                        }}
                      >
                        {phaseIdx !== 2 && <PlusIcon tw="w-4 h-4" />}
                        {phaseIdx === 2 &&
                          (loading.phase3Iteration ? (
                            <Spinner tw="w-4 h-4" />
                          ) : (
                            <PlusIcon tw="w-4 h-4" />
                          ))}
                      </button>
                    )}
                  </>
                ) : (
                  <div tw="">
                    <div tw="flex w-full items-center justify-center space-x-4 p-4">
                      <div tw="text-center font-bold">{p.name}</div>
                    </div>

                    {(phaseIdx === 1 || phaseIdx === 2) && (
                      <div tw="px-8 pt-4 pb-8 space-y-6">
                        <div tw="flex w-full items-center space-x-2">
                          <div tw="font-bold text-gray-400 text-xs">
                            CONFIGURATION
                          </div>
                          <div tw="h-1 w-full border-b border-gray-200"></div>
                        </div>

                        <div tw="relative w-1/2">
                          <FloatingLabel>Iteration number</FloatingLabel>
                          <NumberInput
                            min={1}
                            value={
                              phaseIdx === 1
                                ? phase2IterationNumber
                                : phase3IterationNumber
                            }
                            onChange={(e) => {
                              if (e.target.value && !isNaN(e.target.value)) {
                                if (phaseIdx === 1) {
                                  setPhase2IterationNumber(+e.target.value);
                                } else {
                                  setPhase3IterationNumber(+e.target.value);
                                }
                              }
                            }}
                          />
                        </div>

                        <div tw="w-full flex justify-end">
                          <PrimaryButton
                            tw="flex items-center"
                            onClick={async () => {
                              await buildPhase(phaseIdx);
                            }}
                          >
                            Run
                            {phaseIdx === 2 && loading.phase3 && (
                              <Spinner tw="mb-0.5 ml-2.5 text-white w-4 h-4" />
                            )}
                          </PrimaryButton>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </ResultBlock>
            );
          })}
          <ExportDropdown tw="" />
        </ResultsDisplayContext.Provider>
      </div>
    </div>
  );
};

export default Results;
