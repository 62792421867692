import {
  computeAlphaPredictionSerie,
  getOhlcDerivative,
} from "./computePreview";

/**
 * Function to brew the values for a line
 * @param previousReportIndicators
 * @param consolidatedSpySeries the consolidated serie spy (T)
 * @param offsetValue the offset value
 * @returns { indicators, reportIndicators } the new indicators and report indicator
 */
const brewingPhase1 = (
  previousResultsStade,
  consolidatedSpySeries,
  indexParameter,
  offsetValue
) => {
  // Initialisation stade // first iteration
  const reportIndicators = [];

  // init stade
  // for each iteration, me make an indicator
  const nbValues = 5;

  // TODO : specific parameter
  const indexToDBefore = 206;

  for (let i = 0; i < indexParameter + offsetValue; i++) {
    let sum = 0;
    const indicators = [];
    for (let k = 0; k < nbValues; k++) {
      //calculate indicator
      const indicator =
        i < indexParameter
          ? 0
          : i === indexParameter
          ? 1
          : previousResultsStade[k][i]?.reportIndicators >
            previousResultsStade[k][i - 1]?.reportIndicators
          ? 1
          : -1;
      sum += indicator;
      indicators.push(indicator);
    }

    // Di = ABS(SpySerie-Ti - SpySerie-T(i-1))
    // const d = Math.abs(consolidatedSpySeries[i] - consolidatedSpySeries[i - 1]);
    const d = Math.abs(
      consolidatedSpySeries[i - indexToDBefore]?.close -
        consolidatedSpySeries[i - indexToDBefore - 1]?.close
    );

    // =SI(SOMME(BW30:CA30)>0;CB29+$D30;CB29-$D30)
    reportIndicators.push({
      index: i,
      indicator1: indicators[0],
      indicator2: indicators[1],
      indicator3: indicators[2],
      indicator4: indicators[3],
      indicator5: indicators[4],
      reportIndicators:
        i < indexParameter
          ? 0
          : i === indexParameter
          ? previousResultsStade[0][indexParameter]?.reportIndicators
          : sum > 0
          ? reportIndicators[i - 1]?.reportIndicators + d
          : reportIndicators[i - 1]?.reportIndicators - d,
    });
  }
  return reportIndicators;
};

/**
 * Function to generate phase 1 stade 1 values
 * @param consolidatedSpySeries the consolidated serie (T)
 * @param indexParameter the index paramater to start
 * @param ohlcSpySerie the base serie
 * @param offsetValue the offset value
 * @returns stade1Line the stade 1 phase 1 results
 */
export const generatePhase1Stade1 = (
  consolidatedSpySeries,
  indexParameter,
  ohlcSpySerie,
  offsetValue
) => {
  // TODO : specific values
  const nbLoop = 5;
  const nbValues = 5;
  const indexToDBefore = 206;
  const indexToABBefore1 = 257;
  const indexToABBefore2 = 288;
  const fixedIndexParameter = indexParameter;
  const indexToH3Before = 301;

  const stade1Line = [];
  for (let k = 0; k < nbValues; k++) {
    const valuesOfLoop = [];

    for (let j = 0; j < nbLoop; ++j) {
      const eValues = [];
      const bValues = [];
      const fValues = [];
      const gValues = [];
      const values = [];

      // TODO : specific values to calculate alpha prediction
      const serieCoefficient = 100;
      const range = 100;

      const ohlcDerivative = getOhlcDerivative(ohlcSpySerie);

      const alphaPredictionSerie = computeAlphaPredictionSerie(ohlcDerivative, {
        range: range,
        serieCoefficient: serieCoefficient,
      });

      // OLD CODE
      // H3 = =SI(EURUSD simple'!$AB1544 > EURUSD simple'!$AB1513;1;-1)
      // alphaPredictionSerie[1243]?.value > alphaPredictionSerie[1212]?.value
      // const h3 =
      //   alphaPredictionSerie[indexParameter - indexToABBefore1]?.value >
      //   alphaPredictionSerie[indexParameter - indexToABBefore2]?.value
      //     ? 1
      //     : -1;

      // H3 = =SI(EURUSD simple'!$AB1544 > EURUSD simple'!$AB1513;1;-1)
      // TODO +44 and +13 specific values ?
      //13 OK, pas 44.
      const h3 =
        alphaPredictionSerie[fixedIndexParameter + 44 - indexToH3Before]
          ?.value >
        alphaPredictionSerie[fixedIndexParameter + 13 - indexToH3Before]?.value
          ? 1
          : -1;

      const alphaH3Value1 =
        alphaPredictionSerie[fixedIndexParameter + 44 - indexToH3Before]?.value;
      const alphaH3Value2 =
        alphaPredictionSerie[fixedIndexParameter + 13 - indexToH3Before]?.value;

      //let idParameter = indexParameter;
      const hValues = [];
      for (let i = 0; i < ohlcDerivative.length; ++i) {
        // Ci = Historic-H2
        // const c = ohlcDerivative[i].high;
        const c = ohlcDerivative[i]?.random;

        let h = 0;

        if (i >= 0 && i < indexParameter + offsetValue) {
          // Bi = spySerie-Ti
          const b = consolidatedSpySeries[i]?.close;
          bValues.push(b);

          // Di = ABS(SpySerie-Ti - SpySerie-T(i-1))
          // const d = Math.abs(consolidatedSpySeries[i] - consolidatedSpySeries[i - 1]);
          const d = Math.abs(
            consolidatedSpySeries[i - indexToDBefore]?.close -
              consolidatedSpySeries[i - indexToDBefore - 1]?.close
          );

          // Ei = Ci>0.5 ? E(i-1)+(Bi-B(i-1)) : E(i-1)-(Bi-B(i-1))
          const e =
            i < indexParameter
              ? 0
              : i === indexParameter
              ? b
              : c > 0.5
              ? eValues[i - 1] + (b - bValues[i - 1])
              : eValues[i - 1] - (b - bValues[i - 1]);
          eValues.push(e);

          // Fi =SI(Ci>0,5;F(i-1)+Di;F(i-1)-Di)
          const f =
            i < indexParameter
              ? 0
              : i === indexParameter
              ? e
              : c > 0.5
              ? fValues[i - 1] + d
              : fValues[i - 1] - d;
          fValues.push(f);

          // Gi = SI(Ci<0,5;G(i-1)+Di;G(i-1)-Di)
          const g =
            i < indexParameter
              ? 0
              : i === indexParameter
              ? f
              : c < 0.5
              ? gValues[i - 1] + d
              : gValues[i - 1] - d;
          gValues.push(g);

          // Hi = SI(H$3=1;Fi;Gi)
          h = h3 === 1 ? f : g;
          // calculate indicator positive or negative for each values for each i
          // O=SI(J6>J5;1;-1)

          hValues.push(h);
        }

        values.push({
          h: h,
          h3: h3,
          alphaH3Value1: alphaH3Value1,
          alphaH3Value2: alphaH3Value2,
          random: c,
          index: i,
          indicatorOfColumn: h > hValues[i - 1] ? 1 : -1,
          iteration: j,
          alphaPredictionSerie: alphaPredictionSerie[i]?.value,
        });
      }
      valuesOfLoop.push(values);
    }

    // calculate average and report for one iteration
    const reportOfIndicators = [];
    const stade = [];

    for (let i = 0; i < ohlcSpySerie.length; ++i) {
      let averageOfLineOfLoop = 0;
      let sumOfLine = 0;
      let sumOfIndicatorsOfLine = 0;
      let d = 0;

      if (i >= 0 && i < indexParameter + offsetValue) {
        for (let j = 0; j < nbLoop; ++j) {
          sumOfLine += valuesOfLoop[j][i].h;
          sumOfIndicatorsOfLine += valuesOfLoop[j][i].indicatorOfColumn;
        }
        averageOfLineOfLoop = sumOfLine / nbLoop;

        // Di = ABS(SpySerie-Ti - SpySerie-T(i-1))
        // const d = Math.abs(consolidatedSpySeries[i] - consolidatedSpySeries[i - 1]);
        d = Math.abs(
          consolidatedSpySeries[i - indexToDBefore]?.close -
            consolidatedSpySeries[i - indexToDBefore - 1]?.close
        );

        reportOfIndicators.push(
          i < indexParameter
            ? 0
            : i === indexParameter
            ? averageOfLineOfLoop
            : sumOfIndicatorsOfLine > 0
            ? reportOfIndicators[i - 1] + d
            : reportOfIndicators[i - 1] - d
        );
      }

      // TODO render generical values with parameters
      stade.push({
        index: i,
        random1: valuesOfLoop[0][i].random,
        h31: valuesOfLoop[0][i].h3,
        alphaH3Value11: valuesOfLoop[0][i].alphaH3Value1,
        alphaH3Value21: valuesOfLoop[0][i].alphaH3Value2,
        random2: valuesOfLoop[1][i].random,
        h32: valuesOfLoop[1][i].h3,
        alphaH3Value12: valuesOfLoop[1][i].alphaH3Value1,
        alphaH3Value22: valuesOfLoop[1][i].alphaH3Value2,
        random3: valuesOfLoop[2][i].random,
        h33: valuesOfLoop[2][i].h3,
        alphaH3Value13: valuesOfLoop[2][i].alphaH3Value1,
        alphaH3Value23: valuesOfLoop[2][i].alphaH3Value2,
        random4: valuesOfLoop[3][i].random,
        h34: valuesOfLoop[3][i].h3,
        alphaH3Value14: valuesOfLoop[3][i].alphaH3Value1,
        alphaH3Value24: valuesOfLoop[3][i].alphaH3Value2,
        random5: valuesOfLoop[4][i].random,
        h35: valuesOfLoop[4][i].h3,
        alphaH3Value15: valuesOfLoop[4][i].alphaH3Value1,
        alphaH3Value25: valuesOfLoop[4][i].alphaH3Value2,
        value1: valuesOfLoop[0][i].h,
        value2: valuesOfLoop[1][i].h,
        value3: valuesOfLoop[2][i].h,
        value4: valuesOfLoop[3][i].h,
        value5: valuesOfLoop[4][i].h,
        indicator1: valuesOfLoop[0][i].indicatorOfColumn,
        indicator2: valuesOfLoop[1][i].indicatorOfColumn,
        indicator3: valuesOfLoop[2][i].indicatorOfColumn,
        indicator4: valuesOfLoop[3][i].indicatorOfColumn,
        indicator5: valuesOfLoop[4][i].indicatorOfColumn,
        alphaPredictionSerie1: valuesOfLoop[0][i].alphaPredictionSerie,
        averageOfValues: averageOfLineOfLoop,
        d: d,
        reportIndicators:
          i < indexParameter
            ? 0
            : i === indexParameter
            ? averageOfLineOfLoop
            : sumOfIndicatorsOfLine > 0
            ? reportOfIndicators[i - 1] + d
            : reportOfIndicators[i - 1] - d,
      });
    }

    stade1Line.push(stade);
  }
  return stade1Line;
};

/**
 * Fucntion to generale all others stades (A, B, C, D)
 * Correspond to Module4 - Macro1attachée - TC Attaché Série Spy.xlsm
 * @param stade1Results the results of the first calculation
 * @param consolidatedSpySeries the consolidated serie (T)
 * @param indexParameter the insex paramater to start
 * @param ohlcSpySerie the base serie
 * @param offsetValue the offset value
 * @returns allStadesIndicators the phase1 indicators
 */
export const generatePhase1OthersStades = (
  stade1Results,
  consolidatedSpySeries,
  indexParameter,
  ohlcSpySerie,
  offsetValue
) => {
  // Iterate brewing stade - BW
  // The number of stades : yellow, blue, red
  const nbStades = 3;
  const allStadesIndicators = [];
  let previousStade = stade1Results;

  for (let k = 0; k < nbStades; k++) {
    // 5 iterations in a stade
    let nbIterations = 5;
    const reportsIndicators = [];

    // last stade has 1 iteration
    if (k === 2) {
      nbIterations = 1;
    }

    for (let j = 0; j < nbIterations; j++) {
      if (j === 0 && k !== 0) {
        // first blue : we take the 5 yellows
        // first red : we take the 5 blues
        previousStade = allStadesIndicators[k - 1];
      } else if (j === 0 && k === 0) {
        // first yellow : we take the 5 whites
        previousStade = stade1Results;
      } else if (k === 0 && j !== 0) {
        // 2nd, 3th, 4th, and 5th yellow : we take the 5 whites
        previousStade = generatePhase1Stade1(
          consolidatedSpySeries,
          indexParameter,
          ohlcSpySerie,
          offsetValue
        );
      } else if (k === 1 && j !== 0) {
        // 2nd, 3th, 4th and 5th blue : generate 5 yellows and 5 white for each iteration
        const nbIterationsIntermediate = 5;
        const reportsIndicatorsIntermediate = [];
        for (let l = 0; l < nbIterationsIntermediate; l++) {
          const previousStadeIt = generatePhase1Stade1(
            consolidatedSpySeries,
            indexParameter,
            ohlcSpySerie,
            offsetValue
          );
          const brewResultsIt = brewingPhase1(
            previousStadeIt,
            consolidatedSpySeries,
            indexParameter,
            offsetValue
          );
          reportsIndicatorsIntermediate.push(brewResultsIt);
        }

        previousStade = reportsIndicatorsIntermediate;
      }

      const brewResults = brewingPhase1(
        previousStade,
        consolidatedSpySeries,
        indexParameter,
        offsetValue
      );
      reportsIndicators.push(brewResults);
    }

    allStadesIndicators.push(reportsIndicators);
  }
  return allStadesIndicators;
};

const computeReliabilityPhase1 = (
  valuesSerie,
  othersValuesSpySeries,
  indexParameter,
  offsetValue,
  typeRel,
  consolidatedSpySeries
) => {
  let nbIterations = 0;
  let sumSimilarity = 0;

  for (let i = indexParameter; i < indexParameter + offsetValue; i++) {
    const variationT =
      (consolidatedSpySeries[i]?.close - consolidatedSpySeries[i - 1]?.close) /
      consolidatedSpySeries[i - 1]?.close;

    const variationSerieBase =
      typeRel === "base" ? othersValuesSpySeries[i]?.r : variationT;

    const variationSerie =
      (valuesSerie[i]?.reportIndicators -
        valuesSerie[i - 1]?.reportIndicators) /
      valuesSerie[i - 1]?.reportIndicators;

    // v = SI(OU(ET($F53>0;J53>0);ET($F53<0;J53<0));1;"")
    const similarity =
      (variationSerie > 0 && variationSerieBase > 0) ||
      (variationSerie < 0 && variationSerieBase < 0)
        ? 1
        : 0;
    sumSimilarity += similarity;
    nbIterations++;
  }
  return sumSimilarity / nbIterations;
};

/**
 * Function to calculate all reliabilities of phase 1
 * @param stade1Results the phase 1 results
 * @param othersStadesResults the others results of phase 1
 * @param othersValuesSpySeries the variation values of spy serie consolidated
 * @param indexParameter the index parameter to start
 * @param offsetValue the offset value
 */
const computeAllReliability = (
  stade1Results,
  othersStadesResults,
  othersValuesSpySeries,
  indexParameter,
  offsetValue,
  consolidatedSpySeries
) => {
  // Compule reliability with T serie (consolidated spy)
  // A
  const stade1Results1ReliabilityT = computeReliabilityPhase1(
    stade1Results[0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade1Results2ReliabilityT = computeReliabilityPhase1(
    stade1Results[1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade1Results3ReliabilityT = computeReliabilityPhase1(
    stade1Results[2],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade1Results4ReliabilityT = computeReliabilityPhase1(
    stade1Results[3],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade1Results5ReliabilityT = computeReliabilityPhase1(
    stade1Results[4],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  // B
  const stade2Results1ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[0][0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade2Results2ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[0][1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade2Results3ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[0][2],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade2Results4ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[0][3],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade2Results5ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[0][4],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  // C
  const stade3Results1ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[1][0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade3Results2ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[1][1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade3Results3ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[1][2],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade3Results4ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[1][3],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const stade3Results5ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[1][4],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  // D
  const stade4Results1ReliabilityT = computeReliabilityPhase1(
    othersStadesResults[2][0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  // Compule reliability with base serie (ohlc serie)
  // A
  const stade1Results1ReliabilityBase = computeReliabilityPhase1(
    stade1Results[0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade1Results2ReliabilityBase = computeReliabilityPhase1(
    stade1Results[1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade1Results3ReliabilityBase = computeReliabilityPhase1(
    stade1Results[2],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade1Results4ReliabilityBase = computeReliabilityPhase1(
    stade1Results[3],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade1Results5ReliabilityBase = computeReliabilityPhase1(
    stade1Results[4],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  // B
  const stade2Results1ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[0][0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade2Results2ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[0][1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade2Results3ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[0][2],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade2Results4ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[0][3],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade2Results5ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[0][4],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  // C
  const stade3Results1ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[1][0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade3Results2ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[1][1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade3Results3ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[1][2],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade3Results4ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[1][3],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const stade3Results5ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[1][4],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  // D
  const stade4Results1ReliabilityBase = computeReliabilityPhase1(
    othersStadesResults[2][0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  return {
    stade1Results1ReliabilityT: stade1Results1ReliabilityT,
    stade1Results2ReliabilityT: stade1Results2ReliabilityT,
    stade1Results3ReliabilityT: stade1Results3ReliabilityT,
    stade1Results4ReliabilityT: stade1Results4ReliabilityT,
    stade1Results5ReliabilityT: stade1Results5ReliabilityT,
    averageStade1T:
      (stade1Results1ReliabilityT +
        stade1Results2ReliabilityT +
        stade1Results3ReliabilityT +
        stade1Results4ReliabilityT +
        stade1Results5ReliabilityT) /
      5,

    stade2Results1ReliabilityT: stade2Results1ReliabilityT,
    stade2Results2ReliabilityT: stade2Results2ReliabilityT,
    stade2Results3ReliabilityT: stade2Results3ReliabilityT,
    stade2Results4ReliabilityT: stade2Results4ReliabilityT,
    stade2Results5ReliabilityT: stade2Results5ReliabilityT,
    averageStade2T:
      (stade2Results1ReliabilityT +
        stade2Results2ReliabilityT +
        stade2Results3ReliabilityT +
        stade2Results4ReliabilityT +
        stade2Results5ReliabilityT) /
      5,

    stade3Results1ReliabilityT: stade3Results1ReliabilityT,
    stade3Results2ReliabilityT: stade3Results2ReliabilityT,
    stade3Results3ReliabilityT: stade3Results3ReliabilityT,
    stade3Results4ReliabilityT: stade3Results4ReliabilityT,
    stade3Results5ReliabilityT: stade3Results5ReliabilityT,
    averageStade3T:
      (stade3Results1ReliabilityT +
        stade3Results2ReliabilityT +
        stade3Results3ReliabilityT +
        stade3Results4ReliabilityT +
        stade3Results5ReliabilityT) /
      5,

    stade4Results1ReliabilityT: stade4Results1ReliabilityT,

    stade1Results1ReliabilityBase: stade1Results1ReliabilityBase,
    stade1Results2ReliabilityBase: stade1Results2ReliabilityBase,
    stade1Results3ReliabilityBase: stade1Results3ReliabilityBase,
    stade1Results4ReliabilityBase: stade1Results4ReliabilityBase,
    stade1Results5ReliabilityBase: stade1Results5ReliabilityBase,
    averageStade1Base:
      (stade1Results1ReliabilityBase +
        stade1Results2ReliabilityBase +
        stade1Results3ReliabilityBase +
        stade1Results4ReliabilityBase +
        stade1Results5ReliabilityBase) /
      5,

    stade2Results1ReliabilityBase: stade2Results1ReliabilityBase,
    stade2Results2ReliabilityBase: stade2Results2ReliabilityBase,
    stade2Results3ReliabilityBase: stade2Results3ReliabilityBase,
    stade2Results4ReliabilityBase: stade2Results4ReliabilityBase,
    stade2Results5ReliabilityBase: stade2Results5ReliabilityBase,
    averageStade2Base:
      (stade2Results1ReliabilityBase +
        stade2Results2ReliabilityBase +
        stade2Results3ReliabilityBase +
        stade2Results4ReliabilityBase +
        stade2Results5ReliabilityBase) /
      5,

    stade3Results1ReliabilityBase: stade3Results1ReliabilityBase,
    stade3Results2ReliabilityBase: stade3Results2ReliabilityBase,
    stade3Results3ReliabilityBase: stade3Results3ReliabilityBase,
    stade3Results4ReliabilityBase: stade3Results4ReliabilityBase,
    stade3Results5ReliabilityBase: stade3Results5ReliabilityBase,
    averageStade3Base:
      (stade3Results1ReliabilityBase +
        stade3Results2ReliabilityBase +
        stade3Results3ReliabilityBase +
        stade3Results4ReliabilityBase +
        stade3Results5ReliabilityBase) /
      5,

    stade4Results1ReliabilityBase: stade4Results1ReliabilityBase,
  };
};

/**
 * Function to compute phase1 series
 * @param ohlcSerie the base serie
 * @param weightParameterSpy the weight parameter
 * @param indexParameter the index to start
 * @returns trendSerie the trend series result
 */
const computePredictionTrendSeriePhase1 = (
  ohlcSerie,
  indexParameter,
  offsetValue,
  weightCoefficient,
  starterParams
) => {
  // Parameters
  const {
    consolidatedSpySeries,
    ohlcSpySerie,
    serieVariations,
    othersValuesSpySeries,
    g1SpySerie,
    h1SpySerie,
    h2SpySerie,
    ohlcDerivative,
    alphaPredictionSerie,
    range,
    serieCoefficient,
    derivatedValues,
  } = starterParams;

  // Generate phase 1
  // Feuil1 of TC Attached Serie Spy file matrix
  const stade1Results = generatePhase1Stade1(
    consolidatedSpySeries,
    indexParameter,
    ohlcSpySerie,
    offsetValue
  );

  const othersStadesResults = generatePhase1OthersStades(
    stade1Results,
    consolidatedSpySeries,
    indexParameter,
    ohlcSpySerie,
    offsetValue
  );

  // compute all reliabilities
  const reliabilities = computeAllReliability(
    stade1Results,
    othersStadesResults,
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    consolidatedSpySeries
  );

  const to = ohlcSerie[ohlcSerie.length - 1]
    ? ohlcSerie[ohlcSerie.length - 1].timestamp
    : null;

  if (to) {
    const trendSerie = [];

    for (let i = 0; i < ohlcSerie.length; ++i) {
      let ag = 0;
      let af = 0;
      let variationT = 0;
      let ae = 0;
      const randomRedDeduction3 = Math.random();
      // calculate red curve for deduction 3
      if (i >= indexParameter && i <= indexParameter + offsetValue) {
        const coefficientDeduction = 1194.73;
        ae = randomRedDeduction3 / (coefficientDeduction * 2);
        // variation de red
        const variationSerie =
          (othersStadesResults[2][0][i]?.reportIndicators -
            othersStadesResults[2][0][i - 1]?.reportIndicators) /
          othersStadesResults[2][0][i - 1]?.reportIndicators;

        variationT =
          (consolidatedSpySeries[i]?.close -
            consolidatedSpySeries[i - 1]?.close) /
          consolidatedSpySeries[i - 1]?.close;

        //=SI(J42>0;-D42+(AE42);-D42-(AE42))
        af =
          variationSerie > 0
            ? -othersValuesSpySeries[i].l + ae
            : -othersValuesSpySeries[i].l - ae;

        //=AG41*(1+AF42)
        ag =
          i === indexParameter
            ? ohlcSerie[i].close
            : trendSerie[i - 1].redDeduction3 * (1 + af);
      }

      trendSerie.push({
        // init values
        timestamp: ohlcSerie[i].timestamp,
        open: ohlcSerie[i].open,
        high: ohlcSerie[i].high,
        low: ohlcSerie[i].low,
        close: ohlcSerie[i].close,

        // Série Spy tab - O column
        SpySerieOpen: ohlcSpySerie[i].open,
        SpySerieHigh: ohlcSpySerie[i].high,
        SpySerieLow: ohlcSpySerie[i].low,
        SpySerieClose: ohlcSpySerie[i].close,

        // T column
        consolidatedSpySeriesOpen: consolidatedSpySeries[i].open,
        consolidatedSpySeriesHigh: consolidatedSpySeries[i].high,
        consolidatedSpySeriesLow: consolidatedSpySeries[i].low,
        consolidatedSpySeriesClose: consolidatedSpySeries[i].close,

        // R column
        serieVariations: serieVariations[i],

        // Others columns
        spyGColumn: othersValuesSpySeries[i - 1]?.g,
        random1: othersValuesSpySeries[i].random1,
        spyJColumn: othersValuesSpySeries[i].j,
        random2: othersValuesSpySeries[i].random2,
        spyLColumn: othersValuesSpySeries[i].l,
        spyMColumn: othersValuesSpySeries[i].m,
        spyNColumn: othersValuesSpySeries[i].n,
        spySColumn: othersValuesSpySeries[i].s,
        spyG1Column: g1SpySerie,
        spyH1Column: h1SpySerie,
        spyH2Column: h2SpySerie,

        // Historique H2 tab
        // F - G - H - I
        ohlcDerivativeRandom: ohlcDerivative[i].random,
        ohlcDerivativeOpen: ohlcDerivative[i].open,
        ohlcDerivativeHigh: ohlcDerivative[i].high,
        ohlcDerivativeLow: ohlcDerivative[i].low,
        ohlcDerivativeClose: ohlcDerivative[i].close,

        // EURUSD simple tab
        // AB
        alphaPredictionSerieValue:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]
            ?.value,

        // Y
        alphaPredictionMValue:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]?.m,
        // Z

        alphaPredictionBValue:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]?.b,

        // X
        alphaPredictionStochOscill:
          alphaPredictionSerie[i - (serieCoefficient + range) - range + 1]
            ?.stochasticOscillator,

        // TC Attache - Feuill1
        // A - B - C - D - E - F - G - H - H3 column
        derivatedValuesA: derivatedValues[i - indexParameter]?.a,
        derivatedValuesB: derivatedValues[i - indexParameter]?.b,
        derivatedValuesC: derivatedValues[i - indexParameter]?.c,
        derivatedValuesD: derivatedValues[i - indexParameter]?.d,
        derivatedValuesE: derivatedValues[i - indexParameter]?.e,
        derivatedValuesF: derivatedValues[i - indexParameter]?.f,
        derivatedValuesG: derivatedValues[i - indexParameter]?.g,
        derivatedValuesH: derivatedValues[i - indexParameter]?.h,
        h3: derivatedValues[0]?.h3,
        h3FirstValue: derivatedValues[0]?.h3FirstValue,
        h3SecondValue: derivatedValues[0]?.h3SecondValue,

        // Stade 1 - iteration 1
        stade1Results1Value1: stade1Results[0][i]?.value1,
        stade1Results1Value2: stade1Results[0][i]?.value2,
        stade1Results1Value3: stade1Results[0][i]?.value3,
        stade1Results1Value4: stade1Results[0][i]?.value4,
        stade1Results1Value5: stade1Results[0][i]?.value5,

        stade1Results1Random1: stade1Results[0][i]?.random1,
        stade1Results1Random2: stade1Results[0][i]?.random2,
        stade1Results1Random3: stade1Results[0][i]?.random3,
        stade1Results1Random4: stade1Results[0][i]?.random4,
        stade1Results1Random5: stade1Results[0][i]?.random5,

        stade1Results1H31: stade1Results[0][i]?.h31,
        stade1Results1H32: stade1Results[0][i]?.h32,
        stade1Results1H33: stade1Results[0][i]?.h33,
        stade1Results1H34: stade1Results[0][i]?.h34,
        stade1Results1H35: stade1Results[0][i]?.h35,

        stade1Results1AlphaH3Value11: stade1Results[0][i]?.alphaH3Value11,
        stade1Results1AlphaH3Value21: stade1Results[0][i]?.alphaH3Value21,
        stade1Results1AlphaH3Value12: stade1Results[0][i]?.alphaH3Value12,
        stade1Results1AlphaH3Value22: stade1Results[0][i]?.alphaH3Value22,
        stade1Results1AlphaH3Value13: stade1Results[0][i]?.alphaH3Value13,
        stade1Results1AlphaH3Value23: stade1Results[0][i]?.alphaH3Value23,
        stade1Results1AlphaH3Value14: stade1Results[0][i]?.alphaH3Value14,
        stade1Results1AlphaH3Value24: stade1Results[0][i]?.alphaH3Value24,
        stade1Results1AlphaH3Value15: stade1Results[0][i]?.alphaH3Value15,
        stade1Results1AlphaH3Value25: stade1Results[0][i]?.alphaH3Value25,

        stade1Results1AlphaValue1: stade1Results[0][i]?.alphaPredictionSerie1,

        stade1Results1Indicator1: stade1Results[0][i]?.indicator1,
        stade1Results1Indicator2: stade1Results[0][i]?.indicator2,
        stade1Results1Indicator3: stade1Results[0][i]?.indicator3,
        stade1Results1Indicator4: stade1Results[0][i]?.indicator4,
        stade1Results1Indicator5: stade1Results[0][i]?.indicator5,
        stade1Results1AverageOfValues: stade1Results[0][i]?.averageOfValues,
        stade1Results1ReportIndicators: stade1Results[0][i]?.reportIndicators,

        // Iteration 2
        stade1Results2Value1: stade1Results[1][i]?.value1,
        stade1Results2Value2: stade1Results[1][i]?.value2,
        stade1Results2Value3: stade1Results[1][i]?.value3,
        stade1Results2Value4: stade1Results[1][i]?.value4,
        stade1Results2Value5: stade1Results[1][i]?.value5,

        stade1Results2Random1: stade1Results[1][i]?.random1,
        stade1Results2Random2: stade1Results[1][i]?.random2,
        stade1Results2Random3: stade1Results[1][i]?.random3,
        stade1Results2Random4: stade1Results[1][i]?.random4,
        stade1Results2Random5: stade1Results[1][i]?.random5,

        stade1Results2H31: stade1Results[1][i]?.h31,
        stade1Results2H32: stade1Results[1][i]?.h32,
        stade1Results2H33: stade1Results[1][i]?.h33,
        stade1Results2H34: stade1Results[1][i]?.h34,
        stade1Results2H35: stade1Results[1][i]?.h35,

        stade1Results2AlphaH3Value11: stade1Results[1][i]?.alphaH3Value11,
        stade1Results2AlphaH3Value21: stade1Results[1][i]?.alphaH3Value21,
        stade1Results2AlphaH3Value12: stade1Results[1][i]?.alphaH3Value12,
        stade1Results2AlphaH3Value22: stade1Results[1][i]?.alphaH3Value22,
        stade1Results2AlphaH3Value13: stade1Results[1][i]?.alphaH3Value13,
        stade1Results2AlphaH3Value23: stade1Results[1][i]?.alphaH3Value23,
        stade1Results2AlphaH3Value14: stade1Results[1][i]?.alphaH3Value14,
        stade1Results2AlphaH3Value24: stade1Results[1][i]?.alphaH3Value24,
        stade1Results2AlphaH3Value15: stade1Results[1][i]?.alphaH3Value15,
        stade1Results2AlphaH3Value25: stade1Results[1][i]?.alphaH3Value25,

        stade1Results2Indicator1: stade1Results[1][i]?.indicator1,
        stade1Results2Indicator2: stade1Results[1][i]?.indicator2,
        stade1Results2Indicator3: stade1Results[1][i]?.indicator3,
        stade1Results2Indicator4: stade1Results[1][i]?.indicator4,
        stade1Results2Indicator5: stade1Results[1][i]?.indicator5,
        stade1Results2AverageOfValues: stade1Results[1][i]?.averageOfValues,
        stade1Results2ReportIndicators: stade1Results[1][i]?.reportIndicators,

        // Iteration 3
        stade1Results3Value1: stade1Results[2][i]?.value1,
        stade1Results3Value2: stade1Results[2][i]?.value2,
        stade1Results3Value3: stade1Results[2][i]?.value3,
        stade1Results3Value4: stade1Results[2][i]?.value4,
        stade1Results3Value5: stade1Results[2][i]?.value5,

        stade1Results3Random1: stade1Results[2][i]?.random1,
        stade1Results3Random2: stade1Results[2][i]?.random2,
        stade1Results3Random3: stade1Results[2][i]?.random3,
        stade1Results3Random4: stade1Results[2][i]?.random4,
        stade1Results3Random5: stade1Results[2][i]?.random5,

        stade1Results3H31: stade1Results[2][i]?.h31,
        stade1Results3H32: stade1Results[2][i]?.h32,
        stade1Results3H33: stade1Results[2][i]?.h33,
        stade1Results3H34: stade1Results[2][i]?.h34,
        stade1Results3H35: stade1Results[2][i]?.h35,

        stade1Results3AlphaH3Value11: stade1Results[2][i]?.alphaH3Value11,
        stade1Results3AlphaH3Value21: stade1Results[2][i]?.alphaH3Value21,
        stade1Results3AlphaH3Value12: stade1Results[2][i]?.alphaH3Value12,
        stade1Results3AlphaH3Value22: stade1Results[2][i]?.alphaH3Value22,
        stade1Results3AlphaH3Value13: stade1Results[2][i]?.alphaH3Value13,
        stade1Results3AlphaH3Value23: stade1Results[2][i]?.alphaH3Value23,
        stade1Results3AlphaH3Value14: stade1Results[2][i]?.alphaH3Value14,
        stade1Results3AlphaH3Value24: stade1Results[2][i]?.alphaH3Value24,
        stade1Results3AlphaH3Value15: stade1Results[2][i]?.alphaH3Value15,
        stade1Results3AlphaH3Value25: stade1Results[2][i]?.alphaH3Value25,

        stade1Results3Indicator1: stade1Results[2][i]?.indicator1,
        stade1Results3Indicator2: stade1Results[2][i]?.indicator2,
        stade1Results3Indicator3: stade1Results[2][i]?.indicator3,
        stade1Results3Indicator4: stade1Results[2][i]?.indicator4,
        stade1Results3Indicator5: stade1Results[2][i]?.indicator5,
        stade1Results3AverageOfValues: stade1Results[2][i]?.averageOfValues,
        stade1Results3ReportIndicators: stade1Results[2][i]?.reportIndicators,

        // Iteration 4
        stade1Results4Value1: stade1Results[3][i]?.value1,
        stade1Results4Value2: stade1Results[3][i]?.value2,
        stade1Results4Value3: stade1Results[3][i]?.value3,
        stade1Results4Value4: stade1Results[3][i]?.value4,
        stade1Results4Value5: stade1Results[3][i]?.value5,

        stade1Results4Random1: stade1Results[3][i]?.random1,
        stade1Results4Random2: stade1Results[3][i]?.random2,
        stade1Results4Random3: stade1Results[3][i]?.random3,
        stade1Results4Random4: stade1Results[3][i]?.random4,
        stade1Results4Random5: stade1Results[3][i]?.random5,

        stade1Results4H31: stade1Results[3][i]?.h31,
        stade1Results4H32: stade1Results[3][i]?.h32,
        stade1Results4H33: stade1Results[3][i]?.h33,
        stade1Results4H34: stade1Results[3][i]?.h34,
        stade1Results4H35: stade1Results[3][i]?.h35,

        stade1Results4AlphaH3Value11: stade1Results[3][i]?.alphaH3Value11,
        stade1Results4AlphaH3Value21: stade1Results[3][i]?.alphaH3Value21,
        stade1Results4AlphaH3Value12: stade1Results[3][i]?.alphaH3Value12,
        stade1Results4AlphaH3Value22: stade1Results[3][i]?.alphaH3Value22,
        stade1Results4AlphaH3Value13: stade1Results[3][i]?.alphaH3Value13,
        stade1Results4AlphaH3Value23: stade1Results[3][i]?.alphaH3Value23,
        stade1Results4AlphaH3Value14: stade1Results[3][i]?.alphaH3Value14,
        stade1Results4AlphaH3Value24: stade1Results[3][i]?.alphaH3Value24,
        stade1Results4AlphaH3Value15: stade1Results[3][i]?.alphaH3Value15,
        stade1Results4AlphaH3Value25: stade1Results[3][i]?.alphaH3Value25,

        stade1Results4Indicator1: stade1Results[3][i]?.indicator1,
        stade1Results4Indicator2: stade1Results[3][i]?.indicator2,
        stade1Results4Indicator3: stade1Results[3][i]?.indicator3,
        stade1Results4Indicator4: stade1Results[3][i]?.indicator4,
        stade1Results4Indicator5: stade1Results[3][i]?.indicator5,
        stade1Results4AverageOfValues: stade1Results[3][i]?.averageOfValues,
        stade1Results4ReportIndicators: stade1Results[3][i]?.reportIndicators,

        // Iteration 5
        stade1Results5Value1: stade1Results[4][i]?.value1,
        stade1Results5Value2: stade1Results[4][i]?.value2,
        stade1Results5Value3: stade1Results[4][i]?.value3,
        stade1Results5Value4: stade1Results[4][i]?.value4,
        stade1Results5Value5: stade1Results[4][i]?.value5,

        stade1Results5Random1: stade1Results[4][i]?.random1,
        stade1Results5Random2: stade1Results[4][i]?.random2,
        stade1Results5Random3: stade1Results[4][i]?.random3,
        stade1Results5Random4: stade1Results[4][i]?.random4,
        stade1Results5Random5: stade1Results[4][i]?.random5,

        stade1Results5H31: stade1Results[4][i]?.h31,
        stade1Results5H32: stade1Results[4][i]?.h32,
        stade1Results5H33: stade1Results[4][i]?.h33,
        stade1Results5H34: stade1Results[4][i]?.h34,
        stade1Results5H35: stade1Results[4][i]?.h35,

        stade1Results5AlphaH3Value11: stade1Results[4][i]?.alphaH3Value11,
        stade1Results5AlphaH3Value21: stade1Results[4][i]?.alphaH3Value21,
        stade1Results5AlphaH3Value12: stade1Results[4][i]?.alphaH3Value12,
        stade1Results5AlphaH3Value22: stade1Results[4][i]?.alphaH3Value22,
        stade1Results5AlphaH3Value13: stade1Results[4][i]?.alphaH3Value13,
        stade1Results5AlphaH3Value23: stade1Results[4][i]?.alphaH3Value23,
        stade1Results5AlphaH3Value14: stade1Results[4][i]?.alphaH3Value14,
        stade1Results5AlphaH3Value24: stade1Results[4][i]?.alphaH3Value24,
        stade1Results5AlphaH3Value15: stade1Results[4][i]?.alphaH3Value15,
        stade1Results5AlphaH3Value25: stade1Results[4][i]?.alphaH3Value25,

        stade1Results5Indicator1: stade1Results[4][i]?.indicator1,
        stade1Results5Indicator2: stade1Results[4][i]?.indicator2,
        stade1Results5Indicator3: stade1Results[4][i]?.indicator3,
        stade1Results5Indicator4: stade1Results[4][i]?.indicator4,
        stade1Results5Indicator5: stade1Results[4][i]?.indicator5,
        stade1Results5AverageOfValues: stade1Results[4][i]?.averageOfValues,
        stade1Results5ReportIndicators: stade1Results[4][i]?.reportIndicators,

        // Others stades - Stade 2 - Iteration 1
        stade2Results1Indicator1: othersStadesResults[0][0][i]?.indicator1,
        stade2Results1Indicator2: othersStadesResults[0][0][i]?.indicator2,
        stade2Results1Indicator3: othersStadesResults[0][0][i]?.indicator3,
        stade2Results1Indicator4: othersStadesResults[0][0][i]?.indicator4,
        stade2Results1Indicator5: othersStadesResults[0][0][i]?.indicator5,
        stade2Results1ReportIndicators:
          othersStadesResults[0][0][i]?.reportIndicators,
        stade2Results1DataIndex: othersStadesResults[0][0][i]?.dataIndex,

        // Iteration 2
        stade2Results2Indicator1: othersStadesResults[0][1][i]?.indicator1,
        stade2Results2Indicator2: othersStadesResults[0][1][i]?.indicator2,
        stade2Results2Indicator3: othersStadesResults[0][1][i]?.indicator3,
        stade2Results2Indicator4: othersStadesResults[0][1][i]?.indicator4,
        stade2Results2Indicator5: othersStadesResults[0][1][i]?.indicator5,
        stade2Results2ReportIndicators:
          othersStadesResults[0][1][i]?.reportIndicators,
        stade2Results2DataIndex: othersStadesResults[0][1][i]?.dataIndex,

        // Iteration 3
        stade2Results3Indicator1: othersStadesResults[0][2][i]?.indicator1,
        stade2Results3Indicator2: othersStadesResults[0][2][i]?.indicator2,
        stade2Results3Indicator3: othersStadesResults[0][2][i]?.indicator3,
        stade2Results3Indicator4: othersStadesResults[0][2][i]?.indicator4,
        stade2Results3Indicator5: othersStadesResults[0][2][i]?.indicator5,
        stade2Results3ReportIndicators:
          othersStadesResults[0][2][i]?.reportIndicators,
        stade2Results3DataIndex: othersStadesResults[0][2][i]?.dataIndex,

        // Iteration 4
        stade2Results4Indicator1: othersStadesResults[0][3][i]?.indicator1,
        stade2Results4Indicator2: othersStadesResults[0][3][i]?.indicator2,
        stade2Results4Indicator3: othersStadesResults[0][3][i]?.indicator3,
        stade2Results4Indicator4: othersStadesResults[0][3][i]?.indicator4,
        stade2Results4Indicator5: othersStadesResults[0][3][i]?.indicator5,
        stade2Results4ReportIndicators:
          othersStadesResults[0][3][i]?.reportIndicators,
        stade2Results4DataIndex: othersStadesResults[0][3][i]?.dataIndex,

        // Iteration 5
        stade2Results5Indicator1: othersStadesResults[0][4][i]?.indicator1,
        stade2Results5Indicator2: othersStadesResults[0][4][i]?.indicator2,
        stade2Results5Indicator3: othersStadesResults[0][4][i]?.indicator3,
        stade2Results5Indicator4: othersStadesResults[0][4][i]?.indicator4,
        stade2Results5Indicator5: othersStadesResults[0][4][i]?.indicator5,
        stade2Results5ReportIndicators:
          othersStadesResults[0][4][i]?.reportIndicators,
        stade2Results5DataIndex: othersStadesResults[0][4][i]?.dataIndex,

        // Stade 3 - Iteration 1
        stade3Results1Indicator1: othersStadesResults[1][0][i]?.indicator1,
        stade3Results1Indicator2: othersStadesResults[1][0][i]?.indicator2,
        stade3Results1Indicator3: othersStadesResults[1][0][i]?.indicator3,
        stade3Results1Indicator4: othersStadesResults[1][0][i]?.indicator4,
        stade3Results1Indicator5: othersStadesResults[1][0][i]?.indicator5,
        stade3Results1ReportIndicators:
          othersStadesResults[1][0][i]?.reportIndicators,
        stade3Results1DataIndex: othersStadesResults[1][0][i]?.dataIndex,

        // Iteration 2
        stade3Results2Indicator1: othersStadesResults[1][1][i]?.indicator1,
        stade3Results2Indicator2: othersStadesResults[1][1][i]?.indicator2,
        stade3Results2Indicator3: othersStadesResults[1][1][i]?.indicator3,
        stade3Results2Indicator4: othersStadesResults[1][1][i]?.indicator4,
        stade3Results2Indicator5: othersStadesResults[1][1][i]?.indicator5,
        stade3Results2ReportIndicators:
          othersStadesResults[1][1][i]?.reportIndicators,
        stade3Results2DataIndex: othersStadesResults[1][1][i]?.dataIndex,

        // Iteration 3
        stade3Results3Indicator1: othersStadesResults[1][2][i]?.indicator1,
        stade3Results3Indicator2: othersStadesResults[1][2][i]?.indicator2,
        stade3Results3Indicator3: othersStadesResults[1][2][i]?.indicator3,
        stade3Results3Indicator4: othersStadesResults[1][2][i]?.indicator4,
        stade3Results3Indicator5: othersStadesResults[1][2][i]?.indicator5,
        stade3Results3ReportIndicators:
          othersStadesResults[1][2][i]?.reportIndicators,
        stade3Results3DataIndex: othersStadesResults[1][2][i]?.dataIndex,

        // Iteration 4
        stade3Results4Indicator1: othersStadesResults[1][3][i]?.indicator1,
        stade3Results4Indicator2: othersStadesResults[1][3][i]?.indicator2,
        stade3Results4Indicator3: othersStadesResults[1][3][i]?.indicator3,
        stade3Results4Indicator4: othersStadesResults[1][3][i]?.indicator4,
        stade3Results4Indicator5: othersStadesResults[1][3][i]?.indicator5,
        stade3Results4ReportIndicators:
          othersStadesResults[1][3][i]?.reportIndicators,
        stade3Results4DataIndex: othersStadesResults[1][3][i]?.dataIndex,

        // Iteration 5
        stade3Results5Indicator1: othersStadesResults[1][4][i]?.indicator1,
        stade3Results5Indicator2: othersStadesResults[1][4][i]?.indicator2,
        stade3Results5Indicator3: othersStadesResults[1][4][i]?.indicator3,
        stade3Results5Indicator4: othersStadesResults[1][4][i]?.indicator4,
        stade3Results5Indicator5: othersStadesResults[1][4][i]?.indicator5,
        stade3Results5ReportIndicators:
          othersStadesResults[1][4][i]?.reportIndicators,
        stade3Results5DataIndex: othersStadesResults[1][4][i]?.dataIndex,

        // Stade 4 - Iteration 1
        stade4Results1Indicator1: othersStadesResults[2][0][i]?.indicator1,
        stade4Results1Indicator2: othersStadesResults[2][0][i]?.indicator2,
        stade4Results1Indicator3: othersStadesResults[2][0][i]?.indicator3,
        stade4Results1Indicator4: othersStadesResults[2][0][i]?.indicator4,
        stade4Results1Indicator5: othersStadesResults[2][0][i]?.indicator5,
        stade4Results1ReportIndicators:
          othersStadesResults[2][0][i]?.reportIndicators,
        stade4Results1DataIndex: othersStadesResults[2][0][i]?.dataIndex,
        index: i,
        redDeduction3: ag,
        randomRedDeduction3: randomRedDeduction3,
        variationT,
        af: af,
        ae: ae,
      });
    }
    const phase1Params = { stade1Results, othersStadesResults };

    return { trendSerie, phase1Params, statsPhase1: { reliabilities } };
  } else {
    return [];
  }
};

/**
 * Function called by the front
 * @param ohlcSerie the base serie
 * @param previsionStart the index to start
 * @param offset the offset of prediction
 * @param weightParameterSpy the weight parameter
 * @returns predictionTrendSerie the trend series result
 */
export function calculateTrendPhase1(
  ohlcSerie,
  indexParameter,
  offset,
  weightCoefficient,
  starterParams
) {
  const predictionTrendSerie = computePredictionTrendSeriePhase1(
    ohlcSerie,
    indexParameter,
    offset,
    weightCoefficient,
    starterParams
  );
  return predictionTrendSerie;
}
