/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { createContext, useContext, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import "twin.macro";
import tw from "twin.macro";
import { ChevronDown, ChevronUp } from "./Icons";
import { useId } from "./useId";

const FormGroupContext = createContext(null);
export const FormGroupProvider = ({ children }) => {
  const formGroupId = useId();
  return (
    <FormGroupContext.Provider value={{ formGroupId }}>
      {children}
    </FormGroupContext.Provider>
  );
};

export const useFormGroupContext = () => {
  return useContext(FormGroupContext);
};

export const useFormGroupId = () => {
  const context = useFormGroupContext();
  return context.formGroupId;
};

/**
 * Return the unique id associated to the current `FormGroup` or `InlineFormGroup`.
 * This value can be used to assign a `label` `htmlFor` prop or an `input` `id`.
 * @type {() => string}
 */
 export const useFormId = () => {
  const context = useContext(FormGroupContext);
  if (context === null) {
    throw new Error("`useFormId` should be wrapped withing a `FormGroup`");
  }
  return context.id;
};

export const FormGroup = (props) => {
  return (
    <FormGroupProvider>
      <div tw="relative" {...props} />
    </FormGroupProvider>
  );
};

export const LabelBase = styled("label")(
  tw`block text-sm font-medium text-gray-700`
);

/** @type {import("react").FC<import("react").LabelHTMLAttributes<HTMLLabelElement>>} */
export const Label = ({id, ...props}) => {
  // const formGroupId = useFormGroupId();
  return <LabelBase htmlFor={id} {...props} />;
};

/** @type {import("react").FC<import("react").LabelHTMLAttributes<HTMLLabelElement>>} */
export const FloatingLabel = ({id, ...props}) => {
  return (
    <Label
    htmlFor={id}
      tw="absolute -top-0.5 z-10 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      {...props}
    />
  );
};

export const InputBase = styled("input")(
  tw`shadow-sm pt-4 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md mt-1`
);

/** @type {import("react").FC<{ rules?: import("react-hook-form").RegisterOptions } & import("react").InputHTMLAttributes<HTMLInputElement>>} */
export const Input = ({ name, rules, ...props }) => {
  // const { register } = useFormContext();
  // const formGroupId = useFormGroupId();
  return <InputBase {...props} />;
};

/** @type {typeof Input} */
export const NumberInput = ({ rules, ...props }) => {
  return (
    <Input type="number" rules={{ valueAsNumber: true, ...rules }} {...props} />
  );
};

/**
 * Return the field name associated to the current `FormGroup` or `InlineFormGroup`.
 * This value can be used to retrieve the react hook form associated with the current field or
 * assign the field name attribute.
 * @type {() => string}
 */
 export const useFormName = () => {
  const context = useContext(FormGroupContext);
  if (context === null) {
    throw new Error("`useFormName` should be wrapped withing a `FormGroup`");
  }
  return context.name;
};


/**
 * Retrieve the react hook form validation error for the current field.
 */
 export const useFormError = () => {
  const { errors } = useFormContext();
  const name = useFormName();
  const error = get(errors, name);
  return error;
};


export const NumberInputCustomArrow = ({ rules, ...props }) => {

  const [old, setOld] = useState(null)

  return (
    <>
      <div
        className="numberInputCustomArrow"
        css={{
          "&": tw`flex flex-col select-none`,
        }}
      >
        <Input type="number" rules={{ valueAsNumber: true, ...rules }} {...props} />
        <div
          className="arrows"
          css={{
            "&": tw`hidden absolute top-0 right-0 flex-col mt-[11px] mr-[5px]`,
          }}
        >
          <ChevronUp
            css={{
              "&": tw`w-auto px-0.5 bg-gray-100 hover:bg-gray-300`,
            }}
            onClick={(e) => {
              if (!old) {
                setOld(new Date().getTime())
                if (props.upClickCallback) {
                  props.upClickCallback(e);
                }
              } else {
                const diff = new Date().getTime() - old;
                if (diff <= 300) {
                  // block
                } else {
                  if (props.upClickCallback) {
                    props.upClickCallback(e);
                  }
                }
                setOld(new Date().getTime())
              }
            }}
          />
          <ChevronDown
             css={{
              "&": tw`w-auto px-0.5 bg-gray-100 hover:bg-gray-300`,
            }}
            onClick={(e) => {
              if (!old) {
                setOld(new Date().getTime())
                if (props.downClickCallback) {
                  props.downClickCallback(e);
                }
              } else {
                const diff = new Date().getTime() - old;
                if (diff <= 300) {
                  // block
                } else {
                  if (props.downClickCallback) {
                    props.downClickCallback(e);
                  }
                }
                setOld(new Date().getTime())
              }
            }}
          />
        </div>
      </div>
    </>
  
  );
};

export const ColorInput = (props) => {
  return (
    <Input
      type="color"
      css={{
        "&": tw`overflow-hidden`,
        "&::-webkit-color-swatch-wrapper": tw`p-0`,
        "&::-webkit-color-swatch": tw`border-0`,
      }}
      {...props}
    />
  );
};

export const InlineFormGroup = (props) => {
  return <FormGroup tw="flex items-start space-y-0 space-x-3" {...props} />;
};


/**
 * Checkbox component.
 * @type {React.FC<React.InputHTMLAttributes<HTMLInputElement>>}
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <Label>Checkbox field</Label>
 * </InlineFormGroup>
 * @example
 * <InlineFormGroup>
 *   <Checkbox name="checkboxField" />
 *   <div>
 *     <Label>Checkbox field</Label>
 *     <CheckboxHelperText>Some helper text.</CheckboxHelperText>
 *   </div>
 * </InlineFormGroup>
 */
 export const Checkbox = ({ rules, name, ...props }) => {
  const { register } = useFormContext();
  const formGroupId = useFormGroupId();

  return (
    <div tw="flex items-center h-5">
      <input
        { ...register(name, rules)}
        id={formGroupId}
        name={name}
        type="checkbox"
        tw="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
      />
    </div>
  );
};
