import { saveAs } from "file-saver";
import { useMutation } from "react-query";
import excelTemplate from "./template-phase3.xlsm";

export const useExportExcelTemplatePhase3 = () => {
  return useMutation(async ({ phase3Data }) => {
    const { default: ExcelJS } = await import("exceljs");
    const resFile = await (await fetch(excelTemplate)).blob();
    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(resFile);

    const phase3 = workbook.getWorksheet(1);

    // template start at row 4
    const rowIndex = 4;
    const shiftSecondTable = 37;
    const shiftThirdTable = 70;

    phase3Data?.forEach((iterationData, idxIteration) => {
      const iterationOffset = idxIteration * 110;
      iterationData.trendSerie[0].phase3Results.forEach((el, idx) => {
        const date = el.timestamp
          ? new Date(el.timestamp * 1000).toISOString().split("T")[0]
          : "";
        phase3.getCell(`C${rowIndex + idx + iterationOffset}`).value = date;
        phase3.getCell(`D${rowIndex + idx + iterationOffset}`).value = el.base;
        phase3.getCell(`G${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].serieT1;
        phase3.getCell(`I${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].serieT2;
        phase3.getCell(`K${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].serieT3;
        phase3.getCell(`M${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].serieT4;
        phase3.getCell(`O${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].serieT5;

        phase3.getCell(`X${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].red1;
        phase3.getCell(`Y${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].red2;
        phase3.getCell(`Z${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].red3;
        phase3.getCell(`AA${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].red4;
        phase3.getCell(`AB${rowIndex + idx + iterationOffset}`).value =
          iterationData.exportData[idx].red5;

        phase3.getCell(`X${shiftSecondTable + idx + iterationOffset}`).value =
          iterationData.translations.serie1[idx].translation2;
        phase3.getCell(`Y${shiftSecondTable + idx + iterationOffset}`).value =
          iterationData.translations.serie2[idx].translation2;
        phase3.getCell(`Z${shiftSecondTable + idx + iterationOffset}`).value =
          iterationData.translations.serie3[idx].translation2;
        phase3.getCell(`AA${shiftSecondTable + idx + iterationOffset}`).value =
          iterationData.translations.serie4[idx].translation2;
        phase3.getCell(`AB${shiftSecondTable + idx + iterationOffset}`).value =
          iterationData.translations.serie5[idx].translation2;

        phase3.getCell(`X${shiftThirdTable + idx + iterationOffset}`).value =
          iterationData.translations.serie1[idx].translation3;
        phase3.getCell(`Y${shiftThirdTable + idx + iterationOffset}`).value =
          iterationData.translations.serie2[idx].translation3;
        phase3.getCell(`Z${shiftThirdTable + idx + iterationOffset}`).value =
          iterationData.translations.serie3[idx].translation3;
        phase3.getCell(`AA${shiftThirdTable + idx + iterationOffset}`).value =
          iterationData.translations.serie4[idx].translation3;
        phase3.getCell(`AB${shiftThirdTable + idx + iterationOffset}`).value =
          iterationData.translations.serie5[idx].translation3;
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    let fileName = "export-trend-copilot";
    saveAs(blob, `${fileName}.xlsx`);
  });
};
