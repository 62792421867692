import { createContext, useState } from "react";

export const SeriesCalculationsContext = createContext();

export const SeriesCalculationsProvider = ({ children }) => {
  const [starterParams, setStarterParams] = useState();

  const [previewData, setPreviewData] = useState();

  const [lastUpdated, setLastUpdated] = useState();

  const [phase1Calculations, setPhase1Calculations] = useState({
    phase1Data: undefined,
    phase1Params: undefined,
    phase1Stats: undefined
  });

  const [phase2IterationNumber, setPhase2IterationNumber] = useState(1);
  const [phase2Calculation, setPhase2Calculation] = useState();

  const [phase3IterationNumber, setPhase3IterationNumber] = useState(1);
  const [phase3Calculation, setPhase3Calculation] = useState();

  return (
    <SeriesCalculationsContext.Provider
      value={{
        starterParams,
        setStarterParams,
        previewData,
        setPreviewData,
        phase1Calculations,
        setPhase1Calculations,
        phase2IterationNumber,
        setPhase2IterationNumber,
        phase2Calculation,
        setPhase2Calculation,
        phase3IterationNumber,
        setPhase3IterationNumber,
        phase3Calculation,
        setPhase3Calculation,
        lastUpdated,
        setLastUpdated
      }}
    >
      {children}
    </SeriesCalculationsContext.Provider>
  );
};
