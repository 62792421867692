import { calculateTrendPhase1 } from "./computePhase1";
import { calculateTrendPhasePreview } from "./computePreview";

/**
 * Function to cumpute reliability between base serie and serie
 * @param valuesSerie the serie to evaluate reliability
 * @param othersValuesSpySeries the variation of T/base in s attribute
 * @param indexParameter the index parameter to start
 * @param offsetValue the offset value
 */
const computeReliability = (
  phase3Results,
  othersValuesSpySeries,
  indexParameter,
  offsetValue,
  typeRel
) => {
  // TODO specific paramters
  // const nbIterationAdded = 2;
  // const startIndexAdded = 6;

  let nbIterations = 0;
  let sumSimilarity = 0;
  let sumSimilarity2 = 0;

  for (let i = indexParameter; i < indexParameter + offsetValue; i++) {
    const variationUniv =
      (phase3Results[nbIterations]?.univSerie -
        phase3Results[nbIterations - 1]?.univSerie) /
      phase3Results[nbIterations - 1]?.univSerie;

    const variationSerieBase =
      typeRel === "base" ? othersValuesSpySeries[i]?.r : variationUniv;

    // calculate variation of valuesSerie
    const variationSerie =
      (phase3Results[nbIterations]?.plannedSerie -
        phase3Results[nbIterations - 1]?.plannedSerie) /
      phase3Results[nbIterations - 1]?.plannedSerie;

    // v = SI(OU(ET($F53>0;J53>0);ET($F53<0;J53<0));1;"")
    const similarity =
      (variationSerie > 0 && variationSerieBase > 0) ||
      (variationSerie < 0 && variationSerieBase < 0)
        ? 1
        : 0;
    sumSimilarity += similarity;

    const similarity2 =
      typeRel === "base"
        ? (variationUniv > 0 && variationSerieBase > 0) ||
          (variationUniv < 0 && variationSerieBase < 0)
          ? 1
          : 0
        : 0;
    sumSimilarity2 += similarity2;

    nbIterations++;
  }
  typeRel === "base" &&
    console.log("similarité univ/base : ", sumSimilarity2 / nbIterations);
  return sumSimilarity / nbIterations;
};

/**
 * Function to calculate all reliabilities of phase 1
 * @param stade1Results the phase 1 results
 * @param othersStadesResults the others results of phase 1
 * @param othersValuesSpySeries the variation values of spy serie consolidated
 * @param indexParameter the index parameter to start
 * @param offsetValue the offset value
 */
const computeAllReliability = (
  valuesIterations,
  phase3Results,
  indexParameter,
  offsetValue
) => {
  // Compule reliability with T serie (consolidated spy)
  // Iteration 1 of D with T
  // othersStadesResults[2][0]
  const phase3ReliabilityUniversal = computeReliability(
    phase3Results,
    valuesIterations[0].othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "universal"
  );

  // Compule reliability with base serie (ohlc serie)
  // Iteration 1 of D with base
  const phase3ReliabilityBase = computeReliability(
    phase3Results,
    valuesIterations[0].othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base"
  );

  return {
    phase3ReliabilityUniversal: phase3ReliabilityUniversal,
    phase3ReliabilityBase: phase3ReliabilityBase,
  };
};

/**
 * Function to compute calculation of phase 3
 * @param ohlcSerie the base serie
 * @param indexParamter the index parameter
 * @param offsetValue the offset value
 * @param coefficient the weight coefficient of the spy serie
 * @returns the results of the phase 3
 */
const computePredictionTrendSeriePhase3 = (
  ohlcSerie,
  indexParameter,
  offsetValue,
  weightCoefficient
) => {
  const results = [];

  // TODO specific parameter
  // h2 = (1- taux de corrélation entre Série de Base & Série à Prévoir)
  const p1503 = 0.7;
  const h2 = 1 - p1503;

  // We iterate 5 times generatePhase1() with new serie spy and we take the last report value (red value)
  const valuesIterations = [];
  for (let j = 0; j < 5; j++) {
    // Preview values
    const { starterParams } = calculateTrendPhasePreview(
      ohlcSerie,
      indexParameter,
      weightCoefficient,
      offsetValue
    );

    // Pahse 1 values
    const { phase1Params } = calculateTrendPhase1(
      ohlcSerie,
      indexParameter,
      offsetValue,
      weightCoefficient,
      starterParams
    );

    // red values
    const plannedSerie = phase1Params.othersStadesResults[2][0].map(
      (el, idx) => ({
        ...el,
        timestamp: ohlcSerie[idx].timestamp,
      })
    );

    valuesIterations.push({
      serieToPlan: starterParams.consolidatedSpySeries,
      plannedSerie,
      othersValuesSpySeries: starterParams.othersValuesSpySeries,
      starterParams: starterParams,
    });
  }

  const phase3Results = [];

  // r2 =SOMME(R4:R33)/30
  // s2 =SOMME(S4:S33)/30
  // t2 =SOMME(T4:T33)/30
  // u2 =SOMME(U4:U33)/30
  // v2 =SOMME(V4:V33)/30
  let r2 = 0;
  let s2 = 0;
  let t2 = 0;
  let u2 = 0;
  let v2 = 0;

  // aj2 =SOMME(AJ4:AJ33)/30
  // ak2 =SOMME(AK4:AK33)/30
  // al2 =SOMME(AL4:AL33)/30
  // am2 =SOMME(AM4:AM33)/30
  // an2 =SOMME(AN4:AN33)/30
  let aj2 = 0;
  let ak2 = 0;
  let al2 = 0;
  let am2 = 0;
  let an2 = 0;

  // ao2 =SOMME(AO4:AO33)/30
  // ap2 =SOMME(AP4:AP33)/30
  let ao2 = 0;
  let ap2 = 0;

  let countLine = 0;

  // TODO 1500 à 1530
  for (let i = indexParameter; i < indexParameter + offsetValue; ++i) {
    // D4 = Serie Spy E1504 - initial close value
    const d = ohlcSerie[i].close;

    // E5 = SI(D5>D4;1;-1)
    const d1 = ohlcSerie[i - 1]?.close;
    const e = d > d1 ? 1 : -1;

    // col G, I, K, M, O => Serie Spy T
    const g = valuesIterations[0].serieToPlan[i].close;
    const iCol = valuesIterations[1].serieToPlan[i].close;
    const k = valuesIterations[2].serieToPlan[i].close;
    const m = valuesIterations[3].serieToPlan[i].close;
    const o = valuesIterations[4].serieToPlan[i].close;

    // calculate h, j, l, n, p
    // h==SI(G9>G8;1;-1)
    // TODO : check i parameter
    const h = g > phase3Results[countLine - 1]?.g ? 1 : -1;
    const j = iCol > phase3Results[countLine - 1]?.i ? 1 : -1;
    const l = k > phase3Results[countLine - 1]?.k ? 1 : -1;
    const n = m > phase3Results[countLine - 1]?.m ? 1 : -1;
    const p = o > phase3Results[countLine - 1]?.o ? 1 : -1;

    // q =SI(SOMME(H13;J13;L13;N13;P13)>0;1;-1)
    const q = h + j + l + n + p > 0 ? 1 : -1;

    // Univesal serie
    // =SI(SOMME(h:p)>0;univSerie+D6;univSerie-D6)
    // Take the variation of the base
    // Serie Spy Tab
    const averageT = (g + iCol + k + m + o) / 5;
    const univSerie = averageT;

    // r =SI(H29=$E29;1;"")
    // s =SI(J29=$E29;1;"")
    // t =SI(L29=$E29;1;"")
    // u =SI(N29=$E29;1;"")
    // v =SI(P29=$E29;1;"")
    const r = e === h ? 1 : 0;
    const s = e === j ? 1 : 0;
    const t = e === l ? 1 : 0;
    const u = e === n ? 1 : 0;
    const v = e === p ? 1 : 0;

    r2 += r;
    s2 += s;
    t2 += t;
    u2 += u;
    v2 += v;

    // col X, Y, Z, AA, AB => EW Feuill1 TC Attache Serie Spy
    // const EW = othersPhasesResults[2][0][i].reportIndicators
    const x = valuesIterations[0].plannedSerie[i]?.reportIndicators;
    const y = valuesIterations[1].plannedSerie[i]?.reportIndicators;
    const z = valuesIterations[2].plannedSerie[i]?.reportIndicators;
    const aa = valuesIterations[3].plannedSerie[i]?.reportIndicators;
    const ab = valuesIterations[4].plannedSerie[i]?.reportIndicators;

    // ac =SI(X29>X28;1;-1)
    const ac =
      x > valuesIterations[0].plannedSerie[i - 1]?.reportIndicators ? 1 : -1;
    const ad =
      y > valuesIterations[1].plannedSerie[i - 1]?.reportIndicators ? 1 : -1;
    const ae =
      z > valuesIterations[2].plannedSerie[i - 1]?.reportIndicators ? 1 : -1;
    const af =
      aa > valuesIterations[3].plannedSerie[i - 1]?.reportIndicators ? 1 : -1;
    const ag =
      ab > valuesIterations[4].plannedSerie[i - 1]?.reportIndicators ? 1 : -1;

    // ai =SI(SOMME(AC15:AG15)>0;1;-1)
    const ai = ac + ad + ae + af + ag > 0 ? 1 : -1;
    const averageRedSeries = (x + y + z + aa + ab) / 5;

    // =SI(AI9>0;AH8+ABS(X9-X8);AH8-ABS(X9-X8))
    const plannedSerie2 =
      i === indexParameter
        ? univSerie
        : ai > 0
        ? averageRedSeries + Math.abs(x - phase3Results[countLine - 1]?.x)
        : averageRedSeries - Math.abs(x - phase3Results[countLine - 1]?.x);

    // calculate serie planned
    // =SI(SOMME(AC6:AG6)>0;AI5+D6;AI5-D6)
    // Take the variation of the base
    const plannedSerie = plannedSerie2;

    // aj =SI($AI31=H31;1;"")
    // ak =SI($AI31=J31;1;"")
    // al =SI($AI31=L31;1;"")
    // am =SI($AI31=N31;1;"")
    // an =SI($AI31=P31;1;"")
    const aj = ai === h ? 1 : 0;
    const ak = ai === j ? 1 : 0;
    const al = ai === l ? 1 : 0;
    const am = ai === n ? 1 : 0;
    const an = ai === p ? 1 : 0;

    aj2 += aj;
    ak2 += ak;
    al2 += al;
    am2 += am;
    an2 += an;

    // ao =SI(AI10=Q10;1;"")
    // ap =SI(AI10=E10;1;"")
    const ao = ai === q ? 1 : 0;
    const ap = ai === e ? 1 : 0;

    ao2 += ao;
    ap2 += ap;

    phase3Results.push({
      d: d,
      e: e,
      g: g,
      h: h,
      i: iCol,
      j: j,
      k: k,
      l: l,
      m: m,
      n: n,
      o: o,
      p: p,
      q: q,
      r: r,
      s: s,
      t: t,
      u: u,
      v: v,
      x: x,
      y: y,
      z: z,
      aa: aa,
      ab: ab,
      ac: ac,
      ad: ad,
      ae: ae,
      af: af,
      ag: ag,
      ai: ai,
      aj: aj,
      ak: ak,
      al: al,
      am: am,
      an: an,
      ao: ao,
      ap: ap,
      univSerie: univSerie,
      plannedSerie: plannedSerie,
      timestamp: ohlcSerie[i]?.timestamp,
      base: ohlcSerie[i]?.close,
      t1: valuesIterations[0].serieToPlan[i].close,
      t2: valuesIterations[1].serieToPlan[i].close,
      t3: valuesIterations[2].serieToPlan[i].close,
      t4: valuesIterations[3].serieToPlan[i].close,
      t5: valuesIterations[4].serieToPlan[i].close,
      red1: valuesIterations[0].plannedSerie[i]?.reportIndicators,
      red2: valuesIterations[1].plannedSerie[i]?.reportIndicators,
      red3: valuesIterations[2].plannedSerie[i]?.reportIndicators,
      red4: valuesIterations[3].plannedSerie[i]?.reportIndicators,
      red5: valuesIterations[4].plannedSerie[i]?.reportIndicators,
      spyLColumnSerie1: valuesIterations[0].othersValuesSpySeries[i]?.l,
      spyLColumnSerie2: valuesIterations[1].othersValuesSpySeries[i]?.l,
      spyLColumnSerie3: valuesIterations[2].othersValuesSpySeries[i]?.l,
      spyLColumnSerie4: valuesIterations[3].othersValuesSpySeries[i]?.l,
      spyLColumnSerie5: valuesIterations[4].othersValuesSpySeries[i]?.l
    });

    countLine++;
  }

  r2 /= countLine;
  s2 /= countLine;
  t2 /= countLine;
  u2 /= countLine;
  v2 /= countLine;

  aj2 /= countLine;
  ak2 /= countLine;
  al2 /= countLine;
  am2 /= countLine;
  an2 /= countLine;

  ao2 /= countLine;
  ap2 /= countLine;

  // p2 =MOYENNE(R2:V2)
  const p2 = (r2 + s2 + t2 + u2 + v2) / 5;

  // ai2 =MOYENNE(AJ2:AN2)
  const ai2 = (aj2 + ak2 + al2 + am2 + an2) / 5;

  console.log("r2 s2 t2 u2 v2", r2, s2, t2, u2, v2);
  console.log("p2 ai2 ao2 ap2", p2, ai2, ao2, ap2);

  // calculate reliabilities of red values
  const reliabilities = computeAllReliability(
    valuesIterations,
    phase3Results,
    indexParameter,
    offsetValue
  );

  results.push({
    h2: h2,
    r2: r2,
    s2: s2,
    t2: t2,
    u2: u2,
    v2: v2,
    aj2: aj2,
    ak2: ak2,
    al2: al2,
    am2: am2,
    an2: an2,
    ai2: ai2,
    ao2: ao2,
    ap2: ap2,
    p2: p2,
    phase3Results: phase3Results,
    reliabilities: reliabilities,
    plannedSerie1: valuesIterations[0].plannedSerie,
    plannedSerie2: valuesIterations[1].plannedSerie,
    plannedSerie3: valuesIterations[2].plannedSerie,
    plannedSerie4: valuesIterations[3].plannedSerie,
    plannedSerie5: valuesIterations[4].plannedSerie,
    serieT1: valuesIterations[0].serieToPlan,
    serieT2: valuesIterations[1].serieToPlan,
    serieT3: valuesIterations[2].serieToPlan,
    serieT4: valuesIterations[3].serieToPlan,
    serieT5: valuesIterations[4].serieToPlan,
    otherSpyData1: valuesIterations[0].othersValuesSpySeries,
    otherSpyData2: valuesIterations[1].othersValuesSpySeries,
    otherSpyData3: valuesIterations[2].othersValuesSpySeries,
    otherSpyData4: valuesIterations[3].othersValuesSpySeries,
    otherSpyData5: valuesIterations[4].othersValuesSpySeries,
    base: ohlcSerie,
  });

  // iterate 10 times and calculate average of ai2, ao2 and ap2
  return {
    trendSerie: results,
  };
};

/**
 * Function to export data to an array for Excel
 * @param ohlcSerie the base serie
 * @param indexParamter the index parameter
 * @param offsetValue the offset value
 * @param predictionTrendSerie the result of phase 3
 * @returns the data array
 */
const exportDataForExcel = (
  ohlcSerie,
  indexParameter,
  offsetValue,
  predictionTrendSerie
) => {
  const exportData = [];
  let countLine = 0;

  for (let i = indexParameter; i < indexParameter + offsetValue; ++i) {
    exportData.push({
      // init values
      timestamp: ohlcSerie[i].timestamp,
      close: ohlcSerie[i].close,
      baseIndicator:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].e,

      h2: predictionTrendSerie.trendSerie[0].h2,

      // series T
      serieT1: predictionTrendSerie.trendSerie[0].serieT1[i].close,
      serieT1Indicator:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].h,
      serieT2: predictionTrendSerie.trendSerie[0].serieT2[i].close,
      serieT2Indicator:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].j,
      serieT3: predictionTrendSerie.trendSerie[0].serieT3[i].close,
      serieT3Indicator:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].l,
      serieT4: predictionTrendSerie.trendSerie[0].serieT4[i].close,
      serieT4Indicator:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].n,
      serieT5: predictionTrendSerie.trendSerie[0].serieT5[i].close,
      serieT5Indicator:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].p,

      univSerie:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine].univSerie,

      p2: predictionTrendSerie.trendSerie[0].p2,

      q: predictionTrendSerie.trendSerie[0].phase3Results[countLine].q,
      r: predictionTrendSerie.trendSerie[0].phase3Results[countLine].r,
      s: predictionTrendSerie.trendSerie[0].phase3Results[countLine].s,
      t: predictionTrendSerie.trendSerie[0].phase3Results[countLine].t,
      u: predictionTrendSerie.trendSerie[0].phase3Results[countLine].u,
      v: predictionTrendSerie.trendSerie[0].phase3Results[countLine].v,

      // red series
      red1: predictionTrendSerie.trendSerie[0].phase3Results[countLine].red1,
      red2: predictionTrendSerie.trendSerie[0].phase3Results[countLine].red2,
      red3: predictionTrendSerie.trendSerie[0].phase3Results[countLine].red3,
      red4: predictionTrendSerie.trendSerie[0].phase3Results[countLine].red4,
      red5: predictionTrendSerie.trendSerie[0].phase3Results[countLine].red5,

      ac: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ac,
      ad: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ad,
      ae: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ae,
      af: predictionTrendSerie.trendSerie[0].phase3Results[countLine].af,
      ag: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ag,

      ai: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ai,

      plannedSerie:
        predictionTrendSerie.trendSerie[0].phase3Results[countLine]
          .plannedSerie,

      aj: predictionTrendSerie.trendSerie[0].phase3Results[countLine].aj,
      ak: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ak,
      al: predictionTrendSerie.trendSerie[0].phase3Results[countLine].al,
      am: predictionTrendSerie.trendSerie[0].phase3Results[countLine].am,
      an: predictionTrendSerie.trendSerie[0].phase3Results[countLine].an,
      ao: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ao,
      ap: predictionTrendSerie.trendSerie[0].phase3Results[countLine].ap,

      // results percents
      t2: predictionTrendSerie.trendSerie[0].t2,
      u2: predictionTrendSerie.trendSerie[0].u2,
      v2: predictionTrendSerie.trendSerie[0].v2,

      ai2: predictionTrendSerie.trendSerie[0].ai2,
      aj2: predictionTrendSerie.trendSerie[0].aj2,
      ak2: predictionTrendSerie.trendSerie[0].ak2,
      al2: predictionTrendSerie.trendSerie[0].al2,
      am2: predictionTrendSerie.trendSerie[0].am2,
      an2: predictionTrendSerie.trendSerie[0].an2,
      ao2: predictionTrendSerie.trendSerie[0].ao2,
      ap2: predictionTrendSerie.trendSerie[0].ap2,

      index: i,
    });
    countLine++;
  }
  return exportData;
};

/**
 * Function to calculate a translation of a serie
 * @param ohlcSerie the ohlc serie
 * @param serieT the spy serie
 * @param serieRed the red serie
 * @param indexParameter the index parameter
 * @param offsetValue the offset value
 * @returns
 */
const computeTranslation = (
  ohlcSerie,
  serieT,
  serieRed,
  spyData,
  indexParameter,
  offsetValue
) => {
  const resultsTranslation = [];
  let countLines = 0;
  for (let i = indexParameter; i < indexParameter + offsetValue; ++i) {
    // 1st red :
    // translation 2
    // AB = variation of red - variation of spy
    const variationOfSeriePlanned =
      (serieRed[i].reportIndicators - serieRed[i - 1].reportIndicators) /
      serieRed[i - 1].reportIndicators;

    //AC41*(1+AB42)
    const translation2 =
      i === indexParameter
        ? ohlcSerie[i].close
        : resultsTranslation[countLines - 1].translation2 *
          (1 + (variationOfSeriePlanned - spyData[i].l));

    // translation 3
    const randomRedDeduction3 = Math.random();
    const coefficientDeduction = 1194.73;
    const ae = randomRedDeduction3 / (coefficientDeduction * 2);

    //=SI(J42>0;-D42+(AE42);-D42-(AE42))
    const af =
      variationOfSeriePlanned > 0
        ? -spyData[i].l + ae
        : -spyData[i].l - ae;

    //=AG41*(1+AF42)
    const translation3 =
      i === indexParameter
        ? ohlcSerie[countLines].close
        : resultsTranslation[countLines - 1].translation3 * (1 + af);

    resultsTranslation.push({
      translation2: translation2,
      translation3: translation3,
    });

    countLines++;
  }
  return resultsTranslation;
};

/**
 * Function to calculate translations
 * @param ohlcSerie 
 * @param predictionTrendSerie 
 * @param indexParameter 
 * @param offsetValue 
 * @returns 
 */
const computeTranslations = (
  ohlcSerie,
  predictionTrendSerie,
  indexParameter,
  offsetValue
) => {
  return {
    serie1: computeTranslation(
      ohlcSerie,
      predictionTrendSerie.trendSerie[0].serieT1,
      predictionTrendSerie.trendSerie[0].plannedSerie1,
      predictionTrendSerie.trendSerie[0].otherSpyData1,
      indexParameter,
      offsetValue
    ),
    serie2: computeTranslation(
      ohlcSerie,
      predictionTrendSerie.trendSerie[0].serieT2,
      predictionTrendSerie.trendSerie[0].plannedSerie2,
      predictionTrendSerie.trendSerie[0].otherSpyData2,
      indexParameter,
      offsetValue
    ),
    serie3: computeTranslation(
      ohlcSerie,
      predictionTrendSerie.trendSerie[0].serieT3,
      predictionTrendSerie.trendSerie[0].plannedSerie3,
      predictionTrendSerie.trendSerie[0].otherSpyData3,
      indexParameter,
      offsetValue
    ),
    serie4: computeTranslation(
      ohlcSerie,
      predictionTrendSerie.trendSerie[0].serieT4,
      predictionTrendSerie.trendSerie[0].plannedSerie4,
      predictionTrendSerie.trendSerie[0].otherSpyData4,
      indexParameter,
      offsetValue
    ),
    serie5: computeTranslation(
      ohlcSerie,
      predictionTrendSerie.trendSerie[0].serieT5,
      predictionTrendSerie.trendSerie[0].plannedSerie5,
      predictionTrendSerie.trendSerie[0].otherSpyData5,
      indexParameter,
      offsetValue
    )
  }
};

/**
 * Function called by the front
 * @param ohlcSerie the base serie
 * @param indexParameter the index parameter
 * @param offsetValue the offset value
 * @param coefficient the weight coefficient of the spy serie
 * @param iterationNumber the number of iterations
 * @returns predictionTrendSerie the trend series result
 */
export function calculateTrendPhase3(
  ohlcSerie,
  indexParameter,
  offsetValue,
  coefficient
) {
  const predictionTrendSerie = computePredictionTrendSeriePhase3(
    ohlcSerie,
    indexParameter,
    offsetValue,
    coefficient
  );

  const translations = computeTranslations(
    ohlcSerie,
    predictionTrendSerie,
    indexParameter,
    offsetValue
  );
  predictionTrendSerie.translations = translations;

  const exportData = exportDataForExcel(
    ohlcSerie,
    indexParameter,
    offsetValue,
    predictionTrendSerie
  );

  predictionTrendSerie.exportData = exportData;

  return predictionTrendSerie;
}
