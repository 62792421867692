/** @jsxImportSource @emotion/react */

import { useContext, useEffect, useState } from "react";
import "twin.macro";
import { Spinner } from "../shared/Spinner";
import { Tabs } from "../shared/Tabs";
import { filterZeroValues } from "../shared/dataFilter";
import { historicalSeries } from "../trading-series/HistoricalSeries";
import { TrendConfigurationContext } from "../trend-config/TrendConfigurationProvider";
import { SeriesCalculationsContext } from "../trending-series-calculations/SeriesCalculationsProvider";
import { calculateTrendPhase1 } from "../trending-series-calculations/computePhase1";
import { calculateTrendPhasePreview } from "../trending-series-calculations/computePreview";
import { ChartManagerContext } from "./ChartManagerProvider";
import Preview from "./Preview";
import Results from "./Results";

/**
 * Function to get the index of the date select according to the data serie
 * @param previsionStart the date to start calculation
 * @param historicalSerie the base serie
 * @returns the index parameter
 */
export const getIndexByDate = (previsionStart, historicalSerie) => {
  for (let i = 0; i < historicalSerie.length; ++i) {
    if (Date.parse(previsionStart) <= historicalSerie[i].timestamp * 1000) {
      return i;
    }
  }
  return historicalSerie.length;
};

/**
 * Function to get the offset value of the offset select param
 * @param offset the offset date
 * @returns the offset value
 */
export const getOffsetBySelectOffsetValue = (offset) => {
  return offset.value === "T + 30" ? 30 : 35;
};

/** @type {import("react").FC} */
const Dashboard = () => {
  const [tabs, setTabs] = useState();

  const { trendConfig, configForCalculations } = useContext(
    TrendConfigurationContext
  );
  const { historicalSerie, coefficient } = trendConfig;
  const {
    setLastUpdated,
    setStarterParams,
    setPreviewData,
    setPhase1Calculations,
    setPhase2Calculation,
    setPhase3Calculation,
  } = useContext(SeriesCalculationsContext);

  const { resetChartManager } = useContext(ChartManagerContext);

  const executeCalculations = () => {
    // Preview values
    const { trendSerie: trendSeriePreview, starterParams } =
      calculateTrendPhasePreview(
        historicalSerie.data ?? historicalSeries[historicalSerie.id].data,
        configForCalculations.indexParamter,
        coefficient,
        configForCalculations.offsetValue
      );
    setStarterParams(starterParams);
    resetChartManager();

    const previewData = {
      seriesName: JSON.stringify({
        label: historicalSerie.label,
        a: Math.random(),
      }),
      tradingSerie: trendSeriePreview.map((el) => filterZeroValues(el)),
    };

    // Phase1 values
    const {
      trendSerie: trendSeriePhase1,
      phase1Params,
      statsPhase1,
    } = calculateTrendPhase1(
      historicalSerie.data ?? historicalSeries[historicalSerie.id].data,
      configForCalculations.indexParamter,
      configForCalculations.offsetValue,
      coefficient,
      starterParams
    );
    const phase1Data = {
      seriesName: JSON.stringify({
        label: historicalSerie.label,
        a: Math.random(),
      }),
      tradingSerie: trendSeriePhase1.map((el) => filterZeroValues(el)),
    };

    setPreviewData(previewData);
    setPhase1Calculations([
      {
        phase1Data,
        phase1Params,
        phase1Stats: statsPhase1,
      },
    ]);
    setPhase2Calculation(undefined);
    setPhase3Calculation(undefined);

    setTabs([
      {
        label: "Preview",
        content: <Preview previewData={previewData} />,
      },
      {
        label: "Results",
        content: <Results />,
      },
      {
        label: "Interpretation",
        content: <Results isInterpretation={true} />,
      },
    ]);

    setLastUpdated(Date.now());
  };

  useEffect(() => {
    executeCalculations();
  }, []);

  useEffect(() => {
    executeCalculations();
  }, [trendConfig]);

  return (
    <div tw="flex flex-col flex-1 h-full px-4 2xl:px-8 py-4">
      {tabs ? (
        <Tabs tabs={tabs} />
      ) : (
        <div tw="h-96 w-full flex items-center justify-center space-x-2">
          <Spinner tw="w-6 h-6" />
          <span tw="text-gray-500">Processing...</span>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
