// @ts-nocheck
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import {
  Annotate,
  Chart,
  LabelAnnotation,
  LineSeries,
  MouseCoordinateX,
  MouseCoordinateY,
  XAxis,
  YAxis,
} from "react-financial-charts";
import { theme } from "twin.macro";
import { formatPercentage } from "../shared/formatPercentage";

const strokeDasharray = ["Solid", "ShortDot", "Solid", "Solid", "ShortDot"];

const positionAnnotation = (chartExtremes, value) => {
  if (value >= chartExtremes.max - 0.005) {
    return value - 0.004;
  } else if (value <= chartExtremes.min + 0.005) {
    return value + 0.001;
  } else {
    return value;
  }
};

const annotationProps = (color, chartExtremes, text, yValue) => {
  return {
    fontSize: 16,
    fill: color,
    opacity: 1,
    text: `${text}%`,
    rotate: -20,
    y: ({ yScale, datum }) => {
      return yScale(positionAnnotation(chartExtremes, yValue));
    },
    tooltip: (d) => timeFormat("%B")(d.date),
  };
};

const getStrokeDasharray = (index) => {
  return strokeDasharray[index];
};

/**
 * @typedef {{
 * id: string | number;
 * yExtents: number[] | ((data: any) => number) | ((data: any) => number[]);
 * data: { date: Date, open: number, high: number, low: number, close: number }[];
 * origin: number[] | ((width: number, height: number) => number[]);
 * height: number;
 * displayDateXAxis?: boolean;
 * extraLineToDisplay: {}
 * chartExtremes: { min: number, max: number}
 * statsToDisplay: {}
 * }} TradingOHLCSerieChartProps
 */
/** @type {import("react").FC<TradingOHLCSerieChartProps>} */
export const Phase2ComputationCharts = ({
  id,
  yExtents,
  data,
  origin,
  height,
  displayDateXAxis = false,
  lineToDisplay,
  chartExtremes,
  stats,
  statsToDisplay = {},
}) => {
  const pricesDisplayFormat = format(".4f");
  const timeDisplayFormat = timeFormat("%d %b %Y %H:%M");
  const valueDisplayFormat = format(".4f");

  return (
    <Chart id={id} origin={origin} height={height} yExtents={yExtents}>
      {displayDateXAxis ? (
        <XAxis
          showGridLines
          tickFormat={(index) => {
            return typeof index === "number"
              ? timeDisplayFormat(data[index].date)
              : timeDisplayFormat(index);
          }}
          tickStrokeStyle={theme`colors.gray.300`}
          strokeStyle={theme`colors.gray.300`}
          gridLinesStrokeStyle={theme`colors.gray.200`}
        />
      ) : (
        <XAxis
          showGridLines
          showTickLabel={false}
          showTicks={false}
          axisAt="bottom"
          strokeStyle={theme`colors.gray.300`}
          gridLinesStrokeStyle={theme`colors.gray.200`}
        />
      )}
      <YAxis
        showGridLines
        tickFormat={pricesDisplayFormat}
        tickStrokeStyle={theme`colors.gray.300`}
        strokeStyle={theme`colors.gray.300`}
        gridLinesStrokeStyle={theme`colors.gray.200`}
        zoomEnabled={true}
      />

      {lineToDisplay.map((line) => {
        return line.lines.map((line) => (
          <>
            <LineSeries
              yAccessor={(data) => data[line.field]}
              strokeStyle={line.color}
              strokeWidth={2}
              // strokeDasharray={getStrokeDasharray(index)}
            />

            {stats[statsToDisplay[line.color]] && (
              <Annotate
                with={LabelAnnotation}
                when={(d) =>
                  d.date.getTime() === data[data.length - 1].date.getTime()
                }
                usingProps={annotationProps(
                  line.color,
                  chartExtremes,
                  formatPercentage(stats[statsToDisplay[line.color]]),
                  data[data.length - 1][line.field]
                )}
              />
            )}
          </>
        ));
      })}

      <MouseCoordinateX displayFormat={timeDisplayFormat} />
      <MouseCoordinateY displayFormat={pricesDisplayFormat} rectWidth={60} />
    </Chart>
  );
};
