/** @jsxImportSource @emotion/react */
import { useController, useFormContext } from "react-hook-form";
import { useFormGroupId } from "../shared/Form";
import { Select } from "../shared/Select";
import tw from "twin.macro";

const getOptionValue = (option) => option.id;

/** @type {import("react").FC<import("react-hook-form").UseControllerProps & import("react").ComponentProps<typeof Select>>} */
export const CustomSelect = ({
  name,
  control,
  options,
  rules,
  defaultValue,
  ...props
}) => {

  const getClassNamePrefix = () => {
    return `${name}-select`;
  };

  const getSubClassNamePrefix = () => {
    return `& .${name}-select__menu`;
  };

  return (
    <Select
      classNamePrefix={getClassNamePrefix()}
      options={options}
      getOptionValue={getOptionValue}
      {...props}
      css={{
        [getSubClassNamePrefix()]: tw`!z-50`,
      }}
    />
  );
};
