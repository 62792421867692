import { saveAs } from "file-saver";
import { useMutation } from "react-query";

export const seriesPhase1 = [
  {
    name: "Base values",
    keys: [
      // init values
      { label: "Date", key: "date" },
      { label: "Open", key: "open" },
      { label: "High", key: "high" },
      { label: "Low", key: "low" },
      { label: "Close", key: "close" },

      // Spy serie tab
      { label: "Serie spy tab - G", key: "spyGColumn" },
      { label: "Serie spy tab - G1", key: "spyG1Column" },
      { label: "Serie spy tab - H1", key: "spyH1Column" },
      { label: "Serie spy tab - H2", key: "spyH2Column" },
      { label: "Serie spy tab - Random1 (I)", key: "random1" },
      { label: "Serie spy tab - J column (J)", key: "spyJColumn" },
      { label: "Serie spy tab - random2 (K)", key: "random2" },
      { label: "Serie spy tab - L column (L)", key: "spyLColumn" },
      { label: "Serie spy tab - M column (M)", key: "spyMColumn" },
      { label: "Serie spy tab - N column (N)", key: "spyNColumn" },
      { label: "Serie spy tab - open (O)", key: "SpySerieOpen" },
      { label: "Serie spy tab - high (O)", key: "SpySerieHigh" },
      { label: "Serie spy tab - low (O)", key: "SpySerieLow" },
      { label: "Serie spy tab - close (O)", key: "SpySerieClose" },
      { label: "Serie spy tab - serie variation (R)", key: "serieVariations" },
      { label: "Serie spy tab - S column (S)", key: "spySColumn" },
      {
        label: "Serie spy tab - consolidated open (T)",
        key: "consolidatedSpySeriesOpen",
      },
      {
        label: "Serie spy tab - consolidated high (T)",
        key: "consolidatedSpySeriesHigh",
      },
      {
        label: "Serie spy tab - consolidated low (T)",
        key: "consolidatedSpySeriesLow",
      },
      {
        label: "Serie spy tab - consolidated close (T)",
        key: "consolidatedSpySeriesClose",
      },

      // Historique H2 tab
      {
        label: "Historique H2 tab - Derivative random (F)",
        key: "ohlcDerivativeRandom",
      },
      {
        label: "Historique H2 tab - Derivative open (G)",
        key: "ohlcDerivativeOpen",
      },
      {
        label: "Historique H2 tab - Derivative high (H)",
        key: "ohlcDerivativeHigh",
      },
      {
        label: "Historique H2 tab - Derivative low (I)",
        key: "ohlcDerivativeLow",
      },
      {
        label: "Historique H2 tab - Derivative close (J)",
        key: "ohlcDerivativeClose",
      },

      // EURUSD simple tab
      {
        label: "EURUSD simple tab - Stochastic Oscillator (X)",
        key: "alphaPredictionStochOscill",
      },
      {
        label: "EURUSD simple tab - Least Square m (Y)",
        key: "alphaPredictionMValue",
      },
      {
        label: "EURUSD simple tab - Least Square b (Z)",
        key: "alphaPredictionBValue",
      },
      {
        label: "EURUSD simple tab - Alpha prediction value (AB)",
        key: "alphaPredictionSerieValue",
      },

      // TC Attache - Feuill1
      { label: "TC Attache - Feuill1 - A", key: "derivatedValuesA" },
      { label: "TC Attache - Feuill1 - B", key: "derivatedValuesB" },
      { label: "TC Attache - Feuill1 - C", key: "derivatedValuesC" },
      { label: "TC Attache - Feuill1 - D", key: "derivatedValuesD" },
      { label: "TC Attache - Feuill1 - E", key: "derivatedValuesE" },
      { label: "TC Attache - Feuill1 - F", key: "derivatedValuesF" },
      { label: "TC Attache - Feuill1 - G", key: "derivatedValuesG" },
      { label: "TC Attache - Feuill1 - H", key: "derivatedValuesH" },
      { label: "TC Attache - Feuill1 - H3", key: "h3" },
      { label: "TC Attache - Feuill1 - H3 first value", key: "h3FirstValue" },
      { label: "TC Attache - Feuill1 - H3 second value", key: "h3SecondValue" },

      // { label: "Index", key: "index" },

      // todo matrix
      // Stade 1 - iteration 1
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - random 1",
        key: "phase1Results1Random1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - value 1",
        key: "phase1Results1Value1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - random 2",
        key: "phase1Results1Random2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - value 2",
        key: "phase1Results1Value2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - random 3",
        key: "phase1Results1Random3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - value 3",
        key: "phase1Results1Value3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - random 4",
        key: "phase1Results1Random4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - value 4",
        key: "phase1Results1Value4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - random 5 ",
        key: "phase1Results1Random5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - value 5",
        key: "phase1Results1Value5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 1",
        key: "phase1Results1H31",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 1 first value",
        key: "phase1Results1AlphaH3Value11",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 1 second value",
        key: "phase1Results1AlphaH3Value21",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 2",
        key: "phase1Results1H32",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 2 first value",
        key: "phase1Results1AlphaH3Value12",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 2 second value",
        key: "phase1Results1AlphaH3Value22",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 3",
        key: "phase1Results1H33",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 3 first value",
        key: "phase1Results1AlphaH3Value13",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 3 second value",
        key: "phase1Results1AlphaH3Value23",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 4",
        key: "phase1Results1H34",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 4 first value",
        key: "phase1Results1AlphaH3Value14",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 4 second value",
        key: "phase1Results1AlphaH3Value24",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 5",
        key: "phase1Results1H35",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 5 first value",
        key: "phase1Results1AlphaH3Value15",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - h3 5 second value",
        key: "phase1Results1AlphaH3Value25",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - Alpha prediction 1",
        key: "phase1Results1AlphaValue1",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - indicator 1",
        key: "phase1Results1Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - indicator 2",
        key: "phase1Results1Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - indicator 3",
        key: "phase1Results1Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - indicator 4",
        key: "phase1Results1Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - indicator 5",
        key: "phase1Results1Indicator5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - Average",
        key: "phase1Results1AverageOfValues",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 1 - Report",
        key: "phase1Results1ReportIndicators",
      },

      // Iteration 2
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - random 1",
        key: "phase1Results2Random1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - value 1",
        key: "phase1Results2Value1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - random 2",
        key: "phase1Results2Random2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - value 2",
        key: "phase1Results2Value2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - random 3",
        key: "phase1Results2Random3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - value 3",
        key: "phase1Results2Value3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - random 4",
        key: "phase1Results2Random4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - value 4",
        key: "phase1Results2Value4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - random 5",
        key: "phase1Results2Random5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - value 5",
        key: "phase1Results2Value5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 1",
        key: "phase1Results2H31",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 1 first value",
        key: "phase1Results2AlphaH3Value11",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 1 second value",
        key: "phase1Results2AlphaH3Value21",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 2",
        key: "phase1Results2H32",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 2 first value",
        key: "phase1Results2AlphaH3Value12",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 2 second value",
        key: "phase1Results2AlphaH3Value22",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 3",
        key: "phase1Results2H33",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 3 first value",
        key: "phase1Results2AlphaH3Value13",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 3 second value",
        key: "phase1Results2AlphaH3Value23",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 4",
        key: "phase1Results2H34",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 4 first value",
        key: "phase1Results2AlphaH3Value14",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 4 second value",
        key: "phase1Results2AlphaH3Value24",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 5",
        key: "phase1Results2H35",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 5 first value",
        key: "phase1Results2AlphaH3Value15",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - h3 5 second value",
        key: "phase1Results2AlphaH3Value25",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - indicator 1",
        key: "phase1Results2Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - indicator 2",
        key: "phase1Results2Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - indicator 3",
        key: "phase1Results2Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - indicator 4",
        key: "phase1Results2Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - indicator 5",
        key: "phase1Results2Indicator5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - Average",
        key: "phase1Results2AverageOfValues",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 2 - Report",
        key: "phase1Results2ReportIndicators",
      },

      // Iteration 3
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - random 1",
        key: "phase1Results3Random1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - value 1",
        key: "phase1Results3Value1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - random 2",
        key: "phase1Results3Random2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - value 2",
        key: "phase1Results3Value2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - random 3",
        key: "phase1Results3Random3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - value 3",
        key: "phase1Results3Value3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - random 4",
        key: "phase1Results3Random4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - value 4",
        key: "phase1Results3Value4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - random 5",
        key: "phase1Results3Random5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - value 5",
        key: "phase1Results3Value5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 1",
        key: "phase1Results3H31",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 1 first value",
        key: "phase1Results3AlphaH3Value11",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 1 second value",
        key: "phase1Results3AlphaH3Value21",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 2",
        key: "phase1Results3H32",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 2 first value",
        key: "phase1Results3AlphaH3Value12",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 2 second value",
        key: "phase1Results3AlphaH3Value22",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 3",
        key: "phase1Results2H33",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 3 first value",
        key: "phase1Results3AlphaH3Value13",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 3 second value",
        key: "phase1Results3AlphaH3Value23",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 4",
        key: "phase1Results3H34",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 4 first value",
        key: "phase1Results3AlphaH3Value14",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 4 second value",
        key: "phase1Results3AlphaH3Value24",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 5",
        key: "phase1Results3H35",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 5 first value",
        key: "phase1Results3AlphaH3Value15",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - h3 5 second value",
        key: "phase1Results3AlphaH3Value25",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - indicator 1",
        key: "phase1Results3Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - indicator 2",
        key: "phase1Results3Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - indicator 3",
        key: "phase1Results3Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - indicator 4",
        key: "phase1Results3Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - indicator 5",
        key: "phase1Results3Indicator5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - Average",
        key: "phase1Results3AverageOfValues",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 3 - Report",
        key: "phase1Results3ReportIndicators",
      },

      // Iteration 4
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - random 1",
        key: "phase1Results4Random1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - value 1",
        key: "phase1Results4Value1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - random 2",
        key: "phase1Results4Random2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - value 2",
        key: "phase1Results4Value2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - random 3",
        key: "phase1Results4Random3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - value 3",
        key: "phase1Results4Value3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - random 4",
        key: "phase1Results4Random4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - value 4",
        key: "phase1Results4Value4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - random 5",
        key: "phase1Results4Random5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - value 5",
        key: "phase1Results4Value5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 1",
        key: "phase1Results4H31",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 1 first value",
        key: "phase1Results4AlphaH3Value11",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 1 second value",
        key: "phase1Results4AlphaH3Value21",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 2",
        key: "phase1Results4H32",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 2 first value",
        key: "phase1Results4AlphaH3Value12",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 2 second value",
        key: "phase1Results4AlphaH3Value22",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 3",
        key: "phase1Results4H33",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 3 first value",
        key: "phase1Results4AlphaH3Value13",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 3 second value",
        key: "phase1Results4AlphaH3Value23",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 4",
        key: "phase1Results4H34",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 4 first value",
        key: "phase1Results4AlphaH3Value14",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 4 second value",
        key: "phase1Results4AlphaH3Value24",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 5",
        key: "phase1Results4H35",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 5 first value",
        key: "phase1Results4AlphaH3Value15",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - h3 5 second value",
        key: "phase1Results4AlphaH3Value25",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - indicator 1",
        key: "phase1Results4Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - indicator 2",
        key: "phase1Results4Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - indicator 3",
        key: "phase1Results4Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - indicator 4",
        key: "phase1Results4Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - indicator 5",
        key: "phase1Results4Indicator5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - Average",
        key: "phase1Results4AverageOfValues",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 4 - Report",
        key: "phase1Results4ReportIndicators",
      },

      // Iteration 5
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - random 1",
        key: "phase1Results5Random1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - value 1",
        key: "phase1Results5Value1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - random 2",
        key: "phase1Results5Random2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - value 2",
        key: "phase1Results5Value2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - random 3",
        key: "phase1Results5Random3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - value 3",
        key: "phase1Results5Value3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - random 4",
        key: "phase1Results5Random4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - value 4",
        key: "phase1Results5Value4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - random 5",
        key: "phase1Results5Random5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - value 5",
        key: "phase1Results5Value5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 1",
        key: "phase1Results5H31",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 1 first value",
        key: "phase1Results5AlphaH3Value11",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 1 second value",
        key: "phase1Results5AlphaH3Value21",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 2",
        key: "phase1Results5H32",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 2 first value",
        key: "phase1Results5AlphaH3Value12",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 2 second value",
        key: "phase1Results5AlphaH3Value22",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 3",
        key: "phase1Results5H33",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 3 first value",
        key: "phase1Results5AlphaH3Value13",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 3 second value",
        key: "phase1Results5AlphaH3Value23",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 4",
        key: "phase1Results5H34",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 4 first value",
        key: "phase1Results5AlphaH3Value14",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 4 second value",
        key: "phase1Results5AlphaH3Value24",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 5",
        key: "phase1Results5H35",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 5 first value",
        key: "phase1Results5AlphaH3Value15",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - h3 5 second value",
        key: "phase1Results5AlphaH3Value25",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - indicator 1",
        key: "phase1Results5Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - indicator 2",
        key: "phase1Results5Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - indicator 3",
        key: "phase1Results5Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - indicator 4",
        key: "phase1Results5Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - indicator 5",
        key: "phase1Results5Indicator5",
      },

      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - Average",
        key: "phase1Results5AverageOfValues",
      },
      {
        label: "TC Attache - Feuill1 - Stade 1 - Iteration 5 - Report",
        key: "phase1Results5ReportIndicators",
      },

      // Stade 2 - Iteration 1
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Indicator 1",
        key: "phase2Results1Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Indicator 2",
        key: "phase2Results1Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Indicator 3",
        key: "phase2Results1Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Indicator 4",
        key: "phase2Results1Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Indicator 5",
        key: "phase2Results1Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Report",
        key: "phase2Results1ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 2 - Iteration 1 - Data index",
      //   key: "phase2Results1DataIndex",
      // },

      // Iteration 2
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Indicator 1",
        key: "phase2Results2Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Indicator 2",
        key: "phase2Results2Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Indicator 3",
        key: "phase2Results2Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Indicator 4",
        key: "phase2Results2Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Indicator 5",
        key: "phase2Results2Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Report",
        key: "phase2Results2ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 2 - Iteration 2 - Data index",
      //   key: "phase2Results2DataIndex",
      // },

      // Iteration 3
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Indicator 1",
        key: "phase2Results3Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Indicator 2",
        key: "phase2Results3Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Indicator 3",
        key: "phase2Results3Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Indicator 4",
        key: "phase2Results3Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Indicator 5",
        key: "phase2Results3Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Report",
        key: "phase2Results3ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 2 - Iteration 3 - Data index",
      //   key: "phase2Results3DataIndex",
      // },

      // Iteration 4
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Indicator 1",
        key: "phase2Results4Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Indicator 2",
        key: "phase2Results4Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Indicator 3",
        key: "phase2Results4Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Indicator 4",
        key: "phase2Results4Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Indicator 5",
        key: "phase2Results4Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Report",
        key: "phase2Results4ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 2 - Iteration 4 - Data index",
      //   key: "phase2Results4DataIndex",
      // },

      // Iteration 5
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Indicator 1",
        key: "phase2Results5Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Indicator 2",
        key: "phase2Results5Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Indicator 3",
        key: "phase2Results5Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Indicator 4",
        key: "phase2Results5Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Indicator 5",
        key: "phase2Results5Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Report",
        key: "phase2Results5ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 2 - Iteration 5 - Data index",
      //   key: "phase2Results5DataIndex",
      // },

      // Stade 3 - Iteration 1
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Indicator 1",
        key: "phase3Results1Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Indicator 2",
        key: "phase3Results1Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Indicator 3",
        key: "phase3Results1Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Indicator 4",
        key: "phase3Results1Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Indicator 5",
        key: "phase3Results1Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Report",
        key: "phase3Results1ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 3 - Iteration 1 - Data index",
      //   key: "phase3Results1DataIndex",
      // },

      // Iteration 2
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Indicator 1",
        key: "phase3Results2Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Indicator 2",
        key: "phase3Results2Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Indicator 3",
        key: "phase3Results2Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Indicator 4",
        key: "phase3Results2Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Indicator 5",
        key: "phase3Results2Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Report",
        key: "phase3Results2ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 3 - Iteration 2 - Data index",
      //   key: "phase3Results2DataIndex",
      // },

      // Iteration 3
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Indicator 1",
        key: "phase3Results3Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Indicator 2",
        key: "phase3Results3Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Indicator 3",
        key: "phase3Results3Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Indicator 4",
        key: "phase3Results3Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Indicator 5",
        key: "phase3Results3Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Report",
        key: "phase3Results3ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 3 - Iteration 3 - Data index",
      //   key: "phase3Results3DataIndex",
      // },

      // Iteration 4
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Indicator 1",
        key: "phase3Results4Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Indicator 2",
        key: "phase3Results4Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Indicator 3",
        key: "phase3Results4Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Indicator 4",
        key: "phase3Results4Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Indicator 5",
        key: "phase3Results4Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Report",
        key: "phase3Results4ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 4 - Iteration 4 - Data index",
      //   key: "phase3Results4DataIndex",
      // },

      // Iteration 5
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Indicator 1",
        key: "phase3Results5Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Indicator 2",
        key: "phase3Results5Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Indicator 3",
        key: "phase3Results5Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Indicator 4",
        key: "phase3Results5Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Indicator 5",
        key: "phase3Results5Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Report",
        key: "phase3Results5ReportIndicators",
      },
      // {
      //   label: "TC Attache - Feuill1 - Stade 4 - Iteration 5 - Data index",
      //   key: "phase3Results5DataIndex",
      // },

      // Stade 5 - Iteration 1
      {
        label: "TC Attache - Feuill1 - Stade 5 - Iteration 1 - Indicator 1",
        key: "phase4Results1Indicator1",
      },
      {
        label: "TC Attache - Feuill1 - Stade 5 - Iteration 1 - Indicator 2",
        key: "phase4Results1Indicator2",
      },
      {
        label: "TC Attache - Feuill1 - Stade 5 - Iteration 1 - Indicator 3",
        key: "phase4Results1Indicator3",
      },
      {
        label: "TC Attache - Feuill1 - Stade 5 - Iteration 1 - Indicator 4",
        key: "phase4Results1Indicator4",
      },
      {
        label: "TC Attache - Feuill1 - Stade 5 - Iteration 1 - Indicator 5",
        key: "phase4Results1Indicator5",
      },
      {
        label: "TC Attache - Feuill1 - Stade 5 - Iteration 1 - Report",
        key: "phase4Results1ReportIndicators",
      },
    ],
  },
];

const horizontalSpacing = 1;

const drawColumns = (series) => {
  let columns = [];
  for (let i = 0; i < series.length; i++) {
    for (let y = 0; y < series[i].keys.length; y++) {
      columns.push({ name: series[i].keys[y].label });
    }
    for (let s = 0; s < horizontalSpacing; s++) {
      columns.push({ name: " " });
    }
  }
  return columns;
};

const parseData = (data, phase1) => {
  const base = {
    date: new Date(data.timestamp*1000) || undefined,
    open: data.open || undefined,
    high: data.high || undefined,
    low: data.low || undefined,
    close: data.close || undefined,

    spyGColumn: data.spyGColumn || undefined,
    spyG1Column: data.spyG1Column || undefined,
    spyH1Column: data.spyH1Column || undefined,
    spyH2Column: data.spyH2Column || undefined,
    random1: data.random1 || undefined,
    spyJColumn: data.spyJColumn || undefined,
    random2: data.random2 || undefined,
    spyLColumn: data.spyLColumn || undefined,
    spyMColumn: data.spyMColumn || undefined,
    spyNColumn: data.spyNColumn || undefined,
    SpySerieOpen: data.SpySerieOpen || undefined,
    SpySerieHigh: data.SpySerieHigh || undefined,
    SpySerieLow: data.SpySerieLow || undefined,
    SpySerieClose: data.SpySerieClose || undefined,
    serieVariations: data.serieVariations || undefined,
    spySColumn: data.spySColumn || undefined,
    consolidatedSpySeriesOpen: data.consolidatedSpySeriesOpen || undefined,
    consolidatedSpySeriesHigh: data.consolidatedSpySeriesHigh || undefined,
    consolidatedSpySeriesLow: data.consolidatedSpySeriesLow || undefined,
    consolidatedSpySeriesClose: data.consolidatedSpySeriesClose || undefined,

    ohlcDerivativeRandom: data.ohlcDerivativeRandom || undefined,
    ohlcDerivativeOpen: data.ohlcDerivativeOpen || undefined,
    ohlcDerivativeHigh: data.ohlcDerivativeHigh || undefined,
    ohlcDerivativeLow: data.ohlcDerivativeLow || undefined,
    ohlcDerivativeClose: data.ohlcDerivativeClose || undefined,

    alphaPredictionStochOscill: data.alphaPredictionStochOscill || undefined,
    alphaPredictionMValue: data.alphaPredictionMValue || undefined,
    alphaPredictionBValue: data.alphaPredictionBValue || undefined,
    alphaPredictionSerieValue: data.alphaPredictionSerieValue || undefined,

    derivatedValuesA: data.derivatedValuesA || undefined,
    derivatedValuesB: data.derivatedValuesB || undefined,
    derivatedValuesC: data.derivatedValuesC || undefined,
    derivatedValuesD: data.derivatedValuesD || undefined,
    derivatedValuesE: data.derivatedValuesE || undefined,
    derivatedValuesF: data.derivatedValuesF || undefined,
    derivatedValuesG: data.derivatedValuesG || undefined,
    derivatedValuesH: data.derivatedValuesH || undefined,
    h3: data.h3 || undefined,
    h3FirstValue: data.h3FirstValue || undefined,
    h3SecondValue: data.h3SecondValue || undefined,

    phase1Results1Value1: phase1.stade1Results1Value1 || undefined,
    phase1Results1Value2: phase1.stade1Results1Value2 || undefined,
    phase1Results1Value3: phase1.stade1Results1Value3 || undefined,
    phase1Results1Value4: phase1.stade1Results1Value4 || undefined,
    phase1Results1Value5: phase1.stade1Results1Value5 || undefined,


    phase1Results1Random1: phase1.stade1Results1Random1 || undefined,
    phase1Results1Random2: phase1.stade1Results1Random2 || undefined,
    phase1Results1Random3: phase1.stade1Results1Random3 || undefined,
    phase1Results1Random4: phase1.stade1Results1Random4 || undefined,
    phase1Results1Random5: phase1.stade1Results1Random5 || undefined,

    phase1Results1H31: phase1.stade1Results1H31 || undefined,
    phase1Results1H32: phase1.stade1Results1H32 || undefined,
    phase1Results1H33: phase1.stade1Results1H33 || undefined,
    phase1Results1H34: phase1.stade1Results1H34 || undefined,
    phase1Results1H35: phase1.stade1Results1H35 || undefined,

    phase1Results1AlphaH3Value11: phase1.stade1Results1AlphaH3Value11 || undefined,
    phase1Results1AlphaH3Value21: phase1.stade1Results1AlphaH3Value21 || undefined,
    phase1Results1AlphaH3Value12: phase1.stade1Results1AlphaH3Value12 || undefined,
    phase1Results1AlphaH3Value22: phase1.stade1Results1AlphaH3Value22 || undefined,
    phase1Results1AlphaH3Value13: phase1.stade1Results1AlphaH3Value13 || undefined,
    phase1Results1AlphaH3Value23: phase1.stade1Results1AlphaH3Value23 || undefined,
    phase1Results1AlphaH3Value14: phase1.stade1Results1AlphaH3Value14 || undefined,
    phase1Results1AlphaH3Value24: phase1.stade1Results1AlphaH3Value24 || undefined,
    phase1Results1AlphaH3Value15: phase1.stade1Results1AlphaH3Value15 || undefined,
    phase1Results1AlphaH3Value25: phase1.stade1Results1AlphaH3Value25 || undefined,

    phase1Results1AlphaValue1: phase1.stade1Results1AlphaValue1 || undefined,

    phase1Results1Indicator1: phase1.stade1Results1Indicator1 || undefined,
    phase1Results1Indicator2: phase1.stade1Results1Indicator2 || undefined,
    phase1Results1Indicator3: phase1.stade1Results1Indicator3 || undefined,
    phase1Results1Indicator4: phase1.stade1Results1Indicator4 || undefined,
    phase1Results1Indicator5: phase1.stade1Results1Indicator5 || undefined,
    phase1Results1AverageOfValues:
      phase1.stade1Results1AverageOfValues || undefined,
    phase1Results1ReportIndicators:
      phase1.stade1Results1ReportIndicators || undefined,

    phase1Results2Value1: phase1.stade1Results2Value1 || undefined,
    phase1Results2Value2: phase1.stade1Results2Value2 || undefined,
    phase1Results2Value3: phase1.stade1Results2Value3 || undefined,
    phase1Results2Value4: phase1.stade1Results2Value4 || undefined,
    phase1Results2Value5: phase1.stade1Results2Value5 || undefined,
    phase1Results2Random1: phase1.stade1Results2Random1 || undefined,
    phase1Results2Random2: phase1.stade1Results2Random2 || undefined,
    phase1Results2Random3: phase1.stade1Results2Random3 || undefined,
    phase1Results2Random4: phase1.stade1Results2Random4 || undefined,
    phase1Results2Random5: phase1.stade1Results2Random5 || undefined,

    phase1Results2H31: phase1.stade1Results2H31 || undefined,
    phase1Results2H32: phase1.stade1Results2H32 || undefined,
    phase1Results2H33: phase1.stade1Results2H33 || undefined,
    phase1Results2H34: phase1.stade1Results2H34 || undefined,
    phase1Results2H35: phase1.stade1Results2H35 || undefined,

    phase1Results2AlphaH3Value11: phase1.stade1Results2AlphaH3Value11 || undefined,
    phase1Results2AlphaH3Value21: phase1.stade1Results2AlphaH3Value21 || undefined,
    phase1Results2AlphaH3Value12: phase1.stade1Results2AlphaH3Value12 || undefined,
    phase1Results2AlphaH3Value22: phase1.stade1Results2AlphaH3Value22 || undefined,
    phase1Results2AlphaH3Value13: phase1.stade1Results2AlphaH3Value13 || undefined,
    phase1Results2AlphaH3Value23: phase1.stade1Results2AlphaH3Value23 || undefined,
    phase1Results2AlphaH3Value14: phase1.stade1Results2AlphaH3Value14 || undefined,
    phase1Results2AlphaH3Value24: phase1.stade1Results2AlphaH3Value24 || undefined,
    phase1Results2AlphaH3Value15: phase1.stade1Results2AlphaH3Value15 || undefined,
    phase1Results2AlphaH3Value25: phase1.stade1Results2AlphaH3Value25 || undefined,

    phase1Results2Indicator1: phase1.stade1Results2Indicator1 || undefined,
    phase1Results2Indicator2: phase1.stade1Results2Indicator2 || undefined,
    phase1Results2Indicator3: phase1.stade1Results2Indicator3 || undefined,
    phase1Results2Indicator4: phase1.stade1Results2Indicator4 || undefined,
    phase1Results2Indicator5: phase1.stade1Results2Indicator5 || undefined,
    phase1Results2AverageOfValues:
      phase1.stade1Results2AverageOfValues || undefined,
    phase1Results2ReportIndicators:
      phase1.stade1Results2ReportIndicators || undefined,

    phase1Results3Value1: phase1.stade1Results3Value1 || undefined,
    phase1Results3Value2: phase1.stade1Results3Value2 || undefined,
    phase1Results3Value3: phase1.stade1Results3Value3 || undefined,
    phase1Results3Value4: phase1.stade1Results3Value4 || undefined,
    phase1Results3Value5: phase1.stade1Results3Value5 || undefined,
    phase1Results3Random1: phase1.stade1Results3Random1 || undefined,
    phase1Results3Random2: phase1.stade1Results3Random2 || undefined,
    phase1Results3Random3: phase1.stade1Results3Random3 || undefined,
    phase1Results3Random4: phase1.stade1Results3Random4 || undefined,
    phase1Results3Random5: phase1.stade1Results3Random5 || undefined,

    phase1Results3H31: phase1.stade1Results3H31 || undefined,
    phase1Results3H32: phase1.stade1Results3H32 || undefined,
    phase1Results3H33: phase1.stade1Results3H33 || undefined,
    phase1Results3H34: phase1.stade1Results3H34 || undefined,
    phase1Results3H35: phase1.stade1Results3H35 || undefined,

    phase1Results3AlphaH3Value11: phase1.stade1Results3AlphaH3Value11 || undefined,
    phase1Results3AlphaH3Value21: phase1.stade1Results3AlphaH3Value21 || undefined,
    phase1Results3AlphaH3Value12: phase1.stade1Results3AlphaH3Value12 || undefined,
    phase1Results3AlphaH3Value22: phase1.stade1Results3AlphaH3Value22 || undefined,
    phase1Results3AlphaH3Value13: phase1.stade1Results3AlphaH3Value13 || undefined,
    phase1Results3AlphaH3Value23: phase1.stade1Results3AlphaH3Value23 || undefined,
    phase1Results3AlphaH3Value14: phase1.stade1Results3AlphaH3Value14 || undefined,
    phase1Results3AlphaH3Value24: phase1.stade1Results3AlphaH3Value24 || undefined,
    phase1Results3AlphaH3Value15: phase1.stade1Results3AlphaH3Value15 || undefined,
    phase1Results3AlphaH3Value25: phase1.stade1Results3AlphaH3Value25 || undefined,

    phase1Results3Indicator1: phase1.stade1Results3Indicator1 || undefined,
    phase1Results3Indicator2: phase1.stade1Results3Indicator2 || undefined,
    phase1Results3Indicator3: phase1.stade1Results3Indicator3 || undefined,
    phase1Results3Indicator4: phase1.stade1Results3Indicator4 || undefined,
    phase1Results3Indicator5: phase1.stade1Results3Indicator5 || undefined,
    phase1Results3AverageOfValues:
      phase1.stade1Results3AverageOfValues || undefined,
    phase1Results3ReportIndicators:
      phase1.stade1Results3ReportIndicators || undefined,

    phase1Results4Value1: phase1.stade1Results4Value1 || undefined,
    phase1Results4Value2: phase1.stade1Results4Value2 || undefined,
    phase1Results4Value3: phase1.stade1Results4Value3 || undefined,
    phase1Results4Value4: phase1.stade1Results4Value4 || undefined,
    phase1Results4Value5: phase1.stade1Results4Value5 || undefined,
    phase1Results4Random1: phase1.stade1Results4Random1 || undefined,
    phase1Results4Random2: phase1.stade1Results4Random2 || undefined,
    phase1Results4Random3: phase1.stade1Results4Random3 || undefined,
    phase1Results4Random4: phase1.stade1Results4Random4 || undefined,
    phase1Results4Random5: phase1.stade1Results4Random5 || undefined,

    phase1Results4H31: phase1.stade1Results4H31 || undefined,
    phase1Results4H32: phase1.stade1Results4H32 || undefined,
    phase1Results4H33: phase1.stade1Results4H33 || undefined,
    phase1Results4H34: phase1.stade1Results4H34 || undefined,
    phase1Results4H35: phase1.stade1Results4H35 || undefined,

    phase1Results4AlphaH3Value11: phase1.stade1Results4AlphaH3Value11 || undefined,
    phase1Results4AlphaH3Value21: phase1.stade1Results4AlphaH3Value21 || undefined,
    phase1Results4AlphaH3Value12: phase1.stade1Results4AlphaH3Value12 || undefined,
    phase1Results4AlphaH3Value22: phase1.stade1Results4AlphaH3Value22 || undefined,
    phase1Results4AlphaH3Value13: phase1.stade1Results4AlphaH3Value13 || undefined,
    phase1Results4AlphaH3Value23: phase1.stade1Results4AlphaH3Value23 || undefined,
    phase1Results4AlphaH3Value14: phase1.stade1Results4AlphaH3Value14 || undefined,
    phase1Results4AlphaH3Value24: phase1.stade1Results4AlphaH3Value24 || undefined,
    phase1Results4AlphaH3Value15: phase1.stade1Results4AlphaH3Value15 || undefined,
    phase1Results4AlphaH3Value25: phase1.stade1Results4AlphaH3Value25 || undefined,

    phase1Results4Indicator1: phase1.stade1Results4Indicator1 || undefined,
    phase1Results4Indicator2: phase1.stade1Results4Indicator2 || undefined,
    phase1Results4Indicator3: phase1.stade1Results4Indicator3 || undefined,
    phase1Results4Indicator4: phase1.stade1Results4Indicator4 || undefined,
    phase1Results4Indicator5: phase1.stade1Results4Indicator5 || undefined,
    phase1Results4AverageOfValues:
      phase1.stade1Results4AverageOfValues || undefined,
    phase1Results4ReportIndicators:
      phase1.stade1Results4ReportIndicators || undefined,

    phase1Results5Value1: phase1.stade1Results5Value1 || undefined,
    phase1Results5Value2: phase1.stade1Results5Value2 || undefined,
    phase1Results5Value3: phase1.stade1Results5Value3 || undefined,
    phase1Results5Value4: phase1.stade1Results5Value4 || undefined,
    phase1Results5Value5: phase1.stade1Results5Value5 || undefined,
    phase1Results5Random1: phase1.stade1Results5Random1 || undefined,
    phase1Results5Random2: phase1.stade1Results5Random2 || undefined,
    phase1Results5Random3: phase1.stade1Results5Random3 || undefined,
    phase1Results5Random4: phase1.stade1Results5Random4 || undefined,
    phase1Results5Random5: phase1.stade1Results5Random5 || undefined,

    phase1Results5H31: phase1.stade1Results5H31 || undefined,
    phase1Results5H32: phase1.stade1Results5H32 || undefined,
    phase1Results5H33: phase1.stade1Results5H33 || undefined,
    phase1Results5H34: phase1.stade1Results5H34 || undefined,
    phase1Results5H35: phase1.stade1Results5H35 || undefined,

    phase1Results5AlphaH3Value11: phase1.stade1Results5AlphaH3Value11 || undefined,
    phase1Results5AlphaH3Value21: phase1.stade1Results5AlphaH3Value21 || undefined,
    phase1Results5AlphaH3Value12: phase1.stade1Results5AlphaH3Value12 || undefined,
    phase1Results5AlphaH3Value22: phase1.stade1Results5AlphaH3Value22 || undefined,
    phase1Results5AlphaH3Value13: phase1.stade1Results5AlphaH3Value13 || undefined,
    phase1Results5AlphaH3Value23: phase1.stade1Results5AlphaH3Value23 || undefined,
    phase1Results5AlphaH3Value14: phase1.stade1Results5AlphaH3Value14 || undefined,
    phase1Results5AlphaH3Value24: phase1.stade1Results5AlphaH3Value24 || undefined,
    phase1Results5AlphaH3Value15: phase1.stade1Results5AlphaH3Value15 || undefined,
    phase1Results5AlphaH3Value25: phase1.stade1Results5AlphaH3Value25 || undefined,

    phase1Results5Indicator1: phase1.stade1Results5Indicator1 || undefined,
    phase1Results5Indicator2: phase1.stade1Results5Indicator2 || undefined,
    phase1Results5Indicator3: phase1.stade1Results5Indicator3 || undefined,
    phase1Results5Indicator4: phase1.stade1Results5Indicator4 || undefined,
    phase1Results5Indicator5: phase1.stade1Results5Indicator5 || undefined,
    phase1Results5AverageOfValues:
      phase1.stade1Results5AverageOfValues || undefined,
    phase1Results5ReportIndicators:
      phase1.stade1Results5ReportIndicators || undefined,

    phase2Results1Indicator1: phase1.stade2Results1Indicator1 || undefined,
    phase2Results1Indicator2: phase1.stade2Results1Indicator2 || undefined,
    phase2Results1Indicator3: phase1.stade2Results1Indicator3 || undefined,
    phase2Results1Indicator4: phase1.stade2Results1Indicator4 || undefined,
    phase2Results1Indicator5: phase1.stade2Results1Indicator5 || undefined,
    phase2Results1ReportIndicators:
      phase1.stade2Results1ReportIndicators || undefined,
    phase2Results1DataIndex: phase1.stade2Results1DataIndex || undefined,
    phase2Results2Indicator1: phase1.stade2Results2Indicator1 || undefined,
    phase2Results2Indicator2: phase1.stade2Results2Indicator2 || undefined,
    phase2Results2Indicator3: phase1.stade2Results2Indicator3 || undefined,
    phase2Results2Indicator4: phase1.stade2Results2Indicator4 || undefined,
    phase2Results2Indicator5: phase1.stade2Results2Indicator5 || undefined,
    phase2Results2ReportIndicators:
      phase1.stade2Results2ReportIndicators || undefined,
    phase2Results2DataIndex: phase1.stade2Results2DataIndex || undefined,
    phase2Results3Indicator1: phase1.stade2Results3Indicator1 || undefined,
    phase2Results3Indicator2: phase1.stade2Results3Indicator2 || undefined,
    phase2Results3Indicator3: phase1.stade2Results3Indicator3 || undefined,
    phase2Results3Indicator4: phase1.stade2Results3Indicator4 || undefined,
    phase2Results3Indicator5: phase1.stade2Results3Indicator5 || undefined,
    phase2Results3ReportIndicators:
      phase1.stade2Results3ReportIndicators || undefined,
    phase2Results3DataIndex: phase1.stade2Results3DataIndex || undefined,
    phase2Results4Indicator1: phase1.stade2Results4Indicator1 || undefined,
    phase2Results4Indicator2: phase1.stade2Results4Indicator2 || undefined,
    phase2Results4Indicator3: phase1.stade2Results4Indicator3 || undefined,
    phase2Results4Indicator4: phase1.stade2Results4Indicator4 || undefined,
    phase2Results4Indicator5: phase1.stade2Results4Indicator5 || undefined,
    phase2Results4ReportIndicators:
      phase1.stade2Results4ReportIndicators || undefined,
    phase2Results4DataIndex: phase1.stade2Results4DataIndex || undefined,
    phase2Results5Indicator1: phase1.stade2Results5Indicator1 || undefined,
    phase2Results5Indicator2: phase1.stade2Results5Indicator2 || undefined,
    phase2Results5Indicator3: phase1.stade2Results5Indicator3 || undefined,
    phase2Results5Indicator4: phase1.stade2Results5Indicator4 || undefined,
    phase2Results5Indicator5: phase1.stade2Results5Indicator5 || undefined,
    phase2Results5ReportIndicators:
      phase1.stade2Results5ReportIndicators || undefined,
    phase2Results5DataIndex: phase1.stade2Results5DataIndex || undefined,
    phase3Results1Indicator1: phase1.stade3Results1Indicator1 || undefined,
    phase3Results1Indicator2: phase1.stade3Results1Indicator2 || undefined,
    phase3Results1Indicator3: phase1.stade3Results1Indicator3 || undefined,
    phase3Results1Indicator4: phase1.stade3Results1Indicator4 || undefined,
    phase3Results1Indicator5: phase1.stade3Results1Indicator5 || undefined,
    phase3Results1ReportIndicators:
      phase1.stade3Results1ReportIndicators || undefined,
    phase3Results1DataIndex: phase1.stade3Results1DataIndex || undefined,
    phase3Results2Indicator1: phase1.stade3Results2Indicator1 || undefined,
    phase3Results2Indicator2: phase1.stade3Results2Indicator2 || undefined,
    phase3Results2Indicator3: phase1.stade3Results2Indicator3 || undefined,
    phase3Results2Indicator4: phase1.stade3Results2Indicator4 || undefined,
    phase3Results2Indicator5: phase1.stade3Results2Indicator5 || undefined,
    phase3Results2ReportIndicators:
      phase1.stade3Results2ReportIndicators || undefined,
    phase3Results2DataIndex: phase1.stade3Results2DataIndex || undefined,
    phase3Results3Indicator1: phase1.stade3Results3Indicator1 || undefined,
    phase3Results3Indicator2: phase1.stade3Results3Indicator2 || undefined,
    phase3Results3Indicator3: phase1.stade3Results3Indicator3 || undefined,
    phase3Results3Indicator4: phase1.stade3Results3Indicator4 || undefined,
    phase3Results3Indicator5: phase1.stade3Results3Indicator5 || undefined,
    phase3Results3ReportIndicators:
      phase1.stade3Results3ReportIndicators || undefined,
    phase3Results3DataIndex: phase1.stade3Results3DataIndex || undefined,
    phase3Results4Indicator1: phase1.stade3Results4Indicator1 || undefined,
    phase3Results4Indicator2: phase1.stade3Results4Indicator2 || undefined,
    phase3Results4Indicator3: phase1.stade3Results4Indicator3 || undefined,
    phase3Results4Indicator4: phase1.stade3Results4Indicator4 || undefined,
    phase3Results4Indicator5: phase1.stade3Results4Indicator5 || undefined,
    phase3Results4ReportIndicators:
      phase1.stade3Results4ReportIndicators || undefined,
    phase3Results4DataIndex: phase1.stade3Results4DataIndex || undefined,
    phase3Results5Indicator1: phase1.stade3Results5Indicator1 || undefined,
    phase3Results5Indicator2: phase1.stade3Results5Indicator2 || undefined,
    phase3Results5Indicator3: phase1.stade3Results5Indicator3 || undefined,
    phase3Results5Indicator4: phase1.stade3Results5Indicator4 || undefined,
    phase3Results5Indicator5: phase1.stade3Results5Indicator5 || undefined,
    phase3Results5ReportIndicators:
      phase1.stade3Results5ReportIndicators || undefined,
    phase3Results5DataIndex: phase1.stade3Results5DataIndex || undefined,
    phase4Results1Indicator1: phase1.stade4Results1Indicator1 || undefined,
    phase4Results1Indicator2: phase1.stade4Results1Indicator2 || undefined,
    phase4Results1Indicator3: phase1.stade4Results1Indicator3 || undefined,
    phase4Results1Indicator4: phase1.stade4Results1Indicator4 || undefined,
    phase4Results1Indicator5: phase1.stade4Results1Indicator5 || undefined,
    phase4Results1ReportIndicators:
      phase1.stade4Results1ReportIndicators || undefined,
    phase4Results1DataIndex: phase1.stade4Results1DataIndex || undefined,
    phase4Results2Indicator1: phase1.stade4Results2Indicator1 || undefined,
    phase4Results2Indicator2: phase1.stade4Results2Indicator2 || undefined,
    phase4Results2Indicator3: phase1.stade4Results2Indicator3 || undefined,
    phase4Results2Indicator4: phase1.stade4Results2Indicator4 || undefined,
    phase4Results2Indicator5: phase1.stade4Results2Indicator5 || undefined,
    phase4Results2ReportIndicators:
      phase1.stade4Results2ReportIndicators || undefined,
    phase4Results2DataIndex: phase1.stade4Results2DataIndex || undefined,
    phase4Results3Indicator1: phase1.stade4Results3Indicator1 || undefined,
    phase4Results3Indicator2: phase1.stade4Results3Indicator2 || undefined,
    phase4Results3Indicator3: phase1.stade4Results3Indicator3 || undefined,
    phase4Results3Indicator4: phase1.stade4Results3Indicator4 || undefined,
    phase4Results3Indicator5: phase1.stade4Results3Indicator5 || undefined,
    phase4Results3ReportIndicators:
      phase1.stade4Results3ReportIndicators || undefined,
    phase4Results3DataIndex: phase1.stade4Results3DataIndex || undefined,
    phase4Results4Indicator1: phase1.stade4Results4Indicator1 || undefined,
    phase4Results4Indicator2: phase1.stade4Results4Indicator2 || undefined,
    phase4Results4Indicator3: phase1.stade4Results4Indicator3 || undefined,
    phase4Results4Indicator4: phase1.stade4Results4Indicator4 || undefined,
    phase4Results4Indicator5: phase1.stade4Results4Indicator5 || undefined,
    phase4Results4ReportIndicators:
      phase1.stade4Results4ReportIndicators || undefined,
    phase4Results4DataIndex: phase1.stade4Results4DataIndex || undefined,
    phase4Results5Indicator1: phase1.stade4Results5Indicator1 || undefined,
    phase4Results5Indicator2: phase1.stade4Results5Indicator2 || undefined,
    phase4Results5Indicator3: phase1.stade4Results5Indicator3 || undefined,
    phase4Results5Indicator4: phase1.stade4Results5Indicator4 || undefined,
    phase4Results5Indicator5: phase1.stade4Results5Indicator5 || undefined,
    phase4Results5ReportIndicators:
      phase1.stade4Results5ReportIndicators || undefined,
    phase4Results5DataIndex: phase1.stade4Results5DataIndex || undefined,

    index: data.index || undefined,
  };
  const modelsKeys = Object.keys(data).filter((key) => key.match('"model".*'));
  const predictions = modelsKeys.map((modelKey) => {
    const prediction = JSON.parse(modelKey.split("-")[0]);
    const valueKey = modelKey.split("-")[1];
    const value = data[modelKey];
    return { ...prediction, valueKey, value };
  });

  return {
    base,
    predictions: predictions.length > 0 ? predictions : undefined,
  };
};

const findPredictionsFromKey = (predictions, valueKey) => {
  if (predictions) {
    return predictions.filter((prediction) => valueKey === prediction.valueKey);
  } else {
    return [];
  }
};

const drawRows = (series, data, phase1) => {
  const rows = [];

  for (let i = 0; i < data.length; i++) {
    const seriesRows = [];
    const parsedData = parseData(data[i], phase1.phase1Data.tradingSerie[i]);
    let keyIndex = 0;
    let oldValueKey = series[0].valueKey;

    for (let y = 0; y < series.length; y++) {
      const serie = series[y];

      // Find predictions associated to the valueKey
      const currentPredictions = findPredictionsFromKey(
        parsedData.predictions,
        serie.valueKey
      );

      // If we don't have a duplicated valueKey we reset the keyIndex
      if (oldValueKey !== serie.valueKey) {
        keyIndex = 0;
      }

      // Explore all needed keys
      for (let x = 0; x < serie.keys.length; x++) {
        const currentKey = serie.keys[x].key;

        // Get a base value
        if (parsedData.base[currentKey]) {
          seriesRows.push(parsedData.base[currentKey]);
        } else {
          // Get a prediction value
          if (parsedData.predictions) {
            if (currentPredictions.length > 0) {
              const value = currentPredictions[keyIndex][currentKey];
              seriesRows.push(value);
            } else {
              // If prediction is empty push an empty column
              seriesRows.push("");
            }
            // If not base & not prediction push an empty column
          } else {
            seriesRows.push("");
          }
        }
      }

      // If multiple prediction with identical valueKey, we increment index to get the next value
      if (
        currentPredictions.length !== 0 &&
        currentPredictions.length - 1 > keyIndex
      ) {
        keyIndex++;
      }
      oldValueKey = serie.valueKey;

      // Add spacing
      for (let s = 0; s < horizontalSpacing; s++) {
        seriesRows.push("");
      }
    }
    rows.push(seriesRows.flat());
  }
  return rows;
};

export const useExportDataToXSLXPhase1Mutation = () => {
  return useMutation(async ({ previewData, phase1 }) => {
    const { default: ExcelJS } = await import("exceljs");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("export");
    // drawHeaders(series, worksheet);

    const iterationShift = 2300;
    phase1.forEach((p, index) => {
      worksheet.addTable({
        ref:  index === 0 ? "A1" : `A${iterationShift * (index)}`,
        columns: drawColumns(seriesPhase1),
        rows: drawRows(seriesPhase1, previewData, p),
      });
    });
  

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    let fileName = "export-phase1";
    saveAs(blob, `${fileName}.xlsx`);
  });
};
