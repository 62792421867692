/** @jsxImportSource @emotion/react */
import "twin.macro";
import { useState } from "react";
import { useContext } from "react";
import { ResultsDisplayContext } from "./Results";
import { ChevronDown, ChevronUp, Maximize, Minimize } from "../shared/Icons";

export const ResultChildBlock = ({ phaseIdx, iterationIdx, children }) => {
  const { maximizedBlock, setMaximizedBlock } = useContext(
    ResultsDisplayContext
  );

  const [isMinimize, setIsMinimize] = useState(false);

  const isSameBlock =
    maximizedBlock?.phaseIdx === phaseIdx &&
    maximizedBlock?.iterationIdx === iterationIdx;

  return (
    <>
      {!isMinimize && (
        <button
          tw="hidden xl:block absolute top-16 right-2 border border-gray-300 rounded-md p-2"
          onClick={() => {
            setMaximizedBlock(
              !isSameBlock
                ? {
                    phaseIdx,
                    iterationIdx,
                  }
                : undefined
            );
          }}
        >
          {maximizedBlock?.iterationIdx !== iterationIdx ? (
            <Maximize />
          ) : (
            <Minimize />
          )}{" "}
        </button>
      )}

      <div tw="flex bg-gray-50 justify-between items-center w-full mb-1">
        <div tw="flex">
          <div tw="ml-4 z-20 flex items-center justify-center text-sm px-3 py-0 2xl:(text-base px-4 py-2) bg-blue-100 text-blue-700 border-4 border-white font-extrabold rounded-xl">
            {iterationIdx + 1}
          </div>
          <div tw="w-full text-gray-800 px-4 py-2 text-sm 2xl:(text-base px-4 py-3) font-medium">
            <span tw="">Iteration</span>
          </div>
        </div>

        {!maximizedBlock && (
          <button
            tw="hidden xl:block px-3 py-0.5"
            onClick={() => {
              setIsMinimize(!isMinimize);
            }}
          >
            {isMinimize ? (
              <ChevronDown tw="w-5 h-5 z-20" />
            ) : (
              <ChevronUp tw="w-5 h-5 z-20" />
            )}
          </button>
        )}
      </div>

      {!isMinimize && <div tw="space-y-4 px-4 pt-4 pb-8">{children}</div>}
    </>
  );
};

export const ResultBlock = ({ children, ...props }) => {
  return (
    <div
      tw="relative w-full xl:w-1/3 h-full shadow-md rounded-lg bg-white"
      {...props}
    >
      {children}
    </div>
  );
};
