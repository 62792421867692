import { createContext, useState } from "react";
import {
  historicalSeries,
  historicalSeriesWhithoutData,
} from "../trading-series/HistoricalSeries";
import { OFFSET_CHOICES } from "./TrendConfiguration";
import {
  getIndexByDate,
  getOffsetBySelectOffsetValue,
} from "../Results/Dashboard";

export const TrendConfigurationContext = createContext();

export const TrendConfigurationProvider = ({ children }) => {
  const [trendConfig, setTrendConfig] = useState({
    from: new Date(historicalSeriesWhithoutData[3].from),
    to: new Date(historicalSeriesWhithoutData[3].to),
    previsionStart: new Date("2017-05-05T00:00:00"),
    historicalSerie: historicalSeriesWhithoutData
      ? historicalSeriesWhithoutData[3]
      : null,
    offset: OFFSET_CHOICES[0],
    coefficient: 1,
  });

  const configForCalculations = {
    offsetValue: getOffsetBySelectOffsetValue(trendConfig.offset),
    indexParamter: getIndexByDate(
      trendConfig.previsionStart,
      trendConfig.historicalSerie.data ??
        historicalSeries[trendConfig.historicalSerie.id].data
    ),
  };

  return (
    <TrendConfigurationContext.Provider value={{ trendConfig, setTrendConfig, configForCalculations }}>
      {children}
    </TrendConfigurationContext.Provider>
  );
};
