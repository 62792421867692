import { ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import { forwardRef } from "react";
import ReactSelect from "react-select";
import tw from "twin.macro";

const components = {
  DownChevron: ChevronDownIcon,
  CrossIcon: XIcon,
};

const styles = {
  clearIndicator: () =>
    tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors`,
  container: () => tw`relative mt-1`,
  control: (_, state) => [
    tw`flex h-10 bg-white relative w-full border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm`,
    state.isFocused &&
      tw`outline-none ring-1 ring-primary-500 border-primary-500`,
    state.isDisabled && tw`opacity-50`,
  ],
  dropdownIndicator: () =>
    tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors`,
  group: () => tw``,
  groupHeading: () =>
    tw`text-gray-400 text-xs font-medium uppercase tracking-wide px-3 py-1.5`,
  indicatorsContainer: () => tw`flex flex-shrink-0 items-center self-stretch`,
  indicatorSeparator: () => tw`w-px my-2 bg-gray-300 self-stretch`,
  input: () => [
    tw`absolute top-1/2 transform -translate-y-1/2`,
    { "& input": tw`focus:ring-0` },
  ],
  loadingIndicator: () =>
    tw`text-gray-300 flex p-2 hover:text-gray-400 transition-colors [font-size:4px]`,
  loadingMessage: () => tw`text-center text-gray-400 py-2 px-3`,
  menu: () =>
    tw`absolute z-10 mt-1 w-full bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 sm:text-sm`,
  menuList: () => tw`max-h-60 py-1 overflow-auto`,
  multiValue: () =>
    tw`inline-flex items-center py-0.5 pl-2 pr-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800`,
  multiValueLabel: () => tw`truncate`,
  multiValueRemove: () =>
    tw`flex-shrink-0 ml-0.5 h-4 w-4 rounded inline-flex items-center justify-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none focus:bg-gray-500 focus:text-white`,
  noOptionsMessage: () => tw`text-center text-gray-400 py-2 px-3`,
  option: (_, state) => [
    tw`cursor-default select-none py-2 px-3 font-normal text-gray-900 block truncate`,
    state.isDisabled && tw`opacity-50`,
    state.isSelected && tw`font-semibold`,
    state.isFocused && tw`text-white bg-primary-600`,
  ],
  placeholder: () =>
    tw`absolute top-1/2 transform -translate-y-1/2 text-gray-400`,
  singleValue: () => tw`block truncate text-base sm:text-sm text-gray-900`,
  valueContainer: () => tw`flex flex-wrap items-center flex-1 px-3 py-2 gap-1`,
};

/** @type {typeof ReactSelect} */
export const Select = forwardRef((props, ref) => {
  return (
    <ReactSelect ref={ref} components={components} styles={styles} {...props} />
  );
});
