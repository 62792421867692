import { saveAs } from "file-saver";
import { useMutation } from "react-query";
import excelTemplate from "./template.xlsx";
import {
  getIndexByDate,
  getOffsetBySelectOffsetValue,
} from "../../Results/Dashboard";
import { useContext } from "react";
import { TrendConfigurationContext } from "../../trend-config/TrendConfigurationProvider";

const getPhase1DataForExport = (phase1Data, previsionStart, offsetValue) => {
  const indexParamter = getIndexByDate(previsionStart, phase1Data);
  const slicedData = phase1Data.slice(
    indexParamter,
    indexParamter + offsetValue
  );

  return slicedData;
};

export const useExportExcelTemplate = () => {
  const { trendConfig } = useContext(TrendConfigurationContext);

  return useMutation(async ({ phase1Data, phase2Data, phase3Data }) => {
    const { previsionStart, offset } = trendConfig;
    const offsetValue = getOffsetBySelectOffsetValue(offset);

    const { default: ExcelJS } = await import("exceljs");
    const resFile = await (await fetch(excelTemplate)).blob();
    const workbook = new ExcelJS.Workbook();
    const res = await workbook.xlsx.load(resFile);

    const phase3 = workbook.getWorksheet(2);
    const phase2 = workbook.getWorksheet(3);
    const phase1 = workbook.getWorksheet(5);

    // template start at row 39
    const rowIndex = 39;
    let spyLColumn = [];

    phase1Data.forEach((p1, index) => {
      const iterationOffset = index * 40;
      const phase1DataForExport = getPhase1DataForExport(
        p1.phase1Data?.tradingSerie,
        previsionStart,
        offsetValue
      );

      if (index === 0) {
        spyLColumn = phase1DataForExport.map((p) => p.spyLColumn);
      }

      phase1DataForExport?.forEach((d, idx) => {
        phase1.getCell(`A${rowIndex + idx + iterationOffset}`).value = d.timestamp
          ? new Date(d.timestamp * 1000).toISOString().split("T")[0]
          : "";
        phase1.getCell(`B${rowIndex + idx + iterationOffset}`).value = d.close;
        phase1.getCell(`D${rowIndex + idx + iterationOffset}`).value = d.spyLColumn;
        phase1.getCell(`G${rowIndex + idx + iterationOffset}`).value =
          d.consolidatedSpySeriesClose;
        phase1.getCell(`I${rowIndex + idx + iterationOffset}`).value =
          d.stade4Results1ReportIndicators;

        phase1.getCell(`AE${rowIndex + idx + iterationOffset}`).value =
          d.randomRedDeduction3 / (1194.73 * 2);
      });
    });

    phase2Data?.forEach((iterationData, idxIteration) => {
      const iterationOffset = idxIteration * 40;
      iterationData.resultsMethods.forEach((el, idx) => {
        const date = el.timestamp
          ? new Date(el.timestamp * 1000).toISOString().split("T")[0]
          : "";
        phase2.getCell(`A${rowIndex + idx + iterationOffset}`).value = date;
        phase2.getCell(`B${rowIndex + idx + iterationOffset}`).value = el.base;
        phase2.getCell(`D${rowIndex + idx + iterationOffset}`).value =
          spyLColumn[idx];
        phase2.getCell(`G${rowIndex + idx + iterationOffset}`).value = el.t;
        phase2.getCell(`I${rowIndex + idx + iterationOffset}`).value = el.dw;

        phase2.getCell(`AJ${rowIndex + idx + iterationOffset}`).value = date;
        phase2.getCell(`AM${rowIndex + idx + iterationOffset}`).value =
          spyLColumn[idx];
        phase2.getCell(`AK${rowIndex + idx + iterationOffset}`).value = el.base;
        phase2.getCell(`AP${rowIndex + idx + iterationOffset}`).value = el.t;
        phase2.getCell(`AR${rowIndex + idx + iterationOffset}`).value = el.dz;
      });
    });

    phase3Data?.forEach((iterationData, idxIteration) => {
      const iterationOffset = idxIteration * 40;
      iterationData.trendSerie[0].phase3Results.forEach((el, idx) => {
        const date = el.timestamp
          ? new Date(el.timestamp * 1000).toISOString().split("T")[0]
          : "";
        phase3.getCell(`A${rowIndex + idx + iterationOffset}`).value = date;
        phase3.getCell(`D${rowIndex + idx + iterationOffset}`).value =
          spyLColumn[idx] ?? "";
        phase3.getCell(`B${rowIndex + idx + iterationOffset}`).value = el.base;
        phase3.getCell(`G${rowIndex + idx + iterationOffset}`).value =
          el.univSerie;
        phase3.getCell(`I${rowIndex + idx + iterationOffset}`).value =
          el.plannedSerie;
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    let fileName = "export-trend-copilot";
    saveAs(blob, `${fileName}.xlsx`);
  });
};
