// @ts-nocheck
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { fromUnixTime } from "date-fns";
import { memo, useContext } from "react";
import {
  ChartCanvas,
  CrossHairCursor,
  discontinuousTimeScaleProviderBuilder,
  last,
  withSize,
} from "react-financial-charts";
import tw from "twin.macro";
import { Spinner } from "../shared/Spinner";
import { TrendConfigurationContext } from "../trend-config/TrendConfigurationProvider";
import { getIndexByDate, getOffsetBySelectOffsetValue } from "./Dashboard";
import { getChartExtremes } from "../shared/getChartExtremes";
import { Phase2ComputationCharts } from "../trading-ohlc-series/Phase2ComputationCharts";

export const TradingSeriesChartSkeleton = () => {
  return (
    <div tw="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-[5]">
      <Spinner />
    </div>
  );
};

const xScaleProvider =
  discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d.date);
const margin = { left: 0, right: 50, top: 0, bottom: 24 };

const TradingSeriesChartInner = memo(function TradingSeriesChartInner({
  data: tradingSerie,
  width,
  height,
  chartManager: { chartTActive, chart1Active, chart2Active },
  stats,
  isInterpretation,
}) {
  const { trendConfig } = useContext(TrendConfigurationContext);
  const { previsionStart, offset } = trendConfig;
  const offsetValue = getOffsetBySelectOffsetValue(offset);

  const initialDataWithDates = tradingSerie?.map(
    ({ timestamp, ...values }) => ({
      date: fromUnixTime(timestamp),
      timestamp: timestamp,
      ...values,
    })
  );

  const indexParamter = getIndexByDate(previsionStart, initialDataWithDates);

  const slicedData = initialDataWithDates.slice(
    indexParamter,
    indexParamter + offsetValue
  );

  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(slicedData);

  const xExtents = [xAccessor(0), xAccessor(last(data))];

  const buildStatsToDisplayT = () => {
    return {
      "#0166C1": chart1Active ? "method1ResultsReliabilityT" : undefined,
      "#d03eaa": chart2Active ? "method2ResultsReliabilityT" : undefined,
    };
  };

  const buildStatsToDisplayBase = () => {
    return {
      "#0166C1": chart1Active ? "method1ResultsReliabilityBase" : undefined,
      "#d03eaa": chart2Active ? "method2ResultsReliabilityBase" : undefined,
    };
  };

  const statsToDisplay = isInterpretation
    ? buildStatsToDisplayBase()
    : buildStatsToDisplayT();

  const buildLineToDisplay = () => {
    return [
      ...(chartTActive
        ? [
            {
              lines: [
                { color: "black", field: isInterpretation ? "base" : "t" },
              ],
            },
          ]
        : []),
      ...(chart1Active
        ? [
            {
              lines: [{ color: "#0166C1", field: "dw" }],
            },
          ]
        : []),
      ...(chart2Active
        ? [
            {
              lines: [{ color: "#d03eaa", field: "dz" }],
            },
          ]
        : []),
    ];
  };

  const lineToDisplay = buildLineToDisplay();

  const AllYExtents = [];
  lineToDisplay.forEach((el) =>
    el.lines.forEach((line) => {
      AllYExtents.push(line.field);
    })
  );

  const chartExtremes = getChartExtremes(data, AllYExtents);

  const interpretationOffsetToBase = data[0]?.dz
    ? data[0]?.dz - data[0].base
    : undefined;

  const dataWithOffsetShift = data.map((d) => {
    return {
      ...d,
      dz: d.dz - interpretationOffsetToBase,
      dw: d.dw - interpretationOffsetToBase,
    };
  });

  return (
    <>
      <ChartCanvas
        height={height}
        width={width}
        ratio={window.devicePixelRatio}
        margin={margin}
        data={isInterpretation ? dataWithOffsetShift : data}
        displayXAccessor={displayXAccessor}
        xScale={xScale}
        xAccessor={xAccessor}
        // yAccessor={(data) => data["stade3Results1ReportIndicators"]}
        // xExtents={() => [indexParamter - 1, indexParamter + offsetValue + 1]}
        xExtents={xExtents}
        zoomEnabled={true}
      >
        <Phase2ComputationCharts
          id={0}
          origin={[0, 0]}
          displayDateXAxis={true}
          data={isInterpretation ? dataWithOffsetShift : data}
          yExtents={(d) => AllYExtents.map((el) => d[el])}
          lineToDisplay={lineToDisplay}
          chartExtremes={chartExtremes}
          stats={stats}
          statsToDisplay={statsToDisplay}
        />

        <CrossHairCursor />
      </ChartCanvas>
    </>
  );
});

/** @type {import("react").FC<Omit<TradingSeriesChartProps, "width" | "height">>} */
// @ts-ignore
export const Phase2Charts = withSize()(TradingSeriesChartInner);

export const TradingSeriesChartContainer = styled("div")(tw`flex-1 relative`);
