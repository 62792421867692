// @ts-check
/** @type {(yValues: number[]) => { m: number, b: number }} */
export const leastSquares = (yValues) => {
  let xSum = 0;
  let ySum = 0;
  let xySum = 0;
  let xxSum = 0;
  let count = 0;

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (let i = 0; i < yValues.length; i++) {
    const x = i + 1;
    const y = yValues[i];
    xSum += x;
    ySum += y;
    xxSum += x * x;
    xySum += x * y;
    count++;
  }

  /*
   * Calculate m and b for the line equation:
   * y = x * m + b
   */
  const m = (count * xySum - xSum * ySum) / (count * xxSum - xSum * xSum);
  const b = ySum / count - (m * xSum) / count;
  return { m, b };
};
