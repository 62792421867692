import { createContext, useState } from "react";

export const ChartManagerContext = createContext();

const PHASES_STATE = {
  phase1: [
    {
      chartSpyActive: true,
      chartAActive: true,
      chartBActive: true,
      chartCActive: true,
      chartDActive: true,
    },
  ],
  phase2: [
    {
      chartTActive: true,
      chart1Active: true,
      chart2Active: true,
    },
  ],
  phase3: [
    {
      chartBaseActive: true,
      chartUniversalActive: true,
      chartPActive: true,
    },
  ],
};

const INITAL_STATE = {
  results: PHASES_STATE,
  interpretation: PHASES_STATE,
};

export const ChartManagerProvider = ({ children }) => {
  const [chartManager, setChartManager] = useState(INITAL_STATE);

  const initalizeChartIteration = (chartKey, numberOfIteration) => {
    const initialIterationOfResults = [...INITAL_STATE["results"][chartKey]][0];
    const initialIterationOfInterpretation = [
      ...INITAL_STATE["interpretation"][chartKey],
    ][0];

    const resultsIterations = [...Array(numberOfIteration)].map((_p) => {
      return initialIterationOfResults;
    });
    const interpretationIterations = [...Array(numberOfIteration)].map((_p) => {
      return initialIterationOfInterpretation;
    });

    setChartManager({
      ...chartManager,
      results: {
        ...chartManager.results,
        [chartKey]: resultsIterations,
      },
      interpretation: {
        ...chartManager.interpretation,
        [chartKey]: interpretationIterations,
      },
    });
  };

  const addChartIteration = (chartKey) => {
    const initialIterationOfResults = [...INITAL_STATE["results"][chartKey]][0];
    const initialIterationOfInterpretation = [
      ...INITAL_STATE["interpretation"][chartKey],
    ][0];

    const resultsIterations = [
      ...chartManager.results[chartKey],
      initialIterationOfResults,
    ];
    const interpretationIterations = [
      ...chartManager.interpretation[chartKey],
      initialIterationOfInterpretation,
    ];

    setChartManager({
      ...chartManager,
      results: {
        ...chartManager.results,
        [chartKey]: resultsIterations,
      },
      interpretation: {
        ...chartManager.interpretation,
        [chartKey]: interpretationIterations,
      },
    });
  };

  const updateChartPhase = (tabKey, chartKey, field, value) => {
    setChartManager({
      ...chartManager,
      [tabKey]: {
        ...chartManager[tabKey],
        [chartKey]: {
          ...chartManager[tabKey][chartKey],
          [field]: value,
        },
      },
    });
  };

  const updateChartIteration = (
    tabKey,
    chartKey,
    iterationIdx,
    field,
    value
  ) => {
    const chartKeyIterations = [...chartManager[tabKey][chartKey]];
    const iterationToUpdateIdx = chartKeyIterations.findIndex(
      (_i, idx) => idx === iterationIdx
    );
    chartKeyIterations[iterationToUpdateIdx] = {
      ...chartKeyIterations[iterationToUpdateIdx],
      [field]: value,
    };
    setChartManager({
      ...chartManager,
      [tabKey]: {
        ...chartManager[tabKey],
        [chartKey]: chartKeyIterations,
      },
    });
  };

  const resetChartManager = () => {
    setChartManager({ ...INITAL_STATE });
  };

  return (
    <ChartManagerContext.Provider
      value={{
        chartManager,
        updateChartPhase,
        initalizeChartIteration,
        addChartIteration,
        updateChartIteration,
        resetChartManager,
      }}
    >
      {children}
    </ChartManagerContext.Provider>
  );
};
