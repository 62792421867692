/**
 * Function to cumpute reliability between base serie and serie
 * @param valuesSerie the serie to evaluate reliability
 * @param othersValuesSpySeries the variation of T/base in s attribute
 * @param indexParameter the index parameter to start
 * @param offsetValue the offset value
 */
const computeReliability = (
  valuesSerie,
  othersValuesSpySeries,
  indexParameter,
  offsetValue,
  typeRel,
  consolidatedSpySeries
) => {
  let nbIterations = 0;
  let sumSimilarity = 0;

  for (let i = indexParameter; i < indexParameter + offsetValue; i++) {
    const variationT =
      (consolidatedSpySeries[i]?.close - consolidatedSpySeries[i - 1]?.close) /
      consolidatedSpySeries[i - 1]?.close;

    const variationSerieBase =
      typeRel === "base" ? othersValuesSpySeries[i]?.r : variationT;

    // calculate variation of valuesSerie
    const variationSerie =
      (valuesSerie[nbIterations]?.reportIndicators -
        valuesSerie[nbIterations - 1]?.reportIndicators) /
      valuesSerie[nbIterations - 1]?.reportIndicators;

    // v = SI(OU(ET($F53>0;J53>0);ET($F53<0;J53<0));1;"")
    const similarity =
      (variationSerie > 0 && variationSerieBase > 0) ||
      (variationSerie < 0 && variationSerieBase < 0)
        ? 1
        : 0;
    sumSimilarity += similarity;
    nbIterations++;
  }
  return sumSimilarity / nbIterations;
};

/**
 * Function to calculate all reliabilities of phase 1
 * @param phase3Results the phase 3 results
 * @param othersValuesSpySeries the variation values of spy serie consolidated
 * @param indexParameter the index parameter to start
 * @param offsetValue the offset value
 */
const computeAllReliability = (
  phase3Results,
  othersValuesSpySeries,
  indexParameter,
  offsetValue,
  consolidatedSpySeries
) => {
  // Compule reliability with T serie (consolidated spy)
  const method1ResultsReliabilityT = computeReliability(
    phase3Results[0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  const method2ResultsReliabilityT = computeReliability(
    phase3Results[1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "T",
    consolidatedSpySeries
  );

  // Compule reliability with base serie (ohlc serie)
  const method1ResultsReliabilityBase = computeReliability(
    phase3Results[0],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  const method2ResultsReliabilityBase = computeReliability(
    phase3Results[1],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    "base",
    consolidatedSpySeries
  );

  return {
    method1ResultsReliabilityT: method1ResultsReliabilityT,
    method2ResultsReliabilityT: method2ResultsReliabilityT,
    averageT: (method1ResultsReliabilityT + method1ResultsReliabilityT) / 2,

    method1ResultsReliabilityBase: method1ResultsReliabilityBase,
    method2ResultsReliabilityBase: method2ResultsReliabilityBase,
    averageBase:
      (method1ResultsReliabilityBase + method2ResultsReliabilityBase) / 2,
  };
};

/**
 * Function to calculate Stats results of TC Attache Serie Spy
 * @param ohlcSerie the base serie
 * @param starterParams the results of the starter calulation part
 * @param phase1Params the results of the pahase 1 calculation part
 * @param indexParameter the index parameter to start
 * @param offsetValue the offset value
 * @returns the results of the phase 2 calculation
 */
const computePredictionTrendSeriePhase2 = (
  ohlcSerie,
  starterParams,
  phase1Params,
  indexParameter,
  offsetValue
) => {
  // Parameters
  const { consolidatedSpySeries, othersValuesSpySeries } = starterParams;
  const { stade1Results, othersStadesResults } = phase1Params;

  const bk4 = 0.8;

  const resultsOfIteration = [];
  for (let k = 0; k < 3; ++k) {
    let countLines = 0;
    const firstResults = [];

    for (let i = indexParameter; i < indexParameter + offsetValue; ++i) {
      // A = SI(Feuil1!B7>Feuil1!B6;1;-1)
      // Feuill1 B = CLOSE = Serie Spy T
      const a =
        consolidatedSpySeries[i]?.close > consolidatedSpySeries[i - 1]?.close
          ? 1
          : -1;

      // 2. Convert
      // Old calculations
      // AZ = SI(AM6>0;1;-1)
      // BA = SI(AN6>0;1;-1)
      // BB = SI(AO6>0;1;-1)
      // BC = SI(AP6>0;1;-1)
      // BD = SI(AQ6>0;1;-1)
      // const az = am > 0 ? 1 : -1;
      // const ba = an > 0 ? 1 : -1;
      // const bb = ao > 0 ? 1 : -1;
      // const bc = ap > 0 ? 1 : -1;
      // const bd = aq > 0 ? 1 : -1;

      // directly red result
      const az = othersStadesResults[2][0][i]?.indicator1;
      const ba = othersStadesResults[2][0][i]?.indicator2;
      const bb = othersStadesResults[2][0][i]?.indicator3;
      const bc = othersStadesResults[2][0][i]?.indicator4;
      const bd = othersStadesResults[2][0][i]?.indicator5;

      // TO DEL check with excel
      console.log("az, ba, bb, bc, bd", az, ba, bb, bc, bd);

      // 3. Brewing
      const serieToConsolidate = [];

      // Iterate 15 times
      for (let j = 0; j < 15; ++j) {
        // BF to BJ random value
        const bf = Math.random();
        const bg = Math.random();
        const bh = Math.random();
        const bi = Math.random();
        const bj = Math.random();

        // 4. Results of brewing
        // BL = SI(BF20>$BK$4;AZ20;-AZ20)
        const bl = bf > bk4 ? az : -az;
        // BM = SI(BG20>$BK$4;BA20;-BA20)
        const bm = bg > bk4 ? ba : -ba;
        // BN = SI(BH20>$BK$4;BB20;-BB20)
        const bn = bh > bk4 ? bb : -bb;
        // BO = SI(BI20>$BK$4;BC20;-BC20)
        const bo = bi > bk4 ? bc : -bc;
        // BP = SI(BJ20>$BK$4;BD20;-BD20)
        const bp = bj > bk4 ? bd : -bd;

        // bx =SI(SOMME(BL28:BP28)<0;1;-1)
        const bx = bl + bm + bn + bo + bp < 0 ? 1 : -1;

        serieToConsolidate.push({bx, bf, bg, bh, bi, bj, bl, bm, bn, bo, bp});
      }

      // CV =SI(SOMME(CD30:CH30)>0;1;-1)
      const cv =
        serieToConsolidate[0].bx +
          serieToConsolidate[1].bx +
          serieToConsolidate[2].bx +
          serieToConsolidate[3].bx +
          serieToConsolidate[4].bx >
        0
          ? 1
          : -1;
      const cw =
        serieToConsolidate[5].bx +
          serieToConsolidate[6].bx +
          serieToConsolidate[7].bx +
          serieToConsolidate[8].bx +
          serieToConsolidate[9].bx >
        0
          ? 1
          : -1;
      const cx =
        serieToConsolidate[10].bx +
          serieToConsolidate[11].bx +
          serieToConsolidate[12].bx +
          serieToConsolidate[13].bx +
          serieToConsolidate[14].bx >
        0
          ? 1
          : -1;

      // CY =SI(OU(ET($A29>0;CV29>0);ET($A29<0;CV29<0));1;"")
      const cy = (a > 0 && cv > 0) || (a < 0 && cv < 0) ? 1 : 0;
      const cz = (a > 0 && cw > 0) || (a < 0 && cw < 0) ? 1 : 0;
      const da = (a > 0 && cx > 0) || (a < 0 && cx < 0) ? 1 : 0;

      // 7. Affichage par 3 (CV:CX)
      // dc = SI(ABS(SOMME(CV5:CX5))=3;SOMME(CV5:CX5);"")
      const dc = Math.abs(cv + cw + cx) === 3 ? cv + cw + cx : 0;

      // 8. Construction de la Série prévue et à retenir
      // DK = SI(DC6<>"";SI(DC6>0;Feuil1!D6;-Feuil1!D6);"")
      const varSerieT =
        consolidatedSpySeries[i].close - consolidatedSpySeries[i - 1].close;
      const dk = dc !== 0 ? (dc > 0 ? varSerieT : -varSerieT) : 0;

      // db=DB5+SOMME(DJ6:DK6)
      // DB5 - consolidatedSpySeries[i]?.close
      const db =
        i === indexParameter
          ? consolidatedSpySeries[i]?.close
          : firstResults[countLines - 1]?.db + dk;

      firstResults.push({
        a: a,
        serieToConsolidate: serieToConsolidate,
        cv: cv,
        cw: cw,
        cx: cx,
        cy: cy,
        cz: cz,
        da: da,
        dc: dc,
        db: db,
        dk: dk,
        az: az, 
        ba: ba, 
        bb: bb,
        bc: bc,
        bd: bd
      });

      countLines++;
    }

    resultsOfIteration.push({
      intermediateResults: firstResults,
    });
  }

  const resultsMethods = [];
  let sumEB = 0;
  let sumEC = 0;
  let countLine = 0;

  // 10. Results
  for (let i = indexParameter; i < indexParameter + offsetValue; ++i) {
    // ds = SI(DN6>DN5;1;-1)
    const ds =
      resultsOfIteration[0].intermediateResults[countLine]?.db >
      resultsOfIteration[0].intermediateResults[countLine - 1]?.db
        ? 1
        : -1;

    // dt = SI(DO5>DO4;1;-1)
    const dt =
      resultsOfIteration[1].intermediateResults[countLine]?.db >
      resultsOfIteration[1].intermediateResults[countLine - 1]?.db
        ? 1
        : -1;

    // du = SI(DP5>DP4;1;-1)
    const du =
      resultsOfIteration[2].intermediateResults[countLine]?.db >
      resultsOfIteration[2].intermediateResults[countLine - 1]?.db
        ? 1
        : -1;

    // dv = MOYENNE(DN5:DR5)
    const dv =
      (resultsOfIteration[0].intermediateResults[countLine]?.db +
        resultsOfIteration[1].intermediateResults[countLine]?.db +
        resultsOfIteration[2].intermediateResults[countLine]?.db) /
      3;

    // dw = DV5 dw6=SI(SOMME(DS6:DU6)>0;DW5+Feuil1!D6;DW5-Feuil1!D6)
    const dw =
      i === indexParameter
        ? consolidatedSpySeries[i].close
        : ds + dt + du > 0
        ? resultsMethods[countLine - 1]?.dw + stade1Results[0][i]?.d
        : resultsMethods[countLine - 1]?.dw - stade1Results[0][i]?.d;

    // 11. Method 2
    // dy = SI(ABS(SOMME(DG6:DI6))<>0;SOMME(DG6:DI6);"")
    const dy =
      Math.abs(
        resultsOfIteration[0].intermediateResults[countLine]?.dc +
          resultsOfIteration[1].intermediateResults[countLine]?.dc +
          resultsOfIteration[2].intermediateResults[countLine]?.dc
      ) !== 0
        ? resultsOfIteration[0].intermediateResults[countLine]?.dc +
          resultsOfIteration[1].intermediateResults[countLine]?.dc +
          resultsOfIteration[2].intermediateResults[countLine]?.dc
        : 0;

    // ee = SI(EA6<>"";SI(EA6>0;Feuil1!D6;-Feuil1!D6);"")
    const ee =
      dy !== 0
        ? dy > 0
          ? stade1Results[0][i]?.d
          : -stade1Results[0][i]?.d
        : 0;

    // dz = DZ5+SOMME(ED6:EE6)
    const dz =
      i === indexParameter
        ? consolidatedSpySeries[i]?.close
        : resultsMethods[countLine - 1]?.dz + ee;

    // eb = SI(EA6<>"";SI(OU(ET($A6>0;EA6>0);ET($A6<0;EA6<0));1;"");"")
    const eb =
      dy !== 0
        ? (consolidatedSpySeries[i].close > 0 && dy > 0) ||
          (consolidatedSpySeries[i].close < 0 && dy < 0)
          ? 1
          : 0
        : 0;

    // ec = SI(EA6<>"";1;"")
    const ec = dy !== 0 ? 1 : 0;

    sumEB += eb;
    sumEC += ec;

    resultsMethods.push({
      dw: dw,
      ds: ds,
      dt: dt, 
      du: du,
      dv: dv,
      dy: dy,
      dz: dz,
      ee: ee,
      eb: eb,
      ec: ec,
      t: consolidatedSpySeries[i]?.close,
      timestamp: ohlcSerie[i]?.timestamp,
      base: ohlcSerie[i]?.close,
    });
    countLine++;
  }

  // ec4 = SOMME(EB6:EB37)/SOMME(EC6:EC37)
  const ec4 = (sumEB / sumEC) * 100;

  // compute all reliabilities
  const reliabilities = computeAllReliability(
    [
      resultsMethods
        .map((val) => val.dw)
        .map((item) => {
          return {
            reportIndicators: item,
          };
        }),
      resultsMethods
        .map((val) => val.dz)
        .map((item) => {
          return {
            reportIndicators: item,
          };
        }),
    ],
    othersValuesSpySeries,
    indexParameter,
    offsetValue,
    consolidatedSpySeries
  );

  return {
    resultsOfIteration,
    resultsMethods,
    ec4,
    statsPhase2: { reliabilities },
  };
};

/**
 * Function to export data for Excel
 * @param indexParameter the index parameter
 * @param offset the offset
 * @param predictionTrendSerie the results of the phase 2
 * @returns the data array of phase 2 for Excel
 */
const exportDataToExcel = (
  indexParameter,
  offset,
  predictionTrendSerie
) => {
  const exportData = [];
  let countLine = 0;
  for (let i = indexParameter; i < indexParameter + offset; ++i) {
    exportData.push({
      base: predictionTrendSerie.resultsMethods[countLine].base,
      t: predictionTrendSerie.resultsMethods[countLine].t,
      ds: predictionTrendSerie.resultsMethods[countLine].ds,
      dt: predictionTrendSerie.resultsMethods[countLine].dt,
      du: predictionTrendSerie.resultsMethods[countLine].du,
      dv: predictionTrendSerie.resultsMethods[countLine].dv,
      dw: predictionTrendSerie.resultsMethods[countLine].dw,

      dy: predictionTrendSerie.resultsMethods[countLine].dy,
      dz: predictionTrendSerie.resultsMethods[countLine].dz,
      ea: predictionTrendSerie.resultsMethods[countLine].ea,
      eb: predictionTrendSerie.resultsMethods[countLine].eb,
      ec: predictionTrendSerie.resultsMethods[countLine].ec,
      ee: predictionTrendSerie.resultsMethods[countLine].ee,
      timestamp: predictionTrendSerie.resultsMethods[countLine].timestamp,
      ec4: predictionTrendSerie.ec4,

      az: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].az,
      ba: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].ba,
      bb: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].bb,
      bc: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].bc,
      bd: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].bd,

      // Iteration x3
      cvIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].cv,
      cwIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].cw,
      cxIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].cx,
      cyIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].cy,
      czIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].cz,
      daIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].da,
      dkIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].dk,
      dbIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].db,
      dcIteration1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].dc,
      serieToConsolidateIteration1Value1: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[0].bx,
      serieToConsolidateIteration1Value2: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[1].bx,
      serieToConsolidateIteration1Value3: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[2].bx,
      serieToConsolidateIteration1Value4: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[3].bx,
      serieToConsolidateIteration1Value5: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[4].bx,
      serieToConsolidateIteration1Value6: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[5].bx,
      serieToConsolidateIteration1Value7: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[6].bx,
      serieToConsolidateIteration1Value8: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[7].bx,
      serieToConsolidateIteration1Value9: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[8].bx,
      serieToConsolidateIteration1Value10: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[9].bx,
      serieToConsolidateIteration1Value11: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[10].bx,
      serieToConsolidateIteration1Value12: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[11].bx,
      serieToConsolidateIteration1Value13: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[12].bx,
      serieToConsolidateIteration1Value14: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[13].bx,
      serieToConsolidateIteration1Value15: predictionTrendSerie.resultsOfIteration[0].intermediateResults[countLine].serieToConsolidate[14].bx,

      cvIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].cv,
      cwIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].cw,
      cxIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].cx,
      cyIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].cy,
      czIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].cz,
      daIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].da,
      dkIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].dk,
      dbIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].db,
      dcIteration2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].dc,
      serieToConsolidateIteration2Value1: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[0].bx,
      serieToConsolidateIteration2Value2: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[1].bx,
      serieToConsolidateIteration2Value3: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[2].bx,
      serieToConsolidateIteration2Value4: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[3].bx,
      serieToConsolidateIteration2Value5: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[4].bx,
      serieToConsolidateIteration2Value6: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[5].bx,
      serieToConsolidateIteration2Value7: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[6].bx,
      serieToConsolidateIteration2Value8: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[7].bx,
      serieToConsolidateIteration2Value9: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[8].bx,
      serieToConsolidateIteration2Value10: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[9].bx,
      serieToConsolidateIteration2Value11: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[10].bx,
      serieToConsolidateIteration2Value12: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[11].bx,
      serieToConsolidateIteration2Value13: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[12].bx,
      serieToConsolidateIteration2Value14: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[13].bx,
      serieToConsolidateIteration2Value15: predictionTrendSerie.resultsOfIteration[1].intermediateResults[countLine].serieToConsolidate[14].bx,

      cvIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].cv,
      cwIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].cw,
      cxIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].cx,
      cyIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].cy,
      czIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].cz,
      daIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].da,
      dkIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].dk,
      dbIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].db,
      dcIteration3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].dc,
      serieToConsolidateIteration3Value1: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[0].bx,
      serieToConsolidateIteration3Value2: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[1].bx,
      serieToConsolidateIteration3Value3: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[2].bx,
      serieToConsolidateIteration3Value4: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[3].bx,
      serieToConsolidateIteration3Value5: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[4].bx,
      serieToConsolidateIteration3Value6: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[5].bx,
      serieToConsolidateIteration3Value7: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[6].bx,
      serieToConsolidateIteration3Value8: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[7].bx,
      serieToConsolidateIteration3Value9: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[8].bx,
      serieToConsolidateIteration3Value10: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[9].bx,
      serieToConsolidateIteration3Value11: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[10].bx,
      serieToConsolidateIteration3Value12: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[11].bx,
      serieToConsolidateIteration3Value13: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[12].bx,
      serieToConsolidateIteration3Value14: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[13].bx,
      serieToConsolidateIteration3Value15: predictionTrendSerie.resultsOfIteration[2].intermediateResults[countLine].serieToConsolidate[14].bx,
      index: i,
    });
    countLine++;
  }
  return exportData;

};

/**
 * Function called by the front
 * @param phase1Params the results of the phase 1
 * @param indexParameter the index parameter to start
 * @param offset the offset value
 * @param starterParams the results of the starter phase
 * @returns predictionTrendSerie the results of the phase 2 calculation
 */
export function calculateTrendPhase2(
  ohlcSerie,
  phase1Params,
  indexParameter,
  offset,
  starterParams
) {
  const predictionTrendSerie = computePredictionTrendSeriePhase2(
    ohlcSerie,
    starterParams,
    phase1Params,
    indexParameter,
    offset
  );

  const exportData = exportDataToExcel(
    indexParameter,
    offset,
    predictionTrendSerie
  );

  console.log(exportData);
  predictionTrendSerie.exportData = exportData;

  return predictionTrendSerie;
}
