/** @jsxImportSource @emotion/react */
import "twin.macro";
import { ButtonGroup } from "../shared/ButtonGroup";
import { Phase3Charts } from "./Phase3Charts";
import { formatPercentage } from "../shared/formatPercentage";
import { useContext } from "react";
import { ChartManagerContext } from "./ChartManagerProvider";

const CHART_MANAGER_KEY = "phase3";

const Phase3 = ({ data, isInterpretation, iterationIdx }) => {
  const { chartManager, updateChartIteration } =
    useContext(ChartManagerContext);

  const TAB_KEY = isInterpretation ? "interpretation" : "results";

  const baseBtns = [
    {
      label: "B",
      color: "gray",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartBaseActive ?? true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartBaseActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartBaseActive
        );
      },
    },
    {
      label: "U",
      color: "blue",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartUniversalActive ??
        true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartUniversalActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartUniversalActive
        );
      },
    },
  ];

  const computePredictionsBtns = [
    {
      label: "P",
      color: "#009A3D",
      defaultActive:
        chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartPActive ?? true,
      action: () => {
        updateChartIteration(
          TAB_KEY,
          CHART_MANAGER_KEY,
          iterationIdx,
          "chartPActive",
          !chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]?.chartPActive
        );
      },
    },
  ];

  const stats = [
    data.reliabilities?.phase3ReliabilityBase,
    data.reliabilities?.phase3ReliabilityUniversal,
  ];

  return (
    <div tw="h-full">
      <div tw="flex justify-between items-center mb-2 pr-14">
        <div tw="flex justify-between items-center">
          <ButtonGroup buttons={baseBtns} />
        </div>
        <div tw="flex flex-col items-center">
          {data?.reliabilities && (
            <div tw="flex divide-x">
              {stats.map((el, idx) => (
                <div
                  key={`stats-percentage-${idx}`}
                  tw="text-xs first:pl-0 last:pr-0 px-1"
                >
                  {formatPercentage(el)}%
                </div>
              ))}
            </div>
          )}
          <div tw="px-2">
            <ButtonGroup buttons={computePredictionsBtns} />
          </div>
        </div>
      </div>

      <div tw="[height: calc(80vh - 13rem)] w-full">
        {data && chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx] ? (
          <Phase3Charts
            data={data}
            chartManager={chartManager[TAB_KEY][CHART_MANAGER_KEY][iterationIdx]}
            isInterpretation={isInterpretation}
          />
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
};

export default Phase3;
