/** @jsxImportSource @emotion/react */
import { Link } from "react-router-dom";
import tw from "twin.macro";
import TrendConfiguration from "../trend-config/TrendConfiguration";
import { BlackLogo, WhiteLogo } from "./Logo";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const secondaryNavigation = [];

export const Layout = ({ children }) => {
  const { logout, user } = useAuth0();

  const userNavigation = [
    {
      name: "Sign out",
      onClick: () => {
        logout({ returnTo: window.location.origin });
      },
    },
  ];

  return (
    <div tw="h-screen bg-white overflow-hidden flex ">
      {/* Static sidebar for desktop */}
      <div tw="hidden md:flex md:flex-shrink-0">
        <div tw="w-60 2xl:w-80 flex flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <nav tw="bg-white border-r border-gray-300 pb-4 flex flex-col flex-grow overflow-y-auto">
            <div tw="flex-shrink-0 px-4 flex justify-center items-center   pt-4 pb-4">
              <BlackLogo tw="h-7 w-auto" />
            </div>

            <div tw="h-full pt-4 px-3 2xl:px-6">
              <TrendConfiguration />
            </div>
            <div tw="flex-shrink-0 block w-full">
              {secondaryNavigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.to}
                  className="group"
                  tw="border-l-4 border-transparent py-2 px-3 flex items-center text-sm font-medium text-gray-600 hover:text-gray-900 hover:bg-gray-50"
                >
                  <item.icon
                    tw="text-gray-400 group-hover:text-gray-500 mr-3 h-6 w-6"
                    aria-hidden="true"
                  />
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
        </div>
      </div>

      {/* Content area */}
      <div tw="flex-1 flex flex-col">
        <div tw="border-b border-gray-300 w-full flex justify-end py-4 px-4">
          {/* Profile dropdown */}
          <Menu as="div" tw="relative">
            <Menu.Button tw="-m-1.5 flex items-center p-1.5">
              <span tw="sr-only">Open user menu</span>
              {user?.picture && (
                <img
                  tw="h-8 w-8 rounded-full"
                  src={user?.picture}
                  alt=""
                  width={256}
                  height={256}
                />
              )}
              <span tw="hidden lg:flex lg:items-center">
                <span
                  tw="ml-4 text-sm font-semibold leading-6"
                  aria-hidden="true"
                >
                  {user ? user?.email : ""}
                </span>
                <ChevronDownIcon
                  tw="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items tw="absolute right-0 z-10 mt-2.5 w-32 origin-top-right py-2 bg-white border-2 border-gray-100 rounded-md ">
                {userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <button
                        onClick={item.onClick}
                        css={[
                          tw`block px-3 py-1 text-sm leading-6 `,
                          active && tw``,
                        ]}
                      >
                        {item.name}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
        <main tw="flex-1 overflow-y-auto focus:outline-none bg-slate-50">
          {children}
        </main>
      </div>
    </div>
  );
};
