/** @jsxImportSource @emotion/react */

import "twin.macro";
import { MainChart } from "./MainChart";

const Preview = ({previewData}) => {
  
  return (
    <div tw="[height: calc(100vh - 14rem)]  w-full">
       <MainChart data={previewData} />
    </div>
  );
};

export default Preview;
