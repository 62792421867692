import styled from "@emotion/styled";
import tw from "twin.macro";

export const Page = styled("div")(tw`pt-10 pb-16`);

export const PageTitle = styled("h1")(
  tw`text-3xl font-extrabold text-gray-900`
);

export const PageContent = styled("div")(tw`py-6 space-y-10`);
