// @ts-nocheck
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { fromUnixTime } from "date-fns";
import { memo } from "react";
import {
  ChartCanvas,
  discontinuousTimeScaleProviderBuilder,
  withSize,
} from "react-financial-charts";
import "twin.macro";
import tw from "twin.macro";
import { Spinner } from "../shared/Spinner";
import { TradingOHLCSerieChart } from "../trading-ohlc-series/TradingOHLCSerieChart";

export const TradingSeriesChartSkeleton = () => {
  return (
    <div tw="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-[5]">
      <Spinner />
    </div>
  );
};

const xScaleProvider =
  discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d.date);
const margin = { left: 0, right: 60, top: 0, bottom: 24 };

const TradingSeriesChartInner = memo(function TradingSeriesChartInner({
  data: { tradingSerie, seriesName },
  width,
  height,
}) {
  const initialDataWithDates = tradingSerie?.map(
    ({ timestamp, ...values }) => ({
      date: fromUnixTime(timestamp),
      ...values,
    })
  );

  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(initialDataWithDates);

  // Calculate min and max value for the chart
  const min = Math.min(
    ...data.map((value) =>
      Math.min(
        value.consolidatedSpySeriesClose,
        value.open,
        value.high,
        value.low,
        value.close
      )
    )
  );
  const max = Math.max(
    ...data.map((value) =>
      Math.max(
        value.consolidatedSpySeriesClose,
        value.open,
        value.high,
        value.low,
        value.close
      )
    )
  );

  return (
    <>
      <ChartCanvas
        height={height}
        width={width}
        ratio={window.devicePixelRatio}
        margin={margin}
        seriesName={seriesName}
        data={data}
        displayXAccessor={displayXAccessor}
        xScale={xScale}
        xAccessor={xAccessor}
        pointsPerPxThreshold={5}
        xExtents={() => [0, data.length]}
      >
        <TradingOHLCSerieChart
          id={0}
          origin={[0, 0]}
          displayDateXAxis={true}
          data={data}
          yExtents={[max, min]}
        />
      </ChartCanvas>
    </>
  );
});

/** @type {import("react").FC<Omit<TradingSeriesChartProps, "width" | "height">>} */
// @ts-ignore
export const MainChart = withSize()(TradingSeriesChartInner);

export const TradingSeriesChartContainer = styled("div")(tw`flex-1 relative`);
