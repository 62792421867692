// @ts-nocheck
import { format } from "d3-format";
import { timeFormat } from "d3-time-format";
import { Fragment } from "react";
import {
  CandlestickSeries,
  Chart,
  CurrentCoordinate,
  LineSeries,
  MouseCoordinateX,
  MouseCoordinateY,
  OHLCTooltip,
  XAxis,
  YAxis,
} from "react-financial-charts";
import { theme } from "twin.macro";

/**
 * @typedef {{
 * id: string | number;
 * yExtents: number[] | ((data: any) => number) | ((data: any) => number[]);
 * data: { date: Date, open: number, high: number, low: number, close: number }[];
 * origin: number[] | ((width: number, height: number) => number[]);
 * height: number;
 * displayDateXAxis?: boolean;
 * }} TradingOHLCSerieChartProps
 */
/** @type {import("react").FC<TradingOHLCSerieChartProps>} */
export const TradingOHLCSerieChart = ({
  id,
  yExtents,
  data,
  origin,
  height,
  displayDateXAxis = false,
}) => {
  const pricesDisplayFormat = format(".4f");
  const timeDisplayFormat = timeFormat("%d %b %Y %H:%M");
  const valueDisplayFormat = format(".4f");

  return (
    <Chart id={id} origin={origin} height={height} yExtents={yExtents}>
      {displayDateXAxis ? (
        <XAxis
          showGridLines
          tickFormat={(index) => {
            return typeof index === "number"
              ? timeDisplayFormat(data[index].date)
              : timeDisplayFormat(index);
          }}
          tickStrokeStyle={theme`colors.gray.300`}
          strokeStyle={theme`colors.gray.300`}
          gridLinesStrokeStyle={theme`colors.gray.200`}
        />
      ) : (
        <XAxis
          showGridLines
          showTickLabel={false}
          showTicks={false}
          axisAt="bottom"
          strokeStyle={theme`colors.gray.300`}
          gridLinesStrokeStyle={theme`colors.gray.200`}
        />
      )}
      <YAxis
        showGridLines
        tickFormat={pricesDisplayFormat}
        tickStrokeStyle={theme`colors.gray.300`}
        strokeStyle={theme`colors.gray.300`}
        gridLinesStrokeStyle={theme`colors.gray.200`}
      />

      <CandlestickSeries />

      <Fragment>
        <CurrentCoordinate
          yAccessor={(data) => data["consolidatedSpySeriesClose"]}
          fillStyle="black"
        />
        <LineSeries
          yAccessor={(data) => data["consolidatedSpySeriesClose"]}
          strokeStyle="black"
        />
        <MouseCoordinateY displayFormat={valueDisplayFormat} rectWidth={60} />
      </Fragment>

      <MouseCoordinateX displayFormat={timeDisplayFormat} />
      <MouseCoordinateY displayFormat={pricesDisplayFormat} rectWidth={60} />
      <OHLCTooltip
        origin={[8, 16]}
        ohlcFormat={pricesDisplayFormat}
        changeFormat={pricesDisplayFormat}
      />
    </Chart>
  );
};
