
export const calculatePhase3AvgStats = (phase3AllCalculations) => {
    let allReliability = {
        ai2Total: 0,
        ao2Total: 0,
        ap2Total: 0,
        count: 0
    };

    if (phase3AllCalculations) {
        phase3AllCalculations.forEach((calculation) => {
            allReliability = {
                ai2Total : allReliability.ai2Total + calculation.trendSerie[0].ai2,
                ao2Total : allReliability.ao2Total + calculation.trendSerie[0].ao2,
                ap2Total : allReliability.ap2Total + calculation.trendSerie[0].ap2,
                count: allReliability.count + 1
            };
        });
    
        return {
            ai2Avg: allReliability.ai2Total / allReliability.count,
            ao2Avg: allReliability.ao2Total / allReliability.count,
            ap2Avg: allReliability.ap2Total / allReliability.count,
        }
    }

};