import styled from "@emotion/styled";
import tw from "twin.macro";

export const Button = styled("button")(
  tw`inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`
);

export const PrimaryButton = styled("button")(
  tw`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500`
);

export const LeadingButtonIcon = styled("svg")(tw`-ml-1 mr-2 h-5 w-5`);
LeadingButtonIcon.defaultProps = {
  "aria-hidden": "true",
};

export const TrailingButtonIcon = styled("svg")(tw`ml-2 -mr-1 h-5 w-5`);
LeadingButtonIcon.defaultProps = {
  "aria-hidden": "true",
};
