
export const getChartExtremes = (data, fieldsToCheck) => {
  const min = Math.min(
    ...data.map((value) =>
      Math.min(
        ...(fieldsToCheck.map((field) =>
          isNaN(value[field]) ? Infinity : value[field]
        ))
      )
    )
  );

  const max = Math.max(
    ...data.map((value) =>
      Math.max(
        ...(fieldsToCheck.map((field) =>
          isNaN(value[field]) ? -Infinity : value[field]
        ))
      )
    )
  );

  return { min, max };
};
