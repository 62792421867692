// @ts-nocheck
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { fromUnixTime } from "date-fns";
import { memo, useContext } from "react";
import {
  ChartCanvas,
  CrossHairCursor,
  discontinuousTimeScaleProviderBuilder,
  last,
  withSize,
} from "react-financial-charts";
import tw from "twin.macro";
import { Spinner } from "../shared/Spinner";
import { Phase1ComputationCharts } from "../trading-ohlc-series/Phase1ComputationCharts";
import { TrendConfigurationContext } from "../trend-config/TrendConfigurationProvider";
import { getIndexByDate, getOffsetBySelectOffsetValue } from "./Dashboard";
import { getChartExtremes } from "../shared/getChartExtremes";

export const TradingSeriesChartSkeleton = () => {
  return (
    <div tw="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-[5]">
      <Spinner />
    </div>
  );
};

const xScaleProvider =
  discontinuousTimeScaleProviderBuilder().inputDateAccessor((d) => d.date);
const margin = { left: 0, right: 50, top: 0, bottom: 24 };

/**
 * @typedef {{
 * data: {
 *    tradingSerie: import("../trading-series/TradingSerieAPI").TradingSerie;
 *    seriesName: string;
 *    predictionSerieConfigurations: import("../trading-prediction-series/TradingPredictionSerieAPI").PredictionSerieConfiguration[];
 *  },
 * width: number;
 * height: number;
 * }} TradingSeriesChartProps
 */
/** @type {import("react").FC<TradingSeriesChartProps>} */
const TradingSeriesChartInner = memo(function TradingSeriesChartInner({
  data: { tradingSerie, seriesName },
  width,
  height,
  chartManager: {
    chartSpyActive,
    chartAActive,
    chartBActive,
    chartCActive,
    chartDActive,
  },
  stats,
  isInterpretation,
}) {
  const buildExtraLineToDisplay = () => {
    return {
      black: {
        black: chartSpyActive
          ? [isInterpretation ? "close" : "consolidatedSpySeriesClose"]
          : [],
      },
      gray: {
        "#7a7a7a": chartAActive ? ["stade1Results1ReportIndicators"] : [],
        "#b8b8b8": chartAActive ? ["stade1Results2ReportIndicators"] : [],
        "#dbdbdb": chartAActive ? ["stade1Results3ReportIndicators"] : [],
        "#919191": chartAActive ? ["stade1Results4ReportIndicators"] : [],
        "#333131": chartAActive ? ["stade1Results5ReportIndicators"] : [],
      },
      yellow: {
        "#fde047": chartBActive ? ["stade2Results1ReportIndicators"] : [],
        "#ffbb29": chartBActive ? ["stade2Results2ReportIndicators"] : [],
        "#eded21": chartBActive ? ["stade2Results3ReportIndicators"] : [],
        "#cbed21": chartBActive ? ["stade2Results4ReportIndicators"] : [],
        "#eeff00": chartBActive ? ["stade2Results5ReportIndicators"] : [],
      },
      blue: {
        "#1e00b5": chartCActive ? ["stade3Results1ReportIndicators"] : [],
        "#87e1ff": chartCActive ? ["stade3Results2ReportIndicators"] : [],
        "#30a9e6": chartCActive ? ["stade3Results3ReportIndicators"] : [],
        "#c4e2ff": chartCActive ? ["stade3Results4ReportIndicators"] : [],
        "#0554ff": chartCActive ? ["stade3Results5ReportIndicators"] : [],
      },
      red: {
        red: chartDActive ? ["stade4Results1ReportIndicators"] : [],
        "#fc7272": chartDActive
          ? [isInterpretation ? "redDeduction3" : ""]
          : [],
      },
    };
  };

  const extraLineToDisplay = buildExtraLineToDisplay();

  const AllYExtents = [];
  Object.keys(extraLineToDisplay).map((key) => {
    return Object.keys(extraLineToDisplay[key]).map((colorGroup, index) => {
      return extraLineToDisplay[key][colorGroup].map((line) => {
        AllYExtents.push(line);
      });
    });
  });

  const { trendConfig } = useContext(TrendConfigurationContext);
  const { previsionStart, offset } = trendConfig;
  const offsetValue = getOffsetBySelectOffsetValue(offset);

  const initialDataWithDates = tradingSerie?.map(
    ({ timestamp, ...values }) => ({
      date: fromUnixTime(timestamp),
      timestamp: timestamp,
      ...values,
    })
  );
  const indexParamter = getIndexByDate(previsionStart, initialDataWithDates);

  const slicedData = initialDataWithDates.slice(
    indexParamter,
    indexParamter + offsetValue + 1
  );

  const { data, xScale, xAccessor, displayXAccessor } =
    xScaleProvider(slicedData);

  const chartExtremes = getChartExtremes(data, AllYExtents);

  const xExtents = [xAccessor(0), xAccessor(last(data))];

  const buildStatsToDisplayT = () => {
    return {
      gray: {
        "#7a7a7a": chartAActive ? "stade1Results1ReliabilityT" : undefined,
        "#b8b8b8": chartAActive ? "stade1Results2ReliabilityT" : undefined,
        "#dbdbdb": chartAActive ? "stade1Results3ReliabilityT" : undefined,
        "#919191": chartAActive ? "stade1Results4ReliabilityT" : undefined,
        "#333131": chartAActive ? "stade1Results5ReliabilityT" : undefined,
      },
      yellow: {
        "#fde047": chartBActive ? "stade2Results1ReliabilityT" : undefined,
        "#ffbb29": chartBActive ? "stade2Results2ReliabilityT" : undefined,
        "#eded21": chartBActive ? "stade2Results3ReliabilityT" : undefined,
        "#cbed21": chartBActive ? "stade2Results4ReliabilityT" : undefined,
        "#eeff00": chartBActive ? "stade2Results5ReliabilityT" : undefined,
      },
      blue: {
        "#1e00b5": chartCActive ? "stade3Results1ReliabilityT" : undefined,
        "#87e1ff": chartCActive ? "stade3Results2ReliabilityT" : undefined,
        "#30a9e6": chartCActive ? "stade3Results3ReliabilityT" : undefined,
        "#c4e2ff": chartCActive ? "stade3Results4ReliabilityT" : undefined,
        "#0554ff": chartCActive ? "stade3Results5ReliabilityT" : undefined,
      },
      red: {
        red: chartDActive ? "stade4Results1ReliabilityT" : undefined,
      },
    };
  };

  const buildStatsToDisplayBase = () => {
    return {
      gray: {
        "#7a7a7a": chartAActive ? "stade1Results1ReliabilityBase" : undefined,
        "#b8b8b8": chartAActive ? "stade1Results2ReliabilityBase" : undefined,
        "#dbdbdb": chartAActive ? "stade1Results3ReliabilityBase" : undefined,
        "#919191": chartAActive ? "stade1Results4ReliabilityBase" : undefined,
        "#333131": chartAActive ? "stade1Results5ReliabilityBase" : undefined,
      },
      yellow: {
        "#fde047": chartBActive ? "stade2Results1ReliabilityBase" : undefined,
        "#ffbb29": chartBActive ? "stade2Results2ReliabilityBase" : undefined,
        "#eded21": chartBActive ? "stade2Results3ReliabilityBase" : undefined,
        "#cbed21": chartBActive ? "stade2Results4ReliabilityBase" : undefined,
        "#eeff00": chartBActive ? "stade2Results5ReliabilityBase" : undefined,
      },
      blue: {
        "#1e00b5": chartCActive ? "stade3Results1ReliabilityBase" : undefined,
        "#87e1ff": chartCActive ? "stade3Results2ReliabilityBase" : undefined,
        "#30a9e6": chartCActive ? "stade3Results3ReliabilityBase" : undefined,
        "#c4e2ff": chartCActive ? "stade3Results4ReliabilityBase" : undefined,
        "#0554ff": chartCActive ? "stade3Results5ReliabilityBase" : undefined,
      },
      red: {
        red: chartDActive ? "stade4Results1ReliabilityBase" : undefined,
      },
    };
  };

  const statsToDisplay = isInterpretation
    ? buildStatsToDisplayBase()
    : buildStatsToDisplayT();

  const buildDataWithOffset = (
    series,
    interpretationValue,
    idx,
    tOffset,
    field
  ) => {
    const fieldVariation =
      idx === 0
        ? 0
        : (series[idx][field] - series[idx - 1][field]) /
          series[idx - 1][field];

    return idx === 0
      ? series[idx].close
      : (fieldVariation - tOffset + 1) * interpretationValue[idx - 1][field];
    // }
  };

  const interpretationValue = [];

  const dataWithOffsetShift = data.map((d, idx) => {
    interpretationValue.push({
      ...d,
      stade1Results1ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade1Results1ReportIndicators"
      ),
      stade1Results2ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade1Results2ReportIndicators"
      ),
      stade1Results3ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade1Results3ReportIndicators"
      ),
      stade1Results4ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade1Results4ReportIndicators"
      ),
      stade1Results5ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade1Results5ReportIndicators"
      ),
      stade2Results1ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade2Results1ReportIndicators"
      ),
      stade2Results2ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade2Results2ReportIndicators"
      ),
      stade2Results3ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade2Results3ReportIndicators"
      ),
      stade2Results4ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade2Results4ReportIndicators"
      ),
      stade2Results5ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade2Results5ReportIndicators"
      ),
      stade3Results1ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade3Results1ReportIndicators"
      ),
      stade3Results2ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade3Results2ReportIndicators"
      ),
      stade3Results3ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade3Results3ReportIndicators"
      ),
      stade3Results4ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade3Results4ReportIndicators"
      ),
      stade3Results5ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade3Results5ReportIndicators"
      ),
      stade4Results1ReportIndicators: buildDataWithOffset(
        data,
        interpretationValue,
        idx,
        d.spyLColumn,
        "stade4Results1ReportIndicators"
      ),
    });

    return {
      ...d,
      stade1Results1ReportIndicators:
        interpretationValue[idx].stade1Results1ReportIndicators,
      stade1Results2ReportIndicators:
        interpretationValue[idx].stade1Results2ReportIndicators,
      stade1Results3ReportIndicators:
        interpretationValue[idx].stade1Results3ReportIndicators,
      stade1Results4ReportIndicators:
        interpretationValue[idx].stade1Results4ReportIndicators,
      stade1Results5ReportIndicators:
        interpretationValue[idx].stade1Results5ReportIndicators,
      stade2Results1ReportIndicators:
        interpretationValue[idx].stade2Results1ReportIndicators,
      stade2Results2ReportIndicators:
        interpretationValue[idx].stade2Results2ReportIndicators,
      stade2Results3ReportIndicators:
        interpretationValue[idx].stade2Results3ReportIndicators,
      stade2Results4ReportIndicators:
        interpretationValue[idx].stade2Results4ReportIndicators,
      stade2Results5ReportIndicators:
        interpretationValue[idx].stade2Results5ReportIndicators,
      stade3Results1ReportIndicators:
        interpretationValue[idx].stade3Results1ReportIndicators,
      stade3Results2ReportIndicators:
        interpretationValue[idx].stade3Results2ReportIndicators,
      stade3Results3ReportIndicators:
        interpretationValue[idx].stade3Results3ReportIndicators,
      stade3Results4ReportIndicators:
        interpretationValue[idx].stade3Results4ReportIndicators,
      stade3Results5ReportIndicators:
        interpretationValue[idx].stade3Results5ReportIndicators,
      stade4Results1ReportIndicators:
        interpretationValue[idx].stade4Results1ReportIndicators,
      redDeduction3: interpretationValue[idx].redDeduction3,
    };
  });

  return (
    <>
      <ChartCanvas
        height={height}
        width={width}
        ratio={window.devicePixelRatio}
        margin={margin}
        seriesName={seriesName}
        data={isInterpretation ? dataWithOffsetShift : data}
        displayXAccessor={displayXAccessor}
        xScale={xScale}
        xAccessor={xAccessor}
        // yAccessor={(data) => data["stade3Results1ReportIndicators"]}
        // xExtents={() => [indexParamter - 1, indexParamter + offsetValue + 1]}
        xExtents={xExtents}
        zoomEnabled={true}
      >
        <Phase1ComputationCharts
          id={0}
          origin={[0, 0]}
          displayDateXAxis={true}
          data={isInterpretation ? dataWithOffsetShift : data}
          yExtents={(d) => AllYExtents.map((el) => d[el])}
          extraLineToDisplay={extraLineToDisplay}
          chartExtremes={chartExtremes}
          stats={stats}
          statsToDisplay={statsToDisplay}
        />
        <CrossHairCursor />
      </ChartCanvas>
    </>
  );
});

/** @type {import("react").FC<Omit<TradingSeriesChartProps, "width" | "height">>} */
// @ts-ignore
export const Phase1Charts = withSize()(TradingSeriesChartInner);

export const TradingSeriesChartContainer = styled("div")(tw`flex-1 relative`);
