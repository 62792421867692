import { saveAs } from "file-saver";
import { useMutation } from "react-query";

export const seriesPhase2 = [
  {
    name: "Base values",
    keys: [
      // init values
      { label: "Date", key: "date" },
      { label: "Close", key: "close" },
      { label: "Close T", key: "closeT" },
      { label: "Close indicator", key: "closeIndicator" },

      { label: "az", key: "az" },
      { label: "ba", key: "ba" },
      { label: "bb", key: "bb" },
      { label: "bc", key: "bc" },
      { label: "bd", key: "bd" },

      { label: "serieToConsolidateIteration1Value1", key: "serieToConsolidateIteration1Value1" },
      { label: "serieToConsolidateIteration1Value2", key: "serieToConsolidateIteration1Value2" },
      { label: "serieToConsolidateIteration1Value3", key: "serieToConsolidateIteration1Value3" },
      { label: "serieToConsolidateIteration1Value4", key: "serieToConsolidateIteration1Value4" },
      { label: "serieToConsolidateIteration1Value5", key: "serieToConsolidateIteration1Value5" },
      { label: "serieToConsolidateIteration1Value6", key: "serieToConsolidateIteration1Value6" },
      { label: "serieToConsolidateIteration1Value7", key: "serieToConsolidateIteration1Value7" },
      { label: "serieToConsolidateIteration1Value8", key: "serieToConsolidateIteration1Value8" },
      { label: "serieToConsolidateIteration1Value9", key: "serieToConsolidateIteration1Value9" },
      { label: "serieToConsolidateIteration1Value10", key: "serieToConsolidateIteration1Value10" },
      { label: "serieToConsolidateIteration1Value11", key: "serieToConsolidateIteration1Value11" },
      { label: "serieToConsolidateIteration1Value12", key: "serieToConsolidateIteration1Value12" },
      { label: "serieToConsolidateIteration1Value13", key: "serieToConsolidateIteration1Value13" },
      { label: "serieToConsolidateIteration1Value14", key: "serieToConsolidateIteration1Value14" },
      { label: "serieToConsolidateIteration1Value15", key: "serieToConsolidateIteration1Value15" },
      { label: "serieToConsolidateIteration2Value1", key: "serieToConsolidateIteration2Value1" },
      { label: "serieToConsolidateIteration2Value2", key: "serieToConsolidateIteration2Value2" },
      { label: "serieToConsolidateIteration2Value3", key: "serieToConsolidateIteration2Value3" },
      { label: "serieToConsolidateIteration2Value4", key: "serieToConsolidateIteration2Value4" },
      { label: "serieToConsolidateIteration2Value5", key: "serieToConsolidateIteration2Value5" },
      { label: "serieToConsolidateIteration2Value6", key: "serieToConsolidateIteration2Value6" },
      { label: "serieToConsolidateIteration2Value7", key: "serieToConsolidateIteration2Value7" },
      { label: "serieToConsolidateIteration2Value8", key: "serieToConsolidateIteration2Value8" },
      { label: "serieToConsolidateIteration2Value9", key: "serieToConsolidateIteration2Value9" },
      { label: "serieToConsolidateIteration2Value10", key: "serieToConsolidateIteration2Value10" },
      { label: "serieToConsolidateIteration2Value11", key: "serieToConsolidateIteration2Value11" },
      { label: "serieToConsolidateIteration2Value12", key: "serieToConsolidateIteration2Value12" },
      { label: "serieToConsolidateIteration2Value13", key: "serieToConsolidateIteration2Value13" },
      { label: "serieToConsolidateIteration2Value14", key: "serieToConsolidateIteration2Value14" },
      { label: "serieToConsolidateIteration2Value15", key: "serieToConsolidateIteration2Value15" },
      { label: "serieToConsolidateIteration3Value1", key: "serieToConsolidateIteration3Value1" },
      { label: "serieToConsolidateIteration3Value2", key: "serieToConsolidateIteration3Value2" },
      { label: "serieToConsolidateIteration3Value3", key: "serieToConsolidateIteration3Value3" },
      { label: "serieToConsolidateIteration3Value4", key: "serieToConsolidateIteration3Value4" },
      { label: "serieToConsolidateIteration3Value5", key: "serieToConsolidateIteration3Value5" },
      { label: "serieToConsolidateIteration3Value6", key: "serieToConsolidateIteration3Value6" },
      { label: "serieToConsolidateIteration3Value7", key: "serieToConsolidateIteration3Value7" },
      { label: "serieToConsolidateIteration3Value8", key: "serieToConsolidateIteration3Value8" },
      { label: "serieToConsolidateIteration3Value9", key: "serieToConsolidateIteration3Value9" },
      { label: "serieToConsolidateIteration3Value10", key: "serieToConsolidateIteration3Value10" },
      { label: "serieToConsolidateIteration3Value11", key: "serieToConsolidateIteration3Value11" },
      { label: "serieToConsolidateIteration3Value12", key: "serieToConsolidateIteration3Value12" },
      { label: "serieToConsolidateIteration3Value13", key: "serieToConsolidateIteration3Value13" },
      { label: "serieToConsolidateIteration3Value14", key: "serieToConsolidateIteration3Value14" },
      { label: "serieToConsolidateIteration3Value15", key: "serieToConsolidateIteration3Value15" },
      { label: "cvIteration1", key: "cvIteration1" },
      { label: "cwIteration1", key: "cwIteration1" },
      { label: "cxIteration1", key: "cxIteration1" },
      { label: "cyIteration1", key: "cyIteration1" },
      { label: "czIteration1", key: "czIteration1" },
      { label: "daIteration1", key: "daIteration1" },
      { label: "dcIteration1", key: "dcIteration1" },
      { label: "dkIteration1", key: "dkIteration1" },
      { label: "dbIteration1", key: "dbIteration1" },
      { label: "cvIteration2", key: "cvIteration2" },
      { label: "cwIteration2", key: "cwIteration2" },
      { label: "cxIteration2", key: "cxIteration2" },
      { label: "cyIteration2", key: "cyIteration2" },
      { label: "czIteration2", key: "czIteration2" },
      { label: "daIteration2", key: "daIteration2" },
      { label: "dcIteration2", key: "dcIteration2" },
      { label: "dkIteration2", key: "dkIteration2" },
      { label: "dbIteration2", key: "dbIteration2" },
      { label: "cvIteration3", key: "cvIteration3" },
      { label: "cwIteration3", key: "cwIteration3" },
      { label: "cxIteration3", key: "cxIteration3" },
      { label: "cyIteration3", key: "cyIteration3" },
      { label: "czIteration3", key: "czIteration3" },
      { label: "daIteration3", key: "daIteration3" },
      { label: "dcIteration3", key: "dcIteration3" },
      { label: "dkIteration3", key: "dkIteration3" },
      { label: "dbIteration3", key: "dbIteration3" },
      { label: "ds", key: "ds" },
      { label: "dt", key: "dt" },
      { label: "du", key: "du" },
      { label: "dv", key: "dv" },
      { label: "dw", key: "dw" },
      { label: "dy", key: "dy" },
      { label: "dz", key: "dz" },
      { label: "ea", key: "ea" },
      { label: "eb", key: "eb" },
      { label: "ec", key: "ec" },
      { label: "ee", key: "ee" }
    ],
  },
];

const horizontalSpacing = 1;

const drawColumns = (series) => {
  let columns = [];
  for (let i = 0; i < series.length; i++) {
    for (let y = 0; y < series[i].keys.length; y++) {
      columns.push({ name: series[i].keys[y].label });
    }
    for (let s = 0; s < horizontalSpacing; s++) {
      columns.push({ name: " " });
    }
  }
  return columns;
};

const parseData = (data) => {
  const base = {
    date: data.date || undefined,
    close: data.base || undefined,
    closeT: data.t || undefined,
    baseIndicator: data.baseIndicator || undefined,

    az: data.az || undefined,
    ba: data.ba || undefined,
    bb: data.bb || undefined,
    bc: data.bc || undefined,
    bd: data.bd || undefined,

    serieToConsolidateIteration1Value1: data.serieToConsolidateIteration1Value1 || undefined,
    serieToConsolidateIteration1Value2: data.serieToConsolidateIteration1Value2 || undefined,
    serieToConsolidateIteration1Value3: data.serieToConsolidateIteration1Value3 || undefined,
    serieToConsolidateIteration1Value4: data.serieToConsolidateIteration1Value4 || undefined,
    serieToConsolidateIteration1Value5: data.serieToConsolidateIteration1Value5 || undefined,
    serieToConsolidateIteration1Value6: data.serieToConsolidateIteration1Value6 || undefined,
    serieToConsolidateIteration1Value7: data.serieToConsolidateIteration1Value7 || undefined,
    serieToConsolidateIteration1Value8: data.serieToConsolidateIteration1Value8 || undefined,
    serieToConsolidateIteration1Value9: data.serieToConsolidateIteration1Value9 || undefined,
    serieToConsolidateIteration1Value10: data.serieToConsolidateIteration1Value10 || undefined,
    serieToConsolidateIteration1Value11: data.serieToConsolidateIteration1Value11 || undefined,
    serieToConsolidateIteration1Value12: data.serieToConsolidateIteration1Value12 || undefined,
    serieToConsolidateIteration1Value13: data.serieToConsolidateIteration1Value13 || undefined,
    serieToConsolidateIteration1Value14: data.serieToConsolidateIteration1Value14 || undefined,
    serieToConsolidateIteration1Value15: data.serieToConsolidateIteration1Value15 || undefined,
    serieToConsolidateIteration2Value1: data.serieToConsolidateIteration2Value1 || undefined,
    serieToConsolidateIteration2Value2: data.serieToConsolidateIteration2Value2 || undefined,
    serieToConsolidateIteration2Value3: data.serieToConsolidateIteration2Value3 || undefined,
    serieToConsolidateIteration2Value4: data.serieToConsolidateIteration2Value4 || undefined,
    serieToConsolidateIteration2Value5: data.serieToConsolidateIteration2Value5 || undefined,
    serieToConsolidateIteration2Value6: data.serieToConsolidateIteration2Value6 || undefined,
    serieToConsolidateIteration2Value7: data.serieToConsolidateIteration2Value7 || undefined,
    serieToConsolidateIteration2Value8: data.serieToConsolidateIteration2Value8 || undefined,
    serieToConsolidateIteration2Value9: data.serieToConsolidateIteration2Value9 || undefined,
    serieToConsolidateIteration2Value10: data.serieToConsolidateIteration2Value10 || undefined,
    serieToConsolidateIteration2Value11: data.serieToConsolidateIteration2Value11 || undefined,
    serieToConsolidateIteration2Value12: data.serieToConsolidateIteration2Value12 || undefined,
    serieToConsolidateIteration2Value13: data.serieToConsolidateIteration2Value13 || undefined,
    serieToConsolidateIteration2Value14: data.serieToConsolidateIteration2Value14 || undefined,
    serieToConsolidateIteration2Value15: data.serieToConsolidateIteration2Value15 || undefined,
    serieToConsolidateIteration3Value1: data.serieToConsolidateIteration3Value1 || undefined,
    serieToConsolidateIteration3Value2: data.serieToConsolidateIteration3Value2 || undefined,
    serieToConsolidateIteration3Value3: data.serieToConsolidateIteration3Value3 || undefined,
    serieToConsolidateIteration3Value4: data.serieToConsolidateIteration3Value4 || undefined,
    serieToConsolidateIteration3Value5: data.serieToConsolidateIteration3Value5 || undefined,
    serieToConsolidateIteration3Value6: data.serieToConsolidateIteration3Value6 || undefined,
    serieToConsolidateIteration3Value7: data.serieToConsolidateIteration3Value7 || undefined,
    serieToConsolidateIteration3Value8: data.serieToConsolidateIteration3Value8 || undefined,
    serieToConsolidateIteration3Value9: data.serieToConsolidateIteration3Value9 || undefined,
    serieToConsolidateIteration3Value10: data.serieToConsolidateIteration3Value10 || undefined,
    serieToConsolidateIteration3Value11: data.serieToConsolidateIteration3Value11 || undefined,
    serieToConsolidateIteration3Value12: data.serieToConsolidateIteration3Value12 || undefined,
    serieToConsolidateIteration3Value13: data.serieToConsolidateIteration3Value13 || undefined,
    serieToConsolidateIteration3Value14: data.serieToConsolidateIteration3Value14 || undefined,
    serieToConsolidateIteration3Value15: data.serieToConsolidateIteration3Value15 || undefined,
    cvIteration1: data.cvIteration1 || undefined,
    cwIteration1: data.cwIteration1 || undefined,
    cxIteration1: data.cxIteration1 || undefined,
    cyIteration1: data.cyIteration1 || undefined,
    czIteration1: data.czIteration1 || undefined,
    daIteration1: data.daIteration1 || undefined,
    dcIteration1: data.dcIteration1 || undefined,
    dkIteration1: data.dkIteration1 || undefined,
    dbIteration1: data.dbIteration1 || undefined,
    cvIteration2: data.cvIteration2 || undefined,
    cwIteration2: data.cwIteration2 || undefined,
    cxIteration2: data.cxIteration2 || undefined,
    cyIteration2: data.cyIteration2 || undefined,
    czIteration2: data.czIteration2 || undefined,
    daIteration2: data.daIteration2 || undefined,
    dcIteration2: data.dcIteration2 || undefined,
    dkIteration2: data.dkIteration2 || undefined,
    dbIteration2: data.dbIteration2 || undefined,
    cvIteration3: data.cvIteration3 || undefined,
    cwIteration3: data.cwIteration3 || undefined,
    cxIteration3: data.cxIteration3 || undefined,
    cyIteration3: data.cyIteration3 || undefined,
    czIteration3: data.czIteration3 || undefined,
    daIteration3: data.daIteration3 || undefined,
    dcIteration3: data.dcIteration3 || undefined,
    dkIteration3: data.dkIteration3 || undefined,
    dbIteration3: data.dbIteration3 || undefined,
    ds: data.ds || undefined,
    dt: data.dt || undefined,
    du: data.du || undefined,
    dv: data.dv || undefined,
    dw: data.dw || undefined,
    dy: data.dy || undefined,
    dz: data.dz || undefined,
    ea: data.ea || undefined,
    eb: data.eb || undefined,
    ec: data.ec || undefined,
    ee: data.ee || undefined
  };
  const modelsKeys = Object.keys(data).filter((key) => key.match('"model".*'));
  const predictions = modelsKeys.map((modelKey) => {
    const prediction = JSON.parse(modelKey.split("-")[0]);
    const valueKey = modelKey.split("-")[1];
    const value = data[modelKey];
    return { ...prediction, valueKey, value };
  });

  return {
    base,
    predictions: predictions.length > 0 ? predictions : undefined,
  };
};

const findPredictionsFromKey = (predictions, valueKey) => {
  if (predictions) {
    return predictions.filter((prediction) => valueKey === prediction.valueKey);
  } else {
    return [];
  }
};

const drawRows = (series, data) => {
  const rows = [];

  for (let i = 0; i < data.length; i++) {
    const seriesRows = [];
    const parsedData = parseData(data[i]);

    let keyIndex = 0;
    let oldValueKey = series[0].valueKey;

    for (let y = 0; y < series.length; y++) {
      const serie = series[y];

      // Find predictions associated to the valueKey
      const currentPredictions = findPredictionsFromKey(
        parsedData.predictions,
        serie.valueKey
      );

      // If we don't have a duplicated valueKey we reset the keyIndex
      if (oldValueKey !== serie.valueKey) {
        keyIndex = 0;
      }

      // Explore all needed keys
      for (let x = 0; x < serie.keys.length; x++) {
        const currentKey = serie.keys[x].key;

        // Get a base value
        if (parsedData.base[currentKey]) {
          seriesRows.push(parsedData.base[currentKey]);
        } else {
          // Get a prediction value
          if (parsedData.predictions) {
            if (currentPredictions.length > 0) {
              const value = currentPredictions[keyIndex][currentKey];
              seriesRows.push(value);
            } else {
              // If prediction is empty push an empty column
              seriesRows.push("");
            }
            // If not base & not prediction push an empty column
          } else {
            seriesRows.push("");
          }
        }
      }

      // If multiple prediction with identical valueKey, we increment index to get the next value
      if (
        currentPredictions.length !== 0 &&
        currentPredictions.length - 1 > keyIndex
      ) {
        keyIndex++;
      }
      oldValueKey = serie.valueKey;

      // Add spacing
      for (let s = 0; s < horizontalSpacing; s++) {
        seriesRows.push("");
      }
    }
    rows.push(seriesRows.flat());
  }
  return rows;
};

export const useExportDataToXSLXPhase2Mutation = () => {
  return useMutation(async ({ phase2 }) => {
    const { default: ExcelJS } = await import("exceljs");
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("export");
    // drawHeaders(series, worksheet);
    worksheet.addTable({
        ref: "A1",
      columns: drawColumns(seriesPhase2),
      rows: drawRows(seriesPhase2, phase2[0].exportData),
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer]);
    let fileName = "export-phase2";
    saveAs(blob, `${fileName}.xlsx`);
  });
};
