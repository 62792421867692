/** @jsxImportSource @emotion/react */
import {
  ArrowNarrowRightIcon,
  CalendarIcon,
  XIcon,
} from "@heroicons/react/solid";
import DatePicker from "react-date-picker";
import "twin.macro";
import tw from "twin.macro";

export const DatePickerCustom = ({
  name,
  control,
  rules,
  defaultValue,
  shouldUnregister,
  dateExtremes,
  ...props
}) => {

  return (
    <DatePicker
      format="yyyy/MM/dd"
      tw="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md mt-1"
      css={{
        "& .react-date-picker": tw`shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md mt-1`,
        "& .react-date-picker__wrapper": tw`border-none`,
        "& .react-date-picker__inputGroup": tw`px-0 py-2`,
        "& .react-date-picker__inputGroup__input": tw`py-0 h-auto text-sm focus:ring-0 rounded-sm`,
        "& .react-date-picker__inputGroup__input:invalid": tw`bg-gray-100`,
        "& .react-datetime-picker__inputGroup__divider": tw`text-sm`,
        "& .react-date-picker__inputGroup__leadingZero": tw`text-sm`,
        "& .react-date-picker__button": tw`text-gray-300 flex p-2 hover:text-gray-400 focus:(outline-none text-gray-500) transition-colors`,
        "& .react-date-picker__button__icon": {
          stroke: `currentColor !important`,
        },
        "& .react-date-picker__calendar": tw`[z-index:99] w-full mt-1 bg-white shadow-lg rounded-md ring-1 ring-black ring-opacity-5`,
        "& .react-calendar": tw`w-full border-none bg-transparent text-sm text-gray-800 font-sans`,
      }}
      clearIcon={<XIcon tw="w-4 h-4" />}
      calendarIcon={<CalendarIcon tw="w-4 h-4" />}
      rangeDivider={<ArrowNarrowRightIcon tw="w-4 h-4 text-gray-300" />}
      minDate={dateExtremes?.min}
      maxDate={dateExtremes?.max}
      {...props}
    />
  );
};
